import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import theme from 'theme';
import {
  StyledFilterTabs,
  StyledFilterTab,
  StyledFilterMenu,
  FilterText,
  FilterTrigger,
  StyledFilterMenuItem,
  FilterTitle
} from './styles';
import FilterIcon from 'icons/WideFilterIcon';
import { FlexRow } from 'components/styles';
import Popover from 'components/Popover';
import TaskListIcon from 'icons/TaskListIcon';
import { makeGetOrderedFilterPositions } from 'BudgetModule/selectors/positions';
import { makeGetFormattedSkillsWithMembers } from 'SettingsModule/selectors';
import { getFormattedOfficesWithMembers } from 'SettingsModule/selectors/offices';
import { getFormattedRegionsWithMembers } from 'SettingsModule/selectors/regions';
import { getFormattedDisciplinesWithMembers } from 'SettingsModule/selectors/disciplines';

const makeMapStateToProps = () => {
  const getOrderedFilterPositions = makeGetOrderedFilterPositions();
  const getFormattedSkillsWithMembers = makeGetFormattedSkillsWithMembers();

  const mapStateToProps = (state, ownProps) => {
    return {
      positions: getOrderedFilterPositions(state, ownProps),
      skillsArray: getFormattedSkillsWithMembers(state),
      offices: getFormattedOfficesWithMembers(state),
      regions: getFormattedRegionsWithMembers(state),
      disciplines: getFormattedDisciplinesWithMembers(state)
    };
  };
  return mapStateToProps;
};

const FilterTabs = ({
  activeFilterTab,
  onFilterTabSelect,
  filterTabs,
  sectionType,
  className = 'workload-planner'
}) => (
  <StyledFilterTabs
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around'
    }}
    className={className}
  >
    {filterTabs.map(({ label, value }) => (
      <StyledFilterTab
        onClick={() => onFilterTabSelect({ [sectionType]: value })}
        className={activeFilterTab === value ? 'active' : ''}
        key={value}
      >
        {label}
      </StyledFilterTab>
    ))}
  </StyledFilterTabs>
);

const projectFilterTabs = [
  { value: 'projects', label: 'All Projects' },
  { value: 'projectsByBoard', label: 'Portfolios' },
  { value: 'projectsByMember', label: 'All Members' }
];

const projectFilterHash = {
  projects: 'All Projects',
  projectsByBoard: 'Portfolios',
  projectsByMember: 'All Members'
};

const orderTab = (tabs) => {
  const emptyListTabs = [];
  const nonEmptyListTabs = [];

  tabs.forEach((tab) =>
    tab.isEmpty ? emptyListTabs.push(tab) : nonEmptyListTabs.push(tab)
  );

  return [...nonEmptyListTabs, ...emptyListTabs];
};

const FilterMenu = ({
  filterHash,
  activeFilterTab,
  onFilterTabSelect,
  filterTabs,
  sectionType,
  className = 'workload-planner',
  popperClassName = '',
  openCrossFieldFilters,
  isCrossFieldFiltersOpen,
  numCrossFieldFiltersUsed
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const target = useRef(null);

  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleSelect = (value) => {
    onFilterTabSelect({ [sectionType]: value });
    closeMenu();
  };

  return (
    <div>
      <FlexRow>
        {openCrossFieldFilters && !isCrossFieldFiltersOpen && (
          <FilterTrigger onClick={openCrossFieldFilters}>
            <FilterIcon
              height={16}
              color={theme.colors.colorCalendarBlue}
              className="filter-icon"
            />
            <FilterText>
              {numCrossFieldFiltersUsed || ''} Filter
              {numCrossFieldFiltersUsed > 1 ? 's' : ''}
            </FilterText>
          </FilterTrigger>
        )}

        <FilterTrigger onClick={openMenu} ref={target}>
          <TaskListIcon
            width={14}
            height={10}
            color={theme.colors.colorCalendarBlue}
          />
          <BoldedFilterText> {filterHash[activeFilterTab]} </BoldedFilterText>
        </FilterTrigger>
      </FlexRow>
      <Popover
        className={popperClassName}
        isOpen={isOpen}
        closePopover={closeMenu}
        target={target}
      >
        <StyledFilterMenu
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
          }}
          className={className}
        >
          <FilterTitle> Group By </FilterTitle>
          {filterTabs.map(({ label, value, isEmpty = false }) => (
            <StyledFilterMenuItem
              onClick={() => handleSelect(value)}
              className={activeFilterTab === value ? 'active' : ''}
              isEmpty={isEmpty}
              key={value}
            >
              {label}
            </StyledFilterMenuItem>
          ))}
        </StyledFilterMenu>
      </Popover>
    </div>
  );
};

const memberFilterHash = {
  members: 'All Members',
  workGroups: 'Departments',
  membersByBoard: 'Portfolio',
  membersByPosition: 'Roles',
  skills: 'Skills',
  regions: 'Regions',
  offices: 'Offices',
  disciplines: 'Disciplines'
};

const _MemberFilterMenu = ({
  activeFilterTab,
  onFilterTabSelect,
  positions,
  skillsArray,
  offices,
  regions,
  disciplines,
  className
}) => {
  const memberFilterTabs = [
    { value: 'members', label: 'All Members', isEmpty: false }, // There is always at least 1 member
    {
      value: 'workGroups',
      label: 'Departments',
      isEmpty: false
    }, // There are two default department (No Department and Archived), the only member will be a part of No Department
    { value: 'membersByBoard', label: 'Portfolio', isEmpty: false }, // there will always be portfolio
    {
      value: 'membersByPosition',
      label: 'Roles',
      isEmpty: !positions?.length
    },
    {
      value: 'regions',
      label: 'Regions',
      isEmpty: !regions?.length
    },
    {
      value: 'disciplines',
      label: 'Disciplines',
      isEmpty: !disciplines?.length
    },
    { value: 'skills', label: 'Skills', isEmpty: !skillsArray?.length },
    { value: 'offices', label: 'Offices', isEmpty: !offices?.length }
  ];

  const orderedMemberFilterTabs = orderTab(memberFilterTabs);

  return (
    <FilterMenu
      filterHash={memberFilterHash}
      filterTabs={orderedMemberFilterTabs}
      activeFilterTab={activeFilterTab}
      onFilterTabSelect={onFilterTabSelect}
      popperClassName="member-filter-menu-popover"
      className={className}
      sectionType="members"
    />
  );
};

export const MemberFilterMenu = connect(makeMapStateToProps)(_MemberFilterMenu);

export const ProjectFilterMenu = ({
  activeFilterTab,
  onFilterTabSelect,
  className,
  openCrossFieldFilters,
  isCrossFieldFiltersOpen,
  numCrossFieldFiltersUsed
}) => (
  <FilterMenu
    filterHash={projectFilterHash}
    filterTabs={projectFilterTabs}
    activeFilterTab={activeFilterTab}
    onFilterTabSelect={onFilterTabSelect}
    popperClassName="project-filter-menu-popover"
    className={className}
    sectionType="projects"
    openCrossFieldFilters={openCrossFieldFilters}
    isCrossFieldFiltersOpen={isCrossFieldFiltersOpen}
    numCrossFieldFiltersUsed={numCrossFieldFiltersUsed}
  />
);

export const ProjectFilterTabs = ({
  activeFilterTab,
  onFilterTabSelect,
  className,
  projectFilterTabsOverride
}) => (
  <FilterTabs
    filterTabs={projectFilterTabsOverride || projectFilterTabs}
    activeFilterTab={activeFilterTab}
    onFilterTabSelect={onFilterTabSelect}
    className={className}
    sectionType="projects"
  />
);

export const VirtualFilterMemberTabs = ({ setFilterTabs, filterTabs }) => (
  <MemberFilterMenu
    className="virtual-filter"
    activeFilterTab={filterTabs.members}
    onFilterTabSelect={({ members }) =>
      setFilterTabs({ name: 'members', value: members })
    }
  />
);

export const VirtualFilterProjectTabs = ({ setFilterTabs, filterTabs }) => (
  <ProjectFilterMenu
    className="virtual-filter"
    activeFilterTab={filterTabs.members}
    onFilterTabSelect={({ members }) =>
      setFilterTabs({ name: 'members', value: members })
    }
  />
);

VirtualFilterMemberTabs.rowHeight = 28;
VirtualFilterProjectTabs.rowHeight = 28;

const BoldedFilterText = styled(FilterText)`
  font-weight: 600;
`;
