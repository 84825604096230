import { useCallback } from 'react';
import { FilterField } from 'FilterModule/constants';
import { FilterListTypeHook } from 'FilterModule/types';
import { useFilterContext } from 'FilterModule/FilterContextProvider';

const emptyObj = {};
const emptyArray = [];

export const useShowProjectsWithUnassignedRolesFilter: FilterListTypeHook = ({
  field = FilterField.isProjectsWithUnassignedRoles_cf,
  shouldUseDraft
} = emptyObj) => {
  const { currentFilter, draftFilter } = useFilterContext();

  // TODO: can make this reusable for other boolean filter fields

  const isFiltering = Boolean(
    shouldUseDraft
      ? draftFilter[field] ?? currentFilter[field]
      : currentFilter[field]
  );

  const getIsSelected = useCallback(() => isFiltering, [isFiltering]);

  const toggleValue = useCallback(() => {
    (shouldUseDraft ? draftFilter : currentFilter).update({
      [field]: !isFiltering
    });
  }, [currentFilter, field, isFiltering, draftFilter, shouldUseDraft]);

  const selectedItems = isFiltering ? items : emptyArray;

  return {
    toggleSelectedItem: toggleValue,
    optionsArray: items,
    onlySelectedItemLabel: '',
    getIsSelected,
    selectedItems
  };
};

/* ------------------------------------ - ----------------------------------- */

const option = `Only show Projects \nwith Open Roles`;

const items = [option];
