import styled, { css } from 'styled-components';
import { FindPeopleTableVariantType } from '../Table/tableConfigs';
import theme from 'theme';

const calculateTableContainerVariantStyles = {
  MemberSuggestionByPhase: ({ isLoading }) => css`
    min-width: 720px;
    padding-top: ${isLoading ? '30px' : 'unset'};

    .variable-size-list {
      overflow: overlay !important;
      height: 83vh !important;
    }

    .tr {
      &.memberGroupRow {
        &.open {
          .title,
          .date,
          .hours {
            background-color: ${theme.colors.colorPureWhite};
          }
        }
      }

      &.reasonRow {
        .title,
        .date,
        .hours {
          background-color: ${theme.colors.colorPureWhite};
          padding-top: 5px;
        }
      }

      &.stickyHeader {
        &.phaseGroupRow {
          padding: 0;
          .td {
            background-color: ${theme.colors.colorLightGray19};
          }
        }
      }
    }
  `
};

export const StyledTable = styled.div<{
  variant: FindPeopleTableVariantType;
}>`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const ContainerDiv = styled.div<{
  variant: FindPeopleTableVariantType;
  isLoading?: boolean;
}>`
  ${({ variant, isLoading }) =>
    calculateTableContainerVariantStyles[variant]?.({ isLoading })}
`;
export const StyledColumnLabel = styled.div`
  font-size: 13px;
  font-weight: 600;
  height: 100%;
  text-align: center;
  padding-top: 27px;
  color: ${theme.colors.colorLightGray15};

  &.date {
    .date-range {
      color: ${theme.colors.colorCalendarBlue};
      cursor: pointer;
      border-radius: 3px;
    }

    &:hover {
      .date-range {
        background: ${theme.colors.colorLightGray17};
      }
    }
  }

  &.hour {
    padding-top: 20px;
    color: ${theme.colors.colorCalendarBlue};

    .legend-item-container {
      padding: 5px 0 0 6px;
      font-size: 14px;
      font-weight: 600;
      color: ${theme.colors.colorLightGray15};
    }

    .non-editable-field {
      cursor: pointer;
    }

    .hour-input {
      position: relative;
      top: -2px;

      &:focus {
        background: ${theme.colors.colorPureWhite};
      }
      ::placeholder {
        color: ${theme.colors.colorCalendarBlue};
      }
    }
  }
`;
