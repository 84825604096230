import { useState } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';
import { useSelector, connect } from 'react-redux';
import { getSelectedTeamId, getProjectHash, getGroupsHash } from 'selectors';
import PropTypes from 'prop-types';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';

import {
  InviteMemberList,
  AddMemberButton,
  BulkAddMemberListContainer,
  FilterableAddMemberListContainer,
  InviteRow
} from '..';

import {
  StyledTabContainer,
  StyledTab,
  StyledBlueSubmitButton,
  StyledWhiteSubmitButton,
  BulkAddMemberInputContainer
} from './BulkAddMemberList';
import NewCloseIcon from 'icons/NewCloseIcon';

import EditMemberListHeaderTitle from './EditMemberListHeaderTitle';
import useSuggestionTableLoadingState from '../boardDisplay/SuggestionsTable/useSuggestionTableLoadingState';
import useFindPeopleModal from 'SuggestionModule/hooks/useFindPeopleModal';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';
import useCan from 'appUtils/hooks/useCan';
import {
  createPrivatePortfolioMembership,
  createPublicPortfolioMembership
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import {
  CREATE_PORTFOLIO_MEMBERSHIP_TIP,
  INVITE_MEMBER_TO_TEAM_TIP,
  ADD_PROJECT_MEMBERSHIP_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import {
  inviteMemberToTeam,
  inviteGuests
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { noop } from 'appUtils';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const CloseIconContainer = styled.div`
  align-self: start;
  cursor: pointer;
  display: flex;
  margin-top: -2px;
  border-radius: 100px;
  &:hover {
    background: ${theme.colors.colorLightGray27};
  }
`;

const InputClearIconContainer = styled.div`
  position: absolute;
  right: 60px;
  top: calc(50% - 8px);
  display: flex;
  cursor: pointer;
  align-items: center;
  &:hover {
    circle {
      fill: ${theme.colors.colorLightGray17};
    }
  }
`;

export function moveMeToTop(me, memberList) {
  const myAccount = memberList.find((member) => member.account.id === me.id);
  if (!myAccount) {
    return memberList;
  } else {
    const notMe = memberList.filter((member) => member.account.id !== me.id);
    return [myAccount, ...notMe];
  }
}

const Header = styled.header``;

const FieldSet = styled.fieldset`
  .add-member-button {
    display: inline-block;
    margin-left: 70px;
    padding-left: 0;
  }

  &.suggestion-table {
    background-color: ${theme.colors.colorLightGray19};
    ${Header} {
      background-color: ${theme.colors.colorLightGray19};
    }
  }
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`;

const InviteRowContainer = styled.div`
  padding: 10px 54px 16px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .invite-row {
    padding-top: 0 !important;
    border-top: none !important;
    & > span {
      margin-right: 6px;
    }
  }
`;

const TeamBuilderButton = styled.button`
  border-radius: 20px;
  color: ${theme.colors.colorCalendarBlue};
  border: 0.5px solid ${theme.colors.colorCalendarBlue};
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 12px 5px 8px;
  background: ${theme.colors.colorPureWhite};
  &:hover {
    background: ${theme.colors.colorPowderBlue};
  }
`;

const EditMemberList = ({
  allMemberList,
  memberList,
  showInput,
  toggleMemberInput,
  clearMemberFilter,
  showInviteButton,
  toggleInviteButton,
  showInviteForm,
  toggleInviteForm,
  membersNotInBoard,
  inputValue,
  changeInputValue,
  addMemberToSelection,
  addBulkMembersToSelection,
  addMemberToProject,
  selection,
  deleteMemberFromSelection,
  openTeamMemberModal,
  closeModal,
  onCloseModal,
  me,
  roles,
  changeRoleFromLocalList,
  groupId,
  projectId,
  inviteTeamMember,
  addMembersForm,
  bulkAddIsOpen,
  openBulkAddMembersForm,
  closeBulkAddMembersForm,
  membersNotInGroup,
  populateBulkAddMembers,
  emptyBulkAddMembers,
  openAddMembersForm,
  isContained,
  showSuggestions,
  setSuggestions = () => {},
  inBudgetModal,
  boardMembers,
  onClose,
  project,
  checkPermission,
  inviteMemberToTeam,
  inviteGuests
}) => {
  const selectedTeamId = useSelector(getSelectedTeamId);
  const projectHash = useSelector(getProjectHash);
  const groupHash = useSelector(getGroupsHash);
  const { newMembersSuggestionTableFlag } = useFeatureFlags();
  const { isHoursOnly } = useIsHoursOnly();

  const { isSuggestionTableLoading } = useSuggestionTableLoadingState();
  const { handleOpenFindPeopleModal, FindPeopleModal } = useFindPeopleModal();

  const isLoading = showSuggestions ? isSuggestionTableLoading : false;

  const [membersAdded, setMembersAdded] = useState([]);

  const { canAddProjectMembership } = useProjectPermissionState({ projectId });

  const groupIdToAdd = projectId || groupId;

  const bulkAddMembers = () => {
    const memberIds = membersAdded.map((member) => member.account.id);
    if (projectId) {
      const permissions = { projectId, teamId: selectedTeamId };
      addBulkMembersToSelection(groupIdToAdd, memberIds, permissions);
    } else {
      addBulkMembersToSelection(groupIdToAdd, memberIds);
    }
    closeBulkAddMembersForm();
  };

  const onCancel = () => {
    setMembersAdded([]);
    closeBulkAddMembersForm();
    changeInputValue('');
  };

  // Some EditMemberList project prop does not contain the title, need to get
  // title from project hash.
  const projectToUse = project?.title ? project : projectHash[projectId];
  const entityToUse = projectId ? projectToUse : groupHash[groupId];
  const {
    name,
    description,
    title,
    id,
    is_private: isPrivate
  } = entityToUse || {};

  // Portfolio == group == board
  const addToPortfolioCheck = isPrivate
    ? createPrivatePortfolioMembership
    : createPublicPortfolioMembership;

  const canAddToPortfolio = useCan(addToPortfolioCheck, {
    boardId: groupId
  });

  const permissions = { projectId, teamId: selectedTeamId };
  const canInviteToTeam = checkPermission(inviteMemberToTeam, {
    permissions
  });
  const canInviteGuests = checkPermission(inviteGuests, {
    permissions
  });
  const canInvite = canInviteToTeam || canInviteGuests;

  const renderHeader = () => {
    return (
      <>
        <Header className={`add-members-header`}>
          <HeaderTitleContainer>
            <EditMemberListHeaderTitle
              description={description}
              entityType={projectId ? 'project' : 'board'}
              originType={
                projectId
                  ? ORIGIN_TYPE_STRINGS.PROJECT
                  : ORIGIN_TYPE_STRINGS.BOARD
              }
              id={id}
              isLoading={isLoading}
              title={title || name || ''}
              accessText={
                inBudgetModal &&
                !isHoursOnly &&
                'Only Financial Managers and Admins can view and edit rates.'
              }
            />
          </HeaderTitleContainer>
          {addMembersForm?.isOpen && bulkAddIsOpen && !showSuggestions ? (
            <div style={{ display: 'flex' }}>
              <StyledWhiteSubmitButton
                onClick={onCancel}
                style={{ marginRight: 8 }}
              >
                Cancel
              </StyledWhiteSubmitButton>
              <StyledBlueSubmitButton
                onClick={!membersAdded.length ? onCancel : bulkAddMembers}
                className={cn({
                  disabled: !membersAdded.length
                })}
              >
                {!membersAdded.length ? 'Add' : `Add ${membersAdded.length}`}
              </StyledBlueSubmitButton>
            </div>
          ) : (
            <CloseIconContainer
              onClick={() => {
                if (setSuggestions) {
                  setSuggestions(false);
                }
                if (closeBulkAddMembersForm) {
                  closeBulkAddMembersForm();
                }
                if (onClose) {
                  onClose();
                }
                if (closeModal) {
                  closeModal();
                }
                if (onCloseModal) {
                  // explicitly close modal regardless of other props
                  onCloseModal();
                }
              }}
            >
              <NewCloseIcon
                width={35}
                height={35}
                noCircle
                fillColor={theme.colors.colorPureBlack}
              />
            </CloseIconContainer>
          )}
        </Header>
        {/* This portion is tabs for old members suggestion table */}
        {projectId && !newMembersSuggestionTableFlag && (
          <StyledTabContainer>
            <StyledTab
              isActive={!showSuggestions}
              onClick={() => setSuggestions(false)}
            >
              {' '}
              Members
            </StyledTab>
            <StyledTab
              className="suggestion"
              isActive={showSuggestions}
              onClick={() => setSuggestions(true)}
            >
              {' '}
              Suggestions
            </StyledTab>
            <StyledTab></StyledTab>
          </StyledTabContainer>
        )}
        {!showSuggestions && addMembersForm?.isOpen && bulkAddIsOpen && (
          <>
            <InviteRowContainer>
              <InviteRow
                toggleInviteForm={canInvite ? toggleInviteForm : noop}
                borderTop={membersAdded.length}
                tooltip={canInvite ? '' : INVITE_MEMBER_TO_TEAM_TIP}
              />
              {projectId && newMembersSuggestionTableFlag && (
                <TeamBuilderButton
                  onClick={(e) => {
                    handleOpenFindPeopleModal({ projectId });
                  }}
                >
                  <GoToProjectIcon viewBox="0 -0.5 24 24" /> Team Builder
                </TeamBuilderButton>
              )}
            </InviteRowContainer>
            <BulkAddMemberInputContainer className="form-field-full">
              <input
                type="text"
                name="name"
                placeholder="Type name or select below"
                autoFocus={false}
                autoComplete="off"
                onChange={changeInputValue}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') e.preventDefault();
                }}
                value={inputValue}
                onFocus={() => toggleMemberInput(true)}
              />
              {inputValue !== '' ? (
                <InputClearIconContainer onClick={() => changeInputValue('')}>
                  <NewCloseIcon
                    width={20}
                    height={20}
                    fillColor={theme.colors.colorRoyalBlue}
                    stroke="transparent"
                  />
                </InputClearIconContainer>
              ) : null}
            </BulkAddMemberInputContainer>
          </>
        )}
      </>
    );
  };
  const renderContents = () => {
    if (addMembersForm && addMembersForm.isOpen) {
      if (bulkAddIsOpen || showSuggestions) {
        return (
          <BulkAddMemberListContainer
            membersNotInGroup={membersNotInGroup}
            inputValue={inputValue}
            clearMemberFilter={clearMemberFilter}
            changeInputValue={changeInputValue}
            showInviteButton={showInviteButton}
            toggleInviteButton={toggleInviteButton}
            showInviteForm={showInviteForm}
            addMemberToSelection={addMemberToSelection}
            addBulkMembersToSelection={addBulkMembersToSelection}
            addMemberToProject={addMemberToProject}
            selection={selection}
            openTeamMemberModal={openTeamMemberModal}
            closeModal={closeModal}
            me={me}
            groupId={groupId}
            projectId={projectId}
            membersNotInBoard={membersNotInBoard}
            boardMembers={boardMembers}
            toggleInviteForm={toggleInviteForm}
            inviteTeamMember={inviteTeamMember}
            addMembersForm={addMembersForm}
            toggleMemberInput={toggleMemberInput}
            membersAdded={membersAdded}
            setMembersAdded={setMembersAdded}
            showInput={showInput}
            bulkAddIsOpen={bulkAddIsOpen}
            closeBulkAddMembersForm={closeBulkAddMembersForm}
            populateBulkAddMembers={populateBulkAddMembers}
            emptyBulkAddMembers={emptyBulkAddMembers}
            isContained={isContained}
            showSuggestions={showSuggestions}
            setSuggestions={setSuggestions}
          />
        );
      } else {
        // If Project ID is not provided, then it is a board.
        return [
          <AddMemberButton
            className="add-member-button"
            key={1}
            onClick={openBulkAddMembersForm}
            projectId={projectId}
            disabled={projectId ? !canAddProjectMembership : !canAddToPortfolio}
            tooltip={
              projectId
                ? !canAddProjectMembership
                  ? ADD_PROJECT_MEMBERSHIP_TIP
                  : undefined
                : !canAddToPortfolio
                ? CREATE_PORTFOLIO_MEMBERSHIP_TIP
                : undefined
            }
            handleOpenFindPeopleModal={handleOpenFindPeopleModal}
            newMembersSuggestionTableFlag={newMembersSuggestionTableFlag}
          />,
          <InviteMemberList
            key={2}
            memberList={memberList}
            selection={selection}
            deleteMemberFromSelection={deleteMemberFromSelection}
            me={me}
            roles={roles}
            changeRoleFromLocalList={changeRoleFromLocalList}
            groupType={projectId ? 'Project' : 'Portfolio'}
            inBudgetModal={inBudgetModal}
            projectId={projectId}
            inputValue={inputValue}
            alwaysShowThreeDot
          />
        ];
      }
    } else {
      return [
        showInput ? (
          <>
            <FilterableAddMemberListContainer
              key={1}
              allMemberList={
                membersNotInGroup || moveMeToTop(me, allMemberList)
              }
              inputValue={inputValue}
              clearMemberFilter={clearMemberFilter}
              changeInputValue={changeInputValue}
              showInviteButton={showInviteButton}
              toggleInviteButton={toggleInviteButton}
              showInviteForm={showInviteForm}
              toggleInviteForm={toggleInviteForm}
              addMemberToSelection={addMemberToSelection}
              addMemberToProject={addMemberToProject}
              openTeamMemberModal={openTeamMemberModal}
              closeModal={closeModal}
              me={me}
              groupId={groupId}
              projectId={projectId}
              membersNotInBoard={membersNotInBoard}
              inviteTeamMember={inviteTeamMember}
              addMembersForm={addMembersForm}
              toggleMemberInput={toggleMemberInput}
              showInput={showInput}
            />
            <div
              key={2}
              className="add-member-spacer-div"
              style={{ height: '57px' }}
            />
          </>
        ) : (
          <AddMemberButton
            key={3}
            onClick={() => toggleMemberInput(true)}
            handleOpenFindPeopleModal={handleOpenFindPeopleModal}
            newMembersSuggestionTableFlag={newMembersSuggestionTableFlag}
          />
        ),
        <InviteMemberList
          key={4}
          memberList={memberList}
          selection={selection}
          deleteMemberFromSelection={deleteMemberFromSelection}
          me={me}
          roles={roles}
          changeRoleFromLocalList={changeRoleFromLocalList}
          inBudgetModal={inBudgetModal}
          projectId={projectId}
        />
      ];
    }
  };

  return (
    <FieldSet
      className={`insert-delete-member-list ${
        showSuggestions && 'suggestion-table'
      }`}
    >
      <legend>Members</legend>
      {renderHeader()}
      {renderContents()}
      {newMembersSuggestionTableFlag && <FindPeopleModal />}
    </FieldSet>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  inviteMemberToTeam,
  inviteGuests
};

EditMemberList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  memberList: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  allMemberList: PropTypes.array,
  showInput: PropTypes.bool.isRequired,
  toggleMemberInput: PropTypes.func.isRequired,
  changeInputValue: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  addMemberToSelection: PropTypes.func.isRequired,
  deleteMemberFromSelection: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selection: PropTypes.object,
  openTeamMemberModal: PropTypes.func,
  closeModal: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  roles: PropTypes.array,
  changeRoleFromLocalList: PropTypes.func
};

export default withPermissionsCheck(
  connect(mapStateToProps, mapDispatchToProps)(EditMemberList)
);
