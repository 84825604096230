import {
  FilterListType,
  memberFilterListTypes,
  FilterField
} from 'FilterModule/constants';
import { FilterFieldSchema } from 'FilterModule/types';
import {
  makeDefaultFilterListTypeFieldSchema,
  defaultNumberArrayFieldSchema,
  defaultStringArrayFieldSchema,
  makeDefaultBooleanFieldSchema
} from '.';

const emptyObj = {};

const defaultMemberFilterListType = FilterListType.Members;

export const defaultProjectCrossFieldFilterListTypes = [
  FilterListType.Billable,
  FilterListType.ClientNames,
  defaultMemberFilterListType, // will be replaced in validation func below
  FilterListType.ShowProjectsWithUnassignedRoles,
  FilterListType.PhaseBudgetStatus,
  FilterListType.PhaseNames,
  FilterListType.Priorities,
  FilterListType.ProfitCenter,
  FilterListType.ProjectBudgetStatus,
  FilterListType.Regions
];

export type ProjectsCrossFilterFieldSchemas = {
  [FilterField.stackedFilterOrder]?: FilterFieldSchema<
    FilterListType,
    FilterListType[],
    {
      filterLabel?: string;
    }
  >;
  [FilterField.account_ids_cf]: typeof defaultNumberArrayFieldSchema;
  [FilterField.clients_cf]: typeof defaultStringArrayFieldSchema;
  [FilterField.project_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.phase_names_cf]: typeof defaultStringArrayFieldSchema;
  [FilterField.billable_cf]: typeof defaultStringArrayFieldSchema;
  [FilterField.region_ids_cf]: typeof defaultNumberArrayFieldSchema;
  [FilterField.projectBudgetStatus_cf]: typeof defaultStringArrayFieldSchema;
  [FilterField.phaseBudgetStatus_cf]: typeof defaultStringArrayFieldSchema;
  [FilterField.profit_center_cf]: typeof defaultStringArrayFieldSchema;
  [FilterField.isProjectsWithUnassignedRoles_cf]: ReturnType<
    typeof makeDefaultBooleanFieldSchema
  >;
  [FilterField.priority_ids_cf]: typeof defaultStringArrayFieldSchema;
  [FilterField.stackedFilterMembersFilterListType]: FilterFieldSchema<FilterListType>;
};

export const makeDefaultProjectCrossFiltersFieldSchemas = ({
  selectionLimit,
  stackedFilterOrder = defaultProjectCrossFieldFilterListTypes
}: {
  selectionLimit?: number;
  stackedFilterOrder?: FilterListType[];
} = emptyObj): ProjectsCrossFilterFieldSchemas => {
  return {
    [FilterField.project_ids]: defaultNumberArrayFieldSchema,

    [FilterField.account_ids_cf]: defaultNumberArrayFieldSchema,

    [FilterField.phase_names_cf]: defaultStringArrayFieldSchema,

    [FilterField.clients_cf]: defaultStringArrayFieldSchema,

    [FilterField.billable_cf]: defaultStringArrayFieldSchema,

    [FilterField.region_ids_cf]: defaultNumberArrayFieldSchema,

    [FilterField.projectBudgetStatus_cf]: defaultStringArrayFieldSchema,

    [FilterField.phaseBudgetStatus_cf]: defaultStringArrayFieldSchema,

    [FilterField.profit_center_cf]: defaultStringArrayFieldSchema,

    [FilterField.priority_ids_cf]: defaultStringArrayFieldSchema,

    [FilterField.isProjectsWithUnassignedRoles_cf]:
      makeDefaultBooleanFieldSchema(),

    [FilterField.stackedFilterMembersFilterListType]:
      makeDefaultFilterListTypeFieldSchema({
        defaultValue: defaultMemberFilterListType,
        optionsArray: memberFilterListTypes
      }),

    [FilterField.stackedFilterOrder]: {
      defaultValue: stackedFilterOrder,
      optionHash: {
        [FilterListType.Members]: {
          filterLabel: 'Project Members'
        },
        [FilterListType.PhaseNames]: {
          filterLabel: 'Project Has Phase'
        },
        [FilterListType.Priorities]: {
          filterLabel: 'Project Priority'
        }
      },
      isSaveable: false,
      getValidatedFieldValue: ({ currentFilter, originalFieldValue }) => {
        if (
          currentFilter.stackedFilterMembersFilterListType ===
          defaultMemberFilterListType
        ) {
          return originalFieldValue;
        } else {
          return originalFieldValue.map((filterListType) =>
            filterListType === defaultMemberFilterListType
              ? (currentFilter.stackedFilterMembersFilterListType as FilterListType)
              : filterListType
          );
        }
      }
    }
  };
};
