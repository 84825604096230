import intersection from 'lodash/intersection';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import {
  RowValidator,
  MakeRowsValidatorParams,
  MakeOptionsGetterParams,
  CsvImportProject,
  RowOptionsGetter
} from 'CsvImportsModule/types';
import {
  columnIndexesByEntity,
  CsvImportColumn,
  CsvImportEntity,
  CsvImportFieldName
} from 'CsvImportsModule/constants';
import { Board } from 'models/board';

export const getExistingProjectsHashByNumber = (
  existingProjects: CsvImportProject[]
) => {
  return mapValues(groupBy(existingProjects, 'project_number'), (projects) =>
    keyBy(projects, 'id')
  );
};

export const getExistingProjectsHashByTitle = (
  existingProjects: CsvImportProject[]
) => {
  return mapValues(groupBy(existingProjects, 'title'), (projects) =>
    keyBy(projects, 'id')
  );
};

export const getExistingPortfoliosHashByName = (
  existingPortfolios: Board[]
) => {
  return mapValues(groupBy(existingPortfolios, 'name'), (portfolios) =>
    keyBy(portfolios, 'id')
  );
};

export const getExistingProjectIdToPortfolio = (
  existingProjects: CsvImportProject[],
  portfolioHash: Record<number, Board>
) => {
  return existingProjects.reduce((acc, project) => {
    const portfolio = portfolioHash[project.board_id];
    if (portfolio) {
      acc[project.id] = portfolio;
    }
    return acc;
  }, {} as Record<string | number, Board>);
};

// plural since there could be more than one project that has the same title + number combination
export const getProjectIdsThatMatchTitleAndNumber = ({
  existingProjectsHashByTitle,
  existingProjectsHashByNumber,
  enteredProjectTitle,
  enteredProjectNumber
}: {
  existingProjectsHashByTitle: ReturnType<
    typeof getExistingProjectsHashByTitle
  >;
  existingProjectsHashByNumber: ReturnType<
    typeof getExistingProjectsHashByNumber
  >;
  enteredProjectTitle: string;
  enteredProjectNumber: string;
}) => {
  const matchingProjectsByTitle =
    existingProjectsHashByTitle[enteredProjectTitle];
  const matchingProjectsByNumber =
    existingProjectsHashByNumber[enteredProjectNumber];
  const projectIdsThatMatchTitleAndNumber = keyBy(
    intersection(
      Object.keys(matchingProjectsByTitle || {}),
      Object.keys(matchingProjectsByNumber || {})
    ),
    (id) => id
  ); // there could be more than one project that has the same title + number combination
  return projectIdsThatMatchTitleAndNumber;
};

export const getRowValuesForEntityType = (
  row: string[],
  entityType: CsvImportEntity
): Record<CsvImportFieldName, string> => {
  const columnIndexes = columnIndexesByEntity[entityType];
  const getColumnValue = (column: CsvImportColumn) =>
    row[columnIndexes[column]]?.trim() || '';

  return {
    [CsvImportFieldName.EnteredPortfolioName]: getColumnValue(
      CsvImportColumn.PortfolioName
    ),
    [CsvImportFieldName.EnteredProjectTitle]: getColumnValue(
      CsvImportColumn.ProjectTitle
    ),
    [CsvImportFieldName.EnteredProjectNumber]: getColumnValue(
      CsvImportColumn.ProjectNumber
    ),
    [CsvImportFieldName.EnteredPhaseName]: getColumnValue(
      CsvImportColumn.PhaseName
    ),
    [CsvImportFieldName.EnteredPhaseNumber]: getColumnValue(
      CsvImportColumn.PhaseNumber
    ),
    [CsvImportFieldName.EnteredActivityTitle]: getColumnValue(
      CsvImportColumn.ActivityTitle
    ),
    [CsvImportFieldName.EnteredMemberName]: getColumnValue(
      CsvImportColumn.MemberName
    ),
    [CsvImportFieldName.EnteredEmail]: getColumnValue(CsvImportColumn.Email),
    [CsvImportFieldName.EnteredUnassignedRoleName]: getColumnValue(
      CsvImportColumn.UnassignedRoleName
    ),
    [CsvImportFieldName.EnteredDate]: getColumnValue(CsvImportColumn.Date),
    [CsvImportFieldName.EnteredHours]: getColumnValue(CsvImportColumn.Hours),
    [CsvImportFieldName.EnteredStartDate]: getColumnValue(
      CsvImportColumn.StartDate
    ),
    [CsvImportFieldName.EnteredEndDate]: getColumnValue(
      CsvImportColumn.EndDate
    ),
    [CsvImportFieldName.EnteredTotalHours]: getColumnValue(
      CsvImportColumn.TotalHours
    ),
    [CsvImportFieldName.EnteredHoursPerDay]: getColumnValue(
      CsvImportColumn.HoursPerDay
    ),
    [CsvImportFieldName.EnteredDescription]: getColumnValue(
      CsvImportColumn.Description
    ),
    [CsvImportFieldName.EnteredIncludeOffDays]: getColumnValue(
      CsvImportColumn.IncludeOffDays
    ),
    [CsvImportFieldName.EnteredAccessLevel]: getColumnValue(
      CsvImportColumn.AccessLevel
    ),
    [CsvImportFieldName.EnteredFirstName]: getColumnValue(
      CsvImportColumn.FirstName
    ),
    [CsvImportFieldName.EnteredLastName]: getColumnValue(
      CsvImportColumn.LastName
    ),
    [CsvImportFieldName.EnteredPtoHours]: getColumnValue(
      CsvImportColumn.PtoHours
    ),
    [CsvImportFieldName.EnteredPtoDescription]: getColumnValue(
      CsvImportColumn.PtoDescription
    ),
    [CsvImportFieldName.EnteredCurrency]: getColumnValue(
      CsvImportColumn.Currency
    ),
    [CsvImportFieldName.EnteredDepartment]: getColumnValue(
      CsvImportColumn.Department
    ),
    [CsvImportFieldName.EnteredSkills]: getColumnValue(CsvImportColumn.Skills),
    [CsvImportFieldName.EnteredRateAmount]: getColumnValue(
      CsvImportColumn.RateAmount
    ),
    [CsvImportFieldName.EnteredRateDescription]: getColumnValue(
      CsvImportColumn.RateDescription
    ),
    [CsvImportFieldName.EnteredProjectPriority]: getColumnValue(
      CsvImportColumn.ProjectPriority
    ),
    [CsvImportFieldName.EnteredProjectBudgetStatus]: getColumnValue(
      CsvImportColumn.ProjectBudgetStatus
    ),
    [CsvImportFieldName.EnteredProjectClient]: getColumnValue(
      CsvImportColumn.ProjectClient
    ),
    [CsvImportFieldName.EnteredRole]: getColumnValue(CsvImportColumn.Role),
    [CsvImportFieldName.EnteredRegion]: getColumnValue(CsvImportColumn.Region),
    [CsvImportFieldName.EnteredOfficeName]: getColumnValue(
      CsvImportColumn.Office
    ),
    [CsvImportFieldName.EnteredDiscipline]: getColumnValue(
      CsvImportColumn.Discipline
    ),
    [CsvImportFieldName.EnteredPhaseStartDate]: getColumnValue(
      CsvImportColumn.PhaseStartDate
    ),
    [CsvImportFieldName.EnteredPhaseEndDate]: getColumnValue(
      CsvImportColumn.PhaseEndDate
    ),
    [CsvImportFieldName.EnteredPhaseBudgetStatus]: getColumnValue(
      CsvImportColumn.PhaseBudgetStatus
    ),
    [CsvImportFieldName.EnteredPhaseContractType]: getColumnValue(
      CsvImportColumn.PhaseContractType
    ),
    [CsvImportFieldName.EnteredPhaseFee]: getColumnValue(
      CsvImportColumn.PhaseFee
    ),
    [CsvImportFieldName.EnteredPhaseTarget]: getColumnValue(
      CsvImportColumn.PhaseTarget
    ),
    [CsvImportFieldName.EnteredActivityStartDate]: getColumnValue(
      CsvImportColumn.ActivityStartDate
    ),
    [CsvImportFieldName.EnteredActivityEndDate]: getColumnValue(
      CsvImportColumn.ActivityEndDate
    ),
    [CsvImportFieldName.EnteredActivityFee]: getColumnValue(
      CsvImportColumn.ActivityFee
    ),
    [CsvImportFieldName.EnteredActivityTarget]: getColumnValue(
      CsvImportColumn.ActivityTarget
    ),
    [CsvImportFieldName.EnteredPhaseBillable]: getColumnValue(
      CsvImportColumn.PhaseBillable
    ),
    [CsvImportFieldName.EnteredTaskScopeDescription]: getColumnValue(
      CsvImportColumn.TaskScopeDescription
    ),
    [CsvImportFieldName.EnteredTaskPriority]: getColumnValue(
      CsvImportColumn.TaskPriority
    ),
    [CsvImportFieldName.EnteredTaskStatus]: getColumnValue(
      CsvImportColumn.TaskStatus
    ),
    [CsvImportFieldName.EnteredIsScope]: getColumnValue(CsvImportColumn.IsScope)
  };
};

/**
 * Wraps simple row validators that only depend on row values
 */
export const makeRowsValidator = <R extends RowValidator>({
  entityType,
  rowValidator,
  additionalParams
}: MakeRowsValidatorParams<R>) => {
  return (rows: string[][]) =>
    rows.map((row, idx) =>
      rowValidator({
        row,
        entityType,
        additionalParams,
        isLastRow: idx === rows.length - 1
      })
    );
};

/**
 * Wraps row options getter that only depend on row values
 */
export const makeOptionsGetter = <R extends RowOptionsGetter>({
  entityType,
  optionsGetter,
  additionalParams
}: MakeOptionsGetterParams<R>) => {
  return (row: string[]) =>
    optionsGetter({ row, entityType, additionalParams });
};

export const isNumeric = (enteredString: string) =>
  /^[0-9]+(?:\.[0-9]+)?$/.test(enteredString);

/**
 * Reset the ref to empty object if it is last row.
 */

export const resetTempRef = (
  ref: React.MutableRefObject<Record<string, unknown>>,
  isLastRow: boolean | undefined
) => {
  if (isLastRow) {
    ref.current = {};
  }
};

/**
 *
 * @param enteredString - the string entered by the user in boolean field
 * @returns - the boolean value of the string, since Osmos accepts 'true' and 'false' and ignores case, this
 * function will return true if the string is 'true' in any case, and false otherwise.
 */

export const enteredFieldIsTrue = (enteredString: string) => {
  const loweredCaseInput = enteredString.toLowerCase();
  if (loweredCaseInput === 'true') return true;
  else return false;
};

/**
 *
 * @param enteredString - the string entered by the user in boolean field
 * @returns - true if enteredString is 'true' or 'false' ignoring case, false otherwise
 */
export const isValidBooleanString = (enteredString: string) => {
  const loweredCaseInput = enteredString.toLowerCase();
  if (loweredCaseInput === 'true' || loweredCaseInput === 'false') return true;
  else return false;
};
