import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import moment from 'moment';
import ProjectsThenPhasesDropdown from 'components/ProjectsThenPhasesDropdown/ProjectsThenPhasesDropdown';
import ThreeLinesIcon from 'icons/ThreeLinesIcon';

import {
  makeGetProjectBasicInfoById,
  getMe,
  getFlatPhasesHash
} from 'selectors';
import { makeGetPlannerProjectPhase } from 'BudgetModule/selectors';
import QBDownArrow from 'icons/QBDownArrow';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ColorPicker from 'components/ColorPicker';
import {
  SelectContainer,
  StyledDownArrow,
  StyledProjectTitle,
  StyledProjectInfo,
  StyledProjectDescription,
  StyledPhaseNameContainer,
  StyledPhaseName,
  StyledRowContainer,
  ModalProjectContainer,
  StyledColorPicker,
  StyledDot,
  StyledPhaseMilestoneIcon,
  StyledBudgetPhaseMilestoneIcon,
  ActivityContainer,
  StyledDate,
  ThreeLinesIconContainer
} from './styles';
import WorkloadActivity from './WorkloadActivity';
import { DATE_DISPLAY_TINY } from 'appConstants/date';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const PhaseActivityDivider = styled.div`
  margin-left: 6px;
  height: 40px;
  width: 1px;
  background: #828282;
`;

class ProjectsThenPhasesSelector extends React.Component {
  state = {
    isOpen: false,
    projectHovered: false,
    selectedProjectId: null,
    skipToPhaseStep: null
  };

  colorPickerRef = React.createRef();
  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate() {
    rebuildTooltip();
  }

  openDropdown = () => {
    const { onOpen } = this.props;
    this.setState({ isOpen: true });
    if (onOpen) {
      onOpen();
    }
  };

  openDropdownToProjects = () => {
    this.setState({ skipToPhaseStep: false });
    this.openDropdown();
  };

  openDropdownToPhases = (e, projectId) => {
    const { phase } = this.props;
    this.setState({
      selectedProjectId: projectId || phase.project_id,
      skipToPhaseStep: true
    });
    this.openDropdown();
  };

  closeDropdown = () => {
    const { onClose } = this.props;
    this.setState({ isOpen: false, selectedProjectId: null });
    if (onClose) {
      onClose();
    }
  };

  setTargetRef = (ref) => {
    const { dropdownRef } = this.props;
    this.target = ref;
    if (dropdownRef) {
      dropdownRef(ref);
    }
  };

  selectActivity = (item) => {
    this.props.handleSelect({
      projectId: item.project_id,
      phaseId: item.phase_id,
      activityId: item.id,
      activityPhaseId: item.activityPhase?.id,
      taskGroupsCount: item.project?.task_groups_count,
      defaultTaskGroupId: item.project?.task_group_order?.[0],
      item
    });
  };

  selectPhase = (item) => {
    const activityPhase = !item.activity_order?.length
      ? item.activity_phases?.find((activityPhase) => activityPhase.is_default)
      : null;

    this.props.handleSelect({
      projectId: item.project_id,
      phaseId: item.id,
      activityId: activityPhase?.activity_id,
      activityPhaseId: activityPhase?.id,
      taskGroupsCount: item.project?.task_groups_count,
      defaultTaskGroupId: item.project?.task_group_order?.[0],
      item
    });
  };

  selectProject = (item) => {
    const defaultOrMainPhase = this.props.phaseHash[item.defaultOrMainPhaseId];
    const hasActivity = !!defaultOrMainPhase?.activity_order?.length;
    const { shouldSelectProjectOnly } = this.props;

    if (shouldSelectProjectOnly) {
      this.props.handleSelect({
        projectId: item.id
      });
      this.closeDropdown();
      return;
    }

    if (defaultOrMainPhase && !hasActivity) {
      const defaultActivityPhase = defaultOrMainPhase.activity_phases.find(
        (ap) => ap.is_default
      ); // default phase should have a default activity phase
      this.props.handleSelect({
        projectId: item.id,
        phaseId: item.defaultOrMainPhaseId,
        activityId: defaultActivityPhase?.activity_id,
        activityPhaseId: defaultActivityPhase?.id,
        taskGroupsCount: item.project?.task_groups_count,
        defaultTaskGroupId: item.project?.task_group_order?.[0],
        item
      });
    } else {
      this.setState({ selectedProjectId: item.id });
    }
  };

  handleSelectItem = (item) => {
    if (item.is_activity) {
      this.selectActivity(item);
    } else if (item.is_phase) {
      this.selectPhase(item);
    } else {
      this.selectProject(item);
    }
  };

  toggleHover = () =>
    this.setState({ projectHovered: !this.state.projectHovered });

  renderPhase = () => {
    const {
      phase,
      renderPhaseDates,
      isInvalid,
      hideActivity = false,
      // this is temporary until we find out why selectionDisabed is not disabling phase selection
      phaseAndActivitySelectionDisabled = false,
      activityId
    } = this.props;
    const showActivities = !!phase?.activity_order?.length && !hideActivity;
    const activityPhase = phase?.activity_phases.find(
      (ap) => ap.activity_id === activityId
    );

    const showPhaseInfo = phase && !phase?.is_like_default;
    return (
      <>
        <StyledPhaseNameContainer
          data-for="app-tooltip"
          data-class="project-info-tooltips"
          data-tip={phase && !phase.is_like_default ? phase.name : ''}
          ref={this.setTargetRef}
          style={{ alignItems: 'flex-start', paddingTop: '6px' }}
          onClick={
            phaseAndActivitySelectionDisabled ? noop : this.openDropdownToPhases
          }
        >
          <div>
            {showPhaseInfo && (
              <>
                <div style={{ display: 'flex' }}>
                  {phase.is_budget ? (
                    <StyledBudgetPhaseMilestoneIcon height="12" width="12" />
                  ) : (
                    <StyledPhaseMilestoneIcon height="12" width="12" />
                  )}
                  <StyledPhaseName
                    className={`phase-name ${
                      showActivities && 'show-activities'
                    }`}
                  >
                    {phase.name}
                  </StyledPhaseName>
                </div>
                {phase.start_date && renderPhaseDates && (
                  <StyledDate style={{ marginLeft: 36, textAlign: 'left' }}>
                    {moment(phase.start_date).format(DATE_DISPLAY_TINY)}{' '}
                    {phase.end_date &&
                      `- ${moment(phase.end_date).format(DATE_DISPLAY_TINY)}`}
                  </StyledDate>
                )}
              </>
            )}
          </div>
          {showPhaseInfo && showActivities && <PhaseActivityDivider />}
          {showActivities && (
            <ActivityContainer>
              <WorkloadActivity
                activityId={activityId}
                activityPhase={activityPhase}
                handleClick={this.openActivitySelector}
                isInvalid={isInvalid}
              />
            </ActivityContainer>
          )}
        </StyledPhaseNameContainer>
      </>
    );
  };

  render() {
    const { isOpen, selectedProjectId, skipToPhaseStep } = this.state;
    const {
      accountId,
      project,
      isInvalid,
      nameStyle,
      me,
      projectStepEnabled = true,
      renderSelector = true,
      target, // required if !renderSelector,
      showBudgetInformation = true,
      hidePTO = false,
      includePersonalProjects,
      isWide,
      suggestedProjectHash,
      suggestedPhaseHash,
      suggestedActivityHash,
      preventCloseOnSelect,
      popoverClassName,
      selectionDisabled = false,
      projectSelectionDisabled = false,
      disabledTooltip,
      selectLabel,
      renderSelect,
      pickerLocation,
      showActivities,
      showSelectIcon = false,
      getItemHeight,
      onClickOverride
    } = this.props;

    return (
      <>
        {renderSelector && (
          <div
            ref={this.setTargetRef}
            className="project-selector-container"
            style={{ padding: '5px 0px' }}
          >
            {project ? (
              <>
                {projectStepEnabled && (
                  <StyledProjectInfo
                    data-for="app-tooltip"
                    data-html="true"
                    data-class="project-info-tooltips"
                    data-tip={`${project.title} <br /> ${
                      project.description ? `${project.description}` : ''
                    }`}
                    onMouseEnter={this.toggleHover}
                    onMouseLeave={this.toggleHover}
                    isWide={isWide}
                    onClick={
                      onClickOverride ||
                      (selectionDisabled || projectSelectionDisabled
                        ? null
                        : this.openDropdownToProjects)
                    }
                  >
                    {showSelectIcon && (
                      <ThreeLinesIconContainer className="three-line-icon-container">
                        <ThreeLinesIcon />
                      </ThreeLinesIconContainer>
                    )}
                    <StyledRowContainer
                      isDisabled={selectionDisabled || projectSelectionDisabled}
                      data-for="app-tooltip"
                      data-tip={
                        selectionDisabled || projectSelectionDisabled
                          ? disabledTooltip
                          : ''
                      }
                      data-html
                      data-effect="solid"
                      data-class="center"
                    >
                      <StyledColorPicker
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <StyledDot id={project.id} />
                        <ColorPicker
                          projectId={project.id}
                          id={project.id}
                          originType={ORIGIN_TYPE_STRINGS.PROJECT}
                          row
                          ref={this.colorPickerRef}
                          className="color-picker-container"
                          pickerLocation={pickerLocation} // Must be unique to avoid multiple pickers opening
                          alwaysShow={this.state.projectHovered}
                        />
                      </StyledColorPicker>
                      <ModalProjectContainer className="project-container">
                        <StyledProjectTitle
                          nameStyle={nameStyle}
                          data-multiline={true}
                          className="project-title"
                        >
                          {project.title}
                        </StyledProjectTitle>
                        <StyledProjectDescription className="project-description">
                          {project.description &&
                            project.description.length &&
                            ' - '}
                          {project.description}
                        </StyledProjectDescription>
                      </ModalProjectContainer>
                      {!selectionDisabled && !projectSelectionDisabled && (
                        <StyledDownArrow className="down-arrow">
                          <QBDownArrow />
                        </StyledDownArrow>
                      )}
                    </StyledRowContainer>
                  </StyledProjectInfo>
                )}
                {this.props.renderPhaseSelector
                  ? this.props.renderPhaseSelector({
                      openDropdownToPhases: this.openDropdownToPhases
                    })
                  : this.renderPhase()}
              </>
            ) : renderSelect ? (
              renderSelect(this.openDropdownToProjects)
            ) : (
              <SelectContainer
                onClick={onClickOverride || this.openDropdownToProjects}
                isInvalid={isInvalid}
                data-testid="project-selector"
                className="project-selector"
                style={{ fontSize: '18px' }}
              >
                {showSelectIcon && (
                  <ThreeLinesIconContainer className="three-line-icon-container">
                    <ThreeLinesIcon />
                  </ThreeLinesIconContainer>
                )}
                {selectLabel || `Select Project`}
                <StyledDownArrow className="down-arrow">
                  <QBDownArrow />
                </StyledDownArrow>
              </SelectContainer>
            )}
          </div>
        )}
        {(isOpen || this.props.isOpen) && (
          <ProjectsThenPhasesDropdown
            target={target || this.target}
            handleSelect={this.handleSelectItem}
            handleClose={this.closeDropdown}
            renderHeaderCopy={'Select Work Category'}
            showActivities={showActivities ?? true}
            accountId={accountId}
            text={'add a Work Plan'}
            currentUserId={me.id}
            viewType="workload"
            projectId={selectedProjectId}
            clearSelectedProjectId={() =>
              this.setState({ selectedProjectId: null })
            }
            showBudgetInformation={showBudgetInformation}
            projectStepEnabled={projectStepEnabled && !skipToPhaseStep}
            hidePTO={hidePTO}
            includePersonalProjects={includePersonalProjects}
            isWide={isWide}
            shouldPinSuggestions={isWide}
            suggestedProjectHash={suggestedProjectHash}
            suggestedPhaseHash={suggestedPhaseHash}
            suggestedActivityHash={suggestedActivityHash}
            preventCloseOnSelect={preventCloseOnSelect}
            popoverClassName={popoverClassName}
            customGetItemHeight={getItemHeight}
          />
        )}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getProject = makeGetProjectBasicInfoById();
  const getPhase = makeGetPlannerProjectPhase();
  const mapStateToProps = (state, ownProps) => ({
    project: getProject(state, ownProps),
    phase: getPhase(state, ownProps),
    me: getMe(state),
    phaseHash: getFlatPhasesHash(state)
  });
  return mapStateToProps;
};
export default connect(makeMapStateToProps)(ProjectsThenPhasesSelector);
