import React from 'react';
import SvgIcon from 'components/SvgIcon';

const Delete24IconUnmasked = ({ className }) => (
  <SvgIcon
    className={className}
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66407 17.6213V9.32037H7.33204C7.14875 9.32037 7 9.17162 7 8.98833V7.66019C7 7.4769 7.14875 7.32815 7.33204 7.32815H10.3204V6.33204C10.3204 6.14875 10.4691 6 10.6524 6H13.3087C13.492 6 13.6407 6.14875 13.6407 6.33204V7.32815H16.6291C16.8124 7.32815 16.9611 7.4769 16.9611 7.66019V8.98833C16.9611 9.17162 16.8124 9.32037 16.6291 9.32037H16.297V17.6213C16.297 17.8046 16.1483 17.9533 15.965 17.9533H7.99611C7.81283 17.9533 7.66407 17.8046 7.66407 17.6213ZM8.44324 17.1622H15.5179V9.4459H8.44324V17.1622ZM12.9767 6.66407H10.9844V7.32815H12.9767V6.66407ZM16.296 7.98314H7.66407V8.67064H16.296V7.98314ZM10.1353 10.8507V15.6632C10.1353 15.8108 9.94184 15.9306 9.7035 15.9306C9.46517 15.9306 9.27174 15.8108 9.27174 15.6632V10.8507C9.27174 10.7031 9.46517 10.5833 9.7035 10.5833C9.94184 10.5833 10.1353 10.7031 10.1353 10.8507ZM14.2536 15.6632V10.8507C14.2536 10.7031 14.0602 10.5833 13.8219 10.5833C13.5835 10.5833 13.3901 10.7031 13.3901 10.8507V15.6632C13.3901 15.8108 13.5835 15.9306 13.8219 15.9306C14.0602 15.9306 14.2536 15.8108 14.2536 15.6632ZM12.128 10.8507V15.6632C12.128 15.8108 11.9346 15.9306 11.6963 15.9306C11.4579 15.9306 11.2645 15.8108 11.2645 15.6632V10.8507C11.2645 10.7031 11.4579 10.5833 11.6963 10.5833C11.9346 10.5833 12.128 10.7031 12.128 10.8507Z"
      fill="#4F4F4F"
    />
  </SvgIcon>
);

export default Delete24IconUnmasked;
