import { useMemo } from 'react';
import { CurrentFilter } from 'FilterModule/types';
import {
  FilterField,
  filterListTypeToField,
  FilterListType
} from 'FilterModule/constants';
import { isFilterListType } from 'FilterModule/utils';
import sumBy from 'lodash/sumBy';
import { MergeExclusive } from 'type-fest';

type UseFilterListTypeParams<C> = {
  currentFilter: C;
  fieldOverride?: string;
} & MergeExclusive<
  { keyOfFilterListType: string },
  { filterListType: FilterListType }
>;

export const useFilterListType = <C extends CurrentFilter>({
  currentFilter,
  keyOfFilterListType,
  filterListType,
  fieldOverride
}: UseFilterListTypeParams<C>) => {
  const _filterListType =
    filterListType ||
    (keyOfFilterListType
      ? getFilterListType(currentFilter, keyOfFilterListType)
      : undefined);

  const filterListField =
    fieldOverride ||
    (_filterListType
      ? (
          filterListTypeToField as Partial<
            Record<FilterListType, FilterField | FilterField[]>
          >
        )[_filterListType]
      : undefined);

  const filterListFieldsToValue = useMemo(
    () => getFilterListFieldsToValues(filterListField, currentFilter),
    [currentFilter, filterListField]
  );

  const numFilteredItems = useMemo(
    () =>
      sumBy(Object.values(filterListFieldsToValue), (value) => value?.length),
    [filterListFieldsToValue]
  );

  return {
    filterListType: _filterListType,
    filterListField,
    filterListFieldsToValue,
    numFilteredItems
  };
};

/* ------------------------------------ - ----------------------------------- */

/**
 * Given currentFilter, will return currentFilter[keyOfFilterListType] if it is a FilterListType
 * eg. currentFilter.mainFilterListType = FilterListType.SomeFilterListType
 */
const getFilterListType = <C extends CurrentFilter>(
  currentFilter: C,
  keyOfFilterListType: string
) => {
  const value = currentFilter[keyOfFilterListType];
  return isFilterListType(value) ? value : undefined;
};

/**
 * Returns the values of the main filter list field(s) in currentFilter.
 * It's a hash since there can be multiple fields associated with a FilterListType
 */
const getFilterListFieldsToValues = <
  F extends string | string[] | undefined,
  C extends CurrentFilter
>(
  filterField: F,
  currentFilter: C
) => {
  const values: Partial<Record<FilterField, unknown[] | undefined>> = {};

  (Array.isArray(filterField) ? filterField : [filterField]).forEach(
    (field) => {
      const value = currentFilter[field];
      // only handle array values
      values[field] = Array.isArray(value) ? value : undefined;
    }
  );
  return values;
};
