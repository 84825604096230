import { Component } from 'react';
import ReactDOM from 'react-dom';
import { portal } from 'appUtils/portal';
import {
  StyledTaskColumnDraggable,
  StyledDragGripIcon,
  LeftDropdownItemContent,
  RightDropdownItemContent,
  StyledHelpIcon,
  HelpIconContainer
} from './styles';

const COLUMN_LABELS = {
  due_at: 'Due Date',
  schedule_start: 'Schedule Date',
  estimated_hours: 'Time Estimate',
  status: 'Status',
  priority: 'Priority',
  phase_id: 'Phase',
  completed_at: 'Completed',
  task_group_id: 'Task List'
};

class TaskColumnDraggable extends Component {
  handleClick = () => {
    const { handleClick, index } = this.props;
    handleClick(index);
  };

  render() {
    const { index, column, provided, isActive, snapshot = {} } = this.props;
    const usePortal = snapshot.isDragging;
    const text = COLUMN_LABELS[column] ?? '';
    const child = (
      <StyledTaskColumnDraggable
        index={index}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        isActive={isActive}
        onClick={this.handleClick}
      >
        <LeftDropdownItemContent>
          <StyledDragGripIcon />
          <div className="task-column-text">{text}</div>
          {text === 'Schedule Date' ? (
            <HelpIconContainer
              data-for="app-tooltip"
              data-html
              data-effect="solid"
              data-tip="Schedule dates will show </br> on the Project Schedule."
            >
              <StyledHelpIcon />
            </HelpIconContainer>
          ) : null}
        </LeftDropdownItemContent>
        <RightDropdownItemContent isActive={isActive}>
          {isActive ? <div>Hide</div> : <div>Show</div>}
        </RightDropdownItemContent>
      </StyledTaskColumnDraggable>
    );
    return usePortal ? ReactDOM.createPortal(child, portal) : child;
  }
}

export default TaskColumnDraggable;
