import React from 'react';
import SvgIcon from 'components/SvgIcon';
import theme from 'theme';

interface ThreeDotHorizontalProps {
  active?: boolean;
  className?: string;
  size?: number;
  color?: string;
  activeColor?: string;
  ref?: React.Ref<SVGSVGElement>;
  strokeWidth?: number;
  style?: Record<string, unknown>;
  noPadding?: boolean;
}

const ThreeDotHorizontal = ({
  active,
  className,
  size = 26,
  color = theme.colors.colorLightGray11,
  activeColor = theme.colors.colorRoyalBlue,
  ref,
  strokeWidth,
  style,
  noPadding
}: ThreeDotHorizontalProps) => {
  const { d, height, viewBox } = noPadding
    ? {
        d: 'M 0 1.75 C 0 0.7835 0.7835 0 1.7497 0 C 2.716 0 3.4994 0.7835 3.4994 1.75 C 3.4994 2.7165 2.716 3.5 1.7497 3.5 C 0.7835 3.5 0 2.7165 0 1.75 Z M 6.7503 1.75 C 6.7503 0.7835 7.5337 0 8.5 0 C 9.4663 0 10.2497 0.7835 10.2497 1.75 C 10.2497 2.7165 9.4663 3.5 8.5 3.5 C 7.5337 3.5 6.7503 2.7165 6.7503 1.75 Z M 13.5006 1.75 C 13.5006 0.7835 14.284 0 15.2503 0 C 16.2165 0 17 0.7835 17 1.75 C 17 2.7165 16.2165 3.5 15.2503 3.5 C 14.284 3.5 13.5006 2.7165 13.5006 1.75 Z',
        height: (3.5 / 17) * size,
        viewBox: '0 0 17 3.5'
      }
    : {
        d: 'M 4.5 13 C 4.5 12.0335 5.2835 11.25 6.2497 11.25 C 7.216 11.25 7.9994 12.0335 7.9994 13 C 7.9994 13.9665 7.216 14.75 6.2497 14.75 C 5.2835 14.75 4.5 13.9665 4.5 13 Z M 11.2503 13 C 11.2503 12.0335 12.0337 11.25 13 11.25 C 13.9663 11.25 14.7497 12.0335 14.7497 13 C 14.7497 13.9665 13.9663 14.75 13 14.75 C 12.0337 14.75 11.2503 13.9665 11.2503 13 Z M 18.0006 13 C 18.0006 12.0335 18.784 11.25 19.7503 11.25 C 20.7165 11.25 21.5 12.0335 21.5 13 C 21.5 13.9665 20.7165 14.75 19.7503 14.75 C 18.784 14.75 18.0006 13.9665 18.0006 13 Z',
        height: size,
        viewBox: '0 0 26 26'
      };

  return (
    <SvgIcon
      width={size}
      height={height}
      viewBox={viewBox}
      fill="none"
      className={className}
      ref={ref}
      strokeWidth={strokeWidth}
      style={style}
    >
      <path
        d={d}
        stroke={active ? activeColor : color}
        fill={active ? activeColor : color}
      />
    </SvgIcon>
  );
};

export default ThreeDotHorizontal;
