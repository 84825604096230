import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import theme from 'theme';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import { DateContainer } from './styles';
import HelpIcon from 'icons/HelpIcon';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

interface WorkdayDateSelectorProps {
  handleChange: (date: Moment) => void;
}

const WorkdayDateSelector = ({ handleChange }: WorkdayDateSelectorProps) => {
  const [cloneDate, setCloneDate] = useState(moment());
  useEffect(() => {
    rebuildTooltip();
  }, []);
  return (
    <StyledContainer>
      <DateRangeCalendar
        isSingleDay
        // @ts-expect-error date range calender not typed
        onSubmit={({ startDate }) => {
          setCloneDate(startDate);
          handleChange(startDate);
        }}
        itemStartDate={cloneDate}
        customInput={(startDate, endDate, handleOpen) => (
          <DateContainer onClick={handleOpen}>
            Start Date {moment(cloneDate).format('MM/DD/YYYY')}
          </DateContainer>
        )}
      />
      <TooltipContainer
        data-for="app-tooltip"
        data-tip="The schedule will be copied using the <br/> 
        same Phase workdays and day between <br/> phases from the cloned schedule <br/> 
        using the earliest Phase Start Date."
        data-effect="solid"
        data-html
        data-class="center"
      >
        <HelpIcon
          width={8}
          height={8}
          fillColor={theme.colors.colorPaleGray10}
          questionMarkColor={theme.colors.colorPureBlack}
        />
      </TooltipContainer>
    </StyledContainer>
  );
};

export default WorkdayDateSelector;

const TooltipContainer = styled.span`
  position: relative;
  top: -8px;
`;

const StyledContainer = styled.div`
  display: flex;
`;
