import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import bindAll from 'lodash/bindAll';
import cn from 'classnames';
import FaAngleDown from 'react-icons/lib/fa/angle-down';
import {
  ProjectsByPriority,
  ToggleViewDropdown,
  SelectBoardDropdownContainer
} from '..';
import { Dropdown, DropdownToggle } from 'reactstrap';
import ConfigureModulesModal from 'components/Modals/ConfigureModulesModal';
import {
  openAddProjectModal,
  openEditProjectModal,
  saveSearchState,
  useSearchState,
  setBoardSortProperty,
  unselectAllProjects
} from 'actionCreators';
import AddProjectButton from './AddProjectButton';
import {
  getSearch,
  getMe,
  getTotalProjects,
  getIsActive,
  getSelectedBoard,
  getProjectsState,
  getMatchedRouteParams,
  getInstalledBoardModuleIds,
  getBoardColumns,
  getBoardSortProperty,
  getSelectedProjectIds,
  getGroups
} from 'selectors';
import { isStarredOrUnstarredBoard } from 'appUtils/boards';
import MoveIcon from 'icons/MoveIcon';
import CopyBatchIcon from 'icons/CopyBatchIcon';

class ProjectsByPriorityContainer extends React.Component {
  constructor(props) {
    super(props);
    bindAll(this, ['toggleProjectMenuDropdown', 'changeDropdownTitle']);
    this.state = {
      projectMenuDropdownOpen: false,
      isArchived: !props.isActive,
      transitioningViewType: false
    };
  }

  toggleProjectMenuDropdown() {
    this.setState((prevState) => ({
      projectMenuDropdownOpen: !prevState.projectMenuDropdownOpen
    }));
  }

  changeDropdownTitle() {
    this.setState({ isArchived: !this.state.isArchived });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isActive, path, useSearchState, saveSearchState, search } =
      this.props;
    if (isActive !== nextProps.isActive) {
      this.setState({ isArchived: !this.state.isArchived });
    }

    const navToStatus =
      path !== nextProps.path &&
      !path.includes('status') &&
      nextProps.path.includes('status');

    const navToBudget =
      path !== nextProps.path &&
      !path.includes('budget') &&
      nextProps.path.includes('budget');

    if (navToStatus || navToBudget) {
      useSearchState({ home: false, board: true });
      saveSearchState({ home: '', board: search.searchText });
    }
  }

  handleAddProjectClick(priorityId) {
    const { statusName, openAddProjectModal } = this.props;
    if (statusName !== 'Archived') openAddProjectModal({ priorityId });
  }

  renderAddProjectButton = () => {
    const { statusId, statusName } = this.props;
    return (
      <div
        className={cn('add-project', { 'add-project-hidden': false })}
        onClick={() => this.handleAddProjectClick(statusId)}
      >
        {statusName !== 'Archived' ? <AddProjectButton /> : null}
      </div>
    );
  };

  renderViewMenu = () => {
    const isStatusMenu = true;
    return (
      <Dropdown
        toggle={this.toggleProjectMenuDropdown}
        isOpen={this.state.projectMenuDropdownOpen}
      >
        <DropdownToggle
          className={cn('view-menu', {
            active: this.state.projectMenuDropdownOpen,
            'is-blue': this.state.isArchived
          })}
        >
          {!this.state.isArchived ? 'Active' : 'Archived'}
          <FaAngleDown
            className={cn('down-arrow', {
              active: this.state.isArchived
            })}
          />
        </DropdownToggle>
        <ToggleViewDropdown
          isStatusMenu={!isStatusMenu}
          isOpen={this.state.projectMenuDropdownOpen}
          toggle={this.toggleProjectMenuDropdown}
          isArchived={this.state.isArchived}
          changeDropdownTitle={this.changeDropdownTitle}
        />
      </Dropdown>
    );
  };

  renderProjectsByPriority = () => {
    const {
      projectListGrouped,
      openEditProjectModal,
      statusId,
      me,
      search,
      showPriorityOptionsForId,
      setPriorityOptionsId,
      isFetchingProjects,
      isFiltering,
      deleteProject,
      totalAboveProjects,
      renderWaypoint,
      columnOrder,
      setScrollableListRef,
      boardSortProperty,
      setBoardSortProperty,
      shouldRenderSkeletons,
      shouldShowSpinner,
      selectedBoard
    } = this.props;
    return (
      <ProjectsByPriority
        isFetchingProjects={isFetchingProjects}
        statusId={statusId}
        projectListGrouped={projectListGrouped}
        openEditProjectModal={openEditProjectModal}
        me={me}
        search={search}
        showPriorityOptionsForId={showPriorityOptionsForId}
        setPriorityOptionsId={setPriorityOptionsId}
        isFiltering={isFiltering}
        deleteProject={deleteProject}
        totalAboveProjects={totalAboveProjects}
        renderWaypoint={renderWaypoint}
        renderHeader={this.renderHeader}
        columnOrder={columnOrder}
        setScrollableListRef={setScrollableListRef}
        boardSortProperty={boardSortProperty}
        setBoardSortProperty={setBoardSortProperty}
        shouldRenderSkeletons={shouldRenderSkeletons}
        shouldShowSpinner={shouldShowSpinner}
        shouldShowBatchOptions={!isStarredOrUnstarredBoard(selectedBoard)}
      />
    );
  };

  renderHeader = () => {
    return (
      <div className={cn('project-list-header')}>
        <a className={'project-list-group-status'}>
          <div className={`header-${this.props.matchedParams.viewType}`}>
            {this.renderAddProjectButton()}
            <div
              className={cn('project-dropdown-title', {
                active: this.state.isArchived
              })}
            >
              {!isStarredOrUnstarredBoard(this.props.selectedBoard) &&
                this.renderViewMenu()}
            </div>
          </div>
        </a>
      </div>
    );
  };

  render() {
    const { statusName } = this.props;
    return (
      <div>
        <div
          className={`project-list-group ${
            statusName && statusName.toLowerCase()
          }`}
        >
          {this.renderProjectsByPriority()}

          <ConfigureModulesModal moduleType="boardView" />
        </div>
      </div>
    );
  }
}

ProjectsByPriorityContainer.propTypes = {
  statusId: PropTypes.number.isRequired,
  statusName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projectListGrouped: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object,
  openAddProjectModal: PropTypes.func.isRequired,
  openEditProjectModal: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const path = ownProps.location.pathname;
  return {
    path,
    me: getMe(state),
    search: getSearch(state),
    totalProjects: getTotalProjects(state),
    isActive: getIsActive(state),
    selectedBoard: getSelectedBoard(state),
    projects: getProjectsState(state),
    installedModuleIds: getInstalledBoardModuleIds(state, ownProps),
    matchedParams: getMatchedRouteParams(state),
    columnOrder: getBoardColumns(state),
    boardSortProperty: getBoardSortProperty(state),
    selectedProjectIds: getSelectedProjectIds(state),
    boards: getGroups(state)
  };
};

const mapDispatchToProps = {
  openAddProjectModal,
  openEditProjectModal,
  saveSearchState,
  useSearchState,
  setBoardSortProperty,
  unselectAllProjects
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectsByPriorityContainer)
);
