import moment from 'moment';
import intersection from 'lodash/intersection';
import cloneDeep from 'lodash/cloneDeep';
import {
  TASK_ACTIONS,
  PROJECT_ACTIONS,
  NOTE_ACTIONS,
  COMMENT_ACTIONS,
  EMAIL_ACTIONS,
  INTEGRATION_ACTIONS
} from 'appConstants/actionDescription';
import * as Sentry from '@sentry/browser';
import { DEPENDENCY_STRINGS } from 'appConstants/workload';
import { AnyAction } from 'redux';
import isPlainObject from 'lodash/isPlainObject';

export const action = (type, payload = {}) => ({ type, ...payload });

/**
 * Legacy createAction. Use rtk createAction for new actions
 */
export const createAction = (type: string, payload) => {
  const action: AnyAction = {
    type
  };

  if (isPlainObject(payload)) {
    const { meta, ...actionPayload } = payload;
    if (meta) {
      action.meta = meta;
    }
    action.payload = actionPayload;
  } else if (payload) {
    action.payload = payload;
  }
  return action;
};

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const TRIGGER = 'TRIGGER';
const ABORT = 'ABORT';

export const REQUEST_TYPES = {
  REQUEST,
  SUCCESS,
  FAILURE,
  TRIGGER,
  ABORT
};

const OPTS_DEFAULT = {
  createTrigger: true
};

export interface RequestTypes {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
  TRIGGER: string;
  ABORT: string;
}

export const createRequestTypes = (
  base: string,
  { createTrigger }: { createTrigger?: boolean } = OPTS_DEFAULT
): RequestTypes => {
  const actions = [REQUEST, SUCCESS, FAILURE, ABORT];
  if (createTrigger) {
    actions.push(TRIGGER);
  }
  return actions.reduce(
    (acc, type) => ({ ...acc, [type]: `${base}_${type}` }),
    {}
  ) as RequestTypes;
};

export const createResponseEntityHandler = (actionRequestTypes) => ({
  request: () => action(actionRequestTypes.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(actionRequestTypes.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(actionRequestTypes.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
});

export const formatStatusDate = (date) => {
  const momentDate = moment(date);
  const yesterdayMomentDate = moment().subtract(1, 'days');
  const isToday = moment().isSame(momentDate, 'day');
  const isYesterday = yesterdayMomentDate.isSame(momentDate, 'day');
  const renderDate = isToday ? 'Today' : isYesterday ? 'Yesterday' : undefined;

  const rawDate = momentDate.utc().format('MM/DD/YY');
  return (
    renderDate ||
    rawDate
      .split('/')
      .map((item, idx) => {
        if (idx !== 2 && item[0] === '0') {
          return item[1];
        } else {
          return item;
        }
      })
      .join('/')
  );
};

export const isUserTeamAdmin = (userId, team) => {
  const teamAdminIds = team.team_members
    .filter((member) => member.is_admin)
    .map((admin) => admin.account.id);
  return teamAdminIds.includes(userId);
};

export const checkIfEditing = (e, classes, setId) => {
  if (!e) {
    setTimeout(() => {
      setId(null);
    }, 50);
  } else if (
    !intersection(e.target.classList, classes).length &&
    // if an arrow was click in date picker
    !(e.target.tagName === 'svg' || e.target.tagName === 'path')
  ) {
    setId(null);
  }
};

export const getNumFromPxString = (pxString) => +pxString.split('px')[0];

export const calculateDragScroll = (container, extraTopOffset = 0) => {
  const containerTop = container && container.offsetTop + extraTopOffset;
  const el = document.getElementsByClassName('gu-mirror')[0];
  if (el && container) {
    const mirrorOffsetTop = (el as HTMLElement).offsetTop - containerTop;
    const scrollDownBreakPoint = container.clientHeight - el.clientHeight;
    const scrollUpBreakPoint = containerTop + el.clientHeight;
    const shouldScrollDown = mirrorOffsetTop >= scrollDownBreakPoint;
    const shouldScrollUp = mirrorOffsetTop <= scrollUpBreakPoint;
    const scrollUpDist = 7;
    const scrollDownDist = 10;
    if (shouldScrollDown) {
      container.scrollTop += scrollDownDist; // scroll down 10px at a time
    } else if (shouldScrollUp) {
      container.scrollTop -= scrollUpDist; // scroll up 7px at a time
    }
  }
};

// Determining user notification type
export const isTaskAction = (actionType) => TASK_ACTIONS.includes(actionType);
export const isProjectAction = (actionType) =>
  PROJECT_ACTIONS.includes(actionType);
export const isNoteAction = (actionType) => NOTE_ACTIONS.includes(actionType);
export const isCommentAction = (actionType) =>
  COMMENT_ACTIONS.includes(actionType);

export const isEmailAction = (actionType) => EMAIL_ACTIONS.includes(actionType);
export const isIntegrationNotificationAction = (actionType) =>
  INTEGRATION_ACTIONS.includes(actionType);

export const buildMilestoneDates = (milestone) => {
  const { start_date, end_date } = milestone;
  const startDate = moment(start_date).format('MMM DD');
  const endDate = moment(end_date).format('MMM DD');

  const hasDate = startDate !== 'Invalid date';
  const isSingleDayMilestone = hasDate && startDate === endDate;

  if (isSingleDayMilestone) return startDate;
  else if (hasDate) return `${startDate} - ${endDate}`;
  else return ' ';
};

export const sortByOrderNumber = (milestones) =>
  milestones?.slice().sort((a, b) => a.order_number - b.order_number) ?? [];

export const calcMilestoneIsOverdue = (milestone, baseDate = moment()) =>
  milestone &&
  moment(milestone.end_date).diff(moment(baseDate), 'days', true) < 0 &&
  !milestone.completed_at;

export const upsertItemPosition = (targetId, position, list, newInfo) => {
  const currentIndex = list.findIndex(
    (item) => item && item.id === Number(targetId)
  );

  const splitPoint =
    currentIndex > position || currentIndex === -1 ? position : position + 1;

  const firstHalf = cloneDeep(list).slice(0, splitPoint);

  const secondHalf = cloneDeep(list).slice(splitPoint);

  const firstHalfTargetPosition = firstHalf.findIndex(
    (item) => item && item.id === Number(targetId)
  );

  const secondHalfTargetPosition = secondHalf.findIndex(
    (item) => item && item.id === Number(targetId)
  );

  if (firstHalfTargetPosition > -1) {
    firstHalf.splice(firstHalfTargetPosition, 1);
  } else if (secondHalfTargetPosition > -1) {
    secondHalf.splice(secondHalfTargetPosition, 1);
  }

  const newList = firstHalf.concat([newInfo]).concat(secondHalf);
  return newList;
};

export const getGhostText = (viewType, isCompleted) => {
  switch (viewType) {
    case 'team':
      return (
        <p>
          This is a team for grouping projects so you can track, schedule, and
          manage workload.
          <br />
          Create a team for every department, or major project.
        </p>
      );
    case 'home-projects':
      return !isCompleted ? (
        <p>
          These are your tasks for team projects including tasks you&apos;ve
          been assigned.
        </p>
      ) : (
        <p>You have no completed team project tasks.</p>
      );
    case 'home-followed':
      return !isCompleted ? (
        <p>
          These are active project tasks you are following.
          <br />
          Tasks you delegated are automatically followed.
        </p>
      ) : (
        <p>
          None of the project tasks you are currently following are completed.
        </p>
      );
    case 'home-personal':
      return !isCompleted ? (
        <p>
          These are your personal tasks which aren&apos;t team related. e.g. Fix
          Kitchen Sink.
          <br />
          Only you can see all the tasks in this section.
        </p>
      ) : (
        <p>You have no completed personal tasks.</p>
      );
    case 'profile':
      return !isCompleted ? (
        <p>This team member has no project tasks for this team.</p>
      ) : (
        <p>This team member has no completed tasks.</p>
      );
    case 'project-detail':
      return !isCompleted ? <p /> : <p>This project has no completed tasks.</p>;
    default:
      return null;
  }
};

export const makeIdHash = (idArray) =>
  idArray?.reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {}) ?? {};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const stopPropagation = (e) => e.stopPropagation();
export const preventDefault = (e) => e.preventDefault();

export const getLength = (array) => {
  if (array && (Array.isArray(array) || typeof array === 'string'))
    return array.length;
  return 0;
};

export const getTruncatedDescription = ({
  fullText = '',
  singleLineCutoff,
  lastLineCutoff,
  numLines
}) => {
  let numDisplayedLines = 1;
  if (!fullText) {
    return '';
  }
  const firstLineBreak = fullText.slice(0, singleLineCutoff).lastIndexOf(' ');
  if (firstLineBreak === -1) {
    return {
      numDisplayedLines,
      truncatedDescription: fullText,
      remainder: ''
    };
  }
  const firstLine = fullText.slice(0, firstLineBreak);
  let middleLines = '';
  let remainderIndex = firstLineBreak;
  for (let i = 1; i < numLines - 1; i++) {
    const nextLineBreak = fullText
      .slice(remainderIndex, remainderIndex + singleLineCutoff)
      .lastIndexOf(' ');
    const line = fullText.slice(remainderIndex, remainderIndex + nextLineBreak);
    middleLines += line;
    numDisplayedLines += line.length ? 1 : 0;
    remainderIndex += nextLineBreak;
  }

  const remainder = fullText.slice(remainderIndex);
  const shouldTruncate = remainder.length > lastLineCutoff;

  if (shouldTruncate) {
    const lastLineWithCutoff = remainder.slice(0, lastLineCutoff);
    // @ts-expect-error boolean being used as index
    if (lastLineWithCutoff.endsWith(' ') || remainder[lastLineCutoff === ' ']) {
      numDisplayedLines += 1;
      return {
        truncatedDescription:
          firstLine + middleLines + lastLineWithCutoff.concat('...'),
        remainder: remainder.slice(lastLineCutoff),
        numDisplayedLines
      };
    } else {
      const lastWordBreak = lastLineWithCutoff.lastIndexOf(' ');
      const truncatedDescription =
        firstLine +
        middleLines +
        lastLineWithCutoff.slice(0, lastWordBreak).concat('...');
      return {
        truncatedDescription,
        remainder: remainder.slice(lastWordBreak),
        numDisplayedLines
      };
    }
  } else {
    return {
      truncatedDescription: firstLine + middleLines + remainder,
      remainder: '',
      numDisplayedLines
    };
  }
};

export const removeHtmlTags = (string) => {
  return string.replace(/<[^>]+>/g, '');
};

export const serializeItem = ({ itemId, itemType, itemSection }) =>
  `${itemType}--${itemId}${itemSection ? '--' + itemSection : ''}`;

export const deserializeItem = (id) => {
  if (!id?.split) {
    try {
      throw new Error('');
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtra('id_to_deserialize', id);
        scope.setExtra('stack', error.stack);
        Sentry.captureException('bad serialized id');
      });
    }
    return {};
  }
  const [itemType, itemId, itemSection] = id.split('--');
  return { itemType, itemId, itemSection };
};

export const replaceNegativeAggValues = (agg) => {
  const hideNegative = true;

  const replaceIfNegative = (value) => {
    if (!hideNegative) return +value || 0;
    return !+value || +value < 0 ? 0 : +value;
  };
  const totals = { ...agg };

  [
    'estimated_expense',
    'estimated_hours',
    'invoice_amount',
    'invoice_estimate_amount',
    'planned_expense',
    'planned_expense_cost_rate',
    'planned_hours',
    'spent_expense',
    'spent_expense_cost_rate',
    'spent_hours',
    'total_invoice_amount',
    'total_invoice_estimate_amount',
    'total_planned_expense',
    'total_planned_expense_cost_rate',
    'total_planned_hours',
    'total_spent_expense',
    'total_spent_expense_cost_rate',
    'total_spent_hours',
    'total_spent_hours_'
  ].forEach((dataKey) => {
    totals[dataKey] = replaceIfNegative(totals[dataKey]);
  });
  return totals;
};

export const serializeId = ({ itemType, id, ids, delimiter = '--' }) =>
  `${itemType}${delimiter}${
    id ||
    ids?.reduce(
      (acc, cur, index) => acc + `${index ? delimiter : ''}${cur}`,
      ''
    )
  }`;

export const deserializeId = (id, delimiter = '--') => {
  if (!id?.split) {
    return {};
  }
  const [itemType, ...ids] = id.split(delimiter);
  return { itemType, itemId: ids[0], itemId2: ids[1], ids };
};

export const generateWeeklyRecurringIcalString = (
  day = 'FR',
  freq = 'WEEKLY',
  date = moment()
) => {
  const formattedDate = date
    .clone()
    .add(1, 'seconds')
    .format('YYYY-MM-DDTHH:mm:ss');
  return `DTSTART;TZID=EST:${formattedDate}\nRRULE:FREQ=${freq};BYDAY=${day}`;
};

export const generateTimesheetReminderString = (timesheetReminderDates) => {
  const monthHash = {};
  let reminderString = '';
  Object.keys(timesheetReminderDates).forEach((date) => {
    const month = moment(date).format('MMM');
    if (!monthHash[month]) {
      monthHash[month] = true;
      reminderString += month + ' ' + moment(date).format('D') + ', ';
    } else {
      reminderString += moment(date).format('D') + ', ';
    }
  });
  return reminderString.slice(0, -2);
};

export const isStartDateDependency = (dependency) =>
  dependency === DEPENDENCY_STRINGS.START ||
  dependency === DEPENDENCY_STRINGS.END_TO_START;

export const isEndDateDependency = (dependency) =>
  dependency === DEPENDENCY_STRINGS.END ||
  dependency === DEPENDENCY_STRINGS.START_TO_END;
