import React from 'react';
import styled from 'styled-components';
import logo from 'images/mosaic-logo.png';

const StyledSuccessMessage = styled.div`
  width: 600px;
  margin: auto;
  margin-top: 15vh;
  padding: 0 1.5rem;
  background-color: #ffffff;
  border: none;
  text-align: center;

  button {
    border-radius: 55px;
    border: 1px solid #0074d9;
    padding: 10px 25px;
    margin: 10px auto;
    width: inherit;
    height: inherit;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const ThanksHeader = styled.h3`
  font-size: 2.25rem;
  color: #0074d9;
  font-weight: 100;
`;
const StyledCalendlyContainer = styled.div`
  .calendly-inline-widget {
    width: 500px;
    margin: 10px auto;
    height: 600px;
  }

  @media screen and (max-width: 767px) {
    .calendly-inline-widget {
      width: 100%;
    }
  }
`;

class SignupSuccess extends React.Component {
  render() {
    return (
      <div className="auth-page">
        <a href="https://www.mosaicapp.com">
          <img src={logo} className="login-logo" />
        </a>
        <StyledSuccessMessage>
          <div>
            <ThanksHeader>
              <h3> Thanks for Signing Up! </h3>
            </ThanksHeader>
            <p>
              Thanks for signing up! We’re excited to get you started with
              Mosaic. <br /> Have a question or want to skip the wait to get in
              touch with our team? You can reach us at
              <a href="mailto:support@mosaicapp.com">support@mosaicapp.com</a>
              or through our live chat 24/7.
            </p>
            <a href="https://www.mosaicapp.com">
              <button>Go to Mosaic </button>
            </a>
          </div>
          <StyledCalendlyContainer>
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/mosaic-info"
            />
          </StyledCalendlyContainer>
        </StyledSuccessMessage>
      </div>
    );
  }
}

export default SignupSuccess;
