import { useCallback, memo } from 'react';
import Filter from 'views/projectPlanner/Filter';
import { Page } from 'models/filter';
import { useFindPeopleFilterContext } from '../filterConfig';
import { FilterField } from 'FilterModule/constants';

interface FindPeopleProjectSelectorProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

export const FindPeopleProjectSelector = memo(
  ({ isOpen, toggleIsOpen }: FindPeopleProjectSelectorProps) => {
    const { currentFilter, draftFilter } = useFindPeopleFilterContext();

    // save project_ids filter so reopening the page shows the last selected project by default
    // saving only that field since other filters are not saved
    const handleSave = useCallback(() => {
      draftFilter.meta.hasChanges &&
        draftFilter.save([FilterField.project_ids]);
    }, [draftFilter]);

    return (
      <Filter
        viewBy="projects"
        pageName={Page.FindPeople}
        filterListId="find-people"
        noTrigger
        innerHeightAdjustment={154}
        filterContainerClass="find-people"
        listWidth={225}
        crossFieldDependencies={currentFilter.stackedFilterOrder}
        draftFilter={draftFilter}
        isOpen={isOpen}
        handleClose={toggleIsOpen}
        saveOverride={handleSave}
        additionalFilterOptions={additionalFilterOptions}
        isoStateIdPrefix="find-people"
      />
    );
  }
);

FindPeopleProjectSelector.displayName = 'FindPeopleProjectSelector';

/* ------------------------------------ - ----------------------------------- */

const additionalFilterOptions = {
  isSingleSelect: true
};
