import styled from 'styled-components';
import CloseIcon from 'icons/CloseIcon';
import HelpIcon from 'icons/HelpIcon';
import theme from 'theme';
import CalendarIcon from 'icons/CalendarIcon';
import EstimatedHoursIcon from 'icons/EstimatedHoursIcon';
import TaskStatusIcon from 'icons/TaskStatusIcon';
import TaskMilestoneIcon from 'icons/TaskMilestoneIcon';
import DragGripIcon from 'icons/DragGripIcon';
import CompletedIcon from 'icons/CompletedIcon';
import SelectIcon from 'icons/SelectIcon';
import PriorityFlagIcon from 'icons/PriorityFlagIcon';
import { AddTaskPlusGray } from 'icons/AddTaskPlusGray';
import TimelineIcon from 'icons/TimelineIcon';
import {
  calculateColorPref,
  generateColumnWidth,
  getColumnWidth
} from 'appUtils/styleUtils';
import PhaseMilestoneIconNarrow from 'icons/PhaseMilestoneIconNarrow';
import { Styles } from 'components/Table/styles';
import {
  ShowOnRowHover,
  HideOnRowHover,
  TimerIconContainer
} from 'views/Home/Tasks/TaskTimerCell';

export const calcTaskPanelBorderBottom = ({
  isOnPersonalProject,
  isOnHomeView,
  isComplete,
  isSomeTaskEditing,
  isCreatingTask,
  isMemberModalOpen
}) => {
  if (isCreatingTask && !isMemberModalOpen) {
    return '1px solid transparent';
  }
  if (isOnHomeView || isOnPersonalProject || isMemberModalOpen) {
    return '1px solid #eaeaea';
  }
  if (isComplete || isSomeTaskEditing) {
    return '1px solid transparent';
  }
  return '1px solid #eeeeee';
};

export const StyledPhaseMilestoneIconNarrow = styled(PhaseMilestoneIconNarrow)`
  width: 13.5px;
  height: 14.5px;
  path {
    fill: none;
  }
`;

export const StyledGroupHeaderWrapper = styled.div`
  width: 100%;
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  width: 14px;
  height: 12px;
`;
export const HelpIconContainer = styled.div`
  margin-left: 5px;
`;
export const StyledHelpIcon = styled(HelpIcon)`
  height: 16px;
  width: 16px;
`;

export const StyledEstimatedHoursIcon = styled(EstimatedHoursIcon)`
  width: 12px;
  height: 12px;
`;

export const StyledTaskStatusIcon = styled(TaskStatusIcon)`
  width: 14px;
  height: 10px;
`;
export const StyledPriorityIcon = styled(PriorityFlagIcon)`
  width: 15px;
  height: 17px;
`;

export const StyledTimelineIcon = styled(TimelineIcon)`
  width: 14px;
  height: 11px;
`;

export const StyledTaskMilestoneIcon = styled(TaskMilestoneIcon)`
  width: 14px;
  height: 14px;
`;

export const StyledTaskCompletedIcon = styled(CompletedIcon)`
  height: 15px;
  width: 15px;
`;

export const StyledSelectIcon = styled(SelectIcon)`
  height: 15px;
  width: 15px;
`;

export const StyledDragGripIcon = styled(DragGripIcon)`
  min-width: 7px;
  min-height: 7px;
  height: 7px;
  width: 10px;
  margin: 0px 8px 0px 0px;

  cursor: grab;
`;

export const ClearSort = styled(CloseIcon)`
  height: 10px;
  width: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -12px;
  z-index: 1;
  opacity: 0;
  transition: all 0.25s ease-in-oßut;
`;

export const SortContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TaskSortWrapper = styled.div`
  position: relative;
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
  color: #b2b2b2;
  transition: all 0.25s ease-in-out;
  align-self: flex-end;
  font-size: 0.75em;
  display: flex;
  justify-content: center;
  text-align: center;
  ${generateColumnWidth};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  padding-top: 16px;
  padding-bottom: 6px;
  ${ClearSort} {
    opacity: ${({ isSorted, isSelection }) =>
      isSorted && !isSelection ? 1 : 0};
  }
  &:hover {
    ${ClearSort} {
      opacity: ${({ isSorted, isSelection }) =>
        isSorted && !isSelection ? 1 : 0};
    }
    background: ${({ isSelection, isDisabled }) =>
      isSelection || isDisabled ? 'transparent' : '#fff'};
  }
  background: ${({ isSorted, isSelection }) =>
    isSorted && !isSelection ? '#fff' : 'transparent'};
`;

export const TaskSortIcon = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  height: 14px;

  ${StyledCalendarIcon},
  ${StyledEstimatedHoursIcon} {
    path {
      fill: ${({ isSorted }) => (isSorted ? '#0074d9' : '#808080')};
    }
  }

  ${StyledPhaseMilestoneIconNarrow} {
    path {
      fill: none;
      stroke: ${({ isSorted }) => (isSorted ? '#0074d9' : '#808080')};
    }
  }

  ${StyledTaskStatusIcon} {
    circle {
      stroke: ${({ isSorted }) => (isSorted ? '#0074d9' : '#808080')};
    }
  }
  ${StyledTaskMilestoneIcon} {
    rect {
      stroke: ${({ isSorted }) => (isSorted ? '#0074d9' : '#808080')};
    }
  }
  ${StyledPriorityIcon} {
    path {
      fill: ${({ isSorted }) => (isSorted ? '#0074d9' : '#808080')};
    }
  }
  ${TaskSortWrapper}:hover & {
    ${StyledTaskCompletedIcon},
    ${StyledCalendarIcon},
    ${StyledEstimatedHoursIcon},
    ${StyledPriorityIcon} {
      path {
        fill: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }

    ${StyledPhaseMilestoneIconNarrow} {
      path {
        fill: none;
        stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }

    ${StyledSelectIcon} {
      rect {
        stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }

    ${StyledTaskStatusIcon} {
      circle {
        stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }

    ${StyledTaskMilestoneIcon} {
      rect {
        stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }

    ${StyledTimelineIcon} {
      path {
        fill: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }

    ${StyledTaskCompletedIcon} {
      circle {
        stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }
  }
`;

export const TaskSortText = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-family: 'proxima-nova';
  text-transform: uppercase;
  color: ${({ isSorted }) => (isSorted ? '#0074d9' : '#808080')};

  ${TaskSortWrapper}:hover & {
    color: #0074d9;
  }
`;

export const TaskSortIndicator = styled.div`
  right: -15px;
  bottom: -2px;
  opacity: 0;
  display: flex;
  align-items: center;
  transition: all 0.25s ease-in-out;
  position: absolute;
  ${TaskSortWrapper}:hover & {
    opacity: ${({ isSorted }) => (isSorted ? '0' : '1')};
  }

  > svg {
    path {
      fill: #0074d9;
    }
  }
`;

export const LeftDropdownItemContent = styled.div`
  display: flex;
  align-items: center;

  .task-column-text {
    white-space: nowrap;
    margin-left: 5px;
  }
`;

export const RightDropdownItemContent = styled.div`
  opacity: 1;
  color: ${(props) =>
    props.isActive
      ? theme.colors.colorLightGray5
      : theme.colors.colorSemiDarkGray1};
  cursor: pointer !important;
  margin: 0px;
  &:hover {
    div {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const TaskColumnIconContainer = styled.div`
  min-width: 22px;
  justify-content: center;
  align-items: center;
  display: none;
`;

export const StyledTaskColumnDraggable = styled.div`
  opacity: 1;
  font-size: 14px;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  cursor: pointer !important;

  ${StyledDragGripIcon} {
    opacity: 1;
    margin-top: 1px;
  }

  ${LeftDropdownItemContent} {
    opacity: ${(props) => (props.isActive ? 1 : 0.7)};
    font-size: 14px;
    color: ${theme.colors.colorSemiDarkGray1};
  }

  &:hover {
    background: ${theme.colors.colorTranslucentGray3};
    ${RightDropdownItemContent},
    ${StyledDragGripIcon} {
      opacity: 1;
      color: ${theme.colors.colorSemiDarkGray1};
    }
  }
`;

const TaskListHeaderWrapperBase = styled.div`
  min-height: 52px;
  max-height: 75px;
  height: 52px;
  display: flex;
  margin-left: ${({
    isOnHomeView,
    isOnTeamMemberProfile,
    isOnPersonalProject
  }) =>
    isOnHomeView
      ? '0'
      : isOnTeamMemberProfile
      ? '18px'
      : isOnPersonalProject
      ? '62px'
      : '36px'};
  background: ${({ isOnTeamProject, isOnTeamMemberProfile }) =>
    isOnTeamProject || isOnTeamMemberProfile ? '#f3f3f3' : '#fff'};
  margin-right: ${({ isOnTodayView, isDayPlannerView }) =>
    !isDayPlannerView && isOnTodayView
      ? '-14px'
      : isDayPlannerView
      ? '95px'
      : '0'};
  margin-top: ${({ marginTop }) => marginTop};
`;

const sumColumnWidth = (columns) =>
  columns.map(getColumnWidth).reduce((a, b) => a + b, 0);
const BASE_TASK_HEADER_WRAPPER_WIDTH = 574 + 40;

export const calculateHeaderWidth = (
  activeTaskColumns,
  base = BASE_TASK_HEADER_WRAPPER_WIDTH
) => sumColumnWidth(activeTaskColumns) + base;
export const TaskListHeaderWrapper = styled(TaskListHeaderWrapperBase)`
  width: ${(props) =>
    props.isOnHomeView
      ? props.isOnCompletedView || props.isDayPlannerView
        ? 916
        : props.isOnAssignedByMeView
        ? 881
        : 922
      : calculateHeaderWidth(props.activeTaskColumns)}px;
`;

export const TaskListSectionHeaderWrapper = styled(TaskListHeaderWrapperBase)`
  margin-left: 22px;
`;

export const TaskListHeaderStyleWrapper = styled.div`
  display: flex;
  flex: 1;
  background: ${({ isOnTeamProject }) =>
    isOnTeamProject ? '#f3f3f3' : '#fff'};
  border-bottom: 2px solid ${theme.colors.colorLightGray1};
  position: relative;
  ${(props) => !props.isOnTeamProject && `width: 100%;`}
  ${(props) =>
    props.isOnAssignedByMeView &&
    `
    width: 968px;
  `}
`;

export const ListTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ListTitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: ${({ isSemibold }) => (isSemibold ? 600 : 500)};
  line-height: 22px;
  font-size: 16px;
  color: ${(props) => props.titleColor || '#4a4a4a'};
  margin-bottom: 0px;
`;
export const ListSubTitle = styled.p`
  padding-left: 5px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  color: #808080;
  font-size: 16px;
  margin-bottom: 0px;
`;
export const DayTasksCountWrapper = styled.div`
  position: relative;
  min-width: 22px;
`;

export const CollapseIconContainer = styled.div`
  margin-right: 4px;
  margin-left: 2px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: 0.2s ease-in-out;
  transform: ${({ isCollapsed }) => (isCollapsed ? 'rotate(-90deg)' : '')};
  ${(props) => props.isHidden && 'visibility: hidden;'}

  rect {
    stroke: ${({ isCollapsed }) =>
      isCollapsed ? theme.colors.colorPaleGray5 : 'transparent'};
    fill: ${({ isCollapsed, isOnPersonalProject }) =>
      isCollapsed && isOnPersonalProject
        ? theme.colors.colorTranslucentGray4
        : isCollapsed
        ? theme.colors.colorPureWhite
        : 'transparent'};
  }
  path {
    fill: ${theme.colors.colorSemiDarkGray1};
  }

  &:hover {
    rect {
      fill: ${theme.colors.colorPureWhite};
      stroke: ${theme.colors.colorLightGray1};
    }
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

const showDots = () => `margin-right: 6px;
width: fit-content;
opacity: 1;`;

export const ShowOnHover = styled.div`
  width: 0px;
  opacity: 0;
  transition: all 0.25s;
  margin-top: 4px;

  ${({ openDotMenu }) => (openDotMenu ? showDots() : '')}
`;
export const IconsContainer = styled.div`
  margin-left: 22px;
  display: flex;
`;

export const TaskGroupHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  transition: all 0.25s;
  &:hover {
    ${ShowOnHover} {
      ${({ hideDotMenu }) => (hideDotMenu ? '' : showDots())}
    }
  }
`;

export const StyledDragGrips = styled.div`
  position: absolute;
  visibility: ${(props) =>
    props.isDragging || props.shouldShow ? 'visible' : 'hidden'};
  z-index: 100;
  width: 30px;
  left: -10px;
  top: 2px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    visibility: visible;
  }
`;

export const StickyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: sticky;
  z-index: 2;
  background-color: ${(props) =>
    props.isOnTeamProject
      ? theme.colors.colorTranslucentGray4
      : theme.colors.colorPureWhite};
  top: 0;

  ::before {
    content: '';
    visibility: ${(props) => (props.isVirtual ? 'hidden' : 'visible')};
    left: ${({ isOnPersonalProject }) => (isOnPersonalProject ? 0 : 24)}px;
    width: 100%;
    position: absolute;
    height: 2px;
    content: '';
    background-color: ${calculateColorPref};
  }
  :hover {
    ${StyledDragGrips} {
      visibility: visible;
    }
  }
  ${(props) =>
    props.allCollapsed &&
    `
    ${StyledDragGrips} {
      visibility: visible;
    }
  `}
`;

export const NumTasksIndicator = styled.div`
  white-space: nowrap;
  color: ${theme.colors.colorCalendarGray};
  font-size: 19px;
  align-self: flex-end;
  display: flex;
  align-items: center;
`;

export const DropdownIconContainer = styled.div`
  height: 14px;
  width: 14px;
  margin-right: 6px;
  display: flex;
  align-items: center;
`;

export const ThreeDotMenuListItem = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: ${theme.colors.colorSemiDarkGray1};
  height: 34px;
  display: flex;
  width: 100%;
  padding: 0px 10px;
  margin: 2px 0px;
  align-items: center;
  justify-content: flex-start;

  :hover {
    background-color: ${theme.colors.colorPaleGray7};

    &.delete-icon {
      color: ${theme.colors.colorDeleteRed};
      path {
        fill: ${theme.colors.colorDeleteRed};
      }
    }
  }
`;
export const DropdownWrapper = styled.div`
  padding: 5px 0px;
  width: 100%;
  z-index: 0;
`;

export const StyledAddListIcon = styled(AddTaskPlusGray)``;

export const CreateGroupButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 28px;
  text-align: center;
  color: ${theme.colors.colorMediumGray5};
  font-size: 15px;
  margin-left: 0px;
  width: max-content;

  cursor: pointer;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};

    svg {
      path {
        fill: ${theme.colors.colorRoyalBlue};
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;

export const CreateButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 120px;
  z-index: 100;
  justify-content: flex-start;
  align-self: flex-start;
  margin-left: ${({ isOnPersonalProject }) =>
    isOnPersonalProject ? 70 : 24}px;

  .dropdown {
    width: 90px;
  }
`;

export const DropdownContainer = styled.div`
  margin-left: 20px;

  .task-dropdown-title .down-arrow {
    visibility: hidden;
  }

  .task-dropdown-title:hover {
    font-weight: 600;
    .down-arrow {
      visibility: visible;
    }
  }
`;

export const StyledTaskStatusContainer = styled.div`
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  justify-content: center;
`;

export const StyledTaskStatusDot = styled.div`
  height: 11px;
  width: 11px;
  border-radius: 30px;
  background: ${({ color }) => color};
`;

export const StyledTaskStatusText = styled.div`
  font-size: 12px;
  margin-left: 8px;
  color: ${theme.colors.colorSemiDarkGray1};
  max-width: 87%;
`;
export const EmptyPropertyContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const ExpandCollapseAllContainer = styled.div`
  position: relative;
  left: 43px;
  top: 4px;
  z-index: 5;
  height: 28px;
  width: 28px;
  border-radius: 100px;
  display: ${({ isDefault }) => (isDefault ? 'none' : 'flex')};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s;
  transform-origin: center center;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)'};
  background: ${({ isCollapsed }) =>
    isCollapsed ? theme.colors.colorPureWhite : 'transparent'};
  border: 1px solid
    ${({ isCollapsed }) =>
      isCollapsed ? theme.colors.colorPaleGray5 : 'transparent'};
  path {
    fill: ${({ isCollapsed }) =>
      isCollapsed
        ? theme.colors.colorSemiDarkGray1
        : theme.colors.colorMediumGray4};
  }
  &:hover {
    background: ${theme.colors.colorPureWhite};
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;
export const HomeHeaderText = styled.div`
  font-size: 24px;
  font-weight: 700;
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: ${theme.colors.colorSemiDarkGray6};
  margin-top: 20px;
  margin-left: 1px;
`;

export const StyledTaskTable = styled.div`
  height: 100%;
  padding-top: 12px;
  display: flex;
  flex-direction: column;

  .variable-size-list {
    height: 100% !important;
    min-width: fit-content;
  }
  /* width: 1200px; */
  ${(props) =>
    props.isMemberModalOpen &&
    `
    .table {
    margin-top: -25px;
    }
    .variable-size-list {
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
    }
  `}

  ${(props) =>
    props.isStickyRowClosed &&
    `
      .table-headers-container  {
        .styled-report-header, .styled-sort-icon {
          color: transparent !important;
        }
      }
  `}

  .table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .table-body {
    min-height: 0;
  }

  .table-headers-container {
    align-items: flex-end;
    ${(props) =>
      props.isMemberModalOpen &&
      props.isLoading &&
      `transform: translateY(0px)`};
  }
  .tr.taskRow {
    color: ${theme.colors.colorSemiDarkGray1};

    .td.taskRow:not(.drag):not(.timer) {
      border-bottom: ${calcTaskPanelBorderBottom};
      border-top: ${calcTaskPanelBorderBottom};
    }
    ${HideOnRowHover} {
      margin-top: 0px;
    }
    &:hover {
      ${TimerIconContainer} {
      }
      ${ShowOnRowHover} {
        display: flex;
      }
      ${HideOnRowHover} {
        display: none;
      }
    }
  }
  .tr.taskGroupRow,
  .tr.phaseRow,
  .tr.activityRow {
    color: ${theme.colors.colorSemiDarkGray1};
    // border-bottom: 1px solid ${theme.colors.colorPaleGray7};
    align-items: flex-end;

    .td {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        padding-top: 24px;
        background: transparent;
      }
    }
  }
  .taskGroupRow + .tr.taskRow,
  .phaseRow + .tr.taskRow,
  .activityRow + .tr.taskRow,
  .variable-size-list > div > .tr.taskRow {
    .td.taskRow:not(.drag) {
      border-top: 1px solid transparent;
    }
  }

  .td.removedTaskRow:not(.drag):not(.timer) {
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray4};
    border-left-color: ${({ theme }) => theme.colors.colorTranslucentGray4};

    .taskCompleteCell {
      background-color: transparent;
    }
  }

  ${Styles} {
    min-height: 0;
  }
`;

export const PlaceholderHeaderCell = styled.div`
  width: 95px;
`;
