import { createRequestTypes } from 'appUtils';
export * from './phases';
export * from './access';
export * from './tasks';
export * from './userActivities';
export * from './tooltips';
export * from './billing';
export * from './skills';
export * from './suggestions';
export * from './scopes';
export * from './triggers';
export * from './offline';
export * from './errors';
export * from './api';
export * from './attachments';
export * from './distance';
export * from './comments';

/* constants */
export * from './splitFlags';
export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const GENERIC_ACTION = 'GENERIC_ACTION';

export const PROJECT_MEMBER_ROLE_INDEX = 1;
export const NOOP = 'NOOP';
/* launched by actionCreators.js */
export const LOGOUT = 'LOGOUT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_INVITE = 'LOGOUT_USER_INVITE';
export const LOGOUT_TRIAL_EXPIRY = 'LOGOUT_TRIAL_EXPIRY';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const CALL_HISTORY_METHOD = '@@router/CALL_HISTORY_METHOD';
export const NAVIGATION_CHANGE = 'NAVIGATION_CHANGE';
export const NAVIGATE_UP_CLEANUP = 'NAVIGATE_UP_CLEANUP';
export const NAVIGATE_UP = 'NAVIGATE_UP';
export const NAVIGATE_BACK_CLEANUP = 'NAVIGATE_BACK_CLEANUP';
export const NAVIGATE_BACK = 'NAVIGATE_BACK';
export const NAVIGATE_TO = 'NAVIGATE_TO';
export const SET_NAVIGATION_BLOCKED = 'SET_NAVIGATION_BLOCKED';
export const ADD_PARSED_ROUTED_PARAMS = 'ADD_PARSED_ROUTED_PARAMS';
export const REMOVE_PARSED_ROUTED_PARAMS = 'REMOVE_PARSED_ROUTED_PARAMS';
export const RESET_PARSED_ROUTED_PARAMS = 'RESET_PARSED_ROUTED_PARAMS';
export const SET_LD_FLAGS = 'SET_LD_FLAGS';
export const CALCULATE_RESPONSIVE_STATE =
  'redux-responsive/CALCULATE_RESPONSIVE_STATE';

export const EDIT_GROUP = 'EDIT_GROUP';
export const CLOSE_ADD_GROUP_MODAL = 'CLOSE_ADD_GROUP_MODAL';
export const FETCH_PROJECTS_BY_GROUP = 'FETCH_PROJECTS_BY_GROUP';
export const FETCH_SCHEDULED_PROJECTS = 'FETCH_SCHEDULED_PROJECTS';

export const FETCH_SINGLE_TASK = 'FETCH_SINGLE_TASK';
export const TASK_UPDATE_POSITION = 'TASK_UPDATE_POSITION';

export const TOGGLE_FLICKER_ACTIVITY_CELL = 'TOGGLE_FLICKER_ACTIVITY_CELL';
export const TOGGLE_FLICKER_TASK_CELL = 'TOGGLE_FLICKER_TASK_CELL';

export const CLOSE_INVITE_TEAM_MEMBER_MODAL = 'CLOSE_INVITE_TEAM_MEMBER_MODAL';
export const OPEN_INVITE_TEAM_MEMBER_MODAL = 'OPEN_INVITE_TEAM_MEMBER_MODAL';
export const OPEN_ADD_EDIT_GROUP_MODAL = 'OPEN_ADD_EDIT_GROUP_MODAL';
export const CLOSE_EDIT_GROUP_MODAL = 'CLOSE_EDIT_GROUP_MODAL';
export const OPEN_ADD_PROJECT_MODAL = 'OPEN_ADD_PROJECT_MODAL';
export const OPEN_EDIT_PROJECT_MODAL = 'OPEN_EDIT_PROJECT_MODAL';
export const SWITCH_BOARDS = 'SWITCH_BOARDS';
export const SET_SELECTED_BOARD = 'SET_SELECTED_BOARD';
export const CLEAR_SELECTED_BOARD = 'CLEAR_SELECTED_BOARD';
export const CLOSE_ADD_PROJECT_MODAL = 'CLOSE_ADD_PROJECT_MODAL';
export const CLOSE_EDIT_PROJECT_MODAL = 'CLOSE_EDIT_PROJECT_MODAL';
export const CLOSE_EDIT_PROJECT_MODAL_MAINTAIN_EDIT =
  'CLOSE_EDIT_PROJECT_MODAL_MAINTAIN_EDIT';
export const EDIT_PROJECT_COMMENT = createRequestTypes('EDIT_PROJECT_COMMENT');
export const DELETE_PROJECT_COMMENT = createRequestTypes(
  'DELETE_PROJECT_COMMENT'
);
export const FETCH_PROJECT_BY_ID = 'FETCH_PROJECT_BY_ID';
export const TOGGLE_ARCHIVE_GROUP_MODAL = 'TOGGLE_ARCHIVE_GROUP_MODAL';
export const TOGGLE_DELETE_PROJECT_MODAL = 'TOGGLE_DELETE_PROJECT_MODAL';
export const UPDATE_SELECTED_GROUP_NAME = 'UPDATE_SELECTED_GROUP_NAME';
export const PLAN_QUANTITY_UPDATE = 'PLAN_QUANTITY_UPDATE';
export const PLAN_PERIOD_UPDATE = 'PLAN_PERIOD_UPDATE';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SAVE_SEARCH_STATE = 'SAVE_SEARCH_STATE';
export const USE_SEARCH_STATE = 'USE_SEARCH_STATE';
export const ADD_MEMBER_TO_LOCAL_GROUP = 'ADD_MEMBER_TO_LOCAL_GROUP';
export const ADD_BULK_MEMBERS_TO_LOCAL_GROUP =
  'ADD_BULK_MEMBERS_TO_LOCAL_GROUP';
export const DELETE_MEMBER_FROM_LOCAL_GROUP = 'DELETE_MEMBER_FROM_LOCAL_GROUP';
export const IS_SEARCHING = 'IS_SEARCHING';
export const FILTER_BY_MEMBERS = 'FILTER_BY_MEMBERS';
export const ON_SEARCH = 'ON_SEARCH';
export const TOGGLE_SEARCH_RESULTS = 'TOGGLE_SEARCH_RESULTS';
export const OPEN_ADD_NOTE_MODAL = 'OPEN_ADD_NOTE_MODAL';
export const CLOSE_ADD_NOTE_MODAL = 'CLOSE_ADD_NOTE_MODAL';
export const OPEN_EDIT_NOTE_MODAL = 'OPEN_EDIT_NOTE_MODAL';
export const CLOSE_EDIT_NOTE_MODAL = 'CLOSE_EDIT_NOTE_MODAL';
export const OPEN_DELETE_NOTE_MODAL = 'OPEN_DELETE_NOTE_MODAL';
export const CLOSE_DELETE_NOTE_MODAL = 'CLOSE_DELETE_NOTE_MODAL';
export const CLEAR_NOTES = 'CLEAR_NOTES';
export const SET_HOME_ORIGIN = 'SET_HOME_ORIGIN';
export const SET_ENABLE_CHANGE_PROJECT = 'SET_ENABLE_CHANGE_PROJECT';
export const CLEAR_SELECTED_PROJECT = 'CLEAR_SELECTED_PROJECT';

export const LOAD_WITH_SIDEBAR_OPEN = 'LOAD_WITH_SIDEBAR_OPEN';
export const TRIGGER_MEMBER_LIST_REFRESH = 'TRIGGER_MEMBER_LIST_REFRESH';
export const SET_EDIT_TASK = 'SET_EDIT_TASK';
export const ADD_NEW_TASK_TEMPLATE = 'ADD_NEW_TASK_TEMPLATE';
export const HANDLE_PROJECT_ITEM_STATE = 'HANDLE_PROJECT_ITEM_STATE';
export const MONTH_HEADER_CHANGE = 'MONTH_HEADER_CHANGE';
export const WEEKS_TO_DISPLAY_UPDATE = 'WEEKS_TO_DISPLAY_UPDATE';
export const MONTHS_TO_DISPLAY_UPDATE = 'MONTHS_TO_DISPLAY_UPDATE';
export const DAYS_TO_DISPLAY_UPDATE = 'DAYS_TO_DISPLAY_UPDATE';
export const SET_MODAL = 'SET_MODAL';
export const SET_ACTIVE_KEY = 'SET_ACTIVE_KEY';
export const CANCEL_TRIAL_EXPIRY = 'CANCEL_TRIAL_EXPIRY';
export const CLEAR_BILLING_ERROR = 'CLEAR_BILLING_ERROR';
export const SET_VIDEO_MODAL_VISITED = 'SET_VIDEO_MODAL_VISITED';
export const TOGGLE_MEMBER_INPUT = 'TOGGLE_MEMBER_INPUT';
export const TOGGLE_INVITE_BUTTON = 'TOGGLE_INVITE_BUTTON';
export const TOGGLE_INVITE_FORM = 'TOGGLE_INVITE_FORM';
export const SET_MEMBER_INPUT_VALUE = 'SET_MEMBER_INPUT_VALUE';
export const SET_FILTER_TAG_ID = 'SET_FILTER_TAG_ID';
export const UPDATE_TAG = createRequestTypes('UPDATE_TAG');
export const POPULATE_CACHED_PROJECTS = 'POPULATE_CACHED_PROJECTS';
export const FETCH_CACHED_PROJECTS = 'FETCH_CACHED_PROJECTS';
export const CLEAR_CACHED_PROJECTS = 'CLEAR_CACHED_PROJECTS';
export const CLEAR_ALL_PROJECTS = 'CLEAR_ALL_PROJECTS';
export const CLEAR_BOARD_PROJECTS = 'CLEAR_BOARD_PROJECTS';
export const LAZY_LOADING_PROJECT_LIST = 'LAZY_LOADING_PROJECT_LIST';
export const TOGGLE_HELP_MENU = 'TOGGLE_HELP_MENU';
export const TOGGLE_SIDEBAR_SEARCH = 'TOGGLE_SIDEBAR_SEARCH';
export const CLOSE_SIDEBAR_SEARCH = 'CLOSE_SIDEBAR_SEARCH';
export const OPEN_SIDEBAR_PROJECTS = 'OPEN_SIDEBAR_PROJECTS';
export const CLOSE_SIDEBAR_PROJECTS = 'CLOSE_SIDEBAR_PROJECTS';
export const CLOSE_SIDEBAR_SEARCH_AND_PROJECTS =
  'CLOSE_SIDEBAR_SEARCH_AND_PROJECTS';
export const SET_MOUSE_OVER_SEARCH_BAR = 'SET_MOUSE_OVER_SEARCH_BAR';
export const SET_BOARD_SORT_PROPERTY = 'SET_BOARD_SORT_PROPERTY';
export const ADD_NEW_MILESTONE_TEMPLATE = 'ADD_NEW_MILESTONE_TEMPLATE';
export const REMOVE_NEW_MILESTONE = 'REMOVE_NEW_MILESTONE';
export const SET_EDIT_MILESTONE_ID = 'SET_EDIT_MILESTONE_ID';
export const SET_DELETE_MILESTONE_ID = 'SET_DELETE_MILESTONE_ID';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const OPEN_MILESTONE_MODAL = 'OPEN_MILESTONE_MODAL';
export const CLOSE_MILESTONE_MODAL = 'CLOSE_MILESTONE_MODAL';
export const OPEN_TIMER_DRAWER = 'OPEN_TIMER_DRAWER';
export const CLOSE_TIMER_DRAWER = 'CLOSE_TIMER_DRAWER';
export const OPEN_TIMER_DELETE_MODAL = 'OPEN_TIMER_DELETE_MODAL';
export const CLOSE_TIMER_DELETE_MODAL = 'CLOSE_TIMER_DELETE_MODAL';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';
export const CLEAR_RESEND_INVITE = 'CLEAR_RESEND_INVITE';
export const CLEAR_SELECTED_TEAM_MEMBER = 'CLEAR_SELECTED_TEAM_MEMBER';
export const CLOSE_CLEAR_TASK_VIEW_MODAL = 'CLOSE_CLEAR_TASK_VIEW_MODAL';
export const CLOSE_CLEAR_MEMBER_MODAL = 'CLOSE_CLEAR_MEMBER_MODAL';
export const SET_SELECTED_TASK = 'SET_SELECTED_TASK';
export const OPEN_ADD_MEMBERS_MODAL = 'OPEN_ADD_MEMBERS_MODAL';
export const OPEN_BULK_ADD_MEMBERS_MODAL = 'OPEN_BULK_ADD_MEMBERS_MODAL';
export const CLOSE_BULK_ADD_MEMBERS_MODAL = 'CLOSE_BULK_ADD_MEMBERS_MODAL';
export const POPULATE_BULK_ADD_MEMBERS = 'POPULATE_BULK_ADD_MEMBERS';
export const EMPTY_BULK_ADD_MEMBERS = 'EMPTY_BULK_ADD_MEMBERS';
export const CLOSE_ADD_MEMBERS_MODAL = 'CLOSE_ADD_MEMBERS_MODAL';
export const SET_PLANNER_DATE_HEADER = 'SET_PLANNER_DATE_HEADER';
export const CLEAR_REMOVED_TASKS_TRIGGER = 'CLEAR_REMOVED_TASKS_TRIGGER';
export const CLEAR_REMOVED_TASKS = 'CLEAR_REMOVED_TASKS';
export const TOGGLE_BULK_UPDATE_MODAL = 'TOGGLE_BULK_UPDATE_MODAL';
export const UPDATE_EDITED_TASKS = 'UPDATE_EDITED_TASKS';
export const UPDATE_EDITED_HOME_TASKS = 'UPDATE_EDITED_HOME_TASKS';
export const OPEN_PROJECT_PLANNER_MODAL = 'OPEN_PROJECT_PLANNER_MODAL';
export const CLOSE_PROJECT_PLANNER_MODAL = 'CLOSE_PROJECT_PLANNER_MODAL';
export const SET_PROJECT_PLANNER_MEMBER = 'SET_PROJECT_PLANNER_MEMBER';
export const SET_VISIBLE_WEEK = 'SET_VISIBLE_WEEK';
export const SET_VISIBLE_DATES = 'SET_VISIBLE_DATES';
export const SET_SELECTED_TEAM_MEMBER = 'SET_SELECTED_TEAM_MEMBER';
export const SET_TASK_ACCOUNT_FILTER = 'SET_TASK_ACCOUNT_FILTER';
export const RESET_TASK_FILTERS = 'RESET_TASK_FILTERS';
export const SET_ACTIVE_TASK_FILTER = 'SET_ACTIVE_TASK_FILTER';
export const SET_MEMBER_MODAL_TASK_FILTER = 'SET_MEMBER_MODAL_TASK_FILTER';
export const SET_TASK_VIEW_BY = 'SET_TASK_VIEW_BY';
// export const SET_RECURLY_BILLING_INFO = createRequestTypes(
//   'SET_RECURLY_BILLING_INFO'
// );
// export const FETCH_RECURLY_BILLING_INFO = createRequestTypes(
//   'FETCH_RECURLY_BILLING_INFO'
// );

/* We use these actions to manage the payment flow in Settings > Billing */
export const ENTER_UPDATE_CARD = 'ENTER_UPDATE_CARD';
export const EXIT_UPDATE_CARD = 'EXIT_UPDATE_CARD';

export const PASSWORD_FORGOT_FORM_RESET = 'PASSWORD_FORGOT_FORM_RESET';
export const SIGNUP_CLOSE = 'SIGNUP_CLOSE';

/* Project Comment Modal */
export const OPEN_PROJECT_MODAL = 'OPEN_PROJECT_MODAL';
export const CLOSE_PROJECT_MODAL = 'CLOSE_PROJECT_MODAL';

export const TOGGLE_APP_SIDER_MENU = 'TOGGLE_APP_SIDER_MENU';
export const SET_RELOADER_SCREEN = 'SET_RELOADER_SCREEN';
export const SET_APP_SIDER_MENU_VISIBILITY = 'SET_APP_SIDER_MENU_VISIBILITY';
export const OPEN_GLOBAL_ADD = 'OPEN_GLOBAL_ADD';
export const CLOSE_GLOBAL_ADD = 'CLOSE_GLOBAL_ADD';
export const OPEN_TASK_GLOBAL_ADD = 'OPEN_TASK_GLOBAL_ADD';
export const CLOSE_TASK_GLOBAL_ADD = 'CLOSE_TASK_GLOBAL_ADD';

/* Saga will launch those actions */
export const USER = createRequestTypes('USER');
export const LOGIN = createRequestTypes('LOGIN');
export const SIGNUP = createRequestTypes('SIGNUP');
export const EXCHANGE_ACCESS_TOKEN = 'EXCHANGE_ACCESS_TOKEN';
export const GET_AUTH_TOKEN = createRequestTypes('GET_AUTH_TOKEN');
export const TOGGLE_SIDEBAR_PROJECTS = createRequestTypes(
  'TOGGLE_SIDEBAR_PROJECTS'
);
export const UPDATE_SIDEBAR_PROJECTS_PINNED_STATE = createRequestTypes(
  'UPDATE_SIDEBAR_PROJECTS_PINNED_STATE'
);
export const GROUP_CREATION = createRequestTypes('GROUP_CREATION');
export const GROUP_EDITION = createRequestTypes('GROUP_EDITION');
export const ARCHIVE_BOARD = createRequestTypes('ARCHIVE_BOARD');
export const UNARCHIVE_BOARD = createRequestTypes('UNARCHIVE_BOARD');
export const DELETE_BOARD = createRequestTypes('DELETE_BOARD');
export const GROUPS = createRequestTypes('GROUPS');
export const GROUP_MEMBERS = createRequestTypes('GROUP_MEMBERS');
export const CHANGE_BOARD_POSITION = createRequestTypes(
  'CHANGE_BOARD_POSITION'
);
export const FETCH_SELECTED_BOARD = createRequestTypes('FETCH_SELECTED_BOARD');
export const FETCH_PROJECTS_LIST_VIEW = createRequestTypes(
  'FETCH_PROJECTS_LIST_VIEW'
);
export const EXPORT_PROJECTS_LIST_VIEW = createRequestTypes(
  'EXPORT_PROJECTS_LIST_VIEW'
);
export const FETCH_ARCHIVED_BOARDS = createRequestTypes(
  'FETCH_ARCHIVED_BOARDS'
);
export const TOGGLE_EMAIL_NOTIFICATION = createRequestTypes(
  'TOGGLE_EMAIL_NOTIFICATION'
);
export const FETCH_TEAMS = createRequestTypes('FETCH_TEAMS');
export const ALL_GROUP_MEMBERS = createRequestTypes('ALL_GROUP_MEMBERS');

export const FETCH_PROJECTS = createRequestTypes('FETCH_PROJECTS', {
  createTrigger: false
});

export const FETCH_TASKS = createRequestTypes('FETCH_TASKS', {
  createTrigger: false
});

export const FETCH_TASK = createRequestTypes('FETCH_TASK');

export const TASK_CREATION = createRequestTypes('TASK_CREATION');
export const DELETE_TASK = createRequestTypes('DELETE_TASK');
export const TASK_UPDATE = createRequestTypes('TASK_UPDATE');
export const MOVE_TASK = createRequestTypes('MOVE_TASK', {
  createTrigger: false
});
export const UPDATE_TASK_COMPLETION = createRequestTypes(
  'UPDATE_TASK_COMPLETION'
);

export const ON_GLOBAL_SEARCH = createRequestTypes('ON_GLOBAL_SEARCH');

export const FETCH_NOTE_TAGS_BY_BOARD = createRequestTypes(
  'FETCH_NOTE_TAGS_BY_BOARD'
);

export const FETCH_TASK_GROUPS = createRequestTypes('FETCH_TASK_GROUPS');
export const FETCH_PROJECT_TASK_GROUPS = createRequestTypes(
  'FETCH_PROJECT_TASK_GROUPS'
);
export const CREATE_PROJECT_TASK_GROUP = createRequestTypes(
  'CREATE_PROJECT_TASK_GROUP'
);
export const UPDATE_PROJECT_TASK_GROUP = createRequestTypes(
  'UPDATE_PROJECT_TASK_GROUP'
);
export const DELETE_PROJECT_TASK_GROUP = createRequestTypes(
  'DELETE_PROJECT_TASK_GROUP'
);
export const REORDER_PROJECT_TASK_GROUPS = createRequestTypes(
  'REORDER_PROJECT_TASK_GROUPS'
);
export const TOGGLE_TASK_GROUP = 'TOGGLE_TASK_GROUP';
export const TOGGLE_TASK_GROUP_ALL = 'TOGGLE_TASK_GROUP_ALL';
export const FETCH_PROJECT_TASK_GROUP_TASKS = createRequestTypes(
  'FETCH_PROJECT_TASK_GROUP_TASKS'
);
export const REMOVE_TASK_GROUP_FROM_FETCH_QUEUE =
  'REMOVE_TASK_GROUP_FROM_FETCH_QUEUE';
export const ADD_TASK_GROUP_TO_FETCH_QUEUE = 'ADD_TASK_GROUP_TO_FETCH_QUEUE';
export const ARCHIVE_UNARCHIVE_PROJECT = createRequestTypes(
  'ARCHIVE_UNARCHIVE_PROJECT'
);
export const BATCH_ARCHIVE_UNARCHIVE_PROJECT = createRequestTypes(
  'BATCH_ARCHIVE_UNARCHIVE_PROJECT'
);
export const STAR_PROJECT = createRequestTypes('STAR_PROJECT');
export const UPDATE_NOTIFICATION_PREFERENCES = createRequestTypes(
  'UPDATE_NOTIFICATION_PREFERENCES'
);
export const INVITE_MEMBER = createRequestTypes('INVITE_MEMBER');
export const INVITE_QB_MEMBERS = createRequestTypes('INVITE_QB_MEMBERS');
export const INVITE_VALIDATION = createRequestTypes('INVITE_VALIDATION');

export const PROJECT_CREATION = createRequestTypes('PROJECT_CREATION');
export const PROJECT_EDITION = createRequestTypes('PROJECT_EDITION');
export const UPDATE_PROJECT_BILLABLE = createRequestTypes(
  'UPDATE_PROJECT_BILLABLE'
);
export const PROJECT_POSITION_PERSISTENCE = createRequestTypes(
  'PROJECT_POSITION_PERSISTENCE'
);
export const CREATE_PROJECT_COMMENT = createRequestTypes(
  'CREATE_PROJECT_COMMENT'
);
export const PROJECT = createRequestTypes('PROJECT', { createTrigger: false });
export const TEAM_MEMBERS = createRequestTypes('TEAM_MEMBERS');
export const FETCH_TEAM_MEMBERS_BY_BOARD = createRequestTypes(
  'FETCH_TEAM_MEMBERS_BY_BOARD'
);

export const FETCH_ALL_TEAM_MEMBERS_BY_BOARD = createRequestTypes(
  'FETCH_ALL_TEAM_MEMBERS_BY_BOARD'
);

export const FETCH_ALL_PROJECT_MEMBERS = createRequestTypes(
  'FETCH_ALL_PROJECT_MEMBERS'
);

export const ADD_MEMBER_TO_GROUP = createRequestTypes('ADD_MEMBER_TO_GROUP');
export const DELETE_MEMBER_FROM_GROUP = createRequestTypes(
  'DELETE_MEMBER_FROM_GROUP'
);
export const DELETE_MEMBER_FROM_PHASE = createRequestTypes(
  'DELETE_MEMBER_FROM_PHASE'
);
export const ADD_BULK_MEMBERS_TO_GROUP = createRequestTypes(
  'ADD_BULK_MEMBERS_TO_GROUP'
);

export const FETCH_PROJECT_MEMBERS = createRequestTypes(
  'FETCH_PROJECT_MEMBERS'
);
export const FETCH_PROJECTS_ROLES = createRequestTypes('FETCH_PROJECTS_ROLES');
export const ADD_MEMBER_TO_PROJECT = createRequestTypes(
  'ADD_MEMBER_TO_PROJECT'
);
export const ADD_BULK_MEMBERS_TO_PROJECT = createRequestTypes(
  'ADD_BULK_MEMBERS_TO_PROJECT'
);
export const DELETE_MEMBER_FROM_PROJECT = createRequestTypes(
  'DELETE_MEMBER_FROM_PROJECT'
);
export const UPDATE_PROJECT_MEMBER_ROLE = createRequestTypes(
  'UPDATE_PROJECT_MEMBER_ROLE'
);
export const UPDATE_BOARD_MEMBER_ROLE = createRequestTypes(
  'UPDATE_BOARD_MEMBER_ROLE'
);
export const DELETE_ARCHIVE_GROUP = createRequestTypes('DELETE_ARCHIVE_GROUP');
export const DELETE_PROJECT = createRequestTypes('DELETE_PROJECT');
export const HARD_DELETE_PROJECT = createRequestTypes('HARD_DELETE_PROJECT');
export const DELETE_PROJECT_FROM_ORDER = 'DELETE_PROJECT_FROM_ORDER';
export const PROJECT_UPDATE_POSITION = createRequestTypes(
  'PROJECT_UPDATE_POSITION'
);
export const PROJECT_PRIORITY = createRequestTypes('PROJECT_PRIORITY');
export const TEAM_UPDATE = createRequestTypes('TEAM_UPDATE');
export const UPDATE_TEAM_ORDERED_ITEMS = createRequestTypes(
  'UPDATE_TEAM_ORDERED_ITEMS'
);
export const UPDATE_TEAM_VIEW_SETTINGS = createRequestTypes(
  'UPDATE_TEAM_VIEW_SETTINGS'
);
export const PROFILE_UPDATE = createRequestTypes('PROFILE_UPDATE');
export const PASSWORD_UPDATE = createRequestTypes('PASSWORD_UPDATE');
export const PASSWORD_SETTINGS_UPDATE = createRequestTypes(
  'PASSWORD_SETTINGS_UPDATE'
);

export const SUBSCRIPTION_FETCH = createRequestTypes('SUBSCRIPTION_FETCH');
export const SUBSCRIPTION_CANCELLATION = createRequestTypes(
  'SUBSCRIPTION_CANCELLATION'
);

export const FETCH_MY_PROJECTS = createRequestTypes('FETCH_MY_PROJECTS');
export const FETCH_PROJECT_CLIENTS = createRequestTypes(
  'FETCH_PROJECT_CLIENTS'
);
export const FETCH_ALL_PROJECTS = createRequestTypes('FETCH_ALL_PROJECTS');

export const FETCH_PROJECTS_INDEX_V2 = createRequestTypes(
  'FETCH_PROJECTS_INDEX_V2'
);
export const RESET_PROJECT_FILTER_LIST = 'RESET_PROJECT_FILTER_LIST';
export const MY_PROJECT_UPDATE = createRequestTypes('MY_PROJECT_UPDATE');
export const MOVE_HOME_TASK = createRequestTypes('MOVE_HOME_TASK', {
  createTrigger: false
});

export const RESEND_INVITE = createRequestTypes('RESEND_INVITE');

export const UPDATE_TEAM_MEMBERSHIP = createRequestTypes(
  'UPDATE_TEAM_MEMBERSHIP'
);
export const UPDATE_TEAM_MEMBERSHIP_PROFILE = createRequestTypes(
  'UPDATE_TEAM_MEMBERSHIP_PROFILE'
);

export const UPDATE_TEAM_MEMBER_EMAIL_NOTIFICATION = createRequestTypes(
  'UPDATE_TEAM_MEMBER_EMAIL_NOTIFICATION'
);
export const CREATE_TASK_STATUS = createRequestTypes('CREATE_TASK_STATUS');
export const UPDATE_TASK_STATUS = createRequestTypes('UPDATE_TASK_STATUS');
export const DELETE_TASK_STATUS = createRequestTypes('DELETE_TASK_STATUS');

export const FETCH_TASK_PRIORITIES = createRequestTypes(
  'FETCH_TASK_PRIORITIES'
);
export const CREATE_TASK_PRIORITY = createRequestTypes('CREATE_TASK_PRIORITY');
export const UPDATE_TASK_PRIORITY = createRequestTypes('UPDATE_TASK_PRIORITY');
export const DELETE_TASK_PRIORITY = createRequestTypes('DELETE_TASK_PRIORITY');

export const CREATE_PROJECT_STAGE = createRequestTypes('CREATE_PROJECT_STAGE');
export const UPDATE_PROJECT_STAGE = createRequestTypes('UPDATE_PROJECT_STAGE');
export const DELETE_PROJECT_STAGE = createRequestTypes('DELETE_PROJECT_STAGE');

export const CREATE_PROJECT_STATUS = createRequestTypes(
  'CREATE_PROJECT_STATUS'
);
export const UPDATE_PROJECT_STATUS = createRequestTypes(
  'UPDATE_PROJECT_STATUS'
);
export const DELETE_PROJECT_STATUS = createRequestTypes(
  'DELETE_PROJECT_STATUS'
);

export const CREATE_PROJECT_PRIORITY = createRequestTypes(
  'CREATE_PROJECT_PRIORITY'
);
export const UPDATE_PROJECT_PRIORITY = createRequestTypes(
  'UPDATE_PROJECT_PRIORITY'
);
export const DELETE_PROJECT_PRIORITY = createRequestTypes(
  'DELETE_PROJECT_PRIORITY'
);

export const FETCH_TEAM_MEMBER_PROFILE = createRequestTypes(
  'FETCH_TEAM_MEMBER_PROFILE'
);

/**
 * use this for member detailed profile (less coupling)
 */
export const FETCH_TEAM_MEMBER_DETAIL = createRequestTypes(
  'FETCH_TEAM_MEMBER_DETAIL'
);
export const FETCH_COMMENTS_AND_METADATA = createRequestTypes(
  'FETCH_COMMENTS_AND_METADATA'
);
export const CREATE_COMMENT = createRequestTypes('CREATE_COMMENT');
export const UPDATE_COMMENT = createRequestTypes('UPDATE_COMMENT');
export const DELETE_COMMENT = createRequestTypes('DELETE_COMMENT');

export const DELETE_TASK_ATTACHMENT = createRequestTypes(
  'DELETE_TASK_ATTACHMENT'
);
export const DELETE_PROJECT_ATTACHMENT = createRequestTypes(
  'DELETE_PROJECT_ATTACHMENT'
);

export const FETCH_CHECKINS = createRequestTypes('FETCH_CHECKINS');

export const BULK_CREATE_CHECKIN = createRequestTypes('BULK_CREATE_CHECKIN');

export const CREATE_CHECKIN = createRequestTypes('CREATE_CHECKIN');

export const DELETE_CHECKIN = createRequestTypes('DELETE_CHECKIN');

export const UPDATE_CHECKIN = createRequestTypes('UPDATE_CHECKIN');

export const FETCH_CHECKINS_HOURS = createRequestTypes('FETCH_CHECKINS_HOURS');

export const FETCH_TIMERS = createRequestTypes('FETCH_TIMERS');

export const FETCH_ACTIVE_TIMER = createRequestTypes('FETCH_ACTIVE_TIMER');

export const START_TIMER = createRequestTypes('START_TIMER');

export const END_TIMER = createRequestTypes('END_TIMER');

export const UPDATE_TIMER = createRequestTypes('UPDATE_TIMER');

export const DELETE_TIMER = createRequestTypes('DELETE_TIMER');

export const ASSOCIATE_TASK = createRequestTypes('ASSOCIATE_TASK');

export const SET_TIMER_DATE = createRequestTypes('SET_TIMER_DATE');

// Notifications
export const FETCH_NOTIFICATIONS = createRequestTypes('FETCH_NOTIFICATIONS');

export const FETCH_NOTIFICATIONS_COUNT = createRequestTypes(
  'FETCH_NOTIFICATIONS_COUNT'
);
export const UPDATE_NOTIFICATION_AS_READ = createRequestTypes(
  'UPDATE_NOTIFICATION_AS_READ'
);
export const UPDATE_NOTIFICATIONS_AS_OLD = createRequestTypes(
  'UPDATE_NOTIFICATIONS_AS_OLD'
);
export const MARK_ALL_NOTIFICATIONS_READ = createRequestTypes(
  'MARK_ALL_NOTIFICATIONS_READ'
);

// Project Planner
export const PREDICT_WORKDAYS = createRequestTypes('PREDICT_WORKDAYS');
export const CREATE_WORKLOAD_PLANNER = createRequestTypes(
  'CREATE_WORKLOAD_PLANNER'
);
export const PREDICT_WORKLOAD_PLANNER = createRequestTypes(
  'PREDICT_WORKLOAD_PLANNER'
);
export const VIEW_WORKLOAD_PLANNER = createRequestTypes(
  'VIEW_WORKLOAD_PLANNER'
);
export const FETCH_WORKLOAD_PLANNER = createRequestTypes(
  'FETCH_WORKLOAD_PLANNER'
);
export const UPDATE_WORKLOAD_PLANNER = createRequestTypes(
  'UPDATE_WORKLOAD_PLANNER'
);
export const DELETE_WORKLOAD_PLANNER = createRequestTypes(
  'DELETE_WORKLOAD_PLANNER'
);
export const SPLIT_WORKLOAD_PLANNER = createRequestTypes(
  'SPLIT_WORKLOAD_PLANNER'
);
export const UPDATE_WORKLOAD_PLANNER_MEMBERS = createRequestTypes(
  'UPDATE_WORKLOAD_PLANNER_MEMBERS'
);
export const APPROVE_WORKPLAN_REQUESTS = createRequestTypes(
  'APPROVE_WORKPLAN_REQUESTS'
);
export const FETCH_MEMBER_PROJECTS = createRequestTypes(
  'FETCH_MEMBER_PROJECTS'
);
export const FETCH_SUGGESTIONS = createRequestTypes('FETCH_SUGGESTIONS');

export const SET_PROJECT_SUMMARY_ACCOUNT_IDS =
  'SET_PROJECT_SUMMARY_ACCOUNT_IDS';

export const SET_WORKLOAD_SPLIT_SCREEN_ACTIVE =
  'SET_WORKLOAD_SPLIT_SCREEN_ACTIVE';
export const SET_WORKLOAD_SPLIT_SCREEN_INACTIVE =
  'SET_WORKLOAD_SPLIT_SCREEN_INACTIVE';

export const SET_PLANNER_SPLIT_SCREEN_ACTIVE =
  'SET_PLANNER_SPLIT_SCREEN_ACTIVE';
export const SET_PLANNER_SPLIT_SCREEN_INACTIVE =
  'SET_PLANNER_SPLIT_SCREEN_INACTIVE';

export const SET_SPLIT_SCHEDULE_BAR_ID = 'SET_SPLIT_SCHEDULE_BAR_ID';
export const SET_BAR_CONTEXT_MENU_ID = 'SET_BAR_CONTEXT_MENU_ID';

export const OPEN_ACTIVITY_SIDE_MENU = 'OPEN_ACTIVITY_SIDE_MENU';
export const CLOSE_ACTIVITY_SIDE_MENU = 'CLOSE_ACTIVITY_SIDE_MENU';
export const SET_VISIBLE_INDICES = 'SET_VISIBLE_INDICES';

export const ADD_NEW_PROJECT_ROW = 'ADD_NEW_PROJECT_ROW';
export const DELETE_PROJECT_ROW = 'DELETE_PROJECT_ROW';

export const FETCH_DAY_PLANNER = createRequestTypes('FETCH_DAY_PLANNER');
export const UPDATE_DAY_PLANNER = createRequestTypes('UPDATE_DAY_PLANNER');

export const FETCH_PLANNERS = createRequestTypes('FETCH_PLANNERS');

export const BULK_EDIT_TASKS = createRequestTypes('BULK_EDIT_TASKS');
export const BULK_EDIT_HOME_TASKS = createRequestTypes('BULK_EDIT_HOME_TASKS');

// home screen / batch tasks
export const UPDATE_TASKS_ATTRIBUTES = createRequestTypes(
  'UPDATE_TASKS_ATTRIBUTES'
);

export const CREATE_TASK_FROM_HOME = createRequestTypes(
  'CREATE_TASK_FROM_HOME'
);
export const MOVE_TASKS = createRequestTypes('MOVE_TASKS');
export const COPY_TASKS = createRequestTypes('COPY_TASKS');

export const QUERY_AI = createRequestTypes('QUERY_AI');

export const CREATE_TASK_AND_OPEN_MODAL = 'CREATE_TASK_AND_OPEN_MODAL';

export const UPDATE_SERVER_TASK_ORDER = createRequestTypes(
  'UPDATE_SERVER_TASK_ORDER'
);

export const BATCH_DELETE_TASKS = createRequestTypes('BATCH_DELETE_TASKS');
export const BATCH_COMPLETE_TASKS = createRequestTypes('BATCH_COMPLETE_TASKS');
export const BATCH_REQUEST_TASKS_FOLLOW_UP = createRequestTypes(
  'BATCH_REQUEST_TASKS_FOLLOW_UP'
);
export const BATCH_MOVE_TASKS_TO_PROJECT = createRequestTypes(
  'BATCH_MOVE_TASKS_TO_PROJECT'
);
export const BATCH_COPY_TASKS_TO_PROJECT = createRequestTypes(
  'BATCH_COPY_TASKS_TO_PROJECT'
);
export const BATCH_REVERT_TASKS_TO_INCOMPLETE = createRequestTypes(
  'BATCH_REVERT_TASKS_TO_INCOMPLETE'
);

export const BATCH_MOVE_INBOX_TASKS = createRequestTypes(
  'BATCH_MOVE_INBOX_TASKS'
);

export const BATCH_UNFOLLOW_TASKS = createRequestTypes('BATCH_UNFOLLOW_TASKS');
export const UPDATE_HOME_TASK_SORT_ORDER = 'UPDATE_HOME_TASK_SORT_ORDER';

export const TASK_LIST_LOADING_INITIAL_TASKS = createRequestTypes(
  'TASK_LIST_LOADING_INITIAL_TASKS'
);

export const TASK_LIST_LOADING_ADDITIONAL_TASKS = createRequestTypes(
  'TASK_LIST_LOADING_ADDITIONAL_TASKS'
);

export const UPDATE_CLIENT_TASK_ORDER = createRequestTypes(
  'UPDATE_CLIENT_TASK_ORDER'
);

export const TOGGLE_TASK_CREATION = 'TOGGLE_TASK_CREATION';
export const UPDATE_TASK_HOME_POSITION = 'UPDATE_TASK_HOME_POSITION';

export const INSERT_TASK_BEFORE = 'INSERT_TASK_BEFORE';
export const MOVE_TASK_TO_GROUP = 'MOVE_TASK_TO_GROUP';
export const ADD_TASK_TO_GROUP = 'ADD_TASK_TO_GROUP';
export const REMOVE_TASK_FROM_GROUP = 'REMOVE_TASK_FROM_GROUP';

export const REFRESH_CURRENTLY_SELECTED_HOME_TASKS = createRequestTypes(
  'REFRESH_CURRENTLY_SELECTED_HOME_TASKS'
);

export const UPDATE_TASKS_HOME_POSITION_ON_API = createRequestTypes(
  'UPDATE_TASKS_HOME_POSITION_ON_API'
);

export const UPDATE_TASK_HOME_POSITION_ON_CLIENT =
  'UPDATE_TASK_HOME_POSITION_ON_CLIENT';

export const FLUSH_TASK_LIST_STORE = 'FLUSH_TASK_LIST_STORE';

export const HANDLE_HOME_TASK_COMPLETION_TOGGLE =
  'HANDLE_HOME_TASK_COMPLETION_TOGGLE';

export const HANDLE_TASK_GROUPS_TASK_COMPLETION_TOGGLE =
  'HANDLE_TASK_GROUPS_TASK_COMPLETION_TOGGLE';

export const SET_SELECTED_HOME_TASK = 'SET_SELECTED_HOME_TASK';
export const FLUSH_SELECTED_HOME_TASK = 'FLUSH_SELECTED_HOME_TASK';

export const FETCH_ALL_HOME_TASK_TAGS = createRequestTypes(
  'FETCH_ALL_HOME_TASK_TAGS'
);

export const POST_TASK_COMMENT = createRequestTypes('POST_TASK_COMMENT');

export const PATCH_ACCOUNT_DEFAULT_FOLLOW_UP_MESSAGE = createRequestTypes(
  'PATCH_ACCOUNT_DEFAULT_FOLLOW_UP_MESSAGE'
);

export const TASK_LIST_FILTER_CHANGE = 'TASK_LIST_FILTER_CHANGE';

export const OPEN_ACTIVITY_ROW_DROPDOWN = 'OPEN_ACTIVITY_ROW_DROPDOWN';
export const CLOSE_ACTIVITY_ROW_DROPDOWN = 'CLOSE_ACTIVITY_ROW_DROPDOWN';
export const OPEN_ADD_EDIT_ACTIVITY = 'OPEN_ADD_EDIT_ACTIVITY';
export const CLOSE_ADD_EDIT_ACTIVITY = 'CLOSE_ADD_EDIT_ACTIVITY';
export const OPEN_ADD_EDIT_DESCRIPTION = 'OPEN_ADD_EDIT_DESCRIPTION';
export const CLOSE_ADD_EDIT_DESCRIPTION = 'CLOSE_ADD_EDIT_DESCRIPTION';
export const OPEN_ADD_ACTIVITY_ROW_MENU = 'OPEN_ADD_ACTIVITY_ROW_MENU';
export const CLOSE_ADD_ACTIVITY_ROW_MENU = 'CLOSE_ADD_ACTIVITY_ROW_MENU';
export const OPEN_EDIT_ACTIVITY_ROW_MENU = 'OPEN_EDIT_ACTIVITY_ROW_MENU';
export const CLOSE_EDIT_ACTIVITY_ROW_MENU = 'CLOSE_EDIT_ACTIVITY_ROW_MENU';
export const UPDATE_ACTIVITY_ROW_DESCRIPTION =
  'UPDATE_ACTIVITY_ROW_DESCRIPTION';

export const FETCH_USER_ACTIVITIES = createRequestTypes(
  'FETCH_USER_ACTIVITIES'
);
export const CREATE_TIMESHEET = createRequestTypes('CREATE_TIMESHEET');
export const CREATE_QB_TIMESHEET = createRequestTypes('CREATE_QB_TIMESHEET');
export const UPDATE_TIMESHEET = createRequestTypes('UPDATE_TIMESHEET');
export const BULK_UPDATE_TIMESHEETS = createRequestTypes(
  'BULK_UPDATE_TIMESHEETS'
);
export const UPDATE_QB_TIMESHEET = createRequestTypes('UPDATE_QB_TIMESHEET');
export const DELETE_TIMESHEETS = createRequestTypes('DELETE_TIMESHEETS');
export const DELETE_QB_TIMESHEET = createRequestTypes('DELETE_QB_TIMESHEET');
export const SUBMIT_DATES = 'SUBMIT_DATES';
export const SET_SUBMISSION_STATUS = 'SET_SUBMISSION_STATUS';
export const DELETE_ACTIVITY_ROW = 'DELETE_ACTIVITY_ROW';
export const DELETE_ACTIVITY_ROW_ASK = 'DELETE_ACTIVITY_ROW_ASK';
export const DELETE_ACTIVITY_ROW_CANCEL = 'DELETE_ACTIVITY_ROW_CANCEL';
export const FETCH_ACTIVITIES = createRequestTypes('FETCH_ACTIVITIES');
export const FETCH_DESCRIPTIONS = createRequestTypes('FETCH_DESCRIPTIONS');
export const FETCH_TIMESHEETS = createRequestTypes('FETCH_TIMESHEETS');
export const FETCH_TIMESHEETS_INDEX = createRequestTypes(
  'FETCH_TIMESHEETS_INDEX'
);
export const TIME_ENTRY_CREATE_UPDATE_ERROR = 'TIME_ENTRY_CREATE_UPDATE_ERROR';
export const CLEAR_TIME_ENTRY_ERROR = 'CLEAR_TIME_ENTRY_ERROR';
export const FETCH_TIMESHEETS_UTILIZATION = createRequestTypes(
  'FETCH_TIMESHEETS_UTILIZATION'
);
export const PROCESS_TIME_ENTRIES_FOR_QUICKBOOKS = createRequestTypes(
  'PROCESS_TIME_ENTRIES_FOR_QUICKBOOKS'
);
export const EXPORT_TIMESHEETS = createRequestTypes('EXPORT_TIMESHEETS');
export const MOUNT_TIMESHEET = 'MOUNT_TIMESHEET';
export const BATCH_SELECT_TIMESHEETS = 'BATCH_SELECT_TIMESHEETS';
export const CLEAR_BATCH_SELECTED_TIMESHEETS =
  'CLEAR_BATCH_SELECTED_TIMESHEETS';
export const SORT_TIMESHEET_REPORT = 'SORT_TIMESHEET_REPORT';
export const SET_TIMESHEET_REPORT_DATE_RANGE =
  'SET_TIMESHEET_REPORT_DATE_RANGE';
export const SET_TIMESHEET_REMINDER_DAY = 'SET_TIMESHEET_REMINDER_DAY';
export const SET_TIMESHEET_REMINDER_SENDER_ID =
  'SET_TIMESHEET_REMINDER_SENDER_ID';
export const SET_IS_ADDING_NEW_TIMESHEET_ROW =
  'SET_IS_ADDING_NEW_TIMESHEET_ROW';
export const CLEAR_IS_ADDING_NEW_TIMESHEET_ROW =
  'CLEAR_IS_ADDING_NEW_TIMESHEET_ROW';
export const UPDATE_CREATE_ROW_DATA = 'UPDATE_CREATE_ROW_DATA';
export const SHOW_TOTALS = 'SHOW_TOTALS';
export const HIDE_TOTALS = 'HIDE_TOTALS';

export const SELECT_SUBMISSION_DATES = 'SELECT_SUBMISSION_DATES';
export const UNSELECT_SUBMISSION_DATES = 'UNSELECT_SUBMISSION_DATES';
export const FLUSH_SUBMISSION_DATES = 'FLUSH_SUBMISSION_DATES';

export const SET_SHOW_TIMESHEET_MOVED_MESSAGE =
  'SET_SHOW_TIMESHEET_MOVED_MESSAGE';
export const SET_SHOW_UPDATES_MOVED_MESSAGE = 'SET_SHOW_UPDATES_MOVED_MESSAGE';

export const FLUSH_ACTIVITIES = 'FLUSH_ACTIVITIES';
export const FLUSH_TIMESHEETS = 'FLUSH_TIMESHEETS';
export const FLUSH_SCHEDULE_BARS = 'FLUSH_SCHEDULE_BARS';
export const FLUSH_DESCRIPTIONS = 'FLUSH_DESCRIPTIONS';
export const FLUSH_USER_ACTIVITIES = 'FLUSH_USER_ACTIVITIES';
export const CREATE_ACTIVITY = createRequestTypes('CREATE_ACTIVITY');
export const UPDATE_ACTIVITY = createRequestTypes('UPDATE_ACTIVITY');
export const DELETE_ACTIVITY = createRequestTypes('DELETE_ACTIVITY');
export const FETCH_ACTIVITY_MEMBERS = createRequestTypes(
  'FETCH_ACTIVITY_MEMBERS'
);
export const CREATE_ACTIVITY_MEMBERS = createRequestTypes(
  'CREATE_ACTIVITY_MEMBERS'
);
export const ARCHIVE_ACTIVITY_MEMBER = createRequestTypes(
  'ARCHIVE_ACTIVITY_MEMBER'
);
export const CREATE_DESCRIPTION = createRequestTypes('CREATE_DESCRIPTION');
export const UPDATE_DESCRIPTION = createRequestTypes('UPDATE_DESCRIPTION');
export const DELETE_DESCRIPTIONS = createRequestTypes('DELETE_DESCRIPTIONS');

export const OPEN_FLAGGED_TASKS_MODAL = 'OPEN_FLAGGED_TASKS_MODAL';
export const CLOSE_PAST_DUE_TASK_MODAL = 'CLOSE_PAST_DUE_TASK_MODAL';
export const CLOSE_PAST_SCHEDULED_TASK_MODAL =
  'CLOSE_PAST_SCHEDULED_TASK_MODAL';
export const CLOSE_HOME_TASK_MODAL = 'CLOSE_HOME_TASK_MODAL';

export const OPEN_COLOR_PICKER = 'OPEN_COLOR_PICKER';
export const CLOSE_COLOR_PICKER = 'CLOSE_COLOR_PICKER';

export const FETCH_PROJECT_COLOR_PREFERENCES = createRequestTypes(
  'FETCH_PROJECT_COLOR_PREFERENCES'
);

export const CREATE_PROJECT_COLOR_PREFERENCE = createRequestTypes(
  'CREATE_PROJECT_COLOR_PREFERENCE'
);
export const UPDATE_PROJECT_COLOR_PREFERENCE = createRequestTypes(
  'UPDATE_PROJECT_COLOR_PREFERENCE'
);
export const DELETE_PROJECT_COLOR_PREFERENCE = createRequestTypes(
  'DELETE_PROJECT_COLOR_PREFERENCE'
);

export const FETCH_BOARD_PREFERENCES = createRequestTypes(
  'FETCH_BOARD_PREFERENCES'
);

export const CREATE_BOARD_PREFERENCE = createRequestTypes(
  'CREATE_BOARD_PREFERENCE'
);
export const UPDATE_BOARD_PREFERENCE = createRequestTypes(
  'UPDATE_BOARD_PREFERENCE'
);
export const DELETE_BOARD_PREFERENCE = createRequestTypes(
  'DELETE_BOARD_PREFERENCE'
);
export const FETCH_TASK_GROUP_PREFERENCES = createRequestTypes(
  'FETCH_TASK_GROUP_PREFERENCES'
);

export const CREATE_TASK_GROUP_PREFERENCE = createRequestTypes(
  'CREATE_TASK_GROUP_PREFERENCE'
);
export const UPDATE_TASK_GROUP_PREFERENCE = createRequestTypes(
  'UPDATE_TASK_GROUP_PREFERENCE'
);
export const DELETE_TASK_GROUP_PREFERENCE = createRequestTypes(
  'DELETE_TASK_GROUP_PREFERENCE'
);

export const FETCH_TEAM_MEMBERSHIP_PREFERENCES = createRequestTypes(
  'FETCH_TEAM_MEMBERSHIP_PREFERENCES'
);

export const CREATE_TEAM_MEMBERSHIP_PREFERENCE = createRequestTypes(
  'CREATE_TEAM_MEMBERSHIP_PREFERENCE'
);
export const UPDATE_TEAM_MEMBERSHIP_PREFERENCE = createRequestTypes(
  'UPDATE_TEAM_MEMBERSHIP_PREFERENCE'
);
export const DELETE_TEAM_MEMBERSHIP_PREFERENCE = createRequestTypes(
  'DELETE_TEAM_MEMBERSHIP_PREFERENCE'
);

export const FETCH_TEAM_PREFERENCES = createRequestTypes(
  'FETCH_TEAM_PREFERENCES'
);

export const CREATE_TEAM_PREFERENCE = createRequestTypes(
  'CREATE_TEAM_PREFERENCE'
);
export const UPDATE_TEAM_PREFERENCE = createRequestTypes(
  'UPDATE_TEAM_PREFERENCE'
);
export const DELETE_TEAM_PREFERENCE = createRequestTypes(
  'DELETE_TEAM_PREFERENCE'
);

export const FETCH_TAG_PREFERENCES = createRequestTypes(
  'FETCH_TAG_PREFERENCES'
);

export const CREATE_TAG_PREFERENCE = createRequestTypes(
  'CREATE_TAG_PREFERENCE'
);
export const UPDATE_TAG_PREFERENCE = createRequestTypes(
  'UPDATE_TAG_PREFERENCE'
);
export const DELETE_TAG_PREFERENCE = createRequestTypes(
  'DELETE_TAG_PREFERENCE'
);

export const SET_HOME_TASK_EDIT_PROPERTY = 'SET_HOME_TASK_EDIT_PROPERTY';
export const UPDATE_PROJECT_MODULES = createRequestTypes(
  'UPDATE_PROJECT_MODULES'
);
export const UPDATE_BOARD_MODULES = createRequestTypes('UPDATE_BOARD_MODULES');

export const SET_FROM_BOARD_VIEW_NAV_TEXT = 'SET_FROM_BOARD_VIEW_NAV_TEXT';
export const CLEAR_FROM_BOARD_VIEW_NAV_TEXT = 'CLEAR_FROM_BOARD_VIEW_NAV_TEXT';

export const TOGGLE_BATCH_SELECTED_TASKS = 'TOGGLE_BATCH_SELECTED_TASKS';
export const SET_BATCH_SELECTED_TASKS = 'SET_BATCH_SELECTED_TASKS';
export const CLEAR_BATCH_SELECTED_TASKS = 'CLEAR_BATCH_SELECTED_TASKS';
export const FLUSH_BATCH_SELECTED_TASKS = 'FLUSH_BATCH_SELECTED_TASKS';

export const OPEN_WORKLOAD_EVENTS_MODAL = 'OPEN_WORKLOAD_EVENTS_MODAL';
export const CLOSE_WORKLOAD_EVENTS_MODAL = 'CLOSE_WORKLOAD_EVENTS_MODAL';
export const SET_SELECTED_WORKLOAD_EVENT = 'SET_SELECTED_WORKLOAD_EVENT';
export const SET_WORKLOAD_EVENT_RANGE = 'SET_WORKLOAD_EVENT_RANGE';
export const BATCH_SELECT_WORKLOAD_EVENT_RECIPIENTS =
  'BATCH_SELECT_WORKLOAD_EVENT_RECIPIENTS';
export const CLEAR_BATCH_SELECTED_WORKLOAD_EVENT_RECIPIENTS =
  'CLEAR_BATCH_SELECTED_WORKLOAD_EVENT_RECIPIENTS';

export const CREATE_WORKLOAD_PLANNER_EVENT = createRequestTypes(
  'CREATE_WORKLOAD_PLANNER_EVENT'
);

export const CREATE_NOTIFICATION_PREVIEW = createRequestTypes(
  'CREATE_NOTIFICATION_PREVIEW'
);
export const SEND_WORKLOAD_PLANNER_EVENT = createRequestTypes(
  'SEND_WORKLOAD_PLANNER_EVENT'
);
export const FETCH_WORKLOAD_PLANNER_EVENTS = createRequestTypes(
  'FETCH_WORKLOAD_PLANNER_EVENTS'
);
export const FETCH_WORKLOAD_PLANNER_EVENT = createRequestTypes(
  'FETCH_WORKLOAD_PLANNER_EVENT'
);
export const UPDATE_WORKLOAD_PLANNER_EVENT = createRequestTypes(
  'UPDATE_WORKLOAD_PLANNER_EVENT'
);
export const DELETE_WORKLOAD_PLANNER_EVENT = createRequestTypes(
  'DELETE_WORKLOAD_PLANNER_EVENT'
);
export const FETCH_WORKLOAD_PLANNER_EVENT_LAST_SENT = createRequestTypes(
  'FETCH_WORKLOAD_PLANNER_EVENT_LAST_SENT'
);

export const OPEN_WORKLOAD_MODAL = 'OPEN_WORKLOAD_MODAL';
export const CLOSE_WORKLOAD_MODAL = 'CLOSE_WORKLOAD_MODAL';
export const OPEN_WORKLOAD_MEMBER_ROW = 'OPEN_WORKLOAD_MEMBER_ROW';
export const CLOSE_WORKLOAD_MEMBER_ROW = 'CLOSE_WORKLOAD_MEMBER_ROW';
export const OPEN_ALL_WORKLOAD_MEMBER_ROWS = 'OPEN_ALL_WORKLOAD_MEMBER_ROWS';
export const CLOSE_ALL_WORKLOAD_MEMBER_ROWS = 'CLOSE_ALL_WORKLOAD_MEMBER_ROWS';
export const OPEN_WORKLOAD_PROJECT_ROW = 'OPEN_WORKLOAD_PROJECT_ROW';
export const CLOSE_WORKLOAD_PROJECT_ROW = 'CLOSE_WORKLOAD_PROJECT_ROW';
export const OPEN_ALL_WORKLOAD_PROJECT_ROWS = 'OPEN_ALL_WORKLOAD_PROJECT_ROWS';
export const CLOSE_ALL_WORKLOAD_PROJECT_ROWS =
  'CLOSE_ALL_WORKLOAD_PROJECT_ROWS';

export const OPEN_PLANNER_BAR_MODAL = 'OPEN_PLANNER_BAR_MODAL';
export const CLOSE_PLANNER_BAR_MODAL = 'CLOSE_PLANNER_BAR_MODAL';
export const PLANNER_BAR_MODAL_CLOSED = 'PLANNER_BAR_MODAL_CLOSED';

export const OPEN_WORKLOAD_FILTER = `OPEN_WORKLOAD_FILTER`;
export const CLOSE_WORKLOAD_FILTER = `CLOSE_WORKLOAD_FILTER`;
export const SET_WORKLOAD_FILTER = `SET_WORKLOAD_FILTER`;
export const CLEAR_WORKLOAD_FILTER = `CLEAR_WORKLOAD_FILTER`;
export const SET_WORKLOAD_VIEW_BY = 'SET_WORKLOAD_VIEW_BY';
export const SET_WORKLOAD_VIEW_TYPE = 'SET_WORKLOAD_VIEW_TYPE';
export const SET_CONDENSED_ZOOM_LEVEL = 'SET_CONDENSED_ZOOM_LEVEL';

export const SET_TIMELINE_VIEW_BY = 'SET_TIMELINE_VIEW_BY';
export const SET_SCHEDULE_VIEW_BY = 'SET_SCHEDULE_VIEW_BY';
export const SET_TIMESHEET_VIEW_BY = 'SET_TIMESHEET_VIEW_BY';
export const SET_UTILIZATION_VIEW_BY = 'SET_UTILIZATION_VIEW_BY';
export const SCROLL_TIMESHEET = 'SCROLL_TIMESHEET';
export const TOGGLE_TASK_SIDEBAR_IS_OPEN = 'TOGGLE_TASK_SIDEBAR_IS_OPEN';
export const SET_ZOOM = 'SET_ZOOM';

export const DENY_PERMISSION = 'DENY_PERMISSION';
export const HANDLE_ERROR_MESSAGE = 'HANDLE_ERROR_MESSAGE';
export const CLEAR_DENY_PERMISSION = 'CLEAR_DENY_PERMISSION';
export const FETCH_USER_PERMISSIONS = createRequestTypes(
  'FETCH_USER_PERMISSIONS'
);

export const REMOVE_PROJECT_SUBSCRIPTIONS = 'REMOVE_PROJECT_SUBSCRIPTIONS';
export const REMOVE_BOARD_SUBSCRIPTIONS = 'REMOVE_BOARD_SUBSCRIPTIONS';
export const REMOVE_WORKLOAD_SUBSCRIPTIONS = 'REMOVE_WORKLOAD_SUBSCRIPTIONS';

export const SUBSCRIBE_WORKLOAD = 'SUBSCRIBE_WORKLOAD';
export const WS_TASK = 'WS_TASK';
export const WS_TASK_COMMENT = 'WS_TASK_COMMENT';
export const WS_PROJECT_COMMENT = 'WS_PROJECT_COMMENT';
export const WS_PROJECT_NOTE = 'WS_PROJECT_NOTE';
export const WS_PROJECT_MEMBER = 'WS_PROJECT_MEMBER';
export const WS_ACCOUNT = 'WS_ACCOUNT';
export const WS_TEAM_MEMBER = 'WS_TEAM_MEMBER';
export const WS_SCHEDULE_BAR = 'WS_SCHEDULE_BAR';
export const WS_TEAM_POSITION = 'WS_TEAM_POSITION';
export const WS_POSITION = 'WS_POSITION';
export const WS_TEAM_MEMBER_SKILL = 'WS_TEAM_MEMBER_SKILL';
export const WS_TEAM_SKILL = 'WS_TEAM_SKILL';
export const WS_WORK_GROUP_MEMBERSHIP = 'WS_WORK_GROUP_MEMBERSHIP';
export const WS_HOLIDAY = 'WS_HOLIDAY';
export const WS_PHASE = 'WS_PHASE';
export const WS_SCHEDULED_EVENT = 'WS_SCHEDULED_EVENT';
export const WS_PROJECT = 'WS_PROJECT';
export const OPEN_MODULES_MODAL = 'OPEN_MODULES_MODAL';
export const CLOSE_MODULES_MODAL = 'CLOSE_MODULES_MODAL';

export const FETCH_ACCOUNT_FILTERS = createRequestTypes(
  'FETCH_ACCOUNT_FILTERS'
);
export const FETCH_ACCOUNT_FILTER = createRequestTypes('FETCH_ACCOUNT_FILTER');

export const CREATE_ACCOUNT_FILTER = createRequestTypes(
  'CREATE_ACCOUNT_FILTER'
);
export const UPDATE_ACCOUNT_FILTER = createRequestTypes(
  'UPDATE_ACCOUNT_FILTER'
);
export const FETCH_FILTER_BY_IDS = createRequestTypes('FETCH_FILTER_BY_IDS');

export const UPDATE_ACCOUNT_FILTER_LOCAL = 'UPDATE_ACCOUNT_FILTER_LOCAL';
export const RESET_ACCOUNT_FILTER_LOCAL = 'RESET_ACCOUNT_FILTER_LOCAL';
export const CLEAR_ACCOUNT_FILTERS_LOCAL = 'CLEAR_ACCOUNT_FILTERS_LOCAL';
export const DELETE_ACCOUNT_FILTER = createRequestTypes(
  'DELETE_ACCOUNT_FILTER'
);
export const OPEN_TIMESHEET_ACTIVITY_MODAL_FOR_DAY =
  'OPEN_TIMESHEET_ACTIVITY_MODAL_FOR_DAY';
export const CLOSE_TIMESHEET_ACTIVITY_MODAL_FOR_DAY =
  'CLOSE_TIMESHEET_ACTIVITY_MODAL_FOR_DAY';
export const DOWNLOAD_URL = 'DOWNLOAD_URL';
export const GENERATE_REPORT = createRequestTypes('GENERATE_REPORT');

export const SORT_BUDGET_SETTINGS = 'SORT_BUDGET_SETTINGS';
export const CHECK_HAS_TIME_ENTRIES = createRequestTypes(
  'CHECK_HAS_TIME_ENTRIES'
);

export const FETCH_REMAINING_TIMESHEET_HOURS = createRequestTypes(
  'FETCH_REMAINING_TIMESHEET_HOURS'
);

export const FLUSH_HAS_TIME_ENTRIES = 'FLUSH_HAS_TIME_ENTRIES';
export const FETCH_TIMESHEETS_PREDICTIONS = createRequestTypes(
  'FETCH_TIMESHEETS_PREDICTIONS'
);

export const OPEN_TIMESHEET_SETTINGS_MODAL = 'OPEN_TIMESHEET_SETTINGS_MODAL';
export const CLOSE_TIMESHEET_SETTINGS_MODAL = 'CLOSE_TIMESHEET_SETTINGS_MODAL';

export const OPEN_TIMESHEET_INCOMPLETE_MODAL =
  'OPEN_TIMESHEET_INCOMPLETE_MODAL';
export const CLOSE_TIMESHEET_INCOMPLETE_MODAL =
  'CLOSE_TIMESHEET_INCOMPLETE_MODAL';

export const OPEN_SEND_REMINDER_MODAL = 'OPEN_SEND_REMINDER_MODAL ';
export const CLOSE_SEND_REMINDER_MODAL = 'CLOSE_SEND_REMINDER_MODAL ';
export const SET_INCOMPLETE_TIMESHEET_DATES = 'SET_INCOMPLETE_TIMESHEET_DATES ';

export const FETCH_FILTERED_USER_ACTIVITIES_FOR_PROJECTS = createRequestTypes(
  'FETCH_FILTERED_USER_ACTIVITIES_FOR_PROJECTS'
);
export const SELECT_PROJECTS = 'SELECT_PROJECTS';
export const UNSELECT_PROJECT = 'UNSELECT_PROJECT';
export const SELECT_ALL_PROJECTS = 'SELECT_ALL_PROJECTS';
export const UNSELECT_ALL_PROJECTS = 'UNSELECT_ALL_PROJECTS';
export const MOVE_PROJECTS_TO_BOARD = createRequestTypes(
  'MOVE_PROJECTS_TO_BOARD'
);
export const CLONE_PROJECTS = createRequestTypes('CLONE_PROJECTS');
export const CLONE_PROJECT = createRequestTypes('CLONE_PROJECT');

export const FETCH_WORK_GROUPS = createRequestTypes('FETCH_WORK_GROUPS');
export const FETCH_WORK_GROUP = createRequestTypes('FETCH_WORK_GROUP');
export const CREATE_WORK_GROUP = createRequestTypes('CREATE_WORK_GROUP');
export const UPDATE_WORK_GROUP = createRequestTypes('UPDATE_WORK_GROUP');
export const ARCHIVE_WORK_GROUP = createRequestTypes('ARCHIVE_WORK_GROUP');
export const UNARCHIVE_WORK_GROUP = createRequestTypes('UNARCHIVE_WORK_GROUP');
export const DELETE_WORK_GROUP = createRequestTypes('DELETE_WORK_GROUP');
export const CREATE_WORK_GROUP_MEMBERSHIP = createRequestTypes(
  'CREATE_WORK_GROUP_MEMBERSHIP'
);
export const DELETE_WORK_GROUP_MEMBERSHIP = createRequestTypes(
  'DELETE_WORK_GROUP_MEMBERSHIP'
);

export const FETCH_CLIENTS = createRequestTypes('FETCH_CLIENTS');
export const FETCH_CLIENT = createRequestTypes('FETCH_CLIENT');
export const CREATE_CLIENT = createRequestTypes('CREATE_CLIENT');
export const UPDATE_CLIENT = createRequestTypes('UPDATE_CLIENT');
export const FETCH_USER_ACTIVITIES_FOR_ACTIONABLE = createRequestTypes(
  'FETCH_USER_ACTIVITIES_FOR_ACTIONABLE'
);
export const FETCH_ACTIVITY_LOG_USER_ACTIVITIES = createRequestTypes(
  'FETCH_ACTIVITY_LOG_USER_ACTIVITIES'
);
export const OPEN_ACTIVITY_LOG_MODAL = 'OPEN_ACTIVITY_LOG_MODAL';
export const CLOSE_ACTIVITY_LOG_MODAL = 'CLOSE_ACTIVITY_LOG_MODAL';
export const CLEAR_USER_ACTIVITIES = 'CLEAR_USER_ACTIVITIES';

export const FETCH_PROJECT_TOTALS = createRequestTypes('FETCH_PROJECT_TOTALS');
export const CLEAR_PROJECT_TOTALS = 'CLEAR_PROJECT_TOTALS';

export const LOAD_CSV_IMPORT_DATA = createRequestTypes('LOAD_CSV_IMPORT_DATA');
export const CLEAR_CSV_IMPORT_DATA = 'CLEAR_CSV_IMPORT_DATA';

export const FETCH_PRIORITIES = createRequestTypes('FETCH_PRIORITIES');

export const CHAIN_ACTIONS = 'CHAIN_ACTIONS';

export const APP_CUES = {
  HOME_PAGE_APP_CUES: '-M1XG3rpz2jSP5xa0UWY',
  TEAM_PAGE_APP_CUES: '-M1aGPYuvBPyeGPqQOuf',
  TIMESHEET_PAGE_APP_CUES: '-M1ajGfAP0v-REz3x5cb',
  WORKLOAD_PAGE_APP_CUES: '-M25Q63AaoGDHgfVOFdR',
  MEMBERS_PAGE_APP_CUES: '-M36Ue1RgCJwpouqQEi8',
  ACTIVITY_FEED_PAGE_APP_CUES: '-M3wSDRmp4nfMJ-W8RQC',
  SCHEDULE_PAGE_APP_CUES: '1a33b173-620e-4ca7-8f8b-d811454527bd',
  PDV_BUDGET_FLOW: '-M2B3gu5tZRfyMdfGkmS',
  PDV_SHOW_FLOW: '-M25TvCC1fe73TnGDX--',
  MEMBERS_SETTINGS: 'eb18b24d-a13c-476c-a21c-b939485e5b34',
  HOME_NOTIFICATIONS: 'dc69e7f2-f659-4f41-808f-ce96471b34e9',
  HOME_COMPLETED: '859741f7-a99f-48d0-b95e-31c483ca486a',
  REPORT_TIMESHEET_APP_CUES: 'cb95b6f3-0afa-4319-ae1e-f3c3a0b26e1f',
  REPORT_TIMESHEET_STATUS_APP_CUES: '4b03198b-7746-4daa-95b5-4d1d97ed5283',
  REPORT_BUDGET_APP_CUES: 'a0159509-bbd4-475c-98f7-9db59c21e286',
  REPORT_WORKLOAD_APP_CUES: '9dc4095a-dda8-4094-9d13-a37a490c4bd4',
  REPORT_ACTIVITY_APP_CUES: '743f7eb2-d062-4437-bdd3-d296cba053a1',
  REPORT_PROFIT_APP_CUES: 'e48a9eee-361e-4d42-a60b-61cbaa0e773c',
  REPORT_VARIANCE_APP_CUES: '7ecec56a-4506-4949-8721-fc77dadf1cb4',
  REPORT_UTILIZATION_APP_CUES: '8224b061-8742-4992-8b01-a3b1e39d0000'
};

export const INTRO_VIDEOS_FLOW_ID = {
  TIME_SHEET_REPORT_VIDEO: 'a0741c1d-476c-4d06-9b1d-42f7746f54c9',
  TIME_SHEET_STATUS_REPORT_VIDEO: '9f1eefff-4957-4673-8222-c8fd8fa6c11b',
  ORGANIZATION_SETTINGS_VIDEO: '9030f2a6-4aef-4bca-b24d-b07ae054b7c1',
  BUDGET_REPORT_VIDEO: 'ded02370-4632-4258-a7e6-11241858c033',
  VARIANCE_REPORT_VIDEO: '062bc4b4-12c9-461e-a70b-34638f19ab39',
  BUDGET_WALKTHROUGH_VIDEO: '7d1f5075-cdce-4fc3-9026-d0d51b61b240',
  WORKLOAD_REPORT_VIDEO: 'b3f5f813-ba00-4521-a66e-1caf4afb878a',
  COST_RATE_BILL_RATE_VIDEO: '73d2fec0-0ba8-44c6-baad-6192fdd5e691',
  WORKLOAD_PROJECTS_VIDEO: 'b39a0f95-6d00-4d27-9780-114e1fb8cdbf',
  WORKLOAD_MEMBERS_VIDEO: '8c9b8547-3abc-48c2-842a-be1cb7471ad8',
  SELECT_INTEGRATION_HELP_VIDEO: '5b2dcb0f-539b-4070-93b2-60e6ee36aac9',
  IMPORTING_OR_LINKING_DATA_INTEGRATION_HELP_VIDEO: '81477c3b-4396-4719-ac28-504f9c3cef92',
  PROJECT_OVERVIEW_HELP_VIDEO: 'f2723911-31dc-4a74-8d50-412166d1e0d7',
  HOME_HELP_VIDEO: 'd222fe7c-b25b-40bd-9c9e-2a682d85572d',
  TEAMS_HELP_VIDEO: '0ebc77e3-8103-474f-b9c3-5e4e8bf29d73',
  TIMESHEET_HELP_VIDEO: '4630e61c-0e44-41de-92cf-7961be2c110e',
  TIMESHEET_ACTIVITY_HELP_VIDEO: '451737c5-4733-46ad-ba5d-0b3e5102b2eb',
  PORTFOLIO_HELP_VIDEO: '8d56deb2-8509-49b8-a3e8-6767ce381b7b',
  DASHBOARD_VIDEO: 'cc206a01-2a4d-44bb-9dbf-df320be8d4e2',
  MEMBERS_SETTINGS_VIDEO: 'e5744c7a-7305-40d6-8ee4-f2f7812a31ca',
  PLANNER_VIDEO: 'b39a0f95-6d00-4d27-9780-114e1fb8cdbf'
};

export const PERSONAL_BOARD_NAMES = {
  Home: 'Personal',
  'Starred Projects': 'Favorites',
  'UnStarred Projects': 'My Projects'
};

export const RATE_TYPES = {
  COST_RATE: 'Cost Rate',
  BILL_RATE: 'Bill Rate'
};

export const RATE_MODAL_TYPES = {
  ...RATE_TYPES,
  UNASSIGNED: 'Unassigned Rate/Hr'
};
export const RATE_MODAL_COLUMNS = {
  DELETE: { name: 'delete', label: '' },
  POSITION: { name: 'role', label: 'Role' },
  DESCRIPTION: { name: 'role', label: 'Description' },
  RATE: { name: 'rate', label: 'Rate/Hr' },
  MULTIPLIER: { name: 'multiplier', label: 'Multiplier' },
  START_DATE: { name: 'start-date', label: 'Start' },
  DATE_SEPARATOR: { name: 'date-separator', label: '' },
  END_DATE: { name: 'end-date', label: 'End' },
  PADDING: { name: 'padding', label: '' }
};
export const RATE_MODAL_ADD_ROW_LABEL = {
  [RATE_MODAL_TYPES.COST_RATE]: 'Add New Cost Rate',
  [RATE_MODAL_TYPES.BILL_RATE]: '+ Add New'
};

export const BUDGET_RECORD_DATA_TYPES = {
  PROJECT_DAILY: 'ProjectDaily',
  ACCOUNT_DAILY: 'AccountDaily',
  ACCOUNT_PROJECT_DAILY: 'AccountProjectDaily',
  PHASE_DAILY: 'PhaseDaily',
  ACCOUNT_PHASE_DAILY: 'AccountPhaseDaily',
  ACTIVITY_PHASE_DAILY: 'ActivityPhaseDaily',
  ACCOUNT_ACTIVITY_PHASE_DAILY: 'AccountActivityPhaseDaily',
  // Timeless
  ACCOUNT: 'Account',
  PROJECT: 'Project',
  CLIENT: 'Client',
  CLIENT_PROJECT: 'ClientProject',
  CLIENT_PHASE: 'ClientPhase',
  CLIENT_PHASE_ACTIVITY: 'ClientPhaseActivity',
  PHASE: 'Phase',
  PHASE_ACTIVITY: 'PhaseActivity',
  ACCOUNT_PROJECT: 'AccountProject',
  ACCOUNT_PHASE: 'AccountPhase',
  ACCOUNT_PHASE_ACTIVITY: 'AccountPhaseActivity',
  ACCOUNT_POSITION: 'AccountPosition',
  ACCOUNT_POSITION_PROJECT: 'AccountPositionProject',
  ACCOUNT_POSITION_PHASE: 'AccountPositionPhase',
  ACCOUNT_POSITION_PHASE_ACTIVITY: 'AccountPositionPhaseActivity',
  POSITION: 'Position',
  POSITION_PROJECT: 'PositionProject',
  POSITION_PHASE: 'PositionPhase',
  POSITION_PHASE_ACTIVITY: 'PositionPhaseActivity',
  POSITION_ACCOUNT: 'PositionAccount'
};

export const DEFAULT_FEE_TARGET_PERCENTAGE = 100;
export const MAIN_PHASE_NAME = 'Main Project Phase';

export const REDUCER_NAMES = {
  CAPACITY_REPORT: 'capacityReport',
  SCOPES: 'scopes'
};

export const APP_OFFLINE_MESSAGE = `Something went wrong loading your data. Try again in a couple seconds.`;
