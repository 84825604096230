import { VIEW_BY, DEPENDENCY_STRINGS } from 'appConstants/workload';
import { AVAILABLE_OPTIONS } from 'components/Dependency/constants';
import {
  CalendarDependencyItemState,
  CalendarDependencyState,
  CalendarEndDependencyState,
  CalendarStartDependencyState,
  DependableType,
  DependencyEnds,
  DependencyEndsNone,
  DependencyInfoArrayItem,
  DependencyItemState,
  DependencySetterMap,
  DependencyType
} from 'components/Dependency/types';
import { Phase, PhaseId } from 'ProjectsModule/phases/models/phase';
import { DependencyInfoBEArrayItem } from 'models/dependency';

export const isStartDateDependency = (
  dependency: Nullable<DependencyType>
): dependency is CalendarStartDependencyState =>
  dependency === DEPENDENCY_STRINGS.START ||
  dependency === DEPENDENCY_STRINGS.END_TO_START;

export const isEndDateDependency = (
  dependency: Nullable<DependencyType>
): dependency is CalendarEndDependencyState =>
  dependency === DEPENDENCY_STRINGS.END ||
  dependency === DEPENDENCY_STRINGS.START_TO_END;

export const isLegacyStartAndEndDateDependency = (
  dependency: Nullable<DependencyType>
) => dependency === DEPENDENCY_STRINGS.START_AND_END;

export const isStartAndEndDateDependency = (
  dependency: CalendarDependencyState
) =>
  isStartDateDependency(dependency.start) &&
  isEndDateDependency(dependency.end);

export const isSetDependency = (
  dependency: Nullable<
    | DependencyEnds
    | CalendarDependencyState
    | CalendarDependencyItemState
    | DependencyItemState
    | undefined
  >
): dependency is Exclude<
  | DependencyEnds
  | CalendarDependencyState
  | CalendarDependencyItemState
  | DependencyItemState,
  DependencyEndsNone | null
> => !!dependency && dependency !== DEPENDENCY_STRINGS.NONE;

export const isSetStartOrEndDependency = (
  dependency: CalendarDependencyState
): boolean =>
  isSetDependency(dependency?.start) || isSetDependency(dependency?.end);

export const isSetStartAndEndDependency = (
  dependency: CalendarDependencyState
): boolean =>
  isSetDependency(dependency?.start) && isSetDependency(dependency?.end);

export const getHasDependency = (
  dependency: CalendarDependencyState | 'none'
) => dependency && dependency !== VIEW_BY.NONE;

export const clearOldDependencies = (
  dependencies: DependencyInfoBEArrayItem[],
  dependencyType: DependableType
): DependencyInfoArrayItem[] =>
  dependencies.map((dependency) => ({
    dependency_type: 'none',
    dependable_type: dependencyType,
    dependable_id: dependency.parent_id
  }));

export const updateDependencies = (
  newDependencyItems: CalendarDependencyItemState,
  dependencyType: DependableType,
  prevDependencies: DependencyInfoBEArrayItem[] | undefined = []
): DependencyInfoArrayItem[] =>
  prevDependencies
    .filter(
      (prevDependency) =>
        (isStartDateDependency(prevDependency.dependency_type) &&
          (!isSetDependency(newDependencyItems.start) ||
            !compareDependencies(
              newDependencyItems.start,
              prevDependencies
            ))) ||
        (isEndDateDependency(prevDependency.dependency_type) &&
          (!isSetDependency(newDependencyItems.end) ||
            !compareDependencies(newDependencyItems.end, prevDependencies)))
    )
    .map((prevDependency) => ({
      dependency_type: 'none',
      dependable_type: dependencyType,
      dependable_id: prevDependency.parent_id
    }));

export const compareDependencies = (newDependencies, prevDependencies) => {
  return newDependencies?.id === prevDependencies?.parent_id;
};

export const generateNewDependencyInfos = <
  TItem extends { dependencies: DependencyInfoBEArrayItem[] }
>(
  item: TItem,
  dependency: CalendarDependencyState | 'none',
  dependencyItem: CalendarDependencyItemState | undefined,
  dependencyType: DependableType
) => {
  const newDependencyInfos: DependencyInfoArrayItem[] = [];

  if (dependency === 'none' || !isSetStartOrEndDependency(dependency)) {
    const oldDependencies = item?.dependencies;

    // unset dependencies if they exist
    if (oldDependencies && oldDependencies.length > 0) {
      newDependencyInfos.push(
        ...clearOldDependencies(oldDependencies, dependencyType)
      );
    }
  } else if (isSetDependency(dependency) && isSetDependency(dependencyItem)) {
    // need to remove old dependency if necessary
    newDependencyInfos.push(
      ...updateDependencies(dependencyItem, dependencyType, item?.dependencies)
    );

    if (isSetDependency(dependency.start) && !!dependencyItem.start) {
      newDependencyInfos.push({
        dependency_type: dependency.start,
        dependable_type: dependencyType,
        dependable_id: dependencyItem.start.id || null
      });
    }

    if (isSetDependency(dependency.end) && !!dependencyItem.end) {
      newDependencyInfos.push({
        dependency_type: dependency.end,
        dependable_type: dependencyType,
        dependable_id: dependencyItem.end.id || null
      });
    }
  }

  return newDependencyInfos;
};

export const dependencyStateToSetterMapper = ({
  dependency,
  dependencyItem,
  dependencyItemType,
  hasStartEndDependency = false
}: {
  dependency: CalendarDependencyState;
  dependencyItem: CalendarDependencyItemState;
  dependencyItemType: DependableType;
  hasStartEndDependency: boolean;
}): DependencySetterMap[] => {
  // take in dependency, dependencyItem, dependencyItemType
  const mappedDependencies: DependencySetterMap[] = [];

  if (
    hasStartEndDependency &&
    dependency.start &&
    dependency.end &&
    dependencyItem.start &&
    dependencyItem.end
  ) {
    mappedDependencies.push({
      baseOption: AVAILABLE_OPTIONS.startEnd,
      dependableItem: {
        dependableId: dependencyItem.start.id,
        dependableType: dependencyItemType,
        label: dependencyItem.start.name,
        startDate: dependencyItem.start.start_date,
        endDate: dependencyItem.start.end_date
      },
      targetOption: AVAILABLE_OPTIONS.startEnd
    });
  } else {
    if (dependency.start && dependencyItem.start) {
      mappedDependencies.push({
        baseOption: AVAILABLE_OPTIONS.start,
        dependableItem: {
          dependableId: dependencyItem.start.id,
          dependableType: dependencyItemType,
          label: dependencyItem.start.name,
          startDate: dependencyItem.start.start_date,
          endDate: dependencyItem.start.end_date
        },
        targetOption:
          dependency.start === DEPENDENCY_STRINGS.START
            ? AVAILABLE_OPTIONS.start
            : AVAILABLE_OPTIONS.end
      });
    }

    if (dependency.end && dependencyItem.end) {
      mappedDependencies.push({
        baseOption: AVAILABLE_OPTIONS.end,
        dependableItem: {
          dependableId: dependencyItem.end.id,
          dependableType: dependencyItemType,
          label: dependencyItem.end.name,
          startDate: dependencyItem.end.start_date,
          endDate: dependencyItem.end.end_date
        },
        targetOption:
          dependency.end === DEPENDENCY_STRINGS.END
            ? AVAILABLE_OPTIONS.end
            : AVAILABLE_OPTIONS.start
      });
    }
  }

  return mappedDependencies;
};

export const dependencySetterToStateMapper = (
  dependencyArray: DependencySetterMap[]
): {
  dependency: CalendarDependencyState;
  dependencyItem: CalendarDependencyItemState;
  dependencyItemType: Nullable<DependableType>;
} => {
  const dependency: CalendarDependencyState = { start: null, end: null };
  const dependencyItem: CalendarDependencyItemState = {
    start: null,
    end: null
  };
  let dependencyItemType: Nullable<DependableType> = null;

  dependencyArray.forEach((dependencyArrayItem) => {
    const {
      dependableItem: {
        dependableId,
        label,
        dependableType,
        startDate,
        endDate
      }
    } = dependencyArrayItem;

    if (
      dependencyArrayItem.baseOption === AVAILABLE_OPTIONS.start ||
      dependencyArrayItem.baseOption === AVAILABLE_OPTIONS.startEnd
    ) {
      dependency.start =
        dependencyArrayItem.targetOption === AVAILABLE_OPTIONS.start ||
        dependencyArrayItem.targetOption === AVAILABLE_OPTIONS.startEnd
          ? DEPENDENCY_STRINGS.START
          : DEPENDENCY_STRINGS.END_TO_START;

      dependencyItem.start = {
        id: dependableId,
        name: label,
        start_date: startDate,
        end_date: endDate
      };

      dependencyItemType = dependableType;
    }

    if (
      dependencyArrayItem.baseOption === AVAILABLE_OPTIONS.end ||
      dependencyArrayItem.baseOption === AVAILABLE_OPTIONS.startEnd
    ) {
      dependency.end =
        dependencyArrayItem.targetOption === AVAILABLE_OPTIONS.end ||
        dependencyArrayItem.targetOption === AVAILABLE_OPTIONS.startEnd
          ? DEPENDENCY_STRINGS.END
          : DEPENDENCY_STRINGS.START_TO_END;

      dependencyItem.end = {
        id: dependableId,
        name: label,
        start_date: startDate,
        end_date: endDate
      };

      dependencyItemType = dependableType;
    }
  });

  return {
    dependency,
    dependencyItem,
    dependencyItemType
  };
};

// Map dependency array in phase entity to dependency and dependencyItem objects
export const mapNewPhaseDependencies = ({
  phase,
  phasesHash
}: {
  phase: Phase;
  phasesHash: Record<PhaseId, Phase>;
}) => {
  const initialDependencyItem: CalendarDependencyItemState = {
    start: null,
    end: null
  };
  const initialDependency: CalendarDependencyState = { start: null, end: null };

  // calendar widget and calendar tab on home/member modal don't need dependencies so they don't fetch them
  phase.dependencies?.forEach((dependency) => {
    if (isLegacyStartAndEndDateDependency(dependency.dependency_type)) {
      initialDependency.start = DEPENDENCY_STRINGS.START;
      initialDependencyItem.start = phasesHash[dependency.parent_id] ?? null;
      initialDependency.end = DEPENDENCY_STRINGS.END;
      initialDependencyItem.end = phasesHash[dependency.parent_id] ?? null;
    } else if (isStartDateDependency(dependency.dependency_type)) {
      initialDependency.start = dependency.dependency_type;
      initialDependencyItem.start = phasesHash[dependency.parent_id] ?? null;
    } else if (isEndDateDependency(dependency.dependency_type)) {
      initialDependency.end = dependency.dependency_type;
      initialDependencyItem.end = phasesHash[dependency.parent_id] ?? null;
    }
  });

  return { initialDependency, initialDependencyItem };
};
