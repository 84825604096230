import React from 'react';
import { makeDateFromDateTime } from 'appUtils/momentUtils';

import {
  TaskCalendarToggle,
  ToggleIconWrapper,
  ToggleTextWrapper
} from './styles/components';
import TaskDateUtilities from 'appUtils/TaskDateUtilities';

const TaskDatePickerInput = ({
  selectedDate,
  taskDateType,
  taskIsCreating,
  taskIsEditing,
  alwaysRenderInput,
  icon,
  bulk,
  currentFilter,
  taskEditProperty,
  isTaskCompleted,
  projectDetailView,
  isTaskModal,
  testIdPrefix
}) => {
  const generateTaskDateStatus = () => {
    if (!selectedDate || isTaskCompleted) return 'unassigned';
    if (
      (taskDateType === 'schedule' || taskDateType === 'schedule_start') &&
      TaskDateUtilities.isOverdue(selectedDate)
    )
      return 'past_scheduled';
    if (TaskDateUtilities.isOverdue(selectedDate)) return 'overdue';
    if (TaskDateUtilities.isToday(selectedDate)) return 'today';
    return 'future';
  };

  const taskStatus = generateTaskDateStatus();

  // TODO: Test and refactor if neccassary
  const generateTaskDateText = () => {
    if (bulk) {
      return 'All';
    }
    if (selectedDate && (taskDateType === 'due' || taskDateType === 'due_at')) {
      if (taskStatus === 'today') return 'Today';
      return makeDateFromDateTime(selectedDate).format('MMM D');
    }
    if (
      selectedDate &&
      (taskDateType === 'schedule' || taskDateType === 'schedule_start')
    ) {
      if (taskStatus === 'today') return 'Today';
      return makeDateFromDateTime(selectedDate).format('MMM D');
    }
    if (taskDateType === 'schedule' || taskDateType === 'schedule_start') {
      return 'Plan';
    }
    if (taskDateType === 'due' || taskDateType === 'due_at') {
      return 'Due';
    }
    return 'Due';
  };

  const text = generateTaskDateText();

  return (
    <TaskCalendarToggle
      taskStatus={taskStatus}
      taskDateType={taskDateType}
      selectedDate={selectedDate}
      taskIsCreating={taskIsCreating}
      alwaysRenderInput={alwaysRenderInput}
      isTaskModal={isTaskModal}
      currentFilter={currentFilter}
      bulk={bulk}
      taskIsEditing={taskIsEditing}
      taskEditProperty={taskEditProperty}
      projectDetailView={projectDetailView}
      data-testid={`${testIdPrefix}-schedule`}
    >
      {icon && (
        <ToggleIconWrapper
          taskIsEditing={taskIsEditing}
          taskIsCreating={taskIsCreating}
          taskStatus={taskStatus}
          taskDateType={taskDateType}
        >
          {React.cloneElement(icon, { taskStatus })}
        </ToggleIconWrapper>
      )}
      <ToggleTextWrapper isToday={text === 'Today'} bulk={bulk}>
        {text}
      </ToggleTextWrapper>
    </TaskCalendarToggle>
  );
};

export default TaskDatePickerInput;
