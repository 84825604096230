import React from 'react';
import {
  MilestoneProgressBarContainer,
  EmptyDefaultHeaderCell
} from './styles';
import moment from 'moment';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import {
  DateInputDiv,
  DateProgress,
  DateDash,
  DateDiv
} from 'views/projectDisplay/styles';
import { getRemainingWorkDays } from 'appUtils/phaseDisplayUtils';
import { START_DATE, END_DATE } from 'react-dates/constants';
import styled from 'styled-components';
import theme from 'theme';
import NewDependencyDateRangeCalendar from 'components/DateRangeCalendar/NewDependencyDateRangeCalendar';

const noop = () => {};

class DateProgressBar extends React.Component {
  onClear = () => {
    this.props.onSave({ startDate: null, endDate: null, workDays: 0 });
  };

  newSaveDates = ({ startDate, endDate, dependency, dependencyItem }) => {
    if (!startDate?.isValid()) {
      return;
    }

    this.props.onSave({
      startDate: startDate.format('MM/DD/YYYY'),
      endDate: endDate?.isValid?.()
        ? endDate.format('MM/DD/YYYY')
        : startDate.format('MM/DD/YYYY'),
      dependency,
      dependencyItem
    });
  };

  renderAddButton = (handleOpen) => {
    const { isDisabled } = this.props;

    return (
      <EmptyDefaultHeaderCell
        onClick={
          isDisabled
            ? noop
            : (e) => {
                e.stopPropagation();
                handleOpen();
              }
        }
        height={21}
        className="add-button"
      >
        Plan
      </EmptyDefaultHeaderCell>
    );
  };

  formatDate = (date, placeholder) =>
    moment(date).isValid() ? moment(date).format('M/DD/YY') : placeholder;

  renderTimeline = (handleOpen) => {
    const {
      data,
      isDisabled,
      disabledTooltip = 'Unable to set dates.',
      tooltipContent = '',
      percentage
    } = this.props;
    const startDate = this.formatDate(data?.start_date, '');
    const endDate = this.formatDate(data?.end_date, '');
    const hasDates = startDate && endDate;

    const { total_work_days: totalWorkDays } = data || {};
    const remainingWorkDays = getRemainingWorkDays(data);
    const currentProgressPercent =
      ((totalWorkDays - remainingWorkDays) / totalWorkDays) * 100 || 0;
    return hasDates ? (
      <Container
        className="budget-phase-progress-dates-container"
        onClick={
          isDisabled
            ? noop
            : (e) => {
                e.stopPropagation();
                handleOpen.bind(this, START_DATE)();
              }
        }
      >
        <DateInputDiv
          data-for="app-tooltip"
          data-tip={isDisabled ? disabledTooltip : tooltipContent}
          data-html
          isPhase
          isSelected={!!startDate}
          className="budget-phase-progress-dates"
          data-effect="solid"
        >
          <DateProgress
            className="date-progress"
            currentProgress={currentProgressPercent}
          />
          <Date
            className="start-date"
            data-testid={`progress-start-date-${data?.id}`}
          >
            {startDate ? moment(startDate).format('M/D/YY') : 'Start Date'}
          </Date>
          <DateDash className="date-dash">–</DateDash>
          <Date
            className="end-date"
            onClick={
              isDisabled
                ? noop
                : (e) => {
                    e.stopPropagation();
                    handleOpen.bind(this, END_DATE)();
                  }
            }
            data-testid={`progress-end-date-${data?.id}`}
          >
            {endDate ? moment(endDate).format('M/D/YY') : 'End Date'}
          </Date>
        </DateInputDiv>
        {percentage && <Percentage>{percentage}%</Percentage>}
      </Container>
    ) : (
      this.renderAddButton(handleOpen)
    );
  };

  render() {
    const { data, isDisabled, phaseCalendarProps } = this.props;

    const MilestonePhaseCalendarComponent = phaseCalendarProps
      ? NewDependencyDateRangeCalendar
      : DateRangeCalendar;

    return (
      <MilestoneProgressBarContainer
        ref={(ref) => (this.calendarContainer = ref)}
        className="app-cues-milestone-progress-bar"
        isDefaultPhase={data?.is_like_default}
        isDisabled={isDisabled}
      >
        <MilestonePhaseCalendarComponent
          onSubmit={this.newSaveDates}
          customInput={(startDate, endDate, handleOpen) =>
            data?.start_date
              ? this.renderTimeline(handleOpen)
              : this.renderAddButton(handleOpen)
          }
          itemStartDate={data?.start_date}
          itemEndDate={data?.end_date}
          showClear
          onClear={this.onClear}
          {...phaseCalendarProps}
        />
      </MilestoneProgressBarContainer>
    );
  }
}

const Percentage = styled.span`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-left: 7px;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  width: fit-content;

  &:hover .budget-phase-progress-dates > *,
  &:hover > span {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

const Date = styled(DateDiv)`
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: 100%;
`;

export default React.memo(DateProgressBar);
