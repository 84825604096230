import { buildRealmURL, updateAPIClientBaseURL } from './utils';
import { APIClient, APIClientConfig } from './APIClient';
import { EventEmitterMixin } from 'appCore/helpers/EventEmitterMixin';

enum RealmAPIClientEvents {
  onChangeBaseURL = 'onChangeBaseURL'
}

// it only handles baseURL for now to support dynamic baseURL for realm
export class RealmAPIClient extends EventEmitterMixin(APIClient) {
  private readonly defaultBaseURL: string;

  constructor(config: APIClientConfig) {
    super(config);
    this.config = config;
    this.defaultBaseURL = config.baseURL;
    this.retrieveRealmBaseURL();
  }

  private retrieveRealmBaseURL() {
    updateAPIClientBaseURL({ APIClient: this });
  }

  private updateBaseURL(baseURL: string) {
    this.config.baseURL = baseURL;
    this.emitter.emit(RealmAPIClientEvents.onChangeBaseURL, baseURL);
  }

  resetToDefaultBaseURL() {
    this.updateBaseURL(this.defaultBaseURL);
  }

  useRealm(realmId: string) {
    const newBaseURL = buildRealmURL({
      baseURL: this.defaultBaseURL,
      realmId: realmId
    });

    this.updateBaseURL(newBaseURL);
  }

  onChangeBaseURL(handler: (baseURL: string) => void) {
    this.emitter.on(RealmAPIClientEvents.onChangeBaseURL, handler);
    return this;
  }
}
