import { AddableFiltersContainer } from 'FilterModule/components/AddableFilters/AddableFiltersContainer';
import { MutableRefObject, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import useUpdate from 'react-use/lib/useUpdate';
import styled from 'styled-components';

export const PAGE_VIEW_FILTER_ID = 'page-view-filter-portal';

/**
 * Used to create a page view filter to the right of the page title. This
 * component must be used within a FilterContextProvider.
 *
 * The schema for this filter must be defined in the same `filterConfig.ts` file
 * as the other filters for the page. The schema must include
 * `FilterField.stackedFilterOrder`, `FilterField.filterOrder`, and appropriate
 * value fields.
 */
export const PageViewFilterPortal = () => {
  // Used to force a repaint.
  const update = useUpdate();

  // A reference to the element of `PageViewFilter` that will contain the
  // filter.
  const targetRef: MutableRefObject<HTMLElement | null> = useRef(null);

  // The filter container can load after this component loads. This ensures that
  // the reference to the filter container is updated when it is added.
  const mutationObserverRef = useRef(
    new MutationObserver((_records, mutationObserver) => {
      if (!targetRef.current?.isConnected) {
        const newPortal = document.getElementById(PAGE_VIEW_FILTER_ID);
        targetRef.current = newPortal;
        if (newPortal) update();
      }
      // Stop searching for the filter container once it is found.
      else {
        mutationObserver.takeRecords();
        mutationObserver.disconnect();
      }
    })
  );

  useEffect(() => {
    const mutationObserver = mutationObserverRef.current;

    // Start looking for the filter container when this component mounts.
    mutationObserver.observe(document.body, { subtree: true, childList: true });

    // Clear the records and stop looking for the filter container when this
    // component unmounts.
    return () => {
      mutationObserver.takeRecords();
      mutationObserver.disconnect();
    };
  }, []);

  // Create the portal if the filter container exists.
  return (
    targetRef.current &&
    createPortal(
      <AddableFiltersContainer
        dropdownHeaderOverride={<Header />}
        toggleLabelOverride={null}
      />,
      targetRef.current
    )
  );
};

const Header = styled.div.attrs({ children: ['Filter Members'] })`
  font-size: 18px;
`;

/**
 * The container for the page view filter.
 */
export const PageViewFilter = styled.div.attrs({
  id: PAGE_VIEW_FILTER_ID
})`
  flex: 1;
  font-size: 12px;
  font-weight: normal;
  margin: -100em 0;

  .filter-dropdown-toggle {
    padding: 3px 8px;
  }

  .addable-filters-added {
    max-width: initial;
  }
`;
