import {
  FilterListType,
  defaultFilterListTypeToLabel,
  filterListTypeToEntityLabel
} from 'FilterModule/constants';
import {
  FilterFieldSchema,
  FilterFieldSchemaValidatorFunction,
  ConfigOptionHash,
  AllowedFilterListTypesFromFieldSchemas
} from 'FilterModule/types';
import merge from 'lodash/merge';

export * from './projectCrossFilters';
export * from './memberCrossFilters';

/* ------------------------------- validators ------------------------------- */

export const nullableStringFieldOptionHashExistenceValidator: FilterFieldSchemaValidatorFunction<
  Nullable<string>
> = ({ originalFieldValue, optionHash, defaultValue }) => {
  return optionHash && String(originalFieldValue) in optionHash
    ? originalFieldValue
    : defaultValue;
};

/**
 * The field's value is valid if it is a key in the optionHash. Otherwise, defaultValue will be returned
 */
export const defaultOptionHashExistenceValidator: FilterFieldSchemaValidatorFunction<
  any
> = ({ originalFieldValue, optionHash, defaultValue }) => {
  return optionHash && originalFieldValue in optionHash
    ? originalFieldValue
    : defaultValue;
};

export const stringArrayFieldOptionHashExistenceValidator: FilterFieldSchemaValidatorFunction<
  string[]
> = ({ originalFieldValue, optionHash }) => {
  return originalFieldValue.filter((value) => !!optionHash?.[value]);
};

export const defaultArrayFieldOptionHashExistenceValidator: FilterFieldSchemaValidatorFunction<
  any[]
> = ({ originalFieldValue, optionHash }) => {
  return originalFieldValue.filter((value) => !!optionHash?.[value]);
};

export const nullableBooleanFieldValidator: FilterFieldSchemaValidatorFunction<
  boolean
> = ({ originalFieldValue, defaultValue }) => {
  return !(
    originalFieldValue === null || typeof originalFieldValue === 'boolean'
  )
    ? defaultValue
    : originalFieldValue;
};

/* ---------------------------------- sort ---------------------------------- */

export const makeDefaultSortOrderFieldSchema = (
  defaultValue: null | 'asc' | 'desc' = null
): FilterFieldSchema<never, Nullable<string>> => ({
  defaultValue: defaultValue,
  optionHash: {
    asc: true,
    desc: true
  },
  getValidatedFieldValue: null
});

/* ----------------------------- filterListType ----------------------------- */

export const makeDefaultFilterListTypeFieldSchema = <
  AdditionalOptionHashValueProps
>({
  defaultValue,
  optionsArray,
  optionHash,
  isSaveable
}: {
  defaultValue: FilterListType;
  optionsArray?: FilterListType[];
  optionHash?: ConfigOptionHash<FilterListType, AdditionalOptionHashValueProps>;
  isSaveable?: false;
}): FilterFieldSchema<FilterListType> => {
  return {
    defaultValue,
    getValidatedFieldValue: null,
    isSaveable,
    optionHash: merge(
      (optionsArray || []).reduce((acc, option) => {
        acc[option] = {
          label: defaultFilterListTypeToLabel[option],
          value: option
        };
        return acc;
      }, {} as NonNullable<typeof optionHash>),
      optionHash
    ),
    optionsArray
  };
};

/* --------------------------------- generic -------------------------------- */

export const makeDefaultArrayFieldSchema = <T extends any>(): FilterFieldSchema<
  never,
  T[]
> => ({
  defaultValue: [],
  getValidatedFieldValue: null
});

export const defaultNumberArrayFieldSchema =
  makeDefaultArrayFieldSchema<number>();

export const defaultStringArrayFieldSchema =
  makeDefaultArrayFieldSchema<string>();

export const makeDefaultBooleanFieldSchema = ({
  defaultValue = false
}: { defaultValue?: Nullable<boolean> } = {}) => ({
  defaultValue,
  getValidatedFieldValue: null
});

/** Provide FieldSchemasType if option checking needed by AllowedFilterListTypesFromFieldSchemas */
export const makeDefaultFilterOrderFieldSchema = <
  FieldSchemasType extends Record<string, FilterFieldSchema>
>({
  defaultValue,
  optionsArray,
  optionHashOverrideValues,
  isSaveable
}: {
  defaultValue: AllowedFilterListTypesFromFieldSchemas<FieldSchemasType>[];
  optionsArray: AllowedFilterListTypesFromFieldSchemas<FieldSchemasType>[];
  /**
   * Will be merged with the default generated optionHash
   */
  optionHashOverrideValues?: ConfigOptionHash<
    AllowedFilterListTypesFromFieldSchemas<FieldSchemasType>,
    { fieldOverride?: string }
  >;
  filterListTypeToFieldOverride?: Partial<Record<FilterListType, string>>;
  isSaveable?: false;
}): FilterFieldSchema<
  FilterListType,
  FilterListType[],
  { fieldOverride?: string }
> => ({
  defaultValue,
  isSaveable,
  optionsArray,
  optionHash: merge(
    (optionsArray || []).reduce((acc, option) => {
      acc[option] = {
        label: defaultFilterListTypeToLabel[option as FilterListType],
        value: option
      };
      return acc;
    }, {} as NonNullable<typeof optionHashOverrideValues>),
    optionHashOverrideValues
  ),
  getValidatedFieldValue:
    stringArrayFieldOptionHashExistenceValidator as FilterFieldSchemaValidatorFunction<
      FilterListType[]
    >
});
