import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedTeamId, getSelectedTeamViewSettings } from 'selectors';
import { updateTeamViewSettings } from 'actionCreators';
import {
  Label,
  Field
} from 'SettingsModule/components/StandardSettings/General/styles';
import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import ButtonField from 'SettingsModule/components/ButtonsField';

const TimesheetsSettings = () => {
  const dispatch = useDispatch();
  const teamSettings = useSelector(getSelectedTeamViewSettings);
  const teamId = useSelector(getSelectedTeamId);
  const [settings, setSettings] = useState(teamSettings);
  useEffect(() => {
    setSettings(teamSettings);
  }, [teamSettings]);

  const handleChangeSettings = (name: string, value: string, type: string) => {
    const viewSettings = {
      ...settings,
      [name]: value === 'true'
    };
    setSettings(viewSettings);
    dispatch(updateTeamViewSettings({ viewSettings, teamId }));
  };

  return (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="Mosaic's Timesheets can be turned off<br/>
          for using your ERP or third party<br/>
          Timesheet tool will disable Timesheets.<br/>
          Time Tracking tools and checkins can still be used."
        data-effect="solid"
        data-html
        data-class="center"
      >
        Timesheet
        <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <ButtonField
          type="radio"
          name="is_timesheet_disabled"
          value="false"
          checked={!settings?.is_timesheet_disabled}
          onClick={handleChangeSettings}
          label="Enabled"
        />

        <ButtonField
          type="radio"
          name="is_timesheet_disabled"
          value="true"
          checked={!!settings?.is_timesheet_disabled}
          onClick={handleChangeSettings}
          label="Disable"
        />
      </Field>
    </>
  );
};

export default TimesheetsSettings;
