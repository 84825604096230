import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  DateDiv,
  DateInputDiv,
  DateProgress,
  DateDash,
  DependencyLinkIconContainer,
  DateLabelContainer
} from './styles';
import {
  isStartDateDependency,
  isEndDateDependency,
  generateNewDependencyInfos,
  mapNewPhaseDependencies
} from 'appUtils/newDependencies';

import {
  getRemainingWorkDays,
  getPhaseProgressTooltipContent,
  getPhaseProgress,
  isPhaseArchived
} from 'appUtils/phaseDisplayUtils';
import {
  predictWorkdaysAndUpdatePhase,
  fetchUserActivitiesForActionable
} from 'actionCreators';
import {
  DEPENDENCY_STRINGS_DISPLAY,
  DEPENDENCY_TYPES
} from 'appConstants/workload';
import NewDependencyDateRangeCalendar from 'components/DateRangeCalendar/NewDependencyDateRangeCalendar';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';
import { getFlatPhasesHash } from 'selectors';
import { nullifyInvalidDate } from 'appUtils/momentUtils';
import { DATE_DISPLAY_TINY } from 'appConstants/date';
import { actionableTypesHash } from 'appConstants/userActivities';
import noop from 'lodash/noop';

const MilestoneTableStartDateCell = (props) => {
  const dispatch = useDispatch(null);
  const [newWorkDays, setNewWorkDays] = useState(null);
  const phasesHash = useSelector(getFlatPhasesHash);

  const { phase, row } = props;

  const orderedPhases = row.original.orderedPhases;
  const { total_work_days } = phase || {};
  const displayWorkDays =
    newWorkDays !== null ? newWorkDays : phase ? total_work_days : '';
  const remainingWorkDays = getRemainingWorkDays(phase);
  const currentProgressPercent =
    ((displayWorkDays - remainingWorkDays) / displayWorkDays) * 100 || 0;

  const onSuccessActionsForDatesUpdate = [
    {
      selector: noop,
      successAction: () => {
        if (phase?.project_id) {
          dispatch(
            fetchUserActivitiesForActionable({
              project_ids: [phase.project_id],
              actionable_type: actionableTypesHash.Phase,
              limit: 500,
              keepCurrentActivitiesOnTrigger: true
            })
          );
        }
      }
    }
  ];

  const handlePredictionAndUpdateStartDate = ({
    startDate,
    dependency,
    dependencyItem
  }) => {
    const formattedStartDate = nullifyInvalidDate(startDate, 'MM/DD/YYYY');

    const dependencyInfos = generateNewDependencyInfos(
      phase,
      dependency,
      dependencyItem,
      DEPENDENCY_TYPES.PHASE
    );
    dispatch(
      predictWorkdaysAndUpdatePhase({
        phase,
        startDate: formattedStartDate,
        endDate: !isPhase ? formattedStartDate : phase.end_date,
        dependencyInfos,
        meta: {
          onSuccess: onSuccessActionsForDatesUpdate
        }
      })
    );
  };

  const handlePredictionAndUpdateDates = ({
    startDate,
    endDate,
    dependency,
    dependencyItem
  }) => {
    const formattedStartDate = nullifyInvalidDate(startDate, 'MM/DD/YYYY');
    const formattedEndDate = nullifyInvalidDate(endDate, 'MM/DD/YYYY');

    const dependencyInfos = generateNewDependencyInfos(
      phase,
      dependency,
      dependencyItem,
      DEPENDENCY_TYPES.PHASE
    );

    dispatch(
      predictWorkdaysAndUpdatePhase({
        phase,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        dependencyInfos: dependencyInfos,
        meta: {
          onSuccess: onSuccessActionsForDatesUpdate
        }
      })
    );
  };

  const isPhase = phase.is_budget;
  const isArchived = isPhaseArchived(phase);
  const phaseProgress = getPhaseProgress(phase);
  const noDependencyTooltip = getPhaseProgressTooltipContent(phaseProgress);

  const nonArchivedPhases = useMemo(() => {
    return orderedPhases
      ? orderedPhases
          .filter(
            (orderedPhase) =>
              orderedPhase &&
              !orderedPhase.is_archived &&
              orderedPhase.is_budget &&
              orderedPhase.id !== phase.id &&
              orderedPhase.start_date
          )
          .map((orderedPhase) => ({
            dependableId: orderedPhase.id,
            dependableType: 'Phase',
            label: orderedPhase.name,
            startDate: orderedPhase.start_date,
            endDate: orderedPhase.end_date
          }))
      : [];
  }, [orderedPhases, phase]);

  const { initialDependency, initialDependencyItem } = mapNewPhaseDependencies({
    phase,
    phasesHash
  });

  const getDependencyTooltip = () => {
    const startDependencyName = isStartDateDependency(initialDependency.start)
      ? `Phase Start Dependent on ${
          DEPENDENCY_STRINGS_DISPLAY[initialDependency.start]
        } of ${initialDependencyItem.start.name}`
      : undefined;
    const endDependencyName = isEndDateDependency(initialDependency.end)
      ? `Phase End Dependent ${
          DEPENDENCY_STRINGS_DISPLAY[initialDependency.end]
        } of ${initialDependencyItem.end.name}`
      : undefined;

    const dependencyText = [startDependencyName, endDependencyName]
      .filter(Boolean)
      .join(`<br /> <br />`);

    return `${dependencyText}`;
  };

  const singleDateCustomInput = (startDate, endDate, handleOpen) => (
    <DateInputDiv
      data-for="app-tooltip"
      data-tip={isArchived ? 'Unable to set dates for archived phases.' : ''}
      isSelected={!!startDate}
    >
      {phase.dependencies.length > 0 && (
        <DependencyLinkIconContainer isMilestone>
          <DependencyLinkIcon fillColor="#333333" />
        </DependencyLinkIconContainer>
      )}
      <DateDiv
        className="start-date-div"
        isSetDate={!startDate}
        onClick={isArchived ? noop : handleOpen.bind(this)}
      >
        {startDate ? moment(startDate).format(DATE_DISPLAY_TINY) : 'Plan'}
      </DateDiv>
    </DateInputDiv>
  );

  return (
    <div className="milestone-date-range">
      {isPhase && phase?.start_date && phase?.end_date ? (
        <DateInputDiv
          data-for="app-tooltip"
          data-tip={
            isArchived
              ? 'Unable to set dates for archived phases.'
              : phase.dependencies.length > 0
              ? getDependencyTooltip()
              : noDependencyTooltip
          }
          data-class="mw-250"
          data-html
          data-effect="solid"
          isPhase={isPhase}
          isSelected={phase?.start_date}
        >
          <NewDependencyDateRangeCalendar
            showInputs
            itemStartDate={phase.start_date}
            itemEndDate={phase.end_date}
            customInput={(startDate, endDate, handleOpen) => (
              <DateLabelContainer
                onClick={isArchived ? noop : handleOpen.bind(this)}
              >
                <DateDiv>
                  {!!initialDependency.start && (
                    <DependencyLinkIconContainer>
                      <DependencyLinkIcon fillColor="#333333" />
                    </DependencyLinkIconContainer>
                  )}
                  <span>
                    {startDate
                      ? moment(startDate).format(DATE_DISPLAY_TINY)
                      : 'Start Date'}
                  </span>
                </DateDiv>
                <DateDash>
                  <span>-</span>
                </DateDash>
                <DateDiv>
                  {!!initialDependency.end && (
                    <DependencyLinkIconContainer isEndDate>
                      <DependencyLinkIcon fillColor="#333333" />
                    </DependencyLinkIconContainer>
                  )}
                  <span>
                    {endDate
                      ? moment(endDate).format(DATE_DISPLAY_TINY)
                      : 'End Date'}
                  </span>
                </DateDiv>
              </DateLabelContainer>
            )}
            onSubmit={handlePredictionAndUpdateDates}
            onClear={handlePredictionAndUpdateDates}
            phases={nonArchivedPhases}
            isPhaseCalendar
            showWorkdays
            shouldRenderFooter
            showClear
            initialDependency={initialDependency}
            itemWorkDays={phase.total_work_days}
            item={phase}
            itemType={DEPENDENCY_TYPES.PHASE}
            initialDependencyItem={initialDependencyItem}
            initialDependencyItemType={DEPENDENCY_TYPES.PHASE}
            calendarClassNames={'phase-calendar'}
          />
          <DateProgress currentProgress={currentProgressPercent} />
        </DateInputDiv>
      ) : isPhase ? (
        <DateInputDiv>
          <DateDiv isSetDate className="set-date-phase">
            <NewDependencyDateRangeCalendar
              showInputs
              itemStartDate={phase?.start_date}
              itemEndDate={phase?.end_date}
              customInput={(startDate, endDate, handleOpen) => (
                <div>
                  <span onClick={isArchived ? noop : handleOpen.bind(this)}>
                    Plan
                  </span>
                </div>
              )}
              onSubmit={handlePredictionAndUpdateDates}
              onClear={handlePredictionAndUpdateDates}
              phases={nonArchivedPhases}
              isPhaseCalendar
              showWorkdays
              shouldRenderFooter
              showClear
              itemType={DEPENDENCY_TYPES.PHASE}
              item={phase}
              initialDependencyItemType={DEPENDENCY_TYPES.PHASE}
              calendarClassNames={'phase-calendar'}
            />
          </DateDiv>
        </DateInputDiv>
      ) : (
        <NewDependencyDateRangeCalendar
          itemStartDate={phase.start_date}
          labels={{ start: 'DATE' }}
          showInputs
          onSubmit={handlePredictionAndUpdateStartDate}
          onClear={handlePredictionAndUpdateStartDate}
          showClear
          isSingleDay
          customInput={singleDateCustomInput}
          shouldRenderFooter
          phases={nonArchivedPhases}
          isMilestoneCalendar
          itemType={DEPENDENCY_TYPES.MILESTONE}
          item={phase}
          initialDependency={initialDependency}
          initialDependencyItemType={DEPENDENCY_TYPES.PHASE}
          initialDependencyItem={initialDependencyItem}
          calendarClassNames={'phase-calendar'}
        />
      )}
    </div>
  );
};

export default MilestoneTableStartDateCell;
