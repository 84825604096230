import styled from 'styled-components';
import unassignedIcon from 'images/empty-member.svg';
import AddMembersIcon from 'icons/addmembericonwhite.svg';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import LockIcon from 'images/lock-blue.svg';
import theme from 'theme';
import EmailIcon from 'icons/EmailIcon';
import VisibilityIcon from 'icons/VisibilityIcon';
export const addTaskHeight = 50;
export const taskCardHeight = 112; // variable based on contents, this is roughly the base
export const threeLineCardHeight = 300;
export const laneHeaderHeight = 30;
const minRowHeight = addTaskHeight + threeLineCardHeight;
export const fitTaskHeight = (rowHeight) =>
  rowHeight === 0
    ? `${minRowHeight}px`
    : `${minRowHeight + taskCardHeight * (Math.min(rowHeight, 1) - 1)}px`;
export const getPlannerColumnHeight = ({ isOnTeamTaskPlanner, rowHeight }) =>
  isOnTeamTaskPlanner ? fitTaskHeight(rowHeight) : 'calc(80vh - 30px)';

export const PlannerColumnBody = styled.div`
  min-height: ${`${minRowHeight}px`};
  height: ${getPlannerColumnHeight};
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.dragging-over {
    .planner-add-task {
      visibility: none;
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 0.4em;
      background: inherit;
    }
  }
`;

export const Page = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;

  .team-member-cell,
  ${PlannerColumnBody} {
    ${({ teamPlanner }) => teamPlanner && `max-height: 60vh`}
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 30px;
`;

export const CellWrapper = styled.div`
  .team-member-cell {
    min-height: ${`${minRowHeight + laneHeaderHeight}px`};
    height: ${({ rowHeight }) => rowHeight && fitTaskHeight(rowHeight)};
  }
`;

export const TaskCardAssignee = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-family: 'proxima-nova';
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.colorPureWhite};
  background: ${({ isAssigned, assignedToMe, theme }) =>
    !isAssigned
      ? `url(${unassignedIcon}) no-repeat center;`
      : assignedToMe
      ? theme.colors.colorMediumGray8
      : theme.colors.colorLightGray4};
`;

export const ToggleText = styled.div`
  font-size: 12px;
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  border-radius: 5px;
  background-color: ${(props) =>
    props.selected ? theme.colors.colorPureWhite : 'transparent'};
  border: 1px solid
    ${(props) =>
      props.selected ? theme.colors.colorLightGray4 : 'transparent'};
  padding: 2px 16px;
  cursor: pointer;
  color: ${theme.colors.colorSemiDarkGray1};
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const ViewMenuToggleText = styled.div`
  font-weight: 600;
  border-top: 1px solid ${theme.colors.colorMediumGray9};
  border-bottom: 1px solid ${theme.colors.colorMediumGray9};
  cursor: pointer;
  color: ${theme.colors.colorMediumGray9};
  font-size: 9px;
  margin: 0px 6px;
  margin-top: 5px;
  width: 23px;
  text-align: center;
  white-space: nowrap;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    border-top: 1px solid ${theme.colors.colorRoyalBlue};
    border-bottom: 1px solid ${theme.colors.colorRoyalBlue};
  }
`;

export const Toggle = styled.div`
  margin: 0px 0;
  border-right: 1px solid
    ${(props) =>
      props.borderRight ? theme.colors.colorLightGray9 : 'transparent'};
`;

export const ZoomLevelContainer = styled.div`
  background: ${theme.colors.colorPaleGray1};
  display: flex;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 0px 4px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 0px 1px;
  justify-content: space-around;
`;

export const ViewIconContainer = styled.div``;

export const StyledSettingsIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const StyledEmailIcon = styled(EmailIcon)``;

export const VisibilityText = styled.span`
  color: ${theme.colors.colorMediumGray9};
  margin-left: 2px;
`;

export const MembersContainer = styled.div`
  position: absolute;
  top: -14px;
  right: 25px;
  font-size: 12px;
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;

  .project-planner-timeline-container & {
    top: 0px;
    left: 22px;
  }
  &.workload-visibility-indicator:hover {
    svg path {
      stroke: ${theme.colors.colorCalendarBlue};
    }
    ${VisibilityText} {
      color: ${theme.colors.colorCalendarBlue};
    }
  }
`;

export const StyledAddMembersIcon = styled.div`
  height: 32px !important;
  width: 32px !important;
  background-color: ${theme.colors.colorRoyalBlue};
  background-image: url(${AddMembersIcon});
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  margin-left: 6px;
  border: 1px solid ${theme.colors.colorRoyalBlue};
`;

export const StyledLockIcon = styled.div`
  background: url(${LockIcon}) no-repeat center;
  height: 14px;
  margin-right: 6px;
  width: 11px;
  display: inline-block;
`;

export const LeftOptionsContainer = styled.div`
  position: absolute;
  left: 38px;
  top: 40px;
  display: flex;
  align-items: center;
`;

export const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon)`
  margin-right: 4px;
  height: 14px;
  width: 14px;
  path {
    stroke: ${theme.colors.colorMediumGray5};
  }
`;
export const AddPhaseButton = styled.div`
  visibility: ${(props) => (props.hide ? 'hidden' : 'visible')};
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${theme.colors.colorRoyalBlue};
  border: 1px solid transparent;
  background: transparent;
  padding: 1px 10px;
  border-radius: 100px;
  cursor: pointer;
  ${StyledPhaseMilestoneIcon} {
    path {
      stroke: ${theme.colors.colorRoyalBlue};
    }
  }
  &:hover {
    background: ${theme.colors.colorRoyalBlue};
    color: ${theme.colors.colorPureWhite};
    ${StyledPhaseMilestoneIcon} {
      path {
        stroke: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

export const StyledVisibilityIcon = styled(VisibilityIcon)``;

export const DropdownWrapper = styled.div`
  padding: 0px;
  width: 100%;
`;

export const MenuListItem = styled.div`
  font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
  min-width: 115px;
  color: ${(props) => props.theme.colors.colorSemiDarkGray3};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 34px;
  padding-left: 33px;
  line-height: 34px;
  :hover {
    background-color: ${(props) => props.theme.colors.colorTranslucentGray4};
    cursor: pointer;
  }
`;

export const MenuTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-top: 12px;
  margin-left: 17px;
  margin-bottom: 5px;
`;

export const ActivityIconContainer = styled.div`
  margin-top: -2px;
  margin-right: 3px;
`;

export const ActivitySideMenuButton = styled.div`
  width: 68px;
  height: 20px;
  border: 1px solid ${theme.colors.colorCalendarBlue};
  background: ${theme.colors.colorPureWhite};
  color: ${theme.colors.colorCalendarBlue};
  display: flex;
  border-radius: 3px;
  font-size: 11px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 31px;
  right: 14px;
  z-index: 100;
`;

export const VisibilitySettingsIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 4px;
  cursor: pointer;
  &:hover {
    path {
      stroke: ${theme.colors.colorCalendarBlue};
    }
  }
  margin-top: 1px;
`;

export const SaveIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 5px;
  margin-top: -4px;
  .hide-on-hover {
    display: inline;
  }
  .show-on-hover {
    display: none;
  }
  &:hover {
    .hide-on-hover {
      display: none;
    }
    .show-on-hover {
      display: inline;
    }
  }
`;
