import styled from 'styled-components';
import BudgetLegendItem from 'BudgetModule/components/BudgetLegendItem';

const ProjectTeamListHeader = () => {
  return (
    <Container>
      <div className="heaer-text">Project Team</div>
      {/* <BudgetLegendItem
        text="estimated"
        tooltipContent={undefined}
        hideDot={undefined}
        label={undefined}
        forceShowDot={undefined}
        estimatedNotTarget={undefined}
      /> */}
    </Container>
  );
};

export default ProjectTeamListHeader;

const Container = styled.div`
  display: grid;
  grid-template-columns: 232px 103px;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.colorMediumGray9};
  margin-bottom: 1rem;
  padding-left: 1rem;

  .legend-item-container {
    font-size: 14px;
    font-weight: 600;
  }
`;
