import React from 'react';
import SvgIcon from 'components/SvgIcon';

const NoteIcon = ({
  isHovered,
  color = '#b3b3b3',
  height = '16',
  className,
  width = '16'
}) => (
  <SvgIcon
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 14V1L8 1H10H11V3.5C11 4.05228 11.4477 4.5 12 4.5H15V5.625V7.5V14H1ZM14.6899 3.5L13.6515 2.64815L12 1.29341V3.5H14.6899ZM0 1C0 0.447715 0.447715 0 1 0H8H10H11.6423C11.8736 0 12.0977 0.0801673 12.2765 0.226851L14.2857 1.875L15.6342 2.9812C15.8658 3.17114 16 3.45486 16 3.75435V5.625V7.5V14C16 14.5523 15.5523 15 15 15H1C0.447715 15 0 14.5523 0 14V1Z"
      fill={`${isHovered ? '#0074D9' : color}`}
    />
  </SvgIcon>
);

export default NoteIcon;
