import React from 'react';
import { connect } from 'react-redux';
import ModuleList from 'components/ViewFactory/ModuleList';
import {
  getMatchedRouteParams,
  getInstalledMembersModuleIds,
  getMyTeamMembership
} from 'selectors';
import { withRouter } from 'react-router-dom';
import { generatePath } from 'react-router';
import { SectionRoutes } from 'appConstants/navigation';

const teamMemberIdRequired = {
  timesheet: true,
  tasks: true
};
class TeamMembersHeader extends React.Component {
  // function signature required by ModuleList due to tab switching function signature on board and project views
  changeViewDisplay = (newTab) => (e) => {
    const { history, matchedParams, myTeamMemberShip } = this.props;
    const { teamSlug } = matchedParams;
    if (!myTeamMemberShip || !teamSlug) {
      return;
    }
    const teamMemberId = teamMemberIdRequired[newTab]
      ? myTeamMemberShip.id
      : undefined;

    const route = generatePath(SectionRoutes.MEMBERS, {
      teamSlug,
      teamMemberId,
      membersViewType: newTab
    });

    history.push(route);
  };

  render() {
    const { installedModuleIds } = this.props;
    return (
      <div>
        <ModuleList
          currentTab={this.props.matchedParams.membersViewType}
          changeTabs={this.changeViewDisplay}
          installedModuleIds={installedModuleIds}
          viewType={'members'}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  installedModuleIds: getInstalledMembersModuleIds(state, ownProps),
  matchedParams: getMatchedRouteParams(state),
  myTeamMemberShip: getMyTeamMembership(state)
});
export default withRouter(connect(mapStateToProps)(TeamMembersHeader));
