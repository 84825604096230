import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

type ThreeLineWithPlusIconProps = Pick<
  SvgIconProps,
  'width' | 'height' | 'fill' | 'className'
>;

export const ThreeLineWithPlusIcon = ({
  width = '27',
  height = '12',
  fill = '#2F80ED',
  className = ''
}: ThreeLineWithPlusIconProps) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 8 12"
  >
    <path d="M14 1.44443H7H2.50733e-06L0 0.5H14V1.44443Z" fill={fill} />
    <path d="M5.28302 8.57357V9.5H8.71698V8.57357H5.28302Z" fill={fill} />
    <path
      d="M3.16981 4.57745L3.16981 5.51474H10.8302V4.57745H3.16981Z"
      fill={fill}
    />
    <path
      d="M13.5508 7.41016H15.1602V7.94922H13.5508V9.61328H13.0078V7.94922H11.4062V7.41016H13.0078V5.73828H13.5508V7.41016Z"
      fill={fill}
    />
  </SvgIcon>
);

export default ThreeLineWithPlusIcon;
