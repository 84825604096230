import { VisibleTimeRange } from '../types';
import { startOfWeek } from 'date-fns';
import { getVisibleTimeRange } from 'appUtils/projectPlannerUtils';
import { ZOOM_LEVELS } from 'appConstants/workload';
import { TimelineBasedZoomLevels } from './types';

const getMondayOfWeek = (date: Date) => startOfWeek(date, { weekStartsOn: 1 });

export function getInitialVisibleRanges({
  visibleTimeStart,
  zoom
}: {
  visibleTimeStart?: EpochTimeStamp;
  zoom?: TimelineBasedZoomLevels;
} = {}): VisibleTimeRange {
  if (visibleTimeStart !== undefined) {
    const { visibleTimeEnd } = getVisibleTimeRange({
      zoom: zoom ?? ZOOM_LEVELS.WEEK,
      timeStart: visibleTimeStart
    });
    return {
      visibleTimeStart,
      visibleTimeEnd: visibleTimeEnd.toDate().getTime()
    };
  }

  return getDefaultVisibleRanges();
}

const getDefaultVisibleRanges = (): VisibleTimeRange => {
  const { visibleTimeEnd } = getVisibleTimeRange({
    zoom: ZOOM_LEVELS.WEEK,
    timeStart: getMondayOfWeek(new Date()).getTime()
  });
  return {
    visibleTimeStart: getMondayOfWeek(new Date()).getTime(),
    visibleTimeEnd: visibleTimeEnd.valueOf()
  };
};
