import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import { useEffect, ReactNode } from 'react';
import { CURRENCY_LIST_UNIQUE } from 'CurrencyModule/constants/currencyList';
import styled from 'styled-components';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { getCurrencySymbolByCode } from '../utils';
import { Currency } from '../types';
import CurrencyDropdownItem from './CurrencyDropdownItem';

export const CurrencyDropdown = ({
  isOpen,
  target,
  onClose,
  onSelect,
  HeaderButton,
  customHeader,
  popoverClassname = 'currency-item'
}: {
  isOpen: boolean;
  target: HTMLElement | null;
  onClose: () => void;
  onSelect: ({ currencyCode }: { currencyCode: string }) => void;
  HeaderButton?: ReactNode;
  customHeader?: string;
  popoverClassname?: string;
}) => {
  const { MultiStepFlyout, openFlyout, closeFlyout } = useMultiStepFlyout();

  useEffect(() => {
    if (isOpen && target) openFlyout({ target });
    else closeFlyout();
  }, [isOpen, openFlyout, closeFlyout, target]);

  const handleSelect = (e, { item }: { item: Currency }) => {
    const { currencyCode } = item;
    onSelect({ currencyCode });
    handleClose();
  };

  const handleClose = () => {
    closeFlyout();
    onClose();
  };

  const renderItem = ({ item }: { item: Currency }): ReactNode => {
    const { currencyCode, currencyName } = item;
    const currencySymbol = getCurrencySymbolByCode({ currencyCode });
    const currencyString = `${currencyName} (${currencyCode})`;
    return (
      <CurrencyDropdownItem
        currencySymbol={currencySymbol}
        currencyString={currencyString}
      />
    );
  };

  const handleFilter = (item: Currency, searchWords: string[]): Currency =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['currencyCode', 'currencyName']
    });

  const renderHeaderButton = (): ReactNode => {
    return (
      <HeaderButtonContainer onClick={handleClose}>
        {HeaderButton}
      </HeaderButtonContainer>
    );
  };

  return (
    <MultiStepFlyout
      copy={{
        headerInitial: customHeader ?? 'Select Currency',
        searchPlaceholder: 'Search or Select'
      }}
      items={CURRENCY_LIST_UNIQUE}
      idKey="currencyCode"
      renderItem={renderItem}
      hideFooter
      editDisabled
      itemHeight={48}
      isWhite
      searchEnabled
      renderHeaderButton={renderHeaderButton}
      popoverClassName={popoverClassname}
      closePopover={handleClose}
      handleSelect={handleSelect}
      itemFilter={handleFilter}
      className="currency-list-flyout"
      target={target}
    />
  );
};

const HeaderButtonContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
