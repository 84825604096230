import React from 'react';
import ImportFlow from 'CsvImportsModule/components/ImportFlow';
import { CsvImportEntity } from 'CsvImportsModule/constants';

const TimesheetsTab = () => {
  return (
    <div
      className="team-settings-modal"
      style={{
        flexDirection: 'column',
        display: 'flex',
        overflow: 'visible',
        paddingLeft: 24
      }}
    >
      <div
        className="team-settings-modal-header"
        style={{ borderBottom: 'none', paddingLeft: 7 }}
      >
        Timesheet
      </div>
      <ImportFlow entityType={CsvImportEntity.Timesheet} />
    </div>
  );
};

export default TimesheetsTab;
