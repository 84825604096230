import { memo } from 'react';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { isExistenceCheckOptionHash } from 'FilterModule/filterSchemas/utils';
import {
  FilterField,
  FilterListType,
  filterListTypeToField
} from 'FilterModule/constants';
import { AddableFilters, AddableFiltersProps } from './AddableFilters';
import difference from 'lodash/difference';
import { ConfigOptionHash } from 'FilterModule/types';

export const AddableFiltersContainer = memo(
  ({
    dropdownHeaderOverride,
    toggleLabelOverride
  }: Pick<
    AddableFiltersProps,
    'dropdownHeaderOverride' | 'toggleLabelOverride'
  >) => {
    const { currentFilter, currentFilterSchema } = useFilterContext();

    const filterOrderFieldSchemaOptionsConfig =
      currentFilterSchema.fields[FilterField.filterOrder]?.optionsConfig;

    const filterOptionsArray =
      filterOrderFieldSchemaOptionsConfig?.optionsArray as FilterListType[];

    const filterOptionHash = filterOrderFieldSchemaOptionsConfig?.optionHash;

    const filterOptionHashToUse =
      !filterOptionHash || isExistenceCheckOptionHash(filterOptionHash)
        ? undefined
        : (filterOptionHash as ConfigOptionHash<
            FilterListType,
            { fieldOverride?: string }
          >);

    const updateFilterOrder = (nextOrder: FilterListType[]) => {
      if (currentFilter.filterOrder) {
        const removedFilterListTypes = difference(
          currentFilter.filterOrder,
          nextOrder
        );
        const fieldsToClear = removedFilterListTypes.flatMap(
          (listType) =>
            filterOptionHashToUse?.[listType]?.fieldOverride ||
            filterListTypeToField[listType]
        );

        // if there are any removed, reset their fields
        currentFilter.update({
          filterOrder: nextOrder,
          ...fieldsToClear.reduce((acc, cur) => {
            acc[cur] = currentFilterSchema.initialValues[cur];
            return acc;
          }, {})
        });
      }
    };

    if (
      !filterOptionsArray ||
      !filterOptionHash ||
      !currentFilter.filterOrder
    ) {
      return null;
    }

    return (
      <AddableFilters
        dropdownHeaderOverride={dropdownHeaderOverride}
        filterOrder={currentFilter.filterOrder}
        optionsArray={filterOptionsArray}
        optionHash={filterOptionHash}
        toggleLabelOverride={toggleLabelOverride}
        updateFilterOrder={updateFilterOrder}
      />
    );
  }
);

AddableFiltersContainer.displayName = 'AddableFiltersContainer';
