import React from 'react';
import theme from 'theme';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import DeleteIcon from 'icons/DeleteIcon';
import keyBy from 'lodash/keyBy';
import {
  DeleteIconContainer,
  StyledMilestoneIcon,
  StyledBudgetPhaseMilestoneIcon,
  StyledBudgetIconContainer,
  AddPlusContainer,
  StyledAddPlusIcon,
  PlusMemberContainer,
  DateDiv,
  DateInputDiv
} from './styles';
import { MilestoneInput, MemberInitials } from 'views';
import MilestoneWorkDaysInput from './MilestoneWorkDaysInput';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import debounce from 'lodash/debounce';
import {
  updatePhase,
  predictPhase,
  addNewMilestoneTemplate,
  setEditMilestoneId,
  fetchPhasesByProjectIds,
  createPhaseMembers,
  predictWorkdaysAndUpdatePhase,
  checkHasTimeEntries
} from 'actionCreators';
import {
  getSelectedTeamId,
  getEditingMilestoneId,
  getDeletingMilestoneId,
  getUserIsAdmin,
  getTeamMembershipsByAccountId,
  getMe
} from 'selectors';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import { calcMilestoneIsOverdue } from 'appUtils';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import {
  calculatePhaseSaveDateRange,
  calculateSelectedDateRange
} from 'appUtils/dateRangeHelpers';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import { isInAnyPopover } from 'appUtils/popoverClicks';
import DragGripIcon from 'icons/DragGripIcon';
import styled from 'styled-components';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { isPhaseInactive, isPhaseArchived } from 'appUtils/phaseDisplayUtils';

const StyledDragGrips = styled.div`
  position: absolute;
  left: 8px;
  visibility: hidden;
  :hover {
    visibility: visible;
  }
  .project-milestone-row:hover & {
    visibility: visible;
  }
`;
const InactiveText = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 10px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 62px;
`;

const today = moment();
const noop = () => {};

class MilestoneRow extends React.Component {
  state = {
    newDates: null,
    newDescription: null,
    newWorkDays: null,
    rowFocussed: false,
    focusedProp: null,
    submitted: false
  };

  componentWillUnmount() {
    this.handleMilestoneSubmit();
    setTimeout(() => {
      this.setMilestoneId(null);
    }, 100);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      editingMilestoneId,
      phase,
      selectedProject,
      fetchPhasesByProjectIds
    } = this.props;
    const isExistingMilestone =
      editingMilestoneId &&
      phase &&
      editingMilestoneId !== nextProps.editingMilestoneId &&
      editingMilestoneId === phase.id;

    if (isExistingMilestone) {
      this.handleMilestoneSubmit(editingMilestoneId);
    }

    if (
      phase &&
      phase.end_date !== nextProps.phase.end_date &&
      selectedProject
    ) {
      fetchPhasesByProjectIds({ projectIds: [selectedProject.id] });
    }

    if (!phase && nextProps.phase) {
      this.setMilestoneId(nextProps.phase.id);
    }
  }

  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate(prevProps, prevState) {
    const { phase, predictedPhase, predictedPhaseId } = this.props;
    const { newDates, newWorkDays, focusedProp } = this.state;
    if (prevProps.phase !== phase) {
      rebuildTooltip();
    }
    if (
      prevProps.predictedPhase !== predictedPhase &&
      predictedPhaseId === phase.id
    ) {
      const { total_work_days, start_date, end_date } = predictedPhase;
      const predictedDates = calculateSelectedDateRange({
        newValues: [],
        start: moment(start_date),
        end: moment(end_date),
        itemExists: true
      });

      this.setState({
        newDates: focusedProp === 'newDates' ? newDates : predictedDates,
        newWorkDays:
          focusedProp === 'newWorkDays' ? newWorkDays : total_work_days
      });
    }

    const newDatesDifferent =
      newDates !== prevState.newDates &&
      focusedProp === 'newDates' &&
      newDates?.length === 2;
    const workDaysDifferent =
      newWorkDays !== prevState.newWorkDays && focusedProp === 'newWorkDays';
    if (newDatesDifferent || workDaysDifferent) {
      const [start_date, end_date] = calculatePhaseSaveDateRange({
        phase,
        newDates
      });
      const total_work_days = workDaysDifferent ? newWorkDays : undefined;
      this.predictPhase({
        ...phase,
        start_date,
        end_date: total_work_days ? undefined : end_date,
        total_work_days
      });
    }
  }

  getPermissions = () => {
    const { selectedProject, selectedTeamId } = this.props;
    const permissions = {
      projectId: selectedProject?.id,
      teamId: selectedTeamId
    };
    return permissions;
  };

  handleDone = (members) => {
    const { createPhaseMembers, phase } = this.props;
    const accountIds = members.map((member) => member.account.id);
    createPhaseMembers({
      projectId: phase.project_id,
      phaseId: phase.id,
      accountIds
    });
  };

  checkForEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  handleDescriptionChange = (event) => {
    this.setState({
      newDescription: event.target.value
    });
  };

  handleWorkDaysChange = (event) => {
    this.setState({
      newWorkDays: event.target.value,
      focusedProp: 'newWorkDays'
    });
  };

  handleToggleComplete = (event, milestoneId) => {
    this.handleMilestoneSubmit(milestoneId, true);
  };

  handleBlur = (event) => {
    const classString = event.relatedTarget
      ? event.relatedTarget.className
      : '';

    if (isInAnyPopover(event)) return;

    const openingCalendar =
      classString.includes('project-milestone-start-date') ||
      classString.includes('project-milestone-end-date') ||
      classString.includes('milestone-calendar');

    if (!openingCalendar) {
      this.setMilestoneId(null);
    }
    this.setState({ rowFocussed: false });
  };

  clearDates = () => {
    this.setState({
      newDates: []
    });
  };

  saveDates = () => {
    this.setMilestoneId(null);
    this.setState({ rowFocussed: false });
  };

  setMilestoneId = (id) => {
    const { editingMilestoneId, setEditMilestoneId } = this.props;
    if (editingMilestoneId !== id) {
      setEditMilestoneId(id);
    }
  };

  setDeleteMilestoneId = () => {
    const {
      phase,
      openPhaseModal,
      checkHasTimeEntries,
      selectedTeamId,
      canDelete
    } = this.props;

    if (!canDelete) {
      return;
    }

    checkHasTimeEntries({
      phase_ids: [phase.id],
      team_id: selectedTeamId,
      permissions: {
        mine: false
      }
    });
    openPhaseModal({
      id: phase.id,
      name: phase.name,
      projectId: phase.project_id,
      isDeletePhase: true
    });
  };

  handleMilestoneSubmit = (prevEditingMilestoneId, toggleComplete) => {
    const { updatePhase, clearAdding, selectedProject, phase } = this.props;
    const { newDescription, newDates, newWorkDays } = this.state;
    const isExistingMilestone =
      prevEditingMilestoneId &&
      phase &&
      phase.id === prevEditingMilestoneId &&
      (newDescription !== null ||
        Array.isArray(newDates) ||
        toggleComplete ||
        newWorkDays);

    const description =
      phase && newDescription !== null ? newDescription : phase?.name;
    const workDays =
      phase && newWorkDays !== null ? newWorkDays : phase?.total_work_days;

    const [startDate, endDate] = calculatePhaseSaveDateRange({
      phase,
      newDates
    });

    clearAdding();
    const permissions = this.getPermissions();
    if (isExistingMilestone) {
      const willBeComplete = toggleComplete
        ? !phase.is_complete
        : !!phase.is_complete;

      updatePhase({
        id: phase.id,
        projectId: selectedProject.id,
        name: description,
        startDate,
        endDate,
        workDays,
        complete: willBeComplete,
        permissions
      });
    }
  };

  handleFocus = () => {
    const { phase } = this.props;
    const { id } = phase || {};
    const newEditingMilestoneId = id;
    this.setState({ rowFocussed: true });
    this.setMilestoneId(newEditingMilestoneId);
  };

  handleClick = (e) => {
    const { phase } = this.props;
    const { id, order_number } = phase || {};

    return order_number ? this.handleToggleComplete(e, id) : null;
  };

  renderPhaseDeleteIcon = () => {
    const { userIsAdmin, canDelete } = this.props;

    const dataFor = canDelete ? 'app-tooltip' : 'admin-tooltip';
    const noPermissionTip = `${'You must have Project Budget Edit Permissions to remove this Phase.'} ${
      userIsAdmin ? '' : ' Contact Your Admin(s)'
    }`;
    const dataTip = `${canDelete ? '' : noPermissionTip}`;
    return (
      <>
        <DeleteIconContainer
          className="delete-icon-container"
          onClick={this.setDeleteMilestoneId}
          data-for={dataFor}
          data-effect="solid"
          data-class="phase-milestone-tooltip"
          data-tip={dataTip}
          canDelete={canDelete}
        >
          <DeleteIcon />
        </DeleteIconContainer>
      </>
    );
  };

  isValid = () => true;

  _predictPhase = (phase) => {
    const { predictPhase } = this.props;
    if (this.isValid()) {
      predictPhase(phase);
    }
  };

  memberFilter = (members) => {
    const { phase } = this.props;
    const phaseMembershipsByAccountId = keyBy(
      phase?.phase_memberships?.filter((member) => !member.discarded_at),
      (item) => item.account_id
    );
    return members.filter(
      (member) => !phaseMembershipsByAccountId[member?.account?.id]
    );
  };

  renderAddMembers = () => {
    const { project, phase } = this.props;
    return (
      <BulkMemberSelector
        onClose={this.saveDates}
        projectId={project?.id}
        phaseId={phase.id}
        memberFilter={this.memberFilter}
        renderSelect={({ onClick }) => (
          <AddPlusContainer onClick={onClick}>
            <StyledAddPlusIcon />
          </AddPlusContainer>
        )}
        handleDone={this.handleDone}
        membershipLevel={'phase'}
        footerInitialCopy="Add All Project Members"
        bulkActionAffectedMemberIds={
          project?.project_membership?.map((member) => member?.account?.id) ??
          []
        }
      />
    );
  };

  renderMembers = () => {
    const { phase, teamMembershipsByAccountId, me } = this.props;
    return phase.phase_memberships
      .filter((member) => !member.discarded_at)
      .map((phaseMember) => teamMembershipsByAccountId[phaseMember.account_id])
      .filter((teamMember) => !!teamMember)
      .slice(0, 3)
      .map((teamMember, idx) => (
        <MemberInitials
          key={teamMember.account.id}
          member={teamMember}
          idx={teamMember.account.id}
          size="small"
          classes={cn(
            'regular-member selected',
            {
              selected: false
            },
            { 'logged-member': teamMember.account.id === me?.id }
          )}
          onClick={() => {}}
        />
      ));
  };

  handlePredictionAndUpdate = ({ startDate, endDate }) => {
    const { phase, predictWorkdaysAndUpdatePhase } = this.props;
    predictWorkdaysAndUpdatePhase({
      phase,
      startDate: startDate?.format('MM/DD/YYYY'),
      endDate: endDate?.format('MM/DD/YYYY')
    });
  };

  predictPhase = debounce(this._predictPhase, 300);

  render() {
    const {
      milestonesLength,
      index,
      phase,
      addNewMilestoneTemplate,
      teamMembershipsByAccountId,
      canDelete
    } = this.props;
    const { id, is_complete, order_number } = phase || {};
    const memberLength = phase.phase_memberships
      .filter((member) => !member.discarded_at)
      .map((phaseMember) => teamMembershipsByAccountId[phaseMember.account_id])
      .filter((teamMember) => !!teamMember).length;

    const milestoneIsOverdue = calcMilestoneIsOverdue(phase, today);
    const isPhaseMilestone = phase?.is_budget;
    const newDescription = isPhaseMilestone
      ? phase.name
      : this.state.newDescription;
    const newWorkDays = isPhaseMilestone
      ? phase.total_work_days
      : this.state.newWorkDays;

    const isInactive = isPhaseInactive(phase);
    const isArchived = isPhaseArchived(phase);

    const { newDates } = this.state;
    return (
      <div
        className={cn('project-milestone-row', {
          'last-element': index && index === milestonesLength - 1,
          'is-focussed': this.state.rowFocussed
        })}
        ref={(ref) => (this.calendarContainer = ref)}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        data-for="app-tooltip"
        data-effect="solid"
        data-offset="{'top': -25}"
        data-html
        data-tip={
          canDelete
            ? ''
            : 'Only PMs and Financial Managers <br/> can add Phases to Projects.'
        }
      >
        <div className="milestone-left">
          <StyledDragGrips>
            <DragGripIcon />
          </StyledDragGrips>
          <div className="milestone-index-col" onClick={this.handleClick}>
            {order_number ? (
              <div
                className={cn('project-milestone-index', {
                  'is-complete': completed_at,
                  'is-overdue': milestoneIsOverdue
                })}
              >
                <div className="project-milestone-order-number">
                  {order_number}
                </div>
              </div>
            ) : null}
          </div>

          <div className={cn('project-milestone-title-col')}>
            {isInactive && <InactiveText>INACTIVE</InactiveText>}
            {phase?.is_budget ? (
              <StyledBudgetIconContainer
                data-effect="solid"
                data-tip={'This phase is part of the project budget.'}
              >
                <StyledBudgetPhaseMilestoneIcon />
              </StyledBudgetIconContainer>
            ) : (
              <StyledBudgetIconContainer>
                <StyledMilestoneIcon />
              </StyledBudgetIconContainer>
            )}
            <MilestoneInput
              phase={phase}
              handleDescriptionChange={this.handleDescriptionChange}
              addNewMilestoneTemplate={addNewMilestoneTemplate}
              newDescription={newDescription}
              checkForEnterPress={this.checkForEnterPress}
              handleBlur={this.handleBlur}
              isPhaseMilestone={isPhaseMilestone}
            />
          </div>
        </div>
        <div className="milestone-members">
          {memberLength - 3 > 0 ? (
            <PlusMemberContainer className="member-initials">
              +{memberLength - 3}
            </PlusMemberContainer>
          ) : (
            ''
          )}

          {this.renderMembers()}
          {this.renderAddMembers()}
        </div>
        <div className="milestone-date-range">
          <DateRangeCalendar
            itemStartDate={newDates?.[0] ? newDates[0] : phase?.start_date}
            itemEndDate={newDates?.[1] ? newDates[1] : phase?.end_date}
            onSubmit={this.handlePredictionAndUpdate}
            customInput={(startDate, endDate, handleOpen) => (
              <DateInputDiv
                data-for="app-tooltip"
                data-tip={
                  isArchived ? `Unable to set dates for archived phases.` : ''
                }
              >
                <DateDiv
                  onClick={
                    isArchived ? noop : handleOpen.bind(this, START_DATE)
                  }
                >
                  {startDate
                    ? moment(startDate).format('MM/D/YY')
                    : 'Start Date'}
                </DateDiv>
                <div>-</div>
                <DateDiv
                  onClick={isArchived ? noop : handleOpen.bind(this, END_DATE)}
                >
                  {endDate ? moment(endDate).format('MM/D/YY') : 'End Date'}
                </DateDiv>
              </DateInputDiv>
            )}
          />
        </div>
        <div className="milestone-right">
          <MilestoneWorkDaysInput
            phase={phase}
            handleChange={this.handleWorkDaysChange}
            newWorkDays={newWorkDays}
            checkForEnterPress={this.checkForEnterPress}
            handleBlur={this.handleBlur}
            isPhaseMilestone={isPhaseMilestone}
          />
          {isPhaseMilestone ? (
            this.renderPhaseDeleteIcon()
          ) : (
            <div onClick={this.setDeleteMilestoneId}>
              <DeleteIconContainer
                className={'delete-icon-container'}
                canDelete={canDelete}
              >
                <DeleteIcon />
              </DeleteIconContainer>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  editingMilestoneId: getEditingMilestoneId(state),
  deletingMilestoneId: getDeletingMilestoneId(state),
  selectedTeamId: getSelectedTeamId(state),
  userIsAdmin: getUserIsAdmin(state),
  teamMembershipsByAccountId: getTeamMembershipsByAccountId(state),
  me: getMe(state),
  predictedPhase: state.phases.predictedPhase,
  predictedPhaseId: state.phases.predictedPhaseId
});

const mapDispatchToProps = {
  addNewMilestoneTemplate,
  setEditMilestoneId,
  fetchPhasesByProjectIds,
  updatePhase,
  predictPhase,
  createPhaseMembers,
  predictWorkdaysAndUpdatePhase,
  checkHasTimeEntries
};

export default withRouter(
  withPermissionsCheck(
    connect(mapStateToProps, mapDispatchToProps)(MilestoneRow)
  )
);
