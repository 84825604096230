import React from 'react';
import SvgIcon from 'components/SvgIcon';

const ThinCloseIcon = ({
  width = '12px',
  height = '12px',
  fill = '#4F4F4F',
  stroke = '#4F4F4F',
  strokeWidth = '0.8',
  ...rest
}) => (
  <SvgIcon width={width} height={height} viewBox="0 0 12 12" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3854 1L5.98958 5.39062L1.61458 1.02083L1 1.63542L5.375 6L1 10.3698L1.61458 10.9792L5.98958 6.61458L10.3854 11L11 10.3906L6.60417 6L11 1.61458L10.3854 1Z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </SvgIcon>
);

export default ThinCloseIcon;
