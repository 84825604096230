import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import {
  getOrderedTeamRates,
  getOrderedCurrentTeamRates,
  getArchivedTeamRates,
  getTeamRates,
  getOrderedArchivedTeamRates,
  getIsRateEditingFlag,
  getEditMemberRateItem
} from 'BudgetModule/selectors';
import { getSelectedTeamId } from 'selectors';
import {
  StandardRatesContainer,
  StyledRateDescription,
  StyledNewRateContainer,
  StyledNewRate,
  StyledNewRateDescription,
  StyledRateMessage,
  StyledTitleText,
  StyledDescriptionText,
  StyledTitleTextContainer,
  MiniHeaderContainer,
  HeaderContainer,
  StyledMemberName,
  StyledMemberTitle,
  MiniStandardRatesHeader,
  StyledRatesRowContainer,
  StyledRatesRowOuterContainer,
  DeleteIconContainer,
  EditHeaderContainer,
  StyledBlueButton,
  StyledDeleteIcon,
  CreateStandardButton,
  ButtonContainer,
  StyledHeader,
  BackKaratContainer,
  XIconContainer,
  StyledHeaderCell,
  StyledCell,
  EmptyCell,
  EditRateContainer
} from './styles';
import BackKarat from 'icons/BackKarat';
import NewCloseIcon from 'icons/NewCloseIcon';
import { ArchiveButton } from 'BudgetModule/components/styles';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { updateTeamOrderedItems } from 'actionCreators';

import {
  createRate,
  updateRate,
  createMemberRate,
  updateMemberRate,
  editMemberRate,
  archiveRate,
  unarchiveRate
} from 'BudgetModule/actionCreators';

import withPermissionsCheck from 'hocs/withPermissionsCheck';
import { isToday } from 'appUtils/momentUtils';
import HelpIcon from 'images/help-icon-2.svg';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import {
  FormattedCurrencyByTeam,
  FormattedCurrencyByProject
} from 'components/FormatCurrency';
import {
  getTeamCurrencyCodeByTeamId,
  makeGetDefaultCurrencyCodeById
} from 'CurrencyModule/selectors';
import { getCurrencySymbol } from 'BudgetModule/components/BudgetTable/utils';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import LockWithTooltip from 'components/Tooltips/LockWithTooltip';
import { getUserIsAdmin } from 'PermissionsModule/selectors';

const colWidths = {
  showRates: '23px 90px 250px',
  editingShowRates: '0px 90px 231px',
  noRates: '23px 448px',
  editingNoRates: '0px 420px'
};

const listItemContainerStyle = `
border: none;
padding: 0px;

&:hover{
  background: none;
}

`;

const noop = () => {};
const rateCopy = {
  headerInitial: 'Select Rate',
  headerEdit: 'Edit Standard Rate',
  headerAdd: 'Add Rate/Hr',
  sticky: 'Create New Rate',
  footerInitial: (
    <LockWithTooltip
      text="Edit Standard Rate"
      tooltipContent="Only Admins can Create or Edit Standard Rate"
    />
  ),
  footerEdit: 'Done'
};

const archivedHeader = { isHeader: true, title: 'Archived' };
const getListWithArchived = createSelector(
  getOrderedCurrentTeamRates,
  getOrderedArchivedTeamRates, // change once BE updated
  (currentRates, archivedRates) => [
    ...currentRates,
    ...(archivedRates.length ? [archivedHeader] : []),
    ...archivedRates
  ]
);

class RatesDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      isEditing: this.props.isEditing || false,
      isAdding: false,
      editingItem: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isEditing && !this.state.isEditing) {
      this.setState({
        isAdding: false,
        editingItem: null
      });
    }
  }

  renderItem = ({ item, selectCallback, index, onSetItemEditing }) => {
    const { isEditing } = this.state;
    const { projectId } = this.props;

    if (this.props.renderItem) {
      return this.props.renderItem({
        item,
        selectCallback,
        index,
        isEditing
      });
    }

    const rowColWidths = isEditing
      ? colWidths.editingShowRates
      : colWidths.showRates;

    if (item.isHeader) {
      return (
        <StyledHeader>
          <span>{item?.title}</span>
        </StyledHeader>
      );
    }

    const FormattedCurrencyComponent =
      item?.rate && projectId
        ? FormattedCurrencyByProject
        : item?.rate
        ? FormattedCurrencyByTeam
        : undefined;

    const currencyFormatterProps = item?.rate && projectId ? { projectId } : {};

    return (
      <StyledRatesRowOuterContainer
        onClick={isEditing ? onSetItemEditing : undefined}
      >
        <StyledRatesRowContainer
          isArchived={item?.archived}
          colWidths={rowColWidths}
        >
          <DeleteIconContainer>
            <StyledDeleteIcon />
          </DeleteIconContainer>
          <StyledCell style={{ paddingLeft: 10 }}>
            {FormattedCurrencyComponent && (
              <FormattedCurrencyComponent
                currencyFormatterProps={currencyFormatterProps}
                currencyValue={item?.rate}
              />
            )}
          </StyledCell>
          <StyledCell>
            <span className="no-text-overflow">{item?.description}</span>
          </StyledCell>
        </StyledRatesRowContainer>
      </StyledRatesRowOuterContainer>
    );
  };

  renderTableBodyHeader = () => {
    if (this.props.renderTableBodyHeader) {
      return this.props.renderTableBodyHeader();
    }
    const headerColWidths = colWidths.showRates;

    const DeleteCell = this.state.isEditing ? StyledHeaderCell : EmptyCell;

    return (
      <MiniHeaderContainer
        colWidths={headerColWidths}
        style={{ padding: '0 32px' }}
      >
        <DeleteCell />
        <StyledHeaderCell>Rate/Hr.</StyledHeaderCell>
        <StyledHeaderCell>Description</StyledHeaderCell>
      </MiniHeaderContainer>
    );
  };

  handleSelect = (e, { item, selectCallback }) => {
    e.preventDefault();
    const {
      createMemberRate,
      updateMemberRate,
      memberBudgetId,
      activeRate,
      projectId,
      isEditing,
      editMemberRate,
      editRate
    } = this.props;
    const { id } = item;
    if (item.isHeader || isEditing) return;

    if (activeRate && isToday(moment(activeRate.start_date)) && !editRate.id) {
      updateMemberRate({
        ...activeRate,
        rate: item,
        memberBudgetId,
        projectId
      });
    } else if (editRate.id) {
      updateMemberRate({
        ...editRate,
        rate: item,
        memberBudgetId,
        projectId
      });
    } else {
      createMemberRate({
        memberBudgetId,
        rateId: id,
        projectId
      });
    }
    if (selectCallback) {
      selectCallback();
    }
    editMemberRate({
      isEditMemberRate: false,
      editMemberRateItem: {}
    });
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item,
      filterKeysArray: ['description', 'rate']
    });

  renderHeaderText = () => {
    const {
      customHeader,
      handleClose,
      headerText,
      showX = false,
      showKarat = true
    } = this.props;

    return customHeader ? (
      customHeader()
    ) : (
      <HeaderContainer>
        <BackKaratContainer showKarat={showKarat} onClick={handleClose}>
          <BackKarat />
        </BackKaratContainer>
        <StyledMemberTitle>
          {headerText || 'Select New Rate/Hr'}
        </StyledMemberTitle>

        <XIconContainer showX={showX} onClick={handleClose}>
          <NewCloseIcon fillColor={'black'} />
        </XIconContainer>
      </HeaderContainer>
    );
  };

  renderStickyRow = () => {
    return <div />;
  };

  renderEditStickyRow = () => {
    return (
      <ButtonContainer onClick={() => this.setState({ isAdding: true })}>
        <CreateStandardButton>Create Rate</CreateStandardButton>
      </ButtonContainer>
    );
  };

  renderEditHeaderText = ({ addMode }) => {
    return (
      <EditHeaderContainer>
        <HeaderContainer>
          <StyledMemberName>
            {addMode ? 'Add Bill Rate' : 'Edit Standard Rate'}
          </StyledMemberName>
        </HeaderContainer>
      </EditHeaderContainer>
    );
  };

  renderEdit = ({
    isNew,
    item,
    submitCallback = noop,
    clearEditingId = noop
  }) => {
    const { editingItem } = this.state;
    const { teamCurrencyCode, projectCurrencyCode, projectId } = this.props;

    if (!isNew && !editingItem) {
      this.setState({
        editingItem: item
      });
    }
    rebuildTooltip();

    const currencySymbol = getCurrencySymbol(
      projectId ? projectCurrencyCode : teamCurrencyCode
    );

    return (
      <EditRateContainer>
        <StyledTitleTextContainer>
          <StyledTitleText>Rate</StyledTitleText>
          <StyledDescriptionText>Description</StyledDescriptionText>
        </StyledTitleTextContainer>
        <StyledNewRateContainer
          onSubmit={(e) =>
            this.handleSubmit(e, {
              isNew,
              item,
              submitCallback,
              clearEditingId
            })
          }
        >
          <StyledNewRate
            as={NumberFormat}
            ref={(ref) => (this.rateEdit = ref)}
            defaultValue={isNew ? '' : item?.rate}
            type="text"
            prefix={currencySymbol}
            thousandSeparator={true}
            placeholder={`${currencySymbol}0`}
            decimalScale={2}
          />
          <StyledNewRateDescription
            ref={(ref) => (this.descriptionEdit = ref)}
            isInvalid={this.state.submitted && !this.descriptionLength()}
            defaultValue={isNew ? '' : item?.description}
            type="text"
            placeholder="Type rate description"
            onChange={this.clearSubmitted}
          />
          {!isNew && (
            <ArchiveButton
              type="button"
              onClick={() => this.handleArchiveClick(item, submitCallback)}
              style={{
                position: 'absolute',
                bottom: 22
              }}
            >
              {item?.archived ? 'Unarchive' : 'Archive'}
            </ArchiveButton>
          )}
          {/* submit input needed for multi input submit */}
          <input type="submit" style={{ display: 'none' }} />
        </StyledNewRateContainer>
        <StyledRateMessage>
          These are Standard Bill rates for the Organization.
          <br />
          Use Custom Rates for project specific rates.
        </StyledRateMessage>
      </EditRateContainer>
    );
  };

  getPermissions = () => {
    const { teamId } = this.props;
    return {
      teamId
    };
  };

  clearSubmitted = () => this.setState({ submitted: false });
  setSubmitted = () => this.setState({ submitted: true });
  handleSubmit = (e, { item, isNew, clearEditingId }) => {
    e.preventDefault();
    e.stopPropagation();

    this.setSubmitted();
    setTimeout(this.clearSubmitted, 400);
    if (this.getIsInvalid()) {
      return;
    }

    const description = this.descriptionEdit.value;
    const rate = this.rateEdit.state.numAsString;

    const {
      createRate,
      updateRate,
      teamId,
      setIsEditing,
      setAddMode,
      addMode
    } = this.props;

    if (isNew) {
      createRate({
        teamId,
        description,
        rate
      });
    } else {
      const { id } = item;
      updateRate({
        id,
        description,
        rate
      });
    }
    clearEditingId && clearEditingId();
    if (addMode || this.state.isAdding) {
      this.handleCloseAdd();
    }
    this.setState({ editingItem: null });
  };

  setEditing = (item) => {
    this.setState({ isEditing: !!item });
  };

  handleCloseAdd = () => {
    const { setAddMode } = this.props;
    this.setState({
      isAdding: false
    });
    setAddMode && setAddMode(false);
  };

  handleArchiveClick = (item, submitCallback) => {
    const { archiveRate, unarchiveRate } = this.props;
    item.archived ? unarchiveRate(item) : archiveRate(item);
    submitCallback();
    this.setState({ editingItem: null });
  };

  reorder = (newOrder) => {
    const { updateTeamOrderedItems, teamId } = this.props;
    updateTeamOrderedItems({
      team_id: teamId,
      rate_order: newOrder
    });
  };

  isItemDraggable = (item) => !item.archived && !item.isHeader;
  isItemUnSelectable = (item) => item.isHeader;
  isItemEditable = (item) => !item.isHeader;

  descriptionLength = () =>
    this.descriptionEdit ? this.descriptionEdit.value.trim().length : 0;

  getIsInvalid = () => {
    const { editingItem } = this.state;
    const { userIsAdmin } = this.props;
    return (
      !userIsAdmin ||
      ((editingItem || this.props.addMode || this.state.isAdding) &&
        !this.descriptionLength())
    );
  };

  render() {
    const {
      currentRates,
      handleClose,
      target,
      ratesWithArchived,
      className,
      popoverClassName,
      isAlwaysGlobal = true,
      addMode,
      editRate,
      editMemberRate,
      setIsEditing,
      userIsAdmin
    } = this.props;
    const isInvalid = this.getIsInvalid();
    const rates = this.state.isEditing ? ratesWithArchived : currentRates;
    return (
      <MultiStepFlyout
        copy={rateCopy}
        items={rates}
        idKey="id"
        renderEdit={this.renderEdit}
        renderItem={this.renderItem}
        handleSelect={this.props?.handleSelect || this.handleSelect}
        handleSubmit={this.handleSubmit}
        isWhite={true}
        itemFilter={this.itemFilter}
        reorder={this.reorder}
        handleClose={handleClose}
        isInvalid={isInvalid}
        target={target}
        stickyRow
        isAlwaysGlobal={isAlwaysGlobal}
        dragEnabled
        listWidth={424}
        minListHeight={0}
        listHeight={367}
        itemHeight={54}
        globalClassName={className || 'standard-rate-phases-global'}
        listItemContainerStyle={listItemContainerStyle}
        renderTableBodyHeader={this.renderTableBodyHeader}
        renderEditHeaderText={this.renderEditHeaderText}
        renderHeader={this.renderHeaderText}
        renderStickyRow={this.renderStickyRow}
        renderEditStickyRow={this.renderEditStickyRow}
        editMode={this.state.isEditing}
        onFooterClick={
          this.state.editingItem ||
          addMode ||
          this.state.isAdding ||
          !userIsAdmin
            ? undefined
            : () => {
                setIsEditing && setIsEditing(!this.state.isEditing);
                this.setState((state) => ({ isEditing: !state.isEditing }));
                editMemberRate({
                  isEditMemberRate: !this.state.isEditing,
                  editMemberRateItem: editRate
                });
              }
        }
        isItemDraggable={this.isItemDraggable}
        isItemUnSelectable={this.isItemUnSelectable}
        isItemEditable={this.isItemEditable}
        hideClose={true}
        popoverClassName={popoverClassName}
        addMode={addMode || this.state.isAdding}
        onCancel={
          addMode || this.state.isAdding
            ? (e) => {
                e.stopPropagation();
                this.handleCloseAdd();
              }
            : (e, { clearEditingId }) => {
                clearEditingId();
                this.setState({ editingItem: null });
              }
        }
        clearEditOnSubmit
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getProjectCurrencyCode = makeGetDefaultCurrencyCodeById();

  const mapStateToProps = (state, ownProps) => ({
    currentRates: getOrderedCurrentTeamRates(state),
    teamId: getSelectedTeamId(state),
    isEditing: getIsRateEditingFlag(state),
    editRate: getEditMemberRateItem(state),
    ratesWithArchived: getListWithArchived(state),
    teamCurrencyCode: getTeamCurrencyCodeByTeamId(state),
    projectCurrencyCode: getProjectCurrencyCode(state, {
      entityType: ENTITY_TYPES.project,
      entityId: ownProps.projectId
    }),
    userIsAdmin: getUserIsAdmin(state)
  });

  return mapStateToProps;
};

const mapDispatchToProps = {
  createRate,
  updateRate,
  createMemberRate,
  updateMemberRate,
  updateTeamOrderedItems,
  editMemberRate,
  archiveRate,
  unarchiveRate
};

export default withPermissionsCheck(
  connect(makeMapStateToProps, mapDispatchToProps)(RatesDropdown)
);
