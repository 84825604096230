import { createSelector } from 'reselect';
import { createSelectorCache } from 'appUtils/reselect';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import partition from 'lodash/partition';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/orderBy';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import {
  findDefaultBoardId,
  findDefaultIntegrationBoard
} from 'IntegrationsModule/utils';

import { getTimesheetsState, getMe } from './core';

import {
  createTimesheetWeekKeys,
  checkTimesheetsForAllNotSubmittedOrRejected
} from 'appUtils/timesheetUtils';
import { findMostRecent, getMondayOfWeek } from 'appUtils/momentUtils';

import { getAllTeams } from './team';
import { getProjectHash, getOwnProjectId } from './projects';
import { getFlatPhasesHash } from './phases';
import { TIMESHEET_STATUSES } from 'appConstants/timesheets';

import { VIEW_BY } from 'appConstants/workload';
import isEqual from 'lodash/isEqual';

const emptyArray = [];
const moment = extendMoment(Moment);

// todo resolve import dependency loop with ./planner and BudgetModule
const getPositionsState = (state) => state.positions;

const getPositions = createSelector(
  getPositionsState,
  (state) => state?.positions
);

const getMemberBudgetsState = (state) => state.memberBudgets;

export const getMemberBudgets = (state) =>
  state.memberBudgets?.memberBudgets ?? emptyObj;

export const getFetchedMemberBudgetProjectIds = (state) =>
  state.memberBudgets?.fetchedMemberBudgetProjectIds || emptyObj;

const getMemberBudgetsWithPositions = createSelector(
  getMemberBudgets,
  getPositions,
  (memberBudgets, positions) =>
    mapValues(memberBudgets, (memberBudget) => ({
      ...memberBudget,
      position: positions[memberBudget.position_id]
    }))
);
export const getMemberBudgetsByProjectIdWithUnassigned = createSelector(
  getMemberBudgetsWithPositions,
  (memberBudgets) => {
    const groupedByProjectId = groupBy(
      memberBudgets,
      (memberBudget) => memberBudget.project_id
    );
    const partitionUnassignedToEnd = (memberBudgetList) =>
      flatten(partition(memberBudgetList, (mb) => mb.account_id !== null));

    return mapValues(groupedByProjectId, partitionUnassignedToEnd);
  }
);

/**
 * does not handle unassigned
 */
export const getMemberBudgetsByProjectId = createSelector(
  getMemberBudgetsWithPositions,
  (memberBudgets) =>
    Object.values(memberBudgets).reduce((hash, memberBudget) => {
      if (!hash[memberBudget.project_id]) {
        hash[memberBudget.project_id] = {};
      }
      hash[memberBudget.project_id][
        memberBudget.project_membership?.account_id
      ] = memberBudget;
      return hash;
    }, {})
);

export const getMemberBudgetsWithUnassignedByProjectId = createSelector(
  getMemberBudgets,
  (memberBudgets) => {
    return groupBy(memberBudgets, (memberBudget) => memberBudget.project_id);
  }
);

export const getAllUnassignedMemberBudgets = createSelector(
  getMemberBudgetsWithPositions,
  (memberBudgets) => {
    const foundUnassignedMemberBudgetIdsHash = {};
    return Object.values(memberBudgets).reduce((acc, cur) => {
      if (
        !cur.account_id &&
        !foundUnassignedMemberBudgetIdsHash[cur.member_budget_id]
      ) {
        foundUnassignedMemberBudgetIdsHash[cur.member_budget_id] = cur;
        acc.push(cur);
      }
      return acc;
    }, []);
  }
);

export const getDeepEqualOwnProjectMemberBudgets = createSelectorCache(
  () =>
    createSelector(
      getOwnProjectId,
      getMemberBudgetsWithUnassignedByProjectId,
      (projectId, memberBudgetsByProjectId) => {
        const projectMemberBudgets =
          memberBudgetsByProjectId[projectId] || emptyArray;
        return projectMemberBudgets;
      },
      {
        memoizeOptions: {
          // result will have the same reference if has same values as previous result
          // useful for when many other selectors (with expensive calculations) depend on this one
          // see also createDeepEqualSelector
          resultEqualityCheck: isEqual
        }
      }
    ),
  'projectId',
  {
    max: 100, // cache up to 100 selectors by projectId
    maxAge: 1000 * 60 * 60 // 1 hour
  }
);

/**
 * requires ownProps.projectId and returns array of unassigned member budgets of the project
 */
export const getOwnProjectUnassignedMemberBudgets = createSelectorCache(
  () =>
    createSelector(getDeepEqualOwnProjectMemberBudgets, (memberBudgets) => {
      return memberBudgets.filter(
        (memberBudget) => memberBudget.account_id === null
      );
    }),
  'projectId',
  {
    max: 100, // cache size
    maxAge: 1000 * 60 * 60 // 1 hour
  }
);

/**
 * requires ownProps.projectId and returns { [positionId]: total count of unassigned member budgets of the same position }
 */
export const getOwnProjectUnassignedCounts = createSelectorCache(
  () =>
    createSelector(
      getOwnProjectUnassignedMemberBudgets,
      (unassignedMemberBudgets) => {
        return mapValues(
          groupBy(unassignedMemberBudgets, (mb) => mb.position_id),
          (positionGroup) => positionGroup.length
        );
      }
    ),
  'projectId',
  {
    max: 100, // cache size
    maxAge: 1000 * 60 * 60 // 1 hour
  }
);

/**
 * requires ownProps.projectId and returns { [positionId]: highest position_number of unassigned member budgets of the same position }
 */
export const getOwnHighestPositionNumberByPositionId = createSelectorCache(
  () =>
    createSelector(
      getOwnProjectUnassignedMemberBudgets,
      (unassignedMemberBudgets) =>
        mapValues(
          groupBy(unassignedMemberBudgets, (mb) => mb.position_id),
          (positionGroup) =>
            Math.max(...positionGroup.map((mb) => mb.position_number))
        )
    ),
  'projectId',
  {
    max: 100, // cache size
    maxAge: 1000 * 60 * 60 // 1 hour
  }
);

/**
 * requires ownProps.projectId
 * returns [memberBudget] of the project, with unassigned at the end of the array + adds
 * 'showPositionNumber: true' when there are more than 1 of the same unassigned role
 *
 *  hasn't been used or tested yet (!)
 */
export const getFormattedOwnProjectMemberBudgets = createSelectorCache(
  () =>
    createSelector(
      getDeepEqualOwnProjectMemberBudgets,
      getOwnProjectUnassignedCounts,
      (projectMemberBudgets, projectUnassignedRoleCounts) => {
        const partitionUnassignedToEnd = (memberBudgetList) =>
          flatten(partition(memberBudgetList, (mb) => mb.account_id !== null));

        return partitionUnassignedToEnd(projectMemberBudgets).map((mb) => {
          if (
            mb.account_id === null &&
            mb.project_number !== null &&
            projectUnassignedRoleCounts[mb.position_id] > 1
          ) {
            return {
              ...mb,
              showPositionNumber: true
            };
          }
          return mb;
        });
      }
    ),
  'projectId',
  {
    max: 100, // cache up to 100 selectors by projectId
    maxAge: 1000 * 60 * 60 // 1 hour
  }
);

/**
 * does not handle unassigned
 */
export const makeGetOwnProjectMemberBudgetsByAccountIds = () =>
  createSelector(
    getOwnProjectId,
    getMemberBudgetsByProjectId,
    (projectId, memberBudgets) => {
      return Object.values(memberBudgets[projectId] || {}).reduce(
        (acc, cur) => {
          acc[cur.project_membership?.account_id] = cur;
          return acc;
        },
        {}
      );
    }
  );
const getTimesheetViewBy = (state, ownProps) =>
  ownProps?.viewBy || state.workloadPlannerFilter.timesheetViewBy;

const getTimesheetIsWeek = (state, ownProps) =>
  ownProps?.isWeek ??
  (state.workloadPlannerFilter.timesheetIsWeek === true ||
    state.workloadPlannerFilter.timesheetIsWeek === 'true');

export const getUseTimesheetIsWeek = createSelector(
  getTimesheetIsWeek,
  getTimesheetViewBy,
  (isWeek, viewBy) => isWeek && viewBy === VIEW_BY.MEMBERS
);

const byId = (item) => item && item.id;
const byAccountId = (item) => item?.account?.id;
const emptyObj = {};

export const getOwnTimesheets = (state, ownProps) => ownProps.timesheets;

export const getDescriptionsHash = createSelector(
  getTimesheetsState,
  (state) => state.descriptions
);
export const getAllDescriptions = createSelector(
  getTimesheetsState,
  (state) => state.descriptions
);

export const getAllActivityRowInfo = createSelector(
  getTimesheetsState,
  (state) => state.activityRows
);
export const getIsFetchingActivities = createSelector(
  getTimesheetsState,
  (state) => state.isFetchingActivities
);

export const getIsActivitiesLoaded = createSelector(
  getTimesheetsState,
  (state) => state.isActivitiesLoaded
);

export const getAllTimesheetsByDescription = createSelector(
  getTimesheetsState,
  (state) => state.timesheets
);

export const getFlatTimesheets = createSelector(
  getAllTimesheetsByDescription,
  (timesheetsByDescription) =>
    flatten(
      Object.values(timesheetsByDescription).map((timesheetsUnderDescription) =>
        Object.values(timesheetsUnderDescription)
      )
    )
);
export const getAreTimeEntriesStillCreating = createSelector(
  getFlatTimesheets,
  (timesheets) => timesheets.some((timesheet) => timesheet.id === 'new')
);

export const getAllDescriptionInfo = createSelector(
  getTimesheetsState,
  (state) => state.descriptions
);
export const getIsLoadingDescriptions = createSelector(
  getTimesheetsState,
  (timesheetsState) => timesheetsState.isLoadingDescriptions
);

export const getDescriptions = createSelector(
  getDescriptionsHash,
  (descriptions) => Object.values(descriptions)
);

export const getPlannerModalDates = (state) => ({
  visibleTimeStart: state.projectPlannerModal.visibleTimeStart,
  visibleTimeEnd: state.projectPlannerModal.visibleTimeEnd
});

export const getDisplayedDescriptions = createSelector(
  getAllTimesheetsByDescription,
  getDescriptions,
  getPlannerModalDates,
  (
    allTimesheetsByDescription,
    descriptions,
    { visibleTimeStart, visibleTimeEnd }
  ) => {
    const timesheetKeys = createTimesheetWeekKeys(visibleTimeStart);
    const startDate = visibleTimeStart.clone().format('MM/DD/YYYY');
    const displayedDescriptionIds = keyBy(
      descriptions
        .filter((description) => description.date === startDate)
        .map((description) => ({ id: description.id })),
      byId
    );

    const displayedTimesheets = Object.entries(
      allTimesheetsByDescription
    ).reduce(
      (reducedDescriptions, [descriptionId, timesheetsObj]) => {
        timesheetKeys.forEach((timesheetKey) => {
          if (timesheetsObj[timesheetKey]) {
            if (!reducedDescriptions[descriptionId]) {
              reducedDescriptions[descriptionId] = {};
            }
            reducedDescriptions[descriptionId][timesheetKey] =
              timesheetsObj[timesheetKey];
          }
        });

        return reducedDescriptions;
      },
      { ...displayedDescriptionIds }
    );
    return displayedTimesheets;
  }
);
export const getFormattedDisplayedDiscriptions = createSelector(
  getDisplayedDescriptions,
  getAllDescriptions,
  getAllActivityRowInfo,
  (displayedDescriptions, allDescriptions, allActivities) => {
    const formattedDescriptions = Object.entries(displayedDescriptions).reduce(
      (descriptionsHash, [descriptionId, timesheetsEntriesByDate]) => {
        if (!descriptionsHash[descriptionId]) {
          const description = allDescriptions[descriptionId];
          const activity =
            (allActivities &&
              description &&
              allActivities[description.activity_id]) ||
            {};
          descriptionsHash[descriptionId] = {
            timesheetsByDate: timesheetsEntriesByDate,
            id: descriptionId,
            ...description,
            titleRequired: activity.require_title
          };
        }
        return descriptionsHash;
      },
      {}
    );
    return formattedDescriptions;
  }
);
const getTimesheetMountedTime = (state) => state.timesheets.time;

/**
 * @deprecated use typesafe selector instead
 */
const getTeamMembershipsByAccountId = createSelector(
  getAllTeams,
  (allTeams) =>
    (allTeams[0] &&
      allTeams[0].team_members &&
      keyBy(allTeams[0].team_members, byAccountId)) ||
    emptyObj
);

const getMyTeamMembership = createSelector(
  getTeamMembershipsByAccountId,
  getMe,
  (teamMemberships, me) => teamMemberships[me.id]
);

export const getSortedAndGroupedDisplayedDescriptions = createSelector(
  getFormattedDisplayedDiscriptions,
  getTimesheetMountedTime,
  getMyTeamMembership,
  (descriptions, timeMounted, myTeamMembership) => {
    const showInOrder =
      myTeamMembership?.timesheet_preferences?.group_by === 'asEntered';
    if (showInOrder) {
      return Object.values(descriptions).sort((a, b) => +b.id - +a.id);
    }
    // show 'by project'
    // split rows for two different sort conditions
    const [rowsCreatedBeforeMount, rowsCreatedAfterMount] = partition(
      descriptions,
      (description) =>
        moment(description.created_at).isBefore(moment(timeMounted).valueOf())
    );
    // sort new rows by created at
    const sortedNewRows = rowsCreatedAfterMount
      .slice()
      .sort((a, b) => +b.id - +a.id);

    /* group old rows by project and sort groups by most recently created
      this results in new rows appending to the top without affecting prior sort order. On refresh/remount, the new rows are old rows and group appropriately
    */

    const groupedByProjectId = groupBy(
      rowsCreatedBeforeMount,
      (description) => description.project_id
    );

    const sortedByMostRecentCreatedAt = sortBy(
      groupedByProjectId,
      (descriptions) => {
        const mostRecent = findMostRecent(
          descriptions,
          (description) => description.created_at
        );
        const mostRecentValue = mostRecent
          ? moment(mostRecent.created_at).valueOf()
          : 0;

        return -mostRecentValue;
      }
    );
    const groupedRowsCreatedBeforeMount = flatMap(
      sortedByMostRecentCreatedAt,
      (descriptions) =>
        sortBy(
          descriptions,
          (description) => -moment(description.created_at).valueOf()
        )
    );
    return [...sortedNewRows, ...groupedRowsCreatedBeforeMount];
  }
);
export const getDisplayedDescriptionsProjectIds = createSelector(
  getSortedAndGroupedDisplayedDescriptions,
  (descriptions) => [
    ...new Set(descriptions.map((description) => description.project_id))
  ]
);

export const getDescriptionsProjectIdsString = createSelector(
  getDisplayedDescriptionsProjectIds,
  (projectIds) => JSON.stringify(projectIds.join())
);

export const getTimesheetProjectsOrderIndex = createSelector(
  getDisplayedDescriptionsProjectIds,
  (projectIds) =>
    projectIds.reduce((indexHash, projectId, index) => {
      indexHash[projectId] = index;
      return indexHash;
    }, {})
);

export const getShowTotals = createSelector(
  getTimesheetsState,
  (state) => state.showTotals
);
export const getSubmissionDates = createSelector(
  getTimesheetsState,
  (state) => state.selectedSubmissionDates
);
export const makeGetDescriptionIsEditible = () =>
  createSelector(getOwnTimesheets, checkTimesheetsForAllNotSubmittedOrRejected);

export const getAllTimesheets = (state) => state.timesheets.timesheetHash;
export const getAllTimesheetOrder = (state) => state.timesheets.timesheetOrder;
export const getOrderedTimesheets = createSelector(
  getAllTimesheets,
  getAllTimesheetOrder,
  (timesheets, order) =>
    order.map((id) => timesheets[id]).filter((timesheet) => timesheet)
);
// todo: resolve import dependency between this and selectors/index

// Intentionally leaving out personal boards for now
// due to potential side effects, but including them in groupsHash below
const getAllGroups = (state) => state.groups.groupList;
export const getGroups = createSelector(getAllGroups, (groups) =>
  groups.filter((board) => !board.is_administrative)
);
export const getDefaultIntegrationBoard = createSelector(getGroups, (boards) =>
  findDefaultIntegrationBoard(boards)
);
export const getPersonalBoards = (state) => state.groups.personalBoards;

export const getGroupsHash = createSelector(
  getAllGroups,
  getPersonalBoards,
  (groups, personalBoards) => keyBy([...groups, ...personalBoards], byId)
);

// end todo

export const getTimesheetSortState = createSelector(
  getTimesheetsState,
  (state) => state.sortBy
);
export const getBatchSelectedTimesheetsHash = createSelector(
  getTimesheetsState,
  (state) => state.selectedTimesheetsHash
);

export const getBatchSelectedTimesheetIds = createSelector(
  getBatchSelectedTimesheetsHash,
  (timesheetHash) =>
    Object.entries(timesheetHash)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
);

export const getIsAllTimesheetsBatchSelected = createSelector(
  getAllTimesheetOrder,
  getBatchSelectedTimesheetsHash,
  (timesheetOrder, batchSelectedTimesheets) =>
    timesheetOrder.every((id) => batchSelectedTimesheets[id])
);

export const getIsSomeTimesheetsBatchSelected = createSelector(
  getAllTimesheetOrder,
  getBatchSelectedTimesheetsHash,
  (timesheetOrder, batchSelectedTimesheets) =>
    timesheetOrder.some((id) => batchSelectedTimesheets[id])
);

export const getNumberTimesheetsSelected = createSelector(
  getAllTimesheetOrder,
  getBatchSelectedTimesheetsHash,
  (timesheetOrder, batchSelectedTimesheets) =>
    timesheetOrder.filter((id) => batchSelectedTimesheets[id]).length
);

export const getTimesheetDateRange = createSelector(
  (state, ownProps) => ownProps?.startDate,
  (state, ownProps) => ownProps?.endDate,
  getTimesheetsState,
  (start, end, state) =>
    start && end
      ? {
          start,
          end
        }
      : state.dateRange
);

export const getTimesheetReportDateRange = createSelector(
  getTimesheetDateRange,
  getUseTimesheetIsWeek,
  (dateRange, isWeek) =>
    isWeek
      ? {
          start: getMondayOfWeek(moment(dateRange.end)).valueOf(),
          end: getMondayOfWeek(moment(dateRange.end)).add(6, 'days').valueOf()
        }
      : {
          start: moment(dateRange.start).valueOf(),
          end: moment(dateRange.end).valueOf()
        }
);

export const getCreateRowData = createSelector(
  getTimesheetsState,
  (state) => state.createRowData
);
export const getIsAddingRow = createSelector(
  getTimesheetsState,
  (state) => state.isAddingNewRow
);

// common output selector used by multiple createSelectors below
const formatTimesheets = (
  timesheets,
  batchSelectedTimesheets,
  activities,
  teamMembersByAccountId,
  projects,
  boards,
  teamRates,
  phases,
  memberBudgets
) =>
  Object.values(timesheets).map((timesheet) => {
    let approverIdToUse;
    let lastUpdatedAt;
    if (timesheet.status === TIMESHEET_STATUSES.APPROVED) {
      approverIdToUse = timesheet.approver_id;
      lastUpdatedAt = timesheet.approved_at;
    } else if (timesheet.status === TIMESHEET_STATUSES.REJECTED) {
      approverIdToUse = timesheet.rejecter_id;
      lastUpdatedAt = timesheet.rejected_at;
    }

    const isSelected = batchSelectedTimesheets[timesheet?.id];
    const member = teamMembersByAccountId[timesheet?.account_id];
    const activity = activities[timesheet?.activity_id];
    const project = projects[timesheet?.project_id];
    const phase = phases[timesheet?.phase_id];

    const memberBudget =
      timesheet?.account_id && timesheet.account_id !== 0
        ? memberBudgets[timesheet.project_id]?.[timesheet.account_id]
        : undefined;
    const board = boards[timesheet?.board_id];
    const approver = approverIdToUse
      ? teamMembersByAccountId[approverIdToUse]
      : null;

    return {
      ...timesheet,
      isSelected,
      member,
      approver,
      lastUpdatedAt,
      activity,
      project,
      phase,
      memberBudget,
      board
    };
  });
// convert createRow to compatible shape to merge with table rows
export const getCreateRowDataForSerialization = createSelector(
  getCreateRowData,
  getAllActivityRowInfo,
  getProjectHash,
  getFlatPhasesHash,
  (createRowData, activities, projects, phases) => {
    const activity = activities[createRowData?.activity_id];
    const project = projects[createRowData?.project_id];
    const phase = phases[createRowData?.phase_id];
    const billable = activity?.billable && project?.billable && phase?.billable;
    return [{ ...createRowData, createRow: true, billable }];
  }
);

export const getFormattedTimesheets = createSelector(
  getOrderedTimesheets,
  getBatchSelectedTimesheetsHash,
  getAllActivityRowInfo,
  getTeamMembershipsByAccountId,
  getProjectHash,
  getGroupsHash,
  // getTeamRates  todo: resolve import dependencies
  (state) => state.rates?.rates ?? emptyObj,
  getFlatPhasesHash,
  getMemberBudgetsByProjectId,
  formatTimesheets
);
export const getFormattedCreateRow = createSelector(
  getCreateRowDataForSerialization,
  getBatchSelectedTimesheetsHash,
  getAllActivityRowInfo,
  getTeamMembershipsByAccountId,
  getProjectHash,
  getGroupsHash,
  // getTeamRates  todo: resolve import dependencies
  (state) => state.rates?.rates ?? emptyObj,
  getFlatPhasesHash,
  getMemberBudgetsByProjectId,
  formatTimesheets
);

const extractTimesheetInfo = ({
  isSelected,
  member,
  activity,
  project,
  phase,
  board,
  description_id,
  description_title
}) => ({
  isSelected,
  member,
  activity,
  project,
  phase,
  board,
  description_id,
  description_title,
  account_id: member?.account?.id
});

export const getFormattedTimesheetsWithCreateRow = createSelector(
  getIsAddingRow,
  getFormattedTimesheets,
  getFormattedCreateRow,
  getUseTimesheetIsWeek,
  getTimesheetReportDateRange,
  (isAddingRow, timesheets, createRow, isWeek, { start, end }) => {
    const rangeToInclude = moment.range(start, end);
    const timesheetsToUse = timesheets.filter((timeEntry) =>
      moment(timeEntry.date).within(rangeToInclude)
    );
    if (isWeek) {
      const groupedByDescription = groupBy(
        timesheetsToUse,
        (timesheet) => timesheet.description_id
      );
      const mappedToDescriptionShape = map(
        groupedByDescription,
        (timesheets) => {
          const [timesheet] = timesheets;
          return {
            timesheets: keyBy(timesheets, (timesheet) => timesheet.date),
            ...extractTimesheetInfo(timesheet)
          };
        }
      );
      return mappedToDescriptionShape;
    } else {
      return isAddingRow ? [...createRow, ...timesheets] : timesheets;
    }
  }
);

export const getTimesheetOffset = createSelector(
  getTimesheetsState,
  (state) => state.offset
);
export const getAllSelectedMemberIds = createSelector(
  getOrderedTimesheets,
  getBatchSelectedTimesheetsHash,
  (allTimesheets, batchSelectedTimesheetIds) => {
    const batchSelectedTimesheets = allTimesheets.filter(
      (timesheet) => batchSelectedTimesheetIds[timesheet.id]
    );
    const accountIds = batchSelectedTimesheets.map(
      (timesheet) => timesheet.account_id
    );
    return accountIds;
  }
);

export const getAllSelectedTimesheetsMatchMember = createSelector(
  getAllSelectedMemberIds,
  (accountIds) => {
    const uniqueIds = Array.from(new Set(accountIds));
    return uniqueIds.length < 2;
  }
);
export const getAllSelectedTimesheetsHaveOverrideRate = createSelector(
  getAllTimesheets,
  getBatchSelectedTimesheetIds,
  (timesheets, selectedTimesheetIds) => {
    return selectedTimesheetIds.every(
      (id) =>
        timesheets[id]?.override_rate || timesheets[id]?.override_rate === 0
    );
  }
);

export const getSelectedTimesheetsProjectId = createSelector(
  getAllTimesheets,
  getBatchSelectedTimesheetIds,
  (timesheets, selectedTimesheetIds) => {
    let projectId = null;
    if (selectedTimesheetIds.length) {
      const firstProjectId = timesheets[selectedTimesheetIds[0]].project_id;
      if (
        selectedTimesheetIds.every(
          (id) => timesheets[id].project_id === firstProjectId
        )
      ) {
        projectId = firstProjectId;
      }
    }
    return projectId;
  }
);

export const getTimesheetAPITotalCount = createSelector(
  getTimesheetsState,
  (state) => state.timesheetCount
);
export const getTimesheetGroupCounts = createSelector(
  getTimesheetsState,
  (state) => state.timesheetGroupCounts
);
export const getTimesheetGroupTotalHours = createSelector(
  getTimesheetsState,
  (state) => state.timesheetGroupTotalHours || emptyObj
);
export const getTimesheetGroupTotalAmount = createSelector(
  getTimesheetsState,
  (state) => state.timesheetGroupTotalAmount || emptyObj
);
export const getTimesheetsIsLoading = createSelector(
  getTimesheetsState,
  (state) => state.isLoadingTimesheetRows
);

export const getTimesheetsPredictions = createSelector(
  getTimesheetsState,
  (state) => state.timesheetsPredictions
);

export const getTimesheetsPredictionsByDate = createSelector(
  getTimesheetsPredictions,
  (predictions) => {
    return predictions.reduce((predictionHash, prediction) => {
      (predictionHash[prediction.date] = predictionHash[prediction.date] || {})[
        prediction.phase_id
      ] = prediction.estimate;
      return predictionHash;
    }, {});
  }
);

// Unused for now but may need in the future for auto timesheet
// -------------------------------------------------------------------
// export const getProportionalTimesheetsPredictions = createSelector(
//   getTimesheetsPredictions,
//   // getUserCapacityForDate, currently hard-coded as 8
//   (timesheetPreds) => {
//     const scaledTimesheetPreds = { ...timesheetPreds };
//     // Totals per day per account, as well as associated projects
//     const sumHoursPerDay = {};
//     const overCapacityDatesByAccount = {};
//     const seenProjects = {};

//     // Find out which values need to be scaled
//     Object.values(scaledTimesheetPreds).forEach((prediction) => {
//       if (
//         !prediction ||
//         !prediction.account_id ||
//         !prediction.date ||
//         !prediction.project_id ||
//         !prediction.hours
//       ) {
//         return;
//       }
//       const { account_id, date, project_id, hours } = prediction;

//       // Initializing data structures
//       if (!sumHoursPerDay[account_id]) {
//         sumHoursPerDay[account_id] = {};
//         overCapacityDatesByAccount[account_id] = new Set();
//         seenProjects[account_id] = {};
//       }
//       if (!sumHoursPerDay[account_id][date]) {
//         sumHoursPerDay[account_id][date] = { total: 0, projectIds: [] };
//         seenProjects[account_id][date] = new Set();
//       }
//       // Add to day totals only if the project hasn't been seen yet
//       if (!seenProjects[account_id][date].has(project_id)) {
//         seenProjects[account_id][date].add(project_id);
//         sumHoursPerDay[account_id][date].projectIds.push(project_id); // Remember the project in case we need to scale the hours
//         sumHoursPerDay[account_id][date].total += hours;
//         if (sumHoursPerDay[account_id][date].total > 8) {
//           overCapacityDatesByAccount[account_id].add(date);
//         }
//       }
//     });

//     // Go through over-capacity dates, and re-calculate proportional predicted hours
//     for (const [accountId, dates] of Object.entries(
//       overCapacityDatesByAccount
//     )) {
//       dates.forEach((d) => {
//         const totalForDate = sumHoursPerDay[accountId][d].total;
//         const ratio = 8 / totalForDate;
//         for (const projectId of sumHoursPerDay[accountId][d].projectIds) {
//           const key = `${d}-${accountId}-${projectId}`;
//           scaledTimesheetPreds[key] = {
//             ...scaledTimesheetPreds[key],
//             hours: (scaledTimesheetPreds[key].hours *= ratio)
//           };
//         }
//       });
//     }

//     return scaledTimesheetPreds;
//   }
// );

export const getIsSettingsModalOpen = createSelector(
  getTimesheetsState,
  (state) => state.isSettingsModalOpen
);

export const getDateRangeProjectActivities = createSelector(
  getTimesheetsState,
  (state) => state.dateRangeProjectActivities
);

export const getShowTimesheetMovedMessage = createSelector(
  getTimesheetsState,
  (state) => state.showTimesheetMovedMessage
);

const getOwnFilterStateId = (state, ownProps) => {
  return (
    ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id
  );
};

export const makeGetTimesheetsUtilizationByFilter = () =>
  createSelector(
    getOwnFilterStateId,
    getTimesheetsState,
    (filterId, state) => state.utilizationByFilter?.[filterId] || emptyArray
  );

export const makeGetIsLoadingUtilizationByFilter = () =>
  createSelector(
    getOwnFilterStateId,
    getTimesheetsState,
    (filterId, state) => state.loadingUtilizationByFilter[filterId]
  );

export const getTimesheetReminderDay = createSelector(
  getTimesheetsState,
  (state) => state.timesheetReminderDay
);

export const getTimesheetReminderSenderId = createSelector(
  getTimesheetsState,
  (state) => state.timesheetReminderSenderId
);

export const getIsIncompleteModalOpen = createSelector(
  getTimesheetsState,
  (state) => state.isIncompleteModalOpen
);

export const getIsSendReminderModalOpen = createSelector(
  getTimesheetsState,
  (state) => state.isSendReminderModalOpen
);

export const getIncompleteTimesheetDates = createSelector(
  getTimesheetsState,
  (state) => state.incompleteTimesheetDates ?? emptyObj
);

export const getSendReminderId = createSelector(
  getTimesheetsState,
  (state) => state.sendReminderId
);
