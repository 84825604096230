import { createAction } from '@reduxjs/toolkit';
import { createRequestTypes } from 'appUtils';
import { ACTION_META_KEY } from 'appConstants/actions';
import { createActionCreatorsMap } from 'reduxInfra/utils/createActionCreatorsMap';
import { ValueOf } from 'type-fest';
import {
  UpdateTeamMembershipParams,
  UpdateTeamMembershipSuccessResponse,
  ArchiveTeamMembersParams,
  UnarchiveTeamMembersParams,
  HardDeleteTeamMembersParams
} from './api/types';

/* --------------------------- update team membership --------------------------- */

type UpdateTeamMembershipPayload =
  ActionParamsWithMeta<UpdateTeamMembershipParams>;

const UPDATE_TEAM_MEMBERSHIP = createRequestTypes('UPDATE_TEAM_MEMBERSHIP_V2');

export const updateTeamMembership = createAction(
  UPDATE_TEAM_MEMBERSHIP.TRIGGER,
  ({ meta, ...payload }: UpdateTeamMembershipPayload) => ({ meta, payload })
);

export const updateTeamMembershipActionCreatorsMap = createActionCreatorsMap<
  UpdateTeamMembershipPayload,
  UpdateTeamMembershipSuccessResponse,
  ValueOf<Pick<ReturnType<typeof updateTeamMembership>, typeof ACTION_META_KEY>>
>(UPDATE_TEAM_MEMBERSHIP);

/* -------------------------- archive team members -------------------------- */

const ARCHIVE_TEAM_MEMBERS = createRequestTypes('ARCHIVE_TEAM_MEMBERS');

type ArchiveTeamMembersInitialPayload = ActionParamsWithMeta<
  ArchiveTeamMembersParams,
  IMeta<() => void, () => void>
>;

export const archiveTeamMembers = createAction(
  ARCHIVE_TEAM_MEMBERS.TRIGGER,
  ({ meta, ...payload }: ArchiveTeamMembersInitialPayload) => ({
    meta,
    payload
  })
);

export const archiveTeamMembersActionCreatorsMap =
  createActionCreatorsMap(ARCHIVE_TEAM_MEMBERS);

/* -------------------------- unarchive team members -------------------------- */

const UNARCHIVE_TEAM_MEMBERS = createRequestTypes('UNARCHIVE_TEAM_MEMBERS');

type UnarchiveTeamMembersInitialPayload = ActionParamsWithMeta<
  UnarchiveTeamMembersParams,
  IMeta<() => void, () => void>
>;

export const unarchiveTeamMembers = createAction(
  UNARCHIVE_TEAM_MEMBERS.TRIGGER,
  ({ meta, ...payload }: UnarchiveTeamMembersInitialPayload) => ({
    meta,
    payload
  })
);

export const unarchiveTeamMembersActionCreatorsMap = createActionCreatorsMap(
  UNARCHIVE_TEAM_MEMBERS
);

/* ------------------------ hard delete team members ------------------------ */

const HARD_DELETE_TEAM_MEMBERS = createRequestTypes('HARD_DELETE_TEAM_MEMBERS');

export const hardDeleteTeamMembers = createAction<HardDeleteTeamMembersParams>(
  HARD_DELETE_TEAM_MEMBERS.TRIGGER
);

export const hardDeleteTeamMembersActionCreatorsMap =
  createActionCreatorsMap<HardDeleteTeamMembersParams>(
    HARD_DELETE_TEAM_MEMBERS
  );
