import { useCallback, useState, useMemo } from 'react';
import get from 'lodash/get';
import set from 'lodash/fp/set';

const emptyObj = {};

export const COLLAPSE_MODES = {
  COLLAPSING: 'COLLAPSING',
  OPENING: 'OPENING'
};
const MODES = COLLAPSE_MODES;

const ROOT = 'root';
export const TOGGLE_VALUES_KEY = 'toggles';

export const defaultParentCollapseState = {
  mode: MODES.COLLAPSING,
  allCollapsed: false,
  totalNumToggles: 0,
  numToggled: 0,
  [TOGGLE_VALUES_KEY]: {}
};

const defaultParentCollapseStates = {
  [ROOT]: defaultParentCollapseState
};

const getCollapseValueAccessor = ({ toggleId }) => {
  return [TOGGLE_VALUES_KEY, toggleId].join('.');
};

/**
 * parentCollapseStates: {
 *   root: {
 *     type: root,
 *     mode: COLLAPSING,
 *     allCollapsed: false,
 *     totalNumToggles: 2,
 *     numToggled: 1,
 *     toggles: {
 *       phase-30577: true
 *     }
 *   },
 *   phase-30577: {
 *     type: phase
 *     mode: OPENING,
 *     allCollapsed: true,
 *     totalNumToggles: 3,
 *     numToggled: 0,
 *     toggles: {
 *       activity-314: false
 *     }
 *   }
 * }
 */

/**
 * Hook for controlling collapse logic of nested structures
 *
 * -  ids can be either a singular id eg. 'phase-30577', or { parentId, toggleId }.
 * -  'root' will be used as default parent when no parentId is present
 * -  Currently only cares about parent and child, so eg. if you collapse all on the parent
 *    the children will all be collapsed, but their children (the grandchildren of the entity
 *    you collapsed all) will maintain their collapse states.
 */
const useNestedCollapse = ({
  initialParentCollapseStates = defaultParentCollapseStates,
  toggleCallback,
  maxNumOpen // currently only '1' is a valid value
} = emptyObj) => {
  const [parentCollapseStates, setParentCollapseStates] = useState(
    initialParentCollapseStates
  );

  /**
   * Updates or initializes a parent state (collapse level)
   */
  const setParentCollapseState = useCallback(({ id, values }) => {
    const parentKey = id ?? ROOT;
    // initialize with default values if id does not exist yet
    setParentCollapseStates((currentValues) => ({
      ...currentValues,
      [parentKey]: {
        id: parentKey,
        ...(currentValues[parentKey] || defaultParentCollapseState),
        ...values,
        ...(values.allCollapsed && { mode: MODES.OPENING })
      }
    }));
  }, []);

  const getParentCollapseState = useCallback(
    (parentId) => {
      const parentKey = parentId ?? ROOT;
      const currentParentCollapseState = parentCollapseStates[parentKey];
      if (!currentParentCollapseState) {
        return { shouldAbort: true };
      }
      return {
        parentKey,
        currentParentCollapseState,
        setParentCollapseState
      };
    },
    [parentCollapseStates, setParentCollapseState]
  );

  const getCurrentParentCollapseState = useCallback(
    (parentId) => {
      const { currentParentCollapseState, shouldAbort } =
        getParentCollapseState(parentId);
      if (shouldAbort) return null;
      return currentParentCollapseState;
    },
    [getParentCollapseState]
  );

  const getParentAndChildValues = useCallback(
    (ids) => {
      let parentId, toggleId;
      // support simple id args eg. toggleCollapse(2)
      if (typeof ids === 'object') {
        parentId = ids.parentId;
        toggleId = ids.toggleId;
      } else {
        toggleId = ids;
      }
      const { parentKey, currentParentCollapseState, shouldAbort } =
        getParentCollapseState(parentId);
      if (shouldAbort) return { shouldAbort: true };
      if (toggleId === undefined) {
        console.error('missing toggleId');
        return { shouldAbort: true };
      }

      const valueAccessor = getCollapseValueAccessor({ toggleId }); // eg. 'toggles.phase-30577'
      const currentValue = get(currentParentCollapseState, valueAccessor);

      return {
        parentKey,
        currentParentCollapseState,
        currentValue,
        valueAccessor
      };
    },
    [getParentCollapseState]
  );

  /**
   * Collapse or expand a given id.
   * ids can be either a singular id, or { parentId, toggleId }.
   * When parentId is not given, it will default to root.
   */
  const toggleCollapse = useCallback(
    (ids) => {
      const {
        parentKey,
        currentParentCollapseState,
        currentValue,
        valueAccessor,
        shouldAbort
      } = getParentAndChildValues(ids);

      if (shouldAbort) return;

      const nextValue = !currentValue;

      // set the new value of the toggleId
      const nextParentCollapseState = set(
        valueAccessor,
        nextValue,
        maxNumOpen === 1 && nextValue
          ? { ...currentParentCollapseState, numToggled: 0, toggles: {} }
          : currentParentCollapseState
      );
      const next = nextParentCollapseState; // for readability
      // update num toggled
      next.numToggled += nextValue ? 1 : -1;

      // Check if allCollapsed needs to be updated
      if (next.mode === MODES.OPENING) {
        if (next.allCollapsed && next.numToggled > 0) {
          next.allCollapsed = false;
        } else if (!next.allCollapsed && next.numToggled === 0) {
          next.allCollapsed = true;
        }
      } else {
        // in COLLAPSING mode
        if (!next.allCollapsed && next.numToggled === next.totalNumToggles) {
          next.allCollapsed = true;
        } else if (
          next.allCollapsed &&
          next.numToggled < next.totalNumToggles
        ) {
          next.allCollapsed = false;
        }
      }

      // If all toggles have the same state, flip the mode and reset
      if (next.numToggled === next.totalNumToggles) {
        next.mode =
          next.mode === MODES.COLLAPSING ? MODES.OPENING : MODES.COLLAPSING;
        next.numToggled = 0;
        next[TOGGLE_VALUES_KEY] = {};
      }

      setParentCollapseStates((current) => ({
        ...current,
        [parentKey]: nextParentCollapseState
      }));

      toggleCallback && toggleCallback();
    },
    [getParentAndChildValues, toggleCallback, maxNumOpen]
  );

  /**
   * Collapse/expand the children of a parent. When parentId is not given, it
   * will default to root. Default behaviour is that 'expand all' will only occur
   * when all children are collapsed ie. allCollapsed = true
   */
  const toggleCollapseAll = useCallback(
    (parentId, collapseOrExpand) => {
      const { parentKey, currentParentCollapseState, shouldAbort } =
        getParentCollapseState(parentId);
      if (shouldAbort) return;
      const nextParentCollapseState = {
        ...currentParentCollapseState,
        numToggled: 0,
        [TOGGLE_VALUES_KEY]: {}
      };

      if (
        (currentParentCollapseState.allCollapsed &&
          collapseOrExpand !== 'collapse') ||
        collapseOrExpand === 'expand'
      ) {
        // expand all
        nextParentCollapseState.mode = MODES.COLLAPSING;
        nextParentCollapseState.allCollapsed = false;
      } else {
        // collapse all
        nextParentCollapseState.mode = MODES.OPENING;
        nextParentCollapseState.allCollapsed = true;
      }

      setParentCollapseStates((current) => ({
        ...current,
        [parentKey]: nextParentCollapseState
      }));

      toggleCallback && toggleCallback();
    },
    [getParentCollapseState, toggleCallback]
  );

  const getIsOpen = useCallback(
    (ids) => {
      const { currentParentCollapseState, currentValue, shouldAbort } =
        getParentAndChildValues(ids);
      if (shouldAbort) return;

      if (currentParentCollapseState.mode === MODES.COLLAPSING) {
        return !currentValue;
      } else {
        return !!currentValue;
      }
    },
    [getParentAndChildValues]
  );

  const returnValues = useMemo(
    () => ({
      setParentCollapseState,
      parentCollapseStates,
      getIsOpen,
      getParentCollapseState,
      toggleCollapse,
      toggleCollapseAll,
      getCurrentParentCollapseState,
      allCollapsed: parentCollapseStates.root?.allCollapsed
    }),
    [
      setParentCollapseState,
      parentCollapseStates,
      getIsOpen,
      getParentCollapseState,
      toggleCollapse,
      toggleCollapseAll,
      getCurrentParentCollapseState
    ]
  );

  return returnValues;
};

export default useNestedCollapse;
