import { ROW_TYPES } from '../tableConfigs';
import {
  BaseTableListItemsBuilder,
  BaseTableListItem
} from 'components/Table/types';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import { serializeId } from 'appUtils';
import { MemberGroupListItemsBuilderArgs } from './types';

/* --------------------------------- Reasons --------------------------------- */

export const useMembersGroupedListItemsBuilder = ({
  getIsOpen,
  toggleCollapse,
  setParentCollapseState,
  getCurrentParentCollapseState
}: UseNestedCollapseHookReturnedValues) => {
  const memberGroupedListItemsBuilder: BaseTableListItemsBuilder<
    BaseTableListItem,
    MemberGroupListItemsBuilderArgs
  > = ({
    order,
    parentGroupId,
    reasons,
    accountId,
    phase,
    phaseMembership,
    member,
    projectTotal,
    teamMembershipsByAccountId
  }) => {
    const listItems = order.map((reasonId, index) => {
      const reasonValue = reasons[reasonId];
      const reason = {
        [reasonId]: reasonValue
      };

      const items = {
        accountId,
        reasonId,
        rowType: ROW_TYPES.reasonRow,
        rowHeight: 40,
        id: serializeId({
          itemType: 'reason',
          id: undefined,
          ids: [parentGroupId, reasonId]
        }),
        reason,
        phase,
        phaseMembership,
        member,
        projectTotal,
        teamMembershipsByAccountId,
        isLast: index === order.length - 1
      };

      return items;
    });
    return listItems;
  };

  return memberGroupedListItemsBuilder;
};
