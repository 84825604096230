import * as constants from '../constants';
import { createAction } from 'appUtils';

const createWSRemovalAction = (type, payload, channels) => {
  const action = {
    type
  };
  if (payload) {
    action.payload = payload;
  }
  if (channels) {
    action.payload = {
      ...action.payload,
      channels
    };
  }
  return action;
};

export const fetchSpentHoursByDay = (params) =>
  createAction(constants.FETCH_SPENT_HOURS_BY_DAY.TRIGGER, params);
export const fetchPlannedHoursByDay = (params) =>
  createAction(constants.FETCH_PLANNED_HOURS_BY_DAY.TRIGGER, params);

export const fetchMemberBudgetTotals = (params) =>
  createAction(constants.FETCH_MEMBER_BUDGET_TOTALS.TRIGGER, params);
export const fetchMemberBudgets = (params) =>
  createAction(constants.FETCH_MEMBER_BUDGETS.TRIGGER, params);
export const createMemberBudget = (params) =>
  createAction(constants.CREATE_MEMBER_BUDGET.TRIGGER, params);
export const updateMemberBudget = (params) =>
  createAction(constants.UPDATE_MEMBER_BUDGET.TRIGGER, params);
export const assignMemberBudget = (params) =>
  createAction(constants.ASSIGN_MEMBER_BUDGET.TRIGGER, params);
export const fetchMemberBudgetPhase = (params) =>
  createAction(constants.FETCH_MEMBER_BUDGET_PHASE.TRIGGER, params);
export const deleteMemberBudget = (params) =>
  createAction(constants.DELETE_MEMBER_BUDGET.TRIGGER, params);
export const archiveMemberBudgetPhase = (params) =>
  createAction(constants.ARCHIVE_MEMBER_BUDGET_PHASE.TRIGGER, params);
export const unarchiveMemberBudgetPhase = (params) =>
  createAction(constants.UNARCHIVE_MEMBER_BUDGET_PHASE.TRIGGER, params);

export const createPhaseEstimation = (params) =>
  createAction(constants.CREATE_PHASE_ESTIMATION.TRIGGER, params);
export const updatePhaseEstimation = (params) =>
  createAction(constants.UPDATE_PHASE_ESTIMATION.TRIGGER, params);
export const deletePhaseEstimation = (params) =>
  createAction(constants.DELETE_PHASE_ESTIMATION.TRIGGER, params);

export const updateActivityPhase = (params) =>
  createAction(constants.UPDATE_ACTIVITY_PHASE.TRIGGER, params);
export const hardDeleteActivityPhase = (params) =>
  createAction(constants.HARD_DELETE_ACTIVITY_PHASE.TRIGGER, params);

export const openRemoveMemberModal = (params) =>
  createAction(constants.OPEN_REMOVE_MEMBER_MODAL, params);
export const closeRemoveMemberModal = (params) =>
  createAction(constants.CLOSE_REMOVE_MEMBER_MODAL, params);
export const openArchiveMemberModal = (params) =>
  createAction(constants.OPEN_ARCHIVE_MEMBER_MODAL, params);
export const closeArchiveMemberModal = (params) =>
  createAction(constants.CLOSE_ARCHIVE_MEMBER_MODAL, params);

export const openBudgetModal = (params) =>
  createAction(constants.OPEN_BUDGET_MODAL, params);
export const closeBudgetModal = (params) =>
  createAction(constants.CLOSE_BUDGET_MODAL, params);

export const budgetAccessCheck = (params) =>
  createAction(constants.BUDGET_ACCESS_CHECK, params);

export const openActivityDropdown = (params) =>
  createAction(constants.OPEN_ACTIVITY_DROPDOWN, params);

export const closeActivityDropdown = (params) =>
  createAction(constants.CLOSE_ACTIVITY_DROPDOWN, params);

export const openActivityModal = (params) =>
  createAction(constants.OPEN_ACTIVITY_MODAL, params);
export const closeActivityModal = (params) =>
  createAction(constants.CLOSE_ACTIVITY_MODAL, params);

export const updateMemberBudgetOrder = (params) =>
  createAction(constants.UPDATE_MEMBER_BUDGET_ORDER, params);

export const toggleDecimals = () => createAction(constants.TOGGLE_DECIMALS);

export const fetchTeamMembersRates = (params) =>
  createAction(constants.FETCH_TEAM_MEMBERS_RATES.TRIGGER, params);
export const updateTeamMemberRate = (params) =>
  createAction(constants.UPDATE_TEAM_MEMBER_RATE.TRIGGER, params);

export const fetchAllTeamRates = (params) =>
  createAction(constants.FETCH_ALL_TEAM_RATES.TRIGGER, params);
export const fetchMemberTeamRates = (params) =>
  createAction(constants.FETCH_MEMBER_TEAM_RATES.TRIGGER, params);
export const createMemberTeamRate = (params) =>
  createAction(constants.CREATE_MEMBER_TEAM_RATE.TRIGGER, params);
export const updateMemberTeamRate = (params) =>
  createAction(constants.UPDATE_MEMBER_TEAM_RATE.TRIGGER, params);
export const deleteMemberTeamRate = (params) =>
  createAction(constants.DELETE_MEMBER_TEAM_RATE.TRIGGER, params);

export const fetchRates = (params) =>
  createAction(constants.FETCH_RATES.TRIGGER, params);
export const createRate = (params) =>
  createAction(constants.CREATE_RATE.TRIGGER, params);
export const updateRate = (params) =>
  createAction(constants.UPDATE_RATE.TRIGGER, params);
export const archiveRate = (params) =>
  createAction(constants.ARCHIVE_RATE.TRIGGER, params);
export const unarchiveRate = (params) =>
  createAction(constants.UNARCHIVE_RATE.TRIGGER, params);
// export const deleteRate = params =>
//   createAction(constants.DELETE_RATE.TRIGGER, params);

export const fetchMemberRates = (params) =>
  createAction(constants.FETCH_MEMBER_RATES.TRIGGER, params);
export const createMemberRate = (params) =>
  createAction(constants.CREATE_MEMBER_RATE.TRIGGER, params);
export const updateMemberRate = (params) =>
  createAction(constants.UPDATE_MEMBER_RATE.TRIGGER, params);
export const deleteMemberRate = (params) =>
  createAction(constants.DELETE_MEMBER_RATE.TRIGGER, params);

/* --------------------------------- Salary --------------------------------- */
export const fetchAllTeamSalaries = (params) =>
  createAction(constants.FETCH_ALL_TEAM_SALARIES.TRIGGER, params);
export const fetchMemberSalaries = (params) =>
  createAction(constants.FETCH_MEMBER_SALARIES.TRIGGER, params);
export const createMemberSalary = (params) =>
  createAction(constants.CREATE_MEMBER_SALARY.TRIGGER, params);
export const updateMemberSalary = (params) =>
  createAction(constants.UPDATE_MEMBER_SALARY.TRIGGER, params);
export const deleteMemberSalary = (params) =>
  createAction(constants.DELETE_MEMBER_SALARY.TRIGGER, params);

/* -------------------------------- Overhead -------------------------------- */
export const fetchOverhead = (params) =>
  createAction(constants.FETCH_OVERHEAD.TRIGGER, params);
export const createOverhead = (params) =>
  createAction(constants.CREATE_OVERHEAD.TRIGGER, params);

export const fetchTeamRoles = (params) =>
  createAction(constants.FETCH_TEAM_ROLES.TRIGGER, params);
export const createTeamRole = (params) =>
  createAction(constants.CREATE_TEAM_ROLE.TRIGGER, params);
export const updateTeamRole = (params) =>
  createAction(constants.UPDATE_TEAM_ROLE.TRIGGER, params);
// export const deleteTeamRole = params =>
//   createAction(constants.DELETE_TEAM_ROLE.TRIGGER, params);

export const openProjectPhaseDetails = (params) =>
  createAction(constants.OPEN_PROJECT_PHASE_DETAILS, params);
export const closeProjectPhaseDetails = (params) =>
  createAction(constants.CLOSE_PROJECT_PHASE_DETAILS);
export const clearHoursDropdown = () =>
  createAction(constants.CLEAR_HOURS_DROPDOWN);

export const fetchProjectBudgetViewSettings = (params) =>
  createAction(constants.FETCH_PROJECT_BUDGET_VIEW_SETTINGS.TRIGGER, params);
export const updateProjectBudgetViewSettings = (params) =>
  createAction(constants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.TRIGGER, params);
export const fetchTeamBudgetViewSettings = (params) =>
  createAction(constants.FETCH_TEAM_BUDGET_VIEW_SETTINGS.TRIGGER, params);
export const updateTeamBudgetViewSettings = (params) =>
  createAction(constants.UPDATE_TEAM_BUDGET_VIEW_SETTINGS.TRIGGER, params);
export const setInitialMembersOrderByPhaseId = (params) =>
  createAction(constants.SET_INITIAL_MEMBERS_ORDER, params);
export const editMemberRate = (params) =>
  createAction(constants.EDIT_MEMBER_RATE, params);

export const bulkAssignActivity = (params) =>
  createAction(constants.BULK_ASSIGN_ACTIVITY, params);
export const bulkMoveTimeAndRemoveActivity = (params) =>
  createAction(constants.BULK_MOVE_TIME_AND_REMOVE_ACTIVITY, params);
export const bulkDeleteTimeAndRemoveActivity = (params) =>
  createAction(constants.BULK_DELETE_TIME_AND_REMOVE_ACTIVITY, params);

/* --------------------------------- Billing -------------------------------- */

export const fetchProjectBillingCategories = (params) =>
  createAction(constants.FETCH_PROJECT_BILLING_CATEGORIES.TRIGGER, params);
export const fetchProjectInvoices = (params) =>
  createAction(constants.FETCH_PROJECT_INVOICES.TRIGGER, params);
export const createProjectInvoice = (params) =>
  createAction(constants.CREATE_PROJECT_INVOICE.TRIGGER, params);
export const updateProjectInvoice = (params) =>
  createAction(constants.UPDATE_PROJECT_INVOICE.TRIGGER, params);
export const deleteProjectInvoice = (params) =>
  createAction(constants.DELETE_PROJECT_INVOICE.TRIGGER, params);
export const setBillingTableView = (view) => ({
  type: constants.SET_BILLING_TABLE_VIEW,
  payload: {
    view
  }
});

/* ----------------------------- Budget records ----------------------------- */
export const fetchBudgetRecords = (params) =>
  createAction(constants.FETCH_BUDGET_RECORDS.TRIGGER, params);
export const fetchProjectBudgetRecord = (params) =>
  createAction(constants.FETCH_PROJECT_BUDGET_RECORD.TRIGGER, params);
export const clearBudgetRecordsState = (params) =>
  createAction(constants.CLEAR_BUDGET_RECORDS_STATE, params);

/* ----------------------------- Positions ----------------------------- */

export const fetchPositions = (params) =>
  createAction(constants.FETCH_POSITIONS.TRIGGER, params);
export const fetchPosition = (params) =>
  createAction(constants.FETCH_POSITION.TRIGGER, params);
export const createPosition = (params) =>
  createAction(constants.CREATE_POSITION.TRIGGER, params);
export const updatePosition = (params) =>
  createAction(constants.UPDATE_POSITION.TRIGGER, params);
// do not use for now
export const deletePosition = (params) =>
  createAction(constants.DELETE_POSITION.TRIGGER, params);
export const archivePosition = (params) =>
  createAction(constants.ARCHIVE_POSITION.TRIGGER, params);

export const fetchTeamPositions = (params) =>
  createAction(constants.FETCH_TEAM_POSITIONS.TRIGGER, params);
export const createTeamPosition = (params) =>
  createAction(constants.CREATE_TEAM_POSITION.TRIGGER, params);
export const updateTeamPosition = (params) =>
  createAction(constants.UPDATE_TEAM_POSITION.TRIGGER, params);
export const deleteTeamPosition = (params) =>
  createAction(constants.DELETE_TEAM_POSITION.TRIGGER, params);

export const fetchMemberPositions = (params) =>
  createAction(constants.FETCH_MEMBER_POSITIONS.TRIGGER, params);
export const createMemberPosition = (params) =>
  createAction(constants.CREATE_MEMBER_POSITION.TRIGGER, params);
export const updateMemberPosition = (params) =>
  createAction(constants.UPDATE_MEMBER_POSITION.TRIGGER, params);
export const deleteMemberPosition = (params) =>
  createAction(constants.DELETE_MEMBER_POSITION.TRIGGER, params);

export const fetchAccountPositions = (params) =>
  createAction(constants.FETCH_ACCOUNT_POSITIONS.TRIGGER, params);
export const createAccountPosition = (params) =>
  createAction(constants.CREATE_ACCOUNT_POSITION.TRIGGER, params);
export const updateAccountPosition = (params) =>
  createAction(constants.UPDATE_ACCOUNT_POSITION.TRIGGER, params);
export const deleteAccountPosition = (params) =>
  createAction(constants.DELETE_ACCOUNT_POSITION.TRIGGER, params);

export const fetchPositionRates = (params) =>
  createAction(constants.FETCH_POSITION_RATES.TRIGGER, params);
export const createPositionRate = (params) =>
  createAction(constants.CREATE_POSITION_RATE.TRIGGER, params);
export const updatePositionRate = (params) =>
  createAction(constants.UPDATE_POSITION_RATE.TRIGGER, params);
export const deletePositionRate = (params) =>
  createAction(constants.DELETE_POSITION_RATE.TRIGGER, params);

export const createPositionSkills = (params) =>
  createAction(constants.CREATE_POSITION_SKILLS.TRIGGER, params);
export const updatePositionSkills = (params) =>
  createAction(constants.UPDATE_POSITION_SKILLS.TRIGGER, params);
export const fetchPositionSkills = (params) =>
  createAction(constants.FETCH_POSITION_SKILLS.TRIGGER, params);
export const deletePositionSkills = (params) =>
  createAction(constants.DELETE_POSITION_SKILLS.TRIGGER, params);

export const updatePositionLocationRange = (params) =>
  createAction(constants.UPDATE_POSITION_LOCATION_RANGE.TRIGGER, params);
export const deletePositionLocationRange = (params) =>
  createAction(constants.DELETE_POSITION_LOCATION_RANGE.TRIGGER, params);
