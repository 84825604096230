import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  startTimer,
  endTimer,
  handleErrorMessage,
  triggerTasksAttributesUpdate
} from 'actionCreators';
import { getActiveTimer } from 'selectors';
import moment from 'moment';
import { Task, TaskId } from 'models/task';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getAuthToken } from 'AuthenticationModule/selectors';
import { getMe } from 'UsersModule/selectors';
import { formatOnSuccess } from 'appUtils/formatUtils';
import { GENERIC_ACTION } from 'appConstants';

export const useTimer = () => {
  const dispatch = useAppDispatch();
  const me = useAppSelector(getMe);
  const activeTimer = useAppSelector(getActiveTimer);
  const token = useAppSelector(getAuthToken);
  const teamId = useAppSelector(getSelectedTeamId);

  const handleStartTimer = (task: Task) => {
    const isAssignedToMe = task.assignee_ids.some((id) => id === me?.id);
    const isCompleted = !!task.completed_at;
    if (!isAssignedToMe) {
      handleAssignThenStartTimer(task);
    } else if (isCompleted) {
      handleStartCompletedTask(task);
    } else {
      startTimerWorker(task);
    }
  };

  const handleAssignThenStartTimer = (task: Task) => {
    dispatch(
      triggerTasksAttributesUpdate({
        token,
        body: {
          task_ids: [task.id],
          assignee_ids: [...task.assignee_ids, me?.id]
        },
        permissions: {
          teamId
        },
        options: undefined,
        onSuccess: formatOnSuccess(() => {
          startTimerWorker(task);
          dispatch(
            handleErrorMessage({
              type: GENERIC_ACTION,
              isFeError: true,
              errorMessage:
                'You have been assigned this task. You must be assigned to this task to start a timer.'
            })
          );
        })
      })
    );
  };

  const handleStartCompletedTask = (task: Task) => {
    startTimerWorker(task);
    dispatch(
      handleErrorMessage({
        type: GENERIC_ACTION,
        isFeError: true,
        errorMessage: 'You’ve started a timer on a completed task.'
      })
    );
  };

  const startTimerWorker = (task: Task) => {
    if (me) {
      dispatch(
        startTimer({
          account_id: me.id,
          task_id: task.id,
          activity_phase_id: task.activity_phase_id,
          activity_id: task.activity_id,
          phase_id: task.phase_id,
          project_id: task.project_id,
          date: moment().format('YYYY-MM-DD'), // will always be the current date
          auto_generate_check_in: true
        })
      );
    }
  };

  const handleEndTimer = () => {
    if (me) {
      dispatch(
        endTimer({
          account_id: me.id
        })
      );
    }
  };

  const getIsTaskActiveTimer = (taskId: TaskId) =>
    activeTimer && activeTimer.task_id === taskId;

  const toggleTimer = (task: Task) => {
    const isTaskActiveTimer = getIsTaskActiveTimer(task.id);
    if (isTaskActiveTimer) {
      handleEndTimer();
    } else {
      handleStartTimer(task);
    }
  };

  return {
    activeTimer,
    startTimer: handleStartTimer,
    endTimer: handleEndTimer,
    getIsTaskActiveTimer,
    toggleTimer
  };
};
