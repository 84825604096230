import React from 'react';

export class OnboardForm extends React.Component {
  constructor(props) {
    super(props);
    this.formSubmit = this.formSubmit.bind(this);
    this.skipToHome = this.skipToHome.bind(this);
    this.state = {
      disabled: true,
      company: '',
      invites: '',
      loaded: false
    };
  }

  componentWillMount() {
    const { users, auth } = this.props;
    if (auth.token && !users.me) {
      this.props.fetchUser();
    }
  }

  // remove when adding members during signup reincorporated
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { users, redirectToHome } = this.props;

    if (users.me) {
      const nextHasCompany = nextProps.users.me.hasCompany;

      if (nextHasCompany) {
        // let element = document.querySelector(
        //   '.auth-modal-submit.set-password-modal-actions button'
        // );
        this.nextBtn.disabled = false;

        redirectToHome();
      }
    }
  }

  handleChange(event) {
    const name = event.target.name;
    if (this.state.company) {
      this.setState({
        [name]: event.target.value,
        disabled: false
      });
      return;
    }
    this.setState({ [name]: event.target.value });
  }

  formSubmit(event) {
    event.preventDefault();
    let element = document.querySelector(
      '.auth-modal-submit.set-password-modal-actions button'
    );

    if (this.state.invites) {
      const { invites } = this.state;
      let allEmails = invites.split(';');
      allEmails = allEmails.filter(function (email) {
        var re =
          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return re.test(email.trim());
      });
      allEmails.forEach((email) => this.props.inviteMembers(email));

      element.disabled = true;
      this.setState({ invites: '' });
      this.props.redirectToHome();
      return;
    }
    if (this.state.company) {
      const { company } = this.state;
      const { auth, profileSet } = this.props;

      element.disabled = true;
      this.setState({ company: '' });
      profileSet(auth.token, { company, accountId: auth.account.id });
    }
  }

  skipToHome() {
    this.props.redirectToHome();
  }

  render() {
    const { users } = this.props;
    const inviteMembers = false;

    return (
      <div className="input-invite">
        {users.me ? (
          (users.hasCompany || users.me.hasCompany) && inviteMembers ? ( // switch to disable adding additional members
            <div className="invite-members">
              <h2>Invite Members</h2>
              <h6>Get more done by inviting team members</h6>
              <textArea
                placeholder="Type email addresses (separated by a semicolon)"
                name="invites"
                onChange={this.handleChange.bind(this)}
              />
              <div className="auth-modal-submit">
                <button type="submit" onClick={this.formSubmit}>
                  Send
                </button>
                <div className="skip" onClick={this.skipToHome}>
                  Skip for Now
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h2>Welcome!</h2>
              <h6>Please enter your company&#39;s name</h6>
              <div className="set-company">
                <form onSubmit={this.formSubmit.bind(this)}>
                  <input
                    type="text"
                    placeholder="Company Name (e.g My Company LLC)"
                    name="company"
                    className="user-input"
                    onChange={this.handleChange.bind(this)}
                  />
                  <div
                    name="company"
                    className="auth-modal-submit set-password-modal-actions"
                  >
                    <button
                      ref={(element) => (this.nextBtn = element)}
                      type="submit"
                    >
                      Next
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )
        ) : null}
      </div>
    );
  }
}

export default OnboardForm;
