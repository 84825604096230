import { useCallback, useMemo, useEffect } from 'react';
import { useMount } from 'react-use';
import { useAppSelector } from 'reduxInfra/hooks';
import useBudgetProjectAndPhasesMemberships from 'BudgetModule/hooks/useBudgetProjectAndPhasesMemberships';
import { LIST_ITEM_TYPES } from 'SuggestionModule/components/FindPeople/List/constants';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import useProjectTeamListItemsBuilder from './useProjectTeamListItemsBuilder';
import { PhaseListItem } from '../types';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';

const parentId = 'main';

const useProjectTeamListBuilder = ({
  phasesOrder,
  projectId,
  openBudgetModal,
  getIsOpen,
  toggleCollapse,
  getCurrentParentCollapseState,
  setParentCollapseState,
  toggleCollapseAll
}: UseNestedCollapseHookReturnedValues & {
  phasesOrder: number[];
  projectId: number;
  openBudgetModal: () => void;
}) => {
  const {
    getFormattedPhaseMembershipHashOfPhase,
    getFlattenedPartitionedPhaseMembershipListByPhaseId,
    getPhase
  } = useBudgetProjectAndPhasesMemberships({
    projectId,
    phaseIds: phasesOrder
  });
  const teamMembershipByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  const itemsBuilder = useProjectTeamListItemsBuilder({
    getIsOpen,
    toggleCollapse,
    getCurrentParentCollapseState,
    setParentCollapseState,
    toggleCollapseAll
  });

  // this hook only remounts when projectId changes (selecting a new project)
  useMount(() => {
    toggleCollapseAll(parentId, 'collapse');
  });

  const projectTeamListBuilder = () => {
    const list = phasesOrder.reduce((acc: PhaseListItem[], phaseId: number) => {
      const phase = getPhase(phaseId);

      // Hiding default phase
      if (phase && !phase.is_like_default) {
        const phaseToggleId = `projectId-${projectId}-phaseId-${phaseId}`;
        const isPhaseOpen = getIsOpen({
          toggleId: phaseToggleId,
          parentId
        });

        const phaseMembershipsOrder =
          getFlattenedPartitionedPhaseMembershipListByPhaseId({
            phaseId
          }).map((pm) => pm.id);

        // Phase membership
        const phaseMembershipHashOfPhase =
          getFormattedPhaseMembershipHashOfPhase({
            phaseId
          });

        // Filter out phase membership that is not archived and defined
        const filteredPhaseMembershipsOrder = phaseMembershipsOrder.filter(
          (phaseMembershipId) => {
            const phaseMembership =
              phaseMembershipHashOfPhase[phaseMembershipId];
            const phaseMembershipAccountId = phaseMembership?.account_id;

            return (
              phaseMembership &&
              !phaseMembership.archived &&
              phaseMembershipAccountId &&
              teamMembershipByAccountId[phaseMembershipAccountId]
            );
          }
        );

        const parentPhaseHasActivitiesPhase = phase.activity_order.length > 0;

        // Building phase membership list
        const phaseMembershipsList = itemsBuilder({
          isParentOpen: isPhaseOpen,
          order: filteredPhaseMembershipsOrder,
          phaseMembershipHashOfPhase,
          phaseId,
          phase,
          projectId,
          parentPhaseHasActivitiesPhase,
          openBudgetModal
        });

        const phaseListItem = {
          id: `phase-${phase.id}`,
          listItems: phaseMembershipsList,
          isOpen: isPhaseOpen,
          isParentOpen: true,
          itemType: LIST_ITEM_TYPES.Phase,
          toggleId: phaseToggleId,
          isEmpty: !phaseMembershipsList.length,
          phaseMembershipsOrder: filteredPhaseMembershipsOrder,
          hasActivityPhases: parentPhaseHasActivitiesPhase,
          toggleCollapse: () =>
            toggleCollapse({
              toggleId: phaseToggleId,
              parentId
            }),
          openBudgetModal,
          phase,
          projectId
        };

        acc.push(phaseListItem);
      }

      return acc;
    }, []);

    /* ------------------------ Handling collapse states (in case the list length change) ------------------------ */

    const collapseState = getCurrentParentCollapseState(parentId);

    // Initializing the default collapse state
    if (!collapseState) {
      setParentCollapseState({
        values: {
          totalNumToggles: list.length,
          allCollapsed: true
        },
        id: parentId
      });
    } else if (collapseState.totalNumToggles !== list.length) {
      // update number of toggles
      setParentCollapseState({
        values: {
          totalNumToggles: list.length
        },
        id: parentId
      });
    }

    return list;
  };

  return projectTeamListBuilder;
};

export default useProjectTeamListBuilder;
