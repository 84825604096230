import { useMemo, useEffect } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { ROW_TYPES } from '../tableConfigs';
import {
  BaseTableListsBuilder,
  BaseTableListItemsBuilder,
  BaseTableListItem,
  BaseTableList
} from 'components/Table/types';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import { serializeId } from 'appUtils';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { makeGetOwnBudgetTotals } from 'BudgetModule/selectors';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import { MemberGroupListItemsBuilderArgs } from './types';
import { serializeMemberAlternatesRowId, filterReasons } from '../../utils';

export const useMembersGroupedListsBuilder = ({
  getIsOpen,
  toggleCollapse,
  listItemsBuilder,
  setParentCollapseState,
  getCurrentParentCollapseState
}: UseNestedCollapseHookReturnedValues & {
  listItemsBuilder: BaseTableListItemsBuilder<
    BaseTableListItem,
    MemberGroupListItemsBuilderArgs
  >;
}) => {
  const getBudgetTotals = useMemo(makeGetOwnBudgetTotals, []);

  const { projectTotal } = useAppSelector((state) =>
    getBudgetTotals(state, { filterStateId: FilterStateIds.fetchPhaseTotals })
  );

  const teamMembershipsByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  const memberGroupedListsBuilder = ({
    order,
    parentGroupId,
    membersHash,
    phase,
    phaseMembership,
    numOfRemainingSuggestedMembers,
    isShowingAllMembers,
    shouldShowMemberAlternatesRow
  }) => {
    const lists = order.reduce((acc, accountId: number) => {
      const member = membersHash[accountId] || {};
      const reasons = member?.reasons || {};
      const reasonsOrder = filterReasons(Object.keys(reasons));

      if (teamMembershipsByAccountId[accountId] && reasonsOrder?.length) {
        const groupId = serializeId({
          itemType: 'member',
          ids: [parentGroupId, accountId],
          id: undefined
        });

        const listItems = listItemsBuilder({
          order: reasonsOrder,
          parentGroupId: groupId,
          reasons,
          accountId,
          phase,
          phaseMembership,
          member,
          projectTotal,
          teamMembershipsByAccountId
        });

        const isListOpen = getIsOpen({
          parentId: parentGroupId,
          toggleId: groupId
        });

        const list = {
          id: groupId,
          accountId,
          listItems,
          isList: true,
          isFullyLoaded: true,
          addEmptyRow: isListOpen,
          toggleCollapse: () =>
            toggleCollapse({ parentId: parentGroupId, toggleId: groupId }),
          isOpen: Boolean(isListOpen),
          rowHeight: 50,
          rowType: ROW_TYPES.memberGroupRow,
          /* -------------------------------------------------------------------------- */
          phase,
          phaseMembership,
          member,
          projectTotal,
          teamMembershipsByAccountId,
          numOfRemainingSuggestedMembers,
          isShowingAllMembers
        };

        acc.push(list);
      }

      return acc;
    }, []);

    // Adding the alternates row to the end of list
    const memberAlternatesRowId = phaseMembership?.id
      ? serializeMemberAlternatesRowId({
          phaseMembershipId: phaseMembership.id
        })
      : null;

    if (shouldShowMemberAlternatesRow && memberAlternatesRowId) {
      const isMemberAlternatesRowOpen = getIsOpen({
        parentId: parentGroupId,
        toggleId: memberAlternatesRowId
      });
      lists.push({
        id: memberAlternatesRowId,
        isList: false,
        isFullyLoaded: true,
        listItems: [],
        addEmptyRow: false,
        toggleCollapse: () =>
          toggleCollapse({
            parentId: parentGroupId,
            toggleId: memberAlternatesRowId
          }),
        isOpen: Boolean(isMemberAlternatesRowOpen),
        rowHeight: 25,
        rowType: ROW_TYPES.memberAlternatesRow,
        /* -------------------------------------------------------------------------- */
        phase,
        phaseMembership,
        accountId: null,
        member: undefined,
        projectTotal: undefined,
        teamMembershipsByAccountId,
        numOfRemainingSuggestedMembers,
        isShowingAllMembers
      });
    }

    const collapseState = getCurrentParentCollapseState(parentGroupId);

    // Initializing the default collapse state, where we collapse by default if top members, collapsed if member alternates row
    if (!collapseState) {
      const nextToggles = {
        ...lists.reduce((acc, list) => {
          acc[list.id] = true;
          return acc;
        }, {})
      };

      setParentCollapseState({
        values: {
          totalNumToggles: lists.length,
          toggles: nextToggles
        },
        id: parentGroupId
      });
    } else if (collapseState.totalNumToggles !== lists.length) {
      // update number of toggles
      setParentCollapseState({
        values: {
          totalNumToggles: lists.length
        },
        id: parentGroupId
      });
    }
    return lists;
  };

  return memberGroupedListsBuilder;
};
