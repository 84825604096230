import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CloseIcon from 'images/close.svg';
import { StyledSearch, CloseIconContainer } from './styles';

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCloseIconContainer = styled(CloseIconContainer)`
  position: absolute;
  right: 10px;
  img {
    width: 24px;
    height: 24px;
  }
  &:hover {
    img {
      filter: brightness(0.95);
      background: white;
      border-radius: 50%;
    }
  }
`;

class FlyoutSearch extends React.Component {
  handleChange = (e) => {
    const { handleChange } = this.props;
    handleChange(e);
  };

  render() {
    const {
      copy,
      placeholderSize,
      showCloseInSearch,
      value,
      handleClose,
      popoverClassName
    } = this.props;
    return (
      <SearchContainer>
        <StyledSearch
          autoFocus
          value={value}
          onChange={this.handleChange}
          placeholder={copy.searchPlaceholder || ''}
          placeholderSize={placeholderSize}
          className="flyout-search-input"
        />
        {showCloseInSearch && (
          <StyledCloseIconContainer onClick={handleClose}>
            <img src={CloseIcon} />
          </StyledCloseIconContainer>
        )}
      </SearchContainer>
    );
  }
}

FlyoutSearch.propTypes = {
  copy: PropTypes.object.isRequired
  // handleClick: PropTypes.func.isRequired
};

export default FlyoutSearch;
