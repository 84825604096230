import React from 'react';
import SvgIcon from 'components/SvgIcon';

const PencilIconDark = ({
  className,
  width = '16',
  height = '17',
  stroke = '#4a4a4a'
}) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 17"
  >
    <path
      d="M1.85611 11.3558L4.13337 13.9817L1.04454 14.9885C1.04239 14.9863 1.04007 14.9838 1.03759 14.981L1.03697 14.9802C1.00874 14.9478 0.989163 14.8927 1.0065 14.823L1.00691 14.8213L1.85611 11.3558ZM12.9561 4.27446L12.9672 4.28719L12.979 4.29915C12.9804 4.30046 13 4.32446 13 4.38129C13 4.43681 12.9802 4.4845 12.956 4.51235L12.9556 4.51279L5.86155 12.6944L2.92596 9.30875L10.113 1.01994C10.1131 1.01986 10.1131 1.01978 10.1132 1.01971C10.1171 1.01526 10.1206 1.01165 10.1237 1.00874C10.1266 1.0115 10.1298 1.0149 10.1334 1.01905L12.9561 4.27446Z"
      stroke={stroke}
      fill="transparent"
    />
  </SvgIcon>
);

export default PencilIconDark;
