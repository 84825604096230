import React from 'react';
import SvgIcon, { SvgIconProps } from 'components/SvgIcon';

interface NavRightArrowProps
  extends Pick<SvgIconProps, 'width' | 'height' | 'className'> {
  strokeWidth?: string | number;
  strokeColor?: string;
}

const NavRightArrow = ({
  className,
  height = 18,
  width = 19,
  strokeWidth = '2',
  strokeColor = '#4f4f4f'
}: NavRightArrowProps) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    className={className}
  >
    <path
      d="M9.65063 16.45L17.4506 8.64995L9.65064 0.849951"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
    <path
      d="M0.550659 8.64966L17.4507 8.64966"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default NavRightArrow;
