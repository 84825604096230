import { useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getFlatPhasesHash, getSuggestionsByPhaseMembership } from 'selectors';
import { ROW_TYPES } from '../tableConfigs';
import {
  BaseTableListItemsBuilder,
  BaseTableListItem
} from 'components/Table/types';
import keyBy from 'lodash/keyBy';
import { makeSuggestedMembersList } from 'SuggestionModule/components/FindPeople//utils';
import { makeGetOwnBudgetTotals } from 'BudgetModule/selectors';

import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import { serializeId } from 'appUtils';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';

import useUnassignedRolesByProjectAndPhases from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import {
  serializeMemberAlternatesRowId,
  getAccountTotalsByMemberBudgetId
} from '../../utils';
import { PhaseGroupListItemsBuilderExtraArgs } from './types';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';

export const usePhasesGroupedListsBuilder = ({
  getIsOpen,
  toggleCollapse,
  setParentCollapseState,
  getCurrentParentCollapseState,
  listItemsBuilder,
  projectId
}: UseNestedCollapseHookReturnedValues & {
  listItemsBuilder: BaseTableListItemsBuilder<
    BaseTableListItem,
    PhaseGroupListItemsBuilderExtraArgs
  >;
  projectId: Nullable<number>;
}) => {
  const phaseHash = useAppSelector(getFlatPhasesHash);

  const realSuggestionsByPhaseMemberships = useAppSelector(
    getSuggestionsByPhaseMembership
  );

  const teamMembershipsByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );

  const getBudgetTotals = useMemo(makeGetOwnBudgetTotals, []);

  const { phaseTotals } = useAppSelector((state) =>
    getBudgetTotals(state, { filterStateId: FilterStateIds.fetchPhaseTotals })
  );

  const {
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
  } = useUnassignedRolesByProjectAndPhases({ projectId });

  const phasesGroupedListsBuilder = ({
    order,
    parentGroupId, // main
    showDemoSuggestions,
    unassignedMemberBudgetId
  }) => {
    const lists = (order as number[]).map((phaseId) => {
      const phase = phaseHash[phaseId] || {};
      const phaseMembershipWithUnassignedMemberBudgetAndPosition =
        getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
          { phaseId, memberBudgetId: unassignedMemberBudgetId }
        );
      const hasActivityPhases = phase.activity_order.length > 0;
      const accountTotals = getAccountTotalsByMemberBudgetId(
        phase,
        phaseTotals,
        phaseMembershipWithUnassignedMemberBudgetAndPosition?.member_budget_id
      );

      const phaseMembershipId =
        phaseMembershipWithUnassignedMemberBudgetAndPosition?.id;

      const groupId = serializeId({
        itemType: 'phase',
        id: undefined,
        ids: [phaseId, phaseMembershipId]
      });

      const isShowingAllMembers = phaseMembershipId
        ? (getIsOpen({
            parentId: groupId,
            toggleId: serializeMemberAlternatesRowId({
              phaseMembershipId
            })
          }) as boolean)
        : false;

      const {
        suggestedMembersToShow,
        numOfRemainingSuggestedMembers,
        allSuggestedMembers,
        shouldShowMemberAlternatesRow,
        memberIdsOrder
      } = makeSuggestedMembersList({
        realSuggestionsByPhaseMemberships,
        phaseMembershipId,
        showDemoSuggestions,
        phase,
        isShowingAllMembers,
        teamMembershipsByAccountId
      });

      const isListOpen = getIsOpen({
        parentId: parentGroupId,
        toggleId: groupId
      });

      if (memberIdsOrder.length) {
        const listItems = listItemsBuilder({
          order: memberIdsOrder,
          parentGroupId: groupId,
          membersHash: keyBy(suggestedMembersToShow, 'account_id'),
          phase,
          phaseMembership: phaseMembershipWithUnassignedMemberBudgetAndPosition,
          numOfRemainingSuggestedMembers,
          isShowingAllMembers,
          shouldShowMemberAlternatesRow
        });

        const list = {
          id: phaseId,
          sectionId: groupId,
          phaseId,
          listItems,
          isList: true,
          isFullyLoaded: true,
          addEmptyRow: isListOpen,
          toggleCollapse: () =>
            toggleCollapse({ parentId: parentGroupId, toggleId: groupId }),
          isOpen: Boolean(isListOpen),
          rowHeight: 75,
          rowType: ROW_TYPES.phaseGroupRow,
          phase,
          phaseMembership: phaseMembershipWithUnassignedMemberBudgetAndPosition,
          accountTotals,
          hasActivityPhases,
          allSuggestedMembers
        };

        return list;
      } else {
        // No members suggestion for this phase
        return {
          id: phaseId,
          phaseId,
          sectionId: groupId,
          listItems: [
            {
              rowType: ROW_TYPES.noMatchRow,
              rowHeight: 40
            }
          ],
          isList: true,
          isFullyLoaded: true,
          addEmptyRow: false,
          toggleCollapse: () =>
            toggleCollapse({ parentId: parentGroupId, toggleId: groupId }),
          isOpen: Boolean(isListOpen),
          rowHeight: 75,
          rowType: ROW_TYPES.phaseGroupRow,
          phase,
          phaseMembership: phaseMembershipWithUnassignedMemberBudgetAndPosition,
          accountTotals,
          hasActivityPhases
        };
      }
    });

    const collapseState = getCurrentParentCollapseState(parentGroupId);

    if (!collapseState) {
      setParentCollapseState({
        values: {
          totalNumToggles: lists.length
        },
        id: parentGroupId
      });
    } else if (collapseState.totalNumToggles !== lists.length) {
      // update number of toggles
      setParentCollapseState({
        values: {
          totalNumToggles: lists.length
        },
        id: parentGroupId
      });
    }

    return lists;
  };

  return phasesGroupedListsBuilder;
};
