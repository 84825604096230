import React from 'react';
import { useSelector } from 'react-redux';
import { getAllTeamMembersWithArchived } from 'selectors';
import keyBy from 'lodash/keyBy';
import styled from 'styled-components';
import ActivityIcon from 'icons/AddWidgetsThumbnails/Activity.svg';
import AvailabilityIcon from 'icons/AddWidgetsThumbnails/Availability.svg';
import BudgetIcon from 'icons/AddWidgetsThumbnails/Budget.svg';
import CapacityIcon from 'icons/AddWidgetsThumbnails/Capacity.svg';
import FinancialForecastIcon from 'icons/AddWidgetsThumbnails/FinancialForecast.svg';
import PerformanceIcon from 'icons/AddWidgetsThumbnails/Performance.svg';
import ProfitabilityIcon from 'icons/AddWidgetsThumbnails/Profitability.svg';
import RevenueVsForecastIcon from 'icons/AddWidgetsThumbnails/RevenueVsForecast.svg';
import SpentIcon from 'icons/AddWidgetsThumbnails/Spent.svg';
import SpentVsPlannedIcon from 'icons/AddWidgetsThumbnails/SpentVsPlanned.svg';
import TasksIcon from 'icons/AddWidgetsThumbnails/Tasks.svg';
import TimeReportIcon from 'icons/AddWidgetsThumbnails/TimeReport.svg';
import TimesheetsIcon from 'icons/AddWidgetsThumbnails/Timesheets.svg';
import TimesheetStatusIcon from 'icons/AddWidgetsThumbnails/TimesheetStatus.svg';
import UtilizationAreaChartIcon from 'icons/AddWidgetsThumbnails/UtilizationAreaChart.svg';
import UtilizationBarChartIcon from 'icons/AddWidgetsThumbnails/UtilizationBarChart.svg';
import UtilizationIcon2 from 'icons/AddWidgetsThumbnails/Utilization2.svg';
import WorkloadIcon from 'icons/AddWidgetsThumbnails/Workload.svg';
import WorkloadBreakdownIcon from 'icons/AddWidgetsThumbnails/WorkloadBreakdown.svg';
import WorkloadForecastIcon from 'icons/AddWidgetsThumbnails/WorkloadForecast.svg';
import WorkloadWidget from 'DashboardModule/components/Widgets/WorkloadWidget';
import WorkloadWidgetTwo from 'DashboardModule/components/Widgets/Capacity/WorkloadWidgetTwo/WorkloadWidgetTwo';
import TimesheetWidget from 'DashboardModule/components/Widgets/TimesheetWidget';
import TimesheetStatusWidgetTwo from 'DashboardModule/components/Widgets/TimesheetStatusWidgetTwo/TimesheetStatusWidgetTwo';
import TimesheetStatusWidget from 'DashboardModule/components/Widgets/TimesheetStatusWidget/TimesheetStatusWidget';
import UtilizationWidget from 'DashboardModule/components/Widgets/UtilizationWidget';
import UtilizationWidgetTwo from 'DashboardModule/components/Widgets/UtilizationWidgetTwo';
import MiniUtilizationTableWidget from 'DashboardModule/components/Widgets/MiniUtilizationTableWidget/MiniUtilizationTableWidget';
import BudgetWidget from 'DashboardModule/components/Widgets/Budget/BudgetWidget';

import { ZOOM_LEVELS, VIEW_BY } from 'appConstants/workload';
import { TIMESHEET_STATUSES } from 'appConstants/timesheets';
import { CUSTOM_FILTER_VALUES, Page } from 'appConstants/filters';
import {
  formatUtilizationDateRangeFromFilter,
  formatCapacityDateRangeFromFilter
} from 'appUtils/projectPlannerUtils';
import { GROUP_BY as TIMESHEET_REPORT_GROUP_BY } from 'ReportsModule/components/Time/TimesheetsTable/constants';
import NewMembersIcon from 'icons/NewMembersIcon';
import { CHART_TYPE } from 'ReportsModule/constants';
import UtilizationChartWidgetContainer from 'DashboardModule/components/Widgets/Utilization/UtilizationChartWidgetContainer';
import WidgetHeader from 'DashboardModule/components/Widgets/shared/WidgetHeader';

export const WIDGET_TYPES = {
  CAPACITY: 'capacityWidget',
  WORKLOAD_FORECAST: 'workloadForecastWidget',
  REVENEU_VS_FORECASE: 'revenueVsForecastWidget',
  WORKLOAD_BREAKDOWN: 'workloadBreakdownWidget',
  SPENT_VS_PLANNED: 'spentVsPlannedWidget',
  ACTIVITY: 'activityWidget',
  FINANCIAL_FORECAST: 'financialForecastWidget',
  TIMESHEET: 'timesheetWidget',
  WORKLOAD: 'workloadWidget',
  WORKLOAD_TWO: 'workloadWidgetTwo',
  UTILIZATION: 'utilizationWidget',
  UTILIZATION_TWO: 'utilizationWidgetTwo',
  UTILIZATION_TABLE: 'utilizationTableWidget',
  UTILIZATION_AREA_CHART: 'utilizationAreaChartWidget', // utilizationOrganizationAreaChartWidget
  UTILIZATION_ORGANIZATION_BAR_CHART: 'utilizationOrganizationBarChartWidget',
  /**
   * @deprecated use Org utilization widget insted
   */
  UTILIZATION_TEAM_AREA_CHART: 'utilizationTeamAreaChartWidget',
  /**
   * @deprecated use Org utilization widget insted
   */
  UTILIZATION_TEAM_BAR_CHART: 'utilizationTeamBarChartWidget', // deprecated
  TIME_REPORT: 'timeReportWidget',
  TIMESHEET_STATUS_TWO: 'timesheetStatusWidgetTwo',
  TIMESHEET_STATUS_TABLE: 'timesheetStatusTableWidget',
  PERFORMANCE: 'performanceWidget',
  TASKS: 'tasksWidget',
  PROFITABILITY: 'profitabilityWidget',
  SPENT: 'spentWidget',
  AVAILABILITY: 'availabilityWidget',
  BUDGET: 'budgetWidget'
};

const Img = styled.img`
  width: 100%;
`;

const getDateRangeFormatter = (widgetType) => {
  switch (widgetType) {
    case WIDGET_TYPES.WORKLOAD:
    case WIDGET_TYPES.WORKLOAD_TWO:
      return formatCapacityDateRangeFromFilter;

    case WIDGET_TYPES.UTILIZATION:
    case WIDGET_TYPES.UTILIZATION_TABLE:
      return formatUtilizationDateRangeFromFilter;

    default:
      return formatCapacityDateRangeFromFilter;
  }
};

const CustomWidgetHeader = ({
  activeFilter,
  title,
  customDescription = '',
  description,
  widgetType
}) => {
  const dateRangeTypeDescription =
    getDateRangeFormatter(widgetType)(activeFilter);
  return (
    <WidgetHeader
      title={title}
      customDescription={customDescription}
      description={description ?? dateRangeTypeDescription}
      numMembers={activeFilter?.account_ids?.length || 0}
    />
  );
};

const CustomTeamWidgetHeader = ({ activeFilter, title, description }) => {
  const teamMembers = useSelector(getAllTeamMembersWithArchived);
  return (
    <WidgetHeader
      title={title}
      description={description}
      numMembers={teamMembers.length}
    />
  );
};

const checkIfEmpty = (activeFilter, filterType) =>
  activeFilter[filterType].length < 1;

// const timeReportWidget = {
//   id: 'timeReportWidget',
//   uid: WIDGET_TYPES.TIME_REPORT,
//   icon: <Img src={TimeReportIcon} />,
//   info: 'Overview of team members’ workload and availability',
//   renderer: () => 'Custom Time Report Widget',
//   title: 'Time Report',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };
const workloadWidget = {
  id: 'workloadWidget',
  uid: WIDGET_TYPES.WORKLOAD,
  icon: <Img src={WorkloadIcon} />,
  renderer: WorkloadWidget,
  renderCustomHeader: CustomWidgetHeader,
  info: '',
  title: 'Workload Summary',
  filterPageName: Page.Capacity,
  filterInit: {
    name: VIEW_BY.MEMBERS,
    custom: { range: ZOOM_LEVELS.WEEK }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.showHolidays]: true,
    [CUSTOM_FILTER_VALUES.showPto]: true
  },
  checkIfEmpty: (activeFilter) => checkIfEmpty(activeFilter, 'account_ids')
};
const workloadWidgetTwo = {
  id: 'workloadWidgetTwo',
  uid: WIDGET_TYPES.WORKLOAD_TWO,
  icon: <Img src={TimesheetsIcon} />,
  renderer: WorkloadWidgetTwo,
  infoTitle: 'This + Next Week',
  info: '',
  renderCustomHeader: ({ activeFilter, customDescription }) =>
    CustomWidgetHeader({
      activeFilter,
      title: `Workload`,
      description: '2 Weeks',
      customDescription
    }),
  title: 'Workload',
  filterPageName: Page.Capacity,
  filterInit: {
    name: VIEW_BY.MEMBERS,
    // date range from beginning of this week to end of next week
    custom: { range: ZOOM_LEVELS.START_OF_THIS_WEEK_TO_END_OF_NEXT_WEEK }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.range]: true,
    [CUSTOM_FILTER_VALUES.showHolidays]: true,
    [CUSTOM_FILTER_VALUES.showPto]: true
  },
  checkIfEmpty: (activeFilter) => checkIfEmpty(activeFilter, 'account_ids')
};
// const capacityWidget = {
//   id: 'capacityWidget',
//   uid: WIDGET_TYPES.CAPACITY,
//   icon: <Img src={CapacityIcon} />,
//   info: 'Overview of team members’ workload and availability',
//   title: 'Capacity',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };
// const workloadForecastWidget = {
//   id: 'workloadForecastWidget',
//   uid: WIDGET_TYPES.WORKLOAD_FORECAST,
//   icon: <Img src={WorkloadForecastIcon} />,
//   info: 'Overview of team members’ workload and availability',
//   title: 'Workload Forecast',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };
// const revenueVsForecastWidget = {
//   id: 'revenueVsForecastWidget',
//   uid: WIDGET_TYPES.REVENEU_VS_FORECASE,
//   icon: <Img src={RevenueVsForecastIcon} />,
//   info: 'Overview of team members’ workload and availability',
//   title: 'Revenue Vs Forecast',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };

/* ------------------------------- Utilization ------------------------------ */

export const utilizationWidget = {
  id: 'utilizationWidget',
  uid: WIDGET_TYPES.UTILIZATION,
  icon: <Img src={WorkloadIcon} />,
  renderer: UtilizationWidget,
  renderCustomHeader: CustomWidgetHeader,
  info: '',
  title: 'Utilization Summary',
  filterPageName: Page.Utilization,
  filterInit: {
    name: VIEW_BY.MEMBERS,
    custom: { range: ZOOM_LEVELS.WEEK }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.showPto]: true,
    [CUSTOM_FILTER_VALUES.showHolidays]: true
  },
  checkIfEmpty: (activeFilter) => checkIfEmpty(activeFilter, 'account_ids')
};
export const utilizationWidgetTwo = {
  id: 'utilizationWidgetTwo',
  uid: WIDGET_TYPES.UTILIZATION_TWO,
  icon: <Img src={TimesheetsIcon} />,
  renderer: UtilizationWidgetTwo,
  info: '',
  title: 'Utilization Last 2 Weeks',
  filterPageName: Page.Utilization,
  filterInit: {
    name: VIEW_BY.MEMBERS,
    // date range from beginning of week before last week to end of last week
    custom: {
      range: ZOOM_LEVELS.START_OF_WEEK_BEFORE_LAST_WEEK_TO_END_OF_LAST_WEEK
    }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.range]: true,
    [CUSTOM_FILTER_VALUES.showPto]: true,
    [CUSTOM_FILTER_VALUES.showHolidays]: true
  },
  checkIfEmpty: (activeFilter) => checkIfEmpty(activeFilter, 'account_ids')
};
const utilizationTableWidget = {
  id: 'utilizationTableWidget',
  uid: WIDGET_TYPES.UTILIZATION_TABLE,
  icon: <Img src={UtilizationIcon2} />,
  renderer: MiniUtilizationTableWidget,
  renderCustomHeader: CustomWidgetHeader,
  info: '',
  title: 'Utilization Bar Chart',
  filterPageName: Page.Utilization,
  filterInit: {
    name: VIEW_BY.MEMBERS,
    custom: { range: ZOOM_LEVELS.WEEK }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.showPto]: true,
    [CUSTOM_FILTER_VALUES.showHolidays]: true
  },
  limits: {
    account_ids: 20
  },
  checkIfEmpty: (activeFilter) => checkIfEmpty(activeFilter, 'account_ids')
};

const utilizationAreaChartWidget = {
  id: 'utilizationAreaChartWidget',
  uid: WIDGET_TYPES.UTILIZATION_AREA_CHART,
  icon: <Img src={UtilizationAreaChartIcon} />,
  renderer: UtilizationChartWidgetContainer,
  renderCustomHeader: ({ activeFilter, title, customDescription }) =>
    CustomWidgetHeader({
      activeFilter,
      title,
      description: ' ',
      customDescription
    }),
  info: '',
  title: 'Org Utilization Graph',
  filterPageName: Page.Utilization,
  filterInit: {
    name: VIEW_BY.ORGANIZATION,
    custom: { range: ZOOM_LEVELS.HALF, chartType: CHART_TYPE.AREA_GRAPH }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.range]: true,
    [CUSTOM_FILTER_VALUES.showPto]: false,
    [CUSTOM_FILTER_VALUES.showHolidays]: false,
    chartType: true
  }
};

const utilizationTeamAreaChartWidget = {
  id: 'utilizationTeamAreaChartWidget',
  uid: WIDGET_TYPES.UTILIZATION_TEAM_AREA_CHART,
  icon: <Img src={UtilizationAreaChartIcon} />,
  renderer: UtilizationChartWidgetContainer,
  renderCustomHeader: ({ activeFilter, title }) =>
    CustomTeamWidgetHeader({
      activeFilter,
      title,
      description: 'Past 6 Months'
    }),
  info: 'Overview of team members’ workload and availability',
  title: 'Team Utilization',
  filterPageName: Page.Utilization,
  filterInit: {
    name: VIEW_BY.TEAM,
    custom: { range: ZOOM_LEVELS.HALF, chartType: CHART_TYPE.AREA_GRAPH }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.range]: true,
    [CUSTOM_FILTER_VALUES.showPto]: false,
    [CUSTOM_FILTER_VALUES.showHolidays]: false,
    chartType: true
  }
};

const utilizationOrganizationBarChartWidget = {
  id: 'utilizationOrganizationBarChartWidget',
  uid: WIDGET_TYPES.UTILIZATION_ORGANIZATION_BAR_CHART,
  icon: <Img src={UtilizationBarChartIcon} />,
  renderer: UtilizationChartWidgetContainer,
  renderCustomHeader: ({ activeFilter, customDescription }) =>
    CustomWidgetHeader({
      activeFilter,
      description: '  ',
      title: 'Org Utilization',
      customDescription
    }),
  info: '',
  title: 'Org Utilization',
  filterPageName: Page.Utilization,
  filterInit: {
    name: VIEW_BY.ORGANIZATION,
    custom: { range: ZOOM_LEVELS.HALF, chartType: CHART_TYPE.BAR_CHART }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.range]: true,
    [CUSTOM_FILTER_VALUES.showPto]: false,
    [CUSTOM_FILTER_VALUES.showHolidays]: false,
    chartType: true
  }
};

const utilizationTeamBarChartWidget = {
  id: 'utilizationTeamBarChartWidget',
  uid: WIDGET_TYPES.UTILIZATION_TEAM_BAR_CHART,
  icon: <Img src={UtilizationBarChartIcon} />,
  renderer: UtilizationChartWidgetContainer,
  renderCustomHeader: ({ activeFilter }) =>
    CustomTeamWidgetHeader({
      activeFilter,
      title: 'Team Utilization',
      description: 'Past 6 Months'
    }),
  info: 'Overview of team members’ workload and availability',
  title: 'Team Utilization',
  filterPageName: Page.Utilization,
  filterInit: {
    name: VIEW_BY.TEAM,
    custom: { range: ZOOM_LEVELS.HALF, chartType: CHART_TYPE.BAR_CHART }
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.range]: true,
    [CUSTOM_FILTER_VALUES.showPto]: false,
    [CUSTOM_FILTER_VALUES.showHolidays]: false,
    chartType: true
  }
};

/* ------------------------------------ - ----------------------------------- */

// const workloadBreakdownWidget = {
//   id: 'workloadBreakdownWidget',
//   uid: WIDGET_TYPES.WORKLOAD_BREAKDOWN,
//   icon: <Img src={WorkloadBreakdownIcon} />,
//   info: 'Overview of team members’ workload and availability',
//   title: 'Workload Breakdown',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };
// const spentVsPlannedWidget = {
//   id: 'spentVsPlannedWidget',
//   uid: WIDGET_TYPES.SPENT_VS_PLANNED,
//   icon: <Img src={SpentVsPlannedIcon} />,
//   info: 'Overview of team members’ workload and availability',
//   title: 'Spent Vs Planned',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };
// const activityWidget = {
//   id: 'activityWidget',
//   uid: WIDGET_TYPES.ACTIVITY,
//   icon: <Img src={ActivityIcon} />,
//   info: 'Overview of team members’ workload and availability',
//   title: 'Activity',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };
// const financialForecastWidget = {
//   id: 'financialForecastWidget',
//   uid: WIDGET_TYPES.FINANCIAL_FORECAST,
//   icon: <Img src={FinancialForecastIcon} />,
//   info: 'Overview of team members’ workload and availability',
//   title: 'Finanical Forecast',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };
// const timesheetWidget = {
//   id: 'timesheetWidget',
//   uid: WIDGET_TYPES.TIMESHEET,
//   icon: <Img src={TimesheetsIcon} />,
//   renderer: TimesheetWidget,
//   info: 'Overview of team members’ workload and availability',
//   title: 'Timesheets',
//   description: 'description',
//   filterPageName: 'Workload Planner'
// };

/* ------------------------- Timesheet status report ------------------------ */

const statusToLabel = {
  [TIMESHEET_STATUSES.APPROVED]: 'Approved',
  [TIMESHEET_STATUSES.SUBMITTED]: 'Submitted',
  [TIMESHEET_STATUSES.ENTERED]: 'Entered'
};

const timesheetStatusWidgetTwo = {
  id: 'timesheetStatusWidgetTwo',
  uid: WIDGET_TYPES.TIMESHEET_STATUS_TWO,
  icon: <Img src={TimesheetsIcon} />,
  renderer: TimesheetStatusWidgetTwo,
  filterInit: {
    name: VIEW_BY.MEMBERS,
    custom: {
      // date range from beginning of last week to end of current week
      range: ZOOM_LEVELS.START_OF_LAST_WEEK_TO_END_OF_THIS_WEEK,
      groupBy: TIMESHEET_REPORT_GROUP_BY.WEEK
    }
  },
  renderCustomHeader: ({ activeFilter, customDescription }) =>
    CustomWidgetHeader({
      activeFilter,
      title: `Timesheet`,
      description: '2 Weeks',
      customDescription
    }),
  info: '',
  title: 'Timesheet',
  filterPageName: Page.Timesheet,
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.range]: true,
    [CUSTOM_FILTER_VALUES.groupBy]: true
  },
  checkIfEmpty: (activeFilter) => checkIfEmpty(activeFilter, 'account_ids')
};

const makeTimesheetStatusTableWidget = (status) => {
  return {
    id: 'timesheetStatusTableWidget',
    uid: `${WIDGET_TYPES.TIMESHEET_STATUS_TABLE}-${status.toLowerCase()}`,
    icon: <Img src={TimesheetStatusIcon} />,
    renderer: TimesheetStatusWidget,
    renderCustomHeader: ({ activeFilter, customDescription }) =>
      CustomWidgetHeader({
        activeFilter,
        title: `Timesheet ${statusToLabel[activeFilter?.custom?.statusView]}`,
        description: 'Last 4 Weeks',
        customDescription
      }),
    filterInit: {
      name: VIEW_BY.MEMBERS,
      custom: {
        statusView: status,
        range: ZOOM_LEVELS.MONTH
      }
    },
    title: `Timesheets ${statusToLabel[status]}`,
    info: '',
    filterPageName: Page.TimesheetStatusReport,
    disabledOptions: {
      [CUSTOM_FILTER_VALUES.range]: true
    },
    limits: {
      account_ids: 12
    },
    checkIfEmpty: (activeFilter) => checkIfEmpty(activeFilter, 'account_ids')
  };
};

const timesheetStatusTableEnteredWidget = makeTimesheetStatusTableWidget(
  TIMESHEET_STATUSES.ENTERED
);
const timesheetStatusTableSubmittedWidget = makeTimesheetStatusTableWidget(
  TIMESHEET_STATUSES.SUBMITTED
);
const timesheetStatusTableApprovedWidget = makeTimesheetStatusTableWidget(
  TIMESHEET_STATUSES.APPROVED
);

export const budgetWidget = {
  id: 'budgetWidget',
  uid: WIDGET_TYPES.BUDGET,
  icon: <Img src={BudgetIcon} />,
  renderer: BudgetWidget,
  renderCustomHeader: CustomWidgetHeader,
  info: '',
  title: 'Budget Tracking',
  filterPageName: Page.Budget,
  filterInit: {
    name: VIEW_BY.PROJECTS
  },
  disabledOptions: {
    [CUSTOM_FILTER_VALUES.displayFormat]: true,
    [CUSTOM_FILTER_VALUES.hideNoBudget]: true,
    // although this is in activeFilter.custom, the name of the value is isViewingCostRate which may cause some confusion
    rateType: true
  },
  checkIfEmpty: (activeFilter) => checkIfEmpty(activeFilter, 'phase_ids'),
  limits: {
    phase_ids: 20
  }
};

/* ------------------------------------ - ----------------------------------- */

const performanceWidget = {
  id: 'performanceWidget',
  uid: WIDGET_TYPES.PERFORMANCE,
  icon: <Img src={PerformanceIcon} />,
  info: 'Overview of team members’ workload and availability',
  title: 'Performance',
  description: 'description',
  filterPageName: 'Workload Planner'
};
const tasksWidget = {
  id: 'tasksWidget',
  uid: WIDGET_TYPES.TASKS,
  icon: <Img src={TasksIcon} />,
  info: 'Overview of team members’ workload and availability',
  title: 'Tasks',
  description: 'description',
  filterPageName: 'Workload Planner'
};
const profitabilityWidget = {
  id: 'profitabilityWidget',
  uid: WIDGET_TYPES.PROFITABILITY,
  icon: <Img src={ProfitabilityIcon} />,
  info: 'Overview of team members’ workload and availability',
  title: 'Profitability',
  description: 'description',
  filterPageName: 'Workload Planner'
};
const spentWidget = {
  id: 'spentWidget',
  uid: WIDGET_TYPES.SPENT,
  icon: <Img src={SpentIcon} />,
  info: 'Overview of team’s spent hours or budget on projects',
  title: 'Spent',
  description: 'description',
  filterPageName: 'Workload Planner'
};
const availabilityWidget = {
  id: 'availabilityWidget',
  uid: WIDGET_TYPES.AVAILABILITY,
  icon: <Img src={AvailabilityIcon} />,
  info: 'Overview of team members’ workload and availability',
  title: 'Availability',
  description: 'description',
  filterPageName: 'Workload Planner'
};

export const defaultAddableWidgets = [
  // timeReportWidget,
  workloadWidget,
  workloadWidgetTwo,
  // capacityWidget,
  // workloadForecastWidget,
  // revenueVsForecastWidget,
  utilizationWidget,
  utilizationWidgetTwo,
  utilizationTableWidget,
  utilizationAreaChartWidget,
  utilizationOrganizationBarChartWidget,
  // utilizationTeamAreaChartWidget,
  // utilizationTeamBarChartWidget,
  // workloadBreakdownWidget,
  // spentVsPlannedWidget,
  // activityWidget,
  // financialForecastWidget,
  // timesheetWidget,
  timesheetStatusWidgetTwo,
  timesheetStatusTableEnteredWidget,
  timesheetStatusTableSubmittedWidget,
  timesheetStatusTableApprovedWidget,
  // performanceWidget,
  // tasksWidget,
  // profitabilityWidget,
  // spentWidget,
  // availabilityWidget,
  budgetWidget
];

export const defaultAddableWidgetsHash = keyBy(
  defaultAddableWidgets,
  (item) => item.id
);

// used for when a certain type of widget may have different versions/widgetConfigs
export const defaultAddableWidgetsHashByUid = keyBy(
  defaultAddableWidgets,
  (item) => item.uid
);

export const widgetReportViews = {
  [WIDGET_TYPES.TIME_REPORT]: 'time',
  [WIDGET_TYPES.WORKLOAD]: 'capacity',
  [WIDGET_TYPES.WORKLOAD_TWO]: 'capacity',
  [WIDGET_TYPES.CAPACITY]: 'time',
  [WIDGET_TYPES.WORKLOAD_FORECAST]: 'time',
  [WIDGET_TYPES.REVENEU_VS_FORECASE]: 'time',
  [WIDGET_TYPES.UTILIZATION]: 'utilization',
  [WIDGET_TYPES.UTILIZATION_TWO]: 'utilization',
  [WIDGET_TYPES.UTILIZATION_TABLE]: 'utilization',
  [WIDGET_TYPES.UTILIZATION_AREA_CHART]: 'utilization',
  [WIDGET_TYPES.UTILIZATION_TEAM_AREA_CHART]: 'utilization',
  [WIDGET_TYPES.UTILIZATION_ORGANIZATION_BAR_CHART]: 'utilization',
  [WIDGET_TYPES.UTILIZATION_TEAM_BAR_CHART]: 'utilization',
  [WIDGET_TYPES.WORKLOAD_BREAKDOWN]: 'time',
  [WIDGET_TYPES.SPENT_VS_PLANNED]: 'time',
  [WIDGET_TYPES.ACTIVITY]: 'time',
  [WIDGET_TYPES.FINANCIAL_FORECAST]: 'time',
  [WIDGET_TYPES.TIMESHEET_STATUS_TWO]: 'time',
  [WIDGET_TYPES.TIMESHEET_STATUS_TABLE]: 'timesheetstatus',
  [WIDGET_TYPES.PERFORMANCE]: 'time',
  [WIDGET_TYPES.TASKS]: 'time',
  [WIDGET_TYPES.PROFITABILITY]: 'time',
  [WIDGET_TYPES.SPENT]: 'time',
  [WIDGET_TYPES.AVAILABILITY]: 'time',
  [WIDGET_TYPES.BUDGET]: 'budget'
};

// add widgets that have a feature flag
export const widgetFeatureFlag = {
  [WIDGET_TYPES.BUDGET]: 'dashboardTeamBudgetWidgetFlag'
};
