import { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAppDispatch } from 'reduxInfra/hooks';
import { resetProjectFilterList } from 'actionCreators';
import styled, { css } from 'styled-components';
import { usePageContext } from 'contexts/PageContextProvider';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import {
  FilterField,
  filterListTypeToEntityLabel,
  FilterListType,
  crossFilterFieldOverrideHash
} from 'FilterModule/constants';
import { useToggle, useUnmount } from 'react-use';
import { SideFilterHeader } from './SideFilterHeader';
import { StackedFiltersContainer } from 'FilterModule/components/FilterListsTable/StackedFiltersContainer';
import { FilterListContainer } from 'FilterModule/components/FilterListsTable/FilterListContainer';

// FilterListTable tableConfig must be updated if this changes
const WIDTH = 300;

export type SideFilterVariant =
  | 'ReportSideFilter'
  | 'WorkloadPlannerSideFilter'
  | 'WidgetModalSideFilter';

interface SideFilterContainerProps {
  variant: SideFilterVariant;
  shouldUsePortal?: boolean;
}

/**
 * Side selection filter. Requires FilterContext
 */
export const SideFilter = ({
  variant,
  shouldUsePortal = true
}: SideFilterContainerProps) => {
  const dispatch = useAppDispatch();
  const { pageName } = usePageContext();

  // old name for isoStateId; used only in project reducer
  const filterListId = `${pageName}-SideFilterContainer`;

  const {
    currentFilter,
    draftFilter,
    mainFilterListType,
    currentFilterSchema,
    toggleIsSideFilterOpen,
    isSideFilterOpen: isOpen
  } = useFilterContext();

  const shouldSaveLocally = variant === 'WidgetModalSideFilter';

  const [isStackedFiltersOpen, toggleIsStackedFiltersOpen] = useToggle(false);

  const stackedFilterOrder = currentFilter[FilterField.stackedFilterOrder];

  const numStackedFilters =
    (Array.isArray(stackedFilterOrder) && stackedFilterOrder.length) || 0;

  const hasStackedFilters = numStackedFilters > 0;

  // for displaying loading icon
  const [isFilterSaving, setIsFilterSaving] = useState(false);

  // note: stacked filters in SideFilter use special _cf (cross filter) filter fields
  const numStackedFiltersUsed = useMemo(() => {
    if (hasStackedFilters) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return stackedFilterOrder!.reduce((acc, filterListType) => {
        // a FilterListType may be associated with a single filter field, or with multiple
        const fields = (
          crossFilterFieldOverrideHash as Partial<
            Record<FilterListType, FilterField | FilterField[]>
          >
        )[filterListType];

        if (!fields) return acc;

        const fieldsToUse = Array.isArray(fields) ? fields : [fields];

        const filterIsBeingUsed = !!fieldsToUse.find((field) => {
          const filterValue = draftFilter[field] ?? currentFilter[field];
          // handles only array values for now
          return Array.isArray(filterValue) && filterValue.length > 0;
        });

        if (filterIsBeingUsed) return acc + 1;
        return acc;
      }, 0);
    }
    return 0;
  }, [stackedFilterOrder, draftFilter, currentFilter, hasStackedFilters]);

  const handleDone = () => {
    let isSaving = false;

    if (draftFilter.meta.hasChanges) {
      isSaving = true;
      setIsFilterSaving(true);
      setTimeout(() => {
        shouldSaveLocally ? draftFilter.saveLocally() : draftFilter.save();
        setIsFilterSaving(false);
        toggleIsSideFilterOpen();
      }, 1);
    }

    if (!isSaving) {
      toggleIsSideFilterOpen();
    }
  };

  const handleCancel = () => {
    if (draftFilter.meta.hasChanges) {
      draftFilter.reset();
    }
    toggleIsSideFilterOpen();
  };

  const resultsMetaData = useMemo(() => {
    return hasStackedFilters && mainFilterListType
      ? {
          isoStateId: `SideFilter-${mainFilterListType}`, // match FilterListContainer isoStateIdPrefix
          filterListType: mainFilterListType
        }
      : undefined;
  }, [hasStackedFilters, mainFilterListType]);

  useUnmount(() => {
    if (filterListId) {
      dispatch(
        resetProjectFilterList({
          filterListId
        })
      );
    }
  });

  /* --------------------------------- render --------------------------------- */

  const actualFilterWidth = WIDTH + (hasStackedFilters ? 240 : 0);
  const visibleWidth = isStackedFiltersOpen ? actualFilterWidth : WIDTH;

  // portal necessary to avoid z-index issues
  const portalId = variantToPortalId[variant];
  const portalTarget = portalId ? document.getElementById(portalId) : null;

  if (!mainFilterListType) return null;

  const entityLabel = mainFilterListType
    ? filterListTypeToEntityLabel[mainFilterListType] || ''
    : '';

  const render = (
    <StyledFilterContainer
      sideFilterVariant={variant}
      isOpen={isOpen}
      filterWidth={visibleWidth}
      actualFilterWidth={actualFilterWidth}
    >
      {/* The part that slides out */}
      <StyledFilter filterWidth={actualFilterWidth}>
        {/* Note: not hiding the body when not open to prevent unnecessary loads */}
        <FilterColumns>
          {hasStackedFilters && (
            <StackedFiltersContainer
              headerTitle={entityLabel}
              handleClose={toggleIsStackedFiltersOpen}
              numStackedFiltersUsed={numStackedFiltersUsed}
              resultsMetaData={resultsMetaData}
              isVisible={isStackedFiltersOpen && isOpen}
              filterListTypeToFieldOverrideHash={crossFilterFieldOverrideHash}
              variant="SideFilter"
            />
          )}

          <MainFilterContainer $width={WIDTH}>
            <SideFilterHeader
              width={WIDTH}
              isStackedFiltersOpen={isStackedFiltersOpen}
              toggleIsStackedFiltersOpen={toggleIsStackedFiltersOpen}
              hasStackedFilters={hasStackedFilters}
              numStackedFiltersUsed={numStackedFiltersUsed}
              hasChanges={!shouldSaveLocally && draftFilter.meta.hasChanges}
              entityLabel={entityLabel}
              handleCancel={handleCancel}
              handleDone={handleDone}
              isSaving={isFilterSaving}
              filterListTypeKey={
                FilterField.mainFilterListType_local in
                currentFilterSchema.fields
                  ? FilterField.mainFilterListType_local
                  : FilterField.mainFilterListType
              }
            />

            {/* The main list of filter items */}
            <FilterListContainer />
          </MainFilterContainer>
        </FilterColumns>
      </StyledFilter>
    </StyledFilterContainer>
  );

  return (
    <>
      {shouldUsePortal
        ? portalTarget
          ? createPortal(render, portalTarget)
          : null
        : render}
    </>
  );
};

/* ------------------------------------ - ----------------------------------- */

export const SIDE_FILTER_PORTAL_IDS = {
  reportSideFilterPortal: 'side-filter-portal',
  widgetModal: 'widget-modal-side-filter-portal'
};

const variantToPortalId: Partial<Record<SideFilterVariant, string>> = {
  ReportSideFilter: SIDE_FILTER_PORTAL_IDS.reportSideFilterPortal,
  WidgetModalSideFilter: SIDE_FILTER_PORTAL_IDS.widgetModal
};

const FilterColumns = styled.div`
  display: flex;
  height: 100%;
`;

const MainFilterContainer = styled.div<{ $width: number }>`
  width: ${({ $width }) => $width}px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledFilter = styled.div<{ filterWidth: number }>`
  left: ${({ filterWidth }) => -(filterWidth || 300)}px;
  position: absolute;
  bottom: 0;
  transition: 0.2s;
  min-width: ${({ filterWidth }) => `${filterWidth || 225}px`};
  width: ${({ filterWidth }) => `${filterWidth || 225}px`};
  background-color: white;
  display: flex;
  flex-direction: column;

  .side-filter-bulk-actions-container:hover {
    background: transparent;
    cursor: default;
  }
`;

const StyledFilterContainerStylesBySideFilterVariant: Record<
  SideFilterVariant,
  ReturnType<typeof css>
> = {
  ReportSideFilter: css`
    position: fixed;
    ${StyledFilter} {
      height: 100vh;
    }
  `,
  WorkloadPlannerSideFilter: css`
    position: fixed;
    ${StyledFilter} {
      height: calc(100vh - 115px);
    }
  `,
  WidgetModalSideFilter: css`
    position: absolute;
    ${StyledFilter} {
      height: 100%;
    }
  `
};

const StyledFilterContainer = styled.div<{
  isOpen: boolean;
  filterWidth: number;
  actualFilterWidth: number;
  sideFilterVariant: SideFilterVariant;
}>`
  height: 100%;
  top: 0;
  z-index: 100;

  ${StyledFilter} {
    box-shadow: 1px 0 2px 0 rgba(195, 195, 195, 0.5);
    left: ${(props) => `-${props.actualFilterWidth}px`};
    transform: ${({ isOpen, filterWidth }) =>
      isOpen ? `translateX(${filterWidth}px)` : 'none'};
  }

  /* side filter variant specific styles */
  ${({ sideFilterVariant }) =>
    StyledFilterContainerStylesBySideFilterVariant[sideFilterVariant]}
`;

/**
  .my-projects-sidebar-container & {
    height: 100%;
    top: -45px;
    left: 4px;
    ${StyledFilter} {
      height: 100%;
      width: 291px;
      left: -4px;
      padding-left: 4px;
    }
  } */
