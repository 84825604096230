import { put, select, cancel, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as entityActions from '../entityActions';
import { changeEntity, fetchEntity } from 'sagas/generics';
import * as api from '../api';
import { getAuthToken, getPhases, getSelectedTeamId } from 'selectors';
import * as budgetActionCreators from 'BudgetModule/actionCreators';
import * as globalActionCreators from 'actionCreators';
import {
  getBudgetModalProjectId,
  makeGetTeamMembershipByMemberBudgetId,
  makeGetAccountPhaseActivityBudgetRecords,
  makeGetAccountBudgetRecords,
  makeGetOwnPositionRateHistory,
  makeGetOwnPositionCostRateHistory,
  getPositionToCurrentPositionRate,
  getPositionToCurrentPositionCostRate,
  getMemberBudgets
} from '../selectors';
import * as globalSelectors from 'selectors';
import { fetchAllProjects } from 'actionCreators';
import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';
import { serializeBudgetRecord } from 'BudgetModule/utils';

const actionCreators = { ...budgetActionCreators, ...globalActionCreators };
const {
  fetchSpentHoursByDay,
  fetchPlannedHoursByDay,
  fetchMemberBudgetTotals,
  fetchMemberBudgets,
  fetchMemberBudgetPhase,
  createMemberBudget,
  updateMemberBudget,
  assignMemberBudget,
  deleteMemberBudget,
  archiveMemberBudgetPhase,
  unarchiveMemberBudgetPhase,
  createPhaseEstimation,
  updatePhaseEstimation,
  deletePhaseEstimation,
  fetchAllTeamRates,
  fetchMemberTeamRates,
  createMemberTeamRate,
  updateMemberTeamRate,
  deleteMemberTeamRate,
  fetchRates,
  createRate,
  updateRate,
  archiveRate,
  unarchiveRate,
  fetchTeamMembersRates,
  deleteMemberRate,
  // deleteRate,
  fetchMemberRates,
  createMemberRate,
  updateMemberRate,
  // deleteRate,
  fetchTeamRoles,
  createTeamRole,
  updateTeamRole,
  // deleteTeamRole,
  fetchProjectBudgetViewSettings,
  fetchTeamBudgetViewSettings,
  updateProjectBudgetViewSettings,
  updateTeamBudgetViewSettings,
  updateTeamMemberRate,
  bulkAssignActivityEstimations,
  bulkAssignActivityScheduleBars,
  bulkDeleteActivityScheduleBars,
  bulkAssignDescriptions,
  fetchAllTeamSalaries,
  fetchMemberSalaries,
  createMemberSalary,
  updateMemberSalary,
  deleteMemberSalary,
  fetchOverhead,
  createOverhead,
  updateActivityPhase,
  fetchPositions,
  fetchPosition,
  createPosition,
  updatePosition,
  deletePosition,
  archivePosition,
  fetchTeamPositions,
  createTeamPosition,
  updateTeamPosition,
  deleteTeamPosition,
  fetchMemberPositions,
  createMemberPosition,
  updateMemberPosition,
  deleteMemberPosition,
  fetchAccountPositions,
  createAccountPosition,
  updateAccountPosition,
  deleteAccountPosition,
  fetchPositionRates,
  createPositionRate,
  updatePositionRate,
  deletePositionRate,
  updatePositionSkills,
  createPositionSkills,
  fetchPositionSkills,
  deletePositionSkills,
  updatePositionLocationRange,
  deletePositionLocationRange,
  hardDeleteActivityPhase
} = entityActions;

export function* updateMemberBudgetOrderWorker(action) {
  const { sourceIndex, destinationIndex, phaseId } = action.payload;
  const phases = yield select(getPhases);
  const phase = phases[phaseId];
  const newOrder = [...phase.member_budget_orders];
  const budgetId = newOrder[sourceIndex];
  newOrder.splice(sourceIndex, 1);
  newOrder.splice(destinationIndex, 0, budgetId);

  yield put(
    actionCreators.updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      memberBudgetOrder: newOrder
    })
  );
}

export function* fetchSpentHoursByDayWorker(action) {
  const token = yield select(getAuthToken);
  const { phaseId, memberBudgetId } = action.payload;

  yield fetchEntity(
    fetchSpentHoursByDay,
    api.fetchSpentHoursByDay,
    memberBudgetId,
    [token, phaseId],
    action
  );
}
export function* fetchPlannedHoursByDayWorker(action) {
  const token = yield select(getAuthToken);
  const { phaseId, memberBudgetId } = action.payload;

  yield fetchEntity(
    fetchPlannedHoursByDay,
    api.fetchPlannedHoursByDay,
    memberBudgetId,
    [token, phaseId],
    action
  );
}
export function* fetchMemberBudgetsWorker(action) {
  const token = yield select(getAuthToken);
  const {
    projectId,
    projectIds,
    memberBudgetIds,
    onSuccess = []
  } = action.payload;
  const projectIdsToUse = projectId !== undefined ? [projectId] : projectIds;

  const { error, response } = yield fetchEntity(
    fetchMemberBudgets,
    api.fetchMemberBudgets,
    projectIdsToUse,
    [token, memberBudgetIds],
    action
  );
  yield put(
    actionCreators.fetchMemberBudgetTotals({
      projectIds: projectIdsToUse,
      memberBudgetIds
    })
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}
export function* fetchMemberBudgetTotalsWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, projectIds, memberBudgetIds } = action.payload;
  const projectIdsToUse = projectId !== undefined ? [projectId] : projectIds;

  yield fetchEntity(
    fetchMemberBudgetTotals,
    api.fetchMemberBudgetTotals,
    projectIdsToUse,
    [token, memberBudgetIds],
    action
  );
}
export function* fetchMemberBudgetPhaseWorker(action) {
  const token = yield select(getAuthToken);
  const { phaseId, accountId, projectId } = action.payload;

  yield fetchEntity(
    fetchMemberBudgetPhase,
    api.fetchMemberBudgetPhase,
    accountId,
    [token, phaseId, projectId],
    action
  );
}
export function* createMemberBudgetWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, memberBudgets, refetchScopes, onSuccess } = action.payload;

  const { error, response } = yield changeEntity(
    createMemberBudget,
    api.createMemberBudget,
    [token, projectId, memberBudgets],
    action,
    { projectId, memberBudgets }
  );

  if (!error && response) {
    yield put(
      actionCreators.fetchMemberBudgets({
        projectId
      })
    );
    yield put(
      actionCreators.fetchPhases({
        projectId
      })
    );
    yield put(actionCreators.fetchPhaseTotals({ projectId }));
    if (refetchScopes) {
      yield put(actionCreators.fetchScopes({ projectId, all: true }));
    }
    onSuccess && onSuccess({ error, response });
  }
}
export function* updateMemberBudgetWorker(action) {
  const token = yield select(getAuthToken);
  const {
    memberBudgetId,
    teamRoleId,
    startDate,
    endDate,
    requiredHours,
    radius
  } = action.payload;

  yield changeEntity(
    updateMemberBudget,
    api.updateMemberBudget,
    [
      token,
      memberBudgetId,
      teamRoleId,
      startDate,
      endDate,
      requiredHours,
      radius
    ],
    action
  );
}
export function* assignMemberBudgetWorker(action) {
  const token = yield select(getAuthToken);

  const {
    memberBudgetId,
    projectMembershipId,
    projectId,
    accountId,
    onSuccess = []
  } = action.payload;

  const { error, response } = yield changeEntity(
    assignMemberBudget,
    api.assignMemberBudget,
    [token, memberBudgetId, projectMembershipId, accountId],
    undefined,
    action
  );

  onSuccess.forEach(({ successAction, selector }) =>
    successAction(selector(action.payload, response))
  );

  if (response) {
    const memberBudgetId = response.project_membership.member_budget_id;
    yield put(
      actionCreators.fetchMemberBudgets({
        projectId,
        memberBudgetIds: [memberBudgetId]
      })
    );
  }
  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  yield put(
    actionCreators.fetchPhaseTotals({
      projectId
    })
  );
}
export function* deleteMemberBudgetWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, id, onSuccess = [] } = action.payload;

  const { error, response } = yield changeEntity(
    deleteMemberBudget,
    api.deleteMemberBudget,
    [token, id],
    action,
    action.payload
  );

  // refetch project member budgets and phases (to update phase_memberships)
  if (!error && response && projectId !== undefined) {
    yield put(
      actionCreators.fetchMemberBudgets({
        projectId
      })
    );
    yield put(
      actionCreators.fetchPhases({
        projectId
      })
    );
  }

  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}
export function* archiveMemberBudgetPhaseWorker(action) {
  const token = yield select(getAuthToken);
  const { memberBudgetId, phaseId, projectId } = action.payload;
  yield changeEntity(
    archiveMemberBudgetPhase,
    api.archiveMemberBudgetPhase,
    [token, memberBudgetId, phaseId],
    action
  );
  yield put(
    actionCreators.fetchMemberBudgets({
      projectId,
      memberBudgetIds: [memberBudgetId]
    })
  );
  yield put(
    actionCreators.fetchPhaseTotals({
      projectId
    })
  );
}
export function* unarchiveMemberBudgetPhaseWorker(action) {
  const token = yield select(getAuthToken);
  const { memberBudgetId, phaseId, projectId } = action.payload;
  yield changeEntity(
    unarchiveMemberBudgetPhase,
    api.unarchiveMemberBudgetPhase,
    [token, memberBudgetId, phaseId],
    action
  );
  yield put(
    actionCreators.fetchMemberBudgets({
      projectId,
      memberBudgetIds: [memberBudgetId]
    })
  );
  yield put(
    actionCreators.fetchPhaseTotals({
      projectId
    })
  );
}

export function* createPhaseEstimationWorker(action) {
  const token = yield select(getAuthToken);
  const {
    phase_id,
    member_budget_id,
    estimated_hours,
    estimated_percentage,
    estimated_amount,
    project_id,
    activity_id,
    scope_id,
    refetchPhaseTotalsFilterStateId
  } = action.payload;

  const { error, response } = yield changeEntity(
    createPhaseEstimation,
    api.createPhaseEstimation,
    [
      token,
      member_budget_id,
      phase_id,
      estimated_hours,
      estimated_amount,
      estimated_percentage,
      activity_id,
      scope_id
    ],
    action
  );
  yield put(
    actionCreators.fetchMemberBudgets({
      projectId: project_id,
      memberBudgetIds: [member_budget_id]
    })
  );

  yield put(
    actionCreators.fetchPhaseTotals({
      projectId: project_id,
      ...(refetchPhaseTotalsFilterStateId && {
        filterStateId: refetchPhaseTotalsFilterStateId
      })
    })
  );

  // Update aggregate if exists (eg. if the budget modal was opened
  // on Budget Report page)
  const teamId = yield select(globalSelectors.getSelectedTeamId);
  const getAccountPhaseActivityBudgetRecords =
    makeGetAccountPhaseActivityBudgetRecords();
  const accountPhaseActivityBudgetRecords = yield select(
    getAccountPhaseActivityBudgetRecords
  );
  const getAccountBudgetRecords = makeGetAccountBudgetRecords();
  const accountBudgetRecords = yield select(getAccountBudgetRecords);
  const getTeamMembershipByMemberBudgetId =
    makeGetTeamMembershipByMemberBudgetId();
  const teamMembershipByMemberBudgetId = yield select((state) =>
    getTeamMembershipByMemberBudgetId(state, {
      memberBudgetId: member_budget_id
    })
  );
  const accountId = teamMembershipByMemberBudgetId?.account?.id;

  if (!error && response.phase_id && response.activity_id) {
    const accountPhaseActivityGroupId = serializeBudgetRecord({
      aggregate_type: BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE_ACTIVITY,
      account_id: accountId,
      phase_id,
      activity_id: response.activity_id
    });

    if (accountPhaseActivityBudgetRecords[accountPhaseActivityGroupId]) {
      yield put(
        actionCreators.fetchBudgetRecords({
          params: {
            team_id: teamId,
            phase_ids: [response.phase_id],
            activity_ids: [response.activity_id],
            data_type: BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE_ACTIVITY
          }
        })
      );
    }

    if (accountBudgetRecords[accountId]) {
      yield put(
        actionCreators.fetchBudgetRecords({
          params: {
            team_id: teamId,
            account_ids: [accountId],
            data_type: BUDGET_RECORD_DATA_TYPES.ACCOUNT
          }
        })
      );
    }
  }
}
export function* updatePhaseEstimationWorker(action) {
  const token = yield select(getAuthToken);
  const {
    id,
    member_budget_id,
    estimated_hours,
    project_id,
    estimated_amount,
    estimated_percentage,
    activity_id,
    refetchPhaseTotalsFilterStateId
  } = action.payload;

  yield changeEntity(
    updatePhaseEstimation,
    api.updatePhaseEstimation,
    [
      token,
      id,
      member_budget_id,
      estimated_hours,
      estimated_amount,
      estimated_percentage,
      activity_id
    ],
    action
  );
  yield put(
    actionCreators.fetchMemberBudgets({
      projectId: project_id,
      memberBudgetIds: [member_budget_id]
    })
  );

  yield put(
    actionCreators.fetchPhaseTotals({
      projectId: project_id,
      ...(refetchPhaseTotalsFilterStateId && {
        filterStateId: refetchPhaseTotalsFilterStateId
      })
    })
  );
}
export function* deletePhaseEstimationWorker(action) {
  const token = yield select(getAuthToken);
  const { id, member_budget_id, project_id } = action.payload;

  yield changeEntity(
    deletePhaseEstimation,
    api.deletePhaseEstimation,
    [token, id, member_budget_id],
    action
  );

  yield put(
    actionCreators.fetchMemberBudgets({
      projectId: project_id,
      memberBudgetIds: [member_budget_id]
    })
  );

  yield put(
    actionCreators.fetchPhaseTotals({
      projectId: project_id
    })
  );
}

export function* fetchAllTeamRatesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(
    fetchAllTeamRates,
    api.fetchAllTeamRates,
    teamId,
    [token],
    action
  );
}

export function* fetchMemberTeamRatesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamMembershipId } = action.payload;

  yield fetchEntity(
    fetchMemberTeamRates,
    api.fetchMemberTeamRates,
    teamMembershipId,
    [token],
    action
  );
}

export function* createMemberTeamRateWorker(action) {
  const token = yield select(getAuthToken);
  const { teamMembershipId, rateId, startDate, endDate, override, teamId } =
    action.payload;

  yield changeEntity(
    createMemberTeamRate,
    api.createMemberTeamRate,
    [token, teamMembershipId, rateId, startDate, endDate, override],
    action,
    action.payload // saga payload used in reducer on success
  );
  yield put(actionCreators.fetchMemberTeamRates({ teamMembershipId }));
  yield put(actionCreators.fetchAllTeamRates({ teamId }));
}

export function* updateMemberTeamRateWorker(action) {
  const token = yield select(getAuthToken);
  const {
    teamRateId,
    rateId,
    startDate,
    endDate,
    teamId,
    teamMembershipId,
    override
  } = action.payload;

  const { error, response } = yield changeEntity(
    updateMemberTeamRate,
    api.updateMemberTeamRate,
    [token, teamRateId, rateId, startDate, endDate, override],
    action
  );

  yield put(actionCreators.fetchMemberTeamRates({ teamMembershipId }));
  yield put(actionCreators.fetchAllTeamRates({ teamId }));
}

export function* deleteMemberTeamRateWorker(action) {
  const token = yield select(getAuthToken);
  const { teamRateId, teamId, teamMembershipId } = action.payload;
  yield changeEntity(
    deleteMemberTeamRate,
    api.deleteMemberTeamRate,
    [token, teamRateId],
    action,
    action.payload
  );

  yield put(actionCreators.fetchAllTeamRates({ teamId }));
  yield put(actionCreators.fetchMemberTeamRates({ teamMembershipId }));
}

export function* fetchRatesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(fetchRates, api.fetchRates, teamId, [token], action);
}
export function* fetchTeamMembersRatesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, includeArchived = false } = action.payload;

  yield fetchEntity(
    fetchTeamMembersRates,
    api.fetchTeamMembersRates,
    teamId,
    [token, includeArchived],
    action
  );
}
export function* updateTeamMemberRateWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, rateId, accountId, overrideExisting = true } = action.payload;

  yield changeEntity(
    updateTeamMemberRate,
    api.updateTeamMemberRate,
    [token, teamId, accountId, rateId, overrideExisting],
    action
  );
}
export function* createRateWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, description, rate, multiplierLow, multiplierHigh } =
    action.payload;

  yield changeEntity(
    createRate,
    api.createRate,
    [token, teamId, description, rate, multiplierLow, multiplierHigh],
    action
  );
}
export function* updateRateWorker(action) {
  const token = yield select(getAuthToken);
  const { id, description, rate, multiplierLow, multiplierHigh, mergeRateId } =
    action.payload;

  const { error, response } = yield changeEntity(
    updateRate,
    api.updateRate,
    [token, id, description, rate, multiplierLow, multiplierHigh, mergeRateId],
    action,
    action.payload
  );

  if (!error && response && mergeRateId) {
    const teamId = yield select(getSelectedTeamId);
    // refetch since one of the rates was deleted
    yield put(
      actionCreators.fetchRates({
        teamId
      })
    );
  }
}
export function* archiveRateWorker(action) {
  const token = yield select(getAuthToken);
  const { id, description, rate } = action.payload;

  yield changeEntity(
    archiveRate,
    api.archiveRate,
    [token, id, description, rate],
    action
  );
}
export function* unarchiveRateWorker(action) {
  const token = yield select(getAuthToken);
  const { id, description, rate } = action.payload;

  yield changeEntity(
    unarchiveRate,
    api.unarchiveRate,
    [token, id, description, rate],
    action
  );
}
// export function* deleteRateWorker(action) {
//   const token = yield select(getAuthToken);
//   const { projectId, id } = action.payload;

//   yield changeEntity(
//     deleteRate,
//     api.deleteRate,
//     projectId,
//     [token, projectId, id],
//     action
//   );
// }

export function* fetchMemberRatesWorker(action) {
  const token = yield select(getAuthToken);
  const { memberBudgetId, onSuccess = [] } = action.payload;

  const { error, response } = yield fetchEntity(
    fetchMemberRates,
    api.fetchMemberRates,
    memberBudgetId,
    [token],
    action
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}
export function* createMemberRateWorker(action) {
  const token = yield select(getAuthToken);
  const modalProjectId = yield select(getBudgetModalProjectId);
  const { memberBudgetId, rateId, startDate, endDate } = action.payload;
  const projectId = modalProjectId || action.payload.projectId;
  yield changeEntity(
    createMemberRate,
    api.createMemberRate,
    [token, memberBudgetId, rateId, startDate, endDate],
    action
  );
  yield put(
    actionCreators.fetchMemberBudgets({
      projectId,
      memberBudgetIds: [memberBudgetId]
    })
  );
  yield put(
    actionCreators.fetchMemberRates({
      projectId,
      memberBudgetId
    })
  );
  yield put(
    actionCreators.fetchPhaseTotals({
      projectId
    })
  );
}
export function* updateMemberRateWorker(action) {
  const token = yield select(getAuthToken);
  const { id, rate, start_date, end_date, memberBudgetId, projectId } =
    action.payload;
  yield changeEntity(
    updateMemberRate,
    api.updateMemberRate,
    [token, id, rate.id, start_date, end_date],
    action
  );
  yield put(
    actionCreators.fetchMemberRates({
      projectId,
      memberBudgetId
    })
  );
  yield put(
    actionCreators.fetchMemberBudgets({
      projectId,
      memberBudgetIds: [memberBudgetId]
    })
  );
  yield put(
    actionCreators.fetchPhaseTotals({
      projectId
    })
  );
}
export function* deleteMemberRateWorker(action) {
  const token = yield select(getAuthToken);
  const { id, projectId, memberBudgetId } = action.payload;

  yield changeEntity(
    deleteMemberRate,
    api.deleteMemberRate,
    [token, id],
    action
  );

  yield put(
    actionCreators.fetchMemberRates({
      projectId,
      memberBudgetId
    })
  );
  yield put(
    actionCreators.fetchMemberBudgets({
      projectId,
      memberBudgetIds: [memberBudgetId]
    })
  );
  yield put(
    actionCreators.fetchPhaseTotals({
      projectId
    })
  );
}

/* -------------------------------- Salaries -------------------------------- */

export function* fetchAllTeamSalariesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(
    fetchAllTeamSalaries,
    api.fetchAllTeamSalaries,
    teamId,
    [token],
    action
  );
}
export function* fetchMemberSalariesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamMembershipId, teamId } = action.payload;

  yield fetchEntity(
    fetchMemberSalaries,
    api.fetchMemberSalaries,
    teamMembershipId,
    [token, teamId],
    action,
    action.payload
  );
}
export function* createMemberSalaryWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const {
    activeRateType,
    actualHourlyRate,
    annualRate,
    description,
    endDate,
    hourlyRate,
    hoursPerYear,
    overheadFactor,
    startDate,
    teamMembershipId
  } = action.payload;
  const body = {
    active_rate_type: activeRateType,
    actual_hourly_rate: actualHourlyRate,
    annual_rate: annualRate,
    description,
    end_date: endDate,
    hourly_rate: hourlyRate,
    hours_per_year: hoursPerYear,
    overhead_factor: overheadFactor,
    start_date: startDate,
    team_membership_id: teamMembershipId
  };
  const { error, response } = yield changeEntity(
    createMemberSalary,
    api.createMemberSalary,
    [token, body],
    action,
    action.payload
  );
  if (!error && response) {
    yield put(
      budgetActionCreators.fetchMemberSalaries({
        teamMembershipId,
        teamId
      })
    );
  }
}
export function* updateMemberSalaryWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const {
    id,
    activeRateType,
    actualHourlyRate,
    annualRate,
    description,
    endDate,
    hourlyRate,
    hoursPerYear,
    overheadFactor,
    startDate,
    teamMembershipId
  } = action.payload;
  const body = {
    active_rate_type: activeRateType,
    actual_hourly_rate: actualHourlyRate,
    annual_rate: annualRate,
    description,
    end_date: endDate,
    hourly_rate: hourlyRate,
    hours_per_year: hoursPerYear,
    overhead_factor: overheadFactor,
    start_date: startDate
  };
  yield changeEntity(
    updateMemberSalary,
    api.updateMemberSalary,
    [token, id, body],
    action,
    action.payload
  );
  yield delay(1000);
  yield put(
    budgetActionCreators.fetchMemberSalaries({
      teamMembershipId,
      teamId
    })
  );
}
export function* deleteMemberSalaryWorker(action) {
  const token = yield select(getAuthToken);
  const { id, teamId, teamMembershipId } = action.payload;

  const { error, response } = yield changeEntity(
    deleteMemberSalary,
    api.deleteMemberSalary,
    [token, id],
    action,
    action.payload
  );

  if (!error) {
    yield put(
      budgetActionCreators.fetchAllTeamSalaries({
        teamId
      })
    );
    yield put(
      budgetActionCreators.fetchMemberSalaries({
        teamId,
        teamMembershipId
      })
    );
  }
}

/* -------------------------------- Overhead -------------------------------- */

export function* fetchOverheadWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(
    fetchOverhead,
    api.fetchOverhead,
    teamId,
    [token],
    action,
    action.payload
  );
}

export function* createOverheadWorker(action) {
  const token = yield select(getAuthToken);
  const {
    teamId,
    totalPayroll,
    totalExpense,
    overheadFactor,
    actualHourlyRate,
    onSuccess = []
  } = action.payload;
  const body = {
    team_id: teamId,
    total_payroll: totalPayroll,
    total_expense: totalExpense,
    overhead_factor: overheadFactor,
    actual_hourly_rate: actualHourlyRate
  };
  const { error, response } = yield changeEntity(
    createOverhead,
    api.createOverhead,
    [token, body],
    action,
    action.payload
  );

  if (!error) {
    yield put(
      budgetActionCreators.fetchAllTeamSalaries({
        teamId
      })
    );
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

/* ------------------------------- Team Roles ------------------------------- */

export function* fetchTeamRolesWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(
    fetchTeamRoles,
    api.fetchTeamRoles,
    teamId,
    [token],
    action
  );
}
export function* createTeamRoleWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, name } = action.payload;

  yield changeEntity(
    createTeamRole,
    api.createTeamRole,
    [token, teamId, name],
    action
  );
}
export function* updateTeamRoleWorker(action) {
  const token = yield select(getAuthToken);
  const { team_role_id, name } = action.payload;

  yield changeEntity(
    updateTeamRole,
    api.updateTeamRole,
    [token, team_role_id, name],
    action
  );
}
// export function* deleteRateWorker(action) {
//   const token = yield select(getAuthToken);
//   const { projectId, id } = action.payload;

//   yield changeEntity(
//     deleteRate,
//     api.deleteRate,
//     projectId,
//     [token, projectId, id],
//     action
//   );
// }

export function* fetchProjectBudgetViewSettingsWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId } = action.payload;

  yield fetchEntity(
    fetchProjectBudgetViewSettings,
    api.fetchProjectBudgetViewSettings,
    projectId,
    [token],
    action
  );
}
export function* updateProjectBudgetViewSettingsWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, budgetSettings } = action.payload;

  yield changeEntity(
    updateProjectBudgetViewSettings,
    api.updateProjectBudgetViewSettings,
    [token, projectId, budgetSettings],
    action,
    action.payload
  );
}
export function* fetchTeamBudgetViewSettingsWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId } = action.payload;

  yield fetchEntity(
    fetchTeamBudgetViewSettings,
    api.fetchTeamBudgetViewSettings,
    teamId,
    [token],
    action
  );
}
export function* updateTeamBudgetViewSettingsWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, body } = action.payload;

  yield changeEntity(
    updateTeamBudgetViewSettings,
    api.updateTeamBudgetViewSettings,
    [token, teamId, body],
    action
  );
}

export function* openBudgetModalWorker(action) {
  if (!action.payload.skipNavigation) {
    yield put(
      actionCreators.navigateToBudgetModal({
        budgetViewType: 'budget',
        projectId: action.payload.id
      })
    );
  }
}

export function* closeBudgetModalWorker() {
  yield put(actionCreators.navigateUp());
}

export function* bulkAssignActivityWorker(action) {
  const token = yield select(getAuthToken);
  const { phaseId, activityId, projectId } = action.payload;
  const body = {
    phase_id: phaseId,
    target_activity_id: activityId
  };

  yield changeEntity(
    bulkAssignActivityEstimations,
    api.bulkAssignActivityEstimations,
    [token, body],
    action
  );
  yield changeEntity(
    bulkAssignActivityScheduleBars,
    api.bulkAssignActivityScheduleBars,
    [token, body],
    action
  );
  yield put(
    actionCreators.fetchMemberBudgets({
      projectId
    })
  );
  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
  yield put(
    actionCreators.fetchPhaseTotals({
      projectId
    })
  );
}

export function* bulkMoveTimeAndRemoveActivityWorker(action) {
  const { sourceActivityId, phaseId, activityId, phase } = action.payload;
  const token = yield select(getAuthToken);
  const body = {
    phase_id: phaseId,
    target_activity_id: activityId,
    source_activity_id: sourceActivityId
  };

  const descriptionResponse = yield changeEntity(
    bulkAssignDescriptions,
    api.bulkAssignDescriptions,
    [token, body],
    action
  );

  const scheduleBarResponse = yield changeEntity(
    bulkAssignActivityScheduleBars,
    api.bulkAssignActivityScheduleBars,
    [token, body],
    action
  );

  if (
    (!descriptionResponse.error ||
      descriptionResponse.error === 'Unable to find any Descriptions') &&
    !scheduleBarResponse.error
  ) {
    yield put(
      actionCreators.updatePhase({
        id: phase.id,
        projectId: phase.project_id,
        activityOrder: phase.activity_order.filter(
          (id) => id !== sourceActivityId
        )
      })
    );
  }
}

export function* bulkDeleteTimeAndRemoveActivityWorker(action) {
  const { phaseId, activityId, projectId, phase } = action.payload;
  const token = yield select(getAuthToken);
  const body = {
    phase_id: phaseId,
    activity_id: activityId
  };
  const { error, response } = yield changeEntity(
    bulkDeleteActivityScheduleBars,
    api.bulkDeleteActivityScheduleBars,
    [token, body],
    action
  );

  if (!error) {
    yield put(
      actionCreators.updatePhase({
        id: phase.id,
        projectId: phase.project_id,
        activityOrder: phase.activity_order.filter((id) => id !== activityId)
      })
    );
  }
}

export function* updateActivityPhaseWorker(action) {
  const token = yield select(getAuthToken);
  const {
    id,
    total,
    projectId,
    estimatedCost,
    startDate,
    endDate,
    workDays,
    title,
    activityId,
    profitPercentage,
    estimated_hours,
    budget_activity_phase_by,
    feeType,
    billable,
    percentageOfPhaseBudget,
    budgetFixedFeeWith,
    budgetHourlyWith,
    budgetInternalWith,
    onSuccess = []
  } = action.payload;

  const body = {
    total,
    estimated_cost: estimatedCost,
    start_date: startDate,
    end_date: endDate,
    total_work_days: workDays,
    title: title,
    activity_id: activityId,
    profit_percentage: profitPercentage,
    estimated_hours: estimated_hours,
    budget_activity_phase_by: budget_activity_phase_by,
    fee_type: feeType,
    percentage_of_phase: percentageOfPhaseBudget,
    budget_fixed_fee_with: budgetFixedFeeWith,
    budget_hourly_with: budgetHourlyWith,
    budget_internal_with: budgetInternalWith,
    billable
  };

  const { error, response } = yield changeEntity(
    updateActivityPhase,
    api.updateActivityPhase,
    [token, id, body],
    action,
    action.payload
  );

  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
    yield put(
      actionCreators.fetchPhases({
        projectId
      })
    );
    yield put(actionCreators.fetchActivities({}));
  }
}

export function* hardDeleteActivityPhaseWorker(action) {
  const token = yield select(getAuthToken);
  const { id, projectId } = action.payload;

  yield changeEntity(
    hardDeleteActivityPhase,
    api.hardDeleteActivityPhase,
    [token, id],
    action,
    action.payload
  );

  yield put(
    actionCreators.fetchPhases({
      projectId
    })
  );
}

/* --------------------------------- Billing -------------------------------- */

export function* fetchProjectBillingCategoriesWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId } = action.payload;
  yield fetchEntity(
    entityActions.fetchProjectBillingCategories,
    api.fetchProjectBillingCategories,
    projectId,
    [token],
    action
  );
}

export function* fetchProjectInvoicesWorker(action) {
  const token = yield select(getAuthToken);
  const { projectId, is_estimate } = action.payload;

  yield fetchEntity(
    entityActions.fetchProjectInvoices,
    api.fetchProjectInvoices,
    projectId,
    [token, is_estimate],
    action
  );
}

export function* createProjectInvoiceWorker(action) {
  const token = yield select(getAuthToken);
  const {
    phaseId,
    billingCategoryId,
    periodStart,
    periodEnd,
    invoiceType,
    percentage,
    isEstimate,
    invoiceDate,
    amount,
    invoiceNumber,
    notes,
    projectId
  } = action.payload;

  const body = {
    phase_id: phaseId,
    billing_category_id: billingCategoryId,
    period_start: periodStart,
    period_end: periodEnd,
    invoice_type: invoiceType,
    percentage,
    is_estimate: isEstimate,
    invoice_date: invoiceDate,
    amount,
    invoice_number: invoiceNumber,
    notes
  };

  yield changeEntity(
    entityActions.createProjectInvoice,
    api.createProjectInvoice,
    [token, body, projectId],
    action,
    action.payload
  );
}

export function* updateProjectInvoiceWorker(action) {
  const token = yield select(getAuthToken);
  const { body, project_id, id } = action.payload;

  yield changeEntity(
    entityActions.updateProjectInvoice,
    api.updateProjectInvoice,
    [token, id, body, project_id],
    action,
    action.payload
  );
}

export function* deleteProjectInvoiceWorker(action) {
  const token = yield select(getAuthToken);
  const { invoiceId, projectId } = action.payload;

  yield changeEntity(
    entityActions.deleteProjectInvoice,
    api.deleteProjectInvoice,
    [token, invoiceId, projectId],
    action,
    action.payload
  );
}

/* ----------------------------- Budget records ----------------------------- */
export function* fetchBudgetRecordsWorker(action) {
  const token = yield select(getAuthToken);
  const projectHash = yield select(globalSelectors.getProjectHash);
  const isFetchingAccountsProjects = yield select(
    (state) => state.budgetRecords.isFetchingAccountsProjects
  );

  if (isFetchingAccountsProjects) yield cancel();

  const { params, shouldFetchProjects = true } = action.payload;

  const { error, response } = yield fetchEntity(
    entityActions.fetchBudgetRecords,
    api.fetchBudgetRecords,
    params,
    [token],
    action
  );

  if (
    shouldFetchProjects &&
    response?.records &&
    [
      BUDGET_RECORD_DATA_TYPES.PROJECT,
      BUDGET_RECORD_DATA_TYPES.ACCOUNT_PROJECT,
      BUDGET_RECORD_DATA_TYPES.ACCOUNT_POSITION_PROJECT
    ].includes(params.data_type)
  ) {
    // Fetch unloaded projects
    const projectIds =
      params.project_ids || response.records.map((record) => record.project_id);
    const unloadedProjectIds = Array.from(
      new Set(projectIds.filter((id) => !projectHash[id]))
    );
    if (unloadedProjectIds.length) {
      yield put(fetchAllProjects({ projectIds: unloadedProjectIds }));
    }
  }
}

export function* fetchProjectBudgetRecordWorker(action) {
  const token = yield select(getAuthToken);
  const { projectIds, teamId, onSuccess = [] } = action.payload;
  const params = {
    team_id: teamId,
    project_ids: projectIds,
    data_type: BUDGET_RECORD_DATA_TYPES.PROJECT
  };
  const { error, response } = yield fetchEntity(
    entityActions.fetchProjectBudgetRecord,
    api.fetchBudgetRecords,
    params,
    [token],
    action
  );

  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

/* -------------------------------- Positions ------------------------------- */

export function* fetchPositionsWorker(action) {
  const token = yield select(getAuthToken);
  const { onSuccess = [], teamId } = action.payload;

  const { error, response } = yield fetchEntity(
    fetchPositions,
    api.fetchPositions,
    teamId,
    [token],
    action
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

export function* fetchPositionWorker(action) {
  const token = yield select(getAuthToken);
  const { id } = action.payload;

  yield fetchEntity(fetchPosition, api.fetchPosition, id, [token], action);
}

export function* createPositionWorker(action) {
  const token = yield select(getAuthToken);
  const { name, teamId, rateId } = action.payload;

  yield changeEntity(
    createPosition,
    api.createPosition,
    [token, { name, team_id: teamId, rate_id: rateId }],
    action,
    action.payload
  );
}

export function* updatePositionWorker(action) {
  const token = yield select(getAuthToken);
  const { name, id, mergePositionId } = action.payload;

  const { error, response } = yield changeEntity(
    updatePosition,
    api.updatePosition,
    [
      token,
      {
        name,
        position_id: id,
        merge_position_id: mergePositionId
      }
    ],
    action,
    action.payload
  );

  if (!error && response && mergePositionId) {
    const teamId = yield select(getSelectedTeamId);
    // refetch since one of the positions was deleted
    yield put(
      actionCreators.fetchPositions({
        teamId
      })
    );
  }
}

// do not use for now
export function* deletePositionWorker(action) {
  const token = yield select(getAuthToken);
  const { id } = action.payload;

  yield changeEntity(
    deletePosition,
    api.deletePosition,
    [token, id],
    action,
    action.payload
  );
}

export function* archivePositionWorker(action) {
  const token = yield select(getAuthToken);
  const { id, archive = true } = action.payload;

  const { error, response } = yield changeEntity(
    archivePosition,
    api.archivePosition,
    [token, id, archive],
    action,
    action.payload
  );
  if (!error && response) {
    yield put(actionCreators.fetchPosition({ id }));
  }
}

/* ----------------------------- Team Positions ----------------------------- */

export function* fetchTeamPositionsWorker(action) {
  const token = yield select(getAuthToken);
  // isActive means fetching only current team roles
  const { teamId, positionId, accountId, isActive = true } = action.payload;

  yield fetchEntity(
    fetchTeamPositions,
    api.fetchTeamPositions,
    teamId,
    [token, positionId, accountId, isActive],
    action
  );
}

export function* createTeamPositionWorker(action) {
  const token = yield select(getAuthToken);
  const {
    name,
    teamId,
    accountId,
    positionId,
    startDate,
    endDate,
    overrideMemberPositions // apply change to all projects
  } = action.payload;

  const { error, response } = yield changeEntity(
    createTeamPosition,
    api.createTeamPosition,
    [
      token,
      {
        name,
        team_id: teamId,
        account_id: accountId,
        position_id: positionId,
        start_date: startDate,
        end_date: endDate,
        override_member_positions: overrideMemberPositions
      }
    ],
    action,
    action.payload
  );
  if (!error && response) {
    yield put(
      actionCreators.fetchTeamPositions({
        teamId,
        accountId,
        isActive: false
      })
    );
  }
}

export function* updateTeamPositionWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const {
    name,
    id,
    positionId,
    startDate,
    endDate,
    overrideMemberPositions, // apply change to all projects
    accountId // for re-fetching below
  } = action.payload;

  const { error, response } = yield changeEntity(
    updateTeamPosition,
    api.updateTeamPosition,
    [
      token,
      {
        name,
        team_position_id: id,
        position_id: positionId,
        start_date: startDate,
        end_date: endDate,
        override_member_positions: overrideMemberPositions
      }
    ],
    action,
    action.payload
  );
  if (!error && response) {
    yield put(
      actionCreators.fetchTeamPositions({
        teamId,
        accountId,
        isActive: false
      })
    );
  }
}

export function* deleteTeamPositionWorker(action) {
  const token = yield select(getAuthToken);
  const { id } = action.payload;

  yield changeEntity(
    deleteTeamPosition,
    api.deleteTeamPosition,
    [token, id],
    action,
    action.payload
  );
}

/* ----------------------------- Member Positions ----------------------------- */

export function* fetchMemberPositionsWorker(action) {
  const token = yield select(getAuthToken);
  const {
    teamId,
    projectId,
    phaseId,
    memberBudgetId,
    accountId,
    positionId,
    includeArchived
  } = action.payload;

  const params = {
    team_id: teamId,
    project_id: projectId,
    phase_id: phaseId,
    member_budget_id: memberBudgetId,
    account_id: accountId,
    position_id: positionId,
    include_archived: includeArchived
  };

  yield fetchEntity(
    fetchMemberPositions,
    api.fetchMemberPositions,
    params,
    [token],
    action
  );
}

// not used
export function* createMemberPositionWorker(action) {
  const token = yield select(getAuthToken);
  const {
    memberBudgetId,
    accountId,
    endDate,
    positionId,
    startDate,
    teamId,
    projectId,
    phaseId,
    overridePhaseMemberPositions
  } = action.payload;

  const body = {
    member_budget_id: memberBudgetId,
    account_id: accountId,
    end_date: endDate,
    position_id: positionId,
    start_date: startDate,
    team_id: teamId,
    project_id: projectId,
    phase_id: phaseId,
    override_phase_member_positions: overridePhaseMemberPositions
  };

  const { error, response } = yield changeEntity(
    createMemberPosition,
    api.createMemberPosition,
    [token, body],
    action,
    action.payload
  );
  if (!error && response) {
    yield put(
      actionCreators.fetchMemberBudgets({
        projectId,
        memberBudgetIds: [memberBudgetId]
      })
    );
  }
}

export function* updateMemberPositionWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const {
    endDate,
    positionId,
    startDate,
    id,
    overridePhaseMemberPositions,
    projectId, // for re-fetching member budgets only
    memberBudgetId, // for re-fetching only
    onSuccess = []
  } = action.payload;

  const body = {
    end_date: endDate,
    position_id: positionId,
    start_date: startDate,
    member_position_id: id,
    override_phase_member_positions: overridePhaseMemberPositions // not used yet
  };

  const { error, response } = yield changeEntity(
    updateMemberPosition,
    api.updateMemberPosition,
    [token, body],
    action,
    action.payload
  );
  if (!error && response) {
    if (projectId) {
      yield put(
        actionCreators.fetchMemberBudgets({
          projectId,
          memberBudgetIds: [memberBudgetId]
        })
      );
    }
    yield put(
      actionCreators.fetchMemberPositions({
        teamId,
        memberBudgetId
      })
    );
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

// not used
export function* deleteMemberPositionWorker(action) {
  const token = yield select(getAuthToken);
  const { id, projectId, memberBudgetId } = action.payload;

  const { error, response } = yield changeEntity(
    deleteMemberPosition,
    api.deleteMemberPosition,
    [token, id],
    action,
    action.payload
  );
  if (!error && response && projectId) {
    yield put(
      actionCreators.fetchMemberBudgets({
        projectId,
        memberBudgetIds: [memberBudgetId]
      })
    );
  }
}

/* ---------------------------- Account Positions --------------------------- */

export function* fetchAccountPositionsWorker(action) {
  const token = yield select(getAuthToken);
  const { teamId, positionId, accountId, includeArchived } = action.payload;

  const params = {
    team_id: teamId,
    position_id: positionId,
    account_id: accountId,
    include_archived: includeArchived
  };

  yield fetchEntity(
    fetchAccountPositions,
    api.fetchAccountPositions,
    params,
    [token],
    action
  );
}

export function* createAccountPositionWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { accountId, positionId, overrideMemberPositions } = action.payload;

  const body = {
    team_id: teamId,
    account_id: accountId,
    position_id: positionId,
    override_member_positions: overrideMemberPositions
  };

  const { error, response } = yield changeEntity(
    createAccountPosition,
    api.createAccountPosition,
    [token, body],
    action,
    action.payload
  );

  if (!error && response) {
    yield put(
      actionCreators.fetchAccountPositions({
        teamId,
        accountId: response.account_id
      })
    );
  }
}
export function* updateAccountPositionWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { id, positionId } = action.payload;

  const body = {
    position_id: positionId,
    account_position_id: id
  };

  const { error, response } = yield changeEntity(
    updateAccountPosition,
    api.updateAccountPosition,
    [token, body],
    action,
    action.payload
  );

  if (!error && response) {
    yield put(
      actionCreators.fetchAccountPositions({
        teamId,
        accountId: response.account_id
      })
    );
  }
}
export function* deleteAccountPositionWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { id, accountId } = action.payload;

  const { error, response } = yield changeEntity(
    deleteAccountPosition,
    api.deleteAccountPosition,
    [token, id],
    action,
    action.payload
  );

  // it will not refetch all the data but only the account that had deleted accountPosition id
  if (!error && response && accountId) {
    yield put(
      actionCreators.fetchAccountPositions({
        teamId,
        accountId
      })
    );
  }
}

/* ----------------------------- Position Rates ----------------------------- */

export function* fetchPositionRatesWorker(action) {
  const token = yield select(getAuthToken);
  const {
    teamId,
    positionId,
    rateId,
    isCostRate,
    activeOnly,
    includeArchived
  } = action.payload;

  yield fetchEntity(
    fetchPositionRates,
    api.fetchPositionRates,
    teamId,
    [token, positionId, rateId, isCostRate, activeOnly, includeArchived],
    action
  );
}

export function* createPositionRateWorker(action) {
  const token = yield select(getAuthToken);
  const {
    teamId,
    positionId,
    rateId,
    startDate,
    endDate,
    isCostRate,
    rateAmount,
    overrideUnassignedMemberRates
  } = action.payload;

  const { error, response } = yield changeEntity(
    createPositionRate,
    api.createPositionRate,
    [
      token,
      {
        team_id: teamId,
        position_id: positionId,
        rate_id: rateId,
        start_date: startDate,
        end_date: endDate,
        is_cost_rate: isCostRate,
        rate_amount: rateAmount,
        override_unassigned_member_rates: overrideUnassignedMemberRates
      }
    ],
    action,
    action.payload
  );

  if (!error && response) {
    yield call(refetchPositionRates, {
      positionId,
      isCostRate
    });
  }
}

export function* updatePositionRateWorker(action) {
  const token = yield select(getAuthToken);
  const {
    original,
    rateId,
    startDate,
    endDate,
    rateAmount,
    overrideUnassignedMemberRates
  } = action.payload;

  const { error, response } = yield changeEntity(
    updatePositionRate,
    api.updatePositionRate,
    [
      token,
      {
        position_rate_id: original.id,
        rate_id: rateId,
        start_date: startDate,
        end_date: endDate,
        rate_amount: rateAmount,
        override_unassigned_member_rates: overrideUnassignedMemberRates
      }
    ],
    action,
    action.payload
  );

  if (!error && response) {
    yield call(refetchPositionRates, {
      positionId: original.position_id,
      isCostRate: original.is_cost_rate
    });
  }
}

export function* deletePositionRateWorker(action) {
  const token = yield select(getAuthToken);
  const { original } = action.payload;

  const { error, response } = yield changeEntity(
    deletePositionRate,
    api.deletePositionRate,
    [token, original.id],
    action,
    action.payload
  );
  if (!error && response) {
    yield call(refetchPositionRates, {
      positionId: original.position_id,
      isCostRate: original.is_cost_rate
    });
  }
}

// helper for refetching data on Create, Update and Delete PositionRate
function* refetchPositionRates({ positionId, isCostRate }) {
  const teamId = yield select(getSelectedTeamId);
  const fetchParams = {
    teamId,
    positionId,
    isCostRate: isCostRate || false
  };

  // update position's PositionRate history
  yield put(actionCreators.fetchPositionRates(fetchParams));

  // update position's current PositionRate
  yield put(
    actionCreators.fetchPositionRates({
      ...fetchParams,
      activeOnly: true // get only the current rate
    })
  );
}

export function* updatePositionSkillsWorker(action) {
  const token = yield select(getAuthToken);

  const { member_budget_id, position_skill_id, level } = action.payload;
  const { error, response } = yield changeEntity(
    updatePositionSkills,
    api.updatePositionSkills,
    [token, position_skill_id, level],
    action,
    action.payload
  );

  yield put(actionCreators.fetchPositionSkills({ member_budget_id }));
}

export function* createPositionSkillsWorker(action) {
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);

  const { member_budget_id, skills } = action.payload;

  const { error, response } = yield changeEntity(
    createPositionSkills,
    api.createPositionSkills,
    [token, teamId, member_budget_id, skills],
    action,
    action.payload
  );

  yield put(actionCreators.fetchPositionSkills({ member_budget_id }));
}

export function* fetchPositionSkillsWorker(action) {
  const token = yield select(getAuthToken);

  const { member_budget_id } = action.payload;

  yield changeEntity(
    fetchPositionSkills,
    api.fetchPositionSkills,
    [token, member_budget_id],
    action,
    action.payload
  );
}

export function* deletePositionSkillsWorker(action) {
  const token = yield select(getAuthToken);

  const { position_skill_id, member_budget_id } = action.payload;

  yield changeEntity(
    deletePositionSkills,
    api.deletePositionSkills,
    [token, position_skill_id],
    action,
    action.payload
  );

  yield put(actionCreators.fetchPositionSkills({ member_budget_id }));
}

export function* updatePositionLocationRangeWorker(action) {
  const { range_id, member_budget_id } = action.payload;

  yield put(
    actionCreators.updateMemberBudget({
      memberBudgetId: member_budget_id,
      radius: range_id
    })
  );
}

export function* deletePositionLocationRangeWorker(action) {
  const { member_budget_id } = action.payload;

  yield put(
    actionCreators.updateMemberBudget({
      memberBudgetId: member_budget_id,
      radius: null
    })
  );
}
