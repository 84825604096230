interface MemberRoleAndCapacityTooltipProps {
  name: string;
  teamRoleName: string;
  totalCapacity: string | number;
}
export const getMemberRoleAndCapacityTooltip = ({
  name,
  teamRoleName,
  totalCapacity
}: MemberRoleAndCapacityTooltipProps): string => {
  return `
    <div class="workload-member">
      <b class="name">${name}</b><br/>
      <span class="label">${teamRoleName || 'No Default Role'}</span>
      ${
        totalCapacity
          ? `
          <hr class="divider" />
          <div class="row">
            <p class="label">Capacity</p>
            <p class="value">${totalCapacity}/wk</p>
          </div>`
          : `<div></div>`
      }
    </div>
  `;
};
