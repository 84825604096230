import { useContext } from 'react';
import styled from 'styled-components';
import { renderItem } from 'SuggestionModule/components/FindPeople/List/constants';
import useSimpleStaticList from 'appUtils/hooks/useSimpleStaticList/useSimpleStaticList';
import useProjectUnassignedRolesListBuilder from './helpers/useProjectUnassignedRolesListBuilder';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import { LIST_ITEM_TYPES } from '../constants';
import { SimpleStaticListItem } from 'appUtils/hooks/useSimpleStaticList/types';

const calculateItemHeight = (item: SimpleStaticListItem) => {
  const { itemType, isOpen } = item;
  if (itemType === LIST_ITEM_TYPES.ProjectMembershipRequirement) {
    return 40;
  } else if (
    itemType ===
      LIST_ITEM_TYPES.ProjectUnassignedMemberBudgetWithRequirements &&
    isOpen
  ) {
    return 25;
  } else if (itemType === LIST_ITEM_TYPES.AddProjectMembershipRequirement) {
    return 30;
  }
  return 50;
};

const UnassignedRolesListBody = () => {
  const {
    selectedProjectId,
    isFetchingEntityRequirements,
    handleSelectUnassignedRole,
    onDeleteRequirement,
    onAddRequirementsClick
  } = useContext(FindPeopleModalContext);

  const { List } = useSimpleStaticList({
    useInitialListBuilder: useProjectUnassignedRolesListBuilder,
    renderItem,
    itemHeight: calculateItemHeight,
    initialListBuilderArgs: {
      projectId: selectedProjectId,
      isFetchingEntityRequirements,
      handleSelectUnassignedRole,
      onDeleteRequirement,
      onAddRequirementsClick
    }
  });

  return <StyledListContainer>{List}</StyledListContainer>;
};

export default UnassignedRolesListBody;

const StyledListContainer = styled.div`
  height: 100%;
  width: 270px;

  .add-requirements-item-project-membership-list-item-container,
  .project-membership-requirement-list-item-container {
    &.open {
      background: white;
    }
  }

  .main-list {
    overflow-x: hidden !important;
  }
`;
