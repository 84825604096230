import AddViewTasksIcon from 'images/addviewtasksicon.svg';
export const moduleTextHash = {
  //  1: 'General',
  2: 'Phases',
  3: 'Milestones',
  4: 'Work Categories',
  5: 'Roles',
  6: 'Rates',
  7: 'Holidays',
  8: 'Projects',
  9: 'Profile',
  10: 'PTO',
  11: 'Capacity'
};

export const moduleHash = {
  // 1: 'general',
  2: 'phases',
  3: 'milestones',
  4: 'work_categories',
  5: 'roles',
  6: 'rates',
  7: 'holidays',
  8: 'projects',
  9: 'profile',
  10: 'pto',
  11: 'capacity'
};

export const defaultNavModuleIds = [2, 3, 4, 5, 6, 10, 11, 7, 8];
const nonDefaultNavModuleIds = [];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

export const moduleDescriptionHash = {
  // 1: 'View General Standard Settings',
  2: 'View Standard Phases',
  3: 'View Standard Milestones',
  4: 'View Standard Milestones',
  5: 'View Standard Roles',
  6: 'View Standard Rates',
  7: 'View Standard Holidays',
  8: 'View Standard Project Settings',
  9: 'View Standard Profile Settings'
};
export const moduleColorHash = {
  1: '#F14848',
  2: '#F14848',
  3: '#F14848',
  4: '#F14848',
  5: '#F14848',
  6: '#F14848',
  7: '#F14848',
  8: '#F14848',
  9: '#F14848'
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon}) `,
  2: `url(${AddViewTasksIcon}) `,
  3: `url(${AddViewTasksIcon}) `,
  4: `url(${AddViewTasksIcon}) `,
  5: `url(${AddViewTasksIcon}) `,
  6: `url(${AddViewTasksIcon}) `,
  7: `url(${AddViewTasksIcon}) `,
  8: `url(${AddViewTasksIcon}) `,
  9: `url(${AddViewTasksIcon}) `
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
