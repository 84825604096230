import { serializeFilterLevelName } from 'FilterModule/filterSchemas/utils';
import { Page } from 'models/filter';
import {
  FilterLevelSchemas,
  CurrentFilter,
  FilterFieldSchema
} from 'FilterModule/types';
import {
  makeDefaultProjectCrossFiltersFieldSchemas,
  ProjectsCrossFilterFieldSchemas,
  defaultNumberArrayFieldSchema,
  makeDefaultSortOrderFieldSchema,
  defaultOptionHashExistenceValidator,
  defaultStringArrayFieldSchema,
  makeDefaultFilterOrderFieldSchema,
  nullableBooleanFieldValidator
} from 'FilterModule/filterSchemas/commonFieldSchemas';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { FilterField, FilterListType } from 'FilterModule/constants';
import { SORT_BY } from './constants';
import { VIEW_BY } from 'appConstants/workload';
import mapValues from 'lodash/mapValues';

// -----------------------------------------------------------------------------
//                       project list view field schemas
// -----------------------------------------------------------------------------

const reportProjectViewFilterName = serializeFilterLevelName({
  level1: Page.AllProjectsReport,
  level2: VIEW_BY.PROJECTS
});

/**
 * this page/view currently does not have save functionality
 */
const memberProfileProjectViewFilterName = serializeFilterLevelName({
  level1: Page.MemberModalProjects,
  level2: VIEW_BY.PROJECTS
});

/**
 * With filter fields for All Projects Report -> projects and Member Profile Projects (FE only) -> projects
 */
type ProjectDetailsProjectViewFieldSchemas = ProjectsCrossFilterFieldSchemas & {
  [FilterField.project_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.clients]: typeof defaultNumberArrayFieldSchema;
  /**
   * is archived status. matching existing field names to keep backward compatibility.
   *
   * uses default options in ArchivedStatusFilterDropdown
   */
  projectStatus: FilterFieldSchema<never, Nullable<boolean>>;
  /**
   * budget status
   */
  statusView: typeof defaultStringArrayFieldSchema;
  [FilterField.priority_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.sortValue]: FilterFieldSchema<never, string>;
  [FilterField.sortOrder]: ReturnType<typeof makeDefaultSortOrderFieldSchema>;
  [FilterField.filterOrder]: FilterFieldSchema<
    FilterListType,
    FilterListType[],
    { fieldOverride?: string }
  >;
};

const makeFilterListTypeOptions = ({ isReport }: { isReport?: boolean }) => [
  ...(isReport ? [] : [FilterListType.Projects]),
  FilterListType.ArchivedStatus,
  FilterListType.ProjectBudgetStatus,
  FilterListType.Priorities,
  FilterListType.ClientNames
];

// schema
const makeProjectViewFieldSchemas = ({
  isReport
}: {
  isReport?: boolean;
}): ProjectDetailsProjectViewFieldSchemas => {
  const filterListTypeOptions = makeFilterListTypeOptions({ isReport });

  return {
    ...makeDefaultProjectCrossFiltersFieldSchemas(),

    [FilterField.priority_ids]: defaultNumberArrayFieldSchema,

    [FilterField.project_ids]: defaultNumberArrayFieldSchema,

    [FilterField.clients]: defaultNumberArrayFieldSchema,

    statusView: defaultStringArrayFieldSchema,

    projectStatus: {
      defaultValue: null,
      getValidatedFieldValue: nullableBooleanFieldValidator
    },

    // sortValue
    [FilterField.sortValue]: {
      defaultValue: SORT_BY.ACTIVITY,
      optionHash: mapValues(SORT_BY, () => true),
      getValidatedFieldValue: defaultOptionHashExistenceValidator
    },

    // sortOrder
    [FilterField.sortOrder]: makeDefaultSortOrderFieldSchema('desc'),

    // filterOrder
    [FilterField.filterOrder]: makeDefaultFilterOrderFieldSchema({
      defaultValue: [],
      optionsArray: filterListTypeOptions,
      optionHashOverrideValues: {
        [FilterListType.ProjectBudgetStatus]: {
          label: 'Status',
          fieldOverride: 'statusView'
        },
        [FilterListType.ArchivedStatus]: {
          fieldOverride: 'projectStatus'
        }
      }
    })
  };
};

// -----------------------------------------------------------------------------
//                              initialFilterLevelSchemas
// -----------------------------------------------------------------------------

export const makeInitialFilterLevelSchemas = (
  defaultValues: Parameters<typeof makeProjectViewFieldSchemas>[0]
): FilterLevelSchemas => ({
  [reportProjectViewFilterName]: {
    fieldSchemas: makeProjectViewFieldSchemas(defaultValues)
  },
  [memberProfileProjectViewFilterName]: {
    fieldSchemas: makeProjectViewFieldSchemas(defaultValues)
  }
});

// -----------------------------------------------------------------------------
//                              CurrentFilterType
// -----------------------------------------------------------------------------

export type ProjectDetailsProjectViewFieldSchemasurrentFilterType =
  CurrentFilter<ProjectDetailsProjectViewFieldSchemas>;

export const useAllProjectsReportProjectViewFilterContext = () =>
  useFilterContext<ProjectDetailsProjectViewFieldSchemasurrentFilterType>();
