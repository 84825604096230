import {
  getDatesExcludeDaysOff,
  getWeekendsArrayFromRange,
  getWorkdayPercent,
  getWorkdayHours,
  getWorkDaysFromScheduleBars
} from '../../utils';
import isEqual from 'lodash/isEqual';
import { usePrevious } from 'appUtils/hooks/usePrevious';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { predictWorkloadPlanner } from 'actionCreators';
import { WorkPlan } from '../models/workPlan';
import { PredictStatus, PredictWorkloadPlannerParams } from '../types';
import debounce from 'lodash/debounce';
import pickBy from 'lodash/pickBy';
import round from 'lodash/round';
import { defaultDebounceDelay } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccountCapacities,
  getTeamCapacity
} from 'CapacityModule/selectors';
import moment from 'moment';
import { DailyCapacity } from 'models/accountCapacity';
import { TeamCapacity } from 'CapacityModule/models';

interface ExtraValues {
  work_days: number;
  workday_percent?: string; // it should be string to support decimal point while entering value i.e 3.
  datesArray: Array<string>;
}

const calculateNonSuggestionWorkdays = ({
  workplan
}: {
  workplan: WorkPlan;
}) => {
  return workplan?.bars ? getWorkDaysFromScheduleBars(workplan.bars) : 1;
};

const calculateSuggestionWorkdays = ({
  workplan
}: {
  workplan: WorkPlanOrSuggestedBar;
}) => {
  return workplan?.schedule?.length || 1;
};

const calculateWorkdays = ({
  workplan
}: {
  workplan: WorkPlanOrSuggestedBar;
}) => {
  return workplan?.isWorkplanSuggestion
    ? calculateSuggestionWorkdays({ workplan })
    : calculateNonSuggestionWorkdays({ workplan });
};

// constants to refine only predictable values
const predictableValues: Partial<Record<keyof WorkPlan, true>> = {
  start_date: true,
  end_date: true,
  bars: true,
  daily_hours: true,
  total_hours: true,
  all_day: true
};

export type ModifiableTarget =
  | keyof Omit<WorkPlan, 'start_date' | 'end_date' | 'description'>
  | 'date_range'
  | keyof ExtraValues
  | 'reset';

export type UpdateHandler = (
  modifyTarget: ModifiableTarget,
  values: Partial<WorkPlan> & Partial<ExtraValues>
) => void;

interface UsePredictReturned {
  predictedWorkplan: WorkPlan;
  extraValues: ExtraValues;
  update: UpdateHandler;
  isPredicting: boolean;
}

interface UsePredictHookOptions {
  debounceDelay?: number;
  shouldPredictWithInitialValue?: boolean;
}
interface WorkPlanOrSuggestedBar extends WorkPlan {
  // these are params from suggestedBar which is used by workplan request
  isWorkplanRequest: boolean;
  workplanRequestId: number;
  work_days?: number;
  schedule?: { date: string; hours: number }[];
  isWorkplanSuggestion?: boolean;
  reject: boolean;
}

type UsePredictHook = (
  initialBar: WorkPlanOrSuggestedBar,
  options?: UsePredictHookOptions
) => UsePredictReturned;

/**
 * usePredict
 *
 * take bar and form values, and return predicted values
 */
export const usePredict: UsePredictHook = (initialWorkplan, options = {}) => {
  const {
    debounceDelay = defaultDebounceDelay,
    shouldPredictWithInitialValue = false
  } = options;

  const previousWorkplan = usePrevious(initialWorkplan);

  const [workplan, setWorkplan] = useState(initialWorkplan);
  const [predictStatus, setPredictStatus] = useState<PredictStatus>('initial');

  const defaultDailyTeamCapacity: TeamCapacity = useSelector(getTeamCapacity);

  const lastModifiedTargetRef = useRef<{
    target: ModifiableTarget | undefined;
    timestamp: number | undefined;
  }>({
    target: undefined,
    timestamp: undefined
  });

  const setLastModifiedTarget = (targetKey: ModifiableTarget | undefined) => {
    if (lastModifiedTargetRef) {
      lastModifiedTargetRef.current = {
        target: targetKey,
        timestamp: new Date().valueOf()
      };
    }
  };

  const [workDays, setWorkdays] = useState<number>(
    calculateWorkdays({ workplan })
  );

  const [workdayPercent, setWorkdayPercent] = useState<string | undefined>(
    undefined
  );

  const dispatch = useDispatch();

  const accountId = workplan.account_id;
  const accountCapacitiesHash = useSelector(getAccountCapacities);
  const accountCapacity = accountCapacitiesHash[accountId];
  const accountCapacityToUse: DailyCapacity | undefined = accountId
    ? accountCapacity
    : workplan.member_budget_id
    ? defaultDailyTeamCapacity
    : undefined;

  const getDatesArray = useCallback(
    ({ startDate, endDate }: { startDate: string; endDate: string }) => {
      if (startDate && endDate) {
        return getDatesExcludeDaysOff({
          startDate,
          endDate,
          daysOff: workplan.include_weekends
            ? []
            : getWeekendsArrayFromRange({
                startDate,
                endDate
              })
        });
      }
      return [];
    },
    [workplan.include_weekends]
  );

  const datesArray = useMemo(
    () =>
      getDatesArray({
        startDate: workplan.start_date,
        endDate: workplan.end_date
      }),
    [getDatesArray, workplan.start_date, workplan.end_date]
  );

  /* ------------------ update workplan state before predict ------------------ */
  const handleUpdate: UpdateHandler = (modifyTarget, values) => {
    // Omit these values from predict params but use them to update stateBar except ...rest
    const {
      workday_percent: workdayPercent,
      work_days,
      daily_hours: modifiedDailyHours,
      ...rest
    } = values;

    // changing lock would not trigger to predict hours
    if (modifyTarget === 'lock_hour') {
      return setWorkplan((prev) => ({
        ...prev,
        lock_hour: Boolean(values.lock_hour)
      }));
    }

    if (work_days !== undefined) {
      setWorkdays(work_days);
    }

    if (workdayPercent !== undefined) {
      setWorkdayPercent(workdayPercent);
    }

    let dailyHours: string = (modifiedDailyHours ?? workplan.daily_hours) || '';

    const nextAccountCapacityToUse = values.account_id
      ? accountCapacitiesHash[values.account_id]
      : values.member_budget_id
      ? defaultDailyTeamCapacity
      : accountCapacityToUse;

    if (nextAccountCapacityToUse) {
      const datesArray = getDatesArray({
        startDate: values.start_date ?? workplan.start_date,
        endDate: values.end_date ?? workplan.end_date
      });

      if (
        modifyTarget === 'workday_percent' &&
        !isNaN(Number(workdayPercent))
      ) {
        // calculate daily hours based on entered workday percent to predict hours
        const newDailyHours = getWorkdayHours({
          dailyCapacity: nextAccountCapacityToUse,
          workdayPercent: Number(workdayPercent),
          datesArray
        });
        dailyHours = String(newDailyHours);
      }
      if (
        modifyTarget === 'reset' ||
        modifyTarget === 'date_range' ||
        modifyTarget === 'account_id' ||
        modifyTarget === 'member_budget_id' ||
        modifyTarget === 'daily_hours'
      ) {
        const newWorkdayPercent = getWorkdayPercent({
          dailyCapacity: nextAccountCapacityToUse,
          dailyHours: Number(dailyHours),
          datesArray
        });
        setWorkdayPercent(newWorkdayPercent ? String(newWorkdayPercent) : '');
      }
    }

    const newWorkplan = {
      ...workplan,
      ...rest,
      work_days,
      daily_hours: String(dailyHours)
    };

    setLastModifiedTarget(modifyTarget);
    setWorkplan(newWorkplan);

    const shouldDropDailyHours =
      newWorkplan.lock_hour &&
      (modifyTarget === 'reset' ||
        (modifyTarget === 'total_hours' &&
          (!dailyHours || Number(dailyHours) === 0)));

    const refinedValuesToPredict: PredictWorkloadPlannerParams = {
      account_id: newWorkplan.account_id,
      project_id: newWorkplan.project_id,
      phase_id: newWorkplan.phase_id,
      start_date: newWorkplan.start_date,
      end_date: newWorkplan.end_date,
      all_day: newWorkplan.all_day ?? false, // if all_day is true, predicted total_hours always 0
      daily_hours:
        /*
         * If total hours are locked and the dates are changed, do not set the
         * number of hours per day.
         */
        shouldDropDailyHours ? undefined : dailyHours,
      total_hours: newWorkplan.lock_hour ? newWorkplan.total_hours : undefined,
      include_weekends: newWorkplan.include_weekends,
      include_holidays: newWorkplan.include_holidays,
      lock_hour: newWorkplan.lock_hour, // false: lock daily hours, true: lock total hours
      work_days: work_days?.toString(),
      phase_dependency: newWorkplan.phase_dependency,
      type: newWorkplan.type,
      ...rest
    };

    if (modifyTarget === 'include_weekends') {
      refinedValuesToPredict.work_days = workDays.toString();
    }

    const valuesToPredict = refinedValuesToPredict.all_day
      ? {
          start_date: refinedValuesToPredict.start_date,
          end_date: refinedValuesToPredict.end_date
        }
      : {
          start_date: refinedValuesToPredict.start_date,
          end_date: refinedValuesToPredict.end_date,
          daily_hours: refinedValuesToPredict.daily_hours,
          total_hours: refinedValuesToPredict.total_hours
        };
    const haveEnoughValuesToPredict = validateToPredict(valuesToPredict);
    const canPredict = haveEnoughValuesToPredict;

    if (canPredict) {
      setPredictStatus('willPredict');
      handleDebouncedPredict({
        values: refinedValuesToPredict,
        lastModifiedTimestamp: lastModifiedTargetRef.current.timestamp,
        onPredict: updatePredictedValues
      });
    }
  };

  /* ------------------- update workplan state after predict ------------------ */
  const updateSetTime = useCallback(
    (dailyHours: string) => {
      const needToUpdateStartandEndTime =
        workplan.start_time &&
        workplan.end_time &&
        dailyHours !== workplan.daily_hours;
      const formattedDailyHours = dailyHours ? round(Number(dailyHours), 2) : 0;

      // Even though already checked for workplan.start_time in needToUpdateStartandEndTime
      // need to re-state this condition to supress warning about it being possibly null
      if (needToUpdateStartandEndTime && workplan.start_time) {
        const [startTime, startTimezone] = workplan.start_time.split(' ');

        const endTime = moment(startTime, 'HH:mm')
          .add(formattedDailyHours, 'h')
          .format('HH:mm');

        setWorkplan((prev) => ({
          ...prev,
          end_time: `${endTime} ${startTimezone}`
        }));
      }
    },
    [workplan.start_time, workplan.end_time, workplan.daily_hours]
  );

  const updatePredictedValues = useCallback(
    (predictedValues: WorkPlan) => {
      const formattedValues: WorkPlan = {
        ...predictedValues,
        daily_hours: Number(predictedValues.daily_hours)
          ? round(Number(predictedValues.daily_hours), 2).toString()
          : '',
        total_hours: Number(predictedValues.total_hours)
          ? round(Number(predictedValues.total_hours), 2).toString()
          : ''
      };

      const pickedValues = pickBy(formattedValues, (_, key) => {
        return predictableValues[key];
      });

      setWorkplan((prev) => ({
        ...prev,
        ...pickedValues
      }));

      const workdays = pickedValues.bars
        ? getWorkDaysFromScheduleBars(pickedValues.bars)
        : 1;

      setWorkdays(workdays);
      // calculate workdayPercent when daily hours is predicted
      const nextAccountCapacityToUse = predictedValues.account_id
        ? accountCapacitiesHash[predictedValues.account_id]
        : predictedValues.member_budget_id
        ? defaultDailyTeamCapacity
        : accountCapacityToUse;

      // when entering workdapy percent, would not override entered value with predicted value
      if (
        pickedValues.daily_hours &&
        nextAccountCapacityToUse &&
        lastModifiedTargetRef.current.target !== 'workday_percent'
      ) {
        const newWorkdayPercent = getWorkdayPercent({
          dailyCapacity: nextAccountCapacityToUse,
          dailyHours: Number(pickedValues.daily_hours) || 0,
          datesArray: getDatesArray({
            startDate: pickedValues.start_date ?? workplan.start_date,
            endDate: pickedValues.end_date ?? workplan.end_date
          })
        });
        setWorkdayPercent(newWorkdayPercent ? String(newWorkdayPercent) : '');
      } else {
        setWorkdayPercent((prev) => (prev && prev !== '0' ? prev : '')); // 1.001 -> 1 or 0 -> empty string
      }

      updateSetTime(formattedValues.daily_hours);
    },
    [
      accountCapacitiesHash,
      accountCapacityToUse,
      getDatesArray,
      workplan.end_date,
      workplan.start_date,
      defaultDailyTeamCapacity,
      updateSetTime
    ]
  );

  /* --------------------------------- predict -------------------------------- */
  const handlePredict = useCallback(
    ({
      values,
      lastModifiedTimestamp,
      onPredict
    }: {
      values: PredictWorkloadPlannerParams;
      lastModifiedTimestamp?: number;
      onPredict: (predictedValues: WorkPlan) => void;
    }) => {
      setPredictStatus('predicting');
      dispatch(
        predictWorkloadPlanner({
          ...values,
          onSuccess: [
            {
              successAction: ({ activity_phase_schedule_bar: values }) => {
                setPredictStatus('done');
                // prevent to override values while enter values by user
                if (
                  lastModifiedTimestamp ===
                  lastModifiedTargetRef.current.timestamp
                ) {
                  onPredict(values);
                }
              },
              selector: (payload, response) => response
            }
          ],
          onFailure: () => {
            setPredictStatus('error');
          }
        })
      );
    },
    [dispatch]
  );

  const handleDebouncedPredict = useMemo(
    () => debounce(handlePredict, debounceDelay),
    [handlePredict, debounceDelay]
  );

  const initialPredict = useCallback(
    (initialWorkplan: WorkPlan) => {
      const canPredict = validateToPredict({
        start_date: initialWorkplan.start_date,
        end_date: initialWorkplan.end_date,
        daily_hours: initialWorkplan.daily_hours,
        total_hours: initialWorkplan.total_hours
      });

      if (canPredict) {
        setPredictStatus('willPredict');
        handleDebouncedPredict({
          values: initialWorkplan,
          lastModifiedTimestamp: lastModifiedTargetRef.current.timestamp,
          onPredict: updatePredictedValues
        });
      }
    },
    [handleDebouncedPredict, updatePredictedValues]
  );

  const prevWorkplanAccountCapacity = usePrevious(
    accountCapacitiesHash[initialWorkplan.account_id]
  );

  const workplanAccountCapacity =
    accountCapacitiesHash[initialWorkplan.account_id];

  useEffect(() => {
    if (
      !isEqual(initialWorkplan, previousWorkplan) ||
      !isEqual(prevWorkplanAccountCapacity, workplanAccountCapacity)
    ) {
      setWorkplan(initialWorkplan);
      setWorkdays(calculateWorkdays({ workplan: initialWorkplan }));

      const accountCapacityToUse: DailyCapacity | undefined =
        initialWorkplan.account_id
          ? accountCapacitiesHash[initialWorkplan.account_id]
          : initialWorkplan.member_budget_id
          ? defaultDailyTeamCapacity
          : undefined;

      const nextWorkdayPercent = accountCapacityToUse
        ? getWorkdayPercent({
            dailyHours: Number(initialWorkplan.daily_hours) || 0,
            dailyCapacity: accountCapacityToUse,
            datesArray: getDatesArray({
              startDate: initialWorkplan.start_date,
              endDate: initialWorkplan.end_date
            })
          })
        : 0;
      setWorkdayPercent(nextWorkdayPercent ? String(nextWorkdayPercent) : '');

      if (shouldPredictWithInitialValue) {
        initialPredict(initialWorkplan);
      }
    }
  }, [
    accountCapacitiesHash,
    workplanAccountCapacity,
    prevWorkplanAccountCapacity,
    getDatesArray,
    initialWorkplan,
    previousWorkplan,
    defaultDailyTeamCapacity,
    initialPredict,
    shouldPredictWithInitialValue
  ]);

  const validateToPredict = (valuesToPredict: {
    start_date?: string;
    end_date?: string;
    daily_hours?: string;
    total_hours?: string;
  }): boolean =>
    Object.values(valuesToPredict).filter((item) => item === undefined)
      .length <= 1;

  // update predict hours on opening the modal to calculate daily_hours when approving workplan request
  useEffect(() => {
    if (
      initialWorkplan.isWorkplanRequest &&
      initialWorkplan.start_date &&
      initialWorkplan.end_date
    ) {
      handlePredict({
        values: {
          account_id: initialWorkplan.account_id,
          project_id: initialWorkplan.project_id,
          phase_id: initialWorkplan.phase_id,
          start_date: initialWorkplan.start_date,
          end_date: initialWorkplan.end_date,
          all_day: false,
          total_hours: initialWorkplan.total_hours,
          work_days: initialWorkplan.work_days?.toString(),
          include_weekends: false,
          phase_dependency: null,
          type: initialWorkplan.type
        },
        onPredict: updatePredictedValues
      });
    }
  }, [handlePredict, initialWorkplan, updatePredictedValues]);

  return {
    predictedWorkplan: workplan,
    update: handleUpdate,
    isPredicting:
      predictStatus === 'predicting' || predictStatus === 'willPredict',
    extraValues: {
      work_days: workDays,
      workday_percent: workdayPercent,
      datesArray
    }
  };
};
