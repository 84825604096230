import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSidebarProjectsOpen } from 'selectors';
import styled from 'styled-components';
import {
  CustomCheckBoxContainer,
  CustomCheckBox,
  CheckBoxDiv,
  StyledFolderIcon,
  StyledFilterRow,
  RowContent
} from 'ReportsModule/components/styles';
import theme from 'theme';
import GripIcon from 'images/grip.svg';
import BoardDropdown from './BoardDropdown';
import { StyledDropdown } from './ProjectDropdown';
import { StyledThreeDot } from 'IntegrationsModule/components/ThreeDot';
import { PERSONAL_BOARD_NAMES } from 'appConstants';
import StarYellow from 'images/star-yellow.svg';
import Karat from 'images/thin-arrow.svg';
import {
  CustomStyledSelectToggle,
  CustomClickableArea
} from 'FilterModule/components/FilterListsTable/styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

const BoardIcon = styled(StyledFolderIcon)`
  width: 18px;
  height: 15px;
  margin-right: 8px;
`;
export const StyledGrip = styled.img`
  height: 11px;
  width: 11px;
  visibility: ${(props) => (props.isHidden ? 'hidden!important' : 'hidden')};
  ${(props) => props.show && 'visibility: visible;'}
  margin: 0 8px;
`;
export const Arrow = styled.img`
  transition: 0.2s ease-in-out;
  transform: ${({ isOpen }) => (!isOpen ? 'rotate(0deg)' : 'rotate(90deg)')};
  position: relative;
  left: 1px;
  transform-origin: center 5px;
`;

const StarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  img {
    width: 15px;
    height: 15px;
  }
`;

export const StyledBoardRow = styled(StyledFilterRow)`
  ${StyledThreeDot} {
    visibility: hidden;
  }
  ${StyledDropdown} {
    position: absolute;
    right: 29px;
  }
  &:hover {
    ${StyledGrip} {
      visibility: visible;
    }
    ${StyledThreeDot} {
      visibility: visible;
    }
  }
  ${(props) =>
    props.showSelectionBox &&
    `
    ${StyledGrip} {
      margin: 0 0 0 4px;
    }
  `}
`;
const StyledRowContent = styled(RowContent)`
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  align-items: center;
  margin: 0;
  font-weight: 600;
`;
export const CollapseContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 16px;
`;
export const StyledCount = styled.span`
  font-size: ${({ isSideFilter }) => (isSideFilter ? '13px' : '12px')};
  margin-right: 7px;
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  color: ${theme.colors.colorMediumGray5};

  ${(props) =>
    props.isHighlighted &&
    `
    color: ${theme.colors.colorCalendarBlue};
    font-weight: 600;
  `}

  ${StyledBoardRow}:hover & {
    visibility: hidden;
  }
`;

const StyledSideFilterCheckboxContainer = styled.div`
  height: 100%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoardRow = ({
  item,
  setIsOpen,
  search,
  showDragGrips,
  allCollapsed,
  isProjectPhaseSelector,
  loadPhasesByProjectIds,
  isSideFilter,
  itemsCount
}) => {
  // item is entire list
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const sidebarProjectsOpen = useSelector(getSidebarProjectsOpen);

  useEffect(() => {
    rebuildTooltip();
  }, []);

  const {
    isHeaderAllSelected,
    isHeaderSomeSelected,
    headerHandleClick,
    selectionIsDisabled,
    numSelected,
    project_count,
    project_position,
    isPersonal,
    name,
    isProjectsSidebar,
    projectFilterListId,
    isFullyLoaded,
    hideBoardCheckBox
  } = item || {};

  const showSelectionBox = !isProjectsSidebar && !hideBoardCheckBox;

  const isPartiallySelected = !isHeaderAllSelected && numSelected > 0;

  const itemSelected = itemsCount?.itemSelected;
  const selectionLimit = itemsCount?.selectionLimit;
  const isMaximumSelected = itemSelected && itemSelected >= selectionLimit;
  const showCheckedOnHover =
    !selectionIsDisabled &&
    (!isMaximumSelected || (isMaximumSelected && isPartiallySelected));
  const showIndeterminateStateOnHover =
    !selectionIsDisabled &&
    (isHeaderAllSelected ||
      (isPartiallySelected && itemSelected === selectionLimit));

  useEffect(() => {
    if (isProjectsSidebar && dropdownOpen && !sidebarProjectsOpen) {
      setDropdownOpen(false);
    }
  }, [dropdownOpen, isProjectsSidebar, sidebarProjectsOpen]);

  return (
    <StyledBoardRow
      isSelected={isHeaderAllSelected}
      onClick={() => {
        if (isProjectPhaseSelector && !item.isOpen) {
          // no need to load for archived projects. it is handled when clicking show archived
          loadPhasesByProjectIds([...project_position]);
        }
        setIsOpen({ uid: item.uid, value: !item.isOpen });
      }}
      showSelectionBox={showSelectionBox}
      data-testid={`row-${item.uid}-${projectFilterListId}${
        isFullyLoaded ? '-loaded' : ''
      }`}
    >
      <StyledGrip
        src={GripIcon}
        isHidden={item.isDragDisabled}
        show={showDragGrips?.board && allCollapsed?.board}
        showSelectionBox={showSelectionBox}
      />
      {showSelectionBox &&
        !hideBoardCheckBox &&
        (isSideFilter ? (
          <StyledSideFilterCheckboxContainer>
            <CustomClickableArea
              onClick={(e) => {
                e.stopPropagation();
                if (!selectionIsDisabled) {
                  headerHandleClick(item);
                }
              }}
              showIndeterminateStateOnHover={showIndeterminateStateOnHover}
              showCheckedOnHover={showCheckedOnHover}
              toggleSize={14}
              data-for="app-tooltip"
              data-tip={`Maximum of ${itemSelected} of ${selectionLimit} Selected.`}
              data-tip-disable={
                !!selectionIsDisabled ||
                Boolean(
                  !selectionLimit ||
                    !(
                      isMaximumSelected &&
                      (isPartiallySelected || !isHeaderSomeSelected)
                    )
                )
              }
            />
            <CustomStyledSelectToggle
              isChecked={isHeaderAllSelected}
              isPartiallyChecked={isPartiallySelected}
              isHidden={!!selectionIsDisabled}
              size={14}
            />
          </StyledSideFilterCheckboxContainer>
        ) : (
          <CheckBoxDiv
            key="1"
            style={{ padding: 10 }}
            onClick={(e) => {
              e.stopPropagation();
              if (!selectionIsDisabled) {
                headerHandleClick(item);
              }
            }}
          >
            <CustomCheckBoxContainer
              isChecked={isHeaderAllSelected}
              style={{
                height: 14,
                width: 14,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              isHidden={selectionIsDisabled}
            >
              <CustomCheckBox
                isChecked={isHeaderAllSelected} // || isHeaderSomeSelected}
                // style={
                //   !isHeaderAllSelected && isHeaderSomeSelected
                //     ? indeterminateStyles
                //     : {}
                // }
              />
            </CustomCheckBoxContainer>
          </CheckBoxDiv>
        ))}

      <StyledRowContent
        data-testid={`${item.name}`}
        data-for={'app-tooltip'}
        data-html
        data-effect="solid"
        data-class="center mw-250"
        data-tip={`${item.name}`}
      >
        {name === PERSONAL_BOARD_NAMES['Starred Projects'] ? (
          <StarContainer>
            <img src={StarYellow} />
          </StarContainer>
        ) : (
          <BoardIcon boardId={item.id} />
        )}
        <span>{item.name}</span>
      </StyledRowContent>
      <CollapseContainer>
        <BoardDropdown
          isOpen={dropdownOpen}
          setIsOpen={(value) => setDropdownOpen(value)}
          board={item}
        />

        <StyledCount
          isHidden={search.projects?.length || dropdownOpen}
          isHighlighted={!!numSelected}
          isSideFilter={isSideFilter}
        >
          {!isNaN(item.displayCount)
            ? !isProjectsSidebar
              ? `${numSelected}/${item.displayCount}`
              : item.displayCount
            : ''}
        </StyledCount>
        <Arrow src={Karat} isOpen={item?.isOpen} />
      </CollapseContainer>
    </StyledBoardRow>
  );
};

export default BoardRow;
