import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';
import { MemberRate } from 'RatesModule/models';
import { AnyAction } from '@reduxjs/toolkit';

export interface MemberRatesState {
  memberRateIds: number[];
  memberRates: Record<number, MemberRate>;
  isEditMemberRate: boolean;
  editMemberRateItem: MemberRate | Record<string, never>;
}

export const initialState: MemberRatesState = {
  memberRateIds: [],
  memberRates: {},
  isEditMemberRate: false,
  editMemberRateItem: {}
};

const byId = (item) => item.id;

const memberRates = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case budgetConstants.FETCH_MEMBER_RATES.SUCCESS: {
      const { member_rates } = action.payload.response;
      return {
        ...state,
        memberRateIds: member_rates.map(byId),
        memberRates: {
          ...state.memberRates,
          ...keyBy(member_rates, byId)
        }
      };
    }
    case budgetConstants.EDIT_MEMBER_RATE: {
      const { isEditMemberRate, editMemberRateItem } = action.payload;
      return {
        ...state,
        isEditMemberRate,
        editMemberRateItem
      };
    }
    default:
      return state;
  }
};

export default memberRates;
