import { useRef, useMemo } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import KaratRight from 'icons/KaratRight';
import PhaseDiamondIcon from 'icons/BudgetPhaseMilestoneIcon';
import { useAppDispatch } from 'reduxInfra/hooks';
import { createPhaseMembers } from 'actionCreators';
import {
  PhaseListItem,
  PhaseMembershipWithUnassignedMemberBudgetAndFormattedPositionListItem,
  PhaseListMembershipListItem,
  AddMemberToPhaseListItem
} from 'SuggestionModule/components/FindPeople/List/ProjectTeam/types';
import EllipsisText from 'components/EllipsisText';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import UnassignedMemberIcon from 'icons/UnassignedMemberIcon';
import {
  StyledKaratContainer,
  StyledItemContainer
} from 'SuggestionModule/components/FindPeople/List/styles';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import BulkMemberSelector from 'views/projectPlanner/workloadBarModal/BulkMemberSelector';
import keyBy from 'lodash/keyBy';
import { TeamMember } from 'TeamsModule/models/teamMember';
import { getCreatePhaseMembersRefetchActions } from 'SuggestionModule/components/FindPeople/utils';
import { formatNumWithMaxOneDecimal } from 'appUtils/formatUtils';
import NumberFormat from 'react-number-format';
import useCurrentRoles from 'BudgetModule/hooks/useCurrentRoles';
import { NumberFormatInput } from 'components/styles';
import useMultiLevelDropdown from 'components/MultiLevelDropdown/useMultiLevelDropdown';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import {
  createPhaseEstimation,
  updatePhaseEstimation
} from 'BudgetModule/actionCreators';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import { StyledDropdownLabel } from 'SuggestionModule/components/FindPeople/styles';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';

const phaseMemberFilters = (
  members: TeamMember[],
  phase: PhaseListItem['phase']
) => {
  const membershipsByAccountId = keyBy(
    phase?.phase_memberships?.filter((member) => !member.discarded_at),
    (item) => item.account_id
  );

  return members.filter(
    (member) => !membershipsByAccountId[member?.account?.id]
  );
};

/* ----------------------------- Custom Renderer ---------------------------- */

export const Phase = (phaseItem: PhaseListItem) => {
  const dispatch = useAppDispatch();
  const {
    phase,
    isOpen,
    toggleCollapse,
    isEmpty,
    projectId,
    phaseMembershipsOrder,
    hasActivityPhases,
    openBudgetModal
  } = phaseItem;
  const { name, id: phaseId } = phase;

  const { MultiLevelDropdown, dropdownRef, openDropdown, closeDropdown } =
    useMultiLevelDropdown();

  const menuContent = useMemo(() => {
    return {
      items: [
        {
          name: PhaseMembershipOptions.EditBudget,
          label: (
            <StyledDropdownLabel>
              <GoToProjectIcon color={theme.colors.colorMediumGray9} /> Edit
              Budget
            </StyledDropdownLabel>
          ),
          subMenu: undefined
        }
      ]
    };
  }, []);

  const onClickHash = useMemo(() => {
    const value = {
      [PhaseMembershipOptions.EditBudget]: () => {
        openBudgetModal();
        closeDropdown();
      }
    };

    return value;
  }, [openBudgetModal, closeDropdown]);

  const handleDone = (members: TeamMember[]) => {
    const accountIds = members.map((member) => member.account.id);

    dispatch(
      createPhaseMembers({
        projectId,
        phaseId,
        accountIds,
        shouldRefetch: false,
        onSuccess: [
          {
            successAction: () => {
              const refetchActions = getCreatePhaseMembersRefetchActions({
                projectId
              });
              refetchActions.forEach((action) => dispatch(action));
            },
            selector: (payload, response) => ({ payload, response })
          }
        ]
      })
    );
  };

  const totalMembershipCounts = phaseMembershipsOrder.length;
  const shouldShowCount = totalMembershipCounts && !isOpen;

  const phaseNameWithCount = shouldShowCount
    ? `${name} | ${phaseMembershipsOrder.length}`
    : name;

  return (
    <StyledItemContainer className="phase">
      <StyledKaratContainer
        isEmpty={false} // Spec: Showing arrow even phase has no members
        onClick={toggleCollapse}
        isOpen={isOpen}
      >
        <KaratRight />
      </StyledKaratContainer>
      <PhaseDiamondIcon
        strokeColor={theme.colors.colorMediumGray9}
        strokeWidth="1.5px"
        width="13"
        height="13"
        className={undefined}
      />{' '}
      <EllipsisText
        tooltip={phaseNameWithCount}
        width={undefined}
        maxWidth={220}
      >
        {`${name}`}
      </EllipsisText>{' '}
      {shouldShowCount ? (
        <span className="total-member-count">
          | {phaseMembershipsOrder.length}
        </span>
      ) : (
        <></>
      )}
      <BulkMemberSelector
        projectId={projectId}
        shouldUseMemberFilter
        phaseId={phaseId}
        renderSelect={({ onClick }) =>
          isOpen && (
            <StyledIcon
              ref={dropdownRef}
              onClick={hasActivityPhases ? openDropdown : onClick}
            >
              <PlusCircleIcon height={15} width={15} />
            </StyledIcon>
          )
        }
        handleDone={handleDone}
        membershipLevel={'phase'}
        hideFooter
        memberFilter={(members: TeamMember[]) =>
          phaseMemberFilters(members, phase)
        }
        searchPlaceholder="Type name or select below"
      />
      {hasActivityPhases && (
        <MultiLevelDropdown
          menuContent={menuContent}
          onClickHash={onClickHash}
        />
      )}
    </StyledItemContainer>
  );
};

enum PhaseMembershipOptions {
  EditBudget = 'editBudget'
}

export const PhaseMembership = (
  phaseMembershipItem: PhaseListMembershipListItem
) => {
  const dispatch = useAppDispatch();

  const {
    phaseMembership,
    teamMembershipByAccountId,
    accountTotals,
    projectId,
    parentPhaseHasActivitiesPhase,
    memberBudget,
    openBudgetModal
  } = phaseMembershipItem;

  const { account_id, phase_id, member_budget_id } = phaseMembership;
  const { teamRole, memberRole } = useCurrentRoles({
    accountId: account_id as number,
    memberBudget
  });
  const roleToUse = memberRole || teamRole;
  const hasRole = roleToUse && !roleToUse.is_default;
  const member = teamMembershipByAccountId[account_id as number];
  const memberName = member ? member.account?.name : '';

  const { activity_phase_estimation_id, hours: accountBudgetHours } =
    accountTotals?.estimated || {};

  const { MultiLevelDropdown, dropdownRef, openDropdown, closeDropdown } =
    useMultiLevelDropdown();

  const hoursRef = useRef<NumberFormat<unknown>>(null);

  const onBlur = () => {
    if (hoursRef?.current) {
      const { numAsString } = hoursRef.current.state;

      const newHours = isNaN(parseFloat(numAsString))
        ? 0
        : parseFloat(numAsString);
      if (accountBudgetHours !== newHours) {
        if (activity_phase_estimation_id) {
          dispatch(
            updatePhaseEstimation({
              project_id: projectId,
              id: activity_phase_estimation_id,
              estimated_hours: newHours,
              member_budget_id,
              phase_id,
              refetchPhaseTotalsFilterStateId: FilterStateIds.fetchPhaseTotals
            })
          );
        } else {
          dispatch(
            createPhaseEstimation({
              project_id: projectId,
              estimated_hours: newHours,
              member_budget_id,
              phase_id,
              refetchPhaseTotalsFilterStateId: FilterStateIds.fetchPhaseTotals
            })
          );
        }
      }
    }
  };

  const menuContent = useMemo(() => {
    return {
      items: [
        {
          name: PhaseMembershipOptions.EditBudget,
          label: (
            <StyledDropdownLabel>
              <GoToProjectIcon color={theme.colors.colorMediumGray9} /> Edit
              Budget
            </StyledDropdownLabel>
          ),
          subMenu: undefined
        }
      ]
    };
  }, []);

  const onClickHash = useMemo(() => {
    const value = {
      [PhaseMembershipOptions.EditBudget]: () => {
        openBudgetModal();
        closeDropdown();
      }
    };

    return value;
  }, [openBudgetModal, closeDropdown]);

  const renderHours = () => {
    if (parentPhaseHasActivitiesPhase) {
      return (
        <span ref={dropdownRef} onClick={openDropdown}>
          {accountBudgetHours
            ? `${formatNumWithMaxOneDecimal(accountBudgetHours)}h`
            : '0h'}
          <MultiLevelDropdown
            menuContent={menuContent}
            onClickHash={onClickHash}
          />
        </span>
      );
    }

    return (
      <NumberFormatInput
        defaultValue={accountBudgetHours}
        placeholder={'0h'}
        ref={hoursRef}
        onBlur={onBlur}
        decimalScale={2}
        allowNegative={false}
        thousandSeparator
        allowLeadingZeros={false}
        suffix={'h'}
        className="hour-input"
        {...defaultTooltipProps}
        data-tip="Budgeted hours"
      />
    );
  };

  return (
    <StyledItemContainer className="phase-membership">
      <MemberInitials
        classes="regular-member-no-hover member-initials"
        member={member}
      />
      <div>
        <EllipsisText
          tooltip={memberName}
          width={undefined}
          maxWidth={undefined}
          className="member-name"
        >
          {memberName}
        </EllipsisText>
        {hasRole ? (
          <EllipsisText
            tooltip={roleToUse.name}
            width={undefined}
            maxWidth={undefined}
            className="member-role"
          >
            {roleToUse.name}
          </EllipsisText>
        ) : (
          <></>
        )}
      </div>
      <div></div>
      <div></div>
      <div className="hour">{renderHours()}</div>
    </StyledItemContainer>
  );
};

export const PhaseMembershipWithUnassignedMemberBudget = (
  phaseMembershipWithUnassignedMemberBudgetAndFormattedPositionItem: PhaseMembershipWithUnassignedMemberBudgetAndFormattedPositionListItem
) => {
  const {
    phaseMembership: { position }
  } = phaseMembershipWithUnassignedMemberBudgetAndFormattedPositionItem;
  const { nameWithCount, name } = position || {};

  const nameToUse = nameWithCount || name;

  return (
    <StyledItemContainer className="phase-membership-unassigned-member-budget">
      <UnassignedMemberIcon />

      <EllipsisText maxWidth={230}>{nameToUse}</EllipsisText>
    </StyledItemContainer>
  );
};

export const AddMemberToPhase = (
  AddMemberToPhaseListItem: AddMemberToPhaseListItem
) => {
  return (
    <StyledItemContainer className="add-member-to-phase">
      Click + to Add Members
    </StyledItemContainer>
  );
};

const StyledIcon = styled.div`
  cursor: pointer;
  padding-left: 5px;
  &:hover {
    circle {
      fill: ${theme.colors.colorRoyalBlue};
    }

    path {
      stroke-width: 1px;
      stroke: ${theme.colors.colorPureWhite};
    }
  }
`;
