export const splitFlags = [
  'showErrorMessages',
  'shouldUseIntegrationsV2',
  'QB_V2_WhiteList',
  'showAISuggestionsMemberModalDemo',
  'budgetRateToggle',
  'workplanRequests',
  'stubSuggestionValues',
  'workplanRequests',
  'timeEntryCSVImport',
  'recurly-billing',
  'authServerLoginFlow',
  'useAuthServer',
  'useSmsMfa',
  'shouldRedirectToOnboard',
  'personalDashboard',
  'memberModalWorkSchedule',
  'memberModalWorkPlanner',
  'memberModalOverview',
  'memberModalProjects',
  'memberModalCalendar',
  'memberModalProfile',
  'scope',
  'scopeRequests',
  'integrationSettingsForm',
  'workloadOrganizationGraph',
  'workloadClientView',
  'isRightSideFilterEnabled',
  'autoLinkModal',
  'historicalUtilization',
  'scopeRequestSort',
  'varianceReportRoleView',
  'budgetVarianceReport',
  'workloadForecastReportFlag',
  'billingForecastReportFlag',
  'syncLogTableFlag',
  'addHolidayButtonFlag',
  'scheduleVarianceReport',
  'timeTrackingFlag',
  'scheduleVarianceReport',
  'skillsReportFlag',
  'workloadForecastReportDemoData',
  'crossFieldFilterOnPlannerFlag',
  'checkInsReportFlag',
  'allProjectsReportFlag',
  'isDisciplineEnabledInSettings',
  'isRegionEnabledInSettings',
  'isLocationEnabledInSettings',
  'isOfficeEnabledInSettings',
  'isPTOEnabledInSettings',
  'isRoleEnabledInSettings',
  'osmosImportV2Flag',
  'workplanRequestsFlag',
  'dashboardTeamBudgetWidgetFlag',
  'isActivitySideBarEnabled',
  'autoRescheduleWorkplanDemo',
  'workplansCsvImportFlag',
  'googleCalendarEventsFlag',
  'ptoReportFlag',
  'budgetTableChartViewFlag',
  'ptoReportFlag',
  'useSamlAuth',
  'timeProjectionReportFlag',
  'roleForecastReportFlag',
  'timesheetEstimate',
  'newPermissionSettings',
  'demandReportChartsFlag',
  'orgChartFlag',
  'projectCurrencySetting',
  'skillReportSuggestionFlag',
  'memberAddToPhaseSuggestionFlag', // i.e: Add to Phase flyout on Budget modal,
  'homeTimesheetSuggestionFlag',
  'hoursOnlyFlag',
  'canProvisionAsanaFlag',
  'canProvisionSalesforceFlag',
  'teamCurrencySetting',
  'isCapacityEnabledInSettings',
  'unplannedScopesFlag',
  'workloadSelectionLimitFlag',
  'membersCsvImportFlag',
  'capacityOnWorkPlanCalendar',
  'spentTimeReportFlag',
  'plannedTimeReportFlag',
  'unspentBudgetReportFlag',
  'workloadVisibilitySettingsFlag',
  'unassignedWorkPlansSplitView',
  'projectPhasesActivityPhasesCSVImport',
  'customHeatmapSetting',
  'crossFilterOnWorkloadFlag',
  'tentativePlanVisibilitySettingFlag',
  'plannerViewPhaseHoursFlag',
  'taskScopeCsvImportFlag',
  'projectLevelPermissionFlag',
  'canProvisionGcalFlag',
  'canProvisionOutlookFlag',
  'canProvisionJiraHostedFlag',
  'projectLevelPermissionFlag',
  'projectBudgetSettingsModalFlag',
  'taskDrawerUpdateFlag',
  'syncLogOverviewFlag',
  'newMembersSuggestionTableFlag',
  'restrictAccessToProjectsFlag',
  'timeFormatFlag',
  'dateFormatFlag',
  'teamLanguageFlag',
  'startOfWeekFlag',
  'fullTimeEquivalentFlag',
  'phaseReferenceNumberFlag',
  'workPlanHoursDisplayDefaultFlag',
  'workPlanLockingTeamDefaultFlag',
  'deleteAccountFlag',
  'restrictAccessToProjectsFlag',
  'isSecuritySettingsEnabledFlag',
  'mfaEnabledFlag',
  'showExpandAllFlag',
  'newMembersSuggestionTableFlag',
  'viewFilterOnPlannerFlag',
  'securitySettingsNewUIFlag',
  'csvImportLogFlag',
  'phaseSortOrderFlag',
  'autoplanModalFlag',
  'workCategoryRateGroupFlag',
  'newMemberRatesFlag',
  'roleRateGroupFlag',
  'settingsBudgetTabFlag',
  'teamBudgetSettingsFlag',
  'printReportFlag'
] as const;

export type SplitFlags = Record<typeof splitFlags[number], boolean>;
