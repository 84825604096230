import { memo } from 'react';
import styled from 'styled-components';
import {
  AddableFiltersDropdown,
  AddableFiltersDropdownProps
} from './AddableFiltersDropdown';
import { FilterDropdownToggle } from './FilterDropdownToggle';
import {
  ProjectsFilterDropdown,
  ProjectBudgetStatusFilterDropdown,
  ClientNamesFilterDropdown,
  PrioritiesFilterDropdown,
  ArchivedStatusFilterDropdown,
  MembersFilterDropdown,
  DisciplinesFilterDropdown,
  PositionsFilterDropdown,
  DepartmentsFilterDropdown,
  OfficesFilterDropdown
} from 'FilterModule/components/FilterDropdowns';
import { FilterListType } from 'FilterModule/constants';
import { FilterListTypeDropdown } from 'FilterModule/types';
import { ProjectsWithUnassignedRolesFilterDropdown } from '../FilterDropdowns/ProjectsWithUnassignedRolesFilterDropdown';

export type AddableFiltersProps = Required<
  Omit<
    AddableFiltersDropdownProps,
    'dropdownHeaderOverride' | 'toggleLabelOverride'
  >
> &
  Partial<AddableFiltersDropdownProps>;

export const AddableFilters = memo(
  ({
    filterOrder,
    dropdownHeaderOverride,
    optionsArray,
    optionHash,
    toggleLabelOverride,
    updateFilterOrder
  }: AddableFiltersProps) => {
    return (
      <StyledAddableFiltersContainer>
        <AddableFiltersDropdown
          dropdownHeaderOverride={dropdownHeaderOverride}
          filterOrder={filterOrder}
          optionsArray={optionsArray}
          optionHash={optionHash}
          toggleLabelOverride={toggleLabelOverride}
          updateFilterOrder={updateFilterOrder}
        />

        <StyledFilters className="addable-filters-added">
          {filterOrder.map((filterListType) => {
            const DropdownComponent =
              filterListTypeToDropdownComponent[filterListType];

            if (!DropdownComponent) return null;

            const renderToggle = ({
              isOpen,
              customLabel
            }: {
              isOpen: boolean;
              customLabel: string;
            }) => (
              <FilterDropdownToggle
                key={filterListType}
                isOpen={isOpen}
                filterListType={filterListType}
                optionHash={optionHash}
                customLabel={customLabel}
                fieldOverride={optionHash[filterListType]?.fieldOverride}
              />
            );

            return (
              <DropdownComponent
                key={filterListType}
                field={optionHash[filterListType]?.fieldOverride}
                renderToggle={renderToggle}
              />
            );
          })}
        </StyledFilters>
      </StyledAddableFiltersContainer>
    );
  }
);

AddableFilters.displayName = 'AddableFilters';

/* ------------------------------------ - ----------------------------------- */

/**
 * The supported FilterListTypes and their components
 */
const filterListTypeToDropdownComponent: Partial<
  Record<FilterListType, FilterListTypeDropdown>
> = {
  [FilterListType.Projects]: ProjectsFilterDropdown,
  [FilterListType.ClientNames]: ClientNamesFilterDropdown,
  [FilterListType.ProjectBudgetStatus]: ProjectBudgetStatusFilterDropdown,
  [FilterListType.Priorities]: PrioritiesFilterDropdown,
  [FilterListType.ArchivedStatus]: ArchivedStatusFilterDropdown,
  [FilterListType.Members]: MembersFilterDropdown,
  [FilterListType.Disciplines]: DisciplinesFilterDropdown,
  [FilterListType.Positions]: PositionsFilterDropdown,
  [FilterListType.Departments]: DepartmentsFilterDropdown,
  [FilterListType.Offices]: OfficesFilterDropdown,
  [FilterListType.ShowProjectsWithUnassignedRoles]:
    ProjectsWithUnassignedRolesFilterDropdown
};

const StyledFilters = styled.div`
  display: flex;
  align-items: center;
  max-width: 30vw;
  flex-wrap: wrap;
  gap: 4px;
`;

const StyledAddableFiltersContainer = styled.div`
  display: flex;
  align-items: center;

  .addable-filters-toggle {
    margin-right: 5px;
  }

  .filter-dropdown-toggle {
    display: flex;
    align-items: center;
    padding-bottom: 2px;
    height: 26px;
  }
`;
