import { createSelector } from 'reselect';
import { makeIdHash } from 'appUtils';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import groupBy from 'lodash/groupBy';
import { initialState as phasesInitialState } from '../reducers/phases';
import { initialState as phaseTemplatesInitialState } from '../reducers/phaseTemplates';
import {
  getProjectHash,
  getMemberProjectsHash,
  getAllFetchedProjectIdsHash,
  getOOOProject,
  getOwnProjectId
} from './projects';
import { hasAssociatedTime } from 'appUtils/budgetUtils';
import {
  getIsPhaseLikeDefault,
  isPhaseArchived
} from 'appUtils/phaseDisplayUtils';
import { getMe } from './core';
import { DEPENDABLE_TYPES } from 'components/Dependency/constants';

const getIsFetching = (state) => state.fetching;
const emptyObj = {};
const emptyArray = [];
const byId = (item) => item && item.id;

// todo: resolve import loop
const getAllActivityRowInfo = (state) => state.timesheets.activityRows;

const getCheckinsHours = (state) => state.home.checkinsHours;

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getPhasesState = (state) => state.phases || phasesInitialState;

export const getPhaseTemplatesState = (state) =>
  state.phaseTemplates || phaseTemplatesInitialState;

export const getAllProjectTotals = createSelector(
  getPhasesState,
  (state) => state.projectTotals
);
export const getBudgetReportProjectTotals = createSelector(
  getPhasesState,
  (state) => state.reportTotals
);

export const getPhasesAndMilestones = createSelector(
  getPhasesState,
  (phaseState) => phaseState.phases
);

export const getPhases = createSelector(
  getPhasesAndMilestones,
  (phasesAndMilestones) =>
    pickBy(phasesAndMilestones, (phase) => phase.is_budget)
);

export const getMilestones = createSelector(
  getPhasesAndMilestones,
  (phasesAndMilestones) =>
    pickBy(phasesAndMilestones, (phase) => !phase.is_budget)
);

export const getPhaseIds = createSelector(
  getPhasesState,
  (phaseState) => phaseState.phaseIds
);

export const getMilestoneIds = createSelector(getMilestones, (phases) =>
  Object.keys(phases).reduce((acc, val) => {
    acc.push(phases[val].id);
    return acc;
  }, [])
);

export const getPhaseNames = createSelector(
  getPhasesState,
  (phaseState) => phaseState?.phaseNames || emptyArray
);
export const getIsFetchingPhaseNames = createSelector(
  getPhasesState,
  (phaseState) => phaseState?.isFetchingPhaseNames || false
);
export const getHasFetchedPhaseNames = createSelector(
  getPhasesState,
  (phaseState) => phaseState?.hasFetchedPhaseNames || false
);

export const getPhasesOrder = createSelector(
  getPhasesState,
  getPhases,
  (phaseState, phases) => [
    ...Array.from(new Set(phaseState.phaseOrder.filter((id) => phases[id])))
  ] // removes duplicate numbers
);

export const getModalPhaseId = createSelector(
  getPhasesState,
  (state) => state.modalPhaseId
);

export const getModalPhase = createSelector(
  getModalPhaseId,
  getPhases,
  (id, phases) => id && phases[id]
);

export const getModalPhaseIsAllPhases = createSelector(
  getPhasesState,
  (state) => state.isAllPhases
);

export const getModalPhaseProjectId = createSelector(
  getPhasesState,
  (state) => state.modalProjectId
);
export const getModalPhaseName = createSelector(
  getPhasesState,
  (state) => state.modalPhaseName
);
export const getModalPhaseIsArchivedFlag = createSelector(
  getPhasesState,
  (state) => state.isArchived
);

export const getHasTimeEntries = (state) => state.timesheets.hasTimeEntries;

const getOwnPhaseId = (_, ownProps) => ownProps?.phaseId;

export const makeGetIsPhaseHasTimeEntries = () =>
  createSelector(
    getHasTimeEntries,
    getOwnPhaseId,
    (hasTimeEntries, phaseId) =>
      !!hasTimeEntries?.find((hasTimeEntry) => {
        return (
          hasTimeEntry.phase_id === phaseId &&
          hasTimeEntry.has_associated_time_entries
        );
      })
  );

export const makeGetIsPhaseHasWorkplans = () =>
  createSelector(
    getHasTimeEntries,
    getOwnPhaseId,
    (hasTimeEntries, phaseId) =>
      !!hasTimeEntries?.find(
        (hasTimeEntry) =>
          hasTimeEntry.phase_id === phaseId &&
          hasTimeEntry.has_associated_activity_phase_schedule_bars
      )
  );

export const getModalPhaseHasTimeEntries = createSelector(
  getHasTimeEntries,
  getModalPhaseId,
  (hasTimeEntries, id) =>
    hasTimeEntries?.find(
      (hasTimeEntry) =>
        hasTimeEntry.phase_id === id && hasTimeEntry.has_associated_time_entries
    )
);

export const getModalPhaseHasTimeEntriesOrWorkplans = createSelector(
  getHasTimeEntries,
  getModalPhaseId,
  (hasTimeEntries, id) =>
    hasTimeEntries?.find(
      (hasTimeEntry) =>
        hasTimeEntry.phase_id === id &&
        (hasTimeEntry.has_associated_time_entries ||
          hasTimeEntry.has_associated_activity_phase_schedule_bars)
    )
);

export const getActivityModalPhaseId = createSelector(
  getPhasesState,
  (state) => state.activityPhaseModalPhaseId
);

export const getActivityPhaseModalActivityId = createSelector(
  getPhasesState,
  (state) => state.activityPhaseModalActivityId
);
export const getActivityPhaseModalActivity = createSelector(
  getActivityPhaseModalActivityId,
  getAllActivityRowInfo,
  (id, activities) => activities[id]
);

export const getModalActivityPhaseHasTimeEntries = createSelector(
  (state) => state.timesheets.hasTimeEntries,
  getActivityModalPhaseId,
  getActivityPhaseModalActivityId,
  (hasTimeEntries, phaseId, activityId) =>
    hasTimeEntries?.find(
      (hasTimeEntry) =>
        hasTimeEntry.phase_id === phaseId &&
        hasTimeEntry.activity_id === activityId &&
        hasAssociatedTime(hasTimeEntry)
    )
);

export const getIsCustomPhaseFlag = createSelector(
  getPhasesState,
  (phaseState) => phaseState.isCustomPhase
);

export const getPhaseTemplateIds = createSelector(
  getPhaseTemplatesState,
  (phaseTemplateState) => phaseTemplateState.phaseTemplateIds
);

export const getPhaseTemplateOrder = createSelector(
  getPhaseTemplatesState,
  (phaseTemplateState) => phaseTemplateState.phaseTemplateOrder
);

export const getPhaseTemplates = createSelector(
  getPhaseTemplatesState,
  (phaseTemplateState) =>
    phaseTemplateState && phaseTemplateState.phaseTemplates
);

export const getPhaseTemplatesList = createSelector(
  getPhaseTemplates,
  (phaseTemplates) => Object.values(phaseTemplates)
);

export const getIsLoadingPhases = createSelector(getPhasesState, getIsFetching);

export const getIsAddPhase = createSelector(
  getPhasesState,
  (state) => state.isAddPhase
);

export const getIsAddMilestone = createSelector(
  getPhasesState,
  (state) => state.isAddMilestone
);

export const getIsLoadingBoardPhases = createSelector(
  getPhasesState,
  (state) => state.boardFetching
);
export const getIsLoadingPhaseTemplates = createSelector(
  getPhaseTemplatesState,
  getIsFetching
);

const serializeMainPhase = (phase, projectPhases) =>
  projectPhases.length <= 1
    ? {
        ...phase,
        is_main_like_default: true
      }
    : {
        ...phase,
        name: 'Main Project Phase'
      };

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
const getPhaseStatePhaseHash = createSelector(
  getPhasesState,
  (state) => state.phaseHash
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getFlatPhasesAndMilestonesHash = createSelector(
  getPhaseStatePhaseHash,
  (phaseHash) => {
    const phases = Object.values(phaseHash);
    const budgetPhases = phases.filter((phase) => phase.is_budget);
    const budgetPhasesByProject = groupBy(
      Object.values(budgetPhases),
      (phase) => phase.project_id
    );
    const computedPhaseHash = keyBy(
      phases
        .map((phase) =>
          phase.is_main
            ? serializeMainPhase(phase, budgetPhasesByProject[phase.project_id])
            : phase
        )
        .map((phase) =>
          getIsPhaseLikeDefault(phase)
            ? { ...phase, is_like_default: true }
            : phase
        ),
      byId
    );
    return computedPhaseHash;
  }
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getFlatPhasesAndMilestones = createSelector(
  getFlatPhasesAndMilestonesHash,
  (phasesAndMilestonesHash) => Object.values(phasesAndMilestonesHash)
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getFlatPhases = createSelector(
  getFlatPhasesAndMilestones,
  (phasesAndMilestones) =>
    phasesAndMilestones.filter((phase) => phase.is_budget)
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getFlatPhasesHash = createSelector(getFlatPhases, (phases) => {
  const phasesByProjectId = groupBy(
    Object.values(phases),
    (phase) => phase.project_id
  );
  return keyBy(
    phases
      .map((phase) =>
        phase.is_main
          ? serializeMainPhase(phase, phasesByProjectId[phase.project_id])
          : phase
      )
      .map((phase) =>
        getIsPhaseLikeDefault(phase)
          ? { ...phase, is_like_default: true }
          : phase
      ),
    byId
  );
});
export const getFetchedPhasesProjectIdsHash = createSelector(
  getPhasesState,
  (state) => state.fetchedPhasesProjectIds || emptyObj
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getPhasesAndMilestonesByProjectHash = createSelector(
  getPhasesState,
  (state) => state.phasesByProject || emptyObj
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getPhasesByProjectHash = createSelector(
  getPhasesAndMilestonesByProjectHash,
  getFlatPhasesHash,
  (phasesAndMilestones, phaseHash) => {
    const filtered = Object.values(phasesAndMilestones).map((project) => {
      const activePhases = project.phases
        ?.filter((phase) => phase && phase.is_budget)
        .map((phase) => ({ ...phase, ...phaseHash[phase.id] }));

      const activePhaseHash = keyBy(activePhases, byId);

      const activePhaseOrders = activePhases
        .filter((phase) => activePhaseHash[phase.id])
        .map((phase) => phase.id);

      return {
        ...project,
        phases: activePhases,
        phase_orders: activePhaseOrders,
        archivedPhaseIds: activePhases
          .filter((phase) => phase.archived)
          .map((phase) => phase.id)
      };
    });
    return keyBy(filtered, byId);
  }
);

export const getMilestonesByProjectHash = createSelector(
  getPhasesAndMilestonesByProjectHash,
  (phasesAndMilestones) => {
    const filtered = Object.values(phasesAndMilestones).map((project) => {
      const activePhases = project.phases?.filter(
        (phase) => phase && !phase.is_budget
      );
      const activePhaseHash = keyBy(activePhases, byId);
      const activePhaseOrders = project.phase_orders.filter(
        (id) => activePhaseHash[id]
      );
      return {
        ...project,
        phases: activePhases,
        phase_orders: activePhaseOrders
      };
    });
    return keyBy(filtered, byId);
  }
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getPhasesByProjectIdsOrder = createSelector(
  getPhasesState,
  (state) => state.phasesByProjectIdsOrder || emptyArray
);

/**
 * @deprecated Use the typesafe version from `ProjectsModule/phases/selectors`.
 */
export const getPhasesByProject = createSelector(
  getPhasesByProjectIdsOrder,
  getPhasesByProjectHash,
  (order, hash) => {
    const phasesByProjects = order.map((id) => hash[id]);
    return phasesByProjects || emptyArray;
  }
);

export const getPhaseSearchOffset = createSelector(
  getPhasesState,
  (state) => state.offset
);
export const getPhaseTotalsOffset = createSelector(
  getPhasesState,
  (state) => state.totalsOffset
);
export const getTotalProjectCount = createSelector(
  getPhasesState,
  (state) => state.totalProjectCount
);

export const getPhaseTotals = createSelector(
  getPhasesState,
  (phaseState) => phaseState.phaseTotals
);

export const getFlatMilestones = createSelector(
  getFlatPhasesAndMilestones,
  (phasesAndMilestones) =>
    phasesAndMilestones.filter((phase) => !phase.is_budget)
);

export const getFlatMilestonesHash = createSelector(
  getFlatMilestones,
  (milestones) => keyBy(milestones, byId)
);

export const getPhaseAndMilestoneNamesById = createSelector(
  getFlatPhasesAndMilestones,
  (phases) =>
    phases.reduce((phaseNamesById, currentPhase) => {
      const phaseName = currentPhase.name;
      phaseNamesById[currentPhase.id] = phaseName;
      return phaseNamesById;
    }, {})
);

export const getPhaseNamesByPhaseId = createSelector(
  getFlatPhasesHash,
  (phases) =>
    Object.keys(phases).reduce((acc, val) => {
      const phaseName = phases[val].name;
      const phaseId = phases[val].id;
      acc[phaseId] = phaseName;
      return acc;
    }, {})
);

export const getMilestoneNamesByMilestoneId = createSelector(
  getFlatMilestonesHash,
  (milestones) =>
    Object.keys(milestones).reduce((acc, val) => {
      const milestoneName = milestones[val].name;
      const milestoneId = milestones[val].id;
      acc[milestoneId] = milestoneName;
      return acc;
    }, {})
);

export const getPhaseModalOpen = createSelector(
  getPhasesState,
  (state) => state.phaseModalOpen
);
export const getPhaseTemplateDropdownOpen = createSelector(
  getPhasesState,
  (state) => state.phaseTemplateDropdownOpen
);
export const getPhaseTemplateModalOpen = createSelector(
  getPhasesState,
  (state) => state.phaseTemplateModalOpen
);

export const getPhaseTemplateModalId = createSelector(
  getPhasesState,
  (state) => state.modalPhaseTemplateId
);

export const getLowerCaseExistingPhaseTemplates = createSelector(
  getPhaseTemplates,
  (phaseTemplates) =>
    makeIdHash(
      Object.values(phaseTemplates)
        .filter(
          (phaseTemplate) => phaseTemplate.name && !phaseTemplate.archived
        )
        .map((phaseTemplate) => phaseTemplate.name.toLowerCase())
    )
);

export const getOrderedPhaseTemplates = createSelector(
  getPhaseTemplateOrder,
  getPhaseTemplates,
  (ids, phaseTemplates) => ids.map((id) => phaseTemplates[id])
);

export const getModalPhaseTemplate = createSelector(
  getPhaseTemplates,
  getPhaseTemplateModalId,
  (phaseTemplates, id) => phaseTemplates[id] || emptyObj
);

export const getModalPhaseTemplateName = createSelector(
  getModalPhaseTemplate,
  (phaseTemplate) => phaseTemplate.name
);

export const getPhaseName = createSelector(
  getPhasesState,
  (phasesState) => phasesState.phaseName
);

export const getIsDeletePhaseFlag = createSelector(
  getPhasesState,
  (phasesState) => phasesState.isDeletePhase
);

export const getPrevPhaseModal = createSelector(
  getPhasesState,
  (phasesState) => phasesState.prevModal
);

export const getIsEditInfoModalFlag = createSelector(
  getPhasesState,
  (phasesState) => phasesState.isEditInfoModal
);

export const getIsEditActivityPhaseModalOpen = createSelector(
  getPhasesState,
  (phasesState) => phasesState.isEditActivityPhaseModalOpen
);

export const getActivePhasesAndMilestonesByProjectRaw = createSelector(
  getPhasesAndMilestonesByProjectHash,
  getFlatPhasesHash,
  (phasesByProject, phaseHash) => {
    const filtered = Object.values(phasesByProject).map((project) => {
      const activePhases = project.phases
        ?.filter((phase) => phase && !phase.archived)
        .map((phase) => ({ ...phase, ...phaseHash[phase.id] }));
      const activePhasesHash = keyBy(activePhases, byId);
      const archivedPhases = project.phases
        ?.filter((phase) => phase && phase.archived)
        .map((phase) => ({ ...phase, ...phaseHash[phase.id] }));
      const archivedPhasesHash = keyBy(archivedPhases, byId);
      const activePhasesOrder = activePhases.map((phase) => phase.id);
      const archivedPhaseIds = archivedPhases.map((phase) => phase.id);
      const defaultPhase = project.phases?.find(
        (phase) => phase.is_like_default && !phase.archived
      );

      const allPhasesOrder = [...activePhasesOrder, ...archivedPhaseIds];

      // in areas with drag and drop, we still need to use the phase_order
      const manualActivePhases = project.phase_orders
        .map((id) => activePhasesHash[id])
        .filter((phase) => !!phase);
      const manualArchivedPhases = project.phase_orders
        .map((id) => archivedPhasesHash[id])
        .filter((phase) => !!phase);
      const manualActivePhasesOrder = manualActivePhases.map(
        (phase) => phase.id
      );
      const manualArchivedPhasesOrder = manualArchivedPhases.map(
        (phase) => phase.id
      );
      return {
        ...project,
        defaultOrMainPhaseId: defaultPhase?.id,
        manualActivePhases: manualActivePhases,
        manualArchivedPhases: manualArchivedPhases,
        phases: activePhases,
        allPhases: project.phases,
        allPhasesOrder: allPhasesOrder,
        activePhasesOrder: activePhasesOrder,
        archivedPhaseIds: archivedPhaseIds,
        manualActivePhasesOrder: manualActivePhasesOrder,
        manualArchivedPhasesOrder: manualArchivedPhasesOrder
      };
    });
    return filtered;
  }
);

export const getActivePhasesAndMilestonesByProject = createSelector(
  getActivePhasesAndMilestonesByProjectRaw,
  (projects) => {
    return keyBy(projects, byId);
  }
);

export const getActivePhasesByProject = createSelector(
  getActivePhasesAndMilestonesByProjectRaw,
  getFlatPhasesHash,
  (projects, phaseHash) => {
    const filtered = projects.map((project) => ({
      ...project,
      phases: project.phases
        .filter((phase) => phase.is_budget)
        .map((phase) => ({ ...phase, ...phaseHash[phase.id] }))
    }));
    return keyBy(filtered, byId);
  }
);

export const getActiveMilestonesByProject = createSelector(
  getActivePhasesAndMilestonesByProjectRaw,
  getFlatPhasesHash,
  (projects, phaseHash) => {
    const filtered = projects.map((project) => ({
      ...project,
      phases: project.phases
        .filter((phase) => !phase.is_budget)
        .map((phase) => ({ ...phase, ...phaseHash[phase.id] }))
    }));
    return keyBy(filtered, byId);
  }
);

// filters out archived phases, phases with budget statuses other than 'active' are still selected
export const getActiveBudgetPhasesByProject = createSelector(
  getPhasesByProjectHash,
  getFlatPhasesHash,
  (phasesByProject, phaseHash) => {
    const filtered = Object.values(phasesByProject).map((project) => {
      const activePhases = project.phases?.filter(
        (phase) => phase && !isPhaseArchived(phase)
      );
      const defaultPhase = project.phases?.find(
        (phase) =>
          (phase.is_default_phase || phaseHash[phase.id]?.is_like_default) &&
          !isPhaseArchived(phase)
      );
      const byIdActivePhases = keyBy(activePhases, byId);
      const activePhaseOrder =
        project.phase_orders?.filter((id) => byIdActivePhases[id]) ??
        emptyArray;
      const orderedActivePhases = activePhaseOrder.map((id) => ({
        ...byIdActivePhases[id],
        ...phaseHash[id]
      }));

      return {
        ...project,
        defaultOrMainPhaseId: defaultPhase?.id,
        phases: orderedActivePhases
      };
    });
    return keyBy(filtered, byId);
  }
);

export const getActivePhasesByProjectOrderedByProject = createSelector(
  getPhasesByProjectIdsOrder,
  getActivePhasesByProject,
  (projectIds, activePhasesByProject) =>
    projectIds
      .map((id) => activePhasesByProject[id])
      .filter((project) => project)
);
export const getActiveBudgetPhasesByProjectOrderedByProject = createSelector(
  getPhasesByProjectIdsOrder,
  getActiveBudgetPhasesByProject,
  (projectIds, activePhasesByProject) =>
    projectIds
      .map((id) => activePhasesByProject[id])
      .filter((project) => project)
);

export const getActiveNonDefaultPhasesAndMilestonesByProjectRaw =
  createSelector(
    getPhasesAndMilestonesByProjectHash,
    getFlatPhasesHash,
    (phasesByProject, phaseHash) => {
      const filtered = Object.values(phasesByProject).map((project) => ({
        ...project,
        phases:
          (project.phases &&
            project.phases.filter(
              (phase) =>
                phase &&
                !phase.is_default_phase &&
                !phaseHash[phase.id]?.is_like_default &&
                !phase.archived
            )) ||
          emptyArray
      }));
      return filtered;
    }
  );

export const getActiveNonDefaultPhasesAndMilestonesByProject = createSelector(
  getActiveNonDefaultPhasesAndMilestonesByProjectRaw,
  (projects) => keyBy(projects, byId)
);

export const getActiveNonDefaultPhasesByProject = createSelector(
  getActiveNonDefaultPhasesAndMilestonesByProjectRaw,
  (projects) => {
    const filtered = projects.map((project) => ({
      ...project,
      phases: project.phases.filter((phase) => phase.is_budget)
    }));
    return keyBy(filtered, byId);
  }
);

export const getActiveNonDefaultMilestonesByProject = createSelector(
  getActiveNonDefaultPhasesAndMilestonesByProjectRaw,
  (projects) => {
    const filtered = projects.map((project) => ({
      ...project,
      phases: project.phases.filter((phase) => !phase.is_budget)
    }));
    return keyBy(filtered, byId);
  }
);

const getViewType = (state, ownProps) => ownProps?.viewType;

const getBudgetTotals = (state) =>
  state.workloadPlannerBarModal.budgetAggregatesByAccountId;
const getOwnAccountId = (state, ownProps) => ownProps?.accountId;
const makeGetBudgetTotalsByAccount = () =>
  createSelector(
    getOwnAccountId,
    getBudgetTotals,
    (accountId, budgetTotals) => budgetTotals[accountId]
  );

const formatProjectAndPhasesProject = (
  project,
  phasesByProjectProject,
  budgetTotals,
  isSuggested,
  budgetTotalsAccountId
) => {
  const formattedProject = {
    hasPhases: phasesByProjectProject.phases.length > 1,
    defaultOrMainPhaseId: phasesByProjectProject.defaultOrMainPhaseId,
    phaseTitles: '',
    ...project,
    budgetTotals: budgetTotals?.[project.id]?.budget_totals,
    isSuggested: isSuggested,
    budgetTotalsAccountIsMember:
      budgetTotalsAccountId &&
      !!project.member_account_ids.find(
        (projectMemberId) => projectMemberId === budgetTotalsAccountId
      )
  };
  return formattedProject;
};

const formatProjectAndPhasesPhase = (
  phase,
  project,
  myId,
  budgetTotals,
  budgetTotalsAccountId,
  isSuggested
) => {
  const formattedPhase = {
    ...phase,
    is_phase: true,
    is_administrative: project.is_administrative,
    title: phase?.name,
    project_title: project.title,
    project_description: project.description,
    user_is_member: !!phase?.phase_memberships.find(
      (phaseMembership) => phaseMembership.account_id === myId
    ),
    budgetTotalsAccountIsMember:
      budgetTotalsAccountId &&
      !!phase?.phase_memberships.find(
        (phaseMembership) =>
          phaseMembership.account_id === budgetTotalsAccountId
      ),
    project_number: project.project_number,
    client: project.client,
    project,
    budgetTotals:
      budgetTotals?.[project.id]?.phase_totals?.[phase.id]?.budget_totals,
    activityTotals:
      budgetTotals?.[project.id]?.phase_totals?.[phase.id]?.activity_totals,
    isSuggested: isSuggested
  };
  return formattedPhase;
};
const formatProjectAndPhasesActivity = (
  activity,
  phase,
  activityPhase,
  budgetTotalsAccountId,
  isSuggested
) => {
  const formattedActivity = {
    ...activity,
    activityPhase: activityPhase,
    is_activity: true,
    budgetTotalsAccountIsMember:
      !!activityPhase?.activity_phase_memberships.find(
        (activityPhaseMembership) =>
          activityPhaseMembership.account_id === budgetTotalsAccountId
      ), // TODO update with activityPhaseMembershi
    phase,
    project: phase.project,
    phase_id: phase.id,
    project_id: phase.project.id,
    board_id: phase.project.board_id,
    budgetTotals: phase.activityTotals?.[activity.id]?.budget_totals,
    isSuggested: isSuggested
  };
  return formattedActivity;
};

const formatProjectsAndPhases = (projects, phasesByProjects, me) => {
  return projects.reduce((projectsAndPhases, project) => {
    const projectPhases = phasesByProjects[project.id];

    const phases = projectPhases?.phases || emptyArray;
    const formattedProject = { ...project };
    projectsAndPhases.push(formattedProject);
    phases
      .filter((phase) => phase && !phase.is_like_default && !phase.archived)
      .forEach((phase) => {
        const formattedPhase = formatProjectAndPhasesPhase(
          phase,
          project,
          me?.id
        );
        projectsAndPhases.push(formattedPhase);
        formattedProject.phaseTitles = `${formattedProject.phaseTitles} ${
          phase.name || ''
        }`;
      });
    return projectsAndPhases;
  }, []);
};

const getIncludePersonalProjects = (state, ownProps) =>
  ownProps?.includePersonalProjects !== undefined
    ? ownProps?.includePersonalProjects
    : false;

const getSuggestedProjectHash = (state, ownProps) =>
  ownProps?.suggestedProjectHash || emptyObj;

const getSuggestedPhaseHash = (state, ownProps) =>
  ownProps.suggestedPhaseHash || emptyObj;

const getSuggestedActivityHash = (state, ownProps) =>
  ownProps.suggestedActivityHash || emptyObj;

const getShouldPinSuggestions = (state, ownProps) =>
  ownProps?.shouldPinSuggestions;

export const getPlannerProjectsWithoutPinned = createSelector(
  getProjectHash,
  getActiveBudgetPhasesByProjectOrderedByProject,
  getMemberProjectsHash,
  getAllFetchedProjectIdsHash,
  getViewType,
  makeGetBudgetTotalsByAccount(),
  getIncludePersonalProjects,
  getSuggestedProjectHash,
  getShouldPinSuggestions,
  getOwnAccountId,
  (
    projects,
    phasesByProjects,
    memberProjectsHash,
    fetchedProjectIdsHash,
    viewType,
    budgetTotalsForAccount,
    includePersonalProjects,
    suggestedProjectHash,
    shouldPinSuggestions,
    budgetTotalsAccountId
  ) => {
    const formattedProjects = phasesByProjects.reduce(
      (projectsArray, currentProject) => {
        if (
          viewType !== 'workload' && // only accept projects that have been included as the result of a paginated fetch with normal permissions.
          !fetchedProjectIdsHash[currentProject.id]
        ) {
          return projectsArray;
        }
        const project = projects[currentProject.id];
        if (!project || project.is_archived || project.is_administrative) {
          return projectsArray;
        }
        if (project.is_personal && !includePersonalProjects) {
          return projectsArray;
        }
        const formattedProject = formatProjectAndPhasesProject(
          project,
          currentProject,
          budgetTotalsForAccount,
          !!suggestedProjectHash[project.id],
          budgetTotalsAccountId
        );
        projectsArray.push(formattedProject);
        return projectsArray;
      },
      []
    );
    if (shouldPinSuggestions) {
      formattedProjects.sort((a, b) => b.isSuggested - a.isSuggested);
    }
    return formattedProjects;
  }
);

export const getPlannerProjectsAndPhasesWithoutPinned = createSelector(
  getPlannerProjectsWithoutPinned,
  getActiveBudgetPhasesByProject,
  getMe,
  formatProjectsAndPhases
);

export const getPinnedProjects = createSelector(getOOOProject, (oooProject) => {
  if (!oooProject) {
    return emptyArray;
  }
  const formattedOOOProject = {
    ...oooProject,
    hasPhases: false,
    ...(oooProject.phases.length === 1 && {
      defaultOrMainPhaseId: oooProject.phases[0]
    })
  };
  return [formattedOOOProject];
});

export const getPinnedProjectsAndPhases = createSelector(
  getPinnedProjects,
  getActiveBudgetPhasesByProject,
  getMe,
  formatProjectsAndPhases
);

export const getPlannerProjects = createSelector(
  getPlannerProjectsWithoutPinned,
  getPinnedProjects,
  (projects, pinnedProjects) => [...pinnedProjects, ...projects]
);

export const makeSuggestedProjectHash = createSelector(
  getCheckinsHours,
  (checkinsHours) => {
    const checkinsHoursArr = Object.values(checkinsHours);
    return checkinsHoursArr.length > 0
      ? keyBy(checkinsHoursArr[0].project_totals, (item) => item.project_id)
      : {};
  }
);

export const makeGetPlannerPhases = () =>
  createSelector(
    getOwnProjectId,
    getProjectHash,
    getActiveBudgetPhasesByProject,
    getMemberProjectsHash,
    makeGetBudgetTotalsByAccount(),
    getOwnAccountId,
    getSuggestedPhaseHash,
    (
      projectId,
      projectHash,
      phasesByProjects,
      memberProjectsHash,
      budgetTotalsForAccount,
      budgetTotalsAccountId,
      suggestedPhaseHash
    ) => {
      const project = projectHash[projectId];
      const projectPhases = phasesByProjects[projectId] || {};
      const phaseOrder =
        projectPhases.phase_orders ||
        project?.phase_orders ||
        project?.phases ||
        [];

      const phases = projectPhases.phases || emptyArray;
      const phaseHash = keyBy(phases, byId);

      return phaseOrder
        .map((phaseId) => phaseHash[phaseId])
        .filter((phase) => phase && !phase.archived)
        .map((phase) =>
          formatProjectAndPhasesPhase(
            phase,
            project,
            memberProjectsHash,
            budgetTotalsForAccount,
            budgetTotalsAccountId,
            suggestedPhaseHash[phase.id]
          )
        );
    }
  );
export const getPlannerPhases = makeGetPlannerPhases();

export const makeGetPlannerPhasesAndActivities = () =>
  createSelector(
    makeGetPlannerPhases(),
    getAllActivityRowInfo,
    getOwnAccountId,
    getSuggestedActivityHash,
    (phases, activities, budgetTotalsAccountId, suggestedActivityHash) =>
      phases.reduce((phasesAndActivities, phase) => {
        const hasActivities = !!phase.activity_order.length;
        /**
         *  default phase can be passed from makeGetPlannerPhases selector
         *  if default phase has at least one work category, it should not include phase itself to hide default phase row.
         *  */
        if (!phase.is_like_default) {
          phasesAndActivities.push({
            ...phase,
            hasActivities
          });
        }
        phase.activity_order.forEach((activityId) => {
          const activity = activities[activityId];
          const activityPhase = phase.activity_phases?.find(
            (activityPhase) => activityPhase.activity_id === activityId
          );
          if (activity) {
            phasesAndActivities.push(
              formatProjectAndPhasesActivity(
                activity,
                phase,
                activityPhase,
                budgetTotalsAccountId,
                suggestedActivityHash[phase.id]
                  ? suggestedActivityHash[phase.id][activityPhase.activity_id]
                  : false
              )
            );
          }
        });
        return phasesAndActivities;
      }, [])
  );
export const getPlannerPhasesAndActivities =
  makeGetPlannerPhasesAndActivities();

export const makeSuggestedPhaseHash = createSelector(
  getCheckinsHours,
  (checkinsHours) => {
    const phaseHash = {};
    const checkinsHoursArr = Object.values(checkinsHours);
    if (checkinsHoursArr.length > 0) {
      checkinsHoursArr[0].project_totals.forEach((project) => {
        project.phases.forEach((phase) => {
          phaseHash[phase.phase_id] = true;
        });
      });
    }
    return phaseHash;
  }
);

export const makeSuggestedActivityHash = createSelector(
  getCheckinsHours,
  (checkinsHours) => {
    const activitiesByPhaseIdHash = {};
    const checkinsHoursArr = Object.values(checkinsHours);
    if (checkinsHoursArr.length > 0) {
      checkinsHoursArr[0].project_totals.forEach((project) => {
        project.phases.forEach((phase) => {
          activitiesByPhaseIdHash[phase.phase_id] = {};
          phase.activities.forEach((activity) => {
            activitiesByPhaseIdHash[phase.phase_id][
              activity.activity_id
            ] = true;
          });
        });
      });
    }
    return activitiesByPhaseIdHash;
  }
);

export const getPlannerProjectsAndPhases = createSelector(
  getPlannerProjectsAndPhasesWithoutPinned,
  getPinnedProjectsAndPhases,
  (projectsAndPhases, pinnedProjectsAndPhases) => [
    ...pinnedProjectsAndPhases,
    ...projectsAndPhases
  ]
);

export const getBudgetReportTotalsOrder = createSelector(
  getPhasesState,
  (state) => state.reportTotalsOrder
);
export const getIsLoadingBudgetReportTotals = createSelector(
  getPhasesState,
  (state) => state.isLoadingReportTotals
);

export const getActivityPhaseHash = createSelector(getFlatPhases, (phases) => {
  const activityPhaseHash = {};
  phases.forEach((phase) =>
    phase.activity_phases.forEach(
      (activityPhase) => (activityPhaseHash[activityPhase.id] = activityPhase)
    )
  );
  return activityPhaseHash;
});

export const getActivityPhaseModalPhase = createSelector(
  getActivityModalPhaseId,
  getFlatPhasesHash,
  (id, phases) => id && phases[id]
);

const getOwnFilterStateId = (state, ownProps) => {
  return (
    ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id
  );
};

const getOwnSelectedPhaseId = (state, ownProps) => {
  return ownProps?.phaseId;
};

const getOwnSelectedProjectId = (state, ownProps) => {
  return ownProps?.projectId;
};

export const makeGetPhaseDependencyItems = () => {
  return createSelector(
    getActivePhasesAndMilestonesByProject,
    getFlatPhasesAndMilestonesHash,
    getOwnSelectedProjectId,
    getOwnSelectedPhaseId,
    (projectsPhases, phaseMilestonesHash, projectId, phaseId) => {
      const phase = projectId && projectsPhases[projectId];
      if (!phase) return [];

      const { activePhasesOrder } = phase;

      const newDependableItems = activePhasesOrder
        ? activePhasesOrder
            .filter((orderedPhase) => {
              const orderedPhaseHash = phaseMilestonesHash[orderedPhase];
              return (
                orderedPhaseHash &&
                !orderedPhaseHash.is_archived &&
                orderedPhaseHash.is_budget &&
                orderedPhaseHash.id !== phaseId
              );
            })
            .map((orderedPhase) => {
              const orderedPhaseHash = phaseMilestonesHash[orderedPhase];
              return {
                dependableId: orderedPhaseHash.id,
                label: orderedPhaseHash.name,
                dependableType: DEPENDABLE_TYPES.PHASE,
                startDate: orderedPhaseHash.start_date,
                endDate: orderedPhaseHash.end_date
              };
            })
        : [];

      return newDependableItems;
    }
  );
};

export const makeGetPhaseIdsByFilterId = () =>
  createSelector(
    getPhasesState,
    getOwnFilterStateId,
    (state, filterId) => state.phaseIdsByFilter[filterId] || emptyArray
  );

const getOwnActivityPhaseId = (state, ownProps) => ownProps.activityPhaseId;

export const makeGetOwnActivityPhase = () =>
  createSelector(
    getActivityPhaseHash,
    getOwnActivityPhaseId,
    (activityPhaseHash, activityPhaseId) =>
      activityPhaseHash[activityPhaseId] || emptyObj
  );

export const makeGetOwnActivityPhaseUsingPhaseId = () =>
  createSelector(
    getFlatPhasesHash,
    (_, ownProps) => ownProps?.phaseId,
    getOwnActivityPhaseId,
    (phaseHash, phaseId, activityPhaseId) =>
      phaseHash[phaseId]?.activity_phases?.find(
        (activityPhase) => activityPhase.id === activityPhaseId
      )
  );

export const getPhase = createSelector(
  getFlatPhasesHash,
  getOwnSelectedPhaseId,
  (phasesHash, phaseId) => phaseId && phasesHash[phaseId]
);
export const makeGetPhase = (ownProps) => (state) => getPhase(state, ownProps);
