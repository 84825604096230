import React from 'react';
import {
  UTILIZATION_OPTIONS_DISPLAY,
  UTILIZATION_RANGE_OPTIONS_BY_VIEW,
  showMonthPickerForCustom,
  utilizationDateRangeFormatterByView
} from 'appUtils/projectPlannerUtils';

import DateRangeOptions from 'components/options/DateRangeOptions';

const UtilizationRangeOptions = ({ activeFilter, viewBy, value }) => {
  return (
    <DateRangeOptions
      activeFilter={activeFilter}
      currentValue={value}
      options={UTILIZATION_RANGE_OPTIONS_BY_VIEW[viewBy]}
      optionsHash={UTILIZATION_OPTIONS_DISPLAY}
      showMonthPickerForCustom={showMonthPickerForCustom[viewBy]}
      toggleFormatter={utilizationDateRangeFormatterByView[viewBy]}
      unhideArrowOnHover
    />
  );
};

export default UtilizationRangeOptions;
