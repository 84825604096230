import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateProjectModules, openModulesModal } from 'actionCreators';

const AddModuleListItem = ({ openModulesModal }) => (
  <li className={'clickable'} id="add-tab" onClick={openModulesModal}>
    Add View
  </li>
);

const mapDispatchToProps = {
  updateProjectModules,
  openModulesModal
};
export default withRouter(connect(null, mapDispatchToProps)(AddModuleListItem));
