import theme from 'theme';
import styled from 'styled-components';
import ThreeLineWithPlusIcon from 'icons/ThreeLineWithPlusIcon';

export interface AddableFiltersToggleProps {
  handleClick?: () => void;

  isOpen?: boolean;

  /** Overrides the label of the toggle. If `null`, the label is hidden. */
  labelOverride?: Nullable<string>;
}

export const AddableFiltersToggle = ({
  handleClick,
  isOpen,
  labelOverride
}: AddableFiltersToggleProps) => {
  return (
    <StyledFilterDropdownToggle
      onClick={handleClick}
      className="addable-filters-toggle"
      isOpen={isOpen}
    >
      <ThreeLineWithPlusIcon />
      {labelOverride !== null && <span>{labelOverride || 'Filter'}</span>}
    </StyledFilterDropdownToggle>
  );
};

/* ------------------------------------ - ----------------------------------- */

const StyledFilterDropdownToggle = styled.div<{ isOpen?: boolean }>`
  cursor: pointer;
  height: 100%;
  padding: 1px 2px;
  white-space: nowrap;

  ${(props) => props.isOpen && `filter: brightness(80%);`}
  &:hover {
    filter: brightness(80%);
  }

  svg {
    margin-right: 3px;
  }
  span {
    font-size: 15px;
    color: ${theme.colors.colorCalendarBlue};
  }
`;
