import React from 'react';
import { useSelector } from 'react-redux';
import { getIsCsvImportDataReady, getIsCSVProvisioned } from 'selectors';
import { getIsIntegrationsV2Loading } from 'IntegrationsModule/selectors';
import ImportButton from './ImportButton';
import ProvisionButton from './ProvisionButton';
import LoadImportDataButton from './LoadImportDataButton';
import LoadImportDataTooltip from './LoadImportDataTooltip';
import styled from 'styled-components';
import { CsvImportEntity } from 'CsvImportsModule/constants';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';

const entityTypeToFlag = {
  [CsvImportEntity.Timesheet]: 'timeEntryCSVImport',
  [CsvImportEntity.WorkPlan]: 'workplansCsvImportFlag',
  [CsvImportEntity.Member]: 'membersCsvImportFlag',
  [CsvImportEntity.ProjectPhaseActivityPhase]:
    'projectPhasesActivityPhasesCSVImport',
  [CsvImportEntity.TaskScope]: 'taskScopeCsvImportFlag'
};

interface ImportFlowProps {
  entityType: CsvImportEntity;
}

const ImportFlow = ({ entityType }: ImportFlowProps) => {
  const flags = useFeatureFlags();
  const isCsvImportEnabled = flags[entityTypeToFlag[entityType]];
  const isCsvImportDataReady = useSelector(getIsCsvImportDataReady);
  const isProvisioned = useSelector(getIsCSVProvisioned);
  const isFetchingProvisions = useSelector(getIsIntegrationsV2Loading);

  if (!isCsvImportEnabled) {
    return null;
  }

  return (
    <ImportFlowContainer>
      {!isProvisioned && !isFetchingProvisions && (
        <>
          <ProvisionButton />
          <LoadImportDataTooltip
            isProvisioned={isProvisioned}
            isCsvImportDataReady={undefined}
          />
        </>
      )}
      {isProvisioned && (
        <>
          <ImportButton
            isCsvImportDataReady={isCsvImportDataReady}
            entityType={entityType}
          />
          {
            <LoadImportDataTooltip
              isCsvImportDataReady={isCsvImportDataReady}
              isProvisioned={isProvisioned}
            />
          }
          {!isCsvImportDataReady && <LoadImportDataButton />}
        </>
      )}
    </ImportFlowContainer>
  );
};

export default ImportFlow;

/* ------------------------------------ - ----------------------------------- */

const ImportFlowContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 7px;
`;
