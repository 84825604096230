import { lazy, Suspense } from 'react';
import DefaultViewPermissionCheck from 'containers/DefaultViewPermissionCheck';
import { PageContainer, PageContainerProps } from 'views/PageContainer';
import { Page } from 'models/filter';
import { initialFilterLevelSchemas } from './filterConfig';

const ImportLogTableContainer = lazy(
  () => import('./ImportLogTable/ImportLogTableContainer')
);

const pageName = Page.ImportLogOverview;
// only 1 view for now on this page
const view = 'overview';

const ImportLogPageContainer = (props) => {
  const pageContainerProps: PageContainerProps = {
    pageContextProviderProps: {
      currentView: view,
      pageName: pageName
    },
    filterContextProviderProps: {
      filterSchemaParams: {
        pageName: pageName,
        initialFilterLevelSchemas,
        level2: view
      }
    }
  };
  return (
    // Page context
    <PageContainer {...pageContainerProps}>
      {/* permission check */}
      <DefaultViewPermissionCheck
        condition={true} // TODO space permission check
        // viewToFlag={}
      >
        <Suspense fallback={<div />}>
          <ImportLogTableContainer {...props} />
        </Suspense>
      </DefaultViewPermissionCheck>
    </PageContainer>
  );
};

export default ImportLogPageContainer;
