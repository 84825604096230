import jwtDecode from 'jwt-decode';
import * as constants from 'appConstants';
import { action } from 'appUtils';

/* auth */

export const login = {
  request: () => action(constants.LOGIN.REQUEST),
  success: (response) => {
    try {
      jwtDecode(response.auth_token);
    } catch (e) {
      return action(constants.LOGIN.FAILURE, {
        payload: {
          error: {
            status: 403,
            statusText: 'Invalid token'
          }
        }
      });
    }
    return action(constants.LOGIN.SUCCESS, { payload: { response } });
  },
  failure: (error) => action(constants.LOGIN.FAILURE, { payload: { error } })
};

export const getAuthToken = {
  request: () => action(constants.GET_AUTH_TOKEN.REQUEST),
  success: () => action(constants.GET_AUTH_TOKEN.SUCCESS),
  failure: (error) =>
    action(constants.GET_AUTH_TOKEN.FAILURE, { payload: { error } })
};
export const signup = {
  request: () => action(constants.SIGNUP.REQUEST),
  success: () => action(constants.SIGNUP.SUCCESS),
  failure: (error) => action(constants.SIGNUP.FAILURE, { payload: { error } })
};

export const onGlobalSearch = {
  request: () => action(constants.ON_GLOBAL_SEARCH.REQUEST),
  success: (response, requestPayload) =>
    action(constants.ON_GLOBAL_SEARCH.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.ON_GLOBAL_SEARCH.FAILURE, { payload: { error } })
};

/* groups */

export const groupCreation = {
  request: (payload) => action(constants.GROUP_CREATION.REQUEST, { payload }),
  success: () => action(constants.GROUP_CREATION.SUCCESS),
  failure: (error) =>
    action(constants.GROUP_CREATION.FAILURE, { payload: { error } })
};

export const groupEdition = {
  request: () => action(constants.GROUP_EDITION.REQUEST),
  success: () => action(constants.GROUP_EDITION.SUCCESS),
  failure: (error) =>
    action(constants.GROUP_EDITION.FAILURE, { payload: { error } })
};

export const putArchiveBoard = {
  request: () => action(constants.ARCHIVE_BOARD.REQUEST),
  success: () => action(constants.ARCHIVE_BOARD.SUCCESS),
  failure: (error) =>
    action(constants.ARCHIVE_BOARD.FAILURE, { payload: { error } })
};

export const putUnarchiveBoard = {
  request: () => action(constants.UNARCHIVE_BOARD.REQUEST),
  success: () => action(constants.UNARCHIVE_BOARD.SUCCESS),
  failure: (error) =>
    action(constants.UNARCHIVE_BOARD.FAILURE, { payload: { error } })
};

export const changeBoardPosition = {
  request: () => action(constants.CHANGE_BOARD_POSITION.REQUEST),
  success: (response) =>
    action(constants.CHANGE_BOARD_POSITION.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.CHANGE_BOARD_POSITION.FAILURE, { payload: { error } })
};

export const groups = {
  request: () => action(constants.GROUPS.REQUEST),
  success: (response) =>
    action(constants.GROUPS.SUCCESS, { payload: { response } }),
  failure: (error) => action(constants.GROUPS.FAILURE, { payload: { error } })
};

export const selectedBoard = {
  request: () => action(constants.FETCH_SELECTED_BOARD.REQUEST),
  success: (response) =>
    action(constants.FETCH_SELECTED_BOARD.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.FETCH_SELECTED_BOARD.FAILURE, { payload: { error } })
};
export const projectsListView = {
  request: () => action(constants.FETCH_PROJECTS_LIST_VIEW.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_PROJECTS_LIST_VIEW.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECTS_LIST_VIEW.FAILURE, { payload: { error } })
};
export const projectsListViewExport = {
  request: () => action(constants.EXPORT_PROJECTS_LIST_VIEW.REQUEST),
  success: (response, requestPayload) =>
    action(constants.EXPORT_PROJECTS_LIST_VIEW.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.EXPORT_PROJECTS_LIST_VIEW.FAILURE, { payload: { error } })
};

export const archivedBoards = {
  request: () => action(constants.FETCH_ARCHIVED_BOARDS.REQUEST),
  success: (response) =>
    action(constants.FETCH_ARCHIVED_BOARDS.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.FETCH_ARCHIVED_BOARDS.FAILURE, { payload: { error } })
};

export const deleteDeleteBoard = {
  request: () => action(constants.DELETE_BOARD.REQUEST),
  success: (response) =>
    action(constants.DELETE_BOARD.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.DELETE_BOARD.FAILURE, { payload: { error } })
};

export const groupMembers = {
  request: (id, initialActionPayload) =>
    action(constants.GROUP_MEMBERS.REQUEST, { initialActionPayload }),
  success: (response, id, initialActionPayload) =>
    action(constants.GROUP_MEMBERS.SUCCESS, {
      payload: { response },
      initialActionPayload
    }),
  failure: (error) =>
    action(constants.GROUP_MEMBERS.FAILURE, { payload: { error } })
};

export const allGroupMembers = {
  request: () => action(constants.ALL_GROUP_MEMBERS.REQUEST),
  success: (response) =>
    action(constants.ALL_GROUP_MEMBERS.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.ALL_GROUP_MEMBERS.FAILURE, { payload: { error } })
};

export const fetchTeams = {
  request: () => action(constants.FETCH_TEAMS.REQUEST),
  success: (response) =>
    action(constants.FETCH_TEAMS.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.FETCH_TEAMS.FAILURE, { payload: { error } })
};

/* projects */
export const fetchProjects = {
  request: () => action(constants.FETCH_PROJECTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECTS.FAILURE, { payload: { error } })
};

export const projectCreation = {
  request: () => action(constants.PROJECT_CREATION.REQUEST),
  success: () => action(constants.PROJECT_CREATION.SUCCESS),
  failure: (error) =>
    action(constants.PROJECT_CREATION.FAILURE, { payload: { error } })
};

export const projectEdition = {
  request: () => action(constants.PROJECT_EDITION.REQUEST),
  success: (response, requestPayload) =>
    action(constants.PROJECT_EDITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.PROJECT_EDITION.FAILURE, { payload: { error } })
};
export const updateProjectBillable = {
  request: () => action(constants.UPDATE_PROJECT_BILLABLE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_PROJECT_BILLABLE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_PROJECT_BILLABLE.FAILURE, { payload: { error } })
};

export const projectUpdatePostion = {
  request: () => action(constants.PROJECT_UPDATE_POSITION.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.PROJECT_UPDATE_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.PROJECT_UPDATE_POSITION.FAILURE, { payload: { error } })
};

export const project = {
  request: () => action(constants.PROJECT.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.PROJECT.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.PROJECT.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};

export const projectPriority = {
  request: () => action(constants.PROJECT_PRIORITY.REQUEST),
  success: (response) =>
    action(constants.PROJECT_PRIORITY.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.PROJECT_PRIORITY.FAILURE, { payload: { error } })
};

export const updateProjectArchiveFlag = {
  request: () => action(constants.ARCHIVE_UNARCHIVE_PROJECT.REQUEST),
  success: (response) =>
    action(constants.ARCHIVE_UNARCHIVE_PROJECT.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_UNARCHIVE_PROJECT.FAILURE, { payload: { error } })
};

export const updateBatchProjectArchiveFlag = {
  request: () => action(constants.BATCH_ARCHIVE_UNARCHIVE_PROJECT.REQUEST),
  success: (response) =>
    action(constants.BATCH_ARCHIVE_UNARCHIVE_PROJECT.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.BATCH_ARCHIVE_UNARCHIVE_PROJECT.FAILURE, {
      payload: { error }
    })
};

/* users */

export const user = {
  request: () => action(constants.USER.REQUEST),
  success: (response) =>
    action(constants.USER.SUCCESS, { payload: { response } }),
  failure: (error) => action(constants.USER.FAILURE, { payload: { error } })
};

export const inviteMember = {
  request: () => action(constants.INVITE_MEMBER.REQUEST),
  success: (response) =>
    action(constants.INVITE_MEMBER.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.INVITE_MEMBER.FAILURE, { payload: { error } })
};

export const inviteQBMembers = {
  request: () => action(constants.INVITE_QB_MEMBERS.REQUEST),
  success: (response) =>
    action(constants.INVITE_QB_MEMBERS.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.INVITE_QB_MEMBERS.FAILURE, { payload: { error } })
};

export const inviteValidation = {
  request: () => action(constants.INVITE_VALIDATION.REQUEST),
  success: (response) =>
    action(constants.INVITE_VALIDATION.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.INVITE_VALIDATION.FAILURE, { payload: { error } })
};

export const teamMembers = {
  request: () => action(constants.TEAM_MEMBERS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.TEAM_MEMBERS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.TEAM_MEMBERS.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};
export const fetchTeamMembersByBoard = {
  request: () => action(constants.FETCH_TEAM_MEMBERS_BY_BOARD.REQUEST),
  success: (response) =>
    action(constants.FETCH_TEAM_MEMBERS_BY_BOARD.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_MEMBERS_BY_BOARD.FAILURE, {
      payload: { error }
    })
};

export const fetchAllTeamMembersByBoard = {
  request: () => action(constants.FETCH_ALL_TEAM_MEMBERS_BY_BOARD.REQUEST),
  success: (response) =>
    action(constants.FETCH_ALL_TEAM_MEMBERS_BY_BOARD.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_ALL_TEAM_MEMBERS_BY_BOARD.FAILURE, {
      payload: { error }
    })
};

export const fetchAllProjectMembers = {
  request: () => action(constants.FETCH_ALL_PROJECT_MEMBERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ALL_PROJECT_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_ALL_PROJECT_MEMBERS.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const addMemberToGroup = {
  request: () => action(constants.ADD_MEMBER_TO_GROUP.REQUEST),
  success: (response) =>
    action(constants.ADD_MEMBER_TO_GROUP.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.ADD_MEMBER_TO_GROUP.FAILURE, { payload: { error } })
};

export const addBulkMembersToGroup = {
  request: () => action(constants.ADD_BULK_MEMBERS_TO_GROUP.REQUEST),
  success: (response) =>
    action(constants.ADD_BULK_MEMBERS_TO_GROUP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.ADD_BULK_MEMBERS_TO_GROUP.FAILURE, { payload: { error } })
};

export const fetchProjectMemberSuggestions = {
  request: () => action(constants.FETCH_SUGGESTIONS.REQUEST),
  success: (response, _, requestPayload, meta) =>
    action(constants.FETCH_SUGGESTIONS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_SUGGESTIONS.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};

export const deleteMemberFromGroup = {
  request: () => action(constants.DELETE_MEMBER_FROM_GROUP.REQUEST),
  success: (response) =>
    action(constants.DELETE_MEMBER_FROM_GROUP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_MEMBER_FROM_GROUP.FAILURE, { payload: { error } })
};

export const updateTeamMembership = {
  request: () => action(constants.UPDATE_TEAM_MEMBERSHIP.REQUEST),
  success: (response) =>
    action(constants.UPDATE_TEAM_MEMBERSHIP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_MEMBERSHIP.FAILURE, { payload: { error } })
};

export const updateTeamMembershipProfile = {
  request: () => action(constants.UPDATE_TEAM_MEMBERSHIP_PROFILE.REQUEST),
  success: (response) =>
    action(constants.UPDATE_TEAM_MEMBERSHIP_PROFILE.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_MEMBERSHIP_PROFILE.FAILURE, {
      payload: { error }
    })
};

export const updateTeamMemberEmailNotification = {
  request: () =>
    action(constants.UPDATE_TEAM_MEMBER_EMAIL_NOTIFICATION.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.UPDATE_TEAM_MEMBER_EMAIL_NOTIFICATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_MEMBER_EMAIL_NOTIFICATION.FAILURE, {
      payload: { error }
    })
};

export const createTaskStatus = {
  request: () => action(constants.CREATE_TASK_STATUS.REQUEST),
  success: (response) =>
    action(constants.CREATE_TASK_STATUS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.CREATE_TASK_STATUS.FAILURE, { payload: { error } })
};
export const updateTaskStatus = {
  request: () => action(constants.UPDATE_TASK_STATUS.REQUEST),
  success: (response) =>
    action(constants.UPDATE_TASK_STATUS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_TASK_STATUS.FAILURE, { payload: { error } })
};
export const deleteTaskStatus = {
  request: () => action(constants.DELETE_TASK_STATUS.REQUEST),
  success: (response) =>
    action(constants.DELETE_TASK_STATUS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_TASK_STATUS.FAILURE, { payload: { error } })
};

export const fetchTaskPriorities = {
  request: () => action(constants.FETCH_TASK_PRIORITIES.REQUEST),
  success: (response) =>
    action(constants.FETCH_TASK_PRIORITIES.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_TASK_PRIORITIES.FAILURE, { payload: { error } })
};
// below task priority code is defunct, remove
export const createTaskPriority = {
  request: () => action(constants.CREATE_TASK_PRIORITY.REQUEST),
  success: (response) =>
    action(constants.CREATE_TASK_PRIORITY.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.CREATE_TASK_PRIORITY.FAILURE, { payload: { error } })
};
export const updateTaskPriority = {
  request: () => action(constants.UPDATE_TASK_PRIORITY.REQUEST),
  success: (response) =>
    action(constants.UPDATE_TASK_PRIORITY.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_TASK_PRIORITY.FAILURE, { payload: { error } })
};
export const deleteTaskPriority = {
  request: () => action(constants.DELETE_TASK_PRIORITY.REQUEST),
  success: (response) =>
    action(constants.DELETE_TASK_PRIORITY.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_TASK_PRIORITY.FAILURE, { payload: { error } })
};

export const createProjectStage = {
  request: () => action(constants.CREATE_PROJECT_STAGE.REQUEST),
  success: (response) =>
    action(constants.CREATE_PROJECT_STAGE.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.CREATE_PROJECT_STAGE.FAILURE, { payload: { error } })
};
export const updateProjectStage = {
  request: () => action(constants.UPDATE_PROJECT_STAGE.REQUEST),
  success: (response) =>
    action(constants.UPDATE_PROJECT_STAGE.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_PROJECT_STAGE.FAILURE, { payload: { error } })
};
export const deleteProjectStage = {
  request: () => action(constants.DELETE_PROJECT_STAGE.REQUEST),
  success: (response) =>
    action(constants.DELETE_PROJECT_STAGE.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_PROJECT_STAGE.FAILURE, { payload: { error } })
};

export const createProjectStatus = {
  request: () => action(constants.CREATE_PROJECT_STATUS.REQUEST),
  success: (response) =>
    action(constants.CREATE_PROJECT_STATUS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.CREATE_PROJECT_STATUS.FAILURE, { payload: { error } })
};
export const updateProjectStatus = {
  request: () => action(constants.UPDATE_PROJECT_STATUS.REQUEST),
  success: (response) =>
    action(constants.UPDATE_PROJECT_STATUS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_PROJECT_STATUS.FAILURE, { payload: { error } })
};
export const deleteProjectStatus = {
  request: () => action(constants.DELETE_PROJECT_STATUS.REQUEST),
  success: (response) =>
    action(constants.DELETE_PROJECT_STATUS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_PROJECT_STATUS.FAILURE, { payload: { error } })
};

export const createProjectPriority = {
  request: () => action(constants.CREATE_PROJECT_PRIORITY.REQUEST),
  success: (response) =>
    action(constants.CREATE_PROJECT_PRIORITY.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.CREATE_PROJECT_PRIORITY.FAILURE, { payload: { error } })
};
export const updateProjectPriority = {
  request: () => action(constants.UPDATE_PROJECT_PRIORITY.REQUEST),
  success: (response) =>
    action(constants.UPDATE_PROJECT_PRIORITY.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_PROJECT_PRIORITY.FAILURE, { payload: { error } })
};
export const deleteProjectPriority = {
  request: () => action(constants.DELETE_PROJECT_PRIORITY.REQUEST),
  success: (response) =>
    action(constants.DELETE_PROJECT_PRIORITY.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_PROJECT_PRIORITY.FAILURE, { payload: { error } })
};

export const deleteMemberFromProject = {
  request: () => action(constants.DELETE_MEMBER_FROM_PROJECT.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_MEMBER_FROM_PROJECT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_MEMBER_FROM_GROUP.FAILURE, { payload: { error } })
};

/* status */

export const statusCreation = {
  request: () => action(constants.CREATE_PROJECT_COMMENT.REQUEST),
  success: (response) =>
    action(constants.CREATE_PROJECT_COMMENT.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.CREATE_PROJECT_COMMENT.FAILURE, { payload: { error } })
};
export const statusEdit = {
  request: () => action(constants.EDIT_PROJECT_COMMENT.REQUEST),
  success: (response) =>
    action(constants.EDIT_PROJECT_COMMENT.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.EDIT_PROJECT_COMMENT.FAILURE, { payload: { error } })
};
export const statusDelete = {
  request: () => action(constants.DELETE_PROJECT_COMMENT.REQUEST),
  success: (response) =>
    action(constants.DELETE_PROJECT_COMMENT.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.DELETE_PROJECT_COMMENT.FAILURE, { payload: { error } })
};

/* tasks */

export const fetchTask = {
  request: () => action(constants.FETCH_TASK.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TASK.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TASK.FAILURE, { payload: { error } })
};

export const taskUpdatePosition = {
  request: () => action(constants.MOVE_TASK.REQUEST),
  success: (response) =>
    action(constants.MOVE_TASK.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.MOVE_TASK.FAILURE, { payload: { error } })
};

export const fetchTaskGroups = {
  request: () => action(constants.FETCH_TASK_GROUPS.REQUEST),
  success: (response, id, initialActionPayload) =>
    action(constants.FETCH_TASK_GROUPS.SUCCESS, {
      payload: { response, initialActionPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TASK_GROUPS.FAILURE, { payload: { error } })
};
export const fetchProjectTaskGroups = {
  request: () => action(constants.FETCH_PROJECT_TASK_GROUPS.REQUEST),
  success: (response, projectId, initialActionPayload) =>
    action(constants.FETCH_PROJECT_TASK_GROUPS.SUCCESS, {
      payload: { response, projectId, initialActionPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_TASK_GROUPS.FAILURE, { payload: { error } })
};
export const createProjectTaskGroup = {
  request: () => action(constants.CREATE_PROJECT_TASK_GROUP.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_PROJECT_TASK_GROUP.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_PROJECT_TASK_GROUP.FAILURE, { payload: { error } })
};
export const updateProjectTaskGroup = {
  request: () => action(constants.UPDATE_PROJECT_TASK_GROUP.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_PROJECT_TASK_GROUP.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_PROJECT_TASK_GROUP.FAILURE, { payload: { error } })
};
export const deleteProjectTaskGroup = {
  request: () => action(constants.DELETE_PROJECT_TASK_GROUP.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_PROJECT_TASK_GROUP.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_PROJECT_TASK_GROUP.FAILURE, { payload: { error } })
};
export const reorderProjectTaskGroups = {
  request: () => action(constants.REORDER_PROJECT_TASK_GROUPS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.REORDER_PROJECT_TASK_GROUPS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.REORDER_PROJECT_TASK_GROUPS.FAILURE, {
      payload: { error }
    })
};
/* comments */

export const commentsAndMetadataFetch = {
  request: () => action(constants.FETCH_COMMENTS_AND_METADATA.REQUEST),
  success: (response) =>
    action(constants.FETCH_COMMENTS_AND_METADATA.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_COMMENTS_AND_METADATA.FAILURE, {
      payload: { error }
    })
};

export const commentCreate = {
  request: () => action(constants.CREATE_COMMENT.REQUEST),
  success: (response) =>
    action(constants.CREATE_COMMENT.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.CREATE_COMMENT.FAILURE, { payload: { error } })
};

export const commentUpdate = {
  request: () => action(constants.UPDATE_COMMENT.REQUEST),
  success: (response) =>
    action(constants.UPDATE_COMMENT.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.UPDATE_COMMENT.FAILURE, { payload: { error } })
};

export const commentDelete = {
  request: () => action(constants.DELETE_COMMENT.REQUEST),
  success: (response) =>
    action(constants.DELETE_COMMENT.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.DELETE_COMMENT.FAILURE, { payload: { error } })
};

export const taskAttachmentDelete = {
  request: () => action(constants.DELETE_TASK_ATTACHMENT.REQUEST),
  success: (response) =>
    action(constants.DELETE_TASK_ATTACHMENT.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.DELETE_TASK_ATTACHMENT.FAILURE, { payload: { error } })
};

export const projectAttachmentDelete = {
  request: () => action(constants.DELETE_PROJECT_ATTACHMENT.REQUEST),
  success: (response) =>
    action(constants.DELETE_PROJECT_ATTACHMENT.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_PROJECT_ATTACHMENT.FAILURE, { payload: { error } })
};

export const fetchEntityComments = {
  request: () => action(constants.FETCH_ENTITY_COMMENTS.REQUEST),
  success: (response) =>
    action(constants.FETCH_ENTITY_COMMENTS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_ENTITY_COMMENTS.FAILURE, { payload: { error } })
};
export const createEntityComment = {
  request: () => action(constants.CREATE_ENTITY_COMMENT.REQUEST),
  success: (response) =>
    action(constants.CREATE_ENTITY_COMMENT.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.CREATE_ENTITY_COMMENT.FAILURE, { payload: { error } })
};
export const updateEntityComment = {
  request: () => action(constants.UPDATE_ENTITY_COMMENT.REQUEST),
  success: (response) =>
    action(constants.UPDATE_ENTITY_COMMENT.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_ENTITY_COMMENT.FAILURE, { payload: { error } })
};
export const deleteEntityComment = {
  request: () => action(constants.DELETE_ENTITY_COMMENT.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_ENTITY_COMMENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_ENTITY_COMMENT.FAILURE, { payload: { error } })
};

export const bulkCheckinCreate = {
  request: () => action(constants.BULK_CREATE_CHECKIN.REQUEST),
  success: (response) =>
    action(constants.BULK_CREATE_CHECKIN.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.BULK_CREATE_CHECKIN.FAILURE, {
      payload: {
        error
      }
    })
};

export const checkinCreate = {
  request: () => action(constants.CREATE_CHECKIN.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_CHECKIN.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_CHECKIN.FAILURE, {
      payload: {
        error
      }
    })
};

export const checkinDelete = {
  request: () => action(constants.DELETE_CHECKIN.REQUEST),
  success: (response) =>
    action(constants.DELETE_CHECKIN.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_CHECKIN.FAILURE, {
      payload: {
        error
      }
    })
};

export const checkinUpdate = {
  request: () => action(constants.UPDATE_CHECKIN.REQUEST),
  success: (response) =>
    action(constants.UPDATE_CHECKIN.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_CHECKIN.FAILURE, {
      payload: {
        error
      }
    })
};

export const checkinsFetch = {
  request: () => action(constants.FETCH_CHECKINS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_CHECKINS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_CHECKINS.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const checkinsHoursFetch = {
  request: () => action(constants.FETCH_CHECKINS_HOURS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_CHECKINS_HOURS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_CHECKINS_HOURS.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const timersFetch = {
  request: () => action(constants.FETCH_TIMERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TIMERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_TIMERS.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const activeTimerFetch = {
  request: () => action(constants.FETCH_ACTIVE_TIMER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ACTIVE_TIMER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_ACTIVE_TIMER.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const timerStart = {
  request: () => action(constants.START_TIMER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.START_TIMER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.START_TIMER.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const timerEnd = {
  request: () => action(constants.END_TIMER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.END_TIMER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.END_TIMER.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const timerUpdate = {
  request: () => action(constants.UPDATE_TIMER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_TIMER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_TIMER.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const timerDelete = {
  request: () => action(constants.DELETE_TIMER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_TIMER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.DELETE_TIMER.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const taskAssociate = {
  request: () => action(constants.ASSOCIATE_TASK.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ASSOCIATE_TASK.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.ASSOCIATE_TASK.FAILURE, {
      payload: { error, requestPayload }
    })
};

// notifications
export const notificationsFetch = {
  request: () => action(constants.FETCH_NOTIFICATIONS.REQUEST),
  success: (response) =>
    action(constants.FETCH_NOTIFICATIONS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_NOTIFICATIONS.FAILURE, {
      payload: {
        error
      }
    })
};

export const notificationsCountFetch = {
  request: () => action(constants.FETCH_NOTIFICATIONS_COUNT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_NOTIFICATIONS_COUNT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_NOTIFICATIONS_COUNT.FAILURE, {
      payload: {
        error,
        requestPayload
      }
    })
};

export const notificationUpdate = {
  request: () => action(constants.UPDATE_NOTIFICATION_AS_READ.REQUEST),
  success: (response) =>
    action(constants.UPDATE_NOTIFICATION_AS_READ.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_NOTIFICATION_AS_READ.FAILURE, {
      payload: {
        error
      }
    })
};

export const readAllNotifications = {
  request: () => action(constants.MARK_ALL_NOTIFICATIONS_READ.REQUEST),
  success: (response) =>
    action(constants.MARK_ALL_NOTIFICATIONS_READ.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.MARK_ALL_NOTIFICATIONS_READ.FAILURE, {
      payload: {
        error
      }
    })
};

export const notificationsUpdate = {
  request: () => action(constants.UPDATE_NOTIFICATIONS_AS_OLD.REQUEST),
  success: (response) =>
    action(constants.UPDATE_NOTIFICATIONS_AS_OLD.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_NOTIFICATIONS_AS_OLD.FAILURE, {
      payload: {
        error
      }
    })
};

/* notes in NoteModule */

/* tags */

export const fetchNoteTags = {
  request: () => action(constants.FETCH_NOTE_TAGS_BY_BOARD.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_NOTE_TAGS_BY_BOARD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_NOTE_TAGS_BY_BOARD.FAILURE, { payload: { error } })
};

export const updateTag = {
  request: () => action(constants.UPDATE_TAG.REQUEST),
  success: (response) =>
    action(constants.UPDATE_TAG.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_TAG.FAILURE, { payload: { error } })
};

/* settings */
export const profileUpdate = {
  request: () => action(constants.PROFILE_UPDATE.REQUEST),
  success: (response) =>
    action(constants.PROFILE_UPDATE.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.PROFILE_UPDATE.FAILURE, { payload: { error } })
};

export const passwordSettingsUpdate = {
  request: () => action(constants.PASSWORD_SETTINGS_UPDATE.REQUEST),
  success: (response) =>
    action(constants.PASSWORD_SETTINGS_UPDATE.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.PASSWORD_SETTINGS_UPDATE.FAILURE, { payload: { error } })
};

export const toggleEmailNotification = {
  request: () => action(constants.TOGGLE_EMAIL_NOTIFICATION.REQUEST),
  success: (response) =>
    action(constants.TOGGLE_EMAIL_NOTIFICATION.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.TOGGLE_EMAIL_NOTIFICATION.FAILURE, { payload: { error } })
};

export const passwordUpdate = {
  request: () => action(constants.PASSWORD_UPDATE.REQUEST),
  success: (response) =>
    action(constants.PASSWORD_UPDATE.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.PASSWORD_UPDATE.FAILURE, { payload: { error } })
};

export const companyUpdate = {
  request: () => action(constants.TEAM_UPDATE.REQUEST),
  success: (response) =>
    action(constants.TEAM_UPDATE.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.TEAM_UPDATE.FAILURE, { payload: { error } })
};

export const updateTeamOrderedItems = {
  request: () => action(constants.UPDATE_TEAM_ORDERED_ITEMS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_TEAM_ORDERED_ITEMS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_ORDERED_ITEMS.FAILURE, { payload: { error } })
};
export const updateTeamViewSettings = {
  request: () => action(constants.UPDATE_TEAM_VIEW_SETTINGS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_TEAM_VIEW_SETTINGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_TEAM_VIEW_SETTINGS.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const myProjectsStateUpdate = {
  request: () => action(constants.UPDATE_SIDEBAR_PROJECTS_PINNED_STATE.REQUEST),
  success: (response) =>
    action(constants.UPDATE_SIDEBAR_PROJECTS_PINNED_STATE.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_SIDEBAR_PROJECTS_PINNED_STATE.FAILURE, {
      payload: { error }
    })
};

/* BILLING */

export const subscriptionFetch = {
  request: () => action(constants.SUBSCRIPTION_FETCH.REQUEST),
  success: (response) =>
    action(constants.SUBSCRIPTION_FETCH.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.SUBSCRIPTION_FETCH.FAILURE, { payload: { error } })
};

export const subscriptionCancellation = {
  request: () => action(constants.SUBSCRIPTION_CANCELLATION.REQUEST),
  success: (response) =>
    action(constants.SUBSCRIPTION_CANCELLATION.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.SUBSCRIPTION_CANCELLATION.FAILURE, { payload: { error } })
};

/* home */

export const fetchMyProjects = {
  request: () => action(constants.FETCH_MY_PROJECTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MY_PROJECTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MY_PROJECTS.FAILURE, { payload: { error } })
};

export const fetchProjectClients = {
  request: () => action(constants.FETCH_PROJECT_CLIENTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECT_CLIENTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_CLIENTS.FAILURE, { payload: { error } })
};
export const fetchAllProjects = {
  request: () => action(constants.FETCH_ALL_PROJECTS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_ALL_PROJECTS.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_ALL_PROJECTS.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};
export const fetchProjectsIndexV2 = {
  request: () => action(constants.FETCH_PROJECTS_INDEX_V2.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_PROJECTS_INDEX_V2.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_PROJECTS_INDEX_V2.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};

export const myProjectUpdate = {
  request: () => action(constants.MY_PROJECT_UPDATE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.MY_PROJECT_UPDATE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.MY_PROJECT_UPDATE.FAILURE, { payload: { error } })
};

export const projectStarUpdate = {
  request: () => action(constants.STAR_PROJECT.REQUEST),
  success: (response) =>
    action(constants.STAR_PROJECT.SUCCESS, { payload: { response } }),
  failure: (error) =>
    action(constants.STAR_PROJECT.FAILURE, { payload: { error } })
};

export const notificationPreferencesUpdate = {
  request: () => action(constants.UPDATE_NOTIFICATION_PREFERENCES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_NOTIFICATION_PREFERENCES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_NOTIFICATION_PREFERENCES.FAILURE, {
      payload: { error }
    })
};

/* milestones */

export const inviteResend = {
  request: () => action(constants.RESEND_INVITE.REQUEST),
  success: (response) =>
    action(constants.RESEND_INVITE.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.RESEND_INVITE.FAILURE, { payload: { error } })
};

export const memberProfileFetch = {
  request: () => action(constants.FETCH_TEAM_MEMBER_PROFILE.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_MEMBER_PROFILE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_MEMBER_PROFILE.FAILURE, { payload: { error } })
};

export const fetchTeamMemberDetail = {
  request: () => action(constants.FETCH_TEAM_MEMBER_DETAIL.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TEAM_MEMBER_DETAIL.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_MEMBER_DETAIL.FAILURE, { payload: { error } })
};

export const fetchDayPlanner = {
  request: () => action(constants.FETCH_DAY_PLANNER.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_DAY_PLANNER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_DAY_PLANNER.FAILURE, { payload: { error } })
};

export const updateDayPlanner = {
  request: () => action(constants.UPDATE_DAY_PLANNER.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_DAY_PLANNER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_DAY_PLANNER.FAILURE, { payload: { error } })
};

export const fetchPlannersAction = {
  request: () => action(constants.FETCH_PLANNERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PLANNERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PLANNERS.FAILURE, { payload: { error } })
};

export const projectPlannerFetch = {
  request: () => action(constants.FETCH_WORKLOAD_PLANNER.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_WORKLOAD_PLANNER.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_WORKLOAD_PLANNER.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};

export const projectPlannerSplit = {
  request: () => action(constants.SPLIT_WORKLOAD_PLANNER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.SPLIT_WORKLOAD_PLANNER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.SPLIT_WORKLOAD_PLANNER.FAILURE, { payload: { error } })
};

export const projectPlannerUpdate = {
  request: () => action(constants.UPDATE_WORKLOAD_PLANNER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_WORKLOAD_PLANNER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_WORKLOAD_PLANNER.FAILURE, { payload: { error } })
};
export const projectPlannerCreate = {
  request: () => action(constants.CREATE_WORKLOAD_PLANNER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_WORKLOAD_PLANNER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_WORKLOAD_PLANNER.FAILURE, { payload: { error } })
};
export const workdaysPredict = {
  request: () => action(constants.PREDICT_WORKDAYS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.PREDICT_WORKDAYS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.PREDICT_WORKDAYS.FAILURE, { payload: { error } })
};
export const projectPlannerPredict = {
  request: () => action(constants.PREDICT_WORKLOAD_PLANNER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.PREDICT_WORKLOAD_PLANNER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.PREDICT_WORKLOAD_PLANNER.FAILURE, { payload: { error } })
};
export const projectPlannerDelete = {
  request: () => action(constants.DELETE_WORKLOAD_PLANNER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_WORKLOAD_PLANNER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_WORKLOAD_PLANNER.FAILURE, { payload: { error } })
};

export const projectPlannerEventsFetch = {
  request: () => action(constants.FETCH_WORKLOAD_PLANNER_EVENTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_WORKLOAD_PLANNER_EVENTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_WORKLOAD_PLANNER_EVENTS.FAILURE, {
      payload: { error }
    })
};
export const projectPlannerEventFetch = {
  request: () => action(constants.FETCH_WORKLOAD_PLANNER_EVENT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_WORKLOAD_PLANNER_EVENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_WORKLOAD_PLANNER_EVENT.FAILURE, {
      payload: { error }
    })
};

export const projectPlannerEventUpdate = {
  request: () => action(constants.UPDATE_WORKLOAD_PLANNER_EVENT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_WORKLOAD_PLANNER_EVENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_WORKLOAD_PLANNER_EVENT.FAILURE, {
      payload: { error }
    })
};
export const projectPlannerEventCreate = {
  request: () => action(constants.CREATE_WORKLOAD_PLANNER_EVENT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_WORKLOAD_PLANNER_EVENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_WORKLOAD_PLANNER_EVENT.FAILURE, {
      payload: { error }
    })
};
export const projectPlannerNotificationCreate = {
  request: () => action(constants.CREATE_NOTIFICATION_PREVIEW.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_NOTIFICATION_PREVIEW.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_NOTIFICATION_PREVIEW.FAILURE, {
      payload: { error }
    })
};
export const projectPlannerEventSend = {
  request: () => action(constants.SEND_WORKLOAD_PLANNER_EVENT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.SEND_WORKLOAD_PLANNER_EVENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.SEND_WORKLOAD_PLANNER_EVENT.FAILURE, {
      payload: { error }
    })
};

export const projectPlannerEventDelete = {
  request: () => action(constants.DELETE_WORKLOAD_PLANNER_EVENT.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_WORKLOAD_PLANNER_EVENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_WORKLOAD_PLANNER_EVENT.FAILURE, {
      payload: { error }
    })
};

export const projectPlannerEventLastSentFetch = {
  request: () =>
    action(constants.FETCH_WORKLOAD_PLANNER_EVENT_LAST_SENT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_WORKLOAD_PLANNER_EVENT_LAST_SENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_WORKLOAD_PLANNER_EVENT_LAST_SENT.FAILURE, {
      payload: { error }
    })
};

export const projectPlannerMembersUpdate = {
  request: () => action(constants.UPDATE_WORKLOAD_PLANNER_MEMBERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_WORKLOAD_PLANNER_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_WORKLOAD_PLANNER_MEMBERS.FAILURE, {
      payload: { error }
    })
};

export const approveWorkplanRequests = {
  request: () => action(constants.APPROVE_WORKPLAN_REQUESTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.APPROVE_WORKPLAN_REQUESTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.APPROVE_WORKPLAN_REQUESTS.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const memberProjectsFetch = {
  request: () => action(constants.FETCH_MEMBER_PROJECTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_PROJECTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_MEMBER_PROJECTS.FAILURE, {
      payload: { error }
    })
};

export const tasksBulkEdit = {
  request: () => action(constants.BULK_EDIT_TASKS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.BULK_EDIT_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BULK_EDIT_TASKS.FAILURE, {
      payload: { error }
    })
};
export const homeTasksBulkEdit = {
  request: () => action(constants.BULK_EDIT_HOME_TASKS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.BULK_EDIT_HOME_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BULK_EDIT_HOME_TASKS.FAILURE, {
      payload: { error }
    })
};

export const batchRequestTasksFollowUp = {
  request: () => action(constants.BATCH_REQUEST_TASKS_FOLLOW_UP.REQUEST),
  success: (response) =>
    action(constants.BATCH_REQUEST_TASKS_FOLLOW_UP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.BATCH_REQUEST_TASKS_FOLLOW_UP.FAILURE, {
      payload: { error }
    })
};

// BATCH ACTIONS
export const batchMoveTasksToProject = {
  request: () => action(constants.BATCH_MOVE_TASKS_TO_PROJECT.REQUEST),
  success: (response, requestPayload) =>
    action(constants.BATCH_MOVE_TASKS_TO_PROJECT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BATCH_MOVE_TASKS_TO_PROJECT.FAILURE, {
      payload: { error }
    })
};

export const batchCopyTasksToProject = {
  request: (sagaPayload) => {
    if (sagaPayload.shouldAddTaskToView) {
      return action(constants.BATCH_COPY_TASKS_TO_PROJECT.REQUEST, {
        payload: { body: sagaPayload.body, uuids: sagaPayload.uuids }
      });
    } else {
      return action('IGNORE_TASK_COPY');
    }
  },
  success: (response, body, sagaPayload) => {
    if (sagaPayload.shouldAddTaskToView) {
      return action(constants.BATCH_COPY_TASKS_TO_PROJECT.SUCCESS, {
        payload: { response, body: sagaPayload.body }
      });
    } else {
      return action('IGNORE_TASK_COPY');
    }
  },
  failure: (error) =>
    action(constants.BATCH_COPY_TASKS_TO_PROJECT.FAILURE, {
      payload: { error }
    })
};

export const batchDeleteTasks = {
  request: () => action(constants.BATCH_DELETE_TASKS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.BATCH_DELETE_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BATCH_DELETE_TASKS.FAILURE, { payload: { error } })
};

export const batchCompleteTasks = {
  request: (sagaPayload) =>
    action(constants.BATCH_COMPLETE_TASKS.REQUEST, {
      payload: sagaPayload
    }),
  success: (response, requestPayload) =>
    action(constants.BATCH_COMPLETE_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BATCH_COMPLETE_TASKS.FAILURE, { payload: { error } })
};

export const batchRevertTasksToIncomplete = {
  request: () => action(constants.BATCH_REVERT_TASKS_TO_INCOMPLETE.REQUEST),
  success: (response) =>
    action(constants.BATCH_REVERT_TASKS_TO_INCOMPLETE.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.BATCH_REVERT_TASKS_TO_INCOMPLETE.FAILURE, {
      payload: { error }
    })
};

export const batchAcceptInboxTasks = {
  request: () => action(constants.BATCH_MOVE_INBOX_TASKS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.BATCH_MOVE_INBOX_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BATCH_MOVE_INBOX_TASKS.FAILURE, { payload: { error } })
};

export const batchUnfollowTasks = {
  request: () => action(constants.BATCH_UNFOLLOW_TASKS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.BATCH_UNFOLLOW_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BATCH_UNFOLLOW_TASKS.FAILURE, { payload: { error } })
};

// CRUD
export const updateTasksAttributesEntityActions = {
  request: () => action(constants.UPDATE_TASKS_ATTRIBUTES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_TASKS_ATTRIBUTES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TASKS_ATTRIBUTES.FAILURE, {
      payload: { error }
    })
};

export const createTaskEntityAction = {
  request: ({ shouldUpdateTaskList, ...sagaPayload }) => {
    if (shouldUpdateTaskList) {
      return action(constants.CREATE_TASK_FROM_HOME.REQUEST, {
        payload: sagaPayload
      });
    }
    return action('IGNORE_TASK_CREATION');
  },
  success: (response, requestPayload) =>
    action(constants.CREATE_TASK_FROM_HOME.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_TASK_FROM_HOME.FAILURE, {
      payload: { error }
    })
};
export const moveTasksEntityAction = {
  request: (payload) =>
    action(constants.MOVE_TASKS.REQUEST, {
      payload
    }),
  success: (response, requestPayload) =>
    action(constants.MOVE_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.MOVE_TASKS.FAILURE, {
      payload: { error }
    })
};
export const copyTasksEntityAction = {
  request: (payload) =>
    action(constants.COPY_TASKS.REQUEST, {
      payload
    }),
  success: (response, requestPayload) =>
    action(constants.COPY_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.COPY_TASKS.FAILURE, {
      payload: { error }
    })
};

export const queryAIEntityAction = {
  request: () => action(constants.QUERY_AI.REQUEST),
  success: (response, requestPayload) =>
    action(constants.QUERY_AI.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.QUERY_AI.FAILURE, {
      payload: { error }
    })
};

export const fetchTaskListAdditionalDataLoad = {
  request: () => action(constants.TASK_LIST_LOADING_ADDITIONAL_TASKS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.TASK_LIST_LOADING_ADDITIONAL_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.TASK_LIST_LOADING_ADDITIONAL_TASKS.FAILURE, {
      payload: { error }
    })
};

export const fetchAllHomeTags = {
  request: () => action(constants.FETCH_ALL_HOME_TASK_TAGS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_ALL_HOME_TASK_TAGS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ALL_HOME_TASK_TAGS.FAILURE, { payload: { error } })
};

export const createTaskCommentEntityAction = {
  request: () => action(constants.POST_TASK_COMMENT.REQUEST),
  success: (response, requestPayload) =>
    action(constants.POST_TASK_COMMENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.POST_TASK_COMMENT.FAILURE, { payload: { error } })
};

export const updateAccountDefaultMessage = {
  request: () =>
    action(constants.PATCH_ACCOUNT_DEFAULT_FOLLOW_UP_MESSAGE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.PATCH_ACCOUNT_DEFAULT_FOLLOW_UP_MESSAGE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.PATCH_ACCOUNT_DEFAULT_FOLLOW_UP_MESSAGE.FAILURE, {
      payload: { error }
    })
};
export const activitiesFetch = {
  request: () => action(constants.FETCH_ACTIVITIES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ACTIVITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ACTIVITIES.FAILURE, {
      payload: { error }
    })
};
export const descriptionsFetch = {
  request: () => action(constants.FETCH_DESCRIPTIONS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_DESCRIPTIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_DESCRIPTIONS.FAILURE, {
      payload: { error }
    })
};
export const filteredActivitiesPerProjectFetch = {
  request: () =>
    action(constants.FETCH_FILTERED_USER_ACTIVITIES_FOR_PROJECTS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_FILTERED_USER_ACTIVITIES_FOR_PROJECTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_FILTERED_USER_ACTIVITIES_FOR_PROJECTS.FAILURE, {
      payload: { error }
    })
};
export const timesheetsFetch = {
  request: () => action(constants.FETCH_TIMESHEETS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TIMESHEETS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_TIMESHEETS.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const timesheetsIndexFetch = {
  request: () => action(constants.FETCH_TIMESHEETS_INDEX.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TIMESHEETS_INDEX.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TIMESHEETS_INDEX.FAILURE, {
      payload: { error }
    })
};
export const timesheetsPredictionsFetch = {
  request: () => action(constants.FETCH_TIMESHEETS_PREDICTIONS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TIMESHEETS_PREDICTIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TIMESHEETS_PREDICTIONS.FAILURE, {
      payload: { error }
    })
};
export const fetchTimesheetsUtilization = {
  request: () => action(constants.FETCH_TIMESHEETS_UTILIZATION.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TIMESHEETS_UTILIZATION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_TIMESHEETS_UTILIZATION.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const processTimeEntriesForQuickbooks = {
  request: () => action(constants.PROCESS_TIME_ENTRIES_FOR_QUICKBOOKS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.PROCESS_TIME_ENTRIES_FOR_QUICKBOOKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.PROCESS_TIME_ENTRIES_FOR_QUICKBOOKS.FAILURE, {
      payload: { error }
    })
};
export const exportingTimesheets = {
  request: () => action(constants.EXPORT_TIMESHEETS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.EXPORT_TIMESHEETS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.EXPORT_TIMESHEETS.FAILURE, {
      payload: { error }
    })
};

export const userActivitiesFetch = {
  request: () => action(constants.FETCH_USER_ACTIVITIES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_USER_ACTIVITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_USER_ACTIVITIES.FAILURE, {
      payload: { error }
    })
};

export const activityCreate = {
  request: () => action(constants.CREATE_ACTIVITY.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_ACTIVITY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_ACTIVITY.FAILURE, {
      payload: { error }
    })
};

export const activityUpdate = {
  request: () => action(constants.UPDATE_ACTIVITY.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_ACTIVITY.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_ACTIVITY.FAILURE, {
      payload: { error }
    })
};

export const fetchActivityMembers = {
  request: () => action(constants.FETCH_ACTIVITY_MEMBERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ACTIVITY_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ACTIVITY_MEMBERS.FAILURE, { payload: { error } })
};

export const createActivityMembers = {
  request: () => action(constants.CREATE_ACTIVITY_MEMBERS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_ACTIVITY_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_ACTIVITY_MEMBERS.FAILURE, { payload: { error } })
};

export const archiveActivityMember = {
  request: () => action(constants.ARCHIVE_ACTIVITY_MEMBER.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ARCHIVE_ACTIVITY_MEMBER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_ACTIVITY_MEMBER.FAILURE, { payload: { error } })
};

export const descriptionCreate = {
  request: () => action(constants.CREATE_DESCRIPTION.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_DESCRIPTION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_DESCRIPTION.FAILURE, {
      payload: { error }
    })
};

export const descriptionUpdate = {
  request: () => action(constants.UPDATE_DESCRIPTION.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_DESCRIPTION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_DESCRIPTION.FAILURE, {
      payload: { error }
    })
};

export const descriptionsDelete = {
  request: () => action(constants.DELETE_DESCRIPTIONS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_DESCRIPTIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_DESCRIPTIONS.FAILURE, {
      payload: { error }
    })
};

export const timesheetCreate = {
  request: () => action(constants.CREATE_TIMESHEET.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_TIMESHEET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_TIMESHEET.FAILURE, {
      payload: { error }
    })
};
export const timesheetQBCreate = {
  request: () => action(constants.CREATE_QB_TIMESHEET.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_QB_TIMESHEET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_QB_TIMESHEET.FAILURE, {
      payload: { error }
    })
};
export const timesheetUpdate = {
  request: () => action(constants.UPDATE_TIMESHEET.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_TIMESHEET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TIMESHEET.FAILURE, {
      payload: { error }
    })
};
export const timesheetBulkUpdate = {
  request: () => action(constants.BULK_UPDATE_TIMESHEETS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.BULK_UPDATE_TIMESHEETS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.BULK_UPDATE_TIMESHEETS.FAILURE, {
      payload: { error }
    })
};
export const timesheetQBUpdate = {
  request: () => action(constants.UPDATE_QB_TIMESHEET.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_QB_TIMESHEET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_QB_TIMESHEET.FAILURE, {
      payload: { error }
    })
};
export const timesheetsDelete = {
  request: () => action(constants.DELETE_TIMESHEETS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_TIMESHEETS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_TIMESHEETS.FAILURE, {
      payload: { error }
    })
};
export const timesheetQBDelete = {
  request: () => action(constants.DELETE_QB_TIMESHEET.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_QB_TIMESHEET.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_QB_TIMESHEET.FAILURE, {
      payload: { error }
    })
};

export const projectColorPreferencesFetch = {
  request: () => action(constants.FETCH_PROJECT_COLOR_PREFERENCES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_PROJECT_COLOR_PREFERENCES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_COLOR_PREFERENCES.FAILURE, {
      payload: { error }
    })
};

export const projectColorPreferenceUpdate = {
  request: () => action(constants.UPDATE_PROJECT_COLOR_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_PROJECT_COLOR_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_PROJECT_COLOR_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const projectColorPreferenceCreate = {
  request: () => action(constants.CREATE_PROJECT_COLOR_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_PROJECT_COLOR_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_PROJECT_COLOR_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const projectColorPreferenceDelete = {
  request: () => action(constants.DELETE_PROJECT_COLOR_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_PROJECT_COLOR_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_PROJECT_COLOR_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const boardPreferencesFetch = {
  request: () => action(constants.FETCH_BOARD_PREFERENCES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_BOARD_PREFERENCES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_BOARD_PREFERENCES.FAILURE, {
      payload: { error }
    })
};

export const boardPreferenceUpdate = {
  request: () => action(constants.UPDATE_BOARD_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_BOARD_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_BOARD_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const boardPreferenceCreate = {
  request: () => action(constants.CREATE_BOARD_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_BOARD_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_BOARD_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const boardPreferenceDelete = {
  request: () => action(constants.DELETE_BOARD_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_BOARD_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_BOARD_PREFERENCE.FAILURE, {
      payload: { error }
    })
};
export const taskGroupPreferencesFetch = {
  request: () => action(constants.FETCH_TASK_GROUP_PREFERENCES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_TASK_GROUP_PREFERENCES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TASK_GROUP_PREFERENCES.FAILURE, {
      payload: { error }
    })
};

export const taskGroupPreferenceUpdate = {
  request: () => action(constants.UPDATE_TASK_GROUP_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_TASK_GROUP_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TASK_GROUP_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const taskGroupPreferenceCreate = {
  request: () => action(constants.CREATE_TASK_GROUP_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_TASK_GROUP_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_TASK_GROUP_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const taskGroupPreferenceDelete = {
  request: () => action(constants.DELETE_TASK_GROUP_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_TASK_GROUP_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_TASK_GROUP_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const teamMembershipPreferencesFetch = {
  request: () => action(constants.FETCH_TEAM_MEMBERSHIP_PREFERENCES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_TEAM_MEMBERSHIP_PREFERENCES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_MEMBERSHIP_PREFERENCES.FAILURE, {
      payload: { error }
    })
};

export const teamMembershipPreferenceUpdate = {
  request: () => action(constants.UPDATE_TEAM_MEMBERSHIP_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_TEAM_MEMBERSHIP_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_MEMBERSHIP_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const teamMembershipPreferenceCreate = {
  request: () => action(constants.CREATE_TEAM_MEMBERSHIP_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_TEAM_MEMBERSHIP_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_TEAM_MEMBERSHIP_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const teamMembershipPreferenceDelete = {
  request: () => action(constants.DELETE_TEAM_MEMBERSHIP_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_TEAM_MEMBERSHIP_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_TEAM_MEMBERSHIP_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const teamPreferencesFetch = {
  request: () => action(constants.FETCH_TEAM_PREFERENCES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_TEAM_PREFERENCES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TEAM_PREFERENCES.FAILURE, {
      payload: { error }
    })
};

export const teamPreferenceUpdate = {
  request: () => action(constants.UPDATE_TEAM_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_TEAM_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TEAM_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const teamPreferenceCreate = {
  request: () => action(constants.CREATE_TEAM_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_TEAM_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_TEAM_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const teamPreferenceDelete = {
  request: () => action(constants.DELETE_TEAM_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_TEAM_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_TEAM_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const tagPreferencesFetch = {
  request: () => action(constants.FETCH_TAG_PREFERENCES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_TAG_PREFERENCES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TAG_PREFERENCES.FAILURE, {
      payload: { error }
    })
};

export const tagPreferenceUpdate = {
  request: () => action(constants.UPDATE_TAG_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_TAG_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_TAG_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const tagPreferenceCreate = {
  request: () => action(constants.CREATE_TAG_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_TAG_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_TAG_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const tagPreferenceDelete = {
  request: () => action(constants.DELETE_TAG_PREFERENCE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_TAG_PREFERENCE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_TAG_PREFERENCE.FAILURE, {
      payload: { error }
    })
};

export const projectModulesUpdate = {
  request: () => action(constants.UPDATE_PROJECT_MODULES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_PROJECT_MODULES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_PROJECT_MODULES.FAILURE, {
      payload: { error }
    })
};

export const boardModulesUpdate = {
  request: () => action(constants.UPDATE_BOARD_MODULES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_BOARD_MODULES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_BOARD_MODULES.FAILURE, {
      payload: { error }
    })
};

export const userPermissionsFetch = {
  request: () => action(constants.FETCH_USER_PERMISSIONS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_USER_PERMISSIONS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_USER_PERMISSIONS.FAILURE, {
      payload: { error }
    })
};

export const accountFilterFetch = {
  request: () => action(constants.FETCH_ACCOUNT_FILTER.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_ACCOUNT_FILTER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ACCOUNT_FILTER.FAILURE, {
      payload: { error }
    })
};
export const accountFiltersFetch = {
  request: () => action(constants.FETCH_ACCOUNT_FILTERS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_ACCOUNT_FILTERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ACCOUNT_FILTERS.FAILURE, {
      payload: { error }
    })
};

export const accountFilterUpdate = {
  request: () => action(constants.UPDATE_ACCOUNT_FILTER.REQUEST),
  success: (response, _, requestPayload, meta) =>
    action(constants.UPDATE_ACCOUNT_FILTER.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.UPDATE_ACCOUNT_FILTER.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};

export const accountFilterCreate = {
  request: () => action(constants.CREATE_ACCOUNT_FILTER.REQUEST),
  success: (response, _, requestPayload, meta) =>
    action(constants.CREATE_ACCOUNT_FILTER.SUCCESS, {
      payload: { response, requestPayload },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.CREATE_ACCOUNT_FILTER.FAILURE, {
      payload: { error, requestPayload },
      meta
    })
};

export const accountFilterDelete = {
  request: () => action(constants.DELETE_ACCOUNT_FILTER.REQUEST),
  success: (response, requestPayload) =>
    action(constants.DELETE_ACCOUNT_FILTER.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.DELETE_ACCOUNT_FILTER.FAILURE, {
      payload: { error }
    })
};

export const fetchFilterByIds = {
  request: () => action(constants.FETCH_FILTER_BY_IDS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_FILTER_BY_IDS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_FILTER_BY_IDS.FAILURE, {
      payload: { error }
    })
};

export const fetchAccess = {
  request: () => action(constants.FETCH_ACCESS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ACCESS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_ACCESS.FAILURE, {
      payload: { error }
    })
};
export const updateAccess = {
  request: () => action(constants.UPDATE_ACCESS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_ACCESS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_ACCESS.FAILURE, {
      payload: { error }
    })
};
export const gettingTasks = {
  request: () => action(constants.FETCH_TASKS_V2.REQUEST),
  success: (response, _id, requestPayload) =>
    action(constants.FETCH_TASKS_V2.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, _response, requestPayload) =>
    action(constants.FETCH_TASKS_V2.FAILURE, {
      payload: { error, requestPayload }
    }),
  abort: (requestPayload) =>
    action(constants.FETCH_TASKS_V2.ABORT, {
      payload: { requestPayload }
    })
};
export const fetchTaskCounts = {
  request: () => action(constants.FETCH_TASK_COUNTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_TASK_COUNTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_TASK_COUNTS.FAILURE, {
      payload: { error }
    })
};
export const fetchMemberEndsBeforeTaskCount = {
  request: () => action(constants.FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const fetchMemberTodayTaskCount = {
  request: () => action(constants.FETCH_MEMBER_TODAY_TASK_COUNT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_TODAY_TASK_COUNT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_MEMBER_TODAY_TASK_COUNT.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const fetchMemberOverdueTaskCount = {
  request: () => action(constants.FETCH_MEMBER_OVERDUE_TASK_COUNT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_OVERDUE_TASK_COUNT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_MEMBER_OVERDUE_TASK_COUNT.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const fetchMemberScheduledTaskCount = {
  request: () => action(constants.FETCH_MEMBER_SCHEDULED_TASK_COUNT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_SCHEDULED_TASK_COUNT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_MEMBER_SCHEDULED_TASK_COUNT.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const fetchMemberUnscheduledTaskCount = {
  request: () => action(constants.FETCH_MEMBER_UNSCHEDULED_TASK_COUNT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_UNSCHEDULED_TASK_COUNT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_MEMBER_UNSCHEDULED_TASK_COUNT.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const fetchMemberCompletedTaskCount = {
  request: () => action(constants.FETCH_MEMBER_COMPLETED_TASK_COUNT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_MEMBER_COMPLETED_TASK_COUNT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_MEMBER_COMPLETED_TASK_COUNT.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const exportingTasks = {
  request: () => action(constants.EXPORT_TASKS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.EXPORT_TASKS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.EXPORT_TASKS.FAILURE, {
      payload: { error }
    })
};
export const generateReport = {
  request: () => action(constants.GENERATE_REPORT.REQUEST),
  success: (response, requestPayload) =>
    action(constants.GENERATE_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.GENERATE_REPORT.FAILURE, {
      payload: { error }
    })
};

export const checkHasTimeEntries = {
  request: () => action(constants.CHECK_HAS_TIME_ENTRIES.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CHECK_HAS_TIME_ENTRIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CHECK_HAS_TIME_ENTRIES.FAILURE, {
      payload: { error }
    })
};

export const fetchRemainingTimesheetHours = {
  request: () => action(constants.FETCH_REMAINING_TIMESHEET_HOURS.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_REMAINING_TIMESHEET_HOURS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_REMAINING_TIMESHEET_HOURS.FAILURE, {
      payload: { error }
    })
};

export const moveProjectToBoard = {
  request: () => action(constants.MOVE_PROJECTS_TO_BOARD.REQUEST),
  success: (response, requestPayload) =>
    action(constants.MOVE_PROJECTS_TO_BOARD.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.MOVE_PROJECTS_TO_BOARD.FAILURE, {
      payload: { error }
    })
};

export const cloneProject = {
  request: (payload) => action(constants.CLONE_PROJECT.REQUEST, payload),
  success: (response, requestPayload, sagaPayload) =>
    action(constants.CLONE_PROJECT.SUCCESS, {
      payload: { response, requestPayload, sagaPayload }
    }),
  failure: (error, response, sagaPayload) =>
    action(constants.CLONE_PROJECT.FAILURE, {
      payload: { error, sagaPayload }
    })
};

export const fetchWorkGroups = {
  request: () => action(constants.FETCH_WORK_GROUPS.REQUEST),
  success: (response) =>
    action(constants.FETCH_WORK_GROUPS.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_WORK_GROUPS.FAILURE, { payload: { error } })
};
export const fetchWorkGroup = {
  request: () => action(constants.FETCH_WORK_GROUP.REQUEST),
  success: (response) =>
    action(constants.FETCH_WORK_GROUP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_WORK_GROUP.FAILURE, { payload: { error } })
};

export const createWorkGroup = {
  request: () => action(constants.CREATE_WORK_GROUP.REQUEST),
  success: (response) =>
    action(constants.CREATE_WORK_GROUP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.CREATE_WORK_GROUP.FAILURE, { payload: { error } })
};
export const updateWorkGroup = {
  request: () => action(constants.UPDATE_WORK_GROUP.REQUEST),
  success: (response) =>
    action(constants.UPDATE_WORK_GROUP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UPDATE_WORK_GROUP.FAILURE, { payload: { error } })
};
export const archiveWorkGroup = {
  request: () => action(constants.ARCHIVE_WORK_GROUP.REQUEST),
  success: (response) =>
    action(constants.ARCHIVE_WORK_GROUP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.ARCHIVE_WORK_GROUP.FAILURE, { payload: { error } })
};
export const unarchiveWorkGroup = {
  request: () => action(constants.UNARCHIVE_WORK_GROUP.REQUEST),
  success: (response) =>
    action(constants.UNARCHIVE_WORK_GROUP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.UNARCHIVE_WORK_GROUP.FAILURE, { payload: { error } })
};
export const deleteWorkGroup = {
  request: () => action(constants.DELETE_WORK_GROUP.REQUEST),
  success: (response) =>
    action(constants.DELETE_WORK_GROUP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_WORK_GROUP.FAILURE, { payload: { error } })
};

export const createWorkGroupMembership = {
  request: () => action(constants.CREATE_WORK_GROUP_MEMBERSHIP.REQUEST),
  success: (response) =>
    action(constants.CREATE_WORK_GROUP_MEMBERSHIP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.CREATE_WORK_GROUP_MEMBERSHIP.FAILURE, {
      payload: { error }
    })
};
export const deleteWorkGroupMembership = {
  request: () => action(constants.DELETE_WORK_GROUP_MEMBERSHIP.REQUEST),
  success: (response) =>
    action(constants.DELETE_WORK_GROUP_MEMBERSHIP.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.DELETE_WORK_GROUP_MEMBERSHIP.FAILURE, {
      payload: { error }
    })
};

/* --------------------------------- Skills --------------------------------- */

export const fetchSkills = {
  request: () => action(constants.FETCH_SKILLS.REQUEST),
  success: (response, id, requestPayload, meta) =>
    action(constants.FETCH_SKILLS.SUCCESS, {
      payload: { response },
      meta
    }),
  failure: (error, response, requestPayload, meta) =>
    action(constants.FETCH_SKILLS.FAILURE, { payload: { error }, meta })
};
export const fetchSkill = {
  request: () => action(constants.FETCH_SKILL.REQUEST),
  success: (response) =>
    action(constants.FETCH_SKILL.SUCCESS, {
      payload: { response }
    }),
  failure: (error) =>
    action(constants.FETCH_SKILL.FAILURE, { payload: { error } })
};

export const createSkill = {
  request: () => action(constants.CREATE_SKILL.REQUEST),
  success: (response) =>
    action(constants.CREATE_SKILL.SUCCESS, {
      payload: { response }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.CREATE_SKILL.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const updateSkill = {
  request: () => action(constants.UPDATE_SKILL.REQUEST),
  success: (response) =>
    action(constants.UPDATE_SKILL.SUCCESS, {
      payload: { response }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_SKILL.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const archiveSkill = {
  request: () => action(constants.ARCHIVE_SKILL.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.ARCHIVE_SKILL.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.ARCHIVE_SKILL.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const unarchiveSkill = {
  request: () => action(constants.UNARCHIVE_SKILL.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UNARCHIVE_SKILL.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UNARCHIVE_SKILL.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const deleteSkill = {
  request: () => action(constants.DELETE_SKILL.REQUEST),
  success: (response) =>
    action(constants.DELETE_SKILL.SUCCESS, {
      payload: { response }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.DELETE_SKILL.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const createSkillMembership = {
  request: () => action(constants.CREATE_SKILL_MEMBERSHIP.REQUEST),
  success: (response) =>
    action(constants.CREATE_SKILL_MEMBERSHIP.SUCCESS, {
      payload: { response }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.CREATE_SKILL_MEMBERSHIP.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const updateSkillMembership = {
  request: () => action(constants.UPDATE_SKILL_MEMBERSHIP.REQUEST),
  success: (response) =>
    action(constants.UPDATE_SKILL_MEMBERSHIP.SUCCESS, {
      payload: { response }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_SKILL_MEMBERSHIP.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const deleteSkillMembership = {
  request: () => action(constants.DELETE_SKILL_MEMBERSHIP.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_SKILL_MEMBERSHIP.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.DELETE_SKILL_MEMBERSHIP.FAILURE, {
      payload: { error, requestPayload }
    })
};

/* ------------------------------ Skills Report ----------------------------- */
export const fetchEntitySkillsAndSkillsReport = {
  request: () =>
    action(constants.FETCH_ENTITY_SKILLS_AND_SKILLS_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ENTITY_SKILLS_AND_SKILLS_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_ENTITY_SKILLS_AND_SKILLS_REPORT.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const fetchSkillsReport = {
  request: () => action(constants.FETCH_SKILLS_REPORT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SKILLS_REPORT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_SKILLS_REPORT.FAILURE, {
      payload: { error, requestPayload }
    })
};

/* ------------------------------ Entity Skills ----------------------------- */

export const fetchEntitySkills = {
  request: () => action(constants.FETCH_ENTITY_SKILLS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ENTITY_SKILLS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_ENTITY_SKILLS.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const createEntitySkill = {
  request: () => action(constants.CREATE_ENTITY_SKILL.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.CREATE_ENTITY_SKILL.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.CREATE_ENTITY_SKILL.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const updateEntitySkill = {
  request: () => action(constants.UPDATE_ENTITY_SKILL.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.UPDATE_ENTITY_SKILL.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_ENTITY_SKILL.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const deleteEntitySkill = {
  request: () => action(constants.DELETE_ENTITY_SKILL.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.DELETE_ENTITY_SKILL.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.DELETE_ENTITY_SKILL.FAILURE, {
      payload: { error, requestPayload }
    })
};

/* --------------------------------- Clients -------------------------------- */

export const fetchClients = {
  request: () => action(constants.FETCH_CLIENTS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_CLIENTS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_CLIENTS.FAILURE, { payload: { error } })
};
export const fetchClient = {
  request: () => action(constants.FETCH_CLIENT.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_CLIENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_CLIENT.FAILURE, { payload: { error } })
};
export const createClient = {
  request: () => action(constants.CREATE_CLIENT.REQUEST),
  success: (response, requestPayload) =>
    action(constants.CREATE_CLIENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.CREATE_CLIENT.FAILURE, {
      payload: { error }
    })
};
export const updateClient = {
  request: () => action(constants.UPDATE_CLIENT.REQUEST),
  success: (response, requestPayload) =>
    action(constants.UPDATE_CLIENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.UPDATE_CLIENT.FAILURE, {
      payload: { error }
    })
};

/* --------------------------------- Scopes --------------------------------- */

export const fetchScopes = {
  request: () => action(constants.FETCH_SCOPES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_SCOPES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_SCOPES.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const createScope = {
  request: () => action(constants.CREATE_SCOPE.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.CREATE_SCOPE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.CREATE_SCOPE.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const updateScope = {
  request: () => action(constants.UPDATE_SCOPE.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.UPDATE_SCOPE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_SCOPE.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const deleteScope = {
  request: () => action(constants.DELETE_SCOPE.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.DELETE_SCOPE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.DELETE_SCOPE.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const moveScopes = {
  request: () => action(constants.MOVE_SCOPES.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.MOVE_SCOPES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.MOVE_SCOPES.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const updateScopePosition = {
  request: () => action(constants.UPDATE_SCOPE_POSITION.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.UPDATE_SCOPE_POSITION.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_SCOPE_POSITION.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const assignScopeMembers = {
  request: () => action(constants.ASSIGN_SCOPE_MEMBERS.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.ASSIGN_SCOPE_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.ASSIGN_SCOPE_MEMBERS.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const unassignScopeMembers = {
  request: () => action(constants.UNASSIGN_SCOPE_MEMBERS.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.UNASSIGN_SCOPE_MEMBERS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UNASSIGN_SCOPE_MEMBERS.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const updateScopeMembership = {
  request: () => action(constants.UPDATE_SCOPE_MEMBERSHIP.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.UPDATE_SCOPE_MEMBERSHIP.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.UPDATE_SCOPE_MEMBERSHIP.FAILURE, {
      payload: { error, requestPayload }
    })
};
export const deleteScopeAttachment = {
  request: () => action(constants.DELETE_SCOPE_ATTACHMENT.REQUEST),
  success: (response, body, requestPayload) =>
    action(constants.DELETE_SCOPE_ATTACHMENT.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.DELETE_SCOPE_ATTACHMENT.FAILURE, {
      payload: { error, requestPayload }
    })
};

/* ------------------------------------ - ----------------------------------- */

export const fetchUserActivitiesForActionable = {
  request: () => action(constants.FETCH_USER_ACTIVITIES_FOR_ACTIONABLE.REQUEST),
  success: (response, requestPayload) =>
    action(constants.FETCH_USER_ACTIVITIES_FOR_ACTIONABLE.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_USER_ACTIVITIES_FOR_ACTIONABLE.FAILURE, {
      payload: { error }
    })
};

export const fetchActivityLogUserActivities = {
  request: () => action(constants.FETCH_ACTIVITY_LOG_USER_ACTIVITIES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_ACTIVITY_LOG_USER_ACTIVITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error, response, requestPayload) =>
    action(constants.FETCH_ACTIVITY_LOG_USER_ACTIVITIES.FAILURE, {
      payload: { error, requestPayload }
    })
};

export const fetchProjectTotals = {
  request: () => action(constants.FETCH_PROJECT_TOTALS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PROJECT_TOTALS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PROJECT_TOTALS.FAILURE, { payload: { error } })
};

// export const recurlyBillingUpdate = {
//   request: () => action(constants.SET_RECURLY_BILLING_INFO.REQUEST),
//   success: (response, id, requestPayload) =>
//     action(constants.SET_RECURLY_BILLING_INFO.SUCCESS, {
//       payload: { response, requestPayload }
//     }),
//   failure: error =>
//     action(constants.SET_RECURLY_BILLING_INFO.FAILURE, { payload: { error } })
// };

// export const recurlyBillingFetch = {
//   request: () => action(constants.FETCH_RECURLY_BILLING_INFO.REQUEST),
//   success: (response, id, requestPayload) =>
//     action(constants.FETCH_RECURLY_BILLING_INFO.SUCCESS, {
//       payload: { response, requestPayload }
//     }),
//   failure: error =>
//     action(constants.FETCH_RECURLY_BILLING_INFO.FAILURE, { payload: { error } })
// };

export const loadCSVData = {
  request: () => action(constants.LOAD_CSV_IMPORT_DATA.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.LOAD_CSV_IMPORT_DATA.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.LOAD_CSV_IMPORT_DATA.FAILURE, { payload: { error } })
};

export const fetchPriorities = {
  request: () => action(constants.FETCH_PRIORITIES.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PRIORITIES.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PRIORITIES.FAILURE, { payload: { error } })
};

export const predictionHoursFetch = {
  request: () => action(constants.FETCH_PREDICTION_HOURS.REQUEST),
  success: (response, id, requestPayload) =>
    action(constants.FETCH_PREDICTION_HOURS.SUCCESS, {
      payload: { response, requestPayload }
    }),
  failure: (error) =>
    action(constants.FETCH_PREDICTION_HOURS.FAILURE, { payload: { error } })
};
