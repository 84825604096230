import { MouseEvent, useCallback, useState } from 'react';
import { RequireAtLeastOne } from 'type-fest';

type TargetElement = RequireAtLeastOne<{
  event: MouseEvent<HTMLElement>;
  target: HTMLElement;
}>;

export const usePopperState = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>();

  const handleOpen = useCallback(({ event, target }: TargetElement) => {
    setAnchorElement(target || event?.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorElement(undefined);
  }, []);

  return {
    isOpen: Boolean(anchorElement),
    anchorElement,
    open: handleOpen,
    close: handleClose
  };
};
