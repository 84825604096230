import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import {
  Field,
  Label
} from 'SettingsModule/components/StandardSettings/General/styles';
import ButtonField from 'SettingsModule/components/ButtonsField';
import { noop } from 'appUtils';

export const WorkplanHoursDisplaySetting = () => {
  const handleClick = (value) => {
    // Update this when linking with BE in the future
    noop();
  };

  return (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="This can be overridden by individual users"
        data-effect="solid"
        data-html
        data-class="center"
      >
        Work Plan Hrs Display Default
        <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <ButtonField
          type="radio"
          value="hours"
          checked={false}
          name="hours"
          onClick={handleClick}
          label="Total Hours"
        />
        <ButtonField
          type="radio"
          value="percent"
          checked={false}
          name="percent"
          onClick={handleClick}
          label="% of Capacity"
        />
      </Field>
    </>
  );
};
