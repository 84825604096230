import {
  TRAINING_DATA_REQUIRED_STRING,
  ERROR_MESSAGES
} from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

import { RowValidator } from 'CsvImportsModule/types';

export const skillsValidator: RowValidator = ({ row, entityType }) => {
  // not required, should not start with comma
  const { enteredSkills } = getRowValuesForEntityType(row, entityType);

  if (enteredSkills === TRAINING_DATA_REQUIRED_STRING) return false;
  else if (!enteredSkills) return true;
  else {
    const enteredSkillsSplitByComma = enteredSkills.split(',');
    if (
      enteredSkillsSplitByComma.length > 1 &&
      enteredSkillsSplitByComma[0]?.trim() === ''
    )
      return ERROR_MESSAGES.skillsStartingWithComma;
    return true;
  }
};
