import React, { useCallback } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import KaratRight from 'icons/KaratRight';
import { MemberGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';

const MemberTitleCell = ({ row }: MemberGroupRowProps) => {
  const {
    teamMembershipsByAccountId,
    member: suggestedMember,
    isOpen,
    accountId,
    listItems = [],
    toggleCollapse
  } = row.original;
  const member = teamMembershipsByAccountId[accountId];
  const { score } = suggestedMember;

  return (
    <StyledTitleContainer onClick={toggleCollapse}>
      <StyledKaratContainer
        isOpen={isOpen || false}
        className="app-cues-styled-karat"
        isHidden={!listItems.length}
      >
        <KaratRight />
      </StyledKaratContainer>
      <MemberInitials
        member={member}
        classes={cn('regular-member-no-hover selected')}
      />
      <StyledNameContainer>
        <div className="name">{member?.account?.name}</div>
        <StyledPercentContainer>
          {(score * 100).toFixed(0)}% Match
        </StyledPercentContainer>
      </StyledNameContainer>
    </StyledTitleContainer>
  );
};

export default MemberTitleCell;

const StyledKaratContainer = styled.div<{
  isHidden: boolean;
  isOpen: boolean;
}>`
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  margin-right: 5px;
  ${(props) =>
    props.isHidden &&
    `
    pointer-events: none;
    visibility: hidden;
  `}
  &:hover {
    background-color: ${theme.colors.colorPaleGray5};
  }
  path {
    stroke-width: 0.5;
    stroke: ${theme.colors.colorMediumGray9};
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};
`;

const StyledNameContainer = styled.div`
  font-size: 14px;
  color: ${theme.colors.colorMediumGray9};
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .location-text {
    font-size: 12px;
    color: ${theme.colors.colorCalendarGray};
  }

  .name {
    font-weight: 600;
  }
`;

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 6px;
  cursor: pointer;
  margin-left: 5px;
`;

const StyledPercentContainer = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorCalendarBlue};
  cursor: pointer;
`;
