import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import styled from 'styled-components';

import { getAuth, getSplitFlags, getSplitState } from 'selectors';
import { navigateToHome } from 'actionCreators';
import OnboardContainer from './OnboardContainer';

const OnboardModal = styled(Modal)`
  max-width: 962px;
  .modal-content {
    width: 962px;
    height: 592px;
    -webkit-border-radius: 16px !important;
    -moz-border-radius: 16px !important;
    border-radius: 16px !important;
    overflow: hidden;
  }
`;

const OnboardModalIframe = styled.iframe`
  width: 962px;
  height: 592px;
  border: none;
`;

const OnboardModalContainer = ({
  inviteToken,
  email,
  tempPassword,
  errorMessage,
  hasTeam
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useSelector(getAuth);
  const splitFlags = useSelector(getSplitFlags);
  const splitState = useSelector(getSplitState);

  const queryParams = qs.stringify({
    email,
    temp_password: tempPassword,
    inviteToken,
    authToken: auth.token,
    errorMessage,
    hasTeam
  });

  const url = `${process.env.MOSAIC_AUTH_DOMAIN}/embedded/free-trial?${queryParams}`;

  const slug = auth.account?.slug;
  const handleIframeEvent = useCallback(
    (e) => {
      if (e.data === 'Success') {
        dispatch(navigateToHome({ userSlug: slug }));
      } else {
        try {
          const eventData = JSON.parse(event.data);

          if (typeof eventData === 'object' && eventData.type) {
            if (eventData.type === 'badInvite') {
              history.push('/');
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    [dispatch, history, slug]
  );

  useEffect(() => {
    if (splitFlags.shouldRedirectToOnboard) {
      window.addEventListener('message', handleIframeEvent);
    }
  }, [handleIframeEvent, splitFlags.shouldRedirectToOnboard]);

  if (!splitState.isReady) {
    return null;
  } else if (splitFlags.shouldRedirectToOnboard) {
    return (
      <OnboardModal isOpen>
        <OnboardModalIframe src={url} />
      </OnboardModal>
    );
  } else {
    return <OnboardContainer history={history} />;
  }
};

export default OnboardModalContainer;
