import { useState, useCallback, useMemo } from 'react';
import { useToggle } from 'react-use';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { SimpleConfirmModal } from 'components/Modals';
import {
  getFlatPhasesHash,
  makeGetIsPhaseHasTimeEntries,
  makeGetIsPhaseHasWorkplans
} from 'selectors';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { hardDeletePhase, checkHasTimeEntries } from 'actionCreators';
import { Phase } from 'ProjectsModule/phases/models/phase';

const initialModalProps = {
  phaseId: 0,
  projectId: 0
};

type ModalPropsType = {
  phaseId: number;
  projectId: number;
};

const makeModalContents = ({
  hasTimeEntries,
  hasWorkplans,
  phase
}: {
  hasTimeEntries: boolean;
  hasWorkplans: boolean;
  phase: Phase;
}) => {
  let displayMessage;
  const name = phase.name;

  if (hasTimeEntries && hasWorkplans) {
    displayMessage = (
      <div>
        <b>{name}</b> has time entries and work plans that will be deleted. Are
        you sure you want to permanently delete <b>{name}</b> ?
      </div>
    );
  }

  if (hasTimeEntries && !hasWorkplans) {
    displayMessage = (
      <div>
        <b>{name}</b> has time entries that will be deleted. Are you sure you
        want to permanently delete <b>{name}</b> ?
      </div>
    );
  }

  if (!hasTimeEntries && hasWorkplans) {
    displayMessage = (
      <div>
        <b>{name}</b> has work plans that will be deleted. Are you sure you want
        to permanently delete <b>{name}</b> ?
      </div>
    );
  }

  if (!hasTimeEntries && !hasWorkplans) {
    displayMessage = (
      <div>
        Are you sure you want to permanently delete <b>{name}</b> ?
      </div>
    );
  }

  return {
    displayMessage
  };
};
const useHardDeletePhaseModal = () => {
  const [{ phaseId, projectId }, setModalProps] =
    useState<ModalPropsType>(initialModalProps);
  const dispatch = useAppDispatch();
  const [isOpen, toggle] = useToggle(false);
  const phasesHash = useAppSelector(getFlatPhasesHash);
  const teamId = useAppSelector(getSelectedTeamId);

  const getIsPhaseHasTimeEntries = useMemo(makeGetIsPhaseHasTimeEntries, []);
  const getIsPhaseHasWorkplans = useMemo(makeGetIsPhaseHasWorkplans, []);

  const hasTimeEntries = useAppSelector((state) =>
    getIsPhaseHasTimeEntries(state, { phaseId })
  );
  const hasWorkplans = useAppSelector((state) =>
    getIsPhaseHasWorkplans(state, { phaseId })
  );

  const handleOpenHardDeletePhaseModal = useCallback(
    (modalProps) => {
      const { phaseId, projectId } = modalProps;
      const phase = phaseId ? phasesHash[phaseId] : undefined;

      if (phase && projectId) {
        dispatch(
          checkHasTimeEntries({
            team_id: teamId,
            phase_ids: [phaseId],
            permissions: {
              projectId,
              teamId,
              mine: false
            }
          })
        );
        setModalProps(modalProps);
        toggle();
      }
    },
    [dispatch, phasesHash, teamId, toggle]
  );

  const handleHardDeletePhase = useCallback(() => {
    dispatch(
      hardDeletePhase({
        projectId,
        id: phaseId
      })
    );
  }, [dispatch, phaseId, projectId]);

  const ModalComponent = useCallback(() => {
    const phase = phaseId ? phasesHash[phaseId] : undefined;

    if (!phase) {
      return <></>;
    }

    const { displayMessage } = makeModalContents({
      hasTimeEntries,
      hasWorkplans,
      phase
    });

    return isOpen ? (
      <SimpleConfirmModal
        body={displayMessage}
        header={'Delete Phase of Work'}
        toggle={toggle}
        isOpen
        confirmLabel="Delete forever"
        onConfirm={handleHardDeletePhase}
      />
    ) : (
      <></>
    );
  }, [
    handleHardDeletePhase,
    hasTimeEntries,
    hasWorkplans,
    isOpen,
    phaseId,
    phasesHash,
    toggle
  ]);

  return {
    HardDeletePhaseModal: ModalComponent,
    handleOpenHardDeletePhaseModal
  };
};

export default useHardDeletePhaseModal;
