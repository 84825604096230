import * as Sentry from '@sentry/browser';
import moment from 'moment';
import {
  DEPENDENCY_STRINGS,
  DRAG_ACTIONS,
  RESIZE_DIRECTIONS,
  VIEW_BY,
  ZOOM_LEVELS,
  ZOOM_STRINGS,
  ZOOM_TO_SNAP_VALUES
} from 'appConstants/workload';
import {
  formatDateRange,
  formatDateRangeFromFilter,
  generateDateRange,
  DATE_RANGES
} from 'appUtils/dateRangeHelpers';
import { LEFT, RIGHT } from 'appConstants/projectPlanner';

export const keyifyScheduleBarDate = (date) =>
  moment(date).format('MM/DD/YYYY');

export const calcDayChange = (calendarTime, scheduleBarTime) =>
  moment.duration(moment(calendarTime).startOf('day').diff(scheduleBarTime));

export const isSameOrBetween = (dateToCheck, startDate, endDate) =>
  moment(dateToCheck).isBetween(startDate, endDate, 'day', '[]');

export const createOrUpdateScheduleBar = (
  newScheduleBar,
  existingScheduleBars
) => {
  const {
    start_date: startDate,
    end_date: endDate,
    project_id: projectId
  } = newScheduleBar;

  const overLappedScheduleBars = existingScheduleBars.filter(
    (existingScheduleBar) =>
      (keyifyScheduleBarDate(existingScheduleBar.start_date) === endDate ||
        keyifyScheduleBarDate(existingScheduleBar.end_date) === startDate) &&
      existingScheduleBar.project_id === projectId
  );

  if (overLappedScheduleBars.length === 2) {
    const schedBarToUpdate = overLappedScheduleBars.find(
      (schedBar) => keyifyScheduleBarDate(schedBar.end_date) === startDate
    );
    const schedBarToDelete = overLappedScheduleBars.find(
      (schedBar) => keyifyScheduleBarDate(schedBar.start_date) === endDate
    );

    return {
      merged: true,
      hasSchedToDelete: true,
      scheduleBar: {
        ...schedBarToUpdate,
        start_date: keyifyScheduleBarDate(schedBarToUpdate.start_date),
        end_date: keyifyScheduleBarDate(schedBarToDelete.end_date)
      },
      barToDelete: schedBarToDelete
    };
  }

  if (overLappedScheduleBars.length === 1) {
    const overlapScheduleBar = overLappedScheduleBars[0];

    if (keyifyScheduleBarDate(overlapScheduleBar.end_date) === startDate) {
      return {
        merged: true,
        scheduleBar: {
          ...overlapScheduleBar,
          start_date: keyifyScheduleBarDate(overlapScheduleBar.start_date),
          end_date: endDate
        }
      };
    } else {
      return {
        merged: true,
        scheduleBar: {
          ...overlapScheduleBar,
          end_date: keyifyScheduleBarDate(overlapScheduleBar.end_date),
          start_date: startDate
        }
      };
    }
  } else {
    return {
      merged: false,
      scheduleBar: {
        ...newScheduleBar
      }
    };
  }
};

// Returns whether this click is a deletion or an addition
export const isDeletion = (
  memberScheduleBars,
  selectedDate,
  projectId,
  accountId
) => {
  const matchingSchedules = memberScheduleBars.filter(
    (schedule) =>
      projectId &&
      schedule.project_id == projectId &&
      (accountId ? accountId == schedule.account_id : true)
  );
  const isWithinExistingSchedule = matchingSchedules.find((schedule) => {
    return (
      isSameOrBetween(selectedDate, schedule.start_date, schedule.end_date) &&
      isSameOrBetween(
        moment(selectedDate).clone().add(1, 'day'),
        schedule.start_date,
        schedule.end_date
      )
    );
  });
  return isWithinExistingSchedule;
};

export const getDragTooltipDates = ({ itemContext, item }) => {
  const { resizeEdge, resizeTime } = itemContext;
  const { start_date: startDate, end_date: endDate } = item;

  // Resizing
  return resizeEdge === LEFT
    ? {
        startDate: moment(resizeTime),
        endDate
      }
    : resizeEdge === RIGHT
    ? {
        startDate,
        endDate: moment(resizeTime)
      }
    : // Dragging
      {
        startDate,
        endDate: moment(startDate).add(
          item.end_date - item.start_date,
          'milliseconds'
        )
      };
};

/**
 * Snaps a date according to a given zoom level. If the item has a dependency,
 * its date does not change.
 */
// <T extends { end_date: number, start_date: number}>(
//   zoom: keyof typeof ZOOM_LEVELS,
//   action: keyof typeof DRAG_ACTIONS,
//   item: T,
//   time: number,
//   resizeEdge: keyof typeof RESIZE_DIRECTIONS
// )
// => number
export const getSnappedDate = (zoom, action, item, time, resizeEdge) => {
  const { end_date: endDate, start_date: startDate } = item;

  // Time zone change adjustments, such as daylight savings time. Since the
  // times are snapped to the day, one of two times of day are possible,
  // discounting time zone changes: the start of day (00:00:00) or the end of
  // day (23:59:59).
  //
  // In the case of the start of day, the seconds will always be set to 00
  // and the time is rounded to the nearest start of day. Thus, for any time
  // with 00 seconds between 12:00:00 the previous day and 11:59:59 the
  // current day, the time will be set to 00:00:00 on the current day.
  //
  // In the case of the end of day, the seconds will always be set to 59 and
  // the time is rounded to the nearest end of day. Thus, for any time with
  // 59 seconds between 12:00:00 the current day and 11:59:59 the next day,
  // the time will be set to 23:59:59 on the current day.
  //
  // Assuming `initialTime` was the time value at the start of the move or
  // resize, it would be possible to compute the adjustment as follows.
  // ```moment(initialTime).utcOffset() - moment(time).utcOffset()```
  // However, that value is not tracked. Note that `currentEdgeTime` may
  // appear to be that value, but is updated every time the relevant edge is
  // snapped.
  const adjustedTime = moment(time);
  if (adjustedTime.seconds() === 0)
    adjustedTime.add(12, 'hours').startOf('day');
  else if (adjustedTime.seconds() === 59)
    adjustedTime.subtract(12, 'hours').endOf('day');
  else console.warn(`Unexpected time to snap: ${adjustedTime.format()}`);

  // The only way to modify the end date is by resizing the right side.
  const isEndDate = resizeEdge === RESIZE_DIRECTIONS.RIGHT;
  const currentEdgeTime = isEndDate ? endDate : startDate;

  // Modifying an edge with a dependency should not allow moving or resizing
  // that dependency.
  if (checkItemDependencies(item, resizeEdge, action)) {
    return currentEdgeTime;
  }

  // Ensure that the time is correctly aligned according to the snap
  // interval.
  const targetTime =
    ZOOM_TO_SNAP_VALUES[zoom] === 7
      ? // Time on the week scale is snapped to Thursday, because the Unix
        // epoch started on a Thursday. We use the same day as that of the
        // original time.
        adjustedTime.day(moment(currentEdgeTime).day())
      : isEndDate
      ? adjustedTime
      : // Time on the day scale is snapped to 23:59:59.9999 the previous
        // day, so to get the correct start date, the time needs to be
        // incremented by 1 millisecond.
        adjustedTime.add(1, 'ms');

  // Resizing
  if (action === DRAG_ACTIONS.RESIZE) {
    return resizeEdge === RESIZE_DIRECTIONS.LEFT
      ? // Do not allow the new start date to pass the end date.
        moment.min(targetTime, moment(endDate)).startOf('day').valueOf()
      : // Do not allow the new end date to pass the start date.
        moment.max(targetTime, moment(startDate)).endOf('day').valueOf();
  }

  // Moving (start date)
  return targetTime.startOf('day').valueOf();
};

/**
 * Add a timezone offset to a Unix epoch date to get the local date.
 *
 * This function is needed because the `react-calendar-timeline` library
 * snaps to the nearest date in UTC time when snapping to the nearest date.
 */
// (date: number) => number
export const addTimezoneOffsetToDate = (date) =>
  date + new Date().getTimezoneOffset() * 1000 * 60;

export const serializeBar = ({ itemId, itemType }) => `${itemType}--${itemId}`;

export const deserializeBar = (id) => {
  if (!id?.split) {
    try {
      throw new Error('');
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtra('id_to_deserialize', id);
        scope.setExtra('stack', error.stack);
        Sentry.captureException('bad serialized id');
      });
    }
    return {};
  }
  const [itemType, itemId] = id.split('--');
  return { itemType, itemId };
};

const weeksFromZoomHash = {
  [ZOOM_LEVELS.YEAR]: 52,
  [ZOOM_LEVELS.HALF]: 26, // not used on timeline based views
  [ZOOM_LEVELS.QUARTER]: 12,
  [ZOOM_LEVELS.MONTH]: 6,
  [ZOOM_LEVELS.TWO_WEEKS]: 2, // not used on timeline based views
  [ZOOM_LEVELS.THIS_WEEK]: 1, // not used on timeline based views
  [ZOOM_LEVELS.WEEK]: 2,
  [ZOOM_LEVELS.DAY]: 1
};

export const getWeeksFromZoom = (zoom) => weeksFromZoomHash[zoom];

export const getVisibleTimeRange = ({ zoom, timeStart }) => {
  const baseTime = moment(timeStart).clone();

  if (zoom === ZOOM_LEVELS.DAY) {
    return {
      visibleTimeStart: baseTime,
      visibleTimeEnd: baseTime.clone().add(5, 'days') // show 5 days for Day Zoom level (Day View)
    };
  }

  return {
    visibleTimeStart: baseTime,
    visibleTimeEnd: baseTime.clone().add(weeksFromZoomHash[zoom], 'week')
  };
};

export const zoomToTopIntervalHash = {
  [ZOOM_LEVELS.YEAR]: ZOOM_STRINGS.YEAR,
  [ZOOM_LEVELS.QUARTER]: ZOOM_STRINGS.MONTH,
  [ZOOM_LEVELS.MONTH]: ZOOM_STRINGS.MONTH,
  [ZOOM_LEVELS.WEEK]: ZOOM_STRINGS.WEEK,
  [ZOOM_LEVELS.DAY]: ZOOM_STRINGS.DAY
};

export const zoomToIntervalHash = {
  [ZOOM_LEVELS.YEAR]: ZOOM_STRINGS.MONTH,
  [ZOOM_LEVELS.QUARTER]: ZOOM_STRINGS.WEEK,
  [ZOOM_LEVELS.MONTH]: ZOOM_STRINGS.WEEK,
  [ZOOM_LEVELS.WEEK]: ZOOM_STRINGS.DAY,
  [ZOOM_LEVELS.DAY]: ZOOM_STRINGS.DAY
};
export const zoomToTopIntervalFormat = {
  [ZOOM_LEVELS.YEAR]: (date) => moment(date).format('YYYY'),
  [ZOOM_LEVELS.QUARTER]: (date) => {
    const momentDate = moment(date);
    const formatString = momentDate.month() === 0 ? 'MMM YYYY' : 'MMM';
    return momentDate.format(formatString);
  },
  [ZOOM_LEVELS.MONTH]: (date) => `${date.format('MMM')}`,
  [ZOOM_LEVELS.WEEK]: (date) =>
    `${date.format('MMM D')} - ${date.clone().add(6, 'days').format('MMM D')}`,
  [ZOOM_LEVELS.DAY]: (date) => date.format('MMM D')
};
export const zoomToIntervalFormat = {
  [ZOOM_LEVELS.YEAR]: (date) => `${date.format('MMM')}`,
  [ZOOM_LEVELS.QUARTER]: (date) =>
    `${date.format('D')} - ${date.clone().add(6, 'days').format('D')}`,
  [ZOOM_LEVELS.MONTH]: (date) =>
    `${date.format('MMM D')} - ${date.clone().add(6, 'days').format('MMM D')}`,
  // eslint-disable-next-line react/display-name
  [ZOOM_LEVELS.WEEK]: (date) => (
    <>
      <span>{date.format('dd')}</span>
      <span>&nbsp;{date.format('D')}</span>
    </>
  ),
  [ZOOM_LEVELS.DAY]: (date) => date.format('dd')
};

/* --------------------------- Demand Report Chart -------------------------- */
export const GENERATE_DEMAND_CHART_RANGE = {
  [ZOOM_LEVELS.QUARTER]: () => ({
    start_date: moment().startOf('week').format('YYYY-MM-DD'),
    end_date: moment().add(11, 'weeks').endOf('week').format('YYYY-MM-DD')
  }),
  [ZOOM_LEVELS.YEAR]: () => ({
    start_date: moment().startOf('month').format('YYYY-MM-DD'),
    end_date: moment().add(11, 'months').endOf('month').format('YYYY-MM-DD')
  })
};

export const formatDemandChartDateRange = (args) => {
  return formatDateRange({ ...args, generator: GENERATE_DEMAND_CHART_RANGE });
};

/* ------------------------------------ * ----------------------------------- */

export const CAPACITY_LABELS = {
  [ZOOM_LEVELS.YEAR]: 'Next 12 Months',
  [ZOOM_LEVELS.HALF]: 'Next 6 Months',
  [ZOOM_LEVELS.QUARTER]: 'Next 3 Months',
  [ZOOM_LEVELS.MONTH]: 'Next 30 Days',
  [ZOOM_LEVELS.TWO_WEEKS]: 'Next 14 Days',
  [ZOOM_LEVELS.WEEK]: 'Next 7 Days',
  [ZOOM_LEVELS.THIS_WEEK]: 'This Week',
  [ZOOM_LEVELS.NEXT_WEEK]: 'Next Week',
  [ZOOM_LEVELS.DAY]: 'Today',
  [ZOOM_LEVELS.THIS_YEAR]: 'Year to Date',
  custom: 'Custom'
};

export const CAPACITY_ORGANIZATION_LABELS = {
  [ZOOM_LEVELS.YEAR]: 'Year',
  [ZOOM_LEVELS.QUARTER]: 'Quarter',
  custom: 'Custom'
};

export const CAPACITY_OPTIONS_MENU = {
  [ZOOM_LEVELS.DAY]: {
    value: ZOOM_LEVELS.DAY,
    label: CAPACITY_LABELS[ZOOM_LEVELS.DAY]
  },
  [ZOOM_LEVELS.WEEK]: {
    value: ZOOM_LEVELS.WEEK,
    label: CAPACITY_LABELS[ZOOM_LEVELS.WEEK]
  },
  [ZOOM_LEVELS.TWO_WEEKS]: {
    value: ZOOM_LEVELS.TWO_WEEKS,
    label: CAPACITY_LABELS[ZOOM_LEVELS.TWO_WEEKS]
  },
  [ZOOM_LEVELS.THIS_WEEK]: {
    value: ZOOM_LEVELS.THIS_WEEK,
    label: CAPACITY_LABELS[ZOOM_LEVELS.THIS_WEEK]
  },
  [ZOOM_LEVELS.NEXT_WEEK]: {
    value: ZOOM_LEVELS.NEXT_WEEK,
    label: CAPACITY_LABELS[ZOOM_LEVELS.NEXT_WEEK]
  },
  [ZOOM_LEVELS.MONTH]: {
    value: ZOOM_LEVELS.MONTH,
    label: CAPACITY_LABELS[ZOOM_LEVELS.MONTH]
  },
  [ZOOM_LEVELS.QUARTER]: {
    value: ZOOM_LEVELS.QUARTER,
    label: CAPACITY_LABELS[ZOOM_LEVELS.QUARTER]
  },
  [ZOOM_LEVELS.HALF]: {
    value: ZOOM_LEVELS.HALF,
    label: CAPACITY_LABELS[ZOOM_LEVELS.HALF]
  },
  [ZOOM_LEVELS.YEAR]: {
    value: ZOOM_LEVELS.YEAR,
    label: CAPACITY_LABELS[ZOOM_LEVELS.YEAR]
  },
  custom: { value: 'custom', label: 'Custom' }
};

export const CAPACITY_OPTIONS_DISPLAY = {
  [ZOOM_LEVELS.DAY]: {
    value: ZOOM_LEVELS.DAY,
    label: CAPACITY_LABELS[ZOOM_LEVELS.DAY]
  },
  [ZOOM_LEVELS.WEEK]: {
    value: ZOOM_LEVELS.WEEK,
    label: CAPACITY_LABELS[ZOOM_LEVELS.WEEK]
  },
  [ZOOM_LEVELS.THIS_WEEK]: {
    value: ZOOM_LEVELS.THIS_WEEK,
    label: CAPACITY_LABELS[ZOOM_LEVELS.THIS_WEEK]
  },
  [ZOOM_LEVELS.NEXT_WEEK]: {
    value: ZOOM_LEVELS.NEXT_WEEK,
    label: CAPACITY_LABELS[ZOOM_LEVELS.NEXT_WEEK]
  },
  [ZOOM_LEVELS.TWO_WEEKS]: {
    value: ZOOM_LEVELS.TWO_WEEKS,
    label: CAPACITY_LABELS[ZOOM_LEVELS.TWO_WEEKS]
  },
  [ZOOM_LEVELS.MONTH]: {
    value: ZOOM_LEVELS.MONTH,
    label: CAPACITY_LABELS[ZOOM_LEVELS.MONTH]
  },
  [ZOOM_LEVELS.QUARTER]: {
    value: ZOOM_LEVELS.QUARTER,
    label: CAPACITY_LABELS[ZOOM_LEVELS.QUARTER]
  },
  [ZOOM_LEVELS.HALF]: {
    value: ZOOM_LEVELS.HALF,
    label: CAPACITY_LABELS[ZOOM_LEVELS.HALF]
  },
  [ZOOM_LEVELS.YEAR]: {
    value: ZOOM_LEVELS.YEAR,
    label: CAPACITY_LABELS[ZOOM_LEVELS.YEAR]
  },
  [ZOOM_LEVELS.THIS_YEAR]: {
    value: ZOOM_LEVELS.THIS_YEAR,
    label: CAPACITY_LABELS[ZOOM_LEVELS.THIS_YEAR]
  },
  custom: { value: 'custom', label: 'Custom' }
};

export const CAPACITY_ORGANIZATION_OPTIONS_DISPLAY = {
  [ZOOM_LEVELS.QUARTER]: {
    value: ZOOM_LEVELS.QUARTER,
    label: CAPACITY_ORGANIZATION_LABELS[ZOOM_LEVELS.QUARTER]
  },
  [ZOOM_LEVELS.YEAR]: {
    value: ZOOM_LEVELS.YEAR,
    label: CAPACITY_ORGANIZATION_LABELS[ZOOM_LEVELS.YEAR]
  },
  custom: { value: 'custom', label: 'Custom' }
};

export const CAPACITY_OPTIONS_DISPLAY_BY_VIEW = {
  [VIEW_BY.MEMBERS]: CAPACITY_OPTIONS_DISPLAY,
  [VIEW_BY.PROJECTS]: CAPACITY_OPTIONS_DISPLAY,
  [VIEW_BY.CLIENTS]: CAPACITY_OPTIONS_DISPLAY,
  [VIEW_BY.ORGANIZATION]: CAPACITY_ORGANIZATION_OPTIONS_DISPLAY
};

export const CAPACITY_OPTIONS = [
  CAPACITY_OPTIONS_MENU[ZOOM_LEVELS.THIS_WEEK],
  CAPACITY_OPTIONS_MENU[ZOOM_LEVELS.NEXT_WEEK],
  // CAPACITY_OPTIONS_MENU[ZOOM_LEVELS.WEEK],
  // CAPACITY_OPTIONS_MENU[ZOOM_LEVELS.TWO_WEEKS],
  CAPACITY_OPTIONS_MENU[ZOOM_LEVELS.MONTH],
  CAPACITY_OPTIONS_MENU[ZOOM_LEVELS.QUARTER],
  // CAPACITY_OPTIONS_MENU[ZOOM_LEVELS.HALF],
  // CAPACITY_OPTIONS_MENU[ZOOM_LEVELS.YEAR],
  CAPACITY_OPTIONS_MENU.custom
];

export const CAPACITY_ORGANIZATION_RANGE_OPTIONS = [
  CAPACITY_ORGANIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.QUARTER],
  CAPACITY_ORGANIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.YEAR]
  // CAPACITY_ORGANIZATION_OPTIONS_DISPLAY.custom
];
export const CAPACITY_RANGE_OPTIONS_BY_VIEW = {
  [VIEW_BY.MEMBERS]: CAPACITY_OPTIONS,
  [VIEW_BY.PROJECTS]: CAPACITY_OPTIONS,
  [VIEW_BY.CLIENTS]: CAPACITY_OPTIONS,
  [VIEW_BY.ORGANIZATION]: CAPACITY_ORGANIZATION_RANGE_OPTIONS
};

export const GENERATE_CAPACITY_RANGE = {
  [ZOOM_LEVELS.DAY]: () => ({
    start_date: moment().format('MM/DD/YYYY'),
    end_date: moment().format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.WEEK]: () => ({
    start_date: moment().format('MM/DD/YYYY'),
    end_date: moment().add(6, 'days').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.THIS_WEEK]: () => ({
    end_date: moment().endOf('week').format('MM/DD/YYYY'),
    start_date: moment().startOf('week').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.NEXT_WEEK]: () => ({
    end_date: moment().add(1, 'week').endOf('week').format('MM/DD/YYYY'),
    start_date: moment().add(1, 'week').startOf('week').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.START_OF_THIS_WEEK_TO_END_OF_NEXT_WEEK]: () => ({
    end_date: moment().add(1, 'week').endOf('week').format('MM/DD/YYYY'),
    start_date: moment().startOf('week').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.TWO_WEEKS]: () => ({
    start_date: moment().format('MM/DD/YYYY'),
    end_date: moment().add(13, 'days').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.MONTH]: () => ({
    start_date: moment().format('MM/DD/YYYY'),
    end_date: moment().add(29, 'days').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.QUARTER]: () => ({
    start_date: moment().format('MM/DD/YYYY'),
    end_date: moment().add(3, 'months').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.HALF]: () => ({
    start_date: moment().format('MM/DD/YYYY'),
    end_date: moment().add(6, 'months').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.YEAR]: () => ({
    start_date: moment().format('MM/DD/YYYY'),
    end_date: moment().add(1, 'year').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.THIS_YEAR]: () => ({
    start_date: moment().startOf('year').format('MM/DD/YYYY'),
    end_date: moment().endOf('year').format('MM/DD/YYYY')
  }),
  custom: ({ start, end } = {}) => ({
    start_date: moment(start).format('MM/DD/YYYY'),
    end_date: moment(end).format('MM/DD/YYYY')
  }),
  [DATE_RANGES.CUSTOM]: ({ start, end } = {}) => ({
    start_date: moment(start).format('MM/DD/YYYY'),
    end_date: moment(end).format('MM/DD/YYYY')
  })
};

export const GENERATE_CAPACITY_CHART_RANGE = {
  ...GENERATE_CAPACITY_RANGE,
  [ZOOM_LEVELS.YEAR]: () =>
    generateDateRange({ range: DATE_RANGES.NEXT_12_MONTHS })
};

// Use this when args.range may not come from activeFilter (eg. a default value not saved on the filter)
export const formatCapacityDateRange = (args) => {
  return formatDateRange({
    ...args,
    generator: GENERATE_CAPACITY_RANGE
  });
};
export const formatCapacityChartDateRange = (args) => {
  return formatDateRange({
    ...args,
    generator: GENERATE_CAPACITY_CHART_RANGE
  });
};

export const capacityDateRangeGeneratorByView = {
  [VIEW_BY.MEMBERS]: GENERATE_CAPACITY_RANGE,
  [VIEW_BY.ORGANIZATION]: GENERATE_CAPACITY_CHART_RANGE
};
export const capacityDateRangeFormatterByView = {
  [VIEW_BY.MEMBERS]: formatCapacityDateRange,
  [VIEW_BY.PROJECTS]: formatCapacityDateRange,
  [VIEW_BY.ORGANIZATION]: formatCapacityChartDateRange
};
export const formatCapacityDateRangeFromFilter = (activeFilter) => {
  return formatDateRangeFromFilter(activeFilter, formatCapacityDateRange);
};

/* ---------------------------- Workload Forecast --------------------------- */
export const WORKLOAD_FORECAST_LABELS = {
  [ZOOM_LEVELS.QUARTER]: 'Quarterly',
  [ZOOM_LEVELS.YEAR]: '12 Months'
};

export const WORKLOAD_FORECAST_OPTIONS_DISPLAY = {
  [ZOOM_LEVELS.QUARTER]: {
    value: ZOOM_LEVELS.QUARTER,
    label: WORKLOAD_FORECAST_LABELS[ZOOM_LEVELS.QUARTER]
  },
  [ZOOM_LEVELS.YEAR]: {
    value: ZOOM_LEVELS.YEAR,
    label: WORKLOAD_FORECAST_LABELS[ZOOM_LEVELS.YEAR]
  }
};

export const WORKLOAD_FORECAST_RANGE_OPTIONS = [
  WORKLOAD_FORECAST_OPTIONS_DISPLAY[ZOOM_LEVELS.QUARTER],
  WORKLOAD_FORECAST_OPTIONS_DISPLAY[ZOOM_LEVELS.YEAR]
];

// quarter view for Workload forecast is 12 full weeks.
export const GENERATE_WORKLOAD_FORECAST_RANGE = {
  [ZOOM_LEVELS.QUARTER]: () => ({
    start_date: moment().isoWeekday(1).add(-7, 'weeks').format('YYYY-MM-DD'), // budget_report requires different date format(ISO_8601)
    end_date: moment()
      .isoWeekday(6)
      .add(4, 'weeks')
      .add(1, 'day')
      .format('YYYY-MM-DD')
  }),
  [ZOOM_LEVELS.YEAR]: ({ isBeforeHalfMonth }) => {
    const past = isBeforeHalfMonth ? -9 : -8;
    const future = isBeforeHalfMonth ? 2 : 3;

    return {
      start_date: moment()
        .startOf('month')
        .add(past, 'months')
        .format('YYYY-MM-DD'),
      end_date: moment()
        .add(future, 'months')
        .endOf('month')
        .format('YYYY-MM-DD')
    };
  }
};

export const formatForecastTypeChartDateRange = ({
  isBeforeHalfMonth,
  ...args
}) => {
  const generator = {
    [ZOOM_LEVELS.QUARTER]:
      GENERATE_WORKLOAD_FORECAST_RANGE[ZOOM_LEVELS.QUARTER],
    [ZOOM_LEVELS.YEAR]: () =>
      GENERATE_WORKLOAD_FORECAST_RANGE[ZOOM_LEVELS.YEAR]({
        isBeforeHalfMonth
      })
  };
  return formatDateRange({
    ...args,
    generator
  });
};

/* ------------------------------- Utilization ------------------------------ */
export const UTILIZATION_LABELS = {
  [ZOOM_LEVELS.YEAR]: 'Last 12 Months',
  [ZOOM_LEVELS.HALF]: 'Last 6 Months',
  [ZOOM_LEVELS.QUARTER]: 'Quarterly',
  [ZOOM_LEVELS.MONTH]: 'Last Month',
  [ZOOM_LEVELS.THIS_MONTH]: 'This Month',
  [ZOOM_LEVELS.WEEK]: 'Last Week',
  [ZOOM_LEVELS.THIS_WEEK]: 'This Week',
  [ZOOM_LEVELS.THIS_YEAR]: 'Year to Date',
  [ZOOM_LEVELS.LAST_12_WEEKS]: 'Last 12 Weeks',
  custom: 'Custom'
};

export const UTILIZATION_OPTIONS_DISPLAY = {
  [ZOOM_LEVELS.WEEK]: {
    value: ZOOM_LEVELS.WEEK,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.WEEK]
  },
  [ZOOM_LEVELS.THIS_WEEK]: {
    value: ZOOM_LEVELS.THIS_WEEK,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.THIS_WEEK]
  },
  [ZOOM_LEVELS.MONTH]: {
    value: ZOOM_LEVELS.MONTH,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.MONTH]
  },
  [ZOOM_LEVELS.THIS_MONTH]: {
    value: ZOOM_LEVELS.THIS_MONTH,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.THIS_MONTH]
  },
  [ZOOM_LEVELS.QUARTER]: {
    value: ZOOM_LEVELS.QUARTER,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.QUARTER]
  },
  [ZOOM_LEVELS.HALF]: {
    value: ZOOM_LEVELS.HALF,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.HALF]
  },
  [ZOOM_LEVELS.YEAR]: {
    value: ZOOM_LEVELS.YEAR,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.YEAR]
  },
  [ZOOM_LEVELS.THIS_YEAR]: {
    value: ZOOM_LEVELS.THIS_YEAR,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.THIS_YEAR]
  },
  [ZOOM_LEVELS.LAST_12_WEEKS]: {
    value: ZOOM_LEVELS.LAST_12_WEEKS,
    label: UTILIZATION_LABELS[ZOOM_LEVELS.LAST_12_WEEKS]
  },
  custom: { value: 'custom', label: 'Custom' }
};

export const UTILIZATION_MEMBERS_OPTIONS = [
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.WEEK],
  // UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.THIS_WEEK],
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.MONTH],
  // UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.THIS_MONTH],
  // UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.QUARTER],
  // UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.HALF],
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.YEAR],
  UTILIZATION_OPTIONS_DISPLAY.custom
];

export const UTILIZATION_ORGANIZATION_RANGE_OPTIONS = [
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.LAST_12_WEEKS],
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.YEAR],
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.THIS_YEAR],
  UTILIZATION_OPTIONS_DISPLAY.custom
];
export const UTILIZATION_TEAM_RANGE_OPTIONS = [
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.LAST_12_WEEKS],
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.YEAR],
  UTILIZATION_OPTIONS_DISPLAY[ZOOM_LEVELS.THIS_YEAR],
  UTILIZATION_OPTIONS_DISPLAY.custom
];

export const UTILIZATION_RANGE_OPTIONS_BY_VIEW = {
  [VIEW_BY.MEMBERS]: UTILIZATION_MEMBERS_OPTIONS,
  [VIEW_BY.ORGANIZATION]: UTILIZATION_ORGANIZATION_RANGE_OPTIONS,
  [VIEW_BY.TEAM]: UTILIZATION_TEAM_RANGE_OPTIONS
};

export const showMonthPickerForCustom = {
  [VIEW_BY.ORGANIZATION]: true,
  [VIEW_BY.TEAM]: true
};

export const GENERATE_UTILIZATION_RANGE = {
  [ZOOM_LEVELS.WEEK]: () => ({
    end_date: moment().add(-1, 'week').endOf('week').format('MM/DD/YYYY'),
    start_date: moment().add(-1, 'week').startOf('week').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.THIS_WEEK]: () => ({
    end_date: moment().endOf('week').format('MM/DD/YYYY'),
    start_date: moment().startOf('week').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.PREV_WEEK]: () => ({
    end_date: moment().add(-2, 'week').endOf('week').format('MM/DD/YYYY'),
    start_date: moment().add(-2, 'week').startOf('week').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.NEXT_WEEK]: () => ({
    end_date: moment().add(1, 'week').endOf('week').format('MM/DD/YYYY'),
    start_date: moment().add(1, 'week').startOf('week').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.START_OF_WEEK_BEFORE_LAST_WEEK_TO_END_OF_LAST_WEEK]: () => ({
    end_date: moment().add(-1, 'week').endOf('week').format('MM/DD/YYYY'),
    start_date: moment().add(-2, 'week').startOf('week').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.MONTH]: () => ({
    end_date: moment().add(-1, 'month').endOf('month').format('MM/DD/YYYY'),
    start_date: moment().add(-1, 'month').startOf('month').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.THIS_MONTH]: () => ({
    end_date: moment().endOf('month').format('MM/DD/YYYY'),
    start_date: moment().startOf('month').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.QUARTER]: () => ({
    end_date: moment().format('MM/DD/YYYY'),
    start_date: moment().add(-3, 'months').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.THIS_YEAR]: () => ({
    start_date: moment().startOf('year').format('MM/DD/YYYY'),
    end_date: moment().endOf('year').format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.HALF]: () =>
    generateDateRange({ range: DATE_RANGES.COMPLETED_HALF_YEAR }),
  [ZOOM_LEVELS.YEAR]: () => ({
    end_date: moment().format('MM/DD/YYYY'),
    start_date: moment()
      .add(-1, 'year')
      .add(1, 'month')
      .startOf('month')
      .format('MM/DD/YYYY')
  }),
  [ZOOM_LEVELS.LAST_12_WEEKS]: () => ({
    start_date: moment()
      .startOf('week')
      .isoWeekday(1)
      .add(-12, 'week')
      .format('MM/DD/YYYY'),
    end_date: moment().startOf('week').add(-1, 'day').format('MM/DD/YYYY')
  }),
  custom: ({ start, end }) => ({
    start_date: moment(start).format('MM/DD/YYYY'),
    end_date: moment(end).format('MM/DD/YYYY')
  })
};

export const GENERATE_UTILIZATION_CHART_RANGE = {
  ...GENERATE_UTILIZATION_RANGE,
  [ZOOM_LEVELS.YEAR]: () =>
    generateDateRange({ range: DATE_RANGES.COMPLETED_YEAR })
};

export const utilizationRangeGeneratorByView = {
  [VIEW_BY.MEMBERS]: GENERATE_UTILIZATION_RANGE,
  [VIEW_BY.ORGANIZATION]: GENERATE_UTILIZATION_CHART_RANGE,
  [VIEW_BY.TEAM]: GENERATE_UTILIZATION_CHART_RANGE
};

// Use this when args.range may not come from activeFilter (eg. a default value not saved on the filter)
export const formatUtilizationDateRange = (args) => {
  return formatDateRange({ ...args, generator: GENERATE_UTILIZATION_RANGE });
};
// same as above but ZOOM_LEVELS.YEAR = completed past 12 months
export const formatUtilizationChartDateRange = (args) => {
  return formatDateRange({
    ...args,
    generator: GENERATE_UTILIZATION_CHART_RANGE
  });
};

export const utilizationDateRangeFormatterByView = {
  [VIEW_BY.MEMBERS]: formatUtilizationDateRange,
  [VIEW_BY.ORGANIZATION]: formatUtilizationChartDateRange,
  [VIEW_BY.TEAM]: formatUtilizationChartDateRange
};

export const formatUtilizationDateRangeFromFilter = (activeFilter) => {
  return formatDateRangeFromFilter(activeFilter, formatUtilizationDateRange);
};

const WORKLOAD_MEMBER_SORT_ZOOM_MAP = {
  today: [ZOOM_LEVELS.DAY],
  this_week: [ZOOM_LEVELS.WEEK],
  next_two_weeks: [ZOOM_LEVELS.TWO_WEEKS],
  next_four_weeks: [ZOOM_LEVELS.MONTH]
};

export const GET_MEMBER_WORKLOAD_FETCH_END_DATE_MODIFIERS = (viewBy) =>
  GENERATE_CAPACITY_RANGE[WORKLOAD_MEMBER_SORT_ZOOM_MAP[viewBy]]?.().end_date;

export const GET_WORKLOAD_FETCH_END_DATE_MODIFIERS = (zoom) => {
  const numWeeks = getWeeksFromZoom(zoom) || 2;
  return [numWeeks, 'weeks'];
};

export const CAPACITY_PERCENT_OR_TOTAL_OPTIONS_DISPLAY = {
  percent: { value: 'percent', label: 'Percent' },
  total: { value: 'total', label: 'Hours' }
};

export const CAPACITY_PERCENT_OR_TOTAL_OPTIONS = [
  CAPACITY_PERCENT_OR_TOTAL_OPTIONS_DISPLAY.percent,
  CAPACITY_PERCENT_OR_TOTAL_OPTIONS_DISPLAY.total
];

export const checkDependency = (dependency, resizeEdge, action) =>
  (dependency && action === DRAG_ACTIONS.MOVE) ||
  dependency === DEPENDENCY_STRINGS.START_AND_END ||
  (resizeEdge === RESIZE_DIRECTIONS.LEFT &&
    dependency === DEPENDENCY_STRINGS.START) ||
  (resizeEdge === RESIZE_DIRECTIONS.RIGHT &&
    dependency === DEPENDENCY_STRINGS.END);

export const checkItemDependencies = (item, resizeEdge, action) => {
  if (item.dependencies) {
    return item.dependencies.some((dependency) =>
      checkDependency(dependency.dependency_type, resizeEdge, action)
    );
  } else if (item.phase_dependency) {
    return checkDependency(item.phase_dependency, resizeEdge, action);
  }
  return false;
};
