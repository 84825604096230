import theme from 'theme';
import { ReactComponent as TimerIcon } from 'icons/timer.svg';
import { ReactComponent as StopButton } from 'icons/stoptimer.svg';
import styled from 'styled-components';
import { ReactComponent as PlayButton } from 'icons/playtimer.svg';
import { ReactComponent as SmallPlayButton } from 'icons/small-play-button.svg';
import { useTimer } from 'appUtils/hooks/useTimer';

const TaskTimerCell = (props) => {
  const { toggleTimer, getIsTaskActiveTimer } = useTimer();

  const { task } = props;
  const isTaskActiveTimer = getIsTaskActiveTimer(task.id);
  const isTaskOnPersonalProject = task.project?.is_personal;

  const handleTimerClick = () => {
    toggleTimer(task);
  };

  return !isTaskOnPersonalProject && task.status ? (
    <TimerIconContainer
      data-for="app-tooltip"
      data-tip={
        isTaskActiveTimer
          ? 'Click to Stop Timer'
          : 'Click to Start Timer </br> View Timers in top right'
      }
      data-html
      data-effect="solid"
      data-class="center"
      onClick={handleTimerClick}
      isTaskActiveTimer={isTaskActiveTimer}
    >
      <HideOnRowHover isTaskActiveTimer={isTaskActiveTimer}>
        {isTaskActiveTimer ? (
          <PlayButton />
        ) : (
          <SmallPlayButton height={10} width={12} />
        )}
      </HideOnRowHover>
      <ShowOnRowHover>
        <HideOnHover>
          {isTaskActiveTimer ? (
            <PlayButton />
          ) : (
            <TimerOnHoverContainer>
              <TimerIcon color={theme.colors.colorLightGray15} />
            </TimerOnHoverContainer>
          )}
        </HideOnHover>
        <ShowOnHover>
          <StopButton height={22} width={22} />
        </ShowOnHover>
      </ShowOnRowHover>
    </TimerIconContainer>
  ) : null;
};

const HideOnHover = styled.div``;

const ShowOnHover = styled.div`
  display: none;
`;

export const TimerIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-left: -50px;
  &:hover {
    ${HideOnHover} {
      display: ${(props) => (props.isTaskActiveTimer ? 'none' : 'block')};
    }
    ${ShowOnHover} {
      display: ${(props) => (props.isTaskActiveTimer ? 'block' : 'none')};
    }
  }
  cursor: pointer;
`;

export const ShowOnRowHover = styled.div`
  display: none;
`;

export const HideOnRowHover = styled.div`
  display: flex;
  ${(props) =>
    props.isTaskActiveTimer &&
    `
    margin-top:3px;
`};
`;

export const TimerOnHoverContainer = styled.div`
  &:hover {
    path:nth-child(1) {
      stroke: ${theme.colors.colorRoyalBlue};
      fill: ${theme.colors.colorRoyalBlue};
    }
    path:nth-child(2) {
      stroke: ${theme.colors.colorRoyalBlue};
    }

    path:nth-child(3) {
      stroke: ${theme.colors.colorRoyalBlue};
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export default TaskTimerCell;
