import styled from 'styled-components';
import theme from 'theme';

export const Label = styled.label`
  align-items: center;
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  flex: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  width: fit-content;
`;

export const Field = styled.div`
  flex: 2;
  display: flex;
`;
