import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import {
  getBudgetModalOpen,
  getBudgetModalProjectId,
  getIsNewBudget,
  getProjectBudgetSettings,
  makeGetSelectedPhases
} from 'BudgetModule/selectors';
import {
  getSelectedProject,
  getSelectedAccountIds,
  getMe,
  getProjectHash,
  makeGetProjectBasicInfo,
  getAddMembersFormIsOpen,
  getSelectedTeamId
} from 'selectors';
import {
  closeBudgetModal,
  openBudgetModal,
  fetchRates,
  fetchProjectBudgetViewSettings,
  fetchMemberBudgets,
  fetchPositions,
  fetchMemberPositions
} from 'BudgetModule/actionCreators';
import { fetchEntityOptions } from 'EntityOptionsModule/actionCreators';
import {
  fetchProjectById,
  fetchPhases,
  fetchAccess,
  checkHasTimeEntries,
  openAccessModal
} from 'actionCreators';
import GlobalActivityDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/GlobalActivityDropdown';
import ActivityModal from 'views/projectPlanner/ActivityModal';
import BudgetModalHeader from './BudgetModalHeader';
import RemoveModal from './RemoveModal';
import ArchiveMemberModal from './ArchiveMemberModal';
import RemoveMemberModal from './RemoveMemberModal';
import ActivityPhaseModal from 'BudgetModule/components/ActivityPhaseModal/ActivityPhaseModal';
import ModalContext from '../../../js/containers/ModalContext';
import PhaseModal from 'BudgetModule/components/PhaseModal/PhaseModal';
import ReactTooltip from 'react-tooltip';
import { ProjectMembers } from 'views';
import styled from 'styled-components';
import {
  MemberContainer,
  HoverContainer,
  BudgetViewersContainer
} from './styles';
import BudgetAccessModal from 'components/Permissions/Access/BudgetAccessModal';
import { buildAccessIdentifier } from 'appUtils/access';
import BudgetModalSidebarNav from './BudgetModalSidebarNav';
import BudgetMembersModal from './BudgetMembersModal';
import BudgetModalPermissionCheck from './BudgetModalPermissionCheck';
import BudgetVisibility from 'components/Permissions/Access/BudgetVisibilityIndicator';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoaderV2';

export const BudgetMembersModalContext = React.createContext({});

class BudgetModal extends React.Component {
  state = {
    removeModalOpen: false,
    settingsModalOpen: false,

    // state for BudgetMemberModal
    isOpenMembersModal: false,
    showSuggestionsOnMemberModalOpen: false
  };

  componentDidMount() {
    const {
      projectId,
      fetchProjectById,
      checkHasTimeEntries,
      phases,
      teamId,
      fetchPositions,
      fetchMemberPositions,
      fetchEntityOptions
    } = this.props;
    if (projectId) {
      this.fetchData(projectId);
      fetchProjectById(projectId);
      fetchEntityOptions({
        team_id: teamId,
        entity_id: projectId,
        entity_type: ENTITY_TYPES.project
      });
    }

    const permissions = this.getPermissions();

    if (phases?.length && permissions) {
      checkHasTimeEntries({
        team_id: teamId,
        phase_ids: phases.map((phase) => phase.id),
        permissions
      });
    }
    if (teamId) {
      fetchPositions({ teamId });
    }
    if (projectId && teamId) {
      fetchMemberPositions({ teamId, projectId });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      open,
      projectId,
      fetchProjectById,
      allProjects,
      checkHasTimeEntries,
      phases,
      teamId,
      fetchPositions,
      fetchMemberPositions,
      fetchEntityOptions
    } = this.props;
    const prevProject = prevProps.allProjects[prevProps.projectId];
    const project = allProjects[projectId];

    if (prevProps.open !== open && projectId) {
      fetchProjectById(projectId);
    }
    const prevPhaseIds = prevProps.phases.map((phase) => phase.id);
    const currPhaseIds = phases.map((phase) => phase.id);
    const shouldCheckHasTimeEntries = !isEqual(currPhaseIds, prevPhaseIds);

    const permissions = this.getPermissions();

    if (phases?.length && shouldCheckHasTimeEntries && permissions) {
      checkHasTimeEntries({
        team_id: teamId,
        phase_ids: phases.map((phase) => phase.id),
        permissions
      });
    }

    if (
      projectId &&
      (prevProps.projectId !== projectId ||
        prevProject?.member_account_ids !== project?.member_account_ids ||
        prevProps?.project_membership !== project?.project_membership)
    ) {
      this.fetchData(projectId);
      if (teamId) {
        fetchMemberPositions({ teamId, projectId });
        fetchEntityOptions({
          team_id: teamId,
          entity_id: projectId,
          entity_type: ENTITY_TYPES.project
        });
      }
    }
    if (teamId && prevProps.teamId !== teamId) {
      fetchPositions({ teamId });
      if (projectId) {
        fetchMemberPositions({ teamId, projectId });
      }
    }
  }

  fetchData = (projectId) => {
    const { fetchProjectBudgetViewSettings, fetchPhases, fetchMemberBudgets } =
      this.props;
    fetchProjectBudgetViewSettings({ projectId });
    fetchPhases({ projectId });
    fetchMemberBudgets({ projectId });
  };

  membersInstance = React.createRef();

  static contextType = ModalContext;

  getPermissions = () => {
    const { teamId, projectId } = this.props;

    if (!teamId) return;

    return {
      teamId,
      projectId,
      mine: false
    };
  };

  handleOpen = () => {
    const { teamId, projectId, fetchRates, fetchAccess } = this.props;

    if (teamId) {
      fetchRates({ teamId });
      fetchAccess({
        actableId: teamId,
        actableType: 'Team',
        actionType: 'activity_phase_schedule_bars'
      });
    }

    if (projectId) {
      fetchAccess({
        actableId: projectId,
        actableType: 'Project',
        actionType: 'budget_ui'
      });
    }
  };

  handleClose = (e) => {
    const { closeBudgetModal } = this.props;
    const { getModal } = this.context;
    const modal = getModal();
    if (modal && modal.contains(e.target)) {
      return;
    }
    closeBudgetModal({});
  };

  openRemoveModal = () => {
    this.setState({ removeModalOpen: true });
  };

  closeRemoveModal = () => {
    this.setState({ removeModalOpen: false });
  };

  openSettingsModal = () => {
    this.setState({ settingsModalOpen: true });
  };

  closeSettingsModal = () => {
    this.setState({ settingsModalOpen: false });
  };

  openMembersModal = ({ showSuggestionsOnOpen = false } = {}) => {
    this.setState({
      isOpenMembersModal: true,
      showSuggestionsOnMemberModalOpen: showSuggestionsOnOpen
    });
  };

  closeMembersModal = () => {
    this.setState({ isOpenMembersModal: false });
  };

  removeMember = () => {
    setTimeout(this.handleClose, 100); // better cascaded close animation
  };

  buildWorkloadAccessProps = ({ actableType, actableId, actionType }) => {
    const accessIdentifier = buildAccessIdentifier({
      actableType,
      actableId,
      actionType
    });
    if (!this.workloadAccessMemo[accessIdentifier]) {
      this.workloadAccessMemo[accessIdentifier] = {
        accessIdentifier
      };
    }
    return this.workloadAccessMemo[accessIdentifier];
  };

  workloadAccessMemo = {};

  getProjectBudget = () => {
    const { project, projectId, allProjects } = this.props;
    const projectBudget =
      `${project?.id}` === `${projectId}` ? project : allProjects[projectId];
    return projectBudget;
  };

  render() {
    const {
      open,
      projectId,
      projectSelectedAccountIds,
      me,
      project,
      allProjects,
      teamId,
      children,
      budgetSettings,
      isOpenAddMembersModal,
      openAccessModal
    } = this.props;
    const projectBudget = this.getProjectBudget();

    const isDollars = budgetSettings?.estimated_cost;
    const { removeModalOpen, isOpenMembersModal } = this.state;

    // temporary fix for budget modal having incorrect project on first load
    const isLoading = +project?.id !== +projectId;

    // BudgetMembersModal is required to be open from phase, activity and scope rows on Time tab
    const contextValue = {
      openMembersModal: this.openMembersModal
    };

    return (
      <StyledModal
        isOpen={open}
        toggle={this.handleClose}
        className="budget-modal"
        wrapClassName="budget-modal-wrapper"
        onEnter={this.handleOpen}
      >
        <BudgetModalPermissionCheck projectId={projectId}>
          <ReactTooltip id="budget-phase-tooltip" effect="solid" place="top" />
          <BudgetModalHeader
            openRemoveModal={this.openRemoveModal}
            openSettingsModal={this.openSettingsModal}
            closeRemoveModal={this.closeRemoveModal}
            openAddMembersForm={this.openMembersModal}
            isLoading={isLoading}
          />
          {projectBudget && (
            <MemberContainer ref={this.membersInstance}>
              <SkeletonLoader
                isLoading={isLoading}
                height={20}
                width={80}
                style={{ marginRight: 3 }}
              >
                <HoverContainer>
                  <ProjectMembers
                    me={me}
                    selectedAccountIds={projectSelectedAccountIds}
                    project={projectBudget}
                    projectId={projectBudget?.id}
                    target={this.membersInstance}
                    inBudgetModal
                    isDollars={isDollars}
                    openMembersModal={this.openMembersModal}
                  />{' '}
                </HoverContainer>
                <span style={{ padding: '0 2px' }}>|</span>
                <HoverContainer>
                  <BudgetViewersContainer
                    onClick={() => {
                      openAccessModal({
                        modalIdentifier: buildAccessIdentifier({
                          actableType: 'Project',
                          actableId: projectId,
                          actionType: 'budget_ui'
                        })
                      });
                    }}
                  >
                    <BudgetVisibility
                      accessIdentifier={buildAccessIdentifier({
                        actableType: 'Project',
                        actableId: projectId,
                        actionType: 'budget_ui'
                      })}
                    />
                  </BudgetViewersContainer>
                </HoverContainer>
              </SkeletonLoader>
            </MemberContainer>
          )}

          {/* Nav and body */}
          <StyledModalBody>
            <BudgetModalSidebarNav />
            <BudgetMembersModalContext.Provider value={contextValue}>
              <MainContentContainer>
                {isLoading ? null : children}
              </MainContentContainer>
            </BudgetMembersModalContext.Provider>
            {/* matches flex value of sidebar nav, for keeping main content centered */}
            <div style={{ flex: 0.4 }} />
          </StyledModalBody>

          {/* Modals */}
          <RemoveModal
            isOpen={removeModalOpen}
            handleClose={this.closeRemoveModal}
            handleConfirm={this.removeMember}
          />
          <RemoveMemberModal />
          <ArchiveMemberModal />
          <BudgetAccessModal
            accessIdentifier={buildAccessIdentifier({
              actableType: 'Project',
              actableId: projectId,
              actionType: 'budget_ui'
            })}
            workloadAccessProps={this.buildWorkloadAccessProps({
              actableType: 'Team',
              actableId: teamId,
              actionType: 'activity_phase_schedule_bars'
            })}
            projectId={projectId}
            project={projectBudget}
          />
          {isOpenMembersModal && (
            <BudgetMembersModal
              isOpen
              closeMembersModal={this.closeMembersModal}
              project={projectBudget}
              showSuggestionsOnOpen={
                this.state.showSuggestionsOnMemberModalOpen
              }
              isOpenMembersModal={isOpenMembersModal}
            />
          )}
          <GlobalActivityDropdown />
          <ActivityPhaseModal />
          <ActivityModal />
          <PhaseModal />
        </BudgetModalPermissionCheck>
      </StyledModal>
    );
  }
}

BudgetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeBudgetModal: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
  const getProjectInfo = makeGetProjectBasicInfo();
  const getSelectedPhases = makeGetSelectedPhases();
  const mapStateToProps = (state, ownProps) => ({
    projectInfo: getProjectInfo(state, ownProps),
    open: getBudgetModalOpen(state) || ownProps.isOpen,
    projectId: getBudgetModalProjectId(state, ownProps),
    projectSelectedAccountIds: getSelectedAccountIds(state),
    isNewBudget: getIsNewBudget(state),
    isOpenAddMembersModal: getAddMembersFormIsOpen(state),
    me: getMe(state),
    project: getSelectedProject(state),
    allProjects: getProjectHash(state),
    budgetSettings: getProjectBudgetSettings(state, ownProps),
    phases: getSelectedPhases(state, ownProps),
    teamId: getSelectedTeamId(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  closeBudgetModal,
  openBudgetModal,
  fetchRates,
  fetchProjectById,
  checkHasTimeEntries,
  fetchProjectBudgetViewSettings,
  fetchPhases,
  fetchAccess,
  fetchMemberBudgets,
  openAccessModal,
  fetchPositions,
  fetchMemberPositions,
  fetchEntityOptions
};

export default withRouter(
  connect(makeMapStateToProps, mapDispatchToProps)(BudgetModal)
);

/* ------------------------------------ - ----------------------------------- */

const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex: 1;
  min-height: 0;
  justify-content: space-between;
  height: 100%;
  padding: 0;
  margin: 0 22px;
  .styled-nav-link {
    padding-left: 12px;
  }

  .styled-nav-link.active {
    border-width: 4px;
    padding-left: 7px;
  }
`;

const StyledModal = styled(Modal)`
  .members-count:hover:before {
    background-color: unset;
  }
`;

const MainContentContainer = styled.div`
  padding-top: 92px;
  min-width: 0;
  height: 100%;
`;
