import { select } from 'redux-saga/effects';
import { getAuthToken } from 'AuthenticationModule/selectors';
import {
  accountsSuggestionActionCreatorsMap,
  accountsSuggestionActions
} from 'SuggestionModule/actionCreators';
import { accountsSuggestionApis } from 'SuggestionModule/api';
import { fetchEntity } from 'sagas/generics';

function* fetchAccountsSuggestionForProjects(
  action: ReturnType<
    typeof accountsSuggestionActions.fetchAccountsSuggestionForProjects
  >
) {
  const token = yield select(getAuthToken);

  const params = {
    requests: action.payload.requests
  };

  yield fetchEntity(
    accountsSuggestionActionCreatorsMap.fetchAccountsSuggestionForProjects,
    accountsSuggestionApis.fetchAccountsSuggestionForProjects,
    null,
    [token, params],
    action
  );
}

function* fetchAccountsSuggestionForPhases(
  action: ReturnType<
    typeof accountsSuggestionActions.fetchAccountsSuggestionForPhases
  >
) {
  const token = yield select(getAuthToken);

  const { requests } = action.payload;

  const params = {
    requests
  };

  yield fetchEntity(
    accountsSuggestionActionCreatorsMap.fetchAccountsSuggestionForPhases,
    accountsSuggestionApis.fetchAccountsSuggestionForPhases,
    null,
    [token, params],
    action
  );
}

/* -------------------------------------------------------------------------- */
export const accountsSuggestionWorker = {
  fetchAccountsSuggestionForProjects,
  fetchAccountsSuggestionForPhases
};
