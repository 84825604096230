import {
  useState,
  useRef,
  useEffect,
  useMemo,
  ReactNode,
  useCallback
} from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { createPhaseMembers, openMilestoneModal } from 'actionCreators';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import { getProjectHash } from 'ProjectsModule/selectors';
import useUnassignedRolesByProjectAndPhases, {
  ProjectUnassignedMemberBudgetWithPosition,
  PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
} from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { useMultiStepFlyout } from 'components/MultiStepFlyout/useMultiStepFlyout';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { useSelector } from 'react-redux';
import { PhaseWithDefault } from 'ProjectsModule/phases/selectors/types';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { TextButtonWithBorder, BlueSubmitButton } from 'components/styles';
import EllipsisText from 'components/EllipsisText';
import cn from 'classnames';
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { openRemoveMemberModal } from 'BudgetModule/actionCreators';
import RemoveMemberModal from 'BudgetModule/components/BudgetModal/RemoveMemberModal';

const itemFilter = (item, searchWords) =>
  filterItemWithWhiteSpace({
    searchWords,
    item,
    filterKeysArray: ['name']
  });

const emptyArr = [];
const emptyObj = {};

type PhaseItem = PhaseWithDefault & {
  hasUnassignedRole: boolean;
  toBeAdded?: boolean;
};

const addUnassignedRoleToPhasesChainId =
  'add-unassigned-role-to-phases-chain-id';

/**
 * This hook does not take into account phases that have activity phases
 */
const useAddRemoveUnassignedRoleFromPhasesDropdown = ({
  unassignedMemberBudgetId,
  projectId,
  phasesOrder,
  shouldCreatePhaseMembersActionRefetch = true
}: {
  unassignedMemberBudgetId: Nullable<number>;
  projectId: Nullable<number>;
  phasesOrder?: number[];
  shouldCreatePhaseMembersActionRefetch?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const dispatchChain = useDispatchChain();
  const [addedPhases, setAddPhases] = useState<number[]>(emptyArr);
  const phaseHash = useSelector(getFlatPhasesHash);
  const { MultiStepFlyout, openFlyout, closeFlyout, isOpen } =
    useMultiStepFlyout();
  const projectHash = useAppSelector(getProjectHash);
  const {
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId
  } = useUnassignedRolesByProjectAndPhases({ projectId });
  const { status } = useRequestStatus({
    requestStatusId: addUnassignedRoleToPhasesChainId
  });
  const {
    isExecuting: isAddingUnassignedRoleToPhases,
    isSuccess: addUnassignedRoleToPhasesSuccess
  } = status || {};

  // Closing flyout will reset state
  useEffect(() => {
    if (!isOpen) {
      setAddPhases(emptyArr);
    }
  }, [isOpen]);

  const project = projectId ? projectHash[projectId] : undefined;

  // Default to phase_orders of the provided projectId
  const phasesOrderToUse =
    phasesOrder || (projectId ? project?.phase_orders || emptyArr : emptyArr);

  const renderItems = ({ item }: { item: PhaseItem }) => {
    const { toBeAdded, hasUnassignedRole } = item;

    const textToUse = toBeAdded
      ? 'Added'
      : hasUnassignedRole
      ? 'Remove'
      : 'Add';

    return (
      <StyledItem>
        <EllipsisText className="phase-name">
          <BudgetPhaseMilestoneIcon
            className={undefined}
            strokeWidth={undefined}
          />{' '}
          <span>{item.name}</span>
        </EllipsisText>
        <div
          className={cn({
            'to-be-added': item.toBeAdded,
            'has-role': item.hasUnassignedRole,
            'has-no-role': !item.hasUnassignedRole
          })}
        >
          {textToUse}
        </div>
      </StyledItem>
    );
  };

  const handleSelect = (e, { item }: { item: PhaseItem }) => {
    if (item.hasUnassignedRole) {
      // Open up the modal to remove
      closeFlyout();
      dispatch(
        openRemoveMemberModal({
          memberBudget: {
            isUnassigned: true,
            phaseMembershipId:
              getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
                { phaseId: item.id, memberBudgetId: unassignedMemberBudgetId }
              )?.id,
            phaseId: item.id,
            projectId
          }
        })
      );
    } else if (item.toBeAdded) {
      // Remove from addedPhases
      setAddPhases((prev) => prev.filter((id) => id !== item.id));
    } else {
      // Not have unassigned role
      // Add to addedPhases
      setAddPhases((prev) => [...prev, item.id]);
    }
  };

  const onAddUnassignRoleToPhases = useCallback(() => {
    if (!projectId || !addedPhases?.length || !unassignedMemberBudgetId) {
      closeFlyout();
      return;
    }

    const actions = addedPhases.map((phaseId) => {
      return createPhaseMembers({
        projectId,
        phaseId,
        memberBudgetIds: [unassignedMemberBudgetId],
        shouldRefetch: shouldCreatePhaseMembersActionRefetch
      });
    });

    dispatchChain([actions], {
      chainId: addUnassignedRoleToPhasesChainId,
      continueOnFailure: true,
      continueOnCancellation: true,
      cleanupChainStatuses: true
    } as any);

    closeFlyout();
  }, [
    projectId,
    addedPhases,
    unassignedMemberBudgetId,
    dispatchChain,
    closeFlyout,
    shouldCreatePhaseMembersActionRefetch
  ]);

  const renderHeaderButton = (): ReactNode => {
    return (
      <HeaderButtonContainer onClick={closeFlyout}>
        <TextButtonWithBorder>Cancel</TextButtonWithBorder>
        <BlueSubmitButton fontWeight={600} onClick={onAddUnassignRoleToPhases}>
          Add
        </BlueSubmitButton>
      </HeaderButtonContainer>
    );
  };

  const onFooterClick = ({ e }) => {
    if (project) {
      closeFlyout();
      dispatch(openMilestoneModal({ project }));
    }
  };

  const items = useMemo(() => {
    const phasesContainUnassignedRole: PhaseItem[] = [];
    const phasesNotContainUnassignedRole: PhaseItem[] = [];

    // Phases to add this unassigned role to
    const phasesIdsNotContainUnassignedRoleToBeAddedSet: Set<number> = new Set(
      addedPhases
    );

    phasesOrderToUse.forEach((id) => {
      const phaseMembership =
        getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
          { phaseId: id, memberBudgetId: unassignedMemberBudgetId }
        );

      const phase = phaseHash[id];

      if (
        phase &&
        !phasesIdsNotContainUnassignedRoleToBeAddedSet.has(id) && // Must not be in the list of phases to be added
        !phase.archived && // Not archived
        phase.activity_order.length === 0 // Does not have activity phases
      ) {
        // Already added
        if (phaseMembership) {
          phasesContainUnassignedRole.push({
            ...phase,
            hasUnassignedRole: true
          });
        } else {
          // Not added
          phasesNotContainUnassignedRole.push({
            ...phase,
            hasUnassignedRole: false
          });
        }
      }
    });

    return [
      ...phasesContainUnassignedRole,
      ...Array.from(phasesIdsNotContainUnassignedRoleToBeAddedSet).map(
        (id) => ({ ...phaseHash[id], toBeAdded: true })
      ),
      ...phasesNotContainUnassignedRole
    ];
  }, [
    addedPhases,
    phasesOrderToUse,
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
    unassignedMemberBudgetId,
    phaseHash
  ]);

  return {
    AddRemoveUnassignedRoleFromPhasesDropdown: (
      <>
        <MultiStepFlyout
          items={items}
          copy={{
            headerInitial: 'Add/Remove Role from Phase',
            footerInitial: (
              <StyledFooterContainer>
                <BudgetPhaseMilestoneIcon
                  className={undefined}
                  strokeWidth={undefined}
                />
                Manage Project Phases
              </StyledFooterContainer>
            ),
            searchPlaceholder: 'Type name or select below'
          }}
          idKey="id"
          renderItem={renderItems}
          editDisabled
          listWidth={468}
          itemHeight={48}
          isWhite
          searchEnabled
          renderHeaderButton={renderHeaderButton}
          closePopover={closeFlyout}
          handleSelect={handleSelect}
          itemFilter={itemFilter}
          onFooterClick={onFooterClick}
          popoverClassName="add-remove-unassigned-role-from-phases-dropdown"
        />
        <RemoveMemberModal />
      </>
    ),
    isAddingUnassignedRoleToPhases,
    addUnassignedRoleToPhasesSuccess,
    openAddRemoveUnassignedRoleFromPhasesDropdown: openFlyout,
    closeAddRemoveUnassignedRoleFromPhasesDropdown: closeFlyout
  };
};

export default useAddRemoveUnassignedRoleFromPhasesDropdown;

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;

  .to-be-added {
    color: ${theme.colors.colorCalendarBlue};
  }
  .has-role {
    color: ${theme.colors.colorCalendarRed};
  }
  .has-no-role {
    color: ${theme.colors.colorCalendarBlue};
  }
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledFooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  cursor: pointer;

  path {
    stroke: ${theme.colors.colorRoyalBlue};
  }
`;
