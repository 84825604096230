import { changeEntity } from './generics';
import { select } from 'redux-saga/effects';
import { api } from '../service';
import * as entityActions from '../actions';
import { getAuthToken } from 'selectors';

export function* fetchPredictionHours(action) {
  const { account_ids, forecast_horizon } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.predictionHoursFetch,
    api.fetchPredictionHours,
    [token, account_ids, forecast_horizon],
    action
  );
}
