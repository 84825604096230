import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';
import { RequireAtLeastOne } from 'type-fest';
import {
  StyledMemberCard,
  MemberInfo,
  MemberName,
  MemberRateDescription,
  SelectRoleContainer,
  ArchivedLabel
} from './styles';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import MemberCardContentLoader from './MemberCardContentLoader';
import {
  getSelectedAccountIdsHash,
  makeGetTeamMembershipByAccountId as makeGetTeamMembershipByAccountIdOld
} from 'selectors';
import {
  makeGetActiveRateByMemberBudget,
  getTeamRates,
  getProjectBudgetSettings,
  getDefaultTeamRates
} from 'BudgetModule/selectors';
import { fetchMemberRates } from 'BudgetModule/actionCreators';
import cn from 'classnames';
import UnassignedMemberInfo from './UnassignedMemberInfo';
import SetDefaultPromptModal from './SetDefaultPromptModal';
import useCurrentRates from 'BudgetModule/hooks/useCurrentRates';
import useCurrentRoles from 'BudgetModule/hooks/useCurrentRoles';
import {
  makeGetDefaultCurrencyCodeById,
  getTeamCurrencyCodeByTeamId
} from 'CurrencyModule/selectors';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
import { currencyFormatter } from 'appUtils/hooks/useFormatCurrency/utils';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import { makeGetTeamMembershipByAccountId } from 'TeamsModule/selectors';
import { getMe } from 'UsersModule/selectors';
import { getUserIsAdmin } from 'PermissionsModule/selectors';
import { MemberBudget } from 'models/memberBudget';

const RoleRateRow = styled(MemberRateDescription)`
  display: flex;
  align-items: center;

  ${SelectRoleContainer} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

type MemberCardProps = PropsFromRedux &
  RequireAtLeastOne<
    {
      accountId?: number;
      memberBudget?: MemberBudget;
    } & {
      // Seems not used
      setMemberInfoRef?: never;
      isInTable?: boolean;
      openAssignBudgetDropdown?: () => void;
      isMemberArchived?: boolean;
      handleRateClick?: () => void;
      handleRoleClick?: () => void;
      projectId?: number;
      phaseId?: number;
      openRateSelectionType?: 'rates' | 'teamRates';
      useTeamRate?: boolean;
      useTeamRole?: boolean;
      showHourlyRate?: boolean;
      initialsSize?: string;
      isAdmin?: boolean;
      testIdPrefix?: string;
      currencyCode?: string;
      isSkipDefaultPrompt?: boolean;
      showRoleRateTooltip?: boolean;
    }
  >;

const MemberCard = ({
  accountId,
  // To suprress TS warning
  memberBudget = {
    account_id: 0,
    end_date: null,
    id: 0,
    'is_discarded?': false,
    member_positions: [],
    member_rates: [],
    position_id: 0,
    position_number: 0,
    project_id: 0,
    project_membership: {
      account_id: null,
      created_at: '',
      discarded_at: null,
      id: 0,
      is_request: false,
      member_budget_id: 0,
      notify_comments: false,
      notify_notes: false,
      notify_tasks: false,
      position_id: 0,
      project_id: 0,
      project_role: undefined,
      starred: false,
      team_id: 0,
      team_membership_id: null,
      updated_at: ''
    },
    radius: null,
    required_hours: '',
    skills: undefined,
    start_date: '',
    team_id: 0
  },
  teamMembership,
  me,
  setMemberInfoRef,
  selectedAccountIdsHash,
  isInTable = false,
  openAssignBudgetDropdown,
  isMemberArchived,
  handleRateClick,
  handleRoleClick,
  fetchMemberRates,
  projectId,
  phaseId,
  openRateSelectionType,
  useTeamRate,
  useTeamRole,
  showHourlyRate,
  initialsSize = 'medium',
  isAdmin,
  testIdPrefix,
  projectCurrencyCode,
  teamCurrencyCode,
  isSkipDefaultPrompt = false,
  showRoleRateTooltip = true
}: MemberCardProps) => {
  const containerRef = React.useRef(null);
  const [defaultPromptType, setDefaultPromptType] = useState<
    'rate' | 'role' | null
  >(null);
  const isLoading = false;
  const isUnassigned = !teamMembership;
  const { teamRate, memberRate } = useCurrentRates({ memberBudget, accountId });
  const { memberRole, teamRole } = useCurrentRoles({ memberBudget, accountId });
  const { isHoursOnly } = useIsHoursOnly();

  const showRateContent = showHourlyRate && !isHoursOnly;

  const rateToUse = useTeamRate ? teamRate : memberRate;
  const roleToUse = useTeamRole ? teamRole : memberRole;
  const hasRole = roleToUse && !roleToUse?.is_default;

  const onUnassignedClick = (e) => {
    e.preventDefault();
    if (isUnassigned && isInTable) {
      openAssignBudgetDropdown?.();
    }
  };

  const onRateClick = (e) => {
    e.stopPropagation();
    if (isAdmin && !teamRate && !isSkipDefaultPrompt) {
      setDefaultPromptType('rate');
    } else if (handleRateClick) {
      handleRateClick();
    }
  };

  const onRoleClick = (e) => {
    e.stopPropagation();
    if (isAdmin && teamRole?.is_default && !isSkipDefaultPrompt) {
      setDefaultPromptType('role');
    } else if (handleRoleClick) {
      handleRoleClick();
    }
  };

  // default to USD for team rates until currency by team is ready
  // Jira Ticket: https://mosaic-ai.atlassian.net/browse/WEB-2956
  const currencyCodeToUse = useTeamRate
    ? teamCurrencyCode
    : projectCurrencyCode;

  const formattedRate =
    currencyCodeToUse && rateToUse?.rate
      ? currencyFormatter({
          currencyCode: currencyCodeToUse,
          options: {
            minimumFractionDigits: 0
          }
        }).format(rateToUse?.rate)
      : '';

  return (
    <>
      {isLoading ? (
        <StyledMemberCard>
          <MemberCardContentLoader />
        </StyledMemberCard>
      ) : (
        <StyledMemberCard
          ref={setMemberInfoRef}
          // @ts-expect-error component not typed
          isInTable={isInTable}
          isClickable={isUnassigned && isInTable}
          isMemberArchived={isMemberArchived}
          className="member-card"
        >
          <div
            onClick={isUnassigned && isInTable ? onUnassignedClick : undefined}
          >
            <MemberInitials
              addNewNote={true}
              member={teamMembership}
              size={initialsSize}
              classes={cn('regular-member-no-hover selected', {
                'logged-member-no-hover':
                  teamMembership && teamMembership.account.id === me?.id,
                'unassigned-icon no-border': isUnassigned,
                selected:
                  selectedAccountIdsHash &&
                  teamMembership &&
                  !!selectedAccountIdsHash[teamMembership.account.id]
              })}
            />
          </div>
          {isUnassigned ? (
            <UnassignedMemberInfo
              memberBudget={memberBudget}
              rate={memberRate}
              handleRateClick={handleRateClick}
              isInTable={isInTable}
              fetchMemberRates={fetchMemberRates}
              phaseId={phaseId}
              openRateSelectionType={openRateSelectionType}
              projectId={projectId}
              showHourlyRate={showRateContent}
              testIdPrefix={testIdPrefix}
            />
          ) : (
            <MemberInfo ref={containerRef} className="member-info">
              {isMemberArchived && <ArchivedLabel>ARCHIVED</ArchivedLabel>}
              <MemberName
                data-for="app-tooltip"
                data-tip={
                  teamMembership?.account.name.length > 9
                    ? teamMembership.account.name
                    : ''
                }
                data-delay-show="700"
                data-effect="solid"
                className="member-card-name"
                data-testid={`${testIdPrefix ? `${testIdPrefix}-` : ''}${
                  teamMembership.account.name
                }`}
              >
                {teamMembership && teamMembership.account.name}
              </MemberName>

              {(openRateSelectionType || isInTable) && showRateContent && (
                <RoleRateRow className="member-rate-description">
                  <SelectRoleContainer
                    data-for="app-tooltip"
                    data-tip={hasRole ? roleToUse.name : ''}
                    data-tip-disable={!showRoleRateTooltip}
                    data-class="mw-250"
                    // @ts-expect-error component not typed
                    unset={hasRole}
                    isInTable
                    className={cn('select-role-container', {
                      inTable: isInTable
                    })}
                    onClick={onRoleClick}
                    data-testid={`role-${phaseId}-${teamMembership.account.name}`}
                    maxWidth={showRateContent ? '64%' : '100%'}
                  >
                    {hasRole
                      ? roleToUse.name
                      : handleRoleClick
                      ? 'Set Role'
                      : ''}
                  </SelectRoleContainer>
                  {showRateContent && (
                    <>
                      <span>&nbsp;|&nbsp;</span>
                      <SelectRoleContainer
                        data-for="app-tooltip"
                        data-tip={rateToUse ? `${formattedRate}/h` : ''}
                        data-tip-disable={!showRoleRateTooltip}
                        data-class="mw-250"
                        // @ts-expect-error component not typed
                        unset={!!rateToUse}
                        isInTable
                        onClick={onRateClick}
                        data-testid={`rate-${phaseId}-${teamMembership.account.name}`}
                      >
                        {rateToUse ? `${formattedRate}/h` : 'Set Rate'}
                      </SelectRoleContainer>
                    </>
                  )}
                </RoleRateRow>
              )}
            </MemberInfo>
          )}
        </StyledMemberCard>
      )}

      {defaultPromptType && (
        <SetDefaultPromptModal
          onClose={() => setDefaultPromptType(null)}
          type={defaultPromptType}
          onCancel={
            defaultPromptType === 'role' ? handleRoleClick : handleRateClick
          }
        />
      )}
    </>
  );
};

const makeMapStateToProps = () => {
  const getTeamMembershipByAccountId = makeGetTeamMembershipByAccountIdOld();
  const getActiveRateByMemberBudget = makeGetActiveRateByMemberBudget();
  const getDefaultCurrencyCode = makeGetDefaultCurrencyCodeById();
  const mapStateToProps = (state, ownProps) => {
    return {
      teamMembership:
        ownProps.member || ownProps.accountId
          ? getTeamMembershipByAccountId(state, ownProps)
          : undefined,
      me: getMe(state),
      selectedAccountIdsHash: getSelectedAccountIdsHash(state),
      rates: getTeamRates(state),
      activeRate: getActiveRateByMemberBudget(state, ownProps),
      budgetSettings: getProjectBudgetSettings(state, ownProps),
      defaultTeamRates: getDefaultTeamRates(state),
      isAdmin: getUserIsAdmin(state),
      projectCurrencyCode: getDefaultCurrencyCode(state, {
        entityType: ENTITY_TYPES.project,
        entityId: ownProps.projectId
      }),
      teamCurrencyCode: getTeamCurrencyCodeByTeamId(state)
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = {
  fetchMemberRates
};

const connector = connect(makeMapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

// add this extra step in constructing the component definition to have proper types inferred for any other component importing this component to use
const component = connector(MemberCard);
export default component;
