import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { predictWorkdays, handleErrorMessage } from 'actionCreators';
import { getCalendarPrediction, getSelectedTeamId, getMe } from 'selectors';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import moment from 'moment';
import {
  ReportsDateContainer,
  StyledDates,
  StyledCalendarIcon
} from 'ReportsModule/components/styles';
import { NavLeft, NavRight, renderMonthElement } from './Nav';
import {
  CalendarContainer,
  CalendarStartDependencyLinkIconContainer,
  CalendarEndDependencyLinkIconContainer,
  CalendarDateBottomTextContainer,
  CalendarDayContainer,
  DoneButton,
  CancelButton
} from './WorkplanCalendarStyles';
import {
  DEPENDENCY_STRINGS,
  WORK_PLAN_CALENDAR_OPTION_TYPES,
  WORK_PLAN_CALENDAR_SELECT_OPTION_TYPES
} from 'appConstants/workload';
import { fetchUtilizations } from 'UtilizationModule/actionCreators';
import { isInTimePicker } from 'appUtils/popoverClicks';
import { getDay } from 'date-fns';
import { GENERIC_ACTION } from 'appConstants';
import { calculateWeeklyDateRange } from './utils';
import WorkplanCalendarPanel from './WorkplanCalendarPanel';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';
import theme from 'theme';
import {
  isStartDateDependency,
  isEndDateDependency,
  isStartAndEndDateDependency,
  isSetStartAndEndDependency,
  isSetStartOrEndDependency,
  dependencySetterToStateMapper,
  dependencyStateToSetterMapper
} from 'appUtils/newDependencies';
import { generateRowsTooltipData } from 'appUtils/tooltipDisplayUtils';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { formatNumWithMaxTwoDecimals } from 'appUtils/formatUtils';
import { MOMENT_ISO_DATE, MOMENT_USA_DATE } from 'appConstants/date';
const noOp = () => {};

const dayRenderer = (
  day,
  modifier,
  dayUtilization,
  dayCapacity,
  utilizationPercentage,
  isPTO,
  isHoliday,
  capacityOnWorkPlanCalendar,
  isDayStartDependency = false,
  isDayEndDependency = false
) => {
  const classNames = [];
  if (
    modifier.has('selected-start') ||
    modifier.has('selected-end') ||
    modifier.has('hovered-offset') ||
    modifier.has('hovered')
  ) {
    classNames.push('circleContainer');
  }

  if (getDay(day) === 6 || getDay(day) === 0) {
    classNames.push('weekend');
  }

  const dataTip = generateRowsTooltipData({
    rows: [
      {
        label: `${formatNumWithMaxTwoDecimals(dayUtilization)}h Planned`,
        fontColor: theme.colors.colorPureWhite,
        shouldInsertDivider: true
      },
      {
        label: `${formatNumWithMaxTwoDecimals(dayCapacity)}h Capacity`,
        fontColor: theme.colors.colorPureWhite
      }
    ]
  });

  if (
    utilizationPercentage > 100 &&
    !isPTO &&
    !isHoliday &&
    capacityOnWorkPlanCalendar
  ) {
    classNames.push('over-capacity');
  }

  const className = classNames?.reduce(
    (acc, cur, index) => acc + `${index ? ' ' : ''}${cur}`,
    ''
  );

  return (
    <CalendarDayContainer
      className={className}
      isPTO={isPTO}
      isHoliday={isHoliday}
      capacityOnWorkPlanCalendar={capacityOnWorkPlanCalendar}
      utilizationPercentage={Math.min(utilizationPercentage, 100)}
      data-for={'app-tooltip'}
      data-html
      data-effect="solid"
      data-class="blue-dark-tooltip"
      data-tip={dataTip}
      data-tip-disable={!capacityOnWorkPlanCalendar}
    >
      {isDayStartDependency && (
        <CalendarStartDependencyLinkIconContainer>
          <DependencyLinkIcon
            width={10}
            height={24}
            fillColor={theme.colors.colorCalendarBlue}
          />
        </CalendarStartDependencyLinkIconContainer>
      )}
      {day.format('D')}
      {isDayEndDependency && (
        <CalendarEndDependencyLinkIconContainer>
          <DependencyLinkIcon
            width={10}
            height={24}
            fillColor={theme.colors.colorCalendarBlue}
          />
        </CalendarEndDependencyLinkIconContainer>
      )}
      {(isHoliday || isPTO) && capacityOnWorkPlanCalendar && (
        <CalendarDateBottomTextContainer>
          {isHoliday ? 'OFF' : 'PTO'}
        </CalendarDateBottomTextContainer>
      )}
    </CalendarDayContainer>
  );
};

const DateRangeCalendar = ({
  itemStartDate,
  itemEndDate = null,
  itemWorkDays = 0,
  onTimeChange = noOp,
  onSubmit = noOp,
  isWeekly = false,
  isSingleDay = false,
  showWorkdays = false,
  customInput,
  showClear = false,
  onSetRange,
  showSetRange,
  onClose,
  hideInputs = false,
  startOpen = false,
  target = null,
  showInputs,
  labels,
  customLabel,
  shouldRenderFooter = false,
  workplanAllDay,
  workplanUseWeeklyPlanning,
  workplanIncludeWeekends,
  workplanTentative,
  workplanDailyHours,
  onWorkdaysChange = noOp,
  endDateFocus = false,
  disableDependency,
  phaseStartDate,
  phaseEndDate,
  initialDependency = { start: null, end: null },
  initialDependencyItem = { start: null, end: null },
  initialDependencyItemType = null,
  initialDependencyStepItem = null,
  phaseId,
  isOOO,
  accountId,
  phases,
  accountCapacities = {},
  accountUtilizations = {},
  accountPTODates = new Set(),
  additionalDailyHours = 0,
  item,
  itemType,
  holidayDates = {}
}) => {
  const { capacityOnWorkPlanCalendar } = useFeatureFlags();
  // setup State hooks

  // format props
  const momentStart = moment(itemStartDate).isValid()
    ? moment(itemStartDate)
    : null;
  const momentEnd = isSingleDay
    ? null
    : moment(itemEndDate).isValid()
    ? moment(itemEndDate)
    : null;

  // calendar modal open state
  const [isOpen, setIsOpen] = useState(startOpen);

  const dispatch = useDispatch();

  // calendar states
  // used as selected value but is not "saved" until done button is clicked
  const [startDate, setStartDate] = useState(momentStart);
  const [endDate, setEndDate] = useState(momentEnd);
  const [focusedInput, setFocusedInput] = useState(
    endDateFocus ? END_DATE : START_DATE
  );

  const [workDays, setWorkDays] = useState(itemWorkDays);
  const [dependency, setDependency] = useState({ start: null, end: null });
  const [dependencyItem, setDependencyItem] = useState({
    start: null,
    end: null
  });
  const [dependencyItemType, setDependencyItemType] = useState(null);
  const [dependencyStepItem, setDependencyStepItem] = useState(null);
  const calendarPrediction = useSelector(getCalendarPrediction);
  const teamId = useSelector(getSelectedTeamId);

  const [predictUpdate, setPredictUpdate] = useState(null);
  const [allDay, setAllDay] = useState(false);
  const [includeWeekends, setIncludeWeekends] = useState(false);
  const [isTentative, setIsTentative] = useState(false);
  const [isWeeklyPlanning, setIsWeeklyPlanning] = useState(false);

  const me = useSelector(getMe);

  // Setters and Getters
  const setStartDateAndString = (startDate) => {
    setStartDate(startDate);
  };

  const setEndDateAndString = (endDate) => {
    setEndDate(endDate);
  };

  const getWeeklyStartOffset = (day) => day.startOf('week');
  const getWeeklyEndOffset = (day) => day.endOf('week');

  const getWeeklyPlanningStartOffset = (day) =>
    focusedInput === START_DATE
      ? day.clone().startOf('week')
      : startDate.clone().add(2, 'days') || day;
  const getWeeklyPlanningEndOffset = (day) =>
    focusedInput === END_DATE
      ? day
          .clone()
          .endOf('week')
          .add(includeWeekends ? 0 : -2, 'days')
      : endDate?.clone() || day;

  const isWeeklyProps = isWeekly
    ? {
        startDateOffset: getWeeklyStartOffset,
        endDateOffset: getWeeklyEndOffset
      }
    : {};

  const isWeeklyPlanningProps = isWeeklyPlanning
    ? {
        startDateOffset: getWeeklyPlanningStartOffset,
        endDateOffset: getWeeklyPlanningEndOffset
      }
    : {};

  const getInitialMonth = () =>
    startDate && startDate.isValid() ? startDate : moment();

  const shouldRenderButtonPanel =
    showInputs || (!isSingleDay && !isWeekly) || showSetRange;

  const getIsDateValid = (date) => moment(date).isValid();

  // Workplan Calendar useEffects / state

  useEffect(() => {
    if (isOpen) {
      setDependency({
        start: initialDependency.start,
        end: initialDependency.end
      });
      setDependencyItem({
        start: initialDependencyItem.start,
        end: initialDependencyItem.end
      });
      setDependencyItemType(initialDependencyItemType);
      setDependencyStepItem(initialDependencyStepItem);
      setIncludeWeekends(workplanIncludeWeekends);
      setAllDay(workplanAllDay);
      setIsTentative(workplanTentative);
      setIsWeeklyPlanning(workplanUseWeeklyPlanning);
    }
  }, [
    initialDependency.start,
    initialDependency.end,
    initialDependencyItem.start,
    initialDependencyItem.end,
    workplanIncludeWeekends,
    workplanAllDay,
    workplanTentative,
    isOpen,
    workplanUseWeeklyPlanning,
    initialDependencyItemType,
    initialDependencyStepItem
  ]);

  useEffect(() => {
    if (isOpen && startDate && endDate && teamId && accountId) {
      dispatch(
        fetchUtilizations({
          startDate: startDate.format(MOMENT_USA_DATE),
          endDate: endDate.format(MOMENT_USA_DATE),
          accountIds: [accountId],
          teamId: teamId
        })
      );
    }
  }, [startDate, endDate, dispatch, teamId, accountId, isOpen]);

  useEffect(() => {
    if (
      !!startDate &&
      !!endDate &&
      startDate?.isValid?.() &&
      isOpen &&
      predictUpdate
    ) {
      dispatch(
        predictWorkdays({
          team_id: teamId,
          account_id: accountId || me?.id,
          start_date:
            predictUpdate !== 'start_date'
              ? startDate.format(MOMENT_USA_DATE)
              : undefined,
          end_date:
            predictUpdate !== 'end_date'
              ? endDate.isBefore(startDate)
                ? startDate.format(MOMENT_USA_DATE)
                : endDate.format(MOMENT_USA_DATE)
              : undefined,
          work_days: predictUpdate !== 'work_days' ? workDays : undefined,
          include_weekends: includeWeekends,
          is_pto: isOOO
        })
      );
    }
  }, [
    dispatch,
    startDate,
    endDate,
    workDays,
    includeWeekends,
    phaseId,
    allDay,
    isOOO,
    teamId,
    isOpen,
    predictUpdate,
    accountId,
    me
  ]);

  useEffect(() => {
    if (!!calendarPrediction && isOpen) {
      if (
        predictUpdate === 'start_date' &&
        !moment(calendarPrediction.start_date).isSame(moment(startDate))
      ) {
        setStartDateAndString(moment(calendarPrediction.start_date));
      }
      if (
        predictUpdate === 'end_date' &&
        !moment(calendarPrediction.end_date).isSame(moment(endDate))
      ) {
        setEndDateAndString(moment(calendarPrediction.end_date));
      }
      if (
        predictUpdate === 'work_days' &&
        calendarPrediction.work_days !== workDays
      ) {
        setWorkDays(calendarPrediction.work_days);
      }
    }
  }, [
    calendarPrediction,
    endDate,
    startDate,
    workDays,
    includeWeekends,
    isOpen,
    predictUpdate,
    isWeeklyPlanning
  ]);

  // Effects
  // call props.onTimeChange() anytime there is an update to the start date, end date, or workdays
  useEffect(() => {
    if (
      !moment(startDate).isSame(moment(itemStartDate), 'day') ||
      !moment(endDate).isSame(moment(itemEndDate), 'day') ||
      workDays !== itemWorkDays
    ) {
      onTimeChange({ startDate, endDate, workDays });
    }
  }, [
    startDate,
    endDate,
    workDays,
    itemStartDate,
    itemEndDate,
    itemWorkDays,
    onTimeChange
  ]);

  useEffect(() => {
    setIsOpen(startOpen);
  }, [startOpen]);

  // update state when recieve new props
  useEffect(() => {
    setStartDateAndString(
      getIsDateValid(itemStartDate) ? moment(itemStartDate) : null
    );
    setEndDateAndString(
      getIsDateValid(itemEndDate) ? moment(itemEndDate) : null
    );
    setWorkDays(itemWorkDays);
  }, [itemStartDate, itemEndDate, itemWorkDays]);

  useEffect(() => {
    if (isSingleDay) {
      setFocusedInput(START_DATE);
    } else if (
      isSetStartAndEndDependency({
        start: dependency.start,
        end: dependency.end
      })
    ) {
      setFocusedInput(null);
    } else if (isStartDateDependency(dependency.start)) {
      setFocusedInput(END_DATE);
    } else {
      setFocusedInput(START_DATE);
    }
  }, [isSingleDay, dependency.start, dependency.end]);

  useEffect(() => {
    if (
      !isSetStartOrEndDependency({
        start: initialDependency.start,
        end: initialDependency.end
      })
    ) {
      setFocusedInput(endDateFocus ? END_DATE : START_DATE);
    }
  }, [endDateFocus, isOpen, initialDependency.start, initialDependency.end]);

  // action Handler

  // callback for calendar when a selection is made.
  const handleDatesChange = ({
    startDate: newStartDate,
    endDate: newEndDate
  }) => {
    if (isSingleDay) {
      // Don't allow date change when there's a dependency
      if (!isSetStartOrEndDependency(dependency)) {
        setStartDateAndString(newStartDate);
        setEndDateAndString(newStartDate);
      }
    } else if (isWeekly) {
      setStartDateAndString(newStartDate);
      setEndDateAndString(newEndDate);
    } else if (isWeeklyPlanning) {
      setPredictUpdate('work_days');
      if (focusedInput === START_DATE) {
        const hasInvalidRange =
          newStartDate && endDate && newStartDate.isAfter(endDate);
        // when it has invalid range, correct end date
        const calculatedRange = calculateWeeklyDateRange({
          startDate: newStartDate,
          endDate: hasInvalidRange ? newStartDate : endDate
        });

        setStartDateAndString(calculatedRange.startDate);
        setEndDateAndString(calculatedRange.endDate);
        setFocusedInput(END_DATE);
      } else if (focusedInput === END_DATE) {
        const hasInvalidRange =
          startDate && newEndDate && newEndDate.isBefore(startDate);
        // when it has invalid range, correct start date
        const calculatedRange = calculateWeeklyDateRange({
          startDate: hasInvalidRange ? newEndDate : startDate,
          endDate: newEndDate
        });

        setStartDateAndString(calculatedRange.startDate);
        setEndDateAndString(calculatedRange.endDate);
        setFocusedInput(START_DATE);
      }
    } else if (focusedInput === START_DATE) {
      setPredictUpdate('work_days');

      if (endDate && moment(newStartDate).isAfter(endDate)) {
        // don't allow start date be after end dependency date
        if (!isEndDateDependency(dependency.end)) {
          setStartDateAndString(newStartDate);
          setEndDateAndString(undefined);
        }
      } else {
        setStartDateAndString(newStartDate);
      }
    } else if (focusedInput === END_DATE) {
      setPredictUpdate('work_days');

      // when selected end date is before start date, only newStartDate has value
      // don't allow end date before start dependency date
      if (newStartDate && !isStartDateDependency(dependency.start)) {
        setStartDateAndString(newStartDate);
        setEndDateAndString(undefined);
      }
      if (newEndDate) {
        setEndDateAndString(newEndDate);
      }
    }
  };

  // handles focus for the calendar. Determins what value the next click will update
  const handleFocusChange = (newFocusedInput) => {
    const isDependencySet =
      isSetStartOrEndDependency(dependency) &&
      !isSetStartAndEndDependency(dependency);
    setFocusedInput(
      isWeeklyPlanning
        ? focusedInput === START_DATE
          ? END_DATE
          : START_DATE
        : isSingleDay
        ? START_DATE
        : isDependencySet
        ? isStartDateDependency(dependency.start)
          ? END_DATE
          : START_DATE
        : newFocusedInput || START_DATE
    );
  };

  const isValidRange = () => {
    if (!startDate && !endDate) {
      return false;
    } else if (startDate && !endDate) {
      return true;
    } else if (startDate && endDate && !startDate.isAfter(endDate)) {
      return true;
    }
    return false;
  };

  // handler when the user clicks teh done button.
  // calls props.OnSubmit()
  const handleDone = () => {
    setIsOpen(startOpen);
    if (isSingleDay) {
      onSubmit({ startDate, dependency, dependencyItem, dependencyItemType });
    } else if (isValidRange()) {
      onSubmit({
        startDate,
        endDate: endDate && !endDate.isValid() ? null : endDate,
        dependency,
        dependencyItem,
        dependencyItemType,
        includeWeekends,
        allDay,
        isTentative,
        useWeeklyPlanning: isWeeklyPlanning
      });
    } else {
      handleClose();
      dispatch(
        handleErrorMessage({
          type: GENERIC_ACTION,
          isFeError: true,
          errorMessage: 'Start Date must be before End Date'
        })
      );
    }
  };

  // resets modal state and closes the modal.
  // also calls props.onClose()
  const handleClose = (e) => {
    if (e && isInTimePicker(e)) {
      return;
    }
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
    setStartDateAndString(momentStart);
    setEndDateAndString(momentEnd);
    setWorkDays(itemWorkDays);
    setDependency({ start: null, end: null });
  };

  // opens the modal
  const handleOpen = () => {
    setIsOpen(true);
  };

  // on work plan calendar, just reset back to previously set dates
  // since work plans don't allow for null dates
  const handleClear = () => {
    handleClose();
  };

  // render helpers
  const formatDate = (date) => date?.clone?.().format?.('MMM DD, YYYY');
  const formatDateNoYear = (date) => date?.clone?.().format?.('MMM DD');

  const renderCalendarInfoComponent = () => {
    if (!shouldRenderButtonPanel) return;

    return getWorkplanCalendarPanel();
  };

  const handleUpdateCalendarSelectType = (optionType) => {
    if (optionType === WORK_PLAN_CALENDAR_SELECT_OPTION_TYPES.WEEKS)
      setIsWeeklyPlanning(true);
    else setIsWeeklyPlanning(false);
  };

  const handleCalendarCheckboxChange = (key, value = null) => {
    if (key === WORK_PLAN_CALENDAR_OPTION_TYPES.ALL_DAY) {
      setAllDay(value);
    }

    if (key === WORK_PLAN_CALENDAR_OPTION_TYPES.INCLUDE_WEEKENDS) {
      setIncludeWeekends(value);
    }

    if (key === WORK_PLAN_CALENDAR_OPTION_TYPES.SET_WEEKENDS_PREDICT_CONSTANT) {
      setWeekendsPredictConstant();
    }
  };

  const getWorkplanCalendarPanel = () => {
    const selectTypeProps = {
      selectType: isWeeklyPlanning
        ? WORK_PLAN_CALENDAR_SELECT_OPTION_TYPES.WEEKS
        : WORK_PLAN_CALENDAR_SELECT_OPTION_TYPES.DAYS,
      handleChange: handleUpdateCalendarSelectType
    };

    const checkboxRowValueProps = {
      showAllDay: isOOO,
      allDay,
      includeWeekends
    };

    const dependencySetterProps = {
      dependencies: dependencyStateToSetterMapper({
        dependency,
        dependencyItem,
        dependencyItemType,
        hasStartEndDependency: true
      }),
      onChange: handleSetNewDependency
    };

    return (
      <WorkplanCalendarPanel
        selectTypeProps={selectTypeProps}
        checkboxRowValueProps={checkboxRowValueProps}
        handleCalendarCheckboxChange={handleCalendarCheckboxChange}
        dependencySetterProps={dependencySetterProps}
        baseItem={item}
      />
    );
  };

  // --------Workplan Calendar functions-------

  const handleSetDependency = (value, itemStartDate, itemEndDate, item) => {
    if (!disableDependency) {
      if (isStartDateDependency(value)) {
        setStartDateAndString(
          moment(
            value === DEPENDENCY_STRINGS.START ? itemStartDate : itemEndDate
          )
        );
        setDependency({
          start: value,
          end: null
        });
        setDependencyItem({ start: item, end: null });
        setPredictUpdate('end_date');
      } else if (isEndDateDependency(value)) {
        setEndDateAndString(
          moment(value === DEPENDENCY_STRINGS.END ? itemEndDate : itemStartDate)
        );
        setDependency({
          start: null,
          end: value
        });
        setDependencyItem({ start: null, end: item });
        setPredictUpdate('start_date');
      } else if (value === DEPENDENCY_STRINGS.START_AND_END) {
        setStartDateAndString(moment(itemStartDate));
        setEndDateAndString(moment(itemEndDate));
        setDependency({
          start: DEPENDENCY_STRINGS.START,
          end: DEPENDENCY_STRINGS.END
        });
        setDependencyItem({ start: item, end: item });
        setPredictUpdate('work_days');
      }
      setDependencyStepItem(null);
    }
  };

  const handleSetNewDependency = (value) => {
    const {
      dependency: newDependency,
      dependencyItem: newDependencyItem,
      dependencyItemType: newDependencyItemType
    } = dependencySetterToStateMapper(value);

    if (newDependencyItem.start) {
      const newStartDate =
        newDependency.start === DEPENDENCY_STRINGS.START
          ? newDependencyItem.start.start_date
          : newDependencyItem.start.end_date;
      setStartDateAndString(moment(newStartDate));

      if (!isEndDateDependency(newDependency.end)) {
        setPredictUpdate('end_date');
      } else {
        setPredictUpdate('work_days');
      }
    }

    if (newDependencyItem.end) {
      const newEndDate =
        newDependency.end === DEPENDENCY_STRINGS.END
          ? newDependencyItem.end.end_date
          : newDependencyItem.end.start_date;
      setEndDateAndString(moment(newEndDate));

      if (!isStartDateDependency(newDependency.start)) {
        setPredictUpdate('start_date');
      } else {
        setPredictUpdate('work_days');
      }
    }

    setDependency(newDependency);
    setDependencyItem(newDependencyItem);
    setDependencyItemType(newDependencyItemType);
  };

  const clearDependency = (isStartDependencySetter) => {
    isStartDependencySetter
      ? handleSetDependency(
          { start: DEPENDENCY_STRINGS.NONE },
          null,
          null,
          null
        )
      : handleSetDependency({ end: DEPENDENCY_STRINGS.NONE }, null, null, null);
  };

  const getWeekendsPredictConstant = () => {
    if (
      !isSetStartOrEndDependency(dependency) ||
      isStartDateDependency(dependency.start)
    ) {
      return 'end_date';
    } else if (isEndDateDependency(dependency.end)) {
      return 'start_date';
    } else {
      return 'work_days';
    }
  };

  const setWeekendsPredictConstant = () => {
    setPredictUpdate(getWeekendsPredictConstant());
  };

  const getDayUtilizationInfo = (day) => {
    const dayUtilization =
      accountUtilizations[day.format(MOMENT_ISO_DATE)] ?? 0;
    const dayOfWeek = day.format('dddd').toLowerCase();
    const dayCapacity = accountCapacities[dayOfWeek] ?? 0;
    const utilizationPercentage =
      dayCapacity !== 0 ? (dayUtilization / dayCapacity) * 100 : 100;

    return { dayUtilization, dayCapacity, utilizationPercentage };
  };

  const handleDateRenderer = (day, modifier) => {
    const dependencyStartDate =
      isStartDateDependency(dependency.start) ||
      isStartAndEndDateDependency(dependency)
        ? dependencyItem.start.start_date // no need to worry about start to end dependencies on work plan
        : null;
    const dependencyEndDate =
      isEndDateDependency(dependency.end) ||
      isStartAndEndDateDependency(dependency)
        ? dependencyItem.end.end_date // no need to worry about end to start dependencies on work plan
        : null;

    const isDayStartDependency =
      day.isSame(dependencyStartDate, 'day') &&
      startDate.isSame(dependencyStartDate, 'day');
    const isDayEndDependency =
      day.isSame(dependencyEndDate, 'day') &&
      endDate.isSame(dependencyEndDate, 'day');

    const { dayUtilization, dayCapacity, utilizationPercentage } =
      getDayUtilizationInfo(day);

    const isPTO = accountPTODates.has(day.format(MOMENT_ISO_DATE));
    const isHoliday = !!holidayDates[day.format(MOMENT_USA_DATE)];

    return dayRenderer(
      day,
      modifier,
      dayUtilization,
      dayCapacity,
      utilizationPercentage,
      isPTO,
      isHoliday,
      capacityOnWorkPlanCalendar,
      isDayStartDependency,
      isDayEndDependency
    );
  };

  const isDayHighlighted = (date) => {
    const { utilizationPercentage } = getDayUtilizationInfo(date);
    const isPTO = accountPTODates.has(date.format(MOMENT_ISO_DATE));
    const isHoliday = !!holidayDates[date.format(MOMENT_USA_DATE)];

    return (
      utilizationPercentage > 100 &&
      !isPTO &&
      !isHoliday &&
      capacityOnWorkPlanCalendar
    );
  };

  const handleMonthChange = (date) => {
    dispatch(
      fetchUtilizations({
        startDate: date.startOf('month').format(MOMENT_USA_DATE),
        endDate: date.endOf('month').format(MOMENT_USA_DATE),
        accountIds: [accountId],
        teamId: teamId
      })
    );
  };

  return (
    <>
      <div>
        {customInput ? (
          customInput(itemStartDate, itemEndDate, handleOpen)
        ) : (
          <ReportsDateContainer onClick={handleOpen}>
            <StyledCalendarIcon height="12px" width="12px" />
            <StyledDates className="styled-dates">
              {customLabel ||
                (isSingleDay
                  ? momentStart.format('MM/DD')
                  : isWeekly
                  ? `${formatDateNoYear(momentStart)} - ${formatDate(
                      momentEnd
                    )}`
                  : `${formatDate(momentStart)} - ${formatDate(momentEnd)}`)}
            </StyledDates>
          </ReportsDateContainer>
        )}
      </div>
      {isOpen && (
        <CalendarContainer
          isSingleDay={isSingleDay}
          isWeekly={isWeekly}
          shouldRenderButtonPanel={shouldRenderButtonPanel}
        >
          <CancelButton onClick={handleClear} showClear={showClear}>
            Clear
          </CancelButton>
          <DoneButton onClick={handleDone}>Done</DoneButton>
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDatesChange}
            focusedInput={focusedInput}
            onFocusChange={handleFocusChange}
            initialVisibleMonth={getInitialMonth}
            renderMonthElement={renderMonthElement}
            navPrev={<NavLeft />}
            navNext={<NavRight />}
            renderDayContents={handleDateRenderer}
            renderCalendarInfo={renderCalendarInfoComponent}
            hideKeyboardShortcutsPanel
            enableOutsideDays
            firstDayOfWeek={isWeekly ? 1 : 0}
            isDayHighlighted={isDayHighlighted}
            {...isWeeklyProps}
            {...isWeeklyPlanningProps}
            minimumNights={0}
            onNextMonthClick={handleMonthChange}
            onPrevMonthClick={handleMonthChange}
          />
        </CalendarContainer>
      )}
    </>
  );
};

export default DateRangeCalendar;
