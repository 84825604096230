import {
  Phase,
  PhaseMembership,
  PhaseMembershipWithUnassignedMemberBudget,
  AddMemberToPhase
} from './ProjectTeam/helpers/render';
import {
  ProjectUnassignedMemberBudgetWithRequirements,
  ProjectMembershipRequirement,
  AddProjectMembershipRequirement,
  RequirementLoader
} from './UnassignedRoles/helpers/render';

export const LIST_ITEM_TYPES = {
  Phase: 'phase',
  PhaseMembership: 'pm',
  AddMemberToPhase: 'add-membert-to-phase',
  /* -------------------------------------------------------------------------- */
  PhaseMembershipWithUnassignedMemberBudget: 'unassigned',
  ProjectUnassignedMemberBudgetWithRequirements:
    'project-unassigned-mb-with-requirements',
  ProjectMembershipRequirement: 'project-membership-requirement',
  AddProjectMembershipRequirement: 'add-project-membership-requirement',
  RequirementLoader: 'requirement-loader'
} as const;

export const listItemTypeToRenderHash = {
  [LIST_ITEM_TYPES.Phase]: Phase,
  [LIST_ITEM_TYPES.PhaseMembership]: PhaseMembership,
  [LIST_ITEM_TYPES.AddMemberToPhase]: AddMemberToPhase,
  [LIST_ITEM_TYPES.PhaseMembershipWithUnassignedMemberBudget]:
    PhaseMembershipWithUnassignedMemberBudget,
  [LIST_ITEM_TYPES.ProjectUnassignedMemberBudgetWithRequirements]:
    ProjectUnassignedMemberBudgetWithRequirements,
  [LIST_ITEM_TYPES.ProjectMembershipRequirement]: ProjectMembershipRequirement,
  [LIST_ITEM_TYPES.AddProjectMembershipRequirement]:
    AddProjectMembershipRequirement,
  [LIST_ITEM_TYPES.RequirementLoader]: RequirementLoader
};

export const renderItem = (item, index) =>
  listItemTypeToRenderHash[item.itemType](item, index);
