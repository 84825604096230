import { useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  makeGetActiveWorkloadPlannerFilter,
  getWorkloadViewBy
} from 'selectors';
import WorkloadPlannerContainer from 'views/projectPlanner/WorkloadPlannerContainer';
import { PageContainer } from 'views/PageContainer';
import { Page } from 'models/filter';
import { FILTER_PAGES } from 'appConstants/workload';
import { connect } from 'react-redux';
import { useQueryParams, NumberParam } from 'use-query-params';
import { SideFilter } from 'FilterModule/components/SideFilter';
import { initialFilterLevelSchemas } from './filterConfig';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import { PageViewFilterPortal } from 'views/layoutStructuralComponents/PageViewFilter';

const pageName = Page.WorkloadPlanner;

const ScheduleContainer = (props) => {
  const { crossFilterOnWorkloadFlag, viewFilterOnPlannerFlag } =
    useFeatureFlags();

  const { viewBy } = props;
  const [query] = useQueryParams({
    filter_id: NumberParam
  });
  const { filter_id: filterId } = query;

  const getActiveFilter = useMemo(makeGetActiveWorkloadPlannerFilter, []);

  const activeFilter = useAppSelector((state) =>
    getActiveFilter(state, { viewBy, pageName })
  );

  const pageContainerProps = useMemo(
    () =>
      !crossFilterOnWorkloadFlag
        ? null
        : {
            pageContextProviderProps: {
              currentView: viewBy,
              pageName
            },
            filterContextProviderProps: {
              filterSchemaParams: {
                initialFilterLevelSchemas,
                pageName,
                level2: viewBy
              },
              // making sure the same filter is used (other parts of this page are using older
              // activeFilter infra)
              matchingFiltersOverride: [activeFilter]
            }
          },
    [activeFilter, viewBy, crossFilterOnWorkloadFlag]
  );

  if (!crossFilterOnWorkloadFlag) {
    return (
      <WorkloadPlannerContainer
        viewBy={viewBy}
        pageName={FILTER_PAGES.WORKLOAD_PLANNER}
        plannerType="workload"
        filterId={filterId}
      />
    );
  }

  return (
    // Page/filter context
    <PageContainer {...pageContainerProps}>
      <SideFilter variant="WorkloadPlannerSideFilter" shouldUsePortal={false} />

      <WorkloadPlannerContainer
        viewBy={viewBy}
        pageName={FILTER_PAGES.WORKLOAD_PLANNER}
        plannerType="workload"
        filterId={filterId}
        activeFilter={activeFilter}
      />

      {viewFilterOnPlannerFlag && <PageViewFilterPortal />}
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  viewBy: getWorkloadViewBy(state)
});
export default connect(mapStateToProps)(ScheduleContainer);
