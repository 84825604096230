/**
 * A mapping from the index of the day of the week returned by
 * `Date.prototype.getDay()` to the lowercase English name of that day used by
 * APIs.
 */
export const DATE_DAYS_MAP = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday'
};

export const MOMENT_ISO_DATE = 'YYYY-MM-DD';
export const MOMENT_USA_DATE = 'MM/DD/YYYY';

export const DATE_FNS_ISO_DATE = 'yyyy-MM-dd';
export const DATE_FNS_USA_DATE = 'MM/dd/yyyy';
export const DATE_FNS_SHORT_USA_DATE = 'M/d/yy';
export const DATE_DISPLAY_SHORT = 'M/D/YYYY';

export const DATE_DISPLAY_TINY = 'M/D/YY';
