import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHomeTaskObj } from 'selectors';
import { fetchTasksV2 } from 'actionCreators';

interface UseWorkPlanTasksProps {
  taskOrder: Array<number>;
}

export const useWorkPlanTasks = ({
  taskOrder: initialTaskOrder
}: UseWorkPlanTasksProps) => {
  const dispatch = useDispatch();
  const [taskOrder, setTaskOrder] = useState(initialTaskOrder);

  const taskHash = useSelector(getHomeTaskObj);

  useEffect(() => {
    setTaskOrder(initialTaskOrder);
  }, [initialTaskOrder]);

  const tasks = useMemo(() => {
    if (!taskOrder) return [];

    return taskOrder
      .flatMap((taskId) => {
        const task = taskHash[taskId];
        return task?.description ? [task] : [];
      })
      .sort((a, b) => {
        if (!a.completed_at === !b.completed_at) {
          return 0;
        } else if (!b.completed_at) {
          return 1;
        }
        return -1;
      });
  }, [taskHash, taskOrder]);

  // currently it fetches tasks by workplan schedule instead of task orders
  const getTasks = useCallback(
    ({ taskOrder }: { taskOrder: Array<number> }) => {
      dispatch(fetchTasksV2({ body: { all: true, task_ids: taskOrder } }));
    },
    [dispatch]
  );

  // React dependencies are reference-based for arrays, such as
  // `initialTaskOrder`. The tasks need only be fetched if the set of tasks
  // changes; a change in array reference or order are not important. By sorting
  // the IDs and converting the array into a string, both problmes are avoided.
  const initialTaskOrderString = JSON.stringify(
    initialTaskOrder.slice().sort()
  );
  useEffect(() => {
    if (initialTaskOrder.length) {
      getTasks({ taskOrder: initialTaskOrder });
    }
    // See comment above.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTasks, initialTaskOrderString]);

  const handleUpdateTaskIds = (taskIds: Array<number>) => {
    setTaskOrder(taskIds);
  };

  return {
    tasks,
    taskOrder,
    onUpdateTaskIds: handleUpdateTaskIds
  };
};
