import moment from 'moment';
import { getMaxDate } from 'appUtils/momentUtils';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
// put all possible date formats here so moment doesn't show warning
export const DATE_FORMATS = [DEFAULT_DATE_FORMAT, 'MM-DD-YYYY', 'YYYY-MM-DD'];

export const calculateSelectedDateRange = ({
  newValues,
  start,
  end,
  itemExists = true
}) => {
  let newDates = [];
  if (itemExists) {
    if (start) {
      newDates.push(moment(start));
    }
    if (end) {
      newDates.push(moment(end));
    }
  }
  if (!itemExists || newValues.length === 2 || newValues[0] > moment(end)) {
    newDates = newValues;
  } else if (newValues.length === 1) {
    newDates[0] = newValues[0];
  }
  return newDates;
};

export const calculateTaskSaveDateRange = ({ task, newDates }) => {
  if (!newDates) {
    return [null, null];
  }

  const startDate = newDates.length
    ? moment(newDates[0]).startOf('day').format('YYYY-MM-DD')
    : Array.isArray(newDates)
    ? null
    : task?.schedule_start
    ? moment(task.schedule_start).format('YYYY-MM-DD')
    : null;

  const endDate =
    newDates.length === 2
      ? moment(newDates[1]).endOf('day').format('YYYY-MM-DD')
      : newDates.length === 1
      ? null
      : Array.isArray(newDates)
      ? null
      : task?.schedule_end
      ? moment(task.schedule_end).format('YYYY-MM-DD')
      : null;

  const dates = [
    startDate ? moment(startDate) : null,
    endDate ? getMaxDate(startDate, endDate) : null
  ];
  return dates;
};

export const calculatePhaseSaveDateRange = ({ phase, newDates }) => {
  if (!newDates) {
    return [phase?.start_date, phase?.end_date];
  }

  const startDate =
    newDates && newDates.length
      ? moment(newDates[0]).startOf('day').format('MM/DD/YYYY')
      : Array.isArray(newDates)
      ? null
      : phase
      ? moment(phase.start_date).format('MM/DD/YYYY')
      : null;

  const endDate =
    newDates && newDates.length === 2
      ? moment(newDates[1]).endOf('day').format('MM/DD/YYYY')
      : newDates && newDates.length === 1
      ? moment(newDates[0]).endOf('day').add(1, 'week').format('MM/DD/YYYY')
      : Array.isArray(newDates)
      ? null
      : phase
      ? moment(phase.end_date).format('MM/DD/YYYY')
      : null;

  return [startDate, endDate];
};

export const formatDateRangeString = ({
  start_date,
  end_date,
  dateFormat = 'MMM D',
  dateFormat1,
  dateFormat2,
  separator = ' - ',
  yearSuffix1 = '',
  yearSuffix2 = ''
}) => {
  const startDate = moment(start_date, DATE_FORMATS);
  const endDate = moment(end_date, DATE_FORMATS);

  // If the dates are equal or if there is no end date, only show the start date.
  if (start_date === end_date || !end_date)
    return startDate.format(dateFormat + yearSuffix1);

  // If the date range covers a whole month, only show the month and year.
  if (
    startDate.isSame(endDate, 'month') &&
    startDate.isSame(endDate, 'year') &&
    startDate.date() === 1 &&
    endDate.date() === endDate.daysInMonth()
  )
    return startDate.format('MMM YYYY');

  // Otherwise, show both dates with the seperator.
  return `${startDate.format(
    (dateFormat1 || dateFormat) + yearSuffix1
  )}${separator}${endDate.format((dateFormat2 || dateFormat) + yearSuffix2)}`;
};

/* ------------------------------------ - ----------------------------------- */

export const DATE_RANGES = {
  ALL_DATES: 'ALL_DATES',
  ALL_PAST: 'ALL_PAST', // incl. today
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  // 4th week is this week up to today
  PAST_4_WEEKS: 'PAST_4_WEEKS',
  COMPLETED_PAST_TWO_WEEKS: 'COMPLETED_PAST_TWO_WEEKS',
  COMPLETED_PAST_12_WEEKS: 'COMPLETED_PAST_12_WEEKS',
  LAST_WEEK: 'LAST_WEEK',
  START_OF_WEEK_TO_TODAY: 'START_OF_WEEK_TO_TODAY',
  TODAY_TO_END_OF_WEEK: 'TODAY_TO_END_OF_WEEK',
  THIS_WHOLE_WEEK: 'THIS_WHOLE_WEEK', //
  NEXT_WEEK: 'NEXT_WEEK',
  // start of month to today
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  THIS_WHOLE_MONTH: 'THIS_WHOLE_MONTH',
  NEXT_30_DAYS: 'NEXT_30_DAYS',

  COMPLETED_HALF_YEAR: 'COMPLETED_HALF_YEAR',
  COMPLETED_YEAR: 'COMPLETED_YEAR',
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  NEXT_YEAR: 'NEXT_YEAR',

  // last 8 months + current month + next 3 months
  LAST_8_NEXT_3_MONTHS: 'LAST_8_NEXT_3_MONTHS',
  LAST_3_MONTHS: 'LAST_3_MONTHS',
  // next 3 months starting from today
  NEXT_3_MONTHS: 'NEXT_3_MONTHS',
  YEAR_TO_DATE: 'YEAR_TO_DATE',
  START_OF_LAST_WEEK_TO_END_OF_THIS_WEEK:
    'START_OF_LAST_WEEK_TO_END_OF_THIS_WEEK',
  // next 12 months starting from first day of current month to last day of next 11 months (e.g. 2022-01-01 to 2022-12-31)
  NEXT_12_MONTHS: 'NEXT_12_MONTHS',
  CUSTOM: 'CUSTOM'
};

/**
 * For generating specific date ranges. startOfWeek => sunday = 0, monday = 1
 */
export const generateDateRange = ({
  range,
  start,
  end,
  startOfWeek = 0,
  dateFormat = DEFAULT_DATE_FORMAT
}) => {
  const today = moment().format(dateFormat);

  const rangeGenerators = {
    [DATE_RANGES.ALL_PAST]: () => ({
      start_date: moment('1900-01-01').format(dateFormat),
      end_date: today
    }),
    [DATE_RANGES.TODAY]: () => ({
      start_date: today,
      end_date: today
    }),
    [DATE_RANGES.YESTERDAY]: () => {
      const yesterday = moment().add(-1, 'day').format(dateFormat);
      return {
        start_date: yesterday,
        end_date: yesterday
      };
    },

    [DATE_RANGES.PAST_4_WEEKS]: () => ({
      start_date: moment()
        .startOf('week')
        .isoWeekday(startOfWeek)
        .add(-3, 'weeks')
        .format(dateFormat),
      end_date: today
    }),
    [DATE_RANGES.COMPLETED_PAST_TWO_WEEKS]: () => {
      const startDate = moment()
        .startOf('week')
        .isoWeekday(startOfWeek)
        .add(-2, 'week');
      return {
        start_date: startDate.format(dateFormat),
        end_date: startDate.clone().add(13, 'days').format(dateFormat)
      };
    },

    [DATE_RANGES.LAST_WEEK]: () => {
      const startDate = moment()
        .startOf('week')
        .isoWeekday(startOfWeek)
        .add(-1, 'week');
      return {
        start_date: startDate.format(dateFormat),
        end_date: startDate.clone().add(6, 'days').format(dateFormat)
      };
    },
    [DATE_RANGES.START_OF_LAST_WEEK_TO_END_OF_THIS_WEEK]: () => {
      const startDate = moment()
        .startOf('week')
        .isoWeekday(startOfWeek)
        .add(-1, 'week');
      return {
        start_date: startDate.format(dateFormat),
        end_date: startDate.clone().add(13, 'days').format(dateFormat)
      };
    },
    [DATE_RANGES.START_OF_WEEK_TO_TODAY]: () => ({
      start_date: moment()
        .startOf('week')
        .isoWeekday(startOfWeek)
        .format(dateFormat),
      end_date: today
    }),
    [DATE_RANGES.TODAY_TO_END_OF_WEEK]: () => ({
      start_date: today,
      end_date: moment()
        .startOf('week')
        .isoWeekday(startOfWeek + 6)
        .format(dateFormat)
    }),
    [DATE_RANGES.THIS_WHOLE_WEEK]: () => ({
      start_date: moment().startOf('week').format(dateFormat),
      end_date: moment().endOf('week').format(dateFormat)
    }),
    [DATE_RANGES.NEXT_WEEK]: () => ({
      start_date: moment().add(1, 'week').startOf('week').format(dateFormat),
      end_date: moment().add(1, 'week').endOf('week').format(dateFormat)
    }),

    [DATE_RANGES.LAST_MONTH]: () => ({
      start_date: moment().add(-1, 'month').startOf('month').format(dateFormat),
      end_date: moment().add(-1, 'month').endOf('month').format(dateFormat)
    }),
    [DATE_RANGES.THIS_MONTH]: () => ({
      start_date: moment().startOf('month').format(dateFormat),
      end_date: today
    }),
    [DATE_RANGES.THIS_WHOLE_MONTH]: () => ({
      start_date: moment().startOf('month').format(dateFormat),
      end_date: moment().endOf('month').format(dateFormat)
    }),
    [DATE_RANGES.NEXT_30_DAYS]: () => ({
      start_date: today,
      end_date: moment().add(29, 'days').format(dateFormat)
    }),

    [DATE_RANGES.COMPLETED_HALF_YEAR]: () => ({
      start_date: moment()
        .startOf('month')
        .add(-6, 'months')
        .format(dateFormat),
      end_date: moment().startOf('month').add(-1, 'day').format(dateFormat)
    }),
    [DATE_RANGES.COMPLETED_YEAR]: () => ({
      start_date: moment().startOf('month').add(-1, 'years').format(dateFormat),
      end_date: moment().startOf('month').add(-1, 'day').format(dateFormat)
    }),
    [DATE_RANGES.LAST_8_NEXT_3_MONTHS]: () => ({
      start_date: moment()
        .startOf('month')
        .add(-8, 'months')
        .format(dateFormat),
      end_date: moment().endOf('month').add(3, 'months').format(dateFormat)
    }),
    [DATE_RANGES.LAST_3_MONTHS]: () => ({
      start_date: moment()
        .subtract(3, 'month')
        .startOf('month')
        .format(dateFormat),
      end_date: moment().endOf('month').format(dateFormat)
    }),
    [DATE_RANGES.NEXT_3_MONTHS]: () => ({
      start_date: today,
      end_date: moment().add(3, 'months').format(dateFormat)
    }),
    [DATE_RANGES.YEAR_TO_DATE]: () => ({
      start_date: moment().startOf('year').format(dateFormat),
      end_date: moment().format(dateFormat)
    }),
    [DATE_RANGES.NEXT_12_MONTHS]: () => ({
      start_date: moment().startOf('month').format(dateFormat),
      end_date: moment().add(11, 'months').endOf('month').format(dateFormat)
    }),
    [DATE_RANGES.CUSTOM]: () => ({
      start_date: moment(start).format(dateFormat),
      end_date: moment(end).format(dateFormat)
    })
  };
  return rangeGenerators[range]?.() || { start_date: null, end_date: null };
};

export const makeDateRangeGeneratorHash = ({ startOfWeek = 1 } = {}) =>
  Object.values(DATE_RANGES).reduce((acc, cur) => {
    acc[cur] = ({ start, end }) =>
      generateDateRange({
        range: cur,
        startOfWeek,
        start,
        end
      });
    return acc;
  }, {});

export const defaultDateRangeGeneratorHash = makeDateRangeGeneratorHash();

export const formatDateRange = ({
  range,
  customStart,
  customEnd,
  generator,
  yearSuffix = ', YYYY'
}) => {
  // ZOOM_LEVEL.QUATER has value 0. Do not use range === undefined. Some of ranges may be null.
  if ((!range && range !== 0) || !generator[range]) return '';

  const { start_date, end_date } = generator[range]({
    start: customStart,
    end: customEnd
  });

  return formatDateRangeString({
    start_date,
    end_date,
    yearSuffix,
    ...(!moment(start_date, DATE_FORMATS).isSame(moment(), 'year') && {
      yearSuffix1: yearSuffix
    }),
    ...(!moment(end_date, DATE_FORMATS).isSame(moment(), 'year') && {
      yearSuffix2: yearSuffix
    })
  });
};
// Use this when args.range comes only from activeFilter (eg. no default value coming from somewhere else)
export const formatDateRangeFromFilter = (activeFilter, formatter) => {
  return formatter({
    range: activeFilter?.custom?.range,
    customStart: activeFilter?.custom?.start_date,
    customEnd: activeFilter?.custom?.end_date
  });
};

/**
 * Builds calendar-like structure list of weeks of a month. Each item
 * represents a week and is a list containing data for each day of the week
 * @param {string} date         the month will be based off this date
 * @param {string} startOfWeek  sunday or monday
 */
export const buildCalendarMonthDateRows = (date, startOfWeek = 'sunday') => {
  const startOfWeekCompareValue = startOfWeek === 'sunday' ? 7 : 1;
  const endOfWeek =
    startOfWeekCompareValue === 1 ? 7 : startOfWeekCompareValue - 1;

  const weeksArray = [];
  const startOfMonth = moment(date).startOf('month');
  const endOfMonth = moment(date).endOf('month');

  const startOfCalendar =
    startOfMonth.isoWeekday() === startOfWeekCompareValue
      ? startOfMonth
      : startOfMonth
          .startOf('isoWeek')
          .isoWeekday(
            startOfWeekCompareValue === 7 ? 0 : startOfWeekCompareValue
          );
  const endOfCalendar =
    endOfMonth.isoWeekday() === startOfWeekCompareValue
      ? endOfMonth.clone().add(1, 'day').isoWeekday(endOfWeek)
      : endOfMonth.clone().endOf('isoWeek').isoWeekday(endOfWeek);

  const currDate = startOfCalendar.clone().subtract(1, 'day');

  while (currDate.isBefore(endOfCalendar, 'day')) {
    weeksArray.push(
      Array(7)
        .fill(0)
        .map(() => {
          const nextDate = currDate.add(1, 'day');
          return {
            date: nextDate.format('YYYY-MM-DD'),
            day: nextDate.date(),
            isWeekend: ['Sunday', 'Saturday'].includes(nextDate.format('dddd')),
            isToday: moment().isSame(nextDate, 'day'),
            isCorrectMonth: nextDate.isSame(date, 'month'),
            dayOfWeek: nextDate.format('dddd').toLowerCase()
          };
        })
    );
  }
  return weeksArray;
};

export const generateWeeksFromCurrentWeek = (
  date,
  totalWeeks = 4,
  startOfWeek = 'sunday'
) => {
  const firstDayOfWeek = startOfWeek === 'sunday' ? 0 : 1;
  const startDate = date.clone().day(firstDayOfWeek);
  const endDate = startDate.clone().add(totalWeeks, 'weeks').subtract(1, 'day');

  const weeksArray = [];
  const currDate = startDate.clone().subtract(1, 'day');

  while (currDate.isBefore(endDate, 'day')) {
    weeksArray.push(
      Array(7)
        .fill(0)
        .map((_, index) => {
          const nextDate = currDate.add(1, 'day');
          return {
            weekOrder: index,
            date: nextDate.format('MM/DD/YYYY'),
            month: nextDate.format('MMM'),
            day: nextDate.date(),
            isWeekend: ['Sunday', 'Saturday'].includes(nextDate.format('dddd')),
            isToday: moment().isSame(nextDate, 'day'),
            isCorrectMonth: nextDate.isSame(date, 'month'),
            dayOfWeek: nextDate.format('dddd').toLowerCase()
          };
        })
    );
  }

  return weeksArray;
};
