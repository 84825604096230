import React from 'react';
import { connect } from 'react-redux';
import { predictWorkdaysAndUpdatePhase } from 'actionCreators';
import {
  isPhaseArchived,
  getPhaseProgress,
  getPhaseProgressTooltipContent
} from 'appUtils/phaseDisplayUtils';
import DateProgressBar from './DateProgressBar';
import { makeGetPhaseDependencyItems, getFlatPhasesHash } from 'selectors';
import { DEPENDENCY_TYPES } from 'appConstants/workload';
import {
  generateNewDependencyInfos,
  mapNewPhaseDependencies
} from 'appUtils/newDependencies';
import { nullifyInvalidDate } from 'appUtils/momentUtils';

class MilestoneProgress extends React.Component {
  updatePhase = ({ startDate, endDate, dependency, dependencyItem }) => {
    const { phase, predictWorkdaysAndUpdatePhase } = this.props;
    if (phase) {
      const formattedStartDate = nullifyInvalidDate(startDate, 'MM/DD/YYYY');
      const formattedEndDate = nullifyInvalidDate(endDate, 'MM/DD/YYYY');

      const dependencyInfos = generateNewDependencyInfos(
        phase,
        dependency,
        dependencyItem,
        DEPENDENCY_TYPES.PHASE
      );

      predictWorkdaysAndUpdatePhase({
        phase,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        dependencyInfos
      });
    }
  };

  render() {
    const {
      phase,
      isDisabled,
      disabledTooltip,
      percentage,
      phases,
      phasesHash,
      calendarClassName
    } = this.props;
    const isArchived = isPhaseArchived(phase);
    const phaseProgress = getPhaseProgress(phase);
    const tooltipContent = getPhaseProgressTooltipContent(phaseProgress);

    const { initialDependency, initialDependencyItem } =
      mapNewPhaseDependencies({ phase, phasesHash });

    const phaseCalendarProps = {
      showInputs: true,
      phases: phases,
      isPhaseCalendar: true,
      showWorkdays: true,
      shouldRenderFooter: true,
      initialDependency,
      itemWorkDays: phase.total_work_days,
      item: phase,
      itemType: DEPENDENCY_TYPES.PHASE,
      initialDependencyItem,
      initialDependencyItemType: DEPENDENCY_TYPES.PHASE,
      calendarClassNames: `phase-calendar ${calendarClassName ?? ''}`
    };

    return (
      <DateProgressBar
        isDisabled={isDisabled || isArchived}
        disabledTooltip={
          isArchived
            ? 'Unable to set dates for archived phases.'
            : disabledTooltip
        }
        data={phase}
        onSave={this.updatePhase}
        tooltipContent={tooltipContent}
        percentage={percentage}
        phaseCalendarProps={phaseCalendarProps}
      />
    );
  }
}

const mapDispatchToProps = {
  predictWorkdaysAndUpdatePhase
};

const makeMapStateToProps = () => {
  const getDependencyPhases = makeGetPhaseDependencyItems();
  const mapStateToProps = (state, ownProps) => ({
    phases: getDependencyPhases(state, ownProps),
    phasesHash: getFlatPhasesHash(state)
  });
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(MilestoneProgress);
