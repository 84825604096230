import moment from 'appUtils/momentConfig';
import {
  SUGGESTIONS_OVER_CAPACITY_HOURS_BUFFER,
  SUGGESTIONS_OVER_CAPACITY_OCCURENCE_TOLERANCE
} from 'appConstants/workplanSuggestions';
import { getCapacity } from 'appUtils/hooks/useWorkloadDataProvider';
import { format } from 'date-fns';
import { DATE_FNS_USA_DATE } from 'appConstants/date';
import { getIsPhaseLikeDefault } from 'ProjectsModule/utils/phaseDisplay';
import { SUGGESTIONS_MIN_HOURS } from 'appConstants/workplanSuggestions';
import sumBy from 'lodash/sumBy';
import { isPtoProject } from 'SuggestionModule/utils';

export const constructWorkPlanFromSuggestion = (s, gap) => {
  const predictedTotalHours = s.predicted_value;
  // line below is useful for getting semi-random stable predictions for testing with
  // const predictedTotalHours = (s.predicted_value * 10000) % 7;
  const { workDaysLength, workingDays } = gap;

  const predictedAverageDailyHours = predictedTotalHours / workDaysLength;
  const suggestedWorkPlan = {
    ...s,
    start_date: moment(workingDays[0].date).format('MM/DD/YYYY'),
    end_date: moment(workingDays[workDaysLength - 1].date).format('MM/DD/YYYY'),
    account_id: s.account_id,
    daily_hours: predictedAverageDailyHours.toFixed(1),
    total_hours: predictedTotalHours.toFixed(1),
    workingDays,
    predictedAverageDailyHours: predictedAverageDailyHours,
    bars: [{ ...s, total_hours: predictedTotalHours }] // todo optimistically construct actual subbars instead of relying on prediction
  };
  return suggestedWorkPlan;
};
export const constructSuggestedWorkPlans = (s, largestAvailabilityGaps = []) =>
  largestAvailabilityGaps.map((gap) => constructWorkPlanFromSuggestion(s, gap));
export const checkIfSuggestedWorkPlanFitsAvailibityConstraints = (
  suggestedWorkPlan
) => {
  const { workingDays, predictedAverageDailyHours } = suggestedWorkPlan;
  return (
    workingDays.filter(({ availability }) => {
      return (
        +availability + +SUGGESTIONS_OVER_CAPACITY_HOURS_BUFFER <
        predictedAverageDailyHours
      );
    }).length >= SUGGESTIONS_OVER_CAPACITY_OCCURENCE_TOLERANCE
  );
};

const stableEmptyChunks = { chunksSortedByLength: [] };

export const getChunksSortedByLength = ({
  suggestionsHashByAccount,
  accountId,
  accountCapacity,
  cappedUtilizationsByMember
}) => {
  const suggestions = suggestionsHashByAccount?.[accountId];
  if (!suggestions?.length || !accountCapacity) {
    return stableEmptyChunks;
  }

  // assumed that suggestions always have consistent start and end dates
  const dates = Array.from(
    moment
      .range(moment(suggestions[0].start_date), moment(suggestions[0].end_date))
      .by('day')
  );

  const availabilityChunks = dates.reduce(
    (acc, date, index) => {
      const committed =
        cappedUtilizationsByMember?.[accountId]?.[date.format('YYYY-MM-DD')];
      const { capacityWithoutPto } = getCapacity(
        accountCapacity,
        undefined,
        date.format('dddd').toLowerCase(),
        date,
        false,
        cappedUtilizationsByMember?.[accountId]?.PTO
      );
      const availability = Math.max(
        Math.abs(
          capacityWithoutPto +
            SUGGESTIONS_OVER_CAPACITY_HOURS_BUFFER -
            committed,
          0
        )
      );

      // capacityWithoutPto = capacity - PTO
      // availability = capacity - PTO - committed (planned hours)
      const offOrAvailable = !capacityWithoutPto || availability;
      const lastDate = index === dates.length - 1;
      if (offOrAvailable) {
        acc.currentChunk.push({
          date,
          capacity: capacityWithoutPto,
          availability,
          committed
        });
      }

      if (!offOrAvailable || lastDate) {
        if (acc.currentChunk.length) {
          const workingDays = acc.currentChunk.filter(
            (chunk) => chunk.capacity > 0
          );
          if (workingDays.length) {
            const formattedChunk = {
              chunk: acc.currentChunk,
              workDaysLength: workingDays.length,
              workingDays
            };
            acc.chunks.push(formattedChunk);
          }
        }
        acc.currentChunk = [];
      }
      return acc;
    },
    { chunks: [], currentChunk: [] }
  );
  const chunksSortedByLength = availabilityChunks.chunks.sort(
    (a, b) => b.workDaysLength - a.workDaysLength
  );
  return { chunksSortedByLength };
};

export const checkIsValidSuggestionForWorkplan = ({
  totalHours,
  project,
  phase,
  schedule
}) => {
  // skip too small suggestions or if project or phase does not exist
  return !!(
    totalHours >= SUGGESTIONS_MIN_HOURS &&
    project &&
    phase &&
    schedule?.length &&
    !isPtoProject(project)
  );
};

export const makeSuggestedWorkplan = ({
  suggestion,
  project,
  phase,
  dailyHours,
  accountId,
  totalHours,
  defaultActivityId
}) => {
  const schedule = suggestion.schedule;
  const startDate = format(new Date(schedule[0].date), DATE_FNS_USA_DATE);
  const endDate = format(
    new Date(schedule[schedule.length - 1].date),
    DATE_FNS_USA_DATE
  );

  const formattedProjectInfo = {
    account_id: accountId,
    project_name: project.title,
    phase_name: phase?.name,
    is_default_phase: phase?.is_default_phase,
    is_like_default: getIsPhaseLikeDefault(phase),
    activity_id: phase?.activity_order?.[0] || defaultActivityId,
    start_date: startDate,
    end_date: endDate
  };
  const suggestedWorkplan = {
    ...suggestion,
    ...formattedProjectInfo,
    bars: [{ ...suggestion }],
    total_hours: totalHours,
    daily_hours: dailyHours
  };

  return suggestedWorkplan;
};

/**
 * Make workplan suggestions per account using suggestion from BE
 */
export const makeSuggestedWorkplansWithProjectInfo = ({
  allProjects,
  defaultActivityId,
  accountId,
  phases,
  suggestionsByAccountId
}) => {
  const suggestedWorkplans = suggestionsByAccountId.reduce(
    (acc, suggestion) => {
      const schedule = suggestion.schedule;
      const project = allProjects?.[suggestion.project_id];
      const phase = phases?.[suggestion.phase_id];
      const totalHours = sumBy(schedule, (date) => date.hours);
      const dailyHours = suggestion.daily_prediction; // (totalHours / schedule.length).toFixed(2);

      const isValidSuggestionForWorkplan = checkIsValidSuggestionForWorkplan({
        totalHours,
        project,
        phase,
        schedule
      });

      if (!isValidSuggestionForWorkplan) {
        return acc;
      }

      const suggestedWorkplan = makeSuggestedWorkplan({
        suggestion,
        totalHours,
        dailyHours,
        project,
        phase,
        accountId,
        defaultActivityId
      });

      acc.push(suggestedWorkplan);

      return acc;
    },
    []
  );

  return suggestedWorkplans;
};
