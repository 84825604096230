import { serializeFilterLevelName } from 'FilterModule/filterSchemas/utils';
import { Page } from 'models/filter';
import {
  FilterLevelSchemas,
  CurrentFilter,
  FilterFieldSchema
} from 'FilterModule/types';
import {
  defaultNumberArrayFieldSchema,
  makeDefaultBooleanFieldSchema,
  makeDefaultFilterOrderFieldSchema,
  makeDefaultMemberCrossFiltersFieldSchemas,
  makeDefaultProjectCrossFiltersFieldSchemas,
  MembersCrossFilterFieldSchemas,
  ProjectsCrossFilterFieldSchemas
} from 'FilterModule/filterSchemas/commonFieldSchemas';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { VIEW_BY } from 'appConstants/workload';
import { FilterField, FilterListType } from 'FilterModule/constants';

const workloadFilterName = serializeFilterLevelName({
  level1: Page.WorkloadPlanner,
  level2: VIEW_BY.MEMBERS
});

export const plannerFilterName = serializeFilterLevelName({
  level1: Page.WorkloadPlanner,
  level2: VIEW_BY.PROJECTS
});

// -----------------------------------------------------------------------------
//                              workloadFieldSchemas
// -----------------------------------------------------------------------------

/**
 * With guaranteed filter fields for Workload
 */
type WorkloadFieldSchemas = MembersCrossFilterFieldSchemas;

// schema
const workloadFieldSchemas: WorkloadFieldSchemas = {
  ...makeDefaultMemberCrossFiltersFieldSchemas({
    selectionLimit: 50
  })
};

// -----------------------------------------------------------------------------
//                              plannerFieldSchemas
// -----------------------------------------------------------------------------

/**
 * With guaranteed filter fields for Planner
 */
type PlannerFieldSchemas = ProjectsCrossFilterFieldSchemas & {
  // ----- Page View Filter -----

  [FilterField.account_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.position_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.work_group_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.office_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.discipline_ids]: typeof defaultNumberArrayFieldSchema;
  [FilterField.isProjectsWithUnassignedRoles]: ReturnType<
    typeof makeDefaultBooleanFieldSchema
  >;

  [FilterField.filterOrder]: FilterFieldSchema<
    FilterListType,
    FilterListType[],
    { fieldOverride?: string }
  >;
};

// note: Planner currently uses old infra for the project selector (VirtualFilter).
// therefore the stacked filters are not crossFieldDependencies, and there is no mainFilterListType here

const plannerFieldSchemas: PlannerFieldSchemas = {
  ...makeDefaultProjectCrossFiltersFieldSchemas(),

  // ----- Page View Filter -----

  [FilterField.account_ids]: defaultNumberArrayFieldSchema,
  [FilterField.position_ids]: defaultNumberArrayFieldSchema,
  [FilterField.work_group_ids]: defaultNumberArrayFieldSchema,
  [FilterField.office_ids]: defaultNumberArrayFieldSchema,
  [FilterField.discipline_ids]: defaultNumberArrayFieldSchema,
  [FilterField.isProjectsWithUnassignedRoles]: makeDefaultBooleanFieldSchema({
    defaultValue: null
  }),

  // filterOrder
  [FilterField.filterOrder]: makeDefaultFilterOrderFieldSchema({
    defaultValue: [],
    optionsArray: [
      FilterListType.Members,
      FilterListType.Positions,
      FilterListType.Departments,
      FilterListType.Offices,
      FilterListType.Disciplines,
      FilterListType.ShowProjectsWithUnassignedRoles
    ]
  })
};

/* ------------------------ initialFilterLevelSchemas ----------------------- */

export const initialFilterLevelSchemas: FilterLevelSchemas = {
  // Workload (member view)
  [workloadFilterName]: {
    fieldSchemas: workloadFieldSchemas
  },
  [plannerFilterName]: {
    fieldSchemas: plannerFieldSchemas
  }
};

/* ---------------------------- CurrentFilterType --------------------------- */

export type WorkloadCurrentFilterType = CurrentFilter<WorkloadFieldSchemas>;
export const useWorkloadFilterContext = () =>
  useFilterContext<WorkloadCurrentFilterType>();

export type PlannerCurrentFilterType = CurrentFilter<PlannerFieldSchemas>;
export const usePlannerFilterContext = () =>
  useFilterContext<PlannerCurrentFilterType>();
