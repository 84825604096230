import styled, { css } from 'styled-components';
import theme from 'theme';
import { useFilterListType } from 'FilterModule/hooks/useFilterListType';
import { FilterListType } from 'FilterModule/constants';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { ValueOf } from 'type-fest';

interface FilterDropdownToggleProps {
  isOpen: boolean;
  testId?: string;
  filterListType: FilterListType;
  /** FilterListType to config */
  optionHash: Partial<
    Record<
      FilterListType,
      {
        [key: string]: unknown;
        label?: string;
        singularToggleLabel?: string;
        pluralToggleLabel?: string;
        toggleLabel?: string;
      }
    >
  >;
  customLabel?: string;
  fieldOverride?: string;
}

/**
 * To override default labels, use: singularToggleLabel, pluralToggleLabel, or toggleLabel
 */
export const FilterDropdownToggle = ({
  isOpen,
  filterListType,
  fieldOverride,
  optionHash,
  testId,
  customLabel
}: FilterDropdownToggleProps) => {
  const { currentFilter } = useFilterContext();
  const { numFilteredItems } = useFilterListType({
    filterListType,
    currentFilter,
    fieldOverride
  });

  const config = optionHash[filterListType];

  if (!config) {
    return null;
  }

  return (
    <FilterDropdownToggleUI
      label={
        customLabel ??
        makeLabel({
          config,
          count: numFilteredItems,
          filterListType
        })
      }
      isOpen={isOpen}
      testId={testId}
    />
  );
};

export const FilterDropdownToggleUI = ({
  label,
  isOpen,
  testId
}: {
  label: string;
  isOpen: boolean;
  testId?: string;
}) => {
  return (
    <StyledFilterDropdownToggle
      isOpen={isOpen}
      className="filter-dropdown-toggle"
      data-testid={`${testId}-filter-dropdown-toggle`}
    >
      {label}
    </StyledFilterDropdownToggle>
  );
};

/* ------------------------------------ - ----------------------------------- */

const defaultPluralLabelsByFilterListType = {
  [FilterListType.ActivityIds]: 'Work Categories',
  [FilterListType.Projects]: 'Projects',
  [FilterListType.Priorities]: 'Priorities',
  [FilterListType.ProjectBudgetStatus]: 'Statuses'
};

const defaultSingularLabelsByFilterListType = {
  [FilterListType.ActivityIds]: 'Work Category',
  [FilterListType.Projects]: 'Project'
};

const defaultLabelsByFilterListType = {
  [FilterListType.Members]: 'People'
};

const makeLabel = ({
  config,
  count,
  filterListType
}: {
  config: ValueOf<FilterDropdownToggleProps['optionHash']>;
  count: number;
  filterListType: FilterListType;
}) => {
  const isMultiple = count > 1;

  const singularLabel =
    config?.singularToggleLabel ||
    defaultSingularLabelsByFilterListType[filterListType];

  const pluralLabel = isMultiple
    ? config?.pluralToggleLabel ||
      (singularLabel ? singularLabel + 's' : '') ||
      defaultPluralLabelsByFilterListType[filterListType]
    : null;

  const toggleLabel = config?.toggleLabel;

  const defaultToggleLabel = defaultLabelsByFilterListType[filterListType];

  const defaultLabel = config?.label;

  const label =
    pluralLabel ||
    singularLabel ||
    toggleLabel ||
    defaultToggleLabel ||
    defaultLabel ||
    '';

  return `${count > 0 ? `${count} ` : ''}${label}`;
};

const dimOnHoverStyle = css`
  filter: brightness(94%);
`;

const StyledFilterDropdownToggle = styled.div<{ isOpen: boolean }>`
  background: ${theme.colors.colorPaleGray10};
  padding: 3px 6px;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray9};
  width: max-content;
  border-radius: 6px;

  cursor: pointer;
  &:hover {
    ${dimOnHoverStyle}
  }
  ${(props) => props.isOpen && dimOnHoverStyle}
`;
