import styled, { keyframes } from 'styled-components';
import {
  generatePageOverlay,
  generateColumnWidth,
  calculateProjectColorPreference
} from 'appUtils/styleUtils';
import { Wrapper } from 'components/TaskAssigneeDropdown/styles';
import EstimatedHoursIcon from 'icons/EstimatedHoursIcon';
import {
  StyledTaskMilestoneIcon,
  StyledTaskCompletedIcon,
  StyledTaskStatusIcon,
  calculateHeaderWidth,
  StyledTimelineIcon,
  StyledPriorityIcon,
  StyledTaskTable,
  calcTaskPanelBorderBottom
} from '../TaskList/styles';
import KaratRight from 'icons/KaratRight';
import theme from 'theme';
import { Wrapper as BatchActionsWrapper } from 'components/BatchActions/styles';
import { StyledTableRow } from 'components/Table/styles';
import {
  ShowOnRowHover,
  HideOnRowHover,
  TimerIconContainer
} from './TaskTimerCell';
import NumberFormat from 'react-number-format';
import cn from 'classnames';

// Task Layout

export const TaskWrapper = styled(BatchActionsWrapper)`
  cursor: pointer;
  display: flex;
  outline: none;
  width: auto;
  min-height: 60px;
  padding: 0;
  margin: 0;
  &:hover {
    ${ShowOnRowHover} {
      display: flex;
    }
    ${HideOnRowHover} {
      display: none;
    }
  }
`;

export const TaskDragHandlePanel = styled.div`
  display: ${({ isCreateRow }) => (isCreateRow ? 'none' : 'flex')};
  flex-basis: 22px;
  ${(props) =>
    !props.isVirtual &&
    `
  margin-top: 28px;
  width: 22px;
  `}

  svg {
    visibility: hidden;
  }

  ${TaskWrapper}:hover &, ${StyledTableRow}:hover & {
    svg {
      visibility: ${({ currentFilter, taskIsEditing }) =>
        ['accepted', 'project', 'today'].includes(currentFilter.scope) &&
        ['incomplete', 'default'].includes(currentFilter.state) &&
        !taskIsEditing
          ? 'visible'
          : 'hidden'};
    }
  }
`;

export const TaskRowNumber = styled.div`
  visibility: hidden;
  position: relative;
  left: -6px;
  top: 1px;

  font-family: 'Open Sans';
  color: ${theme.colors.colorMediumGray1};
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;

  ${TaskWrapper}:hover &,
  ${StyledTableRow}:hover & {
    visibility: visible;
    height: 16px;
  }
  ${StyledTaskTable} & {
    position: absolute;
    left: 3px;
    top: 2px;
  }
`;

const calcTaskPanelBackgroundColor = (props) => {
  const {
    beingEdited,
    isActive,
    isBatchSelected,
    isOnHomeView,
    isOnProjectView,
    isOnTeamProject,
    taskProperty
  } = props;
  if (
    taskProperty === 'drag' ||
    taskProperty === 'completed' ||
    (isOnProjectView && beingEdited && !isActive)
  ) {
    return 'transparent';
  }
  if ((isOnHomeView || isOnProjectView) && !isOnTeamProject && beingEdited) {
    return theme.colors.colorTranslucentGray4;
  }
  if (isBatchSelected) {
    return theme.colors.colorPaleBlue2;
  }
  return theme.colors.colorPureWhite;
};

export const TaskCircleCheckWrapper = styled.span`
  cursor: pointer;
  margin: 17px 0 0 0;
  padding: 0;
  svg {
    vertical-align: bottom;
    transform: scale(0.8);
  }
`;

const calcBoxShadow = ({
  isOnTeamProject,
  isOnHomeView,
  isOnPersonalProject,
  beingEdited,
  isSomeTaskEditing,
  isCreatingTask
}) => {
  if (isCreatingTask) {
    return 'none';
  }
  return isOnTeamProject
    ? beingEdited
      ? '0 0 4px rgba(0,0,0,0.1)'
      : isSomeTaskEditing
      ? 'none'
      : '0 0 4px rgba(0,0,0,0.1)'
    : (isOnHomeView || isOnPersonalProject) && beingEdited
    ? '0 0 4px rgba(0,0,0,0.1)'
    : '';
};

const calcBorderSide = ({
  isOnPersonalProject,
  isOnHomeView,
  isOnTeamProject,
  beingEdited,
  isSomeTaskEditing,
  isComplete,
  isCreatingTask
}) => {
  if (isCreatingTask) {
    return '1px solid transparent';
  }
  return isOnHomeView || isOnPersonalProject
    ? '1px solid transparent'
    : isOnTeamProject & !isComplete
    ? beingEdited
      ? '1px solid #eeeeee'
      : isSomeTaskEditing
      ? '1px solid transparent'
      : '1px solid #eeeeee'
    : '1px solid transparent ';
};

export const TaskMainContentPanel = styled.div`
  display: flex;
  min-height: 60px;
  flex-basis: 662px;
  flex: 1;
  align-items: flex-start;
  max-height: ${(props) => (props.isSelectedHomeTask ? '70vh' : 'auto')};
  position: ${(props) => (props.isSelectedHomeTask ? 'fixed' : 'auto')};
  ${(props) =>
    props.isSelectedHomeTask
      ? `
    top: 15vh;
    left: calc(50% - 730px /2)`
      : ``};

  ${TaskCircleCheckWrapper} {
    margin-top: ${(props) => (props.isSelectedHomeTask ? '18px' : '20px')};
  }

  box-shadow: ${(props) =>
    props.isDragging || props.beingEdited ? '0 0 4px rgba(0,0,0,0.1)' : 'none'};
  margin-bottom: ${(props) => (props.isOnTeamProject ? '4px' : '0px')};
  border-bottom: ${calcTaskPanelBorderBottom};
  border-left: ${calcBorderSide};
  border-right: ${calcBorderSide};

  &:hover {
    box-shadow: ${calcBoxShadow};
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    background: transparent;
  }
`;

export const TaskLineItem = styled.div`
  display: flex;
  background-color: ${calcTaskPanelBackgroundColor};
  border-left: ${calcBorderSide};
  border-right: ${calcBorderSide};
  box-shadow: ${(props) =>
    props.isDragging || props.beingEdited ? '0 0 4px rgba(0,0,0,0.1)' : 'none'};
  flex: 1;
  margin-bottom: ${(props) => (props.isOnTeamProject ? '4px' : '0px')};

  &:hover {
    box-shadow: ${calcBoxShadow};
  }

  .modal & {
    background-color: transparent;
    border-width: 0;
    box-shadow: none;
    margin: 0;
  }

  ${TaskMainContentPanel} {
    box-shadow: none;
    background-color: transparent;
    border: none;

    &:hover {
      box-shadow: none;
    }
  }
`;

export const TaskColumnBox = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: ${({ isTaskModal }) => (isTaskModal ? `center` : `center`)};
  border-left: ${({
    isOnTeamProject,
    isTaskModal,
    isNewTask,
    taskProperty,
    isAddRow
  }) =>
    isOnTeamProject && !isTaskModal && !isNewTask && !isAddRow
      ? ['drag', 'completed', 'description'].includes(taskProperty)
        ? '2px solid transparent'
        : '2px solid #f3f3f3'
      : 0};
  background-color: ${calcTaskPanelBackgroundColor};
  ${generateColumnWidth};
  ${(props) =>
    !props.isOnTeamProject &&
    ['drag', 'completed'].includes(props.taskProperty) &&
    'outline: 1px solid white;'}
  ${(props) => props.isAddRow && 'cursor: pointer;'}
`;

export const TaskColumnBoxHeightWrapper = styled.div`
  height: ${({ isTaskModal }) => (isTaskModal ? `auto` : `60px`)};
  padding-top: ${({ isTaskModal, isOnProjectView }) =>
    isTaskModal || isOnProjectView ? `0` : `2px`};
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;
export const TaskBatchActionTogglePanel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

// Task Content Layout
export const TaskContentWrapper = styled.div`
  display: flex;
  ${(props) =>
    props.isSelectedHomeTask && !props.isTaskModal ? 'padding-top: 18px;' : ''}
  width: ${(props) =>
    props.isSelectedHomeTask
      ? '734px'
      : props.isVirtualList
      ? '100%'
      : '540px'};
  margin-right: 0px;
  padding-right: 0px;
  margin-top: ${(props) => (props.taskIsEditing ? '10px' : '0')};
  min-height: 60px;
  padding-left: ${(props) => (props.isSelectedHomeTask ? '0' : '20px')};
  border-bottom: ${calcTaskPanelBorderBottom};
  .modal & {
    background-color: ${theme.colors.colorPureWhite};
  }
`;
// Task Content Layout
export const TaskContentMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: auto;
  user-select: text;
`;

export const TaskContentScroller = styled.div`
  overflow-y: ${(props) =>
    props.isSelectedHomeTask /* isSelectedHomeTask indicates that we are in a modal */
      ? `auto`
      : `none`};
  max-height: ${(props) => (props.isSelectedHomeTask ? '60vh' : 'auto')};
`;

export const StyledTaskContentTop = styled.div`
  ${(props) => (props.isTaskModal ? 'height: 38px;' : 'height: 16px;')}
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => (props.isTaskModal ? '8px' : '')};
  padding: ${(props) => (props.isSelectedHomeTask ? '0 10px' : '0')};
  align-items: ${(props) => (props.isSelectedHomeTask ? 'center' : 'baseline')};
  font-size: 11px;
  color: ${theme.colors.colorMediumGray4};
  ${HideOnRowHover} {
    margin-top: 0px;
  }
  &:hover {
    ${ShowOnRowHover} {
      display: flex;
    }
    ${HideOnRowHover} {
      display: none;
    }
  }
  svg {
    height: 12px;
    width: 12px;
  }
  ${TimerIconContainer} {
    margin-left: 0px;
    svg {
      height: unset;
      width: unset;
    }
  }
`;

export const TopContentContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  margin-left: ${(props) => (props.isTaskModal ? '2px' : '0px')};
`;
export const StyledTaskContentMiddle = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${(props) => (props.isSelectedHomeTask ? '0 10px' : '0')};
  margin-top: ${(props) => (props.isSelectedHomeTask ? '5px' : '')};

  textarea {
    background-color: ${calcTaskPanelBackgroundColor};
    border-color: transparent !important;
  }

  .content-middle-task-note-container {
    display: ${(props) => (props.isTaskModal ? 'flex' : 'none')};
    margin-left: 18px;
    margin-right: 18px;
    border-bottom: 1px solid ${theme.colors.colorPaleGray5};
    align-items: baseline;
  }

  .content-middle-container {
    display: flex;
    position: relative;
    align-items: flex-start;
    flex: 1;
    max-width: ${(props) => (props.isTaskModal ? 'calc(100% - 45px);' : '')};
    &.border-bottom {
      align-items: center;
    }
  }
  &.scroll {
    overflow-y: auto;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0;
      background: transparent;
    }
  }
`;
export const StyledTaskContentBottom = styled.div`
  border-top: ${(props) =>
    props.isSelectedHomeTask
      ? ''
      : `2px solid ${props.isOnTeamProject ? '#f3f3f3' : '#e4e4e4'}`};

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    background: transparent;
  }
  display: ${(props) => (props.isTaskModal ? 'block' : 'none')};
`;
export const TaskContentLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding-left: ${(props) =>
    props.isOnTeamProject || props.isOnPersonalProject ? `12px` : '0px'};
`;

export const ProjectMenuButton = styled.span`
  font-family: 'Open Sans';
  font-size: 11px;
  line-height: normal;
  align-items: center;
  padding-bottom: 6px;
  color: ${({ taskBeingEdited, theme }) =>
    taskBeingEdited
      ? theme.colors.colorRoyalBlue
      : theme.colors.colorMediumGray5};
  cursor: pointer;
  visibility: ${({
    show,
    taskBeingEdited,
    hasProject,
    projectMenuButtonIsVisible
  }) =>
    show && (taskBeingEdited || hasProject || projectMenuButtonIsVisible)
      ? 'visible'
      : 'hidden'};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;

  &:hover {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
  }
`;

export const TaskDescriptionAndProjectContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding-top: ${(props) => (props.isSelectedHomeTask ? '20px' : '5px')};
  overflow: hidden;

  ${ProjectMenuButton} {
    ${({ isDefaultPersonalProject, isOnHomeView }) =>
      isDefaultPersonalProject && isOnHomeView && `visibility: hidden;`}
  }

  :hover {
    ${ProjectMenuButton} {
      ${({ isDefaultPersonalProject, isOnHomeView }) =>
        isDefaultPersonalProject && isOnHomeView && `visibility: visible;`}
    }
  }
`;

export const TaskColumnsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-self: center;
  height: 100%;
`;

export const TaskContentRight = styled.div`
  display: flex;
  ${TaskColumnBox}:not(.hideBorder) {
    border-bottom: ${calcTaskPanelBorderBottom};
  }
`;

export const TaskCircleWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 36px;
  height: 60px;
  padding: ${(props) => (props.isOnProjectView ? `0 8px 0 0` : `0 4px`)};
  justify-content: center;
`;

const animateCheck = keyframes`
  0% {
    transform: scale(1.0);;
    stroke: #fff;
  }

  50% {
    transform: scale(1.6);;
    stroke: #fff;
  }

  100% {
    transform: scale(1.0);
    stroke: #fff;
  }
`;

export const TaskCircleToggleWrapper = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
`;

export const TaskCircleImage = styled.svg.attrs(({ $isAnimating }) => ({
  className: cn({ isAnimating: $isAnimating })
}))`
  width: 26px;
  height: 26px;

  .isAnimating {
    animation: 0.25s ${animateCheck} ease-out;
  }
`;

export const TaskCirclePath = styled.path`
  opacity: ${({ isCompleted }) => (isCompleted ? '0.8' : '1.0')};
  stroke: ${({ isCompleted }) =>
    isCompleted ? theme.colors.colorDarkGreen2 : theme.colors.colorLightGray9};
  fill: ${({ isAnimating, isGray, isDisabled }) =>
    isAnimating && !isDisabled ? '#93C597' : isGray ? '#f3f3f3' : '#fff'};

  ${TaskWrapper}:hover &,
  ${StyledTableRow}:hover & {
    stroke: ${({ isCompleted, isEditing, isDisabled, rowEditing }) =>
      isCompleted && !isDisabled
        ? theme.colors.colorDarkGreen2
        : isEditing || rowEditing
        ? '#CBCBCB'
        : '#0074d9'};
    fill: #fff;
  }
  &:hover,
  ${TaskCircleImage}:hover & {
    stroke: ${({ isCompleted, isDisabled, theme, rowEditing }) =>
      isCompleted && !isDisabled
        ? '#93C597'
        : rowEditing
        ? '#CBCBCB'
        : theme.colors.colorRoyalBlue} !important;
    fill: #fff;
  }

  &.project-detail-task {
    ${TaskWrapper}:hover &,
    ${StyledTableRow}:hover &,
    &:hover,
    ${TaskCircleImage}:hover & {
      stroke: ${({
        isCompleted,
        isEditing,
        isDisabled,
        isCreateRow,
        rowEditing
      }) =>
        isCompleted && !isDisabled
          ? theme.colors.colorDarkGreen2
          : rowEditing
          ? theme.colors.colorLightGray3
          : isCreateRow && theme.colors.colorRoyalBlue} !important;
    }
  }
  ${(props) => props.rowEditing && 'fill: transparent!important;'}
`;

export const TaskCheckImage = styled.path`
  opacity: ${({ isCompleted }) => (isCompleted ? '0.8' : '1.0')};
  visibility: ${({ isCompleted }) => (isCompleted ? 'visible' : 'hidden')};
  fill: ${({ isAnimating, isCompleted, isDisabled, theme }) =>
    isAnimating
      ? '#FFF'
      : isCompleted && !isDisabled
      ? '#93C597'
      : theme.colors.colorRoyalBlue};

  ${TaskWrapper}:hover &,
  ${StyledTableRow}:hover & {
    visibility: visible;
    fill: ${({ isCompleted, isDisabled }) =>
      isCompleted && !isDisabled ? '#93C597' : '#e3e3e3'};
  }

  ${StyledTaskContentMiddle}:hover & {
    visibility: visible;
    fill: ${({ isCompleted, isDisabled }) =>
      isCompleted && !isDisabled ? '#93C597' : '#e3e3e3'};
  }
  ${TaskCircleCheckWrapper}:hover & {
    fill: ${({ isCompleted, isDisabled, theme }) =>
      isCompleted && !isDisabled ? '#93C597' : theme.colors.colorRoyalBlue};
  }
  ${(props) => props.rowEditing && 'visibility: hidden!important;'}

  &.project-detail-task {
    fill: ${({ isCreateRow, isCompleted }) =>
      isCreateRow
        ? theme.colors.colorBudgetGrey
        : isCompleted
        ? theme.colors.colorDarkGreen2
        : 'transparent'};
    visibility: visible;
    ${TaskWrapper}:hover &,
    ${StyledTableRow}:hover &,
    ${StyledTaskContentMiddle}:hover &,
    ${TaskCircleCheckWrapper}:hover {
      visibility: visible;
      fill: ${({ isCompleted, isDisabled, isCreateRow }) =>
        isCompleted && !isDisabled
          ? theme.colors.colorDarkGreen2
          : isCreateRow && theme.colors.colorRoyalBlue};
    }
  }
`;

export const CreateTaskClickBox = styled.div`
  margin-top: 0px;
  width: 98px;
  padding-left: 12px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 84px;
  align-self: center;
`;

export const TaskCreationMainContentPanelBase = styled(TaskMainContentPanel)`
  flex: auto;

  ${TaskDescriptionAndProjectContainer} {
    padding: 21px 3px 3px;
  }
  ${TaskContentRight} {
    align-self: center;
    height: 100%;
  }

  ${TaskCircleWrapper} {
    padding-left: 5px;
    padding-right: 5px;
    width: 47px;
    margin-right: 10px;
  }

  ${TaskCircleCheckWrapper} {
    margin-top: 18px;
  }

  ${Wrapper} {
    ${(props) => (props.projectDetail ? 'height: 60px' : '')};
  }

  .assignee-tooltip {
    left: unset;
  }

  .tooltip-arrow {
    left: calc(50% - 10px);
  }

  ${TaskCirclePath} {
    fill: #fff;
  }
  ${TaskContentLeft},
  ${CreateTaskClickBox},
  ${TaskColumnBox} {
    flex-grow: 0;
  }
`;

export const TaskCreationMainContentPanel = styled(
  TaskCreationMainContentPanelBase
)`
  margin-bottom: 0px !important;
  margin-left: ${({ projectDetail, isOnPersonalProject }) =>
    projectDetail
      ? '0 '
      : isOnPersonalProject
      ? '-4px !important'
      : '8px !important'};
`;

export const VirtualTaskCreationMainContentPanel = styled(
  TaskCreationMainContentPanelBase
)`
  height: 60px;
  margin-bottom: 0px;
  margin-left: ${({ isOnPersonalProject }) =>
    isOnPersonalProject ? '0' : '3px'};
`;

export const DropdownArrowWrapper = styled.span`
  margin-left: 6px;
  display: ${({ taskBeingEdited, isDetailModal }) =>
    taskBeingEdited || isDetailModal ? 'inline-block' : 'none'};
`;

export const EstimatedIcon = styled(EstimatedHoursIcon)`
  path {
    fill: ${theme.colors.colorSemiDarkGray1};
  }
  height: 12px;
  width: 12px;
  margin: 0px 4px;
  display: ${({ displayShow }) => (displayShow ? 'flex' : 'none')};
  opacity: ${({ show, taskIsEditing }) => (show || taskIsEditing ? 1 : 0)};
  ${TaskWrapper}:hover &,
  ${StyledTableRow}:hover & {
    opacity: 1;
  }
`;

export const EstimatedInput = styled(NumberFormat)`
  background-color: transparent;
  border: 1px solid transparent;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  font-size: 12px;
  height: 100%;
  padding: 0 1em;
  text-align: center;
  width: 100%;

  &::placeholder {
    color: transparent;
    font-family: 'proxima-nova';
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
  }

  ${TaskWrapper}:hover &, ${StyledTableRow}:hover & {
    color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
    &::placeholder {
      color: ${({ theme }) => theme.colors.colorMediumGray5};
    }
  }

  &:focus {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
    text-align: right;
  }

  &.taskIsEditing::placeholder {
    color: ${({ theme }) => theme.colors.colorMediumGray5};
  }
`;

export const Hours = styled.span`
  position: relative;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  font-weight: normal;
  text-transform: initial;
  padding-right: 25px;
`;

export const StyledTotalEstHours = styled.div`
  font-size: 14px;
  font-family: proxima-nova;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};

  text-align: center;
  padding-top: 4px;
  border: 1px solid transparent;
  background-color: transparent;

  ${Hours} {
    font-weight: 600;
  }
`;

const BeforeTaskPropertyDropdown = generatePageOverlay({
  zIndex: 1,
  backgroundColor: 'transparent'
});

export const TaskStatusDragGripPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  margin-top: 3px;
`;

export const StyledTaskStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTaskStatusDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 30px;
  background: ${({ color }) => color};
  flex-shrink: 0;
`;
export const StyledEmptyTaskStatusDot = styled(StyledTaskStatusDot)`
  background: transparent;
  border: 1px solid ${theme.colors.colorMediumGray1};
`;
export const StyledTaskStatus = styled.div``;

const calcTaskStatusPadding = (props) => {
  if (props.isEditing) {
    return 0;
  } else if (props.editMode) {
    return 8;
  } else {
    return 20;
  }
};

export const StyledListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: ${calcTaskStatusPadding}px;
  padding-right: ${calcTaskStatusPadding}px;
  padding-top: ${({ isEditing }) => (isEditing ? '0px' : '8px')};
  padding-bottom: ${({ isEditing }) => (isEditing ? '0px' : '8px')};
  min-width: 130px;
  cursor: ${({ isEditing }) => (isEditing ? 'default' : 'pointer')};
  :hover {
    background-color: ${({ isEditing }) =>
      isEditing ? 'transparent' : theme.colors.colorTranslucentGray5};
  }
`;

export const StyledTaskStatusListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const PencilContainer = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => (props.editMode ? 'visible' : 'hidden')};
`;

const TaskCellDropdown = styled.div`
  background-color: white;
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);
`;

export const OptionsDropdown = styled.div`
  background-color: white;
  display: flex;
  max-height: 320px;
  overflow-y: auto;
  flex-direction: column;
`;

export const StyledMilestone = styled.div`
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  z-index: 1;
  text-align: center;

  cursor: pointer;
  color: ${theme.colors.colorSemiDarkGray1};

  ${(props) => props.isDropdownOpen && BeforeTaskPropertyDropdown};
`;

export const NewTaskProperty = styled.div.attrs(
  ({ $isNewTask, $isTaskModal, $showValue, $showOnHover, $taskIsEditing }) => ({
    className: cn({
      showValue: $showValue,
      showOnTaskHover:
        !$isNewTask && !$isTaskModal && !$taskIsEditing && $showOnHover
    })
  })
)`
  align-items: center;
  color: ${({ theme }) => theme.colors.colorMediumGray5};
  cursor: pointer;
  display: flex;
  font-size: 11px;
  height: 100%;
  justify-content: center;
  opacity: 0.6;
  text-transform: uppercase;
  width: 100%;

  &.showOnTaskHover {
    visibility: hidden;
  }

  &.showValue {
    opacity: 1;
  }

  &.isDropdownOpen {
    ${BeforeTaskPropertyDropdown}
  }

  // Show the optionally hidden value when hovering over the task item.
  ${TaskWrapper}:hover &, ${StyledTableRow}:hover & {
    visibility: visible;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.colorRoyalBlue};
    opacity: 1;

    ${StyledTaskStatusIcon} circle,
    ${StyledPriorityIcon}.no-priority path,
    ${StyledTaskMilestoneIcon} rect {
      stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }

  // Add a space between a SVG followed by a label
  svg + * {
    margin-left: 5px;
  }
`;

export const MilestoneDate = styled.div`
  margin-top: -3px;
`;

export const MilestoneDescription = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  width: 95%;
`;

export const StyledMilestoneRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray1};
  background-color: ${({ isSelected, theme }) =>
    isSelected && theme.colors.colorPaleGray7};
  margin: 2px 0px;
  cursor: pointer;
  padding: 4px 10px;
  min-height: 43px;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorTranslucentGray5};
  }
`;

const MilestoneDropdownWidth = 200;
export const MilestoneDropdown = styled(TaskCellDropdown)`
  width: ${`${MilestoneDropdownWidth}px`};
`;

export const MilestoneDropdownContent = styled.div`
  padding: 5px 0px 0px 0px;

  ${MilestoneDate} {
    color: ${theme.colors.colorMediumGray5};
  }
  ${StyledMilestone} {
    margin-right: 10px;
  }
`;

export const TaskPropertyCell = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5px;
  font-size: 11px;
  ${({ estimatedHours }) => estimatedHours && `cursor: text;`}

  &:hover {
    ${Hours},
    ${EstimatedIcon} {
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
      opacity: 1;
    }
    ${EstimatedInput} {
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
    ${EstimatedInput}::placeholder {
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
    ${EstimatedIcon} {
      path {
        fill: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }
    ${StyledTimelineIcon} {
      path {
        fill: ${({ theme }) => theme.colors.colorRoyalBlue};
      }
    }
  }
`;

export const ProjectTitleCircle = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${calculateProjectColorPreference};
  margin-right: 5px;
  flex: none;
`;

export const StyledTaskCompleted = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 15px;
  color: ${theme.colors.colorSemiDarkGray1};

  ${StyledTaskCompletedIcon} {
    margin-right: 5px;
  }
`;

export const StyledTaskMoveMenuContainer = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  svg {
    height: 24px;
    width: 24px;
    transform: rotate(90deg);
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }
`;

export const StyledTaskMoveMenu = styled.div`
  height: 100px;
  width: 124px;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  flex-direction: column;
  background-color: ${theme.colors.colorPureWhite};
  box-shadow: 0px 1px 4px rgba(82, 82, 82, 0.5);

  &::before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    width: 36px;
    height: 36px;
    top: 0px;
    left: 74px;
    z-index: -1;
    box-shadow: 0px 0px 5px 0px rgba(82, 82, 82, 0.5);
  }
  &::after {
    top: -26px;
    left: 92px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 18px;
    margin-left: -18px;
    transform: rotate(180deg);
  }
`;

export const TaskMoveMenuPopout = styled.div`
  position: absolute;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
  z-index: 1;
`;
export const TaskMoveMenuListItem = styled.div`
  display: flex;
  height: 20px;
  align-items: center;
  font-size: 14px;
  height: 40px;
  color: #3b3b3b;

  &.disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.colorLightGray6};
  }
`;

const BASE_PROJECT_WIDTH = 498;
const BASE_HOME_WIDTH = 576;
export const RemovedTask = styled.div`
  margin-left: ${(props) => (props.isOnHomeView ? '22px' : '62px')};
  margin-right: 18px;
  padding-left: ${(props) => (props.isOnHomeView ? '28px' : '48px')};
  background-color: #f3f3f3;
  color: #979797;
  font-size: 14px;
  flex: none;
  width: ${(props) =>
    calculateHeaderWidth(
      props.activeTaskColumns.filter((item) => item !== 'timer'),
      props.isOnHomeView ? BASE_HOME_WIDTH : BASE_PROJECT_WIDTH
    )}px;
  display: flex;
  align-items: center;
`;

export const FlyoutMenuWrapper = styled.div`
  position: absolute;
  right: -120px;
  background-color: #fff;
  width: 150px;
`;

export const StatusEditHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;
  align-items: center;
`;
export const SaveButton = styled.div`
  font-size: 12px;
  border-radius: 5px;
  color: ${theme.colors.colorPureWhite};
  padding: 4px 10px;
  background: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
`;

export const DeleteButton = styled.button`
  color: ${(props) =>
    props.newStatus ? '#A4A4A4' : theme.colors.colorDeleteRed};
  border: none;
  background: ${theme.colors.colorTranslucentGray4};
  padding: 10px 0px;
`;

export const DeleteButtonContainer = styled.div`
  justify-content: center;
  background: ${theme.colors.colorTranslucentGray4};
  display: flex;
`;
export const StatusEditInput = styled.input`
  background: ${theme.colors.colorTranslucentGray4};
  border: none;
  height: 28px;
  margin: 5px 10px;
  padding: 0 8px;
  font-size: 12px;
  :focus {
    border: 1px solid #0074d9;
  }
`;

export const DoneButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isBlueButton }) =>
    isBlueButton ? theme.colors.colorPureWhite : theme.colors.colorRoyalBlue};
  background-color: ${({ isBlueButton }) =>
    isBlueButton ? theme.colors.colorRoyalBlue : theme.colors.colorPaleGray7};
  padding: 8px 0px;
  margin-top: 10px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  border-top: 1px solid ${theme.colors.colorLightGray9};
  cursor: pointer;
`;
export const NewStatusText = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  font-size: 13px;
  display: flex;
  padding: 10px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const DropdownScrollableContainer = styled.div``;

export const AddTimelinesText = styled.div`
  color: ${theme.colors.colorRoyalBlue};
  background: ${theme.colors.colorPaleGray7};
  border-top: 1px solid ${theme.colors.colorLightGray9};
  text-align: center;
  padding: 12px 10px;
  margin-top: 4px;
  cursor: pointer;
  font-weight: 600;
`;

export const DropdownItemComponent = styled.div`
  width: ${({ isInDropdown, hasTitle }) =>
    isInDropdown || hasTitle ? '100%' : '0px'};
  height: ${({ hasTitle, shouldNotCenter }) =>
    hasTitle && shouldNotCenter ? '100%' : ''};
  padding-top: ${({ hasTitle, shouldNotCenter }) =>
    hasTitle && shouldNotCenter ? '14px' : ''};
`;

export const StyledTaskPriorityContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -1px;
  /*to compensate for the padding in the cell and to center it, the negative margin was added;*/
`;

export const StyledTaskPriorityText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray1};
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-left: 8px;
`;

export const StyledTaskStatusText = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray1};
  margin-left: 8px;
  max-width: 200px;
`;

export const SelectedTaskPriority = styled.div`
  padding: 0px 4px;
  ${StyledTaskPriorityContainer} {
    justify-content: center;
  }
`;

export const EmptyPropertyContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TaskProjectTitleGroup = styled.div`
  display: inline-flex;
  align-items: baseline;
  position: relative;
  max-width: 450px;
`;

export const TaskProjectDescription = styled.span`
  font-size: 11px;
  font-weight: 400;
  color: ${theme.colors.colorMediumGray5};
`;
export const TaskProjectTitle = styled.div`
  margin-left: 12px;
  margin-right: 4px;
  font-size: 12px;
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  overflow-x: hidden;
  font-weight: 400;
  color: ${({ isEmpty }) =>
    isEmpty ? theme.colors.colorRoyalBlue : theme.colors.colorSemiDarkGray4};
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${TaskProjectDescription} {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const SelectedTaskList = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-left: 4px;
  color: ${theme.colors.colorSemiDarkGray4};
  white-space: nowrap;
  text-overflow: ellipses;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledKaratRight = styled(KaratRight)`
  height: 10px !important;
  width: 6px !important;
`;

export const GoToProjectText = styled.div`
  position: absolute;
  top: 20px;
  left: 12px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledDateAndDescription = styled.div`
  width: 100%;
  position: relative;
`;
export const StyledMilestoneIconContainer = styled.div`
  align-self: ${({ center }) => (center ? 'center' : 'flex-start')};
  margin-right: 5px;
`;

export const CloseModalIconContainer = styled.span`
  position: absolute;
  top: -30px;
  right: 0;
  cursor: pointer;

  svg {
    position: unset;
  }
`;
