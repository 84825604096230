import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccountFiltersFetched } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';

import PhaseNamesFilterDropdown from 'components/filters/PhaseNamesFilterDropdown';
import BillableFilterDropdown from 'components/filters/BillableFilterDropdown';
import ActivitiesFilterDropdown from 'components/filters/ActivitiesFilterDropdown';
import ClientsFilterDropdown from 'components/filters/ClientsFilterDropdown';
import TimesheetStatusFilterDropdown from 'components/filters/TimesheetStatusFilterDropdown';
import MembersFilterDropdown from 'components/filters/MembersFilterDropdown';
import ProjectsFilterDropdown from 'components/filters/ProjectsFilterDropdown';
import ProjectStatusOptionsDropdown from 'components/filters/ProjectStatusOptionsDropdown';
import ProjectPrioritiesFilterDropdown from 'components/filters/ProjectPrioritiesFilterDropdown';
import PositionFilterDropdown from 'components/filters/PositionFilterDropdown';
import PhaseBudgetStatusFilterDropdown from 'components/filters/PhaseBudgetStatusFilterDropdown';
import PhaseFeeTypeFilterDropdown from 'components/filters/PhaseFeeTypeFilterDropdown';
import CustomFieldsFilterDropdown from './CustomFieldsFilterDropdown';
import EditorsFilterDropdown from './EditorsFilterDropdown';
import FilterIcon from 'icons/WideFilterIcon';
import ThreeLineWithPlusIcon from 'icons/ThreeLineWithPlusIcon';
import DeleteXIcon from 'icons/DeleteXIcon';

import {
  FILTER_SECTIONS,
  filterSectionToFilterKey
} from 'appConstants/filters';
import get from 'lodash/get';
import { INTERNAL_LABELS } from 'appConstants/customFields';
import ProjectBudgetStatusFilterDropdown from './ProjectBudgetStatusFilterDropdown';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import { ValueOf } from 'type-fest';
import { StateAccountFilter } from 'models/filter';

const emptyArr = [];

const sectionToComponent = {
  [FILTER_SECTIONS.PHASES]: PhaseNamesFilterDropdown,
  [FILTER_SECTIONS.BILLABLE]: BillableFilterDropdown,
  [FILTER_SECTIONS.ACTIVITIES]: ActivitiesFilterDropdown,
  [FILTER_SECTIONS.CLIENTS]: ClientsFilterDropdown,
  [FILTER_SECTIONS.STATUSES]: TimesheetStatusFilterDropdown,
  [FILTER_SECTIONS.MEMBERS]: MembersFilterDropdown,
  [FILTER_SECTIONS.PROJECTS]: ProjectsFilterDropdown,
  [FILTER_SECTIONS.PROJECT_STATUSES]: ProjectStatusOptionsDropdown,
  [FILTER_SECTIONS.PROJECT_PRIORITY]: ProjectPrioritiesFilterDropdown,
  [FILTER_SECTIONS.PROFIT_CENTERS]: CustomFieldsFilterDropdown,
  [FILTER_SECTIONS.PROJECT_BUDGET_STATUSES]: ProjectBudgetStatusFilterDropdown,
  [FILTER_SECTIONS.EDITORS]: EditorsFilterDropdown,
  [FILTER_SECTIONS.POSITIONS]: PositionFilterDropdown,
  [FILTER_SECTIONS.PHASE_BUDGET_STATUSES]: PhaseBudgetStatusFilterDropdown,
  [FILTER_SECTIONS.PHASE_FEE_TYPES]: PhaseFeeTypeFilterDropdown
};

const sectionToComponentProps = {
  [FILTER_SECTIONS.PROFIT_CENTERS]: {
    internalLabel: INTERNAL_LABELS.PROFIT_CENTER
  }
};

const StyledFilters = styled.div`
  display: flex;
  height: 24px; // match height of toggles
  .filter-dropdown-toggle {
    margin-right: 4px;
    margin-bottom: 2px;
  }
  .filter-icon {
    transform: scale(1.1, 1);
    margin-right: 10px;
    cursor: pointer;
  }
`;

const FilterIconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FilterSections = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ThreeLineWithPlusIconContainer = styled.div`
  padding-right: 10px;

  .filter-icon {
    margin-right: 3px;
  }

  span {
    font-size: 13px;
    color: ${theme.colors.colorRoyalBlue};
  }

  svg {
    height: 11px;
  }
`;

export type ActiveFilter = Omit<
  StateAccountFilter,
  'is_default' | 'page' | 'team_id' | 'team_membership_id'
>;

export interface FilterProps<Filter extends ActiveFilter> {
  activeFilter?: Filter;
  className?: string;
  sections?: readonly ValueOf<typeof FILTER_SECTIONS>[];
  useNewIconWithText?: boolean;
}

const Filters = <Filter extends ActiveFilter>({
  activeFilter,
  className,
  sections = emptyArr,
  useNewIconWithText = false
}: FilterProps<Filter>) => {
  // default: shows only filters that have filtered items
  const [showEmptyFilters, setShowEmptyFilters] = useState(false);
  const filtersFetched = useSelector(getAccountFiltersFetched);
  const { isHoursOnly } = useIsHoursOnly();

  const shouldDisplay = sections.length && activeFilter && filtersFetched;

  const shouldDisplaySections = !isHoursOnly
    ? sections
    : sections.filter(
        // Filter out Billable Filter if the team is hours only.
        (section) =>
          section !== FILTER_SECTIONS.BILLABLE &&
          section !== FILTER_SECTIONS.PHASE_FEE_TYPES
      );

  const visibleSections = useMemo(() => {
    if (!shouldDisplay) return;
    if (showEmptyFilters) return new Set(shouldDisplaySections);
    return new Set(
      shouldDisplaySections.filter((section) => {
        const filterKey = filterSectionToFilterKey[section];
        if (!filterKey) return false;

        const filterValue = get(activeFilter, filterKey);

        switch (section) {
          // option dropdown need this condition to be shown on filters
          // FIXME: if we have more option dropdowns here, it need to be refactored
          case FILTER_SECTIONS.PROJECT_STATUSES:
            return filterValue !== null;
          default:
            return filterValue?.length;
        }
      })
    );
  }, [activeFilter, shouldDisplaySections, shouldDisplay, showEmptyFilters]);

  if (!visibleSections) return null;

  return (
    <StyledFilters className={className}>
      <FilterIconContainer>
        {showEmptyFilters ? (
          <DeleteXIcon
            width={29}
            height={12}
            color={theme.colors.colorRoyalBlue}
            className="filter-icon"
            onClick={() => setShowEmptyFilters(!showEmptyFilters)}
          />
        ) : useNewIconWithText ? (
          <ThreeLineWithPlusIconContainer
            onClick={() => setShowEmptyFilters(!showEmptyFilters)}
          >
            <ThreeLineWithPlusIcon className="filter-icon" />
            <span>Filter</span>
          </ThreeLineWithPlusIconContainer>
        ) : (
          <FilterIcon
            width={29}
            height={16}
            color={theme.colors.colorCalendarBlue}
            className="filter-icon"
            onClick={() => setShowEmptyFilters(!showEmptyFilters)}
          />
        )}
      </FilterIconContainer>
      <FilterSections>
        {shouldDisplaySections.map((section) => {
          const FilterComponent = sectionToComponent[section];
          return (
            FilterComponent && (
              <FilterComponent
                key={section}
                activeFilter={activeFilter}
                {...sectionToComponentProps[section]}
                isHidden={!visibleSections.has(section)}
              />
            )
          );
        })}
      </FilterSections>
    </StyledFilters>
  );
};

export default React.memo(Filters);
