import React from 'react';
import SvgIcon from 'components/SvgIcon';

const KaratRight = ({
  className = 'karat-right',
  height = '10px',
  width = '6px'
}) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 6 10"
    className={className}
  >
    <path
      id="karat-right"
      d="M0.376354 1.33651L4.04004 5.00019L0.376354 8.66387L1.1601 9.46387L5.62378 5.00019L1.1601 0.536507L0.376354 1.33651Z"
      fill="#4a4a4a"
    />
  </SvgIcon>
);

export default KaratRight;
