import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment
} from 'react';
import ContactAdminMessage from './ContactAdminMessage';
import SendArrowIcon from 'icons/SendArrowIcon';
import HelpIcon from 'icons/HelpIcon';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import InviteTeamMemberFormConfirmCancelModal from './InviteTeamMemberFormConfirmCancelModal';

import {
  getIsOnProjectView,
  getTeamSlug,
  getUserIsAdmin,
  getGroupsErrorMessage,
  getOnBoardView
} from 'selectors';
import { useList } from 'react-use';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { toggleInviteForm } from 'actionCreators';
import styled from 'styled-components';
import theme from 'theme';
import { TextButtonWithBorder } from 'components/styles';
import { AccessRoles, EmploymentTypes, Invitee } from 'PermissionsModule/types';
import { ACCESS_ROLES, EMPLOYMENT_TYPES } from 'PermissionsModule/constants';
import {
  MemberEditFormFields,
  MemberEditFormFieldsProps
} from 'TeamsModule/components/MemberEditFormFields';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import useCan from 'appUtils/hooks/useCan';
import { inviteMemberToTeam } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/organization';
import { isValidEmail } from 'appUtils/formatUtilsTyped';

const generateInviteeObject = ({
  includeMember
}: {
  includeMember: boolean;
}): Invitee => {
  /*
    If you have permission to invite to organization then you have all options for employmentType
    If you only have invite guest permission then the options will onnly include Guest and External Project Contractor.
  */
  return {
    firstName: '',
    lastName: '',
    email: '',
    referenceNumber: '',
    employmentType: includeMember
      ? EMPLOYMENT_TYPES.member
      : EMPLOYMENT_TYPES.externalProjectContractor,
    teamRole: ACCESS_ROLES.TEAM_MEMBER
  };
};

export interface InviteTeamMemberFormProps {
  formSubmit: (
    event: MouseEvent | FormEvent,
    inviteeRole: string,
    permissions: Record<string, unknown>,
    invitees: Invitee[]
  ) => void;
  newFormSubmit: (params: { invitees: Invitee[] }) => void;
}

export const InviteTeamMemberForm = ({
  formSubmit,
  newFormSubmit
}: InviteTeamMemberFormProps) => {
  const selectedTeamId = useAppSelector(getSelectedTeamId);
  const isOnProjectView = useAppSelector(getIsOnProjectView);
  const isOnBoardView = useAppSelector(getOnBoardView);
  const teamSlug = useAppSelector(getTeamSlug);
  const userIsAdmin = useAppSelector(getUserIsAdmin);
  const errorMessage = useAppSelector(getGroupsErrorMessage);

  const [memberTabActive, setMemberTabActive] = useState(true);
  const [showMultiple, setShowMultiple] = useState(false);

  const canInviteToTeam = useCan(inviteMemberToTeam);

  const [invitees, inviteesListActions] = useList([
    generateInviteeObject({ includeMember: canInviteToTeam })
  ]);

  const [isShowConfirmCancel, setIsShowConfirmCancel] = useState(false);

  const { newPermissionSettings } = useFeatureFlags();

  const dispatch = useAppDispatch();

  const canSubmit = useMemo(() => {
    const inviteeIsValid = ({ email, firstName, lastName }: Invitee) =>
      (email && isValidEmail({ email }) && firstName && lastName) ||
      (!email && !firstName && !lastName);

    return invitees.every(inviteeIsValid);
  }, [invitees]);

  const noProjectGuest = !isOnProjectView;
  const noExternalProjectContractor = isOnBoardView;
  const showContactAdminMessage = !userIsAdmin && !memberTabActive;

  useEffect(() => {
    rebuildTooltip();
  });

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      index: number,
      fieldToChange: keyof Invitee
    ) => {
      const inviteeBeforeUpdate = invitees[index];

      if (inviteeBeforeUpdate) {
        inviteesListActions.updateAt(index, {
          ...inviteeBeforeUpdate,
          [fieldToChange]: event.target.value
        });
      }

      event.stopPropagation();
    },
    [invitees, inviteesListActions]
  );

  const handleChangeEmploymentType = useCallback(
    ({
      employmentType,
      index
    }: {
      employmentType: EmploymentTypes;
      index: number;
    }) => {
      const inviteeBeforeUpdate = invitees[index];

      if (inviteeBeforeUpdate) {
        // external project contractors and project guests can only have login access role
        const inviteeTeamRole =
          employmentType === EMPLOYMENT_TYPES.externalProjectContractor ||
          employmentType === EMPLOYMENT_TYPES.projectGuest
            ? ACCESS_ROLES.LOGIN
            : inviteeBeforeUpdate.teamRole;

        inviteesListActions.updateAt(index, {
          ...inviteeBeforeUpdate,
          employmentType,
          teamRole: inviteeTeamRole
        });
      }
    },
    [invitees, inviteesListActions]
  );

  const handleChangeTeamRole = useCallback(
    ({ role, index }: { role: AccessRoles; index: number }) => {
      const inviteeBeforeUpdate = invitees[index];

      if (inviteeBeforeUpdate) {
        inviteesListActions.updateAt(index, {
          ...inviteeBeforeUpdate,
          teamRole: role
        });
      }
    },
    [invitees, inviteesListActions]
  );

  const getPermissions = useCallback(() => {
    const permissions = {
      teamId: selectedTeamId
    };

    return permissions;
  }, [selectedTeamId]);

  const handleSubmit = useCallback(
    (e: MouseEvent | FormEvent) => {
      const permissions = getPermissions();

      if (newPermissionSettings) {
        newFormSubmit({ invitees });
      } else {
        const inviteeRole = memberTabActive ? 'member' : 'guest';
        formSubmit(e, inviteeRole, permissions, invitees);
      }
    },
    [
      formSubmit,
      getPermissions,
      invitees,
      memberTabActive,
      newFormSubmit,
      newPermissionSettings
    ]
  );

  const addMoreInvitees = useCallback(() => {
    inviteesListActions.push(
      ...[
        generateInviteeObject({ includeMember: canInviteToTeam }),
        generateInviteeObject({ includeMember: canInviteToTeam }),
        generateInviteeObject({ includeMember: canInviteToTeam })
      ]
    );
  }, [canInviteToTeam, inviteesListActions]);

  const showMultipleInviteeForm = (e: MouseEvent) => {
    e.stopPropagation();
    addMoreInvitees();
    setShowMultiple(true);
  };

  const toggleMemberTabActive = (e: MouseEvent) => {
    e.stopPropagation();
    setMemberTabActive(!memberTabActive);
  };

  const handleChangeAllInviteeFields = useCallback(
    (index: number) => (fields: Invitee) => {
      inviteesListActions.updateAt(index, fields);
    },
    [inviteesListActions]
  );

  const employmentTypeOptions: MemberEditFormFieldsProps['employmentTypeOptions'] =
    useMemo(() => {
      return {
        [EMPLOYMENT_TYPES.member]: { isAvailable: true },
        [EMPLOYMENT_TYPES.internalContractor]: { isAvailable: true },
        [EMPLOYMENT_TYPES.externalProjectContractor]: {
          isAvailable: !noExternalProjectContractor
        },
        [EMPLOYMENT_TYPES.projectGuest]: {
          isAvailable: !noProjectGuest,
          unavailableTooltip:
            'Project Guests only have access to individual projects and must be invited directly from the project.'
        }
      };
    }, [noExternalProjectContractor, noProjectGuest]);

  const renderInviteFormFields = useCallback(
    ({ invitee, index }: { invitee: Invitee; index: number }) => {
      return (
        <MemberEditFormFields
          formFields={invitee}
          employmentTypeOptions={employmentTypeOptions}
          onChange={({ event, field }) => handleChange(event, index, field)}
          onChangeTeamRole={(role) => handleChangeTeamRole({ role, index })}
          onChangeEmploymentType={(employmentType) =>
            handleChangeEmploymentType({ employmentType, index })
          }
          onChangeAllFields={handleChangeAllInviteeFields(index)}
        />
      );
    },
    [
      employmentTypeOptions,
      handleChange,
      handleChangeAllInviteeFields,
      handleChangeEmploymentType,
      handleChangeTeamRole
    ]
  );

  const renderSingleInvite = () => {
    const firstInvitee = invitees[0];
    if (!firstInvitee) return <></>;

    return (
      <SingleInviteWrapper>
        <InviteTitle>
          <StyledInviteTitleText>
            {memberTabActive ? (
              'Invite to Mosaic'
            ) : (
              <>
                Invite a Guest to Project
                <HelpIconContainer
                  data-for="app-tooltip"
                  data-tip="Guests can only be invited<br>to individual projects"
                  data-html
                  data-effect="solid"
                  data-class="center"
                >
                  <HelpIcon circleColor="#808080" fillColor="tranparent" />
                </HelpIconContainer>
              </>
            )}
          </StyledInviteTitleText>
        </InviteTitle>
        {showContactAdminMessage ? (
          <ContactAdminMessage />
        ) : (
          <>
            {renderInviteFormFields({ invitee: firstInvitee, index: 0 })}
            {errorMessage ? (
              <ErrorMessageContainer>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </ErrorMessageContainer>
            ) : null}
            <InviteTeamButtonContainer>
              <InviteMembersLinkContainer>
                {!noProjectGuest &&
                  memberTabActive &&
                  !newPermissionSettings && (
                    <InviteMultipleLink onClick={toggleMemberTabActive}>
                      Invite a Project Guest
                    </InviteMultipleLink>
                  )}
                {memberTabActive && (
                  <InviteMultipleLink
                    onClick={
                      !isOnProjectView
                        ? showMultipleInviteeForm
                        : () =>
                            window.open(
                              `${window.location.origin}/${teamSlug}/members/members/views`
                            )
                    }
                  >
                    {isOnProjectView && <GoToProjectIcon />}
                    Invite Multiple Members
                  </InviteMultipleLink>
                )}
              </InviteMembersLinkContainer>
              {renderActionButtons({
                onCancel: () => dispatch(toggleInviteForm({ open: false }))
              })}
            </InviteTeamButtonContainer>
          </>
        )}
      </SingleInviteWrapper>
    );
  };

  const renderActionButtons = useCallback(
    ({ onCancel }: { onCancel: () => void }) => {
      return (
        <InviteButtonWrapper>
          <TextButtonWithBorder onClick={onCancel}>Cancel</TextButtonWithBorder>
          <InviteButton
            type="submit"
            disabled={!canSubmit}
            onClick={handleSubmit}
          >
            <SendArrowIcon
              width={16}
              height={13}
              color="#fff"
              strokeWidth={0.7}
              style={{ marginTop: 2 }}
            />
            <span>Send Invite</span>
          </InviteButton>
        </InviteButtonWrapper>
      );
    },
    [canSubmit, handleSubmit]
  );

  const renderMultipleInvites = () => {
    return (
      <MultipleInvitesWrapper>
        <RowsContainer>
          {errorMessage ? (
            <ErrorMessageContainer>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorMessageContainer>
          ) : null}
          <ActionsRow>
            <InviteToTitle>Invite to Mosaic</InviteToTitle>
            {renderActionButtons({
              onCancel: () => setIsShowConfirmCancel(true)
            })}
          </ActionsRow>
          <MultipleInviteesContainer>
            {invitees.map((invitee, index) => (
              <Fragment key={index}>
                {renderInviteFormFields({ invitee, index })}
                {index !== invitees.length - 1 && <DividerLine />}
              </Fragment>
            ))}
          </MultipleInviteesContainer>
          <AddMoreLinkContainer>
            <AddMoreLink onClick={addMoreInvitees}>Add More</AddMoreLink>
          </AddMoreLinkContainer>
        </RowsContainer>
        <InviteTeamMemberFormConfirmCancelModal
          isOpen={isShowConfirmCancel}
          handleConfirmation={() => dispatch(toggleInviteForm({ open: false }))}
          toggleShowConfirmCancel={() => setIsShowConfirmCancel(false)}
        />
      </MultipleInvitesWrapper>
    );
  };

  return (
    <RootContainer onSubmit={handleSubmit}>
      {showMultiple ? renderMultipleInvites() : renderSingleInvite()}
    </RootContainer>
  );
};

const MultipleInvitesWrapper = styled.div`
  max-height: 95vh;
  height: 95vh;
  max-width: 90vw;
  width: 90vw;
  overflow-y: auto;
  padding-top: 95px;
  background-color: ${theme.colors.colorLightGray19};
`;
const SingleInviteWrapper = styled.div`
  width: 500px;
  padding: 40px 48px;
  background-color: ${theme.colors.colorLightGray19};
`;

const ActionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const InviteButton = styled.button`
  display: flex;
  align-items: center;
  background: ${theme.colors.colorRoyalBlue};
  border: none;
  border-radius: 3px;
  font-size: 15px;
  width: 100%;
  height: 36px;
  color: ${theme.colors.colorPureWhite};
  padding: 6px 12px;

  &:hover {
    background-color: #0059a6;
  }

  &:disabled {
    background: ${theme.colors.colorMediumGray1};
    border-color: ${theme.colors.colorMediumGray1};
  }

  svg {
    margin: 0 5px;
  }
`;
const InviteToTitle = styled.div`
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  color: ${theme.colors.colorPureBlack};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`;
const RowsContainer = styled.div`
  width: 403px;
  margin: 0 auto;
`;
const InviteMultipleLink = styled.div`
  margin: auto 0px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;

  :hover {
    font-weight: 600;

    svg {
      transform: scale(1.1);
    }
  }

  svg {
    position: relative;
    top: -1px;
    margin-left: -6px;
  }
`;

const AddMoreLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 86px;
`;

const AddMoreLink = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 15px 24px;
  cursor: pointer;
  color: ${theme.colors.colorRoyalBlue};

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

const InviteButtonWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
`;

const StyledInviteTitleText = styled.div`
  font-family: 'Open Sans';
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  color: ${theme.colors.colorPureBlack};
  font-weight: 600;
  display: flex;
`;

const HelpIconContainer = styled.span`
  margin-left: 5px;
  height: 100%;
  position: relative;
  top: -1px;
`;

const InviteMembersLinkContainer = styled.div``;

const RootContainer = styled.form`
  height: 100%;
  border: solid 1px ${theme.colors.colorPaleGray5};
`;

const InviteTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  height: inherit;
  margin-bottom: 37px;
`;

const ErrorMessageContainer = styled.div`
  text-align: right;
  padding-top: 0.4rem;
`;

const ErrorMessage = styled.div`
  color: ${theme.colors.colorCrimsonRed};
  margin-top: 1.3rem;
  font-size: 10px;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 10px;
`;

const InviteTeamButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 15px 1px 0px;
`;

const MultipleInviteesContainer = styled.div`
  display: grid;
  gap: 30px;
`;

const DividerLine = styled.div`
  height: 1px;
  border: 1px solid #dcdcdc;
`;
