import { useCallback, useEffect } from 'react';
import {
  accessBudgetReport,
  accessWorkloadReport,
  accessTimesheetReport,
  accessUtilizationReport,
  accessProfitReport,
  accessTimeVarianceReport,
  accessTimesheetStatusReport,
  accessDemandReport,
  accessWorkloadForecastReport,
  accessScheduleVarianceReport,
  accessSkillsReport,
  accessOrgChartReport,
  accessBudgetVarianceReport,
  accessCheckInsReport,
  accessPlannedTimeReport,
  accessSpentTimeReport,
  accessTimeProjectionReport,
  accessTimesheetApprovalReport,
  accessProjectsReport
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/report';
import useCan from 'appUtils/hooks/useCan';
import { useAppSelector } from 'reduxInfra/hooks';
import { getSplitFlags } from 'selectors';
import { getAllPermissionsByActions } from 'PermissionsModule/selectors';
import { ACCESS_ROLES } from 'PermissionsModule/constants';
import isEmpty from 'lodash/isEmpty';
import { RolePermission, PermissionsActions } from 'PermissionsModule/types';

// Mapping used to determine the allowed roles for each report on report landing.
const viewTypeToPermissionIdentifier: Partial<
  Record<string, PermissionsActions>
> = {
  time: 'access_timesheet_report',
  budget: 'access_budget_report',
  capacity: 'access_workload_report',
  utilization: 'access_utilization_report',
  profit: 'access_profit_report',
  variance: 'access_time_variance_report',
  budgetvariance: 'access_budget_variance_report',
  timesheetstatus: 'access_timesheet_status_report',
  demand: 'access_demand_report',
  workloadforecast: 'access_workload_forecast_report',
  skills: 'access_skills_report',
  spenttime: 'access_spent_time_report',
  plannedtime: 'access_planned_time_report',
  timeprojection: 'access_time_projection_report'
  // checkins: 'access_check_ins_report', // Don't have this on BE, not sure what intention is
  // allprojects: 'access_projects_report', // Don't have this on BE, not sure what intention is
  // schedulevariance: 'access_schedule_variance_report', // Don't have this on BE, not sure what intention is
  // unspentbudget: 'access_unspent_budget_report', // Don't have this on BE, not sure what intention is
  // pto: 'access_pto_report' // Don't have this on BE, not sure what intention is
};

// ADMIN == Admin, WORKLOAD_MANAGER == Work Planner, FINANCIAL_MANAGER == Budget Manager
const { ADMIN, WORKLOAD_MANAGER, FINANCIAL_MANAGER } = ACCESS_ROLES;

export const useReportPermissions = () => {
  const {
    budgetVarianceReport,
    workloadForecastReportFlag,
    scheduleVarianceReport,
    skillsReportFlag,
    checkInsReportFlag,
    allProjectsReportFlag,
    ptoReportFlag,
    timeProjectionReportFlag,
    spentTimeReportFlag,
    plannedTimeReportFlag,
    unspentBudgetReportFlag
  } = useAppSelector(getSplitFlags);

  const permissionsByAction = useAppSelector(getAllPermissionsByActions);

  const canAccessBudgetReport = useCan(accessBudgetReport);
  const canAccessWorkloadReport = useCan(accessWorkloadReport);
  const canAccessTimesheetReport = useCan(accessTimesheetReport);
  const canAccessUtilizationReport = useCan(accessUtilizationReport);
  const canAccessProfitReport = useCan(accessProfitReport);
  const canAccessTimeVarianceReport = useCan(accessTimeVarianceReport);
  const canAccessTimesheetStatusReport = useCan(accessTimesheetStatusReport);
  const canAccessDemandReport = useCan(accessDemandReport);
  const canAccessWorkloadForecastReport = useCan(accessWorkloadForecastReport);
  const canAccessScheduleVarianceReport = useCan(accessScheduleVarianceReport);
  const canAccessSkillsReport = useCan(accessSkillsReport);
  const canAccessBudgetVarianceReport = useCan(accessBudgetVarianceReport);
  const canAccessCheckInsReport = useCan(accessCheckInsReport);
  const canAccessPlannedTimeReport = useCan(accessPlannedTimeReport);
  const canAccessSpentTimeReport = useCan(accessSpentTimeReport);
  const canAccessTimeProjectionReport = useCan(accessTimeProjectionReport);
  const canAccessProjectsReport = useCan(accessProjectsReport);

  // Unused atm
  const canAccessOrgChartReport = useCan(accessOrgChartReport);
  const canAccessTimesheetApprovalReport = useCan(
    accessTimesheetApprovalReport
  );

  const allowedRoles = useCallback(
    ({ viewType }) => {
      // Need to get the permission name that is associated with the viewType then
      // return all the roles that have true permission value from permissionByActions
      const permissionIdentifer = viewTypeToPermissionIdentifier[viewType];
      const permissionsForViewType = permissionIdentifer
        ? permissionsByAction[permissionIdentifer] || {}
        : {};

      if (isEmpty(permissionsForViewType)) return [];

      const accessRoles = Object.entries(
        permissionsForViewType as Record<string, RolePermission>
      ).reduce((acc, [role, value]) => {
        if (value.display_value && value.permission_value) {
          // These are the roles we display on report cards.
          switch (role) {
            case 'Admin':
              acc.push(ADMIN);
              break;
            case 'Financial Manager':
              acc.push(FINANCIAL_MANAGER);
              break;
            case 'Workload Planner':
              acc.push(WORKLOAD_MANAGER);
              break;
          }
        }
        return acc;
      }, [] as number[]);

      // Check to see if permissionForViewType is empty, if so, return default permissions
      return accessRoles;
    },
    [permissionsByAction]
  );

  // Maps report page name with appropriate access permission
  // Can put split flag in here to determine if the report is available
  // If report has no permissions and flags, assign true.
  const reportPermissionHash = {
    time: canAccessTimesheetReport,
    budget: canAccessBudgetReport,
    capacity: canAccessWorkloadReport,
    utilization: canAccessUtilizationReport,
    profit: canAccessProfitReport,
    variance: canAccessTimeVarianceReport,
    budgetvariance: canAccessBudgetVarianceReport && budgetVarianceReport,
    timesheetstatus: canAccessTimesheetStatusReport,
    demand: canAccessDemandReport,
    workloadforecast:
      canAccessWorkloadForecastReport && workloadForecastReportFlag,
    schedulevariance: canAccessScheduleVarianceReport && scheduleVarianceReport,
    skills: canAccessSkillsReport && skillsReportFlag,
    checkins: canAccessCheckInsReport && checkInsReportFlag,
    allprojects: canAccessProjectsReport && allProjectsReportFlag,
    timeprojection: canAccessTimeProjectionReport && timeProjectionReportFlag,
    spenttime: canAccessSpentTimeReport && spentTimeReportFlag,
    plannedtime: canAccessPlannedTimeReport && plannedTimeReportFlag,
    unspentbudget: unspentBudgetReportFlag, // This doesnt have a permission
    pto: ptoReportFlag
  };

  return {
    reportPermissionHash,
    allowedRoles
  };
};
