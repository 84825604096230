import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

class SignupForm extends React.Component {
  state = {
    name: '',
    email: ''
  };

  componentWillMount() {
    if (this.props.email) {
      this.setState({
        email: this.props.email
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.statusText && nextProps.statusText) {
      const element = document.querySelector(
        '.auth-modal-submit.signup-modal-actions button'
      );

      if (element) {
        element.disabled = false;
      }
    }
  }

  handleChange = (event) => {
    const name = event.target.name;
    this.setState({ [name]: event.target.value });
  };

  handleCheckChange = (event) => {
    const name = event.target.name;
    this.setState({ [name]: !this.state[name] });
  };

  formSubmit = (event) => {
    const { handleSubmit } = this.props;
    event.preventDefault();
    const element = document.querySelector(
      '.auth-modal-submit.signup-modal-actions button'
    );

    if (element) {
      element.disabled = true;
    }

    // *****
    if (this.state.name && this.state.email) {
      const { name, email } = this.state;
      event.preventDefault();
      // creating accounts in Mosaic
      handleSubmit(this.state.email);
      // reporting new account to HubSpot
      if (name !== '' && email !== '') {
        fetch('http://hubspot.mosaicapp.com/signup', {
          method: 'POST',
          type: 'cors',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email, name })
        }).catch((error) => {
          console.error(error);
        });
      }
    }
  };

  trackConversion = () => {
    var capterra_vkey = 'faa0c01512e42ee23fda781d85a3c534';

    var capterra_vid = '2116331';

    var capterra_prefix =
      document.location.protocol == 'https:'
        ? 'https://ct.capterra.com'
        : 'http://ct.capterra.com';

    var ct = document.createElement('script');
    ct.type = 'text/javascript';
    ct.async = true;
    ct.src =
      capterra_prefix +
      '/capterra_tracker.js?vid=' +
      capterra_vid +
      '&vkey=' +
      capterra_vkey;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(ct, s);
  };

  render() {
    const { statusText, goToLogin } = this.props;
    if (statusText === 'Activation email sent.') this.trackConversion();

    return (
      <div>
        <div>
          {statusText !== 'Activation email sent.' ? (
            <div className="input-signup" id="signup-form">
              <h3>Create Account</h3>
              <form onSubmit={this.formSubmit}>
                <p className="action-link">
                  Already have access?&nbsp;
                  <a className="signup-link" onClick={goToLogin}>
                    Log in
                  </a>
                </p>
                <div className="auth-modal-error">
                  <span>
                    {statusText
                      ? 'This email is already registered. Did you mean to login?'
                      : ''}
                  </span>
                </div>
                <div className={cn('form-group', { 'has-danger': statusText })}>
                  <input
                    type="text"
                    value={this.state.name}
                    name="name"
                    onChange={this.handleChange}
                    placeholder="Name"
                    required
                    className={cn('user-input', {
                      'form-control-danger': statusText
                    })}
                  />
                </div>
                <div className={cn('form-group', { 'has-danger': statusText })}>
                  <input
                    type="email"
                    value={this.state.email}
                    name="email"
                    onChange={this.handleChange}
                    placeholder="Email"
                    required
                    className={cn('user-input', {
                      'form-control-danger': statusText
                    })}
                  />
                </div>
                <div className="auth-modal-submit signup-modal-actions">
                  <button type="submit">Sign Up</button>
                </div>
              </form>
            </div>
          ) : (
            <div className="input-signup-confirm">
              <h2>Thank you for joining Mosaic!</h2>
              <h6>We just sent you a link to activate your account.</h6>
              <p className="password-reset-reminder">
                Make sure to check your junk folder if you don&#39;t find the
                email.
              </p>
              <div className="auth-modal-resend">
                <button type="submit" onClick={this.formSubmit}>
                  Resend
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SignupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  statusText: PropTypes.string
};

export default SignupForm;
