import { useEffect, useRef, useState, useMemo } from 'react';
import { useAppDispatch } from 'reduxInfra/hooks';
import { StyledColumnLabel } from 'SuggestionModule/components/FindPeople/Table/styles';
import { updatePhaseMembership } from 'actionCreators';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import moment from 'moment';
import { getPhaseDatesData } from 'SuggestionModule/components/FindPeople/utils';
import { PhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { DATE_DISPLAY_TINY, MOMENT_ISO_DATE } from 'appConstants/date';

const PhaseMembershipDateCell = ({ row }: PhaseGroupRowProps) => {
  const dispatch = useAppDispatch();
  const calendarTarget = useRef(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const { customRowProps, phase, phaseMembership } = row.original;

  const {
    endDateToUse,
    startDateToUse,
    phaseHasDates,
    phaseMembershipHasDates
  } = useMemo(
    () =>
      getPhaseDatesData({
        phase,
        phaseMembership
      }),
    [phase, phaseMembership]
  );

  const formattedStartDate = moment(startDateToUse).format(DATE_DISPLAY_TINY);

  const formattedEndDate = moment(endDateToUse).format(DATE_DISPLAY_TINY);

  const handleUpdatePhaseMembershipDateRange = (
    startDate: Nullable<string>,
    endDate: Nullable<string>
  ) => {
    if (!phaseMembership?.id) {
      return;
    }
    dispatch(
      updatePhaseMembership({
        phaseMembershipId: phaseMembership.id,
        start_date: startDate,
        end_date: endDate
      })
    );
  };

  const handleRangeSubmit = ({ startDate, endDate }) => {
    if (!startDate?.isValid?.() && !endDate?.isValid?.()) {
      return;
    }
    setCalendarOpen(false);
    handleUpdatePhaseMembershipDateRange(
      moment(startDate).format(MOMENT_ISO_DATE),
      moment(endDate).format(MOMENT_ISO_DATE)
    );
  };

  const handleClearRange = () => {
    handleUpdatePhaseMembershipDateRange(null, null);
  };

  return (
    <StyledColumnLabel className="date">
      <div>DATES REQUIRED</div>
      <div
        ref={calendarTarget}
        onClick={() => setCalendarOpen(true)}
        className="date-range"
      >
        {/*  */}
        {(phaseHasDates || phaseMembershipHasDates) &&
        formattedStartDate &&
        formattedEndDate
          ? `${formattedStartDate} \xa0–\xa0 ${formattedEndDate}`
          : `Start \xa0–\xa0 End`}
      </div>
      {calendarOpen && (
        <DateRangeCalendar
          startOpen
          target={calendarTarget as any} // Error: target apparently has to be null | undefined..
          onClose={() => setCalendarOpen(false)}
          onSubmit={handleRangeSubmit as any} // Error: onSubmit takes empty argument...
          itemStartDate={startDateToUse}
          itemEndDate={endDateToUse}
          showClear={!!(startDateToUse && endDateToUse)}
          onClear={handleClearRange}
          customInput={() => null}
          /* -------------------------------Added for TS------------------------------------------- */
          onSetRange={undefined}
          showSetRange={undefined}
          showInputs={undefined}
          labels={undefined}
          customLabel={undefined}
          workplanAllDay={undefined}
          workplanUseWeeklyPlanning={undefined}
          workplanIncludeWeekends={undefined}
          workplanTentative={undefined}
          workplanStartTime={undefined}
          workplanEndTime={undefined}
          workplanDailyHours={undefined}
          isWorkplanCalendar={undefined}
          isPhaseCalendar={undefined}
          disableDependency={undefined}
          phaseStartDate={undefined}
          phaseEndDate={undefined}
          phaseId={undefined}
          isOOO={undefined}
          accountId={undefined}
          phases={undefined}
          isTaskCalendar={undefined}
          item={undefined}
          itemType={undefined}
          isScopeCalendar={undefined}
          isMilestoneCalendar={undefined}
          calendarClassNames={undefined}
        />
      )}
    </StyledColumnLabel>
  );
};

export default PhaseMembershipDateCell;
