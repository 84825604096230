import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';
import GearIcon from 'icons/GearIcon';
import {
  Toggle,
  ToggleContainer,
  ToggleText,
  StyledSettingsIcon,
  StyledEmailIcon,
  DropdownWrapper,
  MenuListItem,
  MenuTitle,
  ViewMenuToggleText,
  ActivitySideMenuButton,
  ActivityIconContainer,
  VisibilitySettingsIconContainer,
  ZoomLevelContainer,
  SaveIconContainer
} from './styles';
import NewSaveIcon from 'icons/NewSaveIcon';
import NewSaveIconSolid from 'icons/NewSaveIconSolid';
import {
  ZOOM_LEVELS,
  VIEW_TYPE,
  CONDENSED_ZOOM_LEVELS
} from 'appConstants/workload';
import WorkloadVisibility from 'components/Permissions/Access/WorkloadVisibilityIndicator';
import { noop } from 'appUtils';
import { updateAccountFilterLocal, openActivitySideMenu } from 'actionCreators';
import Popover from 'components/Popover';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import ActivityIcon from 'icons/ActivityTimesheetIcon';
import { usePopover } from 'components/Popover/usePopover';
import WorkloadVisibilitySettings from './WorkloadVisibilitySettings';
import PlannerVisibilitySettings from './PlannerVisibilitySettings';

import { ReactComponent as VisibilitySettingsIcon } from 'icons/viewsettings.svg';

const TodayButton = ({ handleClick, visible }) => (
  <div
    className={cn('today-button', {
      'out-of-view': !visible
    })}
    onClick={handleClick}
  >
    Today
  </div>
);

const UnMemoizedZoomOptions = ({
  zoom,
  handleSetZoom,
  workloadViewType,
  condensedZoomLevel,
  handleSetCondensedZoomLevel,
  showCondensedOption,
  handleOpenActivitySideMenu,
  activeFilterChanged,
  onSaveFilterClick,
  showDayView,
  isOnScheduleView
}) => {
  const { Popover: ViewSettingsPopover, openPopover: openViewSettingsPopover } =
    usePopover();
  const isDay = zoom === ZOOM_LEVELS.DAY;
  const isWeek = zoom === ZOOM_LEVELS.WEEK;
  const isMonth = zoom === ZOOM_LEVELS.MONTH;
  const isQuarter = zoom === ZOOM_LEVELS.QUARTER;
  const isYear = zoom === ZOOM_LEVELS.YEAR;
  const { isActivitySideBarEnabled, workloadVisibilitySettingsFlag } =
    useFeatureFlags();

  useEffect(() => {
    if (!showDayView && isDay) {
      handleSetZoom(ZOOM_LEVELS.WEEK);
    }
  }, [showDayView, isDay, handleSetZoom]);

  const popoverRef = useRef(null);
  const [isViewTypeMenuOpen, setIsViewTypeMenuOpen] = useState(false);

  const openViewTypeMenu = () => {
    setIsViewTypeMenuOpen(true);
  };

  const closeViewTypeMenu = () => {
    setIsViewTypeMenuOpen(false);
  };

  const getCurrentZoomLabel = () => {
    if (workloadViewType === VIEW_TYPE.NORMAL) {
      return '100%';
    } else if (condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM) {
      return '75%';
    } else if (condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL) {
      return '50%';
    } else {
      return '25%';
    }
  };

  return (
    <div className="schedule-actions">
      <ToggleContainer className="app-cues-toggle-container">
        {isActivitySideBarEnabled && (
          <ActivitySideMenuButton onClick={handleOpenActivitySideMenu}>
            <ActivityIconContainer>
              <ActivityIcon
                height={10}
                width={10}
                color={theme.colors.colorCalendarBlue}
              />
            </ActivityIconContainer>
            Activity
          </ActivitySideMenuButton>
        )}

        <SaveIconContainer
          onClick={onSaveFilterClick}
          className="app-cues-settings-icon"
          data-for="app-tooltip"
          data-effect="solid"
          data-class="blue-dark-tooltip"
          data-tip="Save Current View Settings"
        >
          <div className="hide-on-hover">
            <NewSaveIcon
              color={
                activeFilterChanged
                  ? theme.colors.colorBudgetBlue
                  : theme.colors.colorMediumGray5
              }
              width="13"
              height="12"
              strokeWidth="0.6"
            />
          </div>
          <div className="show-on-hover">
            <NewSaveIconSolid width="13" height="12" strokeWidth="0.6" />
          </div>
        </SaveIconContainer>
        {workloadVisibilitySettingsFlag && (
          <VisibilitySettingsIconContainer
            data-for="app-tooltip"
            data-effect="solid"
            data-class="blue-dark-tooltip"
            data-tip="View Settings"
            onClick={openViewSettingsPopover}
          >
            <VisibilitySettingsIcon />
          </VisibilitySettingsIconContainer>
        )}
        {showCondensedOption && (
          <Toggle
            data-for="app-tooltip"
            data-effect="solid"
            data-class="blue-dark-tooltip"
            data-tip="Row Height"
            borderRight={isQuarter || isYear}
            onClick={openViewTypeMenu}
          >
            <ViewMenuToggleText ref={popoverRef}>
              {getCurrentZoomLabel()}
            </ViewMenuToggleText>
          </Toggle>
        )}
        <ZoomLevelContainer>
          {showDayView && (
            <Toggle onClick={() => handleSetZoom(ZOOM_LEVELS.DAY)}>
              <ToggleText selected={isDay}>Day</ToggleText>
            </Toggle>
          )}
          <Toggle
            onClick={() => handleSetZoom(ZOOM_LEVELS.WEEK)}
            borderRight={isQuarter || isYear}
          >
            <ToggleText selected={isWeek}>Wk</ToggleText>
          </Toggle>
          <Toggle
            onClick={() => handleSetZoom(ZOOM_LEVELS.MONTH)}
            borderRight={isWeek || isYear}
          >
            <ToggleText selected={isMonth}>Mo</ToggleText>
          </Toggle>
          <Toggle
            onClick={() => handleSetZoom(ZOOM_LEVELS.QUARTER)}
            borderRight={isMonth || isWeek}
          >
            <ToggleText selected={isQuarter}>Qtr</ToggleText>
          </Toggle>
          <Toggle
            onClick={() => handleSetZoom(ZOOM_LEVELS.YEAR)}
            borderRight={false}
          >
            <ToggleText selected={isYear}>Yr</ToggleText>
          </Toggle>
        </ZoomLevelContainer>
        <Popover
          className="view-dropdown-menu"
          isOpen={isViewTypeMenuOpen}
          target={popoverRef}
          closePopover={closeViewTypeMenu}
        >
          <DropdownWrapper>
            <MenuTitle>Row Height</MenuTitle>
            <MenuListItem
              onClick={() => {
                handleSetCondensedZoomLevel(null);
                closeViewTypeMenu();
              }}
            >
              <div
                style={{
                  marginLeft: '4px',
                  marginTop: '1px',
                  fontWeight:
                    workloadViewType === VIEW_TYPE.NORMAL ? '600' : '400'
                }}
              >
                100%
              </div>
            </MenuListItem>
            <MenuListItem
              onClick={() => {
                handleSetCondensedZoomLevel(CONDENSED_ZOOM_LEVELS.MEDIUM);
                closeViewTypeMenu();
              }}
            >
              <div
                style={{
                  marginLeft: '12px',
                  fontWeight:
                    workloadViewType === VIEW_TYPE.CONDENSED &&
                    condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
                      ? '600'
                      : '400'
                }}
              >
                75%
              </div>
            </MenuListItem>
            <MenuListItem
              onClick={() => {
                handleSetCondensedZoomLevel(CONDENSED_ZOOM_LEVELS.SMALL);
                closeViewTypeMenu();
              }}
            >
              <div
                style={{
                  marginLeft: '12px',
                  fontWeight:
                    workloadViewType === VIEW_TYPE.CONDENSED &&
                    condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
                      ? '600'
                      : '400'
                }}
              >
                50%
              </div>
            </MenuListItem>
            <MenuListItem
              onClick={() => {
                handleSetCondensedZoomLevel(CONDENSED_ZOOM_LEVELS.VERY_SMALL);
                closeViewTypeMenu();
              }}
            >
              <div
                style={{
                  marginLeft: '12px',
                  fontWeight:
                    workloadViewType === VIEW_TYPE.CONDENSED &&
                    condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
                      ? '600'
                      : '400'
                }}
              >
                25%
              </div>
            </MenuListItem>
          </DropdownWrapper>
        </Popover>
        <ViewSettingsPopover className="visibility-settings-menu">
          {isOnScheduleView ? (
            <PlannerVisibilitySettings />
          ) : (
            <WorkloadVisibilitySettings />
          )}
        </ViewSettingsPopover>
      </ToggleContainer>
    </div>
  );
};
const ZoomOptions = React.memo(UnMemoizedZoomOptions);

const SettingsIcon = ({ handleClick }) => (
  <StyledSettingsIcon onClick={handleClick} className="app-cues-settings-icon">
    <GearIcon />
  </StyledSettingsIcon>
);

const EmailIcon = ({ handleClick }) => (
  <StyledSettingsIcon onClick={handleClick} className="app-cues-email-icon">
    <StyledEmailIcon />
  </StyledSettingsIcon>
);

const StyledDateNav = styled.div`
  display: flex;
`;

const oneDateRenderer = (date) => (
  <>
    <b>{date.format('MMM')} </b>
    {date.format('YYYY')}
  </>
);

export const defaultDateRenderer = ({ date }) => {
  if (!Array.isArray(date)) {
    return oneDateRenderer(date);
  }
  if (date.length === 1) {
    return oneDateRenderer(date[0]);
  }
  const [start, end] = date;
  // returns false for same month in different years https://momentjscom.readthedocs.io/en/latest/moment/05-query/02-is-same/
  const isSameMonth = start.isSame(end, 'month');
  const isSameYear = start.isSame(end, 'year');

  if (isSameMonth) {
    return oneDateRenderer(start);
  }
  return (
    <>
      <b>{start.format('MMM')} </b>
      {!isSameYear && start.format('YYYY')} - <b>{end.format('MMM')} </b>
      {end.format('YYYY')}
    </>
  );
};
const DateNav = ({
  scrollBack,
  scrollForward,
  scrollToToday,
  header,
  isTodayOnScreen,
  showZoom,
  zoom,
  setZoom,
  shouldUsePassedSetZoom,
  showSettings,
  onSettingsClick = noop,
  showEmail,
  onEmailClick = noop,
  SHOW_DEMO_VIEWERS_COMPONENT = false,
  onAccessClick,
  accessIdentifier,
  showButton,
  children,
  dateRenderer = defaultDateRenderer,
  isOnScheduleView,
  hideTodayButton = false,
  filter,
  workloadViewType,
  condensedZoomLevel,
  showCondensedOption = true,
  activeFilterChanged,
  onSaveFilterClick,
  workloadSettings,
  showDayView
}) => {
  const dispatch = useDispatch();

  const handleSetCondensedZoomLevel = (newCondensedZoomLevel) => {
    dispatch(
      updateAccountFilterLocal({
        ...filter,
        custom: {
          ...filter?.custom,
          condensedZoomLevel: newCondensedZoomLevel,
          workloadViewType: newCondensedZoomLevel
            ? VIEW_TYPE.CONDENSED
            : VIEW_TYPE.NORMAL
        }
      })
    );
  };

  const handleSetZoom = (newZoom) => {
    dispatch(
      updateAccountFilterLocal({
        ...filter,
        custom: {
          ...filter?.custom,
          zoom: newZoom
        }
      })
    );
  };

  const handleOpenActivitySideMenu = () => {
    dispatch(openActivitySideMenu());
  };

  return (
    <div className="schedule-header">
      {children}
      <StyledDateNav className="date-nav">
        <div className="left-arrow app-cues-left-arrow" onClick={scrollBack} />
        <div className="header-today-row">
          <div className="header-text">{dateRenderer({ date: header })}</div>
          {(showSettings || showEmail || !hideTodayButton) && (
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}
            >
              {!hideTodayButton && (
                <TodayButton
                  className="app-cues-today-button"
                  handleClick={scrollToToday}
                  visible={isTodayOnScreen}
                />
              )}
              {showSettings && <SettingsIcon handleClick={onSettingsClick} />}
              {showEmail && <EmailIcon handleClick={onEmailClick} />}
            </div>
          )}
        </div>
        <div
          className="right-arrow app-cues-right-arrow"
          onClick={scrollForward}
        />
      </StyledDateNav>
      {SHOW_DEMO_VIEWERS_COMPONENT && (
        <WorkloadVisibility
          accessIdentifier={accessIdentifier}
          handleClick={onAccessClick}
        />
      )}
      {showZoom && (
        <ZoomOptions
          zoom={zoom}
          handleSetZoom={shouldUsePassedSetZoom ? setZoom : handleSetZoom}
          handleSetCondensedZoomLevel={handleSetCondensedZoomLevel}
          workloadViewType={workloadViewType}
          condensedZoomLevel={condensedZoomLevel}
          showCondensedOption={showCondensedOption}
          handleOpenActivitySideMenu={handleOpenActivitySideMenu}
          activeFilterChanged={activeFilterChanged}
          onSaveFilterClick={onSaveFilterClick}
          workloadSettings={workloadSettings}
          showDayView={showDayView}
          isOnScheduleView={isOnScheduleView}
        />
      )}
    </div>
  );
};

export default React.memo(DateNav);
