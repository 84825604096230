import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import {
  LoginContainer,
  ForgotPasswordContainer,
  OnboardContainer,
  SignupContainer,
  SignupSuccess,
  OnboardModalContainer
} from 'views';

import BoardContainerRouter from './BoardContainerRouter';
import TeamMemberProfileRouter from './TeamMemberProfileRouter';
import SettingsRouter from './SettingsRouter';
import ReportsRouter from './ReportsRouter';
import DashboardRouter from './DashboardRouter';
import MembersRouter from './MembersRouter';
import WorkloadRouter from './WorkloadRouter';
import ScheduleRouter from './ScheduleRouter';
import ActivityFeedRouter from './ActivityFeedRouter';
import HomeRouter from './HomeRouter';
import SetupRouter from './SetupRouter';
import ResetRouter from './ResetRouter';
import withRouterParams from './WithRouterParams';
import LoadApp from '../LoadApp';
import ProjectDetailsRouter from './ProjectDetailsRouter';
import TeamSettingsContainerRouter from './TeamSettingsContainerRouter';
import { requireAuthentication } from 'appUtils/authentication';
import PATHS from 'appConstants/paths';

// requireAuthentication only redirects if it blocks a routing attempt further down the component tree. If it is applied on a route that is a leaf, there is no redirect effect and users see blank pages.

const AuthedBoardContainerRouter = requireAuthentication(BoardContainerRouter);
const AuthedTeamMemberProfileRouter = requireAuthentication(
  TeamMemberProfileRouter
);
const AuthedSettingsRouter = requireAuthentication(SettingsRouter);
const AuthedReportsRouter = requireAuthentication(ReportsRouter);
const AuthedDashboardRouter = requireAuthentication(DashboardRouter);
const AuthedMembersRouter = requireAuthentication(MembersRouter);
const AuthedWorkloadRouter = requireAuthentication(WorkloadRouter);
const AuthedScheduleRouter = requireAuthentication(ScheduleRouter);
const AuthedActivityFeedRouter = requireAuthentication(ActivityFeedRouter);
const AuthedHomeRouter = requireAuthentication(HomeRouter);
const AuthedProjectDetailsRouter = requireAuthentication(ProjectDetailsRouter);
const AuthedTeamSettingsContainerRouter = requireAuthentication(
  TeamSettingsContainerRouter
);
const AuthedOnboardModalContainer = requireAuthentication(
  OnboardModalContainer
);

class LoadAppRouter extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const { auth, match } = this.props;
    const { auth: nextAuth, match: nextMatch } = nextProps;
    const shouldUpdate = auth !== nextAuth || match.url !== nextMatch.url;
    return shouldUpdate;
  }

  render() {
    return (
      <LoadApp>
        <Switch>
          <Route path="/login" component={LoginContainer} />
          <Route path="/invitation" component={SignupContainer} />
          <Route path="/success" component={SignupSuccess} />
          <Route path="/onboarding" component={AuthedOnboardModalContainer} />
          <Route
            path={`${PATHS.TEAM_SLUG}${PATHS.REPORTS}`}
            component={AuthedReportsRouter}
          />
          <Route
            path={[
              `${PATHS.TEAM_SLUG}${PATHS.DASHBOARD}/:dashboardId/report/:reportId`,
              `${PATHS.TEAM_SLUG}${PATHS.DASHBOARD}/:dashboardId?`
            ]}
            component={AuthedDashboardRouter}
          />
          <Route path="/settings" component={AuthedSettingsRouter} />
          <Route path="/forgot" component={ForgotPasswordContainer} />
          <Route
            path={[
              '/setup/invite/:inviteToken',
              '/setup/invite',
              // TODO: In the near future, remove matches to below path, it is only being included for outstanding invites that received an incorrect invite email link
              '/invite/:inviteToken'
            ]}
            component={SetupRouter}
          />
          <Route path="/reset/:inviteToken" component={ResetRouter} />
          {/* support old /team-settings route */}
          <Redirect exact from={`/team-settings`} to={`/team/settings/info`} />
          <Redirect
            exact
            from={`/team-settings/members`}
            to={`/team/settings/members/members`}
          />
          <Redirect
            exact
            from={`/team-settings/:viewType`}
            to={`/team/settings/:viewType`}
          />
          <Route path="/:teamSlug/members" component={AuthedMembersRouter} />
          <Route
            path={[
              '/:teamSlug/workload/members/split/:splitScreenType?/:splitScreenAccountId?',
              '/:teamSlug/workload/members'
            ]}
            component={AuthedWorkloadRouter}
          />
          <Route
            path={[
              '/:teamSlug/planner/:plannerViewType/split/:splitScreenProjectId?',
              '/:teamSlug/planner/:plannerViewType'
            ]}
            component={AuthedScheduleRouter}
          />
          <Route
            path="/:userSlug/activity-feed"
            component={AuthedActivityFeedRouter}
          />
          <Route
            path="/:teamSlug/profile/:userSlug/:teamMemberId"
            component={AuthedTeamMemberProfileRouter}
          />
          <Route
            path="/:teamSlug/boards/:boardSlug/:boardId"
            component={AuthedBoardContainerRouter}
          />
          <Route
            path="/:teamSlug/project/:projectSlug/:projectId"
            component={AuthedProjectDetailsRouter}
          />
          <Route
            path="/:teamSlug/settings"
            component={AuthedTeamSettingsContainerRouter}
          />
          <Route
            path={'/:userSlug/home/:homeViewType'}
            component={AuthedHomeRouter}
          />
          <Route path="*" component={LoginContainer} />
        </Switch>
      </LoadApp>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withRouterParams(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LoadAppRouter))
);
