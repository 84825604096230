import { DependencyInfoBEArrayItem } from 'models/dependency';
import { PhaseMembership } from './phaseMembership';
import { SetRequired } from 'type-fest';

/**
 * An ID for a phase.
 */
export type PhaseId = number;

/**
 * An ID for an activity phase, an association between an activity and a phase.
 */
export type ActivityPhaseId = number;

export interface Phase {
  id: PhaseId;
  phase_number?: number;
  billable: boolean;
  budget_totals?: unknown; // Fix me
  is_default_phase: boolean;
  is_main: boolean;
  is_budget: boolean;
  project_id: number;
  archived: boolean;
  total: string;
  name: string;
  start_date?: string;
  end_date?: string;
  is_complete: boolean;
  include_weekends: boolean;
  include_holidays: boolean;
  total_work_days?: unknown; // Fix me
  bars: Array<unknown>; // Fix me
  activity_order: Array<unknown>; // Fix me
  fee_type?: string;
  is_inactive?: boolean;
  deleted: boolean;
  profit_percentage: string;
  estimated_cost: string;
  budget_status: string;
  dependencies: Array<DependencyInfoBEArrayItem>;
  baseline: Record<string, any>; // Fix me
  phase_memberships: Array<PhaseMembership>;
  member_budget_orders: Array<number>;
  activity_phases: Array<ActivityPhase>;
  custom_fields: PhaseCustomFields;
  is_like_default: boolean;
  estimated_hours: string;
  budget_phase_by: string;
  budget_fixed_fee_with: string;
  budget_hourly_with: string;
  budget_internal_with: string;
}

export interface ActivityPhase {
  id: ActivityPhaseId;
  activity_id: number;
  project_id: number;
  fee_type?: string;
  total: string;
  estimated_cost: string;
  start_date?: string;
  end_date?: string;
  bars: Array<unknown>; // Fix me
  total_work_days?: unknown; // Fix me
  billable: boolean;
  custom_fields: PhaseCustomFields;
  budget_activity_phase_by_id: number;
  phase_id: number;
  is_default: boolean;
  project_scope_order: Array<unknown>; // Fix me
  estimated_hours: string;
  budget_activity_phase_by: string;
  budget_fixed_fee_with: string;
  budget_hourly_with: string;
  budget_internal_with: string;
}

export const isActivityPhase = (
  phase: Phase | ActivityPhase
): phase is ActivityPhase => 'activity_id' in phase;

// If ActivityPhase has different Custom Fields than Phase,
// then make another seperate type
interface PhaseCustomFields {
  [x: string]: unknown; // Add to me
}

export type PartialPhase = SetRequired<Partial<Phase>, 'id'> & {
  isPartial: boolean;
};
