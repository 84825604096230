import 'isomorphic-fetch';
import qs from 'qs';
import { callIntegrationApi, callApi, callQBAgentApi } from 'appUtils/api';

export const initializeIntegration = (
  teamId,
  token,
  integrationId,
  integrationLabel
) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `fetch-data/${integrationId}/${teamId}/${integrationLabel}`,
    token,
    fetchInit
  );
};

/* -------------------------------- Accounts -------------------------------- */

export const fetchIntegrationAccounts = (
  teamId,
  token,
  integrationId,
  body = {}
) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/employee`,
    token,
    fetchInit
  );
};

export const mapAccounts = (token, teamId, integrationId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/employee`,
    token,
    fetchInit
  );
};

export const fetchMappedMosaicAccountIds = (teamId, token, integrationId) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `mappings/utils/mapped-entities/${teamId}/${integrationId}/employee`,
    token,
    fetchInit
  );
};

/* ------------------------------- Activities ------------------------------- */

export const fetchIntegrationActivities = (
  teamId,
  token,
  integrationId,
  body = {}
) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/activity`,
    token,
    fetchInit
  );
};

export const mapActivities = (token, teamId, integrationId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/activity`,
    token,
    fetchInit
  );
};

export const fetchMappedMosaicActivityIds = (teamId, token, integrationId) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `mappings/utils/mapped-entities/${teamId}/${integrationId}/activity`,
    token,
    fetchInit
  );
};

/* --------------------------------- Clients -------------------------------- */

export const fetchIntegrationClients = (
  teamId,
  token,
  integrationId,
  body = {}
) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/client`,
    token,
    fetchInit
  );
};

export const mapClients = (token, teamId, integrationId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/client`,
    token,
    fetchInit
  );
};

export const fetchMappedMosaicClientIds = (teamId, token, integrationId) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `mappings/utils/mapped-entities/${teamId}/${integrationId}/client`,
    token,
    fetchInit
  );
};

/* -------------------------------- Projects -------------------------------- */

export const fetchIntegrationProjects = (
  teamId,
  token,
  integrationId,
  body = { limit: 200, offset: 0, sortAttributes: [] }
) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/project`,
    token,
    fetchInit
  );
};

export const mapProjects = (token, teamId, integrationId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/project`,
    token,
    fetchInit
  );
};

export const fetchMappedMosaicProjectIds = (teamId, token, integrationId) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `mappings/utils/mapped-entities/${teamId}/${integrationId}/project`,
    token,
    fetchInit
  );
};

export const mapAllPhases = (token, teamId, targetServiceId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/batch/map-all-phases/${teamId}/${targetServiceId}/project`,
    token,
    fetchInit
  );
};

/* --------------------------------- Phases --------------------------------- */

export const mapPhases = (token, teamId, integrationId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/phase`,
    token,
    fetchInit
  );
};

export const fetchMappedMosaicPhaseIds = (teamId, token, integrationId) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `mappings/utils/mapped-entities/${teamId}/${integrationId}/phase`,
    token,
    fetchInit
  );
};

export const fetchIntegrationPhases = (
  teamId,
  token,
  integrationId,
  body = {}
) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/phase`,
    token,
    fetchInit
  );
};

export const mapAllActivities = (token, teamId, targetServiceId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/batch/map-all-phases/${teamId}/${targetServiceId}/phase`,
    token,
    fetchInit
  );
};

// Activity phase
export const mapActivityPhases = (token, teamId, integrationId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/activity_phase`,
    token,
    fetchInit
  );
};

/* ------------------------------ Time entries ------------------------------ */

export const fetchIntegrationTimeEntries = (
  teamId,
  token,
  integrationId,
  body = {}
) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `mappings/${teamId}/${integrationId}/time_entry`,
    token,
    fetchInit
  );
};

/* --------------------------------- global --------------------------------- */

export const fetchAllIntegrationDomains = (_, token) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(`configs/agent-domains`, token, fetchInit);
};
export const fetchIntegrationsV2 = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = qs.stringify({ mosaicTeamId: teamId });
  return callIntegrationApi(
    `configs/integrations?${queryParams}`,
    token,
    fetchInit
  );
};

export const linkQuickbooks = (config) => {
  const { teamId, token } = config;
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const apiEndpoint = `qb-sync-settings/${teamId}/`;
  return callQBAgentApi(apiEndpoint, token, fetchInit);
};

export const revokeQuickbooks = (teamId, token) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callQBAgentApi(`revoke/${teamId}/`, token, fetchInit);
};

export const fetchIntegrations = {
  quickbooks: linkQuickbooks
};

export const deleteIntegration = {
  quickbooks: revokeQuickbooks
};

export const disconnectIntegrationV2 = (teamId, token, targetServiceId) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `provision/delete/${targetServiceId}`,
    token,
    fetchInit
  );
};

export const fetchIntegrationAuthStatus = (
  teamId,
  token,
  targetServiceId,
  targetService
) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `forward/${targetService}/auth-check/${teamId}/${targetServiceId}`,
    token,
    fetchInit
  );
};

export const provisionIntegration = (token, body) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(`provision`, token, fetchInit);
};

export const updateIntegrationConfig = (token, targetServiceId, body) => {
  const fetchInit = {
    method: 'put',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(
    `provision/integrationsSettingSchema/${targetServiceId}`,
    token,
    fetchInit
  );
};

export const fetchIntegrationEntityStats = (token, targetServiceId) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `mappings/stats/${targetServiceId}`,
    token,
    fetchInit
  );
};

export const fetchIntegrationSettingsSchema = (token, targetServiceId) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `provision/integrationsSettingSchema/${targetServiceId}`,
    token,
    fetchInit
  );
};

export const fetchAutoLinkPhases = (token, targetServiceId) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `mappings/utils/phase-name/${targetServiceId}`,
    token,
    fetchInit
  );
};

export const fetchAutoLinkChildrenByPhaseName = (
  token,
  targetServiceId,
  parentPhaseName
) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callIntegrationApi(
    `mappings/utils/phase-name/${targetServiceId}/${encodeURIComponent(
      parentPhaseName
    )}`,
    token,
    fetchInit
  );
};

export const fetchSyncLog = (token, integrationId, body) => {
  const fetchInit = {
    method: 'post',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callIntegrationApi(`sync-log/list/${integrationId}`, token, fetchInit);
};

export const fetchWorkspaces = (_, token, params) => {
  const fetchInit = {
    method: 'get',
    // credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    apiKey: params.apiKey
  };

  const url = `${params.endpointPath}?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;

  return callIntegrationApi(url, token, fetchInit);
};
