import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateProjectBudgetViewSettings,
  fetchProjectBudgetViewSettings
} from 'BudgetModule/actionCreators';
import { getProjectBudgetSettingsHash } from 'BudgetModule/selectors';

const useProjectBudgetSettings = (projectId) => {
  const dispatch = useDispatch();

  const projectBudgetSettingsHash = useSelector(getProjectBudgetSettingsHash);

  const projectBudgetSettings = useMemo(() => {
    // Masking the settings to only let $ or hours rendered
    const budgetSettings = projectBudgetSettingsHash[projectId] || {};
    let cost =
      budgetSettings.estimated_cost ||
      budgetSettings.spent_cost ||
      budgetSettings.planned_cost;
    const hours =
      (budgetSettings.estimated_hours ||
        budgetSettings.spent_hours ||
        budgetSettings.planned_hours) &&
      !cost;
    if (!hours && !cost) {
      cost = true; // default is cost
    }
    const percent =
      (budgetSettings.estimated_percent ||
        budgetSettings.spent_percent ||
        budgetSettings.planned_percent) &&
      false;
    return {
      ...budgetSettings,
      rate: false,
      estimated_hours: hours,
      planned_hours: hours,
      remaining_hours: hours,
      spent_hours: hours,
      estimated_cost: cost,
      planned_cost: cost,
      remaining_cost: cost,
      spent_cost: cost,
      remaining_percent: percent,
      estimated_percent: true,
      spent_percent: percent,
      planned_percent: percent,
      decimals: false
    };
  }, [projectBudgetSettingsHash, projectId]);

  const updateProjectBudgetSettings = useCallback(
    (updatedSettings) => {
      if (projectId !== undefined) {
        dispatch(
          updateProjectBudgetViewSettings({
            projectId,
            budgetSettings: updatedSettings,
            original: projectBudgetSettings
          })
        );
      }
    },
    [dispatch, projectBudgetSettings, projectId]
  );

  const toggleProjectHoursDollarsSetting = useCallback(
    (isDollars) => {
      const updatedSettings = {
        rate: false,
        estimated_hours: !isDollars,
        planned_hours: !isDollars,
        remaining_hours: !isDollars,
        estimated_cost: isDollars,
        planned_cost: isDollars,
        remaining_cost: isDollars,
        remaining_percent: true,
        estimated_percent: true,
        spent_percent: false,
        planned_percent: false,
        spent_hours: !isDollars,
        spent_cost: isDollars,
        decimals: false
      };
      updateProjectBudgetSettings(updatedSettings);
    },
    [updateProjectBudgetSettings]
  );

  useEffect(() => {
    if (!projectBudgetSettings && projectId !== undefined) {
      dispatch(fetchProjectBudgetViewSettings({ projectId }));
    }
  }, [dispatch, projectBudgetSettings, projectId]);

  return {
    projectBudgetSettings,
    isScopeEnabled: projectBudgetSettings?.is_scope_enabled,
    updateProjectBudgetSettings,
    toggleProjectHoursDollarsSetting
  };
};

export default useProjectBudgetSettings;
