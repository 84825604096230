import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';

/* ------------------------------ Filter fields ----------------------------- */

/**
 * Standard + common filter fields (including custom filter fields)
 *
 * Try not to add highly specific uncommon fields here
 *
 * fields ending with 'cf' are used for cross filtering. eg. a report could have a phase names view filter as well
 * as a phase name cross filter for the project selector - they must be separate fields
 */
export enum FilterField {
  account_ids = 'account_ids',
  account_ids_cf = 'account_ids_cf',
  position_ids = 'position_ids',
  position_ids_cf = 'position_ids_cf',
  project_ids = 'project_ids',
  project_ids_cf = 'project_ids_cf',
  phase_names = 'phase_names',
  phase_names_cf = 'phase_names_cf',
  phase_ids = 'phase_ids',
  phase_ids_cf = 'phase_ids_cf',
  activity_ids = 'activity_ids',
  activity_ids_cf = 'activity_ids_cf',
  clients = 'clients',
  clients_cf = 'clients_cf',
  client_ids = 'client_ids',
  client_ids_cf = 'client_ids_cf',
  board_ids = 'board_ids',
  board_ids_cf = 'board_ids_cf',
  work_group_ids = 'work_group_ids',
  work_group_ids_cf = 'work_group_ids_cf',
  skill_ids = 'skill_ids',
  skill_ids_cf = 'skill_ids_cf',
  // skill levels = skill + level eg. 123-2 (level 2 for skill id 123)
  skillLevels = 'skillLevels',
  skillLevels_cf = 'skillLevels_cf',
  region_ids = 'region_ids',
  region_ids_cf = 'region_ids_cf',
  office_ids = 'office_ids',
  office_ids_cf = 'office_ids_cf',
  discipline_ids = 'discipline_ids',
  discipline_ids_cf = 'discipline_ids_cf',
  profit_center = 'profit_center',
  profit_center_cf = 'profit_center_cf',
  billable = 'billable',
  billable_cf = 'billable_cf',
  projectBudgetStatus = 'projectBudgetStatus',
  projectBudgetStatus_cf = 'projectBudgetStatus_cf',
  phaseBudgetStatus = 'phaseBudgetStatus',
  phaseBudgetStatus_cf = 'phaseBudgetStatus_cf',
  dateRange = 'dateRange',
  start_date = 'start_date',
  end_date = 'end_date',
  groupBy = 'groupBy',
  sortOrder = 'sortOrder',
  sortValue = 'sortValue',
  priority_ids = 'priority_ids',
  priority_ids_cf = 'priority_ids_cf',
  archivedStatus = 'archivedStatus',
  archivedStatus_cf = 'archivedStatus_cf',
  /**
   * For rendering a main filter eg. side selection filter
   */
  mainFilterListType = 'mainFilterListType',
  /**
   * Use for non-saveable mainFilterListType
   */
  mainFilterListType_local = 'mainFilterListType_local',
  /**
   * For rendering a list of filters
   */
  filterOrder = 'filterOrder',
  /**
   * For rendering StackedFilters
   */
  stackedFilterOrder = 'stackedFilterOrder',
  /**
   * For the group by option in stacked filter member filter
   */
  stackedFilterMembersFilterListType = 'stackedFilterMembersFilterListType',
  /**
   * Contractor (internal/external), Guest, etc
   */
  employmentType = 'employmentType',
  employmentType_cf = 'employmentType_cf',
  isProjectsWithUnassignedRoles = 'isProjectsWithUnassignedRoles',
  isProjectsWithUnassignedRoles_cf = 'isProjectsWithUnassignedRoles_cf'
}

/**
 * Standard filter fields that are defined on the filter object (ie. not in filter.custom)
 */
export const isStandardFilterFieldHash = {
  [FilterField.account_ids]: true,
  [FilterField.position_ids]: true,
  [FilterField.project_ids]: true,
  [FilterField.phase_names]: true,
  [FilterField.phase_ids]: true,
  [FilterField.activity_ids]: true,
  [FilterField.clients]: true,
  [FilterField.client_ids]: true,
  [FilterField.board_ids]: true
};

/* ----------------------------- FilterListType ----------------------------- */

/** Values must match keys */
export enum FilterListType {
  // members
  Members = 'Members',
  MembersByDepartment = 'MembersByDepartment',
  MembersByPortfolio = 'MembersByPortfolio',
  MembersByPosition = 'MembersByPosition',
  MembersByRegion = 'MembersByRegion',
  MembersByDiscipline = 'MembersByDiscipline',
  MembersBySkill = 'MembersBySkill',
  MembersByOffice = 'MembersByOffice',
  // projects
  Projects = 'Projects',
  ProjectsByPortfolio = 'ProjectsByPortfolio',
  ProjectsByMember = 'ProjectsByMember',
  ProjectBudgetStatus = 'ProjectBudgetStatus',
  ShowProjectsWithUnassignedRoles = 'ShowProjectsWithUnassignedRoles',
  // phases
  PhasesByProjectsByPortfolio = 'PhasesByProjectsByPortfolio',
  PhaseIds = 'PhaseIds',
  PhaseNames = 'PhaseNames',
  PhaseBudgetStatus = 'PhaseBudgetStatus',
  // activities
  ActivityIds = 'ActivityIds',
  // misc
  Portfolios = 'Portfolios',
  Departments = 'Departments',
  Skills = 'Skills',
  SkillLevels = 'SkillLevels',
  Positions = 'Positions',
  Regions = 'Regions',
  Offices = 'Offices',
  Disciplines = 'Disciplines',
  ClientNames = 'ClientNames',
  ClientIds = 'ClientIds',
  Billable = 'Billable',
  ProfitCenter = 'ProfitCenter',
  EmploymentType = 'EmploymentType',
  Priorities = 'Priorities',
  ArchivedStatus = 'ArchivedStatus'
}

const entityLabels = {
  member: 'People',
  position: 'Role',
  project: 'Project',
  phase: 'Phase',
  client: 'Client',
  portfolio: 'Portfolio',
  department: 'Department',
  discipline: 'Discipline',
  skill: 'Skill',
  region: 'Region',
  office: 'Office'
};

export const filterListTypeToEntityLabel: Partial<
  Record<FilterListType, string>
> = {
  [FilterListType.Members]: entityLabels.member,
  [FilterListType.MembersByDepartment]: entityLabels.member,
  [FilterListType.MembersByPortfolio]: entityLabels.member,
  [FilterListType.MembersByPosition]: entityLabels.member,
  [FilterListType.MembersByRegion]: entityLabels.member,
  [FilterListType.MembersBySkill]: entityLabels.member,
  [FilterListType.MembersByOffice]: entityLabels.member,
  [FilterListType.MembersByDiscipline]: entityLabels.member,
  [FilterListType.Projects]: entityLabels.project,
  [FilterListType.ProjectsByPortfolio]: entityLabels.project,
  [FilterListType.ProjectsByMember]: entityLabels.project,
  [FilterListType.PhasesByProjectsByPortfolio]: entityLabels.phase,
  [FilterListType.PhaseNames]: entityLabels.phase,
  [FilterListType.PhaseIds]: entityLabels.phase,
  [FilterListType.Portfolios]: entityLabels.portfolio,
  [FilterListType.Departments]: entityLabels.department,
  [FilterListType.Skills]: entityLabels.skill,
  [FilterListType.SkillLevels]: entityLabels.skill,
  [FilterListType.Positions]: entityLabels.position,
  [FilterListType.Regions]: entityLabels.region,
  [FilterListType.Offices]: entityLabels.office,
  [FilterListType.ClientNames]: entityLabels.client,
  [FilterListType.ClientIds]: entityLabels.client
};

export const projectFilterListTypes = [
  FilterListType.ProjectsByMember,
  FilterListType.ProjectsByPortfolio,
  FilterListType.Projects,
  FilterListType.PhasesByProjectsByPortfolio
];

export const projectFilterListTypesHash = keyBy(projectFilterListTypes);

export const memberFilterListTypes = [
  FilterListType.Members,
  FilterListType.MembersByDepartment,
  FilterListType.MembersByDiscipline,
  FilterListType.MembersByOffice,
  FilterListType.MembersByPortfolio,
  FilterListType.MembersByPosition,
  FilterListType.MembersByRegion,
  FilterListType.MembersBySkill
];

export const memberFilterListTypesHash = keyBy(memberFilterListTypes);

/**
 * Fields must be array type (ie. selection filters)
 * A FilterListType may have multiple fields, in which case the value is an array
 */
export const filterListTypeToField = {
  [FilterListType.Members]: FilterField.account_ids,
  [FilterListType.MembersByDepartment]: FilterField.account_ids,
  [FilterListType.MembersByPortfolio]: FilterField.account_ids,
  [FilterListType.MembersByPosition]: FilterField.account_ids,
  [FilterListType.MembersByRegion]: FilterField.account_ids,
  [FilterListType.MembersBySkill]: FilterField.account_ids,
  [FilterListType.MembersByOffice]: FilterField.account_ids,
  [FilterListType.MembersByDiscipline]: FilterField.account_ids,
  [FilterListType.Portfolios]: FilterField.board_ids,
  [FilterListType.Projects]: FilterField.project_ids,
  [FilterListType.ProjectsByPortfolio]: FilterField.project_ids,
  [FilterListType.ProjectsByMember]: FilterField.project_ids,
  [FilterListType.ProjectBudgetStatus]: FilterField.projectBudgetStatus,
  [FilterListType.PhasesByProjectsByPortfolio]: FilterField.phase_ids,
  [FilterListType.PhaseIds]: FilterField.phase_ids,
  [FilterListType.PhaseNames]: FilterField.phase_names,
  [FilterListType.PhaseBudgetStatus]: FilterField.phaseBudgetStatus,
  [FilterListType.ClientNames]: FilterField.clients,
  [FilterListType.ClientIds]: FilterField.client_ids,
  [FilterListType.Departments]: FilterField.work_group_ids,
  [FilterListType.Skills]: FilterField.skill_ids,
  [FilterListType.SkillLevels]: FilterField.skillLevels,
  [FilterListType.Positions]: FilterField.position_ids,
  [FilterListType.Disciplines]: FilterField.discipline_ids,
  [FilterListType.Regions]: FilterField.region_ids,
  [FilterListType.Offices]: FilterField.office_ids,
  [FilterListType.ActivityIds]: FilterField.activity_ids,
  [FilterListType.Billable]: FilterField.billable,
  [FilterListType.ProfitCenter]: FilterField.profit_center,
  [FilterListType.EmploymentType]: FilterField.employmentType,
  [FilterListType.Priorities]: FilterField.priority_ids,
  [FilterListType.ArchivedStatus]: FilterField.archivedStatus,
  [FilterListType.ShowProjectsWithUnassignedRoles]:
    FilterField.isProjectsWithUnassignedRoles
  // Example of how to add array property:
  // [FilterListType.RolesAndAccounts]: [
  //   FilterField.account_ids,
  //   FilterField.position_ids
  // ] as FilterField[] // necessary for preventing type errors due to readonly array
} as const;

/**
 * Use these for cross filter FilterListType to FilterField hash
 */
export const crossFilterFieldOverrideHash = {
  [FilterListType.Members]: FilterField.account_ids_cf,
  [FilterListType.MembersByDepartment]: FilterField.account_ids_cf,
  [FilterListType.MembersByPortfolio]: FilterField.account_ids_cf,
  [FilterListType.MembersByPosition]: FilterField.account_ids_cf,
  [FilterListType.MembersByRegion]: FilterField.account_ids_cf,
  [FilterListType.MembersBySkill]: FilterField.account_ids_cf,
  [FilterListType.MembersByOffice]: FilterField.account_ids_cf,
  [FilterListType.MembersByDiscipline]: FilterField.account_ids_cf,
  [FilterListType.Portfolios]: FilterField.board_ids_cf,
  [FilterListType.Projects]: FilterField.project_ids_cf,
  [FilterListType.ProjectsByPortfolio]: FilterField.project_ids_cf,
  [FilterListType.ProjectsByMember]: FilterField.project_ids_cf,
  [FilterListType.ProjectBudgetStatus]: FilterField.projectBudgetStatus_cf,
  [FilterListType.PhasesByProjectsByPortfolio]: FilterField.phase_ids_cf,
  [FilterListType.PhaseIds]: FilterField.phase_ids_cf,
  [FilterListType.PhaseNames]: FilterField.phase_names_cf,
  [FilterListType.PhaseBudgetStatus]: FilterField.phaseBudgetStatus_cf,
  [FilterListType.ClientNames]: FilterField.clients_cf,
  [FilterListType.ClientIds]: FilterField.client_ids_cf,
  [FilterListType.Departments]: FilterField.work_group_ids_cf,
  [FilterListType.Skills]: FilterField.skill_ids_cf,
  [FilterListType.SkillLevels]: FilterField.skillLevels_cf,
  [FilterListType.Positions]: FilterField.position_ids_cf,
  [FilterListType.Disciplines]: FilterField.discipline_ids_cf,
  [FilterListType.Regions]: FilterField.region_ids_cf,
  [FilterListType.Offices]: FilterField.office_ids_cf,
  [FilterListType.ActivityIds]: FilterField.activity_ids_cf,
  [FilterListType.Billable]: FilterField.billable_cf,
  [FilterListType.ProfitCenter]: FilterField.profit_center_cf,
  [FilterListType.EmploymentType]: FilterField.employmentType_cf,
  [FilterListType.Priorities]: FilterField.priority_ids_cf,
  [FilterListType.ArchivedStatus]: FilterField.archivedStatus_cf,
  [FilterListType.ShowProjectsWithUnassignedRoles]:
    FilterField.isProjectsWithUnassignedRoles_cf
} as const;

/**
 * FilterListType to dot notation key on filters.
 * Currently only used in VirtualFilter as a replacement for filterSectionToFilterKey
 */
export const filterListTypeToKey: Record<FilterListType, string> = mapValues(
  crossFilterFieldOverrideHash,
  (field) => {
    if (Array.isArray(field)) return 'TODO';
    return isStandardFilterFieldHash[field] ? field : `custom.${field}`;
  }
);

export const defaultFilterListTypeToLabel = {
  [FilterListType.Members]: 'All Members',
  [FilterListType.MembersByDepartment]: 'Departments',
  [FilterListType.MembersByPortfolio]: 'Portfolio',
  [FilterListType.MembersByPosition]: 'Roles',
  [FilterListType.MembersByRegion]: 'Regions',
  [FilterListType.MembersBySkill]: 'Skills',
  [FilterListType.MembersByOffice]: 'Offices',
  [FilterListType.MembersByDiscipline]: 'Disciplines',
  [FilterListType.Projects]: 'Projects',
  [FilterListType.ProjectsByPortfolio]: 'Portfolios',
  [FilterListType.ProjectsByMember]: 'All Members',
  [FilterListType.PhasesByProjectsByPortfolio]: 'Phases',
  [FilterListType.PhaseIds]: 'Phases',
  [FilterListType.PhaseNames]: 'Phases',
  [FilterListType.Portfolios]: 'Portfolios',
  [FilterListType.Departments]: 'Departments',
  [FilterListType.Skills]: 'Skills',
  [FilterListType.SkillLevels]: 'Skills',
  [FilterListType.Positions]: 'Roles',
  [FilterListType.Regions]: 'Regions',
  [FilterListType.Offices]: 'Offices',
  [FilterListType.ClientNames]: 'Clients',
  [FilterListType.ClientIds]: 'Clients',
  [FilterListType.Priorities]: 'Priority',
  [FilterListType.ArchivedStatus]: 'Active/Archived',
  [FilterListType.ShowProjectsWithUnassignedRoles]: 'Unassigned',
  [FilterListType.Disciplines]: 'Disciplines'
};

/* ---------------------------------- Sort ---------------------------------- */

/**
 * Use for sort attributes and groupBys
 */
export enum SortAttribute {
  Approver = 'Approver',
  Assignee = 'Assignee',
  Project = 'Project',
  Phase = 'Phase',
  ActivityPhase = 'ActivityPhase',
  Description = 'Description',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  CreatedDate = 'CreatedDate',
  RequestedBy = 'RequestedBy',
  EstimatedHours = 'EstimatedHours'
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}

/* -------------------------------- Billable -------------------------------- */

export const BillableValues = {
  BILLABLE: 'Billable',
  NOT_BILLABLE: 'Not Billable'
} as const;
