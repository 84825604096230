import React from 'react';
import SvgIcon from 'components/SvgIcon';

const QBDownArrow = ({ className = '', fill = '#4a4a4a' }) => (
  <SvgIcon width="10" height="6" viewBox="0 0 10 6" className={className}>
    <path
      d="M5.00032 5.00899L0.450117 0.000699997L0 0.496204L5.00032 6L10 0.495502L9.54988 0L5.00032 5.00899Z"
      fill={fill}
    />
  </SvgIcon>
);

export default QBDownArrow;
