import { createAction } from '@reduxjs/toolkit';
import { TimelineView } from '../types';

type addTimelineViewParams = Pick<TimelineView, 'id' | 'type'> & {
  initialVisibleTimeStart?: EpochTimeStamp;
};

type removeTimelineViewParams = Pick<TimelineView, 'id'>;

export const addTimelineView =
  createAction<addTimelineViewParams>('ADD_TIMELINE_VIEW');
export const removeTimelineView = createAction<removeTimelineViewParams>(
  'REMOVE_TIMELINE_VIEW'
);
