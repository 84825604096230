import React, { lazy, Suspense } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getSettingsModule } from 'SettingsModule/package/settingsModule';

const SettingsContainer = lazy(() => import('./SettingsContainer'));

const LoadSettingsContainer = (props) => (
  <DynamicModuleLoader modules={[getSettingsModule()]}>
    <Suspense fallback={<div />}>
      <SettingsContainer {...props} />
    </Suspense>
  </DynamicModuleLoader>
);
export default LoadSettingsContainer;
