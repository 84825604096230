import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getSortedTeamMembers,
  makeGetTeamMembershipByAccountId,
  getMe,
  makeGetProjectBasicInfoById,
  makeGetBoardByProjectBasicInfoId,
  makeGetProjectMemberships,
  getIsOnReportsView,
  makeGetOwnActivityPhaseUsingPhaseId,
  getTeamMembersHash,
  getSplitFlags,
  getGroupsHash
} from 'selectors';
import { fetchBoardMembers } from 'actionCreators';
import {
  SelectContainer,
  StyledDownArrow,
  MemberName,
  ArchivedText
} from './styles';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import cn from 'classnames';
import { InnerDropdownOption } from 'views/projectPlanner/styles';
import BulkMembersDropdown from './BulkMembersDropdown';

import { rebuildTooltip } from 'appUtils/tooltipUtils';
import QBDownArrow from 'icons/QBDownArrow';
import keyBy from 'lodash/keyBy';
import { makeGetOwnPhase } from 'BudgetModule/selectors';
import { permissionsUtils } from 'PermissionsModule/utils';
import debounce from 'lodash/debounce';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';
import {
  createPrivatePortfolioMembership,
  createPublicPortfolioMembership
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import { useCheckPermission } from 'hocs/withPermissionsCheck';
import { useAppDispatch } from 'reduxInfra/hooks';
import { membershipLevels } from './shared/constants';
import { includes } from 'appUtils/typeUtils';

const makeMemberCollector = (addedMembers) => ({
  addedMembers: [...addedMembers],
  scopeMembers: [],
  activityMembers: [],
  phaseMembers: [],
  projectMembers: [],
  boardOnlyMembers: [],
  otherMembers: []
});
class BulkMemberSelector extends React.Component {
  state = {
    addedMembers: [],
    isOpen: false,
    // initialize in state and later modified in the `PermissionWrapper` component
    // we have to do this way because we need to conditionally call and extract the values returned from hooks, see `PermissionWrapper` for more information
    permissionProps: {
      canAddProjectMembership: false,
      canAddToPortfolio: false
    }
  };

  componentDidMount() {
    rebuildTooltip();
    const { board, isListItem, addedAccountIds } = this.props;
    if (board && !isListItem) {
      this.loadBoardMembers();
    }
    if (addedAccountIds?.length) {
      this.handleAddedAccountIds();
    }
  }

  componentDidUpdate(prevProps) {
    const { board, isListItem } = this.props;
    rebuildTooltip();
    if (prevProps.board !== board && !isListItem) {
      this.loadBoardMembers();
    }
    if (prevProps.addedAccountIds !== this.props.addedAccountIds) {
      this.handleAddedAccountIds();
    }
  }

  // pass props.addedAccountIds to get addedMembers to follow outside changes
  handleAddedAccountIds = () => {
    const { addedAccountIds, teamMembersHash } = this.props;
    this.setState({
      addedMembers: addedAccountIds
        .filter((accountId) => !!accountId)
        .map((accountId) => teamMembersHash[accountId])
    });
  };

  // This function is called repeatedly on scroll, need debounce
  loadBoardMembers = debounce(() => {
    const { board, fetchBoardMembers } = this.props;
    if (!board?.id) {
      return;
    }
    fetchBoardMembers(board.id);
  }, 500);

  openDropdown = () => {
    if (this.props.onOpen) {
      this.props.onOpen();
    }
    const { isListItem } = this.props;
    this.setState({ isOpen: true });
    if (isListItem) {
      this.loadBoardMembers();
    }
  };

  closeDropdown = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({ isOpen: false, addedMembers: [] });
  };

  addMember = (member) => {
    if (this.props.singleSelect) {
      this.setState({ addedMembers: [member] });
    } else {
      this.setState({ addedMembers: [...this.state.addedMembers, member] });
    }
  };

  removeMember = (member) =>
    this.setState({
      addedMembers: this.state.addedMembers.filter(
        (addedMember) => addedMember.id !== member.id
      )
    });

  handleSelect = (item) => {
    const { addedMembers } = this.state;
    const { closeOnSelect } = this.props;
    if (
      addedMembers.find((member) => member.account.id === item?.account?.id)
    ) {
      this.removeMember(item);
    } else {
      this.addMember(item);
    }
    if (closeOnSelect) {
      this.props.handleDone([item]);
      this.closeDropdown();
    }
  };

  renderMember = () => {
    const { selectedMember, me, nameStyle, selectionDisabled } = this.props;
    return (
      <InnerDropdownOption
        onClick={this.openDropdown}
        isArchived={selectedMember?.is_archived}
      >
        <MemberInitials
          member={selectedMember}
          classes={cn('regular-member-no-hover selected', {
            'logged-member-no-hover': selectedMember.account.id === me.id
          })}
          idx={'idx-placeholder'}
          isOnTaskModal
        />

        <MemberName nameStyle={nameStyle}>
          {selectedMember.account.name}
        </MemberName>

        {!selectionDisabled && (
          <StyledDownArrow className="styled-down-arrow">
            <QBDownArrow />
          </StyledDownArrow>
        )}
        {selectedMember?.is_archived ? (
          <ArchivedText>Archived</ArchivedText>
        ) : null}
      </InnerDropdownOption>
    );
  };

  renderSelect = () => {
    return (
      <SelectContainer onClick={this.openDropdown}>
        {`Assign Member`}
        <StyledDownArrow>
          <QBDownArrow />
        </StyledDownArrow>
      </SelectContainer>
    );
  };

  getScopeMemberIds = () =>
    this.props.scope?.assignees
      ? keyBy(this.props.scope?.assignees, (id) => id)
      : [];

  getActivityMemberIds = () =>
    this.props.activityPhase?.activity_phase_memberships
      ? keyBy(
          this.props.activityPhase?.activity_phase_memberships
            .filter((member) => !member.discarded_at)
            .map((item) => item.account_id)
        )
      : [];

  getPhaseMemberIds = () =>
    this.props.phase?.phase_memberships
      ? keyBy(
          this.props.phase?.phase_memberships
            .filter((member) => !member.discarded_at)
            .map((item) => item.account_id)
        )
      : [];

  getProjectMemberIds = () =>
    this.props.project?.member_account_ids
      ? keyBy(this.props.project?.member_account_ids)
      : [];

  getBoardMemberIds = () =>
    this.props.boardMembers?.length
      ? keyBy(this.props.boardMembers?.map((member) => member.account.id))
      : [];

  getSortedMembers = () => {
    const {
      project,
      board,
      boardMembers,
      members,
      projectMemberships,
      includeUnassigned = false,
      skipGuests = false,
      memberFilter = (identity) => identity,
      isOnPlanner,
      shouldUseMemberFilter,
      customMakeSortedMembersFn,
      flags,
      suggestionsProps,
      membershipLevel
    } = this.props;
    const unassignedRow = {
      unassigned: true,
      account: { id: null }
    };
    if (project && board && !boardMembers?.length) {
      return [];
    }
    if (!project || !board || !boardMembers?.length) {
      if (includeUnassigned) {
        return [unassignedRow, ...members];
      }
      // TODO: Deprecate `isOnPlanner` prop in favour of `shouldUseMemberFilter`
      if (isOnPlanner || shouldUseMemberFilter) {
        return memberFilter(members);
      }
      return members;
    }
    const onScopeIds = this.getScopeMemberIds();
    const onActivityIds = this.getActivityMemberIds();
    const onPhaseIds = this.getPhaseMemberIds();
    const onProjectIds = this.getProjectMemberIds();
    const onBoardIds = this.getBoardMemberIds();

    const { canAddProjectMembership, canAddToPortfolio } =
      this.state.permissionProps;

    const addedMembersByAccountId = keyBy(
      this.state.addedMembers,
      (item) => item.account?.id
    );

    const {
      addedMembers,
      scopeMembers,
      activityMembers,
      phaseMembers,
      projectMembers,
      boardOnlyMembers,
      otherMembers
    } = members.reduce((collector, member) => {
      const {
        scopeMembers,
        activityMembers,
        phaseMembers,
        projectMembers,
        boardOnlyMembers,
        otherMembers
      } = collector;
      const memberId = member?.account?.id;
      if (addedMembersByAccountId[memberId]) {
        return collector;
      }

      const isAddingToPortfolio =
        this.props.membershipLevel === membershipLevels.board;

      // note: even if adding to e.g. phase/activity, it still technically means adding to the project
      const isAddingToProject = !includes(
        [membershipLevels.team, membershipLevels.board],
        membershipLevel
      );

      if (onProjectIds?.[memberId]) {
        // reaching inside here means the member is on the project and on the portfolio
        const memberData = {
          ...member,
          role_ids: projectMemberships[member?.account?.id]?.role_ids
        };

        if (onPhaseIds?.[memberId]) {
          if (onActivityIds?.[memberId]) {
            if (onScopeIds[memberId]) {
              scopeMembers.push(memberData);
            } else {
              activityMembers.push(memberData);
            }
          } else {
            phaseMembers.push(memberData);
          }
        } else {
          projectMembers.push(memberData);
        }
      } else if (skipGuests && permissionsUtils.getIsProjectGuest(member)) {
        // skip guests that are not already on the project
        return collector;
      } else if (onBoardIds?.[memberId]) {
        // reaching inside here means the member is not on the project but is on the portfolio
        if (canAddProjectMembership) {
          boardOnlyMembers.push(member);
        }
      } else {
        // reaching inside here means the member is not on the project and portfolio
        let isAddMember = false;

        if (isAddingToProject) {
          isAddMember = canAddToPortfolio && canAddProjectMembership;
        } else if (isAddingToPortfolio) {
          isAddMember = canAddToPortfolio;
        }

        if (isAddMember) {
          otherMembers.push(member);
        }
      }
      return collector;
    }, makeMemberCollector(this.state.addedMembers));

    const sortedMembers = customMakeSortedMembersFn
      ? customMakeSortedMembersFn({
          addedMembers,
          scopeMembers,
          activityMembers,
          phaseMembers,
          projectMembers,
          boardOnlyMembers,
          otherMembers,
          flags,
          suggestionsProps
        })
      : [
          ...addedMembers,
          ...scopeMembers,
          ...activityMembers,
          ...phaseMembers,
          ...projectMembers,
          ...boardOnlyMembers,
          ...otherMembers
        ];
    if (includeUnassigned) {
      sortedMembers.unshift(unassignedRow);
    }
    return memberFilter(sortedMembers);
  };

  handleDone = () => {
    this.props.handleDone(this.state.addedMembers);
    this.closeDropdown();
  };

  onFooterClick = () => {
    const { bulkActionAffectedMemberIds, members, memberFilter } = this.props;

    const addedMembersByAccountId = keyBy(
      this.state.addedMembers,
      (item) => item.account?.id
    );
    const bulkActionAffectedIdHash = keyBy(bulkActionAffectedMemberIds);

    const initiallyFilteredMembers = members.filter(
      (member) =>
        !addedMembersByAccountId[member?.account?.id] &&
        bulkActionAffectedIdHash[member?.account?.id]
    );
    // some dropdowns don't pass memberFilter in order to show added members with 'remove' tag
    // e.g. scope detail modal assignee dropdown
    const membersToAdd = memberFilter
      ? memberFilter(initiallyFilteredMembers)
      : initiallyFilteredMembers;

    this.setState({
      addedMembers: [...this.state.addedMembers, ...membersToAdd]
    });
  };

  updatePermissionProps = (permissionProps) =>
    this.setState({ permissionProps });

  render() {
    const { isOpen } = this.state;
    const {
      selectedMember,
      projectId,
      project,
      selectionDisabled,
      joinModalText,
      membershipLevel,
      footerInitialCopy,
      alwaysOpen,
      hideFooter,
      addTextClassName,
      mediumWidth,
      isOnPlanner,
      isOnBudget,
      suggestionsProps,
      renderHeader,
      renderHeaderButton,
      listWidth,
      listHeight,
      popoverClassName,
      searchPlaceholder,
      popoverPlacement,
      groupHash
    } = this.props;
    const renderMember = this.props.renderMember || this.renderMember;
    const renderSelect = this.props.renderSelect || this.renderSelect;

    const addedMembersByAccountId = keyBy(
      this.state.addedMembers,
      (item) => item.account?.id
    );
    const portfolio = groupHash[project?.board_id];

    return (
      <>
        {project && portfolio && (
          <PermissionWrapper
            project={project}
            portfolio={portfolio}
            setPermissionProps={this.updatePermissionProps}
          />
        )}
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          ref={(ref) => (this.target = ref)}
        >
          {selectedMember
            ? renderMember({ onClick: this.openDropdown })
            : renderSelect({ onClick: this.openDropdown })}
        </div>
        {(isOpen || alwaysOpen) && !selectionDisabled && (
          <BulkMembersDropdown
            renderHeader={renderHeader}
            renderHeaderButton={renderHeaderButton}
            listWidth={listWidth}
            listHeight={listHeight}
            popoverClassName={popoverClassName}
            searchPlaceholder={searchPlaceholder}
            popoverPlacement={popoverPlacement}
            members={this.getSortedMembers()}
            target={this.props.target || this.target}
            handleClose={this.closeDropdown}
            handleSelect={this.handleSelect}
            projectId={projectId}
            scopeMemberIds={this.getScopeMemberIds()}
            activityMemberIds={this.getActivityMemberIds()}
            phaseMemberIds={this.getPhaseMemberIds()}
            projectMemberIds={this.getProjectMemberIds()}
            boardMemberIds={this.getBoardMemberIds()}
            joinModalText={joinModalText}
            handleDone={this.handleDone}
            membershipLevel={membershipLevel}
            footerInitialCopy={footerInitialCopy}
            onFooterClick={this.onFooterClick}
            addedMembersByAccountId={addedMembersByAccountId}
            hideFooter={hideFooter}
            addTextClassName={addTextClassName}
            mediumWidth={mediumWidth}
            isOnPlanner={isOnPlanner}
            isOnBudget={isOnBudget}
            suggestionsProps={suggestionsProps}
          />
        )}
      </>
    );
  }
}

const projectIdGetter = (state, ownProps) => ownProps.projectId;
const makeMapStateToProps = () => {
  const getTeamMembership = makeGetTeamMembershipByAccountId();
  const getProjectMemberships = makeGetProjectMemberships({ projectIdGetter });
  const getProjectInfo = makeGetProjectBasicInfoById();
  const getBoard = makeGetBoardByProjectBasicInfoId();
  const getOwnPhase = makeGetOwnPhase();
  const getOwnActivityPhaseUsingPhaseId = makeGetOwnActivityPhaseUsingPhaseId();

  const mapStateToProps = (state, ownProps) => ({
    members: ownProps.members || getSortedTeamMembers(state),
    projectMemberships: getProjectMemberships(state, ownProps),
    me: getMe(state),
    selectedMember: getTeamMembership(state, ownProps),
    phase: getOwnPhase(state, ownProps),
    activityPhase:
      ownProps.activityPhase ||
      getOwnActivityPhaseUsingPhaseId(state, {
        phaseId: ownProps.phaseId,
        activityPhaseId: ownProps.activityPhaseId
      }),
    project: getProjectInfo(state, ownProps),
    board: getBoard(state, ownProps),
    boardMembers: state.groups.memberList,
    isOnReportsView: getIsOnReportsView(state),
    teamMembersHash: getTeamMembersHash(state),
    flags: getSplitFlags(state),
    groupHash: getGroupsHash(state)
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps, {
  fetchBoardMembers
})(BulkMemberSelector);

// reference: https://goodguydaniel.com/blog/call-react-hooks-inside-condition
const PermissionWrapper = ({ project, portfolio, setPermissionProps }) => {
  const checkPermission = useCheckPermission();
  const dispatch = useAppDispatch();

  const { canAddProjectMembership } = useProjectPermissionState({
    projectId: project.id
  });

  const addToPortfolioCheck = portfolio.is_private
    ? createPrivatePortfolioMembership
    : createPublicPortfolioMembership;

  const canAddToPortfolio = dispatch(
    checkPermission(addToPortfolioCheck, {
      permissions: {
        boardId: project.board_id
      }
    })
  );

  useEffect(() => {
    setPermissionProps({ canAddProjectMembership, canAddToPortfolio });
  }, [canAddProjectMembership, canAddToPortfolio, setPermissionProps]);

  return null;
};
