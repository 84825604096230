import {
  Field,
  Label
} from 'SettingsModule/components/StandardSettings/General/styles';
import ButtonField from 'SettingsModule/components/ButtonsField';
import styled from 'styled-components';
import { noop } from 'appUtils';

export const PhaseReferenceSetting = () => {
  const handleClick = (value) => {
    // Update this when linking with BE in the future
    noop();
  };

  return (
    <>
      <Label>Phase Reference #</Label>
      <StyledField>
        <RadioButtonsContainer>
          <ButtonField
            type="radio"
            value="enable"
            checked={false}
            name="enable"
            onClick={handleClick}
            label="Enable"
          />
          <ButtonField
            type="radio"
            value="disable"
            checked={false}
            name="disable"
            onClick={handleClick}
            label="Disable"
          />
        </RadioButtonsContainer>
        {/* When implementing logic, conditionally render the component below to only show if Enabled is selected */}
        <ButtonField
          type="checkbox"
          value="disable"
          checked={false}
          name="disable"
          onClick={handleClick}
          label="Automatically Assign Project Numbers"
        />
      </StyledField>
    </>
  );
};

const StyledField = styled(Field)`
  flex-direction: column;
`;

const RadioButtonsContainer = styled.div`
  display: flex;
`;
