import theme from 'theme';
import { Modal } from 'reactstrap';
import styled from 'styled-components';
import FindPeopleContainer from '../FindPeopleContainer';
import { FilterContextProvider } from 'FilterModule/FilterContextProvider';
import { Page } from 'models/filter';
import { initialFilterLevelSchemas } from '../filterConfig';

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: ${theme.colors.colorLightGray19};
    height: 100%;
    width: 100%;
    overflow-y: hidden;
  }

  &.modal-dialog {
    height: calc(100vh - 35px);
    margin: 0 auto;
    max-width: 100vw;
  }
`;

type FindPeopleModalProps = {
  toggle: () => void;
  unassignedMemberBudgetId?: Nullable<number>;
  projectId?: Nullable<number>;
};

/**
 * Note: Either use useFindPeopleModal hook or withFindPeopleModal HOC to get the FindPeopleModal component and handlers
 *
 * Open from spaces that provides projectId (i.e: Budget Modal, project info page): Use that project as the latest selected one
 * Open from spaces that don't provide projectId (i.e: Planner): default to the last selected one
 */
const FindPeopleModal = ({
  toggle,
  unassignedMemberBudgetId,
  projectId
}: FindPeopleModalProps) => {
  // Filter context for project selector
  const filterContextProviderProps: React.ComponentProps<
    typeof FilterContextProvider
  > = {
    filterSchemaParams: {
      pageName: Page.FindPeople,
      level2: 'projects',
      initialFilterLevelSchemas
    }
  };

  return (
    <StyledModal size="xl" isOpen toggle={toggle}>
      <FilterContextProvider {...filterContextProviderProps}>
        <FindPeopleContainer
          $unassignedMemberBudgetId={unassignedMemberBudgetId}
          toggleModal={toggle}
          $projectId={projectId}
        />
      </FilterContextProvider>
    </StyledModal>
  );
};

export default FindPeopleModal;
