import {
  Label,
  Field
} from 'SettingsModule/components/StandardSettings/General/styles';
import ButtonField from 'SettingsModule/components/ButtonsField';
import { noop } from 'appUtils';

export const DefaultTimeFormatSetting = () => {
  const handleClick = (value) => {
    // Update this when linking with BE in the future
    noop();
  };

  return (
    <>
      <Label>Default Time Format</Label>
      <Field>
        <ButtonField
          type="radio"
          value="12h"
          checked={false}
          name="12h"
          onClick={handleClick}
          label="12HR AM/PM 1:30"
        />
        <ButtonField
          type="radio"
          value="24h"
          checked={false}
          name="24h"
          onClick={handleClick}
          label="24HR 13:30"
        />
      </Field>
    </>
  );
};
