import React, { useRef } from 'react';
import { connect } from 'react-redux';
import CellContainer from './CellContainer';
import TaskCircle from '../TaskCircle';
import {
  setEditTaskId,
  triggerTasksAttributesUpdate,
  updateTaskPosition,
  fetchCommentsAndMetadata,
  fetchTaskGroups,
  closeAndClearTaskViewModal,
  setSelectedTask,
  setSelectedHomeTask,
  triggerTaskCompletionToggle,
  flushSelectedHomeTask,
  setHomeTaskEditProperty,
  navigateToTaskModal,
  openConfirmCompleteTaskModal
} from 'actionCreators';

import {
  getOnProjectDetail,
  getIsOnTeamProject,
  getOnMembersView,
  getOnHomeView,
  getIsOnPersonalProject,
  getAllTasks,
  getTaskEditProperty,
  getCurrentFilter,
  getSearchText,
  getIsFetchingTasks,
  getAuthToken,
  getSelectedTeamId,
  getMatchedRouteParams,
  getIsOnProjectView,
  getIsTaskModalOpen,
  getEditingTask,
  getShouldTriggerTaskConfirmCompleteModal,
  getCreatedTasksTempIds,
  getIsMemberModalOpen
} from 'selectors';
import { keyifyDate } from 'appUtils/plannerUtils';

const CompleteCell = (props) => {
  const { row, isMemberModalOpen, isOnProjectView } = props;
  const {
    id,
    completed_at,
    createRow,
    taskProps = {},
    customRowProps,
    isFirstRow,
    list: { id: listId, createRowData, setCreating }
  } = row.original;
  const { taskIsEditing, isNewTask, isOnTeamPageView, isOnHomeView } =
    taskProps;

  const markTaskCompletion = () => {
    const {
      setSelectedHomeTask,
      setSelectedTask,
      closeAndClearTaskViewModal,
      flushSelectedHomeTask,
      setEditTaskId,
      handleTaskCompletionToggle,
      task,
      searchText,
      token,
      handleTaskPositionUpdate,
      taskType,
      isFetchingTasks,
      index,
      currentFilter: { scope, state },
      listLength,
      isTaskModalOpen,
      openConfirmCompleteTaskModal,
      shouldTriggerTaskConfirmCompleteModal
    } = props;
    if (createRow) {
      return;
    }
    const taskId = task.id;
    const projectId = task.project_id;
    // if task is completed, un-complete - otherwise, complete it
    const isCompleted = task.completed_at !== null;
    const position = isCompleted ? index : listLength;
    const viewingAllTask = state === 'default';
    if (!isFetchingTasks) {
      const isOverdue = task.due_at && task.due_at < Date();
      const permissions = {};
      if (
        task.assignee_ids?.length > 1 &&
        shouldTriggerTaskConfirmCompleteModal
      ) {
        openConfirmCompleteTaskModal({ taskId: task.id });
        return;
      }
      if (!isTaskModalOpen) {
        closeAndClearTaskViewModal({ skipNavigateUp: true });
        flushSelectedHomeTask();
        setSelectedHomeTask(null);
        setSelectedTask(null);
        setEditTaskId(null);
      }
      handleTaskCompletionToggle({
        taskId,
        isCompleting: !isCompleted, // used to determine if task needs to be re-ordered because the list is a day planner order
        doNotRemoveTask: viewingAllTask // prevents task from being removed From all task list when complete is toggled
        // permissions
      });
      handleTaskPositionUpdate({
        token,
        taskId,
        projectId,
        taskType,
        isCompleted: !isCompleted,
        wasCompleted: isCompleted,
        isOverdue,
        taskPosition: task.position,
        oldTaskPosition: task.position,
        selectedAccountIds: [],
        searchText,
        circleClicked: true,
        plannerPosition: position,
        dateKey: scope === 'today' ? keyifyDate(task.schedule_start) : null
        // permissions
      });
    }
  };

  const onCreateRowClick = () => {
    setCreating({ id: listId, createRowData });
    row.setState({
      editingProperty: 'description',
      taskId: row.original.id || 'addTask'
    });
  };

  const renderAsTeamProject = isOnTeamPageView && !isOnHomeView;
  return (
    <CellContainer taskProps={taskProps} taskProperty="completed" row={row}>
      <TaskCircle
        markComplete={markTaskCompletion}
        isCompleted={completed_at !== null && !createRow}
        isEditing={taskIsEditing}
        onCreateRowClick={createRow ? onCreateRowClick : null}
        isOnTeamProject={renderAsTeamProject}
        isOnTeamPageView={isOnTeamPageView}
        taskCircleClass={isOnProjectView ? 'project-detail-task' : ''}
        isOnProjectView={isOnProjectView}
        key={props.task?.id}
        isCreateRow={createRow}
        rowEditing={customRowProps?.rowEditing}
        isMemberModalOpen={isMemberModalOpen}
      />
    </CellContainer>
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => ({
    token: getAuthToken(state),
    isFetchingTasks: getIsFetchingTasks(state),
    searchText: getSearchText(state),
    isOnProjectDetail: getOnProjectDetail(state),
    currentFilter: getCurrentFilter(state),
    isOnTeamProject: getIsOnTeamProject(state),
    isOnTeamPageView: getOnMembersView(state),
    isOnHomeView: getOnHomeView(state),
    isOnPersonalProject: getIsOnPersonalProject(state),
    task: ownProps.row.original,
    listLength: getAllTasks(state).length,

    isEditingTask: getEditingTask(state) === ownProps.taskId,
    taskEditProperty: getTaskEditProperty(state),
    selectedTeamId: getSelectedTeamId(state),
    matchedParams: getMatchedRouteParams(state),
    isOnProjectView: getIsOnProjectView(state),
    isTaskModalOpen: getIsTaskModalOpen(state),
    isMemberModalOpen: getIsMemberModalOpen(state),
    createdTasksTempIds: getCreatedTasksTempIds(state),
    shouldTriggerTaskConfirmCompleteModal:
      getShouldTriggerTaskConfirmCompleteModal(state)
  });
  return mapStateToProps;
};

const mapDispatchToProps = {
  setSelectedTask,
  setSelectedHomeTask,
  closeAndClearTaskViewModal,
  fetchCommentsAndMetadata,
  fetchTaskGroups,
  setEditTaskId,
  handleTasksAttributesUpdate: triggerTasksAttributesUpdate,
  handleTaskPositionUpdate: updateTaskPosition,
  handleTaskCompletionToggle: triggerTaskCompletionToggle,
  flushSelectedHomeTask,
  setHomeTaskEditProperty,
  navigateToTaskModal,
  openConfirmCompleteTaskModal
};
export default connect(makeMapStateToProps, mapDispatchToProps)(CompleteCell);
