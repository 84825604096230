import { useRef, ReactNode } from 'react';
import styled from 'styled-components';
import OrganizationSettingsDropdown from './OrganizationSettingsDropdown';
import { usePopover } from 'components/Popover/usePopover';
import cn from 'classnames';
import theme from 'theme';
import ReactTooltip from 'react-tooltip';

type PopoverOptions = Record<string, PopoverItem>;

interface PopoverItem {
  item: ReactNode;
}

type OrganizationSettingsPopoverProps = {
  options: PopoverOptions;
  currentlySelectedKey: keyof PopoverOptions;
  showEllipsisTooltip?: boolean;
};

export const OrganizationSettingsPopover = ({
  options,
  currentlySelectedKey,
  showEllipsisTooltip
}: OrganizationSettingsPopoverProps) => {
  const { Popover, openPopover, closePopover, isOpen } = usePopover();

  const popoverRef = useRef(null);

  const handleToggle = () => {
    if (isOpen) {
      closePopover();
    } else if (popoverRef?.current) {
      openPopover({ target: popoverRef.current });
    }
  };

  const handleSelect = () => {
    closePopover();
    ReactTooltip.hide();
  };

  return (
    <OrganizationSettingsPopoverContainer
      ref={popoverRef}
      onClick={handleToggle}
    >
      <OrganizationSettingsDropdown
        label={options[currentlySelectedKey]?.item ?? ''}
        showEllipsisTooltip={showEllipsisTooltip}
      />
      <Popover>
        {Object.keys(options).map((optionKey) => (
          <PopoverItem
            key={optionKey}
            className={cn({ selected: optionKey === currentlySelectedKey })}
            onClick={handleSelect}
          >
            {options[optionKey]?.item}
          </PopoverItem>
        ))}
      </Popover>
    </OrganizationSettingsPopoverContainer>
  );
};

const OrganizationSettingsPopoverContainer = styled.div``;

const PopoverItem = styled.div`
  padding: 8px 16px;

  cursor: pointer;

  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
  }

  &.selected {
    font-weight: 600;
  }
`;
