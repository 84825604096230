import { usableImportTokens, CsvImportEntity } from './constants';
import { makeRowsValidator, makeOptionsGetter } from './validation';

// these configs come from the Osmos-provided code snippets

type ConfigParams = {
  requiredPortfolioValidator?: ReturnType<typeof makeRowsValidator>;
  nullablePortfolioValidator?: ReturnType<typeof makeRowsValidator>;
  portfolioOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  projectValidator?: unknown;
  projectNumberValidator?: unknown;
  phaseValidator?: unknown;
  phaseNumberValidator?: ReturnType<typeof makeRowsValidator>;
  phaseOptionsGetter?: unknown;
  activityValidator?: unknown;
  activityOptionsGetter?: unknown;
  emailValidator?: unknown;
  memberValidator?: unknown;
  startDateValidator?: ReturnType<typeof makeRowsValidator>;
  endDateValidator?: ReturnType<typeof makeRowsValidator>;
  dateValidator?: unknown;
  hoursValidator?: ReturnType<typeof makeRowsValidator>;
  totalHoursValidator?: ReturnType<typeof makeRowsValidator>;
  hoursPerDayValidator?: ReturnType<typeof makeRowsValidator>;
  projectBudgetStatusValidator?: ReturnType<typeof makeRowsValidator>;
  projectBudgetStatusOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  projectClientValidator?: ReturnType<typeof makeRowsValidator>;
  projectClientOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  projectPriorityValidator?: ReturnType<typeof makeRowsValidator>;
  projectPriorityOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  phaseStartDateValidator?: ReturnType<typeof makeRowsValidator>;
  phaseEndDateValidator?: ReturnType<typeof makeRowsValidator>;
  phaseBudgetStatusValidator?: ReturnType<typeof makeRowsValidator>;
  phaseBudgetStatusOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  phaseContractTypeValidator?: ReturnType<typeof makeRowsValidator>;
  phaseContractTypeOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  phaseFeeValidator?: ReturnType<typeof makeRowsValidator>;
  phaseFeeOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  phaseTargetValidator?: ReturnType<typeof makeRowsValidator>;
  phaseTargetOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  activityStartDateValidator?: ReturnType<typeof makeRowsValidator>;
  activityEndDateValidator?: ReturnType<typeof makeRowsValidator>;
  activityFeeValidator?: ReturnType<typeof makeRowsValidator>;
  activityFeeOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  activityTargetValidator?: ReturnType<typeof makeRowsValidator>;
  activityTargetOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  accessLevelValidator?: ReturnType<typeof makeRowsValidator>;
  accessLevelOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  roleValidator?: ReturnType<typeof makeRowsValidator>;
  roleOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  regionValidator?: ReturnType<typeof makeRowsValidator>;
  regionOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  ptoHoursValidator?: ReturnType<typeof makeRowsValidator>;
  ptoHoursOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  ptoDescriptionValidator?: ReturnType<typeof makeRowsValidator>;
  ptoDescriptionOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  officeValidator?: ReturnType<typeof makeRowsValidator>;
  officeOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  disciplineValidator?: ReturnType<typeof makeRowsValidator>;
  disciplineOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  departmentValidator?: ReturnType<typeof makeRowsValidator>;
  departmentOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  skillsValidator?: ReturnType<typeof makeRowsValidator>;
  currencyValidator?: ReturnType<typeof makeRowsValidator>;
  currencyOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  rateAmountValidator?: ReturnType<typeof makeRowsValidator>;
  rateAmountOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  rateDescriptionValidator?: ReturnType<typeof makeRowsValidator>;
  rateDescriptionOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  firstNameValidator?: ReturnType<typeof makeRowsValidator>;
  lastNameValidator?: ReturnType<typeof makeRowsValidator>;
  projectOfficeValidator?: ReturnType<typeof makeRowsValidator>;
  projectOfficeOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  projectRegionValidator?: ReturnType<typeof makeRowsValidator>;
  projectRegionOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  projectDisciplineValidator?: ReturnType<typeof makeRowsValidator>;
  projectDisciplineOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  memberImportEmailValidator?: ReturnType<typeof makeRowsValidator>;
  taskPriorityValidator?: ReturnType<typeof makeRowsValidator>;
  taskPriorityOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  taskStatusValidator?: ReturnType<typeof makeRowsValidator>;
  taskStatusOptionsGetter?: ReturnType<typeof makeOptionsGetter>;
  taskScopeDescriptionValidator?: ReturnType<typeof makeRowsValidator>;
  teamId?: unknown;
  sessionId?: unknown;
  importInitiatedAt?: unknown;
  myId?: unknown;
  shouldUseV2?: boolean;
  selectedGroupName?: string;
};

export const makeTimeEntriesConfig = ({
  projectValidator,
  projectNumberValidator,
  dateValidator,
  hoursValidator,
  phaseValidator,
  phaseOptionsGetter,
  activityValidator,
  activityOptionsGetter,
  memberValidator,
  emailValidator,
  teamId,
  sessionId,
  importInitiatedAt,
  myId,
  shouldUseV2,
  nullablePortfolioValidator,
  portfolioOptionsGetter
}: ConfigParams) => ({
  schema: {
    fields: [
      {
        name: 'project_title',
        displayName: 'Project Title',
        description: '',
        validator: projectValidator
      },
      {
        name: 'project_number',
        displayName: 'Project Number',
        description: 'Project Number',
        validator: projectNumberValidator
      },
      {
        name: 'date',
        displayName: 'Date',
        description: 'Date',
        validator: dateValidator
      },
      {
        name: 'hours',
        displayName: 'Hours',
        description: 'Hours',
        validator: hoursValidator
      },
      {
        name: 'phase_name',
        displayName: 'Phase',
        description: 'Name of Phase of Work.',
        validator: phaseValidator,
        validOptions: phaseOptionsGetter
      },
      {
        name: 'activity_title',
        displayName: 'Work Category',
        description: 'Name of Work Category',
        validator: activityValidator,
        validOptions: activityOptionsGetter
      },
      {
        name: 'employee_name',
        displayName: 'Employee Name',
        description: 'Full Name, separated by a space.',
        validator: memberValidator
      },
      {
        name: 'employee_email',
        displayName: 'Employee Email',
        description: 'The email address for the employee on Mosaic',
        validator: emailValidator
      },
      {
        name: 'hourly_rate',
        displayName: 'Hourly Rate',
        description: 'Hourly Rate'
      },
      {
        name: 'description',
        displayName: 'Description',
        description: 'Description of time spent.'
      },
      {
        name: 'team_id',
        fieldType: 'constantValue',
        constantValue: teamId
      },
      {
        name: 'session_id',
        fieldType: 'constantValue',
        constantValue: sessionId
      },
      {
        name: 'billable',
        displayName: 'Billable',
        description: 'Was time spent billable?'
      },
      {
        name: 'import_initiated_at',
        fieldType: 'constantValue',
        constantValue: importInitiatedAt
      },
      {
        name: 'import_account_id',
        fieldType: 'constantValue',
        constantValue: myId
      },
      {
        name: 'portfolio_name',
        displayName: 'Portfolio',
        description: 'Portfolio Name',
        validator: nullablePortfolioValidator,
        validOptions: portfolioOptionsGetter
      },
      {
        name: 'phase_number',
        displayName: 'Phase Number',
        description: 'Phase Number'
      }
    ]
  },
  token: usableImportTokens[CsvImportEntity.Timesheet],
  uploadDescription: 'Description for uploading time entries',
  hideUploadDescription: true,
  ...(shouldUseV2
    ? { enableTransformBuilder2: true }
    : { enableLegacyTransformBuilder: true })
});

export const makeWorkPlansConfig = ({
  requiredPortfolioValidator,
  portfolioOptionsGetter,
  projectValidator,
  projectNumberValidator,
  phaseValidator,
  phaseOptionsGetter,
  activityValidator,
  activityOptionsGetter,
  emailValidator,
  startDateValidator,
  endDateValidator,
  totalHoursValidator,
  hoursPerDayValidator,
  teamId,
  sessionId,
  importInitiatedAt,
  myId
}: ConfigParams) => ({
  schema: {
    fields: [
      {
        name: 'email',
        displayName: 'Employee Email',
        description: 'The email address for the employee on Mosaic',
        validator: emailValidator
      },
      {
        name: 'portfolio',
        displayName: 'Portfolio',
        description: 'Portfolio Name',
        validator: requiredPortfolioValidator,
        validOptions: portfolioOptionsGetter
      },
      {
        name: 'project_name',
        displayName: 'Project',
        description: 'Project Title',
        validator: projectValidator
      },
      {
        name: 'project_number',
        displayName: 'Project Number',
        description: 'Project Number',
        validator: projectNumberValidator
      },
      {
        name: 'phase',
        displayName: 'Phase',
        description: 'Name of Phase of Work',
        validator: phaseValidator,
        validOptions: phaseOptionsGetter
      },
      {
        name: 'phase_number',
        displayName: 'Phase Number',
        description: 'Phase Number'
      },
      {
        name: 'work_category',
        displayName: 'Work Category',
        description: 'Name of Work Category',
        validator: activityValidator,
        validOptions: activityOptionsGetter,
        allowCustomOptions: true
      },
      {
        name: 'start',
        displayName: 'Start Date',
        description: 'Start Date',
        validator: startDateValidator
      },
      {
        name: 'end',
        displayName: 'End Date',
        description: 'End Date',
        validator: endDateValidator
      },
      {
        name: 'total_hours',
        displayName: 'Total Hours',
        description: 'Total Hours',
        validator: totalHoursValidator
      },
      {
        name: 'hours_per_day',
        displayName: 'Hours Per Day',
        description: 'Hours Per Day',
        validator: hoursPerDayValidator
      },
      {
        name: 'description',
        displayName: 'Description',
        description: 'Work plan Description'
      },
      {
        name: 'include_off_days',
        displayName: 'Include Off Days',
        description: 'Does the work plan include off days?'
      },
      {
        name: 'team_id',
        fieldType: 'constantValue',
        constantValue: teamId
      },
      {
        name: 'session_id',
        fieldType: 'constantValue',
        constantValue: sessionId
      },
      {
        name: 'import_initiated_at',
        fieldType: 'constantValue',
        constantValue: importInitiatedAt
      },
      {
        name: 'import_account_id',
        fieldType: 'constantValue',
        constantValue: myId
      }
    ]
  },
  token: usableImportTokens[CsvImportEntity.WorkPlan],
  uploadDescription: '',
  hideUploadDescription: true,
  enableTransformBuilder2: true
});

export const makeMembersConfig = ({
  memberImportEmailValidator,
  firstNameValidator,
  lastNameValidator,
  accessLevelValidator,
  accessLevelOptionsGetter,
  roleValidator,
  roleOptionsGetter,
  rateAmountValidator,
  rateAmountOptionsGetter,
  rateDescriptionValidator,
  rateDescriptionOptionsGetter,
  ptoHoursValidator,
  ptoHoursOptionsGetter,
  ptoDescriptionValidator,
  ptoDescriptionOptionsGetter,
  disciplineValidator,
  disciplineOptionsGetter,
  officeValidator,
  officeOptionsGetter,
  regionValidator,
  regionOptionsGetter,
  currencyValidator,
  currencyOptionsGetter,
  departmentValidator,
  departmentOptionsGetter,
  skillsValidator,
  teamId,
  sessionId,
  importInitiatedAt,
  myId,
  shouldUseV2
}: ConfigParams) => ({
  schema: {
    fields: [
      {
        name: 'email',
        displayName: 'email',
        description: "Member's email address",
        validator: memberImportEmailValidator
      },
      {
        name: 'first_name',
        displayName: 'First Name',
        description: "Member's first name",
        validator: firstNameValidator
      },
      {
        name: 'last_name',
        displayName: 'Last Name',
        description: "Member's last name",
        validator: lastNameValidator
      },
      {
        name: 'skip_invite',
        displayName: 'Skip Invite',
        description: 'Should this member receive an invitation to Mosaic email?'
      },
      {
        name: 'access_level',
        displayName: 'Employment Type',
        description: "Member's Employment Type",
        validator: accessLevelValidator,
        validOptions: accessLevelOptionsGetter
      },
      {
        name: 'role',
        displayName: 'Role',
        description: "Member's role",
        validator: roleValidator,
        validOptions: roleOptionsGetter
      },
      {
        name: 'rate_amount',
        displayName: 'Rate Amount',
        description: 'Rate Amount',
        validator: rateAmountValidator,
        validOptions: rateAmountOptionsGetter
      },
      {
        name: 'rate_description',
        displayName: 'Rate Description',
        description: 'Rate Description',
        validator: rateDescriptionValidator,
        validOptions: rateDescriptionOptionsGetter
      },
      {
        name: 'pto_hours',
        displayName: 'PTO Hours',
        description: 'Amount of pto hours for this member',
        validator: ptoHoursValidator,
        validOptions: ptoHoursOptionsGetter
      },
      {
        name: 'pto_description',
        displayName: 'PTO Description',
        description: 'PTO Description',
        validator: ptoDescriptionValidator,
        validOptions: ptoDescriptionOptionsGetter
      },
      {
        name: 'discipline',
        displayName: 'Discipline',
        description: "Member's discipline",
        validator: disciplineValidator,
        validOptions: disciplineOptionsGetter
      },
      {
        name: 'office',
        displayName: 'Office',
        description: "Member's office",
        validator: officeValidator,
        validOptions: officeOptionsGetter,
        allowCustomOptions: true
      },
      {
        name: 'region',
        displayName: 'Region',
        description: "Member's region",
        validator: regionValidator,
        validOptions: regionOptionsGetter
      },
      {
        name: 'team_id',
        fieldType: 'constantValue',
        constantValue: teamId
      },
      {
        name: 'session_id',
        fieldType: 'constantValue',
        constantValue: sessionId
      },
      {
        name: 'import_initiated_at',
        fieldType: 'constantValue',
        constantValue: importInitiatedAt
      },
      {
        name: 'import_account_id',
        fieldType: 'constantValue',
        constantValue: myId
      },
      {
        name: 'currency',
        displayName: 'Currency',
        description: "Member's currency",
        fieldType: 'constantValue',
        constantValue: '',
        validator: currencyValidator,
        validOptions: currencyOptionsGetter
      },
      {
        name: 'department',
        displayName: 'Department',
        description: "Member's department",
        validator: departmentValidator,
        validOptions: departmentOptionsGetter
      },
      {
        name: 'skills',
        displayName: 'Skills',
        description: "Member's skills",
        validator: skillsValidator
      }
    ]
  },
  token: usableImportTokens[CsvImportEntity.Member],
  uploadDescription: '',
  hideUploadDescription: true,
  ...(shouldUseV2
    ? { enableTransformBuilder2: true }
    : { enableLegacyTransformBuilder: true })
});

export const makeProjectPhaseActivityPhaseConfig = ({
  requiredPortfolioValidator,
  portfolioOptionsGetter,
  projectValidator,
  projectNumberValidator,
  phaseValidator,
  phaseOptionsGetter,
  projectClientValidator,
  projectClientOptionsGetter,
  projectRegionValidator,
  projectRegionOptionsGetter,
  projectOfficeValidator,
  projectOfficeOptionsGetter,
  projectDisciplineValidator,
  projectDisciplineOptionsGetter,
  projectBudgetStatusValidator,
  projectBudgetStatusOptionsGetter,
  projectPriorityValidator,
  projectPriorityOptionsGetter,
  phaseStartDateValidator,
  phaseEndDateValidator,
  phaseBudgetStatusValidator,
  phaseBudgetStatusOptionsGetter,
  phaseFeeValidator,
  phaseFeeOptionsGetter,
  phaseTargetValidator,
  phaseTargetOptionsGetter,
  phaseContractTypeValidator,
  phaseContractTypeOptionsGetter,
  activityValidator,
  activityOptionsGetter,
  activityStartDateValidator,
  activityEndDateValidator,
  activityFeeValidator,
  activityFeeOptionsGetter,
  activityTargetValidator,
  activityTargetOptionsGetter,
  teamId,
  sessionId,
  importInitiatedAt,
  myId,
  shouldUseV2,
  selectedGroupName
}: ConfigParams) => ({
  schema: {
    fields: [
      {
        name: 'portfolio_name',
        displayName: 'Portfolio Name',
        description: 'Portfolio Name',
        fieldType: 'constantValue',
        constantValue: selectedGroupName,
        validator: requiredPortfolioValidator,
        validOptions: portfolioOptionsGetter
      },
      {
        name: 'project_title',
        displayName: 'Project Title',
        description: 'Project Title',
        validator: projectValidator
      },
      {
        name: 'project_number',
        displayName: 'Project Number',
        description: 'Project Number',
        validator: projectNumberValidator
      },
      {
        name: 'project_client',
        displayName: 'Project Client',
        description: "Project's client",
        validator: projectClientValidator,
        validOptions: projectClientOptionsGetter
      },
      {
        name: 'project_billable',
        displayName: 'Project Billable',
        description: 'Is this project billable?'
      },
      {
        name: 'project_budget_status',
        displayName: 'Project Budget Status',
        description: "Project's budget status",
        validator: projectBudgetStatusValidator,
        validOptions: projectBudgetStatusOptionsGetter
      },
      {
        name: 'project_priority',
        displayName: 'Project Priority',
        description: "Project's priority",
        validator: projectPriorityValidator,
        validOptions: projectPriorityOptionsGetter
      },
      {
        name: 'project_region',
        displayName: 'Project Region',
        description: "Project's region",
        validator: projectRegionValidator,
        validOptions: projectRegionOptionsGetter
      },
      {
        name: 'project_office',
        displayName: 'Project Office',
        description: "Project's office",
        validator: projectOfficeValidator,
        validOptions: projectOfficeOptionsGetter
      },
      {
        name: 'project_discipline',
        displayName: 'Project Discipline',
        description: "Project's discipline",
        validator: projectDisciplineValidator,
        validOptions: projectDisciplineOptionsGetter
      },
      {
        name: 'phase_name',
        displayName: 'Phase Name',
        description: 'Name of Phase of Work',
        validator: phaseValidator,
        validOptions: phaseOptionsGetter
      },
      {
        name: 'phase_number',
        displayName: 'Phase Number',
        description: 'Phase number'
      },
      {
        name: 'phase_start_date',
        displayName: 'Phase Start Date',
        description: 'Phase start date',
        validator: phaseStartDateValidator
      },
      {
        name: 'phase_end_date',
        displayName: 'Phase End Date',
        description: 'Phase end date',
        validator: phaseEndDateValidator
      },
      {
        name: 'phase_budget_status',
        displayName: 'Phase Budget Status',
        description: "Phase's budget status",
        validator: phaseBudgetStatusValidator,
        validOptions: phaseBudgetStatusOptionsGetter
      },
      {
        name: 'phase_fee',
        displayName: 'Phase Fee',
        description: "Phase's fee",
        validator: phaseFeeValidator,
        validOptions: phaseFeeOptionsGetter
      },
      {
        name: 'phase_target',
        displayName: 'Phase Budget',
        description: "Phase's budget",
        validator: phaseTargetValidator,
        validOptions: phaseTargetOptionsGetter
      },
      {
        name: 'phase_contract_type',
        displayName: 'Phase Contract Type',
        description: "Phase's contract type",
        validator: phaseContractTypeValidator,
        validOptions: phaseContractTypeOptionsGetter
      },
      {
        name: 'phase_billable',
        displayName: 'Phase Billable',
        description: 'Is this phase billable?'
      },
      {
        name: 'phase_is_milestone',
        displayName: 'Phase Is Milestone',
        description: 'Is this a milestone?'
      },
      {
        name: 'activity_phase_title',
        displayName: 'Work Category Title',
        description: 'Name of Work Category',
        validator: activityValidator,
        validOptions: activityOptionsGetter
      },
      {
        name: 'activity_phase_start_date',
        displayName: 'Work Category Start Date',
        description: 'Work Category start date',
        validator: activityStartDateValidator
      },
      {
        name: 'activity_phase_end_date',
        displayName: 'Work Category End Date',
        description: 'Work Category end date',
        validator: activityEndDateValidator
      },
      {
        name: 'activity_phase_billable',
        displayName: 'Work Category Billable',
        description: 'Is this Work Category billable?'
      },
      {
        name: 'activity_phase_fee',
        displayName: 'Work Category Fee',
        description: "Work Category's Fee",
        validator: activityFeeValidator,
        validOptions: activityFeeOptionsGetter
      },
      {
        name: 'activity_phase_target',
        displayName: 'Work Category Budget',
        description: "Work Category's Budget",
        validator: activityTargetValidator,
        validOptions: activityTargetOptionsGetter
      },
      {
        name: 'team_id',
        fieldType: 'constantValue',
        constantValue: teamId
      },
      {
        name: 'session_id',
        fieldType: 'constantValue',
        constantValue: sessionId
      },
      {
        name: 'import_initiated_at',
        fieldType: 'constantValue',
        constantValue: importInitiatedAt
      },
      {
        name: 'import_account_id',
        fieldType: 'constantValue',
        constantValue: myId
      },
      {
        name: 'activity_phase_number',
        displayName: 'Work Category Number',
        description: 'Work Category Number',
        fieldType: 'constantValue',
        constantValue: ''
      }
    ]
  },
  token: usableImportTokens[CsvImportEntity.ProjectPhaseActivityPhase],
  uploadDescription: '',
  hideUploadDescription: true,
  ...(shouldUseV2
    ? { enableTransformBuilder2: true }
    : { enableLegacyTransformBuilder: true })
});

export const makeTaskScopeConfig = ({
  emailValidator,
  taskScopeDescriptionValidator,
  startDateValidator,
  endDateValidator,
  taskPriorityValidator,
  taskPriorityOptionsGetter,
  taskStatusValidator,
  taskStatusOptionsGetter,
  requiredPortfolioValidator,
  portfolioOptionsGetter,
  projectValidator,
  projectNumberValidator,
  phaseValidator,
  phaseOptionsGetter,
  activityValidator,
  activityOptionsGetter,
  teamId,
  sessionId,
  importInitiatedAt,
  myId,
  shouldUseV2
}: ConfigParams) => ({
  schema: {
    fields: [
      {
        name: 'email',
        displayName: 'employee email',
        description: 'The email address for the employee on Mosaic',
        validator: emailValidator
      },
      {
        name: 'is_scope',
        displayName: 'is_scope',
        description: 'Are you importing scope instead of task?'
      },
      {
        name: 'description',
        displayName: 'description',
        description: 'Description of your task/scope',
        validator: taskScopeDescriptionValidator
      },
      {
        name: 'notes',
        displayName: 'notes',
        description: 'Notes for your task/scope'
      },
      {
        name: 'start_date',
        displayName: 'start date',
        description: 'Planned Start Date of your task/scope',
        validator: startDateValidator
      },
      {
        name: 'end_date',
        displayName: 'end date',
        description: 'Planned End Date of your task/scope',
        validator: endDateValidator
      },
      {
        name: 'priority',
        displayName: 'priority',
        description: 'Task priority',
        validator: taskPriorityValidator,
        validOptions: taskPriorityOptionsGetter
      },
      {
        name: 'time_estimate',
        displayName: 'time_estimate',
        description: 'Task time estimate'
      },
      {
        name: 'status',
        displayName: 'status',
        description: 'Task status',
        validator: taskStatusValidator,
        validOptions: taskStatusOptionsGetter
      },
      {
        name: 'portfolio_name',
        displayName: 'portfolio_name',
        description: 'Portfolio Name',
        validator: requiredPortfolioValidator,
        validOptions: portfolioOptionsGetter
      },
      {
        name: 'project_title',
        displayName: 'project_title',
        description: 'Project Title',
        validator: projectValidator
      },
      {
        name: 'project_number',
        displayName: 'project_number',
        description: 'Project Number',
        validator: projectNumberValidator
      },
      {
        name: 'phase_name',
        displayName: 'phase_name',
        description: 'Name of Phase of Work',
        validator: phaseValidator,
        validOptions: phaseOptionsGetter
      },
      {
        name: 'phase_number',
        displayName: 'phase_number',
        description: 'Phase number'
      },
      {
        name: 'activity_phase_title',
        displayName: 'activity_phase_title',
        description: 'Name of Work Category',
        validator: activityValidator,
        validOptions: activityOptionsGetter
      },
      {
        name: 'team_id',
        fieldType: 'constantValue',
        constantValue: teamId
      },
      {
        name: 'session_id',
        fieldType: 'constantValue',
        constantValue: sessionId
      },
      {
        name: 'import_initiated_at',
        fieldType: 'constantValue',
        constantValue: importInitiatedAt
      },
      {
        name: 'import_account_id',
        fieldType: 'constantValue',
        constantValue: myId
      }
    ]
  },
  token: usableImportTokens[CsvImportEntity.TaskScope],
  uploadDescription: '',
  hideUploadDescription: true,
  ...(shouldUseV2
    ? { enableTransformBuilder2: true }
    : { enableLegacyTransformBuilder: true })
});

export const configuratorsByEntity = {
  [CsvImportEntity.Timesheet]: makeTimeEntriesConfig,
  [CsvImportEntity.WorkPlan]: makeWorkPlansConfig,
  [CsvImportEntity.Member]: makeMembersConfig,
  [CsvImportEntity.ProjectPhaseActivityPhase]:
    makeProjectPhaseActivityPhaseConfig,
  [CsvImportEntity.TaskScope]: makeTaskScopeConfig
};
