import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { LIST_ITEM_TYPES } from 'SuggestionModule/components/FindPeople/List/constants';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import {
  ProjectMembershipRequirementListItem,
  ProjectMembershipRequirementListItemDataObject,
  AddProjectMembershipRequirementListItem
} from '../types';
import { ProjectUnassignedMemberBudgetWithPosition } from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { FindPeopleModalContextValuesType } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';

const useProjectMembershipRequirementsItemsBuilder = ({
  getIsOpen,
  toggleCollapse,
  getCurrentParentCollapseState,
  setParentCollapseState
}: UseNestedCollapseHookReturnedValues) => {
  const projectMembershipRequirementsItemsBuilder = ({
    isParentOpen,
    parentId,
    order,
    projectMembership,
    memberBudget,
    isFetchingEntityRequirements,
    onAddRequirementsClick
  }: {
    isParentOpen?: boolean;
    parentId: string;
    order: ProjectMembershipRequirementListItemDataObject[] | never[];
    projectMembership: ProjectUnassignedMemberBudgetWithPosition['project_membership'];
    memberBudget: ProjectUnassignedMemberBudgetWithPosition;
    isFetchingEntityRequirements: FindPeopleModalContextValuesType['isFetchingEntityRequirements'];
    onAddRequirementsClick: AddProjectMembershipRequirementListItem['onAddRequirementsClick'];
  }) => {
    const list: ProjectMembershipRequirementListItem[] = [];

    /* ----------------------------- Requirements ---------------------------- */
    order.forEach(
      (
        requirementDataObject: ProjectMembershipRequirementListItemDataObject
      ) => {
        const projectMembershipRequirementListItem: ProjectMembershipRequirementListItem =
          {
            id: requirementDataObject.serializedId,
            memberBudget,
            projectMembership,
            requirement: requirementDataObject,
            itemType: LIST_ITEM_TYPES.ProjectMembershipRequirement,
            toggleId: undefined,
            isOpen: true,
            isParentOpen,
            containerClassName:
              'project-membership-requirement-list-item-container open',
            toggleCollapse: undefined,
            onAddRequirementsClick
          };

        list.push(projectMembershipRequirementListItem);
      }
    );

    return list;
  };

  return projectMembershipRequirementsItemsBuilder;
};

export default useProjectMembershipRequirementsItemsBuilder;
