import { useContext } from 'react';
import { useAppDispatch } from 'reduxInfra/hooks';
import styled from 'styled-components';
import theme from 'theme';
import ProjectsThenPhasesSelector from 'views/projectPlanner/workloadBarModal/ProjectsThenPhasesSelector';
import UnassignedRolesSelectorForProject from 'components/roles/dropdowns/UnassignedRolesDropdown/UnassignedRolesSelectorForProject';
import UnassignedRolesList from 'SuggestionModule/components/FindPeople/List/UnassignedRoles/UnassignedRolesListContainer';
import { FindPeopleRequirementsSelector } from './FindPeopleRequirementsSelector';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import ReactTooltip from 'react-tooltip';
import { openRemoveMemberModal } from 'BudgetModule/actionCreators';
import { ProjectUnassignedMemberBudgetWithPosition } from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { makeEntitiesArrayForEntityRequirementFetch } from 'RequirementsModule/utils';
import { ENTITY_REQUIREMENT_TYPES } from 'RequirementsModule/constants';
import { fetchEntityRequirements } from 'RequirementsModule/actionCreators';

const getItemHeight = (item) => {
  return 55; // Only project
};

const FindPeopleFilter = () => {
  const {
    handleSelectUnassignedRoleFromDropdown,
    toggleRequirementsSelector,
    numOfProjectUnassignedRoles,
    selectedProjectId,
    selectedUnassignedMemberBudgetId,
    isRequirementsSelectorOpen,
    isFetchingEntityRequirements,
    isFetchingPhaseTotals,
    teamId,
    resetUnassignedRoleAndSelectedPhaseIds,
    toggleIsProjectSelectorOpen
  } = useContext(FindPeopleModalContext);

  const dispatch = useAppDispatch();

  const handleDeleteExistingUnassignedRoles = (
    e: unknown,
    {
      item,
      closeBulkUnassignedRoleAddDropdown
    }: {
      item: ProjectUnassignedMemberBudgetWithPosition;
      closeBulkUnassignedRoleAddDropdown: () => void;
    }
  ) => {
    dispatch(
      openRemoveMemberModal({
        memberBudget: {
          ...item,
          isUnassigned: true,
          onRemoveUnassignedRoleSuccess: [
            {
              successAction: (_) => {
                if (item.id === selectedUnassignedMemberBudgetId) {
                  resetUnassignedRoleAndSelectedPhaseIds();
                }
              },
              selector: (_payload: unknown, _response: unknown) => ({
                _payload,
                _response
              })
            }
          ]
        }
      })
    );
    ReactTooltip.hide();
    closeBulkUnassignedRoleAddDropdown();
  };

  const createMemberBudgetOnSuccess = ({ response }) => {
    if (response?.member_budgets) {
      dispatch(
        fetchEntityRequirements({
          team_id: teamId as number,
          entities: makeEntitiesArrayForEntityRequirementFetch(
            response?.member_budgets,
            ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP
          )
        })
      );
    }
  };

  const isLoading = isFetchingEntityRequirements || isFetchingPhaseTotals;

  return (
    <FindPeopleFilterContainer>
      {/* Reusing this component for rendering purposes only. The dropdown isn't used */}
      <ProjectsThenPhasesSelector
        projectId={selectedProjectId}
        pickerLocation={`find-people-${selectedProjectId}`}
        handleSelect={undefined}
        showActivities={false}
        hideActivity
        shouldSelectProjectOnly
        phaseAndActivitySelectionDisabled
        hidePTO
        popoverClassName={'find-people-project-then-phases-dropdown'}
        showSelectIcon
        getItemHeight={getItemHeight}
        onClickOverride={toggleIsProjectSelectorOpen}
      />

      {selectedProjectId && (
        <Section className="unassigned-roles-list-section">
          <SectionHeader>
            UNASSIGNED{' '}
            {numOfProjectUnassignedRoles
              ? `| ${numOfProjectUnassignedRoles}`
              : ''}{' '}
          </SectionHeader>
          <UnassignedRolesSelectorForProject
            projectId={selectedProjectId}
            unassignedMemberBudgetId={null}
            onExistingUnassignedRoleSelect={undefined}
            combineExistingAndAddableUnassignedRoles
            customHandleDeleteExistingUnassignedRoles={
              handleDeleteExistingUnassignedRoles
            }
            createMemberBudgetOnSuccess={createMemberBudgetOnSuccess}
            isLoading={isLoading}
          />
          <UnassignedRolesList isLoading={isLoading} />
        </Section>
      )}
      {selectedProjectId && (
        <Section className="requirements-selector-section">
          <FindPeopleRequirementsSelector
            isOpen={isRequirementsSelectorOpen}
            toggleIsOpen={toggleRequirementsSelector}
          />
        </Section>
      )}
    </FindPeopleFilterContainer>
  );
};

export default FindPeopleFilter;

const FindPeopleFilterContainer = styled.div``;

const SectionHeader = styled.div`
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  color: ${theme.colors.colorLightGray15};
`;

const Section = styled.div`
  margin: 1rem 0px;

  &.unassigned-roles-list-section {
    margin-left: 28px;

    ${SectionHeader} {
      margin: 5px 0 15px 0px;
    }
  }

  &.phase-selector-section {
    cursor: pointer;
    color: ${theme.colors.colorCalendarBlue};

    path {
      fill: ${theme.colors.colorCalendarBlue};
    }

    &:hover {
      font-weight: 600;
    }
  }
`;
