import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import cn from 'classnames';
import theme from 'theme';
import { getMe, makeGetTeamMembershipByAccountId } from 'selectors';
import HelpIcon from 'icons/HelpIcon';
import QBDownArrow from 'icons/QBDownArrow';
import { BarBudgetContainer } from './BarBudgetContainer';
import { useWorkPlanForm } from '../WorkPlanFormProvider';
import MemberSelector, {
  StyledSelectContainer
} from '../../workloadBarModal/MemberSelector';
import { StyledDownArrow } from '../../workloadBarModal/styles';
import MemberInitials from 'views/memberDisplay/MemberInitials';
import { TaskAssigneeCircle } from 'components/TaskAssigneeDropdown/styles';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import useMemberBudget from 'appUtils/hooks/budget/useMemberBudget';
import { WorkPlan } from '../models/workPlan';
import { EDIT_WORK_PLANS_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';

export const MemberFields = () => {
  const {
    form: { workplan, initialWorkplan, isRequest, showErrors, hasError },
    member: { budgetTotals, isMemberArchived, onSelectMember },
    permissions: { canEditWorkplan }
  } = useWorkPlanForm();

  const { project_id, phase_id, activity_id, account_id, member_budget_id } =
    workplan;

  const me = useSelector(getMe);
  const getTeamMembership = useMemo(
    () => makeGetTeamMembershipByAccountId(),
    []
  );
  const selectedMember = useSelector((state) =>
    getTeamMembership(state, {
      accountId: account_id
    })
  );

  const { memberBudget: selectedMemberBudget, name } = useMemberBudget({
    memberBudgetId: member_budget_id,
    projectId: project_id,
    memberBudget: undefined
  });

  useEffect(() => {
    if (isMemberArchived) {
      rebuildTooltip();
    }
  }, [isMemberArchived]);

  const renderMember = ({ onClick }) => {
    const hourDiff = getTotalHoursDiff({ workplan, initialWorkplan });

    const shouldUseMemberBudget = selectedMemberBudget && !selectedMember;

    const idToUse = shouldUseMemberBudget
      ? selectedMemberBudget?.id
      : selectedMember.account.id;

    const nameToDisplay = shouldUseMemberBudget
      ? name
      : selectedMember.account.name;

    return (
      <MemberContainer
        onClick={onClick}
        data-for="app-tooltip"
        data-tip={
          isRequest
            ? "You can't change assignee when<br />you are approving Work Plan Request."
            : !canEditWorkplan
            ? EDIT_WORK_PLANS_TIP
            : ''
        }
        data-effect="solid"
        data-html
        data-class="center"
      >
        {shouldUseMemberBudget ? (
          <TaskAssigneeCircle style={{ marginLeft: 0 }} />
        ) : (
          <MemberInitials
            member={selectedMember}
            classes={cn('regular-member-no-hover selected', {
              'logged-member-no-hover': idToUse === me.id
            })}
            idx={'idx-placeholder'}
            isOnTaskModal
          />
        )}
        <MemberInfoContainer>
          <MemberNameContainer>
            <MemberName className="styled-member-name">
              {nameToDisplay}
            </MemberName>
            {canEditWorkplan && (
              <StyledDownArrow className="styled-down-arrow">
                <QBDownArrow />
              </StyledDownArrow>
            )}
          </MemberNameContainer>
          {isMemberArchived && (
            <ArchivedText>
              Phase Member Archived{' '}
              <div
                data-tip="Member is archived in this phase"
                data-for="app-tooltip"
                data-effect="solid"
                data-html
              >
                <HelpIcon
                  circleColor={theme.colors.colorCalendarRed}
                  questionMarkColor={theme.colors.colorCalendarRed}
                  fillColor="transparent"
                  width={'15'}
                />
              </div>
            </ArchivedText>
          )}
          <BarBudgetContainer budgetTotals={budgetTotals} hourDiff={hourDiff} />
        </MemberInfoContainer>
      </MemberContainer>
    );
  };

  return (
    <MemberFieldsContainer>
      <MemberSelector
        skipSelectProjectMembership
        projectId={project_id}
        accountId={account_id}
        phaseId={phase_id}
        activityId={activity_id}
        bar={workplan}
        initialBar={workplan}
        showBudgetInfo
        memberBudgetId={member_budget_id}
        handleSelect={onSelectMember}
        includedUnassignedMemberBudgets={project_id} // can't show member budgets if project is not selected
        renderMember={renderMember}
        popoverClassName={'workplan-form-member-selector'}
        noHeader
        isOnWorkplanModal
        itemHeight={49}
        selectionDisabled={isRequest || !canEditWorkplan} // users without permission can't reassign (incl their own) work plans
        disabledTooltip="You don't have permissions to reassign Work Plans. <br/> Contact the Work Plan creator shown in activity <br/> or Admins to delete"
        showError={
          showErrors &&
          hasError([
            'missing_account_id_or_member_budget_id',
            'selected_archived_phase_member'
          ])
        }
      />
    </MemberFieldsContainer>
  );
};

const MemberFieldsContainer = styled.div`
  ${StyledSelectContainer} {
    padding: 12px 16px;
  }
`;

const MemberContainer = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  box-shadow: none;
`;

const MemberInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MemberNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MemberName = styled.div`
  color: ${({ theme }) => theme.colors.colorMediumGray7};
  font-size: 16px;
  font-weight: 600;
`;

const ArchivedText = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.colorMediumGray5};
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const getTotalHoursDiff = ({
  workplan,
  initialWorkplan
}: {
  workplan: WorkPlan;
  initialWorkplan: WorkPlan;
}) => {
  const initialHours =
    !initialWorkplan || workplan.phase_id !== initialWorkplan.phase_id
      ? 0
      : +initialWorkplan.total_hours;
  const currentHours = +workplan.total_hours || 0;
  const hourDiff = currentHours - initialHours;
  return hourDiff || 0;
};
