import { useState, useCallback, lazy, Suspense } from 'react';

const DefaultMergeBudgetModal = lazy(
  () => import('BudgetModule/components/MergeBudgetModal/MergeBudgetModal')
);

interface AssignMemberBudgetParams {
  assignedName: string;
  unassignedMemberBudgetId: Nullable<number>;
  projectId: Nullable<number>;
  // it should be totalEstimatedHours since unassigned role can be added multiple phases/work categories
  totalEstimatedHours: number;
  totalPlannedHours: number;
  assignedAccountId?: number;
  assignedProjectMembershipId?: number;
  onAssignMemberBudgetSuccess?: {
    successAction: (args: any) => any;
    selector: (payload: any, response: any) => any;
  }[];
}

const defaultParams: AssignMemberBudgetParams = {
  assignedName: '',
  unassignedMemberBudgetId: null,
  projectId: null,
  totalEstimatedHours: 0,
  totalPlannedHours: 0,
  assignedAccountId: undefined,
  assignedProjectMembershipId: undefined,
  onAssignMemberBudgetSuccess: []
};

export const useMergeBudgetModal = () => {
  const [isMergeBudgetModalOpen, setIsMergeBudgetModalOpen] = useState(false);
  const [assignMemberBudgetParams, setAssignMemberBudgetParams] =
    useState(defaultParams);

  const handleCloseMergeBudgetModal = useCallback(() => {
    setIsMergeBudgetModalOpen(false);
    setAssignMemberBudgetParams(defaultParams);
  }, []);

  const handleOpenMergeBudgetModal = useCallback(
    (assignMemberBudgetParams: AssignMemberBudgetParams) => {
      setAssignMemberBudgetParams(assignMemberBudgetParams);
      setIsMergeBudgetModalOpen(true);
    },
    []
  );

  const MergeBudgetModal = useCallback(
    () =>
      isMergeBudgetModalOpen ? (
        <Suspense fallback={<div />}>
          <DefaultMergeBudgetModal
            assignMemberBudgetParams={assignMemberBudgetParams}
            handleClose={handleCloseMergeBudgetModal}
          />
        </Suspense>
      ) : null,
    [
      assignMemberBudgetParams,
      handleCloseMergeBudgetModal,
      isMergeBudgetModalOpen
    ]
  );

  return {
    isMergeBudgetModalOpen,
    handleCloseMergeBudgetModal,
    handleOpenMergeBudgetModal,
    assignMemberBudgetParamsState: assignMemberBudgetParams,
    MergeBudgetModal
  };
};
