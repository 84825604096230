import styled from 'styled-components';
import theme from 'theme';

interface SwitchProps {
  isChecked: boolean;
  checkedText?: string;
  uncheckedText?: string;
  onChange: () => void;
}

export const Switch = ({
  isChecked,
  checkedText = 'On',
  uncheckedText = 'Off',
  onChange
}: SwitchProps) => {
  return (
    <RootContainer>
      <ContentContainer $isActive={isChecked} onClick={onChange}>
        <TextContainer>{checkedText}</TextContainer>
      </ContentContainer>
      <ContentContainer $isActive={!isChecked} onClick={onChange}>
        <TextContainer>{uncheckedText}</TextContainer>
      </ContentContainer>
    </RootContainer>
  );
};

const RootContainer = styled.span`
  display: inline-flex;
  border-radius: 10px;
  border: 1px solid ${theme.colors.colorLightGray6};
  max-width: fit-content;
  height: 20px;
  cursor: pointer;
`;

interface ContentContainerProps {
  $isActive: boolean;
}

const ContentContainer = styled.div.attrs<ContentContainerProps>((props) => ({
  style: {
    backgroundColor: props.$isActive
      ? theme.colors.colorQbTooltipGreen
      : undefined,
    color: props.$isActive ? 'white' : theme.colors.colorCalendarGray
  }
}))<ContentContainerProps>`
  border-radius: 10px;
  padding: 2px 6px;
`;

const TextContainer = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
`;
