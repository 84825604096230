import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import {
  SuggestionState,
  AccountsSuggestionForPhasesInstance
} from 'SuggestionModule/types';
import {
  getSuggestedAccountsForProjectsState,
  getSuggestedAccountsForPhasesState
} from './shared';

const emptyObj = {};

const getOwnIsoStateId = (
  _,
  ownProps: {
    isoStateId: string | number;
  }
): string | number => ownProps.isoStateId;

export const makeGetSuggestedAccountsForProjectsByIsoStateId = () =>
  createSelector(
    getSuggestedAccountsForProjectsState,
    getOwnIsoStateId,
    (suggestions, isoStateId) => {
      return suggestions[isoStateId] || emptyObj;
    }
  );

export const makeGetSuggestedAccountsForPhasesByIsoStateId = () =>
  createSelector<
    [
      Selector<RootState, SuggestionState['suggestedAccountsForPhases']>,
      typeof getOwnIsoStateId
    ],
    Record<number, AccountsSuggestionForPhasesInstance>
  >(
    getSuggestedAccountsForPhasesState,
    getOwnIsoStateId,
    (suggestions, isoStateId) => {
      return suggestions[isoStateId] || emptyObj;
    }
  );
