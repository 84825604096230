import { useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

export const Tag = ({ id, onClick, dataTip, name, type }) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <TagContainer
      id={`${id}-${type}`}
      data-for="app-tooltip"
      data-effect="solid"
      data-class="mw-40vw word-break-all"
      data-tip={dataTip}
      onClick={onClick}
      className="tag-container"
    >
      <span className="tag-name">{name}</span>
    </TagContainer>
  );
};

const TagContainer = styled.span`
  white-space: nowrap;
  max-width: 85%;
  font-size: 12px;
  padding: 0 5px;
  border-radius: 5px;
  background-color: ${theme.colors.colorLightGray7};
  cursor: pointer;

  .tag-name {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 4px;
  }

  &:hover {
    color: ${theme.colors.colorCalendarBlue};
  }
`;
