import theme from 'theme';
import styled from 'styled-components';
import KaratRight from 'icons/KaratRight';
import { PhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';

const CollapseCell = ({ row }: PhaseGroupRowProps) => {
  const { isOpen, toggleCollapse } = row.original;

  return (
    <StyledKaratContainer
      isOpen={isOpen || false}
      className="app-cues-styled-karat"
      onClick={toggleCollapse}
    >
      <KaratRight />
    </StyledKaratContainer>
  );
};

export default CollapseCell;

const StyledKaratContainer = styled.div<{
  isOpen: boolean;
}>`
  height: 24px;
  width: 24px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s ease-in-out;
  position: relative;
  left: 10px;
  top: 34px;
  path {
    stroke-width: 0.5;
    stroke: ${theme.colors.colorMediumGray9};
  }
  &:hover {
    background-color: ${theme.colors.colorPaleGray5};
    path {
      stroke: ${theme.colors.colorCalendarBlue};
      fill: ${theme.colors.colorCalendarBlue};
    }
  }
  transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};
`;
