import { Page } from 'models/filter';
import { FilterLevelSchemas, CurrentFilter } from 'FilterModule/types';
import {
  makeDefaultProjectCrossFiltersFieldSchemas,
  ProjectsCrossFilterFieldSchemas,
  defaultProjectCrossFieldFilterListTypes
} from 'FilterModule/filterSchemas/commonFieldSchemas';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { serializeFilterLevelName } from 'FilterModule/filterSchemas/utils';
import { FilterListType } from 'FilterModule/constants';

// NOTE: this page does not actually use filters. Using filter schema to be able to reuse existing filter components,
// but these will not interact with real AccountFilters

export const filterLevelName = serializeFilterLevelName({
  level1: Page.FindPeople,
  level2: 'projects' // 'projects' due to old filter components doing things based on viewBy === 'projects'
});

type FindPeopleFieldSchemas = ProjectsCrossFilterFieldSchemas;

const fieldSchemas: FindPeopleFieldSchemas =
  makeDefaultProjectCrossFiltersFieldSchemas({
    stackedFilterOrder: (() => {
      // default cross filters but with unassigned filter at top
      const order = defaultProjectCrossFieldFilterListTypes.filter(
        (item) => item !== FilterListType.ShowProjectsWithUnassignedRoles
      );
      order.unshift(FilterListType.ShowProjectsWithUnassignedRoles);
      return order;
    })()
  });

/* ------------------------ initialFilterLevelSchemas ----------------------- */

export const initialFilterLevelSchemas: FilterLevelSchemas = {
  [filterLevelName]: {
    fieldSchemas
  }
};

/* ---------------------------- CurrentFilterType --------------------------- */

export type FindPeopleCurrentFilterType = CurrentFilter<FindPeopleFieldSchemas>;

export const useFindPeopleFilterContext = () =>
  useFilterContext<FindPeopleCurrentFilterType>();
