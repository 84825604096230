import cn from 'classnames';
import { useEffect } from 'react';
import { usePopover } from 'components/Popover/usePopover';
import { AccessRoles } from 'PermissionsModule/types';
import { AccessLevelsDropdownContent } from './AccessLevelsDropdownContent';
import { useAppSelector } from 'reduxInfra/hooks';
import { getUserIsAdmin } from 'PermissionsModule/selectors';
import { getTeamMembersHashByTeamMembership } from 'TeamsModule/selectors';
import { permissionsUtils } from 'PermissionsModule/utils';

interface AccessLevelsDropdownProps {
  isOpen: boolean;
  popoverTarget: HTMLElement | Falsy;
  isSummaryView?: boolean;
  isExcludeAdmin?: boolean;
  teamMembershipIds?: number[];
  onClose: () => void;
  onSelect: (newRole: AccessRoles) => void;
  onRequestArchiveMember?: () => void;
}

export const AccessLevelsDropdown = ({
  isSummaryView = false,
  isExcludeAdmin = false,
  isOpen,
  popoverTarget,
  teamMembershipIds = [],
  onClose,
  onSelect,
  onRequestArchiveMember
}: AccessLevelsDropdownProps) => {
  const { Popover, openPopover, closePopover } = usePopover();

  const userIsAdmin = useAppSelector(getUserIsAdmin);

  const teamMembersHash = useAppSelector(getTeamMembersHashByTeamMembership);
  const teamMembersFromIds = teamMembershipIds.flatMap(
    (id) => teamMembersHash[id] ?? []
  );

  const isEveryMemberBaseMember = teamMembersFromIds.every(
    permissionsUtils.getIsBaseMember
  );

  useEffect(() => {
    if (isOpen && popoverTarget) {
      openPopover({ target: popoverTarget });
    } else {
      closePopover();
    }
  }, [closePopover, isOpen, openPopover, popoverTarget]);

  return (
    <>
      <Popover
        closePopover={onClose}
        className={cn('access-levels-dropdown-popover', {
          'summary-view': isSummaryView
        })}
      >
        <AccessLevelsDropdownContent
          isIncludeAdmin={
            isExcludeAdmin ? false : isEveryMemberBaseMember && userIsAdmin
          }
          isIncludeWorkPlanner={userIsAdmin}
          isIncludeBudgetManager={userIsAdmin}
          isSummaryView={isSummaryView}
          teamMembershipIds={teamMembershipIds}
          onClose={onClose}
          onSelect={onSelect}
          onRequestArchiveMember={onRequestArchiveMember}
        />
      </Popover>
    </>
  );
};
