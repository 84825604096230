import * as constants from '../constants';
import { getQBActionOrDummyType } from 'appConstants/qbFetchActionType';
import { createAction } from 'appUtils';

export const initializeIntegration = (params) =>
  createAction(constants.INITIALIZE_INTEGRATION.TRIGGER, params);

// Accounts
export const fetchIntegrationAccounts = (params) =>
  createAction(constants.FETCH_INTEGRATION_ACCOUNTS.TRIGGER, params);
export const mapAccounts = (params) =>
  createAction(constants.MAP_ACCOUNTS.TRIGGER, params);
export const batchSelectIntegrationAccounts = (params) =>
  createAction(constants.BATCH_SELECT_INTEGRATION_ACCOUNTS, params);
export const setSelectedIntegrationAccounts = (params) =>
  createAction(constants.SET_SELECTED_INTEGRATION_ACCOUNTS, params);
export const clearBatchSelectedIntegrationAccounts = (params) =>
  createAction(constants.CLEAR_BATCH_SELECTED_INTEGRATION_ACCOUNTS, params);
export const setAccountMappingStep = (params) =>
  createAction(constants.SET_ACCOUNT_MAPPING_STEP, params);
export const setAccountRowState = (params) =>
  createAction(constants.SET_ACCOUNT_ROW_STATE, params);
export const clearAccountsMappingSteps = (params) =>
  createAction(constants.CLEAR_ACCOUNTS_MAPPING_STEPS, params);
export const removeIntegrationAccounts = (params) =>
  createAction(constants.REMOVE_INTEGRATION_ACCOUNTS, params);
export const setIntegrationAccountsSearchLoading = (params) =>
  createAction(constants.SET_INTEGRATION_ACCOUNTS_SEARCH_LOADING, params);
export const fetchMappedMosaicAccountIds = (params) =>
  createAction(constants.FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.TRIGGER, params);
export const archiveMappedMosaicAccountSuccess = (params) =>
  createAction(constants.ARCHIVE_MAPPED_MOSAIC_ACCOUNT_SUCCESS, params);

// Activities
export const fetchIntegrationActivities = (params) =>
  createAction(constants.FETCH_INTEGRATION_ACTIVITIES.TRIGGER, params);
export const mapActivities = (params) =>
  createAction(constants.MAP_ACTIVITIES.TRIGGER, params);
export const batchSelectIntegrationActivities = (params) =>
  createAction(constants.BATCH_SELECT_INTEGRATION_ACTIVITIES, params);
export const setSelectedIntegrationActivities = (params) =>
  createAction(constants.SET_SELECTED_INTEGRATION_ACTIVITIES, params);
export const clearBatchSelectedIntegrationActivities = (params) =>
  createAction(constants.CLEAR_BATCH_SELECTED_INTEGRATION_ACTIVITIES, params);
export const setActivityMappingStep = (params) =>
  createAction(constants.SET_ACTIVITY_MAPPING_STEP, params);
export const setActivityRowState = (params) =>
  createAction(constants.SET_ACTIVITY_ROW_STATE, params);
export const clearActivitiesMappingSteps = (params) =>
  createAction(constants.CLEAR_ACTIVITIES_MAPPING_STEPS, params);
export const removeIntegrationActivities = (params) =>
  createAction(constants.REMOVE_INTEGRATION_ACTIVITIES, params);
export const setIntegrationActivitiesSearchLoading = (params) =>
  createAction(constants.SET_INTEGRATION_ACTIVITIES_SEARCH_LOADING, params);
export const fetchMappedMosaicActivityIds = (params) =>
  createAction(constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.TRIGGER, params);
export const setIntegrationActivitiesCounts = (params) =>
  createAction(constants.SET_INTEGRATION_ACTIVITIES_COUNTS, params);

// Clients
export const fetchIntegrationClients = (params) =>
  createAction(constants.FETCH_INTEGRATION_CLIENTS.TRIGGER, params);
export const mapClients = (params) =>
  createAction(constants.MAP_CLIENTS.TRIGGER, params);
export const batchSelectIntegrationClients = (params) =>
  createAction(constants.BATCH_SELECT_INTEGRATION_CLIENTS, params);
export const setSelectedIntegrationClients = (params) =>
  createAction(constants.SET_SELECTED_INTEGRATION_CLIENTS, params);
export const clearBatchSelectedIntegrationClients = (params) =>
  createAction(constants.CLEAR_BATCH_SELECTED_INTEGRATION_CLIENTS, params);
export const setClientMappingStep = (params) =>
  createAction(constants.SET_CLIENT_MAPPING_STEP, params);
export const setClientRowState = (params) =>
  createAction(constants.SET_CLIENT_ROW_STATE, params);
export const clearClientsMappingSteps = (params) =>
  createAction(constants.CLEAR_CLIENTS_MAPPING_STEPS, params);
export const removeIntegrationClients = (params) =>
  createAction(constants.REMOVE_INTEGRATION_CLIENTS, params);
export const setIntegrationClientsSearchLoading = (params) =>
  createAction(constants.SET_INTEGRATION_CLIENTS_SEARCH_LOADING, params);
export const resetIntegrationClients = (params) =>
  createAction(constants.RESET_INTEGRATION_CLIENTS, params);
export const fetchMappedMosaicClientIds = (params) =>
  createAction(constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.TRIGGER, params);

// Projects
export const fetchIntegrationProjects = (params) =>
  createAction(constants.FETCH_INTEGRATION_PROJECTS.TRIGGER, params);
export const refetchIntegrationProject = (params) =>
  createAction(constants.REFETCH_INTEGRATION_PROJECT.TRIGGER, params);
export const mapProjects = (params) =>
  createAction(constants.MAP_PROJECTS.TRIGGER, params);
export const clearMovedIntegrationProjects = (params) =>
  createAction(constants.CLEAR_MOVED_INTEGRATION_PROJECTS, params);
export const batchSelectIntegrationProjects = (params) =>
  createAction(constants.BATCH_SELECT_INTEGRATION_PROJECTS, params);
export const clearBatchSelectedIntegrationProjects = (params) =>
  createAction(constants.CLEAR_BATCH_SELECTED_INTEGRATION_PROJECTS, params);
export const setIntegrationProjectsSearchLoading = (params) =>
  createAction(constants.SET_INTEGRATION_PROJECTS_SEARCH_LOADING, params);
export const fetchMappedMosaicProjectIds = (params) =>
  createAction(constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.TRIGGER, params);
export const mapAllPhases = (params) =>
  createAction(constants.MAP_ALL_PHASES.TRIGGER, params);

// Phases
export const mapPhases = (params) =>
  createAction(constants.MAP_PHASES.TRIGGER, params);
export const setIntegrationsMappingStep = (params) =>
  createAction(constants.SET_INTEGRATIONS_MAPPING_STEP, params);
export const clearIntegrationsMappingStep = (params) =>
  createAction(constants.CLEAR_INTEGRATIONS_MAPPING_STEP, params);
export const clearProjectsMappingSteps = (params) =>
  createAction(constants.CLEAR_PROJECTS_MAPPING_STEPS, params);
export const clearPhasesMappingSteps = (params) =>
  createAction(constants.CLEAR_PHASES_MAPPING_STEPS, params);
export const fetchMappedMosaicPhaseIds = (params) =>
  createAction(constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.TRIGGER, params);

export const fetchIntegrationPhases = (params) =>
  createAction(constants.FETCH_INTEGRATION_PHASES.TRIGGER, params);
export const setLinkingIntegrationPhase = (params) =>
  createAction(constants.SET_LINKING_INTEGRATION_PHASE, params);
export const setLinkingMosaicPhase = (params) =>
  createAction(constants.SET_LINKING_MOSAIC_PHASE, params);
export const clearLinkingPhases = (params) =>
  createAction(constants.CLEAR_LINKING_PHASES, params);
export const mapAllActivities = (params) =>
  createAction(constants.MAP_ALL_ACTIVITIES.TRIGGER, params);
export const fetchAutoLinkPhases = (params) =>
  createAction(constants.FETCH_AUTO_LINK_PHASES.TRIGGER, params);

export const fetchAutoLinkChildrenByPhaseName = (params) =>
  createAction(
    constants.FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME.TRIGGER,
    params
  );

// Activity phases
export const mapActivityPhases = (params) =>
  createAction(constants.MAP_ACTIVITY_PHASES.TRIGGER, params);
export const setLinkingIntegrationActivityPhase = (params) =>
  createAction(constants.SET_LINKING_INTEGRATION_ACTIVITY_PHASE, params);
export const setLinkingMosaicActivityPhase = (params) =>
  createAction(constants.SET_LINKING_MOSAIC_ACTIVITY_PHASE, params);
export const clearLinkingActivityPhases = (params) =>
  createAction(constants.CLEAR_LINKING_ACTIVITY_PHASES, params);

// Time entries
export const fetchMosaicTimeEntryMappingStatusesByIntegration = (params) =>
  createAction(
    constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES_BY_INTEGRATION.TRIGGER,
    params
  );
export const fetchMosaicTimeEntryMappingStatuses = (params) =>
  createAction(constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES, params);

// global
export const linkIntegration = (params) =>
  createAction(constants.LINK_INTEGRATION.TRIGGER, params);

export const disconnectIntegration = (params) =>
  createAction(constants.DISCONNECT_INTEGRATION.TRIGGER, params);

export const disconnectIntegrationV2 = (params) =>
  createAction(constants.DISCONNECT_INTEGRATION_V2.TRIGGER, params);

export const fetchIntegrations = (params) =>
  createAction(constants.FETCH_INTEGRATIONS.TRIGGER, params);

export const fetchIntegration = (params) =>
  createAction(
    getQBActionOrDummyType(constants.FETCH_INTEGRATION.TRIGGER),
    params
  );

export const saveButtonClick = (params) =>
  createAction(constants.SAVE_BUTTON_CLICKED, params);

export const isSyncCancelling = (params) =>
  createAction(constants.IS_SYNC_CANCELLING, params);

export const fetchIntegrationAuthStatus = (params) =>
  createAction(constants.FETCH_INTEGRATION_AUTH_STATUS.TRIGGER, params);

export const provisionIntegration = (params) =>
  createAction(constants.PROVISION_INTEGRATION.TRIGGER, params);

export const setIsProvisioning = (value) =>
  createAction(constants.SET_IS_PROVISIONING, value);

export const updateIntegrationConfig = (params) =>
  createAction(constants.UPDATE_INTEGRATION_CONFIG.TRIGGER, params);

export const fetchMappedMosaicEntityIds = (params) =>
  createAction(constants.FETCH_MAPPED_MOSAIC_ENTITY_IDS.TRIGGER, params);

export const fetchIntegrationEntityStats = (params) =>
  createAction(constants.FETCH_INTEGRATION_ENTITY_STATS.TRIGGER, params);

export const fetchIntegrationSettingsSchema = (params) =>
  createAction(constants.FETCH_INTEGRATION_SETTINGS_SCHEMA.TRIGGER, params);

export const fetchSyncLog = (params) =>
  createAction(constants.FETCH_SYNC_LOG.TRIGGER, params);

export const updateTrayConfigWizard = (params) =>
  createAction(constants.UPDATE_TRAY_CONFIG_WIZARD, params);
