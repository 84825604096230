import { Suspense, memo } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import withRouterParams from './WithRouterParams';
import NavigationTracker from 'views/Navigation/NavigationTracker';
import { Views } from 'appConstants/navigation';
import BudgetSettingsTab from 'SettingsModule/components/BudgetSettings/BudgetSettingsTab';
import { budgetSettingsModuleConstants } from 'SettingsModule/components/BudgetSettings/constants';
import { useAppSelector } from 'reduxInfra/hooks';
import { getSplitFlags } from 'selectors';

const { BudgetSettingsViewTypes } = budgetSettingsModuleConstants;

const BudgetSettingsRouter = () => {
  const match = useRouteMatch();
  const {
    newMemberRatesFlag,
    roleRateGroupFlag,
    teamBudgetSettingsFlag,
    workCategoryRateGroupFlag
  } = useAppSelector(getSplitFlags);
  console.log(match);

  const enableSettingsTab = teamBudgetSettingsFlag;
  const enableRatesTab =
    workCategoryRateGroupFlag || newMemberRatesFlag || roleRateGroupFlag;

  return (
    <>
      <NavigationTracker view={Views.BUDGET_SETTINGS} />
      <Suspense fallback={<div />}>
        <Switch>
          {enableSettingsTab && (
            <Route
              path={`${match.url}/${BudgetSettingsViewTypes.settings}`}
              render={() => (
                <BudgetSettingsTab
                  viewType={BudgetSettingsViewTypes.settings}
                />
              )}
            />
          )}
          {enableRatesTab && (
            <Route
              path={`${match.url}/${BudgetSettingsViewTypes.rates}`}
              render={() => (
                <BudgetSettingsTab viewType={BudgetSettingsViewTypes.rates} />
              )}
            />
          )}
          <Redirect
            to={
              enableSettingsTab
                ? `${match.url}/${BudgetSettingsViewTypes.settings}`
                : enableRatesTab
                ? `${match.url}/${BudgetSettingsViewTypes.rates}`
                : match.url.replace(/\/budget$/, '/info')
            }
          />
        </Switch>
      </Suspense>
    </>
  );
};

const BudgetSettingsRouterMemoed = memo(BudgetSettingsRouter);

const BudgetSettingsRouterMemoedRouted = withRouterParams(
  BudgetSettingsRouterMemoed
);

export default BudgetSettingsRouterMemoedRouted;
