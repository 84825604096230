import { put, select, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import moment from 'moment';
import { api } from '../service';
import * as entityActions from '../actions';
import { changeEntity, fetchEntity } from './generics';
import {
  fetchUnloadedProjects,
  fetchUnloadedProjectMemberBudgets
} from './helpers';
import {
  fetchUser,
  fetchPhaseTotalsByBoard,
  sendWorkloadPlannerEvent as sendWorkloadPlannerEventActionCreator,
  fetchWorkloadPlannerEventLastSent as fetchWorkloadPlannerEventLastSentActionCreator,
  fetchWorkloadPlannerEvent as fetchWorkloadPlannerEventActionCreator,
  fetchProjectTotals,
  fetchTasksV2
} from 'actionCreators';
import * as actionCreators from 'actionCreators';
import {
  getAuthToken,
  getCurrentUserId,
  getSelectedTeamId,
  getProjectPlannerVisibleTimeEnd,
  getProjectPlannerVisibleTimeStart,
  getPlannerSchedules
} from 'selectors';
import * as constants from 'appConstants';
import { fetchUtilizations } from 'UtilizationModule/actionCreators';

import { keyifyScheduleBarDate } from 'appUtils/projectPlannerUtils';
import { fetchCapacities } from 'CapacityModule/actionCreators';
import { EntityType } from 'models/entity';

const {
  workdaysPredict,
  projectPlannerFetch,
  projectPlannerUpdate,
  projectPlannerCreate,
  projectPlannerPredict,
  projectPlannerDelete,
  projectPlannerEventsFetch,
  projectPlannerEventFetch,
  projectPlannerEventUpdate,
  projectPlannerEventCreate,
  projectPlannerNotificationCreate,
  projectPlannerEventSend,
  projectPlannerEventDelete,
  projectPlannerEventLastSentFetch,
  projectPlannerMembersUpdate,
  projectPlannerSplit,
  memberProjectsFetch,
  fetchProjectMemberSuggestions
} = entityActions;

export function* refetchWorkloadWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  const scheduleBars = yield select(getPlannerSchedules);
  const scheduleBar = action.payload;
  const scheduleBarBeforeUpdate = scheduleBars[scheduleBar.id] || scheduleBar;
  const visibleTimeStart = yield select((state) =>
    getProjectPlannerVisibleTimeStart(state, { plannerType: 'workload' })
  );
  const visibleTimeEnd = yield select((state) =>
    getProjectPlannerVisibleTimeEnd(state, { plannerType: 'workload' })
  );
  if (action.type !== constants.WS_SCHEDULE_BAR) {
    yield put(
      fetchPhaseTotalsByBoard({
        projectIds: Array.from(
          new Set([scheduleBar.project_id, scheduleBarBeforeUpdate.project_id])
        )
      })
    );
  }
  yield put(
    fetchUtilizations({
      accountIds: Array.from(
        new Set([scheduleBar.account_id, scheduleBarBeforeUpdate.account_id])
      ),
      startDate: keyifyScheduleBarDate(
        moment(visibleTimeStart).add(-6, 'months')
      ),
      endDate: keyifyScheduleBarDate(moment(visibleTimeEnd).add(6, 'months')),
      teamId
    })
  );
  yield put(
    fetchCapacities({
      accountIds: Array.from(
        new Set([scheduleBar.account_id, scheduleBarBeforeUpdate.account_id])
      ),
      teamId
    })
  );
}

export function* fetchWorkloadPlanner(action) {
  const {
    startDate,
    endDate,
    accountId,
    team_member_ids,
    member_budget_ids,
    project_ids,
    sort_attributes,
    groupDeterminant,
    isRequest,
    shouldFetchProjects,
    shouldFetchMemberBudgets,
    limit,
    offset,
    all,
    countOnly,
    position_ids
  } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    projectPlannerFetch,
    api.fetchWorkloadPlanner,
    undefined,
    [
      token,
      accountId,
      startDate,
      endDate,
      team_member_ids,
      member_budget_ids,
      project_ids,
      isRequest,
      sort_attributes,
      groupDeterminant,
      limit,
      offset,
      all,
      countOnly,
      position_ids
    ],
    action,
    !(groupDeterminant || isRequest || countOnly)
      ? constants.RequestPriority.High // higher priority for fetching timeline work plan bars
      : undefined
  );

  if (
    response?.activity_phase_schedule_bars?.length &&
    (shouldFetchProjects || shouldFetchMemberBudgets)
  ) {
    const toFetchIds = response.activity_phase_schedule_bars.map((bar) =>
      isRequest ? bar.requested_project_id : bar.project_id
    );
    // Fetch unloaded projects and phases if wanted
    if (shouldFetchProjects) {
      yield call(fetchUnloadedProjects, {
        projectIds: toFetchIds
      });
    }
    if (shouldFetchMemberBudgets) {
      yield call(fetchUnloadedProjectMemberBudgets, toFetchIds);
    }
  }
}

export function* updateWorkloadPlanner(action) {
  const {
    start_date,
    end_date,
    id,
    account_id,
    project_id,
    phase_id,
    daily_hours,
    total_hours,
    description,
    all_day = false,
    include_weekends = false,
    include_holidays = false,
    activity_id,
    activity_phase_id,
    phase_dependency = null,
    budget_status,
    member_budget_id,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_activity_phase_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    request_date,
    lock_hour,
    add_scope_ids,
    remove_scope_ids,
    add_task_ids,
    remove_task_ids,
    start_time,
    end_time,
    use_weekly_planning,
    dependency_infos = [],
    member_update_strategy
  } = action.payload;

  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const visibleTimeStart = yield select((state) =>
    getProjectPlannerVisibleTimeStart(state, { plannerType: 'workload' })
  );
  const visibleTimeEnd = yield select((state) =>
    getProjectPlannerVisibleTimeEnd(state, { plannerType: 'workload' })
  );
  const scheduleBars = yield select(getPlannerSchedules);
  const scheduleBarBeforeUpdate = scheduleBars[id];
  const { error, response } = yield changeEntity(
    projectPlannerUpdate,
    api.updateWorkloadPlanner,
    [
      token,
      {
        start_date,
        end_date,
        id,
        account_id,
        daily_hours,
        total_hours,
        all_day,
        include_weekends,
        include_holidays,
        project_id,
        phase_id,
        description,
        activity_id,
        activity_phase_id,
        phase_dependency,
        budget_status,
        member_budget_id,
        is_request,
        approver_id,
        requested_account_id,
        requested_member_budget_id,
        requested_project_id,
        requested_phase_id,
        requested_activity_id,
        requested_activity_phase_id,
        requested_start_date,
        requested_end_date,
        requested_all_day,
        requested_total_hours,
        requested_daily_hours,
        request_date,
        lock_hour,
        add_scope_ids,
        remove_scope_ids,
        add_task_ids,
        remove_task_ids,
        start_time,
        end_time,
        use_weekly_planning,
        dependency_infos,
        member_update_strategy
      }
    ],
    action,
    action.payload
  );
  if (!is_request && !error) {
    const earliestDate = moment.min(
      moment(visibleTimeStart),
      moment(start_date),
      moment(scheduleBarBeforeUpdate.start_date),
      moment(response.activity_phase_schedule_bar.start_date)
    );
    const latestDate = moment.max(
      moment(visibleTimeEnd),
      moment(end_date),
      moment(scheduleBarBeforeUpdate.end_date),
      moment(response.activity_phase_schedule_bar.end_date)
    );
    yield put(
      fetchPhaseTotalsByBoard({
        projectIds: Array.from(
          new Set([project_id, scheduleBarBeforeUpdate.project_id])
        )
      })
    );
    yield put(
      fetchUtilizations({
        accountIds: [account_id],
        startDate: keyifyScheduleBarDate(earliestDate),
        endDate: keyifyScheduleBarDate(latestDate),
        teamId
      })
    );
    yield put(fetchCapacities({ accountIds: [account_id], teamId }));
    yield put(
      fetchProjectTotals({
        projectIds: [project_id],
        startDate: moment(start_date).format('YYYY-MM-DD'),
        endDate: moment(end_date).format('YYYY-MM-DD'),
        intervalAmount: '1',
        intervalType: 'days',
        isFuture: true
      })
    );
  }

  // Refetch the tasks that may have received new assignees.
  const taskIds = response?.activity_phase_schedule_bar?.project_task_orders;
  if (member_update_strategy && taskIds?.length) {
    yield put(
      fetchTasksV2({
        body: {
          all: true,
          task_ids: taskIds
        }
      })
    );
  }
}

export function* predictWorkdays(action) {
  const {
    account_id,
    team_id,
    start_date,
    end_date,
    work_days,
    is_pto,
    include_weekends,
    dependency_info
  } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield fetchEntity(
    workdaysPredict,
    api.predictWorkdays,
    undefined,
    [
      token,
      account_id,
      team_id,
      start_date,
      end_date,
      work_days,
      is_pto,
      include_weekends,
      dependency_info
    ],
    action
  );
}

export function* predictWorkloadPlanner(action) {
  const {
    project_id,
    phase_id,
    start_date,
    end_date,
    account_id,
    daily_hours,
    total_hours,
    include_weekends = false,
    include_holidays = false,
    all_day = false,
    phase_dependency = null,
    budget_status,
    member_budget_id,
    work_days = undefined,
    last_modified,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    requester_id,
    request_date,
    lock_hour,
    start_time,
    use_weekly_planning,
    type = EntityType.ActivityPhaseScheduleBar,
    onSuccess,
    onFailure
  } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield changeEntity(
    projectPlannerPredict,
    api.predictWorkloadPlanner,
    [
      token,
      {
        start_date,
        end_date,
        account_id,
        daily_hours,
        total_hours,
        include_weekends,
        include_holidays,
        all_day,
        project_id,
        phase_id,
        phase_dependency,
        budget_status,
        member_budget_id,
        work_days,
        lock_hour,
        is_request,
        last_modified,
        approver_id,
        requested_account_id,
        requested_member_budget_id,
        requested_project_id,
        requested_phase_id,
        requested_activity_id,
        requested_start_date,
        requested_end_date,
        requested_all_day,
        requested_total_hours,
        requested_daily_hours,
        requester_id,
        request_date,
        start_time,
        use_weekly_planning,
        type
      }
    ]
  );

  if (!error) {
    onSuccess?.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  } else {
    onFailure && onFailure(error);
  }
}
export function* createWorkloadPlanner(action) {
  const {
    start_date,
    end_date,
    account_id,
    project_id,
    phase_id,
    daily_hours,
    total_hours,
    description,
    all_day = false,
    include_weekends = false,
    include_holidays = false,
    activity_id,
    activity_phase_id,
    phase_dependency = null,
    budget_status,
    member_budget_id,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_activity_phase_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    request_date,
    requester_id,
    lock_hour,
    scope_ids,
    start_time,
    end_time,
    use_weekly_planning,
    add_task_ids,
    onSuccess = [],
    dependency_infos = []
  } = action.payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { error, response } = yield changeEntity(
    projectPlannerCreate,
    api.createWorkloadPlanner,
    [
      token,
      {
        start_date,
        end_date,
        account_id,
        daily_hours,
        total_hours,
        all_day,
        include_weekends,
        include_holidays,
        project_id,
        phase_id,
        description,
        activity_id,
        activity_phase_id,
        phase_dependency,
        budget_status,
        member_budget_id,
        is_request,
        approver_id,
        requested_account_id,
        requested_member_budget_id,
        requested_project_id,
        requested_phase_id,
        requested_activity_id,
        requested_activity_phase_id,
        requested_start_date,
        requested_end_date,
        requested_all_day,
        requested_total_hours,
        requested_daily_hours,
        request_date,
        requester_id,
        lock_hour,
        scope_ids,
        start_time,
        end_time,
        use_weekly_planning,
        add_task_ids,
        dependency_infos
      }
    ],
    action,
    action.payload
  );
  if (!error) {
    yield put(fetchPhaseTotalsByBoard({ projectIds: [project_id] }));
    yield put(
      fetchUtilizations({
        accountIds: [account_id],
        startDate: start_date,
        endDate: response.activity_phase_schedule_bar.end_date,
        teamId
      })
    );
    yield put(fetchCapacities({ accountIds: [account_id], teamId }));
    yield put(
      fetchProjectTotals({
        projectIds: [project_id],
        startDate: moment(start_date).format('YYYY-MM-DD'),
        endDate: moment(end_date).format('YYYY-MM-DD'),
        intervalAmount: '1',
        intervalType: 'days',
        isFuture: true
      })
    );
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector?.(action.payload, response))
    );
  }
}

export function* splitWorkloadPlanner(action) {
  const { split_date, id, account_id } = action.payload;
  const token = yield select(getAuthToken);
  const myId = yield select(getCurrentUserId);
  const { error, response } = yield changeEntity(
    projectPlannerSplit,
    api.splitWorkloadPlanner,
    [
      token,
      {
        split_date,
        id
      }
    ]
  );
  const formatFetchDate = (date) =>
    moment(date).clone().startOf('day').format('MM/DD/YYYY');
  const startDate = formatFetchDate(moment(split_date).add(-1, 'days'));
  const endDate = formatFetchDate(moment(split_date).add(1, 'days'));

  yield put(
    actionCreators.fetchWorkloadPlanner({
      startDate,
      endDate,
      accountId: myId,
      team_member_ids: [account_id]
    })
  );
}

export function* deleteWorkloadPlanner(action) {
  if (!action.payload) {
    return;
  }

  const { id, account_id, end_date, start_date, project_id } = action.payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);

  const { error, response } = yield changeEntity(
    projectPlannerDelete,
    api.deleteWorkloadPlanner,
    [token, id],
    action,
    action.payload
  );
  if (!error) {
    yield put(fetchPhaseTotalsByBoard({ projectIds: [project_id] }));
    yield put(
      fetchUtilizations({
        accountIds: [account_id],
        startDate: start_date,
        endDate: end_date,
        teamId: teamId
      })
    );
    yield put(fetchCapacities({ accountIds: [account_id], teamId }));
    yield put(
      fetchProjectTotals({
        projectIds: [project_id],
        startDate: moment(start_date).format('YYYY-MM-DD'),
        endDate: moment(end_date).format('YYYY-MM-DD'),
        intervalAmount: '1',
        intervalType: 'days',
        isFuture: true
      })
    );
  }
}

export function* updateWorkloadPlannerMembers(action) {
  const team_planner_members = action.payload;
  const token = yield select(getAuthToken);
  const myId = yield select(getCurrentUserId);
  const { error, response } = yield changeEntity(
    projectPlannerMembersUpdate,
    api.updateWorkloadPlannerMembers,
    [token, team_planner_members]
  );
  if (!error) {
    yield put(fetchUser());
  }
}

export function* approveWorkplanRequestsWorker(action) {
  const { ids } = action.payload;
  const token = yield select(getAuthToken);
  yield changeEntity(
    entityActions.approveWorkplanRequests,
    api.approveWorkplanRequests,
    [token, ids],
    action,
    action.payload
  );
}

export function* fetchMemberProjects(action) {
  const {
    account_id,
    account_ids,
    projectIds,
    search,
    limit,
    offset,
    all,
    is_administrative,
    onSuccess = []
  } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    memberProjectsFetch,
    api.fetchMemberProjects,
    undefined,
    [
      token,
      account_id,
      account_ids,
      projectIds,
      search,
      limit,
      offset,
      all,
      is_administrative
    ],
    action
  );

  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction(selector(action.payload, response))
    );
  }
}

export function* fetchWorkloadPlannerEvents(action) {
  const { team_id, event_type } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    projectPlannerEventsFetch,
    api.fetchWorkloadPlannerEvents,
    undefined,
    [token, team_id, event_type],
    action
  );
}
export function* fetchWorkloadPlannerEvent(action) {
  const { event_id } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    projectPlannerEventFetch,
    api.fetchWorkloadPlannerEvent,
    event_id,
    [token],
    action
  );
}

export function* updateWorkloadPlannerEvent(action) {
  const {
    send_time,
    recipient_account_ids,
    event_id,
    ical_string,
    refetchOnSuccess
  } = action.payload;
  const token = yield select(getAuthToken);

  if (event_id !== 'new') {
    const { error, response } = yield changeEntity(
      projectPlannerEventUpdate,
      api.updateWorkloadPlannerEvent,
      [
        token,
        {
          event_id,
          send_time,
          recipient_account_ids,
          ical_string
        }
      ]
    );
    if (!error) {
      if (refetchOnSuccess) {
        yield put(
          fetchWorkloadPlannerEventActionCreator({
            event_id
          })
        );
      }
    }
  }
}

export function* createNotificationPreview(action) {
  const {
    entity_type,
    entity_id,
    entity_attributes,
    onSuccess = []
  } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    projectPlannerNotificationCreate,
    api.createNotificationPreview,
    [
      token,
      {
        entity_type,
        entity_id,
        entity_attributes
      }
    ]
  );
  if (!error) {
    onSuccess.forEach(({ successAction, selector }) =>
      successAction((action.payload, response))
    );
  }
}

export function* createWorkloadPlannerEvent(action) {
  const {
    event_type,
    send_time,
    recipient_account_ids,
    follower_ids,
    date_range,
    team_id,
    sendNow = false,
    ical_string,
    sender_id,
    refetchOnSuccess = false
  } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield changeEntity(
    projectPlannerEventCreate,
    api.createWorkloadPlannerEvent,
    [
      token,
      {
        event_type,
        send_time,
        recipient_account_ids,
        follower_ids,
        date_range,
        team_id,
        ical_string,
        sender_id
      }
    ]
  );
  if (!error) {
    if (sendNow) {
      yield put(
        sendWorkloadPlannerEventActionCreator({
          id: response.scheduled_event_id
        })
      );
    }
    if (refetchOnSuccess) {
      yield put(
        fetchWorkloadPlannerEventActionCreator({
          event_id: response.scheduled_event_id
        })
      );
    }
  }
}
export function* sendWorkloadPlannerEvent(action) {
  const { id } = action.payload;
  const token = yield select(getAuthToken);
  const teamId = yield select(getSelectedTeamId);
  const { error, response } = yield changeEntity(
    projectPlannerEventSend,
    api.sendWorkloadPlannerEvent,
    [
      token,
      {
        id
      }
    ]
  );
  if (!error && teamId) {
    yield delay(1000);
    yield put(fetchWorkloadPlannerEventLastSentActionCreator({ teamId }));
  }
}

export function* deleteWorkloadPlannerEvent(action) {
  if (!action.payload) {
    return;
  }

  const { event_id } = action.payload;
  const token = yield select(getAuthToken);

  const { error, response } = yield changeEntity(
    projectPlannerEventDelete,
    api.deleteWorkloadPlannerEvent,
    [token, event_id]
  );
  if (!error) {
  }
}

export function* fetchWorkloadPlannerEventLastSent(action) {
  const { teamId } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    projectPlannerEventLastSentFetch,
    api.fetchWorkloadPlannerEventLastSent,
    teamId,
    [token],
    action
  );
}

export function* refetchOnWSScheduledEventWorker(action) {
  const teamId = yield select(getSelectedTeamId);
  if (teamId) {
    yield put(actionCreators.fetchWorkloadPlannerEvents({ team_id: teamId }));
    yield delay(8000); // let background user activities get created
    yield put(actionCreators.fetchWorkloadPlannerEventLastSent({ teamId }));
  }
}

export function* fetchSuggestions(action) {
  const {
    phase_membership_ids,
    position_ids,
    start_date,
    end_date,
    onSuccess = []
  } = action.payload;
  const token = yield select(getAuthToken);
  const { error, response } = yield fetchEntity(
    fetchProjectMemberSuggestions,
    api.fetchSuggestions,
    undefined,
    [token, phase_membership_ids, start_date, end_date],
    action
  );

  onSuccess.forEach(({ successAction, selector }) =>
    successAction(selector(action.payload, response))
  );
}
