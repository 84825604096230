/* eslint-disable no-unused-expressions */
import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect
} from 'react';
import theme from 'theme';
import { connect, useDispatch, batch } from 'react-redux';
import keyBy from 'lodash/keyBy';
import {
  makeGetOrderedProjects,
  makeGetOrderedFilterTeamMembers,
  makeGetOrderedFilterActivities,
  makeGetActiveWorkloadPlannerFilter,
  makeGetActiveWorkloadPlannerFilterIdHashes,
  getMe,
  makeGetOrderedFilterPhaseNames,
  getGroups,
  getSelectedTeamId,
  getAuthToken,
  getPersonalBoards,
  makeGetFilterProjectsFetching,
  makeGetFilterProjectsOffset,
  makeGetFilterProjectsCount,
  makeGetFilterProjectsGroupCounts,
  makeGetFilterProjectOrdersByBoard,
  getProjectHash,
  getGroupsHash,
  getActiveTeam,
  getProjectsByMembersHash,
  getTeamMembershipsByAccountId,
  makeGetOrderedFilterClients,
  getPhasesByProjectHash,
  getCurrentUserPlannerMemberProjectIds,
  getFetchedProjectIds,
  getAreSkillsLoaded,
  getOOOProject,
  getWFHProject
} from 'selectors';
import {
  makeGetOrderedFilterPositions,
  getAccountToTeamPosition
} from 'BudgetModule/selectors/positions';
import {
  fetchAllProjects,
  fetchAllProjectMembers,
  fetchWorkGroups,
  fetchProjectsIndexV2,
  updateAccountFilterTemporarySaveAction,
  updateAccountFilterLocal,
  changeBoardPosition,
  handleErrorMessage,
  clearDenyPermission,
  updateProjectPosition,
  navigateToTeamSettings,
  navigateToMembersOfficeSettings,
  navigateToMembersRegionSettings,
  navigateToMembersDisciplineSettings,
  fetchPhasesByProjectIds,
  fetchSkills,
  fetchAllTeamMembersByBoard
} from 'actionCreators';
import {
  fetchTeamPositions,
  fetchPositions
} from 'BudgetModule/actionCreators';
import { fetchOffices } from 'SettingsModule/actionCreators/settings/office';
import { fetchRegions } from 'SettingsModule/actionCreators/settings/region';
import { fetchDisciplines } from 'SettingsModule/actionCreators/settings/discipline';
import { getFormattedOfficesWithMembers } from 'SettingsModule/selectors/offices';
import { getFormattedRegionsWithMembers } from 'SettingsModule/selectors/regions';
import { getFormattedDisciplinesWithMembers } from 'SettingsModule/selectors/disciplines';
import Spinner from 'react-spinkit';
import { VariableSizeList } from 'react-window';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import { BILLABLE_VALUES } from 'appConstants/filters';
import { filterListTypeToKey, FilterListType } from 'FilterModule/constants';

import {
  TIMESHEET_REPORT_STATUSES,
  FILTER_RENDER_TEXT,
  TIMESHEET_STATUS_REVERSE_LOOKUP
} from 'appConstants/timesheets';
import { GENERIC_ACTION, PERSONAL_BOARD_NAMES } from 'appConstants';
import {
  PROJECT_BUDGET_STATUSES_DISPLAY,
  BUDGET_STATUSES
} from 'appConstants/budgetStatuses';
import {
  StyledFilterListContainer,
  StyledSearchField,
  StyledSummary,
  StyledClear,
  leftValue,
  CollapseAllContainer
} from './styles';
import FilterRow, { components, DraggableFilterRow } from './FilterRow';

import InfiniteLoader from 'react-window-infinite-loader';
import {
  getFormattedWorkGroupSettingsWithLeftoverMembersWorkGroup,
  makeGetFormattedSkillsWithMembers
} from 'SettingsModule/selectors';
import {
  VirtualFilterMemberTabs,
  VirtualFilterProjectTabs
} from './FilterTabs';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { DragClone } from 'components/Table/styles';
import { serializeBar, deserializeBar } from 'appUtils/projectPlannerUtils';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import CollapseAllIcon from 'images/thin-collapse-all.svg';
import debounce from 'lodash/debounce';
import ContentLoader from 'react-content-loader';
import { serializeId } from 'appUtils';
import get from 'lodash/get';
import pick from 'lodash/pick';
import useHasStateChanged from 'appUtils/hooks/useHasStateChanged';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import useImmediateBoolean from 'appUtils/hooks/useImmediateBoolean';
import { getIsPhaseLikeDefault } from 'appUtils/phaseDisplayUtils';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import merge from 'lodash/merge';

const serializeSection = ({ parentId, sectionId }) =>
  `${parentId}--${sectionId}`;

const deserializeSection = (id) => {
  if (!id?.split) {
    return {};
  }
  const [parentId, sectionId] = id.split('--');
  return { parentId, sectionId };
};

const threshold = 30;
const emptyObj = {};
const itemKey = (index, data) => {
  // Find the item at the specified index.
  // In this case "data" is an Array that was passed to List as "itemData".
  const item = data[index];
  return item ? item.id : index;
  // Return a value that uniquely identifies this item.
  // Typically this will be a UID of some sort.
};

const EmptyRow = (props) => <div />;

const buildHeader = ({
  id,
  headerType,
  isOpen,
  headerHeight,
  renderHeader,
  headerLeft,
  isSubList,
  ...list
}) => ({
  isHeader: true,
  ...list,
  isSubList,
  id,
  itemLeft: headerLeft,
  component: headerType,
  isOpen,
  itemHeight: headerHeight || 70,
  listType: id,
  renderHeader,
  isDragDisabled: list.isDragDisabled,
  draggableId: list.draggableId || list.uid
});
const buildRow = ({ list, item, isSubList }) => ({
  isSubList,
  row: item,
  id: list.getItemId(item),
  list,
  itemHeight: item.rowHeight || list.rowHeight || 50,
  itemLeft: list.itemLeft,
  component: item.rowType || list.rowType,
  listType: list.id,
  sectionType: list.sectionType,
  isSelected: list.isItemSelected?.(item),
  handleClick: item.handleClick || list.handleClick,
  handleClear: list.handleClear,
  isCustom: list.isCustom || item.isCustom,
  isDragDisabled:
    typeof item === 'object' && 'isDragDisabled' in item
      ? item.isDragDisabled
      : list.isDragDisabled,
  uid: item.uid,
  draggableId: item.draggableId || item.uid,
  dataType: item.dataType,
  index: item.index,
  label: list.getLabel?.(item)
});

const buildCustomItem = ({ list, item, isSubList }) => ({
  isSubList,
  row: item,
  id: item?.name + list.id,
  uid:
    item.uid ||
    serializeBar({
      itemId: item?.name,
      itemType: list.id
    }),
  draggableId: item.draggableId || item.uid,
  isDragDisabled: true,
  itemHeight: item?.rowHeight || 50,
  isCustom: true,
  component: item,
  listType: list.id,
  sectionType: list.sectionType,
  list,
  index: item?.index || list.listItems.length,
  projectFilterListId: list.projectFilterListId
});
const buildList = ({ lists, engaged, search, isSubList = false }) => {
  const flatList = [];
  let breakFlag = false;
  for (const list of lists) {
    const {
      isOpen,
      listItems,
      isFullyLoaded,
      customItems,
      loader,
      summary,
      showOnEngaged,
      skipHeader,
      showSummary,
      showLoadPadding
    } = list;
    const sectionIsActive = !showOnEngaged || engaged[list.id];
    if (!skipHeader) {
      flatList.push(buildHeader({ isSubList, ...list }));
    }
    if (isOpen) {
      customItems?.forEach((item) =>
        flatList.push(buildCustomItem({ list, item, isSubList }))
      );
      let listItemsToUse = [];
      if (sectionIsActive) {
        listItemsToUse = listItems;
      } else {
        listItemsToUse = listItems.filter(list.isItemSelected);
      }
      for (const item of listItemsToUse) {
        if (item.hasSubLists) {
          const { list: subList, breakFlag: subBreakFlag } = buildList({
            lists: [item],
            engaged,
            search,
            isSubList: true
          });
          flatList.push(...subList);
          if (subBreakFlag) {
            breakFlag = true;
            break;
          }
        } else {
          flatList.push(buildRow({ list, item, isSubList }));
        }
      }
    } else if (showSummary) {
      flatList.push(buildCustomItem({ list, item: summary, isSubList }));
    }
    if (isOpen && !isFullyLoaded) {
      if (loader && sectionIsActive) {
        flatList.push(buildCustomItem({ list, item: loader, isSubList }));
        // Necessary for loadMoreItems to be called, for example
        // when opened row is second from the bottom and loader
        // takes up the last row.
        if (showLoadPadding) {
          flatList.push(
            buildRow({
              list: lists[lists.length - 1] || {
                getItemId: (item) => item?.id
              },
              item: {
                rowHeight: 20,
                rowType: EmptyRow,
                isCustom: true,
                isDragDisabled: true,
                index: 0
              }
            })
          );
        }
      }
      if (sectionIsActive) {
        // continue to next list if section is not active
        breakFlag = true;
        break;
      }
    }
  }
  return { list: flatList, breakFlag };
};

const projectFilter = (item, searchWords) =>
  filterItemWithWhiteSpace({
    searchWords,
    item,
    filterKeysArray: [
      'title',
      'description',
      'project_title',
      'project_description',
      'client'
    ]
  });
const memberFilter = (item, searchWords) =>
  filterItemWithWhiteSpace({
    searchWords,
    item: item.account,
    filterKeysArray: ['name', 'initials']
  });
const positionFilter = (item, searchWords) =>
  filterItemWithWhiteSpace({
    searchWords,
    item: item,
    filterKeysArray: ['name']
  });
const phaseFilter = (item, searchWords) =>
  filterItemWithWhiteSpace({
    searchWords,
    item: { name: item },
    filterKeysArray: ['name']
  });
const activityFilter = (item, searchWords) =>
  filterItemWithWhiteSpace({
    searchWords,
    item,
    filterKeysArray: ['title']
  });
const clientFilter = (item, searchWords) =>
  filterItemWithWhiteSpace({
    searchWords,
    item,
    filterKeysArray: ['title']
  });

const initialSearchState = {
  projects: '',
  members: '',
  positions: '',
  skills: '',
  clients: '',
  phases: '',
  activities: '',
  projectsByBoard: '',
  projectsByMember: ''
};
const initialFilterTabsState = {
  projects: 'projects',
  members: 'members',
  clients: '',
  phases: '',
  activities: ''
};

const tabsToSectionsHash = {
  projectsByBoard: 'projects',
  projectsByMember: 'projects',
  workGroups: 'members'
};

const DATATYPES = {
  PROJECT: 'project',
  BOARD: 'board',
  MEMBER: 'member',
  PHASE: 'phase'
};

const Summary = ({ list, setIsOpen }) => {
  const { listItems, renderSummaryItem, summaryNoun, numberOfSelectedItems } =
    list;
  if (numberOfSelectedItems === undefined) {
    return null;
  }
  let text = '';
  if (numberOfSelectedItems === 0) {
    text = 'All';
  } else if (numberOfSelectedItems === 1) {
    text = renderSummaryItem(
      listItems.find((item) => list.isItemSelected(item))
    );
  } else {
    text = `${numberOfSelectedItems} ${summaryNoun}`;
  }
  return (
    <StyledSummary
      isActive={numberOfSelectedItems !== 0}
      onClick={() => setIsOpen({ uid: list.uid, value: true })}
    >
      {text}
    </StyledSummary>
  );
};
Summary.rowHeight = 24;

const Loader = ({ item }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 15
    }}
    data-testid={`row-${item.uid}-${item.projectFilterListId}`}
  >
    <Spinner fadeIn="none" name="ball-beat" />
  </div>
);

const ProjectLoader = ({ item }) => (
  <div
    style={{
      marginLeft: 43
    }}
    data-testid={`row-${item.uid}-${item.projectFilterListId}`}
  >
    <Spinner fadeIn="none" name="ball-beat" />
  </div>
);

const getSearchText = (isEngaged) =>
  isEngaged ? 'Search Or Select Below' : 'Search';

const ProjectSearch = ({
  children,
  index,
  item,
  style,
  setSearch,
  search,
  engaged,
  setEngaged
}) => (
  <StyledSearchField
    style={style}
    placeholder={getSearchText(engaged.projects)}
    value={search.projects}
    onChange={(e) => setSearch({ name: 'projects', value: e.target.value })}
    onFocus={() => setEngaged({ name: 'projects', value: true })}
    isActive={!!search.projects}
  />
);

const MemberSearch = ({
  children,
  index,
  item,
  style,
  setSearch,
  search,
  engaged,
  setEngaged
}) => (
  <StyledSearchField
    style={style}
    placeholder={getSearchText(engaged.members)}
    value={search.members}
    onChange={(e) => setSearch({ name: 'members', value: e.target.value })}
    onFocus={() => setEngaged({ name: 'members', value: true })}
    isActive={!!search.members}
  />
);
const PositionSearch = ({
  children,
  index,
  item,
  style,
  setSearch,
  search,
  engaged,
  setEngaged
}) => (
  <StyledSearchField
    style={style}
    placeholder={getSearchText(engaged.positions)}
    value={search.positions}
    onChange={(e) => setSearch({ name: 'positions', value: e.target.value })}
    onFocus={() => setEngaged({ name: 'positions', value: true })}
    isActive={!!search.positions}
  />
);

const PhaseSearch = ({
  children,
  index,
  item,
  style,
  setSearch,
  search,
  engaged,
  setEngaged
}) => (
  <StyledSearchField
    style={style}
    placeholder={getSearchText(engaged.phases)}
    value={search.phases}
    onChange={(e) => setSearch({ name: 'phases', value: e.target.value })}
    onFocus={() => setEngaged({ name: 'phases', value: true })}
    isActive={!!search.phases}
  />
);
const ActivitySearch = ({
  children,
  index,
  item,
  style,
  setSearch,
  search,
  engaged,
  setEngaged
}) => (
  <StyledSearchField
    style={style}
    placeholder={getSearchText(engaged.activities)}
    value={search.activities}
    onChange={(e) => setSearch({ name: 'activities', value: e.target.value })}
    onFocus={() => setEngaged({ name: 'activities', value: true })}
    isActive={!!search.activities}
  />
);
const ClientSearch = ({
  children,
  index,
  item,
  style,
  setSearch,
  search,
  engaged,
  setEngaged
}) => (
  <StyledSearchField
    style={style}
    placeholder={getSearchText(engaged.clients)}
    value={search.clients}
    onChange={(e) => setSearch({ name: 'clients', value: e.target.value })}
    onFocus={() => setEngaged({ name: 'clients', value: true })}
    isActive={!!search.clients}
  />
);

const Clear = ({ list }) => (
  <StyledClear
    onClick={list.handleClear}
    isVisible={!!list.numberOfSelectedItems}
  >
    Clear Selected {list.numberOfSelectedItems}
  </StyledClear>
);
ProjectSearch.rowHeight = 30;
MemberSearch.rowHeight = 30;
PhaseSearch.rowHeight = 30;
ActivitySearch.rowHeight = 30;
ClientSearch.rowHeight = 30;
Clear.rowHeight = 24;

const sortAttributes = {
  board_id: { attribute: 'board_id', direction: 'asc' },
  project_position: { attribute: 'project_position', direction: 'asc' },
  filter_selection: { attribute: 'filter_selection', direction: 'asc' },
  is_archived: { attribute: 'is_archived', direction: 'asc' },
  alphabetical: { attribute: 'alphabetical', direction: 'asc' }
};
const existingFilterPersonalSortAttributes = ['board_id', 'project_position'];
const existingFilterSortAttributes = [
  'board_id',
  'filter_selection',
  'is_archived',
  'alphabetical'
];

const existingFilterFetchParams = (isPersonal = false) => {
  const includedSortAttributes = (
    isPersonal
      ? existingFilterPersonalSortAttributes
      : existingFilterSortAttributes
  ).map((attr) => sortAttributes[attr]);

  return {
    sort_attributes: includedSortAttributes
  };
};
const newFilterFetchParams = (isPersonal = false) => {
  const includedSortAttributes = (
    isPersonal
      ? existingFilterPersonalSortAttributes
      : existingFilterSortAttributes
  )
    .filter((attr) => attr !== 'filter_selection')
    .map((attr) => sortAttributes[attr]);

  return {
    sort_attributes: includedSortAttributes
  };
};

const getProjectDependencyFilterParams = (
  activeFilter,
  crossFieldDependencies,
  isDraftFilter
) => {
  const getFilterValue = (filterListType) => {
    const key = filterListTypeToKey[filterListType];
    return get(activeFilter, isDraftFilter ? key.replace('custom.', '') : key);
  };

  const params = {};
  const addFlexibleFilter = () => {
    if (!params.flexible_filter) {
      params.flexible_filter = {};
    }
  };
  crossFieldDependencies.forEach((filterListType) => {
    const filterValue = getFilterValue(filterListType);

    // handling array fields
    if (Array.isArray(filterValue) && filterValue.length) {
      switch (filterListType) {
        case FilterListType.Members: {
          params.account_ids = filterValue;
          break;
        }
        case FilterListType.PhaseNames: {
          addFlexibleFilter();
          params.flexible_filter.phases = {
            ...params.flexible_filter.phases,
            name_in: filterValue
          };
          break;
        }
        case FilterListType.PhaseBudgetStatus: {
          addFlexibleFilter();
          params.flexible_filter.phases = {
            ...params.flexible_filter.phases,
            budget_status_in: filterValue
          };
          break;
        }
        case FilterListType.ClientNames: {
          addFlexibleFilter();
          params.flexible_filter.client_in = filterValue;
          break;
        }
        case FilterListType.Billable: {
          addFlexibleFilter();
          params.flexible_filter.billable_in = filterValue.map(
            (billableValue) => billableValue === BILLABLE_VALUES.BILLABLE
          );
          break;
        }
        case FilterListType.ProfitCenter: {
          addFlexibleFilter();
          params.flexible_filter.custom_fields = {
            ...params.flexible_filter.custom_fields,
            profit_center_in: filterValue
          };
          break;
        }
        case FilterListType.Priorities: {
          addFlexibleFilter();
          params.flexible_filter.priority_id_in = filterValue;
          break;
        }
        case FilterListType.ProjectBudgetStatus: {
          const isArchived = filterValue.find(
            (status) => status === BUDGET_STATUSES.ARCHIVED
          );
          if (isArchived) {
            params.is_archived = true;
          }
          const statuses = filterValue.filter(
            (status) => status !== BUDGET_STATUSES.ARCHIVED
          );
          if (statuses.length) {
            addFlexibleFilter();
            params.flexible_filter.budget_status_in = statuses;
          }
          break;
        }
        case FilterListType.Regions: {
          addFlexibleFilter();
          params.flexible_filter.region_entities = {
            region: {
              id_in: filterValue
            }
          };
          break;
        }
        default:
          break;
      }
      // handling boolean fields - note: not handling 'false' value currently
    } else if (typeof filterValue === 'boolean' && filterValue) {
      switch (filterListType) {
        case FilterListType.ShowProjectsWithUnassignedRoles: {
          addFlexibleFilter();
          params.flexible_filter.project_memberships = {
            account_id: null
          };
          break;
        }

        default:
          break;
      }
    }
  });

  return params;
};

const getFinalProjectDependencyFilterParams = ({
  dependencyFilterHasValues,
  filter,
  draftFilter,
  crossFieldDependencies
}) => {
  return dependencyFilterHasValues
    ? merge(
        getProjectDependencyFilterParams(filter, crossFieldDependencies),
        draftFilter
          ? getProjectDependencyFilterParams(
              draftFilter,
              crossFieldDependencies,
              true
            )
          : {}
      )
    : null;
};

const DEFAULT_IS_OPEN_STATE = { board: {}, projectsByMember: {} };

/**
 * @deprecated avoid making updates to this component, as FilterListsTable will replace it
 */
const VirtualFilter = ({
  pageName,
  projects,
  members,
  positions,
  activities,
  statuses,
  phaseNames,
  clients,
  workGroups,
  boards,
  filterProjectsFetching,
  filterProjectsOffset,
  viewBy,
  viewByOverride,
  activeFilterIdHashes,
  filter,
  me,
  teamId,
  billableItems,
  filterSections,
  filterTabsOverride = emptyObj,
  sectionsStartOpen = false,
  showStickyHeader = true,
  showOnEngaged = true,
  searchOverride,
  customItems = emptyObj,
  skipHeader = emptyObj,
  leftPadding,
  headerLeftOffset = 20, // handle special padding rule on checkboxes for header items in nested lists on reports
  leftOffset,
  width,
  workGroupMemberships,
  filterProjectsCount,
  filterProjectsGroupCounts,
  innerHeightAdjustment = 365,
  saveLocal = false,
  rowTypes,
  headerTypes,
  showSubStickyHeader,
  token,
  enableDrag,
  isProjectsSidebar,
  personalBoards,
  collapseTarget,
  projectFilterListId,
  projectsByBoardId,
  projectsByMemberId,
  workGroupsLoaded,
  projectHash,
  widgetConfig,
  accountsByPosition,
  skillsArray,
  membersWithBoard,
  groupHash,
  team,
  memberHash,
  projectMemberships,
  isStackedFilter,
  crossFieldDependencies,
  sectionToLabel = emptyObj,
  initialFetchGroupCountsThenProjectsChainId,
  initialFetchAllProjectsRequestStatusId,
  isProjectPhaseSelector,
  phasesByProjectIdsHash,
  projectsByPlannerMemberships,
  fetchedProjectIds,
  isSkillsLoaded,
  offices,
  regions,
  disciplines,
  clearSearch,
  additionalFilterOptions = emptyObj,
  OOOProject,
  WFHProject,
  draftFilter,
  isSideFilter,
  itemsCount
}) => {
  const dispatch = useDispatch();
  const dispatchChain = useDispatchChain();
  const { status: initialFetchGroupCountsThenProjectsChainStatus } =
    useRequestStatus({
      requestStatusId: initialFetchGroupCountsThenProjectsChainId
    });
  const { status: initialFetchAllProjectsRequestStatus } = useRequestStatus({
    requestStatusId: initialFetchAllProjectsRequestStatusId
  });

  const viewByToUse = viewByOverride || viewBy;
  const [isOpen, setIsOpen] = useState(DEFAULT_IS_OPEN_STATE);
  const [allCollapsed, setAllCollapsed] = useState({
    board: false,
    member: false
  });
  const [allOpen, setAllOpen] = useState({
    board: false,
    member: false
  });
  const prevIsOpen = useRef(null);
  const prevAllCollapsed = useRef(null);
  const personalBoardsLoaded = useRef(false);
  const [showArchived, setShowArchived] = useState({});
  const currentDragItem = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [stateIsScrolled, setIsScrolled] = useState(false);
  const [search, setSearch] = useState(initialSearchState);
  const [filterTabs, setFilterTabs] = useState(initialFilterTabsState);
  const [engaged, setEngaged] = useState({});
  const [activeSection, setActiveSection] = useState('members');
  const [includeOrExclude, setIncludeOrExclude] = useState({
    board: 'include',
    member: 'include' // not currently used since all boards are fetched initially
  });
  const [showDragGrips, setShowDragGrips] = useState({});
  const [hasFetched, setHasFetched] = useState({
    board: {},
    member: {}
  });
  const { setBool: setIsShowingSkeletonLoader, bool: isShowingSkeletonLoader } =
    useImmediateBoolean(false);

  const saveActionToUse = saveLocal
    ? updateAccountFilterLocal
    : updateAccountFilterTemporarySaveAction;

  // use when it is a saved (in the db) filter
  const shouldUseFilter = !isNaN(filter.id);

  const getOpenIds = useCallback(
    (target) => {
      return Object.keys(isOpen[target] || {})
        .filter((id) => isOpen[target]?.[id])
        .map((id) => +id);
    },
    [isOpen]
  );
  const getClosedIds = useCallback(
    (target) => {
      return Object.keys(isOpen[target] || {})
        .filter((id) => isOpen[target]?.[id] === false)
        .map((id) => +id);
    },
    [isOpen]
  );

  const resetLoadMoreCache = useCallback(() => {
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.resetloadMoreItemsCache(true);
    }
  }, []);

  const personalBoardsHash = useMemo(
    () => keyBy(personalBoards, (board) => board.id),
    [personalBoards]
  );

  useEffect(() => {
    if (teamId && !isProjectsSidebar && !workGroupsLoaded) {
      dispatch(fetchWorkGroups({ teamId, permissions: { teamId } }));
    }
  }, [teamId, dispatch, isProjectsSidebar, workGroupsLoaded]);

  const isMemberFilterLoaded = useRef(false);

  useEffect(() => {
    if (
      teamId &&
      !isMemberFilterLoaded.current &&
      filterSections.includes('members')
    ) {
      batch(() => {
        dispatch(fetchPositions({ teamId }));
        dispatch(fetchTeamPositions({ teamId }));
        dispatch(fetchSkills({ teamId, loadAll: true }));
        dispatch(fetchRegions({ teamId }));
        dispatch(fetchOffices({ teamId }));
        dispatch(fetchDisciplines({ teamId }));
        dispatch(fetchAllTeamMembersByBoard());
      });
      isMemberFilterLoaded.current = true;
    }
  }, [dispatch, teamId, filterSections]);

  const clearWorkGroupOpenList = useCallback((search) => {
    if (!search && prevIsOpen.current) {
      setIsOpen(prevIsOpen.current);
      setAllCollapsed(prevAllCollapsed.current);
      prevIsOpen.current = null;
      prevAllCollapsed.current = null;
    }
  }, []);

  const [fetchedPhasesByProjectIds, setFetchedPhasesByProjectIds] = useState(
    {}
  );

  const loadPhasesByProjectIds = useCallback(
    (projectIds) => {
      // used prev instead of fetchedPhasesByProjectIds
      // adding it to dependency array will triger chanin reaction and falls into infinite loop
      setFetchedPhasesByProjectIds((prev) => {
        const filteredProjectIds = projectIds.filter((id) => id && !prev[id]);

        if (filteredProjectIds.length) {
          dispatch(
            fetchPhasesByProjectIds({
              projectIds: filteredProjectIds
            })
          );
        }

        return filteredProjectIds.length
          ? { ...prev, ...keyBy(projectIds) }
          : prev;
      });
    },
    [dispatch]
  );

  useEffect(() => {
    const newIds = filter.project_ids.filter(
      (id) => id && !fetchedProjectIds[id]
    );
    if (newIds.length) {
      dispatch(
        fetchAllProjects({
          projectIds: newIds
        })
      );
    }
    if (isProjectPhaseSelector && newIds.length) {
      loadPhasesByProjectIds(newIds);
    }
  }, [
    dispatch,
    fetchedProjectIds,
    filter.project_ids,
    loadPhasesByProjectIds,
    isProjectPhaseSelector
  ]);

  // initial fetch for the projects that shows on top when selecting 'All Projects'
  // there is no fetch actions for those projects in VirtualFilter
  // projectsByPlannerMemberships is the same project list from selector
  // to display initial projects when selecting 'All Projects'
  useEffect(() => {
    if (projectsByPlannerMemberships.length) {
      loadPhasesByProjectIds(projectsByPlannerMemberships);
    }
  }, [loadPhasesByProjectIds, projectsByPlannerMemberships]);

  // when paginating with projects, there is project ids need to come from the response to fetch phasesByProjectIds
  const onSuccessFetchAllProjects = useMemo(
    () => [
      {
        successAction: (projectIds) => {
          if (projectIds.length) {
            loadPhasesByProjectIds(projectIds);
          }
        },
        selector: (payload, response) =>
          response.projects.map((project) => project.id)
      }
    ],
    [loadPhasesByProjectIds]
  );

  const listRef = useRef(null);
  const infiniteLoaderRef = useRef(null);

  const handleAfterSelect = useCallback(() => {
    // closes section and clears search state
    // setEngaged({});
    // setSearch(initialSearchState);
  }, []);

  const handleSetShowArchived = useCallback(
    ({ uid }) => {
      setShowArchived({ ...showArchived, [uid]: !showArchived[uid] });
    },
    [showArchived]
  );

  // activeFilter with non-dependencies omitted. Used for determining
  // when dependencies have changed
  const dependencyFilter = useMemo(() => {
    const keys = crossFieldDependencies?.map(
      (filterListType) => filterListTypeToKey[filterListType]
    );

    const { custom, ...rest } = pick(filter, keys);

    return keys?.length
      ? {
          ...custom,
          ...rest,
          ...(draftFilter && {
            ...pick(
              draftFilter,
              keys.map((key) => key.replace('custom.', ''))
            )
          })
        }
      : emptyObj;
  }, [crossFieldDependencies, filter, draftFilter]);

  // for keeping dependencyFilter out of dependency arrays
  const dependencyFilterEquality = useMemo(
    () =>
      crossFieldDependencies?.length ? JSON.stringify(dependencyFilter) : null,
    [dependencyFilter, crossFieldDependencies?.length]
  );

  const dependencyFilterHasChanged = useHasStateChanged(
    dependencyFilterEquality
  );

  const projectSearchHasChanged = useHasStateChanged(search.projects);

  const projectFilterTabHasChanged = useHasStateChanged(
    filterTabsOverride?.projects
  );

  // handles only array filters right now
  const dependencyFilterHasValues = useMemo(() => {
    if (!crossFieldDependencies) return false;
    return crossFieldDependencies.some((filterListType) => {
      const filterValue = get(
        dependencyFilter,
        filterListTypeToKey[filterListType].replace('custom.', ''),
        []
      );
      if (Array.isArray(filterValue) && filterValue.length) {
        if (filterListType === FilterListType.Billable) {
          return (
            filterValue.length > 0 && filterValue.length < billableItems.length
          );
        }
        return filterValue.length > 0;
      } else if (typeof filterValue === 'boolean') {
        // note: not handling 'false' value currently
        return filterValue;
      }
    });
    // keep dependencyFilter out of the array
  }, [
    crossFieldDependencies,
    dependencyFilterHasChanged,
    billableItems.length
  ]);

  const formattedMembersByBoard = useMemo(() => {
    const boardHash = {};
    const allBoards = Array.from(
      new Set(membersWithBoard.map((member) => member.board_id))
    );
    allBoards.forEach((board) => {
      // member boards may not exist in group hash due to deleted or archived boards
      const currBoard = groupHash[board];
      if (currBoard) {
        boardHash[board] = {
          name: currBoard.name,
          listItems: [],
          accountHash: {}
        };
      }
    });
    membersWithBoard.forEach((member) => {
      const board = boardHash[member.board_id];
      if (board) {
        board.listItems.push(member);
        board.accountHash[member.account.id] = true;
      }
    });
    const formattedBoardArray = Object.keys(boardHash).map((boardKey) => {
      const currBoard = boardHash[boardKey];
      return {
        id: boardKey,
        name: currBoard.name,
        listItems: currBoard.listItems,
        accountHash: currBoard.accountHash
      };
    });
    return formattedBoardArray;
  }, [membersWithBoard, groupHash]);

  const formattedSkills = useMemo(() => {
    // make a hash of skill Ids with id: name, [accounts]
    const skillHash = {};
    skillsArray.forEach((skill) => {
      if (!skill.archived) {
        skillHash[skill.id] = {
          name: skill.name,
          listItems: [],
          accountHash: {}
        };
      }
    });
    skillsArray.forEach((skill) => {
      skill.formattedSkillMembers.forEach((member) => {
        const currSkill = skillHash[skill.id];
        if (currSkill && currSkill.listItems) {
          skillHash[skill.id].listItems.push(member);
          skillHash[skill.id].accountHash[member.account.id] = true;
        }
      });
    });
    const formattedSkillsArray = Object.keys(skillHash).map((skillKey) => {
      const currSkill = skillHash[skillKey];
      return {
        id: skillKey,
        name: currSkill.name,
        listItems: currSkill.listItems,
        accountHash: currSkill.accountHash
      };
    });
    return formattedSkillsArray;
  }, [skillsArray]);

  const formattedOffices = useMemo(() => {
    const formattedOfficesArray = offices.map((office) => {
      return {
        id: office.id,
        name: office.name,
        listItems: office.formattedOfficeMembers,
        accountHash: office.officeMembersHash
      };
    });
    return formattedOfficesArray;
  }, [offices]);

  const formattedRegions = useMemo(() => {
    const formattedRegionsArray = regions.map((region) => {
      return {
        id: region.id,
        name: region.name,
        listItems: region.formattedRegionMembers,
        accountHash: region.regionMembersHash
      };
    });
    return formattedRegionsArray;
  }, [regions]);

  const formattedDisciplines = useMemo(() => {
    const formattedDisciplinesArray = disciplines.map((discipline) => {
      return {
        id: discipline.id,
        name: discipline.name,
        listItems: discipline.formattedDisciplineMembers,
        accountHash: discipline.disciplineMembersHash
      };
    });
    return formattedDisciplinesArray;
  }, [disciplines]);

  const formattedPersonalBoards = useMemo(() => {
    let formattedBoards = personalBoards.map((board) => ({
      ...board,
      isPersonal: true,
      name: PERSONAL_BOARD_NAMES[board.name]
    }));
    if (!isProjectsSidebar) {
      formattedBoards = formattedBoards.filter(
        (board) => board.name !== PERSONAL_BOARD_NAMES.Home
      );
    }
    if (formattedBoards.length && !personalBoardsLoaded.current) {
      if (isProjectsSidebar) {
        const nextIsOpen = { ...isOpen, board: { ...isOpen.board } };
        // Favorites open by default when isProjectsSidebar
        formattedBoards.forEach((board) => {
          if (
            [
              PERSONAL_BOARD_NAMES['Starred Projects']
              // PERSONAL_BOARD_NAMES['UnStarred Projects']
            ].includes(board.name)
          ) {
            nextIsOpen.board[board.id] = true;
          }
        });
        setIsOpen(nextIsOpen);
      }
      personalBoardsLoaded.current = true;
    }
    return formattedBoards.sort((a, b) => (a.name < b.name ? -1 : 1));
  }, [isOpen, isProjectsSidebar, personalBoards]);

  /**
   * filter out boards with no results when user is searching/filtering on project list
   */
  const filteredBoards = useMemo(
    () =>
      search.projects?.length || dependencyFilterHasValues
        ? [...formattedPersonalBoards, ...boards].filter(
            (board) =>
              (
                filterProjectsGroupCounts[board.id] ||
                filterProjectsGroupCounts.default
              )?.total
          )
        : [...formattedPersonalBoards, ...boards],
    [
      boards,
      filterProjectsGroupCounts,
      formattedPersonalBoards,
      search.projects,
      dependencyFilterHasValues
    ]
  );

  const boardIndexHash = useMemo(
    () =>
      [...formattedPersonalBoards, ...boards].reduce((acc, cur, index) => {
        acc[cur.id] = index;
        return acc;
      }, {}),
    [boards, formattedPersonalBoards]
  );

  const projectsByMemberIdsToFetch = useMemo(
    () =>
      projectMemberships && projectHash
        ? Array.from(
            new Set(
              projectMemberships
                .filter(
                  (projectMembership) =>
                    projectMembership.account_id !== null &&
                    !projectHash[projectMembership.project_id] &&
                    (isOpen.projectsByMember?.[projectMembership.account_id] ===
                    undefined
                      ? false
                      : isOpen.projectsByMember[projectMembership.account_id])
                )
                .map((projectMembership) => projectMembership.project_id)
            )
          ).slice(0, 60)
        : [],
    [projectMemberships, projectHash, isOpen?.projectsByMember]
  );

  /* ------------------- used when phsae selector is enabled ------------------ */

  // this is for clicking checkboxes of projects or project itself when there is only a default phase
  const handleProjectClick = useCallback(
    (item, phaseOrder) => {
      const widgetLimitLeft =
        widgetConfig?.limits?.phase_ids &&
        widgetConfig?.limits?.phase_ids - filter.phase_ids.length;

      if (!item.isHeaderAllSelected && widgetLimitLeft <= 0) return null;

      const phasesToAdd = phaseOrder.slice(0, widgetLimitLeft);
      const projectPhases = keyBy(phaseOrder, (phaseId) => phaseId);

      dispatch(
        saveActionToUse({
          ...filter,
          name: viewBy,
          page: pageName,
          project_ids:
            // isSelected is used for projects with default phase
            item.isSelected || item.isHeaderAllSelected
              ? filter.project_ids.filter(
                  (filterItem) => filterItem !== item.id
                )
              : Array.from(new Set([...filter.project_ids, item.id])),
          phase_ids: item.isHeaderAllSelected
            ? filter.phase_ids.filter(
                (filterItem) => !projectPhases[filterItem]
              )
            : Array.from(new Set([...filter.phase_ids, ...phasesToAdd]))
        })
      );
    },
    [
      dispatch,
      filter,
      pageName,
      saveActionToUse,
      viewBy,
      widgetConfig?.limits?.phase_ids
    ]
  );

  // when selecting a single phase
  const handlePhaseClick = useCallback(
    (item) => {
      const projectPhases = keyBy(item.list.phases, (phaseId) => phaseId);
      const nextPhaseIds = item.isSelected
        ? filter.phase_ids.filter((filterItem) => filterItem !== item.row.id)
        : Array.from(new Set([...filter.phase_ids, item.row.id]));
      const isProjectHasSelectedPhases = nextPhaseIds.some(
        (phaseId) => projectPhases[phaseId]
      );
      const nextProjectIds =
        item.isSelected && !isProjectHasSelectedPhases // when the last phase is being unselected
          ? filter.project_ids.filter(
              (filterItem) => filterItem !== item.row.projectId
            )
          : Array.from(new Set([...filter.project_ids, item.row.projectId]));
      dispatch(
        saveActionToUse({
          ...filter,
          name: viewBy,
          page: pageName,
          project_ids: nextProjectIds,
          phase_ids: nextPhaseIds
        })
      );
    },
    [dispatch, filter, pageName, saveActionToUse, viewBy]
  );

  const getProjectRowProps = useCallback(
    ({
      project,
      board = {},
      isDragDisabled = true,
      itemType,
      uid,
      parentId,
      parentItem = {},
      selectionIsDisabled
    }) => {
      const phasesByProjectId = phasesByProjectIdsHash[project.id] || emptyObj;
      const phases = phasesByProjectId.phases || emptyArray;
      const phasesHash = keyBy(phases, (phase) => phase.id);
      const activePhaseOrder = (
        phasesByProjectId.phase_orders || emptyArray
      ).filter((phaseId) => !phasesHash[phaseId].archived);
      const archivedPhaseIds = phasesByProjectId.archivedPhaseIds || emptyArray;
      const isShowingArchived = showArchived[uid];

      const phaseOrder = [
        ...activePhaseOrder,
        ...(isShowingArchived ? archivedPhaseIds : emptyArray)
      ];

      const isFullyLoaded = phases.length;
      const isProjectOpen =
        isOpen[itemType]?.[project.id] === undefined
          ? false
          : isOpen[itemType][project.id];
      const hasOnlyDefaultPhase =
        phases.length === 1 && getIsPhaseLikeDefault(phases[0]);

      const numPhaseSelected = phaseOrder.filter(
        (id) => activeFilterIdHashes.phase_ids?.[id]
      ).length;

      const isArchivedRow = project.id === `${board.id}-showArchived`;
      const isNoActiveProjects = project.id === `${board.id}-noActiveProjects`;

      const listItems = !hasOnlyDefaultPhase
        ? phaseOrder.map((phaseId, index) => {
            return {
              index,
              uid: serializeId({
                ids: [phaseId, project.id],
                itemType: 'phase'
              }),
              projectId: project.id,
              id: phaseId,
              phase: phasesHash[phaseId],
              isDragDisabled: true,
              isFullyLoaded: true,
              isOpen: true,
              rowType: rowTypes?.PhaseRow,
              isCustom: true
            };
          })
        : emptyArray;

      if (!isShowingArchived && archivedPhaseIds.length) {
        listItems.push({
          rowType: components.ShowArchivedRow,
          id: `${project.id}-showArchived`,
          numArchived: archivedPhaseIds.length,
          rowHeight: 40,
          handleClick: () => {
            handleSetShowArchived({ uid });
          },
          isCustom: true,
          isDragDisabled: true,
          dataType: DATATYPES.PHASE
        });
      }

      return {
        getItemId: (item) => item.id,
        uid,
        sectionType: serializeSection({
          parentId,
          sectionId: board.id
        }),
        listItemsSelectionIsDisabled: selectionIsDisabled,
        parentItem,
        isDragDisabled,
        hasOnlyDefaultPhase,
        showSummary: false,
        numPhaseSelected,
        displayCount: phases.length,
        isHeaderAllSelected:
          !!numPhaseSelected &&
          phases.every((phase) => activeFilterIdHashes.phase_ids?.[phase.id]),
        isHeaderSomeSelected:
          !!numPhaseSelected &&
          phases.some((phase) => activeFilterIdHashes.phase_ids?.[phase.id]),
        isItemSelected: (item) => activeFilterIdHashes.phase_ids?.[item.id],
        isFullyLoaded: isFullyLoaded || isArchivedRow,
        loader: ProjectLoader,
        isSubListLoading: !isFullyLoaded,
        isOpen: isProjectOpen,
        hasSubLists:
          !isArchivedRow && !isNoActiveProjects && isProjectPhaseSelector,
        headerType:
          project.rowType || // handles 'Show Archived' and 'No Active Projects' rows
          rowTypes?.ProjectRow ||
          'ProjectRow',
        headerHeight: 50,
        isCustom: !!rowTypes?.ProjectRow,
        isProjectPhaseSelector,
        listItems,
        headerHandleClick: (item) =>
          handleProjectClick(item, [...activePhaseOrder, ...archivedPhaseIds]),
        handleClick: handlePhaseClick
      };
    },
    [
      activeFilterIdHashes.phase_ids,
      handlePhaseClick,
      handleProjectClick,
      handleSetShowArchived,
      isOpen,
      isProjectPhaseSelector,
      phasesByProjectIdsHash,
      rowTypes?.PhaseRow,
      rowTypes?.ProjectRow,
      showArchived
    ]
  );
  /* -------------------------------------------------------------------------- */

  const formattedProjectsByMember = useMemo(() => {
    const memberArr = Object.keys(projectsByMemberId)
      .filter((memberKey) => !!memberHash[memberKey])
      .map((memberKey) => {
        const projectList = projectsByMemberId[memberKey];
        const listItems = [];
        // cut off the list as soon as there is an unloaded project (vs. just filtering out unloaded projects)
        // to prevent list jumping around as some projects might load above the loading row
        for (const projectId of projectList) {
          const project = projectHash[projectId];
          if (project) {
            listItems.push(project);
          } else {
            break;
          }
        }
        return {
          id: memberKey,
          name: memberHash[memberKey]?.account?.name || '',
          listItems: listItems,
          projectHash: keyBy(listItems, 'id'),
          fullProjectList: projectList,
          member: memberHash[memberKey]
        };
      });
    return memberArr;
  }, [projectsByMemberId, projectHash, memberHash]);

  /* ----------------------- project list initial loads ----------------------- */

  /**
   * For fetching initial projects grouped by portfolios (eg. on param changes, not for lazy loading)
   * When full chain is used, project counts for all portfolios are fetched, then the projects
   * (for proper lazy loading as we need to know the right counts). uses initialFetchGroupCountsThenProjectsChainId
   */
  const loadInitialProjectsByBoard = useRef(
    debounce(
      ({ params, hasFiltering, dontFetchCounts }) => {
        const actionsToDispatch = [
          ...(dontFetchCounts
            ? []
            : [
                fetchProjectsIndexV2({
                  ...params,
                  body: { ...params.body, limit: 0 }
                })
              ]),
          ...(hasFiltering ? [fetchProjectsIndexV2(params)] : []) // when no filtering, only fetch the counts and rely on loadMore to fetch the projects
        ];

        if (actionsToDispatch.length) {
          dispatchChain(actionsToDispatch, {
            chainId: initialFetchGroupCountsThenProjectsChainId,
            initial: true,
            takeLatest: true,
            onChainSuccess: hasFiltering ? null : resetLoadMoreCache
          });
        }
      },
      300,
      {
        leading: true, // prevents UI flashing
        trailing: true
      }
    )
  ).current;

  // initial fetch of projects grouped by portfolios (on param changes)
  useEffect(() => {
    if (
      projectFilterListId &&
      // only for projectsByBoard
      !['projects', 'projectsByMember'].includes(
        filterTabsOverride?.projects
      ) &&
      (projectSearchHasChanged ||
        dependencyFilterHasChanged ||
        projectFilterTabHasChanged)
    ) {
      setIsShowingSkeletonLoader(true);
      const hasFiltering = search.projects || dependencyFilterHasValues;

      const params = {
        initial: true,
        filterListId: projectFilterListId,
        boardIndexHash,
        takeLatest: true,
        body: {
          initial: true,
          search_text: search.projects,
          offset: 0,
          limit: 60,
          depth: 'extended',
          is_administrative: false,
          ...(!isProjectsSidebar && { is_personal: false }),
          filter_id: shouldUseFilter ? filter.id : undefined,
          ...(shouldUseFilter
            ? existingFilterFetchParams()
            : newFilterFetchParams()),
          ...(projectFilterListId === 'profit-report' && {
            budget_statuses: [BUDGET_STATUSES.COMPLETE]
          }),
          ...getFinalProjectDependencyFilterParams({
            crossFieldDependencies,
            filter,
            draftFilter,
            dependencyFilterHasValues
          })
        },
        ...(isProjectPhaseSelector && {
          onSuccess: onSuccessFetchAllProjects
        })
      };

      loadInitialProjectsByBoard({
        params,
        hasFiltering,
        dontFetchCounts: search.projects && !dependencyFilterHasValues // when search text param is given, all counts are returned in the response so unnecessary to do separate count fetch
      });

      // when search/filters cleared, reopen the same boards as before search/filters were applied
      if (!hasFiltering) {
        if (prevIsOpen.current) {
          setIsOpen(prevIsOpen.current);
          setAllCollapsed(prevAllCollapsed.current);
          prevIsOpen.current = null;
          prevAllCollapsed.current = null;
        }
        // edge case where if there is 1 search result and search cleared,
        // load more isn't called
        resetLoadMoreCache();
      }
    }
  }, [
    filter,
    draftFilter,
    boardIndexHash,
    dependencyFilterHasChanged,
    dependencyFilterHasValues,
    crossFieldDependencies,
    loadInitialProjectsByBoard,
    filter.id,
    includeOrExclude.board,
    projectFilterListId,
    projectSearchHasChanged,
    search.projects,
    shouldUseFilter,
    filterTabsOverride?.projects,
    isProjectPhaseSelector,
    onSuccessFetchAllProjects,
    projectFilterTabHasChanged,
    setIsShowingSkeletonLoader,
    resetLoadMoreCache,
    isProjectsSidebar
  ]);

  /**
   * For fetching initial projects with no grouping (eg. on param changes, not for lazy loading)
   * uses initialFetchAllProjectsRequestStatusId
   */
  const loadInitialAllProjects = useRef(
    debounce((params) => {
      dispatch(fetchAllProjects(params));
    }, 300)
  ).current;

  /**
   * For fetching initial projects when grouped by members (eg. on param changes, not for lazy loading)
   * uses initialFetchAllProjectsRequestStatusId
   */
  const loadInitialProjectsByMember = useRef(
    debounce((params, hasFiltering) => {
      if (!hasFiltering) {
        if (prevIsOpen.current) {
          setIsOpen(prevIsOpen.current);
          setAllCollapsed(prevAllCollapsed.current);
          prevIsOpen.current = null;
          prevAllCollapsed.current = null;
        }
        // edge case where if there is 1 search result and search cleared,
        // load more isn't called
        if (infiniteLoaderRef.current) {
          infiniteLoaderRef.current.resetloadMoreItemsCache(true);
        }
      }
      dispatch(fetchAllProjectMembers(params));
      if (isProjectPhaseSelector && params.projectIds) {
        loadPhasesByProjectIds(params.projectIds);
      }
    }, 300)
  ).current;

  // initial fetch of projects grouped by member or no group (on param changes)
  useEffect(() => {
    const isAllProjects = filterTabsOverride?.projects === 'projects';
    const isProjectsByMember =
      filterTabsOverride?.projects === 'projectsByMember';
    if (
      (isAllProjects || isProjectsByMember) &&
      (projectSearchHasChanged ||
        dependencyFilterHasChanged ||
        projectFilterTabHasChanged)
    ) {
      setIsShowingSkeletonLoader(true);
      const hasFiltering = search.projects || dependencyFilterHasValues;
      const searchText = search.projects;
      const requestStatusId = initialFetchAllProjectsRequestStatusId;
      const defaultParams = {
        requestStatusId,
        searchText,
        initial: true,
        takeLatest: true,
        is_administrative: false,
        ...(isProjectsSidebar && { is_personal: false }),
        ...getFinalProjectDependencyFilterParams({
          crossFieldDependencies,
          filter,
          draftFilter,
          dependencyFilterHasValues
        })
      };

      if (isAllProjects) {
        loadInitialAllProjects({
          ...defaultParams,
          offset: 0,
          limit: 60,
          filterListId: projectFilterListId,
          ...(projectFilterListId === 'workload' && {
            projectIds: projectsByPlannerMemberships
          })
        });
      } else if (isProjectsByMember) {
        loadInitialProjectsByMember(
          {
            ...defaultParams,
            teamId,
            all: true
          },
          hasFiltering
        );
      }
    }
  }, [
    crossFieldDependencies,
    dependencyFilterHasChanged,
    dependencyFilterHasValues,
    filter,
    draftFilter,
    filterTabsOverride?.projects,
    initialFetchAllProjectsRequestStatusId,
    loadInitialProjectsByMember,
    loadInitialAllProjects,
    projectSearchHasChanged,
    projectsByMemberIdsToFetch,
    search.projects,
    teamId,
    projectFilterListId,
    projectsByPlannerMemberships,
    projectFilterTabHasChanged,
    setIsShowingSkeletonLoader,
    isProjectsSidebar
  ]);

  /* ------------------------------------ - ----------------------------------- */

  const searchCalls = useMemo(
    () => ({
      workGroups: (search) => clearWorkGroupOpenList(search)
    }),
    [clearWorkGroupOpenList]
  );

  const handleSearch = useCallback(
    (name, value) => {
      const apiFnName = filterTabs[name] || name;
      const apiCall = searchCalls[apiFnName];
      if (apiCall) {
        apiCall(value);
      }
    },
    [filterTabs, searchCalls]
  );

  const filteredProjects = useMemo(() => {
    // sort selected to top
    const sortedProjects = projects.sort((a, b) =>
      activeFilterIdHashes.project_ids?.[a.id] &&
      !activeFilterIdHashes.project_ids?.[b.id]
        ? -1
        : 1
    );
    return search.projects?.length
      ? sortedProjects.filter((item) =>
          projectFilter(
            item,
            search.projects.split(' ').filter((str) => str !== '-')
          )
        )
      : sortedProjects;
  }, [projects, search.projects, activeFilterIdHashes]);

  const loadMoreCalls = useMemo(
    () => ({
      projectsByMember: (searchText, item) => {
        // fetchAllProjectMembers gets ALL project ids we are concerned about (includes filter/search params)
        // the fetch here is just for loading the full project data for those ids, which is why there are no
        // filter params

        // fullProjectList holds the ids of projects for the member list that called loadMore
        const fullProjectListOfMember =
          item?.fullProjectList || item?.list?.fullProjectList;

        if (
          filterProjectsFetching ||
          initialFetchAllProjectsRequestStatus?.isLoading ||
          !fullProjectListOfMember
        )
          return;
        const projectIdsToFetch = fullProjectListOfMember.filter(
          (projectId) => !fetchedProjectIds[projectId]
        );
        if (projectIdsToFetch.length) {
          dispatch(
            fetchAllProjects({
              projectIds: projectIdsToFetch.slice(0, 30),
              is_administrative: false,
              offset: 0,
              limit: 30
            })
          );
        }
        if (isProjectPhaseSelector) {
          loadPhasesByProjectIds(projectIdsToFetch);
        }
      },
      projects: (search) => {
        if (
          !initialFetchAllProjectsRequestStatus?.isLoading &&
          !filterProjectsFetching &&
          filterProjectsOffset < filterProjectsCount
        ) {
          const params = {
            requestStatusId: initialFetchAllProjectsRequestStatusId,
            is_administrative: false,
            offset: filterProjectsOffset,
            limit: 60,
            filterListId: projectFilterListId,
            searchText: search,
            ...getFinalProjectDependencyFilterParams({
              crossFieldDependencies,
              filter,
              draftFilter,
              dependencyFilterHasValues
            }),
            ...(isProjectPhaseSelector && {
              onSuccess: onSuccessFetchAllProjects
            })
          };
          if (projectFilterListId === 'workload') {
            dispatch(
              fetchAllProjects({
                ...params,
                projectIds: projectsByPlannerMemberships
              })
            );
          } else {
            dispatch(fetchAllProjects(params));
          }
        }
      },
      projectsByBoard: (searchText, item) => {
        if (initialFetchGroupCountsThenProjectsChainStatus?.isLoading) return;
        const isBoard = item?.dataType === DATATYPES.BOARD;
        if (
          item &&
          filter &&
          !allCollapsed.board &&
          !(isBoard && !isOpen.board[item.id]) &&
          !(item.isFullyLoaded || item.list?.isFullyLoaded)
        ) {
          const isPersonalFetch =
            // DND personal boards project order only for project sidebar and no search
            projectFilterListId === 'projects-sidebar' &&
            !searchText.length &&
            (item.isPersonal || item.list?.isPersonal);

          const includedOrExcludedIds =
            includeOrExclude.board === 'include'
              ? getOpenIds('board')
              : getClosedIds('board');
          let toFetch = Array.from(
            new Set([
              ...Object.keys(filterProjectsGroupCounts).map((id) => +id),
              ...includedOrExcludedIds
            ])
          );
          if (isBoard) {
            // For maintaining proper projectOrder and sending correct offset,
            // only send its id + board_ids that are above this loading board
            const boardIndex = boardIndexHash[item.id];
            toFetch = toFetch.filter(
              (boardId) => boardIndexHash[boardId] <= boardIndex
            );
          }

          if (toFetch.length) {
            const offset = item
              ? isBoard
                ? item.fetchOffset
                : item.index +
                  (item.dataType === DATATYPES.PROJECT ? 1 : 0) +
                  item.list?.fetchOffset
              : 0;

            // loadMore will be called on every scroll. this prevents calling with same offset multiple times
            if (filterProjectsFetching && offset) {
              return;
            }

            const nextHasFetched = {
              member: {},
              board: {
                ...hasFetched.board
              }
            };
            includedOrExcludedIds.forEach(
              (id) => (nextHasFetched.board[id] = true)
            );
            setHasFetched(nextHasFetched);
            dispatch(
              fetchProjectsIndexV2({
                initial: offset === 0,
                filterListId: projectFilterListId,
                boardId: isBoard ? item.id : item.list.id,
                boardIndexHash,
                takeLatest: true,
                body: {
                  search_text: searchText,
                  offset: offset,
                  limit: 60,
                  depth: 'extended',
                  ...(!isProjectsSidebar && { is_personal: false }),
                  is_administrative: false,
                  // board_ids: isPersonalFetch
                  //   ? toFetch.filter(id => personalBoardsHash[+id])
                  //   : toFetch,
                  ...(isPersonalFetch && {
                    board_ids: toFetch.filter((id) => personalBoardsHash[+id])
                  }),
                  board_ids_filter_type: includeOrExclude.board,
                  filter_id: shouldUseFilter ? filter.id : undefined,
                  ...(shouldUseFilter
                    ? existingFilterFetchParams(isPersonalFetch)
                    : newFilterFetchParams(isPersonalFetch)),
                  ...(projectFilterListId === 'profit-report' && {
                    budget_statuses: [BUDGET_STATUSES.COMPLETE]
                  }),
                  ...getFinalProjectDependencyFilterParams({
                    crossFieldDependencies,
                    filter,
                    draftFilter,
                    dependencyFilterHasValues
                  })
                },
                ...(isProjectPhaseSelector && {
                  onSuccess: onSuccessFetchAllProjects
                })
              })
            );
          }
        }
      }
    }),
    [
      allCollapsed.board,
      boardIndexHash,
      crossFieldDependencies,
      dependencyFilterHasValues,
      dispatch,
      filter,
      draftFilter,
      filterProjectsFetching,
      filterProjectsGroupCounts,
      getClosedIds,
      getOpenIds,
      hasFetched.board,
      includeOrExclude.board,
      initialFetchAllProjectsRequestStatusId,
      initialFetchAllProjectsRequestStatus?.isLoading,
      initialFetchGroupCountsThenProjectsChainStatus?.isLoading,
      isOpen.board,
      isProjectPhaseSelector,
      onSuccessFetchAllProjects,
      personalBoardsHash,
      filterProjectsCount,
      projectFilterListId,
      filterProjectsOffset,
      shouldUseFilter,
      fetchedProjectIds,
      loadPhasesByProjectIds,
      projectsByPlannerMemberships,
      isProjectsSidebar
    ]
  );

  const formattedPersonalBoardsEquality = useMemo(
    () => JSON.stringify(formattedPersonalBoards),
    [formattedPersonalBoards]
  );
  useEffect(() => {
    if (formattedPersonalBoards.length && isProjectPhaseSelector) {
      const favoriteBoard = formattedPersonalBoards.find(
        (board) => board.slug === 'starredprojects'
      );
      const projectIds = favoriteBoard
        ? [
            ...favoriteBoard.project_position,
            ...favoriteBoard.archived_project_position
          ].filter((projectId) => projectId)
        : emptyArray;

      if (projectIds.length) {
        loadPhasesByProjectIds(projectIds);
      }
    }
    // formattedPersonalBoards is not included which causes infinite fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formattedPersonalBoardsEquality,
    loadPhasesByProjectIds,
    isProjectPhaseSelector
  ]);

  const filteredMembers = useMemo(
    () =>
      search.members?.length
        ? members.filter((item) =>
            memberFilter(
              item,
              search.members.split(' ').filter((str) => str !== '-')
            )
          )
        : members,
    [members, search.members]
  );

  const filteredMembersHash = useMemo(
    () => keyBy(filteredMembers, (item) => item.account.id),
    [filteredMembers]
  );

  const filteredProjectsHash = useMemo(
    () => keyBy(filteredProjects, (item) => item.id),
    [filteredProjects]
  );

  const positionsWithEntireOrg = useMemo(() => {
    const positionsWithEntireOrg = [...positions];
    positionsWithEntireOrg.splice(filter.position_ids.length, 0, {
      id: 'entire-org',
      name: 'Entire Org'
    });
    return additionalFilterOptions.includeEntireOrg
      ? positionsWithEntireOrg
      : undefined;
  }, [
    additionalFilterOptions.includeEntireOrg,
    filter.position_ids.length,
    positions
  ]);

  const positionsToUse = positionsWithEntireOrg || positions;

  const filteredPositions = useMemo(
    () =>
      search.positions?.length
        ? positionsToUse.filter((item) =>
            positionFilter(
              item,
              search.positions.split(' ').filter((str) => str !== '-')
            )
          )
        : positionsToUse,
    [positionsToUse, search.positions]
  );

  const formattedMembersByPosition = useMemo(() => {
    // make a hash of position Ids with id: name, [accounts]
    const positionHash = {};
    positions.forEach((position) => {
      if (!position.discarded_at) {
        positionHash[position.id] = {
          name: position.name,
          listItems: [],
          accountHash: {}
        };
      }
    });
    Object.keys(accountsByPosition).forEach((accountId) => {
      const currAccount = accountsByPosition[accountId];
      const currPosition = positionHash[currAccount.position_id];
      if (currPosition && currPosition.listItems) {
        currPosition.listItems.push(currAccount);
        currPosition.accountHash[accountId] = true;
      }
    });
    const positionArr = Object.keys(positionHash).map((positionKey) => {
      const currPosition = positionHash[positionKey];
      return {
        id: positionKey,
        name: currPosition.name,
        listItems: currPosition.listItems,
        accountHash: currPosition.accountHash
      };
    });
    return positionArr;
  }, [positions, accountsByPosition]);

  const filteredPhases = useMemo(
    () =>
      search.phases?.length
        ? phaseNames.filter((item) =>
            phaseFilter(
              item,
              search.phases.split(' ').filter((str) => str !== '-')
            )
          )
        : phaseNames,
    [phaseNames, search.phases]
  );

  const filteredActivities = useMemo(
    () =>
      search.activities?.length
        ? activities.filter((item) =>
            activityFilter(
              item,
              search.activities.split(' ').filter((str) => str !== '-')
            )
          )
        : activities,
    [activities, search.activities]
  );

  const filteredClients = useMemo(
    () =>
      search.clients?.length
        ? clients.filter((item) =>
            clientFilter(
              item,
              search.clients.split(' ').filter((str) => str !== '-')
            )
          )
        : clients.filter((item) => item),
    [clients, search.clients]
  );

  const filteredWorkGroups = useMemo(
    () =>
      search.members.length
        ? workGroups.filter(
            (workGroup) =>
              !workGroup.discarded_at &&
              workGroup.formattedWorkGroupMembers.filter(
                (workGroupMember) =>
                  filteredMembersHash[workGroupMember.member.account.id]
              ).length
          )
        : workGroups.filter((workGroup) => !workGroup.discarded_at),
    [filteredMembersHash, search.members.length, workGroups]
  );

  const filteredSkills = useMemo(
    () =>
      search.members.length
        ? formattedSkills.filter(
            (skill) =>
              skill.listItems.filter(
                (member) => filteredMembersHash[member.account.id]
              ).length
          )
        : formattedSkills,
    [search.members.length, formattedSkills, filteredMembersHash]
  );

  const filteredOffices = useMemo(
    () =>
      search.members.length
        ? formattedOffices.filter(
            (office) =>
              office.listItems.filter(
                (member) => filteredMembersHash[member.account.id]
              ).length
          )
        : formattedOffices,
    [filteredMembersHash, formattedOffices, search.members.length]
  );

  const filteredRegions = useMemo(
    () =>
      search.members.length
        ? formattedRegions.filter(
            (region) =>
              region.listItems.filter(
                (member) => filteredMembersHash[member.account.id]
              ).length
          )
        : formattedRegions,
    [filteredMembersHash, formattedRegions, search.members.length]
  );

  const filteredDisciplines = useMemo(
    () =>
      search.members.length
        ? formattedDisciplines.filter(
            (discipline) =>
              discipline.listItems.filter(
                (member) => filteredMembersHash[member.account.id]
              ).length
          )
        : formattedDisciplines,
    [filteredMembersHash, formattedDisciplines, search.members.length]
  );

  const filteredMembersByPosition = useMemo(
    () =>
      search.members.length
        ? formattedMembersByPosition.filter(
            (position) =>
              position.listItems.filter(
                (member) => filteredMembersHash[member.account_id]
              ).length
          )
        : formattedMembersByPosition,
    [search.members.length, formattedMembersByPosition, filteredMembersHash]
  );

  const filteredProjectsByMember = useMemo(
    () =>
      search.projects.length
        ? formattedProjectsByMember.filter(
            (member) =>
              member.listItems.filter((project) => projectHash[project.id])
                .length
          )
        : formattedProjectsByMember,
    [search.projects.length, formattedProjectsByMember, projectHash]
  );

  const handleClearAccountIdsFilter = useCallback(
    () =>
      dispatch(
        saveActionToUse({
          ...filter,
          account_ids: []
        })
      ),
    [dispatch, filter, saveActionToUse]
  );

  const onlySelectedMemberName = useMemo(() => {
    return filter.account_ids.length === 1
      ? memberHash[filter.account_ids[0]]?.account.name
      : null;
  }, [filter.account_ids, memberHash]);

  const lists = useMemo(() => {
    const { itemType: dragItemType, itemId: dragItemId } = deserializeBar(
      isDragging && currentDragItem?.current ? currentDragItem.current : ''
    );
    // currently only project field may have draft filter ( for planner tab )
    const projectFieldToUse = draftFilter?.project_ids || filter.project_ids;
    // For preventing selection when widget has reached max selectable limit
    const widgetLimits = widgetConfig?.limits || {};
    const widgetMemberSelectionDisabled =
      filter.account_ids?.length >= widgetLimits.account_ids;
    const widgetPositionSelectionDisabled =
      filter.position_ids?.length >= widgetLimits.position_ids;
    const widgetProjectSelectionDisabled =
      projectFieldToUse?.length >= widgetLimits.project_ids;
    const widgetClientSelectionDisabled =
      filter.client_ids?.length >= widgetLimits.client_ids;
    const widgetPhaseSelectionDisabled =
      filter.phase_ids?.length >= widgetLimits.phase_ids;

    const memberList = {
      listItems: showArchived.allMembers
        ? [
            ...filteredMembers,
            {
              rowType: components.HideArchivedRow,
              id: `allMembers-hideArchived`,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid: `allMembers` });
              },
              isCustom: true,
              isDragDisabled: true
            }
          ]
        : [
            ...filteredMembers.filter(
              (member) =>
                !member.is_archived ||
                activeFilterIdHashes.account_ids?.[member.account?.id]
            ),
            {
              rowType: components.ShowArchivedRow,
              id: `allMembers-showArchived`,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid: `allMembers` });
              },
              isCustom: true,
              isDragDisabled: true,
              numArchived: filteredMembers.filter(
                (member) => member.is_archived
              ).length
            }
          ],
      getItemId: (item) => item.account?.id || item.id,
      isItemSelected: (item) =>
        activeFilterIdHashes.account_ids?.[item.account?.id],
      summary: Summary,
      listItemsSelectionIsDisabled: widgetMemberSelectionDisabled,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            account_ids: item.isSelected
              ? filter.account_ids.filter(
                  (filterItem) => filterItem !== item.row.account.id
                )
              : Array.from(
                  new Set([...filter.account_ids, item.row.account.id])
                )
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        handleClearAccountIdsFilter();
        handleAfterSelect();
      },
      rowType: 'MemberRow',
      headerType: 'Header',
      isFullyLoaded: members.length,
      id: 'members',
      uid: serializeBar({
        itemId: 'members',
        itemType: 'members'
      }),
      stickyHeaderType: 'members',
      customItems: customItems.memberList || [
        VirtualFilterMemberTabs,
        MemberSearch,
        Clear
      ],
      showOnEngaged: false,
      isOpen:
        isOpen.members?.members === undefined
          ? sectionsStartOpen
          : isOpen.members.members,
      rowHeight: 44,
      renderHeader: () => sectionToLabel.members || 'Member',
      skipHeader: true,
      numberOfSelectedItems: filter?.account_ids?.length,
      summaryNoun: 'Members',
      renderSummaryItem: (item) => item?.account?.name
      // itemLeft: '10px'
    };

    const stubWorkGroups = {
      'no department': true,
      'archived members': true
    };
    const workGroupList = {
      listItems: [
        ...filteredWorkGroups.map((workGroup) => {
          const uid = serializeBar({
            itemId: workGroup.id,
            itemType: 'workGroups'
          });
          const isShowingArchived = showArchived[uid];
          const workGroupListItems = isShowingArchived
            ? workGroup.formattedWorkGroupMembers
                .map((workGroupMember) => workGroupMember.member)
                .filter((member) => filteredMembersHash[member.account.id])
                .sort((a, b) => (!a.is_archived && b.is_archived ? -1 : 1))
            : workGroup.formattedWorkGroupMembers
                .map((workGroupMember) => workGroupMember.member)
                .filter((member) => filteredMembersHash[member.account.id])
                .filter((member) => !member.is_archived);

          const archivedMembersLength = workGroup.formattedWorkGroupMembers
            .map((workGroupMember) => workGroupMember.member)
            .filter((member) => filteredMembersHash[member.account.id])
            .filter((member) => member.is_archived).length;

          if (!isShowingArchived && archivedMembersLength > 0) {
            workGroupListItems.push({
              rowType: components.ShowArchivedRow,
              id: `${workGroup.id}-showArchived`,
              numArchived: archivedMembersLength,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid });
              },
              isCustom: true,
              isDragDisabled: true
            });
          } else if (isShowingArchived && archivedMembersLength > 0) {
            workGroupListItems.push({
              rowType: components.HideArchivedRow,
              id: `${workGroup.id}-hideArchived`,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid });
              },
              isCustom: true,
              isDragDisabled: true
            });
          }

          return {
            ...workGroup,
            uid,
            hasSubLists: true,
            isFullyLoaded:
              !!workGroupMemberships[workGroup.id] ||
              stubWorkGroups[workGroup.id],
            isOpen:
              isOpen.workGroups?.[workGroup.id] === undefined &&
              !search.members &&
              filteredWorkGroups.length > 2
                ? false
                : isOpen.workGroups?.[workGroup.id] === undefined
                ? sectionsStartOpen
                : isOpen.workGroups[workGroup.id],
            selectionIsDisabled: search?.members?.length,
            listItemsSelectionIsDisabled: widgetMemberSelectionDisabled,
            listItems: workGroupListItems,
            getItemId: (item) => item.account?.id || item.id,
            numSelected: workGroup.formattedWorkGroupMembers.filter(
              (workGroupMember) =>
                activeFilterIdHashes.account_ids?.[
                  workGroupMember.member.account.id
                ]
            ).length,
            isHeaderAllSelected:
              workGroup.formattedWorkGroupMembers.length &&
              workGroup.formattedWorkGroupMembers.every(
                (workGroupMember) =>
                  activeFilterIdHashes.account_ids?.[
                    workGroupMember.member.account.id
                  ]
              ),
            isHeaderSomeSelected:
              workGroup.formattedWorkGroupMembers &&
              workGroup.formattedWorkGroupMembers.some(
                (workGroupMember) =>
                  activeFilterIdHashes.account_ids?.[
                    workGroupMember.member.account.id
                  ]
              ),
            isItemSelected: (item) =>
              activeFilterIdHashes.account_ids?.[item.account?.id],
            showSummary: false,
            summary: Summary,
            headerHandleClick: (item) => {
              const bulkAccountIdsToUse = uniq([
                ...filter.account_ids,
                ...item.listItems.map(
                  (workGroupMember) => workGroupMember.account?.id
                )
              ]);

              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isHeaderAllSelected
                    ? filter.account_ids.filter(
                        (filterItem) =>
                          !workGroup.workGroupMembersHash[filterItem]
                      )
                    : widgetLimits.account_ids
                    ? bulkAccountIdsToUse.slice(0, widgetLimits.account_ids)
                    : bulkAccountIdsToUse
                })
              );
            },
            handleClick: (item) => {
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isSelected
                    ? filter.account_ids.filter(
                        (filterItem) => filterItem !== item.row.account.id
                      )
                    : Array.from(
                        new Set([...filter.account_ids, item.row.account.id])
                      )
                })
              );
              handleAfterSelect();
            },
            handleClear: () => {
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: []
                })
              );
              handleAfterSelect();
            },
            sectionType: 'workGroups',
            headerType: 'WorkGroupRow',
            headerHeight: 50,
            renderHeader: () => workGroup.name,
            rowType: 'MemberRow',
            itemLeft: '24px',
            headerLeft: 0,
            headerLeftOffset
            // skipHeader: true
          };
        }),
        ...(!isStackedFilter
          ? [
              {
                rowType: components.AddDepartmentRow,
                id: `addDepartmentRow`,
                rowHeight: 40,
                handleClick: () => {
                  dispatch(
                    navigateToTeamSettings({
                      teamSlug: team && team?.slug,
                      viewType: 'members',
                      tab: 'departments',
                      openInNewWindow: true
                    })
                  );
                },
                isCustom: true,
                isDragDisabled: true
              }
            ]
          : [])
      ],
      getItemId: (item) => item.id,
      isItemSelected: (item) =>
        item.work_group_memberships?.every(
          (membership) =>
            activeFilterIdHashes.account_ids?.[membership.account_id]
        ),
      summary: Summary,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewBy,
            page: pageName,
            account_ids: item.isSelected
              ? filter.account_ids.filter(
                  (filterItem) => filterItem !== item.row.account.id
                )
              : Array.from(
                  new Set([...filter.account_ids, item.row.account.id])
                )
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        handleClearAccountIdsFilter();
        handleAfterSelect();
      },
      rowType: 'WorkGroupRow',
      headerType: 'Header',
      isFullyLoaded: members.length,
      id: 'workGroups',
      uid: serializeBar({
        itemId: 'workGroups',
        itemType: 'members'
      }),
      stickyHeaderType: 'members',
      customItems: customItems.workGroupList || [
        VirtualFilterMemberTabs,
        MemberSearch,
        Clear
      ],
      showOnEngaged: false,
      isOpen:
        isOpen.members?.members === undefined
          ? sectionsStartOpen
          : isOpen.members.members,
      rowHeight: 44,
      renderHeader: () => sectionToLabel.members || 'Member',
      skipHeader: true,
      numberOfSelectedItems: filter?.account_ids?.length,
      onlySelectedItemLabel: onlySelectedMemberName,
      summaryNoun: 'Members',
      renderSummaryItem: (item) => item?.account?.name
    };

    const positionList = {
      listItems: filteredPositions,
      getItemId: (item) => item.id,
      selectionIsDisabled:
        search?.positions?.length || widgetLimits.position_ids > 0,
      listItemsSelectionIsDisabled: widgetPositionSelectionDisabled,
      isItemSelected: (item) =>
        activeFilterIdHashes.position_ids?.[item.id] ||
        (item.id === 'entire-org' && !filter.position_ids.length),
      summary: Summary,
      isSingleSelect: additionalFilterOptions.isSingleSelect,
      handleClick: (item) => {
        const { includeEntireOrg, isSingleSelect } = additionalFilterOptions;
        const isEntireOrgSelected =
          includeEntireOrg && item.row.id === 'entire-org';
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            position_ids: isEntireOrgSelected
              ? []
              : isSingleSelect
              ? [item.row.id]
              : item.isSelected
              ? (filter.position_ids || []).filter(
                  (filterItem) => filterItem !== item.row.id
                )
              : Array.from(
                  new Set([...(filter.position_ids || []), item.row.id])
                )
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewBy,
            page: pageName,
            position_ids: []
          })
        );
        handleAfterSelect();
      },
      rowType: 'PositionRow',
      headerType: 'Header',
      headerHeight: 50,
      isFullyLoaded: positions.length,
      id: 'positions',
      uid: serializeBar({
        itemId: 'positions',
        itemType: 'positions'
      }),
      stickyHeaderType: 'positions',
      customItems: customItems.positionList || [PositionSearch, Clear],
      showOnEngaged: false,
      isOpen:
        isOpen.positions?.positions === undefined
          ? sectionsStartOpen
          : isOpen.positions.positions,
      rowHeight: 44,
      renderHeader: () => 'Roles',
      skipHeader: true,
      numberOfSelectedItems: filter?.position_ids?.length,
      summaryNoun: 'Roles',
      renderSummaryItem: (item) => item?.name
      // itemLeft: '10px'
    };

    const membersByPositionList = {
      listItems: [
        ...filteredMembersByPosition.map((position) => {
          const uid = serializeBar({
            itemId: position.id,
            itemType: 'membersByPosition'
          });
          const isShowingArchived = showArchived[uid];

          const positionMembers = position.listItems
            .filter((member) => filteredMembersHash[member.account_id])
            .map((member) => filteredMembersHash[member.account_id]);

          const positionListItems = isShowingArchived
            ? positionMembers.sort((a, b) =>
                !a.is_archived && b.is_archived ? -1 : 1
              )
            : positionMembers.filter((member) => !member.is_archived);

          const archivedMembersLength = positionMembers.filter(
            (member) => member.is_archived
          ).length;

          const activeMembersLength =
            positionMembers.length - archivedMembersLength;

          if (!isShowingArchived && archivedMembersLength > 0) {
            positionListItems.push({
              rowType: components.ShowArchivedRow,
              id: `${position.id}-showArchived`,
              numArchived: archivedMembersLength,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid });
              },
              isCustom: true,
              isDragDisabled: true
            });
          } else if (isShowingArchived && archivedMembersLength > 0) {
            positionListItems.push({
              rowType: components.HideArchivedRow,
              id: `${position.id}-hideArchived`,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid });
              },
              isCustom: true,
              isDragDisabled: true
            });
          }

          return {
            ...position,
            uid,
            hasArchivedMembers: archivedMembersLength > 0,
            hasSubLists: true,
            isFullyLoaded: true,
            isOpen:
              isOpen.membersByPosition?.[position.id] === undefined &&
              !search.members &&
              filteredMembersByPosition.length > 2
                ? false
                : isOpen.membersByPosition?.[position.id] === undefined
                ? sectionsStartOpen
                : isOpen.membersByPosition[position.id],
            selectionIsDisabled: search?.members?.length,
            listItemsSelectionIsDisabled: widgetMemberSelectionDisabled,
            listItems: positionListItems,
            getItemId: (item) => item.account_id || item.id,
            numSelected: position.listItems.filter(
              (positionMember) =>
                activeFilterIdHashes.account_ids?.[positionMember.account_id] &&
                (!filteredMembersHash[positionMember.account_id]?.is_archived ||
                  isShowingArchived)
            ).length,
            isHeaderAllSelected:
              position.listItems.length &&
              (isShowingArchived
                ? position.listItems.every(
                    (positionMember) =>
                      activeFilterIdHashes.account_ids?.[
                        positionMember.account_id
                      ]
                  )
                : activeMembersLength > 0 &&
                  position.listItems.every(
                    (positionMember) =>
                      activeFilterIdHashes.account_ids?.[
                        positionMember.account_id
                      ] ||
                      filteredMembersHash[positionMember.account_id]
                        ?.is_archived
                  )),
            isHeaderSomeSelected:
              position.listItems.length &&
              position.listItems.some(
                (positionMember) =>
                  activeFilterIdHashes.account_ids?.[positionMember.account_id]
              ),
            isItemSelected: (item) =>
              activeFilterIdHashes.account_ids?.[item.account?.id],
            showSummary: false,
            summary: Summary,
            headerHandleClick: (item) => {
              const bulkAccountIdsToUse = uniq([
                ...filter.account_ids,
                ...item.listItems.map((member) => member.account?.id)
              ]);
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isHeaderAllSelected
                    ? filter.account_ids.filter(
                        (filterItem) => !position.accountHash[filterItem]
                      )
                    : widgetLimits.account_ids
                    ? bulkAccountIdsToUse.slice(0, widgetLimits.account_ids)
                    : bulkAccountIdsToUse
                })
              );
            },
            handleClick: (item) => {
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isSelected
                    ? filter.account_ids.filter(
                        (filterItem) => filterItem !== item.row.account.id
                      )
                    : Array.from(
                        new Set([...filter.account_ids, item.row.account.id])
                      )
                })
              );
              handleAfterSelect();
            },
            handleClear: () => {
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: []
                })
              );
              handleAfterSelect();
            },
            sectionType: 'membersByPosition',
            headerType: 'MemberByPositionRow',
            headerHeight: 50,
            renderHeader: () => position.name,
            rowType: 'MemberRow',
            itemLeft: '24px',
            headerLeft: 0,
            headerLeftOffset
            // skipHeader: true
          };
        }),
        ...(!isStackedFilter
          ? [
              {
                rowType: components.AddRoleRow,
                id: `addRoleRow`,
                rowHeight: 40,
                handleClick: () => {
                  dispatch(
                    navigateToTeamSettings({
                      teamSlug: team && team?.slug,
                      viewType: 'standards',
                      tab: 'roles',
                      openInNewWindow: true
                    })
                  );
                },
                isCustom: true,
                isDragDisabled: true
              }
            ]
          : [])
      ],
      getItemId: (item) => item.id,
      selectionIsDisabled:
        search?.positions?.length || widgetLimits.position_ids > 0,
      listItemsSelectionIsDisabled: widgetPositionSelectionDisabled,
      isItemSelected: (item) => activeFilterIdHashes.position_ids?.[item.id],
      summary: Summary,
      showSummary: false,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            position_ids: item.isSelected
              ? (filter.position_ids || []).filter(
                  (filterItem) => filterItem !== item.row.id
                )
              : Array.from(
                  new Set([...(filter.position_ids || []), item.row.id])
                )
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        handleClearAccountIdsFilter();
        handleAfterSelect();
      },
      rowType: 'MemberByPositionRow',
      headerType: 'Header',
      skipHeader: !isStackedFilter,
      isFullyLoaded: positions.length,
      id: 'membersByPosition',
      uid: serializeBar({
        itemId: 'members',
        itemType: 'members'
      }),
      stickyHeaderType: 'membersByPosition',
      customItems: customItems.positionList || [
        VirtualFilterMemberTabs,
        MemberSearch,
        Clear
      ],
      showOnEngaged: false,
      isOpen:
        isOpen.members?.members === undefined
          ? sectionsStartOpen
          : isOpen.members.members,
      rowHeight: 44,
      renderHeader: () => sectionToLabel.members || 'Member',
      numberOfSelectedItems: filter?.account_ids?.length,
      onlySelectedItemLabel: onlySelectedMemberName,
      summaryNoun: 'Members',
      renderSummaryItem: (item) => item?.name
      // itemLeft: '10px'
    };
    const projectsByMemberList = {
      listItems: filteredProjectsByMember.map((member, index) => {
        const uid = serializeBar({
          itemId: member.id,
          itemType: 'projectsByMember'
        });
        const isShowingArchived = showArchived[uid];

        const memberListItems = isShowingArchived
          ? member.listItems
              .filter((project) => project?.id && projectHash[project?.id])
              .sort((a, b) => {
                if (
                  activeFilterIdHashes.project_ids[a.id] &&
                  !activeFilterIdHashes.project_ids[b.id]
                ) {
                  return -1;
                } else if (!a.is_archived && b.is_archived) {
                  return -1;
                }
                return 1;
              })
          : member.listItems
              .filter((project) => !project.is_archived)
              .sort((a, b) => {
                if (
                  activeFilterIdHashes.project_ids[a.id] &&
                  !activeFilterIdHashes.project_ids[b.id]
                ) {
                  return -1;
                } else {
                  return 1;
                }
              });
        const memberListItemsToUse = isProjectPhaseSelector
          ? memberListItems.map((project) => {
              const itemType = 'projectsByMember';

              return {
                ...project,
                ...(isProjectPhaseSelector &&
                  getProjectRowProps({
                    project,
                    board: project.board,
                    isDragDisabled: true,
                    itemType,
                    uid: serializeId({
                      ids: [project.id, project.board?.id],
                      itemType
                    }),
                    parentId: itemType,
                    selectionIsDisabled: widgetPhaseSelectionDisabled
                  }))
              };
            })
          : memberListItems.map((project) => ({
              ...project,
              rowType: rowTypes?.ProjectRow || 'ProjectRow',
              isCustom: !!rowTypes?.ProjectRow,
              selectionIsDisabled: widgetProjectSelectionDisabled,
              isSingleSelect: additionalFilterOptions.isSingleSelect
            }));

        const archivedProjectIds = member.listItems
          .filter((project) => project.is_archived)
          .map((project) => project.id);
        const archivedProjectsLength = archivedProjectIds.length;

        if (!isShowingArchived && archivedProjectsLength > 0) {
          memberListItemsToUse.push({
            rowType: components.ShowArchivedRow,
            id: `${member.id}-showArchived`,
            numArchived: archivedProjectsLength,
            rowHeight: 20,
            handleClick: () => {
              handleSetShowArchived({ uid });
              if (isProjectPhaseSelector) {
                loadPhasesByProjectIds(archivedProjectIds);
              }
            },
            isCustom: true,
            isDragDisabled: true
          });
        } else if (isShowingArchived && archivedProjectsLength > 0) {
          memberListItemsToUse.push({
            rowType: components.HideArchivedRow,
            id: `${member.id}-hideArchived`,
            rowHeight: 20,
            handleClick: () => {
              handleSetShowArchived({ uid });
            },
            isCustom: true,
            isDragDisabled: true
          });
        }

        const isMemberOpen =
          isOpen.projectsByMember?.[member.id] === undefined
            ? false
            : isOpen.projectsByMember[member.id];

        return {
          ...member,
          index,
          loader: ProjectLoader,
          dataType: DATATYPES.MEMBER,
          uid,
          sectionType: serializeSection({
            parentId: 'projectsByMember',
            sectionId: member.id
          }),
          fullProjectList: member.fullProjectList,
          hasArchivedProjects: archivedProjectsLength > 0,
          hasSubLists: true,
          isFullyLoaded: !(
            member.listItems.length < member.fullProjectList.length
          ),
          isOpen: isMemberOpen,
          selectionIsDisabled: search?.projects?.length,
          listItemsSelectionIsDisabled:
            widgetMemberSelectionDisabled || widgetProjectSelectionDisabled,
          listItems: memberListItemsToUse,
          getItemId: (item) => item.id,
          numSelected: member.fullProjectList.filter(
            (projectId) => activeFilterIdHashes.project_ids?.[projectId]
          ).length,
          isHeaderAllSelected:
            member.fullProjectList.length &&
            member.fullProjectList.every((projectId) => {
              return activeFilterIdHashes.project_ids?.[projectId];
            }),
          isHeaderSomeSelected:
            member.fullProjectList.length &&
            member.fullProjectList.some(
              (projectId) => activeFilterIdHashes.project_ids?.[projectId]
            ),
          isItemSelected: (item) => activeFilterIdHashes.project_ids?.[item.id],
          showSummary: false,
          summary: Summary,
          headerHandleClick: (item) => {
            if (additionalFilterOptions.isSingleSelect) return;

            const fieldToUse = draftFilter?.project_ids || filter.project_ids;

            const bulkAccountIdsToUse = uniq([
              ...fieldToUse,
              ...item.fullProjectList.map((projectId) => projectId)
            ]);

            const nextProjectIds =
              item.isHeaderAllSelected ||
              (widgetLimits.project_ids &&
                fieldToUse.length >= widgetLimits.project_ids)
                ? fieldToUse.filter(
                    (filterItem) => !member.projectHash[filterItem]
                  )
                : widgetLimits.account_ids
                ? bulkAccountIdsToUse.slice(0, widgetLimits.account_ids)
                : bulkAccountIdsToUse;

            if (draftFilter) {
              draftFilter.update({ project_ids: nextProjectIds });
            } else {
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  project_ids: nextProjectIds
                })
              );
            }
          },
          handleClick: (item) => {
            const fieldToUse = draftFilter?.project_ids || filter.project_ids;

            const nextProjectIds = additionalFilterOptions.isSingleSelect
              ? [item.id]
              : item.isSelected
              ? fieldToUse.filter((filterItem) => filterItem !== item.id)
              : Array.from(new Set([...fieldToUse, item.id]));

            if (draftFilter) {
              draftFilter.update({ project_ids: nextProjectIds });
            } else {
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  project_ids: nextProjectIds
                })
              );
            }
            handleAfterSelect();
          },
          headerType: 'ProjectByMemberRow',
          renderHeader: () => member.name,
          rowType: 'ProjectRow',
          itemLeft: '24px',
          headerLeft: 0,
          headerLeftOffset,
          isDragDisabled: true,
          shouldHideCheckbox: additionalFilterOptions.isSingleSelect
          // skipHeader: true
        };
      }),
      getItemId: (item) => item.id,
      selectionIsDisabled:
        search?.projects?.length || widgetLimits.project_ids > 0,
      listItemsSelectionIsDisabled: widgetPositionSelectionDisabled,
      isItemSelected: (item) => activeFilterIdHashes.project_ids?.[item.id],
      summary: Summary,
      rowType: 'ProjectByMemberRow',
      headerType: 'Header',
      isFullyLoaded: members.length,
      id: 'projectsByMember',
      uid: serializeBar({
        itemId: 'projectsByMember',
        itemType: 'projectsByMember'
      }),
      dataType: DATATYPES.MEMBER,
      stickyHeaderType: 'projectsByMember',
      customItems: customItems.projectList || [
        VirtualFilterProjectTabs,
        ProjectSearch,
        Clear
      ],
      showOnEngaged: false,
      isOpen:
        isOpen.projectsByMember?.projectsByMember === undefined
          ? sectionsStartOpen
          : isOpen.projectsByMember?.projectsByMember,
      rowHeight: 44,
      renderHeader: () => 'Members',
      skipHeader: true,
      numberOfSelectedItems: filter?.project_ids?.length,
      summaryNoun: 'Members',
      renderSummaryItem: (item) => item?.name

      // itemLeft: '10px'
    };

    const skillList = {
      listItems: [
        ...filteredSkills.map((skill) => {
          const uid = serializeBar({
            itemId: skill.id,
            itemType: 'skills'
          });
          const isShowingArchived = showArchived[uid];
          const skillListItems = isShowingArchived
            ? skill.listItems
                .filter((member) => filteredMembersHash[member.account.id])
                .map((member) => filteredMembersHash[member.account.id])
                .sort((a, b) => (!a.is_archived && b.is_archived ? -1 : 1))
            : skill.listItems
                .filter((member) => filteredMembersHash[member.account.id])
                .map((member) => filteredMembersHash[member.account.id])
                .filter((member) => !member.is_archived);

          const archivedMembersLength = skill.listItems
            .filter((member) => filteredMembersHash[member.account.id])
            .map((member) => filteredMembersHash[member.account.id])
            .filter((member) => member.is_archived).length;

          if (!isShowingArchived && archivedMembersLength > 0) {
            skillListItems.push({
              rowType: components.ShowArchivedRow,
              id: `${skill.id}-showArchived`,
              numArchived: archivedMembersLength,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid });
              },
              isCustom: true,
              isDragDisabled: true
            });
          } else if (isShowingArchived && archivedMembersLength > 0) {
            skillListItems.push({
              rowType: components.HideArchivedRow,
              id: `${skill.id}-hideArchived`,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid });
              },
              isCustom: true,
              isDragDisabled: true
            });
          }
          return {
            ...skill,
            uid,
            hasArchivedMembers: archivedMembersLength > 0,
            hasSubLists: true,
            isFullyLoaded: true,
            isOpen:
              isOpen.skills?.[skill.id] === undefined &&
              !search.members &&
              filteredSkills.length > 2
                ? false
                : isOpen.skills?.[skill.id] === undefined
                ? sectionsStartOpen
                : isOpen.skills[skill.id],
            selectionIsDisabled: search?.members?.length,
            listItemsSelectionIsDisabled: widgetMemberSelectionDisabled,
            listItems: skillListItems,
            getItemId: (item) => item.account?.id || item.id,
            numSelected: skill.listItems.filter(
              (skillMember) =>
                activeFilterIdHashes.account_ids?.[skillMember.account?.id] &&
                (!skillMember.is_archived || isShowingArchived)
            ).length,
            isHeaderAllSelected:
              skill.listItems.length &&
              skill.listItems.every((skillMember) => {
                return (
                  activeFilterIdHashes.account_ids?.[skillMember.account?.id] ||
                  (skillMember.is_archived && !isShowingArchived)
                );
              }),
            isHeaderSomeSelected:
              skill.listItems.length &&
              skill.listItems.some(
                (skillMember) =>
                  activeFilterIdHashes.account_ids?.[skillMember.account?.id]
              ),
            isItemSelected: (item) =>
              activeFilterIdHashes.account_ids?.[item.account?.id],
            showSummary: false,
            summary: Summary,
            headerHandleClick: (item) => {
              const bulkAccountIdsToUse = uniq([
                ...filter.account_ids,
                ...item.listItems.map((member) => member.account?.id)
              ]);

              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isHeaderAllSelected
                    ? filter.account_ids.filter((filterItem) => {
                        return !skill.accountHash[filterItem];
                      })
                    : widgetLimits.account_ids
                    ? bulkAccountIdsToUse.slice(0, widgetLimits.account_ids)
                    : bulkAccountIdsToUse
                })
              );
            },
            handleClick: (item) => {
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isSelected
                    ? filter.account_ids.filter(
                        (filterItem) => filterItem !== item.row.account.id
                      )
                    : Array.from(
                        new Set([...filter.account_ids, item.row.account.id])
                      )
                })
              );
              handleAfterSelect();
            },
            handleClear: () => {
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: []
                })
              );
              handleAfterSelect();
            },
            sectionType: 'skills',
            headerType: 'SkillRow',
            headerHeight: 50,
            renderHeader: () => skill.name,
            rowType: 'MemberRow',
            itemLeft: '24px',
            headerLeft: 0,
            headerLeftOffset
            // skipHeader: true
          };
        }),
        ...(!isStackedFilter
          ? [
              {
                rowType: components.AddSkillRow,
                id: `addSkillRow`,
                rowHeight: 40,
                handleClick: () => {
                  dispatch(
                    navigateToTeamSettings({
                      teamSlug: team && team?.slug,
                      viewType: 'members',
                      tab: 'skills',
                      openInNewWindow: true
                    })
                  );
                },
                isCustom: true,
                isDragDisabled: true
              }
            ]
          : [])
      ],
      getItemId: (item) => item.id,
      isItemSelected: (item) => activeFilterIdHashes.skill_ids?.[item.id],
      summary: Summary,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            position_ids: item.isSelected
              ? (filter.position_ids || []).filter(
                  (filterItem) => filterItem !== item.row.id
                )
              : Array.from(
                  new Set([...(filter.position_ids || []), item.row.id])
                )
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        handleClearAccountIdsFilter();
        handleAfterSelect();
      },
      rowType: 'SkillRow',
      headerType: 'Header',
      skipHeader: !isStackedFilter,
      isFullyLoaded: isSkillsLoaded,
      id: 'skills',
      uid: serializeBar({
        itemId: 'members',
        itemType: 'members'
      }),
      stickyHeaderType: 'skills',
      customItems: customItems.skillsList || [
        VirtualFilterMemberTabs,
        MemberSearch,
        Clear
      ],
      showOnEngaged: false,
      isOpen:
        isOpen.members?.members === undefined
          ? sectionsStartOpen
          : isOpen.members.members,
      rowHeight: 44,
      renderHeader: () => sectionToLabel.members || 'Member',
      numberOfSelectedItems: filter.account_ids.length,
      onlySelectedItemLabel: onlySelectedMemberName,
      summaryNoun: 'Members',
      renderSummaryItem: (item) => item?.name
      // itemLeft: '10px'
    };

    const officesList = {
      listItems: [
        ...filteredOffices.map((office) => {
          const uid = serializeBar({
            itemId: office.id,
            itemType: 'offices'
          });
          // Filter out the account that matches the searched string
          const officeListItems = search.members?.length
            ? office.listItems.filter(
                (member) => filteredMembersHash[member.account.id]
              )
            : office.listItems;

          return {
            ...office,
            listItems: officeListItems,
            uid,
            hasSubLists: true,
            isFullyLoaded: true, // all members are loaded
            isOpen:
              isOpen.offices?.[office.id] === undefined
                ? false
                : isOpen.offices?.[office.id],
            getItemId: (item) => item.account?.id || item.id,
            numSelected: office.listItems.filter(
              (member) => activeFilterIdHashes.account_ids?.[member.account?.id]
            ).length,
            isHeaderAllSelected:
              office.listItems.length &&
              office.listItems.every((member) => {
                return activeFilterIdHashes.account_ids?.[member.account?.id];
              }),
            isItemSelected: (item) =>
              activeFilterIdHashes.account_ids?.[item.account?.id],
            headerHandleClick: (item) => {
              const bulkAccountIdsToUse = uniq([
                ...filter.account_ids,
                ...item.listItems.map((member) => member.account?.id)
              ]);

              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isHeaderAllSelected
                    ? filter.account_ids.filter((filterItem) => {
                        return !office.accountHash[filterItem];
                      })
                    : widgetLimits.account_ids
                    ? bulkAccountIdsToUse.slice(0, widgetLimits.account_ids)
                    : bulkAccountIdsToUse
                })
              );
            },
            handleClick: (item) => {
              // sub row click (member level) or MemberRow
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isSelected
                    ? filter.account_ids.filter(
                        (filterItem) => filterItem !== item.row.account.id
                      )
                    : Array.from(
                        new Set([...filter.account_ids, item.row.account.id])
                      )
                })
              );
              handleAfterSelect();
            },
            handleClear: () => {
              handleClearAccountIdsFilter();
              handleAfterSelect();
            },
            sectionType: 'offices',
            headerType: 'OfficeRow',
            headerHeight: 50,
            rowType: 'MemberRow',
            itemLeft: '24px',
            headerLeft: 0,
            headerLeftOffset,
            selectionIsDisabled: search?.members?.length,
            listItemsSelectionIsDisabled: widgetMemberSelectionDisabled
          };
        }),
        ...(!isStackedFilter
          ? [
              {
                rowType: components.AddOfficeRow,
                id: `addOfficeRow`,
                rowHeight: 40,
                handleClick: () => {
                  dispatch(
                    navigateToMembersOfficeSettings({
                      teamSlug: team && team?.slug,
                      openInNewWindow: true
                    })
                  );
                },
                isCustom: true,
                isDragDisabled: true
              }
            ]
          : [])
      ],
      id: 'offices',
      getItemId: (item) => item.id,
      handleClear: () => {
        handleClearAccountIdsFilter();
        handleAfterSelect();
      },
      renderHeader: () => sectionToLabel.members || 'Member',
      rowType: 'OfficeRow',
      headerType: 'Header',
      skipHeader: !isStackedFilter,
      isFullyLoaded: true, // All offices fetched
      uid: serializeBar({
        itemId: 'members',
        itemType: 'members'
      }),
      summaryNoun: 'Members',
      showOnEngaged: false,
      isOpen:
        isOpen.members?.members === undefined
          ? sectionsStartOpen
          : isOpen.members.members,
      rowHeight: 44,
      onlySelectedItemLabel: onlySelectedMemberName,
      numberOfSelectedItems: filter.account_ids.length,
      customItems: customItems.officesList || [
        VirtualFilterMemberTabs,
        MemberSearch,
        Clear
      ]
    };
    const regionsList = {
      listItems: [
        ...filteredRegions.map((region) => {
          const uid = serializeBar({
            itemId: region.id,
            itemType: 'regions'
          });
          const regionListItems = search.members?.length
            ? region.listItems.filter(
                (member) => filteredMembersHash[member.account.id]
              )
            : region.listItems;

          return {
            ...region,
            listItems: regionListItems,
            uid,
            hasSubLists: true,
            isFullyLoaded: true,
            isOpen:
              isOpen.regions?.[region.id] === undefined
                ? false
                : isOpen.regions?.[region.id],
            getItemId: (item) => item.account?.id || item.id,
            numSelected: region.listItems.filter(
              (member) => activeFilterIdHashes.account_ids?.[member.account?.id]
            ).length,
            isHeaderAllSelected:
              region.listItems.length &&
              region.listItems.every((member) => {
                return activeFilterIdHashes.account_ids?.[member.account?.id];
              }),
            isItemSelected: (item) =>
              activeFilterIdHashes.account_ids?.[item.account?.id],
            headerHandleClick: (item) => {
              const bulkAccountIdsToUse = uniq([
                ...filter.account_ids,
                ...item.listItems.map((member) => member.account?.id)
              ]);
              // This is when you click on the parent row (Region row)
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isHeaderAllSelected
                    ? filter.account_ids.filter((filterItem) => {
                        return !region.accountHash[filterItem];
                      })
                    : widgetLimits.account_ids
                    ? bulkAccountIdsToUse.slice(0, widgetLimits.account_ids)
                    : bulkAccountIdsToUse
                })
              );
            },
            handleClick: (item) => {
              // sub row click (member level) or MemberRow
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isSelected
                    ? filter.account_ids.filter(
                        (filterItem) => filterItem !== item.row.account.id
                      )
                    : Array.from(
                        new Set([...filter.account_ids, item.row.account.id])
                      )
                })
              );
              handleAfterSelect();
            },
            handleClear: () => {
              handleClearAccountIdsFilter();
              handleAfterSelect();
            },
            sectionType: 'regions',
            headerType: 'RegionRow',
            onlySelectedItemLabel: onlySelectedMemberName,
            headerHeight: 50,
            rowType: 'MemberRow',
            itemLeft: '24px',
            headerLeft: 0,
            headerLeftOffset,
            selectionIsDisabled: !!search?.members?.length, // Don't show parent row box when search is on
            listItemsSelectionIsDisabled: widgetMemberSelectionDisabled
          };
        }),
        ...(!isStackedFilter
          ? [
              {
                rowType: components.AddRegionRow,
                id: `addRegionRow`,
                rowHeight: 40,
                handleClick: () => {
                  dispatch(
                    navigateToMembersRegionSettings({
                      teamSlug: team && team?.slug,
                      openInNewWindow: true
                    })
                  );
                },
                isCustom: true,
                isDragDisabled: true
              }
            ]
          : [])
      ],
      id: 'regions',
      getItemId: (item) => item.id,
      renderHeader: () => sectionToLabel.members || 'Member',
      handleClear: () => {
        handleClearAccountIdsFilter();
        handleAfterSelect();
      },
      rowType: 'RegionRow',
      headerType: 'Header',
      skipHeader: !isStackedFilter,
      isFullyLoaded: true, // Fetched all regions
      uid: serializeBar({
        itemId: 'members',
        itemType: 'members'
      }),
      summaryNoun: 'Members',
      showOnEngaged: false,
      isOpen:
        isOpen.members?.members === undefined
          ? sectionsStartOpen
          : isOpen.members.members,
      rowHeight: 44,
      onlySelectedItemLabel: onlySelectedMemberName,
      numberOfSelectedItems: filter.account_ids.length, // if 0 == all, if 1 == Name of that one selected member, else this + summaryNoun
      customItems: customItems.regionsList || [
        VirtualFilterMemberTabs,
        MemberSearch,
        Clear
      ]
    };

    const disciplinesList = {
      listItems: [
        ...filteredDisciplines.map((discipline) => {
          const uid = serializeBar({
            itemId: discipline.id,
            itemType: 'disciplines'
          });
          const disciplineListItems = search.members?.length
            ? discipline.listItems.filter(
                (member) => filteredMembersHash[member.account.id]
              )
            : discipline.listItems;

          return {
            ...discipline,
            listItems: disciplineListItems,
            uid,
            hasSubLists: true,
            isFullyLoaded: true,
            isOpen:
              isOpen.disciplines?.[discipline.id] === undefined
                ? false
                : isOpen.disciplines?.[discipline.id],
            getItemId: (item) => item.account?.id || item.id,
            numSelected: discipline.listItems.filter(
              (member) => activeFilterIdHashes.account_ids?.[member.account?.id]
            ).length,
            isHeaderAllSelected:
              discipline.listItems.length &&
              discipline.listItems.every((member) => {
                return activeFilterIdHashes.account_ids?.[member.account?.id];
              }),
            isItemSelected: (item) =>
              activeFilterIdHashes.account_ids?.[item.account?.id],
            headerHandleClick: (item) => {
              const bulkAccountIdsToUse = uniq([
                ...filter.account_ids,
                ...item.listItems.map((member) => member.account?.id)
              ]);
              // This is when you click on the parent row (Discipline row)
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isHeaderAllSelected
                    ? filter.account_ids.filter((filterItem) => {
                        return !discipline.accountHash[filterItem];
                      })
                    : widgetLimits.account_ids
                    ? bulkAccountIdsToUse.slice(0, widgetLimits.account_ids)
                    : bulkAccountIdsToUse
                })
              );
            },
            handleClick: (item) => {
              // sub row click (member level) or MemberRow
              dispatch(
                saveActionToUse({
                  ...filter,
                  name: viewBy,
                  page: pageName,
                  account_ids: item.isSelected
                    ? filter.account_ids.filter(
                        (filterItem) => filterItem !== item.row.account.id
                      )
                    : Array.from(
                        new Set([...filter.account_ids, item.row.account.id])
                      )
                })
              );
              handleAfterSelect();
            },
            handleClear: () => {
              handleClearAccountIdsFilter();
              handleAfterSelect();
            },
            sectionType: 'disciplines',
            headerType: 'DisciplineRow',
            onlySelectedItemLabel: onlySelectedMemberName,
            headerHeight: 50,
            rowType: 'MemberRow',
            itemLeft: '24px',
            headerLeft: 0,
            headerLeftOffset,
            selectionIsDisabled: !!search?.members?.length, // Don't show parent row box when search is on
            listItemsSelectionIsDisabled: widgetMemberSelectionDisabled
          };
        }),
        ...(!isStackedFilter
          ? [
              {
                rowType: components.AddDisciplineRow,
                id: `addDisciplineRow`,
                rowHeight: 40,
                handleClick: () => {
                  dispatch(
                    navigateToMembersDisciplineSettings({
                      teamSlug: team && team?.slug,
                      openInNewWindow: true
                    })
                  );
                },
                isCustom: true,
                isDragDisabled: true
              }
            ]
          : [])
      ],
      id: 'disciplines',
      getItemId: (item) => item.id,
      renderHeader: () => sectionToLabel.members || 'Member',
      handleClear: () => {
        handleClearAccountIdsFilter();
        handleAfterSelect();
      },
      rowType: 'DisciplineRow',
      headerType: 'Header',
      skipHeader: !isStackedFilter,
      isFullyLoaded: true, // Fetched all disciplines
      uid: serializeBar({
        itemId: 'members',
        itemType: 'members'
      }),
      summaryNoun: 'Members',
      showOnEngaged: false,
      isOpen:
        isOpen.members?.members === undefined
          ? sectionsStartOpen
          : isOpen.members.members,
      rowHeight: 44,
      onlySelectedItemLabel: onlySelectedMemberName,
      numberOfSelectedItems: filter.account_ids.length, // if 0 == all, if 1 == Name of that one selected member, else this + summaryNoun
      customItems: customItems.disciplinesList || [
        VirtualFilterMemberTabs,
        MemberSearch,
        Clear
      ]
    };
    const membersByBoardList = {
      listItems: formattedMembersByBoard.map((board) => {
        const uid = serializeBar({
          itemId: board.id,
          itemType: 'board'
        });
        const isShowingArchived = showArchived[uid];
        const boardListItems = isShowingArchived
          ? board.listItems
              .filter((member) => filteredMembersHash[member.account.id])
              .map((member) => filteredMembersHash[member.account.id])
              .sort((a, b) => (!a.is_archived && b.is_archived ? -1 : 1))
          : board.listItems
              .filter((member) => filteredMembersHash[member.account.id])
              .map((member) => filteredMembersHash[member.account.id])
              .filter((member) => !member.is_archived);

        const archivedMembersLength = board.listItems
          .filter((member) => filteredMembersHash[member.account_id])
          .map((member) => filteredMembersHash[member.account_id])
          .filter((member) => member.is_archived).length;

        if (!isShowingArchived && archivedMembersLength > 0) {
          boardListItems.push({
            rowType: components.ShowArchivedRow,
            id: `${board.id}-showArchived`,
            numArchived: archivedMembersLength,
            rowHeight: 20,
            handleClick: () => {
              handleSetShowArchived({ uid });
            },
            isCustom: true,
            isDragDisabled: true
          });
        } else if (isShowingArchived && archivedMembersLength > 0) {
          boardListItems.push({
            rowType: components.HideArchivedRow,
            id: `${board.id}-hideArchived`,
            rowHeight: 20,
            handleClick: () => {
              handleSetShowArchived({ uid });
            },
            isCustom: true,
            isDragDisabled: true
          });
        }

        return {
          ...board,
          uid,
          hasSubLists: true,
          displayCount: boardListItems.length - (archivedMembersLength > 0),
          isFullyLoaded: true,
          isOpen:
            isOpen.board?.[board.id] === undefined &&
            !search.members &&
            formattedMembersByBoard.length > 2
              ? false
              : isOpen.board?.[board.id] === undefined
              ? sectionsStartOpen
              : isOpen.board[board.id],
          selectionIsDisabled: search?.members?.length,
          listItemsSelectionIsDisabled: widgetMemberSelectionDisabled,
          listItems: boardListItems,
          getItemId: (item) => item.account?.id || item.id,
          numSelected: board.listItems.filter(
            (boardMember) =>
              activeFilterIdHashes.account_ids?.[boardMember.account?.id] &&
              (!boardMember.is_archived || isShowingArchived)
          ).length,
          isHeaderAllSelected:
            board.listItems.length &&
            board.listItems.every(
              (boardMember) =>
                activeFilterIdHashes.account_ids?.[boardMember.account?.id] ||
                (boardMember.is_archived && !isShowingArchived)
            ),
          isHeaderSomeSelected:
            board.listItems.length &&
            board.listItems.some(
              (boardMember) =>
                activeFilterIdHashes.account_ids?.[boardMember.account?.id]
            ),
          isItemSelected: (item) =>
            activeFilterIdHashes.account_ids?.[item.account.id],
          showSummary: false,
          summary: Summary,
          headerHandleClick: (item) => {
            const bulkAccountIdsToUse = uniq([
              ...filter.account_ids,
              ...item.listItems.map((member) => member.account?.id)
            ]);
            dispatch(
              saveActionToUse({
                ...filter,
                name: viewBy,
                page: pageName,
                account_ids: item.isHeaderAllSelected
                  ? filter.account_ids.filter((filterItem) => {
                      return !board.accountHash[filterItem];
                    })
                  : widgetLimits.account_ids
                  ? bulkAccountIdsToUse.slice(0, widgetLimits.account_ids)
                  : bulkAccountIdsToUse
              })
            );
          },
          handleClick: (item) => {
            dispatch(
              saveActionToUse({
                ...filter,
                name: viewBy,
                page: pageName,
                account_ids: item.isSelected
                  ? filter.account_ids.filter(
                      (filterItem) => filterItem !== item.row.account.id
                    )
                  : Array.from(
                      new Set([...filter.account_ids, item.row.account.id])
                    )
              })
            );
            handleAfterSelect();
          },
          handleClear: () => {
            dispatch(
              saveActionToUse({
                ...filter,
                name: viewBy,
                page: pageName,
                account_ids: []
              })
            );
            handleAfterSelect();
          },
          sectionType: 'membersByBoard',
          headerType: 'BoardRow',
          headerHeight: 50,
          renderHeader: () => board.name,
          rowType: 'MemberRow',
          itemLeft: '24px',
          headerLeft: 0,
          headerLeftOffset
          // skipHeader: true
        };
      }),
      getItemId: (item) => item.id,
      isItemSelected: (item) => activeFilterIdHashes.board_ids?.[item.id],
      summary: Summary,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            position_ids: item.isSelected
              ? (filter.position_ids || []).filter(
                  (filterItem) => filterItem !== item.row.id
                )
              : Array.from(
                  new Set([...(filter.position_ids || []), item.row.id])
                )
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        handleClearAccountIdsFilter();
        handleAfterSelect();
      },
      rowType: 'BoardRow',
      headerType: 'Header',
      skipHeader: !isStackedFilter,
      isFullyLoaded: formattedMembersByBoard.length,
      id: 'membersByBoard',
      uid: serializeBar({
        itemId: 'members',
        itemType: 'members'
      }),
      stickyHeaderType: 'membersByBoard',
      customItems: customItems.portfolioList || [
        VirtualFilterMemberTabs,
        MemberSearch,
        Clear
      ],
      showOnEngaged: false,
      isOpen:
        isOpen.members?.members === undefined
          ? sectionsStartOpen
          : isOpen.members.members,
      rowHeight: 44,
      renderHeader: () => sectionToLabel.members || 'Member',
      numberOfSelectedItems: filter.account_ids.length,
      onlySelectedItemLabel: onlySelectedMemberName,
      summaryNoun: 'Members',
      renderSummaryItem: (item) => item?.name
    };

    let currentFetchOffset = 0;

    let isBoardLoading = false;

    const projectsByBoardList = {
      listItems: filteredBoards
        .map((board, index) => {
          // Actual loaded projects
          const boardProjects = projectsByBoardId[board.id] || []; // loaded projects
          const boardProjectsHash = keyBy(
            boardProjects,
            (projectId) => projectId
          );

          const boardProjectIds = boardProjects.map((idOrObject) => idOrObject);

          const totalProjectsCount = board.isPersonal
            ? board.project_position.length +
              board.archived_project_position.length
            : board.project_count;
          const boardProjectCountInfo =
            filterProjectsGroupCounts[board.id] ||
            filterProjectsGroupCounts.default;
          const boardProjectCount = boardProjectCountInfo?.total; // total count

          const uid = serializeBar({ itemId: board.id, itemType: 'board' });
          const isShowingArchived = showArchived[uid];

          const boardProjectCountForCheckSelected = boardProjectCount
            ? isShowingArchived
              ? boardProjectCount
              : boardProjectCount - boardProjectCountInfo?.numArchived
            : undefined;

          const activeProjectIds = difference(
            board.project_position,
            board.archived_project_position
          );

          const allBoardProjectIdsSet = new Set([
            ...activeProjectIds,
            ...(isShowingArchived ? board.archived_project_position : [])
          ]);

          const uniqAllBoardProjectIds = [...allBoardProjectIdsSet];

          const allBoardProjectIds = uniqAllBoardProjectIds.filter(
            (id) =>
              id &&
              !projectHash[id]?.is_administrative &&
              !projectHash[id]?.is_personal
          );

          const archivedBoardProjects = boardProjects.filter(
            (projectId) => projectHash[projectId]?.is_archived
          );
          const totalNumArchived =
            filterProjectsGroupCounts[board.id]?.numArchived || 0;

          // when all active projects have been loaded and 'show archived' is showing,
          // lazy loading will not be triggered, so consider as fully loaded
          const isFullyLoadedWhenArchivedNotShowing =
            !isShowingArchived &&
            boardProjects.length > 0 &&
            boardProjects.length >= boardProjectCount - totalNumArchived;
          const isAllBoardProjectsLoaded =
            boardProjects.length >= boardProjectCount;
          const isNoProjectResults = boardProjectCount === 0;
          const isBoardWithNoProjects = totalProjectsCount === 0;

          const isFullyLoaded =
            isNoProjectResults ||
            isBoardWithNoProjects ||
            (filterProjectsCount !== null &&
              (isAllBoardProjectsLoaded ||
                isFullyLoadedWhenArchivedNotShowing ||
                board.project_count === undefined));

          const boardProjectsToShow = [];

          if (
            !isShowingArchived &&
            boardProjects.length - archivedBoardProjects.length === 0 &&
            (isFullyLoaded || archivedBoardProjects.length > 0)
          ) {
            boardProjectsToShow.push({
              rowType: components.NoActiveProjectsRow,
              id: `${board.id}-noActiveProjects`,
              rowHeight: 20,
              isCustom: true
            });
          }

          boardProjectsToShow.push(
            ...(isShowingArchived
              ? boardProjects
              : boardProjects.filter(
                  (projectId) => !projectHash[projectId]?.is_archived
                ))
          );

          if (!isShowingArchived && archivedBoardProjects.length) {
            boardProjectsToShow.push({
              rowType: components.ShowArchivedRow,
              id: `${board.id}-showArchived`,
              numArchived: totalNumArchived,
              rowHeight: 20,
              handleClick: () => {
                handleSetShowArchived({ uid });
                if (isProjectPhaseSelector) {
                  loadPhasesByProjectIds(board.archived_project_position);
                }
              },
              isCustom: true,
              isDragDisabled: true
            });
          }

          const boardFetchOffset = currentFetchOffset;
          if (filterProjectsGroupCounts[board.id]?.total) {
            currentFetchOffset += boardProjectCount;
          }

          const isBoardOpen =
            isOpen.board?.[board.id] === undefined
              ? false
              : isOpen.board[board.id];

          if (isBoardOpen && !isFullyLoaded) isBoardLoading = true;

          // boardProjectsToShow will take care of when we expand board row
          // use allBoardProjectIds when collapsed
          const numSelected =
            boardProjectsToShow.length > 0
              ? boardProjectsToShow.filter(
                  (projectRowOrId) =>
                    activeFilterIdHashes.project_ids?.[
                      projectRowOrId?.id ?? projectRowOrId
                    ]
                ).length
              : allBoardProjectIds.filter(
                  (id) => activeFilterIdHashes.project_ids?.[id]
                ).length;
          return {
            ...board,
            displayCount: Math.min(boardProjectCount, totalProjectsCount),
            index,
            hasSubLists: true,
            loader: ProjectLoader,
            isFullyLoaded,
            fetchOffset: boardFetchOffset,
            sectionType: serializeSection({
              parentId: 'projectsByBoard',
              sectionId: board.id
            }),
            showLoadPadding:
              index === filteredBoards.length - 2 && !boardProjects.length,
            numBoards: filteredBoards.length,
            uid,
            isDragDisabled:
              board.isPersonal ||
              dragItemType === 'project' ||
              search.projects?.length > 0,
            showOnEngaged: false,
            isOpen: isBoardOpen,
            isProjectsSidebar,
            hideBoardCheckBox:
              isProjectPhaseSelector || additionalFilterOptions.isSingleSelect, // there is currently no board filter, so this means projects are single selectable
            projectFilterListId,
            filteredProjectsCount: boardProjectsToShow.length,
            selectionIsDisabled: search?.projects?.length,
            listItems: boardProjectsToShow.map((projectRowOrId, index) => {
              const project = projectRowOrId?.id
                ? projectRowOrId
                : projectHash[projectRowOrId];

              const isDragDisabled =
                (dragItemType === 'project' &&
                  !boardProjectsHash[dragItemId]) ||
                search.projects?.length > 0 ||
                project.rowType === 'ShowArchivedRow' ||
                projectFilterListId !== 'projects-sidebar';
              const itemType = !board.isPersonal
                ? 'notPersonalBoardProject'
                : 'project';

              const uid = serializeId({
                ids: [project.id, board.id],
                itemType
              });

              const rowPropsForPhaseLevelSelect = isProjectPhaseSelector
                ? getProjectRowProps({
                    project,
                    board,
                    isDragDisabled:
                      board.isPersonal ||
                      dragItemType === 'project' ||
                      search.projects?.length > 0,
                    itemType,
                    uid,
                    parentId: 'projectsByBoard',
                    parentItem: {
                      dataType: DATATYPES.BOARD,
                      isFullyLoaded,
                      isPersonal: board.isPersonal,
                      id: board.id,
                      fetchOffset: boardFetchOffset
                    },
                    selectionIsDisabled: widgetPhaseSelectionDisabled
                  })
                : emptyObj;

              return {
                getItemId: (item) => item.id,
                boardId: board.id,
                isProjectsSidebar,
                projectFilterListId,
                dataType: DATATYPES.PROJECT,
                index,
                uid,
                isDragDisabled,
                isSingleSelect: additionalFilterOptions.isSingleSelect,
                selectionIsDisabled: widgetProjectSelectionDisabled,
                // add necessary props when phase level selector is enabled
                // this includes phase subrows
                ...rowPropsForPhaseLevelSelect,
                // moved project here from top so that some props for archived row can override props used only for real project
                ...project
              };
            }),
            dataType: DATATYPES.BOARD,
            getItemId: (item) => item.id,
            numSelected,
            isHeaderAllSelected:
              numSelected > 0 &&
              numSelected ===
                (boardProjectCountForCheckSelected ??
                  allBoardProjectIds.length),
            isHeaderSomeSelected: numSelected > 0,
            isItemSelected: (item) =>
              activeFilterIdHashes.project_ids?.[item.id],
            showSummary: false,
            summary: Summary,
            headerHandleClick: (item) => {
              if (additionalFilterOptions.isSingleSelect) return;

              const fieldToUse = draftFilter?.project_ids || filter.project_ids;

              const nextProjectIds =
                item.isHeaderAllSelected ||
                (widgetLimits.project_ids &&
                  fieldToUse.length >= widgetLimits.project_ids)
                  ? fieldToUse.filter(
                      (filterItem) => !allBoardProjectIdsSet.has(filterItem)
                    )
                  : widgetLimits.project_ids
                  ? uniq([
                      ...fieldToUse,
                      // use boardProjectIds if projects are loaded already, it has same orders as it displayed
                      // otherwise it use allBoardProjectIds
                      ...(boardProjectIds.length
                        ? boardProjectIds
                        : allBoardProjectIds)
                    ]).slice(0, widgetLimits.project_ids)
                  : uniq([...fieldToUse, ...allBoardProjectIds]);

              if (draftFilter) {
                draftFilter.update({ project_ids: nextProjectIds });
              } else {
                dispatch(
                  saveActionToUse({
                    ...filter,
                    name: viewBy,
                    page: pageName,
                    project_ids: nextProjectIds
                  })
                );
              }
            },
            handleClick: (item) => {
              const { isSingleSelect } = additionalFilterOptions;

              const fieldToUse = draftFilter?.project_ids || filter.project_ids;
              const nextProjectIds = isSingleSelect
                ? [item.row.id]
                : item.isSelected
                ? fieldToUse.filter((filterItem) => filterItem !== item.row.id)
                : Array.from(new Set([...fieldToUse, item.row.id]));

              if (draftFilter) {
                draftFilter.update({ project_ids: nextProjectIds });
              } else {
                dispatch(
                  saveActionToUse({
                    ...filter,
                    name: viewBy,
                    page: pageName,
                    project_ids: nextProjectIds
                  })
                );
              }
              handleAfterSelect();
            },
            headerType: headerTypes?.BoardRow || 'BoardRow',
            headerHeight: 50,
            renderHeader: (props) => board.name,
            rowType: rowTypes?.ProjectRow || 'ProjectRow',
            isCustom: !!rowTypes?.ProjectRow,
            itemLeft: leftPadding || '24px',
            headerLeft: 0,
            headerLeftOffset,
            stickyHeaderType: 'BoardRow'
            // skipHeader: true
          };
        })
        .filter((list) => list),
      getItemId: (item) => item.id,
      isItemSelected: (item) => activeFilterIdHashes.project_ids?.[item.id],
      showSummary: !enableDrag,
      summary: Summary,
      rowType: 'BoardRow',
      headerType: 'Header',
      id: 'projectsByBoard',
      dataType: DATATYPES.BOARD,
      customItems: customItems.projectList || [
        VirtualFilterProjectTabs,
        ProjectSearch,
        Clear
      ],
      showOnEngaged: false,
      isOpen:
        isOpen.projects?.projects === undefined
          ? isProjectsSidebar
            ? true
            : sectionsStartOpen
          : isOpen.projects.projects,
      isSubListLoading: isBoardLoading,
      rowHeight: 47,
      headerHeight: 50,
      // isFullyLoaded: projects.length >= filterProjectsCount, not necessary since we know all the boards
      renderHeader: () => 'Project',
      skipHeader: skipHeader.projects,
      numberOfSelectedItems: filter?.project_ids?.length,
      summaryNoun: 'Projects',
      renderSummaryItem: (item) => item?.title ?? '',
      uid: serializeBar({
        itemId: 'projectsByBoard',
        itemType: 'projects'
      }),
      selectionIsDisabled: widgetProjectSelectionDisabled
    };

    const projectListItems = !enableDrag
      ? filteredProjects
      : filteredProjects.map((project) => {
          const uid = serializeBar({
            itemId: project.id,
            itemType: 'project'
          });
          return {
            ...(isProjectPhaseSelector &&
              getProjectRowProps({
                project,
                board: project.board,
                isDragDisabled: true,
                itemType: 'project',
                uid,
                parentId: 'projects',
                selectionIsDisabled: widgetPhaseSelectionDisabled
              })),
            ...project,
            uid,
            isSingleSelect: additionalFilterOptions.isSingleSelect,
            isDragDisabled:
              search.projects?.length > 0 ||
              project.rowType === 'ShowArchivedRow'
          };
        });

    const projectList = {
      listItems: projectListItems.map((item) => ({
        ...item,
        selectionIsDisabled: widgetProjectSelectionDisabled
      })),
      getItemId: (item) => item.id,
      isItemSelected: (item) => activeFilterIdHashes.project_ids?.[item.id],
      summary: Summary,
      handleClick: (item) => {
        const fieldToUse = draftFilter?.project_ids || filter.project_ids;

        const nextProjectIds = additionalFilterOptions.isSingleSelect
          ? [item.row.id]
          : item.isSelected
          ? fieldToUse.filter((filterItem) => filterItem !== item.row.id)
          : Array.from(new Set([...fieldToUse, item.row.id]));

        if (draftFilter) {
          draftFilter.update({ project_ids: nextProjectIds });
        } else {
          dispatch(
            saveActionToUse({
              ...filter,
              name: viewByToUse,
              page: pageName,
              project_ids: nextProjectIds
            })
          );
        }
        handleAfterSelect();
      },
      rowType: rowTypes?.ProjectRow || 'ProjectRow',
      isCustom: !!rowTypes?.ProjectRow,
      headerType: 'Header',
      id: 'projects',
      customItems: customItems.projectList || [
        VirtualFilterProjectTabs,
        ProjectSearch,
        Clear
      ],
      showOnEngaged,
      isOpen:
        isOpen.projects?.projects === undefined
          ? sectionsStartOpen
          : isOpen.projects.projects,
      rowHeight: 47,
      isFullyLoaded: filteredProjects.length >= filterProjectsCount,
      loader: Loader,
      renderHeader: () => 'Project',
      skipHeader: skipHeader.projects,
      numberOfSelectedItems: filter?.project_ids?.length,
      summaryNoun: 'Projects',
      renderSummaryItem: (item) => item?.title ?? '',
      uid: serializeBar({
        itemId: 'projects',
        itemType: 'projects'
      })
      // itemLeft: '10px'
    };

    const phaseList = {
      listItems: filteredPhases,
      getItemId: (item) => item,
      isItemSelected: (item) => activeFilterIdHashes.phase_names?.[item],
      summary: Summary,
      showSummary: false,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            phase_names: item.isSelected
              ? filter.phase_names.filter(
                  (filterItem) => filterItem !== item.row
                )
              : Array.from(new Set([...filter.phase_names, item.row]))
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewBy,
            page: pageName,
            phase_names: []
          })
        );
        handleAfterSelect();
      },
      rowType: 'PhaseRow',
      headerType: 'Header',
      customItems: customItems.phaseList || [PhaseSearch, Clear],
      showOnEngaged,
      id: 'phases',
      uid: serializeBar({
        itemId: 'phases',
        itemType: 'phases'
      }),
      rowHeight: 34,
      isOpen:
        isOpen.phases?.phases === undefined
          ? sectionsStartOpen
          : isOpen.phases.phases,
      isFullyLoaded: phaseNames.length,
      loader: Loader,
      renderHeader: () => sectionToLabel.phases || 'Phase',
      numberOfSelectedItems: filter?.phase_names?.length,
      summaryNoun: 'Phases',
      renderSummaryItem: (item) => item
    };

    const activityList = {
      listItems: filteredActivities,
      getItemId: (item) => item.id,
      isItemSelected: (item) => activeFilterIdHashes.activity_ids?.[item.id],
      summary: Summary,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            activity_ids: item.isSelected
              ? filter.activity_ids.filter(
                  (filterItem) => filterItem !== item.row.id
                )
              : Array.from(new Set([...filter.activity_ids, item.row.id]))
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewBy,
            page: pageName,
            activity_ids: []
          })
        );
        handleAfterSelect();
      },
      rowType: 'ActivityRow',
      headerType: 'Header',
      customItems: customItems.activityList || [ActivitySearch, Clear],
      showOnEngaged,
      id: 'activities',
      uid: serializeBar({
        itemId: 'activities',
        itemType: 'activities'
      }),
      rowHeight: 34,
      isOpen:
        isOpen.activities?.activities === undefined
          ? sectionsStartOpen
          : isOpen.activities.activities,
      isFullyLoaded: activities.length,
      renderHeader: () => 'Work Category',
      numberOfSelectedItems: filter?.activity_ids?.length,
      summaryNoun: 'Work Categories',
      renderSummaryItem: (item) => item?.title || ''
    };

    const statusList = {
      listItems: statuses,
      getItemId: (item) => item,
      isItemSelected: (item) =>
        activeFilterIdHashes.status_ids?.[
          TIMESHEET_STATUS_REVERSE_LOOKUP[item]
        ],
      summary: Summary,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            status_ids: item.isSelected
              ? (filter?.status_ids ?? []).filter(
                  (filterItem) =>
                    filterItem !== TIMESHEET_STATUS_REVERSE_LOOKUP[item.row]
                )
              : Array.from(
                  new Set([
                    ...(filter?.status_ids ?? []),
                    TIMESHEET_STATUS_REVERSE_LOOKUP[item.row]
                  ])
                )
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewBy,
            page: pageName,
            status_ids: []
          })
        );
        handleAfterSelect();
      },
      rowType: 'StatusRow',
      headerType: 'Header',
      id: 'statuses',
      uid: serializeBar({
        itemId: 'statuses',
        itemType: 'statuses'
      }),
      isOpen:
        isOpen.statuses?.statuses === undefined
          ? sectionsStartOpen
          : isOpen.statuses.statuses,
      isFullyLoaded: true,
      renderHeader: () => 'Status',
      numberOfSelectedItems:
        filter?.status_ids?.length !== statuses.length
          ? filter?.status_ids?.length ?? 0
          : 0,
      summaryNoun: 'Statuses',
      renderSummaryItem: (item) => FILTER_RENDER_TEXT[item]
    };

    // useClientNames = use client name strings
    // otherwise use client id
    const useClientNames = isStackedFilter;
    const clientList = {
      listItems: filteredClients,
      getItemId: (item) => item.id,
      selectionIsDisabled:
        search?.client_ids?.length || widgetLimits.client_ids > 0,
      listItemsSelectionIsDisabled: widgetClientSelectionDisabled,
      isItemSelected: (item) =>
        useClientNames
          ? activeFilterIdHashes.clients[item.title]
          : activeFilterIdHashes.client_ids?.[item.id],
      summary: Summary,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            ...(useClientNames
              ? {
                  clients: item.isSelected
                    ? filter.clients?.filter(
                        (filterItem) => filterItem !== item.row.title
                      ) ?? []
                    : Array.from(
                        new Set([...(filter?.clients ?? []), item.row.title])
                      )
                }
              : {
                  client_ids: item.isSelected
                    ? (filter.client_ids || []).filter(
                        (filterItem) => filterItem !== item.row.id
                      )
                    : Array.from(
                        new Set([...(filter.client_ids || []), item.row.id])
                      )
                })
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewBy,
            page: pageName,
            client_ids: [],
            clients: []
          })
        );
        handleAfterSelect();
      },
      rowType: 'ClientRow',
      headerType: 'Header',
      customItems: customItems.clientList || [ClientSearch, Clear],
      showOnEngaged,
      id: 'clients',
      uid: serializeBar({
        itemId: 'clients',
        itemType: 'clients'
      }),
      isOpen:
        isOpen.clients?.clients === undefined
          ? sectionsStartOpen
          : isOpen.clients.clients,
      isFullyLoaded: clients.length,
      stickyHeaderType: 'clients',
      skipHeader: !useClientNames,
      renderHeader: () => sectionToLabel.clients || 'Client',
      numberOfSelectedItems: useClientNames
        ? filter.clients.length
        : filter?.client_ids?.length,
      summaryNoun: 'Clients',
      renderSummaryItem: (item) => item?.title
    };

    const billableList = {
      listItems: billableItems,
      getItemId: (item) => item,
      isItemSelected: (item) => activeFilterIdHashes.billable?.[item],
      summary: Summary,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewByToUse,
            page: pageName,
            custom: {
              ...filter.custom,
              billable: item.isSelected
                ? (filter?.custom?.billable ?? []).filter(
                    (value) => value !== item.row
                  )
                : Array.from(
                    new Set([item.row, ...(filter?.custom?.billable ?? [])])
                  )
            }
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        dispatch(
          saveActionToUse({
            ...filter,
            name: viewBy,
            page: pageName,
            custom: {
              ...filter.custom,
              billable: []
            }
          })
        );
        handleAfterSelect();
      },

      rowType: 'BillableRow',
      headerType: 'Header',
      id: 'billable',
      uid: serializeBar({
        itemId: 'billable',
        itemType: 'billable'
      }),
      isOpen:
        isOpen.billable?.billable === undefined
          ? sectionsStartOpen
          : isOpen.billable.billable,
      isFullyLoaded: true,
      numberOfSelectedItems: filter?.custom?.billable?.length === 1 ? 1 : 0,
      renderHeader: () => sectionToLabel.billable || 'Billable',
      summaryNoun: 'Billable',
      renderSummaryItem: (item) => item
    };

    const projectBudgetStatusesList = {
      listItems: projectBudgetStatuses,
      getItemId: (item) => item,
      isItemSelected: (item) =>
        activeFilterIdHashes.projectBudgetStatuses?.[item],
      summary: Summary,
      handleClick: (item) => {
        dispatch(
          saveActionToUse({
            ...filter,
            custom: {
              ...filter.custom,
              projectBudgetStatuses: item.isSelected
                ? (filter.custom.projectBudgetStatuses || []).filter(
                    (value) => value !== item.row
                  )
                : Array.from(
                    new Set([
                      item.row,
                      ...(filter.custom.projectBudgetStatuses || [])
                    ])
                  )
            }
          })
        );
        handleAfterSelect();
      },
      handleClear: () => {
        dispatch(
          saveActionToUse({
            ...filter,
            custom: {
              ...filter.custom,
              projectBudgetStatuses: []
            }
          })
        );
        handleAfterSelect();
      },
      getLabel: (status) => PROJECT_BUDGET_STATUSES_DISPLAY[status],

      rowHeight: 35,
      rowType: 'ProjectBudgetStatusRow',
      headerType: 'Header',
      id: 'projectBudgetStatuses',
      uid: serializeBar({
        itemId: 'projectBudgetStatuses',
        itemType: 'projectBudgetStatuses'
      }),
      isOpen:
        isOpen.projectBudgetStatuses?.projectBudgetStatuses === undefined
          ? sectionsStartOpen
          : isOpen.projectBudgetStatuses.projectBudgetStatuses,
      isFullyLoaded: true,
      numberOfSelectedItems:
        filter.custom.projectBudgetStatuses !== projectBudgetStatuses.length
          ? filter.custom.projectBudgetStatuses?.length || 0
          : 0,
      renderHeader: () =>
        sectionToLabel.projectBudgetStatuses || 'Project Status',
      summaryNoun: 'Statuses',
      renderSummaryItem: (status) => PROJECT_BUDGET_STATUSES_DISPLAY[status]
    };

    const lists = [
      memberList,
      workGroupList,
      positionList,
      projectList,
      projectsByBoardList,
      phaseList,
      activityList,
      clientList,
      billableList,
      statusList,
      skillList,
      officesList,
      regionsList,
      disciplinesList,
      membersByPositionList,
      membersByBoardList,
      projectsByMemberList,
      projectBudgetStatusesList
    ];
    const listHash = keyBy(lists, (item) => item.id);
    const activeFilterTabs = filterSections.map(
      (filterSection) => filterTabs[filterSection] || filterSection
    );
    const listsInOrder = activeFilterTabs.map((id) => listHash[id]);

    // Board projects: in order for loadMoreItems indexing
    // to work properly, if a board is loading, cut off the rest of the lists
    const indexOfProjectsByBoardList =
      listsInOrder.indexOf(projectsByBoardList);
    if (
      projectsByBoardList.isSubListLoading &&
      indexOfProjectsByBoardList > -1
    ) {
      return listsInOrder.slice(0, indexOfProjectsByBoardList + 1);
    }
    const indexOfProjectsByMemberList =
      listsInOrder.indexOf(projectsByMemberList);
    if (
      projectsByMemberList.isSubListLoading &&
      indexOfProjectsByMemberList > -1
    ) {
      return listsInOrder.slice(0, indexOfProjectsByMemberList + 1);
    }
    return listsInOrder;
  }, [
    isDragging,
    widgetConfig,
    filter,
    filteredMembers,
    filteredProjectsByMember,
    members.length,
    customItems.memberList,
    customItems.workGroupList,
    customItems.positionList,
    customItems.projectList,
    customItems.phaseList,
    customItems.activityList,
    customItems.clientList,
    customItems.portfolioList,
    customItems.skillsList,
    customItems.regionsList,
    customItems.officesList,
    customItems.disciplinesList,
    isOpen.projectsByMember,
    isOpen.members,
    isOpen.positions,
    isOpen.membersByPosition,
    isOpen.skills,
    isOpen.projects,
    isOpen.phases,
    isOpen.activities,
    isOpen.statuses,
    isOpen.clients,
    isOpen.billable,
    isOpen.workGroups,
    isOpen.board,
    isOpen.projectBudgetStatuses,
    isOpen.disciplines,
    filteredOffices,
    filteredRegions,
    isOpen.offices,
    isOpen.regions,
    sectionsStartOpen,
    filteredWorkGroups,
    filteredPositions,
    search,
    positions.length,
    filteredMembersByPosition,
    filteredSkills,
    filteredDisciplines,
    formattedMembersByBoard,
    filteredBoards,
    enableDrag,
    isProjectsSidebar,
    filterProjectsCount,
    skipHeader.projects,
    filteredProjects,
    rowTypes,
    showOnEngaged,
    filteredPhases,
    phaseNames.length,
    filteredActivities,
    activities.length,
    statuses,
    filteredClients,
    clients.length,
    billableItems,
    filterSections,
    activeFilterIdHashes.account_ids,
    activeFilterIdHashes.position_ids,
    activeFilterIdHashes.skill_ids,
    activeFilterIdHashes.board_ids,
    activeFilterIdHashes.project_ids,
    activeFilterIdHashes.phase_names,
    activeFilterIdHashes.activity_ids,
    activeFilterIdHashes.status_ids,
    activeFilterIdHashes.client_ids,
    activeFilterIdHashes.clients,
    activeFilterIdHashes.billable,
    activeFilterIdHashes.projectBudgetStatuses,
    dispatch,
    saveActionToUse,
    viewByToUse,
    pageName,
    handleAfterSelect,
    viewBy,
    showArchived,
    workGroupMemberships,
    headerLeftOffset,
    filteredMembersHash,
    handleSetShowArchived,
    team,
    projectsByBoardId,
    filterProjectsGroupCounts,
    projectFilterListId,
    headerTypes,
    leftPadding,
    projectHash,
    filterTabs,
    isStackedFilter,
    sectionToLabel.clients,
    sectionToLabel.members,
    sectionToLabel.phases,
    sectionToLabel.projectBudgetStatuses,
    isProjectPhaseSelector,
    getProjectRowProps,
    loadPhasesByProjectIds,
    isSkillsLoaded,
    handleClearAccountIdsFilter,
    onlySelectedMemberName,
    draftFilter,
    OOOProject?.id,
    additionalFilterOptions,
    sectionToLabel.billable
  ]);

  const flatList = useMemo(
    () => buildList({ lists, engaged, search })?.list ?? emptyArray,
    [engaged, lists, search]
  );

  const firstRowIndices = useMemo(() => {
    return {
      members: flatList.findIndex(
        (item) =>
          item.listType === 'members' ||
          item.listType === 'workGroups' ||
          item.listType === 'positions' ||
          item.listType === 'membersByBoard' ||
          item.listType === 'skills'
      ),
      projects: flatList.findIndex(
        (item) =>
          item.listType === 'projects' ||
          item.listType === 'projectsByBoard' ||
          item.listType === 'projectsByMember'
      ),
      clients: flatList.findIndex((item) => item.listType === 'clients'),
      phases: flatList.findIndex((item) => item.listType === 'phases'),
      activities: flatList.findIndex((item) => item.listType === 'activities')
    };
  }, [flatList]);

  const handleSetIsOpen = useCallback(
    ({ uid, value }) => {
      const { itemType, itemId } = deserializeBar(uid);
      const nameToSetOpen = tabsToSectionsHash[itemId] || itemId;
      const nextIsOpen = {
        ...isOpen,
        [itemType]: { ...isOpen[itemType], [nameToSetOpen]: value }
      };

      const getIsAllCollapsed = () => {
        return Object.keys(nextIsOpen[itemType] || {}).every(
          (id) => !nextIsOpen[itemType][id]
        );
      };
      const getIsAllOpen = () => {
        return Object.keys(nextIsOpen[itemType] || {}).every(
          (id) => nextIsOpen[itemType][id]
        );
      };

      if (!value) {
        setEngaged({ ...engaged, [nameToSetOpen]: value });
        if (getIsAllCollapsed()) {
          setAllCollapsed({ ...allCollapsed, [itemType]: true });
        }
        // if (allOpen[itemType]) {
        //   setAllOpen({ ...allOpen, [itemType]: false });
        // }
      } else {
        // if (getIsAllOpen()) {
        //   setAllOpen({ ...allOpen, [itemType]: true });
        // }
        if (allCollapsed[itemType]) {
          setAllCollapsed({ ...allCollapsed, [itemType]: false });
          setShowDragGrips({ ...showDragGrips, [itemType]: false });
        }
      }
      listRef.current?.resetAfterIndex(0);
      setIsOpen(nextIsOpen);
    },
    [allCollapsed, engaged, isOpen, showDragGrips]
  );
  const handleCollapseAll = useCallback(
    (target) => {
      setIsOpen({ ...isOpen, [target]: {} });
      // setIncludeOrExclude({ ...includeOrExclude, [target]: 'include' });
      setAllCollapsed({ ...allCollapsed, [target]: true });
      // if (allOpen[target]) {
      //   setAllOpen({ ...allOpen, [target]: false });
      // }
      setShowDragGrips({ ...showDragGrips, [target]: true });
    },
    [allCollapsed, isOpen, showDragGrips]
  );
  const handleExpandAll = useCallback(
    (target) => {
      const targetLists = {
        board: [...formattedPersonalBoards, ...boards],
        projectsByMember: formattedProjectsByMember,
        workGroups: filteredWorkGroups
      };
      setIsOpen({
        ...isOpen,
        [target]: targetLists[target].reduce(
          (acc, cur) => ({ ...acc, [cur.id]: true }),
          {}
        )
      });
      // setIncludeOrExclude({ ...includeOrExclude, [target]: 'exclude' });
      setAllCollapsed({ ...allCollapsed, [target]: false });
      // setAllOpen({ ...allOpen, [target]: true });
      setShowDragGrips({ ...showDragGrips, [target]: false });
    },
    [
      allCollapsed,
      boards,
      filteredWorkGroups,
      formattedPersonalBoards,
      isOpen,
      showDragGrips,
      formattedProjectsByMember
    ]
  );
  const toggleCollapseAll = useCallback(
    (target) => {
      if (allCollapsed[target]) {
        handleExpandAll(target);
      } else {
        handleCollapseAll(target);
      }
    },
    [allCollapsed, handleCollapseAll, handleExpandAll]
  );

  useEffect(() => {
    if (
      (filterTabs.members === 'workGroups' ||
        filterTabsOverride.members === 'workGroups') &&
      search.members &&
      !prevIsOpen.current &&
      filteredWorkGroups.some((workGroup) => !isOpen.workGroups?.[workGroup.id])
    ) {
      prevIsOpen.current = isOpen;
      prevAllCollapsed.current = allCollapsed;
      handleExpandAll('workGroups');
    }
  }, [
    allCollapsed,
    filteredWorkGroups,
    handleExpandAll,
    isOpen,
    search.members,
    filterTabs.members,
    filterTabsOverride.members
  ]);

  useEffect(() => {
    if (
      (filterTabs.members === 'membersByposition' ||
        filterTabsOverride.members === 'membersByposition') &&
      search.members &&
      !prevIsOpen.current &&
      filteredMembersByPosition.some(
        (position) => !isOpen.membersByposition?.[position.id]
      )
    ) {
      prevIsOpen.current = isOpen;
      prevAllCollapsed.current = allCollapsed;
      handleExpandAll('membersByposition');
    }
  }, [
    allCollapsed,
    filteredWorkGroups,
    handleExpandAll,
    isOpen,
    search.members,
    filterTabs.members,
    filterTabsOverride.members,
    filteredMembersByPosition
  ]);

  useEffect(() => {
    if (
      filterProjectsFetching &&
      (search.projects || dependencyFilterHasValues) &&
      !prevIsOpen.current &&
      filterTabsOverride?.projects === 'projectsByBoard'
    ) {
      prevIsOpen.current = isOpen;
      prevAllCollapsed.current = allCollapsed;
      handleExpandAll('board');
    }
  }, [
    allCollapsed,
    filterProjectsGroupCounts,
    filteredBoards,
    handleExpandAll,
    isOpen,
    search,
    filterProjectsFetching,
    filterTabsOverride,
    dependencyFilterHasValues
  ]);

  useEffect(() => {
    if (
      (search.projects || dependencyFilterHasValues) &&
      !prevIsOpen.current &&
      filterTabsOverride?.projects === 'projectsByMember'
    ) {
      prevIsOpen.current = isOpen;
      prevAllCollapsed.current = allCollapsed;
      handleExpandAll('projectsByMember');
    }
  }, [
    allCollapsed,
    formattedProjectsByMember,
    handleExpandAll,
    isOpen,
    search,
    filterTabsOverride,
    dependencyFilterHasValues
  ]);

  const handleSetSearch = useCallback(
    ({ name, value }) => {
      setSearch({ ...search, [name]: value });
      handleSearch(name, value);
      listRef.current?.resetAfterIndex(0);
    },
    [handleSearch, search]
  );

  const handleSetFilterTabs = useCallback(
    ({ name, value }) => {
      setFilterTabs({ ...filterTabs, [name]: value });
      listRef.current?.resetAfterIndex(0);
    },
    [filterTabs]
  );
  useEffect(() => {
    if (
      searchOverride?.projects !== undefined &&
      searchOverride?.projects !== search.projects
    ) {
      handleSetSearch({ name: 'projects', value: searchOverride?.projects });
    }
    if (
      searchOverride?.members !== undefined &&
      searchOverride?.members !== search.members
    ) {
      handleSetSearch({ name: 'members', value: searchOverride?.members });
    }
    if (
      searchOverride?.positions !== undefined &&
      searchOverride?.positions !== search.positions
    ) {
      handleSetSearch({ name: 'positions', value: searchOverride?.positions });
    }
    if (
      searchOverride?.clients !== undefined &&
      searchOverride?.clients !== search.clients
    ) {
      handleSetSearch({ name: 'clients', value: searchOverride?.clients });
    }
  }, [
    handleSetSearch,
    search.members,
    search.positions,
    search.projects,
    search.clients,
    searchOverride
  ]);
  useEffect(() => {
    if (
      filterTabsOverride?.projects &&
      filterTabsOverride?.projects !== filterTabs.projects
    ) {
      handleSetFilterTabs({
        name: 'projects',
        value: filterTabsOverride?.projects
      });
    }

    if (
      filterTabsOverride?.members &&
      filterTabsOverride?.members !== filterTabs.members
    ) {
      handleSetFilterTabs({
        name: 'members',
        value: filterTabsOverride?.members
      });
    }
  }, [
    filterTabs.members,
    filterTabs.projects,
    filterTabsOverride,
    handleSetFilterTabs,
    handleSetSearch,
    search.members,
    search.projects,
    searchOverride
  ]);

  const handleSetEngaged = useCallback(
    ({ name, value }) => {
      setEngaged({ ...engaged, [name]: value });
      listRef.current?.resetAfterIndex(0);
    },
    [engaged]
  );

  const getItemSize = useCallback(
    (index) => flatList[index]?.itemHeight || 50,
    [flatList]
  );

  const handleResetHeightCache = useCallback(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, []);

  useEffect(() => {
    handleResetHeightCache();
  }, [handleResetHeightCache, showArchived, isOpen, flatList]);
  // projectsByBoardId

  const isItemLoaded = useCallback((index) => !!flatList[index], [flatList]);

  const loadMoreItems = useCallback(
    (startIndex) => {
      const item = flatList[startIndex - 2];
      if (item && !isShowingSkeletonLoader) {
        const { listType, sectionType } = item;
        const listTypeToUse =
          deserializeSection(sectionType).parentId || listType;
        const section = tabsToSectionsHash[listTypeToUse] || listTypeToUse;
        const apiCall = loadMoreCalls[listTypeToUse];
        const searchText = search[section];

        // when isProjectPhaseSelector, project rows become header rows which has a different prop structure
        const itemToUse =
          isProjectPhaseSelector && item.dataType !== DATATYPES.BOARD
            ? item.parentItem || item.list // when item is loading row, list itself is parentItem
            : item;
        if (apiCall) {
          apiCall(searchText, itemToUse);
        }
      }
    },
    [
      flatList,
      loadMoreCalls,
      search,
      isProjectPhaseSelector,
      isShowingSkeletonLoader
    ]
  );

  const onBeforeCapture = useCallback(
    ({ draggableId }) => {
      // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/responders.md#onbeforecapture - read warnings about modifying position of dragging element before implementing any element repositioning logic.
      const { itemType, itemId } = deserializeBar(draggableId);
      if (!currentDragItem.current) {
        currentDragItem.current = draggableId;
      }
      if (itemType === 'board') {
        prevIsOpen.current = isOpen;
        prevAllCollapsed.current = allCollapsed;
        listRef.current?.scrollToItem(
          flatList.findIndex((item) => item.draggableId === draggableId)
        );
        setIsOpen(DEFAULT_IS_OPEN_STATE);
      }
      setIsDragging(true);
    },
    [allCollapsed, flatList, isOpen]
  );

  const onDragEnd = useCallback(
    ({ draggableId, destination, source }) => {
      currentDragItem.current = null;
      setIsDragging(false);
      /** Uncomment below if we want previously open boards to reopen
    after drop **/
      // if (prevIsOpen.current) {
      //   setIsOpen(prevIsOpen.current);
      // }
      prevIsOpen.current = null;
      prevAllCollapsed.current = null;

      if (!destination) {
        return;
      }

      const { index: destinationIndex } = destination;
      const { index: sourceIndex } = source;
      const { itemId, itemType } = deserializeBar(draggableId);

      switch (itemType) {
        case 'board':
          dispatch(
            changeBoardPosition(
              itemId,
              destinationIndex - formattedPersonalBoards.length
            )
          );
          break;
        case 'project': {
          const project = flatList.find(
            (row) => row.draggableId === draggableId
          );
          const boardId = project?.row.boardId;
          if (project && boardId) {
            const newPosition =
              destinationIndex - (sourceIndex - project.index);

            dispatch(
              updateProjectPosition(
                token,
                itemId,
                true,
                newPosition,
                project.listType,
                true,
                [],
                project.index,
                newPosition,
                projectFilterListId,
                true,
                boardId
              )
            );
          }
          break;
        }
        case 'notPersonalBoardProject':
          dispatch(
            handleErrorMessage({
              type: GENERIC_ACTION,
              isFeError: true,
              errorMessage:
                'Portfolio projects are sorted alphabetically and the order cannot be adjusted on this menu. Project order can be modified in My Projects, Personal and Favorites only.'
            })
          );
          setTimeout(() => dispatch(clearDenyPermission()), 7000);
          break;
        default:
          break;
      }
    },
    [
      dispatch,
      flatList,
      formattedPersonalBoards.length,
      projectFilterListId,
      token
    ]
  );

  const handleScroll = ({ visibleStartIndex }) => {
    if (visibleStartIndex === 0) {
      setActiveSection('members');
      setIsScrolled(false);
      return;
    }
    const item = flatList[visibleStartIndex - 1];
    if (!item) {
      return;
    }
    if (
      item.listType !== activeSection &&
      (!item.sectionType || item.sectionType !== activeSection)
    ) {
      setActiveSection(item.sectionType || item.listType);
    }
    const isScrolled = visibleStartIndex !== 0;
    if (isScrolled !== stateIsScrolled) {
      setIsScrolled(isScrolled);
    }
    rebuildTooltip();
  };

  const { parentId, sectionId } = deserializeSection(activeSection);

  const activeList = lists.find(
    (list) =>
      list.id === parentId ||
      list.stickyHeaderType === parentId ||
      list.sectionType === parentId
  );
  const StickyEl = components[activeList?.headerType];
  const headerItem = (activeList && buildHeader(activeList)) || {
    itemHeight: 70
  };

  const subActiveList = activeList?.listItems.find(
    (list) =>
      list.id === +sectionId ||
      list.stickyHeaderType === +sectionId ||
      list.sectionType === +sectionId
  );

  const SubStickyEl =
    typeof subActiveList?.headerType === 'string'
      ? components[subActiveList.headerType]
      : subActiveList?.headerType;
  const subHeaderItem = (subActiveList && buildHeader(subActiveList)) || {
    itemHeight: 50
  };

  /* ----------------------------- skeleton loader ---------------------------- */

  // reset on initialFetchGroupCountsThenProjectsChainStatus success or failure
  useEffect(() => {
    if (
      initialFetchGroupCountsThenProjectsChainStatus?.isSuccess ||
      initialFetchGroupCountsThenProjectsChainStatus?.error
    ) {
      setIsShowingSkeletonLoader(false);
    }
  }, [
    initialFetchGroupCountsThenProjectsChainStatus,
    setIsShowingSkeletonLoader
  ]);

  // reset on initialFetchAllProjectsRequestStatus success or failure
  useEffect(() => {
    if (
      initialFetchAllProjectsRequestStatus?.isSuccess ||
      initialFetchAllProjectsRequestStatus?.error
    ) {
      setIsShowingSkeletonLoader(false);
    }
  }, [initialFetchAllProjectsRequestStatus, setIsShowingSkeletonLoader]);

  // prevent issue with load more not being called after skeleton loader hidden
  useEffect(() => {
    if (!isShowingSkeletonLoader) {
      resetLoadMoreCache();
    }
  }, [isShowingSkeletonLoader, resetLoadMoreCache]);

  /* ------------------------------------ - ----------------------------------- */

  const itemData = useMemo(
    () => ({
      items: isShowingSkeletonLoader ? emptyArray : flatList,
      search,
      filterTabs,
      engaged,
      me,
      leftOffset,
      leftPadding,
      setIsOpen: handleSetIsOpen,
      setSearch: handleSetSearch,
      setFilterTabs: handleSetFilterTabs,
      setEngaged: handleSetEngaged,
      enableDrag,
      allCollapsed,
      showDragGrips,
      currentDragItem,
      isProjectPhaseSelector,
      loadPhasesByProjectIds,
      clearSearch,
      isSideFilter,
      itemsCount
    }),
    [
      flatList,
      search,
      filterTabs,
      engaged,
      me,
      leftOffset,
      leftPadding,
      handleSetIsOpen,
      handleSetSearch,
      handleSetFilterTabs,
      handleSetEngaged,
      enableDrag,
      allCollapsed,
      showDragGrips,
      isProjectPhaseSelector,
      loadPhasesByProjectIds,
      isShowingSkeletonLoader,
      clearSearch,
      isSideFilter,
      itemsCount
    ]
  );

  return (
    <StyledFilterListContainer
      headerHeight={headerItem.itemHeight}
      $width={width}
      className="filter-list-container"
    >
      {collapseTarget && (
        <CollapseAllContainer
          style={{ right: 14, transformOrigin: 'center 14px' }}
          isCollapsed={allCollapsed[collapseTarget]}
          onClick={() => toggleCollapseAll(collapseTarget)}
        >
          <img src={CollapseAllIcon} />
        </CollapseAllContainer>
      )}

      {isShowingSkeletonLoader && (
        <div
          style={{
            width: '100%',
            height: '100%',
            paddingTop: 46,
            position: 'absolute',
            zIndex: 1
          }}
        >
          <ContentLoader
            height="300"
            primaryColor="#ddd"
            secondaryColor="#eee"
            style={{ margin: '-10px 20px 0px' }}
          >
            <rect x="0" y="5" rx="2" ry="2" width="100%" height="47" />
            <rect x="0" y="65" rx="2" ry="2" width="100%" height="47" />
            <rect x="0" y="125" rx="2" ry="2" width="100%" height="47" />
            <rect x="0" y="185" rx="2" ry="2" width="100%" height="47" />
          </ContentLoader>
        </div>
      )}

      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
        <Droppable
          droppableId={'droppable'}
          mode="virtual"
          renderClone={(provided, snapshot, rubric) => (
            <DragClone>
              <FilterRow
                index={rubric.source.index}
                data={itemData}
                provided={provided}
                style={{ background: theme.colors.colorTranslucentGray4 }}
                isDragging={snapshot.isDragging}
                isSideFilter={isSideFilter}
              />
            </DragClone>
          )}
        >
          {(droppableProvided) => (
            <>
              {showStickyHeader && StickyEl && (
                <div
                  className={`sticky-header ${
                    stateIsScrolled ? 'scrolled' : 'not-scrolled'
                  }`}
                  onClick={() => {
                    handleSetIsOpen({
                      uid: activeList.uid,
                      value: !activeList.isOpen
                    });
                    listRef.current?.scrollToItem(
                      firstRowIndices[parentId] !== undefined
                        ? firstRowIndices[parentId]
                        : firstRowIndices[tabsToSectionsHash[parentId]]
                    );
                    listRef.current?.resetAfterIndex(0);
                  }}
                  style={{
                    boxShadow: stateIsScrolled
                      ? '0px 3px 3px rgba(0, 0, 0, 0.15)'
                      : '',
                    paddingLeft: leftValue,
                    width: '100%',
                    height: headerItem.itemHeight
                  }}
                >
                  <StickyEl
                    setIsOpen={itemData.setIsOpen}
                    setSearch={itemData.setSearch}
                    search={search}
                    me={me}
                    item={headerItem}
                  />
                  {/* lists.find(list => list.id === activeSection)?.renderHeader() */}
                </div>
              )}
              {showSubStickyHeader && SubStickyEl && subActiveList?.isOpen && (
                <div
                  className={`sticky-header ${
                    stateIsScrolled ? 'scrolled' : 'not-scrolled'
                  }`}
                  onClick={() => {
                    listRef.current?.scrollToItem(
                      flatList.findIndex((item) => item.id === subActiveList.id)
                    );
                    listRef.current?.resetAfterIndex(0);
                  }}
                  style={{
                    boxShadow: stateIsScrolled
                      ? '0px 3px 3px rgba(0, 0, 0, 0.15)'
                      : '',
                    width: '100%',
                    height: headerItem.itemHeight
                  }}
                >
                  <SubStickyEl
                    setIsOpen={itemData.setIsOpen}
                    setSearch={itemData.setSearch}
                    search={search}
                    me={me}
                    item={subHeaderItem}
                  />
                  {/* lists.find(list => list.id === activeSection)?.renderHeader() */}
                </div>
              )}
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={2000} // can be arbitrarily large
                loadMoreItems={isShowingSkeletonLoader ? noop : loadMoreItems}
                threshold={threshold}
                ref={infiniteLoaderRef}
              >
                {({ onItemsRendered, ref }) => (
                  <VariableSizeList
                    onItemsRendered={({
                      overscanStartIndex,
                      overscanStopIndex,
                      visibleStartIndex,
                      visibleStopIndex
                    }) => {
                      onItemsRendered({
                        overscanStartIndex,
                        overscanStopIndex,
                        visibleStartIndex,
                        visibleStopIndex
                      });
                      handleScroll({ visibleStartIndex });
                    }}
                    ref={(list) => {
                      ref(list);
                      listRef.current = list;
                    }}
                    overscanCount={10}
                    height={window.innerHeight - innerHeightAdjustment} // todo figure out default height
                    itemCount={flatList.length}
                    itemSize={getItemSize}
                    itemData={itemData}
                    width={width || 240}
                    itemKey={itemKey}
                    className="variable-size-list"
                    outerRef={droppableProvided.innerRef}
                  >
                    {DraggableFilterRow}
                  </VariableSizeList>
                )}
              </InfiniteLoader>
            </>
          )}
        </Droppable>
      </DragDropContext>
    </StyledFilterListContainer>
  );
};

const noop = () => {};
const emptyArray = [];
const billableItems = [BILLABLE_VALUES.BILLABLE, BILLABLE_VALUES.NOT_BILLABLE];
const statusArray = Object.values(TIMESHEET_REPORT_STATUSES);
const phaseBudgetStatuses = [
  BUDGET_STATUSES.NOT_STARTED,
  BUDGET_STATUSES.ACTIVE,
  BUDGET_STATUSES.HOLD,
  BUDGET_STATUSES.COMPLETE
];
const projectBudgetStatuses = [
  BUDGET_STATUSES.PROPOSAL,
  BUDGET_STATUSES.ACTIVE,
  BUDGET_STATUSES.HOLD,
  BUDGET_STATUSES.COMPLETE,
  BUDGET_STATUSES.ARCHIVED
];

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const getActiveWorkloadPlannerFilterIdHashes =
    makeGetActiveWorkloadPlannerFilterIdHashes();
  const getOrderedProjects = makeGetOrderedProjects();
  const getFilterProjectsCount = makeGetFilterProjectsCount();
  const getFilterProjectsGroupCounts = makeGetFilterProjectsGroupCounts();
  const getFilterProjectOrdersByBoard = makeGetFilterProjectOrdersByBoard();
  const getFilterProjectsOffset = makeGetFilterProjectsOffset();
  const getFilterProjectsFetching = makeGetFilterProjectsFetching();
  const getOrderedFilterPositions = makeGetOrderedFilterPositions();
  const getOrderedFilterClients = makeGetOrderedFilterClients();
  const getOrderedFilterTeamMembers = makeGetOrderedFilterTeamMembers();
  const getOrderedFilterActivities = makeGetOrderedFilterActivities();
  const getPhaseNames = makeGetOrderedFilterPhaseNames();
  const getFormattedSkillsWithMembers = makeGetFormattedSkillsWithMembers();

  let initialFetchGroupCountsThenProjectsChainId = null;
  let initialFetchAllProjectsRequestStatusId = null;

  const mapStateToProps = (state, ownProps) => {
    if (
      ownProps.projectFilterListId &&
      !(
        initialFetchGroupCountsThenProjectsChainId &&
        initialFetchAllProjectsRequestStatusId
      )
    ) {
      initialFetchGroupCountsThenProjectsChainId =
        makeInitialFetchGroupCountsThenProjectsChainId(
          ownProps.projectFilterListId
        );
      initialFetchAllProjectsRequestStatusId =
        makeInitialFetchAllProjectsRequestStatusId(
          ownProps.projectFilterListId
        );
    }
    return {
      me: getMe(state),
      token: getAuthToken(state),
      team: getActiveTeam(state),
      teamId: getSelectedTeamId(state),
      projects: getOrderedProjects(state, ownProps),
      boards: getGroups(state),
      groupHash: getGroupsHash(state),
      personalBoards: getPersonalBoards(state) || emptyArray,
      members: getOrderedFilterTeamMembers(state, ownProps),
      positions: getOrderedFilterPositions(state, ownProps),
      clients: getOrderedFilterClients(state, ownProps),
      accountsByPosition: getAccountToTeamPosition(state, ownProps),
      phaseNames: getPhaseNames(state, ownProps),
      activities: getOrderedFilterActivities(state, ownProps),
      billableItems,
      statuses: statusArray,
      fetchedProjectIds: getFetchedProjectIds(state),
      filterProjectsFetching: getFilterProjectsFetching(state, ownProps),
      filterProjectsOffset: getFilterProjectsOffset(state, ownProps),
      filterProjectsCount: getFilterProjectsCount(state, ownProps),
      filterProjectsGroupCounts: getFilterProjectsGroupCounts(state, ownProps),
      projectsByBoardId: getFilterProjectOrdersByBoard(state, ownProps),
      projectsByMemberId: getProjectsByMembersHash(state),
      projectMemberships: state.teamMembers.project_members,
      filter: getActiveWorkloadPlannerFilter(state, ownProps),
      activeFilterIdHashes: getActiveWorkloadPlannerFilterIdHashes(
        state,
        ownProps
      ),
      workGroups:
        getFormattedWorkGroupSettingsWithLeftoverMembersWorkGroup(state),
      workGroupMemberships: state.workGroups.workGroupMemberships,
      workGroupsLoaded: state.workGroups.loaded,
      projectHash: getProjectHash(state),
      skillsArray: getFormattedSkillsWithMembers(state),
      membersWithBoard: state.teamMembers.team_members,
      memberHash: getTeamMembershipsByAccountId(state),
      initialFetchGroupCountsThenProjectsChainId,
      initialFetchAllProjectsRequestStatusId,
      phasesByProjectIdsHash: getPhasesByProjectHash(state),
      projectsByPlannerMemberships:
        getCurrentUserPlannerMemberProjectIds(state),
      isSkillsLoaded: getAreSkillsLoaded(state),
      offices: getFormattedOfficesWithMembers(state),
      regions: getFormattedRegionsWithMembers(state),
      disciplines: getFormattedDisciplinesWithMembers(state),
      OOOProject: getOOOProject(state),
      WFHProject: getWFHProject(state)
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(VirtualFilter);

/* ------------------------------------ - ----------------------------------- */

const makeInitialFetchGroupCountsThenProjectsChainId = (projectFilterListId) =>
  `chain: ${projectFilterListId} project filter list - fetch group counts then projects`;

const makeInitialFetchAllProjectsRequestStatusId = (projectFilterListId) =>
  `${projectFilterListId} project filter list - fetch all projects (by members or no grouping)`;
