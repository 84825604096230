import { useAppSelector } from 'reduxInfra/hooks';
import { TeamMember } from 'models/teamMember';
import { useMemo, MutableRefObject, useCallback, useEffect } from 'react';
import { PTOGroupMembership } from 'SettingsModule/models/pto';
import { makeGetOrderedMemberPTOPolicies } from 'SettingsModule/selectors/ptos';
import MultiStepFlyout from 'components/MultiStepFlyout/MultiStepFlyout';
import {
  StyledCell,
  StyledDate,
  DeleteIconContainer,
  StyledDeleteIcon,
  HeaderContainer,
  StyledMemberName,
  StyledRowContainer,
  StyledSmallPencilIcon,
  AddRateContainer,
  StyledHeaderCell,
  MiniHeaderContainer,
  CloseIconStyle,
  EmptyCell
} from 'BudgetModule/components/BudgetModal/styles';
import { DoneButton } from 'CapacityModule/components/WorkloadModal/styles';
import { MemberInitials } from 'views';
import { getMe } from 'selectors';
import { PTOMemberPolicyDropdownCellProps } from './types';
import { defaultTooltipProps, rebuildTooltip } from 'appUtils/tooltipUtils';
import DateRangeCalendar from 'components/DateRangeCalendar/DateRangeCalendar';
import moment, { Moment } from 'moment';
import { ValueOf } from 'type-fest';
import { PtoGroupMembershipUtils } from 'SettingsModule/utils/ptoGroupMembershipUtils';
import styled from 'styled-components';

const listItemContainerStyle = `
border: none;
padding: 0px;

&:hover{
  background: none;
}
`;

const startLabels = {
  start: 'START'
};

const endLabels = {
  start: 'END'
};

const colWidths = {
  delete: 34
};

const modalColWidths = `${colWidths.delete}px 230px 190px 80px 24px 80px`;

const StartDateCell = ({
  item,
  onDateChange
}: PTOMemberPolicyDropdownCellProps) => {
  return (
    <StyledDate className="startDate" center>
      <DateRangeCalendar
        isSingleDay
        showInputs
        labels={startLabels}
        // @ts-expect-error component not typed properly
        onSubmit={({ startDate }) => {
          if (startDate?.isValid?.()) {
            onDateChange({ item, date: startDate, dateType: 'startDate' });
          }
        }}
        itemStartDate={item.start_date}
        customInput={(startDate, endDate, handleOpen) => (
          <div onClick={handleOpen}>{moment(startDate).format('M/DD/YY')}</div>
        )}
      />
    </StyledDate>
  );
};

const EndDateCell = ({
  item,
  onDateChange
}: PTOMemberPolicyDropdownCellProps) => {
  const isOngoing = PtoGroupMembershipUtils.isOngoing(item);

  return (
    <StyledDate className="endDate" center isDisabled={isOngoing}>
      <DateRangeCalendar
        isSingleDay
        showInputs
        labels={endLabels}
        // @ts-expect-error incorrect inferred types since component written in JS
        onSubmit={({ startDate: endDate }) => {
          if (endDate?.isValid?.()) {
            onDateChange({ item, date: endDate, dateType: 'endDate' });
          }
        }}
        itemStartDate={item.end_date}
        customInput={(startDate, endDate, handleOpen) => (
          <div onClick={isOngoing ? undefined : handleOpen}>
            {isOngoing ? 'Ongoing' : moment(item.end_date).format('M/DD/YY')}
          </div>
        )}
      />
    </StyledDate>
  );
};

const DateSeparatorCell = () => <StyledCell center>-</StyledCell>;

const PolicyCell = ({ onEdit, item }: PTOMemberPolicyDropdownCellProps) => {
  return (
    <StyledCell onClick={() => onEdit(item)}>
      {item.pto_group.hours}h
    </StyledCell>
  );
};

const DescriptionCell = ({
  onEdit,
  item
}: PTOMemberPolicyDropdownCellProps) => (
  <StyledCell onClick={() => onEdit(item)}>
    <span>{item.pto_group.name}</span>
    <StyledSmallPencilIcon />
  </StyledCell>
);

const DeleteCell = ({
  item,
  isDisableDeleteOnlyPolicy,
  isOnlyPolicy,
  onDelete
}: PTOMemberPolicyDropdownCellProps) => {
  const isDeleteDisabled = isOnlyPolicy && isDisableDeleteOnlyPolicy;

  return (
    <DeleteIconContainer
      {...defaultTooltipProps}
      data-tip={isDeleteDisabled ? 'Unable to delete only policy.' : ''}
      onClick={isDeleteDisabled ? undefined : () => onDelete(item)}
    >
      <StyledDeleteIcon />
    </DeleteIconContainer>
  );
};

const columnNames = {
  delete: 'delete',
  policy: 'policy',
  description: 'description',
  startDate: 'startDate',
  dateSeparator: 'dateSeparator',
  endDate: 'endDate'
} as const;

type ColumnNames = ValueOf<typeof columnNames>;
type ColumnFields = { name: ColumnNames; label: string };

const columnsHash: Record<ColumnNames, ColumnFields> = {
  delete: { name: 'delete', label: '' },
  policy: { name: 'policy', label: 'Policy' },
  description: { name: 'description', label: 'Description' },
  startDate: { name: 'startDate', label: 'Start' },
  dateSeparator: { name: 'dateSeparator', label: '' },
  endDate: { name: 'endDate', label: 'End' }
};

const cells: Record<
  ColumnNames,
  (props: PTOMemberPolicyDropdownCellProps) => JSX.Element
> = {
  delete: DeleteCell,
  description: DescriptionCell,
  policy: PolicyCell,
  startDate: StartDateCell,
  dateSeparator: DateSeparatorCell,
  endDate: EndDateCell
};

const HeaderCell = ({
  className,
  label
}: PTOMemberPolicyDropdownHeaderCellProps) => (
  <StyledHeaderCell className={className}>{label}</StyledHeaderCell>
);

const headerCells: Record<
  ColumnNames,
  (props: PTOMemberPolicyDropdownHeaderCellProps) => JSX.Element
> = {
  delete: EmptyCell,
  description: HeaderCell,
  policy: HeaderCell,
  startDate: HeaderCell,
  dateSeparator: HeaderCell,
  endDate: HeaderCell
};

const columns: ColumnFields[] = [
  columnsHash.delete,
  columnsHash.policy,
  columnsHash.description,
  columnsHash.startDate,
  columnsHash.dateSeparator,
  columnsHash.endDate
];

const listWidths = 627 + 16;

interface PTOMemberPolicyDropdownProps {
  target: MutableRefObject<null>;
  teamMember: TeamMember;
  isDisableDeleteOnlyPolicy: boolean;
  onStickyClick: () => void;
  onClose: () => void;
  onEdit: (ptoGroupMembership: PTOGroupMembership) => void;
  onDateChange: ({
    item,
    date,
    dateType
  }: {
    item: PTOGroupMembership;
    date: Moment;
    dateType: string;
  }) => void;
  onDelete: (ptoGroupMembership: PTOGroupMembership) => void;
}

export const PTOMemberPolicyDropdown = ({
  target,
  teamMember,
  isDisableDeleteOnlyPolicy,
  onStickyClick,
  onClose,
  onEdit,
  onDateChange,
  onDelete
}: PTOMemberPolicyDropdownProps) => {
  const getMemberPTOPolicies = useMemo(
    () => makeGetOrderedMemberPTOPolicies(),
    []
  );

  const memberPTOPolicies = useAppSelector((state) =>
    getMemberPTOPolicies(state, { teamMembershipId: teamMember.id })
  );

  const me = useAppSelector(getMe);

  const renderHeader = useCallback(() => {
    return (
      <HeaderContainer style={{ paddingLeft: colWidths.delete }}>
        {/* TODO: Confirm we don't need to support unassigned member like in rates */}
        <MemberInitials
          member={teamMember}
          size="medium"
          classes={`sidebar-member-initials selected ${
            me?.id === teamMember.account.id ? 'logged' : 'regular'
          }-member-no-hover`}
        />
        <StyledMemberName
          style={{ maxWidth: listWidths - colWidths.delete - 32 - 30 }}
        >
          {teamMember.account.first_name}&apos;s PTO Policy
        </StyledMemberName>
      </HeaderContainer>
    );
  }, [me?.id, teamMember]);

  const renderItem = useCallback(
    ({ item }: { item: PTOGroupMembership }) => {
      const earliestPolicyByDate =
        memberPTOPolicies[memberPTOPolicies.length - 1];
      const isEarliestPolicy = earliestPolicyByDate?.id === item.id;
      const isOnlyPolicy = memberPTOPolicies.length === 1;

      const props: PTOMemberPolicyDropdownCellProps = {
        item,
        isDisableDeleteOnlyPolicy,
        isOnlyPolicy,
        onEdit,
        onDelete,
        onDateChange
      };

      return (
        <StyledRowContainer
          colWidths={modalColWidths}
          isLast={isEarliestPolicy}
        >
          {columns.map((column, index) => {
            const Cell = cells[column.name];
            return <Cell {...props} key={index} />;
          })}
        </StyledRowContainer>
      );
    },
    [
      isDisableDeleteOnlyPolicy,
      memberPTOPolicies,
      onDateChange,
      onDelete,
      onEdit
    ]
  );

  const renderTableBodyHeader = useCallback(() => {
    return (
      <div style={{ width: listWidths }}>
        <MiniHeaderContainer colWidths={modalColWidths}>
          {columns.map((column, index) => {
            const Cell = headerCells[column.name];
            return (
              <Cell label={column.label} className={column.name} key={index} />
            );
          })}
        </MiniHeaderContainer>
        <AddRateContainer
          onClick={onStickyClick}
          emptyList={!memberPTOPolicies.length}
          style={{
            width: `calc(100% - ${colWidths.delete}px)`,
            marginLeft: colWidths.delete
          }}
        >
          + Add New
        </AddRateContainer>
      </div>
    );
  }, [memberPTOPolicies.length, onStickyClick]);

  const renderHeaderButton = useCallback(
    () => (
      <CloseIconStyle onClick={onClose}>
        <DoneButton>Done</DoneButton>
      </CloseIconStyle>
    ),
    [onClose]
  );

  useEffect(() => {
    rebuildTooltip();
  }, []);

  return (
    <MultiStepFlyout
      copy={{}}
      target={target}
      items={memberPTOPolicies}
      idKey="id"
      renderHeader={renderHeader}
      renderItem={renderItem}
      handleClose={onClose}
      stickyRow
      hideFooter
      listWidth={listWidths}
      isWhite
      listHeight={200}
      styleWrapper={StyleWrapper}
      listItemContainerStyle={listItemContainerStyle}
      renderTableBodyHeader={renderTableBodyHeader}
      globalClassName="pto-member-policy-dropdown"
      isAlwaysGlobal
      renderHeaderButton={renderHeaderButton}
    />
  );
};

interface PTOMemberPolicyDropdownHeaderCellProps {
  label: string;
  className: string;
}

const StyleWrapper = styled.div<{
  listHeight: number;
}>`
  .empty-list-container {
    height: ${(props) => props.listHeight}px;
  }
`;
