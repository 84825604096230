import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import {
  starProject,
  openEditProjectModal,
  openAddMembersForm,
  openBulkAddMembersForm,
  archiveUnarchiveProject,
  deleteProject,
  hardDeleteProject,
  handleProjectItemState,
  clearSelectedProject,
  toggleDeleteProjectModal,
  navigateToProject,
  exportTasks,
  generateReport,
  cloneProjects
} from 'actionCreators';
import styled from 'styled-components';
import HardDeleteWarningModal from 'components/Modals/HardDeleteWarningModal';
import { getHardDeleteWarningModalProps } from 'IntegrationsModule/utils';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import { withRouter } from 'react-router-dom';
import theme from 'theme';
import ProjectThreeDotMenu from './ProjectThreeDotMenu';
import ModuleList from 'components/ViewFactory/ModuleList';
import ProjectNotificationSettings from 'components/ProjectNotificationSettings';
import ReactTooltip from 'react-tooltip';
import {
  ProjectMembers,
  ProjectDetailUpdates,
  AddMembersContainer,
  SelectBoardDropdownContainer
} from 'views';
import MyStar from 'views/projectDisplay/MyStar';
import AdminTooltip from 'components/Tooltips/ContactAdminsTooltip';
import {
  getOnTaskView,
  getOnNotesView,
  getOnScheduleView,
  getNotesSelectedAccountIds,
  getMe,
  getTaskAccountFilter,
  getSelectedAccountIds,
  getInstalledProjectModuleIds,
  getSelectedTeamId,
  getAuthToken,
  getAccountSlug,
  getUserIsAdmin,
  getMatchedRouteParams,
  getSelectedProjectBoardInfo,
  getGroups,
  getMyPersonalBoardID,
  getUserPermissions,
  getUserIsFinancialManager
} from 'selectors';
import {
  getIsIntegrationsV2Loading,
  getIntegrationsV2,
  getIntegrationsV2Error,
  getMosaicMappingStatuses
} from 'IntegrationsModule/selectors';
import {
  hasAssociatedTime,
  getAssociatedTimeMessage
} from 'appUtils/budgetUtils';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import DeleteIcon from 'icons/delete.svg';
import DeleteIconRed from 'icons/delete-red.svg';
import ArchiveIcon from 'icons/archive.svg';
import SmallPencilIcon from 'icons/small-pencil.svg';
import TeamMembersIcon from 'icons/new-team-member.svg';
import InfoIcon from 'icons/InfoIcon';
import ExportTasks from 'icons/export-tasks.svg';
import ColorSetIcon from 'icons/color-set-icon.svg';
import CopyBatchIcon from 'icons/CopyBatchIcon';
import ColorPicker from 'components/ColorPicker';
import { ColorPickerHoverArea } from 'components/ColorPicker/styles';
import DeleteModal from 'views/taskDisplay/taskUtilityComponents/DeleteModal';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import Popover from 'components/Popover';
import ProjectCloneModal from '../projectCloneModal/ProjectCloneModal';
import KaratRight from 'icons/KaratRight';
import { DATA_TYPES } from 'IntegrationsModule/constants';
import MappingStatusIcon from 'components/MappingStatusIcon';
import {
  deletePortfolioProject,
  archivePortfolioProject
} from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
import {
  DELETE_PROJECT_TIP,
  ARCHIVE_PROJECT_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { noop } from 'appUtils';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

class ProjectDetailHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStep: null,
      isCloneMenuOpen: false,
      isCloneModalOpen: false,
      cloneDestination: null,
      isDownloadOpen: false,
      shouldCloseThreeDot: false,
      isWarningModalOpen: false,
      integrationsContainingMappedDataType: [],
      showGenericWarningMessage: false,
      isCloneProjectMenuOpen: false,
      isCloningIntoExistingProject: false
    };
    this.canDelete = undefined;
    this.canArchive = undefined;
    this.downloadRef = React.createRef();
    this.cloneProjectRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userPermissions !== prevProps.userPermissions) {
      this.canArchive = undefined;
      this.canDelete = undefined;
    }
  }

  checkDeletePermission = () => {
    if (this.canDelete === undefined) {
      const { checkPermission, hardDeleteProject, deletePortfolioProject } =
        this.props;

      const permissions = this.getPermissions();

      if (!permissions) return false;

      const canDeleteProject = checkPermission(hardDeleteProject, {
        permissions
      });
      const canDeletePortfolioProject = checkPermission(
        deletePortfolioProject,
        { permissions }
      );

      this.canDelete = canDeleteProject && canDeletePortfolioProject;
    }
    return this.canDelete;
  };

  checkArchivePermission = () => {
    if (this.canArchive === undefined) {
      const {
        checkPermission,
        archiveUnarchiveProject,
        archivePortfolioProject
      } = this.props;
      const permissions = this.getPermissions();

      if (!permissions) return false;

      const canArchiveProject = checkPermission(archiveUnarchiveProject, {
        permissions
      });

      const canArchivePortfolioProject = checkPermission(
        archivePortfolioProject,
        { permissions }
      );

      this.canArchive = canArchiveProject && canArchivePortfolioProject;
    }
    return this.canArchive;
  };

  getPermissions = () => {
    const { project, selectedTeamId } = this.props;

    if (!selectedTeamId) return;

    return {
      projectId: project?.id,
      boardId: project?.board_id,
      teamId: selectedTeamId
    };
  };

  userCanModify = () =>
    this.props.userIsAdmin || this.props.project?.is_personal;

  handleArchive = () => {
    if (this.checkArchivePermission()) {
      const { archiveUnarchiveProject, token, project } = this.props;
      const permissions = this.getPermissions();

      if (!permissions) return;

      archiveUnarchiveProject({
        token,
        projectId: project.id,
        isActive: !!project.archived_at,
        position: project.position,
        permissions
      });

      this.closeThreeDot();
    }
  };

  archiveProjectAndCloseModal = () => {
    this.handleArchive();
    this.closeDeleteModal();
  };

  openDownloadMenu = () => this.setState({ isDownloadOpen: true });

  closeDownloadMenu = () => this.setState({ isDownloadOpen: false });

  closeThreeDot = () => this.setState({ shouldCloseThreeDot: true });

  openCloneMenu = () => {
    this.setState({ isCloneMenuOpen: true });
  };

  closeCloneMenu = () => {
    this.setState({ isCloneMenuOpen: false });
  };

  toggleCloneMenu = () => {
    this.setState((prevState) => ({
      isCloneMenuOpen: !prevState.isCloneMenuOpen
    }));
  };

  openCloneModal = (e, isCloningIntoExistingProject) => {
    this.setState({
      isCloningIntoExistingProject,
      shouldCloseThreeDot: true,
      isCloneModalOpen: true
    });
  };

  closeCloneModal = () => {
    this.setState({ isCloneModalOpen: false, shouldCloseThreeDot: false });
  };

  handleClone = (flags, targetBoard, cloneDate) => {
    const { cloneProjects, project } = this.props;
    cloneProjects({
      projectIds: { [project.id]: project.id },
      targetBoard: targetBoard.id,
      flags,
      batchId: uuid(),
      ...(cloneDate && {
        relativeWorkdays: true,
        startDate: cloneDate
      })
    });
  };

  closeDeleteModal = (event) => {
    const { toggleDeleteProjectModal } = this.props;
    if (this.userCanModify()) {
      toggleDeleteProjectModal(false);
      if (event) {
        event.preventDefault();
      }
    }
  };

  openDeleteModal = (event) => {
    const { toggleDeleteProjectModal } = this.props;
    /** First check to see if user has permission */
    if (this.userCanModify() && this.checkDeletePermission()) {
      toggleDeleteProjectModal(true);
      if (event) {
        event.preventDefault();
      }
      this.closeThreeDot();
    }
  };

  handleDelete = (event) => {
    const {
      clearSelectedProject,
      handleProjectItemState,
      boardInfo,
      deleteProject,
      project
    } = this.props;
    /** Third check for project having time entries */
    if (this.projectHasTimeEntries()) {
      return;
    }

    const editingProjectId = project.id;
    const selectedBoardId = project.board_id;
    const selectedBoardIsPersonal = project.is_personal;
    const boardSlug = selectedBoardIsPersonal
      ? 'personal'
      : boardInfo?.slug || 'portfolio';
    const isActive = !project.archived_at;

    event.preventDefault();

    const payload = {
      projectId: editingProjectId,
      groupId: selectedBoardId,
      isActive,
      groupSlug: boardSlug,
      isPersonal: selectedBoardIsPersonal
    };

    deleteProject(payload);

    handleProjectItemState(
      null,
      ['detailVisible', 'expanded', 'fixed'],
      [false, false, false]
    );
    clearSelectedProject();
  };

  starProject = (e) => {
    const { starProject, project } = this.props;
    e.stopPropagation();
    starProject({
      projectId: project.id,
      starred: !project.is_starred,
      position: project.position,
      isProjectDetail: true
    });
  };

  onNotificationsClick = () => {
    this.notificationsRef.openDropdown();
  };

  setNotificationsRef = (ref) => (this.notificationsRef = ref);
  onInfoClick = (event) => {
    const { project } = this.props;
    event.stopPropagation();
    this.closeThreeDot();
    this.props.openEditProjectModal(project.id, project.is_personal);
  };

  changeTabs = (viewType) => (e) => {
    const { project, matchedParams, navigateToProject } = this.props;
    const { projectSlug, projectId } = matchedParams;
    navigateToProject({
      teamSlug: project.team_slug,
      projectSlug,
      projectId,
      viewType
    });
  };

  setModalStep = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      modalStep: e.currentTarget.getAttribute('data-modalstep')
    });
  };

  closeModalStep = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({
      modalStep: null,
      isDownloadOpen: false,
      shouldCloseThreeDot: true
    });
  };

  renderModalStep = (step) => {
    const hash = {
      color: this.renderColorModalStep,
      addMembers: this.renderAddMemberModalStep
    };
    return hash[step]();
  };

  renderColorModalStep = () => {
    const { project } = this.props;
    const closeOnSelect = () => {
      this.setState({
        modalStep: null
      });
    };
    return (
      <ColorPickerContainer>
        <ColorPicker
          projectId={project.id}
          id={project.id}
          originType={ORIGIN_TYPE_STRINGS.PROJECT}
          pickerLocation="flyout-menu"
          row
          className="color-picker-container"
          headerText="Select Project Color"
          alwaysOpen
          handleSelect={closeOnSelect}
        >
          <div />
        </ColorPicker>
      </ColorPickerContainer>
    );
  };

  renderAddMemberModalStep = () => (
    <AddMembersContainer
      isOpenMembersModal
      closeModalStep={this.closeModalStep}
      modalType={MODAL_TYPE.PROJECT}
    />
  );

  renderArchivedText = () => {
    const { project } = this.props;
    return project.archived_at ? <ArchivedText>Archived</ArchivedText> : '';
  };

  exportTasks = () => {
    const { exportTasks, project } = this.props;
    exportTasks({
      project_ids: [project.id]
    });
  };

  exportTasksXls = () => {
    const { generateReport, project } = this.props;
    generateReport({ report_type: 'ProjectTasks', actable_id: project.id });
  };

  projectHasTimeEntries = () =>
    this.props.hasTimeEntries?.find(
      (hasTimeEntry) =>
        hasTimeEntry.project_id === this.props.project?.id &&
        hasAssociatedTime(hasTimeEntry)
    );

  renderDeleteBody = () => {
    const { loadingHasTimeEntries, project } = this.props;
    if (loadingHasTimeEntries) {
      return <div />;
    }
    const isActive = !project.archived_at;
    const associatedTime = this.projectHasTimeEntries();

    /** Check if has time entries or workplans */
    if (associatedTime) {
      return (
        <>
          <b>{project.title}</b>{' '}
          {getAssociatedTimeMessage(associatedTime, MODAL_TYPE.PROJECT)}
          {isActive ? ' Would you like to Archive instead?' : ''}
        </>
      );
    }

    return (
      <>
        Are you sure you want to <b>permanently</b> delete{' '}
        <b>{project.title}</b>? You can’t restore a deleted Project.
      </>
    );
  };

  renderDeleteConfirm = ({ deleteOnClick }) => {
    const { project, userIsAdmin, userIsFinancialManger } = this.props;

    const isActive = !project.archived_at;
    const projectHasTimeEntries = this.projectHasTimeEntries();

    const canHardDelete = userIsAdmin || userIsFinancialManger;

    if (projectHasTimeEntries) {
      /**
       * Goal of hard delete
       * - The user wants to be able to delete duplicates time entries from CSV imports
       */

      return (
        <div>
          {isActive && (
            <button
              style={{ width: '6rem' }}
              onClick={this.archiveProjectAndCloseModal}
            >
              Archive
            </button>
          )}
          {canHardDelete && (
            <button
              style={{ backgroundColor: 'red', width: '8rem' }}
              onClick={this.handleHardDelete}
            >
              Delete forever
            </button>
          )}
        </div>
      );
    }

    return (
      <div>
        <button onClick={deleteOnClick}>Yes</button>
      </div>
    );
  };

  handleHardDelete = (event) => {
    /** Hard delete check */
    const {
      mosaicMappingStatuses,
      integrationsV2,
      isV2Loading,
      isV2Error,
      project,
      toggleDeleteProjectModal,
      clearSelectedProject,
      handleProjectItemState,
      boardInfo,
      hardDeleteProject
    } = this.props;

    const {
      integrationsContainingMappedDataType,
      showHardDeleteWarningModal,
      showGenericWarningMessage
    } = getHardDeleteWarningModalProps(
      mosaicMappingStatuses,
      integrationsV2,
      isV2Loading,
      isV2Error,
      DATA_TYPES.PROJECTS,
      project.id
    );

    if (showHardDeleteWarningModal) {
      /** Show hard delete warning modal */
      toggleDeleteProjectModal(false);
      this.setState({
        isWarningModalOpen: true,
        showGenericWarningMessage,
        shouldCloseThreeDot: true,
        integrationsContainingMappedDataType
      });
      return;
    }

    const editingProjectId = project.id;
    const selectedBoardId = project.board_id;
    const selectedBoardIsPersonal = project.is_personal;
    const boardSlug = selectedBoardIsPersonal
      ? 'personal'
      : boardInfo?.slug || 'portfolio';
    const isActive = !project.archived_at;

    event.preventDefault();

    const payload = {
      projectId: editingProjectId,
      groupId: selectedBoardId,
      isActive,
      groupSlug: boardSlug,
      isPersonal: selectedBoardIsPersonal
    };

    hardDeleteProject(payload);

    handleProjectItemState(
      null,
      ['detailVisible', 'expanded', 'fixed'],
      [false, false, false]
    );
    clearSelectedProject();
  };

  closeWarningModal = () => {
    this.setState({
      isWarningModalOpen: false,
      showGenericWarningMessage: false,
      integrationsContainingMappedDataType: []
    });
  };

  render() {
    const {
      project,
      isOnTaskView,
      isOnNotesView,
      selectedAccountIds,
      projectSelectedAccountIds,
      selectedNotesAccountIds,
      me,
      openAddMembersForm,
      installedModuleIds,
      isOpenDeleteProjectModal,
      boardInfo,
      boards,
      matchedParams: { projectViewType }
    } = this.props;
    const { modalStep, isCloneMenuOpen, isCloneModalOpen } = this.state;
    if (!project) return null;
    const {
      client,
      project_number,
      is_starred,
      title,
      description,
      is_personal
    } = project;

    const hasClientOrNumber = client || project_number;
    const starType = is_starred ? 'selected' : 'unselected';

    const canArchive = this.checkArchivePermission();
    const canDelete = this.checkDeletePermission();

    return (
      <PersonalProjectHeightCalculator className="name-desc-members">
        <PersonalProjectHeightCalculator className="detail-header">
          <div className="detail-left">
            <div className="name">
              <div>
                <ProjectDetailHeaderWrapper className="project-name">
                  <ColorPickerHoverArea>
                    <ColorPicker
                      projectId={project.id}
                      id={project.id}
                      originType={ORIGIN_TYPE_STRINGS.PROJECT}
                      row
                      className="color-picker-container"
                    />
                  </ColorPickerHoverArea>
                  <StyledProjectandInfoContainer>
                    <StyledDot projectId={project.id} />
                    <StyledProjectTitle
                      data-testid={`${title}`}
                      projectId={project.id}
                      className="project-name"
                      color={theme.colors.colorSemiDarkGray4}
                      data-tip={title}
                      data-for={title.length > 32 ? 'app-tooltip' : ''}
                      data-effect="solid"
                      data-place="top"
                      onClick={this.onInfoClick}
                    >
                      {title}
                    </StyledProjectTitle>
                    <StyledInfoContainer onClick={this.onInfoClick}>
                      <StyledInfoIcon
                        height="16"
                        width="16"
                        className="info-icon"
                      />
                    </StyledInfoContainer>
                  </StyledProjectandInfoContainer>

                  <ProjectDetailSettings className="project-detail-settings">
                    <MappingStatusIcon
                      id={project.id}
                      dataType={DATA_TYPES.PROJECTS}
                      Container={MappingStatusIconContainer}
                      tooltipPlacement="right"
                    />
                    <SettingsBox
                      onClick={this.starProject}
                      className="project-settings-box"
                    >
                      <MyStar
                        starType={starType}
                        position={'detail-header'}
                        project={project}
                        onProjectRow={false}
                      />
                    </SettingsBox>
                    <SettingsBox
                      onClick={this.onNotificationsClick}
                      className="project-settings-box"
                    >
                      <ProjectNotificationSettings
                        project={project}
                        ref={this.setNotificationsRef}
                        onProjectRow
                        onProjectDetail
                      />
                    </SettingsBox>

                    <ProjectThreeDotMenu
                      modalStep={this.state.modalStep}
                      closeModalStep={this.closeModalStep}
                      isModalOpen={isOpenDeleteProjectModal}
                      threeDotClassName="three-dots-container"
                      shouldClose={this.state.shouldCloseThreeDot}
                      onCloseCallback={() =>
                        this.setState({
                          shouldCloseThreeDot: false,
                          isCloneMenuOpen: false
                        })
                      }
                    >
                      {modalStep ? (
                        this.renderModalStep(modalStep)
                      ) : (
                        <div className="project-menu-item-container">
                          <InfoAndArchived
                            onClick={this.onInfoClick}
                            className="project-menu-item"
                          >
                            <IconContainer>
                              <img
                                className="grey-icon"
                                src={SmallPencilIcon}
                              />
                            </IconContainer>
                            Edit Project Info
                          </InfoAndArchived>

                          {!is_personal && (
                            <AddEditMembers
                              onClick={(e) => {
                                openAddMembersForm(
                                  MODAL_TYPE.PROJECT,
                                  project,
                                  'flyout-menu'
                                );
                                this.setModalStep(e);
                              }}
                              data-modalstep="addMembers"
                              className="project-menu-item"
                            >
                              <IconContainer>
                                <img
                                  className="grey-icon"
                                  src={TeamMembersIcon}
                                />
                              </IconContainer>
                              <div>Add/Edit Members</div>
                            </AddEditMembers>
                          )}
                          <div
                            onClick={this.setModalStep}
                            data-modalstep="color"
                            className="project-menu-item"
                          >
                            <IconContainer>
                              <img className="grey-icon" src={ColorSetIcon} />
                            </IconContainer>
                            <div>Set Project Color</div>
                          </div>
                          <div
                            className="project-menu-item"
                            onClick={this.openDownloadMenu}
                            ref={this.downloadRef}
                          >
                            <IconContainer>
                              <img className="grey-icon" src={ExportTasks} />
                            </IconContainer>
                            <div>Download</div>
                            <IconContainer>
                              <KaratRight />
                            </IconContainer>
                            <Popover
                              isOpen={this.state.isDownloadOpen}
                              target={this.downloadRef}
                              closePopover={this.closeDownloadMenu}
                              placement="right-start"
                            >
                              <DownloadMenu onClick={this.closeThreeDot}>
                                <div
                                  className="downloadMenuItem"
                                  onClick={this.exportTasks}
                                >
                                  .csv
                                </div>
                                <div
                                  className="downloadMenuItem"
                                  onClick={this.exportTasksXls}
                                >
                                  .xls
                                </div>
                              </DownloadMenu>
                            </Popover>
                          </div>
                          <div
                            className="project-menu-item"
                            onClick={this.toggleCloneMenu}
                            ref={this.cloneProjectRef}
                          >
                            <IconContainer>
                              <CopyBatchIcon fill="#4F4F4F" />
                            </IconContainer>
                            <div>Clone Project</div>
                            <IconContainer>
                              <KaratRight />
                            </IconContainer>
                            <Popover
                              isOpen={this.state.isCloneMenuOpen}
                              target={this.cloneProjectRef}
                              closePopover={this.closeCloneMenu}
                              placement="right-start"
                            >
                              <StyledCloneProjectMenu>
                                {CLONE_PROJECT_OPTIONS.map((option) => (
                                  <StyledCloneProjectMenuItem
                                    onClick={(e) =>
                                      this.openCloneModal(
                                        e,
                                        option.mergeProject
                                      )
                                    }
                                    key={option.label}
                                  >
                                    {option.label}
                                  </StyledCloneProjectMenuItem>
                                ))}
                              </StyledCloneProjectMenu>
                            </Popover>
                          </div>
                          <LineBreak />
                          <Archive
                            className="project-menu-item"
                            onClick={canArchive ? this.handleArchive : noop}
                            data-for="app-tooltip"
                            data-tip={ARCHIVE_PROJECT_TIP}
                            data-tip-disable={canArchive}
                            data-effect="solid"
                            data-class="center"
                          >
                            <IconContainer>
                              <img className="grey-icon" src={ArchiveIcon} />
                            </IconContainer>
                            {`${
                              project.archived_at
                                ? 'Unarchive Project'
                                : 'Archive Project'
                            }`}
                          </Archive>
                          <Delete
                            className="project-menu-item"
                            onClick={canDelete ? this.openDeleteModal : noop}
                            data-for="app-tooltip"
                            data-tip={DELETE_PROJECT_TIP}
                            data-tip-disable={canDelete}
                            data-effect="solid"
                            data-class="center"
                          >
                            <IconContainer>
                              <img
                                className="delete-grey-icon"
                                src={DeleteIcon}
                              />
                              <img
                                className="delete-red-icon"
                                src={DeleteIconRed}
                              />
                            </IconContainer>
                            Delete Project
                            {!this.userCanModify() && <AdminTooltip />}
                          </Delete>
                        </div>
                      )}
                    </ProjectThreeDotMenu>
                    {!!this.props.isOpenDeleteProjectModal && (
                      <DeleteModal
                        isOpen={!!this.props.isOpenDeleteProjectModal}
                        toggle={this.closeDeleteModal}
                        deleteOnClick={this.handleDelete}
                        renderBody={this.renderDeleteBody}
                        component={'project'}
                        renderDelete={this.renderDeleteConfirm}
                      />
                    )}
                    {isCloneModalOpen && (
                      <ProjectCloneModal
                        handleClone={this.handleClone}
                        handleCancel={this.closeCloneModal}
                        showProjectSelector={
                          this.state.isCloningIntoExistingProject
                        }
                      />
                    )}
                  </ProjectDetailSettings>
                  {this.renderArchivedText()}
                </ProjectDetailHeaderWrapper>
                <div className="project-info">
                  <div className={description ? 'info-item' : 'hide'}>
                    {description}
                  </div>
                  <div className={hasClientOrNumber ? 'info-item' : 'hide'}>
                    {buildNumberClientString(client, project_number)}
                  </div>
                </div>
              </div>
            </div>
            <ModuleList
              currentTab={projectViewType}
              changeTabs={this.changeTabs}
              installedModuleIds={installedModuleIds}
              viewType={'project'}
            />
          </div>
          <div className="detail-right">
            {!project.is_personal && (
              <>
                <ProjectMembers
                  openAddMembersForm={openAddMembersForm}
                  me={me}
                  isOnTaskView={isOnTaskView}
                  isOnNotesView={isOnNotesView}
                  selectedAccountIds={
                    isOnNotesView
                      ? selectedNotesAccountIds
                      : isOnTaskView
                      ? selectedAccountIds
                      : projectSelectedAccountIds
                  }
                  project={project}
                  projectId={project?.id}
                />
                {(isOnTaskView || isOnNotesView) &&
                  project?.status?.length > 0 && (
                    <ProjectDetailUpdates project={project} />
                  )}
              </>
            )}
          </div>
        </PersonalProjectHeightCalculator>
        <HardDeleteWarningModal
          toggle={this.closeWarningModal}
          isOpen={this.state.isWarningModalOpen}
          showGenericWarningMessage={this.state.showGenericWarningMessage}
          dataType={DATA_TYPES.PROJECTS}
          integrationsContainingMappedDataType={
            this.state.integrationsContainingMappedDataType
          }
        />
      </PersonalProjectHeightCalculator>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  me: getMe(state),
  myPersonalBoardId: getMyPersonalBoardID(state),
  accountSlug: getAccountSlug(state),
  isOnTaskView: getOnTaskView(state),
  isOnNotesView: getOnNotesView(state),
  isOnScheduleView: getOnScheduleView(state),
  selectedAccountIds: getTaskAccountFilter(state),
  isOpenDeleteProjectModal: state.projects.isOpenDeleteProjectModal,
  selectedNotesAccountIds: getNotesSelectedAccountIds(state),
  projectSelectedAccountIds: getSelectedAccountIds(state),
  installedModuleIds: getInstalledProjectModuleIds(state, ownProps),
  selectedTeamId: getSelectedTeamId(state),
  token: getAuthToken(state),
  userIsAdmin: getUserIsAdmin(state),
  userIsFinancialManger: getUserIsFinancialManager(state),
  matchedParams: getMatchedRouteParams(state),
  boardInfo: getSelectedProjectBoardInfo(state),
  boards: getGroups(state),
  hasTimeEntries: state.timesheets.hasTimeEntries,
  loadingHasTimeEntries: state.timesheets.loadingHasTimeEntries,
  userPermissions: getUserPermissions(state),
  integrationsV2: getIntegrationsV2(state),
  isV2Loading: getIsIntegrationsV2Loading(state),
  isV2Error: getIntegrationsV2Error(state),
  mosaicMappingStatuses: getMosaicMappingStatuses(state)
});

const mapDispatchToProps = {
  openEditProjectModal,
  starProject,
  openAddMembersForm,
  openBulkAddMembersForm,
  archiveUnarchiveProject,
  deleteProject,
  hardDeleteProject,
  handleProjectItemState,
  clearSelectedProject,
  toggleDeleteProjectModal,
  navigateToProject,
  generateReport,
  exportTasks,
  cloneProjects,
  deletePortfolioProject,
  archivePortfolioProject
};

export default withRouter(
  withPermissionsCheck(
    connect(mapStateToProps, mapDispatchToProps)(ProjectDetailHeader)
  )
);

const ColorPickerContainer = styled.div``;
const IconContainer = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  transition: 0.275s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);

    transform-origin: center;
    circle {
      fill: ${theme.colors.colorRoyalBlue};
      stroke: ${theme.colors.colorRoyalBlue};
    }
    path {
      fill: ${theme.colors.colorPureWhite};
    }
  }
`;

const ArchivedText = styled.div`
  font-size: 24px;
  color: ${theme.colors.colorLightGray6};
`;

const buildNumberClientString = (client, number) => {
  if (number && client) return `${number} - ${client}`;
  else if (number) return number;
  else if (client) return client;
  else return '';
};
const StyledProjectTitle = styled.div`
  color: ${theme.colors.colorDarkBlue};
  padding-left: 10px;
  max-width: 440px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const MappingStatusIconContainer = styled.div`
  width: 26px;
  height: 36px;
  padding-top: 3px;
  margin: 0px 6px 0px -2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledInfoContainer = styled.div`
  margin-left: 9px;
  margin-top: 2px;
  display: flex;
  align-items: center;
`;
const StyledProjectandInfoContainer = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    ${StyledProjectTitle} {
      color: ${theme.colors.colorRoyalBlue};
    }
    ${StyledInfoIcon} {
      transform: scale(1.1);

      transform-origin: center;
      circle {
        fill: ${theme.colors.colorRoyalBlue};
        stroke: ${theme.colors.colorRoyalBlue};
      }
      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

const LineBreak = styled.div`
  height: 1px;
  margin: 4px 8px;
  background-color: ${({ theme }) => theme.colors.colorPaleGray5};
`;
const PersonalProjectHeightCalculator = styled.div`
  height: ${(props) => (props.is_personal ? '70px' : '140px')};
`;

const ProjectDetailHeaderWrapper = styled.div`
  display: flex;
  padding-left: 8px;

  ${ColorPickerHoverArea} {
    display: flex;
  }
`;

const StyledDot = styled.div`
  background: ${calculateProjectColorPreference};
  height: 12px;
  width: 12px;
  border-radius: 100px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

const InfoAndArchived = styled.div`
  display: inline-flex;
  align-items: center;
`;

const AddEditMembers = styled.div``;

const Archive = styled.div``;

const Delete = styled.div`
  &:hover {
    color: ${(props) => props.theme.colors.colorDeleteRed} !important;
  }
`;

const ProjectDetailSettings = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 14px;
`;

const SettingsBox = styled.div`
  display: flex;
  height: 36px;
  flex-basis: 26px;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
`;

const DownloadMenu = styled.div`
  width: 61px;
  display: flex;
  flex-direction: column;

  .downloadMenuItem {
    font-size: 14px;
    font-weight: normal;
    color: ${({ theme }) => ` ${theme.colors.colorSemiDarkGray3}`};
    height: 34px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    :hover {
      background-color: ${({ theme }) =>
        ` ${theme.colors.colorTranslucentGray4}`};
    }
  }
`;

const StyledCloneProjectMenu = styled.div`
  width: 143px;
  display: flex;
  flex-direction: column;
  padding: 13px 0px 13px 0px;
`;

const StyledCloneProjectMenuItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${theme.colors.colorMediumGray9};
  padding: 3px 0px 3px 16px;
  :hover {
    background-color: ${({ theme }) =>
      ` ${theme.colors.colorTranslucentGray4}`};
  }
  &:first-child {
    margin-bottom: 3px;
  }
`;

const CLONE_PROJECT_OPTIONS = [
  {
    label: 'To New Project',
    mergeProject: false
  }
  // {
  //   label: 'To Existing Project',
  //   mergeProject: true
  // }
];
