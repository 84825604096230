import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSelectedTeamId,
  getGroups,
  getAuthToken,
  getIsCSVProvisioned,
  getDefaultIntegrationBoard
} from 'selectors';
import {
  getIsProvisioning,
  getIsIntegrationsV2Loading
} from 'IntegrationsModule/selectors';
import {
  provisionIntegration,
  setIsProvisioning
} from 'IntegrationsModule/actionCreators';
import { DEFAULT_BOARD_NAME } from 'IntegrationsModule/constants';
import { createBoard } from 'actionCreators';
import CircleSpinner from 'components/Spinners/CircleSpinner';
import styled from 'styled-components';

const StyledInitButton = styled.div`
  color: ${(props) => props.theme.colors.colorPureWhite};
  background: ${(props) => props.theme.colors.colorRoyalBlue};
  border-radius: 4px;
  padding: 5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  transition: color 0.2s, background-color, 0.2s;
  cursor: pointer;
`;
const ProvisionButton = () => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const boards = useSelector(getGroups);
  const token = useSelector(getAuthToken);
  const defaultIntegrationBoard = useSelector(getDefaultIntegrationBoard);

  const foundDefaultBoardId = defaultIntegrationBoard?.id;
  const isProvisioning = useSelector(getIsProvisioning);
  const isProvisioned = useSelector(getIsCSVProvisioned);
  const isFetchingProvisions = useSelector(getIsIntegrationsV2Loading);

  // todo, refactor provisioning step to re-usable hook.

  const onSubmit = () => {
    const onProvision = (defaultBoardId) => {
      dispatch(
        provisionIntegration({
          redirectToIntegrationOnSuccess: false,
          mosaicTeamId: teamId,
          targetService: 'csv-agent',
          serverUrl: 'https://google.com', // placeholder that doesn't break anything
          integrationSetting: {
            constructedByAgent: true,
            sendTimeEntryToMosaicRawEndpoint: true,
            defaultBoardId
          },
          paramsOnAgent: {
            serviceName: 'csv-agent'
          }
        })
      );
    };

    if (!foundDefaultBoardId) {
      const onSuccess = [
        {
          successAction: (boardId) => {
            if (boardId) onProvision(boardId);
          },
          selector: (payload, response) => {
            return response.id;
          }
        }
      ];
      dispatch(setIsProvisioning(true));
      dispatch(
        createBoard(
          token,
          DEFAULT_BOARD_NAME,
          false,
          true,
          null,
          true,
          onSuccess
        )
      );
    } else {
      onProvision(foundDefaultBoardId);
    }
  };

  if (!isProvisioned && !isFetchingProvisions) {
    return (
      <StyledInitButton onClick={onSubmit}>Initialize Imports</StyledInitButton>
    );
  }
  if (isProvisioning) {
    return <CircleSpinner />;
  }
  if (isProvisioned) {
    return null;
  }
  return <>Something Unexpected Happened</>;
};

export default ProvisionButton;
