import React from 'react';

import { areEqual } from 'react-window';
import { Draggable } from 'react-beautiful-dnd';
import { StyledTableRow } from './styles';
import onClickOutside from 'react-onclickoutside';
import DevProps from 'components/dev/DevProps';

const noop = () => {};

// key provided by getCellProps
/* eslint-disable react/jsx-key */

export const DraggableTableRow = React.memo(
  ({ index, style, data: props, isScrolling }) => {
    const {
      prepareRow,
      rows,
      isDragDisabled = true,
      customRowProps,
      hideDevProps
    } = props;
    const row = rows[index];
    prepareRow(row);
    const rowProps = row.getRowProps({ style });
    const { key } = rowProps;

    const {
      draggableId,
      isDragDisabled: rowIsDragDisabled,
      handleClickOutside
    } = row.original;

    if (rowIsDragDisabled || isDragDisabled) {
      return (
        <TableRowRenderer
          row={row}
          prepareRow={prepareRow}
          style={style}
          handleClickOutside={handleClickOutside || noop}
          customRowProps={customRowProps}
          hideDevProps={hideDevProps}
          isScrolling={isScrolling}
        />
      );
    }

    if (__DEV__ && !draggableId && !isDragDisabled && !rowIsDragDisabled) {
      console.log(row);
      throw new Error(
        `row at index ${index} is missing a draggableId. More row infomation:
      rowType: ${row.original.rowType}
      id: ${row.original.id}
      draggableId: ${draggableId}
      `
      );
    }

    return (
      <Draggable
        index={index}
        draggableId={`${draggableId}`}
        isDragDisabled={isDragDisabled || rowIsDragDisabled}
        key={draggableId || key}
      >
        {(draggableProvided) => (
          <TableRowRenderer
            provided={draggableProvided}
            row={row}
            prepareRow={prepareRow}
            style={style}
            handleClickOutside={handleClickOutside || noop}
            customRowProps={customRowProps}
            hideDevProps={hideDevProps}
            isScrolling={isScrolling}
          />
        )}
      </Draggable>
    );
  },
  areEqual
);

// eslint-disable-next-line react/display-name
export const TableRow = React.memo(
  ({ index, style, data: props, provided }) => {
    const { prepareRow, rows, customRowProps } = props;
    const row = rows[index];

    return (
      <TableRowRenderer
        style={style}
        row={row}
        prepareRow={prepareRow}
        provided={provided}
        handleClickOutside={row.original.handleClickOutside || noop}
        customRowProps={customRowProps}
      />
    );
  },
  areEqual
);

export const TableRowRenderer = onClickOutside(function ({
  provided = { draggableProps: {}, dragHandleProps: {} },
  row,
  prepareRow,
  style = {},
  customRowProps,
  hideDevProps,
  isScrolling
}) {
  prepareRow(row);
  const rowProps = row.getRowProps({ style });
  const { style: rowStyle } = rowProps;

  const {
    rowType,
    isOpen,
    className = '',
    columnClassNames,
    devProps,
    rowTooltipProps,
    rowClick
  } = row.original;
  const rowState = isOpen ? 'open' : 'closed';
  const rowActiveState = row.state.isActive ? 'active' : '';
  return (
    <StyledTableRow
      {...rowTooltipProps}
      {...rowProps}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={rowClick && rowClick}
      hasAction={!!rowClick}
      className={`tr ${rowType} ${rowState} ${rowActiveState} ${className}`}
      style={{
        ...rowStyle,
        ...provided.draggableProps.style,
        margin: 0
      }}
    >
      {row.cells.map((cell) => {
        const customColumnWidth = cell.column.customColumnWidths?.[rowType];
        return cell.column[rowType] ? (
          <div
            {...cell.getCellProps()}
            {...(customColumnWidth && { style: { width: customColumnWidth } })}
            className={`td table-cell ${rowType} ${cell.column.id} ${
              columnClassNames?.[cell.column.id] || ''
            }`}
          >
            {!hideDevProps && (
              <DevProps {...(devProps || { rowOriginal: row.original })} />
            )}
            {cell.render(rowType, { customRowProps, isScrolling })}
          </div>
        ) : null;
      })}
    </StyledTableRow>
  );
});
