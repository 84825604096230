import {
  MouseEvent,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import cn from 'classnames';
import theme from 'theme';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import styled from 'styled-components';
import FindPeopleTable from './Table/FindPeopleTable';
import FindPeopleFilter from './Filter/FindPeopleFilter';
import Header from './Header';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
/* -------------------------------------------------------------------------- */
import { getActivePhasesByProject, getTeamSlug } from 'selectors';
import { getProjectHash } from 'ProjectsModule/selectors';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { getSettingsModule } from 'SettingsModule/package/settingsModule';
import { getSelectedTeamId } from 'TeamsModule/selectors';
/* -------------------------------------------------------------------------- */
import {
  fetchMemberBudgets,
  fetchBudgetRecords
} from 'BudgetModule/actionCreators';
import {
  fetchSuggestions as fetchMemberSuggestions,
  fetchPhaseTotals,
  navigateToProject,
  fetchPhases
} from 'actionCreators';
import {
  useLoadOffices,
  useLoadRegions,
  useLoadDisciplines,
  useLoadSkills
} from 'SettingsModule/hooks';
/* -------------------------------------------------------------------------- */
import useDispatchChain from 'appUtils/hooks/useDispatchChain';
import { useRequestStatus } from 'appUtils/hooks/useRequestStatus';
import { useLoadPositions } from 'BudgetModule/hooks';
/* -------------------------------------------------------------------------- */
import { useToggle, useMount } from 'react-use';
import useUnassignedRolesByProjectAndPhases, {
  ProjectUnassignedMemberBudgetWithPosition,
  PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
} from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import useAddRemoveUnassignedRoleFromPhasesDropdown from 'components/roles/dropdowns/UnassignedRolesDropdown/useAddRemoveUnassignedRoleFromPhasesDropdown';
import { ProjectsThenPhasesDropdownSelectedItem } from 'components/ProjectsThenPhasesDropdown/type';
import useFeatureFlags from 'appUtils/hooks/useFeatureFlags';
import moment from 'moment';
import { FindPeopleTableVariantOptions } from './Table/tableConfigs';
import {
  FilterStateIds,
  RequestStatusIds,
  requirementsSelectorPortalId
} from './constants';
import { getCreatePhaseMembersRefetchActions } from './utils';
import { MOMENT_ISO_DATE } from 'appConstants/date';
import ProjectTeam from 'SuggestionModule/components/FindPeople/List/ProjectTeam/ProjectTeamListContainer';
import { FindPeopleModalContext } from 'SuggestionModule/components/FindPeople/contexts/FindPeopleModalContext';
import {
  OnAddRequirementsClickArgs,
  OnDeleteRequirementArgs
} from './contexts/FindPeopleModalContext';
import {
  fetchEntityRequirements,
  updateEntityRequirements
} from 'RequirementsModule/actionCreators';
import { getRequirementsModule } from 'RequirementsModule/package';
import { ENTITY_REQUIREMENT_TYPES } from 'RequirementsModule/constants';
import { getRequirementAssociationsByEntityIdHash } from 'RequirementsModule/selectors/entityRequirements';
import { makeEntitiesArrayForEntityRequirementFetch } from 'RequirementsModule/utils';
import RemoveMemberModal from 'BudgetModule/components/BudgetModal/RemoveMemberModal';
import { BlueSubmitButton, TextButtonWithBorder } from 'components/styles';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import { Modals, REPORT_VIEW_TYPE } from 'appConstants/navigation';
import { useFindPeopleFilterContext } from './filterConfig';
import { FindPeopleProjectSelector } from './ProjectSelector/FindPeopleProjectSelector';
import useHasStateChanged from 'appUtils/hooks/useHasStateChanged';
import { FilterField } from 'FilterModule/constants';

const emptyObj = {};
const emptyArr = [];

const getDefaultText = ({
  projectId,
  unassignedMemberBudgetId
}: {
  projectId: Nullable<number>;
  unassignedMemberBudgetId: Nullable<number>;
}) => {
  // Case 1: Nothing was selected
  if (!projectId && !unassignedMemberBudgetId)
    return 'Add Project and Role to find available team members.';

  // Case 2: Only project is selected, not unassigned role
  if (projectId && !unassignedMemberBudgetId)
    return 'Choose or Add Role to find available team members.';

  return '';
};

/**
 * Return a list of phase ids that does not have activity phases and has the unassigned role
 */
const getActivePhasesOrder = ({
  phaseIds,
  getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
  phasesHash,
  memberBudgetId
}: {
  phaseIds: number[];
  getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId;
  phasesHash: ReturnType<typeof getFlatPhasesHash>;
  memberBudgetId: Nullable<number>;
}) => {
  return phaseIds.filter((id) => {
    const phase = phasesHash[id];

    return (
      phase &&
      phase.activity_order.length === 0 &&
      getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
        {
          phaseId: id,
          memberBudgetId
        }
      )
    );
  });
};

const FindPeopleContainer = ({
  toggleModal,
  $projectId,
  $unassignedMemberBudgetId // Unused right now
}) => {
  const dispatch = useAppDispatch();
  const dispatchChain = useDispatchChain();

  const { currentFilter } = useFindPeopleFilterContext();

  /* --------------------------------- Project -------------------------------- */

  const [isProjectSelectorOpen, toggleIsProjectSelectorOpen] = useToggle(false);

  const selectedProjectId = currentFilter.project_ids[0] || null;

  /* ----------------------------- Unassigned Role ---------------------------- */
  const [
    selectedUnassignedMemberBudgetId,
    setSelectedUnassignedMemberBudgetId
  ] = useState<Nullable<number>>(null);

  const { showAISuggestionsMemberModalDemo: showDemoSuggestions } =
    useFeatureFlags();
  const projectHash = useAppSelector(getProjectHash);
  const phasesHash = useAppSelector(getFlatPhasesHash);
  const activePhasesByProject = useAppSelector(getActivePhasesByProject);
  const teamId = useAppSelector(getSelectedTeamId);
  const teamSlug = useAppSelector(getTeamSlug);
  const requirementAssociationsByProjectMembershipIdHash = useAppSelector(
    (state) =>
      getRequirementAssociationsByEntityIdHash(state, {
        entityRequirementType: ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP
      })
  );

  const {
    getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
    getUnassignedMemberBudgetWithPositionForProject,
    unassignedMemberBudgetsWithPositionForProject,
    unassignedMemberBudgetsWithPositionForProjectHash
  } = useUnassignedRolesByProjectAndPhases({
    projectId: selectedProjectId
  });

  const { project, allProjectPhasesOrder, activeProjectPhasesOrder } =
    useMemo(() => {
      const project =
        selectedProjectId !== null ? projectHash[selectedProjectId] : undefined;

      const { allPhasesOrder = emptyArr } =
        selectedProjectId !== null
          ? activePhasesByProject[selectedProjectId] || emptyObj
          : emptyObj;

      return {
        project,
        allProjectPhasesOrder: (allPhasesOrder as number[]) || emptyArr,
        activeProjectPhasesOrder: getActivePhasesOrder({
          phaseIds: allPhasesOrder,
          phasesHash,
          getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
          memberBudgetId: selectedUnassignedMemberBudgetId
        })
      };
    }, [
      activePhasesByProject,
      getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
      phasesHash,
      projectHash,
      selectedProjectId,
      selectedUnassignedMemberBudgetId
    ]);

  const {
    AddRemoveUnassignedRoleFromPhasesDropdown,
    openAddRemoveUnassignedRoleFromPhasesDropdown,
    isAddingUnassignedRoleToPhases,
    addUnassignedRoleToPhasesSuccess
  } = useAddRemoveUnassignedRoleFromPhasesDropdown({
    projectId: selectedProjectId,
    unassignedMemberBudgetId: selectedUnassignedMemberBudgetId,
    shouldCreatePhaseMembersActionRefetch: false,
    phasesOrder: allProjectPhasesOrder
  });

  const { status: fetchMemberSuggestionForPhasesRequestStatus } =
    useRequestStatus({
      requestStatusId: RequestStatusIds.fetchMemberSuggestionForPhases
    });

  const { status: fetchEntityRequirementsRequestStatus } = useRequestStatus({
    requestStatusId: RequestStatusIds.fetchEntityRequirements
  });

  const { status: fetchPhaseTotalsRequestStatus } = useRequestStatus({
    requestStatusId: RequestStatusIds.fetchPhaseTotals
  });

  const isFetchingMemberSuggestionForPhase =
    fetchMemberSuggestionForPhasesRequestStatus?.isLoading;

  const isFetchingEntityRequirements =
    fetchEntityRequirementsRequestStatus?.isExecuting;

  const isFetchingPhaseTotals = fetchPhaseTotalsRequestStatus?.isExecuting;

  /* --------------------------- Interactions --------------------------- */

  const resetUnassignedRoleAndSelectedPhaseIds = useCallback(() => {
    setSelectedUnassignedMemberBudgetId(null);
  }, []);

  const openBudgetModal = useCallback(() => {
    dispatch(
      navigateToProject({
        projectId: selectedProjectId,
        teamSlug,
        projectSlug: project?.slug,
        viewType: REPORT_VIEW_TYPE.BUDGET,
        openInNewWindow: true,
        modal: Modals.BUDGET,
        budgetViewType: REPORT_VIEW_TYPE.BUDGET
      })
    );
  }, [dispatch, project?.slug, selectedProjectId, teamSlug]);

  // /** Fetch entities and requirements */
  useLoadOffices({ shouldAutoLoad: true });
  useLoadRegions({ shouldAutoLoad: true });
  useLoadSkills({ shouldAutoLoad: true });
  useLoadPositions({ shouldAutoLoad: true });
  useLoadDisciplines({ shouldAutoLoad: true });

  const triggerFetchMembersSuggestion = useCallback(
    ({
      memberBudgetId,
      shouldShowSkeletonLoader
    }: {
      memberBudgetId: Nullable<number>;
      shouldShowSkeletonLoader?: boolean;
    }) => {
      const phaseMembershipsForSuggestion = allProjectPhasesOrder.reduce(
        (
          acc: PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition[],
          phaseId
        ) => {
          const phaseMembership =
            getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId(
              {
                phaseId,
                memberBudgetId
              }
            );

          if (phaseMembership) {
            acc.push(phaseMembership);
          }
          return acc;
        },
        []
      );

      /** Fetch suggestions */
      if (
        memberBudgetId &&
        phaseMembershipsForSuggestion?.length &&
        selectedProjectId
      ) {
        // const onSuccess = [
        //   {
        //     successAction: ({ payload, response }) => {
        //       const actions = phaseMembershipsForSuggestion.map((pm) => {
        //         const phase = phasesHash[pm.phase_id];
        //         const { phaseMembershipStartDate, phaseMembershipEndDate } =
        //           getPhaseDatesData({
        //             phaseMembership: pm,
        //             phase
        //           });
        //         /**
        //          * Note: Need to fetch seperately due to differences in start and end date
        //          */
        //         return fetchBudgetRecords({
        //           params: {
        //             all: true,
        //             team_id: teamId,
        //             project_ids: [selectedProjectId],
        //             phase_ids: [pm.phase_id],
        //             member_budget_ids: [memberBudgetId],
        //             data_type: BUDGET_RECORD_DATA_TYPES.ACCOUNT_PHASE,
        //             start_date: phaseMembershipStartDate,
        //             end_date: phaseMembershipEndDate
        //           },
        //           filterStateModifier:
        //             fetchBudgetRecordsForPhasesFilterStateModifier,
        //           filterStateId: FilterStateIds.fetchBudgetRecordsForPhases
        //         });
        //       });

        //       dispatchChain([actions], {
        //         chainId: RequestStatusIds.fetchBudgetRecordsForPhases,
        //         initial: true,
        //         takeLatest: true,
        //         continueOnFailure: true,
        //         continueOnCancellation: true
        //       } as any); // chainId is null, so need to wait until dispatchChain is typed);
        //     },
        //     selector: (payload, response) => ({ payload, response })
        //   }
        // ];
        dispatch(
          fetchMemberSuggestions({
            phase_membership_ids: phaseMembershipsForSuggestion.map(
              (phaseMembership) => phaseMembership.id
            ),
            start_date: moment().add(-2, 'weeks').format(MOMENT_ISO_DATE),
            end_date: moment().add(2, 'weeks').format(MOMENT_ISO_DATE),
            requestStatusId: RequestStatusIds.fetchMemberSuggestionForPhases,
            ...(shouldShowSkeletonLoader
              ? {
                  initial: true
                }
              : {})
          })
        );
      }
      setSelectedUnassignedMemberBudgetId(() => {
        // There is a case when successfully removing an unassigned role from the phase, then refetched latest project member Budgets
        // won't contain the member budget of the currently selected unassigned role, resulting in a stale state
        // It appears that once the unassigned role is removed from the phase, BE will also remove that unassigned role from the project.
        // Therefore we need to check during every suggestion fetch action that the project still has the unassigned role
        // If it was removed, then the user must reselect a new role to view suggestion for
        const isSelectedUnassignedRoleExistOnProject = memberBudgetId
          ? !!unassignedMemberBudgetsWithPositionForProjectHash[memberBudgetId]
          : false;

        return isSelectedUnassignedRoleExistOnProject ? memberBudgetId : null;
      });
    },
    [
      allProjectPhasesOrder,
      dispatch,
      getPhaseMembershipWithUnassignedMemberBudgetAndPositionByPhaseIdAndMemberBudgetId,
      selectedProjectId,
      unassignedMemberBudgetsWithPositionForProjectHash
    ]
  );
  const { status: refetchAfterAddRoleToPhasesStatus } = useRequestStatus({
    requestStatusId: RequestStatusIds.refetchAfterAddRoleToPhases
  });

  // Refetch actions chain for adding roles to phases success
  useEffect(() => {
    if (!isAddingUnassignedRoleToPhases && addUnassignedRoleToPhasesSuccess) {
      const actions = getCreatePhaseMembersRefetchActions({
        projectId: selectedProjectId as number
      });
      dispatchChain([actions], {
        chainId: RequestStatusIds.refetchAfterAddRoleToPhases,
        continueOnFailure: true,
        continueOnCancellation: true,
        cleanupChainStatuses: true
      });
    }
  }, [
    isAddingUnassignedRoleToPhases,
    addUnassignedRoleToPhasesSuccess,
    selectedProjectId,
    dispatchChain
  ]);

  // Refetch suggestions after the above refetching action is success
  useEffect(() => {
    if (
      !refetchAfterAddRoleToPhasesStatus?.isExecuting &&
      refetchAfterAddRoleToPhasesStatus?.isSuccess
    ) {
      triggerFetchMembersSuggestion({
        memberBudgetId: selectedUnassignedMemberBudgetId
      });
    }
  }, [
    refetchAfterAddRoleToPhasesStatus?.isExecuting,
    refetchAfterAddRoleToPhasesStatus?.isSuccess,
    selectedUnassignedMemberBudgetId,
    triggerFetchMembersSuggestion
  ]);

  /* -------------------------- Project Selector ------------------------- */
  const triggerFetchNewSelectedProjectData = useCallback(
    ({ projectId }) => {
      if (projectId) {
        dispatch(
          fetchMemberBudgets({
            projectId,
            onSuccess: [
              {
                successAction: ({ payload, response }) => {
                  if (response && response.length) {
                    const unassignedMemberBudgets = response.filter(
                      (mb) => !mb.account_id
                    );
                    const entities = makeEntitiesArrayForEntityRequirementFetch(
                      unassignedMemberBudgets,
                      ENTITY_REQUIREMENT_TYPES.PROJECT_MEMBERSHIP
                    );
                    dispatch(
                      fetchEntityRequirements({
                        entities,
                        team_id:
                          teamId ||
                          (unassignedMemberBudgets[0]?.team_id as number),
                        meta: {
                          requestStatusId:
                            RequestStatusIds.fetchEntityRequirements
                        }
                      })
                    );
                  }
                },
                selector: (payload, response) => ({ payload, response })
              }
            ]
          })
        );

        dispatch(fetchPhases({ projectId }));

        // Fetching the numbers to show on when assigning an unassigned role to a phase
        dispatch(
          fetchPhaseTotals({
            projectId,
            initial: true,
            filterStateId: FilterStateIds.fetchPhaseTotals,
            requestStatusId: RequestStatusIds.fetchPhaseTotals
          })
        );
      }
    },
    [dispatch, teamId]
  );

  const hasSelectedProjectIdChanged = useHasStateChanged(selectedProjectId);

  /**
   * When new project is selected
   * - Reset the unassigned role and phase, so user has to rechoose
   * - Refetch member budgets and phase totals (budget data) for this project
   */
  useEffect(() => {
    if (hasSelectedProjectIdChanged) {
      triggerFetchNewSelectedProjectData({ projectId: selectedProjectId });
      resetUnassignedRoleAndSelectedPhaseIds();
    }
  }, [
    dispatch,
    resetUnassignedRoleAndSelectedPhaseIds,
    selectedProjectId,
    triggerFetchNewSelectedProjectData,
    currentFilter,
    hasSelectedProjectIdChanged
  ]);

  /**
   * If project id is given, overwrite the selected project in the filter and save
   * immediately to BE to store as the last selected project
   */
  useMount(() => {
    if ($projectId && selectedProjectId !== $projectId) {
      currentFilter.update(
        { project_ids: [$projectId] },
        { fieldsToSaveAfterUpdate: [FilterField.project_ids] }
      );
    }
  });

  /* ------------------------ Unassigned Role Selector ------------------------ */

  /**
   * Use this if you know the member budget id of the unassigned role
   * Will trigger a new member suggestion fetch
   */
  const handleSelectUnassignedRole = useCallback(
    ({ memberBudgetId }: { memberBudgetId: number }) => {
      if (memberBudgetId !== selectedUnassignedMemberBudgetId) {
        triggerFetchMembersSuggestion({
          memberBudgetId,
          shouldShowSkeletonLoader: true
        });
      }
    },
    [selectedUnassignedMemberBudgetId, triggerFetchMembersSuggestion]
  );

  // Specifically for selecting an item in the unassigned role dropdown
  const handleSelectUnassignedRoleFromDropdown = useCallback(
    (item: ProjectUnassignedMemberBudgetWithPosition) => {
      const newSelectedUnassignedMemberBudgetId = item.id;
      handleSelectUnassignedRole({
        memberBudgetId: newSelectedUnassignedMemberBudgetId as number
      });
    },
    [handleSelectUnassignedRole]
  );

  /* ------------------------------ Requirements ------------------------------ */

  const [isRequirementsSelectorOpen, toggleRequirementsSelector] =
    useToggle(false);

  const onDeleteRequirement = useCallback(
    ({ association_updates }: OnDeleteRequirementArgs) => {
      dispatch(
        updateEntityRequirements({
          team_id: teamId as number,
          association_updates
        })
      );
    },
    [dispatch, teamId]
  );

  const onAddRequirementsClick = useCallback(
    ({ memberBudgetId, projectMembershipId }: OnAddRequirementsClickArgs) => {
      toggleRequirementsSelector(true);
    },
    [toggleRequirementsSelector]
  );

  /* ---------------------------------- Variables ---------------------------------- */
  const numOfProjectUnassignedRoles =
    unassignedMemberBudgetsWithPositionForProject.length;

  const activePhasesNum = activeProjectPhasesOrder.length;

  const selectedUnassignedMemberBudget =
    getUnassignedMemberBudgetWithPositionForProject({
      memberBudgetId: selectedUnassignedMemberBudgetId
    });

  const contextValues = useMemo(
    () => ({
      project,
      selectedProjectId,
      selectedUnassignedMemberBudgetId,
      numOfProjectUnassignedRoles,
      activeProjectPhasesOrder,
      allProjectPhasesOrder,
      phasesHash,
      showDemoSuggestions,
      isRequirementsSelectorOpen,
      isFetchingMemberSuggestionForPhase,
      isFetchingEntityRequirements,
      requirementAssociationsByProjectMembershipIdHash,
      teamId,
      teamSlug,
      selectedUnassignedMemberBudget,
      isFetchingPhaseTotals,
      toggleRequirementsSelector,
      handleSelectUnassignedRole,
      handleSelectUnassignedRoleFromDropdown,
      resetUnassignedRoleAndSelectedPhaseIds,
      triggerFetchMembersSuggestion,
      onDeleteRequirement,
      onAddRequirementsClick,
      openBudgetModal,
      isProjectSelectorOpen,
      toggleIsProjectSelectorOpen
    }),
    [
      teamId,
      project,
      selectedProjectId,
      selectedUnassignedMemberBudgetId,
      numOfProjectUnassignedRoles,
      activeProjectPhasesOrder,
      allProjectPhasesOrder,
      phasesHash,
      showDemoSuggestions,
      isRequirementsSelectorOpen,
      isFetchingEntityRequirements,
      isFetchingMemberSuggestionForPhase,
      requirementAssociationsByProjectMembershipIdHash,
      selectedUnassignedMemberBudget,
      isFetchingPhaseTotals,
      teamSlug,
      toggleRequirementsSelector,
      handleSelectUnassignedRole,
      handleSelectUnassignedRoleFromDropdown,
      resetUnassignedRoleAndSelectedPhaseIds,
      triggerFetchMembersSuggestion,
      onDeleteRequirement,
      onAddRequirementsClick,
      openBudgetModal,
      isProjectSelectorOpen,
      toggleIsProjectSelectorOpen
    ]
  );

  return (
    <DynamicModuleLoader
      modules={[
        getBudgetModule(),
        getSettingsModule(),
        getRequirementsModule()
      ]}
    >
      <FindPeopleModalContext.Provider value={contextValues}>
        <div id={requirementsSelectorPortalId} />

        <FindPeopleProjectSelector
          isOpen={isProjectSelectorOpen}
          toggleIsOpen={toggleIsProjectSelectorOpen}
        />

        <ContainerDiv>
          <Header toggleModal={toggleModal} />
          <Body>
            {/* Left Panel */}
            <FilterContainer>
              <FindPeopleFilter />
            </FilterContainer>

            {/* Middle Panel */}
            <TableContainer
              className={cn({
                'project-selected': !!selectedProjectId,
                'no-phases-selected':
                  !!selectedProjectId &&
                  !!selectedUnassignedMemberBudgetId &&
                  activePhasesNum === 0
              })}
            >
              {selectedUnassignedMemberBudgetId ? (
                <>
                  <div className="middle-panel-header">
                    <div className="phase-selector-container">
                      <TextButtonWithBorder
                        className="add-phase-button"
                        onClick={(event) =>
                          openAddRemoveUnassignedRoleFromPhasesDropdown({
                            event
                          })
                        }
                      >
                        {`${
                          activePhasesNum > 0
                            ? `${activePhasesNum} ${
                                activePhasesNum > 1 ? 'Phases' : 'Phase'
                              }`
                            : '+ Phases'
                        }`}
                      </TextButtonWithBorder>
                      {AddRemoveUnassignedRoleFromPhasesDropdown}
                    </div>
                    <div className="buttons-container">
                      <StyledButton
                        onClick={openBudgetModal}
                        className="budget"
                      >
                        <GoToProjectIcon />
                        Budget
                      </StyledButton>
                    </div>
                  </div>
                  {activePhasesNum ? (
                    <FindPeopleTable
                      variant={
                        FindPeopleTableVariantOptions.MemberSuggestionByPhase
                      }
                    />
                  ) : (
                    <div className="default-table-text">
                      Click + Phase above to add Role to phases.
                    </div>
                  )}
                </>
              ) : (
                <div className="default-table-text">
                  {getDefaultText({
                    projectId: selectedProjectId,
                    unassignedMemberBudgetId: selectedUnassignedMemberBudgetId
                  })}
                </div>
              )}
            </TableContainer>

            {/* Right Panel */}
            <RightPanelContainer>
              {selectedProjectId ? (
                <ProjectTeam isLoading={isFetchingPhaseTotals} />
              ) : (
                <div className="right-panel-empty-div"></div>
              )}
            </RightPanelContainer>
          </Body>
          {/* <AddUnassignRoleToPhasesModal /> */}
          <RemoveMemberModal />
        </ContainerDiv>
      </FindPeopleModalContext.Provider>
    </DynamicModuleLoader>
  );
};

export default FindPeopleContainer;

const ContainerDiv = styled.div`
  margin: 35px 50px 0 50px;
  height: 100%;
`;

const RightPanelContainer = styled.div`
  margin-top: 3rem;

  .right-panel-empty-div {
    width: 25%;
  }
`;

const FilterContainer = styled.div`
  padding-top: 10px;
  width: 320px;
`;

const TableContainer = styled.div`
  margin: 0 auto;

  .middle-panel-header {
    display: grid;
    grid-template-columns: 265px 400px;
    align-items: center;
    position: relative;
    top: 15px;
    z-index: 2;

    .phase-selector-container {
      padding-left: 10px;
      width: fit-content;
      cursor: pointer;
      color: ${theme.colors.colorCalendarBlue};

      .add-phase-button {
        font-weight: 600;
        border-radius: 3px;
        border: 1px solid ${theme.colors.colorPaleBlue};
        cursor: pointer;
        color: ${theme.colors.colorCalendarBlue};
        height: 24px;
        font-size: 13px;
        filter: none;
        padding: 9px;
      }

      path {
        fill: ${theme.colors.colorCalendarBlue};
      }

      &:hover {
        font-weight: 600;
      }

      .toggle-container {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .toggle-text {
        font-size: 20px;
        font-weight: 600;
        color: ${theme.colors.colorMediumGray9};
      }
    }

    .buttons-container {
      margin-left: auto;
    }
  }

  .default-table-text {
    position: relative;
    top: 6rem;
    font-size: 14px;
    right: 175px;
    font-weight: 400;
    font-style: italic;
    color: ${theme.colors.colorCalendarGray};
  }

  &.project-selected {
    .default-table-text {
      right: 0;
    }
  }

  &.no-phases-selected {
    .default-table-text {
      text-align: center;
      top: 4.5rem;
    }
  }
`;

const Body = styled.div`
  display: flex;
`;

const StyledButton = styled(BlueSubmitButton)`
  width: fit-content;
  border-radius: 20px;
  font-weight: 600;

  &:focus {
    filter: none; // prevent background turning grey after click
  }

  &.budget {
    background-color: ${theme.colors.colorPureWhite};
    color: ${theme.colors.colorCalendarBlue};
    border: 0.5px solid ${theme.colors.colorCalendarBlue};
    font-size: 13px;
    height: 24px;
    padding: 0 9px 0 4px;

    &:hover {
      background-color: ${theme.colors.colorRoyalBlue};
      color: ${theme.colors.colorPureWhite};

      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }
  }
`;
