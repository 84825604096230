import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import {
  Field,
  Label
} from 'SettingsModule/components/StandardSettings/General/styles';
import styled from 'styled-components';
import { OrganizationSettingsPopover } from '../shared/OrganizationSettingsPopover';

export const WorkplanLockSetting = () => {
  const options = {
    'hrs/day': {
      item: (
        <OptionWithTooltip
          data-for="app-tooltip"
          data-tip="Lock the Hours Planned per day"
          data-effect="solid"
          data-html
          data-class="center"
        >
          Hrs/Day
          <StyledFloatHelpIcon
            className={undefined}
            width={8}
            height={8}
            dataTip={undefined}
          />
        </OptionWithTooltip>
      )
    },
    percent: {
      item: (
        <OptionWithTooltip
          data-for="app-tooltip"
          data-tip="Lock the % of the Assigned<br/>Member's Workday"
          data-effect="solid"
          data-html
          data-class="center"
        >
          % of Workday
          <StyledFloatHelpIcon
            className={undefined}
            width={8}
            height={8}
            dataTip={undefined}
          />
        </OptionWithTooltip>
      )
    },
    total: {
      item: (
        <OptionWithTooltip
          data-for="app-tooltip"
          data-tip="Lock the Total Hours of<br/>the Work Plan"
          data-effect="solid"
          data-html
          data-class="center"
        >
          Total Hrs
          <StyledFloatHelpIcon
            className={undefined}
            width={8}
            height={8}
            dataTip={undefined}
          />
        </OptionWithTooltip>
      )
    }
  };

  return (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="Set the default locking for when Work<br/>
            Plans dates are modified. Defaults can be<br/>
            personalized by individual users and<br/>
            changed for individual work plans."
        data-effect="solid"
        data-html
        data-class="center"
      >
        Work Plan Locking Default
        <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <OrganizationSettingsPopover
          options={options}
          currentlySelectedKey="percent"
          showEllipsisTooltip={false}
        />
      </Field>
    </>
  );
};

const OptionWithTooltip = styled.div`
  width: fit-content;
`;

const StyledFloatHelpIcon = styled(FloatHelpIcon)`
  position: relative;
  top: -5px;
  left: 3px;
`;
