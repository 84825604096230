import { useEffect } from 'react';
import styled from 'styled-components';
import EllipsisText from 'components/EllipsisText';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import ReactTooltip from 'react-tooltip';

const CurrencyDropdownItem = ({ currencyString, currencySymbol }) => {
  useEffect(() => {
    rebuildTooltip();

    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return (
    <ItemContainer>
      <EllipsisText>{currencyString}</EllipsisText>
      <CurrencySymbol>{currencySymbol}</CurrencySymbol>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorPaleGray9};
  width: 100%;
`;

const CurrencySymbol = styled.div`
  width: fit-content;
  flex: none;
`;

export default CurrencyDropdownItem;
