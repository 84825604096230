import { DATE_FNS_USA_DATE, DATE_FNS_ISO_DATE } from 'appConstants/date';
import {
  parse,
  formatISO,
  format,
  eachDayOfInterval,
  isBefore,
  isSameDay
} from 'date-fns';

/**
 * micro optimized date parse function
 * pass only YYYY-MM-DD
 */
export const parseDate = (dateOnlyString: string) =>
  new Date(dateOnlyString + 'T00:00');

const dayUnit = 1000 * 3600 * 24;

/**
 * micro optimized difference function
 * pass native Date Object
 */
export const differenceInDays = (left: Date, right: Date) =>
  Math.round(left.getTime() - right.getTime()) / dayUnit;

/**
 * Converts a date from the USA `31/01/2000` to the ISO `2000-01-31`.
 */
export const convertUsaDateToIsoDate = (dateString: string): string =>
  formatISO(parse(dateString, DATE_FNS_USA_DATE, new Date()), {
    representation: 'date'
  });

// parse stringified date properly without losing time due to local timezone
export const getISOStringParsedDateObj = (date: string) =>
  new Date(new Date(date).toISOString().slice(0, -1));

/**
 * Converts a date in string to format in `dateFormat` without losing time because of time zone.
 * dateFormat needs to be compatible with the date-fns library.
 */

export const getFormattedDateWithDateFns = (
  dateString: string,
  dateFormat: string
) => format(getISOStringParsedDateObj(dateString), dateFormat);

const getDatesInterval = ({
  startDate,
  endDate
}: {
  startDate: string;
  endDate: string;
}) => {
  const startDateObj = getISOStringParsedDateObj(startDate);
  const endDateObj = getISOStringParsedDateObj(endDate);
  return isBefore(startDateObj, endDateObj) ||
    isSameDay(startDateObj, endDateObj)
    ? eachDayOfInterval({
        start: startDateObj,
        end: endDateObj
      })
    : [];
};

export const getFormattedDatesInterval = ({
  startDate,
  endDate,
  dateFormat = DATE_FNS_ISO_DATE
}: {
  startDate: string;
  endDate: string;
  dateFormat?: string;
}) =>
  getDatesInterval({
    startDate,
    endDate
  }).map((date) => format(date, dateFormat));
