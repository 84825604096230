import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { LIST_ITEM_TYPES } from 'SuggestionModule/components/FindPeople/List/constants';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import { UseNestedCollapseHookReturnedValues } from 'appUtils/hooks/useNestedCollapse/types';
import {
  makeGetOwnBudgetTotals,
  getMemberBudgets
} from 'BudgetModule/selectors';
import {
  PhaseListMembershipListItem,
  AddMemberToPhaseListItem,
  PhaseMembershipWithUnassignedMemberBudgetAndFormattedPositionListItem
} from '../types';
import { getAccountTotalsByMemberBudgetId } from 'SuggestionModule/components/FindPeople/utils';
import { Phase } from 'ProjectsModule/phases/models/phase';

const useProjectTeamListItemsBuilder = ({
  getIsOpen,
  toggleCollapse,
  getCurrentParentCollapseState,
  setParentCollapseState
}: UseNestedCollapseHookReturnedValues) => {
  const teamMembershipByAccountId = useAppSelector(
    getTeamMembershipsByAccountId
  );
  const memberBudgets = useAppSelector(getMemberBudgets);

  const getBudgetTotals = useMemo(makeGetOwnBudgetTotals, []);

  const { phaseTotals } = useAppSelector((state) =>
    getBudgetTotals(state, { filterStateId: FilterStateIds.fetchPhaseTotals })
  );

  const projectTeamListItemsBuilder = ({
    isParentOpen,
    order: phaseMembershipsOrder,
    phaseMembershipHashOfPhase,
    phaseId,
    phase,
    projectId,
    parentPhaseHasActivitiesPhase,
    openBudgetModal
  }: {
    isParentOpen?: boolean;
    order: number[];
    phaseMembershipHashOfPhase;
    phaseId: number;
    phase: Phase;
    projectId: number;
    parentPhaseHasActivitiesPhase: boolean;
    openBudgetModal: () => void;
  }) => {
    const list = phaseMembershipsOrder.reduce(
      (
        acc: (PhaseListMembershipListItem | AddMemberToPhaseListItem)[],
        phaseMembershipId: number,
        index
      ) => {
        const phaseMembership = phaseMembershipHashOfPhase[phaseMembershipId];
        const accountTotals = getAccountTotalsByMemberBudgetId(
          phase,
          phaseTotals,
          phaseMembership?.member_budget_id
        );
        const phaseMembershipListItem = {
          id: `pm-${phaseMembership.id}`,
          itemType: LIST_ITEM_TYPES.PhaseMembership,
          isOpen: true,
          isLast: phaseMembershipsOrder.length - 1 === index,
          parentId: undefined,
          toggleId: undefined,
          toggleCollapse: undefined,
          isParentOpen,
          teamMembershipByAccountId,
          phaseMembership,
          accountTotals,
          projectId,
          parentPhaseHasActivitiesPhase,
          memberBudget: memberBudgets[phaseMembership.member_budget_id],
          openBudgetModal
        };

        acc.push(phaseMembershipListItem);
        return acc;
      },
      []
    );

    // Add prompt text to add phase member by clicking + icon
    if (isParentOpen) {
      list.unshift({
        id: `add-member-to-phase-${phaseId}`,
        itemType: LIST_ITEM_TYPES.AddMemberToPhase,
        isOpen: true,
        parentId: undefined,
        toggleId: undefined,
        toggleCollapse: undefined,
        isParentOpen
      });
    }

    return list;
  };

  return projectTeamListItemsBuilder;
};

export default useProjectTeamListItemsBuilder;
