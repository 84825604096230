import cn from 'classnames';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import { ReactNode, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'theme';
import { ValueOf } from 'type-fest';

export const MOUSE_EVENTS = {
  CLICK: 'click',
  HOVER: 'hover'
} as const;

const MultilevelDropdownContainerBase = css`
  .multilevel-dropdown {
    list-style: none;
    margin-left: 0;
  }
  .multilevel-dropdown > .menu-item,
  .multilevel-dropdown > .menu-item > .menu-label {
    display: flex;
    align-items: center;
  }
  .expand > .menu-item {
    display: list-item;
  }

  .nested,
  .nested-expand {
    position: relative;

    /* Make pseudo-element :before for hover area so that dropdown doesn't close when going out of flyout menu
    This is useful when design requires dropdown menus to have spaces in between */
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
    }
  }
  .nested-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    overflow: hidden;
  }
`;

const StyledMenuItem = styled.li<{ $isNotApplyAnyStyling?: boolean }>`
  ${(props) => {
    if (props.$isNotApplyAnyStyling) {
      return '';
    }

    return `
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding: 8px 16px;
      color: ${theme.colors.colorMediumGray9};
      cursor: pointer;
      display: flex;
      align-items: center;
      white-space: nowrap;
      &:hover:not(.disabled) {
        background: ${theme.colors.colorTranslucentGray4};
      }
      &.disabled {
        cursor: not-allowed;
        color: ${theme.colors.colorLightGray15};
      }
  `;
  }}
`;

const StyledLabel = styled.div``;

const StyledFlyoutMenu = styled.ul<{ noBoxShadow?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: ${theme.colors.colorPureWhite};
  border-radius: 3px;
  box-shadow: ${({ noBoxShadow }) =>
    noBoxShadow ? 'none' : '0px 0px 4px rgb(0 0 0 / 25%)'};
`;

interface MultilevelDropdownContainerProps {
  customStyle?: string;
}

const MultilevelDropdownContainerOnClick = styled.div<MultilevelDropdownContainerProps>`
  ${MultilevelDropdownContainerBase}

  .nested > .nested-dropdown {
    left: calc(100% + 4px);
  }
  .nested-expand > .nested-dropdown {
    top: 0;
    left: calc(100% + 4px);
  }
  .nested > .nested-dropdown,
  .nested-expand > .nested-dropdown {
    height: auto;
    overflow: visible;
  }

  ${({ customStyle }) => customStyle || ''}
`;

const MultilevelDropdownContainerOnHover = styled.div<MultilevelDropdownContainerProps>`
  ${MultilevelDropdownContainerBase}

  .nested:before:hover > .nested-dropdown {
    top: 50%;
    left: 95%;
  }
  .nested-expand:before:hover > .nested-dropdown {
    top: 50%;
    left: 95%;
  }
  .nested:hover > .nested-dropdown,
  .nested-expand:hover > .nested-dropdown {
    height: auto;
    overflow: visible;
  }

  ${({ customStyle }) => customStyle || ''}
`;

type NestedFlyoutMenuProps = Omit<MultilevelDropdownProps, 'menuContent'> & {
  menuContent: MultilevelDropdownProps['menuContent'] | undefined;
};

const NestedFlyoutMenu = ({
  menuContent,
  onClickHash,
  openMethod
}: NestedFlyoutMenuProps) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);

  const [openIndex, setOpenIndex] = useState<Record<number, boolean>>({});

  const toggleMenu = (index: number) =>
    setOpenIndex((prev) => ({ [index]: !prev[index] }));

  const handleClickOpen =
    openMethod === MOUSE_EVENTS.CLICK ? toggleMenu : undefined;

  const renderNestedMenu = menuContent?.items?.map((menu, index) => {
    const handleClick = () => handleClickOpen?.(index);
    const isSubMenuOpen =
      openMethod === MOUSE_EVENTS.CLICK ? openIndex[index] : true;

    return (
      <StyledMenuItem
        key={menu.name}
        className={cn('menu-item', {
          'nested-expand': menu.subMenu,
          disabled: menu.isDisabled
        })}
        onClick={
          !menu.isDisabled
            ? menu.subMenu
              ? handleClick
              : onClickHash[menu.name]
            : undefined
        }
        $isNotApplyAnyStyling={'renderItem' in menu}
        data-for="app-tooltip"
        data-effect="solid"
        data-tip={menu.menuItemTooltip}
      >
        {'renderItem' in menu ? (
          menu.renderItem()
        ) : (
          <StyledLabel className="menu-label">{menu.label}</StyledLabel>
        )}
        {menu.subMenu && isSubMenuOpen && (
          <NestedFlyoutMenu
            menuContent={menu.subMenu}
            onClickHash={onClickHash}
            openMethod={openMethod}
          />
        )}
      </StyledMenuItem>
    );
  });

  return (
    <StyledFlyoutMenu className="nested-dropdown multilevel-dropdown expand">
      {renderNestedMenu}
    </StyledFlyoutMenu>
  );
};

export type MenuItemFields = {
  name: string;
  subMenu?: MenuContent;
  isDisabled?: boolean;
  menuItemTooltip?: string;
} & ({ label: ReactNode } | { renderItem: () => ReactNode });

export interface MenuContent {
  header?: ReactNode;
  items: MenuItemFields[];
}

export interface MultilevelDropdownProps {
  menuContent: MenuContent;
  onClickHash: Record<string, () => void>;
  openMethod?: ValueOf<typeof MOUSE_EVENTS>;
  containerCustomStyle?: string;
}

const MultilevelDropdown = ({
  menuContent,
  onClickHash,
  openMethod = MOUSE_EVENTS.HOVER,
  containerCustomStyle
}: MultilevelDropdownProps) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);
  const [openIndex, setOpenIndex] = useState<Record<number, boolean>>({});

  const toggleMenu = (index: number) =>
    setOpenIndex((prev) => ({ [index]: !prev[index] }));

  const handleClickOpen =
    openMethod === MOUSE_EVENTS.CLICK ? toggleMenu : undefined;

  const isOnHoverOpen = openMethod === MOUSE_EVENTS.HOVER;

  const MultilevelDropdownContainer = isOnHoverOpen
    ? MultilevelDropdownContainerOnHover
    : MultilevelDropdownContainerOnClick;

  const renderFirstLevelHeader = menuContent.header;

  const renderFirstLevelItems = menuContent.items.map((menu, index) => {
    const handleClick = () => handleClickOpen?.(index);
    const isSubMenuOpen =
      openMethod === MOUSE_EVENTS.CLICK ? openIndex[index] : true;

    return (
      <StyledMenuItem
        key={menu.name}
        className={cn('menu-item', {
          nested: menu.subMenu,
          disabled: menu.isDisabled
        })}
        onClick={
          !menu.isDisabled
            ? menu.subMenu
              ? handleClick
              : onClickHash[menu.name]
            : undefined
        }
        $isNotApplyAnyStyling={'renderItem' in menu}
        data-for="app-tooltip"
        data-effect="solid"
        data-tip={menu.menuItemTooltip}
      >
        {'renderItem' in menu ? (
          menu.renderItem()
        ) : (
          <StyledLabel className="menu-label">{menu.label}</StyledLabel>
        )}
        {menu.subMenu && isSubMenuOpen && (
          <NestedFlyoutMenu
            menuContent={menu.subMenu}
            onClickHash={onClickHash}
            openMethod={openMethod}
          />
        )}
      </StyledMenuItem>
    );
  });
  return (
    <MultilevelDropdownContainer customStyle={containerCustomStyle}>
      <StyledFlyoutMenu noBoxShadow={true} className="multilevel-dropdown">
        {renderFirstLevelHeader}
        {renderFirstLevelItems}
      </StyledFlyoutMenu>
    </MultilevelDropdownContainer>
  );
};

export default MultilevelDropdown;
