import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { useAppSelector } from 'reduxInfra/hooks';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';
import { BudgetSettingsTabs } from './BudgetSettingsTabs';
import { Header } from 'SettingsModule/components/styles';
import { SettingsTab } from './Settings/SettingsTab';
import { RatesTab } from './Rates/RatesTab';
import { budgetSettingsModuleConstants } from './constants';

const { BudgetSettingsViewTypes } = budgetSettingsModuleConstants;

const settingViewTypeToComponentHash = {
  [BudgetSettingsViewTypes.settings]: SettingsTab,
  [BudgetSettingsViewTypes.rates]: RatesTab
} as const;

const dynamicModules = [getBudgetModule()];

const BudgetSettingsTab = ({
  viewType
}: {
  viewType: keyof typeof BudgetSettingsViewTypes;
}) => {
  const { teamSlug } = useAppSelector(getMatchedRouteParams);
  const BodyComponent = settingViewTypeToComponentHash[viewType];

  return (
    <DynamicModuleLoader modules={dynamicModules}>
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: 0 }}>
        <Header>Budgets</Header>
        <BudgetSettingsTabs settingsViewType={viewType} teamSlug={teamSlug} />
        {BodyComponent && <BodyComponent />}
      </div>
    </DynamicModuleLoader>
  );
};

export default BudgetSettingsTab;
