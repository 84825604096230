import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  getSelectedTeamId,
  getModalActivityPhaseHasTimeEntries,
  getIsEditActivityPhaseModalOpen
} from 'selectors';

import { closeActivityPhaseModal, checkHasTimeEntries } from 'actionCreators';
import ActivityPhaseModalBody from './ActivityPhaseModalBody';
import GlobalAddEditHeader from '../PhaseTemplateModal/GlobalAddEditHeader';
import ActivityPhaseFeeModal from 'BudgetModule/components/BudgetTable/ActivityPhaseFeeModal/ActivityPhaseFeeModal';

class ActivityPhaseModal extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      open,
      checkHasTimeEntries,
      phaseId,
      activityId,
      teamId,
      isEditActivityPhaseModalOpen
    } = this.props;
    if (open && !prevProps.open && !isEditActivityPhaseModalOpen) {
      checkHasTimeEntries({
        activity_id: activityId,
        phase_id: phaseId,
        team_id: teamId,
        permissions: {
          mine: false
        }
      });
    }
  }

  handleClose = () => {
    const { closeActivityPhaseModal } = this.props;
    closeActivityPhaseModal({});
  };

  renderCopy = () => {
    const { hasTimeEntries } = this.props;
    return hasTimeEntries
      ? 'Delete Work Category'
      : 'Remove Work Category From Budget ';
  };

  render() {
    const {
      open,
      id,
      onDeleteSuccess,
      isEditActivityPhaseModalOpen,
      phaseId,
      activityId
    } = this.props;
    if (isEditActivityPhaseModalOpen) {
      return (
        <ActivityPhaseFeeModal
          phaseId={phaseId}
          activityPhaseId={activityId}
          handleClose={this.handleClose}
        />
      );
    }
    return (
      <Modal isOpen={open} toggle={this.handleClose} className="phase-modal">
        <GlobalAddEditHeader
          handleClose={this.handleClose}
          copy="Activity"
          id={id}
          renderCopy={this.renderCopy}
        />
        <ActivityPhaseModalBody onDeleteSuccess={onDeleteSuccess} />
      </Modal>
    );
  }
}

ActivityPhaseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeActivityPhaseModal: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const hasTimeEntries = getModalActivityPhaseHasTimeEntries(state);
  return {
    open: state.phases.activityPhaseModalOpen,
    phaseId: state.phases.activityPhaseModalPhaseId,
    activityId: state.phases.activityPhaseModalActivityId,
    teamId: getSelectedTeamId(state),
    // If has time entries OR workplans, prevent deletion
    hasTimeEntries:
      hasTimeEntries?.has_associated_time_entries ||
      hasTimeEntries?.has_associated_activity_phase_schedule_bars,
    isEditActivityPhaseModalOpen: getIsEditActivityPhaseModalOpen(state)
  };
};

const mapDispatchToProps = {
  closeActivityPhaseModal,
  checkHasTimeEntries
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPhaseModal);
