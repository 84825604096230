import { callApi } from 'appUtils/api';
import { convertKeysToSnakeCase } from 'appUtils/formatUtils';
import {
  UpdateTeamMembershipParams,
  ArchiveTeamMembersParams,
  UnarchiveTeamMembersParams,
  HardDeleteTeamMembersParams
} from './types';

const updateTeamMembership = (
  token: string,
  {
    teamMembershipId,
    email,
    firstName,
    lastName,
    referenceNumber,
    employmentType,
    teamRole,
    timesheetPreferences
  }: UpdateTeamMembershipParams
) => {
  const body = convertKeysToSnakeCase({
    email,
    firstName,
    lastName,
    employeeNumber: referenceNumber,
    employmentType,
    teamRole,
    timesheetPreferences
  });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`team_memberships/${teamMembershipId}`, token, fetchInit);
};

const archiveTeamMembers = (
  token: string,
  { teamId, teamMembershipIds }: ArchiveTeamMembersParams
) => {
  const body = convertKeysToSnakeCase({ teamId, teamMembershipIds });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('team_memberships/bulk_discard', token, fetchInit);
};

const unarchiveTeamMembers = (
  token: string,
  { teamId, teamMembershipIds }: UnarchiveTeamMembersParams
) => {
  const body = convertKeysToSnakeCase({
    teamId,
    teamMembershipIds,
    undiscard: true
  });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('team_memberships/bulk_discard', token, fetchInit);
};

const hardDeleteTeamMembers = (
  token: string,
  { teamId, teamMembershipIds }: HardDeleteTeamMembersParams
) => {
  const body = convertKeysToSnakeCase({ teamId, teamMembershipIds });

  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('team_memberships/bulk_destroy', token, fetchInit);
};

export const teamsApi = {
  updateTeamMembership,
  archiveTeamMembers,
  unarchiveTeamMembers,
  hardDeleteTeamMembers
};
