import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import { SuggestionState } from 'SuggestionModule/types';
import { initialState } from 'SuggestionModule/reducers/suggestion';

export const getSuggestionState: Selector<RootState, SuggestionState> = (
  state
) => state?.suggestion || initialState;

export const getSuggestedAccountsForProjectsState = createSelector(
  getSuggestionState,
  (state) => state.suggestedAccountsForProjects
);

export const getSuggestedAccountsForPhasesState = createSelector(
  getSuggestionState,
  (state) => state.suggestedAccountsForPhases
);

export const getSuggestedPhasesForAccountsState = createSelector(
  getSuggestionState,
  (state) => state.suggestedPhasesForAccounts
);
