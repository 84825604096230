import {
  ActivityPhase,
  isActivityPhase
} from 'ProjectsModule/phases/models/phase';
import { PhaseWithDefault } from 'ProjectsModule/phases/selectors/types';

export const getIsPhaseLikeDefault = (
  phase: PhaseWithDefault | ActivityPhase | undefined
): boolean =>
  (phase && isActivityPhase(phase)
    ? phase?.is_default
    : phase?.is_default_phase || phase?.is_main_like_default) ?? false;
