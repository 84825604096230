import { createSelector, Selector } from '@reduxjs/toolkit';
import { ReducerName, RootState } from 'reduxInfra/shared';
import { initialState as projectsInitialState } from 'reducers/projects';
import { ProjectsState } from 'ProjectsModule/reducers/types';
import { Project, ProjectId } from 'ProjectsModule/models/project';
import { OwnProjectIdFields } from './types';

export const getProjectsState: Selector<RootState, ProjectsState> = (
  state: RootState
) => state[ReducerName.Projects] || projectsInitialState;

export const getProjectHash = createSelector(
  getProjectsState,
  (state): Record<ProjectId, Project> => state.projectHash
);

export const getOwnProjectId: Selector<
  RootState,
  number,
  [{ projectId: number }]
> = (_state, ownProps) => ownProps.projectId;

/**
 * Gets a project ID. The first successful method below provides the ID:
 * - A valid ID is set in the `projectId` property of the `ownProps` argument.
 * - A valid ID is returned by the `getter.projectIdGetter` property of the
 *   `ownProps` argument.
 */
export const getOwnProjectIdByPropOrGetter: Selector<
  RootState,
  number | undefined,
  [OwnProjectIdFields]
> = (state, ownProps) =>
  ownProps.projectId ?? ownProps.getters?.projectIdGetter(state, ownProps);

/**
 * Gets a project by ID. The first successful method below provides the ID:
 * - A valid ID is set in the `projectId` property of the `ownProps` argument.
 * - A valid ID is returned by the `getter.projectIdGetter` property of the
 *   `ownProps` argument.
 */
export const makeGetProjectById = () =>
  createSelector(
    getProjectHash,
    getOwnProjectIdByPropOrGetter,
    (projects, projectId): Project | undefined => projects[projectId ?? -1]
  );
