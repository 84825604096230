import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  editProject,
  closeEditProjectModal,
  fetchProjectTeam,
  createProject,
  closeAddProjectModal,
  deleteProject,
  toggleDeleteProjectModal,
  handleProjectItemState,
  clearSelectedProject,
  toggleInviteButton,
  toggleInviteForm,
  setMemberInputValue,
  useSearchState,
  saveSearchState,
  archiveUnarchiveProject,
  openAddEditGroupModal,
  navigateToBoard,
  navigateToHome,
  checkHasTimeEntries,
  fetchProjectById,
  setSelectedProject
} from 'actionCreators';
import { AddEditProjectModal } from '..';
import {
  getSelectedGroup,
  getMyPersonalBoardID,
  getMe,
  getAccountSlug,
  getAuthToken,
  getExistingClients,
  getLastTenProjectNumbers,
  getDefaultTeam,
  getProjectsState,
  getTeamName,
  getSelectedTeamId,
  getUserIsAdmin,
  getOnHomeView,
  getProjectHash,
  getGroups,
  getIsCreatingOrUpdatingProject,
  addModalProjectRedirectOnSuccess,
  addModalProjectSuccessCallback
} from 'selectors';
import {
  getDefaultTeam as getQBDefaultTeam,
  getIsQBAddProjectModalOpen,
  getQBAddProjectModalName,
  getQBAddProjectSubcustomerId
} from 'QuickbooksModule/selectors';
import { withRouter } from 'react-router-dom';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import { syncSubcustomersToProjects } from 'QuickbooksModule/actionCreators';
import {
  hasAssociatedTime,
  getAssociatedTimeMessage
} from 'appUtils/budgetUtils';
import { isStarredOrUnstarredBoard } from 'appUtils/boards';
import { MODAL_TYPE } from 'appConstants/addMemberForm';
import { archivePortfolioProject } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/portfolio';
/* EDIT PROJECT UTILITIES */
function findProject(props) {
  const { allProjects } = props;
  const projectId = props.projects.editingProjectID;
  const project = allProjects[projectId];
  return project;
}

/* --------------------- */

class AddEditProjectContainer extends React.Component {
  state = {
    title: '',
    description: '',
    client: '',
    number: '',
    billable: false,
    project: null,
    selectedBoard: null,
    isArchived: null,
    closing: false,
    budget_status: null,
    budget_sub_status: null
  };

  componentDidUpdate(prevProps) {
    // ADD

    if (
      this.props.projects.isAddModalOpen !== prevProps.projects.isAddModalOpen
    ) {
      if (this.props.isQBAddProjectOpen) {
        this.setState(this.QBImportDefaultState(this.props));
      } else {
        this.setState(this.defaultState());
      }
    }

    // EDIT
    const selectedProject = this.props.projects.selectedProject[0];
    if (
      this.props.projects.editingProjectID &&
      this.props.projects.editingProjectID !==
        prevProps.projects.editingProjectID
    ) {
      const foundProject = findProject(this.props);
      const project =
        this.props.projects.editingProjectID === selectedProject?.id
          ? selectedProject
          : foundProject;
      if (project) {
        this.setProjectState(project);
      }
      // Sets selected project and fetches project with project_membership details
      this.props.fetchProjectById(this.props.projects.editingProjectID);

      const permissions = this.getPermissions();

      if (permissions && this.canCheckTimeEntries(permissions)) {
        this.props.checkHasTimeEntries({
          team_id: this.props.teamId,
          project_ids: [this.props.projects.editingProjectID],
          permissions: { ...permissions, mine: false }
        });
      }
    }

    // Use selectedProject so we have access to project membership data
    if (
      selectedProject &&
      this.props.projects.editingProjectID === selectedProject?.id &&
      selectedProject.id !== prevProps.projects.selectedProject[0]?.id
    ) {
      const project = selectedProject;
      this.setProjectState(project);
    }
  }

  setProjectState = (project) => {
    this.setState({
      title: project.title,
      client: project.client || '',
      description: project.description || '',
      number: project.project_number ? project.project_number.toString() : '',
      isArchived: !!project.archived_at || !!project.is_archived,
      billable: project.billable,
      budget_status: project.budget_status,
      budget_sub_status: project.budget_sub_status,
      project
    });
  };

  canArchiveProject = () => {
    const { checkPermission, archivePortfolioProject } = this.props;

    const permissions = this.getPermissions();

    if (!permissions) return;

    const hasPermission = checkPermission(archivePortfolioProject, {
      permissions
    });
    return hasPermission;
  };

  canCheckTimeEntries = ({ teamId, projectId }) => {
    const { checkPermission, checkHasTimeEntries } = this.props;
    if (!teamId || !projectId) {
      return false;
    }
    const hasPermission = checkPermission(checkHasTimeEntries, {
      team_id: teamId,
      project_ids: [projectId],
      permissions: { teamId, projectId, mine: false }
    });
    return hasPermission;
  };

  QBImportDefaultState = (nextProps) => {
    const { qbDefaultBoardID, qbAddProjectName, boardList } = nextProps;
    const selectedBoard = boardList.find(
      (group) => group.id === qbDefaultBoardID
    );
    return {
      title: qbAddProjectName,
      description: '',
      client: '',
      number: '',
      billable: false,
      project: null,
      selectedBoard,
      isArchived: null
    };
  };

  defaultState = () => ({
    title: '',
    description: '',
    client: '',
    number: '',
    billable: false,
    project: null,
    selectedBoard: null,
    isArchived: null,
    budget_status: null,
    budget_sub_status: null
  });

  getPermissions = () => {
    const { project } = this.state;
    const { selectedTeamId } = this.props;
    if (!selectedTeamId) return;

    const permissions = {
      projectId: project?.id,
      boardId: this.getSelectedBoard()?.id,
      teamId: selectedTeamId
    };
    return permissions;
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClientSelect = (client) => this.setState({ client });

  changeSelectedBoard = (selectedBoard) => {
    this.setState({ selectedBoard });
  };

  projectHasTimeEntries = () =>
    this.props.hasTimeEntries?.find(
      (hasTimeEntry) =>
        hasTimeEntry.project_id === this.props.projects.editingProjectID &&
        hasAssociatedTime(hasTimeEntry)
    );

  isAnythingChanged = () => {
    const { project, title, description, client, number, billable } =
      this.state;
    // const { } = this.props;
    const selectedBoard = this.getSelectedBoard();
    if (
      title === project?.title &&
      description === project?.description &&
      client === project?.client &&
      number === project?.project_number &&
      billable === project?.billable &&
      selectedBoard?.id === project?.board_id
    )
      return false;

    return true;
  };

  formSubmitAdd = (event) => {
    const {
      createProject,
      isQBAddProjectOpen,
      syncSubcustomersToProjects,
      qbAddProjectSubcustomerId,
      closeAddProjectModal,
      redirectOnSuccess,
      onSuccessCallback
    } = this.props;
    const permissions = this.getPermissions();
    const {
      title,
      description,
      client,
      number,
      billable,
      budget_status,
      budget_sub_status
    } = this.state;
    const selectedBoard = this.getSelectedBoard();
    event.preventDefault();

    if (isQBAddProjectOpen) {
      const syncedSubcustomersToProjects = {
        subcustomer_id: qbAddProjectSubcustomerId,
        mosaic_board_id: selectedBoard && selectedBoard.id,
        mosaic_project_name: title,
        mosaic_project_description: description,
        mosaic_project_number: number,
        mosaic_project_client: client,
        mosaic_project_billable: billable,
        create_new_project: true,
        import_time_activities: true
      };

      syncSubcustomersToProjects({
        body: {
          records: [syncedSubcustomersToProjects]
        }
      });
      closeAddProjectModal();
    } else if (permissions) {
      // Important because in cases where the board is derived, but never explicitly selected, it is not in state.
      // This needs to be the case to ensure consistent behavior across add/edit and default selected/no default selected

      createProject({
        title,
        description,
        client,
        number,
        billable,
        projectGroupId: selectedBoard && selectedBoard.id,
        isActive: true,
        projectGroupIdToRefresh: selectedBoard && selectedBoard.id,
        memberList: false,
        permissions,
        budget_status,
        budget_sub_status,
        redirectOnSuccess,
        onSuccessCallback
      });
    }
  };

  formSubmitEdit = (event) => {
    const { editProject, projects } = this.props;
    const {
      title,
      description,
      client,
      number,
      billable,
      budget_status,
      budget_sub_status
    } = this.state;
    const project = findProject(this.props) || this.state.project;

    event.preventDefault();
    const permissions = this.getPermissions();
    if (!permissions) return;

    editProject({
      id: projects.editingProjectID,
      title,
      description,
      client,
      number,
      billable,
      projectGroupId: this.getSelectedBoard().id,
      isActive: !project.archived_at,
      projectGroupIdToRefresh: this.props.selectedBoard?.id,
      memberList: false,
      calledByFormSubmit: true,
      permissions,
      budget_status,
      budget_sub_status
    });
  };

  toggleDeleteModal = (event) => {
    this.props.toggleDeleteProjectModal(!this.props.isOpenDeleteProjectModal);
    if (event) {
      event.preventDefault();
    }
  };

  handleArchive = () => {
    const { archiveUnarchiveProject, token } = this.props;
    const { project, isArchived } = this.state;
    const permissions = this.getPermissions();

    if (!permissions) return;

    archiveUnarchiveProject({
      token,
      projectId: project.id,
      isActive: isArchived,
      position: project.position || 0, // if passed in position is undefined we are falling into an infinite render loop
      permissions
    });
  };

  deleteProjectOnClick = (event) => {
    const {
      accountSlug,
      clearSelectedProject,
      deleteProject,
      handleProjectItemState,
      projects,
      selectedBoard,
      team,
      navigateToHome,
      navigateToBoard,
      personalBoardId
    } = this.props;
    const editingProjectId = projects.editingProjectID;
    const selectedBoardId = selectedBoard?.id;
    const isActive = projects.isActive;
    const boardSlug = selectedBoard?.slug;
    const isPersonal =
      selectedBoard?.is_personal || selectedBoardId === personalBoardId;

    event.preventDefault();

    const payload = {
      projectId: editingProjectId,
      groupId: selectedBoardId,
      isActive,
      groupSlug: boardSlug,
      isPersonal
    };

    deleteProject(payload);
    handleProjectItemState(
      null,
      ['detailVisible', 'expanded', 'fixed'],
      [false, false, false]
    );
    clearSelectedProject();

    if (isPersonal) {
      navigateToHome({ userSlug: accountSlug });
    } else {
      navigateToBoard({
        teamSlug: team.slug,
        boardSlug,
        boardId: selectedBoardId
      });
    }
  };

  toggleWrapper = () => {
    const {
      projects,
      createProject,
      closeAddProjectModal,
      closeEditProjectModal
    } = this.props;
    const { title, description, client, number, selectedBoard, billable } =
      this.state;
    this.setState({ closing: true }, () => {
      setTimeout(() => this.setState({ closing: false }), 500);
    });
    if (projects.isAddModalOpen) {
      if (title && title !== '') {
        createProject({
          title,
          description,
          client,
          number,
          billable,
          projectGroupId: selectedBoard && selectedBoard.id,
          isActive: true,
          projectGroupIdToRefresh:
            this.props.selectedBoard && this.props.selectedBoard.id,
          memberList: false
        });
      } else {
        closeAddProjectModal();
      }
    } else {
      closeEditProjectModal();
    }
  };

  setSelectedBoard = (board) => this.setState({ selectedBoard: board });

  // getSelectedBoard resolves cases where a board must first be selected - and is in state,
  // and cases where a board should already be present
  getSelectedBoard = () => {
    const { projects, selectedBoard, groups, isOnHome, useSelectedBoard } =
      this.props;
    const { project } = this.state;
    if (this.state.selectedBoard) {
      return this.state.selectedBoard;
    }
    const projectBoard =
      project &&
      groups.groupList.find((board) => board.id === project.board_id);

    if (projects.isEditModalOpen || projects.isAddModalOpen) {
      if (projects.editModalProjectIsPersonal) {
        return this.generatePersonalBoard();
      }
      if (!isOnHome || useSelectedBoard) {
        return projectBoard || selectedBoard;
      }
    }
  };

  boardIsPersonal = (board) => board && board.id === this.props.personalBoardId;
  generatePersonalBoard = () => {
    // handles case where personalBoardId is not avaiable immediately
    const { personalBoardId } = this.props;
    if (!this.personalBoardMemo[personalBoardId]) {
      this.personalBoardMemo[personalBoardId] = {
        id: personalBoardId,
        name: 'Personal',
        is_private: false
      };
    }
    return this.personalBoardMemo[personalBoardId];
  };

  finishCloseModal = () => {
    const { projects } = this.props;
    // Prevents resetting the state if the user is opening up the DeleteModal
    if (!projects.editingProjectID) {
      this.resetFormState();
      this.setState({ closing: false });
    }
  };

  closeModal = () => {
    const { projects, closeAddProjectModal, closeEditProjectModal } =
      this.props;

    this.setState({ closing: true });
    if (projects.isAddModalOpen) {
      closeAddProjectModal();
    } else {
      closeEditProjectModal();
    }
  };

  resetFormState = () => this.setState(this.defaultState());

  renderDeleteModalBody = () => {
    const { loadingHasTimeEntries } = this.props;
    if (loadingHasTimeEntries) {
      return <div />;
    }
    const associatedTime = this.projectHasTimeEntries();
    if (associatedTime) {
      return (
        <>
          <b>{this.state.title}</b>{' '}
          {getAssociatedTimeMessage(associatedTime, MODAL_TYPE.PROJECT)}
        </>
      );
    }
    return (
      <>
        Are you sure you want to <b>permanently</b> delete{' '}
        <b>{this.state.title}</b>? You can’t restore a deleted Project.
      </>
    );
  };

  renderDeleteModalDelete = ({ deleteOnClick }) => {
    const projectHasTimeEntries = this.projectHasTimeEntries();
    return (
      <button
        onClick={projectHasTimeEntries ? this.toggleDeleteModal : deleteOnClick}
      >
        {projectHasTimeEntries ? 'Ok' : 'Yes'}
      </button>
    );
  };

  personalBoardMemo = {}; // prevent rerending from generating personal board object
  render() {
    const {
      projects,
      groups,
      disableSave,
      personalBoardId,
      isOpenDeleteProjectModal,
      jobNumbers,
      clients,
      openAddEditGroupModal,
      teamName,
      userIsAdmin,
      boardList,
      isCreatingOrUpdatingProject
    } = this.props;
    const selectedBoard = this.getSelectedBoard();
    const modalProjectIsPersonal = this.boardIsPersonal(selectedBoard);
    const canArchiveProject = this.canArchiveProject();
    return (
      <AddEditProjectModal
        project={this.state.project}
        title={this.state.title}
        description={this.state.description}
        client={this.state.client}
        number={this.state.number}
        billable={this.state.billable}
        isOpen={projects.isAddModalOpen || projects.isEditModalOpen}
        isArchived={this.state.isArchived}
        toggleModal={this.toggleWrapper}
        errorMessage={projects.statusText}
        groupList={boardList}
        handleChange={this.handleChange}
        isAnythingChanged={this.isAnythingChanged}
        handleSubmit={
          projects.editingProjectID ? this.formSubmitEdit : this.formSubmitAdd
        }
        finishCloseModal={this.finishCloseModal}
        closeAllModals={this.closeModal}
        closing={this.state.closing}
        group={isStarredOrUnstarredBoard(selectedBoard) ? null : selectedBoard}
        changeSelectedGroup={this.changeSelectedBoard}
        disableSave={disableSave}
        toggleDeleteModal={this.toggleDeleteModal}
        deleteModalIsOpen={isOpenDeleteProjectModal}
        deleteProjectOnClick={this.deleteProjectOnClick}
        isEditModal={!!projects.editingProjectID}
        jobNumbers={jobNumbers}
        clients={clients}
        handleClientSelect={this.handleClientSelect}
        handleArchive={this.handleArchive}
        modalProjectIsPersonal={modalProjectIsPersonal}
        personalBoardId={personalBoardId}
        setSelectedBoard={this.setSelectedBoard}
        openAddEditGroupModal={openAddEditGroupModal}
        teamName={teamName}
        userIsAdmin={userIsAdmin}
        renderDeleteModalBody={this.renderDeleteModalBody}
        renderDeleteModalDelete={this.renderDeleteModalDelete}
        isCreatingOrUpdatingProject={isCreatingOrUpdatingProject}
        canArchiveProject={canArchiveProject}
      />
    );
  }
}

AddEditProjectContainer.propTypes = {
  editProject: PropTypes.func.isRequired,
  createProject: PropTypes.func.isRequired,
  closeEditProjectModal: PropTypes.func.isRequired,
  closeAddProjectModal: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  projects: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  groups: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedBoard: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  isOpenDeleteProjectModal: PropTypes.bool.isRequired,
  toggleDeleteProjectModal: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  me: getMe(state),
  projects: getProjectsState(state),
  allProjects: getProjectHash(state),
  accountSlug: getAccountSlug(state),
  teamName: getTeamName(state),
  teamId: getSelectedTeamId(state),
  token: getAuthToken(state),
  groups: state.groups,
  boardList: getGroups(state),
  selectedBoard: getSelectedGroup(state) || state.groups.selectedBoard,
  disableSave: false,
  isOpenDeleteProjectModal: state.projects.isOpenDeleteProjectModal,
  useSelectedBoard: state.projects.addEditModalUseSelectedBoardOverride,
  team: getDefaultTeam(state),
  jobNumbers: getLastTenProjectNumbers(state),
  clients: getExistingClients(state),
  personalBoardId: getMyPersonalBoardID(state),
  selectedTeamId: getSelectedTeamId(state),
  qbDefaultBoardID: getQBDefaultTeam(state).mosaic_default_board_id,
  userIsAdmin: getUserIsAdmin(state),
  isQBAddProjectOpen: getIsQBAddProjectModalOpen(state),
  qbAddProjectName: getQBAddProjectModalName(state),
  qbAddProjectSubcustomerId: getQBAddProjectSubcustomerId(state),
  isOnHome: getOnHomeView(state),
  hasTimeEntries: state.timesheets.hasTimeEntries,
  loadingHasTimeEntries: state.timesheets.loadingHasTimeEntries,
  isCreatingOrUpdatingProject: getIsCreatingOrUpdatingProject(state),
  redirectOnSuccess: addModalProjectRedirectOnSuccess(state),
  onSuccessCallback: addModalProjectSuccessCallback(state)
});

const mapDispatchToProps = {
  closeEditProjectModal,
  editProject,
  fetchProjectTeam,
  closeAddProjectModal,
  createProject,
  toggleDeleteProjectModal,
  deleteProject,
  handleProjectItemState,
  clearSelectedProject,
  toggleInviteButton,
  toggleInviteForm,
  setMemberInputValue,
  useSearchState,
  saveSearchState,
  archiveUnarchiveProject,
  openAddEditGroupModal,
  navigateToHome,
  navigateToBoard,
  syncSubcustomersToProjects,
  checkHasTimeEntries,
  fetchProjectById,
  setSelectedProject,
  archivePortfolioProject
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  createProject: (args) => dispatchProps.createProject(stateProps.token, args)
});

export default withRouter(
  withPermissionsCheck(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps
    )(AddEditProjectContainer)
  )
);
