import styled from 'styled-components';
import { TaskWrapper } from 'views/Home/Tasks/styles';
import { StyledTableRow } from 'components/Table/styles';

// Task Layout
export const PickerWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  > div {
    > div {
      top: 66px;
      right: 20px;
      left: unset !important;
      width: fit-content !important;
    }
  }
`;

export const TaskDateSelectorContainer = styled.div`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  list-style: none;
  position: relative;
  display: inline-block;
  outline: none;
`;
export const ToggleIconWrapper = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;

  > svg {
    height: 12px;
    width: ${({ taskDateType }) =>
      taskDateType === 'due' || taskDateType === 'due_at' ? '11px' : '14px'};
  }

  > svg,
  g {
    fill: ${({ taskIsCreating, taskStatus, theme }) =>
      taskIsCreating
        ? theme.colors.colorRoyalBlue
        : theme.taskDateIconColor[taskStatus]};
    stroke: ${({ taskIsCreating, taskStatus, theme }) =>
      taskIsCreating
        ? theme.colors.colorRoyalBlue
        : theme.taskDateIconColor[taskStatus]};
  }
`;

export const TaskCalendarToggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-top: 0;
  justify-content: center;
  min-width: 71px;
  height: calc(100%);
  width: 100%;
  line-height: 1.64;
  text-align: center;
  font-size: 12px;
  text-align: center;
  font-weight: ${({ taskStatus, theme }) =>
    theme.taskDateFontWeight[taskStatus]};
  visibility: ${({
    selectedDate,
    taskIsCreating,
    alwaysRenderInput,
    currentFilter = {},
    taskIsEditing,
    taskDateType,
    taskEditProperty,
    projectDetailView,
    isRange
  }) => {
    if (
      currentFilter.subSection === 'scheduled' &&
      !isRange &&
      !alwaysRenderInput
    ) {
      return 'hidden';
    }
    if (taskDateType === taskEditProperty && taskIsEditing) {
      return 'visible';
    }
    return selectedDate || taskIsCreating || alwaysRenderInput
      ? 'visible'
      : 'hidden';
  }};

  opacity: ${({ selectedDate }) => (selectedDate ? '1' : '0.6')};
  color: ${({ taskIsCreating, taskStatus, theme, selectedDate }) =>
    taskIsCreating
      ? theme.colors.colorRoyalBlue
      : theme.taskDateFontColor[taskStatus]};
  margin-right: ${({ bulk }) => (bulk ? '20px' : '0')};
  text-transform: ${({ selectedDate }) =>
    selectedDate ? 'capitalize' : 'uppercase'};

  ${ToggleIconWrapper} {
    height: 11px;
    display: ${({ selectedDate }) => (selectedDate ? 'flex' : 'none')};
  }
  &:hover {
    visibility: visible;
    color: #0074d9;
    opacity: 1;

    svg {
      fill: ${({ hasDependency }) => (hasDependency ? 'none' : '#0074d9')};
      stroke: ${({ hasDependency }) => (hasDependency ? '#0074d9' : 'none')};
    }
  }

  ${TaskWrapper}:hover &, ${StyledTableRow}:hover & {
    visibility: visible;
  }

  ${PickerWrapper}:hover & {
    visibility: visible;
  }
`;
export const ToggleTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 13px;
  font-size: ${({ isToday }) => (isToday ? '13px' : '12px')};
  font-weight: ${({ isToday }) => (isToday ? '600' : '400')};
  font-family: 'proxima-nova';
  color: ${({ theme, bulk }) =>
    bulk ? theme.colors.colorRoyalBlue : 'inherit'};
  min-width: 87px;
`;
