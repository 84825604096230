import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getBudgetModule } from 'BudgetModule/package/budgetModule';
import { getCapacitiesModule } from 'CapacityModule/package/capacityModule';
import { getUtilizationModule } from 'UtilizationModule/package/utilizationModule';
import { getSuggestionModule } from 'SuggestionModule/package/module';
import moment from 'moment';

import Timesheet from 'views/timesheet/Timesheet';
import { BodyColor } from '..';
import GlobalPhaseTemplateDropdown from 'BudgetModule/components/GlobalPhaseTemplateDropdown/GlobalPhaseTemplateDropdown';
import GlobalActivityDropdown from 'views/projectPlanner/plannerModal/ActivityRowDropdown/GlobalActivityDropdown';
import ActivityModal from '../projectPlanner/ActivityModal';
import ActivityModalForDay from 'views/timesheet/Timesheet/ActivityModalForDay';

import PhaseTemplateModal from 'BudgetModule/components/PhaseTemplateModal/PhaseTemplateModal';
import PhaseModal from 'BudgetModule/components/PhaseModal/PhaseModal';
import { fetchCapacities } from 'CapacityModule/actionCreators';
import {
  GENERIC_ACTION,
  BUDGET_RECORD_DATA_TYPES as DATA_TYPES
} from 'appConstants';
import { BudgetBarTooltip } from 'BudgetModule/components/PhaseAndProjectBudgetBar';
import LegendStyleTooltip from 'components/Tooltips/LegendStyleTooltip';

import {
  fetchMemberProjects,
  openProjectPlannerModal,
  closeProjectPlannerModal,
  fetchActivities,
  fetchDescriptions,
  fetchTimesheets,
  flushTimesheets,
  flushScheduleBars,
  flushDescriptions,
  flushUserActivities,
  fetchPhaseTemplates,
  fetchPhasesByProjectIds,
  handleErrorMessage,
  setShowTimesheetMovedMessage,
  fetchCheckins,
  fetchTimers,
  fetchWorkloadPlanner,
  fetchCheckinsHours,
  fetchTimesheetsPredictions
} from 'actionCreators';
import { fetchPhaseSuggestionsForTimesheetAccounts } from 'SuggestionModule/actionCreators';
import {
  getTimelineMemberId,
  isOnTeamMemberProfile,
  getCurrentUserId,
  getMatchedRouteParams,
  getIsOnProjectView,
  getOnProfile,
  getSelectedTeamId,
  isOnTeamMembersTimesheet,
  getPlannerModalAccountId,
  getIsOnOwnTimesheets,
  getIsTimesheetsDisabled,
  getShowTimesheetMovedMessage,
  getDisplayedDescriptionsProjectIds,
  getSplitFlags
} from 'selectors';
import TimesheetSettingsModal from './Timesheet/TimesheetSettingsModal/TimesheetSettingsModal';
import TimesheetIncompleteModal from './Timesheet/TimesheetIncompleteModal';
import { Wrapper } from './Timesheet/styles';
import {
  fetchMembersUtilizationReport,
  fetchUtilizationsReport
} from 'UtilizationModule/actionCreators';
import { fetchBudgetRecords } from 'BudgetModule/actionCreators';

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');
const dateRange = 14;
class TimesheetContainer extends React.Component {
  state = {
    addMemberDropdownOpen: false,
    earliestDate: moment().add(-dateRange, 'days'),
    latestDate: moment().add(dateRange, 'days')
  };

  componentDidMount() {
    const {
      selectedAccountId,
      isTimesheetsDisabled,
      showTimesheetMovedMessage
    } = this.props;

    if (isTimesheetsDisabled) {
      this.handleDisabledTimesheets();
    }
    if (selectedAccountId) {
      this.flushTimesheets();

      this.fetchData(true);
    }
    if (showTimesheetMovedMessage) {
      this.props.handleErrorMessage({
        type: GENERIC_ACTION,
        errorMessage:
          'Timesheets have moved to "Time" on the left menu of the Home Screen.'
      });
      this.props.setShowTimesheetMovedMessage({ showMessage: false });
    }
  }

  flushTimesheets = () => {
    const { flushTimesheets } = this.props;

    flushTimesheets();
  };

  componentWillUnmount() {
    const { closeProjectPlannerModal } = this.props;
    closeProjectPlannerModal();
    this.flushTimesheets();
  }

  handleDisabledTimesheets = () => {
    const { handleErrorMessage } = this.props;

    handleErrorMessage({
      type: 'TIMESHEETS_DISABLED',
      errorMessage: 'Timesheets are off and can only be used for time tracking.'
    });
  };

  componentDidUpdate(prevProps) {
    const {
      visibleTimeStart,
      selectedAccountId,
      isTimesheetsDisabled,
      displayedDescriptionsProjectIds,
      fetchBudgetRecords,
      my_id
    } = this.props;
    const isNewTeam =
      !prevProps.teamId && this.props.teamId !== prevProps.teamId;
    const changedTimelineMember =
      prevProps.selectedAccountId !== selectedAccountId;
    if (isTimesheetsDisabled && !prevProps.isTimesheetsDisabled) {
      this.handleDisabledTimesheets();
    }
    if (isNewTeam || changedTimelineMember) {
      fetchBudgetRecords({
        params: {
          team_id: this.props.teamId,
          account_ids: [my_id],
          data_type: DATA_TYPES.ACCOUNT_PHASE_ACTIVITY
        },
        initial: true
      });
    }
    if (
      isNewTeam ||
      changedTimelineMember ||
      prevProps.visibleTimeStart.valueOf() !== visibleTimeStart.valueOf()
    ) {
      // handle flushing and fetching when changing members on timesheets
      this.fetchData(isNewTeam);
    }
  }

  fetchData = (isNewTeam) => {
    const {
      visibleTimeStart,
      selectedAccountId,
      fetchActivities,
      fetchDescriptions,
      fetchTimesheets,
      teamId,
      fetchPhaseTemplates,
      fetchCapacities,
      fetchMembersUtilizationReport,
      fetchUtilizationsReport,
      fetchWorkloadPlanner,
      fetchTimers,
      fetchCheckins,
      my_id,
      fetchCheckinsHours,
      fetchTimesheetsPredictions,
      fetchPhaseSuggestionsForTimesheetAccounts,
      flags
    } = this.props;

    const { homeTimesheetSuggestionFlag } = flags;

    const baseDate = visibleTimeStart || moment();
    const startDate = formatFetchDate(baseDate.clone().add(-dateRange, 'days'));
    const endDate = formatFetchDate(baseDate.clone().add(dateRange, 'days'));

    if (teamId) {
      fetchCapacities({ accountIds: [selectedAccountId], teamId });
      fetchMembersUtilizationReport({
        team_id: teamId,
        account_ids: [my_id],
        show_status_totals: true,
        show_pto: true,
        show_holidays: true,
        interval_type: 'days',
        interval_amount: 1,
        start_date: startDate,
        end_date: endDate
      });
      // For placeholder values
      fetchCheckins({
        body: {
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          account_ids: [my_id]
        },
        filterStateId: 'timesheet'
      });
      fetchTimers({
        body: {
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          account_id: my_id
        }
      });
      fetchWorkloadPlanner({
        startDate,
        endDate,
        accountId: my_id,
        team_member_ids: [my_id],
        all: true
      });
      // Use to get suggested projects (show lightbulb)
      fetchCheckinsHours({
        body: {
          team_id: teamId,
          account_ids: [my_id],
          start_date: moment().add(-7, 'days').format('MM/DD/YYYY'),
          end_date: moment().format('MM/DD/YYYY')
        },
        initial: true
      });
      // Fetch suggested phases for the current user
      if (homeTimesheetSuggestionFlag) {
        fetchPhaseSuggestionsForTimesheetAccounts({
          account_ids: [my_id],
          start_date: baseDate.clone().format('YYYY-MM-DD'),
          end_date: baseDate.clone().add('6', 'days').format('YYYY-MM-DD')
        });
      }
    }

    // const activityScoresStartDate = formatFetchDate(
    //   moment().add(-activityRange, 'days')
    // );
    // const activityScoresEndDate = formatFetchDate(
    //   moment().add(activityRange, 'days')
    // );
    if (isNewTeam && teamId) {
      fetchPhaseTemplates({ teamId });
      fetchActivities({
        planned: false
      });
    }
    fetchTimesheets({
      startDate,
      endDate,
      accountId: selectedAccountId,
      planned: false
    });
    const onSuccess = [
      {
        successAction: (data) => {
          const { phaseIds } = data;
          fetchTimesheetsPredictions({
            accountId: selectedAccountId,
            startDate: moment(baseDate).startOf('week').format('YYYY-MM-DD'),
            endDate: moment(baseDate).endOf('week').format('YYYY-MM-DD'),
            phaseIds
          });
        },
        selector: (payload, response) => {
          return {
            startDate: payload.predictionStartDate,
            endDate: payload.predictionEndDate,
            phaseIds: response.descriptions
              ? Array.from(
                  new Set(
                    response.descriptions.map(
                      (description) => description.phase_id
                    )
                  )
                )
              : []
          };
        }
      }
    ];
    fetchDescriptions({
      startDate,
      endDate,
      accountId: selectedAccountId,
      planned: false,
      onSuccess: onSuccess
    });
  };

  render() {
    const { teamId, isOnOwnTimesheet, isOnProjectView } = this.props;
    return (
      <Wrapper
        isOnProjectView={isOnProjectView}
        maxWidth={isOnOwnTimesheet ? 'unset' : 'calc(100vw - 280px)'}
        className="timesheets-container"
      >
        <DynamicModuleLoader
          modules={[
            getBudgetModule(),
            getCapacitiesModule(),
            getUtilizationModule(),
            getSuggestionModule()
          ]}
        >
          {teamId && (
            <>
              <BodyColor isGray />
              <Timesheet />
            </>
          )}
          <GlobalPhaseTemplateDropdown />
          <GlobalActivityDropdown />
          <PhaseTemplateModal />
          <PhaseModal />
          <ActivityModal />
          <ActivityModalForDay />
          <TimesheetSettingsModal />
          <TimesheetIncompleteModal />
          <BudgetBarTooltip />
          <LegendStyleTooltip className="timesheetstatus-tooltip" />
        </DynamicModuleLoader>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const matchedParams = getMatchedRouteParams(state);
  const isOnProjectView = getIsOnProjectView(state);
  return {
    my_id: getCurrentUserId(state),
    selectedAccountId: getPlannerModalAccountId(state),
    timelineMemberId: getTimelineMemberId(state, ownProps),
    visibleTimeStart: state.projectPlannerModal.visibleTimeStart,
    isPlanner: !isOnTeamMemberProfile(state, ownProps),
    teamId: getSelectedTeamId(state),
    matchedParams,
    isOnProjectView,
    isOnTeamMemberProfile: getOnProfile(state),
    isOnTimesheet: isOnTeamMembersTimesheet(state),
    isOnOwnTimesheet: getIsOnOwnTimesheets(state),
    isTimesheetsDisabled: getIsTimesheetsDisabled(state),
    showTimesheetMovedMessage: getShowTimesheetMovedMessage(state),
    displayedDescriptionsProjectIds: getDisplayedDescriptionsProjectIds(state),
    flags: getSplitFlags(state)
  };
};

const mapDispatchToProps = {
  fetchMemberProjects,
  openProjectPlannerModal,
  closeProjectPlannerModal,
  fetchActivities,
  fetchDescriptions,
  fetchTimesheets,
  fetchPhaseTemplates,
  fetchPhasesByProjectIds,
  flushTimesheets,
  flushScheduleBars,
  flushDescriptions,
  flushUserActivities,
  fetchCapacities,
  handleErrorMessage,
  setShowTimesheetMovedMessage,
  fetchMembersUtilizationReport,
  fetchUtilizationsReport,
  fetchWorkloadPlanner,
  fetchTimers,
  fetchCheckins,
  fetchBudgetRecords,
  fetchCheckinsHours,
  fetchTimesheetsPredictions,
  fetchPhaseSuggestionsForTimesheetAccounts
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimesheetContainer)
);
