import produce from 'immer';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import partition from 'lodash/partition';
import concat from 'lodash/concat';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import * as constants from 'appConstants';
import * as taskRemoveTypes from 'appConstants/taskRemoveTypes';
import { VIEW_BY } from 'appConstants/workload';
import moment from 'moment';
import { isTodayOn } from 'appUtils/momentUtils';
import { isOverdue, isPastDueDate, keyifyDate } from 'appUtils/plannerUtils';
import { makeIdHash } from 'appUtils';
const emptyArray = [];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const { MOVE_WITH_EXISTING, COPY_WITH_EXISTING } =
  constants.BATCH_ACTION_TASK_GROUP_HEADER_CONSTANTS;

// Add fields if necessary
export const initialFilterState = {
  /**
   *  isFetchingCounts: {
   *    today: true,
   *    overdue: false
   *    // anything that is undefined is not fetching
   *  }
   */
  isFetchingCounts: {},

  /**
   *  taskCounts: {
   *    today: 12,
   *    overdue: 2
   *  }
   */
  memberModalTaskCounts: {}
};

const initialState = {
  activeTaskFilter: {},
  allTasks: [],
  pastDueTasks: [],
  pastScheduledTasks: [],
  pastDueCount: null,
  pastScheduledCount: null,
  scheduledForToday: null,
  taskHash: {},
  projectMembershipHash: {},
  dependencyTaskHash: {},
  timerTaskHash: {},
  editedTasks: {},
  batchSelectedTaskIds: {},
  batchSelectedGroupIds: {}, // whatever tasks are grouped by, not necessarily task groups
  isFetchingTasks: 0,
  isFetchingPastDue: false,
  isFetchingPastScheduled: false,
  isLazyLoading: false,
  totalPages: 0,
  currentPage: 0,
  limit: 25,
  currentFilter: {
    scope: 'default',
    state: 'incomplete', // incomplete is default, default is all tasks
    section: 'My Tasks',
    subSection: 'inbox',
    viewType: ''
  },
  memberModalTaskFilter: {
    state: 'incomplete'
  },
  memberModalTaskCounts: {
    completed: 0,
    unscheduled: 0,
    scheduled: 0,
    overdue: 0,
    today: 0
  },
  selectedTask: null,
  sort: null,
  viewBy: VIEW_BY.TASK_GROUPS,
  selectedAccountIds: [],
  isCreatingTask: false,
  flaggedTasksModalOpen: false,
  pastScheduledModalOpen: false,
  taskEditProperty: null,
  confirmCompleteModalTaskId: null,
  confirmCompleteModalIsOpen: false,
  tempId: null,
  tempIds: {},
  createdTasksToTempIds: {},
  createRowData: {},
  offset: 0,
  taskCount: null,
  taskGroupCounts: {},
  taskRemovals: {},
  filterStates: {},
  fetchedProjectIds: {}
};

const createTaskTemplate = () => ({
  id: null,
  description: null,
  project_id: null,
  assignee_id: null,
  due_at: null,
  completed_at: null,
  created_at: null,
  updated_at: null,
  note: null,
  account_id: null,
  schedule_start: null,
  schedule_end: null,
  is_archived: false,
  is_completed: false,
  current_assignment_status: 'accepted',
  estimated_hours: null,
  status: 'not_started',
  phase_id: null
});

const mapTaskAssignIds = (task) => ({
  ...task,
  assignees: undefined,
  assignee_id: task.assignees?.[0]?.id || task.assignee_ids?.[0],
  assignee_ids:
    task.assignees?.map((assignee) => assignee?.id) || task.assignee_ids || [],
  assigners: undefined,
  assigner_id: task.assigners?.[0]?.id || task.assigner_ids?.[0],
  assigner_ids:
    task.assigners?.map((assigner) => assigner?.id) || task.assigner_ids || [],
  dependencies: task.dependable_entity_dependencies || task.dependencies || [],
  assignee_project_membership_information:
    task.assignee_project_membership_information || []
});
const mapCommentIds = (task) => ({
  ...task,
  task_comment_ids: task.task_comments?.map((comment) => comment.id)
});

const homeTasks = (state = initialState, action) =>
  produce(state, (draft) => {
    const isTodayView = state.currentFilter.scope === 'today';
    switch (action.type) {
      case constants.LOGOUT_USER: {
        return initialState;
      }
      case constants.TOGGLE_TASK_CREATION: {
        draft.isCreatingTask = !draft.isCreatingTask;
        break;
      }
      case constants.CLEAR_REMOVED_TASKS: {
        draft.allTasks = draft.allTasks.filter((id) => !draft.taskRemovals[id]);
        draft.taskRemovals = {};
        break;
      }
      case constants.UPDATE_EDITED_HOME_TASKS: {
        action.payload.forEach(
          (task) =>
            (draft.editedTasks[task.id] = {
              ...draft.editedTasks[task.id],
              ...task
            })
        );
        break;
      }

      case constants.BULK_EDIT_HOME_TASKS.TRIGGER: {
        draft.flaggedTasksModalOpen = false;
        draft.pastScheduledModalOpen = false;
        break;
      }
      case constants.BULK_EDIT_HOME_TASKS.SUCCESS: {
        const responseTasks = action.payload.response.tasks;
        /* response.project_tasks is the json expected by HomePlanner
        - this is necessary for now in order to keep task shape in line with
        the tasks initially fetched in this view.
        */
        const tasks = responseTasks.map(mapTaskAssignIds);

        tasks.forEach((task) => {
          draft.taskHash[task.id] = task;
        });

        draft.pastScheduledTasks = draft.pastScheduledTasks.filter((id) =>
          isOverdue(draft.taskHash[id])
        );
        draft.pastDueTasks = draft.pastDueTasks.filter(
          (id) =>
            isPastDueDate(draft.taskHash[id]) ||
            !isTodayOn(draft.taskHash[id].due_at)
        );

        draft.allTasks = draft.allTasks.filter((id) =>
          isTodayOn(draft.taskHash[id].schedule_start)
        );

        const allTasksHash = makeIdHash(draft.allTasks);
        const pastScheduledTasksHash = makeIdHash(draft.pastScheduledTasks);
        const pastDueTasksHash = makeIdHash(draft.pastDueTasks);

        tasks.forEach((task) => {
          const taskIsScheduledToday = isTodayOn(task.schedule_start);
          const taskIsPastScheduled = isOverdue(task);
          const taskIsPastDue = isPastDueDate(task) || !isTodayOn(task.due_at);
          if (taskIsScheduledToday && !allTasksHash[task.id]) {
            draft.allTasks.unshift(task.id);
          }
          if (taskIsPastScheduled && !pastScheduledTasksHash[task.id]) {
            draft.pastScheduledTasks.unshift(task.id);
          }
          if (taskIsPastDue && !pastDueTasksHash[task.id]) {
            draft.pastDueTasks.unshift(task.id);
          }
        });

        draft.editedTasks = {};
        break;
      }
      case constants.UPDATE_TASKS_ATTRIBUTES.TRIGGER: {
        const {
          body: {
            // A list of the tasks to update.
            task_ids: taskIds = [],

            // These two attributes have special handling.
            assignee_ids: assigneeIds = [],
            unassignee_ids: unassigneeIds = [],

            // The remaining task attributes.
            ...taskAttributes
          },
          options
        } = action.payload;

        // Update each task with the provided task attributes.
        taskIds.forEach((taskId) => {
          const task = draft.taskHash[taskId];
          if (task) {
            // Remove members from the list of assignees.
            if (unassigneeIds.length) {
              const unassignIds = new Set(unassigneeIds);

              // Remove assignees id that are to be unassigned.
              task.assignee_ids =
                task.assignee_ids?.filter((id) => !unassignIds.has(id)) || [];

              // Remove assignments objects that are to be unassigned.
              task.assignments =
                task.assignments?.filter(
                  (assignment) => !unassignIds.has(assignment.assignee_id)
                ) || [];
            }

            // Add members to the list of assignees. The `assignments` list is
            // a list of objects with detailed information regarding the
            // members who are assigned to a task. The `assignee_ids` list is
            // the same list, but only contains the member ids.
            if (assigneeIds.length) {
              // Ensure that the fields are defined.
              task.assignee_ids = task.assignee_ids || [];
              task.assignments = task.assignments || [];

              // Keep members who are not already assigned to the task. This
              // avoid changing the assignement history.
              const alreadyAssignedIds = new Set(task.assignee_ids);
              const newAssigneeIds = assigneeIds.filter(
                (id) => !alreadyAssignedIds.has(id)
              );

              // Add the new assignees.
              task.assignee_ids.push(...newAssigneeIds);

              // Add the new assignment objects.
              task.assignments.push(
                ...newAssigneeIds.map((id) => ({
                  assignee_id: id
                }))
              );
            }

            // Performs a recursive object merge of the remaining task
            // properties. Note that this will not replace whole arrays, but
            // will replace values by index.
            merge(task, taskAttributes);
          }

          // Removes relevant task from view if the task's projectId has
          // changed, unless the user just moves the task to the current
          // project.
          if (!!options && options.shouldRemoveTask) {
            draft.allTasks = draft.allTasks.filter((id) => id !== taskId);
          }
        });

        const selectedAccountIdsHash = makeIdHash(draft.selectedAccountIds);
        if (draft.selectedAccountIds.length) {
          draft.allTasks = state.allTasks.filter((id) =>
            draft.taskHash[id]?.assignee_ids?.some(
              (assigneeId) => selectedAccountIdsHash[assigneeId]
            )
          );
        }
        break;
      }
      case constants.UPDATE_TASKS_ATTRIBUTES.SUCCESS: {
        const {
          response: { tasks, project_memberships: projectMemberships },
          requestPayload: [token, data]
        } = action.payload;
        draft.taskHash = {
          ...state.taskHash,
          ...keyBy(tasks.map(mapTaskAssignIds), (task) => task.id)
        };
        draft.projectMembershipHash = {
          ...state.projectMembershipHash,
          ...projectMemberships
        };
        break;
      }
      case constants.SET_TASK_ACCOUNT_FILTER: {
        draft.selectedAccountIds = action.payload.selectedAccountIds;
        break;
      }
      case constants.TASK_LIST_LOADING_ADDITIONAL_TASKS.TRIGGER: {
        draft.isLazyLoading = true;
        draft.selectedAccountIds = action.payload.accountIds
          ? action.payload.accountIds
          : draft.selectedAccountIds;
        break;
      }
      case constants.TASK_LIST_LOADING_INITIAL_TASKS.TRIGGER: {
        draft.selectedAccountIds = action.payload.accountIds
          ? action.payload.accountIds
          : draft.selectedAccountIds;

        draft.taskCount = null;
        draft.allTasks = [];
        draft.taskHash = {};
        draft.taskGroupCounts = {};

        return;
      }
      case constants.TASK_LIST_LOADING_INITIAL_TASKS.REQUEST: {
        const { scope } = action.payload.request.options.body;
        if (scope !== 'past_due') {
          draft.isFetchingTasks = state.isFetchingTasks + 1;
        }
        break;
      }
      case constants.FETCH_TASK.SUCCESS: {
        const { task } = action.payload.response;

        const mappedAssigneeIdsTask = mapTaskAssignIds(task);
        draft.taskHash[task.id] = mapCommentIds(mappedAssigneeIdsTask);

        break;
      }
      case constants.WS_TASK: {
        const { added, deleted, origin, userId, reorder, ...task } =
          action.payload;
        if (origin === userId) {
          break;
        }
        if (deleted) {
          draft.allTasks.filter((taskId) => taskId !== task.id);
          delete draft.taskHash[task.id];
        }
        if (added && !draft.taskHash[task.id] && origin !== userId) {
          const position = Math.min(draft.allTasks.length, task.position);
          draft.allTasks.splice(position, 0, task.id);
        }
        // task reposition ws logic needs rework to handle task group positioning
        // if (
        //   reorder &&
        //   !state.sort &&
        //   state.currentFilter.state === 'incomplete'
        // ) {
        //   const outsideCurrentLoaded = task.position > draft.allTasks.length;
        //   draft.allTasks = draft.allTasks.filter(taskId => taskId !== task.id);
        //   if (!outsideCurrentLoaded) {
        //     draft.allTasks.splice(task.position, 0, task.id);
        //   }
        // }
        if (!deleted) {
          draft.taskHash[task.id] = mapTaskAssignIds(task);
        }

        break;
      }
      case constants.TASK_LIST_LOADING_INITIAL_TASKS.SUCCESS: {
        const { meta } = action.payload;
        draft.isFetchingTasks = Math.max(state.isFetchingTasks - 1, 0);
        if (meta.action.count) {
          const {
            past_scheduled_count,
            past_due_count,
            scheduled_today_count
          } = action.payload.data;
          draft.pastScheduledCount = past_scheduled_count;
          draft.pastDueCount = past_due_count;
          draft.scheduledForToday = scheduled_today_count;
          break;
        }
        const {
          tasks,
          current_page: current,
          total_pages: total
        } = action.payload.data;

        const currentPage = parseInt(current, 10);
        const totalPages = parseInt(total, 10);

        const taskIds = tasks.map((task) => task.id);
        if (meta.action.payload.pastScheduled) {
          draft.pastScheduledTasks = taskIds;
        } else if (meta.action.payload.pastDue) {
          draft.pastDueTasks = taskIds;
        } else {
          draft.allTasks = taskIds;
          draft.currentPage = currentPage;
          draft.totalPages = totalPages;
        }
        draft.taskHash = {
          ...draft.taskHash,
          ...keyBy(tasks.map(mapTaskAssignIds), (task) => task.id)
        };
        break;
      }
      case constants.FETCH_PROJECT_TASK_GROUP_TASKS.SUCCESS: {
        const {
          tasks,
          current_page: current,
          total_pages: total
        } = action.payload.data;
        const currentPage = parseInt(current, 10);
        const totalPages = parseInt(total, 10);

        draft.allTasks = concat(
          draft.allTasks,
          tasks.map((task) => task.id)
        );

        draft.taskHash = {
          ...draft.taskHash,
          ...keyBy(tasks.map(mapTaskAssignIds), (task) => task.id)
        };
        draft.isLazyLoading = false;
        draft.currentPage = currentPage;
        draft.totalPages = totalPages;
        break;
      }
      case constants.WS_ACCOUNT: {
        if (state.currentFilter.scope === 'project') {
          break;
        }
        const { payload } = action;
        if (!draft.taskHash[payload.id]) {
          // if (draft.tempId) {
          //   draft.tempId = null;
          //   delete draft.tempIds[action.payload.requestPayload[1].id];
          //   draft.allTasks[0] = payload.id;
          // } else {
          //   draft.allTasks.unshift(payload.id);
          // }
        }

        draft.taskHash[payload.id] = payload;

        break;
      }
      case constants.TASK_LIST_LOADING_ADDITIONAL_TASKS.SUCCESS: {
        const {
          tasks,
          current_page: current,
          total_pages: total
        } = action.payload.data;
        const currentPage = parseInt(current, 10);
        const totalPages = parseInt(total, 10);
        draft.allTasks = concat(
          draft.allTasks,
          (tasks || emptyArray).map((task) => task.id)
        );

        draft.taskHash = {
          ...draft.taskHash,
          ...keyBy(
            (tasks || emptyArray).map(mapTaskAssignIds),
            (task) => task.id
          )
        };
        draft.isLazyLoading = false;
        draft.currentPage = currentPage;
        draft.totalPages = totalPages;
        break;
      }
      case constants.HANDLE_HOME_TASK_COMPLETION_TOGGLE: {
        const { taskId, isCompleting, doNotRemoveTask } = action.payload;
        const existingTasks = state.allTasks;
        const [matches, misMatches] = partition(
          existingTasks,
          (existingTask) => {
            return taskId === existingTask;
          }
        );
        const task = draft.taskHash[taskId] || {};

        if (isCompleting) {
          task.completed_at = moment();
          task.is_complete = true;
        } else {
          task.completed_at = null;
          task.is_complete = false;
        }

        if (isTodayView) {
          const shouldAddToMain = isTodayOn(task.schedule_start);
          const shouldAddToPastDue =
            isPastDueDate(task) || !isTodayOn(task.due_at);
          const shouldAddToPastScheduled = isOverdue(task);

          if (isCompleting) {
            // push task to bottom of list and set completed at
            draft.allTasks = misMatches;
            draft.pastScheduledTasks = draft.pastScheduledTasks.filter(
              (id) => id !== taskId
            );
            draft.pastDueTasks = draft.pastDueTasks.filter(
              (id) => id !== taskId
            );
            if (shouldAddToMain) {
              draft.allTasks.push(taskId);
            }
          } else {
            if (shouldAddToPastDue) {
              draft.pastDueTasks.unshift(taskId);
            }
            if (shouldAddToPastScheduled) {
              draft.pastScheduledTasks.unshift(taskId);
            }
          }
        } else {
          const taskDestination = isCompleting
            ? taskRemoveTypes.completed
            : task.schedule_start
            ? taskRemoveTypes.scheduled
            : taskRemoveTypes.inbox;
          if (!doNotRemoveTask) {
            draft.taskRemovals[taskId] = taskDestination;
          }

          // every non day planner case
          if (!matches.length) {
            // the task was toggled from the modal and needs to be re-added to the list
            draft.allTasks.unshift(taskId);
            delete draft.taskRemovals[taskId];
          }
        }
        draft.confirmCompleteModalIsOpen = false;
        draft.confirmCompleteModalTaskId = null;
        break;
      }
      case constants.SET_BATCH_SELECTED_TASKS: {
        const taskIds = action.payload.taskIds || [];
        taskIds.forEach(
          (taskId) => (draft.batchSelectedTaskIds[taskId] = true)
        );
        const groupIds = action.payload.groupIds || [];
        groupIds.forEach(
          (groupId) => (draft.batchSelectedGroupIds[groupId] = true)
        );
        break;
      }
      case constants.CLEAR_BATCH_SELECTED_TASKS: {
        const taskIds = action.payload.taskIds || [];
        const groupIds = action.payload.groupIds || [];
        draft.batchSelectedTaskIds = omit(draft.batchSelectedTaskIds, taskIds);
        draft.batchSelectedGroupIds = omit(
          draft.batchSelectedGroupIds,
          groupIds
        );
        break;
      }
      case constants.FLUSH_BATCH_SELECTED_TASKS: {
        draft.batchSelectedTaskIds = {};
        draft.batchSelectedGroupIds = {};
        break;
      }
      case constants.TOGGLE_BATCH_SELECTED_TASKS: {
        const taskIds = action.payload;
        const toggledOnAlready = taskIds.filter(
          (taskId) => draft.batchSelectedTaskIds[taskId]
        );
        const toggledOffAlready = taskIds.filter(
          (taskId) => !draft.batchSelectedTaskIds[taskId]
        );
        draft.batchSelectedTaskIds = omit(
          draft.batchSelectedTaskIds,
          toggledOnAlready
        );
        toggledOffAlready.forEach(
          (toggledOff) => (draft.batchSelectedTaskIds[toggledOff] = true)
        );
        break;
      }
      case constants.BATCH_MOVE_INBOX_TASKS.SUCCESS: {
        const { taskIds } = action.payload.requestPayload[2];
        const existingTasks = state.allTasks;
        const [matches, misMatches] = partition(
          existingTasks,
          (existingTaskId) => {
            return includes(taskIds, existingTaskId);
          }
        );
        draft.allTasks = misMatches;
        break;
      }
      case constants.FLUSH_TASK_LIST_STORE: {
        draft.allTasks = [];
        draft.pastDueTasks = [];
        draft.pastScheduledTasks = [];
        draft.taskHash = {};
        draft.dependencyTaskHash = {};
        draft.timerTaskHash = {};
        draft.batchSelectedTaskIds = {};
        draft.batchSelectedGroupIds = {};
        draft.taskGroupCounts = {};
        draft.fetchedProjectIds = {};
        draft.taskCount = null;
        break;
      }
      case constants.MOVE_TASKS.TRIGGER: {
        const { options, taskIds, groupIds, groupId, dontRemoveTask } =
          action.payload;
        draft.batchSelectedGroupIds = {};
        draft.batchSelectedTaskIds = {};
        draft.allTasks = [...draft.allTasks]; // always reset order
        const taskIdHash = keyBy(taskIds);
        const groupIdHash = keyBy(groupIds);
        // remove affected tasks from view
        if (dontRemoveTask) {
          break;
        }
        if (options?.shouldRemoveTask) {
          draft.allTasks = draft.allTasks.filter((id) => {
            const task = draft.taskHash[id];
            return !taskIdHash[task?.id] && !groupIdHash[task?.task_group_id];
          });
          break;
        }
        // optimistically update task group id on tasks already in view
        if (groupId !== MOVE_WITH_EXISTING) {
          draft.allTasks.forEach((id) => {
            const task = draft.taskHash[id];
            if (taskIdHash[task?.id] || groupIdHash[task?.task_group_id]) {
              task.task_group_id = groupId;
            }
          });
          break;
        }
        break;
      }
      case constants.MOVE_TASKS.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const [token, request = {}] = requestPayload || [];
        if (
          (request.options?.shouldRemoveTask ||
            request.groupId === MOVE_WITH_EXISTING) &&
          !request.dontRemoveTask
        ) {
          // shouldRemoveTask:  tasks are removed in trigger
          // 'move': insufficient information to handle success here
          break;
        } else {
          response.forEach((responseTask) => {
            const task = draft.taskHash[responseTask.id];
            if (task) {
              // if task was not already loaded, we don't have sufficient information to populate until a refetch occurs
              merge(task, responseTask);
            }
          });
        }
        break;
      }
      case constants.COPY_TASKS.TRIGGER: {
        draft.batchSelectedGroupIds = {};
        draft.batchSelectedTaskIds = {};
        break;
      }
      case constants.COPY_TASKS.REQUEST: {
        const { payload } = action;

        const { canAnticipateCopiedTasks, move_task_ids, uuids, groupId } =
          payload || {};
        if (!canAnticipateCopiedTasks) {
          break;
        }
        move_task_ids.forEach((taskId, idx) => {
          const taskTemplate = createTaskTemplate();
          const tempId = uuids[idx];
          draft.allTasks.unshift(tempId);
          const { description, note, project_id } = draft.taskHash[taskId];
          merge(taskTemplate, {
            description,
            note,
            project_id,
            task_group_id: groupId
          });
          draft.taskHash[tempId] = taskTemplate;
        });
        break;
      }
      case constants.COPY_TASKS.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const [token, request = {}] = requestPayload || [];

        if (request.groupId === COPY_WITH_EXISTING) {
          // insufficient information to handle here
          break;
        } else if (request.canAnticipateCopiedTasks) {
          const tempIds = draft.allTasks.slice(0, response.length);

          tempIds.forEach((tempId, index) => {
            const responseTask = response[index];
            draft.allTasks[index] = responseTask.id;
            draft.taskHash[responseTask.id] = {
              ...draft.taskHash[tempId],
              ...responseTask
            };
            delete draft.taskHash[tempId];
          });
        }
        break;
      }
      case constants.BATCH_MOVE_TASKS_TO_PROJECT.SUCCESS: {
        const { taskIds } = action.payload.requestPayload[2];
        const existingTasks = state.allTasks;
        const [matches, misMatches] = partition(
          existingTasks,
          (existingTaskId) => {
            return includes(taskIds, existingTaskId);
          }
        );
        const updatedTasks = [...matches, ...misMatches];
        draft.allTasks = updatedTasks;
        break;
      }
      case constants.BATCH_COPY_TASKS_TO_PROJECT.REQUEST: {
        const taskTemplate = createTaskTemplate();
        const { taskIds, uuids } = action.payload;
        taskIds.forEach((taskId, idx) => {
          const tmpId = uuids[idx];
          draft.allTasks.unshift(tmpId);
          const { description, note, project_id } = draft.taskHash[taskId];
          merge(taskTemplate, { description, note, project_id });
          draft.taskHash[tmpId] = taskTemplate;
        });
        break;
      }
      case constants.BATCH_COPY_TASKS_TO_PROJECT.SUCCESS: {
        const { response } = action.payload;
        const newIds = [];
        const createdAts = [];
        const updatedAts = [];
        response.forEach((entry) => {
          newIds.push(entry.id);
          createdAts.push(entry.created_at);
          updatedAts.push(entry.updated_at);
        });
        const tmpIds = draft.allTasks.slice(0, newIds.length);
        draft.allTasks.splice(0, newIds.length, ...newIds);

        tmpIds.forEach((tmpId, idx) => {
          const task = draft.taskHash[tmpId];
          task.id = newIds[idx];
          task.created_at = createdAts[idx];
          task.updated_at = updatedAts[idx];
          draft.taskHash[newIds[idx]] = task;

          delete draft.taskHash[tmpId];
        });
        break;
      }
      case constants.BATCH_DELETE_TASKS.SUCCESS: {
        const { taskIds } = action.payload.requestPayload[2];
        const existingTasks = state.allTasks;
        const updatedTasksArray = filter(existingTasks, (existingTaskId) => {
          return !includes(taskIds, existingTaskId);
        });
        draft.taskHash = omit(draft.taskHash, taskIds);
        draft.allTasks = updatedTasksArray;
        break;
      }
      case constants.BATCH_UNFOLLOW_TASKS.SUCCESS: {
        const { taskIds } = action.payload.requestPayload[2];
        const existingTasks = state.allTasks;
        const updatedTasksArray = filter(existingTasks, (existingTaskId) => {
          return !includes(taskIds, existingTaskId);
        });
        draft.allTasks = updatedTasksArray;
        break;
      }
      case constants.BATCH_COMPLETE_TASKS.REQUEST: {
        const { taskIds } = action.payload;
        const viewCompletionState = state.currentFilter.state;
        const selectedTasks = taskIds.map((id) => state.taskHash[id]);
        const selectedTaskObj = keyBy(selectedTasks, (task) => task.id);
        const currentlyIncompleteIds = taskIds.filter(
          (id) => !selectedTaskObj[id].completed_at
        );
        // this case covers 'Today'
        if (viewCompletionState === 'default') {
          const updatedTasks = currentlyIncompleteIds.length
            ? selectedTasks // at LEAST one incomplete (could have completed too)
                .filter((task) => !task.completed_at)
                .map((task) => ({ ...task, completed_at: moment() }))
            : selectedTasks // only completed tasks selected
                .map((task) => ({ ...task, completed_at: null }));
          const updatedTasksObj = keyBy(updatedTasks, (task) => task.id);

          if (currentlyIncompleteIds.length) {
            // move completed tasks to bottom
            draft.allTasks = state.allTasks
              .filter((id) => !updatedTasksObj[id])
              .concat(currentlyIncompleteIds);
          }

          draft.taskHash = {
            ...state.taskHash,
            ...updatedTasksObj
          };
        } else if (viewCompletionState !== 'default') {
          const updatedTasks = selectedTasks.map((task) =>
            viewCompletionState === 'incomplete'
              ? { ...task, completed_at: moment() }
              : { ...task, completed_at: null }
          );

          draft.taskHash = {
            ...state.taskHash,
            ...keyBy(updatedTasks, (task) => task.id)
          };
          const calcTaskDestination = (id) =>
            viewCompletionState === 'incomplete'
              ? taskRemoveTypes.completed
              : draft.taskHash[id] && draft.taskHash[id].schedule_start
              ? taskRemoveTypes.scheduled
              : taskRemoveTypes.inbox;
          state.allTasks.forEach((id) => {
            if (selectedTaskObj[id]) {
              draft.taskRemovals[id] = calcTaskDestination(id);
            }
          });
        }
        break;
      }
      case constants.UPDATE_TASK_HOME_POSITION_ON_CLIENT: {
        draft.allTasks = action.payload.sortedTasks;
        break;
      }
      case constants.CREATE_TASK_FROM_HOME.REQUEST: {
        const { body } = action.payload;
        const {
          id,
          description,
          due_at,
          schedule_start,
          assignee_ids,
          assignee_id,
          project_id,
          view_project_id,
          project_position,
          home_position,
          phase_id,
          status,
          estimated_hours,
          task_group_id,
          nextTaskId
        } = body;

        const fromHomeTop = home_position === 0 && !view_project_id;
        const fromProjectTop = project_position === 0 && view_project_id;
        const useTopPosition = fromHomeTop || fromProjectTop;
        // If nextTaskId is a uuid, need to search for its newly created ID
        const nextCreatedTask = Object.entries(
          state.createdTasksToTempIds
        ).find(([_, tempId]) => tempId === nextTaskId);
        const nextTaskIdToUse = nextCreatedTask
          ? parseInt(nextCreatedTask[0])
          : nextTaskId;
        const nextTaskIndex = draft.allTasks.indexOf(nextTaskIdToUse);
        const position =
          nextTaskIndex !== -1
            ? nextTaskIndex
            : useTopPosition
            ? 0
            : draft.allTasks.length;
        const tempTask = {
          id,
          temp_id: id,
          description,
          project_id,
          assignee_id,
          assignee_ids,
          task_group_id,
          assignments: assignee_ids?.map((assignee_id, index) => ({
            assignee_id,
            id: index
          })), // id is used to specifiy task assignment order
          due_at,
          schedule_start,
          completed_at: null,
          position,
          phase_id,
          status,
          estimated_hours
        };

        draft.allTasks.splice(position, 0, tempTask.id);
        draft.taskHash[id] = tempTask;
        draft.tempId = tempTask.id;
        draft.tempIds[tempTask.id] = tempTask.id;
        break;
      }
      case constants.CREATE_TASK_FROM_HOME.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const { task } = response;
        const [token, requestBody] = requestPayload;
        const { id: tempId } = requestBody;
        const shouldAddToPastDue = isTodayView && isPastDueDate(task);
        const shouldAddToPastScheduled = isTodayView && isOverdue(task);
        const tempTaskIndex = state.allTasks.indexOf(tempId);

        draft.taskHash[task.id] = mapTaskAssignIds(task);
        // handle temp task cleanup, store link between temp id and new id
        delete draft.taskHash[tempId];
        delete draft.tempIds[tempId];
        draft.createdTasksToTempIds[task.id] = tempId;

        if (draft.selectedTask === tempId) {
          draft.selectedTask = task.id;
        }
        if (tempTaskIndex > -1) {
          draft.allTasks.splice(tempTaskIndex, 1, task.id);
        }
        if (shouldAddToPastDue) {
          draft.pastDueTasks.push(task.id);
        }
        if (shouldAddToPastScheduled) {
          draft.pastScheduledTasks.push(task.id);
        }
        if (draft.batchSelectedTaskIds[tempId]) {
          delete draft.batchSelectedTaskIds[tempId];
          draft.batchSelectedTaskIds[task.id] = true;
        }
        break;
      }
      case constants.SET_SELECTED_HOME_TASK: {
        draft.selectedTask = action.taskId;
        break;
      }
      case constants.FLUSH_SELECTED_HOME_TASK: {
        draft.selectedTask = null;
        draft.taskEditProperty = null;
        const selectedAccountIdsHash = makeIdHash(draft.selectedAccountIds);
        if (draft.selectedAccountIds.length) {
          draft.allTasks = state.allTasks.filter((id) =>
            draft.taskHash[id]?.assignee_ids?.some(
              (assigneeId) => selectedAccountIdsHash[assigneeId]
            )
          );
        }
        break;
      }

      case constants.TASK_LIST_FILTER_CHANGE: {
        const { state, scope, section, subSection, viewType } = action.payload;
        draft.currentFilter = {
          state: state || draft.currentFilter.state,
          scope: scope || draft.currentFilter.scope,
          section: section || draft.currentFilter.section,
          subSection: subSection || draft.currentFilter.subSection,
          viewType: viewType || initialState.viewType
        };
        break;
      }
      case constants.UPDATE_HOME_TASK_SORT_ORDER: {
        const { sort, direction } = action.payload;
        draft.sort = sort;
        draft.direction = direction;
        break;
      }
      case constants.UPDATE_DAY_PLANNER.TRIGGER: {
        if (!isTodayView) {
          break;
        }
        const { params } = action.payload;
        const { taskId, position, date } = params;

        if (draft.taskHash[taskId]) {
          draft.taskHash[taskId].schedule_start = keyifyDate(date);
        }

        draft.allTasks = draft.allTasks.filter((task) => task !== taskId);
        draft.allTasks.splice(position, 0, taskId);
        break;
      }
      case constants.RESET_TASK_FILTERS: {
        draft.sort = null;
        draft.currentFilter = {
          scope: 'default',
          state: 'incomplete', // incomplete is default, default is all tasks
          section: draft.currentFilter.section, // Reset is getting called while page load and the app loses track of what section it was on
          subSection: draft.currentFilter.subSection
        };
        draft.selectedAccountIds = [];
        draft.activeTaskFilter = {};
        break;
      }
      case constants.SET_ACTIVE_TASK_FILTER: {
        draft.activeTaskFilter = action.payload.activeFilter;
        break;
      }
      case constants.SET_MEMBER_MODAL_TASK_FILTER: {
        draft.memberModalTaskFilter = action.payload.memberModalTaskFilter;
        break;
      }
      case constants.SET_TASK_VIEW_BY: {
        draft.viewBy = action.payload.viewBy;
        break;
      }
      case constants.OPEN_FLAGGED_TASKS_MODAL: {
        const params = action.payload;
        draft.flaggedTasksModalOpen = true;
        draft.selectedFlag = params.selectedFlag;
        draft.selectedDate = params.selectedDate;
        break;
      }
      case constants.CLOSE_PAST_DUE_TASK_MODAL: {
        draft.flaggedTasksModalOpen = false;
        draft.selectedFlag = '';
        draft.selectedDate = null;
        draft.editedTasks = {};
        break;
      }
      case constants.CLOSE_HOME_TASK_MODAL: {
        draft.flaggedTasksModalOpen = false;
        draft.editedTasks = {};
        break;
      }

      case constants.SET_HOME_TASK_EDIT_PROPERTY: {
        return {
          ...state,
          taskEditProperty: action.payload
        };
      }
      case constants.WS_TASK_COMMENT: {
        const { payload } = action;
        const { project_task_id, deleted, id } = payload;
        const task = draft.taskHash[project_task_id];
        if (task) {
          if (task.task_comments) {
            let taskComments = task.task_comments;
            if (deleted) {
              taskComments = taskComments.filter(
                (comment) => comment.id !== id
              );
            } else if (!taskComments.find((comment) => comment.id === id)) {
              taskComments.unshift(payload);
            }
            task.task_comments = taskComments;
          }
          if (task.task_comment_ids) {
            let taskCommentIds = task.task_comment_ids;
            if (deleted) {
              taskCommentIds = taskCommentIds.filter(
                (commentId) => commentId !== id
              );
            } else if (!taskCommentIds.includes(id)) {
              taskCommentIds.unshift(id);
            }
            task.task_comment_ids = taskCommentIds;
          }
        }
        break;
      }
      case constants.CREATE_COMMENT.SUCCESS: {
        const { response } = action.payload;
        const { project_task_id } = response;
        const task = draft.taskHash[project_task_id];
        if (task) {
          const taskComments = task.comments || [];
          taskComments.unshift(response);
          task.comments = taskComments;
          if (task.task_comments) {
            const taskComments = task.task_comments;
            if (!taskComments.find((comment) => comment.id === response.id)) {
              taskComments.unshift(response);
            }
            task.task_comments = taskComments;
          }
          if (task.task_comment_ids) {
            const taskCommentIds = task.task_comment_ids;
            if (!taskCommentIds.includes(response.id)) {
              taskCommentIds.unshift(response.id);
            }
            task.task_comment_ids = taskCommentIds;
          }
        }
        break;
      }
      case constants.FETCH_TASKS_V2.TRIGGER: {
        const { initial } = action.payload;
        draft.isLazyLoading = true;
        if (initial) {
          draft.taskCount = null;
          draft.allTasks = [];
          draft.taskHash = {};
          draft.taskGroupCounts = {};
        }
        break;
      }
      case constants.FETCH_TASKS_V2.SUCCESS: {
        const {
          taskListType,
          body: { offset, limit, project_ids = [] },
          initial
        } = action.payload.requestPayload;

        const { tasks, task_count, sort_attribute_counts } =
          action.payload.response;
        if (
          tasks?.length &&
          taskListType !== 'dependency' &&
          taskListType !== 'timer'
        ) {
          tasks.forEach(
            (task) => (draft.taskHash[task.id] = mapTaskAssignIds(task))
          );
        } else if (tasks?.length && taskListType === 'dependency') {
          const newDependencyTaskHash = {};
          tasks.forEach((task) => (newDependencyTaskHash[task.id] = task));
          draft.dependencyTaskHash = { ...newDependencyTaskHash };
        } else if (tasks?.length && taskListType === 'timer') {
          const newTimerTaskHash = {};
          tasks.forEach((task) => (newTimerTaskHash[task.id] = task));
          draft.timerTaskHash = { ...draft.timerTaskHash, ...newTimerTaskHash };
        }
        const taskIds = tasks ? tasks.map((task) => task.id) : [];
        draft.isLazyLoading = false;
        if (!taskListType) {
          // if taskListType present, tasks are being fetched for a list that is not the primary list in the app
          draft.allTasks = initial
            ? taskIds
            : Array.from(new Set([...draft.allTasks, ...taskIds]));
          draft.offset =
            (offset !== undefined ? offset : state.offset) + (limit || 0);
          draft.taskCount = task_count;
          draft.taskGroupCounts = sort_attribute_counts;
          const projectIdHash = project_ids.reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
          }, {});
          draft.fetchedProjectIds = {
            ...(initial ? draft.fetchedProjectIds : {}),
            ...projectIdHash
          };
        }
        break;
      }
      case constants.FETCH_TASK_COUNTS.SUCCESS: {
        const { task_count, sort_attribute_counts } = action.payload.response;
        draft.taskCount = task_count;
        draft.taskGroupCounts = sort_attribute_counts;
        break;
      }
      case constants.FETCH_MEMBER_TODAY_TASK_COUNT.TRIGGER: {
        const { filterStateId } = action.payload;
        if (filterStateId) {
          const nextFilterState = !state.filterStates[filterStateId]
            ? initialFilterState
            : state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              today: true
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_TODAY_TASK_COUNT.FAILURE: {
        const { filterStateId } = action.payload.requestPayload;
        if (filterStateId) {
          const nextFilterState = state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              today: false
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_TODAY_TASK_COUNT.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const { task_count } = response;
        const { filterStateId } = requestPayload;

        if (filterStateId) {
          draft.filterStates[filterStateId].isFetchingCounts.today = false;
          draft.filterStates[filterStateId].memberModalTaskCounts.today =
            task_count;
        } else {
          draft.memberModalTaskCounts.today = task_count;
        }
        break;
      }
      case constants.FETCH_MEMBER_OVERDUE_TASK_COUNT.TRIGGER: {
        const { filterStateId } = action.payload;
        if (filterStateId) {
          const nextFilterState = !state.filterStates[filterStateId]
            ? initialFilterState
            : state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              overdue: true
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_OVERDUE_TASK_COUNT.FAILURE: {
        const { filterStateId } = action.payload.requestPayload;
        if (filterStateId) {
          const nextFilterState = state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              overdue: false
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_OVERDUE_TASK_COUNT.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const { task_count } = response;
        const { filterStateId } = requestPayload;

        if (filterStateId) {
          draft.filterStates[filterStateId].isFetchingCounts.overdue = false;
          draft.filterStates[filterStateId].memberModalTaskCounts.overdue =
            task_count;
        } else {
          draft.memberModalTaskCounts.overdue = task_count;
        }
        break;
      }
      case constants.FETCH_MEMBER_SCHEDULED_TASK_COUNT.TRIGGER: {
        const { filterStateId } = action.payload;
        if (filterStateId) {
          const nextFilterState = !state.filterStates[filterStateId]
            ? initialFilterState
            : state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              scheduled: true
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_SCHEDULED_TASK_COUNT.FAILURE: {
        const { filterStateId } = action.payload.requestPayload;
        if (filterStateId) {
          const nextFilterState = state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              scheduled: false
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_SCHEDULED_TASK_COUNT.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const { task_count } = response;
        const { filterStateId } = requestPayload;

        if (filterStateId) {
          draft.filterStates[filterStateId].isFetchingCounts.scheduled = false;
          draft.filterStates[filterStateId].memberModalTaskCounts.scheduled =
            task_count;
        } else {
          draft.memberModalTaskCounts.scheduled = task_count;
        }
        break;
      }
      case constants.FETCH_MEMBER_UNSCHEDULED_TASK_COUNT.TRIGGER: {
        const { filterStateId } = action.payload;
        if (filterStateId) {
          const nextFilterState = !state.filterStates[filterStateId]
            ? initialFilterState
            : state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              unscheduled: true
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_UNSCHEDULED_TASK_COUNT.FAILURE: {
        const { filterStateId } = action.payload.requestPayload;
        if (filterStateId) {
          const nextFilterState = state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              unscheduled: false
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_UNSCHEDULED_TASK_COUNT.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const { task_count } = response;
        const { filterStateId } = requestPayload;

        if (filterStateId) {
          draft.filterStates[
            filterStateId
          ].isFetchingCounts.unscheduled = false;
          draft.filterStates[filterStateId].memberModalTaskCounts.unscheduled =
            task_count;
        } else {
          draft.memberModalTaskCounts.unscheduled = task_count;
        }
        break;
      }

      // This is very specific for TasksWidget.
      case constants.FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT.TRIGGER: {
        const { filterStateId, dateRangeType } = action.payload;
        if (filterStateId && dateRangeType) {
          const nextFilterState = !state.filterStates[filterStateId]
            ? initialFilterState
            : state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              [dateRangeType]: true
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT.FAILURE: {
        const { filterStateId, dateRangeType } = action.payload.requestPayload;
        if (filterStateId) {
          const nextFilterState = state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              [dateRangeType]: false
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const { task_count } = response;
        const { filterStateId, dateRangeType } = requestPayload;

        if (filterStateId) {
          draft.filterStates[filterStateId].isFetchingCounts[
            dateRangeType
          ] = false;
          draft.filterStates[filterStateId].memberModalTaskCounts[
            dateRangeType
          ] = task_count;
        } else {
          draft.memberModalTaskCounts[dateRangeType] = task_count;
        }
        break;
      }

      case constants.FETCH_MEMBER_COMPLETED_TASK_COUNT.TRIGGER: {
        const { filterStateId, range } = action.payload;
        if (filterStateId && range) {
          const nextFilterState = !state.filterStates[filterStateId]
            ? initialFilterState
            : state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              [range]: true
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_COMPLETED_TASK_COUNT.FAILURE: {
        const { filterStateId, range } = action.payload.requestPayload;
        if (filterStateId) {
          const nextFilterState = state.filterStates[filterStateId];
          draft.filterStates[filterStateId] = {
            ...nextFilterState,
            isFetchingCounts: {
              ...nextFilterState.isFetchingCounts,
              [range]: false
            }
          };
        }
        break;
      }
      case constants.FETCH_MEMBER_COMPLETED_TASK_COUNT.SUCCESS: {
        const { response, requestPayload } = action.payload;
        const { task_count } = response;
        const { filterStateId, range } = requestPayload;

        if (filterStateId) {
          draft.filterStates[filterStateId].isFetchingCounts[range] = false;
          draft.filterStates[filterStateId].memberModalTaskCounts[range] =
            task_count;
        } else {
          draft.memberModalTaskCounts.completed = task_count;
        }
        break;
      }
      case constants.INSERT_TASK_BEFORE: {
        const { taskBeforeId, taskId } = action.payload;
        const taskBeforeIndex = draft.allTasks.findIndex(
          (id) => `${id}` === `${taskBeforeId}`
        );
        const taskIndex = draft.allTasks.findIndex(
          (id) => `${id}` === `${taskId}`
        );

        draft.allTasks = reorder(
          draft.allTasks,
          taskIndex,
          taskBeforeIndex + 1
        );
        break;
      }
      case constants.ADD_TASK_TO_GROUP: {
        const {
          taskId,
          taskGroupId,
          groupType = 'taskGroup',
          changedTaskAttributes = {}
        } = action.payload;
        const task = draft.taskHash[taskId];
        const updateTypeHash = {
          taskGroup: 'task_group_id',
          activityPhase: 'activity_phase_id'
        };
        const updateKey = updateTypeHash[groupType];
        if (task) {
          task[updateKey] = taskGroupId;
        }
        draft.taskHash[taskId] = {
          ...task,
          ...changedTaskAttributes
        };
        break;
      }
      case constants.OPEN_CONFIRM_COMPLETE_TASK_MODAL: {
        const { taskId } = action.payload;
        draft.confirmCompleteModalTaskId = taskId;
        draft.confirmCompleteModalIsOpen = true;
        break;
      }
      case constants.CLOSE_CONFIRM_COMPLETE_TASK_MODAL: {
        draft.confirmCompleteModalTaskId = null;
        draft.confirmCompleteModalIsOpen = false;
        break;
      }
    }
  });

export default homeTasks;
