import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { signupUser, signupClose } from 'actionCreators';
import { SignupForm } from '..';
import logo from 'images/mosaic-logo.png';
import { getAuth, getRouterLocationQuery } from 'selectors';

class SignupContainer extends React.Component {
  componentDidMount() {
    this.redirectIfLoggedIn(this.props);
    // Blocking signup until 6-29-18
    const script1 = document.createElement('script');
    script1.id = 'maitre-script-1';
    script1.type = 'text/javascript';
    script1.async = true;
    script1.innerHTML = `!function (m, a, i, t, r, e) { if (m.Maitre) return; r = m.Maitre = {}, r.uuid = t, r.loaded = 0, r.base_url = i, r.queue = [], m.mtr = function () {r.queue.push(arguments)}; e = a.getElementsByTagName('script')[0], c = a.createElement('script'); c.async = !0, c.src = i + '/widget/' + t + '.js', e.parentNode.insertBefore(c, e) }(window, document, 'https://maitreapp.co', 'MFd1f1207dac');`;
    document.body.appendChild(script1);
  }

  componentWillMount() {
    const { signupUser, query } = this.props;
    const email = query && query.get('e');
    if (email) {
      signupUser(email);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
      this.redirectIfLoggedIn(nextProps);
    }
  }

  redirectIfLoggedIn = (props) => {
    const { auth, redirectRoute, history } = props;
    if (auth.isAuthenticated) {
      history.push(redirectRoute);
    } else {
      window.localStorage.clear();
    }
  };

  navigateToLogin = () => {
    const { signupClose, history } = this.props;
    signupClose();
    history.push('/login');
  };

  render() {
    const { signupUser, signup, location, query } = this.props;
    const email = query && query.get('e');

    return (
      <div>
        <div className="auth-page">
          <a href="https://mosaicapp.com">
            <img src={logo} className="login-logo" />
          </a>
          <SignupForm
            handleSubmit={signupUser}
            statusText={signup.statusText}
            goToLogin={this.navigateToLogin}
            location={location}
            email={email}
          />
        </div>
      </div>
    );
  }
}

SignupContainer.propTypes = {
  signupUser: PropTypes.func.isRequired,
  signup: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  query: getRouterLocationQuery(state),
  signup: state.signup,
  redirectRoute: '/',
  auth: getAuth(state)
});

const mapDispatchToProps = { signupUser, signupClose };

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);
