import { getHomeTaskObj, getWorkloadPlannerBarModalState } from 'selectors';
import { UUID } from 'models/uuid';
import { Task } from 'models/task';
import { NonNegative } from 'type-fest';
import { createSelector } from 'reselect';
import { RootState } from 'reduxInfra/shared';

// Types for lazy-loading. These should be moved to `workloadPlannerBarModal`
// once it is updated to Typescript.
export type LazyLoadingGroupId = string;
export type ItemId = number;
export type LazyLoadingContextId = UUID;
export type LazyLoadingHash<Item> = Map<
  LazyLoadingGroupId,
  LazyLoadingGroup<Item>
>;
export type LazyLoadingContext = Map<
  LazyLoadingGroupId,
  LazyLoadingGroupSparse
>;

const getOwnLazyLoadingId = (
  _state: RootState,
  { lazyLoadingId }: { lazyLoadingId: LazyLoadingContextId }
) => lazyLoadingId;

type LazyLoadingCount = { lazyLoadingCount: NonNegative<number> };
export const isLazyLoadingCount = <T>(
  item: T | LazyLoadingCount
): item is LazyLoadingCount => item && 'lazyLoadingCount' in item;

export type LazyLoadingSlot = {
  index: NonNegative<number>;
  isLazyLoading: boolean;
};
export const isLazyLoadingSlot = <T>(
  item: T | LazyLoadingSlot
): item is LazyLoadingSlot => item && 'isLazyLoading' in item;

export interface LazyLoadingGroupSparse {
  /**
   * The number of active lazy-loading requests.
   */
  lazyLoadingCount: NonNegative<number>;

  /**
   * The list of tasks. This array is sparse, meaning some values may be empty.
   * If a value of type `{ lazyLoadingCount: NonNegative }` is present, the
   * `lazyLoadingCount` number represents the number of times the item is being
   * lazy loaded. In principle, this should be `1`, but this will allow proper
   * tracking if an item is lazily-loaded multiple times in parallel and
   * leading attemps fail or are aborted.
   */
  items?: Array<ItemId | LazyLoadingCount>;

  /**
   * The range of tasks that have been updated since the last check. The upper
   * bound of the range is open. Some subsequences of the range may not have
   * been updated.
   */
  updatedRange?: { min: NonNegative<number>; max: NonNegative<number> };
}

export interface LazyLoadingGroup<T> {
  /**
   * The number of active lazy-loading requests.
   */
  lazyLoadingCount: NonNegative<number>;

  /**
   * The list of tasks.
   */
  items?: Array<T | LazyLoadingSlot>;

  /**
   * The range of tasks that have been updated since the last check. The upper
   * bound of the range is open. Some subsequences of the range may not have
   * been updated.
   */
  updatedRange?: { min: NonNegative<number>; max: NonNegative<number> };
}

/**
 * Gets a hash of the lazy-loading groups with sparse task lists in the
 * lazy-loading context.
 */
export const getLazyLoadingHash = createSelector(
  getWorkloadPlannerBarModalState,
  getOwnLazyLoadingId,
  (state, lazyLoadingId): LazyLoadingContext | undefined =>
    state.lazyLoadingContexts?.get(lazyLoadingId)
);

/**
 * Determines if there are groups initially lazy-loading in the lazy-loading
 * context.
 */
const getHasLazyItemsGroupLoading = createSelector(
  getLazyLoadingHash,
  (lazyLoadingHash): boolean =>
    lazyLoadingHash
      ? Array.from(lazyLoadingHash.values()).some(
          ({ lazyLoadingCount, items }) =>
            lazyLoadingCount && items === undefined
        )
      : false
);
export const makeGetHasLazyItemsGroupLoading =
  (ownProps: Parameters<typeof getHasLazyItemsGroupLoading>[1]) =>
  (state: RootState) =>
    getHasLazyItemsGroupLoading(state, ownProps);

/**
 * Optionally maps a task into another structure.
 */
const getOwnTaskFilterMap = <T>(
  _state: RootState,
  { taskFilterMap }: { taskFilterMap?: (task: Task) => T | undefined }
) => taskFilterMap ?? ((task: Task) => task);

/**
 * Gets a hash of the lazy-loading tasks.
 */
export const getLazyLoadingTasks = createSelector(
  getLazyLoadingHash,
  getHomeTaskObj,
  getOwnTaskFilterMap,
  <T = Task>(
    lazyLoadingHash: LazyLoadingContext | undefined,
    taskHash: Record<number, Task>,
    taskFilterMap: (task: Task) => T | undefined
  ): LazyLoadingHash<T> =>
    lazyLoadingHash
      ? new Map(
          Array.from(lazyLoadingHash).map(
            ([groupId, { items: tasks, ...rest }]) => [
              groupId,
              {
                items: tasks
                  ? Array.from(tasks, (taskId, index) => {
                      // We assume that if there is a task ID, the data for
                      // that task is in the task hash.
                      const task =
                        typeof taskId === 'number' && taskHash[taskId];

                      return !task
                        ? ({
                            index,
                            isLazyLoading: Boolean(taskId)
                          } as LazyLoadingSlot)
                        : taskFilterMap(task);
                    }).filter((task: T | undefined): task is T => Boolean(task))
                  : undefined,
                ...rest
              }
            ]
          )
        )
      : new Map()
);
export const makeGetLazyLoadingTasks =
  <T>(ownProps: {
    lazyLoadingId: LazyLoadingContextId;
    taskFilterMap?: (task: Task) => T | undefined;
  }) =>
  (state: RootState) =>
    getLazyLoadingTasks(state, ownProps);
