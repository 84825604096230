import { useCallback, useEffect } from 'react';
import { useAppSelector } from 'reduxInfra/hooks';
import { getTeamMembersHash } from 'selectors';
import { FilterField } from 'FilterModule/constants';
import { useArrayFilterField, useCrossFieldDependencies } from '.';
import { FilterListTypeHookWithItemHash } from 'FilterModule/types';
import { useLeanApi } from 'LeanApiModule/utils';
import { leanApiSchemaVariant } from 'LeanApiModule/common';

const emptyObj = {};
const emptyArray = [];

/**
 * Currently handling account ids only
 */
export const useMembersFilter = ({
  isOff,
  config,
  resultCountHash,
  isResultsLoading,
  shouldUseDraft,
  isoStateId = 'allMembersFilter',
  field = FilterField.account_ids
}: Parameters<FilterListTypeHookWithItemHash>[0] = emptyObj) => {
  const { dependencyFilter, numCrossFieldDependencyFiltersBeingUsed } =
    useCrossFieldDependencies({
      crossFieldDependencies: config?.crossFieldDependencies ?? [],
      isOff,
      shouldUseDraft
    });

  const {
    debouncedFetchLeanApi,
    dispatchClearLeanApiIsoState,
    leanApiIsoState,
    fetchStatus
  } = useLeanApi({ isoStateId });

  const topLevelOrder = leanApiIsoState?.topLevelOrder || emptyArray;

  const memberHash = useAppSelector((state) =>
    isOff ? emptyObj : getTeamMembersHash(state)
  ) as Record<
    number,
    {
      name: string;
      initials: string;
      isLoggedInUser: boolean;
      is_archived: boolean;
      account: { id: number };
    }
  >; // FIXME

  const getIsItemArchived = useCallback(
    (accountId: number) => {
      return memberHash[accountId]?.is_archived ?? false;
    },
    [memberHash]
  );

  const arrayFilterFieldValues = useArrayFilterField({
    field,
    items: topLevelOrder,
    itemHash: memberHash,
    itemSearchableKeys: searchableKeys,
    isOff,
    resultCountHash,
    hasArchivedItems: true,
    getIsItemArchived,
    isFeSearchDisabled: true,
    config,
    labelKey,
    shouldUseDraft
  });

  // fetch
  useEffect(() => {
    if (!isOff) {
      debouncedFetchLeanApi({
        meta: {
          isInitialFetch: true,
          isTakeLatest: true
        },
        schemaVariant: leanApiSchemaVariant.allMembers,
        searchText: arrayFilterFieldValues.searchText,
        filterParams: dependencyFilter
      });
    }
  }, [
    arrayFilterFieldValues.searchText,
    debouncedFetchLeanApi,
    dependencyFilter,
    isOff
  ]);

  // clear isoState when turned off to avoid stale state (if turned back on) since filter params may have changed
  useEffect(() => {
    if (isOff) {
      dispatchClearLeanApiIsoState();
    }
  }, [dispatchClearLeanApiIsoState, isOff]);

  return {
    ...arrayFilterFieldValues,
    itemHash: memberHash,
    isLoading: isResultsLoading || !fetchStatus || fetchStatus.isLoading,
    labelKey,
    resultCountHash,
    hasFilterParams:
      !!arrayFilterFieldValues.searchText ||
      numCrossFieldDependencyFiltersBeingUsed > 0
  };
};

/* ------------------------------------ - ----------------------------------- */

const labelKey = 'name';

const searchableKeys: (keyof { name: string })[] = [labelKey];
