import qs from 'qs';
import { callApi } from 'appUtils/api';
import {
  fetchAccountsSuggestionForProjectsActionPayload,
  fetchAccountsSuggestionForPhasesActionPayload
} from 'SuggestionModule/types';

const fetchAccountsSuggestionForProjects = (
  _,
  token: string,
  params: fetchAccountsSuggestionForProjectsActionPayload
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(params, {
    arrayFormat: 'brackets'
  });

  const url = `suggestions/project_accounts/?${stringifiedQueryParams}`;

  return callApi(url, token, fetchInit);
};

const fetchAccountsSuggestionForPhases = (
  _,
  token: string,
  params: fetchAccountsSuggestionForPhasesActionPayload
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(params, {
    arrayFormat: 'brackets'
  });

  const url = `suggestions/phase_accounts/?${stringifiedQueryParams}`;

  return callApi(url, token, fetchInit);
};

export const accountsSuggestionApis = {
  fetchAccountsSuggestionForProjects,
  fetchAccountsSuggestionForPhases
};
