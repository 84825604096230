import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import Timeline, {
  DateHeader,
  TimelineHeaders,
  SidebarHeader,
  TimelineMarkers
} from 'react-calendar-timeline';
import TodayMarker from './Markers/TodayMarker';
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';
import DateNav from 'views/homePlanner/DateNav';
import MilestoneItemRenderer from './Items/MilestoneItemRenderer';
import TaskItemRenderer from './Items/TaskItemRenderer';
import TaskSidebarContainer from './Sidebars/ProjectDetailTaskSidebarContainer';
import {
  MemberGroupRenderer,
  TaskGroupGroupRenderer,
  TaskPriorityGroupRenderer,
  PhaseGroupRenderer
} from './Groups';
import styled from 'styled-components';
import TimelineRowRenderer from './Rows';
import TimelineOptions from './Options';
import TimelineSortOptions from './Options/TimelineSortOptions';
import { rebuildTooltip } from 'appUtils/tooltipUtils';

import ReactTooltip from 'react-tooltip';
import {
  getZoom,
  getSelectedProjectId,
  getProjectTimelineItems,
  getSelectedProjectTimelineItemIds,
  getProjectTimelineRow,
  getUserTheme,
  getFlatPhasesAndMilestones,
  getHomeTaskObj,
  getAuthToken,
  getSelectedTeamId,
  getProjectPlannerSteps,
  getTimelineViewBy,
  getMe,
  getVisibleTimeStart,
  getVisibleTimeEnd
} from 'selectors';
import {
  setZoom,
  setVisibleDates,
  updatePhase,
  openMilestoneModal,
  fetchPhasesByProjectIds,
  fetchTasksV2,
  fetchTaskGroups,
  navigateToTaskModal,
  setSelectedTask,
  fetchCommentsAndMetadata,
  triggerTasksAttributesUpdate,
  triggerTaskStoreFlush,
  fetchProjectTaskGroups
} from 'actionCreators';
import {
  addTimezoneOffsetToDate,
  calcDayChange,
  deserializeBar,
  getVisibleTimeRange,
  getWeeksFromZoom,
  getSnappedDate,
  zoomToIntervalFormat,
  zoomToIntervalHash,
  zoomToTopIntervalFormat,
  zoomToTopIntervalHash
} from 'appUtils/projectPlannerUtils';
import { getMondayOfWeek } from 'appUtils/momentUtils';
import {
  ITEM_TYPES,
  timelineKeys,
  VIEW_BY,
  ZOOM_LEVELS,
  ZOOM_TO_SNAP_VALUES,
  ZOOM_STRINGS
} from 'appConstants/workload';
import { LEFT, BOTH } from 'appConstants/projectPlanner';
import { isPhaseArchived } from 'appUtils/phaseDisplayUtils';
import noop from 'lodash/noop';

const FORWARD = 'forward';
const BACKWARD = 'backward';

const defaultTimeStart = getMondayOfWeek(moment());
const defaultTimeEnd = getMondayOfWeek(moment()).add(7, 'day');

const StyledTooltip = styled(ReactTooltip)`
  font-weight: normal;
  text-align: center;
  max-width: 240px;
`;

const bindToDay = (date) => (date ? date.format('YYYY-MM-DD') : null);

const formatFetchDate = (date) =>
  moment(date).clone().startOf('day').format('MM/DD/YYYY');

const initialState = {
  earliestDate: moment().add(-28, 'days'),
  latestDate: moment().add(28, 'days')
};

const stubGroup = ({ group }) => <div>{group.id}</div>;

class ProjectTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.itemMoveHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseMove,
      [ITEM_TYPES.TASK]: this.handleTaskMove
    };
    this.itemResizeHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseResize,
      [ITEM_TYPES.TASK]: this.handleTaskResize
    };
    this.itemClickHandlers = {
      [ITEM_TYPES.PHASE]: this.handlePhaseClick,
      [ITEM_TYPES.TASK]: this.handleTaskClick
    };
    this.itemContextMenuHandlers = {
      // [ITEM_TYPES.PHASE]: this.handlePhaseContextMenu,
      [ITEM_TYPES.TASK]: this.handleTaskContextMenu
    };
    this.itemGetters = {
      [ITEM_TYPES.PHASE]: this.getPhaseByItemId,
      [ITEM_TYPES.TASK]: this.getTaskByItemId
    };
    this.itemRenderers = {
      [ITEM_TYPES.PHASE]: MilestoneItemRenderer,
      [ITEM_TYPES.TASK]: TaskItemRenderer
    };
    this.groupRenderers = {
      [VIEW_BY.NONE]: stubGroup,
      [VIEW_BY.MEMBERS]: MemberGroupRenderer,
      [VIEW_BY.TASK_GROUPS]: TaskGroupGroupRenderer,
      [VIEW_BY.TASK_PRIORITIES]: TaskPriorityGroupRenderer,
      [VIEW_BY.PHASES]: PhaseGroupRenderer
    };
  }

  componentDidMount() {
    const {
      triggerTaskStoreFlush,
      projectId,
      fetchPhasesByProjectIds,
      fetchTasksV2,
      fetchProjectTaskGroups
    } = this.props;
    triggerTaskStoreFlush();
    if (projectId) {
      fetchPhasesByProjectIds({ projectIds: [projectId] });
      fetchTasksV2({
        body: {
          project_ids: [projectId],
          schedule_start_start_date: formatFetchDate(this.state.earliestDate),
          schedule_start_end_date: formatFetchDate(this.state.latestDate),
          all: true
        }
      });
      fetchTasksV2({
        body: {
          project_ids: [projectId],
          scheduled_start: false,
          complete: false,
          limit: 50,
          offset: 0,
          sort: 'created_at'
        }
      });
      fetchProjectTaskGroups({ projectId });
    }
    rebuildTooltip();
  }

  componentDidUpdate(prevProps) {
    const {
      projectId,
      triggerTaskStoreFlush,
      fetchPhasesByProjectIds,
      fetchTasksV2,
      fetchProjectTaskGroups
    } = this.props;
    if (prevProps.projectId !== projectId) {
      triggerTaskStoreFlush();
      fetchPhasesByProjectIds({ projectIds: [projectId] });
      fetchTasksV2({
        body: {
          project_ids: [projectId],
          schedule_start_start_date: formatFetchDate(this.state.earliestDate),
          schedule_start_end_date: formatFetchDate(this.state.latestDate),
          all: true
        }
      });
      fetchTasksV2({
        body: {
          project_ids: [projectId],
          scheduled_start: false,
          complete: false,
          limit: 50,
          offset: 0
          // all: true
        }
      });
      fetchProjectTaskGroups({ projectId });
    }
    rebuildTooltip();
  }

  getPermissions = () => {
    const { projectId, teamId } = this.props;
    return {
      projectId,
      teamId
    };
  };

  getPhaseByItemId = (itemId) =>
    this.props.allPhasesAndMilestones.find((p) => p.id === +itemId);

  getTaskByItemId = (itemId) => this.props.taskHash[itemId];

  handleItemMove = (serializedId, calendarTime, newGroupId) => {
    ReactTooltip.hide();

    const { itemType, itemId } = deserializeBar(serializedId);
    const handleMove = this.itemMoveHandlers[itemType];
    handleMove(itemId, calendarTime, newGroupId);
    this.setState({ itemTime: null });
  };

  handleItemResize = (serializedId, calendarTime, edge) => {
    ReactTooltip.hide();

    const { itemType, itemId } = deserializeBar(serializedId);
    const handleResize = this.itemResizeHandlers[itemType];
    handleResize(itemId, calendarTime, edge);
  };

  handleItemClick = (serializedId, e, time) => {
    const adjustedTime = addTimezoneOffsetToDate(time);
    const { itemType, itemId } = deserializeBar(serializedId);
    const handleClick = this.itemClickHandlers[itemType];
    handleClick(itemId, e, adjustedTime);
  };

  handleItemDrag = (itemDragObject) => {
    this.setState({ itemTime: itemDragObject.time });
    ReactTooltip.show(this.startDateRef);
    ReactTooltip.show(this.endDateRef);
  };

  onItemContextMenu = (serializedId, e, time) => {
    const adjustedTime = addTimezoneOffsetToDate(time);
    const { itemType, itemId } = deserializeBar(serializedId);
    const handleContextMenu = this.itemContextMenuHandlers[itemType];
    if (handleContextMenu) {
      handleContextMenu(itemId, e, adjustedTime);
    }
  };

  renderLeftSidebarHeader = ({ getRootProps }) => {
    return (
      <div {...getRootProps()}>
        <div
          style={{
            background: '#fff',
            height: '100%',
            width: '100%',
            borderTop: '1px solid #d9d9d9',
            boxShadow: '2px 0px 4px rgba(0,0,0,0.05)'
          }}
          className="left-sidebar-header"
        ></div>
      </div>
    );
  };

  handleLazyLoad = (calendarTimeStart, calendarTimeEnd) => {
    const { earliestDate, latestDate } = this.state;
    const loadingEarlier = moment(calendarTimeStart).isBefore(earliestDate);
    const loadingLater = moment(latestDate).isBefore(moment(calendarTimeEnd));
    if (loadingEarlier && loadingLater) {
      this.lazyLoad({
        startDate: moment(calendarTimeStart).add(-16, 'days'),
        endDate: moment(calendarTimeEnd).add(16, 'days')
      });
      this.setState({
        earliestDate: moment(calendarTimeStart).add(-16, 'days'),
        latestDate: moment(calendarTimeEnd).add(16, 'days')
      });
    } else if (loadingEarlier) {
      const startDate = earliestDate.clone().add(-16, 'days');
      this.lazyLoad({
        startDate,
        endDate: earliestDate
      });
      this.setState({ earliestDate: startDate });
    } else if (loadingLater) {
      const endDate = latestDate.clone().add(16, 'days');
      this.lazyLoad({
        startDate: latestDate,
        endDate
      });
      this.setState({ latestDate: endDate });
    }
  };

  lazyLoad = ({ startDate, endDate }) => {
    const { fetchTasksV2, projectId } = this.props;
    fetchTasksV2({
      body: {
        project_ids: [projectId],
        schedule_start_start_date: formatFetchDate(startDate),
        schedule_start_end_date: formatFetchDate(endDate),
        all: true
      }
    });
  };

  topIntervalRenderer = ({ getIntervalProps, intervalContext }) => {
    const { interval } = intervalContext;
    const { zoom } = this.props;
    const formatInterval = zoomToTopIntervalFormat[zoom];
    return (
      <div {...getIntervalProps()} onClick={noop}>
        <div className="find-me" />
        <div
          className={cn('styled-header-date-container', {
            showBorder: zoom === ZOOM_LEVELS.DAY
          })}
        >
          {formatInterval(interval.startTime)}
        </div>
      </div>
    );
  };

  checkToday = (date) => {
    const { zoom } = this.props;
    const stepValue = zoomToIntervalHash[zoom];
    return moment(date).range(stepValue).contains(moment());
  };

  intervalRenderer = ({ getIntervalProps, intervalContext }) => {
    const { interval } = intervalContext;
    const { zoom } = this.props;
    const formatInterval = zoomToIntervalFormat[zoom];
    const stepValue = zoomToIntervalHash[zoom];

    return (
      <div {...getIntervalProps()} onClick={noop}>
        <div className="find-me" />
        <div
          className={cn('styled-header-day-container', {
            today: this.checkToday(interval.startTime),
            left: zoom === ZOOM_LEVELS.DAY || zoom === ZOOM_LEVELS.WEEK,
            singleDay: stepValue === ZOOM_STRINGS.DAY
          })}
        >
          {formatInterval(interval.startTime)}
        </div>
      </div>
    );
  };

  handleTimelineScroll = (
    visibleTimeStart,
    visibleTimeEnd,
    updateScrollCanvas
  ) => {
    this.props.setVisibleDates({
      visibleTimeStart: moment(visibleTimeStart),
      visibleTimeEnd: moment(visibleTimeEnd),
      plannerType: this.props.plannerType
    });
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    // fixes issue where header and scroll body don't match up (can happen after scrolling fast horizontally)
    const scrollRefScrollLeft = this.scrollRef?.scrollLeft;
    const headerRefScrollLeft = this.headerRef?.scrollLeft;
    if (
      this.scrollRef &&
      this.headerRef &&
      scrollRefScrollLeft !== headerRefScrollLeft
    ) {
      this.scrollRef.scrollLeft = headerRefScrollLeft;
    }
  };

  setScrollRef = (ref) => (this.scrollRef = ref);
  setHeaderRef = (ref) => (this.headerRef = ref);

  isTodayOnScreen = () => {
    const { visibleTimeStart, visibleTimeEnd } = this.props;
    const today = moment();
    return (
      today.isAfter(visibleTimeStart, 'd') &&
      today.isBefore(visibleTimeEnd, 'd')
    );
  };

  scrollPlannerHalfTimelineWidth = (direction) => {
    const timeline = this.timelineContainer;
    timeline.classList.add('scroll-transition');
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(
      () => timeline.classList.remove('scroll-transition'),
      1500
    );

    const { visibleTimeStart, visibleTimeEnd, setVisibleDates, zoom } =
      this.props;

    const weeks = getWeeksFromZoom(zoom) / 2;
    const operation = direction === FORWARD ? 'add' : 'subtract';

    if (zoom === ZOOM_LEVELS.DAY) {
      setVisibleDates({
        visibleTimeStart: visibleTimeStart.clone()[operation](2, 'day'), // +- 2 days on Day zoom level
        visibleTimeEnd: visibleTimeEnd.clone()[operation](2, 'day'),
        plannerType: this.props.plannerType
      });
      return;
    }

    setVisibleDates({
      visibleTimeStart: visibleTimeStart.clone()[operation](weeks, 'week'),
      visibleTimeEnd: visibleTimeEnd.clone()[operation](weeks, 'week'),
      plannerType: this.props.plannerType
    });
  };

  scrollToToday = () => {
    const { zoom, setVisibleDates } = this.props;
    this.timelineContainer.classList.remove('scroll-transition');
    const today = moment().startOf('day');

    const { visibleTimeStart, visibleTimeEnd } = getVisibleTimeRange({
      zoom,
      timeStart: today
    });

    setVisibleDates({
      visibleTimeStart,
      visibleTimeEnd,
      plannerType: this.props.plannerType
    });
  };

  headerRange = () => getWeeksFromZoom(this.props.zoom) / 2;

  setZoom = (zoom) => {
    const {
      visibleTimeStart: timeStart,
      setVisibleDates,
      setZoom,
      plannerType
    } = this.props;
    setZoom({ zoom, plannerType });
    const { visibleTimeStart, visibleTimeEnd } = getVisibleTimeRange({
      zoom,
      timeStart
    });

    setVisibleDates({
      visibleTimeStart,
      visibleTimeEnd,
      plannerType
    });
  };

  itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps
  }) => {
    const ItemRenderer = this.itemRenderers[deserializeBar(item.id)?.itemType];
    return (
      <ItemRenderer
        item={item}
        itemContext={itemContext}
        getItemProps={getItemProps}
        getResizeProps={getResizeProps}
        userTheme={this.props.userTheme}
        timelineContext={timelineContext}
        me={this.props.me}
        zoom={this.props.zoom}
      />
    );
  };

  groupRenderer = ({ group }) => {
    const { viewBy } = this.props;
    const GroupRenderer = this.groupRenderers[viewBy];
    return <GroupRenderer group={group} />;
  };

  handleItemMove = (serializedId, calendarTime, newGroupId) => {
    const { itemType, itemId } = deserializeBar(serializedId);
    const handleMove = this.itemMoveHandlers[itemType];
    handleMove(itemId, calendarTime, newGroupId);
    this.setState({ itemTime: null });
  };

  handlePhaseMove = (itemId, calendarTime) => {
    const { updatePhase } = this.props;
    const phase = this.getPhaseByItemId(itemId);
    if (isPhaseArchived(phase)) return;
    const diffInDays = calcDayChange(calendarTime, phase.start_date);
    const newStartDate = moment(phase.start_date)
      .add(diffInDays, 'days')
      .format('MM/DD/YYYY');

    const newEndDate = moment(phase.end_date)
      .add(diffInDays, 'days')
      .format('MM/DD/YYYY');

    updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      startDate: newStartDate,
      endDate: newEndDate,
      name: phase.name
    });
  };

  handleTaskMove = (itemId, calendarTime, newGroupId) => {
    const { triggerTasksAttributesUpdate, token } = this.props;
    const task = this.getTaskByItemId(itemId);
    const { itemGroupKey } = this.getKeys();
    const oldGroupId = task[itemGroupKey];
    const diffInDays = calcDayChange(
      bindToDay(moment(calendarTime)),
      task.schedule_start
    );
    const newStartDate = moment(task.schedule_start).add(diffInDays, 'days');

    const newEndDate = moment(
      task.schedule_end || moment(task.schedule_start).add(2, 'days')
    ).add(diffInDays, 'days');

    const permissions = this.getPermissions();

    const body = {
      task_ids: [task.id],
      schedule_start: newStartDate,
      schedule_end: newEndDate
    };
    if (+oldGroupId !== +newGroupId) {
      body[itemGroupKey] = +newGroupId;
    }
    triggerTasksAttributesUpdate({ token, body, permissions });
  };

  handlePhaseResize = (itemId, calendarTime, edge) => {
    const { updatePhase } = this.props;
    const phase = this.getPhaseByItemId(itemId);
    if (isPhaseArchived(phase)) return;
    const propertyToUpdate = edge === LEFT ? 'startDate' : 'endDate';
    const buffer = edge === LEFT ? 1 : -1;
    updatePhase({
      id: phase.id,
      projectId: phase.project_id,
      startDate: phase.start_date,
      endDate: phase.end_date,
      name: phase.name,
      [propertyToUpdate]: moment(calendarTime)
        .add(buffer, 'minute')
        .format('MM/DD/YYYY')
    });
  };

  handleTaskResize = (itemId, calendarTime, edge) => {
    const { triggerTasksAttributesUpdate, token } = this.props;
    const task = this.getTaskByItemId(itemId);
    const propertyToUpdate = edge === LEFT ? 'schedule_start' : 'schedule_end';
    const propertyToPreserve =
      edge === LEFT ? 'schedule_end' : 'schedule_start';
    const buffer = edge === LEFT ? 1 : -1;
    const body = {
      task_ids: [task.id],
      [propertyToUpdate]: bindToDay(moment(calendarTime).add(buffer, 'minute')),
      [propertyToPreserve]: task[propertyToPreserve]
    };
    if (moment(body.schedule_end).isBefore(moment(body.schedule_start))) {
      return;
    }

    const permissions = this.getPermissions();
    triggerTasksAttributesUpdate({
      token,
      body,
      permissions
    });
  };

  handlePhaseClick = () => {
    const { fetchPhasesByProjectIds, projectId, openMilestoneModal } =
      this.props;
    fetchPhasesByProjectIds({ projectIds: [projectId] });
    openMilestoneModal();
  };

  handleTaskClick = (itemId) => {
    const {
      fetchTaskGroups,
      navigateToTaskModal,
      setSelectedTask,
      fetchCommentsAndMetadata
    } = this.props;
    const task = this.getTaskByItemId(itemId);
    fetchTaskGroups({ taskGroupIds: [task.task_group_id] });
    navigateToTaskModal({
      taskId: task.id
    });
    setSelectedTask(task.id);
    fetchCommentsAndMetadata({
      taskId: task.id,
      taskType: 'projects',
      offset: 0,
      limit: 4
    });
  };

  rowRenderer = ({ getLayerRootProps, group, rowData }) => {
    const { steps, zoom, viewBy } = this.props;
    return (
      <TimelineRowRenderer
        getLayerRootProps={getLayerRootProps}
        group={group}
        rowData={rowData}
        steps={steps}
        zoom={zoom}
        viewBy={viewBy}
      />
    );
  };

  getKeys = () => timelineKeys[this.props.viewBy];

  validateMoveResize = (...props) => getSnappedDate(this.props.zoom, ...props);

  render() {
    const {
      visibleTimeStart,
      visibleTimeEnd,
      zoom,
      timelineItems,
      timelineRow,
      selectedTimelineItemIds,
      viewBy
    } = this.props;

    const preventZoom = visibleTimeEnd.valueOf() - visibleTimeStart.valueOf();
    return (
      <div
        ref={(ref) => (this.timelineContainer = ref)}
        className={cn(
          'timeline-container project-planner-timeline-container project-view',
          { 'quarterly-view': zoomToTopIntervalHash[zoom] === 'month' },
          { 'show-horizontal-lines': viewBy !== VIEW_BY.NONE }
        )}
      >
        <StyledTooltip id="task-bar" place="top" multiline={true} />

        <DateNav
          scrollBack={() => this.scrollPlannerHalfTimelineWidth(BACKWARD)}
          scrollForward={() => this.scrollPlannerHalfTimelineWidth(FORWARD)}
          scrollToToday={() => this.scrollToToday()}
          header={[visibleTimeStart, visibleTimeEnd]}
          isTodayOnScreen={this.isTodayOnScreen()}
          showCondensedOption={false}
          showZoom
          zoom={zoom}
          setZoom={this.setZoom}
          showButton
          shouldUsePassedSetZoom
          buttonClick={this.handlePhaseClick}
        >
          <TimelineOptions onButtonClick={this.handlePhaseClick}>
            <TaskSidebarContainer />
          </TimelineOptions>
          <TimelineSortOptions />
        </DateNav>
        <Timeline
          resizeDetector={containerResizeDetector}
          onBoundsChange={this.handleLazyLoad}
          visibleTimeStart={visibleTimeStart.valueOf()}
          visibleTimeEnd={visibleTimeEnd.valueOf()}
          defaultTimeStart={defaultTimeStart}
          defaultTimeEnd={defaultTimeEnd}
          items={timelineItems}
          groups={timelineRow}
          keys={this.getKeys()}
          stackItems
          itemRenderer={this.itemRenderer}
          groupRenderer={this.groupRenderer}
          canResize={BOTH}
          dragSnap={60 * 60 * 1000 * 24 * ZOOM_TO_SNAP_VALUES[zoom]}
          lineHeight={60}
          itemHeightRatio={44 / 60}
          onTimeChange={this.handleTimelineScroll}
          minZoom={preventZoom}
          maxZoom={preventZoom}
          scrollRef={this.setScrollRef}
          headerRef={this.setHeaderRef}
          clickTolerance={5}
          selected={selectedTimelineItemIds}
          onItemMove={this.handleItemMove}
          onItemResize={this.handleItemResize}
          onItemClick={this.handleItemClick}
          sidebarWidth={viewBy === VIEW_BY.NONE ? 0 : 225}
          rowRenderer={this.rowRenderer}
          moveResizeValidator={this.validateMoveResize}
        >
          <TimelineMarkers>
            <TodayMarker />
          </TimelineMarkers>
          <TimelineHeaders>
            <SidebarHeader>{this.renderLeftSidebarHeader}</SidebarHeader>
            <DateHeader
              height={30}
              unit={zoomToTopIntervalHash[zoom]}
              intervalRenderer={this.topIntervalRenderer}
            />
            <DateHeader
              height={29}
              unit={zoomToIntervalHash[zoom]}
              intervalRenderer={this.intervalRenderer}
            />
          </TimelineHeaders>
        </Timeline>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  visibleTimeStart: getVisibleTimeStart(state, ownProps),
  visibleTimeEnd: getVisibleTimeEnd(state, ownProps),
  zoom: getZoom(state, ownProps),
  projectId: getSelectedProjectId(state),
  timelineItems: getProjectTimelineItems(state, ownProps),
  selectedTimelineItemIds: getSelectedProjectTimelineItemIds(state, ownProps),
  timelineRow: getProjectTimelineRow(state),
  userTheme: getUserTheme(state),
  milestones: state.milestones.milestonesHash,
  allPhasesAndMilestones: getFlatPhasesAndMilestones(state),
  taskHash: getHomeTaskObj(state),
  token: getAuthToken(state),
  teamId: getSelectedTeamId(state),
  steps: getProjectPlannerSteps(state, ownProps),
  viewBy: getTimelineViewBy(state),
  me: getMe(state)
});
const mapDispatchToProps = {
  setZoom,
  setVisibleDates,
  updatePhase,
  fetchPhasesByProjectIds,
  openMilestoneModal,
  fetchTasksV2,
  fetchTaskGroups,
  navigateToTaskModal,
  setSelectedTask,
  fetchCommentsAndMetadata,
  triggerTaskStoreFlush,
  triggerTasksAttributesUpdate,
  fetchProjectTaskGroups
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTimeline);
