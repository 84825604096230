import React from 'react';

import { connect } from 'react-redux';
import {
  updateProfile,
  signupUser,
  fetchUser,
  navigateToHome
} from 'actionCreators';
import { OnboardForm } from '..';
import logo from 'images/mosaic-logo.png';
import close from 'images/close-to-login.svg';
import { getAuth, getUsers, getMe } from 'selectors';

export class OnboardContainer extends React.Component {
  render() {
    const {
      history,
      updateProfile,
      signupUser,
      fetchUser,
      users,
      auth,
      navigateToHome
    } = this.props;

    return (
      <div className="auth-page">
        <img src={logo} className="login-logo" />
        <img
          src={close}
          className="close-button"
          onClick={() => history.push('/login')}
        />
        <OnboardForm
          profileSet={updateProfile}
          inviteMembers={signupUser}
          fetchUser={fetchUser}
          redirectToLogin={() => history.push('/login')}
          redirectToHome={() => navigateToHome({ userSlug: auth.account.slug })}
          users={users}
          auth={auth}
        />
      </div>
    );
  }
}
const redirectRoute = '/';
const mapStateToProps = (state) => ({
  redirectRoute,
  auth: getAuth(state),
  invite: state.invite,
  users: getUsers(state),
  me: getMe(state)
});

const mapDispatchToProps = {
  updateProfile,
  signupUser,
  fetchUser,
  navigateToHome
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardContainer);
