import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getIntegrationsModule } from 'IntegrationsModule/package/integrationsModule';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import withNavigation from 'hocs/withNavigation';
import {
  getNavViewText,
  getOnBoardView,
  getOnProjectDetail,
  getOnHomeView,
  getSelectedTeamMember,
  getIsOnTeamProject,
  getFromBoardViewText,
  getSelectedProject,
  getUserIsGuest,
  getTeamNameFromSelectedBoard,
  getNotesSelectedAccountIds,
  getNotesSelectedTagIds,
  getAuthToken,
  getAuth,
  getMe,
  getSearch,
  getSettings,
  getBilling,
  getOnProfile,
  getNavigationHistory,
  getMatchedRouteParams,
  getIsOnProjectView,
  getSelectedTeamId,
  // getRecurlyIsLoaded,
  getTeamsState,
  getIsPreRecurlyTeam,
  getIsOfflineMode
} from 'selectors';
import { fetchIntegrations } from 'IntegrationsModule/actionCreators';
import FileDownloader from './FileDownloader';
import WAYPOINT_INTS from 'appConstants/waypointInts';
import {
  isOnProjectPlanner,
  isOnHomePlanner,
  isOnHomeTasks
} from 'appUtils/views';
import PageMenuContainer from '../menuContainer/PageMenuContainer';
import {
  fetchAccountFilters,
  fetchActivities,
  fetchNotifications,
  fetchGroups,
  fetchMyProjects,
  fetchAllProjects,
  fetchProjectsByGroup,
  fetchCurrentSubscription,
  logoutUser,
  onClearSearch,
  onFocusSearch,
  onSearch,
  onGlobalSearch,
  openAddEditGroupModal,
  logoutTrialExpiry,
  useSearchState,
  saveSearchState,
  clearSelectedProject,
  handleProjectItemState,
  toggleSearchResults,
  fetchCachedProjects,
  toggleSidebarSearch,
  toggleSidebarProjects,
  updateTeam,
  fetchTeams,
  openAddProjectModal,
  fetchProjectColorPreferences,
  fetchBoardPreferences,
  fetchTaskGroupPreferences,
  fetchTeamMembershipPreferences,
  fetchTeamPreferences,
  fetchTagPreferences,
  triggerFetchInitialTasks,
  fetchUserPermissions,
  closeSidebarSearch,
  navigateToSettings,
  navigateToActivityFeed,
  triggerTaskStoreFlush,
  fetchMemberProjects,
  fetchTaskPriorities,
  tryStartOnlineMode
} from 'actionCreators';
import noteActionCreators from 'NoteModule/actionCreators';
import { fetchEntityOptions } from 'EntityOptionsModule/actionCreators';
import { Navbar, ProjectsSidebar } from 'views';
import { ENTITY_TYPES } from 'EntityOptionsModule/constants';
const { fetchNotesByProject } = noteActionCreators;

const SideGrip = styled.div`
  position: absolute;
  width: 3px;
  height: 50px;
  left: 8px;
  top: calc(40vh - 50px);

  background: #cfcfcf;
  border-radius: 100px;
`;

class NavbarContainer extends React.PureComponent {
  state = {
    isOpenBoardMenu: false,
    isMenuOpenedByHover: false,
    isOpenUserMenu: false,
    isOpenHelpSubmenu: false,
    isEditing: false,
    searchText: '',
    searched: false,
    memberListOpen: true
  };

  componentDidMount() {
    const {
      fetchMyProjects,
      fetchAllProjects,
      fetchProjectColorPreferences,
      fetchBoardPreferences,
      fetchTaskGroupPreferences,
      fetchTeamMembershipPreferences,
      fetchTeamPreferences,
      fetchTagPreferences,
      authToken,
      fetchTeams,
      fetchUserPermissions,
      fetchMemberProjects,
      auth,
      fetchTaskPriorities,
      fetchActivities,
      teamId,
      fetchAccountFilters,
      fetchIntegrations,
      dont,
      fetchEntityOptions
    } = this.props;
    fetchUserPermissions();
    this.fetchGroups();

    fetchTeams();
    if (!dont.fetchCurrentSubscription) this.fetchCurrentSubscription();
    if (!dont.fetchProjectColorPreferences) fetchProjectColorPreferences();
    if (!dont.fetchBoardPreferences) fetchBoardPreferences();
    if (!dont.fetchTaskGroupPreferences) fetchTaskGroupPreferences();
    if (!dont.fetchTeamMembershipPreferences) fetchTeamMembershipPreferences();
    if (!dont.fetchTeamPreferences) fetchTeamPreferences();
    if (!dont.fetchTagPreferences) fetchTagPreferences();

    if (!dont.fetchMemberProjects)
      fetchMemberProjects({ account_id: auth.account && auth.account.id });
    if (!dont.fetchMyProjects)
      fetchMyProjects(authToken, {
        offset: 0,
        limit: WAYPOINT_INTS.myProjects.firstLoadBaseLimit
      });
    if (!dont.fetchAllProjects)
      fetchAllProjects({
        offset: 0,
        is_administrative: true
      });
    if (!dont.fetchAllProjects)
      fetchAllProjects({
        offset: 0,
        limit: WAYPOINT_INTS.myProjects.firstLoadBaseLimit
      });
    if (!dont.fetchTaskPriorities) fetchTaskPriorities({});
    if (!dont.fetchActivities) fetchActivities({});
    if (teamId) {
      fetchAccountFilters();
      fetchEntityOptions({
        team_id: teamId,
        entity_id: null,
        entity_type: ENTITY_TYPES.project
      });
      if (!dont.fetchIntegrations) {
        fetchIntegrations();
        // Small delay for after QBO provisions - will be fixed later with websockets
        setTimeout(() => {
          fetchIntegrations();
        }, 1000);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      boardSearchText,
      homeSearchText,
      shouldUseBoard,
      shouldUseHome,
      isOnBoardView,
      isOnHomeView,
      location: { pathname },
      clearSearch
    } = this.props;

    const goingToBoard =
      !nextProps.isOnTaskView &&
      !nextProps.isOnNotesView &&
      nextProps.isOnBoardView;

    const returnToHomeAfterSearch =
      !pathname.includes('home') &&
      nextProps.location.pathname.includes('home') &&
      shouldUseHome &&
      homeSearchText;

    const returnToBoardAfterSearch =
      goingToBoard && shouldUseBoard && boardSearchText;

    if (
      (nextProps.clearSearch && !clearSearch) ||
      (pathname !== nextProps.location.pathname &&
        !nextProps.location.pathname.includes('/home/'))
    ) {
      this.clearSearch(true);
      this.props.closeSidebarSearch();
    }

    if (returnToBoardAfterSearch) {
      this.setState({ searchText: boardSearchText });
      saveSearchState({ home: '', board: '' });
      useSearchState({ home: false, board: false });
    }

    if (
      homeSearchText &&
      shouldUseHome &&
      !isOnHomeView &&
      nextProps.isOnHomeView
    ) {
      this.setState({ searchText: homeSearchText });
      saveSearchState({ home: '', board: '' });
      useSearchState({ home: false, board: false });
    }

    if (
      isOnBoardView &&
      nextProps.isOnHomeView &&
      boardSearchText &&
      shouldUseBoard
    ) {
      this.setState({ searchText: '' });
      saveSearchState({ home: '', board: '' });
      useSearchState({ home: false, board: false });
    }

    if (returnToHomeAfterSearch || returnToBoardAfterSearch) {
      this.setState({ searched: true });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      teamId,
      fetchIntegrations,
      fetchAccountFilters,
      fetchEntityOptions,
      dont
    } = this.props;
    if (prevProps.teamId !== teamId) {
      if (teamId && !dont.fetchIntegrations) {
        fetchIntegrations();
        // Small delay for after QBO provisions - will be fixed later with websockets
        setTimeout(() => {
          fetchIntegrations();
        }, 1000);
      }
      if (teamId) {
        fetchAccountFilters();
        fetchEntityOptions({
          team_id: teamId,
          entity_id: null,
          entity_type: ENTITY_TYPES.project
        });
      }
    }
  }

  // *********** Search functions ************* //

  clearSearch = (noTaskFetch) => {
    this.setState({
      searchText: ''
    });
    this.onClearSearch(noTaskFetch);
  };

  undebouncedOnSearch = (searchText) => {
    const {
      projectIds,
      noteIds,
      tagIds,
      isOnTaskView,
      isOnNotesView,
      groups,
      triggerFetchInitialTasks,
      onSearch,
      isOnHomeView,
      isOnBoardView,
      isActive,
      matchedParams: { projectId },
      isOnProjectView,
      triggerTaskStoreFlush
    } = this.props;

    if (this.state.searchText.length > 1) {
      this.setState({ searched: true });

      onSearch(this.state.searchText);
      this.onGlobalSearch(this.state.searchText);

      if (isOnHomeView) {
        triggerFetchInitialTasks({});
      } else if (isOnNotesView) {
        this.fetchNotesByProject({
          projectId,
          searchText,
          tagIds,
          accountIds: noteIds
        });
      } else if (isOnTaskView) {
        triggerTaskStoreFlush();
        !isOnProjectView && triggerFetchInitialTasks({});
      } else if (isOnBoardView) {
        // must be on board view
        this.fetchProjectsByGroup({
          groupId: groups.selectedGroupID,
          searchText,
          isActive,
          accountIds: projectIds
        });
      }
    }
  };

  onSearch = debounce(this.undebouncedOnSearch, 500);

  onClearSearch = (noTaskFetch) => {
    const { onClearSearch, saveSearchState, useSearchState } = this.props;

    this.setState({ searched: false });
    saveSearchState({ home: '', board: '' });
    useSearchState({ home: false, board: false });

    onClearSearch('');

    if (!noTaskFetch) {
      this.refetchData();
    }
  };

  checkForSearch = () => {
    const {
      search,
      homeSearchText,
      shouldUseHome,
      boardSearchText,
      shouldUseBoard
    } = this.props;
    const searchText = search.searchText;
    const loadHomeSearch = homeSearchText && shouldUseHome;
    const loadBoardSearch = boardSearchText && shouldUseBoard;

    if (!searchText && loadHomeSearch) {
      this.setState({ searchText: homeSearchText });
      this.onSearch(homeSearchText);
    } else if (!searchText && loadBoardSearch) {
      this.setState({ searchText: boardSearchText });
      this.onSearch(boardSearchText);
    }
  };

  // ************ KEYBOARD functions ***************** //

  onKeyUp = (e) => {
    if (e.keyCode === 13) {
      return;
      /*
      enter keeps the search debounce alive when it should be executing,
      offers no additional functionality
      and causes bugs where some reducers are flushed but do not refetch
      */
    }
    if (
      (e.keyCode === 91 || e.keyCode === 92 || e.keyCode === 8) &&
      this.state.searchText.length === 0
    ) {
      this.setState({ searchText: '' });
      this.onClearSearch();

      // ensures searchState cleared on rapid deletion
      setTimeout(() => {
        this.setState({ searchText: '' });
        this.onClearSearch();
      }, 50);
    } else if (this.state.searchText.length > 1) {
      setTimeout(() => this.onSearch(this.state.searchText), 350);
    }
  };

  onChange = (e) => {
    this.setState({
      searchText: e.target.value
    });
  };

  // ************ TOGGLE functions ***************** //

  toggleIsOpenBoardMenu = (params) => {
    const { isOpen } = params;
    this.setState((prevState) => ({
      isOpenBoardMenu:
        isOpen === undefined ? !prevState.isOpenBoardMenu : isOpen,
      isMenuOpenedByHover: false,
      isEditing: false
    }));
  };

  setHoveredOpen = (isOpen) => {
    this.setState(() => ({
      isMenuOpenedByHover: isOpen,
      isOpenBoardMenu: false,
      isEditing: false
    }));
  };

  setIsOpenBoardMenu = (isOpen) => {
    this.setState(() => ({
      isOpenBoardMenu: isOpen,
      isEditing: false
    }));
  };

  toggleEditing = () => {
    this.setState((prevState) => ({
      isEditing: !prevState.isEditing
    }));
  };

  toggleIsOpenUserMenu = () => {
    this.setState((prevState) => ({
      isOpenUserMenu: !prevState.isOpenUserMenu,
      isOpenHelpSubmenu: false
    }));
  };

  toggleViewAllMembers = () => {
    this.setState((prevState) => ({
      memberListOpen: !prevState.memberListOpen
    }));
  };

  // ************ UTILITY functions ***************** //

  openAddEditGroupModal = (isEdit, id) => {
    this.props.openAddEditGroupModal({ isEdit, id });
    this.toggleIsOpenBoardMenu({ isOpen: false });
  };

  // ************ NAVIGATION functions ***************** //

  logout = () => {
    this.props.logoutUser();
    // window.Intercom('shutdown');
  };

  navHome = () => {
    const {
      onClearSearch,
      toggleSidebarProjects,
      toggleSidebarSearch,
      navToHome, // from withNavigation
      isOfflineMode,
      tryStartOnlineMode
    } = this.props;

    if (isOfflineMode) {
      tryStartOnlineMode();
    }
    onClearSearch('');
    toggleSidebarSearch();
    toggleSidebarProjects(null);
    this.setState({ searchText: '' });

    navToHome();
  };

  fetchGroups = () => this.props.fetchGroups(this.props.authToken);
  fetchProjectsByGroup = (params) => this.props.fetchProjectsByGroup(params);
  fetchNotesByProject = (params) => this.props.fetchNotesByProject(params);
  fetchCurrentSubscription = () =>
    this.props.fetchCurrentSubscription(this.props.authToken);

  onGlobalSearch = (searchText) =>
    this.props.onGlobalSearch(this.props.authToken, searchText);

  startTrial = (team) =>
    this.props.startTrial(this.props.authToken, team, null, null, true);

  navActivity = () => {
    const {
      me,
      loadedNotificationsCount,
      fetchNotifications,
      navigateToActivityFeed
    } = this.props;
    if (!loadedNotificationsCount) {
      fetchNotifications({
        offset: 0,
        limit: WAYPOINT_INTS.notifications.baseLimit,
        user_id: me.id
      });
    }
    navigateToActivityFeed({ userSlug: me.slug });
  };

  goToSettings = () => this.props.navigateToSettings();

  // ************ RENDER function ***************** //

  renderHoverListener = () => {
    const { isMenuOpenedByHover, isOpenBoardMenu } = this.state;

    const menuWidth = isOpenBoardMenu ? '300px' : '100px'; // Leaving 70px buffer between the edge of the menu and hover listener
    const ListenForHoverOpen = (
      <div
        style={{
          position: 'fixed',
          height: '100vh',
          width: '10px',
          zIndex: 1000000
        }}
        onMouseEnter={() => this.setHoveredOpen(true)}
      >
        <SideGrip />
      </div>
    );

    const ListenForHoverClose = (
      <div
        style={{
          position: 'fixed',
          top: '0px',
          height: '100vh',
          width: '100vw',
          marginLeft: menuWidth,
          zIndex: 1000000
        }}
        onMouseEnter={() => this.setHoveredOpen(false)}
      />
    );

    return isMenuOpenedByHover || isOpenBoardMenu
      ? ListenForHoverClose
      : ListenForHoverOpen;
  };

  openAddProjectModal = () =>
    this.props.openAddProjectModal({ priorityId: this.props.isActive });

  openAddPersonalProjectModal = (e) => {
    e.stopPropagation();
    this.props.openAddProjectModal({
      priorityId: this.props.isActive,
      isPersonal: true
    });
  };

  refetchData = () => {
    const {
      projectIds,
      noteIds,
      tagIds,
      isOnTaskView,
      isOnNotesView,
      groups,
      isOnHomeView,
      isActive,
      fetchCachedProjects,
      filteringByMembers,
      triggerFetchInitialTasks,
      matchedParams: { projectId },
      triggerTaskStoreFlush,
      isOnProjectView
    } = this.props;
    if (isOnHomeView) {
      triggerFetchInitialTasks({});
    } else if (isOnNotesView) {
      this.fetchNotesByProject({
        projectId,
        searchText: '',
        tagIds,
        accountIds: noteIds
      });
    } else if (isOnTaskView) {
      triggerTaskStoreFlush();
      !isOnProjectView && triggerFetchInitialTasks({});
    } else if (filteringByMembers) {
      // cached projects often != projects you would get by only doing a member filter
      // force fetch, so list after clearing search == list after doing member filter on original list
      this.fetchProjectsByGroup({
        groupId: groups.selectedGroupID,
        searchText: '',
        isActive,
        accountIds: projectIds
      });
    } else {
      fetchCachedProjects();
    }
  };

  render() {
    const {
      me,
      groups,
      openSettings,
      isOnProjectDetail,
      isOnSettingsView,
      toggleSearchResults,
      toggleSidebarSearch,
      toggleSidebarProjects,
      sidebarSearchOpen,
      unreadNotificationsCount,
      isOnActivityFeed,
      navViewText,
      navigationHistory,
      isOnTeamProject,
      homeTaskModalIsOpen,
      selectedProject,
      teamName,
      navToView,
      isOnTeamSettingsView
    } = this.props;

    const isOnPersonalProject = selectedProject && selectedProject.is_personal;
    const isMenuOpen = this.state.isOpenBoardMenu;
    const isSidebarMenuOpen = this.state.isMenuOpenedByHover;
    return (
      <DynamicModuleLoader modules={[getIntegrationsModule()]}>
        {me ? (
          <div
            className={cn('', {
              'is-home':
                isOnHomeTasks(navigationHistory) ||
                isOnHomePlanner(navigationHistory) ||
                isOnPersonalProject,
              'is-activity-feed': isOnActivityFeed
            })}
          >
            <FileDownloader />
            <Navbar
              homeTaskModalIsOpen={homeTaskModalIsOpen}
              clearSearch={this.clearSearch}
              isOnProjectDetail={isOnProjectDetail}
              isOnProjectPlanner={isOnProjectPlanner(navigationHistory)}
              isOpenHelpSubmenu={this.state.isOpenHelpSubmenu}
              isOpenUserMenu={this.state.isOpenUserMenu}
              me={me}
              navActivity={this.navActivity}
              onFocusSearch={this.props.onFocusSearch}
              onKeyUp={this.onKeyUp}
              onSearch={this.onSearch}
              openSettings={openSettings}
              searched={this.state.searched}
              searchText={this.state.searchText}
              toggleSearchResults={toggleSearchResults}
              userFullName={me.name}
              isOnSettingsView={isOnSettingsView}
              isOnTeamSettingsView={isOnTeamSettingsView}
              onChange={this.onChange}
              navToView={navToView}
              navViewText={navViewText}
              checkForSearch={this.checkForSearch}
              sidebarSearchOpen={sidebarSearchOpen}
              toggleSidebarSearch={toggleSidebarSearch}
              toggleSidebarProjects={toggleSidebarProjects}
              unreadNotificationsCount={unreadNotificationsCount}
              isOnTeamProject={isOnTeamProject}
              teamName={teamName}
            />
            <PageMenuContainer
              groups={groups}
              isEditing={this.state.isEditing}
              isOpenBoardMenu={isMenuOpen}
              isSidebarMenuOpen={isSidebarMenuOpen}
              openAddEditGroupModal={this.openAddEditGroupModal}
              openEditGroupModal={this.openEditGroupModal}
              toggleEditing={this.toggleEditing}
              toggleIsOpenBoardMenu={this.toggleIsOpenBoardMenu}
              setIsOpenBoardMenu={this.setIsOpenBoardMenu}
              memberListOpen={this.state.memberListOpen}
              toggleViewAllMembers={this.toggleViewAllMembers}
              goToSettings={this.goToSettings}
            />
            {/* {this.renderHoverListener()} Commenting out the code instead of removing in case we wanted to go back to the prev experience */}
            <div className="home-side">
              <ProjectsSidebar />
            </div>
          </div>
        ) : null}
      </DynamicModuleLoader>
    );
  }
}

NavbarContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  groups: PropTypes.object.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  openAddEditGroupModal: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object
  // eslint-disable-next-line react/forbid-prop-types
};

const mapStateToProps = (state) => {
  const matchedParams = getMatchedRouteParams(state);
  return {
    authToken: getAuthToken(state),
    auth: getAuth(state),
    me: getMe(state),
    search: getSearch(state),
    isOnProjectDetail: getOnProjectDetail(state),
    isOnTeamProject: getIsOnTeamProject(state),
    isOnTaskView:
      state.router.location &&
      state.router.location.pathname.indexOf('tasks') > -1,
    isOnNotesView:
      state.router.location &&
      state.router.location.pathname.indexOf('notes') > -1,
    isOnHomeView: getOnHomeView(state),
    isOnBoardView: getOnBoardView(state),
    isOnSettingsView:
      state.router.location &&
      state.router.location.pathname.indexOf('settings') > -1,
    groups: state.groups,
    navViewText: getNavViewText(state),
    fromBoardViewText: getFromBoardViewText(state),
    settings: getSettings(state),
    billing: getBilling(state),
    projectIds: state.projects.selectedAccountIds,
    filteringByMembers: state.projects.filteringByMembers,
    noteIds: getNotesSelectedAccountIds(state),
    tagIds: getNotesSelectedTagIds(state),
    isActive: state.projects.isActive,
    boardSearchText: state.search.savedSearchTexts.board,
    homeSearchText: state.search.savedSearchTexts.home,
    shouldUseBoard: state.search.shouldUse.board,
    shouldUseHome: state.search.shouldUse.home,
    projectsIsActive: state.projects.isActive,
    selectedProject: getSelectedProject(state),
    searching: state.search.isGlobalSearching,
    showResults: state.search.showResultsPane,
    clearSearch: state.search.clearSearch,
    sidebarSearchOpen: state.search.sidebarSearchOpen,
    isOnProfile: getOnProfile(state),
    isOnActivityFeed: state.router.location.pathname.includes('activity-feed'),
    selectedTeamMember: getSelectedTeamMember(state),
    unreadNotificationsCount: state.notifications.unreadCount,
    loadedNotificationsCount: state.notifications.loadedCount,
    homeTaskModalIsOpen: !!state.homeTasks.selectedTask,
    userIsGuest: getUserIsGuest(state),
    teamName: getTeamNameFromSelectedBoard(state),
    navigationHistory: getNavigationHistory(state),
    isOnProjectView: getIsOnProjectView(state),
    matchedParams,
    teamId: getSelectedTeamId(state),
    teams: getTeamsState(state),
    // recurlyIsLoaded: getRecurlyIsLoaded(state),
    teamIsPreRecurly: getIsPreRecurlyTeam(state),
    isOfflineMode: getIsOfflineMode(state),
    dont: state.dev.dont.NavbarContainer
  };
};

const mapDispatchToProps = {
  fetchActivities,
  fetchNotifications,
  fetchGroups,
  logoutUser,
  fetchProjectsByGroup,
  onClearSearch,
  onFocusSearch,
  onSearch,
  onGlobalSearch,
  fetchCurrentSubscription,
  fetchNotesByProject,
  fetchMyProjects,
  fetchAllProjects,
  openAddEditGroupModal,
  logoutTrialExpiry,
  useSearchState,
  saveSearchState,
  clearSelectedProject,
  handleProjectItemState,
  toggleSearchResults,
  fetchCachedProjects,
  toggleSidebarSearch,
  toggleSidebarProjects,
  updateTeam,
  fetchTeams,
  openAddProjectModal,
  fetchProjectColorPreferences,
  fetchBoardPreferences,
  fetchTaskGroupPreferences,
  fetchTeamMembershipPreferences,
  fetchTeamPreferences,
  fetchTagPreferences,
  triggerFetchInitialTasks,
  fetchUserPermissions,
  closeSidebarSearch,
  navigateToSettings,
  navigateToActivityFeed,
  triggerTaskStoreFlush,
  fetchMemberProjects,
  fetchTaskPriorities,
  fetchIntegrations,
  fetchAccountFilters,
  tryStartOnlineMode,
  fetchEntityOptions
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withNavigation(NavbarContainer))
);
