import 'isomorphic-fetch';
import qs from 'qs';
import warning from 'warning';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import WAYPOINT_INTS from 'appConstants/waypointInts';
import { callApi, callApiV2, callAI } from 'appUtils/api';
import moment from 'moment';
import { makeAttachmentPayload } from 'appUtils/formatUtils';
import { MFA_STATUS } from 'AuthenticationModule/constants';

export const fetchMe = (id, token) => callApi('me', token);

const defaultFetchInit = {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

export const login = (email, password) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, password })
  };
  return callApi('auth/login', null, fetchInit);
};
export const getAuthToken = (accessToken) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi('auth/get_auth_token', accessToken, fetchInit);
};

export const signup = (email) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email })
  };
  return callApi('signup', null, fetchInit);
};

export const postBoard = (boardName, isPrivate, token) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ name: boardName, is_private: isPrivate })
  };
  return callApi('board', token, fetchInit);
};

export const putBoardPosition = (token, boardId, position) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id: boardId, position: position })
  };
  return callApi('board', token, fetchInit);
};

export const putEditBoard = (
  id,
  boardName,
  isPrivate,
  archive,
  unarchive,
  token
) => {
  const editParams = omitBy(
    { id, name: boardName, is_private: isPrivate, archive, unarchive },
    isNull
  );
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(editParams)
  };

  return callApi('board', token, fetchInit);
};

export const deleteBoard = (boardId, token) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `board/${boardId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

// TODO change all instances of group (meaning board) to board
export const getBoards = (id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi('board', token, fetchInit);
};

export const fetchSelectedBoard = (id, boardId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `board/${boardId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchProjectClients = (team_id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id
  };
  return callApi(`team_clients?${qs.stringify(queryParams)}`, token, fetchInit);
};

export const fetchProjectsListView = (
  boardId,
  token,
  isArchived,
  exportParam,
  importParam
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    limit: 5000,
    depth: 'base',
    board_ids: boardId ? [boardId] : undefined
  };

  if (isArchived === true || isArchived === false) {
    queryParams.is_archived = isArchived;
  } else if (isArchived !== null) {
    queryParams.is_archived = false;
  }
  if (exportParam) {
    queryParams.export = exportParam;
  }
  if (importParam) {
    queryParams.import = importParam;
  }

  const apiEndpoint = `projects?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchArchivedBoardsForTeam = (id, get_archived, token, teamId) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    archived: true,
    teamId: teamId
  };
  const apiEndpoint = `board?` + qs.stringify(queryParams);
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchAllTeams = (token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi('teams', token, fetchInit);
};

export const fetchBoardMembers = (id, boardId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `board_members?board_id=${boardId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const postAddMemberToBoard = (boardId, accountId, roleId, token) => {
  warning(!roleId, 'WARNING: roleId not implemented');
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ board_id: boardId, account_id: accountId })
  };
  return callApi('board_members', token, fetchInit);
};

export const postAddMembersToBoard = (boardId, accountIds, roleId, token) => {
  warning(!roleId, 'WARNING: roleId not implemented');
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ board_id: boardId, account_ids: accountIds })
  };
  return callApi('board_members', token, fetchInit);
};

export const updateBoardMember = (id, role, token) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id, role })
  };
  return callApi(`board_members`, token, fetchInit);
};

export const deleteMemberFromBoard = (id, token) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id })
  };
  return callApi('board_members', token, fetchInit);
};

export const onGlobalSearchAPI = (id, token, searchText) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accepts: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    search_text: searchText
  };

  const apiEndpoint = 'search?' + qs.stringify(queryParams);
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchProjectsByBoard = function (
  id,
  boardId,
  token,
  isActive,
  limit,
  offset,
  searchText,
  accountIds,
  all,
  view_type,
  sort
) {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const baseLimit = limit || WAYPOINT_INTS.tasks.baseLimit;

  const queryParams = {
    search_text: searchText,
    account_ids: accountIds || [],
    board_id: boardId,
    archived: !isActive ? true : null,
    limit: baseLimit,
    offset: offset || 0,
    all: all ? true : undefined,
    view_type
  };
  if (sort) {
    queryParams.sort = sort;
  }

  const apiEndpoint =
    'project/?' + qs.stringify(queryParams, { arrayFormat: 'brackets' });
  return callApi(apiEndpoint, token, fetchInit);
};

export const postProject = (
  title,
  description,
  client,
  number,
  billable,
  board_id,
  isActive,
  project_members,
  token,
  budget_status,
  budget_sub_status
) => {
  const body = {
    title,
    client,
    description,
    project_number: number,
    billable,
    board_id,
    archived: !isActive,
    project_members,
    budget_status,
    budget_sub_status
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi('project', token, fetchInit);
};

export const putProject = (
  token,
  {
    title,
    description,
    client,
    number,
    projectGroupId,
    isActive,
    id,
    stage_id,
    status_id,
    priority_id,
    billable,
    budget_status,
    budget_sub_status,
    custom_fields,
    custom_field_internal_label,
    custom_field_value,
    start_date,
    end_date,
    addGeneralTags,
    removeGeneralTags
  }
) => {
  const body = {
    title,
    client,
    description,
    project_number: number,
    board_id: projectGroupId,
    archived: isActive === undefined ? undefined : !isActive,
    id,
    stage_id,
    status_id,
    priority_id,
    billable,
    budget_status,
    budget_sub_status,
    custom_fields,
    custom_field_internal_label,
    custom_field_value,
    start_date,
    end_date,
    add_general_tags: addGeneralTags,
    remove_general_tags: removeGeneralTags
  };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi('project', token, fetchInit);
};

export const updateProjectPosition = (
  position,
  isActive,
  token,
  id,
  wasActive,
  projectGroupIdToRefresh
) => {
  const shouldArchive = wasActive && !isActive ? true : null;
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id,
      archived: shouldArchive,
      position,
      board_id: projectGroupIdToRefresh
    })
  };
  return callApi('project', token, fetchInit);
};

export const fetchSingleTask = (id, token, taskId, projectId) => {
  const fetchInit = {
    // to be consistent with other v2 task controller fetches using POST
    method: 'POST'
  };
  const apiEndpoint = `tasks/${taskId}`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const updateTaskPosition = (
  token,
  taskId,
  isCompleted,
  taskPosition,
  wasCompleted,
  onTaskDetailModal
) => {
  let fetchInit;

  if (isCompleted) {
    fetchInit = {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        position: taskPosition,
        complete: true,
        task_modal: onTaskDetailModal
      })
    };
  } else if (wasCompleted) {
    fetchInit = {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        position: taskPosition,
        incomplete: true,
        task_modal: onTaskDetailModal
      })
    };
  } else {
    fetchInit = {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        position: taskPosition
      })
    };
  }

  return callApi(`project_tasks/${taskId}`, token, fetchInit);
};

export const bulkEditTasks = (token, project_tasks) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ project_tasks })
  };
  return callApi('tasks/', token, fetchInit);
};

export const deleteTask = (token, taskId, taskType) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ task_id: taskId })
  };
  const url = `project_tasks/${taskId}`;

  return callApi(url, token, fetchInit);
};

export const getCommentsAndMetadata = (
  taskId,
  token,
  taskType,
  offset,
  limit
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    offset,
    limit
  };
  const url = `project_task_detail/${taskId}?` + qs.stringify(queryParams);

  return callApi(url, token, fetchInit);
};

export const postComment = (
  token,
  taskId,
  body,
  parentId,
  accountId,
  files,
  membersToNotify
) => {
  const payload = new FormData();
  payload.append('task_id', taskId);
  payload.append('body', body);
  parentId && payload.append('parent_id', parentId);
  payload.append('account_id', accountId);
  if (membersToNotify) {
    payload.append('members_to_notify', JSON.stringify(membersToNotify));
  }
  files &&
    files.forEach((file, i) => {
      payload.append(`file_${i}`, file);
    });
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    body: payload
  };

  const url = `project_task_detail/${taskId}`;
  return callApi(url, token, fetchInit);
};

export const putComment = (token, taskCommentId, body, files) => {
  const payload = new FormData();
  payload.append('task_comment_id', taskCommentId);
  payload.append('body', body);
  files &&
    files.forEach((file, i) => {
      payload.append(`file_${i}`, file);
    });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    body: payload
  };
  const url = `project_task_detail/${taskCommentId}`;
  return callApi(url, token, fetchInit);
};

export const deleteComment = (token, taskCommentId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      task_comment_id: taskCommentId
    })
  };
  const url = `project_task_detail/${taskCommentId}`;
  return callApi(url, token, fetchInit);
};

export const deleteAttachment = (token, attachmentId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include'
  };
  const url = `attachments/${attachmentId}`;
  return callApi(url, token, fetchInit);
};

export const getNotifications = (id, token, user_id, offset, limit) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    offset,
    limit,
    user_id
  };
  const url = `/my/notifications?` + qs.stringify(queryParams);
  return callApi(url, token, fetchInit);
};

export const getNewNotificationsCount = (
  id,
  token,
  user_id,
  groupByProjects
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    new_notifications_count: true,
    user_id,
    group_by_projects: groupByProjects
  };
  const url = `/my/notifications?` + qs.stringify(queryParams);
  return callApi(url, token, fetchInit);
};

export const putNotification = (token, notificationId, isRead) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      notification_id: notificationId,
      is_read: isRead
    })
  };
  const url = `/my/notifications`;
  return callApi(url, token, fetchInit);
};

export const markAllNotificationsRead = (token) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      mark_all: true
    })
  };
  const url = `/my/notifications`;
  return callApi(url, token, fetchInit);
};

export const putNotifications = (token, notificationIds, isOld) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      notification_ids: notificationIds,
      is_old: isOld
    })
  };
  const url = `/my/notifications`;
  return callApi(url, token, fetchInit);
};
export const archiveUnarchiveProject = (token, projectId, isActive) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: projectId,
      archive: `${!isActive}`
    })
  };
  const url = 'project/';
  return callApi(url, token, fetchInit);
};

export const batchArchiveUnarchiveProject = (token, projectIds, isActive) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      project_ids: projectIds,
      archive: `${!isActive}`
    })
  };
  return callApi(`batch_project_updates`, token, fetchInit);
};

export const fetchNoteTagsByBoard = function (id, token, boardId) {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `board/${boardId}/note_tags`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchProjectById = (id, projectId, token, isProjectDetail) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    project_id: projectId,
    is_project_detail: isProjectDetail
  };

  const apiEndpoint = 'project/?' + qs.stringify(queryParams);
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteProject = (projectId, token) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id: projectId })
  };
  return callApi('project', token, fetchInit);
};

export const hardDeleteProject = (projectId, token) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`/projects/${projectId}/hard_destroy`, token, fetchInit);
};

export const postInviteTeamMember = (
  email,
  firstName,
  lastName,
  token,
  inviteeRole
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      first_name: firstName,
      last_name: lastName,
      role: inviteeRole
    })
  };
  return callApi('invite', token, fetchInit);
};

export const validateInvite = (inviteToken) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `invite/?id=${inviteToken}`;
  return callApi(apiEndpoint, null, fetchInit);
};

export const fetchTeamMembers = (id, token, { isGetTeamMemberMfa }) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = qs.stringify({ get_team_member_mfa: isGetTeamMemberMfa });

  return callApi(`team?${queryParams}`, token, fetchInit);
};
export const fetchTeamMembersByBoard = (id, token, boardId) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    board_id: boardId
  };

  const apiEndpoint = `teams?` + qs.stringify(queryParams);
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchAllTeamMembersByBoard = (id, token, boardId) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = '/board_members_all';
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchAllProjectMembers = (id, token, params) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint =
    '/project_members/all?' + qs.stringify(params, { arrayFormat: 'brackets' });
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchProjectMembers = (projectId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `project/${projectId}/members`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const postAddMemberToProject = (id, accountId, projectRole, token) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ account_id: accountId, project_role: projectRole })
  };
  return callApi(`project/${id}/members`, token, fetchInit);
};

export const postAddMembersToProject = (id, accountIds, projectRole, token) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ account_ids: accountIds, project_role: projectRole })
  };
  return callApi(`project/${id}/members`, token, fetchInit);
};

export const deleteMemberFromProject = (id, accountId, token) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ account_id: accountId })
  };
  return callApi(`project/${id}/members`, token, fetchInit);
};

export const putUpdateProjectMember = (id, accountId, projectRole, token) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ account_id: accountId, project_role: projectRole })
  };
  return callApi(`project/${id}/members`, token, fetchInit);
};

export const putBatchUpdateProjectsBoard = (boardId, projectIds, token) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ board_id: boardId, project_ids: projectIds })
  };
  return callApi(`batch_project_updates`, token, fetchInit);
};

export const postProjectComment = (
  token,
  projectId,
  description,
  accountId,
  parentId,
  files
) => {
  const payload = new FormData();
  payload.append('project_id', projectId);
  payload.append('account_id', accountId);
  parentId && payload.append('parent_id', parentId);
  payload.append('description', description);
  files &&
    files.forEach((file, i) => {
      payload.append(`file_${i}`, file);
    });
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    body: payload
  };
  return callApi('project_status', token, fetchInit);
};

export const editProjectComment = (
  token,
  projectId,
  description,
  accountId,
  commentId,
  tempFiles
) => {
  const payload = new FormData();
  payload.append('id', commentId);
  payload.append('description', description);
  tempFiles &&
    tempFiles.forEach((file, i) => {
      payload.append(`file_${i}`, file);
    });
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    body: payload
  };
  return callApi('project_status', token, fetchInit);
};

export const deleteProjectComment = (token, commentId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: commentId
    })
  };
  return callApi('project_status', token, fetchInit);
};

/* SETTINGS */
export const updateProfile = (token, formData) => {
  const requestPayload = {};

  if (formData.old_password && formData.new_password) {
    requestPayload.password = {
      old_password: formData.old_password,
      new_password: formData.new_password
    };
  }
  if (formData.company) {
    requestPayload.account = {
      company: formData.company,
      account_id: formData.accountId
    };
  } else {
    requestPayload.account = {
      first_name: formData.first_name && formData.first_name.trim(),
      middle_initial: formData.middle_initial && formData.middle_initial.trim(),
      last_name: formData.last_name && formData.last_name.trim(),
      email: formData.email
    };
  }

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestPayload)
  };
  return callApi('settings', token, fetchInit);
};

export const putMyProjectsChoice = (token, isPinned) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ are_my_projects_open: isPinned })
  };
  return callApi('settings', token, fetchInit);
};

export const updatePassword = (token, password, firstName, lastName) => {
  let fetchInit;

  if (firstName || lastName) {
    fetchInit = {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: password,
        first_name: firstName,
        last_name: lastName
      })
    };
  } else {
    fetchInit = {
      method: 'put',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: password
      })
    };
  }

  return callApi('me', token, fetchInit);
};

export const updateSettingsPassword = (token, current_password, password) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      password,
      current_password
    })
  };

  return callApi('account/change_password', token, fetchInit);
};

export const emailNotification = (token, accountId, isEmailNotificationOn) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      account_id: accountId,
      email_notification_on: isEmailNotificationOn
    })
  };
  return callApi('email_notification', token, fetchInit);
};

export const updateTeam = (token, payload) => {
  const {
    team,
    logo,
    extendTrial,
    startTrial,
    color,
    defaultFeeTargetPercentage
  } = payload;
  const requestPayload = {
    team_id: team.id,
    name: team.name,
    extend_trial: extendTrial,
    start_trial: startTrial,
    color,
    ...(defaultFeeTargetPercentage && {
      default_target_fee_percentage: defaultFeeTargetPercentage
    })
  };

  if (logo && logo.remove) {
    requestPayload.remove_image = true;
  } else {
    requestPayload.image = logo;
  }

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestPayload)
  };
  return callApi('team', token, fetchInit);
};

// same api endpoint - different reducer cases
export const updateTeamOrderedItems = (token, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi('team', token, fetchInit);
};

export const getAuthMfaStatusPerAccount = (id, token, { accountIds }) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(
    {
      account_ids: accountIds
    },
    { arrayFormat: 'brackets' }
  );

  return callApi(
    `account/auth_mfa_status?${stringifiedQueryParams}`,
    token,
    fetchInit
  );
};

export const toggleIndividualMfa = (
  token,
  { accountIds, isEnableMfa, mfaType }
) => {
  const body = {
    account_ids: accountIds,
    prefer_sms: mfaType === MFA_STATUS.sms,
    disable: !isEnableMfa
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('account/toggle_individual_mfa', token, fetchInit);
};

export const toggleMfaReset = (token, { email, targetMfa }) => {
  const body = { email, target_mfa: targetMfa };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('account/toggle_mfa_reset', token, fetchInit);
};

export const fetchCurrentSubscription = (token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi('payment/current_subscription', token, fetchInit);
};

export const cancelSubscription = (token) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  };
  return callApi('payment/current_subscription', token, fetchInit);
};

/* home */

export const fetchMyProjectsAPI = (id, token, searchText, limit, offset) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    search_text: searchText || undefined,
    limit,
    offset
  };

  const apiEndpoint = 'my/projects?' + qs.stringify(queryParams);

  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchAllProjectsAPI = (
  id,
  token,
  offset,
  limit,
  search_text,
  board_ids,
  project_ids,
  account_ids,
  isArchived,
  is_administrative,
  all,
  flexible_filter,
  is_personal
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    search_text,
    limit,
    offset,
    depth: 'extended',
    board_ids,
    project_ids,
    account_ids,
    is_administrative,
    all,
    flexible_filter,
    is_personal
  };
  if (isArchived === true || isArchived === false) {
    queryParams.is_archived = isArchived;
  } else if (isArchived !== null) {
    queryParams.is_archived = false;
  }
  const apiEndpoint =
    'projects?' + qs.stringify(queryParams, { arrayFormat: 'brackets' });

  return callApi(apiEndpoint, token, fetchInit);
};
export const fetchProjectsIndexV2 = (id, token, fetchParams) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const apiEndpoint =
    'projects/index_v2?' +
    qs.stringify(fetchParams, { arrayFormat: 'brackets' });

  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchCheckinsHours = (team_id, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `team_planner_aggregate_utilization?${qs.stringify(body, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const fetchCheckins = (team_id, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `check_ins?${qs.stringify(body, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const bulkCreateCheckin = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`check_ins/bulk_create`, token, fetchInit);
};

export const createCheckin = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`check_ins/`, token, fetchInit);
};

export const updateCheckin = (token, checkInId, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`check_ins/${checkInId}`, token, fetchInit);
};

export const deleteCheckin = (token, checkInId, body) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`check_ins/${checkInId}`, token, fetchInit);
};

export const fetchTimers = (team_id, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `timers?${qs.stringify(body, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const fetchActiveTimer = (team_id, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `timers/get_active_timer?${qs.stringify(body, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const startTimer = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`timers/start`, token, fetchInit);
};

export const endTimer = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`timers/end`, token, fetchInit);
};

export const updateTimer = (token, id, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`timers`, token, fetchInit);
};

export const deleteTimer = (token, id, payload) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`timers`, token, fetchInit);
};

export const associateTask = (token, id, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`timers/associate_task`, token, fetchInit);
};

export const putMyProjectAPI = (token, myProjectId, position, starred) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ position: position, starred: starred })
  };
  return callApi(`my/projects/${myProjectId}`, token, fetchInit);
};

export const starProject = (id, token, projectId, starred, position) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: projectId,
      starred: starred,
      position: position
    })
  };
  return callApi('project', token, fetchInit);
};

export const updateNotificationPreferences = (
  id,
  token,
  accountId,
  projectId,
  allActivity,
  projectComments,
  tasks,
  notes
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      account_id: accountId,
      notify_tasks: tasks || allActivity,
      notify_notes: notes || allActivity,
      notify_comments: projectComments || allActivity
    })
  };
  return callApi(`project/${projectId}/members`, token, fetchInit);
};

export const resendInvite = (token, email, firstName, lastName) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email
    })
  };

  return callApi('invite_resend', token, fetchInit);
};

export const updateTeamMembership = (token, teamMembershipId, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`team_memberships/${teamMembershipId}`, token, fetchInit);
};

export const createTaskStatus = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`task_statuses/`, token, fetchInit);
};
export const updateTaskStatus = (token, taskStatusId, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`task_statuses/${taskStatusId}`, token, fetchInit);
};

export const deleteTaskStatus = (token, taskStatusId, payload) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`task_statuses/${taskStatusId}`, token, fetchInit);
};

export const fetchTaskPriorities = (id, token, payload) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`task_priorities/`, token, fetchInit);
};
export const createTaskPriority = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`task_priorities/`, token, fetchInit);
};
export const updateTaskPriority = (token, taskPriorityId, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`task_priorities/${taskPriorityId}`, token, fetchInit);
};

export const deleteTaskPriority = (token, taskPriorityId, payload) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`task_priorities/${taskPriorityId}`, token, fetchInit);
};

export const createProjectStage = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`stages/`, token, fetchInit);
};
export const updateProjectStage = (token, id, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`stages/${id}`, token, fetchInit);
};

export const deleteProjectStage = (token, id, payload) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`stages/${id}`, token, fetchInit);
};

export const createProjectStatus = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`statuses/`, token, fetchInit);
};
export const updateProjectStatus = (token, id, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`statuses/${id}`, token, fetchInit);
};

export const deleteProjectStatus = (token, id, payload) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`statuses/${id}`, token, fetchInit);
};

export const createProjectPriority = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`priorities/`, token, fetchInit);
};
export const updateProjectPriority = (token, id, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`priorities/${id}`, token, fetchInit);
};

export const deleteProjectPriority = (token, id, payload) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`priorities/${id}`, token, fetchInit);
};

export const fetchTeamMemberProfile = (id, teamMemberId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`team_memberships/${teamMemberId}`, token, fetchInit);
};

export const dayPlannerFetch = (id, token, date) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    date: date
  };

  const apiEndpoint = `day_planner?` + qs.stringify(queryParams);
  return callApi(apiEndpoint, token, fetchInit);
};

export const dayPlannerUpdate = (
  token,
  taskId,
  position,
  isPersonal,
  date,
  accountId
) => {
  const schedule_start = date && moment(date).format('YYYY-MM-DD');
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      task_id: taskId,
      position: position,
      is_personal: isPersonal,
      date: schedule_start,
      schedule_start,
      assignee_id: accountId
    })
  };

  return callApi('day_planner/', token, fetchInit);
};

export const plannersFetch = (
  id,
  token,
  accountIds,
  baseDate,
  leftOffset,
  rightOffset,
  unscheduled,
  view
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include'
  };
  const queryParams = {
    account_ids: accountIds, // e.g. 140,236
    horizontal_left_offset: leftOffset,
    horizontal_right_offset: rightOffset,
    base_date: baseDate._d,
    unscheduled,
    view
  };
  const apiEndpoint =
    `planners/?` + qs.stringify(queryParams, { arrayFormat: 'brackets' });
  return callApi(apiEndpoint, token, fetchInit);
};

export const splitWorkloadPlanner = (token, { split_date, id }) => {
  const body = {
    split_date
  };
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`/activity_phase_schedule_bars/${id}/split`, token, fetchInit);
};

export const createWorkloadPlanner = (
  token,
  {
    project_id,
    phase_id,
    account_id,
    start_date,
    end_date,
    daily_hours,
    total_hours,
    all_day,
    include_weekends,
    include_holidays,
    description,
    activity_id,
    activity_phase_id,
    phase_dependency,
    budget_status,
    member_budget_id,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_activity_phase_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    request_date,
    requester_id,
    lock_hour,
    scope_ids,
    start_time,
    end_time,
    use_weekly_planning,
    add_task_ids,
    dependency_infos
  }
) => {
  const body = {
    project_id,
    phase_id,
    account_id,
    activity_phase_id,
    start_date,
    end_date,
    daily_hours,
    total_hours,
    all_day,
    include_weekends,
    include_holidays,
    description,
    activity_id,
    phase_dependency: !phase_dependency ? 'none' : phase_dependency,
    budget_status,
    member_budget_id,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_activity_phase_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    request_date,
    requester_id,
    lock_hour,
    scope_ids,
    start_time,
    end_time,
    use_weekly_planning,
    add_task_ids,
    dependency_infos
  };
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi('team_planner/', token, fetchInit);
};

export const fetchWorkloadPlanner = (
  id,
  token,
  account_id,
  startDate,
  endDate,
  team_member_ids,
  member_budget_ids,
  project_ids,
  isRequest,
  sort_attributes,
  groupDeterminant,
  limit,
  offset,
  all,
  countOnly,
  position_ids
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    start_date: startDate,
    end_date: endDate,
    user_id: account_id,
    ...(project_ids && { project_ids }),
    ...(team_member_ids && { team_member_ids }),
    ...(member_budget_ids && { member_budget_ids }),
    ...(isRequest && { is_request: isRequest }),
    ...(sort_attributes && { sort_attributes }),
    ...(groupDeterminant && { group_determinant: groupDeterminant }),
    limit,
    offset,
    all,
    ...(countOnly && { count: true }),
    ...(position_ids && { position_ids })
  };

  const apiEndpoint = `team_planner/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const predictWorkdays = (
  id,
  token,
  account_id,
  team_id,
  start_date,
  end_date,
  work_days,
  is_pto,
  include_weekends,
  dependency_info
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    account_id,
    team_id,
    start_date,
    end_date,
    work_days,
    is_pto,
    include_weekends,
    dependency_info
  };
  const apiEndpoint = `work_days/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const predictWorkloadPlanner = (
  token,
  {
    project_id,
    phase_id,
    account_id,
    start_date,
    end_date,
    daily_hours,
    total_hours,
    all_day,
    include_weekends,
    include_holidays,
    phase_dependency,
    budget_status,
    member_budget_id,
    work_days,
    last_modified,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    requester_id,
    request_date,
    lock_hour,
    start_time,
    use_weekly_planning,
    type
  }
) => {
  const body = {
    project_id,
    phase_id,
    account_id,
    start_date,
    end_date,
    daily_hours,
    total_hours,
    all_day,
    include_weekends,
    include_holidays,
    phase_dependency: !phase_dependency ? 'none' : phase_dependency,
    budget_status,
    member_budget_id,
    last_modified,
    lock_hour,
    work_days,
    is_request,
    last_modified,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    requester_id,
    request_date,
    start_time,
    use_weekly_planning,
    type
  };
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi('team_planner_prediction/', token, fetchInit);
};

export const updateWorkloadPlanner = (
  token,
  {
    start_date,
    end_date,
    id,
    account_id,
    daily_hours,
    total_hours,
    all_day,
    include_weekends,
    include_holidays,
    project_id,
    phase_id,
    description,
    activity_id,
    activity_phase_id,
    phase_dependency,
    budget_status,
    member_budget_id,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_activity_phase_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    request_date,
    lock_hour,
    add_scope_ids,
    remove_scope_ids,
    add_task_ids,
    remove_task_ids,
    start_time,
    end_time,
    use_weekly_planning,
    dependency_infos,
    member_update_strategy
  }
) => {
  const body = {
    account_id,
    start_date,
    end_date,
    daily_hours,
    total_hours,
    all_day,
    include_weekends,
    include_holidays,
    project_id,
    phase_id,
    description,
    activity_id,
    activity_phase_id,
    phase_dependency: !phase_dependency ? 'none' : phase_dependency,
    budget_status,
    member_budget_id,
    is_request,
    approver_id,
    requested_account_id,
    requested_member_budget_id,
    requested_project_id,
    requested_phase_id,
    requested_activity_id,
    requested_activity_phase_id,
    requested_start_date,
    requested_end_date,
    requested_all_day,
    requested_total_hours,
    requested_daily_hours,
    request_date,
    lock_hour,
    add_scope_ids,
    remove_scope_ids,
    add_task_ids,
    remove_task_ids,
    start_time,
    end_time,
    use_weekly_planning,
    dependency_infos,
    member_update_strategy
  };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`team_planner/${id}`, token, fetchInit);
};

export const createActivity = (
  token,
  title,
  require_title,
  billable,
  is_custom
) => {
  const body = {
    title,
    require_title: require_title,
    billable,
    is_custom: is_custom
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`activities`, token, fetchInit);
};

export const updateActivity = (
  token,
  id,
  title,
  archived,
  billable,
  require_title,
  is_custom
) => {
  const body = {
    title,
    archived,
    billable,
    require_title,
    is_custom
  };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`activities/${id}`, token, fetchInit);
};

export const fetchActivityMembers = (activityPhaseId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(
    `activity_phase_members/${activityPhaseId}/`,
    token,
    fetchInit
  );
};

export const createActivityMembers = (
  token,
  activityPhaseId,
  account_ids,
  member_budget_ids
) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      account_ids,
      member_budget_ids
    })
  };
  return callApi(`activity_phase_members/${activityPhaseId}`, token, fetchInit);
};

export const archiveActivityMember = (
  token,
  activityPhaseMemberId,
  archive
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      archive
    })
  };
  return callApi(
    `archive_activity_phase_membership/${activityPhaseMemberId}/`,
    token,
    fetchInit
  );
};

export const createDescription = (
  token,
  activityId,
  phaseId,
  projectId,
  title,
  date,
  accountId,
  planned
) => {
  const body = {
    activity_id: +activityId,
    phase_id: phaseId,
    project_id: projectId,
    title,
    date,
    account_id: accountId,
    planned
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`descriptions`, token, fetchInit);
};

export const updateDescription = (
  token,
  id,
  title,
  activity_id,
  phase_id,
  project_id,
  account_id
) => {
  const body = {
    title,
    activity_id,
    phase_id,
    project_id,
    account_id
  };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`descriptions/${id}`, token, fetchInit);
};

export const deleteDescriptions = (token, descriptionIds) => {
  const body = {
    description_ids: descriptionIds
  };
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`descriptions`, token, fetchInit);
};

export const deleteWorkloadPlanner = (token, scheduleBarId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`team_planner/${scheduleBarId}`, token, fetchInit);
};

export const fetchWorkloadPlannerEvents = (id, token, team_id, event_type) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id,
    event_type
  };

  const apiEndpoint = `scheduled_events/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchWorkloadPlannerEvent = (id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const apiEndpoint = `scheduled_events/show/${id}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const createNotificationPreview = (
  token,
  { entity_type, entity_id, entity_attributes }
) => {
  const body = {
    entity_type,
    entity_id,
    entity_attributes
  };
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi('notification/preview', token, fetchInit);
};

export const fetchRemainingTimesheetHours = (
  id,
  token,
  { team_id, ignore_weekly_cpacity, start_date, end_date, account_ids }
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    team_id,
    ignore_weekly_cpacity,
    start_date,
    end_date,
    account_ids
  };

  const apiEndpoint = `remaining_timesheet_hours/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;

  return callApi(apiEndpoint, token, fetchInit);
};

export const createWorkloadPlannerEvent = (
  token,
  {
    team_id,
    send_time,
    recipient_account_ids,
    follower_ids,
    date_range,
    event_type,
    ical_string,
    sender_id
  }
) => {
  const body = {
    team_id,
    send_time,
    recipient_account_ids,
    follower_ids,
    date_range,
    event_type,
    ical_string,
    sender_id
  };
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi('scheduled_events/', token, fetchInit);
};
export const sendWorkloadPlannerEvent = (token, { id }) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`scheduled_events/${id}`, token, fetchInit);
};

export const updateWorkloadPlannerEvent = (
  token,
  { event_id, send_time, recipient_account_ids, ical_string }
) => {
  const body = {
    send_time,
    recipient_account_ids,
    ical_string
  };

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`scheduled_events/${event_id}`, token, fetchInit);
};

export const deleteWorkloadPlannerEvent = (token, event_id) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`scheduled_events/${event_id}`, token, fetchInit);
};

export const fetchWorkloadPlannerEventLastSent = (id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    team_id: id,
    event_type: 'workload'
  };

  const apiEndpoint = `scheduled_events/get_last_sent_time?${qs.stringify(
    queryParams,
    { arrayFormat: 'brackets' }
  )}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateWorkloadPlannerMembers = (token, team_planner_members) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(team_planner_members)
  };
  return callApi('me', token, fetchInit);
};

export const approveWorkplanRequests = (token, ids) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ids })
  };
  return callApi('team_planner_approve', token, fetchInit);
};

export const fetchMemberProjects = (
  id,
  token,
  account_id,
  account_ids,
  project_ids,
  search_text,
  limit,
  offset,
  all,
  is_administrative
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const accountIds = account_ids || (account_id ? [account_id] : []);
  const queryParams = {
    account_ids: accountIds,
    project_ids: project_ids || [],
    limit: limit || 100,
    offset: offset || 0,
    depth: 'base',
    action_type: 'read_workload_projects',
    all,
    is_administrative
  };
  if (search_text) {
    queryParams.search_text = search_text;
  }
  return callApi(
    `projects/?${qs.stringify(queryParams, { arrayFormat: 'brackets' })}`,
    token,
    fetchInit
  );
};

export const fetchActivities = (
  id,
  token,
  planned = false,
  project_id,
  is_custom
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    planned,
    project_id,
    is_custom
  };

  const apiEndpoint = `activities/?${qs.stringify(queryParams)}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchSuggestions = (
  id,
  token,
  phase_membership_ids,
  start_date,
  end_date
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const queryParams = {
    phase_membership_ids,
    start_date,
    end_date
  };

  const apiEndpoint = `suggestions/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchDescriptions = (
  id,
  token,
  accountId,
  planned = false,
  projectId,
  start_date,
  end_date
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    planned,
    start_date,
    end_date
  };
  if (accountId) {
    queryParams.account_id = accountId;
  }
  if (projectId) {
    queryParams.project_id = projectId;
  }
  const apiEndpoint = `descriptions/?${qs.stringify(queryParams)}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchFilteredActivitiesPerProject = (
  id,
  token,
  accountId,
  project_ids,
  start_date,
  end_date,
  filter = 'timesheet'
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    start_date,
    end_date,
    project_ids,
    filter
  };
  if (accountId) {
    queryParams.account_id = accountId;
  }
  const apiEndpoint = `filtered_user_activities_for_projects_in_range/?${qs.stringify(
    queryParams,
    {
      arrayFormat: 'brackets'
    }
  )}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTimesheets = (
  id,
  token,
  accountId,
  planned = false,
  projectId,
  start_date,
  end_date
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    planned,
    start_date,
    end_date
  };
  if (accountId) {
    queryParams.account_id = accountId;
  }
  if (projectId) {
    queryParams.project_id = projectId;
  }
  const apiEndpoint = `time_entries/?${qs.stringify(queryParams)}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTimesheetsIndex = (id, token, queryParams) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `time_entries_index/?${qs.stringify(
    { ...queryParams, depth: 'extended' },
    {
      arrayFormat: 'brackets'
    }
  )}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const fetchTimesheetsUtilization = (id, token, queryParams) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `utilization_index/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const processTimeEntriesForQuickbooks = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi('time_entries_process_for_quick_books', token, fetchInit);
};

export const fetchProjectActivityScores = (
  id,
  token,
  accountId,
  startDate,
  endDate,
  projectId
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    start_date: startDate,
    end_date: endDate
  };
  if (accountId) {
    queryParams.account_id = accountId;
  }
  if (projectId) {
    queryParams.project_id = projectId;
  }
  const apiEndpoint = `project_activity_scores/?${qs.stringify(queryParams)}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchUserActivities = (
  id,
  token,
  accountId,
  date,
  projectId,
  phaseId
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    date
  };
  if (accountId) {
    queryParams.account_id = accountId;
  }
  if (projectId) {
    queryParams.project_id = projectId;
  }
  if (phaseId) {
    queryParams.phase_id = phaseId;
  }
  const apiEndpoint = `user_activities_for_project_and_phase/?${qs.stringify(
    queryParams
  )}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchUserActivitiesForActionable = (id, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `user_activities_for_actionable/?${qs.stringify(body, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchActivityLogUserActivities = (id, token, body) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const apiEndpoint = `user_activities_for_actionable/?${qs.stringify(body, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const createTimesheet = (
  id,
  token,
  account_id,
  description_id,
  date,
  hours,
  projectId,
  planned = false,
  phaseId,
  billable,
  activity_id,
  title,
  override_rate
) => {
  const body = {
    hours,
    date,
    account_id,
    description_id,
    project_id: projectId,
    planned,
    phase_id: phaseId,
    billable,
    activity_id,
    title,
    override_rate
  };
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const apiEndpoint = `time_entries/`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateTimesheet = (
  id,
  token,
  timesheetId,
  hours,
  status,
  billable
) => {
  const body = {
    hours,
    status,
    billable
  };
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const apiEndpoint = `time_entries/${timesheetId}/`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const bulkUpdateTimesheets = (id, token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const apiEndpoint = `time_entries_batch_update/`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteTimesheets = (id, token, timesheetIds) => {
  const body = {
    time_entry_ids: timesheetIds
  };
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const apiEndpoint = `time_entries`;
  return callApi(apiEndpoint, token, fetchInit);
};

// UI Preferences

export const fetchProjectColorPreferences = (id, token) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `project_colors`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateProjectColorPreference = (token, projectId, color) => {
  const body = {
    color
  };
  const fetchInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `project_colors/${projectId}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const createProjectColorPreference = (token, projectId, color) => {
  const body = {
    project_id: projectId,
    color
  };
  const fetchInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `project_colors`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteProjectColorPreference = (token, projectId) => {
  const fetchInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `project_colors/${projectId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchBoardPreferences = (id, token) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `board_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateBoardPreference = (token, boardId, color) => {
  const body = {
    color
  };
  const fetchInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `board_preferences/${boardId}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const createBoardPreference = (token, boardId, color) => {
  const body = {
    board_id: boardId,
    color
  };
  const fetchInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `board_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteBoardPreference = (token, boardId) => {
  const fetchInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `board_preferences/${boardId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTaskGroupPreferences = (id, token) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `task_group_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateTaskGroupPreference = (token, taskGroupId, color) => {
  const body = {
    color
  };
  const fetchInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `task_group_preferences/${taskGroupId}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const createTaskGroupPreference = (token, taskGroupId, color) => {
  const body = {
    task_group_id: taskGroupId,
    color
  };
  const fetchInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `task_group_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteTaskGroupPreference = (token, taskGroupId) => {
  const fetchInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `task_group_preferences/${taskGroupId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTeamMembershipPreferences = (id, token) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `team_membership_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateTeamMembershipPreference = (
  token,
  teamMembershipId,
  color
) => {
  const body = {
    color
  };
  const fetchInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `team_membership_preferences/${teamMembershipId}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const createTeamMembershipPreference = (
  token,
  teamMembershipId,
  color
) => {
  const body = {
    team_membership_id: teamMembershipId,
    color
  };
  const fetchInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `team_membership_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteTeamMembershipPreference = (token, teamMembershipId) => {
  const fetchInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `team_membership_preferences/${teamMembershipId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTeamPreferences = (id, token) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `team_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateTeamPreference = (token, teamId, color) => {
  const body = {
    color
  };
  const fetchInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `team_preferences/${teamId}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const createTeamPreference = (token, teamId, color) => {
  const body = {
    team_id: teamId,
    color
  };
  const fetchInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `team_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteTeamPreference = (token, teamId) => {
  const fetchInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `team_preferences/${teamId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTagPreferences = (id, token) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `tag_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTimesheetsPredictions = (
  id,
  token,
  accountId,
  startDate,
  endDate,
  phaseIds
) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    account_id: accountId,
    start_date: startDate,
    end_date: endDate,
    phase_ids: phaseIds
  };
  const apiEndpoint =
    'time_entries_estimate/?' +
    qs.stringify(queryParams, { arrayFormat: 'brackets' });
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateTagPreference = (token, tagId, color) => {
  const body = {
    color
  };
  const fetchInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `tag_preferences/${tagId}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const createTagPreference = (token, tagId, color) => {
  const body = {
    tag_id: tagId,
    color
  };
  const fetchInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `tag_preferences`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteTagPreference = (token, tagId) => {
  const fetchInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `tag_preferences/${tagId}`;
  return callApi(apiEndpoint, token, fetchInit);
};

// v2 api calls

export const createTask = (token, body) => {
  const fetchInit = {
    method: 'POST',
    body: JSON.stringify(body)
  };
  const apiEndpoint = `tasks/create`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const moveOrCopyTasks = (token, body) => {
  const fetchInit = {
    method: 'POST',
    body: JSON.stringify(body)
  };
  const apiEndpoint = `tasks/move`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const queryAI = (token, body) => {
  const fetchInit = {
    method: 'POST',
    'Content-Type': 'application/json',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `parse`;

  return callAI(apiEndpoint, token, fetchInit);
};

export const patchTasks = (token, body) => {
  const fetchInit = {
    method: 'PATCH',
    body: JSON.stringify(body)
  };
  const apiEndpoint = `task/update`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const postTasksBatchUpdate = (token, updateType, body) => {
  const fetchInit = {
    method: 'POST',
    body: JSON.stringify(body)
  };
  const apiEndpoint = `task/batch_actions/${updateType}`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const createTaskComment = (token, body) => {
  const fetchInit = {
    method: 'POST',
    body: JSON.stringify(body)
  };
  const apiEndpoint = `task_comment/create`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const patchAccountDefaultFollowUpMessage = (token, body) => {
  const fetchInit = {
    method: 'PATCH',
    body: JSON.stringify(body)
  };
  const apiEndpoint = `accounts/default_follow_up_message`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const fetchV2HomeTaskTags = (
  id,
  token,
  filter,
  projectId,
  assigneeId
) => {
  const body = {
    filter,
    assignee_id: assigneeId
  };
  if (projectId) {
    body.project_id = projectId;
  }

  const fetchInit = {
    method: 'POST',
    body: JSON.stringify(body)
  };
  const apiEndpoint = `tags/queries`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const updateTag = (token, tagId, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApiV2(`tags/${tagId}`, token, fetchInit);
};

export const updateProjectModules = (
  token,
  projectId,
  {
    taskColumns,
    appModules,
    taskStatusOrder,
    taskPriorityOrder,
    movePhaseId,
    moveBehindPhaseId
  }
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: projectId,
      task_columns: taskColumns,
      app_modules: appModules,
      task_status_order: taskStatusOrder,
      task_priority_order: taskPriorityOrder,
      move_phase_id: movePhaseId,
      move_behind_phase_id: moveBehindPhaseId
    })
  };
  const url = 'project/';
  return callApi(url, token, fetchInit);
};
export const updateBoardModules = (
  token,
  projectId,
  boardModules,
  columnOrder,
  stageOrder,
  priorityOrder,
  statusOrder
) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: projectId,
      board_modules: boardModules,
      column_order: columnOrder,
      stage_order: stageOrder,
      priority_order: priorityOrder,
      status_order: statusOrder
    })
  };
  const url = 'board/';
  return callApi(url, token, fetchInit);
};
export const fetchUserPermissions = (id, token) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `user_permissions`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTaskGroups = (id, token, taskGroupIds) => {
  const fetchInit = {
    ...defaultFetchInit,
    method: 'GET'
  };
  const queryParams = {
    task_group_ids: taskGroupIds
  };
  const apiEndpoint = `task_groups/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const fetchProjectTaskGroups = (id, token) => {
  const url = `projects/${id}/task_groups`;
  const fetchInit = {
    ...defaultFetchInit,
    method: 'GET'
  };
  return callApi(url, token, fetchInit);
};
export const createProjectTaskGroup = (token, id, name, index) => {
  const url = `projects/${id}/task_groups`;
  const fetchInit = {
    ...defaultFetchInit,
    method: 'POST',
    body: JSON.stringify({ name, index })
  };
  return callApi(url, token, fetchInit);
};

export const updateProjectTaskGroup = (token, id, name, project_id) => {
  const url = `projects/task_groups/${id}`;
  const fetchInit = {
    ...defaultFetchInit,
    method: 'PUT',
    body: JSON.stringify({ name, project_id })
  };
  return callApi(url, token, fetchInit);
};

export const deleteProjectTaskGroup = (token, id) => {
  const url = `projects/task_groups/${id}`;
  const fetchInit = {
    ...defaultFetchInit,
    method: 'DELETE'
  };
  return callApi(url, token, fetchInit);
};

export const reorderProjectTaskGroups = (token, id, task_group_order) => {
  const url = `projects/${id}/task_groups/reorder`;
  const fetchInit = {
    ...defaultFetchInit,
    method: 'PUT',
    body: JSON.stringify({ task_group_order })
  };
  return callApi(url, token, fetchInit);
};

export const fetchAccountFilter = (id, token, body) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const apiEndpoint = `account_filter/${id}/?${qs.stringify(body, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const fetchAccountFilters = (accountId, token, team_id) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id
  };
  const apiEndpoint = `account_filters/${accountId}/?${qs.stringify(
    queryParams
  )}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateAccountFilter = (token, id, filter) => {
  const fetchInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(filter)
  };
  const apiEndpoint = `account_filter/${id}`;
  return callApi(apiEndpoint, token, fetchInit);
};
export const createAccountFilter = (token, filter) => {
  const fetchInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(filter)
  };
  const apiEndpoint = `account_filter`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const deleteAccountFilter = (token, id) => {
  const fetchInit = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `account_filter/${id}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchFilterByIds = (team_id, token, filter_ids = []) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id,
    filter_ids
  };
  const apiEndpoint = `account_filters_search/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchTasksV2 = (fetchEntityMandatedParam, token, queryParams) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const apiEndpoint = `tasks/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApiV2(apiEndpoint, token, fetchInit);
};

export const fetchAccess = (
  fetchEntityMandatedParam,
  token,
  { actableType, actableId, actionType }
) => {
  const fetchInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    actable_type: actableType,
    actable_id: actableId,
    action_type: actionType
  };
  const apiEndpoint = `permissioners/?${qs.stringify(queryParams)}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const updateAccess = (
  token,
  { accountIds, actableType, actableId, addableRoleIds, removableRoleIds }
) => {
  const body = {
    account_ids: accountIds,
    actable_type: actableType,
    actable_id: actableId,
    addable_role_ids: addableRoleIds,
    removable_role_ids: removableRoleIds
  };
  const fetchInit = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `roles_switch`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const generateReport = (token, body) => {
  const fetchInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `reports_xlsx`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const checkHasTimeEntries = (id, token, queryParams) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `/has_associated_time?${qs.stringify(queryParams, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

export const putCloneProject = (
  projectId,
  targetBoard,
  flags,
  relativeWorkdays,
  startDate,
  token
) => {
  const body = {
    project_id: projectId,
    board_id: targetBoard,
    flags,
    relative_workdays: relativeWorkdays,
    start_date: startDate
  };

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const apiEndpoint = `project_clone`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const fetchWorkGroups = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };
  return callApi(`work_groups/?${qs.stringify(queryParams)}`, token, fetchInit);
};
export const fetchWorkGroup = (id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`work_groups/${id}`, token, fetchInit);
};
export const createWorkGroup = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`work_groups/`, token, fetchInit);
};
export const updateWorkGroup = (token, workGroupId, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`work_groups/${workGroupId}`, token, fetchInit);
};
export const archiveWorkGroup = (token, workGroupId) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`work_groups/${workGroupId}/discard`, token, fetchInit);
};
export const unarchiveWorkGroup = (token, workGroupId) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`work_groups/${workGroupId}/undiscard`, token, fetchInit);
};

export const deleteWorkGroup = (token, workGroupId, payload) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`work_groups/${workGroupId}`, token, fetchInit);
};

export const createWorkGroupMembership = (token, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`work_group_memberships/account`, token, fetchInit);
};

export const deleteWorkGroupMembership = (token, payload) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`work_group_memberships/account`, token, fetchInit);
};

/* --------------------------------- Skills --------------------------------- */

export const fetchSkills = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };
  return callApi(`team_skills/?${qs.stringify(queryParams)}`, token, fetchInit);
};
export const createSkill = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`team_skills/`, token, fetchInit);
};
export const updateSkill = (token, skillId, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`team_skills/${skillId}`, token, fetchInit);
};
export const archiveSkill = (token, skillId) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      archive: true
    })
  };
  return callApi(`team_skills/${skillId}/archive`, token, fetchInit);
};
export const unarchiveSkill = (token, skillId) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      archive: false
    })
  };
  return callApi(`team_skills/${skillId}/archive`, token, fetchInit);
};

export const deleteSkill = (token, skillId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`team_skills/${skillId}`, token, fetchInit);
};

export const createSkillMembership = (token, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`team_member_skills`, token, fetchInit);
};

export const updateSkillMembership = (token, skillMembershipId, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`team_member_skills/${skillMembershipId}`, token, fetchInit);
};

export const deleteSkillMembership = (token, skillMembershipId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`team_member_skills/${skillMembershipId}`, token, fetchInit);
};

export const fetchSkillsReport = (params, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `team_member_skills/report?${qs.stringify(params, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};

/* ------------------------------ Entity Skills ----------------------------- */

export const fetchEntitySkills = (_, token, params) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(
    `entity_skills?${qs.stringify(params, {
      arrayFormat: 'brackets'
    })}`,
    token,
    fetchInit
  );
};
export const createEntitySkill = (token, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`entity_skills`, token, fetchInit);
};
export const updateEntitySkill = (token, entitySkillId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(`entity_skills/${entitySkillId}`, token, fetchInit);
};
export const deleteEntitySkill = (token, entitySkillId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return callApi(`entity_skills/${entitySkillId}`, token, fetchInit);
};

/* --------------------------------- Clients -------------------------------- */

export const fetchClients = (teamId, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    team_id: teamId
  };
  return callApi(`clients/?${qs.stringify(queryParams)}`, token, fetchInit);
};
export const fetchClient = (id, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`clients/${id}`, token, fetchInit);
};
export const createClient = (token, id, payload) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`clients/`, token, fetchInit);
};
export const updateClient = (token, id, payload) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  };
  return callApi(`clients/${id}`, token, fetchInit);
};

/* --------------------------------- Profit --------------------------------- */

export const fetchProjectTotals = (
  id,
  token,
  startDate,
  endDate,
  projectIds,
  intervalAmount,
  intervalType
) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const queryParams = {
    interval_amount: intervalAmount,
    interval_type: intervalType,
    start_date: startDate,
    end_date: endDate,
    project_ids: projectIds
  };

  const apiEndpoint = `budget_aggregates/projects/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

// export const fetchRecurlyBillingInfo = (teamId, token) => {
//   const fetchInit = {
//     method: 'get',
//     credentials: 'include',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     }
//   };

//   const apiEndpoint = `teams/${teamId}/recurly_billing_info`;
//   return callApi(apiEndpoint, token, fetchInit);
// };

// export const updateRecurlyBillingInfo = (token, teamId, billingTokenId) => {
//   const fetchInit = {
//     method: 'post',
//     credentials: 'include',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json'
//     }
//   };

//   const queryParams = {
//     token: billingTokenId
//   };

//   const apiEndpoint = `teams/${teamId}/recurly_billing_token?${qs.stringify(
//     queryParams
//   )}`;
//   return callApi(apiEndpoint, token, fetchInit);
// };

export const fetchPriorities = (_, token) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const apiEndpoint = 'priorities';
  return callApi(apiEndpoint, token, fetchInit);
};

/* -------- PREDICTIONS ------ */
export const fetchPredictionHours = (token, account_ids, forecast_horizon) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      account_ids,
      forecast_horizon
    })
  };

  const apiEndpoint = `predictions/hours`;
  return callApi(apiEndpoint, token, fetchInit);
};

/* --------------------------------- Scopes --------------------------------- */

export const fetchScopes = (_, token, queryParams) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const apiEndpoint = `scope/?${qs.stringify(queryParams, {
    arrayFormat: 'brackets'
  })}`;
  return callApi(apiEndpoint, token, fetchInit);
};

export const createScope = (token, body, files) => {
  const payload = files
    ? makeAttachmentPayload({ body, files })
    : JSON.stringify(body);

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    ...(!files && {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }),
    body: payload
  };
  return callApi(`scope/`, token, fetchInit);
};

export const updateScope = (token, scopeId, body, files) => {
  const payload = files
    ? makeAttachmentPayload({ body, files })
    : JSON.stringify(body);

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    ...(!files && {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }),
    body: payload
  };

  return callApi(`scope/${scopeId}`, token, fetchInit);
};
export const deleteScope = (token, scopeId) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  return callApi(`scope/${scopeId}`, token, fetchInit);
};

export const batchDeleteScopes = (token, body) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`batch_destroy_scopes`, token, fetchInit);
};

export const moveScopes = (token, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`scope_update_activity_phase/`, token, fetchInit);
};

export const updateScopePosition = (token, activityPhaseId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  return callApi(
    `activity_phases/${activityPhaseId}/project_scopes/reorder`,
    token,
    fetchInit
  );
};

export const assignScopeMembers = (token, scopeId, body) => {
  const fetchInit = {
    method: 'post',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(`scope/${scopeId}/assign`, token, fetchInit);
};

export const updateScopeMembership = (token, scopeId, memberBudgetId, body) => {
  const fetchInit = {
    method: 'put',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return callApi(
    `scope/${scopeId}/update_assignment/${memberBudgetId}`,
    token,
    fetchInit
  );
};

/* ------------------------------ EntityComment ----------------------------- */
export const fetchEntityComments = (_, token, queryParam) => {
  const fetchInit = {
    method: 'get',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  const stringifiedQueryParams = qs.stringify(queryParam);
  return callApi(`comment?${stringifiedQueryParams}`, token, fetchInit);
};

export const createEntityComment = (token, body) => {
  const payload = new FormData();
  payload.append('parent_entity_type', body.parent_entity_type);
  payload.append('parent_entity_id', body.parent_entity_id);
  body.parent_comment_id &&
    payload.append('parent_comment_id', body.parent_comment_id);
  payload.append('description', body.description);
  payload.append('members_to_notify', body.members_to_notify);
  body.files?.forEach((file, i) => {
    payload.append(`file_${i}`, file);
  });

  const fetchInit = {
    method: 'post',
    credentials: 'include',
    body: payload
  };

  const url = `comment`;
  return callApi(url, token, fetchInit);
};

export const updateEntityComment = (token, id, body) => {
  const payload = new FormData();
  payload.append('description', body.description);
  // payload.append('members_to_notify', body.members_to_notify);
  body.files?.forEach((file, i) => {
    payload.append(`file_${i}`, file);
  });

  const fetchInit = {
    method: 'put',
    credentials: 'include',
    body: payload
  };

  const url = `comment/${id}`;
  return callApi(url, token, fetchInit);
};

export const deleteEntityComment = (token, { id }) => {
  const fetchInit = {
    method: 'delete',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  const url = `comment/${id}`;
  return callApi(url, token, fetchInit);
};
