import {
  useGenericGroupedListsBuilder,
  GenericGroupedListsBuilderSharedParams,
  FormatList
} from 'components/Table/common/useGenericGroupedListsBuilder';
import { useCallback } from 'react';
import { membersPermissionsTableHelpers } from './layout';
import {
  MemberPermissionListItemsBuilder,
  MemberHeaderListType
} from '../types';
import { EmploymentTypes } from 'PermissionsModule/types';

const { ROW_TYPES } = membersPermissionsTableHelpers;

export const useMemberGroupedListsBuilder = ({
  listItemsBuilder,
  getIsOpen,
  toggleCollapse,
  ordersByGroup,
  groupTotalCounts
}: GenericGroupedListsBuilderSharedParams<MemberPermissionListItemsBuilder>) => {
  const formatList: FormatList<MemberHeaderListType> = useCallback(
    ({ partialList, orderId }) => {
      const employmentType = orderId as EmploymentTypes;
      const list: MemberHeaderListType = {
        ...partialList,
        rowType: ROW_TYPES.memberHeaderRow,
        customItems: [
          {
            rowType: ROW_TYPES.columnHeaderRow,
            rowHeight: 26,
            employmentType
          }
        ],
        isList: true,
        rowHeight: 58,
        employmentType
      };

      return list;
    },
    []
  );

  const memberGroupedListsBuilder = useGenericGroupedListsBuilder({
    listItemsBuilder,
    getIsOpen,
    toggleCollapse,
    ordersByGroup,
    groupTotalCounts,
    listDescription: 'member',
    formatList
  });

  return memberGroupedListsBuilder;
};
