import { put, select } from 'redux-saga/effects';
import { history } from 'store/configureStore';

import { getAuth, getSplitFlags } from 'selectors';
import * as entityActions from '../actions';
import { changeEntity } from './generics';
import { api, persistentState } from '../service';
import { SKIP_LOGIN_SERVER } from 'appConstants/dev';
import { mosaicAPIClients } from 'appCore/API/apiClients';
import { setAuthTokenFetchSuccessDetails } from 'AuthenticationModule/actionCreators';

const { login, signup, getAuthToken } = entityActions;

// eslint-disable-next-line import/prefer-default-export
export function* postLogin(action) {
  const { error } = yield changeEntity(login, api.login, [
    action.payload.email,
    action.payload.password
  ]);
}

export function* postSignup(action) {
  yield changeEntity(signup, api.signup, [action.payload.email]);
}

export function* logoutWorker(action) {
  yield put({
    type: 'LOGOUT_USER'
  });

  // clear realm data
  mosaicAPIClients.resetToDefaultBaseURL();
}

export function* exchangeAccessTokenWorker(action) {
  if (action.payload.realmId) {
    // should build realm URL before exchanging access token
    mosaicAPIClients.useRealm(action.payload.realmId);
  } else {
    // otherwise, use default base URL
    mosaicAPIClients.resetToDefaultBaseURL();
  }

  const { error, response } = yield changeEntity(
    getAuthToken,
    api.getAuthToken,
    [action.payload.token]
  );

  if (response) {
    yield put(
      setAuthTokenFetchSuccessDetails({
        token: response.auth_token,
        isImpersonating: response.is_impersonation,
        realmId: response.realm_id,
        // if realmId is provided in the payload, (usually in the query string)
        // skip updating API base url since it is already set above
        // it can be removed once it can get the realmId from the response safely
        skipUpdateAPIClients: !!action.payload.realmId
      })
    );
  }
}

export function* redirectToAuthServer(action) {
  const auth = yield select(getAuth);
  const splitFlags = yield select(getSplitFlags);
  if (splitFlags.authServerLoginFlow && !SKIP_LOGIN_SERVER) {
    persistentState.saveState({
      auth: persistentState.overridePersistantAuthStorage({
        ...auth,
        wasAuthenticated: false
      })
    });
    window.location.href = `${process.env.MOSAIC_AUTH_DOMAIN}/logout?sourceUrl=${window.location.href}`;
  } else {
    history.push(`/login`);
  }
}
