import React from 'react';
import { connect } from 'react-redux';
import { updateAccountFilterLocal } from 'actionCreators';

import {
  makeGetOrderedFilterTeamMembers,
  getMe,
  makeGetActiveWorkloadPlannerFilter,
  makeGetActiveWorkloadPlannerFilterIdHashes,
  getAllSelected,
  getSelectedTeamId
} from 'selectors';
import {
  InnerDropdownHeader,
  SelectorOption,
  SelectedValue,
  StyledInputContainer,
  CustomCheckBoxContainer,
  StyledInput,
  CloseIconContainer
} from './styles';

import NewCloseIcon from 'icons/NewCloseIcon';
import VirtualFilter from 'ReportsModule/components/Filter/VirtualFilter';
import { MemberFilterMenu } from 'ReportsModule/components/Filter/FilterTabs';
import { StyledFilterRow } from 'ReportsModule/components/styles';
import { filterItemWithWhiteSpace } from 'appUtils/search';
import styled from 'styled-components';
import theme from 'theme';
import { FILTER_PAGES } from 'appConstants/workload';

const getAccountId = (item) => item?.account?.id;

const FilterStyles = styled.div`
  .filter-list-item-container,
  ${StyledFilterRow} {
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${theme.colors.colorSemiDarkGray1} !important;
    font-size: 13px;
    cursor: pointer;

    &:hover {
      background: ${theme.colors.colorTranslucentGray4};
      ${CustomCheckBoxContainer} {
        border-color: ${({ isDisabled }) =>
          isDisabled ? 'transparent' : theme.colors.colorRoyalBlue};
      }
    }
  }
`;

const customItemsOverride = {
  memberList: [],
  workGroupList: [],
  portfolioList: [],
  positionList: [],
  skillsList: [],
  regionsList: [],
  officesList: [],
  disciplinesList: []
};
class MemberFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      isFocused: false,
      filterTab:
        props.pageName === FILTER_PAGES.WORKLOAD_PLANNER &&
        props.activeFilter?.custom?.membersGroupBy
          ? props.activeFilter.custom.membersGroupBy
          : 'members'
    };
  }

  componentDidUpdate(prevProps) {
    const { members } = this.props;
    if (
      members &&
      prevProps.members &&
      members.length !== prevProps.members.length
    ) {
      this.clearMemo();
    }
  }

  searchMemo = {};
  clearMemo = () => (this.searchMemo = {});
  getItems = () => {
    const { search } = this.state;
    if (!this.searchMemo[search]) {
      this.searchMemo[search] = this.filterItems();
    }
    return this.searchMemo[search];
  };

  filterItems = () => {
    const { search } = this.state;
    const { members } = this.props;
    const searchWords = search.split(' ').filter((str) => str !== '-');
    return members.filter(
      (item) =>
        getAccountId(item) &&
        (!search.length || this.itemFilter(item, searchWords)) &&
        this.itemIsActiveOrSelected(item)
    );
  };

  itemFilter = (item, searchWords) =>
    filterItemWithWhiteSpace({
      searchWords,
      item: item.account,
      filterKeysArray: ['name', 'initials']
    });

  itemIsActiveOrSelected = (item) =>
    !item.is_archived ||
    this.props.activeFilterIdHashes?.account_ids?.[item.account.id];

  clearSearch = () => this.setState({ search: '' });
  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSearchFocus = (isFocused) => {
    this.setState({
      ...this.state,
      isFocused: isFocused
    });
  };

  renderHeader = () => {
    const {
      filter,
      updateAccountFilterLocal,
      allSelected,
      widgetConfig,
      // show all option regardless of limits
      forceShowAllOption
    } = this.props;
    const { search, isFocused } = this.state;
    const membersSelected =
      filter && filter.account_ids && filter.account_ids.length !== undefined
        ? filter.account_ids.length
        : '';
    const filterMemberIds = filter?.account_ids ?? [];

    const selectionLimit = widgetConfig?.limits?.account_ids;
    const showAllOption = forceShowAllOption || !selectionLimit;

    const allSelectedToUse = selectionLimit
      ? filterMemberIds.length >= selectionLimit
      : allSelected;

    const bulkSelectorIds = allSelectedToUse
      ? []
      : Array.from(
          new Set([
            ...this.getItems()
              .map((member) => member?.account?.id)
              .filter((id) => id),
            ...filterMemberIds
          ])
        );

    const bulkSelectorIdsToUse = selectionLimit
      ? bulkSelectorIds.slice(0, selectionLimit)
      : bulkSelectorIds;
    const pluralize = membersSelected === 1 ? '' : 's';
    return (
      <>
        <StyledInputContainer style={{ display: 'flex' }}>
          <StyledInput
            onChange={this.handleChange}
            name="search"
            value={search}
            placeholder="Search member or select below"
            autoComplete="off"
            autoFocus
            onFocus={() => this.handleSearchFocus(true)}
            onBlur={() => this.handleSearchFocus(false)}
            isActive={isFocused || !!search.length}
            className="workload-filter"
          />
          {this.state.search !== '' ? (
            <CloseIconContainer
              onClick={this.clearSearch}
              className="filter-close-icon"
            >
              <NewCloseIcon />
            </CloseIconContainer>
          ) : (
            ''
          )}
        </StyledInputContainer>
        <InnerDropdownHeader
          data-for="app-tooltip"
          data-tip={`Max ${selectionLimit} of ${membersSelected} Selected.`}
          data-tip-disable={Boolean(
            !selectionLimit ||
              (selectionLimit !== undefined &&
                membersSelected !== undefined &&
                selectionLimit > membersSelected)
          )}
        >
          {showAllOption && (
            <SelectorOption
              onClick={(e) =>
                updateAccountFilterLocal({
                  ...filter,
                  account_ids: bulkSelectorIdsToUse
                })
              }
            >
              {allSelectedToUse ? 'None \u00A0' : 'All'}
            </SelectorOption>
          )}

          {!!membersSelected && (
            <SelectorOption
              onClick={(e) =>
                updateAccountFilterLocal({
                  ...filter,
                  account_ids: []
                })
              }
            >
              Clear Selected &nbsp;
            </SelectorOption>
          )}
          {membersSelected ? (
            <SelectedValue isBlue={membersSelected}>
              {membersSelected}
              {selectionLimit ? `/${selectionLimit}` : ''}
            </SelectedValue>
          ) : (
            <SelectedValue>0 members selected</SelectedValue>
          )}
        </InnerDropdownHeader>
      </>
    );
  };

  onFilterTabSelect = ({ members }) => {
    const { activeFilter, updateAccountFilterLocal, pageName } = this.props;
    this.setState({ filterTab: members });

    if (pageName === FILTER_PAGES.WORKLOAD_PLANNER) {
      updateAccountFilterLocal({
        ...activeFilter,
        custom: {
          ...activeFilter?.custom,
          membersGroupBy: members
        }
      });
    }
  };

  render() {
    const {
      viewType,
      viewBy,
      pageName,
      innerHeightAdjustment,
      filterId,
      widgetConfig,
      filter,
      listWidth,
      isHeaderHidden,
      additionalFilterOptions
    } = this.props;
    const { filterTab, search } = this.state;

    return (
      <>
        {!isHeaderHidden && (
          <>
            <MemberFilterMenu
              activeFilterTab={filterTab}
              onFilterTabSelect={this.onFilterTabSelect}
            />
            {this.renderHeader()}
          </>
        )}
        <FilterStyles>
          <VirtualFilter
            filterId={filterId}
            viewType={viewType}
            pageName={pageName}
            viewBy={viewBy}
            filterSections={['members']}
            filterTabsOverride={{ members: filterTab }}
            sectionsStartOpen
            showOnEngaged={false}
            showStickyHeader={false}
            searchOverride={{ members: search }}
            customItems={customItemsOverride}
            leftPadding={`10px`}
            headerLeftOffset={0}
            leftOffset={'0px'}
            width={listWidth || 225}
            skipHeader={{ members: true }}
            innerHeightAdjustment={innerHeightAdjustment || 315}
            saveLocal
            widgetConfig={widgetConfig}
            activeFilter={filter}
            additionalFilterOptions={additionalFilterOptions}
          />
        </FilterStyles>
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const getActiveWorkloadPlannerFilterIdHashes =
    makeGetActiveWorkloadPlannerFilterIdHashes();
  const getOrderedFilterTeamMembers = makeGetOrderedFilterTeamMembers();
  const mapStateToProps = (state, ownProps) => {
    const filter = getActiveWorkloadPlannerFilter(state, ownProps);
    return {
      members: getOrderedFilterTeamMembers(state, ownProps),
      filter,
      activeFilterIdHashes: getActiveWorkloadPlannerFilterIdHashes(
        state,
        ownProps
      ),
      allSelected: getAllSelected(state, ownProps),
      teamId: getSelectedTeamId(state),
      me: getMe(state)
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = {
  updateAccountFilterLocal
};
export default connect(makeMapStateToProps, mapDispatchToProps)(MemberFilter);
