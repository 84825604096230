import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import uuid from 'uuid';
import styled from 'styled-components';

// import DueDateBatchMenuItem from './MenuItems/DueDateBatchMenuItem';
import ScheduleBatchMenuItem from './MenuItems/ScheduleBatchMenuItem';
import TaskBatchActionsModal from './TaskBatchActionsModal';
import DatePicker from 'components/DatePicker';
import ProjectSelectionPopover from 'components/ProjectSelectionPopover';
import ProcessStartedModalBody from 'components/ProcessStartedModal/ProcessStartedModalBody';
import { getMaxDate } from 'appUtils/momentUtils';
import cn from 'classnames';

import {
  TaskBatchActionMenuWrapper,
  TaskBatchActionsAnchor,
  TaskBatchActionOption,
  TaskBatchActionOptionIcon,
  TaskBatchActionOptionText,
  LineBreak
} from './styles';
import {
  triggerBatchTasksFollowUp,
  triggerBatchMoveTasksToTop,
  triggerBatchCopyTasksToProject,
  triggerBatchRevertTasksToIncomplete,
  triggerBatchDeleteTasks,
  triggerBatchUnfollowTasks,
  triggerBatchInboxTaskAcceptance,
  triggerTasksAttributesUpdate,
  copyTasks,
  moveTasks,
  triggerTaskCreationServerRequest,
  triggerTaskCommentCreation,
  triggerAccountFollowUpMessageUpdate,
  triggerBatchComplete,
  flushBatchSelectedTasks
} from 'actionCreators';
import MoveIcon from 'icons/MoveIcon';
import FollowUpBatchIcon from 'icons/FollowUpBatchIcon';
import MoveTasksToTopIcon from 'icons/MoveTasksToTopIcon';
import PinTasksIcon from 'icons/PinTasksIcon';
import TrashBatchIcon from 'icons/TrashBatchIcon';
import UnfollowIcon from 'icons/UnfollowIcon';
import CopyBatchIcon from 'icons/CopyBatchIcon';
import CompletedIcon from 'icons/CompletedIcon';
import {
  getIsOnTeamProject,
  getHomeTaskObj,
  getAuthToken,
  getCurrentFilter,
  getSelectedTeamId,
  getMatchedRouteParams,
  getIsMemberModalOpen
} from 'selectors';
import { Modal, ModalBody } from 'reactstrap';
import { noop } from 'appUtils';
import withPermissionsCheck from 'hocs/withPermissionsCheck';
import { deleteTasks } from 'PermissionsModule/SpaceLevelPermissions/actionCreators/project';
import { defaultTooltipProps } from 'appUtils/tooltipUtils';
import {
  DELETE_TASK_BATCH_TIP,
  DELETE_TASK_TIP
} from 'PermissionsModule/SpaceLevelPermissions/constants';
import { getProjectHash } from 'ProjectsModule/selectors';

const processStartedCopy = {
  move: {
    title: 'Moving Tasks',
    processStartedMessage: 'Tasks may take some time to move'
  },
  copy: {
    title: 'Copying Tasks',
    processStartedMessage: 'Copied Tasks may take some time to appear'
  }
};

const baseMenuOptions = [
  'clear',
  'top',
  // 'dueDate',
  'schedule',
  'break',
  'followUp',
  'move',
  'copy',
  'break',
  'complete',
  'delete'
];
const todayMenuOptions = [
  'clear',
  // 'dueDate',
  'schedule',
  'break',
  'followUp',
  'copy',
  'break',
  'complete',
  'delete'
];
const projectMenuOptions = [
  'clear',
  'top',
  // 'dueDate',
  'schedule',
  'break',
  'followUp',
  'move',
  'copy',
  'break',
  'complete',
  'delete'
];
const assignedMenuOptions = [
  'clear',
  // 'dueDate',
  'schedule',
  'break',
  'followUp',
  'move',
  'copy',
  'break',
  'complete',
  'delete'
];

const StyledCompletedIconContainer = styled(CompletedIcon)`
  width: 16px;
  height: 16px;
  margin-top: -2px;
`;
const batchMenuOptionsVisibility = {
  accepted: {
    batchOptions: baseMenuOptions
  },
  today: {
    batchOptions: todayMenuOptions
  },
  completed: {
    batchOptions: baseMenuOptions
  },
  assigned_to_me: {
    batchOptions: assignedMenuOptions
  },
  assigned_by_me: {
    batchOptions: assignedMenuOptions
  },
  project: {
    batchOptions: projectMenuOptions
  },
  profile: {
    batchOptions: baseMenuOptions
  },
  incomplete: {
    batchOptions: baseMenuOptions
  },
  unscheduled: {
    batchOptions: baseMenuOptions
  },
  scheduled: {
    batchOptions: baseMenuOptions
  },
  overdue: {
    batchOptions: baseMenuOptions
  }
};

const popupAlignCoverItem = {
  points: ['tl', 'bl'],
  offset: [0, -20]
};

class TaskBatchActionsMenu extends Component {
  state = {
    schedulingCalendarIsOpen: false,
    // dueDateCalendarIsOpen: false,
    followUpModalIsOpen: false,
    deleteModalIsOpen: false,
    batchFollowUpModalIsVisible: false,
    batchDeleteModalIsVisible: false,
    batchUnfollowModalIsVisible: false,
    processStartedModalType: null
  };

  copyToButton = React.createRef();
  moveToButton = React.createRef();

  /**
   * Check deleteTasks permission with withPermissionCheck + checkPermission instead of
   * withProjectPermissionProvider + useProjectPermissionState hook since we need to
   * check tasks iteratively for their project and assignee ids.
   */
  getCanDeleteSelectedTasks = () => {
    const {
      taskHash,
      selectedBatchTaskIds,
      checkPermission,
      selectedTeamId,
      deleteTasks,
      projectHash
    } = this.props;

    const selectedTasks = Object.values(taskHash).filter((task) =>
      selectedBatchTaskIds.includes(task.id)
    );

    let canDeleteSelectedTasks = true;

    selectedTasks.forEach((selectedTask) => {
      const { project_id: projectId } = selectedTask;

      const project = projectHash[projectId];

      const canDeleteTask =
        checkPermission(deleteTasks, {
          permissions: {
            projectId,
            teamId: selectedTeamId
          }
        }) || project?.is_personal;

      if (!canDeleteTask) canDeleteSelectedTasks = false;
    });

    return canDeleteSelectedTasks;
  };

  getPermissions = () => {
    const {
      matchedParams: { projectId },
      selectedTeamId
    } = this.props;
    const permissions = { projectId, teamId: selectedTeamId };
    return permissions;
  };

  handleMenuOptionClick = (menuOption) => {
    switch (menuOption) {
      case 'accept':
        return this.handleInboxTaskAcceptanceTrigger;
      case 'schedule':
        return this.toggleSchedulingCalendar;
      case 'pin':
      case 'top':
        return this.handleBatchMoveTasksToTop;
      // case 'dueDate':
      //   return this.toggleDueDateCalendar;
      case 'unfollow':
        return this.toggleBatchUnfollowModal;
      case 'followUp':
        return this.toggleBatchFollowUpModal;
      case 'delete':
        return this.toggleBatchDeleteModal;
      case 'complete':
        return this.handleBatchComplete;
      case 'clear':
        return this.handleBatchClear;
      default:
        break;
    }
  };

  // Batch Action Handlers

  handleBatchClear = () => {
    const { flushBatchSelectedTasks } = this.props;
    flushBatchSelectedTasks();
  };

  handleBatchMoveTasksToTop = () => {
    const {
      selectedBatchTaskIds,
      authToken,
      moveTasksToTop,
      flushBatchSelectedTasks,
      matchedParams: { projectId }
    } = this.props;
    const body = {
      taskIds: selectedBatchTaskIds,
      project_id: projectId
    };
    moveTasksToTop({ token: authToken, body });
    flushBatchSelectedTasks();
  };

  handleInboxTaskAcceptanceTrigger = () => {
    const { selectedBatchTaskIds, authToken, handleBatchInboxTaskAcceptance } =
      this.props;
    const body = {
      taskIds: selectedBatchTaskIds
    };
    handleBatchInboxTaskAcceptance({ token: authToken, body });
  };

  handleBatchFollowUpRequest = () => {
    const { selectedBatchTaskIds, authToken, handleBatchTasksFollowUp } =
      this.props;
    const body = {
      taskIds: selectedBatchTaskIds
    };
    handleBatchTasksFollowUp({ token: authToken, body });
    this.toggleBatchFollowUpModal();
    flushBatchSelectedTasks();
  };

  handleBatchUnfollowRequest = () => {
    const {
      selectedBatchTaskIds,
      authToken,
      handleBatchUnfollowTasks,
      flushBatchSelectedTasks
    } = this.props;
    const body = {
      taskIds: selectedBatchTaskIds
    };
    handleBatchUnfollowTasks({ token: authToken, body });
    this.toggleBatchUnfollowModal();
    flushBatchSelectedTasks();
  };

  handleBatchDeleteRequest = () => {
    const {
      selectedBatchTaskIds,
      authToken,
      handleBatchDeleteTasks,
      flushBatchSelectedTasks
    } = this.props;
    const body = {
      taskIds: selectedBatchTaskIds
    };
    const permissions = this.getPermissions();
    handleBatchDeleteTasks({ token: authToken, body, permissions });
    this.toggleBatchDeleteModal();
    flushBatchSelectedTasks();
  };

  handleBatchComplete = () => {
    const {
      selectedBatchTaskIds,
      authToken,
      handleBatchComplete,
      flushBatchSelectedTasks
    } = this.props;
    const body = {
      taskIds: selectedBatchTaskIds
    };
    const permissions = this.getPermissions();
    handleBatchComplete({ token: authToken, body, permissions });
    flushBatchSelectedTasks();
  };

  handleScheduleCalendarSelect = (startMoment, endMoment) => {
    const updatedScheduledDate = startMoment
      ? startMoment.startOf('day').format('YYYY-MM-DD')
      : null;
    const updatedScheduledEndDate = endMoment
      ? endMoment.startOf('day').format('YYYY-MM-DD')
      : null;
    const {
      selectedBatchTaskIds,
      authToken,
      handleTasksAttributesUpdate,
      flushBatchSelectedTasks
    } = this.props;
    const body = {
      task_ids: selectedBatchTaskIds,
      schedule_start: updatedScheduledDate,
      schedule_end: updatedScheduledEndDate
        ? getMaxDate(updatedScheduledDate, updatedScheduledEndDate)
        : null
    };
    const permissions = this.getPermissions();
    handleTasksAttributesUpdate({ token: authToken, body, permissions });
    flushBatchSelectedTasks();
  };

  // handleDueDateCalendarSelect = moment => {
  //   const updatedDueDate = moment
  //     ? moment.startOf('day').format('YYYY-MM-DD')
  //     : null;
  //   const {
  //     selectedBatchTaskIds,
  //     authToken,
  //     handleTasksAttributesUpdate,
  //     flushBatchSelectedTasks
  //   } = this.props;
  //   const body = {
  //     task_ids: selectedBatchTaskIds,
  //     due_at: updatedDueDate
  //   };
  //   const permissions = this.getPermissions();
  //   handleTasksAttributesUpdate({ token: authToken, body, permissions });
  //   flushBatchSelectedTasks();
  // };

  handleMoveTaskSelect = (
    updatedProjectId,
    updatedGroupId,
    updatedPhaseId,
    updatedActivityId,
    updatedActivityPhaseId
  ) => {
    const {
      selectedBatchTaskIds,
      authToken,
      handleTasksAttributesUpdate,
      flushBatchSelectedTasks,
      matchedParams: { projectId },
      moveTasks,
      selectedBatchGroupIds,
      isVirtualList,
      isMemberModalOpen
    } = this.props;
    const options = {
      shouldRemoveTask: +projectId !== +updatedProjectId
    };

    if (isVirtualList) {
      moveTasks({
        projectId: updatedProjectId,
        groupId: updatedGroupId,
        phaseId: updatedPhaseId,
        activityId: updatedActivityPhaseId,
        activityPhaseId: updatedActivityPhaseId,
        taskIds: selectedBatchTaskIds,
        groupIds: selectedBatchGroupIds,
        dontRemoveTask: isMemberModalOpen,
        options
      });
      if (selectedBatchGroupIds?.length && !options.shouldRemoveTask) {
        this.openProcessStartedModal('move');
      }
      return;
    }

    const body = {
      task_ids: selectedBatchTaskIds,
      project_id: updatedProjectId
    };
    if (updatedGroupId) body.task_group_id = updatedGroupId;

    const permissions = this.getPermissions();
    handleTasksAttributesUpdate({
      token: authToken,
      body,
      options,
      permissions
    });
    flushBatchSelectedTasks();
  };

  handleCopyTaskSelect = (updatedProjectId, updatedGroupId) => {
    const {
      authToken,
      selectedBatchTaskIds,
      flushBatchSelectedTasks,
      handleBatchCopyTasksToProject,
      matchedParams: { projectId },
      copyTasks,
      selectedBatchGroupIds,
      isVirtualList
    } = this.props;
    const options = {
      shouldAddTaskToView: Number(projectId) === updatedProjectId
    };
    if (isVirtualList) {
      copyTasks({
        projectId: updatedProjectId,
        groupId: updatedGroupId,
        taskIds: selectedBatchTaskIds,
        groupIds: selectedBatchGroupIds,
        options
      });
      if (selectedBatchGroupIds?.length && options.shouldAddTaskToView) {
        this.openProcessStartedModal('copy');
      }
      return;
    }
    const body = {
      taskIds: selectedBatchTaskIds,
      project_id: updatedProjectId
    };
    if (updatedGroupId) body.task_group_id = updatedGroupId;

    handleBatchCopyTasksToProject({ token: authToken, body, options });
    flushBatchSelectedTasks();
  };

  handleTaskCreation = (task, projectId) => {
    const { authToken, handleTaskCreationServerRequest, matchedParams } =
      this.props;
    const viewProjectId = matchedParams.projectId;
    const { description, note } = task;
    const permissions = this.getPermissions();
    handleTaskCreationServerRequest({
      token: authToken,
      body: {
        id: uuid(),
        description,
        note,
        project_id: projectId,
        view_project_id: viewProjectId
      },
      permissions
    });
  };

  handleTasksFollowUp = (comment, isUpdatingDefault) => {
    const {
      authToken,
      selectedBatchTaskIds,
      handleTaskCommentCreation,
      flushBatchSelectedTasks,
      handleAccountFollowUpMessageUpdate
    } = this.props;
    const permissions = this.getPermissions();
    handleTaskCommentCreation({
      token: authToken,
      body: {
        task_ids: selectedBatchTaskIds,
        body: comment,
        is_updating_default_comment: isUpdatingDefault
      },
      permissions
    });
    if (isUpdatingDefault) {
      handleAccountFollowUpMessageUpdate({
        token: authToken,
        body: {
          default_follow_up_message: comment
        }
      });
    }
    flushBatchSelectedTasks();
  };

  // Modal Triggers

  toggleBatchFollowUpModal = () => {
    this.setState((prevState) => ({
      batchFollowUpModalIsVisible: !prevState.batchFollowUpModalIsVisible
    }));
  };

  toggleBatchUnfollowModal = () => {
    this.setState((prevState) => ({
      batchUnfollowModalIsVisible: !prevState.batchUnfollowModalIsVisible
    }));
  };

  toggleBatchDeleteModal = () => {
    this.setState((prevState) => ({
      batchDeleteModalIsVisible: !prevState.batchDeleteModalIsVisible
    }));
  };

  // Batch Menu Options

  batchMenuOptions = () => {
    const canDeleteSelectedTasks = this.getCanDeleteSelectedTasks();

    const { selectedBatchTaskIds } = this.props;

    return {
      clear: {
        key: 'clear',
        title: `Clear Selected ${
          this.props.numTasksSelected || this.props.selectedBatchTaskIds?.length
        }`
      },
      accept: {
        isDisabled: !!this.props.selectedBatchGroupIds?.length,
        key: 'accept',
        title: 'Move To My Tasks',
        icon: <MoveIcon />
      },
      top: {
        isDisabled: !!this.props.selectedBatchGroupIds?.length,
        key: 'top',
        title: 'Top',
        icon: <MoveTasksToTopIcon />
      },
      pin: {
        isDisabled: !!this.props.selectedBatchGroupIds?.length,
        key: 'pin',
        title: 'Pin',
        icon: <PinTasksIcon />
      },
      // dueDate: {
      //   key: 'dueDate',
      //   component: (
      //     <DueDateBatchMenuItem handleSelect={this.handleDueDateCalendarSelect} />
      //   )
      // },
      schedule: {
        isDisabled: !!this.props.selectedBatchGroupIds?.length,
        key: 'schedule',
        component: (
          <ScheduleBatchMenuItem
            isDisabled={!!this.props.selectedBatchGroupIds?.length}
            handleSelect={this.handleScheduleCalendarSelect}
            target={this.containerRef}
          />
        )
      },
      move: {
        key: 'move',
        component: (
          <ProjectSelectionPopover
            selectedBatchTaskIds={this.props.selectedBatchTaskIds}
            onSelect={this.handleMoveTaskSelect}
            headerText="Move To"
            isMove
            isVirtualList={this.props.isVirtualList}
            popupAlign={popupAlignCoverItem}
            popoverClassName="project-task-move-select-list"
            target={this.containerRef}
            shouldUseOldProjectDropdown // allSelected not handled properly on BE or FE, need to use old menu
          >
            {() =>
              this.renderProjectPopoverMenuItem(
                <MoveIcon />,
                'Move',
                this.moveToButton
              )
            }
          </ProjectSelectionPopover>
        )
      },
      copy: {
        key: 'copy',
        component: (
          <ProjectSelectionPopover
            selectedBatchTaskIds={this.props.selectedBatchTaskIds}
            onSelect={this.handleCopyTaskSelect}
            headerText="Copy To"
            isCopy
            isVirtualList={this.props.isVirtualList}
            popupAlign={popupAlignCoverItem}
            target={this.containerRef}
          >
            {() =>
              this.renderProjectPopoverMenuItem(
                <CopyBatchIcon />,
                'Copy',
                this.copyToButton
              )
            }
          </ProjectSelectionPopover>
        )
      },
      followUp: {
        // This feature is not currently implemented on the backend.
        // https://github.com/LifeCoded/mosaic-rails-api/blob/830c377b1d801a8bd6c0a9e74fe451616f793cb1/app/controllers/v2/task/batch_actions_controller.rb#L62-L64
        isHidden: true, // !this.props.isOnTeamProject,
        isDisabled: !!this.props.selectedBatchGroupIds?.length,
        key: 'followUp',
        title: 'Follow Up',
        icon: <FollowUpBatchIcon />
      },
      complete: {
        isDisabled: !!this.props.selectedBatchGroupIds?.length,
        key: 'complete',
        title: 'Completed',
        icon: (
          <StyledCompletedIconContainer>
            <CompletedIcon color={'#0074d9'} />
          </StyledCompletedIconContainer>
        )
      },
      delete: {
        isDisabled:
          !!this.props.selectedBatchGroupIds?.length || !canDeleteSelectedTasks,
        key: 'delete',
        title: 'Delete',
        icon: <TrashBatchIcon />,
        tooltipContent: !canDeleteSelectedTasks
          ? selectedBatchTaskIds.length > 1
            ? DELETE_TASK_BATCH_TIP
            : DELETE_TASK_TIP
          : undefined
      },
      unfollow: {
        isDisabled: !!this.props.selectedBatchGroupIds?.length,
        key: 'unfollow',
        title: 'Unfollow',
        icon: <UnfollowIcon />
      }
    };
  };

  renderSchedulePicker = () => (
    <DatePicker onChange={this.handleSchedulingCalendarSelect} showDateInput />
  );

  // Renderers

  renderProjectPopoverMenuItem = (icon, title) => {
    return (
      <TaskBatchActionOption>
        <TaskBatchActionOptionIcon>{icon}</TaskBatchActionOptionIcon>
        <TaskBatchActionOptionText>{title}</TaskBatchActionOptionText>
      </TaskBatchActionOption>
    );
  };

  renderTaskBatchActionMenuItem = (option) => {
    const batchMenuOptions = this.batchMenuOptions();
    const { title, icon, isDisabled, tooltipContent } =
      batchMenuOptions[option];
    return (
      <TaskBatchActionOption
        onClick={isDisabled ? noop : this.handleMenuOptionClick(option)}
        className={cn({ disabled: isDisabled })}
      >
        {option !== 'clear' && (
          <TaskBatchActionOptionIcon>{icon}</TaskBatchActionOptionIcon>
        )}
        <TaskBatchActionOptionText
          {...defaultTooltipProps}
          data-tip={tooltipContent}
        >
          {title}
        </TaskBatchActionOptionText>
      </TaskBatchActionOption>
    );
  };

  getDisplayedFilter = () => {
    const { taskFilter } = this.props;
    let displayedFilter;
    if (
      [
        'accepted',
        'assigned_to_me',
        'today',
        'assigned_by_me',
        'profile',
        'project'
      ].includes(taskFilter.scope)
    ) {
      const updatedScope =
        taskFilter.section === 'My Tasks' &&
        taskFilter.subSection === 'scheduled'
          ? 'today'
          : taskFilter.scope;

      displayedFilter = batchMenuOptionsVisibility[updatedScope];
    } else {
      displayedFilter = batchMenuOptionsVisibility[taskFilter.state];
    }

    return displayedFilter || baseMenuOptions;
  };

  renderMenuOptions = () => {
    const { batchOptions } = this.getDisplayedFilter();
    const batchMenuOptions = this.batchMenuOptions();

    return (
      <>
        {batchOptions.map((option, idx) =>
          option === 'break' ? (
            <LineBreak key={idx} />
          ) : (
            !batchMenuOptions[option]?.isHidden && (
              <span key={idx}>
                {'component' in batchMenuOptions[option]
                  ? batchMenuOptions[option].component
                  : this.renderTaskBatchActionMenuItem(option)}
              </span>
            )
          )
        )}
      </>
    );
  };

  renderFollowUpModal = () => {
    const { selectedBatchTaskIds } = this.props;
    const { batchFollowUpModalIsVisible } = this.state;
    return (
      <TaskBatchActionsModal
        isOpen={batchFollowUpModalIsVisible}
        title={`Follow up on these ${selectedBatchTaskIds.length} tasks?`}
        content="The current task assignees will be sent a request for update"
        handleConfirmation={this.handleBatchFollowUpRequest}
        toggle={this.toggleBatchFollowUpModal}
      />
    );
  };

  renderUnfollowModal = () => {
    const { selectedBatchTaskIds } = this.props;
    const { batchUnfollowModalIsVisible } = this.state;
    return (
      <TaskBatchActionsModal
        isOpen={batchUnfollowModalIsVisible}
        title={`Stop following ${selectedBatchTaskIds.length} tasks?`}
        content="Selected task(s) will no longer be shown on this list."
        handleConfirmation={this.handleBatchUnfollowRequest}
        toggle={this.toggleBatchUnfollowModal}
      />
    );
  };

  renderDeleteModal = () => {
    const { selectedBatchTaskIds } = this.props;
    const plural = !(selectedBatchTaskIds.length === 1);
    const { batchDeleteModalIsVisible } = this.state;
    return (
      <TaskBatchActionsModal
        isOpen={batchDeleteModalIsVisible}
        title="Delete tasks?"
        content={
          <>
            Are you sure you want to <b>permanently delete </b>
            {plural ? 'these' : 'this'} {selectedBatchTaskIds.length} task
            {plural ? 's' : ''}? You will be unable to restore deleted tasks.
          </>
        }
        handleConfirmation={this.handleBatchDeleteRequest}
        toggle={this.toggleBatchDeleteModal}
      />
    );
  };

  openProcessStartedModal = (modalType) =>
    this.setState({ processStartedModalType: modalType });

  closeProcessStartedModal = () =>
    this.setState({ processStartedModalType: null });

  render() {
    const {
      selectedBatchTaskIds,
      isOnTeamProject,
      isOnScheduledView,
      isOnHomeView,
      isOnPersonalProject,
      isVirtualList,
      numTasksSelected
    } = this.props;
    const {
      batchFollowUpModalIsVisible,
      batchDeleteModalIsVisible,
      batchUnfollowModalIsVisible,
      processStartedModalType
    } = this.state;
    const shouldRenderBatchActionMenu =
      !isEmpty(selectedBatchTaskIds) || !!numTasksSelected;
    const shouldRenderFollowUpModal =
      shouldRenderBatchActionMenu && batchFollowUpModalIsVisible;
    const shouldRenderDeleteModal =
      shouldRenderBatchActionMenu && batchDeleteModalIsVisible;
    const shouldRenderUnfollowModal =
      shouldRenderBatchActionMenu && batchUnfollowModalIsVisible;
    return (
      <>
        <TaskBatchActionMenuWrapper
          isOnTeamProject={isOnTeamProject}
          isOnScheduledView={isOnScheduledView}
          isOnHomeView={isOnHomeView}
          isOnPersonalProject={isOnPersonalProject}
          isVirtualList={isVirtualList}
          ref={(ref) => (this.containerRef = ref)}
        >
          {shouldRenderBatchActionMenu && this.renderMenuOptions()}
          <TaskBatchActionsAnchor className="batch-actions-anchor">
            {shouldRenderFollowUpModal && this.renderFollowUpModal()}
            {shouldRenderDeleteModal && this.renderDeleteModal()}
            {shouldRenderUnfollowModal && this.renderUnfollowModal()}
          </TaskBatchActionsAnchor>
        </TaskBatchActionMenuWrapper>
        <Modal
          isOpen={processStartedModalType}
          toggle={this.closeProcessStartedModal}
        >
          <ModalBody style={{ paddingBottom: 26 }}>
            <ProcessStartedModalBody
              title={processStartedCopy[processStartedModalType]?.title}
              processStartedMessage={
                processStartedCopy[processStartedModalType]
                  ?.processStartedMessage
              }
              handleOk={this.closeProcessStartedModal}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

TaskBatchActionsMenu.propTypes = {
  authToken: PropTypes.string.isRequired,
  selectedBatchTaskIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleBatchTasksFollowUp: PropTypes.func.isRequired,
  handleBatchDeleteTasks: PropTypes.func.isRequired,
  handleBatchInboxTaskAcceptance: PropTypes.func.isRequired,
  handleBatchComplete: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  authToken: getAuthToken(state),
  taskHash: getHomeTaskObj(state),
  taskFilter: getCurrentFilter(state),
  isOnTeamProject: getIsOnTeamProject(state),
  selectedTeamId: getSelectedTeamId(state),
  matchedParams: getMatchedRouteParams(state),
  isMemberModalOpen: getIsMemberModalOpen(state),
  projectHash: getProjectHash(state)
});

export default withRouter(
  withPermissionsCheck(
    connect(mapStateToProps, {
      copyTasks,
      moveTasks,
      handleTasksAttributesUpdate: triggerTasksAttributesUpdate,
      handleBatchTasksFollowUp: triggerBatchTasksFollowUp,
      moveTasksToTop: triggerBatchMoveTasksToTop,
      handleBatchCopyTasksToProject: triggerBatchCopyTasksToProject,
      handleBatchRevertTasksToIncomplete: triggerBatchRevertTasksToIncomplete,
      handleBatchDeleteTasks: triggerBatchDeleteTasks,
      handleBatchInboxTaskAcceptance: triggerBatchInboxTaskAcceptance,
      handleBatchUnfollowTasks: triggerBatchUnfollowTasks,
      handleTaskCreationServerRequest: triggerTaskCreationServerRequest,
      handleTaskCommentCreation: triggerTaskCommentCreation,
      handleAccountFollowUpMessageUpdate: triggerAccountFollowUpMessageUpdate,
      handleBatchComplete: triggerBatchComplete,
      flushBatchSelectedTasks,
      deleteTasks
    })(TaskBatchActionsMenu)
  )
);
