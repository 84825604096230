import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupsHash, getTeamSlug } from 'selectors';
import styled from 'styled-components';
import theme from 'theme';
import {
  StyledFilterRow,
  CustomCheckBoxContainer,
  CustomCheckBox,
  CheckBoxDiv,
  RowContent,
  StyledProjectDescription,
  StyledProjectTitle,
  ArchivedText,
  StyledFolderIcon,
  CircledRadioCheckBoxContainer,
  CircledRadioCheckBox
} from 'ReportsModule/components/styles';
import {
  InnerDropdownOption,
  StyledListItemContainer
} from 'views/projectPlanner/styles';
import { StyledThreeDot } from 'IntegrationsModule/components/ThreeDot';
import {
  closeSidebarProjects,
  navigateToProject,
  starProject
} from 'actionCreators';
import ProjectColorDot from 'components/ProjectColorDot';
import GripIcon from 'images/grip.svg';
import ProjectDropdown, { StyledDropdown } from './ProjectDropdown';
import FolderIcon from 'images/white-folder-icon.svg';
import StarIcon from 'icons/StarIcon';
import StopIcon from 'images/stop-icon.svg';
import { deserializeBar } from 'appUtils/projectPlannerUtils';
import { PERSONAL_BOARD_NAMES } from 'appConstants';
import Karat from 'images/thin-arrow.svg';
import {
  CustomStyledSelectToggle,
  CustomClickableArea
} from 'FilterModule/components/FilterListsTable/styles';

export const StyledGrip = styled.img`
  height: 11px;
  width: 11px;
  visibility: ${(props) => (props.isHidden ? 'hidden!important' : 'hidden')};
  margin-right: 8px;
`;
export const BoardIcon = styled(StyledFolderIcon)`
  width: 9px;
  height: 8px;
  position: relative;
  bottom: 1px;
  path {
    stroke: ${theme.colors.colorLightGray18};
    fill: transparent !important;
  }
`;
const ProjectInfo = styled.div`
  width: 184px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;
const ProjectArchivedText = styled(ArchivedText)`
  font-size: 10px;
  left: 0;
  top: 3px;
`;
const StarContainer = styled.div`
  .my-star-col {
    width: 100%;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
`;
const RowInfo = styled(InnerDropdownOption)`
  padding: 0;
  height: 100%;
`;
const ProjectNumberAndBoard = styled.div`
  max-width: 100%;
  position: absolute;
  top: 3px;
  color: ${theme.colors.colorLightGray18};
  font-size: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  visibility: hidden;
  & > * {
    margin-right: 5px;
  }
`;
export const StyledProjectRow = styled(StyledFilterRow)`
  ${StyledDropdown} {
    position: absolute;
    right: 20px;
  }
  ${StyledThreeDot} {
    visibility: hidden;
  }
  .filter-list-item-container:hover & {
    ${StyledGrip} {
      visibility: ${(props) => (props.notDragOrderable ? 'hidden' : 'visible')};
    }
    ${StyledThreeDot} {
      visibility: visible;
    }
    ${ProjectNumberAndBoard} {
      visibility: visible;
    }
  }
  ${(props) =>
    props.showSelectionBox &&
    `
    ${StyledDropdown} {
      right: 10px;
    }
    ${ProjectInfo} {
      width: ${props.isSideFilter ? '191px' : '130px'};
    }
    ${StarContainer} {
      margin-right: 24px;
    }
  `}
  ${(props) =>
    props.isSearchResult &&
    `
    ${ProjectNumberAndBoard} {
      visibility: visible;
    }
  `}
`;
const ProjectTitle = styled(StyledProjectTitle)`
  font-weight: 400;
  color: ${theme.colors.colorMediumGray9};
`;
const ProjectDescription = styled(StyledProjectDescription)`
  color: ${theme.colors.colorLightGray18};
  margin: 0;
  max-width: 100%;
`;
const StyledStopIcon = styled.img`
  margin-right: 8px;
  height: 11px;
  width: 11px;
`;

const StyledCountContainer = styled.div`
  display: flex;
  position: relative;
  left: -10px;
`;

const StyledCount = styled.span`
  font-size: 12px;
  margin-right: 7px;
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  color: ${theme.colors.colorMediumGray5};

  ${(props) =>
    props.isHighlighted &&
    `
    color: ${theme.colors.colorCalendarBlue};
    font-weight: 600;
  `}
`;

const Arrow = styled.img`
  transition: 0.2s ease-in-out;
  transform: ${({ isOpen }) => (!isOpen ? 'rotate(0deg)' : 'rotate(90deg)')};
  position: relative;
  left: 1px;
  transform-origin: center 5px;
`;

const StyledSideFilterCheckboxContainer = styled.div`
  height: 100%;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProjectRow = ({
  children,
  index,
  search,
  item,
  isDragging,
  setIsOpen,
  clearSearch,
  isProjectPhaseSelector,
  isSideFilter,
  itemsCount
}) => {
  const {
    list,
    isSelected,
    isHeaderAllSelected,
    headerHandleClick,
    handleClick,
    sectionType,
    uid,
    numPhaseSelected,
    hasOnlyDefaultPhase
  } = item;
  const row = isProjectPhaseSelector ? item : item.row;
  const [showToggle, setShowToggle] = useState(false);
  const boardsHash = useSelector(getGroupsHash);
  const teamSlug = useSelector(getTeamSlug);
  const board = boardsHash[row.board_id];
  const dispatch = useDispatch();
  const { itemType } = deserializeBar(item.uid);

  const itemSelected = itemsCount?.itemSelected;
  const selectionLimit = itemsCount?.selectionLimit;
  const isMaximumSelected = itemSelected && itemSelected >= selectionLimit;

  const selectionIsDisabled = row.selectionIsDisabled;

  const showSelectionBox = !row.isProjectsSidebar;

  const isPartiallySelected =
    isHeaderAllSelected !== undefined &&
    !isHeaderAllSelected &&
    numPhaseSelected > 0;

  const showCheckedOnHover =
    (!isSelected || isPartiallySelected) && !selectionIsDisabled;

  const onClick = (e) => {
    e.stopPropagation();

    if (!isSelected && (selectionIsDisabled || isMaximumSelected)) {
      return;
    }

    if (showSelectionBox) {
      isProjectPhaseSelector ? headerHandleClick(item) : handleClick(item);
      return;
    }

    clearSearch(); // Clear search and navigate, don't cache search
    dispatch(
      navigateToProject({
        teamSlug: row.is_personal ? 'personal' : board?.team_slug || teamSlug,
        projectSlug: row.slug,
        projectId: row.id
      })
    );
  };

  const notDragOrderable = itemType === 'notPersonalBoardProject';

  const getTooltip = () => {
    return `
      <div class="project-row-tooltip">
        <div class="top-row">
          ${
            row.project_number
              ? `<div class="project-number">${row.project_number}</div>`
              : ''
          }
          ${
            board
              ? `
          <img
            class="board-icon"
            src=${FolderIcon}
          />
          <div>${board.name}</div>`
              : ''
          }
        </div>
        <div
          class="project-title break-word-tooltips"
        >
          ${row.title}
        </div>
        ${
          row.description
            ? `<div
                class="project-description break-word-tooltips"
              >
                ${row.description}
              </div>`
            : ''
        }
      </div>
    `;
  };

  const CheckBoxContainer = row.isSingleSelect
    ? CircledRadioCheckBoxContainer
    : CustomCheckBoxContainer;

  const CheckBox = row.isSingleSelect ? CircledRadioCheckBox : CustomCheckBox;

  return (
    <StyledProjectRow
      onClick={
        isProjectPhaseSelector && !hasOnlyDefaultPhase
          ? () => {
              setIsOpen({ uid: row.uid, value: !row.isOpen });
            }
          : onClick
      }
      notDragOrderable={notDragOrderable}
      showSelectionBox={showSelectionBox}
      isSideFilter={isSideFilter}
      isSearchResult={search.projects?.length > 0}
      data-testid={`row-${uid}-${row.projectFilterListId}`}
    >
      <RowInfo
        key="project-item"
        data-for="app-tooltip"
        data-effect="solid"
        data-tip={getTooltip()}
        data-delay-show={500}
        data-scroll-hide={true}
        data-html
      >
        {isDragging && notDragOrderable ? (
          <StyledStopIcon src={StopIcon} />
        ) : (
          <StyledGrip src={GripIcon} isHidden={item.isDragDisabled} />
        )}
        {showSelectionBox &&
          (isSideFilter ? (
            <StyledSideFilterCheckboxContainer>
              <CustomClickableArea
                onClick={
                  isProjectPhaseSelector && !hasOnlyDefaultPhase
                    ? onClick
                    : undefined
                }
                showIndeterminateStateOnHover={
                  isSelected || isHeaderAllSelected
                }
                showCheckedOnHover={showCheckedOnHover}
                toggleSize={14}
                data-for="app-tooltip"
                data-tip={`Maximum of ${itemSelected} of ${selectionLimit} Selected.`}
                data-tip-disable={Boolean(
                  !selectionLimit ||
                    !(
                      isMaximumSelected &&
                      (isPartiallySelected ||
                        !(isSelected || isHeaderAllSelected))
                    )
                )}
              />
              <CustomStyledSelectToggle
                isChecked={isSelected || isHeaderAllSelected}
                isPartiallyChecked={isPartiallySelected}
                isHidden={
                  !(isSelected || isHeaderAllSelected) && selectionIsDisabled
                }
                size={14}
              />
            </StyledSideFilterCheckboxContainer>
          ) : (
            <CheckBoxDiv
              key="1"
              style={{ marginRight: 2 }}
              onClick={
                isProjectPhaseSelector && !hasOnlyDefaultPhase
                  ? onClick
                  : undefined
              }
            >
              <CheckBoxContainer
                isChecked={isSelected || isHeaderAllSelected}
                isHidden={
                  !(isSelected || isHeaderAllSelected) && selectionIsDisabled
                }
              >
                <CheckBox isChecked={isSelected || isHeaderAllSelected} />
              </CheckBoxContainer>
            </CheckBoxDiv>
          ))}

        <ProjectColorDot
          size={8}
          projectId={row.id}
          hasColorPicker
          pickerLocation={`sidebar-${sectionType}-${row.projectFilterListId}-${uid}`}
        />
        <ProjectInfo>
          {row.is_archived ? (
            <ProjectArchivedText>ARCHIVED</ProjectArchivedText>
          ) : (
            <ProjectNumberAndBoard>
              {row.project_number ? <span>{row.project_number}</span> : null}
              {board && (
                <>
                  <BoardIcon />
                  {board.name}
                </>
              )}
            </ProjectNumberAndBoard>
          )}
          <ProjectTitle data-testid={`sidebar-${row.title}`}>
            {row.title}
          </ProjectTitle>
          {row.description && (
            <ProjectDescription>{row.description}</ProjectDescription>
          )}
        </ProjectInfo>
        {row.is_starred &&
          !isProjectPhaseSelector &&
          list?.name !== PERSONAL_BOARD_NAMES['Starred Projects'] && (
            <StarContainer>
              <StarIcon isActive />
            </StarContainer>
          )}
      </RowInfo>
      {isProjectPhaseSelector && !hasOnlyDefaultPhase ? (
        <StyledCountContainer>
          <StyledCount isHidden={row.isOpen} isHighlighted={!!numPhaseSelected}>
            {numPhaseSelected}/{item.displayCount}
          </StyledCount>
          <Arrow src={Karat} isOpen={item?.isOpen} />
        </StyledCountContainer>
      ) : board && !isProjectPhaseSelector ? (
        <ProjectDropdown
          showToggle={showToggle}
          project={row}
          setShowToggle={setShowToggle}
          isProjectsSidebar={row.isProjectsSidebar}
        />
      ) : null}
    </StyledProjectRow>
  );
};

export default ProjectRow;
