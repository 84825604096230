import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import {
  FilterContainer,
  StyledFilter,
  FilterTrigger,
  FilterTriggerButton,
  FilterHeaderContainer,
  CancelButton,
  SaveButton
} from './styles';
import {
  openWorkloadFilter,
  closeWorkloadFilter,
  updateAccountFilter,
  createAccountFilter,
  resetProjectFilterList
} from 'actionCreators';
import OpenFilterMenuIcon from 'icons/OpenFilterMenuIcon';
import { IconContainer } from 'components/GlobalAdd/styles';
import {
  makeGetActiveWorkloadPlannerFilter,
  getVisibleRowsCount,
  getAllSelected
} from 'selectors';
import MemberFilter from './MemberFilter';
import ProjectFilter from './ProjectFilter';
import PositionFilter from './PositionFilter';
import ClientFilter from './ClientFilter';
import {
  FILTER_PAGES,
  FILTER_PAGE_NAMES,
  VIEW_BY
} from 'appConstants/workload';
import ProjectRow from 'views/layoutStructuralComponents/MyProjectSideBarContainer/ProjectRow';
import BoardRow from 'views/layoutStructuralComponents/MyProjectSideBarContainer/BoardRow';
import PhaseRow from 'views/layoutStructuralComponents/MyProjectSideBarContainer/PhaseRow';
import { newFilter } from 'appUtils/filters';
import { StackedFiltersContainer } from 'FilterModule/components/FilterListsTable/StackedFiltersContainer';
import {
  filterListTypeToKey,
  crossFilterFieldOverrideHash
} from 'FilterModule/constants';
import get from 'lodash/get';
import { getSettingsModule } from 'SettingsModule/package/settingsModule';
import RequestsButton from 'views/unplanned/UnplannedTable/RequestsButton';

class Filter extends React.Component {
  state = {
    isCrossFieldFiltersOpen: false,
    numCrossFieldFiltersUsed: 0
  };

  componentDidMount() {
    this.setNumCrossFieldFiltersUsed();
  }

  componentWillUnmount() {
    this.props.resetProjectFilterList({
      filterListId: this.props.filterListId
    });
  }

  componentDidUpdate(prevProps) {
    const { activeFilter } = this.props;
    if (prevProps.activeFilter !== activeFilter) {
      this.setNumCrossFieldFiltersUsed();
    }
  }

  openCrossFieldFilters = () => {
    this.setState({ isCrossFieldFiltersOpen: true });
  };

  hideCrossFieldFilters = () => {
    this.setState({ isCrossFieldFiltersOpen: false });
  };

  setNumCrossFieldFiltersUsed = () => {
    const { activeFilter, crossFieldDependencies } = this.props;
    if (crossFieldDependencies?.length) {
      const numCrossFieldFiltersUsed = crossFieldDependencies.reduce(
        (acc, field) => {
          // handles only array values for now
          const filterValue = get(activeFilter, filterListTypeToKey[field], []);
          // special case for billable where all selected means not filtering
          const isFilterUsed =
            field === 'billable'
              ? filterValue.length === 1
              : filterValue.length > 0;
          return acc + (isFilterUsed ? 1 : 0);
        },
        0
      );
      this.setState({ numCrossFieldFiltersUsed });
    }
  };

  closeFilter = () => {
    const { closeWorkloadFilter, handleClose } = this.props;
    handleClose ? handleClose() : closeWorkloadFilter();
  };

  saveFilter = () => {
    const {
      updateAccountFilter,
      createAccountFilter,
      activeFilter,
      viewBy,
      pageName,
      isNew,
      isNewWidget,
      draftFilter,
      saveOverride
    } = this.props;

    if (saveOverride) {
      saveOverride();
    } else {
      if (draftFilter) {
        draftFilter.meta.hasChanges && draftFilter.save();
      } else {
        if (isNew && !isNewWidget) {
          createAccountFilter({
            ...activeFilter,
            name: viewBy,
            page: pageName
          });
        } else if (activeFilter?.filterChanged && !isNewWidget) {
          updateAccountFilter(activeFilter);
        }
      }
    }
    this.closeFilter();
  };

  handleCancel = () => {
    const { draftFilter } = this.props;
    if (draftFilter?.meta.hasChanges) {
      draftFilter.reset();
    }
    this.closeFilter();
  };

  render() {
    const {
      isOpen,
      openWorkloadFilter,
      viewBy,
      pageName,
      filterEntity,
      visibleRowsCount,
      allSelected,
      filterLimits,
      filterStyling,
      activeFilter,
      filterListId,
      noTrigger,
      innerHeightAdjustment,
      filterContainerClass,
      filterId,
      widgetConfig,
      filterWidth,
      listWidth,
      crossFieldDependencies = emptyArray,
      additionalFilterOptions,
      draftFilter,
      isSideFilter,
      isoStateIdPrefix
    } = this.props;
    const { isCrossFieldFiltersOpen, numCrossFieldFiltersUsed } = this.state;

    const filterCopy = viewBy === 'members' ? 'Member' : 'Project';
    // filterState && filterState.members
    //   ? Object.values(filterState.members).filter(value => !!value).length
    //   : 0;
    const rowCount =
      (viewBy === VIEW_BY.PROJECTS
        ? activeFilter?.project_ids?.length
        : activeFilter?.account_ids?.length) || 0;

    const hasCrossFieldFilters = crossFieldDependencies.length > 0;
    const actualFilterWidth =
      (filterWidth || listWidth) + (hasCrossFieldFilters ? 240 : 0);
    const visibleWidth = isCrossFieldFiltersOpen
      ? actualFilterWidth
      : filterWidth || listWidth;

    return (
      <DynamicModuleLoader modules={[getSettingsModule()]}>
        {!noTrigger && (
          <FilterTrigger
            className={`app-cues-filter-trigger`}
            filterStyling={filterStyling}
          >
            <FilterTriggerButton
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}
              onClick={openWorkloadFilter}
              isFiltered={!allSelected}
              data-testid={'filter-trigger'}
            >
              <IconContainer style={{ marginRight: '0px' }}>
                <OpenFilterMenuIcon />
              </IconContainer>
              {rowCount > 0 && `${rowCount} `}
              {visibleRowsCount == 0 ? 'Select ' : null}
              {filterCopy}s
            </FilterTriggerButton>
            <StyledRequestsButton label="Requests" />
          </FilterTrigger>
        )}
        <FilterContainer
          isOnWorkloadView
          className={filterContainerClass}
          isOpen={isOpen}
          filterWidth={visibleWidth}
        >
          <StyledFilter filterWidth={actualFilterWidth}>
            {/* Header looks different depending on whether cross field filters are open or not */}
            <FilterHeaderContainer
              filterWidth={visibleWidth}
              isCrossFieldFiltersOpen={isCrossFieldFiltersOpen}
            >
              {filterEntity === 'positions' || viewBy === VIEW_BY.POSITIONS
                ? ' Roles'
                : filterEntity === 'members' || viewBy === VIEW_BY.MEMBERS
                ? ' Members'
                : filterEntity === 'projects' || viewBy === VIEW_BY.PROJECTS
                ? ' Projects'
                : filterEntity === 'clients' || viewBy === VIEW_BY.CLIENTS
                ? ' Clients'
                : ''}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CancelButton onClick={this.handleCancel}>Cancel </CancelButton>
                <SaveButton isBlue onClick={this.saveFilter}>
                  Done
                </SaveButton>
              </div>
            </FilterHeaderContainer>

            <FilterColumns>
              {/* Currently only for Planner as selector is VirtualFilter, but stackedfilters uses new infra */}
              {hasCrossFieldFilters && (
                <StackedFiltersContainer
                  handleClose={this.hideCrossFieldFilters}
                  isVisible={isCrossFieldFiltersOpen && isOpen}
                  filterListTypeToFieldOverrideHash={
                    crossFilterFieldOverrideHash
                  }
                  numStackedFiltersUsed={999} // {numCrossFieldFiltersUsed} TODO: fix once Filter.jsx changes merged
                  variant="SideFilter"
                  isoStateIdPrefix={isoStateIdPrefix}
                />
              )}

              <MainFilterContainer $width={filterWidth}>
                {!isOpen ? null : filterEntity === 'positions' ||
                  viewBy === VIEW_BY.POSITIONS ? (
                  <PositionFilter
                    pageName={pageName}
                    viewBy={viewBy}
                    innerHeightAdjustment={innerHeightAdjustment}
                    widgetConfig={widgetConfig}
                    activeFilter={activeFilter}
                    additionalFilterOptions={additionalFilterOptions}
                  />
                ) : filterEntity === 'clients' || viewBy === VIEW_BY.CLIENTS ? (
                  <ClientFilter
                    pageName={pageName}
                    viewBy={viewBy}
                    innerHeightAdjustment={innerHeightAdjustment}
                    widgetConfig={widgetConfig}
                    activeFilter={activeFilter}
                    additionalFilterOptions={additionalFilterOptions}
                  />
                ) : filterEntity === 'members' || viewBy === VIEW_BY.MEMBERS ? (
                  <MemberFilter
                    filterId={filterId}
                    pageName={pageName}
                    viewBy={viewBy}
                    innerHeightAdjustment={innerHeightAdjustment}
                    widgetConfig={widgetConfig}
                    activeFilter={activeFilter}
                    listWidth={listWidth}
                    additionalFilterOptions={additionalFilterOptions}
                    forceShowAllOption
                  />
                ) : filterEntity === 'projects' ||
                  viewBy === VIEW_BY.PROJECTS ? (
                  <ProjectFilter
                    filterId={filterId}
                    pageName={pageName}
                    viewBy={viewBy}
                    filterLimits={filterLimits}
                    customProjectRowComponent={ProjectRow}
                    customPhaseRowComponent={PhaseRow}
                    customBoardRowComponent={BoardRow}
                    showSubStickyHeader
                    initialFilterTab="projectsByBoard"
                    enableDrag
                    filterListId={filterListId}
                    innerHeightAdjustment={innerHeightAdjustment}
                    widgetConfig={widgetConfig}
                    isDefaultAllSelected={pageName === FILTER_PAGES.TIMESHEET}
                    activeFilter={activeFilter}
                    listWidth={listWidth || 250}
                    isProjectPhaseSelector={filterEntity === 'phases'}
                    openCrossFieldFilters={
                      hasCrossFieldFilters
                        ? this.openCrossFieldFilters
                        : undefined
                    }
                    crossFieldDependencies={crossFieldDependencies}
                    isCrossFieldFiltersOpen={isCrossFieldFiltersOpen}
                    numCrossFieldFiltersUsed={numCrossFieldFiltersUsed}
                    additionalFilterOptions={additionalFilterOptions}
                    draftFilter={draftFilter}
                    isSideFilter={isSideFilter}
                  />
                ) : null}
              </MainFilterContainer>
            </FilterColumns>
          </StyledFilter>
        </FilterContainer>
      </DynamicModuleLoader>
    );
  }
}

const makeMapStateToProps = () => {
  const getActiveWorkloadPlannerFilter = makeGetActiveWorkloadPlannerFilter();
  const mapStateToProps = (state, ownProps) => {
    const activeFilter = getActiveWorkloadPlannerFilter(state, ownProps);
    const hasTrigger = !ownProps.noTrigger;
    return {
      isOpen: ownProps.isOpen ?? state.workloadPlannerFilter.isOpen,
      activeFilter,
      isNew: !activeFilter || activeFilter.id === newFilter.id,
      visibleRowsCount: hasTrigger
        ? getVisibleRowsCount(state, ownProps)
        : null,
      allSelected: hasTrigger ? getAllSelected(state, ownProps) : null
    };
  };
  return mapStateToProps;
};
const mapDispatchToProps = {
  openWorkloadFilter,
  closeWorkloadFilter,
  updateAccountFilter,
  createAccountFilter,
  resetProjectFilterList
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Filter);

/* ------------------------------------ - ----------------------------------- */

const emptyArray = [];

const FilterColumns = styled.div`
  display: flex;
  min-height: 0;
  flex: 1;
`;

const MainFilterContainer = styled.div`
  width: ${({ $width }) => $width}px;
`;

const StyledRequestsButton = styled(RequestsButton)`
  && {
    right: 5px;
  }
`;
