import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';
import produce from 'immer';

export const initialState = {
  settings: {}
};
const byId = (item) => item.id;

export default (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case budgetConstants.FETCH_PROJECT_BUDGET_VIEW_SETTINGS.SUCCESS: {
        const { response } = action.payload;
        draft.settings[response.viewable_id] = response;
        break;
      }
      case budgetConstants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.TRIGGER: {
        const { budgetSettings, projectId } = action.payload;
        draft.settings[projectId] = {
          ...state.settings[projectId],
          ...budgetSettings
        };
        break;
      }
      case budgetConstants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.FAILURE: {
        const { original, projectId } = action.payload.requestPayload;
        if (original) {
          draft.settings[projectId] = original;
        }
        break;
      }
      case budgetConstants.UPDATE_PROJECT_BUDGET_VIEW_SETTINGS.SUCCESS: {
        const { response } = action.payload;
        draft.settings[response.viewable_id] = response;
        break;
      }
    }
  });
};
