import { Draft, createReducer } from '@reduxjs/toolkit';
import { SuggestionState } from 'SuggestionModule/types';
import {
  phaseSuggestionsActionCreatorsMap,
  phaseSuggestionsActions,
  accountsSuggestionActionCreatorsMap,
  accountsSuggestionActions
} from 'SuggestionModule/actionCreators';
import { SuggestionSpace } from '../constants';
import keyBy from 'lodash/keyBy';

export const initialState: SuggestionState = {
  suggestedPhasesForAccounts: {
    [SuggestionSpace.Timesheet]: {} // Keep isolated from other spaces
  },
  suggestedAccountsForProjects: {},
  suggestedAccountsForPhases: {}
};

const handleFetchPhaseSuggestionsForTimesheetAccountsTrigger = (
  state: Draft<SuggestionState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: ReturnType<
    typeof phaseSuggestionsActions.fetchPhaseSuggestionsForTimesheetAccounts
  >
) => {
  state.suggestedPhasesForAccounts[SuggestionSpace.Timesheet] = {};
};

const handleFetchPhaseSuggestionsForTimesheetAccountsSuccess = (
  state: Draft<SuggestionState>,
  action: ReturnType<
    typeof phaseSuggestionsActionCreatorsMap.fetchPhaseSuggestionsForTimesheetAccounts.success
  >
) => {
  state.suggestedPhasesForAccounts[SuggestionSpace.Timesheet] =
    action.payload.response;
};

const handleFetchAccountsSuggestionsForProjectsTrigger = (
  state: Draft<SuggestionState>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: ReturnType<
    typeof accountsSuggestionActions.fetchAccountsSuggestionForProjects
  >
) => {
  const { isoStateId } = action.payload;
  state.suggestedAccountsForProjects = {
    ...state.suggestedAccountsForProjects,
    [isoStateId]: {}
  };
};

const handleFetchAccountsSuggestionsForProjectsSuccess = (
  state: Draft<SuggestionState>,
  action: ReturnType<
    typeof accountsSuggestionActionCreatorsMap.fetchAccountsSuggestionForProjects.success
  >
) => {
  const { response } = action.payload;
  const { isoStateId } = action.payload.initialPayload;

  state.suggestedAccountsForProjects = {
    ...state.suggestedAccountsForProjects,
    [isoStateId]: keyBy(response.suggestion_responses, 'project_id')
  };
};

const handleFetchAccountsSuggestionsForPhasesTrigger = (
  state: Draft<SuggestionState>,
  action: ReturnType<
    typeof accountsSuggestionActions.fetchAccountsSuggestionForPhases
  >
) => {
  const { isoStateId } = action.meta;
  state.suggestedAccountsForPhases = {
    ...state.suggestedAccountsForPhases,
    [isoStateId]: {}
  };
};

const handleFetchAccountsSuggestionsForPhasesSuccess = (
  state: Draft<SuggestionState>,
  action: ReturnType<
    typeof accountsSuggestionActionCreatorsMap.fetchAccountsSuggestionForPhases.success
  >
) => {
  const { response } = action.payload;
  const { isoStateId } = action.meta;

  state.suggestedAccountsForPhases = {
    ...state.suggestedAccountsForPhases,
    [isoStateId]: keyBy(response.suggestion_responses, 'phase_id')
  };
};

/**
 * Main reducer that handles all suggestion related global state
 * Do Not use/add to the `suggestions` reducer in `js` folder
 */
const reducer = createReducer(initialState, (builder) => {
  /* ---------------------------- Phase Suggestion ---------------------------- */
  builder.addCase(
    phaseSuggestionsActions.fetchPhaseSuggestionsForTimesheetAccounts,
    handleFetchPhaseSuggestionsForTimesheetAccountsTrigger
  );
  builder.addCase(
    phaseSuggestionsActionCreatorsMap.fetchPhaseSuggestionsForTimesheetAccounts
      .success,
    handleFetchPhaseSuggestionsForTimesheetAccountsSuccess
  );
  /* --------------------------- Accounts Suggestion -------------------------- */
  builder.addCase(
    accountsSuggestionActions.fetchAccountsSuggestionForProjects,
    handleFetchAccountsSuggestionsForProjectsTrigger
  );
  builder.addCase(
    accountsSuggestionActionCreatorsMap.fetchAccountsSuggestionForProjects
      .success,
    handleFetchAccountsSuggestionsForProjectsSuccess
  );
  builder.addCase(
    accountsSuggestionActions.fetchAccountsSuggestionForPhases,
    handleFetchAccountsSuggestionsForPhasesTrigger
  );
  builder.addCase(
    accountsSuggestionActionCreatorsMap.fetchAccountsSuggestionForPhases
      .success,
    handleFetchAccountsSuggestionsForPhasesSuccess
  );
});

export default reducer;
