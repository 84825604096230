import React, { useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import {
  makeGetActiveWorkloadPlannerFilter,
  getScheduleViewBy
} from 'selectors';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';
import SchedulePlannerContainer from 'views/projectPlanner/SchedulePlannerContainer';
import WorkloadPlannerContainer from 'views/projectPlanner/WorkloadPlannerContainerContainer';
import { FILTER_PAGES } from 'appConstants/workload';
import { setWorkloadViewBy } from 'actionCreators';
import { Page } from 'models/filter';
import { SideFilter } from 'FilterModule/components/SideFilter';
import { PageContainer } from 'views/PageContainer';
import { initialFilterLevelSchemas } from '../projectPlanner/filterConfig';

const pageName = Page.WorkloadPlanner;

const ScheduleContainer = () => {
  const dispatch = useAppDispatch();

  const viewBy = useAppSelector(getScheduleViewBy);

  const matchedParams = useAppSelector(getMatchedRouteParams);

  const getActiveFilter = useMemo(makeGetActiveWorkloadPlannerFilter, []);

  const activeFilter = useAppSelector((state) =>
    getActiveFilter(state, { viewBy, pageName })
  );

  // only used for task view
  const pageContainerProps = useMemo(
    () => ({
      pageContextProviderProps: {
        currentView: viewBy,
        pageName
      },
      filterContextProviderProps: {
        filterSchemaParams: {
          initialFilterLevelSchemas,
          pageName,
          level2: viewBy
        },
        // making sure the same filter is used (other parts of this page are using older
        // activeFilter infra)
        matchingFiltersOverride: [activeFilter]
      }
    }),
    [activeFilter, viewBy]
  );

  useEffect(() => {
    dispatch(setWorkloadViewBy({ viewBy: 'projects' }));
  }, [dispatch]);

  return (
    <>
      {matchedParams?.plannerViewType === 'workplans' ? (
        <WorkloadPlannerContainer
          pageName={FILTER_PAGES.WORKLOAD_PLANNER}
          viewBy={'projects'}
          plannerType="workload"
        />
      ) : (
        <PageContainer {...pageContainerProps}>
          <SideFilter variant="WorkloadPlannerSideFilter" />
          <SchedulePlannerContainer
            pageName={FILTER_PAGES.WORKLOAD_PLANNER}
            viewBy={viewBy}
            plannerType="workload"
          />
        </PageContainer>
      )}
    </>
  );
};

export default React.memo(ScheduleContainer);
