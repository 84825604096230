import { useMemo, useCallback } from 'react';
import { useAppDispatch } from 'reduxInfra/hooks';
import styled from 'styled-components';
import theme from 'theme';
import { navigateToMemberModal } from 'actionCreators';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import KaratRight from 'icons/KaratRight';
import SmallUnassignedMemberIcon from 'icons/SmallUnassignedMemberIcon';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { Handlers, ProjectTotal } from './types';
import useMultiLevelDropdown from 'components/MultiLevelDropdown/useMultiLevelDropdown';
import { MOUSE_EVENTS } from 'components/MultiLevelDropdown';

enum MemberMenuOptions {
  AddMember = 'addMember',
  AssignAllHoursToPhaseMember = 'AssignAllHoursToPhaseMember',
  AssignToPhases = 'assignToPhases',
  MemberProfile = 'memberProfile',
  ViewAvailability = 'viewAvailability',
  ViewOrgChart = 'viewOrgChart'
}

const noop = () => undefined;

type UseMemberOptionHookArgs = {
  handleOpenMergeBudgetModal: Handlers['handleOpenMergeBudgetModal'];
  resetUnassignedRoleAndSelectedPhaseIds: Handlers['resetUnassignedRoleAndSelectedPhaseIds'];
  teamMembershipsByAccountId: ReturnType<typeof getTeamMembershipsByAccountId>;
  accountId: number;
  unassignedMemberBudgetId: number;
  projectId: number;
  projectTotal: ProjectTotal;
};

export const useMemberOptions = ({
  handleOpenMergeBudgetModal,
  resetUnassignedRoleAndSelectedPhaseIds,
  teamMembershipsByAccountId,
  accountId,
  unassignedMemberBudgetId,
  projectId,
  projectTotal
}: UseMemberOptionHookArgs) => {
  const dispatch = useAppDispatch();

  const { dropdownRef, openDropdown, closeDropdown, MultiLevelDropdown } =
    useMultiLevelDropdown();

  // Assign this suggested member the total estimated hours and planned hours of this unassigned role
  const handleAssignAllHoursToPhaseMember = useCallback(() => {
    const member = teamMembershipsByAccountId[accountId];

    if (member && unassignedMemberBudgetId) {
      const account = member?.account;
      if (account) {
        const accountTotal = projectTotal.account_totals?.find(
          (accountTotal) =>
            accountTotal.member_budget_id === unassignedMemberBudgetId
        );
        const totalEstimatedHours = accountTotal?.estimated?.hours || 0;
        const totalPlannedHours = accountTotal?.planned?.hours || 0;

        handleOpenMergeBudgetModal({
          assignedAccountId: account.id,
          assignedName: account.name,
          unassignedMemberBudgetId,
          projectId,
          totalEstimatedHours,
          totalPlannedHours,
          onAssignMemberBudgetSuccess: [
            {
              successAction: () => {
                resetUnassignedRoleAndSelectedPhaseIds();
              },
              selector: (_payload: unknown, _response: unknown) => ({
                _payload,
                _response
              })
            }
          ]
        });

        closeDropdown();
      }
    }
  }, [
    teamMembershipsByAccountId,
    accountId,
    unassignedMemberBudgetId,
    projectTotal.account_totals,
    handleOpenMergeBudgetModal,
    projectId,
    closeDropdown,
    resetUnassignedRoleAndSelectedPhaseIds
  ]);

  const handleViewOrgChart = useCallback(() => noop, []);

  const handleViewAvailability = useCallback(() => noop, []);

  const handleOpenMemberProfile = useCallback(() => {
    closeDropdown();
    dispatch(
      navigateToMemberModal({
        memberId: accountId,
        memberViewType: 'profile',
        membersViewType: 'members'
      })
    );
  }, [accountId, closeDropdown, dispatch]);

  const handleAssignToPhases = useCallback(() => noop, []);

  const menuContent = useMemo(() => {
    return {
      items: [
        {
          name: MemberMenuOptions.AddMember,
          label: (
            <StyledDropdownItem>
              <span className="left-add-member-icon">
                <PlusCircleIcon
                  pathStroke={theme.colors.colorMediumGray9}
                  stroke={theme.colors.colorMediumGray9}
                  width={14}
                  height={18}
                />
              </span>
              <span className="label">Add Member</span>
              <span>
                <KaratRight />
              </span>
            </StyledDropdownItem>
          ),
          subMenu: {
            items: [
              {
                name: MemberMenuOptions.AssignAllHoursToPhaseMember,
                label: (
                  <span>
                    {/* This is exactly what this option is doing even though Figma's wording is different. Can't afford to confuse members
                      i.e: Assigning all estimated and planned hours of this unassigned role across all Phases of this project to this member, making the unassigned role disappears 
                    */}
                    Assign Phase Hours to Member
                  </span>
                ),
                subMenu: undefined
              }
              // {
              //   name: MemberMenuOptions.AssignToPhases,
              //   label: 'Assign to multiple Phases',
              //   subMenu: undefined
              // }
            ]
          }
        },
        /* ---------------------------------- TODO ---------------------------------- */
        {
          name: MemberMenuOptions.MemberProfile,
          label: (
            <StyledDropdownItem>
              <span>
                <SmallUnassignedMemberIcon width={14} height={18} />
              </span>
              <span className="label">Member Profile</span>
            </StyledDropdownItem>
          ),
          subMenu: undefined
        }
        // {
        //   name: MemberMenuOptions.ViewAvailability,
        //   label: (
        //     <StyledDropdownItem>
        //       <span>
        //         <TwoStackIcon width={14} height={18} />
        //       </span>
        //       <span className="label">View Availability</span>
        //     </StyledDropdownItem>
        //   ),
        //   subMenu: undefined
        // },
        // {
        //   name: MemberMenuOptions.ViewOrgChart,
        //   label: (
        //     <StyledDropdownItem>
        //       <span>
        //         <OrgIcon width={14} height={18} />
        //       </span>
        //       <span className="label">View Org Chart</span>
        //     </StyledDropdownItem>
        //   ),
        //   subMenu: undefined
        // }
      ]
    };
  }, []);

  const onClickHash = useMemo(() => {
    const value = {
      [MemberMenuOptions.AssignAllHoursToPhaseMember]:
        handleAssignAllHoursToPhaseMember,
      [MemberMenuOptions.AssignToPhases]: handleAssignToPhases,
      [MemberMenuOptions.MemberProfile]: handleOpenMemberProfile,
      [MemberMenuOptions.ViewAvailability]: handleViewAvailability,
      [MemberMenuOptions.ViewOrgChart]: handleViewOrgChart
    };

    return value;
  }, [
    handleAssignAllHoursToPhaseMember,
    handleAssignToPhases,
    handleOpenMemberProfile,
    handleViewAvailability,
    handleViewOrgChart
  ]);

  const MemberOptionMenu = useCallback(() => {
    return (
      <MultiLevelDropdown
        menuContent={menuContent}
        onClickHash={onClickHash}
        openMethod={MOUSE_EVENTS.CLICK}
      />
    );
  }, [MultiLevelDropdown, menuContent, onClickHash]);

  return {
    MemberOptionMenu,
    menuRef: dropdownRef,
    openMenu: openDropdown,
    closeMenu: closeDropdown
  };
};

const StyledDropdownItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .label {
    padding: 0 20px 0 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  }
`;
