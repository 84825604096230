import styled, { css } from 'styled-components';
import theme from 'theme';
import { DropdownMenu, Dropdown } from 'reactstrap';
import ReassignIcon from 'icons/ReassignIcon';
import LockIcon from 'icons/LockIcon';
import {
  StyledStickyCellContainer,
  StyledStickyHeader
} from 'components/Table/TableStickyHeader';
import { Styles } from 'components/Table/styles';
import { StyledMemberCircle } from 'views/memberDisplay/MemberInitials.js';
import {
  StyledLegendBox,
  IssueIconContainer
} from 'BudgetModule/components/styles';

export const EmptyCell = styled.div`
  height: 100%;
  width: 100%;
`;

export const CollapseIconContainer = styled.div<{
  hide: boolean;
  collapsed: boolean;
  isInTable: boolean;
}>`
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
  cursor: pointer;
  path {
    transform-origin: center center;
    transform: ${(props) => (props.collapsed ? `rotate(-90deg)` : ``)};
    transition: 0.2s all;
  }
  rect {
    stroke: ${(props) =>
      props.isInTable
        ? 'transparent'
        : props.collapsed
        ? theme.colors.colorPaleGray5
        : `transparent`};
    fill: ${theme.colors.colorPureWhite};
  }

  &:hover {
    path {
      fill: #0074d9;
    }
    rect {
      fill: ${theme.colors.colorTranslucentGray3};
    }
  }
`;

export const StyledMemberColumnHeader = styled.div`
  color: ${theme.colors.colorMediumGray10};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding-bottom: 2px;
`;

export const PercentageOfTotal = styled.div<{
  isRemainingCostNegative?: boolean;
}>`
  font-size: 13px;
  font-weight: 400;
  z-index: 1;
  color: ${({ isRemainingCostNegative }) =>
    isRemainingCostNegative
      ? theme.colors.colorDeleteRed
      : theme.colors.colorCalendarGray};
  position: absolute;
  bottom: 4px;
  left: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${StyledLegendBox} {
    margin-right: 3px;
  }
`;

export const StyledLockIcon = styled(LockIcon)<{ isActive?: boolean }>`
  height: 12px;
  path {
    fill: ${({ isActive }) =>
      isActive
        ? theme.colors.colorSemiDarkGray1
        : theme.colors.colorMediumGray1};
  }
  margin-right: 4px;
  margin-top: -2px;
`;

export const StyledBudgetStatusLabel = styled.div<{ isArchivedPhase: boolean }>`
  z-index: 1;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGrey10};

  padding: 0 4px;
  line-height: 13px;
  min-width: 37px;
  flex-shrink: 0;
  border-radius: 4px;
  margin-left: 23px;
  ${({ isArchivedPhase }) =>
    !isArchivedPhase &&
    `
    background: ${theme.colors.colorPaleGray2};
    &:hover {
      background: ${theme.colors.colorCalendarBlue};
      color: ${theme.colors.colorPureWhite};
    }
  `}
`;

export const BaseIconContainer = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDot = styled.div<{ title: string }>`
  background: ${({ title }) => calcColor[title]};
  height: 8px;
  width: 8px;
  margin-right: 5px;
  margin-top: 1px;
  border-radius: 100px;
`;
export const calcColor = {
  spent: theme.colors.colorBudgetGreen,
  planned: theme.colors.colorPlannedHours,
  remaining: theme.colors.colorBudgetLightGreen,
  overbudget: theme.colors.colorCalendarRed,
  estimated: theme.colors.colorMediumGray1,
  fee: theme.colors.colorBudgetBlue,
  billingEstimate: theme.colors.colorRemainingTeal,
  profit: theme.colors.colorMediumGreen5,
  loss: theme.colors.colorCalendarRed,
  actual: theme.colors.colorBudgetGreen
};

export const HeaderTableRow = styled.div`
  border-left: 1px solid ${theme.colors.colorLightGray4};
  display: flex;
  th {
    border-right: 1px solid ${theme.colors.colorLightGray4};
  }
`;

export const Table = styled.table<{ layout: string; isArchived: boolean }>`
  table-layout: ${(props) => props.layout};
  border-collapse: collapse;
  position: relative;
  opacity: ${({ isArchived }) => (isArchived ? '0.6' : '1')};
  ${HeaderTableRow} :first-child {
    height: 25px;
    background: ${theme.colors.colorPureWhite};
  }
  ${HeaderTableRow} :nth-child(2) {
    border-top: 1px solid ${theme.colors.colorLightGray4};
    border-bottom: 1px solid ${theme.colors.colorLightGray4};
    height: 25px;
  }
`;
export const ProjectFooterTable = styled(Table)`
  width: 100%;
`;

export const PhaseHeadTable = styled(Table)<{
  isSettings: boolean;
  hide: boolean;
}>`
  /* account for spacing in scroll container that allows for three dot menus on member cards */
  ${(props) =>
    props.isSettings
      ? `margin: 0px auto;`
      : `
  position: relative;
  top: 10px;
  z-index: 100;
  `}
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`;

export const TableWrapper = styled.div``;
export const TBody = styled.tbody`
  border: 0;
`;

export const THead = styled.div`
  border: 0;
  border-bottom: none;
  margin-left: 55px;
  background: ${theme.colors.colorPureWhite};
  visibility: ${(hide) => (hide ? 'hidden' : 'visible')};
`;

export const TH = styled.div<{
  isSettings: boolean;
  hideHeader: boolean;
  header?: string;
  noWrap?: boolean;
  width?: number;
}>`
  min-width: ${(props) => (props.isSettings ? '45px' : '65px')};
  visibility: ${(props) => (props.hideHeader ? 'hidden' : 'visible')};
  width: 105px;
  border: 1px solid ${theme.colors.colorLightGray6};
  border-right: 0px;

  &:last-child {
    border-right: 1px solid ${theme.colors.colorLightGray6};
  }
  font-size: 12px;
  font-weight: ${(props) => (props.isSettings ? '600' : '400')};
  text-align: center;
  background: ${theme.colors.colorTranslucentGray4};
  font-family: 'proxima-nova';
  padding: 0px;
  position: relative;
  z-index: ${({ header }) => (header ? 500 : '')};
  color: ${(props) =>
    props.isSettings
      ? theme.colors.colorSemiDarkGray1
      : theme.colors.colorMediumGray5};

  svg {
    margin-top: -2px;
  }

  ${(props) => props.noWrap && `white-space: nowrap;`}

  ${(props) =>
    props.header &&
    `text-transform: uppercase;
    `}
    ${(props) => props.width && `width: ${props.width}px;`}
`;
export const TFoot = styled.tfoot`
  border: 0;
  border-top: 1px solid ${theme.colors.colorLightGray6};

  ${TH} {
    height: 44px;
  }
`;

export const StyledDragGrips = styled.div<{
  isDragging: boolean;
  allCollapsed: boolean;
}>`
  position: absolute;
  visibility: ${(props) =>
    props.isDragging || props.allCollapsed ? 'visible' : 'hidden'};
  z-index: 100;
  width: 30px;
  left: -30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;

  &:hover {
    visibility: visible;
  }
`;

export const MemberThreeDotMenuContainer = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : '')};
  width: ${({ show }) => (show ? '28px' : '0px')};
  margin-right: ${({ show }) => (show ? '0' : '10px')};
  transition: 0.275s ease-in-out;
  svg {
    path {
      fill: ${theme.colors.colorMediumGray5};
      stroke: ${theme.colors.colorMediumGray5};
    }
  }
`;
export const Row = styled.div<{
  hideInactiveMembers: boolean;
  isMemberArchived: boolean;
  isDragging: boolean;
}>`
  display: ${({ hideInactiveMembers }) => (hideInactiveMembers ? 'none' : '')};
  border: none;
  border-left: none;
  border-right: none;
  position: relative;
  cursor: pointer !important;
  opacity: ${({ isMemberArchived }) => (isMemberArchived ? '0.6' : '1')};

  &:not(:first-child) {
    border-top: 1px solid ${theme.colors.colorPaleGray6};
  }

  &:hover {
    ${StyledDragGrips} {
      visibility: visible;
    }
    ${MemberThreeDotMenuContainer} {
      display: flex;
      width: 28px;
      margin-right: 0px;
    }
  }
  ${(props) =>
    props.isDragging
      ? `background: ${theme.colors.colorPureWhite}; border-bottom: 1px solid  ${theme.colors.colorPaleGray6};`
      : ''};
`;

export const TD = styled.td``;

export const Cell = styled.div<{ width: number }>`
  box-sizing: border-box;
  padding: 0;
  min-width: 114px;
  height: 100%;
  ${(props) => props.width && `width: ${props.width}px;`}
`;

export const CellContent = styled.div<{
  phaseName: unknown;
  mainTotals: unknown;
  isBold: boolean;
  isSemiBold: boolean;
  isEstimatedHours: boolean;
  rightAlign: boolean;
  isCollapsed: boolean;
}>`
  white-space: nowrap;
  font-size: ${(props) => (props.phaseName ? '15px' : '14px')};
  background: ${(props) =>
    props.mainTotals
      ? theme.colors.colorPureWhite
      : theme.colors.colorTranslucentGray4};
  font-weight: ${(props) =>
    props.isBold
      ? '800'
      : props.mainTotals || props.phaseName || props.isSemiBold
      ? '600'
      : 'inherit'};
  color: ${theme.colors.colorSemiDarkGray1};
  position: relative;
  height: 52px;
  max-width: 221px;
  padding: ${(props) =>
    props.isEstimatedHours || props.phaseName ? '0px 0px' : '0px 6px'};
  display: flex;
  justify-content: ${(props) =>
    props.phaseName && !props.rightAlign ? 'flex-start' : 'flex-end'};
  align-items: center;
  border-right: ${(props) =>
    props.phaseName && !props.mainTotals
      ? `1px solid ${theme.colors.colorLightGray6}`
      : '1px solid transparent'};
  border-left: ${(props) =>
    props.phaseName && (!props.mainTotals || props.isCollapsed)
      ? `1px solid ${theme.colors.colorLightGray6}`
      : 'none'};

  .estimated-hours-form {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    color: ${theme.colors.colorRoyalBlue};
    font-size: 14px;
    border: 1px solid transparent;
    &:hover,
    &:focus-within {
      border: 1px solid ${theme.colors.colorRoyalBlue};
      color: ${theme.colors.colorRoyalBlue};
    }
    &:focus-within {
      background: ${theme.colors.colorPureWhite};
    }
  }
  .estimated-hours-input {
    border: 1px solid transparent;
    width: 90px;
    background: transparent;
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${theme.colors.colorRoyalBlue};
    font-size: 14px;
    &::placeholder {
      color: ${theme.colors.colorRoyalBlue};
    }
    &:focus {
      &::placeholder {
        color: ${theme.colors.colorMediumGray1};
      }
    }
  }
  .estimated-cost-input {
    &::placeholder {
      color: ${theme.colors.colorMediumGray1};
    }
  }
  .estimated-percent-form {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${theme.colors.colorRoyalBlue};
    border: 1px solid transparent;
    &:hover,
    &:focus-within {
      border: 1px solid ${theme.colors.colorRoyalBlue};
    }
    &:focus-within {
      background: ${theme.colors.colorPureWhite};
      color: ${theme.colors.colorRoyalBlue};
    }
  }
  .estimated-percent-input {
    border: 1px solid transparent;
    width: 95px;
    background: transparent;
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${theme.colors.colorRoyalBlue};

    &:focus {
      color: ${theme.colors.colorRoyalBlue};
      &::placeholder {
        color: ${theme.colors.colorMediumGray1};
      }
    }
    &::placeholder {
      color: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const AddPhaseOfWork = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  font-weight: 400;
  font-family: 'proxima-nova';
  cursor: pointer;
  svg {
    margin-right: 8px;
  }

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    path {
      fill: ${theme.colors.colorRoyalBlue};
    }
  }
`;

export const EmptyPhaseName = styled.div`
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.colorRoyalBlue};
  margin-top: -10px;
  border-radius: 100px;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledPhaseName = styled.div`
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
  background: ${theme.colors.colorPureWhite};
`;

export const StyledPhaseTableNameContainer = styled.div`
  background: transparent;
`;

export const HiddenRow = styled.div<{ isSettings: boolean }>`
  height: 0px;
  visibility: hidden;
  display: ${({ isSettings }) => (isSettings ? 'none' : '')};
  ${Cell}, ${CellContent} {
    height: 0px !important;
  }
`;

export const StyledPhaseTablesContainer = styled.div<{ moveDown: boolean }>`
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;
  padding-right: 60px;
  margin-right: -60px;
  z-index: 100;
  margin-left: -100px;
  margin-top: ${({ moveDown }) => (moveDown ? '52px' : '')};
  padding-left: 100px;
  max-height: calc(100vh - 295px);
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  position: relative;
`;

export const SettingsWheelContainer = styled.div`
  position: absolute;
  cursor: pointer;
  right: 25px;
  display: none;
  z-index: 101;
  top: 75px;

  &:hover {
    svg {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;
export const StyledPhaseTableWrapper = styled.div<{
  allCollapsed: boolean;
  moveDown: boolean;
}>`
  border-left: 1px solid transparent;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  ${({ allCollapsed }) => (allCollapsed ? `padding-bottom: 0px;` : '')};

  margin-top: ${({ moveDown }) => (moveDown ? '52px' : '')};
  ${StyledDragGrips} {
    top: 2px;
  }
  :hover {
    ${StyledDragGrips} {
      visibility: visible;
    }
  }
`;

export const EmptyDefaultHeaderCell = styled.div<{
  inHeader: boolean;
  height?: number;
}>`
  font-size: 13px;
  color: ${theme.colors.colorRoyalBlue};
  font-weight: 400;
  position: ${({ inHeader }) => (inHeader ? 'relative' : '')};
  top: ${({ inHeader }) => (inHeader ? '14px' : '')};
  padding-bottom: 2px;
  ${(props) => props.height && `height: ${props.height}px;`}
`;

export const ArchivedPhaseText = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 6px;
  color: ${theme.colors.colorMediumGray5};
`;

export const StyledPhaseTableName = styled.div<{ calendarOpen: boolean }>`
  position: sticky;
  top: 0px;
  height: 100%;
  z-index: ${(props) => (props.calendarOpen ? 1000 : 1)};
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  ${EmptyDefaultHeaderCell} {
    font-weight: 600;
  }
`;
export const StyledPhaseNameSticky = styled.div`
  border-right: none;
  font-size: 14px;
  height: 100%;
  width: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
`;

export const PhaseProgressContainer = styled.div<{
  isCollapsed: boolean;
  show: boolean;
  inHeader: boolean;
}>`
  display: ${({ isCollapsed, show }) =>
    isCollapsed || !show ? 'none' : 'flex'};
  flex-direction: column;
  width: 112px;
  height: 100%;
  justify-self: flex-end;
  font-size: 11px;
  text-align: center;
  position: absolute;
  bottom: 4px;
  left: 220px;
  ${(props) =>
    props.inHeader
      ? `position: relative;
          left: 10px;
          bottom: 5px;
          height: 32px;`
      : ''}
`;
export const BudgetProgressBar = styled.div<{ isHidden: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  position: relative;
  width: 100%;
  font-size: 15px;
  z-index: 10;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  input {
    width: 100%;
    height: 100%;
    text-align: center;
  }
`;

export const ProgressBackground = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  height: 5px;
  left: 0;
  border-radius: 10px;
  bottom: 0;
  width: 100%;
  display: flex;
  background: ${({ isCollapsed }) =>
    isCollapsed ? 'transparent' : theme.colors.colorBudgetLightGreen};
`;

export const ProgressBackgroundSpent = styled.div<{
  isCollapsed: boolean;
  width: number;
}>`
  background: ${({ isCollapsed }) =>
    isCollapsed ? 'transparent' : theme.colors.colorBudgetGreen};
  height: 100%;
  flex: ${({ width }) => width};
`;
export const ProgressBackgroundPlanned = styled.div<{
  isCollapsed: boolean;
  width: number;
}>`
  background: ${({ isCollapsed }) =>
    isCollapsed ? 'transparent' : theme.colors.colorPlannedHours};
  height: 100%;
  flex: ${({ width }) => width};
`;
export const ProgressBackgroundRemaining = styled.div<{
  isCollapsed: boolean;
  width: number;
}>`
  background: ${({ isCollapsed }) =>
    isCollapsed ? 'transparent' : theme.colors.colorBudgetLightGreen};
  height: 100%;
  flex: ${({ width }) => width};
`;
export const ProgressBackgroundOverBudget = styled.div<{
  isCollapsed: boolean;
  width: number;
}>`
  background: ${({ isCollapsed }) =>
    isCollapsed
      ? 'transparent'
      : `repeating-linear-gradient(
     135deg,
      transparent,
      transparent 2px,
      #ff0000 4px
)`};
  position: absolute;
  height: 100%;
  width: ${({ width }) => width + '%'};
  max-width: 100%;
  right: 0px;
`;

export const MilestoneProgressBarContainer = styled.div<{
  isDisabled: boolean;
  isDefaultPhase: boolean;
}>`
  font-weight: 400;
  padding: 1px 0px 6px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.isDisabled
      ? theme.colors.colorCalendarGray
      : theme.colors.colorSemiDarkGray1};
  font-weight: 400;
  text-align: center;
  justify-content: flex-start;
  font-size: 14px;
  padding-left: ${({ isDefaultPhase }) => (isDefaultPhase ? '9px' : '46px')};
  cursor: ${(props) => (props.isDisabled ? `default` : 'pointer')};
  z-index: 1;

  .dates-row {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 56px;
      font-size: 12px;
      font-weight: 400;
      border: none;
      background: transparent;
      text-align: center;
      padding: 0;
      color: ${theme.colors.colorRoyalBlue};
    }
  }
  &:hover {
    font-weight: 600;
  }
`;

export const ShowOnHover = styled.div``;
export const IconsContainer = styled.div<{
  isCollapsed: boolean;
  open: boolean;
  hide: boolean;
}>`
  display: flex;
  transition: all 0.25s;

  ${ShowOnHover} {
    position: absolute;
    top: ${({ isCollapsed }) => (isCollapsed ? '12px' : '0px')};
    left: 0px;
    width: 175px;
    opacity: ${(props) => (props.open ? 1 : 0)};
    transition: all 0.25s;
  }

  :hover {
    ${ShowOnHover} {
      opacity: ${(props) => (props.hide ? 0 : 1)};
    }
  }
`;
export const ThreeDotMenuContainer = styled.div`
  margin-left: -4px;
  position: relative;
  top: 1px;
  svg {
    height: 22px;
    stroke-width: 0;
    path {
      fill: ${theme.colors.colorSemiDarkGray1};
      stroke: ${theme.colors.colorSemiDarkGray1};
    }
  }
`;

export const RatesDropdownContainer = styled.div<{
  inTableCell: boolean;
  inMemberModal?: boolean;
}>`
  cursor: pointer;
  justify-content: left;
  align-items: center;
  height: ${({ inMemberModal }) => (inMemberModal ? '0' : '100%')};
  width: ${({ inTableCell }) => (inTableCell ? '100%' : '200px')};
  display: ${({ inTableCell }) => (inTableCell ? 'flex' : 'none')};
`;

export const RatesContainer = styled.div<{ isEmpty: boolean }>`
  color: ${({ isEmpty }) =>
    isEmpty
      ? `${theme.colors.colorRoyalBlue};`
      : `${theme.colors.colorSemiDarkGray1};`};
  cursor: pointer;
  line-height: 1;
`;

export const MultiRateDescription = styled.div`
  font-size: 10px;
  color: ${theme.colors.colorMediumGray5};
  position: absolute;
  line-height: 1.5;
  width: 100%;
`;

export const TotalTextContainer = styled.div`
  padding: 0;
  margin: 0;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 600;
  font-size: 15px;
  margin-right: 12px;
`;

export const PhaseTableCollapseIconContainer = styled.div`
  position: absolute;
  left: -38px;
`;

export const StyledDropdownItem = styled.div<{
  fontSize?: number;
  isDelete: boolean;
}>`
  font-size: ${(props) => props.fontSize || 14}px;
  padding: 6px 12px;
  color: #4f4f4f;
  margin: 1px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .delete-red {
    display: none;
  }
  .delete-gray {
    display: block;
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    color: ${({ isDelete }) => (isDelete ? theme.colors.colorDeleteRed : '')};
    .delete-gray {
      display: none;
    }
    .delete-red {
      display: block;
    }
  }
`;

export const BudgetSettingToggleIconContainer = styled.div`
  width: 14px;
  height: 14px;
  padding: 1px;
  margin-right: 2px;
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorLightGray4};
  border-radius: 3px;
`;
export const BudgetSettingToggleIcon = styled.div<{
  isDisabled: boolean;
  isSelected: boolean;
}>`
  background: ${({ isDisabled, isSelected }) =>
    isDisabled
      ? theme.colors.colorPaleGray5
      : isSelected
      ? theme.colors.colorRoyalBlue
      : 'transparent'};
  border: 1px solid
    ${({ isDisabled, isSelected }) =>
      isDisabled
        ? theme.colors.colorPaleGray5
        : isSelected
        ? theme.colors.colorRoyalBlue
        : 'transparent'};

  width: 100%;
  height: 100%;
  border-radius: 2px;

  &:hover {
    border: 1px solid
      ${({ isDisabled }) =>
        isDisabled ? theme.colors.colorPaleGray5 : theme.colors.colorRoyalBlue};
    background: 'white';
  }
`;

export const StyledPhaseNameHeader = styled.div<{
  isCollapsed: boolean;
  isArchived: boolean;
  isProjectScopeTable: boolean;
}>`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 2px;
  justify-content: ${({ isCollapsed }) => (isCollapsed ? 'center' : '')};
  ${MilestoneProgressBarContainer} {
    padding: 0 3px 0 24px;
  }
  color: ${(props) =>
    props.isArchived
      ? theme.colors.colorCalendarGray
      : theme.colors.colorSemiDarkGray1};
  ${(props) => props.isProjectScopeTable && 'padding-left: 24px;'}
  .milestone-icon-box {
    stroke: ${(props) =>
      props.isArchived
        ? theme.colors.colorCalendarGray
        : theme.colors.colorSemiDarkGray1};
  }
  .phase-milestone-icon-box {
    flex-shrink: 0;
  }
`;

export const StyledPhaseTitle = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  position: relative;
  top: 7px;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    .phase-milestone-icon-box {
      path {
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
    ${ThreeDotMenuContainer} {
      svg {
        path {
          fill: ${theme.colors.colorRoyalBlue};
          stroke: ${theme.colors.colorRoyalBlue};
        }
      }
    }
  }
`;

export const StyledPhaseNameCell = styled.div`
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
`;

export const UnassignedText = styled.div`
  font-size: 15px;
  margin-left: 12px;
  font-weight: 600;
  color: ${theme.colors.colorCalendarBlue};
`;
export const UnassignedContainer = styled.div<{ isHidden: boolean }>`
  color: ${theme.colors.colorMediumGray9};
  margin-left: 31px;
  flex-grow: 1;
  display: flex;
  justify-content: ${({ isHidden }) =>
    isHidden ? 'flex-end' : 'space-between'};
  align-items: center;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
`;

export const UnassignedBox = styled.div<{ hideInactiveMembers: boolean }>`
  visibility: ${({ hideInactiveMembers }) =>
    hideInactiveMembers ? 'hidden' : 'visible'};
  align-items: center;
  display: flex;
  &:hover {
    ${UnassignedText} {
      color: ${theme.colors.colorRoyalBlue};
    }
    svg path {
      fill: ${theme.colors.colorRoyalBlue};
      stroke: ${theme.colors.colorRoyalBlue};
    }
  }
`;
export const StyledReassignIcon = styled(ReassignIcon)`
  margin-right: 8px;
`;

export const SecondaryHeader = styled.div<{ isSettings: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 1px 0px;
  align-items: center;
  justify-content: center;
  ${({ isSettings }) =>
    isSettings
      ? `font-weight: 600;
          color: ${theme.colors.colorSemiDarkGray1};`
      : ''}
`;

export const PrimaryTableHeaders = styled.div`
  padding: 2px 0px 1px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  justify-content: center;
`;

export const ArchivedMemberCount = styled.div`
  padding-left: 6px;
  font-weight: 400;
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const ArchivedMemberCircle = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background: ${theme.colors.colorTranslucentGray5};
  color: ${theme.colors.colorMediumGray5};
  border: 1px solid ${theme.colors.colorPaleGray2};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-left: 28px;
  font-weight: 600;
`;

export const BudgetPhaseMilestoneIconContainer = styled.div`
  margin-right: 6px;
  margin-left: 6px;
  padding-bottom: 4px;
  .dollar-sign-in-icon {
    fill: ${theme.colors.colorSemiDarkGray1};
  }
  ${StyledPhaseNameHeader} & {
    margin-left: 0;
  }
`;

export const BudgetContainer = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  cursor: pointer;
  z-index: 1;
  position: relative;
  font-size: 15px;
  font-weight: 600;
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const MilestoneProgressBar = styled.div<{ width: number }>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: ${(props) => `${props.width}%`};
  max-width: 100%;
  pointer-events: none;
  height: 2px;
  background: ${theme.colors.colorLightGray6};
`;
export const OverbudgetContainer = styled.div`
  font-weight: 600;
  color: ${theme.colors.colorDeleteRed};
  margin-left: 1px;
`;

export const ExpandCollapseAllContainer = styled.div<{
  isHidden: boolean;
  isCollapsed: boolean;
  isRelative?: boolean;
}>`
  height: 26px;
  width: 26px;
  border-radius: 100px;
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'absolute')};
  top: 3px;
  z-index: 100;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  transition: 0.275s;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)'};
  path {
    fill: ${theme.colors.colorLightGray11};
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray3};
    path {
      fill: ${theme.colors.colorLightGray11};
    }
  }
`;

export const BudgetTableSettings = styled.div<{
  isHidden: boolean;
  isCollapsed: boolean;
}>`
  position: absolute;
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'flex')};
  right: 0px;
  bottom: 25px;
  background: ${theme.colors.colorPureWhite};
  border: 1px solid ${theme.colors.colorPaleGray6};
  z-index: 1000;
  width: 115px;
`;
export const HoursDollarsToggleContainer = styled.div<{ isHidden: boolean }>`
  width: 100%;
  height: 100%;
  border: 1px solid ${theme.colors.colorMediumGray14};
  border-bottom: none;
  display: flex;
  border-radius: 4px 4px 0px 0px;
  ${(props) => props.isHidden && `visibility: hidden;`}
`;

export const ToggleOption = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  padding: 3px 4px;
  color: ${({ isActive }) =>
    isActive ? theme.colors.colorCalendarBlue : theme.colors.colorCalendarGray};
  ${({ isActive }) =>
    isActive &&
    `
    background: ${theme.colors.colorPureWhite};
  `}

  &:first-child {
    border-right: 1px solid
      ${({ isActive }) =>
        isActive ? theme.colors.colorLightGray6 : 'transparent'};
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-left: 1px solid
      ${({ isActive }) =>
        isActive ? theme.colors.colorLightGray6 : 'transparent'};
    border-top-right-radius: 4px;
  }
  &:hover {
    ${StyledLockIcon} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }

  &.budget-modal-table-option {
    background: transparent;
    border: none;

    ${({ isActive }) =>
      isActive &&
      `
        color: ${theme.colors.colorRoyalBlue};
        font-weight: 700;
        border-bottom: 2px solid ${theme.colors.colorRoyalBlue};
        border-radius: 0;
    `}
  }
`;

export const RemainingPercentsContainer = styled.div`
  z-index: 1000;
  text-align: center;
  position: absolute;
  right: -60px;
  width: 60px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
`;
export const TableOptionsSettingsContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 114px;
  width: 114px;
  background: ${theme.colors.colorPureWhite};
  z-index: 100;
`;

export const InactiveMemberToggle = styled.div`
  color: ${theme.colors.colorMediumGray5};
  cursor: pointer;

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const StyledInput = styled.input`
  background: ${theme.colors.colorTranslucentGray4};
  color: ${theme.colors.colorRoyalBlue};
  border: 1px solid ${theme.colors.colorRoyalBlue};
  position: relative;
  top: 5px;

  &::placeholder {
    text-align: center;
    padding-right: 10px;
    color: ${theme.colors.colorLightGray5};
  }
`;

export const OverflowContainer = styled.div`
  font-size: 16px;
  font-weight: 800;
  margin-left: 2px;
  color: #a82424;
`;

export const CostInputForm = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${theme.colors.colorRoyalBlue};
  font-size: 14px;
  border: 1px solid transparent;
  &:hover,
  &:focus-within {
    border: 1px solid ${theme.colors.colorRoyalBlue};
    color: ${theme.colors.colorRoyalBlue};
  }
  &::placeholder {
    color: ${theme.colors.colorMediumGray1};
  }
  &:focus-within {
    background: ${theme.colors.colorPureWhite};
  }
  justify-content: flex-end;
  padding-right: 10px;
`;

export const CostInput = styled.input`
  width: 65px;
  border: 1px solid transparent;
  background: transparent;
  text-align: right;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${theme.colors.colorRoyalBlue};
  font-size: 14px;
  &::placeholder {
    color: ${theme.colors.colorRoyalBlue};
  }
`;

export const AccessoryText = styled.span`
  color: ${theme.colors.colorRoyalBlue};
`;

export const CostReadOnly = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
  color: ${theme.colors.colorRoyalBlue};
  cursor: pointer;
  padding-right: 10px;
`;

export const StyledFeeTotalContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFeeTotal = styled.div<{ isArchived: boolean }>`
  font-size: 15px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2px;
  color: ${(props) =>
    props.isArchived
      ? theme.colors.colorMediumGray5
      : theme.colors.colorCalendarBlue};
`;

export const IconContainer = styled.div`
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

export const BarContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 10px;
  z-index: 1;
  .progress-background {
    width: 63px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
`;

export const HoverArea = styled.div`
  height: 56px;
  padding-top: 10px;
  width: 100%;
  position: relative;
  top: -10px;
`;

const border = `1px solid ${theme.colors.colorLightGray6}`;
const budgetTableBorder = `1px solid ${theme.colors.colorLightGray12}`;
const tableOutlineBorder = `1px solid ${theme.colors.colorLightGray7}`;

const cellLeftBorder = css`
  border-left: ${border};
`;
const cellTopBorder = css`
  border-top: ${border};
`;
const cellBorderBottom = css`
  border-bottom: ${border};
`;
const backgroundWhite = css`
  background: white;
`;

export const StyledBudgetTable = styled.div<{
  scrolled: boolean;
  containerBgColor?: string;
}>`
  height: 100%;
  position: relative;
  padding-top: 30px;
  border-top: 1px solid
    ${(props) =>
      props.scrolled ? theme.colors.colorMediumGray1 : 'transparent'};
  .variable-size-list {
    height: 100% !important;
    padding-bottom: 50px;
  }
  .table {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .table-body {
    flex: 1;
    min-height: 0;
  }
  .table-column-header {
    z-index: 1000;
    // thing and entity overlap add phase button and are blank, so lower z index
    &.thing,
    &.entity {
      z-index: 0;
    }
  }
  .tr {
    outline: none;
  }
  .td {
    height: 100%;
    flex-shrink: 0;
    &.totals,
    &.rightPadding {
      flex-shrink: 1;
    }
  }

  /* ---------------------------------- rows ---------------------------------- */

  .tr {
    .td {
      &.entity,
      &.thing,
      &.select,
      &.rightPadding {
        border-left: ${tableOutlineBorder} !important;
      }
    }
    &.outline-bottom {
      .td {
        &:not(.grip):not(.totals):not(.rightPadding):not(.select) {
          border-bottom: ${tableOutlineBorder};
        }
      }
    }
  }
  &.scope-table .tr .td {
    &.rightPadding {
      border-left: none !important;
    }
  }

  /* ---------------------------- Project total row --------------------------- */
  .projectTotalRow.tr {
    .td {
      &:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        ${cellLeftBorder}
        ${cellTopBorder}
        ${backgroundWhite}
      }
    }
  }

  /* -------------- Phase row (remember to also update DragClone) ------------- */
  .phaseRow.tr {
    .td {
      &:not(.grip):not(.totals):not(.rightPadding):not(.select) {
        ${cellTopBorder}
        ${backgroundWhite}
      }
    }
    &.closed {
      .td:not(.grip):not(.totals):not(.rightPadding):not(.select) {
        ${cellLeftBorder}
      }
    }
    &:hover {
      .drag-grips {
        visibility: visible;
      }
    }
  }

  .tr:last-of-type {
    // Last closed phase -> add bottom border
    &.phaseRow.closed {
      .td:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        ${cellBorderBottom}
      }
    }
  }

  /* --------------------------- Phase subtotal row --------------------------- */
  .phaseSubtotalRow.tr {
    .td {
      height: 55px;
      &:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        ${cellTopBorder}
        ${backgroundWhite}
        border-bottom: ${tableOutlineBorder};
      }
    }
  }

  /* ---------------------------- Member header row --------------------------- */

  .memberHeaderRow.tr {
    .td {
      border-left: none !important;
      &:not(.grip):not(.totals):not(.rightPadding):not(.select) {
        ${cellTopBorder}
      }
    }
  }

  /* ---------------------------- Member budget row --------------------------- */
  .memberBudgetRow.tr {
    .td {
      ${StyledMemberCircle} {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
      }
      &:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        border-left: 1px solid ${theme.colors.colorMediumGray11};
        border-top: 1px solid ${theme.colors.colorMediumGray11};
        ${backgroundWhite}
      }
    }
  }
  &.scope-table .memberBudgetRow.tr .td {
    &:not(.grip):not(.totals):not(.rightPadding):not(.select) {
      border-left: none;
    }
    &.entity {
      padding-left: 13px;
    }
  }

  .memberHeaderRow + .memberBudgetRow .td {
    // Remove top border from first memberBudgetRow after memberHeaderRow (which has a bottom border)
    border-top: none !important;
  }

  /* ---------------------- Activity (Work Category) row ---------------------- */
  .activityRow.tr {
    .td {
      &:not(.grip):not(.totals):not(.rightPadding):not(.select) {
        ${cellTopBorder}
        ${backgroundWhite}
      }
    }
    &.closed {
      .td:not(.grip):not(.totals):not(.rightPadding):not(.select) {
        ${cellLeftBorder}
      }
    }
  }

  /* -------------------------- Activity subtotal row ------------------------- */
  .activitySubtotalRow.tr {
    .td {
      height: 55px;
      &:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        ${cellTopBorder}
        ${cellLeftBorder}
        ${backgroundWhite}
      }
    }
  }

  /* ---------------------------- Show archived row --------------------------- */
  .showArchivedRow.tr {
    .td {
      &:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        border-left: 1px solid ${theme.colors.colorMediumGray11};
        border-top: 1px solid ${theme.colors.colorMediumGray11};
        ${backgroundWhite}
      }
    }
  }

  /* ------------------------------- Scope rows ------------------------------- */

  .tr.addScopeRow,
  .tr.scopeRow,
  .tr.scopeSubtotalRow {
    .td:not(.grip):not(.rightPadding):not(.select) {
      ${cellTopBorder}
      ${backgroundWhite}
    }
  }
  .tr.scopeSubtotalRow {
    .td.entity {
      padding-left: 13px;
    }
  }
  .tr.scopeRow {
    &:hover {
      .drag-grips {
        visibility: visible;
      }
    }
  }

  /* ------------------------------ Sticky header ----------------------------- */

  ${StyledStickyHeader} {
    padding: 0 !important;
    top: 55px;

    ${StyledStickyCellContainer} {
      &:not(.grip):not(.rightPadding):not(.totals):not(.select) {
        ${backgroundWhite}
        ${cellTopBorder}
        border-bottom: ${border};
      }
      &.grip,
      &.rightPadding {
        ${(props) =>
          props.containerBgColor && `background: ${props.containerBgColor}`}
      }
      outline: none;
    }

    &:hover {
      ${StyledStickyCellContainer} {
        &:first-child {
          visibility: visible;
        }
      }
    }
  }

  ${Styles} {
    height: 100%;
    padding: 0 !important;
  }

  /* ------------------------------- Scope table ------------------------------ */

  &.scope-table:not(.project-view) {
    display: flex; // for showing batch action menu on the right
    .projectTotalRow.tr,
    .phaseRow.tr,
    .activityRow.tr {
      .td {
        border-left: none !important;
        background: none !important;
      }
    }
  }

  /* --------------------------- Project scope table -------------------------- */

  // same as checking props.isProjectScopeTable. different so styled-component css will be formatted
  &.scope-table.project-view {
    margin-top: 7px;
    .td {
      border-left: none !important;
      &.memberBudgetRow.grip {
        background-color: unset;
      }
    }
  }

  /* ------------------------------- Budget Table ----------------------------- */

  &.budget-table {
    ${StyledStickyCellContainer}.td.fee {
      z-index: 2;
    }
    .projectTotalRow.tr,
    .phaseRow.tr {
      .td {
        border-left: none !important;
        background: none !important;
      }
    }
    .phaseSubtotalRow.tr {
      .td {
        border-left: none !important;
        border-top: none !important;
        background: none !important;
      }
      .td:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        border-bottom: ${budgetTableBorder};
      }
    }
    .activityRow.tr {
      .td {
        border-left: none !important;
        border-top: none !important;
        background: none !important;
      }
      &.closed {
        .td:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
          border-bottom: ${budgetTableBorder};
        }
      }
    }
    .memberBudgetRow.tr,
    .showArchivedRow.tr {
      .td {
        &.entity,
        &.target,
        &.rightPadding {
          border-left: none !important;
        }
      }
    }
    .activitySubtotalRow.tr .td {
      border-top: none !important;
      &:not(.grip):not(.rightPadding):not(.pieChart) {
        border-bottom: ${budgetTableBorder};
      }
      &.entity,
      &.fee,
      &.target,
      &.rightPadding {
        border-left: none !important;
      }
    }
    .memberBudgetRow.tr .td.fee {
      border-left: none !important;
    }
    .showArchivedRow.tr {
      .td {
        border-top: none !important;
        &.fee,
        &.target {
          border-left: none !important;
        }
      }
      .td:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        border-bottom: ${budgetTableBorder};
      }
    }
    .memberHeaderRow.tr {
      .td {
        border-top: none !important;
        &.entity,
        &.fee,
        &.target,
        &.spent,
        &.remaining,
        &.planned {
          border-bottom: 1px solid ${theme.colors.colorMediumGray14};
        }
      }
    }
    .phaseRow.tr {
      .td {
        border-top: none !important;
        &:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
          border-bottom: ${budgetTableBorder};
        }
      }
    }
    .projectTotalRow.tr {
      .td:not(.grip):not(.totals):not(.rightPadding):not(.select):not(.pieChart) {
        border-bottom: ${budgetTableBorder};
      }
    }
  }
`;

export const MilestoneProgressContainer = styled.div`
  position: relative;
  top: 5px;
  width: fit-content;
`;

export const StyledDropdownMenu = styled(DropdownMenu)<{ fontSize?: string }>`
  min-width: 120px;
  background-color: ${theme.colors.colorPureWhite};
  left: -1px !important;
  ${({ fontSize }) =>
    fontSize &&
    `
    font-size: ${fontSize};
  `}
`;

export const StyledDropdown = styled(Dropdown)`
  position: absolute !important;
  top: -20px;
`;

export const TableTypeContainer = styled.div`
  .view-toggle-container {
    border: none;
    background: transparent;
  }
  .view-toggle-option {
    width: 77px;
  }
`;

export const StyledBarAndPercentageContainer = styled.div<{
  isHidingPercentage?: boolean;
}>`
  display: flex;
  font-size: 14px;
  ${({ isHidingPercentage }) => isHidingPercentage && 'height: 100%'};
`;

export const OverbudgetIcon = styled(IssueIconContainer)`
  margin-top: -2px;
  cursor: pointer;
`;
