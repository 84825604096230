import AddViewTasksIcon from 'images/addviewtasksicon.svg';
import AddViewNotesIcon from 'images/addviewnotesicon.svg';
import AddViewMeetingIcon from 'images/addviewmeetingicon.svg';
import AddViewPlannerIcon from 'images/addviewplannericon.svg';
import AddViewTimelinesIcon from 'images/addviewtimelinesicon.svg';

export const moduleTextHash = {
  1: 'All Members',
  // 2: 'Timesheets',
  3: 'Rate',
  // 4: 'Project Planner',
  // 5: 'Tasks',
  6: 'Department',
  7: 'Skills',
  8: 'Roles',
  9: 'Location',
  10: 'Region',
  11: 'Office',
  12: 'Discipline',
  13: 'PTO',
  14: 'Capacity'
};

export const moduleHash = {
  1: 'members',
  // 2: 'timesheet',
  3: 'budget',
  // 4: 'project-planner',
  // 5: 'tasks',
  6: 'departments',
  7: 'skills',
  8: 'roles',
  9: 'locations',
  10: 'regions',
  11: 'offices',
  12: 'disciplines',
  13: 'pto',
  14: 'capacity'
};

export const defaultNavModuleIds = [1, 6, 8, 3, 13, 14, 7, 9, 10, 11, 12];
const nonDefaultNavModuleIds = [];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

export const moduleDescriptionHash = {
  1: 'Manage Members.',
  2: 'View the latest timesheet submissions and manage approvals for teams that track time.',
  3: `Keep up on your teams workload at a glance with a view of everyone's planned tasks.`,
  4: `Keep up on your teams workload at a glance with a heatmap view of everyone's planned hours.`,
  5: `All your team's tasks in one place`,
  6: 'View Departments',
  7: 'View Skills',
  8: 'View Positions'
};
export const moduleColorHash = {
  1: '#78c946',
  2: '#3db489',
  3: '#DAAD0D',
  4: '#E16AA5',
  5: '#F14848',
  6: '#F14848',
  7: '#F14848',
  8: '#F14848'
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon}) `,
  2: `url(${AddViewNotesIcon}) `,
  3: `url(${AddViewTimelinesIcon}) `,
  4: `url(${AddViewPlannerIcon}) `,
  5: `url(${AddViewMeetingIcon}) `,
  6: `url(${AddViewPlannerIcon}) `,
  7: `url(${AddViewPlannerIcon}) `,
  8: `url(${AddViewPlannerIcon}) `
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
