import {
  fork,
  call,
  take,
  takeLatest,
  takeEvery,
  all,
  cancel
} from 'redux-saga/effects';
import { conditionalTakeLatest } from './helpers';
import * as constants from 'appConstants';
import {
  calculateResponsiveState,
  closeSidebarSearchAndProjects,
  checkShouldCloseSidebar
} from './browser';
import { fetchFilteredHomeTaskTagsWorker } from './homeTaskTags';
import {
  createTaskStatus,
  updateTaskStatus,
  deleteTaskStatus
} from './taskStatuses';
import {
  fetchTaskPriorities,
  createTaskPriority,
  updateTaskPriority,
  deleteTaskPriority
} from './taskPriorities';
import {
  createProjectStage,
  updateProjectStage,
  deleteProjectStage
} from './projectStages';
import {
  createProjectPriority,
  updateProjectPriority,
  deleteProjectPriority
} from './projectPriorities';
import {
  createProjectStatus,
  updateProjectStatus,
  deleteProjectStatus
} from './projectStatuses';
import {
  fetchUser,
  postInviteTeamMember,
  fetchTeamMembers,
  updateAccountsDefaultFollowUpMessage,
  postQBInvites
} from './users';
import {
  fetchTeamMembersByBoard,
  fetchAllTeamMembersByBoard,
  fetchAllProjectMembers,
  updateTeamMembership,
  updateTeamMembershipProfile,
  updateTeamMemberEmailNotification,
  fetchTeamMemberWorker
} from './teamMembers';
import { handleTriggerWorker } from './triggers';
import {
  postLogin,
  postSignup,
  logoutWorker,
  redirectToAuthServer,
  exchangeAccessTokenWorker
} from './auth';
import {
  postBoard,
  editGroup,
  fetchGroups,
  fetchBoardMembers,
  fetchAllGroupMembers,
  addMemberToGroup,
  updateBoardMember,
  addBulkMembersToGroup,
  deleteMemberFromGroup,
  archiveBoard,
  unarchiveBoard,
  fetchUserTeams,
  fetchSelectedBoard,
  fetchProjectsListView,
  exportProjectsListView,
  updateBoardPosition,
  fetchArchivedBoards,
  deleteBoard,
  updateBoardModules
} from './groups';
import {
  postProjectComment,
  editProjectComment,
  deleteProjectComment,
  fetchProjectsByGroup,
  fetchProjectById,
  postProject,
  editProject,
  updateProjectBillableWorker,
  updateProjectPosition,
  fetchProjectMembers,
  addMemberToProject,
  addBulkMembersToProject,
  removeMemberFromProject,
  putProjectMember,
  deleteProject,
  archiveUnarchiveProject,
  batchArchiveUnarchiveProject,
  starProject,
  updateNotificationPreferences,
  updateProjectModules,
  fetchProjectTaskGroupTasksQueue,
  fetchProjectTaskGroupTasksSuccess,
  fetchProjectsForTasksWorker,
  fetchProjectClientsWorker,
  moveProjectsToBoard,
  cloneProjectList,
  hardDeleteProject
} from './projects';
import {
  updateTaskPosition,
  fetchSingleTask,
  bulkEditTasks,
  bulkEditHomeTasks,
  fetchCommentsAndMetadata,
  createComment,
  updateComment,
  deleteComment,
  fetchTaskGroups,
  fetchProjectTaskGroups,
  createProjectTaskGroup,
  updateProjectTaskGroup,
  deleteProjectTaskGroup,
  reorderProjectTaskGroups,
  fetchTasksV2,
  exportTasks
} from './tasks';

import {
  fetchNotifications,
  fetchNewNotificationsCount,
  updateNotificationAsRead,
  updateNotificationsAsOld,
  markAllNotificationsRead
} from './notifications';

import { fetchNoteTagsByBoard, updateTag } from './tags';
import {
  putTeam,
  updateTeamOrderedItems,
  updateTeamViewSettingsWorker,
  postProfile,
  updateEmailNotification,
  updateMyProjectsState,
  updateSettingsPassword
} from './settings';
import {
  updateTeamAuthSettingsWorker,
  getAuthMfaStatusPerAccountWorker,
  toggleIndividualMfaWorker,
  toggleMfaResetWorker
} from './settingsTyped';
import {
  fetchDisciplinesWorker,
  createDisciplineWorker,
  updateDisciplineWorker,
  deleteDisciplineWorker,
  updateDisciplineEntitiesWorker
} from './discipline';
import {
  fetchRegionsWorker,
  createRegionWorker,
  updateRegionWorker,
  deleteRegionWorker,
  updateRegionEntitiesWorker
} from './region';
import {
  fetchOfficesWorker,
  createOfficeWorker,
  updateOfficeMetadataWorker,
  updateOfficeLocationWorker,
  deleteOfficeWorker,
  updateOfficeEntitiesWorker
} from './office';
import locationsWorkers from './location';
import { validateInvite, updatePassword, resendInviteEmail } from './invite';
import { fetchCurrentSubscription, cancelSubscription } from './billing';
import {
  fetchMyActiveProjects,
  fetchAllActiveProjects,
  fetchProjectsIndexV2,
  updateMyProject,
  generateReportWorker,
  fetchCheckinsHoursWorker,
  fetchCheckinsWorker,
  bulkCreateCheckinWorker,
  createCheckinWorker,
  updateCheckinWorker,
  deleteCheckinWorker,
  fetchTimersWorker,
  fetchActiveTimerWorker,
  startTimerWorker,
  updateTimerWorker,
  deleteTimerWorker,
  endTimerWorker,
  associateTaskWorker
} from './home';
import {
  closeAndClearTaskViewModal,
  closeAndClearMemberModal,
  navigateUp,
  navigateBack,
  navigateTo,
  navigationChange
} from './navigation';
import { onGlobalSearchSaga } from './search';

import { teamMemberProfileFetch } from './teams';
import { dayPlannerFetch, dayPlannerUpdate } from './dayPlanner';
import { fetchPlanners } from './planners';
import {
  refetchWorkloadWorker,
  fetchWorkloadPlanner,
  updateWorkloadPlanner,
  createWorkloadPlanner,
  predictWorkdays,
  predictWorkloadPlanner,
  deleteWorkloadPlanner,
  fetchWorkloadPlannerEvents,
  fetchWorkloadPlannerEvent,
  updateWorkloadPlannerEvent,
  createWorkloadPlannerEvent,
  createNotificationPreview,
  sendWorkloadPlannerEvent,
  deleteWorkloadPlannerEvent,
  fetchWorkloadPlannerEventLastSent,
  refetchOnWSScheduledEventWorker,
  updateWorkloadPlannerMembers,
  fetchMemberProjects,
  splitWorkloadPlanner,
  fetchSuggestions,
  approveWorkplanRequestsWorker
} from './projectPlanner';
import {
  fetchActivities,
  fetchDescriptions,
  fetchFilteredActivitiesPerProject,
  fetchTimesheets,
  fetchTimesheetsIndex,
  fetchTimesheetsUtilizationWorker,
  processTimeEntriesForQuickbooksWorker,
  exportTimesheets,
  createTimesheet,
  updateTimesheet,
  bulkUpdateTimesheets,
  deleteTimesheets,
  submitDates,
  createActivity,
  updateActivity,
  fetchActivityMembersWorker,
  createActivityMembersWorker,
  archiveActivityMemberWorker,
  createDescription,
  updateDescription,
  deleteDescriptions,
  checkHasTimeEntriesWorker,
  fetchTimesheetsPredictions,
  fetchRemainingTimesheetHoursWorker
} from './timesheets';

import * as settingsConstants from 'SettingsModule/constants';
import * as disciplineConstants from 'SettingsModule/constants/discipline';
import * as regionConstants from 'SettingsModule/constants/region';
import * as officeConstants from 'SettingsModule/constants/office';
import * as locationConstants from 'SettingsModule/constants/location';
import * as noteModuleConstants from 'NoteModule/constants';

import {
  fetchUserActivities,
  fetchUserActivitiesForActionable,
  fetchActivityLogUserActivities
} from './userActivities';
import { deleteTaskAttachment, deleteProjectAttachment } from './attachments';
import {
  fetchProjectColorPreferences,
  updateProjectColorPreference,
  createProjectColorPreference,
  deleteProjectColorPreference,
  fetchBoardPreferences,
  updateBoardPreference,
  createBoardPreference,
  deleteBoardPreference,
  fetchTaskGroupPreferences,
  updateTaskGroupPreference,
  createTaskGroupPreference,
  deleteTaskGroupPreference,
  fetchTeamMembershipPreferences,
  updateTeamMembershipPreference,
  createTeamMembershipPreference,
  deleteTeamMembershipPreference,
  fetchTeamPreferences,
  updateTeamPreference,
  createTeamPreference,
  deleteTeamPreference,
  fetchTagPreferences,
  updateTagPreference,
  createTagPreference,
  deleteTagPreference
} from './userTheme';
import { fetchUserPermissions, clearDenyPermission } from './userPermissions';
import {
  fetchAccountFilterWorker,
  fetchAccountFiltersWorker,
  updateAccountFilterWorker,
  createAccountFilterWorker,
  deleteAccountFilterWorker,
  closeWorkloadFilterWorker,
  fetchFilterByIdsWorker
} from './filters';
import {
  fetchWorkGroups,
  fetchWorkGroup,
  createWorkGroup,
  updateWorkGroup,
  archiveWorkGroup,
  unarchiveWorkGroup,
  deleteWorkGroup,
  createWorkGroupMembership,
  deleteWorkGroupMembership,
  refetchOnWSWorkGroupMembership
} from './workGroups';
import {
  fetchSkillsWorker,
  createSkillWorker,
  updateSkillWorker,
  archiveSkillWorker,
  unarchiveSkillWorker,
  deleteSkillWorker,
  createSkillMembershipWorker,
  updateSkillMembershipWorker,
  deleteSkillMembershipWorker,
  fetchEntitySkillsAndSkillsReportWorker,
  fetchSkillsReportWorker,
  fetchEntitySkillsWorker,
  createEntitySkillWorker,
  updateEntitySkillWorker,
  deleteEntitySkillWorker
} from './skills';
import {
  fetchTodayMemberTaskCount,
  fetchOverdueMemberTaskCount,
  fetchScheduledMemberTaskCount,
  fetchUnscheduledMemberTaskCount,
  fetchCompletedMemberTaskCount,
  fetchEndsBeforeMemberTaskCount
} from './homeTasks';
import {
  fetchClients,
  fetchClient,
  updateClient,
  createClient
} from './clients';
// import { fetchRecurlyBillingInfo updateRecurlyBillingInfo } from './recurly';
import { fetchProjectTotalsWorker } from './profit';
import { fetchAccessWorker, updateAccessWorker } from './access';
import { fetchPredictionHours } from './suggestions';
import { phaseSagas } from './phaseSagas';
import { homeTasksSagas } from './homeTasksSagas';
import { fetchProjectNotesV2 } from 'NoteModule/actionCreators';
import { loadCSVImportDataWorker } from './csvImport';
import {
  fetchScopesWorker,
  createScopeWorker,
  updateScopeWorker,
  deleteScopeWorker,
  assignScopeMembersWorker,
  unassignScopeMembersWorker,
  updateScopeMembershipWorker,
  moveScopesWorker,
  updateScopePositionWorker,
  deleteScopeAttachmentWorker
} from './scopes';
import { chainActionsWorker } from './chainActions';

import { tryStartOnlineModeWorker } from './offline';
import { fetchPrioritiesWorker } from './priorities';
import { handleRequestStatusWorker } from './statuses';
import {
  createEntityCommentWorker,
  updateEntityCommentWorker,
  deleteEntityCommentWorker,
  fetchEntityCommentsWorker
} from './comments';
import { updateTeamAuthSettings } from 'SettingsModule/actionCreators';
import {
  fetchPTOPolicyPerMember,
  fetchPTOPolicies,
  fetchPTOPolicy,
  createPTOPolicy,
  updatePTOPolicy,
  archivePTOPolicy,
  fetchPTOGroupMemberships,
  createPTOGroupMembership,
  updatePTOGroupMembership,
  deletePTOGroupMembership
} from 'SettingsModule/actionCreators/settings/pto';
import ptoWorkers from 'SettingsModule/sagas/pto';
import {
  associateEntityToLocation,
  deleteEntityLocation,
  fetchEntityLocations,
  fetchLocations
} from 'SettingsModule/actionCreators/settings/location';
import {
  updateTeamMembersPermission,
  updateTeamMembersPermissionActionCreatorsMap,
  bulkInviteAccounts,
  bulkInviteAccountsActionCreatorsMap
} from 'PermissionsModule/actionCreators';
import permissionsWorkers from 'PermissionsModule/sagaWorkers';
import { entityOptionsSagas } from 'EntityOptionsModule/sagas';
import { permissionsSagas } from 'PermissionsModule/sagas';
import { currencySagas } from 'CurrencyModule/sagas';
import { leanApiSagas } from 'LeanApiModule/sagas/sagas';
import {
  setAuthTokenFetchSuccessDetails,
  generateTeamSamlInfo,
  associateSamlIdentity,
  setAuthMethod,
  resetPassword
} from 'AuthenticationModule/actionCreators';
import authenticationWorkers from 'AuthenticationModule/sagaWorkers';
import {
  updateTeamMembership as updateTeamMembershipV2,
  archiveTeamMembers,
  unarchiveTeamMembers,
  hardDeleteTeamMembers
} from 'TeamsModule/actionCreators';
import teamsWorkers from 'TeamsModule/sagaWorkers';
import galaxySagas from 'GalaxyModule/sagas/sagas';
import { usersSagas } from 'UsersModule/sagas';

// https://github.com/redux-saga/redux-saga/issues/589 proposal for takeEarliest
const takeEarliest = (pattern, saga, ...args) =>
  fork(function* () {
    // constant while loop not problematic inside of generator function
    while (true) {
      const action = yield take(pattern);
      yield call(saga, ...[...args, action]);
    }
  });

const appSagas = [
  fork(takeLatest, constants.LOGOUT, logoutWorker),
  fork(takeEvery, constants.EXCHANGE_ACCESS_TOKEN, exchangeAccessTokenWorker),
  fork(
    takeEvery,
    setAuthTokenFetchSuccessDetails,
    authenticationWorkers.setAuthTokenFetchSuccessDetailsWorker
  ),
  fork(takeLatest, constants.USER.TRIGGER, fetchUser),
  fork(takeLatest, constants.TRY_START_ONLINE_MODE, tryStartOnlineModeWorker),
  fork(
    takeLatest,
    constants.CALCULATE_RESPONSIVE_STATE,
    calculateResponsiveState
  ),
  fork(
    takeLatest,
    constants.UPDATE_SIDEBAR_PROJECTS_PINNED_STATE.TRIGGER,
    updateMyProjectsState
  ),
  fork(
    takeLatest,
    constants.TOGGLE_BATCH_SELECTED_TASKS,
    checkShouldCloseSidebar
  ),
  fork(takeLatest, constants.SET_BATCH_SELECTED_TASKS, checkShouldCloseSidebar),
  fork(takeLatest, constants.GROUP_CREATION.TRIGGER, postBoard),
  fork(takeLatest, constants.GROUP_EDITION.TRIGGER, editGroup),
  fork(takeLatest, constants.ARCHIVE_BOARD.TRIGGER, archiveBoard),
  fork(takeLatest, constants.UNARCHIVE_BOARD.TRIGGER, unarchiveBoard),
  fork(takeLatest, constants.DELETE_BOARD.TRIGGER, deleteBoard),
  fork(takeLatest, constants.GROUPS.TRIGGER, fetchGroups),
  fork(takeLatest, constants.GROUP_MEMBERS.TRIGGER, fetchBoardMembers),
  fork(takeLatest, constants.ALL_GROUP_MEMBERS.TRIGGER, fetchAllGroupMembers),
  fork(
    takeLatest,
    constants.CLOSE_SIDEBAR_SEARCH_AND_PROJECTS,
    closeSidebarSearchAndProjects
  ),
  fork(
    takeLatest,
    constants.CHANGE_BOARD_POSITION.TRIGGER,
    updateBoardPosition
  ),
  fork(takeLatest, constants.FETCH_SELECTED_BOARD.TRIGGER, fetchSelectedBoard),
  fork(
    takeEvery,
    constants.FETCH_PROJECTS_LIST_VIEW.TRIGGER,
    fetchProjectsListView
  ),
  fork(
    takeEvery,
    constants.EXPORT_PROJECTS_LIST_VIEW.TRIGGER,
    exportProjectsListView
  ),
  fork(
    takeLatest,
    constants.FETCH_ARCHIVED_BOARDS.TRIGGER,
    fetchArchivedBoards
  ),
  fork(takeLatest, constants.ON_GLOBAL_SEARCH.TRIGGER, onGlobalSearchSaga),

  fork(takeEvery, constants.FETCH_SINGLE_TASK, fetchSingleTask),
  fork(takeEvery, constants.TASK_UPDATE_POSITION, updateTaskPosition),

  fork(takeEvery, constants.STAR_PROJECT.TRIGGER, starProject),
  fork(takeLatest, constants.FETCH_PROJECTS_BY_GROUP, fetchProjectsByGroup),
  fork(takeEarliest, constants.PROJECT_CREATION.TRIGGER, postProject),
  fork(takeLatest, constants.PROJECT_EDITION.TRIGGER, editProject),
  fork(
    takeLatest,
    constants.UPDATE_PROJECT_BILLABLE.TRIGGER,
    updateProjectBillableWorker
  ),
  fork(
    takeLatest,
    constants.PROJECT_UPDATE_POSITION.TRIGGER,
    updateProjectPosition
  ),
  fork(
    takeLatest,
    constants.ARCHIVE_UNARCHIVE_PROJECT.TRIGGER,
    archiveUnarchiveProject
  ),
  fork(
    takeLatest,
    constants.BATCH_ARCHIVE_UNARCHIVE_PROJECT.TRIGGER,
    batchArchiveUnarchiveProject
  ),
  fork(
    takeLatest,
    constants.FETCH_NOTE_TAGS_BY_BOARD.TRIGGER,
    fetchNoteTagsByBoard
  ),

  fork(takeLatest, constants.UPDATE_TAG.TRIGGER, updateTag),

  fork(
    takeLatest,
    constants.CREATE_PROJECT_COMMENT.TRIGGER,
    postProjectComment
  ),
  fork(takeLatest, constants.EDIT_PROJECT_COMMENT.TRIGGER, editProjectComment),
  fork(
    takeLatest,
    constants.DELETE_PROJECT_COMMENT.TRIGGER,
    deleteProjectComment
  ),

  fork(takeLatest, constants.INVITE_MEMBER.TRIGGER, postInviteTeamMember),
  fork(takeLatest, constants.INVITE_QB_MEMBERS.TRIGGER, postQBInvites),
  fork(takeLatest, constants.INVITE_VALIDATION.TRIGGER, validateInvite),
  fork(takeLatest, constants.RESEND_INVITE.TRIGGER, resendInviteEmail),
  fork(takeEvery, constants.FETCH_PROJECT_BY_ID, fetchProjectById),
  fork(takeLatest, constants.FETCH_TEAMS.TRIGGER, fetchUserTeams),
  fork(takeLatest, constants.TEAM_MEMBERS.TRIGGER, fetchTeamMembers),
  fork(
    takeLatest,
    constants.FETCH_TEAM_MEMBERS_BY_BOARD.TRIGGER,
    fetchTeamMembersByBoard
  ),
  fork(
    takeLatest,
    constants.FETCH_ALL_TEAM_MEMBERS_BY_BOARD.TRIGGER,
    fetchAllTeamMembersByBoard
  ),
  fork(
    takeLatest,
    constants.FETCH_ALL_PROJECT_MEMBERS.TRIGGER,
    fetchAllProjectMembers
  ),
  fork(
    takeLatest,
    updateTeamMembersPermission,
    permissionsWorkers.updateTeamMembersPermissionWorker
  ),
  fork(
    takeEvery,
    bulkInviteAccounts,
    permissionsWorkers.bulkInviteAccountsWorker
  ),

  fork(
    takeLatest,
    constants.UPDATE_TEAM_MEMBERSHIP.TRIGGER,
    updateTeamMembership
  ),
  fork(
    takeLatest,
    updateTeamMembershipV2,
    teamsWorkers.updateTeamMembershipWorker
  ),
  fork(takeLatest, archiveTeamMembers, teamsWorkers.archiveTeamMembersWorker),
  fork(
    takeLatest,
    unarchiveTeamMembers,
    teamsWorkers.unarchiveTeamMembersWorker
  ),
  fork(
    takeLatest,
    hardDeleteTeamMembers,
    teamsWorkers.hardDeleteTeamMembersWorker
  ),
  fork(
    takeEvery,
    constants.UPDATE_TEAM_MEMBERSHIP_PROFILE.TRIGGER,
    updateTeamMembershipProfile
  ),
  fork(
    takeEvery,
    constants.UPDATE_TEAM_MEMBER_EMAIL_NOTIFICATION.TRIGGER,
    updateTeamMemberEmailNotification
  ),
  fork(takeLatest, constants.CREATE_TASK_STATUS.TRIGGER, createTaskStatus),
  fork(takeLatest, constants.UPDATE_TASK_STATUS.TRIGGER, updateTaskStatus),
  fork(takeLatest, constants.DELETE_TASK_STATUS.TRIGGER, deleteTaskStatus),
  fork(takeLatest, constants.CREATE_PROJECT_STAGE.TRIGGER, createProjectStage),
  fork(takeLatest, constants.UPDATE_PROJECT_STAGE.TRIGGER, updateProjectStage),
  fork(takeLatest, constants.DELETE_PROJECT_STAGE.TRIGGER, deleteProjectStage),
  fork(
    takeLatest,
    constants.CREATE_PROJECT_PRIORITY.TRIGGER,
    createProjectPriority
  ),
  fork(
    takeLatest,
    constants.UPDATE_PROJECT_PRIORITY.TRIGGER,
    updateProjectPriority
  ),
  fork(
    takeLatest,
    constants.DELETE_PROJECT_PRIORITY.TRIGGER,
    deleteProjectPriority
  ),
  fork(
    takeLatest,
    constants.CREATE_PROJECT_STATUS.TRIGGER,
    createProjectStatus
  ),
  fork(
    takeLatest,
    constants.UPDATE_PROJECT_STATUS.TRIGGER,
    updateProjectStatus
  ),
  fork(
    takeLatest,
    constants.DELETE_PROJECT_STATUS.TRIGGER,
    deleteProjectStatus
  ),
  fork(takeLatest, constants.FETCH_TASK_GROUPS.TRIGGER, fetchTaskGroups),
  fork(
    takeLatest,
    constants.FETCH_PROJECT_TASK_GROUPS.TRIGGER,
    fetchProjectTaskGroups
  ),
  fork(
    takeEvery,
    constants.FETCH_PROJECT_TASK_GROUP_TASKS.TRIGGER,
    fetchProjectTaskGroupTasksQueue
  ),
  fork(
    takeEvery,
    constants.FETCH_PROJECT_TASK_GROUP_TASKS.SUCCESS,
    fetchProjectTaskGroupTasksSuccess
  ),
  fork(
    takeLatest,
    constants.REORDER_PROJECT_TASK_GROUPS.TRIGGER,
    reorderProjectTaskGroups
  ),
  fork(
    takeLatest,
    constants.CREATE_PROJECT_TASK_GROUP.TRIGGER,
    createProjectTaskGroup
  ),
  fork(
    takeLatest,
    constants.UPDATE_PROJECT_TASK_GROUP.TRIGGER,
    updateProjectTaskGroup
  ),
  fork(
    takeLatest,
    constants.DELETE_PROJECT_TASK_GROUP.TRIGGER,
    deleteProjectTaskGroup
  ),
  fork(
    takeLatest,
    constants.FETCH_TASK_PRIORITIES.TRIGGER,
    fetchTaskPriorities
  ),
  // task prioroity code is defunct, remove
  fork(takeLatest, constants.CREATE_TASK_PRIORITY.TRIGGER, createTaskPriority),
  fork(takeLatest, constants.UPDATE_TASK_PRIORITY.TRIGGER, updateTaskPriority),
  fork(takeLatest, constants.DELETE_TASK_PRIORITY.TRIGGER, deleteTaskPriority),
  fork(takeLatest, constants.ADD_MEMBER_TO_GROUP.TRIGGER, addMemberToGroup),
  fork(
    takeLatest,
    constants.UPDATE_BOARD_MEMBER_ROLE.TRIGGER,
    updateBoardMember
  ),
  fork(
    takeLatest,
    constants.ADD_BULK_MEMBERS_TO_GROUP.TRIGGER,
    addBulkMembersToGroup
  ),
  fork(
    takeLatest,
    constants.DELETE_MEMBER_FROM_GROUP.TRIGGER,
    deleteMemberFromGroup
  ),
  fork(
    takeLatest,
    constants.FETCH_PROJECT_MEMBERS.TRIGGER,
    fetchProjectMembers
  ),
  fork(
    takeEarliest,
    constants.ADD_MEMBER_TO_PROJECT.TRIGGER,
    addMemberToProject
  ),
  fork(
    takeLatest,
    constants.ADD_BULK_MEMBERS_TO_PROJECT.TRIGGER,
    addBulkMembersToProject
  ),
  fork(
    takeLatest,
    constants.DELETE_MEMBER_FROM_PROJECT.TRIGGER,
    removeMemberFromProject
  ),
  fork(
    takeLatest,
    constants.UPDATE_PROJECT_MEMBER_ROLE.TRIGGER,
    putProjectMember
  ),

  fork(takeLatest, constants.DELETE_ARCHIVE_GROUP.TRIGGER, archiveBoard),
  fork(takeLatest, constants.DELETE_PROJECT.TRIGGER, deleteProject),
  fork(takeLatest, constants.HARD_DELETE_PROJECT.TRIGGER, hardDeleteProject),

  fork(takeLatest, constants.TEAM_UPDATE.TRIGGER, putTeam),
  fork(
    takeLatest,
    constants.UPDATE_TEAM_ORDERED_ITEMS.TRIGGER,
    updateTeamOrderedItems
  ),
  fork(
    takeEvery,
    constants.UPDATE_TEAM_VIEW_SETTINGS.TRIGGER,
    updateTeamViewSettingsWorker
  ),
  fork(takeEvery, updateTeamAuthSettings, updateTeamAuthSettingsWorker),
  fork(
    takeEvery,
    settingsConstants.GET_AUTH_MFA_STATUS_PER_ACCOUNT.TRIGGER,
    getAuthMfaStatusPerAccountWorker
  ),
  fork(
    takeEvery,
    settingsConstants.TOGGLE_INDIVIDUAL_MFA.TRIGGER,
    toggleIndividualMfaWorker
  ),

  fork(
    takeEvery,
    settingsConstants.TOGGLE_MFA_RESET.TRIGGER,
    toggleMfaResetWorker
  ),
  fork(
    takeEvery,
    generateTeamSamlInfo,
    authenticationWorkers.generateTeamSamlInfoWorker
  ),
  fork(
    takeEvery,
    associateSamlIdentity,
    authenticationWorkers.associateSamlIdentityWorker
  ),
  fork(takeEvery, setAuthMethod, authenticationWorkers.setAuthMethodWorker),
  fork(
    takeEvery,
    disciplineConstants.FETCH_DISCIPLINES.TRIGGER,
    fetchDisciplinesWorker
  ),
  fork(
    takeEvery,
    disciplineConstants.CREATE_DISCIPLINE.TRIGGER,
    createDisciplineWorker
  ),
  fork(
    takeEvery,
    disciplineConstants.UPDATE_DISCIPLINE.TRIGGER,
    updateDisciplineWorker
  ),
  fork(
    takeEvery,
    disciplineConstants.DELETE_DISCIPLINE.TRIGGER,
    deleteDisciplineWorker
  ),
  fork(
    takeEvery,
    disciplineConstants.UPDATE_DISCIPLINE_ENTITIES.TRIGGER,
    updateDisciplineEntitiesWorker
  ),

  fork(takeEvery, regionConstants.FETCH_REGIONS.TRIGGER, fetchRegionsWorker),
  fork(takeEvery, regionConstants.CREATE_REGION.TRIGGER, createRegionWorker),
  fork(takeEvery, regionConstants.UPDATE_REGION.TRIGGER, updateRegionWorker),
  fork(takeEvery, regionConstants.DELETE_REGION.TRIGGER, deleteRegionWorker),
  fork(
    takeEvery,
    regionConstants.UPDATE_REGION_ENTITIES.TRIGGER,
    updateRegionEntitiesWorker
  ),

  fork(takeEvery, officeConstants.FETCH_OFFICES.TRIGGER, fetchOfficesWorker),
  fork(takeEvery, officeConstants.CREATE_OFFICE.TRIGGER, createOfficeWorker),
  fork(
    takeEvery,
    officeConstants.UPDATE_OFFICE_METADATA.TRIGGER,
    updateOfficeMetadataWorker
  ),
  fork(
    takeEvery,
    officeConstants.UPDATE_OFFICE_LOCATION.TRIGGER,
    updateOfficeLocationWorker
  ),
  fork(takeEvery, officeConstants.DELETE_OFFICE.TRIGGER, deleteOfficeWorker),
  fork(
    takeEvery,
    officeConstants.UPDATE_OFFICE_ENTITIES.TRIGGER,
    updateOfficeEntitiesWorker
  ),

  fork(takeEvery, fetchLocations, locationsWorkers.fetchLocationsWorker),

  fork(
    takeEvery,
    locationConstants.FETCH_LOCATIONS_BY_TEAM_MEMBERSHIP.TRIGGER,
    locationsWorkers.fetchLocationsByTeamMembershipWorker
  ),

  fork(
    takeEvery,
    fetchEntityLocations,
    locationsWorkers.fetchEntityLocationsWorker
  ),

  fork(
    takeEvery,
    locationConstants.CREATE_OR_UPDATE_ENTITY_LOCATION.TRIGGER,
    locationsWorkers.createOrUpdateEntityLocationWorker
  ),

  fork(
    takeEvery,
    deleteEntityLocation,
    locationsWorkers.deleteEntityLocationWorker
  ),

  fork(
    takeEvery,
    associateEntityToLocation,
    locationsWorkers.associateEntityToLocationWorker
  ),

  fork(
    takeEvery,
    fetchPTOPolicyPerMember,
    ptoWorkers.fetchPTOPolicyPerMemberWorker
  ),

  fork(takeEvery, fetchPTOPolicies, ptoWorkers.fetchPTOPoliciesWorker),

  fork(takeEvery, fetchPTOPolicy, ptoWorkers.fetchPTOPolicyWorker),

  fork(takeEvery, createPTOPolicy, ptoWorkers.createPTOPolicyWorker),

  fork(takeEvery, updatePTOPolicy, ptoWorkers.updatePTOPolicyWorker),

  fork(takeEvery, archivePTOPolicy, ptoWorkers.archivePTOPolicyWorker),

  fork(
    takeEvery,
    fetchPTOGroupMemberships,
    ptoWorkers.fetchPTOGroupMembershipsWorker
  ),

  fork(
    takeEvery,
    createPTOGroupMembership,
    ptoWorkers.createPTOGroupMembershipWorker
  ),

  fork(
    takeEvery,
    updatePTOGroupMembership,
    ptoWorkers.updatePTOGroupMembershipWorker
  ),

  fork(
    takeEvery,
    deletePTOGroupMembership,
    ptoWorkers.deletePTOGroupMembershipWorker
  ),

  fork(takeLatest, constants.PASSWORD_UPDATE.TRIGGER, updatePassword),
  fork(
    takeLatest,
    constants.PASSWORD_SETTINGS_UPDATE.TRIGGER,
    updateSettingsPassword
  ),
  fork(takeEvery, resetPassword, authenticationWorkers.resetPasswordWorker),

  fork(takeLatest, constants.PROFILE_UPDATE.TRIGGER, postProfile),
  fork(
    takeLatest,
    constants.TOGGLE_EMAIL_NOTIFICATION.TRIGGER,
    updateEmailNotification
  ),

  fork(
    takeLatest,
    constants.SUBSCRIPTION_CANCELLATION.TRIGGER,
    cancelSubscription
  ),
  fork(
    takeLatest,
    constants.SUBSCRIPTION_FETCH.TRIGGER,
    fetchCurrentSubscription
  ),

  fork(takeLatest, constants.FETCH_MY_PROJECTS.TRIGGER, fetchMyActiveProjects),

  fork(
    conditionalTakeLatest,
    constants.FETCH_ALL_PROJECTS.TRIGGER,
    fetchAllActiveProjects,
    (action) => action.payload.takeLatest,
    ['filterListId']
  ),

  fork(
    conditionalTakeLatest,
    constants.FETCH_PROJECTS_INDEX_V2.TRIGGER,
    fetchProjectsIndexV2,
    (action) => action.payload.takeLatest,
    ['filterListId']
  ),
  fork(
    takeEvery,
    constants.FETCH_PROJECT_CLIENTS.TRIGGER,
    fetchProjectClientsWorker
  ),
  fork(takeLatest, constants.MY_PROJECT_UPDATE.TRIGGER, updateMyProject),
  fork(
    takeLatest,
    constants.FETCH_TEAM_MEMBER_PROFILE.TRIGGER,
    teamMemberProfileFetch
  ),
  fork(
    takeLatest,
    constants.FETCH_TEAM_MEMBER_DETAIL.TRIGGER,
    fetchTeamMemberWorker
  ),

  fork(
    takeEvery,
    constants.FETCH_COMMENTS_AND_METADATA.TRIGGER,
    fetchCommentsAndMetadata
  ),

  fork(takeLatest, constants.CREATE_COMMENT.TRIGGER, createComment),

  fork(takeLatest, constants.UPDATE_COMMENT.TRIGGER, updateComment),

  fork(takeLatest, constants.DELETE_COMMENT.TRIGGER, deleteComment),

  fork(
    takeLatest,
    constants.DELETE_TASK_ATTACHMENT.TRIGGER,
    deleteTaskAttachment
  ),
  fork(
    takeLatest,
    constants.DELETE_PROJECT_ATTACHMENT.TRIGGER,
    deleteProjectAttachment
  ),

  fork(takeLatest, constants.FETCH_DAY_PLANNER.TRIGGER, dayPlannerFetch),

  fork(takeLatest, constants.UPDATE_DAY_PLANNER.TRIGGER, dayPlannerUpdate),

  fork(takeLatest, constants.FETCH_NOTIFICATIONS.TRIGGER, fetchNotifications),

  fork(
    conditionalTakeLatest,
    constants.FETCH_CHECKINS_HOURS.TRIGGER,
    fetchCheckinsHoursWorker,
    undefined,
    ['filterStateId']
  ),

  fork(
    conditionalTakeLatest,
    constants.FETCH_CHECKINS.TRIGGER,
    fetchCheckinsWorker,
    undefined,
    ['filterStateId']
  ),

  fork(
    takeLatest,
    constants.BULK_CREATE_CHECKIN.TRIGGER,
    bulkCreateCheckinWorker
  ),

  fork(takeLatest, constants.CREATE_CHECKIN.TRIGGER, createCheckinWorker),

  fork(takeLatest, constants.DELETE_CHECKIN.TRIGGER, deleteCheckinWorker),

  fork(takeLatest, constants.UPDATE_CHECKIN.TRIGGER, updateCheckinWorker),

  fork(takeLatest, constants.FETCH_TIMERS.TRIGGER, fetchTimersWorker),

  fork(
    takeLatest,
    constants.FETCH_ACTIVE_TIMER.TRIGGER,
    fetchActiveTimerWorker
  ),

  fork(takeLatest, constants.START_TIMER.TRIGGER, startTimerWorker),

  fork(takeLatest, constants.DELETE_TIMER.TRIGGER, deleteTimerWorker),

  fork(takeLatest, constants.UPDATE_TIMER.TRIGGER, updateTimerWorker),

  fork(takeLatest, constants.END_TIMER.TRIGGER, endTimerWorker),

  fork(takeLatest, constants.ASSOCIATE_TASK.TRIGGER, associateTaskWorker),

  fork(
    takeLatest,
    constants.UPDATE_NOTIFICATION_AS_READ.TRIGGER,
    updateNotificationAsRead
  ),

  fork(
    takeLatest,
    constants.MARK_ALL_NOTIFICATIONS_READ.TRIGGER,
    markAllNotificationsRead
  ),

  fork(
    takeLatest,
    constants.UPDATE_NOTIFICATIONS_AS_OLD.TRIGGER,
    updateNotificationsAsOld
  ),

  // This is not being actively called by users. This is used when changing location.
  fork(takeLatest, constants.LOCATION_CHANGE, fetchNewNotificationsCount),
  // This is for fetching by user
  fork(
    takeLatest,
    constants.FETCH_NOTIFICATIONS_COUNT.TRIGGER,
    fetchNewNotificationsCount
  ),

  fork(takeEvery, constants.FETCH_PLANNERS.TRIGGER, fetchPlanners),

  fork(takeEvery, constants.WS_SCHEDULE_BAR, refetchWorkloadWorker),
  fork(
    takeEvery,
    constants.FETCH_WORKLOAD_PLANNER.TRIGGER,
    fetchWorkloadPlanner
  ),
  fork(
    takeLatest,
    constants.UPDATE_WORKLOAD_PLANNER.TRIGGER,
    updateWorkloadPlanner
  ),
  fork(
    takeLatest,
    constants.CREATE_WORKLOAD_PLANNER.TRIGGER,
    createWorkloadPlanner
  ),
  fork(takeLatest, constants.PREDICT_WORKDAYS.TRIGGER, predictWorkdays),
  fork(
    takeLatest,
    constants.PREDICT_WORKLOAD_PLANNER.TRIGGER,
    predictWorkloadPlanner
  ),
  fork(
    takeLatest,
    constants.SPLIT_WORKLOAD_PLANNER.TRIGGER,
    splitWorkloadPlanner
  ),
  fork(
    takeLatest,
    constants.APPROVE_WORKPLAN_REQUESTS.TRIGGER,
    approveWorkplanRequestsWorker
  ),
  fork(
    takeLatest,
    constants.DELETE_WORKLOAD_PLANNER.TRIGGER,
    deleteWorkloadPlanner
  ),
  fork(
    takeEvery,
    constants.FETCH_WORKLOAD_PLANNER_EVENTS.TRIGGER,
    fetchWorkloadPlannerEvents
  ),
  fork(
    takeEvery,
    constants.FETCH_WORKLOAD_PLANNER_EVENT.TRIGGER,
    fetchWorkloadPlannerEvent
  ),
  fork(takeLatest, constants.FETCH_SUGGESTIONS.TRIGGER, fetchSuggestions),
  fork(
    takeLatest,
    constants.UPDATE_WORKLOAD_PLANNER_EVENT.TRIGGER,
    updateWorkloadPlannerEvent
  ),
  fork(
    takeLatest,
    constants.CREATE_WORKLOAD_PLANNER_EVENT.TRIGGER,
    createWorkloadPlannerEvent
  ),
  fork(
    takeLatest,
    constants.CREATE_NOTIFICATION_PREVIEW.TRIGGER,
    createNotificationPreview
  ),
  fork(
    takeLatest,
    constants.SEND_WORKLOAD_PLANNER_EVENT.TRIGGER,
    sendWorkloadPlannerEvent
  ),

  fork(
    takeLatest,
    constants.DELETE_WORKLOAD_PLANNER_EVENT.TRIGGER,
    deleteWorkloadPlannerEvent
  ),
  fork(
    takeEvery,
    constants.FETCH_WORKLOAD_PLANNER_EVENT_LAST_SENT.TRIGGER,
    fetchWorkloadPlannerEventLastSent
  ),
  fork(
    takeEvery,
    constants.WS_SCHEDULED_EVENT,
    refetchOnWSScheduledEventWorker
  ),
  fork(
    takeLatest,
    constants.UPDATE_WORKLOAD_PLANNER_MEMBERS.TRIGGER,
    updateWorkloadPlannerMembers
  ),
  fork(takeEvery, constants.FETCH_MEMBER_PROJECTS.TRIGGER, fetchMemberProjects),

  fork(takeEvery, constants.FETCH_ACTIVITIES.TRIGGER, fetchActivities),
  fork(takeLatest, constants.FETCH_DESCRIPTIONS.TRIGGER, fetchDescriptions),
  fork(takeEvery, constants.CREATE_TIMESHEET.TRIGGER, createTimesheet),
  fork(takeEvery, constants.UPDATE_TIMESHEET.TRIGGER, updateTimesheet),
  fork(
    takeLatest,
    constants.BULK_UPDATE_TIMESHEETS.TRIGGER,
    bulkUpdateTimesheets
  ),
  fork(takeLatest, constants.DELETE_TIMESHEETS.TRIGGER, deleteTimesheets),
  fork(takeLatest, constants.SUBMIT_DATES, submitDates),
  fork(takeLatest, constants.FETCH_TIMESHEETS.TRIGGER, fetchTimesheets),
  fork(
    takeLatest,
    constants.FETCH_FILTERED_USER_ACTIVITIES_FOR_PROJECTS.TRIGGER,
    fetchFilteredActivitiesPerProject
  ),
  fork(
    takeLatest,
    constants.FETCH_TIMESHEETS_INDEX.TRIGGER,
    fetchTimesheetsIndex
  ),
  fork(
    takeEvery,
    constants.FETCH_TIMESHEETS_UTILIZATION.TRIGGER,
    fetchTimesheetsUtilizationWorker
  ),
  fork(
    takeEvery,
    constants.PROCESS_TIME_ENTRIES_FOR_QUICKBOOKS.TRIGGER,
    processTimeEntriesForQuickbooksWorker
  ),
  fork(takeEvery, constants.EXPORT_TIMESHEETS.TRIGGER, exportTimesheets),
  fork(
    takeLatest,
    constants.FETCH_USER_ACTIVITIES.TRIGGER,
    fetchUserActivities
  ),

  fork(takeLatest, constants.BULK_EDIT_TASKS.TRIGGER, bulkEditTasks),

  fork(takeLatest, constants.BULK_EDIT_HOME_TASKS.TRIGGER, bulkEditHomeTasks),

  fork(
    takeEvery,
    [
      constants.TASK_LIST_LOADING_INITIAL_TASKS.SUCCESS,
      constants.TASK_LIST_LOADING_ADDITIONAL_TASKS.SUCCESS
    ],
    fetchProjectsForTasksWorker
  ),

  fork(
    takeLatest,
    constants.FETCH_ALL_HOME_TASK_TAGS.TRIGGER,
    fetchFilteredHomeTaskTagsWorker
  ),

  fork(
    takeEvery,
    constants.PATCH_ACCOUNT_DEFAULT_FOLLOW_UP_MESSAGE.TRIGGER,
    updateAccountsDefaultFollowUpMessage
  ),

  fork(
    takeEvery,
    constants.UPDATE_NOTIFICATION_PREFERENCES.TRIGGER,
    updateNotificationPreferences
  ),

  fork(
    takeEvery,
    constants.UPDATE_PROJECT_MODULES.TRIGGER,
    updateProjectModules
  ),
  fork(takeEvery, constants.UPDATE_BOARD_MODULES.TRIGGER, updateBoardModules),
  fork(takeEvery, constants.CREATE_ACTIVITY.TRIGGER, createActivity),
  fork(takeEvery, constants.UPDATE_ACTIVITY.TRIGGER, updateActivity),
  fork(
    takeEvery,
    constants.FETCH_ACTIVITY_MEMBERS.TRIGGER,
    fetchActivityMembersWorker
  ),
  fork(
    takeEvery,
    constants.CREATE_ACTIVITY_MEMBERS.TRIGGER,
    createActivityMembersWorker
  ),
  fork(
    takeEvery,
    constants.ARCHIVE_ACTIVITY_MEMBER.TRIGGER,
    archiveActivityMemberWorker
  ),
  fork(takeEvery, constants.CREATE_DESCRIPTION.TRIGGER, createDescription),
  fork(takeEvery, constants.UPDATE_DESCRIPTION.TRIGGER, updateDescription),
  fork(takeEvery, constants.DELETE_DESCRIPTIONS.TRIGGER, deleteDescriptions),
  fork(takeEvery, constants.UPDATE_DESCRIPTION.TRIGGER, updateDescription),
  fork(
    takeEvery,
    constants.CHECK_HAS_TIME_ENTRIES.TRIGGER,
    checkHasTimeEntriesWorker
  ),
  fork(
    takeEvery,
    constants.FETCH_REMAINING_TIMESHEET_HOURS.TRIGGER,
    fetchRemainingTimesheetHoursWorker
  ),
  fork(
    takeEvery,
    constants.FETCH_PROJECT_COLOR_PREFERENCES.TRIGGER,
    fetchProjectColorPreferences
  ),
  fork(
    takeEvery,
    constants.UPDATE_PROJECT_COLOR_PREFERENCE.TRIGGER,
    updateProjectColorPreference
  ),
  fork(
    takeEvery,
    constants.CREATE_PROJECT_COLOR_PREFERENCE.TRIGGER,
    createProjectColorPreference
  ),
  fork(
    takeEvery,
    constants.DELETE_PROJECT_COLOR_PREFERENCE.TRIGGER,
    deleteProjectColorPreference
  ),
  fork(
    takeEvery,
    constants.FETCH_BOARD_PREFERENCES.TRIGGER,
    fetchBoardPreferences
  ),
  fork(
    takeEvery,
    constants.UPDATE_BOARD_PREFERENCE.TRIGGER,
    updateBoardPreference
  ),
  fork(
    takeEvery,
    constants.CREATE_BOARD_PREFERENCE.TRIGGER,
    createBoardPreference
  ),
  fork(
    takeEvery,
    constants.DELETE_BOARD_PREFERENCE.TRIGGER,
    deleteBoardPreference
  ),
  fork(
    takeEvery,
    constants.FETCH_TASK_GROUP_PREFERENCES.TRIGGER,
    fetchTaskGroupPreferences
  ),
  fork(
    takeEvery,
    constants.UPDATE_TASK_GROUP_PREFERENCE.TRIGGER,
    updateTaskGroupPreference
  ),
  fork(
    takeEvery,
    constants.CREATE_TASK_GROUP_PREFERENCE.TRIGGER,
    createTaskGroupPreference
  ),
  fork(
    takeEvery,
    constants.DELETE_TASK_GROUP_PREFERENCE.TRIGGER,
    deleteTaskGroupPreference
  ),
  fork(
    takeEvery,
    constants.FETCH_TEAM_MEMBERSHIP_PREFERENCES.TRIGGER,
    fetchTeamMembershipPreferences
  ),
  fork(
    takeEvery,
    constants.UPDATE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER,
    updateTeamMembershipPreference
  ),
  fork(
    takeEvery,
    constants.CREATE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER,
    createTeamMembershipPreference
  ),
  fork(
    takeEvery,
    constants.DELETE_TEAM_MEMBERSHIP_PREFERENCE.TRIGGER,
    deleteTeamMembershipPreference
  ),
  fork(
    takeEvery,
    constants.FETCH_TEAM_PREFERENCES.TRIGGER,
    fetchTeamPreferences
  ),
  fork(
    takeEvery,
    constants.UPDATE_TEAM_PREFERENCE.TRIGGER,
    updateTeamPreference
  ),
  fork(
    takeEvery,
    constants.CREATE_TEAM_PREFERENCE.TRIGGER,
    createTeamPreference
  ),
  fork(
    takeEvery,
    constants.DELETE_TEAM_PREFERENCE.TRIGGER,
    deleteTeamPreference
  ),
  fork(takeEvery, constants.FETCH_TAG_PREFERENCES.TRIGGER, fetchTagPreferences),
  fork(takeEvery, constants.UPDATE_TAG_PREFERENCE.TRIGGER, updateTagPreference),
  fork(takeEvery, constants.CREATE_TAG_PREFERENCE.TRIGGER, createTagPreference),
  fork(takeEvery, constants.DELETE_TAG_PREFERENCE.TRIGGER, deleteTagPreference),
  fork(
    takeEvery,
    [
      constants.FETCH_USER_PERMISSIONS.TRIGGER,
      constants.PROJECT_CREATION.SUCCESS,
      constants.GROUP_CREATION.SUCCESS,
      constants.ADD_MEMBER_TO_PROJECT.SUCCESS,
      constants.ADD_BULK_MEMBERS_TO_PROJECT.SUCCESS,
      constants.ADD_MEMBER_TO_GROUP.SUCCESS,
      constants.ADD_BULK_MEMBERS_TO_GROUP.SUCCESS,
      constants.UPDATE_PROJECT_MEMBER_ROLE.SUCCESS,
      constants.UPDATE_BOARD_MEMBER_ROLE.SUCCESS,
      updateTeamMembersPermissionActionCreatorsMap.success.type,
      bulkInviteAccountsActionCreatorsMap.success.type
    ],
    fetchUserPermissions
  ),
  fork(
    takeEvery,
    constants.FETCH_ACCOUNT_FILTER.TRIGGER,
    fetchAccountFilterWorker
  ),
  fork(
    takeEvery,
    constants.FETCH_ACCOUNT_FILTERS.TRIGGER,
    fetchAccountFiltersWorker
  ),
  fork(
    takeEvery,
    constants.FETCH_FILTER_BY_IDS.TRIGGER,
    fetchFilterByIdsWorker
  ),
  fork(
    takeEvery,
    constants.UPDATE_ACCOUNT_FILTER.TRIGGER,
    updateAccountFilterWorker
  ),
  fork(
    takeEvery,
    constants.CREATE_ACCOUNT_FILTER.TRIGGER,
    createAccountFilterWorker
  ),
  fork(
    takeEvery,
    constants.DELETE_ACCOUNT_FILTER.TRIGGER,
    deleteAccountFilterWorker
  ),
  fork(takeEvery, constants.FETCH_ACCESS.TRIGGER, fetchAccessWorker),
  fork(takeEvery, constants.UPDATE_ACCESS.TRIGGER, updateAccessWorker),
  fork(
    takeLatest,
    constants.CLOSE_CLEAR_TASK_VIEW_MODAL,
    closeAndClearTaskViewModal
  ),
  fork(
    takeLatest,
    constants.CLOSE_CLEAR_MEMBER_MODAL,
    closeAndClearMemberModal
  ),
  fork(takeLatest, constants.CLOSE_HOME_TASK_MODAL, closeAndClearTaskViewModal),
  fork(takeLatest, constants.NAVIGATE_BACK, navigateBack),
  fork(takeLatest, constants.NAVIGATE_UP, navigateUp),
  fork(takeLatest, constants.NAVIGATE_TO, navigateTo),
  fork(takeLatest, constants.NAVIGATION_CHANGE, navigationChange),
  fork(takeLatest, constants.NAVIGATION_CHANGE, clearDenyPermission),
  fork(takeLatest, constants.NAVIGATION_CHANGE, closeWorkloadFilterWorker),
  fork(
    takeEvery,
    noteModuleConstants.FETCH_PROJECT_NOTES_V2.TRIGGER,
    fetchProjectNotesV2
  ),
  fork(takeEvery, constants.FETCH_TASKS_V2.TRIGGER, fetchTasksV2),
  fork(
    takeEvery,
    constants.FETCH_MEMBER_TODAY_TASK_COUNT.TRIGGER,
    fetchTodayMemberTaskCount
  ),
  fork(
    takeEvery,
    constants.FETCH_MEMBER_OVERDUE_TASK_COUNT.TRIGGER,
    fetchOverdueMemberTaskCount
  ),
  fork(
    takeEvery,
    constants.FETCH_MEMBER_SCHEDULED_TASK_COUNT.TRIGGER,
    fetchScheduledMemberTaskCount
  ),
  fork(
    takeEvery,
    constants.FETCH_MEMBER_UNSCHEDULED_TASK_COUNT.TRIGGER,
    fetchUnscheduledMemberTaskCount
  ),
  fork(
    takeEvery,
    constants.FETCH_MEMBER_COMPLETED_TASK_COUNT.TRIGGER,
    fetchCompletedMemberTaskCount
  ),
  fork(
    takeEvery,
    constants.FETCH_MEMBER_ENDS_BEFORE_TASK_COUNT.TRIGGER,
    fetchEndsBeforeMemberTaskCount
  ),
  fork(takeEvery, constants.EXPORT_TASKS.TRIGGER, exportTasks),
  fork(takeEvery, constants.GENERATE_REPORT.TRIGGER, generateReportWorker),
  fork(
    takeEvery,
    constants.FETCH_TIMESHEETS_PREDICTIONS.TRIGGER,
    fetchTimesheetsPredictions
  ),
  fork(
    takeEvery,
    constants.MOVE_PROJECTS_TO_BOARD.TRIGGER,
    moveProjectsToBoard
  ),
  fork(takeEvery, constants.CLONE_PROJECTS.TRIGGER, cloneProjectList),
  fork(takeEvery, constants.FETCH_WORK_GROUPS.TRIGGER, fetchWorkGroups),
  fork(takeEvery, constants.FETCH_WORK_GROUP.TRIGGER, fetchWorkGroup),
  fork(takeEvery, constants.CREATE_WORK_GROUP.TRIGGER, createWorkGroup),
  fork(takeEvery, constants.UPDATE_WORK_GROUP.TRIGGER, updateWorkGroup),
  fork(takeEvery, constants.ARCHIVE_WORK_GROUP.TRIGGER, archiveWorkGroup),
  fork(takeEvery, constants.UNARCHIVE_WORK_GROUP.TRIGGER, unarchiveWorkGroup),
  fork(takeEvery, constants.DELETE_WORK_GROUP.TRIGGER, deleteWorkGroup),
  fork(
    takeEvery,
    constants.CREATE_WORK_GROUP_MEMBERSHIP.TRIGGER,
    createWorkGroupMembership
  ),
  fork(
    takeEvery,
    constants.DELETE_WORK_GROUP_MEMBERSHIP.TRIGGER,
    deleteWorkGroupMembership
  ),
  fork(
    takeEvery,
    constants.WS_WORK_GROUP_MEMBERSHIP,
    refetchOnWSWorkGroupMembership
  ),
  fork(takeEvery, constants.FETCH_SKILLS.TRIGGER, fetchSkillsWorker),
  fork(takeEvery, constants.CREATE_SKILL.TRIGGER, createSkillWorker),
  fork(takeEvery, constants.UPDATE_SKILL.TRIGGER, updateSkillWorker),
  fork(takeEvery, constants.ARCHIVE_SKILL.TRIGGER, archiveSkillWorker),
  fork(takeEvery, constants.UNARCHIVE_SKILL.TRIGGER, unarchiveSkillWorker),
  fork(takeEvery, constants.DELETE_SKILL.TRIGGER, deleteSkillWorker),
  fork(
    takeEvery,
    constants.CREATE_SKILL_MEMBERSHIP.TRIGGER,
    createSkillMembershipWorker
  ),
  fork(
    takeEvery,
    constants.UPDATE_SKILL_MEMBERSHIP.TRIGGER,
    updateSkillMembershipWorker
  ),
  fork(
    takeEvery,
    constants.DELETE_SKILL_MEMBERSHIP.TRIGGER,
    deleteSkillMembershipWorker
  ),
  fork(
    takeEvery,
    constants.FETCH_ENTITY_SKILLS_AND_SKILLS_REPORT.TRIGGER,
    fetchEntitySkillsAndSkillsReportWorker
  ),
  fork(
    conditionalTakeLatest,
    constants.FETCH_SKILLS_REPORT.TRIGGER,
    fetchSkillsReportWorker,
    undefined, // use default
    ['filterStateId']
  ),
  fork(
    takeEvery,
    constants.FETCH_ENTITY_SKILLS.TRIGGER,
    fetchEntitySkillsWorker
  ),
  fork(
    takeEvery,
    constants.CREATE_ENTITY_SKILL.TRIGGER,
    createEntitySkillWorker
  ),
  fork(
    takeEvery,
    constants.UPDATE_ENTITY_SKILL.TRIGGER,
    updateEntitySkillWorker
  ),
  fork(
    takeEvery,
    constants.DELETE_ENTITY_SKILL.TRIGGER,
    deleteEntitySkillWorker
  ),

  fork(takeEvery, constants.CREATE_CLIENT.TRIGGER, createClient),
  fork(takeEvery, constants.UPDATE_CLIENT.TRIGGER, updateClient),
  fork(takeEvery, constants.FETCH_CLIENTS.TRIGGER, fetchClients),
  fork(takeEvery, constants.FETCH_CLIENT.TRIGGER, fetchClient),
  fork(
    takeEvery,
    constants.FETCH_PROJECT_TOTALS.TRIGGER,
    fetchProjectTotalsWorker
  ),
  fork(
    takeEvery,
    constants.FETCH_USER_ACTIVITIES_FOR_ACTIONABLE.TRIGGER,
    fetchUserActivitiesForActionable
  ),
  fork(
    takeEvery,
    constants.FETCH_ACTIVITY_LOG_USER_ACTIVITIES.TRIGGER,
    fetchActivityLogUserActivities
  ),
  fork(
    takeEvery,
    constants.LOAD_CSV_IMPORT_DATA.TRIGGER,
    loadCSVImportDataWorker
  ),
  // fork(
  //   takeLatest,
  //   constants.FETCH_RECURLY_BILLING_INFO.TRIGGER,
  //   fetchRecurlyBillingInfo
  // ),
  // fork(
  //   takeLatest,
  //   constants.SET_RECURLY_BILLING_INFO.TRIGGER,
  //   updateRecurlyBillingInfo
  // ),
  fork(
    takeEvery,
    constants.FETCH_PREDICTION_HOURS.TRIGGER,
    fetchPredictionHours
  ),
  fork(takeLatest, constants.FETCH_PRIORITIES.TRIGGER, fetchPrioritiesWorker),
  fork(
    conditionalTakeLatest,
    constants.FETCH_SCOPES.TRIGGER,
    fetchScopesWorker,
    undefined, // use default
    ['filterStateId']
  ),
  fork(takeEvery, constants.CREATE_SCOPE.TRIGGER, createScopeWorker),
  fork(takeEvery, constants.UPDATE_SCOPE.TRIGGER, updateScopeWorker),
  fork(takeEvery, constants.DELETE_SCOPE.TRIGGER, deleteScopeWorker),
  fork(
    takeEvery,
    constants.ASSIGN_SCOPE_MEMBERS.TRIGGER,
    assignScopeMembersWorker
  ),
  fork(
    takeEvery,
    constants.UNASSIGN_SCOPE_MEMBERS.TRIGGER,
    unassignScopeMembersWorker
  ),
  fork(
    takeEvery,
    constants.UPDATE_SCOPE_MEMBERSHIP.TRIGGER,
    updateScopeMembershipWorker
  ),
  fork(takeEvery, constants.MOVE_SCOPES.TRIGGER, moveScopesWorker),
  fork(
    takeEvery,
    constants.UPDATE_SCOPE_POSITION.TRIGGER,
    updateScopePositionWorker
  ),
  fork(
    takeEvery,
    constants.DELETE_SCOPE_ATTACHMENT.TRIGGER,
    deleteScopeAttachmentWorker
  ),
  fork(
    conditionalTakeLatest,
    constants.CHAIN_ACTIONS,
    chainActionsWorker,
    (action) => action.payload.takeLatest,
    ['chainId']
  ),
  fork(
    takeEvery,
    constants.FETCH_ENTITY_COMMENTS.TRIGGER,
    fetchEntityCommentsWorker
  ),
  fork(
    takeEvery,
    constants.CREATE_ENTITY_COMMENT.TRIGGER,
    createEntityCommentWorker
  ),
  fork(
    takeEvery,
    constants.UPDATE_ENTITY_COMMENT.TRIGGER,
    updateEntityCommentWorker
  ),
  fork(
    takeEvery,
    constants.DELETE_ENTITY_COMMENT.TRIGGER,
    deleteEntityCommentWorker
  )
];
export default function* rootSaga() {
  yield fork(takeLatest, constants.LOGIN.TRIGGER, postLogin);
  yield fork(takeLatest, constants.SIGNUP.TRIGGER, postSignup);
  yield fork(takeEvery, '*', handleTriggerWorker);
  yield fork(takeEvery, '*', handleRequestStatusWorker);

  // listening for these three actions lets us restart sagas after cancelling them (due to logout or refresh)
  while (
    take([
      constants.LOGIN.TRIGGER,
      constants.SIGNUP.TRIGGER,
      constants.LOCATION_CHANGE
    ])
  ) {
    const nonLoginSagas = yield all([
      ...phaseSagas,
      ...homeTasksSagas,
      ...appSagas,
      ...entityOptionsSagas,
      ...currencySagas,
      ...permissionsSagas,
      ...leanApiSagas,
      ...galaxySagas,
      ...usersSagas
    ]);
    const registeredSagas = yield all(nonLoginSagas);

    // upon receiving the LOGOUT action, cancel all sagas midflight
    // prevents redux being populated by requests that come back post-logout
    yield take(constants.LOGOUT_USER);
    yield cancel(...registeredSagas);
    yield call(redirectToAuthServer);
  }
}
