import { useMemo } from 'react';
import { DependencyList } from './DependencyList';
import { DepedencyListProps } from 'components/Dependency/types';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { makeGetPhaseDependencyItems } from 'selectors';
import { DEPENDABLE_LABELS, DEPENDABLE_TYPES } from './constants';
import { PhaseDependencyItemRenderer } from './PhaseDependencyItemRenderer';
import { useAppSelector } from 'reduxInfra/hooks';

interface BasePhaseDependencyListProps {
  phase: Phase;
  dependencyListProps: Omit<
    DepedencyListProps,
    'dependableItems' | 'baseDependableTypeLabel' | 'targetDependableTypeLabel'
  >;
}

export const BasePhaseDependencyList = ({
  phase,
  dependencyListProps
}: BasePhaseDependencyListProps) => {
  const { project_id: projectId, id: phaseId } = phase;

  const getPhaseDependencyItems = useMemo(
    () => makeGetPhaseDependencyItems(),
    []
  );
  const dependableItems = useAppSelector((state) =>
    getPhaseDependencyItems(state, {
      phaseId,
      projectId
    })
  );

  return (
    <DependencyList
      dependableItems={dependableItems}
      baseDependableTypeLabel={DEPENDABLE_LABELS[DEPENDABLE_TYPES.PHASE]}
      targetDependableTypeLabel={DEPENDABLE_LABELS[DEPENDABLE_TYPES.PHASE]}
      renderDependableItem={PhaseDependencyItemRenderer}
      {...dependencyListProps}
    />
  );
};
