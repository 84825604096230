import { loadState } from 'service/localStorage';
import { RealmAPIClient } from './RealmAPIClient';

/**
 * realmId is optional. if not provided, it will be loaded from localStorage.
 * if realId is not in localStorage, it will be set to default baseURL.
 */
export const updateAPIClientBaseURL = ({
  APIClient,
  realmId
}: {
  APIClient: RealmAPIClient;
  realmId?: string;
}) => {
  const realmIdToUse = realmId ?? getStoredRealmId();

  const baseURLToUse = realmIdToUse
    ? buildRealmURL({
        baseURL: APIClient.config.baseURL,
        realmId: realmIdToUse
      })
    : APIClient.config.baseURL;

  APIClient.updateConfig({
    baseURL: baseURLToUse
  });
};

const denyHostList = [
  'localhost',
  'staging-api.mosaicapp.com',
  'pilot-api.mosaicapp.com',
  'prod-api.mosaicapp.com'
];

const isRealmAvailable = (baseURL: string) => {
  const { host } = new URL(baseURL);
  return !denyHostList.includes(host);
};

/**
 * it builds realm baseURL from baseURL and realmId
 * by replacing the realmId with the host of the baseURL
 * expected example output: [protocol]//[realmid].api.[region].[env].mosaicapp.com
 */
export const buildRealmURL = ({
  baseURL,
  realmId
}: {
  baseURL: string;
  realmId: string;
}) => {
  if (!isRealmAvailable(baseURL)) return baseURL;

  const { protocol, host, pathname } = new URL(baseURL);

  // should replace [realmid] in the host with passed realmId
  const hostToUse = host
    .split('.')
    .map((val, i) => (i === 0 ? realmId : val))
    .join('.');
  return protocol + '//' + hostToUse + pathname;
};

const getStoredRealmId = (): string | undefined => {
  return loadState()?.auth?.realm?.id;
};
