import * as appConstants from 'appConstants';
import * as constants from '../constants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

const defaultMosaicMappingStatusesState = {
  [constants.DATA_TYPES.PROJECTS]: {},
  [constants.DATA_TYPES.PHASES]: {},
  [constants.DATA_TYPES.ACTIVITY_PHASE]: {},
  [constants.DATA_TYPES.CLIENTS]: {},
  [constants.DATA_TYPES.ACCOUNTS]: {},
  [constants.DATA_TYPES.ACTIVITIES]: {},
  [constants.DATA_TYPES.TIME_ENTRIES]: {}
};

/**
 * Notes:
 * "targetServiceId" <=> "integrationId"
 */

export const initialState = {
  integrationDomains: [],
  integrationsV2: {},
  integrations: {
    quickbooks: false
  },
  settingsSchemas: {},
  authStatuses: {},
  isLoading: true,
  isV2Loading: true,
  isV2Error: false,
  isIntegrationsDomainsLoading: true,
  syncStatus: {},
  saveButtonClicked: false,
  isCancelling: false,
  isProvisioning: false,
  // Mapping statuses by target service, target service id, data type, mosaic id
  mosaicMappingStatuses: {},
  // Mapping status counts by target service id, data type
  entityStats: {},
  // Mosaic IDs that mapping statuses have been fetched for by data type
  fetchedMappingStatusMosaicIds: defaultMosaicMappingStatusesState,
  integrationAutoLink: {
    isFetchingPhases: false,
    phases: [],
    subPhasesByPhaseName: {},
    targetServiceId: undefined
  },
  isFetchingSettingsSchemaLoading: false,
  isUpdatingConfigs: false,
  isUpdatingConfigsError: false,
  trayConfigWizard: {} // For tray config wizard specifically
};

const integrations = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }

    case constants.UPDATE_TRAY_CONFIG_WIZARD: {
      const {
        solutionInstanceId,
        url,
        reset = false,
        isIframeReady
      } = action.payload;

      if (reset) {
        return {
          ...state,
          trayConfigWizard: {
            isIframeReady: false
          }
        };
      }
      return {
        ...state,
        trayConfigWizard: {
          isIframeReady,
          solutionInstanceId,
          url // Url to open config wizard
        }
      };
    }

    case constants.FETCH_AUTO_LINK_PHASES.TRIGGER: {
      return {
        ...state,
        integrationAutoLink: {
          ...state.integrationAutoLink,
          isFetchingPhases: true
        }
      };
    }

    case constants.FETCH_AUTO_LINK_PHASES.SUCCESS: {
      const { targetServiceId } = action.payload.requestPayload;
      const { name: phasesNameList } = action.payload.response;
      return {
        ...state,
        integrationAutoLink: {
          ...state.integrationAutoLink,
          targetServiceId,
          isFetchingPhases: false,
          subPhasesByPhaseName: {},
          phases: phasesNameList
        }
      };
    }

    case constants.FETCH_AUTO_LINK_PHASES.FAILURE: {
      return {
        ...state,
        integrationAutoLink: {
          ...state.integrationAutoLink,
          isFetchingPhases: false
        }
      };
    }
    case constants.FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME.SUCCESS: {
      const { parentPhaseName } = action.payload.requestPayload;
      const { name: subPhasesList } = action.payload.response;
      return {
        ...state,
        integrationAutoLink: {
          ...state.integrationAutoLink,
          subPhasesByPhaseName: {
            ...state.integrationAutoLink.subPhasesByPhaseName,
            [parentPhaseName]: subPhasesList
          }
        }
      };
    }
    case constants.FETCH_AUTO_LINK_CHILDREN_BY_PHASE_NAME.FAILURE: {
      return state;
    }
    case constants.FETCH_ALL_INTEGRATION_DOMAINS.TRIGGER: {
      return {
        ...state,
        isIntegrationsDomainsLoading: true
      };
    }
    case constants.FETCH_ALL_INTEGRATION_DOMAINS.SUCCESS: {
      return {
        ...state,
        integrationDomains: action.payload.response,
        isIntegrationsDomainsLoading: false
      };
    }
    case constants.FETCH_ALL_INTEGRATION_DOMAINS.FAILURE: {
      return {
        ...state,
        isIntegrationsDomainsLoading: false
      };
    }
    case constants.FETCH_INTEGRATIONS.TRIGGER: {
      return {
        ...state,
        isV2Loading: true
      };
    }
    case constants.FETCH_INTEGRATIONS.SUCCESS: {
      return {
        ...state,
        integrationsV2: keyBy(
          action.payload.response,
          (integration) => integration.targetServiceId
        ),
        isV2Loading: false
      };
    }
    case constants.FETCH_INTEGRATIONS.FAILURE: {
      return {
        ...state,
        isV2Loading: false,
        isV2Error: true
      };
    }

    case constants.FETCH_INTEGRATION_AUTH_STATUS.SUCCESS: {
      const { targetServiceId } = action.payload.requestPayload;
      const { authenticated } = action.payload.response;

      return {
        ...state,
        authStatuses: {
          ...state.authStatuses,
          [targetServiceId]: authenticated
        }
      };
    }

    case constants.DISCONNECT_INTEGRATION_V2.TRIGGER: {
      const { targetServiceId } = action.payload;

      return {
        ...state,
        integrationsV2: omit(state.integrationsV2, targetServiceId)
      };
    }

    case constants.UPDATE_INTEGRATION_CONFIG.TRIGGER: {
      return {
        ...state,
        isUpdatingConfigs: true
      };
    }

    case constants.UPDATE_INTEGRATION_CONFIG.SUCCESS: {
      const { targetServiceId } = action.payload.requestPayload;
      const updatedIntegration = action.payload.response;
      return {
        ...state,
        isUpdatingConfigs: false,
        isUpdatingConfigsError: false,
        integrationsV2: {
          ...state.integrationsV2,
          [targetServiceId]: {
            ...state.integrationsV2[targetServiceId],
            ...updatedIntegration
          }
        }
      };
    }

    case constants.UPDATE_INTEGRATION_CONFIG.FAILURE: {
      return {
        ...state,
        isUpdatingConfigs: false,
        isUpdatingConfigsError: true
      };
    }

    case constants.FETCH_INTEGRATION.REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case constants.FETCH_INTEGRATION.SUCCESS: {
      const [config] = payload.requestPayload;
      const { authenticated = false, auth_error = false } = payload.response;
      const { integration } = config;
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [integration]: authenticated && !auth_error
        },
        isLoading: false,
        syncStatus: { ...payload.response }
      };
    }
    case constants.FETCH_INTEGRATION.FAILURE: {
      return {
        ...state,
        isLoading: false
      };
    }
    case constants.DISCONNECT_INTEGRATION.SUCCESS: {
      const { integration } = payload.requestPayload;
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [integration]: false
        }
      };
    }
    case constants.SAVE_BUTTON_CLICKED: {
      const { saveButtonClicked } = payload;
      return {
        ...state,
        saveButtonClicked
      };
    }

    case constants.IS_SYNC_CANCELLING: {
      const { isCancelling } = payload;
      return {
        ...state,
        isCancelling
      };
    }

    case constants.FETCH_MAPPED_MOSAIC_ACCOUNT_IDS.SUCCESS:
    case constants.FETCH_MAPPED_MOSAIC_CLIENT_IDS.SUCCESS:
    case constants.FETCH_MAPPED_MOSAIC_ACTIVITY_IDS.SUCCESS:
    case constants.FETCH_MAPPED_MOSAIC_PROJECT_IDS.SUCCESS:
    case constants.FETCH_MAPPED_MOSAIC_PHASE_IDS.SUCCESS: {
      const { integrationId, dataType } = payload.requestPayload;
      const { response } = payload;
      const targetService = state.integrationsV2[integrationId].targetService;

      return {
        ...state,
        mosaicMappingStatuses: {
          ...state.mosaicMappingStatuses,
          [targetService]: {
            ...state.mosaicMappingStatuses[targetService],
            [integrationId]: {
              ...(state.mosaicMappingStatuses[targetService]?.[integrationId] ||
                defaultMosaicMappingStatusesState),
              [dataType]: Object.keys(response)
                .filter(
                  (mappingStatus) =>
                    mappingStatus !== constants.MAPPING_STATUS.DO_NOT_LINK
                )
                .reduce((acc, mappingStatus) => {
                  response[mappingStatus].forEach((id) => {
                    // null ids may be in response
                    if (id) {
                      acc[id] = mappingStatus;
                    }
                  });
                  return acc;
                }, {})
            }
          }
        }
      };
    }

    case constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES: {
      const { timeEntryIds } = payload;
      return {
        ...state,
        fetchedMappingStatusMosaicIds: {
          ...state.fetchedMappingStatusMosaicIds,
          [constants.DATA_TYPES.TIME_ENTRIES]: {
            ...state.fetchedMappingStatusMosaicIds[
              constants.DATA_TYPES.TIME_ENTRIES
            ],
            ...timeEntryIds.reduce((acc, cur) => {
              acc[cur] = true;
              return acc;
            }, {})
          }
        }
      };
    }

    // Time entries are treated differently due to data size (cannot be fetched for all at once)
    case constants.FETCH_MOSAIC_TIME_ENTRY_MAPPING_STATUSES_BY_INTEGRATION
      .SUCCESS: {
      const { entities } = payload.response;
      const { integrationId } = payload.requestPayload;
      const targetService = state.integrationsV2[integrationId].targetService;

      const mappingStatusesByMosaicId = entities.reduce((acc, cur) => {
        acc[cur.mosaicId] = cur.mappingStatus;
        return acc;
      }, {});

      return {
        ...state,
        mosaicMappingStatuses: {
          ...state.mosaicMappingStatuses,
          [targetService]: {
            ...state.mosaicMappingStatuses[targetService],
            [integrationId]: {
              ...(state.mosaicMappingStatuses[targetService]?.[integrationId] ||
                defaultMosaicMappingStatusesState),
              [constants.DATA_TYPES.TIME_ENTRIES]: {
                ...state.mosaicMappingStatuses[targetService]?.[
                  integrationId
                ]?.[constants.DATA_TYPES.TIME_ENTRIES],
                ...mappingStatusesByMosaicId
              }
            }
          }
        }
      };
    }

    case constants.FETCH_INTEGRATION_ENTITY_STATS.SUCCESS: {
      const { integrationId } = payload.requestPayload;
      const { response } = payload;

      return {
        ...state,
        entityStats: {
          [integrationId]: response
        }
      };
    }

    case constants.SET_IS_PROVISIONING: {
      return {
        ...state,
        isProvisioning: payload
      };
    }

    case constants.PROVISION_INTEGRATION.TRIGGER: {
      return {
        ...state,
        isProvisioning: true
      };
    }
    case appConstants.GROUP_CREATION.FAILURE: // 'Import' board failed to create
    case constants.PROVISION_INTEGRATION.FAILURE:
    case constants.PROVISION_INTEGRATION.SUCCESS: {
      return {
        ...state,
        isProvisioning: false
      };
    }

    case constants.FETCH_INTEGRATION_SETTINGS_SCHEMA.TRIGGER: {
      return {
        ...state,
        isFetchingSettingsSchemaLoading: true
      };
    }

    case constants.FETCH_INTEGRATION_SETTINGS_SCHEMA.SUCCESS: {
      const schema = payload.response;
      const { targetServiceId } = action.payload.requestPayload;
      return {
        ...state,
        isFetchingSettingsSchemaLoading: false,
        settingsSchemas: {
          ...state.settingsSchemas,
          [targetServiceId]: schema
        }
      };
    }

    default:
      return state;
  }
};

export default integrations;
