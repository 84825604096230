import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

export const initialState = {
  deletedBudgets: {},
  memberBudgetIds: [],
  memberBudgets: {},
  fetchedMemberBudgetProjectIds: {},
  budgetTotals: {}
};
const byId = (item) => item.id;

const memberBudgets = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case budgetConstants.FETCH_MEMBER_BUDGETS.TRIGGER: {
      const { projectId, projectIds } = action.payload;
      const projectIdsToUpdate = getProjectIdsToUpdate(projectId, projectIds);

      if (!projectIdsToUpdate.length) return state;

      return {
        ...state,
        fetchedMemberBudgetProjectIds: {
          ...state.fetchedMemberBudgetProjectIds,
          ...projectIdsToUpdate.reduce((acc, id) => {
            acc[id] =
              state.fetchedMemberBudgetProjectIds[id] === true
                ? true
                : 'pending'; // updated to true on success/failure
            return acc;
          }, {})
        }
      };
    }
    case budgetConstants.FETCH_MEMBER_BUDGETS.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const { projectId, projectIds, memberBudgetIds } = requestPayload;
      const projectIdsToUpdate = getProjectIdsToUpdate(projectId, projectIds);

      // Keep only fetched member budgets that are not in the local deletedBudgets
      const filteredBudgets = response.filter(
        ({ id }) => !state.deletedBudgets[id]
      );
      const updatedMemberBudgets = keyBy(filteredBudgets, byId);

      const projectIdsToUpdateSet = new Set(projectIdsToUpdate);
      const requestMemberBudgetIdsSet = memberBudgetIds
        ? new Set(memberBudgetIds)
        : null;

      // Get member budget ids for project ids that were fetched for
      const memberBudgetIdsToCheck = Object.values(state.memberBudgets).reduce(
        (acc, memberBudget) => {
          // Case 1: if there is at least 1 member budget in requestPayload,
          // we only need to care about updating those specific member budgets

          if (requestMemberBudgetIdsSet) {
            if (
              projectIdsToUpdateSet.has(memberBudget.project_id) &&
              requestMemberBudgetIdsSet.has(memberBudget.id)
            ) {
              acc.push(memberBudget.id);
            }

            return acc;
          }

          // Case 2: if there is no member budget ids set,
          // meaning this is a full fetch for all existing member budgets per project ids
          // and we need to check all member budgets under `projectIdsToUpdate`

          if (projectIdsToUpdateSet.has(memberBudget.project_id)) {
            acc.push(memberBudget.id);
          }
          return acc;
        },
        []
      );

      // The member budget ids that are Not in the filtered response
      // are considered deleted
      const memberBudgetIdsToRemove = memberBudgetIdsToCheck.filter(
        (memberBudgetId) => !updatedMemberBudgets[memberBudgetId]
      );
      // Skill persist.
      Object.keys(updatedMemberBudgets).forEach((key) => {
        updatedMemberBudgets[key] = {
          ...updatedMemberBudgets[key],
          skills: state.memberBudgets[key]?.skills
        };
      });

      // Retain and updated the existing ones with the response
      // And omit the member budgets that were in redux, but removed on the BE
      const memberBudgetNextState = omit(
        {
          ...state.memberBudgets,
          ...updatedMemberBudgets
        },
        memberBudgetIdsToRemove
      );

      const deletedBudgetsNextState = {
        ...state.deletedBudgets,
        ...memberBudgetIdsToRemove.reduce((acc, removedMemberBudgetId) => {
          acc[removedMemberBudgetId] =
            state.memberBudgets[removedMemberBudgetId];
          return acc;
        }, {})
      };

      return {
        ...state,
        deletedBudgets: deletedBudgetsNextState,
        memberBudgetIds: Object.keys(memberBudgetNextState),
        memberBudgets: memberBudgetNextState,
        ...(projectIdsToUpdate.length > 0 && {
          fetchedMemberBudgetProjectIds: {
            ...state.fetchedMemberBudgetProjectIds,
            ...projectIdsToUpdate.reduce((acc, id) => {
              acc[id] = true;
              return acc;
            }, {})
          }
        })
      };
    }
    case budgetConstants.FETCH_MEMBER_BUDGETS.FAILURE: {
      const { projectId, projectIds } = action.payload.requestPayload;
      const projectIdsToUpdate = getProjectIdsToUpdate(projectId, projectIds);

      if (!projectIdsToUpdate.length) return state;

      return {
        ...state,
        fetchedMemberBudgetProjectIds: {
          ...state.fetchedMemberBudgetProjectIds,
          ...projectIdsToUpdate.reduce((acc, id) => {
            acc[id] = true;
            return acc;
          }, {})
        }
      };
    }
    case budgetConstants.FETCH_MEMBER_BUDGET_TOTALS.SUCCESS: {
      const { budgets } = action.payload.response;
      return {
        ...state,
        budgetTotals: keyBy(budgets, (budget) => budget.project_id)
      };
    }
    case budgetConstants.CREATE_MEMBER_BUDGET.SUCCESS: {
      const { member_budgets } = action.payload.response;
      return {
        ...state,
        memberBudgetIds: [
          ...member_budgets.map(byId),
          ...state.memberBudgetIds
        ],
        memberBudgets: {
          ...state.memberBudgets,
          ...keyBy(member_budgets, byId)
        }
      };
    }
    case budgetConstants.UPDATE_MEMBER_BUDGET.SUCCESS: {
      const member_budget_id = action.payload.response.id;

      // If deleted don't bother updating.
      if (state.deletedBudgets[member_budget_id]) {
        return state;
      }

      const updatedMemberBudget = {
        ...action.payload.response,
        skills: state.memberBudgets[member_budget_id].skills
      };
      return {
        ...state,
        memberBudgets: {
          ...state.memberBudgets,
          [member_budget_id]: updatedMemberBudget
        }
      };
    }
    case budgetConstants.ASSIGN_MEMBER_BUDGET.SUCCESS: {
      const memberBudgetAssign = action.payload.response;
      const payload = action.payload.sagaPayload.payload;
      const assignedId = payload.assignedMemberBudgetId;
      const unassignedId = payload.memberBudgetId;
      const idToDelete =
        !assignedId || memberBudgetAssign.id === assignedId
          ? unassignedId
          : assignedId;
      const newMemberBudgets = omit(state.memberBudgets, [idToDelete]);
      return {
        ...state,
        deletedBudgets: {
          ...state.deletedBudgets,
          [idToDelete]: state.memberBudgets[idToDelete]
        },
        memberBudgets: newMemberBudgets
      };
    }

    case budgetConstants.DELETE_MEMBER_BUDGET.SUCCESS: {
      const deletedMemberBudgetId = action.payload.requestPayload.id;

      return {
        ...state,
        deletedBudgets: {
          ...state.deletedBudgets,
          [deletedMemberBudgetId]: state.memberBudgets[deletedMemberBudgetId]
        },
        memberBudgets: omit(state.memberBudgets, deletedMemberBudgetId),
        memberBudgetIds: state.memberBudgetIds.filter(
          (id) => id !== deletedMemberBudgetId
        )
      };
    }

    case budgetConstants.FETCH_POSITION_SKILLS.SUCCESS: {
      const skills = action.payload.response;
      if (skills.length) {
        const { member_budget_id } = action.payload.requestPayload;
        return {
          ...state,
          memberBudgets: {
            ...state.memberBudgets,
            [member_budget_id]: {
              ...state.memberBudgets[member_budget_id],
              skills
            }
          }
        };
      }
      return state;
    }
    case budgetConstants.DELETE_POSITION_SKILLS.TRIGGER: {
      const { position_skill_id, member_budget_id } = action.payload;
      if (!state.memberBudgets[member_budget_id].skills) {
        return state;
      }

      const memberBudget = state.memberBudgets[member_budget_id];
      const newSkillsList = memberBudget.skills.filter(
        (skill) => !(skill.id === position_skill_id)
      );
      // Filter for all skills with the position_id we are removing.
      return {
        ...state,
        memberBudgets: {
          ...state.memberBudgets,
          [member_budget_id]: {
            ...memberBudget,

            // Maintain the truth that an empty skills list is null, can be changed in the future.
            skills: newSkillsList.length ? newSkillsList : null
          }
        }
      };
    }
    default:
      return state;
  }
};

export default memberBudgets;

/* ------------------------------------ - ----------------------------------- */

export const getProjectIdsToUpdate = (projectId, projectIds) => {
  return projectId !== undefined || projectIds
    ? projectId !== undefined
      ? [projectId]
      : projectIds
    : [];
};
