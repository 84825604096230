import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import styled from 'styled-components';
import theme from 'theme';
import {
  StyledMilestoneIcon,
  StyledBudgetPhaseMilestoneIcon,
  StyledBudgetIconContainer,
  InactiveText,
  DependencyText,
  StatusContainer,
  ThreeDotIconContainer,
  StyledDeleteIcon
} from './styles';
import { MilestoneInput } from 'views';
import {
  isPhaseInactive,
  isPhaseArchived,
  getPhaseDisplayBudgetStatus
} from 'appUtils/phaseDisplayUtils';
import {
  checkHasTimeEntries,
  updatePhase,
  hardDeletePhase
} from 'actionCreators';
import DependencyLinkIcon from 'icons/DependencyLinkIcon';
import ActivityTimesheetIcon from 'icons/ActivityTimesheetIcon';
import { getFlatPhasesHash, getSelectedTeamId } from 'selectors';
import { ThreeDotMenu } from 'components/ThreeDotMenu';
import { ThreeDotMenuListItem } from 'components/tables/ProjectDetailsTable/Cells/shared/ThreeDotMenuListItem';
import moment from 'moment';
import { DATE_DISPLAY_TINY } from 'appConstants/date';
import { ArchiveIcon } from 'icons/ArchiveIcon';
import LockIcon from 'icons/LockIcon';
import useCan from 'appUtils/hooks/useCan';

const MilestoneTableTitleCell = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { phase, project, row, canDelete, openPhaseModal } = props;
  const {
    handleSetIsOpen,
    userActivities,
    isOpen,
    handleOpenHardDeletePhaseModal
  } = row.original;
  const latestActivity = userActivities.length > 0 ? userActivities[0] : null;
  const isPhase = phase?.is_budget;
  const phasesHash = useSelector(getFlatPhasesHash);
  const selectedTeamId = useSelector(getSelectedTeamId);
  const dispatch = useDispatch();
  const canHardDeletePhase = useCan(hardDeletePhase);

  const setDeletePhaseId = () => {
    if (!canDelete) {
      return;
    }
    dispatch(
      checkHasTimeEntries({
        phase_ids: [phase.id],
        team_id: selectedTeamId,
        permissions: {
          mine: false
        }
      })
    );
    openPhaseModal({
      id: phase.id,
      name: phase.name,
      projectId: phase.project_id,
      isDeletePhase: true
    });
  };

  const getDependencyText = () => {
    let dependencyText = '';
    if (phase.dependencies.length > 0) {
      phase.dependencies.forEach((dependency) => {
        dependencyText += `${phasesHash[dependency.parent_id]?.name} `;
      });

      return dependencyText;
    }

    return null;
  };

  const dependencyText = getDependencyText();

  const isPhaseHardDeletable =
    isPhase && project?.id && phase?.id && phase.archived; // Not handling milestone

  const menus = [
    ...(isPhaseHardDeletable
      ? [
          {
            icon: <ArchiveIcon width={18} height={18} />,
            label: 'Unarchive',
            onClick: () => {
              dispatch(
                updatePhase({
                  id: phase.id,
                  projectId: project.id,
                  isArchived: false
                })
              );
            }
          }
        ]
      : []),
    {
      icon:
        isPhaseHardDeletable && !canHardDeletePhase ? (
          <LockIcon fill={theme.colors.colorPureBlack} />
        ) : (
          <StyledDeleteIcon />
        ),
      tooltip:
        isPhaseHardDeletable && !canHardDeletePhase
          ? 'Only Admins can delete archived phases'
          : '',
      label: 'Delete',
      onClick: () => {
        /**
         * Spec: Hard delete archived phase
         * - The delete button will open up hard delete phase modal if phase is archived.
         */

        if (isPhaseHardDeletable) {
          if (canHardDeletePhase) {
            handleOpenHardDeletePhaseModal({
              projectId: project.id,
              phaseId: phase.id
            });
          }
          return;
        }

        // Archive or regular delete option
        setDeletePhaseId();
      }
    }
  ];

  return (
    <div
      className="milestone-left"
      onClick={() => {
        if (isPhase) {
          handleSetIsOpen({
            id: phase.id,
            value: !row.original.isOpen
          });
        }
      }}
    >
      <ThreeDotIconContainer isOpen={isMenuOpen}>
        <ThreeDotMenu
          vertical={true}
          className="delete-icon-container"
          slide={false}
          style={{ width: '16px', justifyContent: 'center' }}
          overrideSetIsOpen={setIsMenuOpen}
          overrideIsOpen={isMenuOpen}
        >
          {menus.map(({ label, icon, tooltip, onClick }) => (
            <ThreeDotMenuListItem
              key={label}
              icon={icon}
              label={label}
              onClick={onClick}
              tooltip={tooltip}
            ></ThreeDotMenuListItem>
          ))}
        </ThreeDotMenu>
      </ThreeDotIconContainer>
      <div className={cn('project-milestone-title-col')}>
        {isPhase ? (
          <StyledBudgetIconContainer
            data-effect="solid"
            data-tip={'This phase is part of the project budget.'}
          >
            <StyledBudgetPhaseMilestoneIcon />
          </StyledBudgetIconContainer>
        ) : (
          <StyledBudgetIconContainer>
            <StyledMilestoneIcon />
          </StyledBudgetIconContainer>
        )}
        <MilestoneInput phase={phase} />
        {latestActivity && (
          <LatestActivityContainer>
            <ActivityTimesheetIcon
              color={theme.colors.colorCalendarGray}
              width={12}
              height={10}
            />
            <LatestActivityText>
              {isOpen
                ? 'Hide Activity'
                : moment(latestActivity.timestamp).format(DATE_DISPLAY_TINY) +
                  ' ' +
                  (latestActivity?.actor?.initials || '')}
            </LatestActivityText>
          </LatestActivityContainer>
        )}
      </div>
    </div>
  );
};

const LatestActivityContainer = styled.div`
  cursor: pointer;
  position: absolute;
  border-radius: 3px;
  color: ${theme.colors.colorCalendarGray};
  padding-left: 2px;
  padding-right: 3px;
  &:hover {
    background: ${theme.colors.colorTranslucentGray3};
    color: ${theme.colors.colorCalendarBlue};
    path {
      fill: ${theme.colors.colorCalendarBlue};
    }
  }
  top: 23px;
  left: -1px;
  display: flex;
  align-items: center;
`;

const LatestActivityText = styled.div`
  margin-left: 4px;
`;

export default MilestoneTableTitleCell;
