import keyBy from 'lodash/keyBy';
import { CURRENCY_LIST_UNIQUE } from 'CurrencyModule/constants/currencyList';

const staging = 'staging';
const party = 'party';
const pilot = 'pilot';
const demo = 'demo';
const prod = 'prod';

export enum CsvImportEntity {
  Timesheet = 'Timesheet',
  WorkPlan = 'WorkPlan',
  Member = 'Member',
  ProjectPhaseActivityPhase = 'ProjectPhaseActivityPhase',
  TaskScope = 'TaskScope'
}

export const CsvImportEntityToLabel: Record<CsvImportEntity, string> = {
  [CsvImportEntity.Timesheet]: 'Time Entries',
  [CsvImportEntity.WorkPlan]: 'Work Plans',
  [CsvImportEntity.Member]: 'Members',
  [CsvImportEntity.ProjectPhaseActivityPhase]:
    'Projects, Phases, and Work Categories',
  [CsvImportEntity.TaskScope]: 'Task/Scope'
};

const importTokens = {
  [staging]: {
    [CsvImportEntity.Timesheet]:
      'gnzedmfbxxspyqw8i-iqxqbxscteetsclh1vpme1xff3okuot',
    [CsvImportEntity.WorkPlan]:
      'pmoetesihvk7tfo5wbpibliegozgiaaeay9zns0v1sbbolpiq',
    [CsvImportEntity.Member]:
      'zm0lmu_jf2ga672psnfaaouet2xzwzlfwbcqx2aon54qhygew',
    [CsvImportEntity.ProjectPhaseActivityPhase]:
      'wxi1a4wyu4pplgoreawmvgugaf6pgua6v-6hzthoowwbnfelq',
    [CsvImportEntity.TaskScope]:
      'xwx1-oasy5um6qzkyra1m1zzrbt2lbeu2qqsadwyxwp7mroqe'
  },
  [party]: {
    [CsvImportEntity.Timesheet]:
      'vxxmvv1ie3zk7nnzrj9rctdfsns8y_-bhhnmny81prmyci4a4',
    [CsvImportEntity.WorkPlan]:
      '1ipvpk0tycz-wprzbbz7zmxuoqdz-pmaxtdas26pzpp1_7wpa',
    [CsvImportEntity.Member]:
      'oo7dclsp8_npvuxn408e0mqjnoojzotw6ttj761tbtqb18qaa',
    [CsvImportEntity.ProjectPhaseActivityPhase]:
      'nmmogebbopm_iooraf3ekfsheyykzffme-a6qjbhqm3rvlmxy'
  },
  [pilot]: {
    [CsvImportEntity.Timesheet]:
      'l6mydgc0awty6y8zefse7rcki64kxwh0tbr_y_h8pqk_rxpu8',
    [CsvImportEntity.WorkPlan]:
      'slxkqpw_15hktoffhgwfpmyftuzvd6ui9w-sbb98vvbwhcjch'
  },
  [demo]: {
    [CsvImportEntity.Timesheet]:
      '4p-ifcktmwzumafo2xb5550p01qsjlh3s_xnyra7i__f9qk-r',
    [CsvImportEntity.WorkPlan]:
      'htaf_fqaus88yhpbtfmj1c3wgwmaagntivzadwz6iyffrwxok',
    [CsvImportEntity.Member]:
      'fhl2gf4epgq7ngj9bgbnvpfah71wwujhettmhrmmettue5k-5',
    [CsvImportEntity.ProjectPhaseActivityPhase]:
      'tfep5_lohpqxiq9ni4ra4rn1xbokawgfdnaf1t_eqo3yr38uv'
  },
  [prod]: {
    [CsvImportEntity.Timesheet]:
      'qyh_3jyyenfis9ttryr0vubnrfieia3s5resqoms65y2afnif',
    [CsvImportEntity.WorkPlan]:
      'yexzif3es1w6pcxgsqyh3fw5kw62s2wwxipqqsrq11rmte2sr',
    [CsvImportEntity.Member]:
      'k0fofn7winvrgejt5jjnv3tf1zqtwhtvf1fp1vspc1ymudpnk',
    [CsvImportEntity.ProjectPhaseActivityPhase]:
      'k2e-1oqs2uibnd48k1fovcqbsmjv4mjnivlrjcaxolblktxh-'
  }
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const usableImportTokens = importTokens[process.env.UI_ENV!];

/**
 * Column types of all CSV import entities
 */
export enum CsvImportColumn {
  TeamId = 'TeamId',
  SessionId = 'SessionId',
  ImportInitiatedAt = 'ImportInitiatedAt',
  ImportAccountId = 'ImportAccountId',

  ProjectTitle = 'ProjectTitle',
  ProjectNumber = 'ProjectNumber',
  Date = 'Date',
  Hours = 'Hours',
  PhaseName = 'PhaseName',
  PhaseNumber = 'PhaseNumber',
  ActivityTitle = 'ActivityTitle',
  MemberName = 'MemberName',
  Email = 'Email',
  HourlyRate = 'HourlyRate',
  Description = 'Description',
  Billable = 'Billable',

  UnassignedRoleName = 'UnassignedRoleName',
  PortfolioName = 'PortfolioName',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  TotalHours = 'TotalHours',
  HoursPerDay = 'HoursPerDay',
  IncludeOffDays = 'IncludeOffDays',
  AccessLevel = 'AccessLevel',
  Region = 'Region',
  Office = 'Office',
  Discipline = 'Discipline',
  FirstName = 'FirstName',
  LastName = 'LastName',
  Role = 'Role',
  Skills = 'Skills',
  Department = 'Department',
  PtoHours = 'PtoHours',
  PtoDescription = 'PtoDescription',
  Currency = 'Currency',
  RateAmount = 'RateAmount',
  RateDescription = 'RateDescription',

  ProjectPriority = 'ProjectPriority',
  ProjectBudgetStatus = 'ProjectBudgetStatus',
  ProjectClient = 'ProjectClient',
  PhaseStartDate = 'PhaseStartDate',
  PhaseEndDate = 'PhaseEndDate',
  PhaseBudgetStatus = 'PhaseBudgetStatus',
  PhaseContractType = 'PhaseContractType',
  PhaseFee = 'PhaseFee',
  PhaseTarget = 'PhaseTarget',
  ActivityStartDate = 'ActivityStartDate',
  ActivityEndDate = 'ActivityEndDate',
  ActivityFee = 'ActivityFee',
  ActivityTarget = 'ActivityTarget',
  PhaseBillable = 'PhaseBillable',

  TaskScopeDescription = 'TaskScopeDescription',
  TaskPriority = 'TaskPriority',
  TaskStatus = 'TaskStatus',
  IsScope = 'IsScope'
}

/**
 * Entered field names of all CSV import entities
 */

export enum CsvImportFieldName {
  EnteredPortfolioName = 'enteredPortfolioName',
  EnteredProjectTitle = 'enteredProjectTitle',
  EnteredProjectNumber = 'enteredProjectNumber',
  EnteredPhaseName = 'enteredPhaseName',
  EnteredPhaseNumber = 'enteredPhaseNumber',
  EnteredActivityTitle = 'enteredActivityTitle',
  EnteredMemberName = 'enteredMemberName',
  EnteredEmail = 'enteredEmail',
  EnteredUnassignedRoleName = 'enteredUnassignedRoleName',
  EnteredDate = 'enteredDate',
  EnteredHours = 'enteredHours',
  EnteredStartDate = 'enteredStartDate',
  EnteredEndDate = 'enteredEndDate',
  EnteredTotalHours = 'enteredTotalHours',
  EnteredHoursPerDay = 'enteredHoursPerDay',
  EnteredDescription = 'enteredDescription',
  EnteredIncludeOffDays = 'enteredIncludeOffDays',
  EnteredAccessLevel = 'enteredAccessLevel',
  EnteredRole = 'enteredRole',
  EnteredRegion = 'enteredRegion',
  EnteredOfficeName = 'enteredOfficeName',
  EnteredDiscipline = 'enteredDiscipline',
  EnteredDepartment = 'enteredDepartment',
  EnteredSkills = 'enteredSkills',
  EnteredProjectPriority = 'enteredProjectPriority',
  EnteredProjectBudgetStatus = 'enteredProjectBudgetStatus',
  EnteredProjectClient = 'enteredProjectClient',
  EnteredPhaseStartDate = 'enteredPhaseStartDate',
  EnteredPhaseEndDate = 'enteredPhaseEndDate',
  EnteredPhaseBudgetStatus = 'enteredPhaseBudgetStatus',
  EnteredPhaseContractType = 'enteredPhaseContractType',
  EnteredPhaseFee = 'enteredPhaseFee',
  EnteredPhaseTarget = 'enteredPhaseTarget',
  EnteredActivityStartDate = 'enteredActivityStartDate',
  EnteredActivityEndDate = 'enteredActivityEndDate',
  EnteredActivityFee = 'enteredActivityFee',
  EnteredActivityTarget = 'enteredActivityTarget',
  EnteredPhaseBillable = 'enteredPhaseBillable',
  EnteredFirstName = 'enteredFirstName',
  EnteredLastName = 'enteredLastName',
  EnteredPtoHours = 'enteredPtoHours',
  EnteredPtoDescription = 'enteredPtoDescription',
  EnteredCurrency = 'enteredCurrency',
  EnteredRateAmount = 'enteredRateAmount',
  EnteredRateDescription = 'enteredRateDescription',
  EnteredTaskScopeDescription = 'enteredTaskScopeDescription',
  EnteredTaskPriority = 'enteredTaskPriority',
  EnteredTaskStatus = 'enteredTaskStatus',
  EnteredIsScope = 'enteredIsScope'
}

/**
 * Used for getting row values
 */
export const columnIndexesByEntity = {
  [CsvImportEntity.Timesheet]: {
    [CsvImportColumn.ProjectTitle]: 0,
    [CsvImportColumn.ProjectNumber]: 1,
    [CsvImportColumn.Date]: 2,
    [CsvImportColumn.Hours]: 3,
    [CsvImportColumn.PhaseName]: 4,
    [CsvImportColumn.ActivityTitle]: 5,
    [CsvImportColumn.MemberName]: 6,
    [CsvImportColumn.Email]: 7,
    [CsvImportColumn.PortfolioName]: 15,
    [CsvImportColumn.PhaseNumber]: 16
  },
  [CsvImportEntity.WorkPlan]: {
    [CsvImportColumn.Email]: 0,
    [CsvImportColumn.PortfolioName]: 1,
    [CsvImportColumn.ProjectTitle]: 2,
    [CsvImportColumn.ProjectNumber]: 3,
    [CsvImportColumn.PhaseName]: 4,
    [CsvImportColumn.PhaseNumber]: 5,
    [CsvImportColumn.ActivityTitle]: 6,
    [CsvImportColumn.StartDate]: 7,
    [CsvImportColumn.EndDate]: 8,
    [CsvImportColumn.TotalHours]: 9,
    [CsvImportColumn.HoursPerDay]: 10,
    [CsvImportColumn.Description]: 11,
    [CsvImportColumn.IncludeOffDays]: 12
  },
  [CsvImportEntity.Member]: {
    [CsvImportColumn.Email]: 0,
    [CsvImportColumn.FirstName]: 1,
    [CsvImportColumn.LastName]: 2,
    [CsvImportColumn.AccessLevel]: 4,
    [CsvImportColumn.Role]: 5,
    [CsvImportColumn.RateAmount]: 6,
    [CsvImportColumn.RateDescription]: 7,
    [CsvImportColumn.PtoHours]: 8,
    [CsvImportColumn.PtoDescription]: 9,
    [CsvImportColumn.Discipline]: 10,
    [CsvImportColumn.Office]: 11,
    [CsvImportColumn.Region]: 12,
    [CsvImportColumn.Currency]: 17,
    [CsvImportColumn.Department]: 18,
    [CsvImportColumn.Skills]: 19
  },
  [CsvImportEntity.ProjectPhaseActivityPhase]: {
    [CsvImportColumn.PortfolioName]: 0,
    [CsvImportColumn.ProjectTitle]: 1,
    [CsvImportColumn.ProjectNumber]: 2,
    [CsvImportColumn.ProjectClient]: 3,
    [CsvImportColumn.ProjectBudgetStatus]: 5,
    [CsvImportColumn.ProjectPriority]: 6,
    [CsvImportColumn.Region]: 7,
    [CsvImportColumn.Office]: 8,
    [CsvImportColumn.Discipline]: 9,
    [CsvImportColumn.PhaseName]: 10,
    [CsvImportColumn.PhaseNumber]: 11,
    [CsvImportColumn.PhaseStartDate]: 12,
    [CsvImportColumn.PhaseEndDate]: 13,
    [CsvImportColumn.PhaseBudgetStatus]: 14,
    [CsvImportColumn.PhaseFee]: 15,
    [CsvImportColumn.PhaseTarget]: 16,
    [CsvImportColumn.PhaseContractType]: 17,
    [CsvImportColumn.PhaseBillable]: 18,
    [CsvImportColumn.ActivityTitle]: 20,
    [CsvImportColumn.ActivityStartDate]: 21,
    [CsvImportColumn.ActivityEndDate]: 22,
    [CsvImportColumn.ActivityFee]: 24,
    [CsvImportColumn.ActivityTarget]: 25
  },
  [CsvImportEntity.TaskScope]: {
    [CsvImportColumn.Email]: 0,
    [CsvImportColumn.IsScope]: 1,
    [CsvImportColumn.TaskScopeDescription]: 2,
    [CsvImportColumn.StartDate]: 4,
    [CsvImportColumn.EndDate]: 5,
    [CsvImportColumn.TaskPriority]: 6,
    [CsvImportColumn.TaskStatus]: 8,
    [CsvImportColumn.PortfolioName]: 9,
    [CsvImportColumn.ProjectTitle]: 10,
    [CsvImportColumn.ProjectNumber]: 11,
    [CsvImportColumn.PhaseName]: 12,
    [CsvImportColumn.PhaseNumber]: 13,
    [CsvImportColumn.ActivityTitle]: 14
  }
};

// string value that is present when Osmos requires training example
export const TRAINING_DATA_REQUIRED_STRING = 'PROVIDE EXAMPLE';

export const ERROR_MESSAGES = {
  missingHourFields: 'Either Hours Per Day or Total Hours is required',
  tooManyHourFields: 'Only one of Hours Per Day and Total Hours can be entered',
  hoursPerDayOverLimit: 'Entered number of hours must be less than 24 hours',
  zeroHourEntered: 'Entered number of hours must be greater than 0',
  hoursPerDayAndTotalHoursAreZero:
    'Hours Per Day and Total Hours cannot both be 0',
  startDateRequired: 'Start date is required',
  endDateRequired: 'End date is required',
  endDateBeforeStartDate: 'End date must be after start date',
  invalidDate: 'Invalid date',
  portfolioNameRequired: 'Portfolio name is required',
  portfolioDoesNotExist: 'The portfolio does not exist',
  projectDoesntMatchPortfolio: 'The project does not belong to this portfolio',
  budgetStatusRequired: 'Budget status is required',
  invalidProjectBudgetStatus: 'Invalid project budget status',
  invalidPhaseBudgetStatus: 'Invalid phase budget status',
  invalidProjectPriority: 'Invalid project priority',
  phaseBillableRequired: "Enter 'true' or 'false' for phase_billable first",
  projectBudgetStatusDoesNotMatch:
    'The entered project budget status does not match the first one you have entered for this project title + project number combination',
  projectPriorityDoesNotMatch:
    'The entered project priority does not match the first one you have entered for this project title + project number combination',
  projectClientDoesNotMatch:
    'The entered project client does not match the first one you have entered for this project title + project number combination',
  phaseBudgetStatusDoesNotMatch:
    'The entered phase budget status does not match the first one you have entered for this project title + project number + phase name combination',
  phaseContractTypeRequired: 'Phase contract type is required',
  invalidPhaseContractType: 'Invalid phase contract type',
  invalidPhaseContractTypeBasedOnBillable:
    'The entered contract type is invalid given its billable status',
  phaseContractTypeDoesNotMatch:
    'The entered phase contract type does not match the first one you have entered for this project title + project number + phase name combination',
  phaseFeeTargetDoesNotMatch:
    'The entered phase fee/budget does not match the first one you have entered for this project title + project number + phase name combinaiton',
  phaseFeeDoesNotMatch:
    'The entered phase fee does not match the first one you have entered for this project title + project number + phase name combinaiton',
  phaseTargetDoesNotMatch:
    'The entered phase budget does not match the first one you have entered for this project title + project number + phase name combinaiton',
  activityFeeTargetDoesNotMatch:
    'The entered work category fee/budget does not match the first one you have entered for this project title + project number + phase name + work category name combination',
  activityFeeDoesNotMatch:
    'The entered work category fee does not match the first one you have entered for this project title + project number + phase name + work category name combination',
  activityTargetDoesNotMatch:
    'The entered work category budget does not match the first one you have entered for this project title + project number + phase name + work category name combination',
  invalidFeeTarget: 'Please enter positive numbers in this field',
  workCategoryRequiredForPhase: 'You must enter a work category for this phase',
  phaseRequiredForRow:
    'Phase name is required because you have entered a non-empty phase name for this project in other rows',
  workCategoryRequiredForRow:
    'A work category is required because you have entered a non-empty work category for this phase in other rows',
  firstNameLastNameRequired: 'First Name and Last Name is required',
  invalidPtoHours: 'Pto Hours needs to be positive numbers',
  ptoHoursRequired: 'Pto hours is required if pto description is entered',
  ptoHoursDoesNotMatchDescription:
    'The pto hours does not match the pto description',
  ptoDescriptionRequired: 'Pto description is required if pto hours is entered',
  rateAmountRequired: 'Rate amount is required if rate description is entered',
  invalidRateAmount: 'Rate amount entered needs to be positive numbers',
  rateDoesNotMatchDescription:
    'The rate amount does not match the rate description',
  invalidOfficeName:
    'Invalid office name, please enter an office name that exists in the team',
  projectOfficeDoesNotMatch:
    'The entered office name does not match the first one you have entered for this project title + project number combination',
  projectDisciplineDoesNotMatch:
    'The entered discipline name does not match the first one you have entered for this project title + project number combination',
  projectRegionDoesNotMatch:
    'The entered region name does not match the first one you have entered for this project title + project number combination',
  invalidCurrencyCode:
    'You can only use currency codes from the option dropdown',
  emailAlreadyExists: 'The email already exists in the team',
  emailIsRequired: 'Email is required',
  skillsStartingWithComma: 'Please enter a skill before comma',
  nonEmptyFieldForDefaultActivity: 'Please enter work category name first',
  invalidTaskPriority: 'Invalid task priority',
  emptyDescription: 'Description is required',
  missingProjectTitle: 'Project title is required',
  missingProjectNumber: 'Project number is required',
  missingProjectTitleAndNumber: 'Project title and number are required',
  enteredHoursIsNotNumeric: 'Entered hours needs to be a valid numeric value',
  invalidDateFormat: 'Please enter a valid date in the format of YYYY-MM-DD',
  invalidEmploymentType:
    'Invalid employment type, please choose one of the options in dropdown'
};

export const MEMBER_ACCESS_LEVELS = [
  '',
  'default',
  'contractor_internal',
  'contractor_external',
  'guest'
];

export const BILLABLE_PHASE_CONTRACT_TYPES = ['Fixed Fee', 'Hourly'];

export const NON_BILLABLE_PHASE_CONTRACT_TYPES = ['Internal'];

export const BILLABLE_PHASE_CONTRACT_TYPES_HASH = keyBy(
  BILLABLE_PHASE_CONTRACT_TYPES
);

export const NON_BILLABLE_PHASE_CONTRACT_TYPES_HASH = keyBy(
  NON_BILLABLE_PHASE_CONTRACT_TYPES
);
export const MEMBER_ACCESS_LEVELS_HASH = keyBy(MEMBER_ACCESS_LEVELS);

export const CURRENCY_CODES_HASH = keyBy(CURRENCY_LIST_UNIQUE, 'currencyCode');

export const CURRENCY_CODES = Object.keys(CURRENCY_CODES_HASH);

export const DEFAULT_TASK_STATUSES = [
  'Not Started',
  'In Progress',
  'Needs Review',
  'Approved',
  'Issue'
];
