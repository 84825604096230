import * as constants from 'appConstants';

export const initialState = {
  emailSent: false,
  statusText: null
};

const signup = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case constants.SIGNUP.REQUEST:
      return {
        ...state,
        emailSent: false,
        statusText: null
      };
    case constants.SIGNUP.SUCCESS:
      return {
        ...state,
        emailSent: true,
        statusText: 'Activation email sent.'
      };
    case constants.SIGNUP.FAILURE:
      return {
        ...state,
        emailSent: false,
        statusText: `${action.payload.error}`
      };
    case constants.SIGNUP_CLOSE:
      return {
        ...state,
        emailSent: false,
        statusText: null
      };
    default:
      return state;
  }
};

export default signup;
