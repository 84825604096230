import {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
  ReactNode,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from 'reduxInfra/hooks';
import {
  getMe,
  getMemberBudgetsByProjectIdWithUnassigned,
  getMyWorkPlanSettings,
  getOOOProject,
  getPhasesByProjectHash,
  getSelectedTeamId,
  getTeamMembershipsByAccountId,
  getWorkloadPlannerBarAccountId,
  getWorkloadPlannerBarDate,
  getWorkloadPlannerBarIsNew,
  getWorkloadPlannerBarModalBar,
  getWorkloadPlannerBarProjectId,
  getWorkloadPlannerBarModalIsOpen,
  getWorkloadPlannerBarModalIsRequest,
  getWorkloadPlannerBarMemberBudgetId,
  getWorkloadPlannerBarModalBudgetTotals,
  getWorkloadPlannerBarModalParentGroupId,
  getWorkloadPlannerBarModalGroupAttribute,
  getWorkloadPlannerBarModalWorkplanStateId,
  getIsLoadingPhases,
  getPhaseSearchOffset,
  getSuggestedBar,
  makeGetCommentCountsByParentEntity,
  getShouldPredictWithInitialValue
} from 'selectors';
import useEntities from 'appUtils/hooks/useEntities';
import {
  getAverageCapacities,
  getHolidayDatesHash,
  getAccountCapacities
} from 'CapacityModule/selectors';
import {
  fetchMemberBudgetPhase,
  fetchMemberBudgets,
  assignMemberBudget
} from 'BudgetModule/actionCreators';
import { approveWorkplanRequest } from 'WorkplanRequestsModule/actionCreators';
import {
  fetchWorkloadPlanner,
  updateWorkloadPlanner,
  createWorkloadPlanner,
  deleteWorkloadPlanner,
  closePlannerBarModal,
  fetchAllProjects,
  fetchPhasesByProjectIds,
  plannerBarModalClosed,
  archivePhaseMember
} from 'actionCreators';
import { fetchCapacities } from 'CapacityModule/actionCreators';
import { isTentativePlan } from '../utils';
import moment, { Moment } from 'moment';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import { isPhaseArchived } from 'appUtils/phaseDisplayUtils';
import {
  workplanFormValidator,
  WorkplanFormValidationError
} from './utils/validations';
import { UpdateHandler, usePredict } from './hooks/usePredict';
import { WorkPlan } from './models/workPlan';
import { BudgetTotals } from 'models/budgetTotals';
import { noop } from 'appUtils';
import { BUDGET_STATUSES } from 'appConstants/budgetStatuses';
import { getUtilizations } from 'UtilizationModule/selectors';
import { isWeekend } from 'appUtils/momentUtils';
import { Scope } from 'models/scope';
import { useWorkPlanScopes } from './hooks/useWorkPlanScopes';
import { useWorkPlanTasks } from './hooks/useWorkPlanTasks';
import { Task } from 'models/task';
import { useWorkPlanActivityLogs } from './hooks/useWorkPlanActivityLogs';
import { ActivityPhaseScheduleBarUserActivity } from './models/activityPhaseScheduleBarUserActivity';
import {
  defaultAverageCapacity,
  defaultAccountTotalCapacity
} from './constants';
import { ApproveWorkplanRequestParams } from 'WorkplanRequestsModule/types';
import {
  generateNewDependencyInfos,
  isStartDateDependency,
  isEndDateDependency,
  isLegacyStartAndEndDateDependency,
  isStartAndEndDateDependency
} from 'appUtils/newDependencies';
import {
  DependencyInfoArrayItem,
  CalendarDependencyState,
  CalendarDependencyItemState,
  DependableType
} from 'components/Dependency/types';
import { DEPENDENCY_TYPES } from 'components/Dependency/constants';
import { EntityType } from 'models/entity';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { CAPACITY_DATE_KEYS } from 'appConstants/workload';
import { TeamCapacity } from 'CapacityModule/models';
import { teamUtils } from 'TeamsModule/utils';

import momentUtils from 'appUtils/momentConfig';
import { ValueOf } from 'type-fest';
import assert from 'assert';
import { useTasks } from 'appUtils/hooks/useTasks';
import { useWorkplanPermission } from 'PermissionsModule/SpaceLevelPermissions/permissionProviders/workplan';
const { range } = momentUtils;

const today = moment().format('MM/DD/YYYY');

const emptyArray = [];

interface WorkPlanFormContextValues {
  modal: {
    isOpen: boolean;
    onOpenModal: () => void;
    closeModal: () => void;
    onCloseModal: () => void;
  };
  form: {
    isNew: boolean;
    isDirty: boolean;
    isPredicting: boolean;
    isRequest: boolean;
    showErrors: boolean;
    hasError: (
      errorKeys?: WorkplanFormValidationError[],
      options?: { inverse?: boolean }
    ) => boolean;
    onSubmit: () => void;
    onCancel: () => void;
    onDelete: () => void;
    onChangePredictableFields: UpdateHandler;
    shouldDisableInputs: boolean;
    isMissingInfo: boolean;
    initialWorkplan: WorkPlan;
    workplan: WorkPlan;
    workDays: string | undefined;
    workdayPercent: string | undefined;
    datesArray: Array<string>;
    isDeleteConfirmModalOpen: boolean;
    onSetDeleteConfirmModalOpen: (value: boolean) => void;
    handleSetDependency: ({
      dependency,
      dependencyItem,
      dependencyItemType
    }: {
      dependency: CalendarDependencyState;
      dependencyItem: CalendarDependencyItemState;
      dependencyItemType: DependableType;
    }) => void;
    initialDependency: CalendarDependencyState;
    initialDependencyItem: CalendarDependencyItemState;
    onUpdateIsTentative: (isTentative: boolean) => void;
    onUpdateIsAllDay: (isAllDay: boolean) => void;
  };
  projectAndPhase: {
    board?: any;
    project?: any;
    phase?: any;
    activity?: any;
    isPTO: boolean;
    onSelectProjectOrPhase: any;
    hasDefaultPhase: boolean;
    disableDependency: boolean;
  };
  description: {
    value: string;
    onUpdate: (value: string) => void;
  };
  member: {
    accountId?: number;
    member?: any;
    onSelectMember: (key: string, value: number) => void;
    isMemberArchived: boolean;
    budgetTotals: BudgetTotals;
    memberAverageCapacity: number;
    memberTotalCapacity: number;
    memberDailyCapacity: Pick<TeamCapacity, typeof CAPACITY_DATE_KEYS[number]>;
    memberUtilizations: any | undefined;
    memberPTODates: Set<string>;
    holidayDates: Record<string, boolean>;
    unassignedMemberBudget: any | null;
    onConfirmToMerge: () => void;
    isReassignConfirmModalOpen: boolean;
    onSetReassignConfirmModalOpen: (value: boolean) => void;
    onConfirmToUnarchiveMember: () => void;
    isUnarchiveMemberConfirmModalOpen: boolean;
    onSetUnarchiveMemberConfirmModalOpen: (value: boolean) => void;
  };

  comment: {
    totalCount: number;
  };
  scope: {
    scopes: Array<Scope>;
    onAddScope: (scope: Scope) => void;
    onRemoveScope: (scope: Scope) => void;
  };
  task: {
    taskOrder: Array<number>;
    tasks: Array<Task>;
    onUpdateTaskIds: (taskIds: Array<number>) => void;
  };

  activityLog: {
    activityLogs: Array<ActivityPhaseScheduleBarUserActivity>;
  };
  calendar: {
    isOpen: boolean;
    handleSetWorkplanCalendarOpen: (value: boolean) => void;
  };
  optionsMenu: {
    isOpen: boolean;
    handleSetWorkplanMoreOptionsOpen: (value: boolean) => void;
  };
  moreOptions: {
    workingWeekends: boolean;
    weeklyPlan: boolean;
    tenativePlan: boolean;
    initialDependency: CalendarDependencyState;
  };
  permissions: {
    canEditWorkplan: boolean;
    canDeleteWorkplan: boolean;
    canAddWorkplanComment: boolean;
    canEditWorkplanTask: boolean;
    canRemoveWorkplanTask: boolean;
    canEditWorkplanScope: boolean;
    canRemoveWorkplanScope: boolean;
  };
}
const WorkPlanFormContext = createContext({} as WorkPlanFormContextValues);

export const WorkPlanFormConsumer = WorkPlanFormContext.Consumer;

const emptyObj = {};

export interface WorkPlanFormProviderProps {
  children: ReactNode;
  onCreateSuccess?: Array<{
    successAction: (selectorValue: any) => void;
    selector?: ((payload: any, response: any) => void) | undefined;
  }>;
  additionalApproveWorkplanRequestParams?: Partial<ApproveWorkplanRequestParams>;
}

export const WorkPlanFormProvider = ({
  children,
  onCreateSuccess,
  additionalApproveWorkplanRequestParams
}: WorkPlanFormProviderProps) => {
  const dispatch = useDispatch();

  /* -------------------------------- selectors ------------------------------- */

  const isOpen = useSelector(getWorkloadPlannerBarModalIsOpen);
  const isNew = useSelector(getWorkloadPlannerBarIsNew);
  const date = useSelector(getWorkloadPlannerBarDate);
  const initialAccountId = useSelector(getWorkloadPlannerBarAccountId);
  const initialProjectId = useSelector(getWorkloadPlannerBarProjectId);
  const memberBudgetId = useSelector(getWorkloadPlannerBarMemberBudgetId);
  const isRequest = useSelector(getWorkloadPlannerBarModalIsRequest);
  const workplanStateId = useSelector(
    getWorkloadPlannerBarModalWorkplanStateId
  );
  const groupAttribute = useSelector(getWorkloadPlannerBarModalGroupAttribute);
  const parentGroupId = useSelector(getWorkloadPlannerBarModalParentGroupId);
  const budgetTotals = useSelector(getWorkloadPlannerBarModalBudgetTotals);
  const suggestedWorkPlan = useSelector(getSuggestedBar);
  const shouldPredictWithInitialValue = useSelector(
    getShouldPredictWithInitialValue
  );
  const initialWorkplan =
    useSelector(getWorkloadPlannerBarModalBar) || emptyObj;
  const isLoadingPhases = useSelector(getIsLoadingPhases);
  const offset = useSelector(getPhaseSearchOffset);

  const PTOProject = useSelector(getOOOProject);
  const myWorkPlanSettings = useSelector(getMyWorkPlanSettings);

  const averageCapacities = useSelector(getAverageCapacities);
  const totalCapacities = useSelector(getAccountCapacities);
  const currentUser = useSelector(getMe);
  const phasesByProject = useSelector(getPhasesByProjectHash);
  const holidayDates = useSelector(getHolidayDatesHash);
  const teamId = useSelector(getSelectedTeamId);
  const memberBudgets = useSelector(getMemberBudgetsByProjectIdWithUnassigned);

  const initialDefaultPhaseAndActivityPhase = useMemo(() => {
    const phase = phasesByProject[initialProjectId]?.phases.find(
      (phase) => phase.is_like_default
    );
    if (!phase) return undefined;

    const activityPhase = phase.activity_phases.find(
      (activityPhase) => activityPhase.is_default
    );
    if (!activityPhase) return undefined;

    return { phase, activityPhase };
  }, [phasesByProject, initialProjectId]);

  const newEmptyWorkplan = {
    account_id: initialAccountId,
    project_id: initialProjectId,
    ...(initialDefaultPhaseAndActivityPhase
      ? {
          phase_id: initialDefaultPhaseAndActivityPhase.phase.id,
          activity_id:
            initialDefaultPhaseAndActivityPhase.activityPhase.activity_id,
          activity_phase_id:
            initialDefaultPhaseAndActivityPhase.activityPhase.id
        }
      : {}),
    start_date: date,
    end_date: date,
    member_budget_id: memberBudgetId,
    all_day: +initialProjectId === PTOProject?.id,
    include_weekends: isWeekend(moment(date))
      ? true
      : Boolean(myWorkPlanSettings?.include_weekends),
    lock_hour: !!myWorkPlanSettings?.lock_hour,
    use_weekly_planning: !!myWorkPlanSettings?.use_weekly_planning,
    type: EntityType.ActivityPhaseScheduleBar
  };

  const initialWorkplanToUse =
    suggestedWorkPlan ?? (isNew ? newEmptyWorkplan : initialWorkplan);

  const {
    extraValues,
    isPredicting,
    predictedWorkplan: workplan,
    update: updateWorkplan
  } = usePredict(initialWorkplanToUse, {
    shouldPredictWithInitialValue
  });

  const { updateTasks } = useTasks();

  const projectId = workplan.project_id;
  const accountId = workplan.account_id;

  const memberBudget = memberBudgets[projectId]?.find(
    (memberBudget) => memberBudget.project_membership?.account_id === accountId
  );
  const accountUtilizations = useSelector(getUtilizations);
  const teamMembers = useSelector(getTeamMembershipsByAccountId);

  const isWorkplanRequest = suggestedWorkPlan?.isWorkplanRequest;

  const { board, project, phase, activity } = useEntities({
    projectId: workplan.project_id,
    phaseId: workplan.phase_id,
    activityId: workplan.activity_id,
    boardId: undefined,
    scopeId: undefined,
    workplanId: undefined,
    noFetch: false
  });

  const hasDefaultPhase = useMemo(
    () =>
      !!phasesByProject[projectId]?.phases.find(
        (phase) => phase.is_like_default
      ),
    [phasesByProject, projectId]
  );
  /* ---------------------------------- modal --------------------------------- */

  const onOpenModal = () => {
    loadData();
  };

  const closeModal = () => {
    handleSetWorkplanCalendarOpen(false);
    dispatch(closePlannerBarModal());
  };

  const onCloseModal = () => {
    dispatch(plannerBarModalClosed());
    setShowErrors(false);
    onClearScope();
    onUpdateTaskIds([]);
  };

  const loadData = () => {
    if (accountId && teamId && !totalCapacities[accountId]) {
      dispatch(
        fetchCapacities({
          accountIds: [accountId],
          teamId
        })
      );
    }
    if (!isLoadingPhases) {
      dispatch(
        fetchAllProjects({
          offset,
          limit: 60
        })
      );
      dispatch(
        fetchPhasesByProjectIds({
          search: '',
          all: true,
          offset,
          limit: 60,
          budgetAccountId: accountId
        })
      );
    }
  };

  /* ------------------------------- dependencies ----------------------------- */
  /* Temporarily store the newly set dependencies. So the newly set dependencies
    will still be there if user re-opens calendar before submitting the modal.
    Will be cleared on work plan modal submit or close.
   */
  const [dependencyInfos, setDependencyInfos] = useState<
    Array<DependencyInfoArrayItem>
  >([]);

  const [phaseDependency, setPhaseDependency] = useState<ValueOf<
    typeof DEPENDENCY_TYPES
  > | null>(null);

  useEffect(() => {
    setPhaseDependency(initialWorkplanToUse.phase_dependency ?? null);
  }, [initialWorkplanToUse.phase_dependency]);
  /* ------------------------------- form state ------------------------------- */
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<
    WorkplanFormValidationError[] | undefined
  >();

  useEffect(() => {
    setValidationErrors(
      workplanFormValidator({
        workplan,
        phase,
        isNew,
        workdayPercent: extraValues.workday_percent
          ? Number(extraValues.workday_percent)
          : undefined
      })
    );
  }, [workplan, phase, isNew, extraValues.workday_percent]);

  const hasError = (
    errorKeys?: WorkplanFormValidationError[],
    { inverse } = { inverse: false }
  ): boolean => {
    if (validationErrors) {
      if (errorKeys) {
        if (inverse) {
          const errors = difference(validationErrors, errorKeys);
          return !!errors.length;
        } else {
          const errors = intersection(validationErrors, errorKeys);
          return !!errors.length;
        }
      } else {
        return !!validationErrors.length;
      }
    }
    return false;
  };

  const handleShowErrors = () => {
    setShowErrors(true);
  };

  const shouldDisableInputs = isPhaseArchived(phase) && isNew;

  // user should select project and member or unassigned role first to fill other fields
  const isMissingInfo = hasError([
    'missing_account_id_or_member_budget_id',
    'missing_project_id'
  ]);

  const handleUpdateIsTentative = (isTentativeNew: boolean) => {
    updateWorkplan('budget_status', {
      budget_status: isTentativeNew
        ? BUDGET_STATUSES.PROPOSAL
        : BUDGET_STATUSES.ACTIVE
    });
  };

  /* ------------------------------- description ------------------------------ */
  const [description, setDescription] = useState<string>(
    initialWorkplan?.description ?? ''
  );

  useEffect(() => {
    handleUpdateDescription(initialWorkplan?.description ?? '');
  }, [initialWorkplan?.description]);

  const handleUpdateDescription = (value: string) => {
    setDescription(value.trim());
  };

  /* ------------------------- member & member budget ------------------------- */

  const member = teamMembers[accountId];

  const isMemberHasProjectMembership = ({ project, member }) =>
    Boolean(
      member?.account?.id &&
        project?.member_account_ids?.includes(member.account.id)
    );

  const isArchivedPhaseMember = hasError(['selected_archived_phase_member']);

  const handleSelectMember = (key: string, value: number) => {
    // passed key from MemberSelector could be member_budget_id or account_id
    switch (key) {
      case 'account_id': {
        // open merge modal if the bar had no account id / but a member budget id (unassigned budget)
        setMemberBudgetIdToMerge(workplan.member_budget_id);
        if (!workplan.account_id && !!workplan.member_budget_id) {
          handleSetReassignConfirmModalOpen(true);
        }
        setShouldMergeFlag(false);
        updateWorkplan(key, {
          account_id: value,
          member_budget_id: undefined
        });
        dispatch(
          fetchCapacities({
            accountIds: [value],
            teamId
          })
        );
        break;
      }
      case 'member_budget_id': {
        updateWorkplan(key, {
          account_id: undefined,
          member_budget_id: value,
          budget_status: BUDGET_STATUSES.PROPOSAL
        });
        break;
      }
    }

    // Immediately submit the member if this is not a work plan request.
    if (!isWorkplanRequest) {
      handleSubmitMember(key, value);
    }
  };

  /**
   * This is to handle the case where a user changes the member of a work plan.
   * Scopes and Tasks on a work plan must have the work plan's member as an
   * assignee. For the time being, it was decided to immediately commit the
   * member of the work plan. The backend will automatically add the member as
   * an assignee to any scopes and tasks associated to the work plan.
   *
   * For new work plans, the tasks are updated directly since a work plan does
   * not yet exist.
   */
  const handleSubmitMember = (key: string, value: number) => {
    // This will not work for work plan requests.
    assert(!isWorkplanRequest);

    const { id } = initialWorkplan;

    const account_id = key === 'account_id' ? value : undefined;
    const member_budget_id = key === 'member_budget_id' ? value : undefined;

    if (isNew) {
      const member_id = account_id ?? member_budget_id;
      if (member_id !== undefined && taskOrder.length)
        updateTasks({
          task_ids: taskOrder,
          assignee_ids: [member_id]
        });
    } else {
      const memberFields = isRequest
        ? {
            requested_account_id: account_id,
            requested_member_budget_id: member_budget_id
          }
        : {
            account_id,
            member_budget_id
          };

      dispatch(
        updateWorkloadPlanner({
          // Required
          id,
          approver_id: workplan.approver_id,
          ...memberFields,

          // Associations
          member_update_strategy: 'assign',

          // Fields that will fallback to potentially incorrect default values in
          // the `updateWorkloadPlanner` saga if not specified.
          all_day: initialWorkplan.all_day,
          dependency_infos: initialWorkplan.dependency_info,
          end_time: initialWorkplan.end_time,
          include_holidays: initialWorkplan.include_holidays,
          include_weekends: initialWorkplan.include_weekends,
          phase_dependency: initialWorkplan.phase_dependency,
          start_time: initialWorkplan.start_time
        })
      );
    }
  };

  const memberUtilizations = accountUtilizations[accountId];

  const [memberBudgetIdToMerge, setMemberBudgetIdToMerge] = useState<
    number | null
  >();
  const unassignedMemberBudget = memberBudgets[projectId]?.find(
    (memberBudget) => memberBudget.id === memberBudgetIdToMerge
  );

  const [isReassignConfirmModalOpen, setIsReassignConfirmModalOpen] =
    useState<boolean>(false);
  const handleSetReassignConfirmModalOpen = (value: boolean) => {
    setIsReassignConfirmModalOpen(value);
  };

  const [
    isUnarchiveMemberConfirmModalOpen,
    setIsUnarchiveMemberConfirmModalOpen
  ] = useState<boolean>(false);
  const handleSetUnarchiveMemberConfirmModalOpen = (value: boolean) => {
    setIsUnarchiveMemberConfirmModalOpen(value);
  };

  const [shouldMergeFlag, setShouldMergeFlag] = useState<boolean>(false);
  const handleConfirmToMerge = () => {
    setShouldMergeFlag(true);
  };

  useEffect(() => {
    if (workplan.account_id && workplan.phase_id && workplan.project_id) {
      dispatch(
        fetchMemberBudgetPhase({
          accountId: workplan.account_id,
          phaseId: workplan.phase_id,
          projectId: workplan.project_id
        })
      );
    }
  }, [dispatch, workplan.account_id, workplan.phase_id, workplan.project_id]);

  const handleUpdateIsAllDay = (isAllDay) => {
    updateWorkplan('budget_status', {
      all_day: isAllDay
    });
  };

  /* ------------------------ workplan calendar popover ----------------------- */
  const [isWorkplanCalendarOpen, setIsWorkplanCalendarOpen] =
    useState<boolean>(false);

  const handleSetWorkplanCalendarOpen = (value: boolean) => {
    setIsWorkplanCalendarOpen(value);
  };

  const memberAverageCapacity =
    averageCapacities[accountId] || defaultAverageCapacity;
  const memberTotalCapacity =
    totalCapacities[accountId]?.total || totalCapacities[accountId]?.total === 0
      ? totalCapacities[accountId]?.total
      : defaultAccountTotalCapacity;
  const memberDailyCapacity = pick(
    totalCapacities[accountId],
    CAPACITY_DATE_KEYS
  );
  const memberPTOPlans =
    totalCapacities[accountId]?.activity_phase_schedule_bars;

  const memberPTODates = useMemo((): Set<string> => {
    const ptoDatesSet = new Set<string>();

    memberPTOPlans &&
      PTOProject &&
      memberPTOPlans.forEach((memberPTOPlan) => {
        /**
         * memberPTOPlans also include plans for when member is working remotely.
         * Exclude these from PTO dates.
         *
         * PTO plans have same project_id as the project returned from getOOOProject selector.
         * WFH plans have a different project_id.
         */
        if (memberPTOPlan.project_id !== PTOProject.id) return;

        const ptoRange = range(
          memberPTOPlan.start_date,
          memberPTOPlan.end_date
        );

        const ptoRangeDates = Array.from(ptoRange.by('day', { step: 1 }));

        ptoRangeDates.forEach((date: Moment) => {
          ptoDatesSet.add(date.format('YYYY-MM-DD'));
        });
      });

    return ptoDatesSet;
  }, [memberPTOPlans, PTOProject]);

  /* ------------------- workplan calendar more options menu ------------------- */

  const [isWorkplanMoreOptionsOpen, setIsWorkplanMoreOptionsOpen] =
    useState<boolean>(false);

  const handleSetWorkplanMoreOptionsOpen = (value: boolean) => {
    setIsWorkplanMoreOptionsOpen(value);
  };

  /* ------------------------------- permissions ------------------------------ */
  const {
    getCanEditWorkPlan,
    getCanDeleteWorkPlan,
    getCanAddWorkPlanComment,
    getCanEditWorkPlanTask,
    getCanRemoveWorkPlanTask,
    getCanEditWorkPlanScope,
    getCanRemoveWorkPlanScope
  } = useWorkplanPermission();

  const permissions = {
    canEditWorkplan: getCanEditWorkPlan({ accountId }),
    canDeleteWorkplan: getCanDeleteWorkPlan({ accountId }),
    canAddWorkplanComment: getCanAddWorkPlanComment({ accountId }),
    canEditWorkplanTask: getCanEditWorkPlanTask({ accountId }),
    canRemoveWorkplanTask: getCanRemoveWorkPlanTask({ accountId }),
    canEditWorkplanScope: getCanEditWorkPlanScope({ accountId }),
    canRemoveWorkplanScope: getCanRemoveWorkPlanScope({ accountId })
  };

  /* ---------------------------- project and phase --------------------------- */

  const isPTO = project && project.id === PTOProject?.id;

  const handleSelectProjectOrPhase = (values: {
    projectId: number;
    phaseId: number;
    activityId: number;
    activityPhaseId: number;
  }) => {
    const shouldUnsetMemberBudgetId = values.projectId !== projectId;

    updateWorkplan('activity_phase_id', {
      project_id: values.projectId,
      phase_id: values.phaseId,
      activity_id: values.activityId,
      activity_phase_id: values.activityPhaseId,
      all_day: values.projectId === PTOProject?.id,
      member_budget_id: shouldUnsetMemberBudgetId
        ? null
        : workplan.member_budget_id
    });
    if (shouldUnsetMemberBudgetId) {
      dispatch(fetchMemberBudgets({ projectId: values.projectId }));
    }
  };

  const disableDependency = Boolean(
    !phase || phase.is_like_default || (!phase.start_date && !phase.end_date)
  );

  const mergeMemberBudget = () => {
    const onSuccess = [
      {
        successAction: () =>
          fetchWorkloadPlanner({
            startDate: moment().add(-1, 'years').format('MM/DD/YYYY'),
            endDate: moment().add(1, 'years').format('MM/DD/YYYY'),
            accountId: accountId,
            project_ids: [projectId],
            permissions: {
              teamId
            },
            all: true
          }),
        selector: noop
      }
    ];
    assignMemberBudget({
      projectId: projectId,
      memberBudgetId: memberBudgetIdToMerge,
      assignedMemberBudgetId: memberBudget?.id,
      projectMembershipId: memberBudget?.project_membership?.id,
      onSuccess
    });
  };

  /* -------------------------------- comments -------------------------------- */
  const getCommentCounts = useMemo(
    () => makeGetCommentCountsByParentEntity(),
    []
  );

  const commentCounts = useAppSelector((state) =>
    workplan.id
      ? getCommentCounts(state, {
          parentEntityType: EntityType.ActivityPhaseScheduleBarGroup,
          parentEntityId: workplan.id
        })
      : undefined
  );

  /* --------------------------------- scopes --------------------------------- */
  const { scopes, onAddScope, onRemoveScope, onClearScope } = useWorkPlanScopes(
    {
      scopeOrders: workplan?.project_scope_orders ?? emptyArray
    }
  );

  /* ---------------------------------- tasks --------------------------------- */

  const { tasks, taskOrder, onUpdateTaskIds } = useWorkPlanTasks({
    taskOrder: workplan?.project_task_orders ?? emptyArray
  });

  const handleUpdateTaskIds = useCallback(
    (taskIds: Array<number>) => {
      onUpdateTaskIds(taskIds);

      const accountId = workplan.account_id;
      const memberBudgetId = workplan.member_budget_id;

      // If the work plan does not yet exist, directly update the tasks.
      if (isNew) {
        const memberId = accountId ?? memberBudgetId;
        if (memberId !== undefined)
          updateTasks({ task_ids: taskIds, assignee_ids: [memberId] });
      }

      // If the work plan does exist, update via the work plan.
      else {
        const memberFields = isRequest
          ? {
              requested_account_id: accountId,
              requested_member_budget_id: memberBudgetId
            }
          : {
              account_id: accountId,
              member_budget_id: memberBudgetId
            };

        // Compute the change of tasks.
        const addedTaskIds = difference(taskIds, workplan.project_task_orders);
        const removedTaskIds = difference(
          workplan.project_task_orders,
          taskIds
        );

        dispatch(
          updateWorkloadPlanner({
            // Required
            ...memberFields,
            id: workplan.id,
            approver_id: workplan.approver_id,

            // Tasks
            member_update_strategy: 'assign',
            add_task_ids: addedTaskIds,
            remove_task_ids: removedTaskIds,

            // Fields that will fallback to potentially incorrect default values in
            // the `updateWorkloadPlanner` saga if not specified.
            all_day: initialWorkplan.all_day,
            dependency_infos: initialWorkplan.dependency_info,
            end_time: initialWorkplan.end_time,
            include_holidays: initialWorkplan.include_holidays,
            include_weekends: initialWorkplan.include_weekends,
            phase_dependency: initialWorkplan.phase_dependency,
            start_time: initialWorkplan.start_time
          })
        );
      }
    },
    [
      dispatch,
      initialWorkplan,
      isNew,
      isRequest,
      onUpdateTaskIds,
      updateTasks,
      workplan
    ]
  );

  /* ------------------------------ activityLogs ------------------------------ */
  const { activityLogs } = useWorkPlanActivityLogs({
    actionableId: workplan?.id,
    actionableType: EntityType.ActivityPhaseScheduleBar
  });

  /* -------------------------------- workplan -------------------------------- */

  const getIsWorkplanOnHoliday = (): boolean => {
    return (
      holidayDates && workplan.start_date && holidayDates[workplan.start_date]
    );
  };

  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState<boolean>(false);

  const handleSetIsDeleteConfirmModalOpen = (value: boolean) => {
    setIsDeleteConfirmModalOpen(value);
  };

  const handleDelete = () => {
    dispatch(
      deleteWorkloadPlanner({
        ...workplan,
        // update orders if necessary
        workplanStateId,
        parentGroupId,
        isRequest: workplan.is_request
      })
    );
    handleSetIsDeleteConfirmModalOpen(false);
    closeModal();
  };

  const handleClickSubmit = () => {
    if (!permissions.canEditWorkplan) {
      closeModal();
      return;
    }

    // skip selected_archived_member. archived phase member will be unarchived
    if (hasError(['selected_archived_phase_member'], { inverse: true })) {
      handleShowErrors();
      return;
    }

    if (shouldMergeFlag) {
      mergeMemberBudget();
    }

    if (member && teamUtils.getIsArchived(member)) {
      handleSetUnarchiveMemberConfirmModalOpen(true);
      return;
    }

    if (isArchivedPhaseMember) {
      if (isMemberHasProjectMembership({ project, member })) {
        handleUnarchiveMember();
      } else {
        // unarchive member if member is archived in project
        // if member doesn't have project membership but archived in phase,
        // can assume that member is archived in project
        handleSetUnarchiveMemberConfirmModalOpen(true);
        return;
      }
    }

    handleSubmit();
  };

  const handleUnarchiveMember = () => {
    const phaseMembership = phase?.phase_memberships?.find(
      (member) => member.account_id === workplan.account_id
    );
    if (phaseMembership) {
      // unarchive member
      dispatch(
        archivePhaseMember({
          id: phaseMembership.id,
          phaseId: workplan.phase_id,
          projectId: workplan.project_id,
          archive: false
        })
      );
    }
  };
  const handleConfirmToUnarchive = () => {
    handleUnarchiveMember();
    handleSubmit();
  };

  const handleSubmit = () => {
    const {
      approver_id,
      account_id: requested_account_id,
      member_budget_id: requested_member_budget_id,
      project_id: requested_project_id,
      phase_id: requested_phase_id,
      activity_id: requested_activity_id,
      activity_phase_id: requested_activity_phase_id,
      start_date: requested_start_date,
      end_date: requested_end_date,
      all_day: requested_all_day,
      total_hours: requested_total_hours,
      daily_hours: requested_daily_hours,
      ...restParams // params that are same for both request & regular work plan
    } = workplan;

    // old workplanRequestParams. leaving it until we are clear to remove
    const workplanRequestParams = {
      is_request: true,
      approver_id,
      requested_account_id,
      requested_member_budget_id,
      requested_project_id,
      requested_phase_id,
      requested_activity_id,
      requested_activity_phase_id,
      requested_start_date,
      requested_end_date,
      requested_all_day,
      requested_total_hours,
      requested_daily_hours,
      requester_id: currentUser.account_id,
      request_date: workplan.request_date || today
    };

    // this is used for workplan request approve params. only required when approving work plan request
    const workplanRequestApproveParams: ApproveWorkplanRequestParams = {
      id: suggestedWorkPlan?.workplanRequestId,
      all_day: requested_all_day,
      daily_hours: +requested_daily_hours,
      start_time: restParams.start_time,
      end_time: restParams.end_time,
      use_weekly_planning: restParams.use_weekly_planning,
      include_weekends: restParams.include_weekends,
      include_holidays: restParams.include_holidays,
      budget_status: restParams.budget_status,
      lock_hour: restParams.lock_hour,
      reject: suggestedWorkPlan?.reject
      // BE fix required for start_date and end_date
      // start_date: requested_start_date,
      // end_date: requested_end_date
    };

    const scopeIds = scopes.map(({ id }) => id);

    const commonProps = {
      ...restParams,
      ...(isRequest ? workplanRequestParams : workplan),
      ...(!isRequest && workplan.is_request && { is_request: false }), // request is in regular work plan modal = being approved
      include_holidays: getIsWorkplanOnHoliday(),
      workplanStateId,
      groupAttribute,
      parentGroupId,
      scope_ids: scopeIds,
      description,
      /*
        currently BE does not fully support dependency_infos array on work plans
        use phase_dependencies value instead
      */

      // dependency_infos: dependencyInfos
      phase_dependency: phaseDependency
    };

    if (isNew && !isWorkplanRequest) {
      dispatch(
        createWorkloadPlanner({
          ...commonProps,
          scope_ids: scopeIds,
          add_task_ids: taskOrder,
          onSuccess: onCreateSuccess,
          member_update_strategy: 'assign'
        })
      );
    } else if (isWorkplanRequest) {
      dispatch(
        approveWorkplanRequest(
          merge(
            {},
            workplanRequestApproveParams,
            additionalApproveWorkplanRequestParams
          )
        )
      );
    } else {
      const addedScopeIds = difference(scopeIds, workplan.project_scope_orders);
      const removedScopeIds = difference(
        workplan.project_scope_orders,
        scopeIds
      );

      const addedTaskIds = difference(taskOrder, workplan.project_task_orders);
      const removedTaskIds = difference(
        workplan.project_task_orders,
        taskOrder
      );

      dispatch(
        updateWorkloadPlanner({
          ...commonProps,
          wasRequest: !isRequest && initialWorkplan.is_request, // check the actual bar as this value may be overwritten by prediction
          add_scope_ids: addedScopeIds,
          remove_scope_ids: removedScopeIds,
          add_task_ids: addedTaskIds,
          remove_task_ids: removedTaskIds,
          member_update_strategy: 'assign'
        })
      );
    }
    closeModal();
    setDependencyInfos([]);
  };

  const handleCancel = () => {
    closeModal();
    setDependencyInfos([]);
  };

  /**
   * If dependencyInfos not empty: user has modified dependency on the calendar (but not yet submitted)
   * Or else, just display the dependency info from existing work plan.
   */

  const { initialDependency, initialDependencyItem } = useMemo(() => {
    const dependencies =
      dependencyInfos?.length > 0
        ? dependencyInfos
        : workplan?.phase_dependency // for now still use phase_dependency until grouped options with dependency array is ready
        ? [{ dependency_type: workplan.phase_dependency }]
        : [];

    const initialDependency: CalendarDependencyState = {
      start: null,
      end: null
    };
    const initialDependencyItem: CalendarDependencyItemState = {
      start: null,
      end: null
    };

    if (!phase || !phase.start_date || !phase.end_date)
      return { initialDependency, initialDependencyItem };

    dependencies.forEach((dependency) => {
      if (isLegacyStartAndEndDateDependency(dependency.dependency_type)) {
        initialDependency.start = DEPENDENCY_TYPES.StartDate;
        initialDependencyItem.start = phase;
        initialDependency.end = DEPENDENCY_TYPES.EndDate;
        initialDependencyItem.end = phase;
      } else if (isStartDateDependency(dependency.dependency_type)) {
        initialDependency.start = dependency.dependency_type;
        initialDependencyItem.start = phase;
      } else if (isEndDateDependency(dependency.dependency_type)) {
        initialDependency.end = dependency.dependency_type;
        initialDependencyItem.end = phase;
      }
    });
    return { initialDependency, initialDependencyItem };
  }, [dependencyInfos, workplan, phase]);

  const moreOptions = {
    workingWeekends: workplan.include_weekends,
    weeklyPlan: workplan.use_weekly_planning,
    tenativePlan: isTentativePlan(workplan.budget_status),
    initialDependency
  };

  const handleSetDependency = ({
    dependency,
    dependencyItem,
    dependencyItemType
  }: {
    dependency: CalendarDependencyState;
    dependencyItem: CalendarDependencyItemState;
    dependencyItemType: DependableType;
  }): void => {
    const phaseDependencyTypeString = isStartAndEndDateDependency(dependency)
      ? DEPENDENCY_TYPES.StartAndEndDate
      : isStartDateDependency(dependency.start)
      ? DEPENDENCY_TYPES.StartDate
      : isEndDateDependency(dependency.end)
      ? DEPENDENCY_TYPES.EndDate
      : null;

    setPhaseDependency(phaseDependencyTypeString);

    setDependencyInfos(
      generateNewDependencyInfos(
        workplan,
        dependency,
        dependencyItem,
        dependencyItemType
      )
    );
  };

  const value: WorkPlanFormContextValues = {
    modal: {
      isOpen,
      onOpenModal,
      closeModal,
      onCloseModal
    },
    form: {
      isNew,
      isDirty: false,
      isPredicting,
      isRequest,
      showErrors,
      hasError,
      onSubmit: handleClickSubmit,
      onCancel: handleCancel,
      onDelete: handleDelete,
      onChangePredictableFields: updateWorkplan,
      onUpdateIsTentative: handleUpdateIsTentative,
      onUpdateIsAllDay: handleUpdateIsAllDay,
      shouldDisableInputs,
      isMissingInfo,
      initialWorkplan,
      workplan, // current state
      workDays: extraValues.work_days?.toString(),
      workdayPercent: extraValues.workday_percent,
      datesArray: extraValues.datesArray,
      isDeleteConfirmModalOpen,
      onSetDeleteConfirmModalOpen: handleSetIsDeleteConfirmModalOpen,
      handleSetDependency,
      initialDependency,
      initialDependencyItem
    },
    projectAndPhase: {
      board,
      project,
      phase,
      activity,
      isPTO,
      onSelectProjectOrPhase: handleSelectProjectOrPhase,
      hasDefaultPhase,
      disableDependency
    },
    description: {
      value: description,
      onUpdate: handleUpdateDescription
    },
    member: {
      accountId,
      member,
      onSelectMember: handleSelectMember,
      isMemberArchived: isArchivedPhaseMember,
      budgetTotals,
      memberAverageCapacity,
      memberTotalCapacity,
      memberUtilizations,
      memberDailyCapacity,
      memberPTODates,
      holidayDates,
      isReassignConfirmModalOpen,
      unassignedMemberBudget,
      onConfirmToMerge: handleConfirmToMerge,
      onSetReassignConfirmModalOpen: handleSetReassignConfirmModalOpen,
      onConfirmToUnarchiveMember: handleConfirmToUnarchive,
      isUnarchiveMemberConfirmModalOpen,
      onSetUnarchiveMemberConfirmModalOpen:
        handleSetUnarchiveMemberConfirmModalOpen
    },
    comment: {
      totalCount: commentCounts?.totalCount ?? 0
    },
    scope: {
      scopes,
      onAddScope,
      onRemoveScope
    },
    task: {
      taskOrder,
      tasks,
      onUpdateTaskIds: handleUpdateTaskIds
    },
    activityLog: {
      activityLogs
    },
    calendar: {
      isOpen: isWorkplanCalendarOpen,
      handleSetWorkplanCalendarOpen
    },
    optionsMenu: {
      isOpen: isWorkplanMoreOptionsOpen,
      handleSetWorkplanMoreOptionsOpen
    },
    moreOptions,
    permissions
  };
  return (
    <WorkPlanFormContext.Provider value={value}>
      {children}
    </WorkPlanFormContext.Provider>
  );
};

export const useWorkPlanForm = () => useContext(WorkPlanFormContext);
