import { useEffect, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import theme from 'theme';
import SimpleBar from 'components/Bars/SimpleBar';
import { rebuildTooltip } from 'appUtils/tooltipUtils';
import moment from 'moment';
import { HTMLTable } from 'components/HTMLTable';
import { SuggestedMemberForPhaseMembership } from 'SuggestionModule/components/FindPeople/types';
import { Phase } from 'ProjectsModule/phases/models/phase';
import { PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition } from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { getPhaseDatesData } from 'SuggestionModule/components/FindPeople/utils';
import { MemberGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';

const calculateMemberHours = ({
  member,
  phaseMembership,
  phase,
  startDate,
  endDate
}: {
  member: SuggestedMemberForPhaseMembership;
  phaseMembership:
    | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
    | undefined;
  phase: Phase;
  startDate: Nullable<string> | undefined;
  endDate: Nullable<string> | undefined;
}) => {
  const memberSchedule = member.schedule || {};
  const scheduleDateKeys = Object.keys(memberSchedule);
  let totalCapacity = 0;
  let totalAvailability = 0;

  scheduleDateKeys.forEach((date) => {
    // Get the available hours and capacity between the selected start and end dates inclusively
    if (moment(date).isBetween(startDate, endDate, 'day', '[]')) {
      const { remaining_capacity, total_capacity } = memberSchedule[date] || {};
      totalCapacity += total_capacity ?? 0;
      totalAvailability += remaining_capacity ?? 0;
    }
  });

  // Formatting (there is a case where these numbers can bee in long decimals)
  totalAvailability = Math.round(totalAvailability);
  totalCapacity = Math.round(totalCapacity);

  return {
    totalAvailability,
    totalCapacity,
    availabilityToCapacityRatio: Math.round(
      Math.min(
        totalCapacity ? (totalAvailability / totalCapacity) * 100 : 0,
        100
      )
    )
  };
};

const getBartooltip = ({
  totalAvailability,
  totalCapacity,
  availabilityToCapacityRatio,
  hasDatesRequired
}) => {
  const bodyRows = [
    {
      name: 'availability',
      columns: [
        {
          content: <div className="title">Availability</div>
        },
        {
          content: <div>{totalAvailability}h</div>
        },
        {
          content: (
            <div>
              | <span className="ratio">{availabilityToCapacityRatio}%</span>
            </div>
          )
        }
      ]
    },
    {
      name: 'capacity',
      columns: [
        {
          content: <div className="title">Capacity</div>
        },
        {
          content: <div>{totalCapacity}h</div>
        },
        {
          content: <div></div>
        }
      ]
    }
  ];

  return renderToString(
    <StyledTooltipContainer>
      {!hasDatesRequired && <div>Over Dates Required</div>}
      {!hasDatesRequired && <hr className="divider" />}
      <HTMLTable bodyRows={bodyRows} />
    </StyledTooltipContainer>
  );
};

const MemberHoursCell = ({ row }: MemberGroupRowProps) => {
  useEffect(() => {
    rebuildTooltip();
  }, []);

  const {
    member,
    phase,
    phaseMembership,
    customRowProps: { showDemoSuggestions }
  } = row.original;

  const { startDateToUse, endDateToUse } = useMemo(
    () =>
      getPhaseDatesData({
        phase,
        phaseMembership
      }),
    [phase, phaseMembership]
  );

  const { totalAvailability, totalCapacity, availabilityToCapacityRatio } =
    useMemo(
      () =>
        calculateMemberHours({
          member,
          phaseMembership,
          phase,
          startDate: startDateToUse,
          endDate: endDateToUse
        }),
      [endDateToUse, member, phase, phaseMembership, startDateToUse]
    );

  const sections = useMemo(
    () => [
      {
        sectionBackground: theme.colors.colorMediumPurple2,
        width: `${availabilityToCapacityRatio}%`,
        height: ''
      }
    ],
    [availabilityToCapacityRatio]
  );

  return (
    <StyledTextContainer
      data-html
      data-class="center"
      data-for="app-tooltip"
      data-effect="solid"
      data-tip={getBartooltip({
        totalAvailability,
        totalCapacity,
        availabilityToCapacityRatio,
        hasDatesRequired: startDateToUse && endDateToUse
      })}
    >
      <div className="total-available-hours">{totalAvailability}h</div>
      <SimpleBar sections={sections} />
    </StyledTextContainer>
  );
};

export default MemberHoursCell;

const StyledTextContainer = styled.div`
  color: ${theme.colors.colorMediumGray9};
  width: 70%;
  height: 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: auto;
  font-size: 13px;
  padding-top: 19px;

  .total-available-hours {
    font-weight: 600;
  }
`;

const StyledTooltipContainer = styled.div`
  .title {
    text-align: left;
    padding-right: 12px;
  }

  .ratio {
    color: ${theme.colors.colorLightGray6};
  }

  hr {
    border-color: white;
    width: 80%;
  }
`;
