import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { getSelectedTeamViewSettings } from 'selectors';
import { updateTeamViewSettings } from 'actionCreators';
import ButtonField from 'SettingsModule/components/ButtonsField';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import useIsHoursOnly from 'appUtils/hooks/useIsHoursOnly';
import {
  Field,
  Label
} from 'SettingsModule/components/StandardSettings/General/styles';

const BudgetTrackingSetting = () => {
  const dispatch = useAppDispatch();
  const viewSettings = useAppSelector(getSelectedTeamViewSettings);
  const { hoursOnlyFlag } = useIsHoursOnly();
  const teamId = useAppSelector(getSelectedTeamId);
  const [isHoursOnly, setIsHoursOnly] = useState(
    viewSettings?.is_hours_only || false
  );

  const updateHoursOnlyViewSettings = () => {
    const newViewSettings = {
      ...viewSettings,
      is_hours_only: !isHoursOnly
    };
    dispatch(updateTeamViewSettings({ viewSettings: newViewSettings, teamId }));
    setIsHoursOnly(!isHoursOnly);
  };

  return hoursOnlyFlag ? (
    <>
      <Label>Budget Tracking</Label>
      <Field>
        <ButtonField
          type="radio"
          value="currency"
          checked={!isHoursOnly}
          name="currency"
          onClick={() => {
            if (isHoursOnly) {
              updateHoursOnlyViewSettings();
            }
          }}
          label="Currency"
        />
        <ButtonField
          type="radio"
          value="hoursOnly"
          checked={isHoursOnly}
          name={'hoursOnly'}
          onClick={() => {
            if (!isHoursOnly) {
              updateHoursOnlyViewSettings();
            }
          }}
          label="Hours Only"
        />
      </Field>
    </>
  ) : null;
};

export default BudgetTrackingSetting;
