import { useMemo, useRef } from 'react';
import styled from 'styled-components';
import theme from 'theme';
import cn from 'classnames';
import { NumberFormatInput } from 'components/styles';
import { useAppSelector, useAppDispatch } from 'reduxInfra/hooks';
import { PhaseGroupRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { StyledColumnLabel } from 'SuggestionModule/components/FindPeople/Table/styles';
import { getPhaseDatesData } from 'SuggestionModule/components/FindPeople/utils';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import { StyledDropdownLabel } from 'SuggestionModule/components/FindPeople/styles';
import BudgetLegendItem from 'BudgetModule/components/BudgetLegendItem';
import {
  createPhaseEstimation,
  updatePhaseEstimation
} from 'BudgetModule/actionCreators';
import useMultiLevelDropdown from 'components/MultiLevelDropdown/useMultiLevelDropdown';
import GoToProjectIcon from 'icons/GoToProjectIcon';
import NumberFormat from 'react-number-format';
import { MOUSE_EVENTS } from 'components/MultiLevelDropdown';

const emptyObj = {};

enum PhaseHoursOptions {
  EditBudget = 'editBudget'
}

const PhaseHoursCell = ({ row }: PhaseGroupRowProps) => {
  const {
    phaseMembership,
    phase,
    accountTotals,
    hasActivityPhases,
    customRowProps: { projectId, openBudgetModal }
  } = row.original;
  const dispatch = useAppDispatch();

  const { dropdownRef, openDropdown, MultiLevelDropdown, closeDropdown } =
    useMultiLevelDropdown();

  const menuContent = useMemo(() => {
    return {
      items: [
        {
          name: PhaseHoursOptions.EditBudget,
          label: (
            <StyledDropdownLabel>
              <GoToProjectIcon color={theme.colors.colorMediumGray9} /> Edit
              Budget
            </StyledDropdownLabel>
          ),
          subMenu: undefined
        }
      ]
    };
  }, []);

  const onClickHash = useMemo(() => {
    const value = {
      [PhaseHoursOptions.EditBudget]: () => {
        openBudgetModal();
        closeDropdown();
      }
    };

    return value;
  }, [openBudgetModal, closeDropdown]);

  const { activity_phase_estimation_id, hours: unassignedRoleBudgetHours } =
    accountTotals?.estimated || {};

  const hoursRef = useRef<NumberFormat<unknown>>(null);

  const onBlur = () => {
    if (hoursRef?.current) {
      const { numAsString } = hoursRef.current.state;
      const newHours = isNaN(parseFloat(numAsString))
        ? 0
        : parseFloat(numAsString);
      if (unassignedRoleBudgetHours !== newHours) {
        if (activity_phase_estimation_id) {
          dispatch(
            updatePhaseEstimation({
              project_id: projectId,
              id: activity_phase_estimation_id,
              estimated_hours: newHours,
              member_budget_id: phaseMembership?.member_budget_id,
              phase_id: phase.id,
              refetchPhaseTotalsFilterStateId: FilterStateIds.fetchPhaseTotals
            })
          );
        } else {
          dispatch(
            createPhaseEstimation({
              project_id: projectId,
              estimated_hours: newHours,
              member_budget_id: phaseMembership?.member_budget_id,
              phase_id: phase.id,
              refetchPhaseTotalsFilterStateId: FilterStateIds.fetchPhaseTotals
            })
          );
        }
      }
    }
  };

  return (
    <StyledColumnLabel
      className={cn('hour', {
        'has-activity-phases': hasActivityPhases
      })}
    >
      <BudgetLegendItem
        text="estimated"
        hideTooltip
        tooltipContent={undefined}
        hideDot={undefined}
        label={'BUDGET HRS.'}
        forceShowDot={undefined}
        estimatedNotTarget={undefined}
      />
      {phaseMembership && !hasActivityPhases && !phase.archived ? (
        <StyledNumberFormatInput
          defaultValue={unassignedRoleBudgetHours}
          placeholder={'0h'}
          onBlur={onBlur}
          decimalScale={2}
          ref={hoursRef}
          allowNegative={false}
          thousandSeparator
          isNumericString
          allowLeadingZeros={false}
          suffix="h"
          className="hour-input"
        />
      ) : (
        <div
          ref={dropdownRef}
          onClick={openDropdown}
          className="non-editable-field"
        >
          {unassignedRoleBudgetHours ?? 0}h
        </div>
      )}
      <MultiLevelDropdown
        menuContent={menuContent}
        onClickHash={onClickHash}
        openMethod={MOUSE_EVENTS.CLICK}
      />
    </StyledColumnLabel>
  );
};

export default PhaseHoursCell;

const StyledNumberFormatInput = styled(NumberFormatInput)`
  border-radius: 3px;
  height: 24px;
  cursor: pointer;

  &:focus,
  &:hover {
    background: ${theme.colors.colorLightGray17};
  }
`;
