import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddTaskStep } from './taskMenu/AddTaskStep';
import { SubMenuTabItemProps, SubMenuTabs } from '../SubMenuTabs';
import { useWorkPlanForm } from '../WorkPlanFormProvider';
import { SidebarTabPanel } from './SidebarTabPanel';
import { useWorkPlanRequestSidePanelForm } from 'WorkplanRequestsModule/components/WorkplanRequestSidePanelProvider';
import { Step, useSidebarPanel } from './SidebarPanelProvider';
import {
  PANEL_INSET_MARGIN,
  PANEL_INSET_PADDING
} from './shared/PanelScroller';

export enum WorkPlanSubMenuTabs {
  comments = 'comments',
  scope = 'scope',
  tasks = 'tasks',
  activity = 'activity'
}

export enum ProviderType {
  WorkPlan = 'WorkPlan',
  WorkplanRequest = 'WorkplanRequest'
}

export const SidebarPanel = ({
  providerType = ProviderType.WorkPlan
}: {
  providerType: ProviderType;
}) =>
  providerType === ProviderType.WorkPlan ? (
    <WorkplanSidePanel providerType={providerType} />
  ) : (
    <WorkplanRequestSidePanel providerType={providerType} />
  );

const WorkplanSidePanel = ({
  providerType
}: {
  providerType: ProviderType;
}) => {
  const {
    form: {
      workplan: {
        project_id,
        phase_id,
        activity_phase_id,
        activity_id,
        start_date,
        end_date,
        account_id,
        member_budget_id
      }
    },
    comment: { totalCount },
    scope: { scopes },
    task: { taskOrder, tasks, onUpdateTaskIds },
    activityLog: { activityLogs }
  } = useWorkPlanForm();
  const [currentTab, setCurrentTab] = useState<WorkPlanSubMenuTabs>(
    WorkPlanSubMenuTabs.comments
  );

  const handleChangeTab = (tabValue: WorkPlanSubMenuTabs) => {
    setCurrentTab(tabValue);
  };

  return (
    <SidebarPanelRenderPart
      totalCount={totalCount}
      scopesCount={scopes.length}
      tasksCount={tasks.length}
      activityLogsCount={activityLogs.length}
      currentTab={currentTab}
      handleChangeTab={handleChangeTab}
      providerType={providerType}
      addTaskStepProps={{
        projectId: project_id,
        phaseId: phase_id,
        activityPhaseId: activity_phase_id,
        activityId: activity_id,
        startDate: start_date,
        endDate: end_date,
        accountId: account_id ?? undefined,
        memberBudgetId: member_budget_id ?? undefined,
        initialTaskIds: taskOrder,
        onUpdateTaskIds
      }}
    />
  );
};

const WorkplanRequestSidePanel = ({
  providerType
}: {
  providerType: ProviderType;
}) => {
  const {
    comment: { totalCount },
    scope: { scopes },
    task: { tasks },
    activityLog: { activityLogs }
  } = useWorkPlanRequestSidePanelForm();
  const [currentTab, setCurrentTab] = useState<WorkPlanSubMenuTabs>(
    WorkPlanSubMenuTabs.comments
  );

  const handleChangeTab = (tabValue: WorkPlanSubMenuTabs) => {
    setCurrentTab(tabValue);
  };

  return (
    <SidebarPanelRenderPart
      totalCount={totalCount}
      scopesCount={scopes.length}
      tasksCount={tasks.length}
      activityLogsCount={activityLogs.length}
      currentTab={currentTab}
      handleChangeTab={handleChangeTab}
      providerType={providerType}
      addTaskStepProps={{
        projectId: undefined,
        startDate: undefined,
        endDate: undefined,
        accountId: undefined,
        memberBudgetId: undefined,
        initialTaskIds: [],
        onUpdateTaskIds: () => {}
      }}
    />
  );
};

interface SidebarPanelRenderPartProps {
  totalCount: number;
  scopesCount: number;
  tasksCount: number;
  activityLogsCount: number;
  currentTab: WorkPlanSubMenuTabs;
  handleChangeTab: (value: WorkPlanSubMenuTabs) => void;
  providerType: ProviderType;
  addTaskStepProps: {
    projectId?: number;
    phaseId?: number;
    activityPhaseId?: number;
    activityId?: number;
    startDate?: string;
    endDate?: string;
    accountId?: number;
    initialTaskIds?: Array<number>;
    memberBudgetId?: number;
    onUpdateTaskIds: (taskIds: Array<number>) => void;
  };
}

const SidebarPanelRenderPart = ({
  totalCount,
  scopesCount,
  tasksCount,
  activityLogsCount,
  currentTab,
  handleChangeTab,
  providerType,
  addTaskStepProps: {
    projectId,
    phaseId,
    activityPhaseId,
    activityId,
    startDate,
    endDate,
    accountId,
    initialTaskIds,
    memberBudgetId,
    onUpdateTaskIds
  }
}: SidebarPanelRenderPartProps) => {
  const { currentStep, closeStep } = useSidebarPanel();

  const tabs: Array<SubMenuTabItemProps<WorkPlanSubMenuTabs>> = [
    {
      label: 'Comments',
      value: WorkPlanSubMenuTabs.comments,
      count: totalCount
    },
    {
      label: 'Scope',
      value: WorkPlanSubMenuTabs.scope,
      count: scopesCount
    },
    ...(providerType === 'WorkPlan'
      ? [
          {
            label: 'Tasks',
            value: WorkPlanSubMenuTabs.tasks,
            count: tasksCount
          }
        ]
      : []),
    {
      label: 'Activity',
      value: WorkPlanSubMenuTabs.activity,
      count: activityLogsCount
    }
  ];

  const isValidAddTaskParams =
    projectId &&
    phaseId &&
    activityPhaseId &&
    activityId &&
    startDate &&
    endDate &&
    (accountId || memberBudgetId);
  useEffect(() => {
    if (currentStep === Step.addTask && !isValidAddTaskParams) closeStep();
  }, [closeStep, currentStep, isValidAddTaskParams]);

  return (
    <SidebarContainer>
      <SideBodyContainer>
        <SidebarPanelHeader>
          <SubMenuTabs
            tabs={tabs}
            currentTab={currentTab}
            onChange={handleChangeTab}
          />
        </SidebarPanelHeader>
        <SidebarPanelContent>
          <SidebarTabPanel
            currentTab={currentTab}
            providerType={providerType}
          />
        </SidebarPanelContent>
        {!!currentStep && (
          <OverlapContainer>
            {currentStep === Step.addTask && isValidAddTaskParams ? (
              <AddTaskStep
                projectId={projectId}
                phaseId={phaseId}
                activityPhaseId={activityPhaseId}
                activityId={activityId}
                scheduleStart={startDate}
                scheduleEnd={endDate}
                accountId={accountId}
                memberBudgetId={memberBudgetId}
                initialTaskIds={initialTaskIds}
                onUpdateTaskIds={onUpdateTaskIds}
                onClose={closeStep}
              />
            ) : null}
          </OverlapContainer>
        )}
      </SideBodyContainer>
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  height: 100%;
  padding: 0 ${PANEL_INSET_MARGIN}px 30px;
`;

const SidebarPanelHeader = styled.div`
  padding-bottom: 20px;
`;

const SideBodyContainer = styled.div`
  background: ${({ theme }) => theme.colors.colorLightGray19};
  border-radius: 8px;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 20px ${PANEL_INSET_PADDING}px 0;
`;

const SidebarPanelContent = styled.div`
  flex: 1;
  height: 100%;
  min-height: 0;
`;

const OverlapContainer = styled.div`
  background-color: #00000040;
  border-radius: 6px;
  inset: 0;
  overflow: hidden;
  padding-left: calc(100% - 440px);
  position: absolute;
`;
