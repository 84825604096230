import ModuleList from 'components/ViewFactory/ModuleList';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'reduxInfra/hooks';
import { getMatchedRouteParams } from 'appCore/navigation/selectors';
import { getInstalledPermissionsSettingsModuleIds } from './selectors';

export const PermissionsSettingsTabs = () => {
  const history = useHistory();
  const installedModuleIds = useAppSelector(
    getInstalledPermissionsSettingsModuleIds
  );
  const matchedParams = useAppSelector(getMatchedRouteParams);
  const { teamSlug } = matchedParams;

  const changeViewDisplay = (newTab) => (e) => {
    const route = `/${teamSlug}/settings/permissions/${newTab}`;
    history.push(route);
  };

  return (
    <div>
      <ModuleList
        currentTab={matchedParams.settingsViewType}
        listClassName="permissions-settings-tabs"
        changeTabs={changeViewDisplay}
        installedModuleIds={installedModuleIds}
        viewType="permissionsSettingsView"
      />
    </div>
  );
};
