import { createSelector, Selector } from '@reduxjs/toolkit';
import { RootState } from 'reduxInfra/shared';
import keyBy from 'lodash/keyBy';
import { getMe, getMyUserId } from 'UsersModule/selectors';
import { TeamMember } from '../models/teamMember';
import { TeamMemberWrapper, TeamsState } from '../types';
import { permissionsUtils } from 'PermissionsModule/utils';

const emptyArray = [];
const emptyObject = {};

const byTeamMemberAccountId = (item: TeamMember) => item.account.id;
const byTeamMemberTeamMembershipId = (item: TeamMember) => item.id;
const byTeamMemberEmail = (item: TeamMember) => item.account.email;

const isNotIntegrationActor = (member: TeamMember) =>
  !member.is_integration_account;

const getTeamsState: Selector<RootState, TeamsState> = (state) => state.teams;

export const getMemberAccountId: Selector<
  RootState,
  number,
  [TeamMember | { accountId: number } | { account_id: number }]
> = (_state, ownProps) => {
  return 'account' in ownProps
    ? ownProps.account.id
    : 'accountId' in ownProps
    ? ownProps.accountId
    : ownProps.account_id;
};

export const getSelectedTeam = createSelector(
  getTeamsState,
  (teamsState) => teamsState.selectedTeam
);

const getAllTeams = createSelector(
  getTeamsState,
  (teamsState) => teamsState.allTeams
);

export const getSelectedTeamId = createSelector(
  getSelectedTeam,
  (selectedTeam) => selectedTeam?.id
);

const getTeamMembersWithIntegrationAccounts = createSelector(
  getSelectedTeam,
  (team) => team?.team_members || emptyArray
);

export const getTeamMembers = createSelector(
  getTeamMembersWithIntegrationAccounts,
  (teamMembers) => teamMembers.filter(isNotIntegrationActor)
);

export const getTeamMembersHash = createSelector(
  getTeamMembers,
  (teamMembers) => keyBy(teamMembers, byTeamMemberAccountId)
);

export const getTeamMembersHashByTeamMembership = createSelector(
  getTeamMembers,
  (teamMembers) => keyBy(teamMembers, byTeamMemberTeamMembershipId)
);

export const getTeamMembersHashByEmail = createSelector(
  getTeamMembers,
  (teamMembers) => keyBy(teamMembers, byTeamMemberEmail)
);

export const getAllTeamMembers = createSelector(
  getTeamMembers,
  getMe,
  (members, me) =>
    members
      .map<TeamMemberWrapper>((member) => ({
        ...member,
        name: member.account.name,
        initials: member.account.initials,
        isLoggedInUser: me?.team_membership_id === member.id
      }))
      .filter((member) => !member.is_archived)
);

export const getAllTeamMembersWithArchived = createSelector(
  getTeamMembers,
  getMe,
  (members, me) =>
    members.map((member) => ({
      ...member,
      name: member.account.name,
      initials: member.account.initials,
      isLoggedInUser: me && me.team_membership_id === member.id
    }))
);

export const sortByPendingAndName = (a: TeamMember, b: TeamMember) => {
  if (a.pending_email === b.pending_email) {
    return a.account.name.toLowerCase() > b.account.name.toLowerCase() ? 1 : -1;
  }
  return a.pending_email ? 1 : -1;
};

export const sortTeamMembers = (
  allTeamMembers: Array<TeamMember>,
  myId: number | undefined
) => {
  const meArray = allTeamMembers.filter((member) => member.account.id === myId);
  const nonGuests = allTeamMembers.filter(
    (member) =>
      member.account.id !== myId && !permissionsUtils.getIsProjectGuest(member)
  );
  const guest = allTeamMembers.filter(
    (member) =>
      member.account.id !== myId && permissionsUtils.getIsProjectGuest(member)
  );

  return [
    ...meArray,
    ...nonGuests.sort(sortByPendingAndName),
    ...guest.sort(sortByPendingAndName)
  ];
};

export const getSortedTeamMembers = createSelector(
  getAllTeamMembers,
  getMyUserId,
  sortTeamMembers
);

export const makeGetSortedTeamMembers = () =>
  createSelector(
    (state, { isIncludeArchived = false }: { isIncludeArchived?: boolean }) =>
      isIncludeArchived
        ? getAllTeamMembersWithArchived(state)
        : getAllTeamMembers(state),
    getMyUserId,
    sortTeamMembers
  );

export const getAdmins = createSelector(
  getAllTeamMembers,
  (teamMembers) =>
    teamMembers?.filter((member) => member?.is_admin) ?? emptyArray
);

export const getTeamMembershipsByAccountId = createSelector(
  getAllTeams,
  (allTeams) =>
    (allTeams &&
      allTeams[0] &&
      allTeams[0].team_members &&
      keyBy(allTeams[0].team_members, byTeamMemberAccountId)) ||
    emptyObject
);

export const makeGetTeamMembershipByAccountId = () =>
  createSelector(
    getTeamMembershipsByAccountId,
    getMemberAccountId,
    (teamMembershipsByAccountId, memberAccountId) =>
      teamMembershipsByAccountId[memberAccountId]
  );

export const getMyTeamMembership = createSelector(
  getTeamMembershipsByAccountId,
  getMe,
  (teamMemberships, me) => me && teamMemberships[me.id]
);
