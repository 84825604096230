import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';
import { batch } from 'react-redux';
import {
  createTaskAndOpenModal,
  fetchCommentsAndMetadata,
  fetchTaskGroups,
  navigateToTaskModal,
  openConfirmCompleteTaskModal,
  setSelectedTask,
  triggerTaskCompletionToggle,
  triggerTasksAttributesUpdate,
  triggerBatchDeleteTasks,
  updateTaskPosition
} from 'actionCreators';
import {
  getAuthToken,
  getIsFetchingTasks,
  getShouldTriggerTaskConfirmCompleteModal
} from 'selectors';
import moment from 'moment';
import uuid from 'uuid';
import { Task } from 'models/task';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { TasksV2UpdateParams } from 'TaskModule/api/types';

// TODO: move to task module
export const useTasks = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthToken);
  const teamId = useAppSelector(getSelectedTeamId);
  const shouldTriggerTaskConfirmCompleteModal = useAppSelector(
    getShouldTriggerTaskConfirmCompleteModal
  );
  const isFetchingTasks = useAppSelector(getIsFetchingTasks);

  const handleOpenTaskModal = (task: Task) => {
    batch(() => {
      dispatch(fetchTaskGroups({ taskGroupIds: [task.task_group_id] }));

      dispatch(
        navigateToTaskModal({
          taskId: task.id
        })
      );
      dispatch(setSelectedTask(task.id));
      dispatch(
        fetchCommentsAndMetadata({
          taskId: task.id,
          taskType: 'projects',
          offset: 0,
          limit: 4
        })
      );
    });
  };

  const handleMarkComplete = (task: Task) => {
    const isCompleted = !!task.completed_at;
    if (!isFetchingTasks) {
      const isOverdue = task.due_at && task.due_at < Date();
      if (
        task.assignee_ids?.length > 1 &&
        shouldTriggerTaskConfirmCompleteModal
      ) {
        dispatch(openConfirmCompleteTaskModal({ taskId: task.id }));
        return;
      }
      dispatch(
        triggerTaskCompletionToggle({
          taskId: task.id,
          isCompleting: !isCompleted,
          doNotRemoveTask: undefined,
          permissions: {
            teamId
          }
        })
      );
      dispatch(
        updateTaskPosition({
          token,
          taskId: task.id,
          projectId: task.project_id,
          taskType: 'projects',
          isCompleted: !isCompleted,
          wasCompleted: isCompleted,
          isOverdue,
          taskPosition: task.position,
          oldTaskPosition: task.position,
          circleClicked: true,
          permissions: {
            teamId
          }
        })
      );
    }
  };

  const handleUpdateTasks = (body: TasksV2UpdateParams) => {
    dispatch(
      triggerTasksAttributesUpdate({
        token,
        body,
        permissions: {
          teamId
        },
        options: undefined
      })
    );
  };

  const handleCreateTaskAndOpenModal = ({
    projectId,
    phaseId,
    activityId,
    activityPhaseId,
    assigneeIds,
    startDate,
    endDate,
    taskListType
  }: {
    projectId: number;
    phaseId: number;
    activityId?: number;
    activityPhaseId?: number;
    assigneeIds?: Array<number>;
    startDate?: string;
    endDate?: string;
    taskListType?: string;
  }) => {
    dispatch(
      createTaskAndOpenModal({
        token,
        body: {
          description: '',
          project_id: projectId,
          phase_id: phaseId,
          activity_id: activityId,
          activity_phase_id: activityPhaseId,
          assignee_ids: assigneeIds ?? [],
          schedule_start: startDate
            ? moment(startDate).format('YYYY-MM-DD')
            : '',
          schedule_end: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
          id: uuid()
        },
        taskListType
      })
    );
  };

  const handleDeleteTasks = (taskIds: Array<number>) => {
    dispatch(
      triggerBatchDeleteTasks({
        token,
        body: {
          taskIds
        }
      })
    );
  };

  return {
    createTaskAndOpenModal: handleCreateTaskAndOpenModal,
    markComplete: handleMarkComplete,
    openTaskModal: handleOpenTaskModal,
    updateTasks: handleUpdateTasks,
    deleteTasks: handleDeleteTasks
  };
};
