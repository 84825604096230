import * as appConstants from 'appConstants';
import * as constants from '../constants';

export const initialState = {
  modalOpen: false,
  modalProjectId: null,
  newBudget: false,
  decimalsVisible: false,
  projectPhaseDetailsId: null,
  activityDropdownOpen: false,
  activityModalOpen: false,
  preFillText: null,
  modalActivityId: null,
  removeMemberModalOpen: false,
  removeMemberModalMemberBudget: null,
  addRequirementModalOpen: false,
  addRequirementModalMemberBudget: null,
  archiveMemberModalOpen: false,
  archiveMemberModalMemberBudget: null,
  archiveMemberModalPhaseId: null,
  archiveMemberModalActivity: null,
  archiveMemberModalActivityMembership: null,
  phaseMembersOrderByPhaseId: {},
  toBeAddedActivityId: null,
  isCustomActivityModal: false
};

const budget = (state = initialState, action) => {
  switch (action.type) {
    case appConstants.LOGOUT_USER: {
      return initialState;
    }
    case constants.OPEN_BUDGET_MODAL: {
      const { id, newBudget } = action.payload;
      return {
        ...state,
        modalOpen: true,
        modalProjectId: id,
        newBudget: !!newBudget
      };
    }
    case constants.CLOSE_BUDGET_MODAL: {
      return {
        ...state,
        modalOpen: false,
        modalProjectId: null,
        newBudget: false,
        phaseMembersOrderByPhaseId: {}
      };
    }
    case constants.OPEN_REMOVE_MEMBER_MODAL: {
      const { memberBudget } = action.payload;
      return {
        ...state,
        removeMemberModalOpen: true,
        removeMemberModalMemberBudget: memberBudget
      };
    }
    case constants.CLOSE_REMOVE_MEMBER_MODAL: {
      return {
        ...state,
        removeMemberModalOpen: false,
        removeMemberModalMemberBudget: null
      };
    }
    case constants.OPEN_ADD_REQUIREMENT_MODAL: {
      const { memberBudget } = action.payload;
      return {
        ...state,
        addRequirementModalOpen: true,
        addRequirementModalMemberBudget: memberBudget
      };
    }
    case constants.CLOSE_ADD_REQUIREMENT_MODAL: {
      return {
        ...state,
        addRequirementModalOpen: false,
        addRequirementModalMemberBudget: null
      };
    }
    case constants.OPEN_ARCHIVE_MEMBER_MODAL: {
      const { memberBudget, phaseId, activity, activityMembership } =
        action.payload;
      return {
        ...state,
        archiveMemberModalOpen: true,
        archiveMemberModalMemberBudget: memberBudget,
        archiveMemberModalPhaseId: phaseId,
        archiveMemberModalActivity: activity,
        archiveMemberModalActivityMembership: activityMembership
      };
    }
    case constants.CLOSE_ARCHIVE_MEMBER_MODAL: {
      return {
        ...state,
        archiveMemberModalOpen: false,
        archiveMemberModalMemberBudget: null,
        archiveMemberModalPhaseId: null,
        archiveMemberModalActivity: null,
        archiveMemberModalActivityMembership: null
      };
    }
    case constants.OPEN_ACTIVITY_DROPDOWN: {
      return {
        ...state,
        activityDropdownOpen: true
      };
    }
    case constants.CLOSE_ACTIVITY_DROPDOWN: {
      return {
        ...state,
        activityDropdownOpen: false
      };
    }
    case constants.OPEN_ACTIVITY_MODAL: {
      const { text, billable, id, toBeAddedActivityId, isCustomActivityModal } =
        action.payload;
      return {
        ...state,
        activityModalOpen: true,
        preFillText: text,
        preFillBillable: billable,
        modalActivityId: id,
        toBeAddedActivityId,
        isCustomActivityModal: !!isCustomActivityModal
      };
    }
    case constants.CLOSE_ACTIVITY_MODAL: {
      return {
        ...state,
        activityModalOpen: false,
        modalActivityId: null,
        preFillText: null,
        preFillBillable: null,
        toBeAddedActivityId: null
      };
    }
    case constants.TOGGLE_DECIMALS: {
      return {
        ...state,
        decimalsVisible: !state.decimalsVisible
      };
    }
    case constants.OPEN_PROJECT_PHASE_DETAILS: {
      const { projectId } = action.payload;
      return {
        ...state,
        projectPhaseDetailsId: projectId
      };
    }
    case constants.CLOSE_PROJECT_PHASE_DETAILS: {
      return {
        ...state,
        projectPhaseDetailsId: null
      };
    }
    case constants.SET_INITIAL_MEMBERS_ORDER: {
      const { phaseId, order } = action.payload;
      return {
        ...state,
        phaseMembersOrderByPhaseId: {
          ...state.phaseMembersOrderByPhaseId,
          [phaseId]: order
        }
      };
    }
    default:
      return state;
  }
};

export default budget;
