import { getSplitFlags } from './core';
import { createSelector } from 'reselect';
import { getIntegrationsV2 } from 'IntegrationsModule/selectors';

export const getIsCsvImportEnabled = createSelector(
  getSplitFlags,
  (flags) => flags?.timeEntryCSVImport
);

export const getCSVImportState = (state) => state.csvImport;

export const getIsCsvImportDataReady = createSelector(
  getCSVImportState,
  (state) => state.loaded
);
export const getCSVAgent = createSelector(getIntegrationsV2, (integrations) =>
  Object.values(integrations).find(
    (integration) =>
      integration.targetService === 'csv-agent' && !integration.isArchived
  )
);

export const getIsCSVProvisioned = createSelector(
  getCSVAgent,
  (csvAgent) => !!csvAgent || process.env.UI_ENV === 'demo'
);
