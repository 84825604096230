import { Phase } from 'ProjectsModule/phases/models/phase';
import { PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition } from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { serializeId } from 'appUtils';
import { getSuggestionsByPhaseMembership } from 'selectors';
import { getTeamMembershipsByAccountId } from 'TeamsModule/selectors';
import { FilterStateIds } from 'SuggestionModule/components/FindPeople/constants';
import { BUDGET_RECORD_DATA_TYPES } from 'appConstants';
import { initialState as initialBudgetRecordsState } from 'BudgetModule/reducers/budgetRecords';
import orderBy from 'lodash/orderBy';
import { generateReasons } from 'views/boardDisplay/SuggestionsTable/util';
import { SuggestedMemberForPhaseMembership } from 'SuggestionModule/components/FindPeople/types';
import {
  fetchPhaseTotals,
  fetchPhasesByProjectIds,
  fetchAllProjects,
  fetchProjectById,
  fetchProjectTeam,
  fetchTeamMembers
} from 'actionCreators';
import { reasonsToNotShowOnUI } from './constants';
import {
  PhaseTotals,
  AccountTotals
} from 'ProjectsModule/models/projectTotals';

/**
 * A getter for phase dates
 * including phase's start and end date, phase membership start and end date and other meta data
 */
export const getPhaseDatesData = ({
  phase,
  phaseMembership
}: {
  phase: Phase | undefined;
  phaseMembership:
    | PhaseMembershipWithUnassignedMemberBudgetAndFormattedPosition
    | undefined;
}) => {
  const phaseStartDate = phase?.start_date;
  const phaseEndDate = phase?.end_date;
  const phaseHasDates = !!phaseStartDate && !!phaseEndDate;

  const phaseMembershipStartDate = phaseMembership?.start_date;
  const phaseMembershipEndDate = phaseMembership?.end_date;
  const phaseMembershipHasDates =
    !!phaseMembershipStartDate && !!phaseMembershipEndDate;

  // Spec - Default to phase start and end date if phase membership does not have date
  const startDateToUse = phaseMembershipHasDates
    ? phaseMembershipStartDate
    : phaseStartDate;
  const endDateToUse = phaseMembershipHasDates
    ? phaseMembershipEndDate
    : phaseEndDate;

  return {
    startDateToUse,
    endDateToUse,

    phaseEndDate,
    phaseStartDate,
    phaseHasDates,

    /* -------------------------------------------------------------------------- */
    phaseMembershipEndDate,
    phaseMembershipStartDate,
    phaseMembershipHasDates
  };
};

export const fetchBudgetRecordsForPhasesFilterStateModifier = ({
  state,
  action,
  filterStateId
}: {
  state: typeof initialBudgetRecordsState;
  action: {
    payload: {
      response: {
        records: {
          member_budget_id: number;
          phase_id: number;
        }[];
        total: number;
      };
      requestPayload: {
        params: {
          data_type: typeof BUDGET_RECORD_DATA_TYPES['ACCOUNT_PHASE'];
          member_budget_ids: number[];
          phase_ids: number[];
          project_ids: number[];
          start_date: string;
          end_date: string;
        };
      };
    };
  };
  filterStateId: typeof FilterStateIds['fetchBudgetRecordsForPhases'];
}) => {
  const { records = [], total } = action.payload.response;
  const {
    data_type,
    member_budget_ids = [],
    phase_ids = [],
    project_ids = [],
    start_date,
    end_date
  } = action.payload.requestPayload.params || {};
  // Individual api call where ids = [entityId], due to each phase has different start and end date
  const projectId = project_ids[0];
  const phaseId = phase_ids[0];
  const memberBudgetId = member_budget_ids[0];

  const nextState = {
    ...state
  };

  if (!phaseId || !memberBudgetId) return nextState;

  const uniqueRecordData = records.find(
    (record) =>
      record.member_budget_id === memberBudgetId && record.phase_id === phaseId
  );

  const uid = serializeId({
    itemType: data_type,
    id: undefined,
    ids: [phaseId, memberBudgetId]
  });

  /**
   * {
   *   recordsByAggregateType: {
   *    [data_type]: {
   *       [memberBudgetId]: {
   *         [uid]: data
   *        }
   *     }
   *   }
   * }
   *
   */

  const nextFilterState = {
    ...nextState.filterStates[filterStateId],
    recordsByAggregateType: {
      ...nextState.filterStates[filterStateId]?.recordsByAggregateType,
      [data_type]: {
        ...nextState.filterStates[filterStateId]?.recordsByAggregateType?.[
          data_type
        ],
        [uid]: uniqueRecordData
      }
    }
  };

  nextState.filterStates = {
    ...nextState.filterStates,
    [filterStateId]: nextFilterState
  };

  return nextState;
};

const SUGGESTIONS_LIMIT = 5;

const REASON_DISPLAY_LIMIT = 5;

/**
 * FE util to create mock reasons based on phase membership id
 */
const generateSortedReasons = (id: number) => {
  const reasonsObj = generateReasons(id);
  const sortedReasons = orderBy(
    Object.entries(reasonsObj).map(([key, value]) => ({ key, value })),
    'value',
    'desc'
  )
    .slice(0, REASON_DISPLAY_LIMIT)
    .reduce((acc, reason) => {
      acc[reason.key] = reason.value;
      return acc;
    }, {});
  return sortedReasons;
};

export const makeSuggestedMembersList = ({
  realSuggestionsByPhaseMemberships,
  phaseMembershipId,
  showDemoSuggestions,
  phase,
  isShowingAllMembers,
  teamMembershipsByAccountId
}: {
  realSuggestionsByPhaseMemberships: ReturnType<
    typeof getSuggestionsByPhaseMembership
  >;
  phaseMembershipId?: number;
  showDemoSuggestions: boolean;
  phase: Phase;
  isShowingAllMembers: boolean;
  teamMembershipsByAccountId: ReturnType<typeof getTeamMembershipsByAccountId>;
}) => {
  const rawList = phaseMembershipId
    ? realSuggestionsByPhaseMemberships[phaseMembershipId]?.suggestions?.reduce(
        (acc, member) => {
          if (teamMembershipsByAccountId[member.account_id]) {
            acc.push(member);
          }

          return acc;
        },
        []
      )
    : undefined;

  if (!rawList)
    return {
      suggestedMembersToShow: [],
      allSuggestedMembers: [],
      numOfRemainingSuggestedMembers: 0,
      shouldShowMemberAlternatesRow: false,
      memberIdsOrder: []
    };

  /**
   * Future: When member rejection is supported
   * - Split the list into two lists:
   *  1) Suggested members that are not rejected
   *  2) Suggested members that are rejected
   * - Sort the first list
   * - Then at the end [suggestedMembersToShow,...rejectedOnes]
   */
  const sortedAllMembers = orderBy(rawList, 'score', 'desc');
  const topSuggestedMembersInLimit = sortedAllMembers.slice(
    0,
    SUGGESTIONS_LIMIT
  );

  // Only showing member alternates row if number of all suggested members equals to number of top suggested members
  const shouldShowMemberAlternatesRow =
    topSuggestedMembersInLimit.length < sortedAllMembers.length;

  /**
   * Case 1: If we need to show member alternates row
   *  - Check toggle state (isShowingAllMembers) to see whether
   *   - If toggle is on, show all members
   *   - If toggle is off, show top suggested members
   * Case 2: If we don't need to show member alternates row
   *  - Then we know that we should be showing all members
   */
  const suggestedMembersToShow = shouldShowMemberAlternatesRow
    ? isShowingAllMembers
      ? sortedAllMembers
      : topSuggestedMembersInLimit
    : sortedAllMembers;

  const numOfRemainingSuggestedMembers = shouldShowMemberAlternatesRow
    ? isShowingAllMembers
      ? 0
      : sortedAllMembers.length - suggestedMembersToShow.length
    : sortedAllMembers.length;

  const memberIdsOrder = suggestedMembersToShow.map(
    (member) => member.account_id
  );

  return {
    suggestedMembersToShow,
    numOfRemainingSuggestedMembers,
    allSuggestedMembers: sortedAllMembers,
    shouldShowMemberAlternatesRow,
    memberIdsOrder
  };
};

export const serializeMemberAlternatesRowId = ({ phaseMembershipId }) => {
  return `member-alternates-row-${phaseMembershipId}`;
};

/**
 *
 * Get a list of follow-up actions as seen in createPhaseMembersWorkers
 * @returns AnyActions[]
 */
export const getCreatePhaseMembersRefetchActions = ({
  projectId,
  filterStateId = FilterStateIds.fetchPhaseTotals
}: {
  projectId: number;
  filterStateId?: string;
}) => {
  return [
    fetchPhasesByProjectIds({ projectIds: [projectId] }),
    fetchPhaseTotals({
      projectId,
      initial: true,
      filterStateId
    }),
    fetchAllProjects({
      projectIds: [projectId]
    }),
    fetchProjectById(projectId),
    fetchProjectTeam(projectId),
    fetchTeamMembers()
  ];
};

/**
 * Filter out reasons that we don't want to show on the UI
 */
export const filterReasons = (reasons: string[]) => {
  return reasons.filter((reason) => !reasonsToNotShowOnUI.has(reason));
};

export const getAccountTotalsByMemberBudgetId = (
  phase: Phase | undefined,
  phaseTotals: PhaseTotals | undefined,
  memberBudgetId: number | undefined
): AccountTotals | undefined => {
  if (!memberBudgetId || !phase || !phaseTotals) return undefined;

  const hasActivityPhases = phase.activity_order.length > 0;
  const { activities = [] } = phaseTotals?.[phase.id] || {};
  // From observation, if a phase does not have activity phases, the first element in "activities" has activity_id = null
  // Each account in activities[0].accounts is a member/unassigned role that belongs to the phase

  // If a phase has activity phases, notice every element in "activities" will have activity_id = number
  // Each accounts in activities[index] is a member/unassigned role that belongs to the activity phase, under the parent phase

  // That is why we checked activities[0] if the phase does not have any activity phases
  const { accounts = [] } = hasActivityPhases ? {} : activities[0] || {};
  const accountTotals =
    accounts.find(
      (total: AccountTotals) => total.member_budget_id === memberBudgetId
    ) || {};

  return accountTotals;
};
