import {
  useActivitiesFilter,
  useBillableFilter,
  useMembersFilter,
  useMembersByDepartmentFilter,
  useMembersByPositionFilter,
  useMembersBySkillFilter,
  useMembersByOfficeFilter,
  useMembersByDisciplineFilter,
  useMembersByRegionFilter,
  useMembersByPortfolioFilter,
  useClientNamesFilter,
  usePhaseNamesFilter,
  useProjectsByPortfolioFilter,
  useDepartmentsFilter,
  useOfficesFilter,
  usePositionsFilter,
  useSkillsFilter,
  useSkillLevelsFilter,
  usePortfoliosFilter,
  useRegionsFilter,
  useProjectBudgetStatusFilter,
  usePhaseBudgetStatusFilter,
  useCustomFieldsFilter,
  useDisciplinesFilter,
  useEmploymentTypeFilter,
  useShowProjectsWithUnassignedRolesFilter,
  usePrioritiesFilter
} from 'FilterModule/hooks';
import { useProjectsFilter } from 'FilterModule/hooks/useProjectsFilter';
import { FilterField, FilterListType } from 'FilterModule/constants';
import { ConfigOptionHash } from 'FilterModule/types';
import { ValueOf } from 'type-fest';
import { useLeanApi } from 'LeanApiModule/utils';

export interface UseFilterDataParams {
  getIsFilterUsed: (filterType: FilterListType) => boolean;
  getFilterListTypeConfig?: (filterType: FilterListType) =>
    | ValueOf<
        ConfigOptionHash<
          unknown,
          {
            isSingleSelect?: boolean;
            crossFieldDependencies?: string[];
            filterLabel?: string;
          }
        >
      >
    | undefined;
  /**
   * For creating unique ids to be associated with api responses and fetch requests. Optional
   * since not all filters use isoState / require fetching, but should be provided when using filters that do
   */
  isoStateIdPrefix?: string;
  /**
   * For knowing the leanApi isoState to look at when the filters here are crossFieldDependencies
   * of that isoState. eg. the resultCountHash for that isoState will be used for determining
   * the count of results per filter items
   */
  resultsMetaData?: {
    isoStateId: string;
    filterListType: FilterListType;
  };
  filterListTypeToFieldOverrideHash?: Partial<
    Record<FilterListType, FilterField>
  >;
  /**
   * Indicates that filter changes should be saved to draftFilter instead of local (redux) filters
   */
  shouldUseDraft?: boolean;
}

export const useFilterData = ({
  getIsFilterUsed,
  getFilterListTypeConfig,
  isoStateIdPrefix,
  resultsMetaData,
  filterListTypeToFieldOverrideHash,
  shouldUseDraft
}: UseFilterDataParams) => {
  /**
   * For knowing the leanApi isoState to look at when the filters here are crossFieldDependencies
   * of that isoState. ie. the resultCountHash for that isoState will be used for determining
   * the count of results per filter items
   */
  const { getResultCountHash, isLoading: isResultsLoading } = useLeanApi({
    isoStateId: resultsMetaData?.isoStateId
  });

  const makeIsoStateId = (filterListType: FilterListType) =>
    `${isoStateIdPrefix}-${filterListType}`;

  const makeHookParams = (filterListType: FilterListType) => ({
    isOff: !getIsFilterUsed(filterListType),
    config: getFilterListTypeConfig
      ? getFilterListTypeConfig(filterListType)
      : undefined,
    resultCountHash: getResultCountHash(filterListType),
    isResultsLoading,
    shouldUseDraft,
    isoStateId: makeIsoStateId(filterListType),
    field: filterListTypeToFieldOverrideHash?.[filterListType]
  });

  const projectsFilterData = useProjectsFilter(
    makeHookParams(FilterListType.Projects)
  );

  const projectsByPortfolioFilterData = useProjectsByPortfolioFilter(
    makeHookParams(FilterListType.ProjectsByPortfolio)
  );

  const projectBudgetStatusFilterData = useProjectBudgetStatusFilter(
    makeHookParams(FilterListType.ProjectBudgetStatus)
  );

  const phaseNamesFilterData = usePhaseNamesFilter(
    makeHookParams(FilterListType.PhaseNames)
  );

  const phaseBudgetStatusFilterData = usePhaseBudgetStatusFilter(
    makeHookParams(FilterListType.PhaseBudgetStatus)
  );

  const activitiesFilterData = useActivitiesFilter(
    makeHookParams(FilterListType.ActivityIds)
  );

  const billableFilterData = useBillableFilter({
    isOff: !getIsFilterUsed(FilterListType.Billable),
    resultCountHash: getResultCountHash(FilterListType.Billable),
    field: filterListTypeToFieldOverrideHash?.[FilterListType.Billable],
    shouldUseDraft
  });

  const membersFilterData = useMembersFilter(
    makeHookParams(FilterListType.Members)
  );

  const membersByDepartmentFilterData = useMembersByDepartmentFilter(
    makeHookParams(FilterListType.MembersByDepartment)
  );

  const membersByPositionFilterData = useMembersByPositionFilter(
    makeHookParams(FilterListType.MembersByPosition)
  );

  const membersBySkillFilterData = useMembersBySkillFilter(
    makeHookParams(FilterListType.MembersBySkill)
  );

  const membersByOfficeFilterData = useMembersByOfficeFilter(
    makeHookParams(FilterListType.MembersByOffice)
  );

  const membersByDisciplineFilterData = useMembersByDisciplineFilter(
    makeHookParams(FilterListType.MembersByDiscipline)
  );

  const membersByRegionFilterData = useMembersByRegionFilter(
    makeHookParams(FilterListType.MembersByRegion)
  );

  const membersByPortfolioFilterData = useMembersByPortfolioFilter(
    makeHookParams(FilterListType.MembersByPortfolio)
  );

  const clientNamesFilterData = useClientNamesFilter(
    makeHookParams(FilterListType.ClientNames)
  );

  const departmentsFilterData = useDepartmentsFilter(
    makeHookParams(FilterListType.Departments)
  );

  const officesFilterData = useOfficesFilter(
    makeHookParams(FilterListType.Offices)
  );

  const regionsFilterData = useRegionsFilter(
    makeHookParams(FilterListType.Regions)
  );

  const disciplinesFilterData = useDisciplinesFilter(
    makeHookParams(FilterListType.Disciplines)
  );

  const positionsFilterData = usePositionsFilter(
    makeHookParams(FilterListType.Positions)
  );

  const skillsFilterData = useSkillsFilter(
    makeHookParams(FilterListType.Skills)
  );

  const skillLevelsFilterData = useSkillLevelsFilter(
    makeHookParams(FilterListType.SkillLevels)
  );

  const portfoliosFilterData = usePortfoliosFilter(
    makeHookParams(FilterListType.Portfolios)
  );

  const profitCenterFilterData = useCustomFieldsFilter({
    ...makeHookParams(FilterListType.ProfitCenter),
    field:
      filterListTypeToFieldOverrideHash?.[FilterListType.ProfitCenter] ||
      FilterField.profit_center
  });

  const employmentTypeFilterData = useEmploymentTypeFilter(
    makeHookParams(FilterListType.EmploymentType)
  );

  const prioritiesFilterData = usePrioritiesFilter(
    makeHookParams(FilterListType.Priorities)
  );

  const showProjectsWithUnassignedRolesFilterData =
    useShowProjectsWithUnassignedRolesFilter(
      makeHookParams(FilterListType.ShowProjectsWithUnassignedRoles)
    );

  const filterValuesHash = {
    [FilterListType.Projects]: projectsFilterData,
    [FilterListType.ProjectsByPortfolio]: projectsByPortfolioFilterData,
    [FilterListType.ProjectBudgetStatus]: projectBudgetStatusFilterData,
    [FilterListType.PhaseNames]: phaseNamesFilterData,
    [FilterListType.PhaseBudgetStatus]: phaseBudgetStatusFilterData,
    [FilterListType.ActivityIds]: activitiesFilterData,
    [FilterListType.Billable]: billableFilterData,
    [FilterListType.Members]: membersFilterData,
    [FilterListType.MembersByDepartment]: membersByDepartmentFilterData,
    [FilterListType.MembersByPosition]: membersByPositionFilterData,
    [FilterListType.MembersBySkill]: membersBySkillFilterData,
    [FilterListType.MembersByOffice]: membersByOfficeFilterData,
    [FilterListType.MembersByDiscipline]: membersByDisciplineFilterData,
    [FilterListType.MembersByRegion]: membersByRegionFilterData,
    [FilterListType.MembersByPortfolio]: membersByPortfolioFilterData,
    [FilterListType.ClientNames]: clientNamesFilterData,
    [FilterListType.Departments]: departmentsFilterData,
    [FilterListType.Offices]: officesFilterData,
    [FilterListType.Positions]: positionsFilterData,
    [FilterListType.Regions]: regionsFilterData,
    [FilterListType.Disciplines]: disciplinesFilterData,
    [FilterListType.Skills]: skillsFilterData,
    [FilterListType.SkillLevels]: skillLevelsFilterData,
    [FilterListType.Portfolios]: portfoliosFilterData,
    [FilterListType.ProfitCenter]: profitCenterFilterData,
    [FilterListType.EmploymentType]: employmentTypeFilterData,
    [FilterListType.ShowProjectsWithUnassignedRoles]:
      showProjectsWithUnassignedRolesFilterData,
    [FilterListType.Priorities]: prioritiesFilterData
  };

  return {
    filterValuesHash
  };
};
