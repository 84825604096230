import React from 'react';
import theme from 'theme';
import ModuleListItem from './ModuleListItem';
import AddModuleListItem from './AddModuleListItem';
import styled from 'styled-components';
import ProjectColumnsDropdown from 'views/projectDisplay/ProjectColumnsDropdown';

const StyledModuleList = styled.div`
  display: ${({ isNotDisplayed }) => (isNotDisplayed ? 'none' : 'flex')};
  .board-nav {
    display: flex;
    font-size: 15px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 0;
    justify-content: flex-start;
    /* padding-left needed to overwrite list styles */
    padding-left: 0;
    font-size: 16px;
    color: ${(props) => props.theme.colors.colorMediumGray5};

    :hover {
      cursor: pointer;
    }

    .selected {
      font-weight: bold;
      color: ${(props) => props.theme.colors.colorDarkBlue};
    }

    .selected:after {
      content: '';
      display: block;
      margin: auto;
      height: 2px;
      width: 100%;
      background: ${(props) => props.theme.colors.colorRoyalBlue};
    }

    li {
      padding: 0 20px 0 4px;
      color: ${(props) => props.theme.colors.colorMediumGray5};
    }

    li:after {
      content: '';
      display: block;
      margin: auto;
      height: 2px;
      width: 0px;
      background: transparent;
      transition: width 0.15s ease, background-color 0.15s ease;
    }

    li:hover:after {
      width: 100%;
      background: ${(props) => props.theme.colors.colorRoyalBlue};
    }

    #add-tab:after {
      background: none;
    }
    #add-tab {
      color: ${(props) => props.theme.colors.colorRoyalBlue};
    }
    #add-tab:hover {
      font-weight: 600;
    }
  }

  &.members-settings-tabs,
  &.permissions-settings-tabs {
    text-transform: uppercase;
    font-size: 15px;
    color: ${theme.colors.colorMediumGray5};
    .selected {
      color: ${theme.colors.colorMediumGray9};
    }
  }
`;

class ModuleList extends React.Component {
  state = {};

  renderModuleTab = (id) => {
    const { currentTab, changeTabs, tabCounts } = this.props;
    return (
      <ModuleListItem
        onClick={changeTabs}
        currentTab={currentTab}
        moduleId={id}
        key={id}
        tabCounts={tabCounts}
      />
    );
  };

  render() {
    const { installedModuleIds, viewType, listClassName } = this.props;
    return (
      <StyledModuleList
        className={
          'nav board-nav-list' + (listClassName ? ` ${listClassName}` : '')
        }
        isNotDisplayed={viewType === 'board'}
      >
        <ul className={`board-nav stacked`}>
          {installedModuleIds.map(this.renderModuleTab)}
          {viewType === 'project' && <ProjectColumnsDropdown show />}
          {viewType !== 'board' &&
            viewType !== 'members' &&
            viewType !== 'project' &&
            viewType !== 'membersSettings' &&
            viewType !== 'permissionsSettingsView' &&
            viewType !== 'standardSettings' &&
            viewType !== 'integrations' &&
            viewType !== 'workload' &&
            viewType !== 'budgetSettings' && (
              <AddModuleListItem moduleIds={installedModuleIds} />
            )}
        </ul>
      </StyledModuleList>
    );
  }
}

export default ModuleList;
