import { MouseEvent, createContext } from 'react';
import { ProjectUnassignedMemberBudgetWithPosition } from 'BudgetModule/hooks/useUnassignedRolesByProjectAndPhases';
import { Project } from 'ProjectsModule/models/project';
import noop from 'lodash/noop';
import { EntityRequirementsState } from 'RequirementsModule/reducers/entityRequirements';
import { getSelectedTeamId } from 'TeamsModule/selectors';
import { getTeamSlug } from 'selectors';
import { getFlatPhasesHash } from 'ProjectsModule/phases/selectors';
import { AssociationUpdatesInstance } from 'RequirementsModule/actionCreators/types';

export type OnDeleteRequirementArgs = {
  association_updates: AssociationUpdatesInstance[];
};

export type OnAddRequirementsClickArgs = {
  memberBudgetId: number;
  projectMembershipId: number;
};

export type FindPeopleModalContextValuesType = {
  project: Project | undefined;
  selectedProjectId: Nullable<number>;
  selectedUnassignedMemberBudgetId: Nullable<number>;
  numOfProjectUnassignedRoles: number;
  activeProjectPhasesOrder: number[];
  allProjectPhasesOrder: number[];
  isFetchingMemberSuggestionForPhase: boolean | undefined;
  showDemoSuggestions: boolean;
  phasesHash: ReturnType<typeof getFlatPhasesHash>;
  isRequirementsSelectorOpen: boolean;
  isFetchingEntityRequirements: boolean | undefined;
  isFetchingPhaseTotals: boolean | undefined;
  requirementAssociationsByProjectMembershipIdHash: EntityRequirementsState['requirementAssociationsByEntity']['entityRequirementType'];
  teamId: ReturnType<typeof getSelectedTeamId>;
  teamSlug: ReturnType<typeof getTeamSlug>;
  selectedUnassignedMemberBudget?: ProjectUnassignedMemberBudgetWithPosition;
  toggleRequirementsSelector: (booleanValue?: boolean) => void;
  handleSelectUnassignedRoleFromDropdown: (
    item: ProjectUnassignedMemberBudgetWithPosition
  ) => void;
  handleSelectUnassignedRole: ({
    memberBudgetId
  }: {
    memberBudgetId: number;
  }) => void;
  resetUnassignedRoleAndSelectedPhaseIds: () => void;
  triggerFetchMembersSuggestion: (args: {
    phaseIds: number[];
    memberBudgetId: Nullable<number>;
    shouldShowSkeletonLoader?: boolean;
  }) => void;
  onDeleteRequirement: ({
    association_updates
  }: OnDeleteRequirementArgs) => void;
  onAddRequirementsClick: ({
    memberBudgetId,
    projectMembershipId
  }: OnAddRequirementsClickArgs) => void;
  openBudgetModal: () => void;
  isProjectSelectorOpen: boolean;
  toggleIsProjectSelectorOpen: () => void;
};

export const FindPeopleModalContext =
  createContext<FindPeopleModalContextValuesType>({
    project: undefined,
    selectedProjectId: null,
    selectedUnassignedMemberBudgetId: null,
    numOfProjectUnassignedRoles: 0,
    activeProjectPhasesOrder: [],
    allProjectPhasesOrder: [],
    isFetchingMemberSuggestionForPhase: false,
    showDemoSuggestions: false,
    phasesHash: {},
    isRequirementsSelectorOpen: false,
    isFetchingEntityRequirements: false,
    isFetchingPhaseTotals: false,
    teamId: undefined,
    teamSlug: undefined,
    requirementAssociationsByProjectMembershipIdHash: {},
    selectedUnassignedMemberBudget: undefined,
    toggleRequirementsSelector: noop,
    handleSelectUnassignedRole: noop,
    handleSelectUnassignedRoleFromDropdown: noop,
    resetUnassignedRoleAndSelectedPhaseIds: noop,
    triggerFetchMembersSuggestion: noop,
    onDeleteRequirement: noop,
    onAddRequirementsClick: noop,
    openBudgetModal: noop,
    isProjectSelectorOpen: false,
    toggleIsProjectSelectorOpen: noop
  });
