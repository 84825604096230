import { createSelector } from 'reselect';
import {
  initialState as dashboardInitialState,
  initialFilterState as dashboardInitialFilterState
} from '../reducers/dashboard';
import { initialState as dashboardWidgetsInitialState } from '../reducers/dashboardWidgets';
import keyBy from 'lodash/keyBy';
import { getOnHomeView } from 'selectors';

const emptyArray = [];
const emptyObj = {};

const getOwnDashboardId = (state, ownProps) => ownProps.dashboardId;
const getOwnAccountId = (state, ownProps) => ownProps.accountId;
const getOwnCustomWidgetFilterMap = (state, ownProps) =>
  ownProps.customWidgetFilterMap ?? {};

export const getDashboardState = (state) =>
  state.dashboard || dashboardInitialState;

export const getDashboardWidgetsState = (state) =>
  state.dashboardWidgets || dashboardWidgetsInitialState;

export const getDashboardOrder = createSelector(
  getDashboardState,
  (state) => state.dashboardOrder
);
export const getDashboards = createSelector(
  getDashboardState,
  (state) => state.dashboards
);
export const getDashboardsLoaded = createSelector(
  getDashboardState,
  (state) => state.loaded
);
export const getDashboardFilterStates = createSelector(
  getDashboardState,
  (state) => state.filterStates
);
// accountId is used as filterStateId in fetch action
export const makeGetDashboardFilterStateByAccount = () =>
  createSelector(
    getDashboardFilterStates,
    getOwnAccountId,
    (state, accountId) => state[accountId] || dashboardInitialFilterState
  );
export const makeGetPersonalDashboardsLoadedByAccount = () =>
  createSelector(
    makeGetDashboardFilterStateByAccount(),
    (state) => state.loaded
  );
export const getOrderedDashboards = createSelector(
  getDashboardOrder,
  getDashboards,
  (ids, dashboards) => ids.map((id) => dashboards[id])
);

export const getIsAddWidgetModalOpen = createSelector(
  getDashboardState,
  (state) => state.isAddWidgetModalOpen
);
export const getWidgetDetailModalId = createSelector(
  getDashboardState,
  (state) => state.widgetDetailModalId
);

export const getWidgets = createSelector(
  getDashboardWidgetsState,
  (state) => state.widgets
);
export const getDashboardWidgets = createSelector(
  getDashboardWidgetsState,
  (state) => state.dashboardWidgets
);

export const getWidgetsByDashboard = getDashboardWidgets;

export const makeGetPersonalDashboardIdByAccountId = () =>
  createSelector(
    makeGetDashboardFilterStateByAccount(),
    (state) => state.selectedDashboardId
  );

export const makeGetPersonalDashboardByAccount = () =>
  createSelector(
    makeGetPersonalDashboardIdByAccountId(),
    getDashboards,
    (dashboardId, dashboardHash) => dashboardHash[dashboardId]
  );

export const makeGetWidgetsByAccountId = () =>
  createSelector(
    makeGetPersonalDashboardIdByAccountId(),
    getDashboardWidgets,
    (dashboardId, widgetsByDashboard) =>
      widgetsByDashboard[dashboardId] || emptyArray
  );

export const makeGetWidgetFilterIdsByAccountId = () =>
  createSelector(makeGetWidgetsByAccountId(), (widgets) =>
    widgets.map((widget) => widget.filter_id)
  );

export const getWidgetsByFilterId = createSelector(
  getDashboardWidgetsState,
  (state) => keyBy(state.widgets, (widget) => widget.filter_id)
);

export const getDashboardMemberships = createSelector(
  getDashboardState,
  (dashboards) => dashboards.membershipsByDashboard
);

export const getFilterIdsByWidgets = createSelector(
  getDashboardWidgets,
  getOwnDashboardId,
  (widgetsByDashboard, selectedDashboardId) => {
    const filterIds = [];
    const widgets = widgetsByDashboard[selectedDashboardId] || {};
    Object.keys(widgets).forEach((id) => filterIds.push(widgets[id].filter_id));
    return filterIds;
  }
);

export const getDashboardOwnerId = createSelector(
  getDashboards,
  getOwnDashboardId,
  (dashboards, selectedDashboardId) =>
    dashboards[selectedDashboardId]?.account_id
);

export const getAllMembershipsByDashboard = createSelector(
  getDashboardMemberships,
  getOwnDashboardId,
  (membershipsByDashboard, dashboardId) =>
    membershipsByDashboard[dashboardId] || []
);

export const getWidgetDetailModalType = createSelector(
  getDashboardState,
  (state) => state.widgetDetailModalType
);

export const makeGetWidgetDataLoadedStateByAccountId = () =>
  createSelector(
    getDashboardWidgetsState,
    getOwnAccountId,
    (state, accountId) =>
      state.widgetDataLoadedByAccountId[accountId] || emptyObj
  );

export const makeGetIsAllWidgetsDataLoaded = () =>
  createSelector(
    makeGetWidgetDataLoadedStateByAccountId(),
    makeGetWidgetsByAccountId(),
    getOwnAccountId,
    getOnHomeView,
    getOwnCustomWidgetFilterMap,
    (loadedState, widgets, accountId, isOnHome, customWidgetFilterMap) => {
      if (!widgets.length) {
        return false;
      }

      const widgetFilterMap = {
        'eventsWidget-personal': true,
        'ptoWidget-personal': true,
        'activityWidget-personal': isOnHome,
        'notificationsWidget-personal': !isOnHome,
        ...customWidgetFilterMap
      };

      // TO DO: Once events and PTO widgets are added, remove filtering
      const filteredWidget = widgets.filter(
        (widget) => !widgetFilterMap[widget.name]
      );

      return filteredWidget.every(
        (widget) =>
          loadedState[`${widget.name}-${accountId}` + (isOnHome ? '-home' : '')]
      );
    }
  );
