import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { TRAINING_DATA_REQUIRED_STRING } from 'CsvImportsModule/constants';
import { getRowValuesForEntityType } from './utils';

export const useMemberValidator = ({ entityType }) => {
  const accounts = useSelector((state) => state.csvImport.accounts);

  return useCallback((rows) => validateRows(rows, entityType), [entityType]);
};

/* ------------------------------------ - ----------------------------------- */

export const validateRows = (rows, entityType) => {
  return rows.map((row) => {
    const { enteredMemberName } = getRowValuesForEntityType(row, entityType);

    return (
      !!enteredMemberName && enteredMemberName !== TRAINING_DATA_REQUIRED_STRING
    );
  });
};
