import FloatHelpIcon from 'components/FloatingIconsWithTooltip/FloatHelpIcon';
import {
  Field,
  Label
} from 'SettingsModule/components/StandardSettings/General/styles';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import theme from 'theme';

export const FullTimeEquivalentSetting = () => {
  return (
    <>
      <Label
        data-for="app-tooltip"
        data-tip="Mosaic uses a weekly full-time equivalent<br/>
            hours for understanding demand<br/>
            and hiring requirements."
        data-effect="solid"
        data-html
        data-class="center"
      >
        Full-time Equivalent
        <FloatHelpIcon width={8} height={8} />
      </Label>
      <Field>
        <HoursInputContainer>
          <HoursInput
            decimalScale={2}
            allowNegative={false}
            isNumericString
            suffix="h"
          />
          = 1 Week
        </HoursInputContainer>
      </Field>
    </>
  );
};

const HoursInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HoursInput = styled(NumberFormat)`
  width: 53px;
  height: 30px;
  border: 1px solid ${theme.colors.colorLightGray12};
  margin-right: 8px;
  &:focus {
    border: 1px solid ${theme.colors.colorRoyalBlue};
  }
  text-align: center;
`;
