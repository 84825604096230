import theme from 'theme';
import HomeTasksIcon from 'icons/HomeTasksIcon';
import DeleteIcon from 'icons/DeleteIcon';
import DeleteXIcon from 'icons/DeleteXIcon';
import { TaskCard } from './TaskCard';
import MatchIcon from 'icons/MatchIcon';
import { TaskWithPlanOverlap } from '../../redux/types';
import { useProjectPermissionState } from 'PermissionsModule/SpaceLevelPermissions/hooks/useProjectPermissionState';
import { DELETE_TASK_TIP } from 'PermissionsModule/SpaceLevelPermissions/constants';
import { ReactComponent as PlayIcon } from 'icons/play.svg';
import { ReactComponent as StopIcon } from 'icons/stop.svg';
import { useTimer } from 'appUtils/hooks/useTimer';

interface DisabledOptionProps {
  disabled?: boolean;
  tooltip?: string;
  disabledTooltip?: string;
}

// deleteTask is handled by the TaskListItem
type TaskMenuKeys =
  | 'markComplete'
  | 'matchWorkPlanDates'
  | 'toggleTimer'
  | 'removeFromWorkPlan';

interface TaskListItemProps {
  task: TaskWithPlanOverlap;
  handleClickTask: (task: TaskWithPlanOverlap) => void;
  handleMarkComplete: (task: TaskWithPlanOverlap) => void;
  handleMatchWorkPlanDates: (task: TaskWithPlanOverlap) => void;
  handleRemoveTask: (task: TaskWithPlanOverlap) => void;
  handleDeleteTask: (task: TaskWithPlanOverlap) => void;
  disabledOptions?: Partial<Record<TaskMenuKeys, DisabledOptionProps>>;
}

export const TaskListItem = ({
  task,
  handleClickTask,
  handleMarkComplete,
  handleMatchWorkPlanDates,
  handleRemoveTask,
  handleDeleteTask,
  disabledOptions = {}
}: TaskListItemProps) => {
  const { project_id: projectId } = task;
  const { canDeleteTasks } = useProjectPermissionState({ projectId });
  const { toggleTimer, getIsTaskActiveTimer } = useTimer();

  const isTaskActiveTimer = getIsTaskActiveTimer(task.id);

  return (
    <TaskCard
      key={task.id}
      task={task}
      onClick={handleClickTask}
      menu={[
        {
          icon: (
            <HomeTasksIcon
              currentColor={theme.colors.colorMediumGray9}
              height={16}
              width={16}
              className={undefined}
            />
          ),
          label: (task) =>
            task.completed_at ? 'Mark Incompleted' : 'Mark Completed',
          onClick: handleMarkComplete,
          isDisabled: disabledOptions.markComplete?.disabled,
          menuItemTooltip: disabledOptions.markComplete?.disabled
            ? disabledOptions.markComplete?.disabledTooltip
            : disabledOptions.markComplete?.tooltip
        },
        {
          icon: <MatchIcon color={theme.colors.colorMediumGray9} />,
          label: 'Match Work Plan Dates',
          onClick: handleMatchWorkPlanDates,
          isDisabled: disabledOptions.matchWorkPlanDates?.disabled,
          menuItemTooltip: disabledOptions.matchWorkPlanDates?.disabled
            ? disabledOptions.matchWorkPlanDates?.disabledTooltip
            : disabledOptions.matchWorkPlanDates?.tooltip
        },
        {
          icon: isTaskActiveTimer ? <StopIcon /> : <PlayIcon />,
          label: isTaskActiveTimer ? 'Stop Timer' : 'Start Timer',
          onClick: toggleTimer,
          isDisabled: disabledOptions.toggleTimer?.disabled,
          menuItemTooltip: disabledOptions.toggleTimer?.disabled
            ? disabledOptions.toggleTimer?.disabledTooltip
            : disabledOptions.toggleTimer?.tooltip
        },
        {
          icon: (
            <DeleteXIcon
              color={theme.colors.colorMediumGray9}
              className={undefined}
            />
          ),
          label: 'Remove from Plan',
          onClick: handleRemoveTask,
          isDisabled: disabledOptions.removeFromWorkPlan?.disabled,
          menuItemTooltip: disabledOptions.removeFromWorkPlan?.disabled
            ? disabledOptions.removeFromWorkPlan?.disabledTooltip
            : disabledOptions.removeFromWorkPlan?.tooltip
        },
        {
          icon: <DeleteIcon />,
          label: 'Delete Task',
          onClick: handleDeleteTask,
          isDisabled: !canDeleteTasks,
          menuItemTooltip: !canDeleteTasks ? DELETE_TASK_TIP : undefined
        }
      ]}
    />
  );
};
