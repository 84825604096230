import React from 'react';
import SvgIcon from 'components/SvgIcon';

const NavLeftArrow = ({ className, height = '18px', width = '19px' }) => (
  <SvgIcon
    width={width}
    height={height}
    viewBox="0 0 19 18"
    fill="none"
    className={className}
  >
    <path
      d="M9.34949 16.45L1.54949 8.64995L9.34949 0.849951"
      stroke="#4F4F4F"
      strokeWidth="2"
    />
    <path
      d="M18.4495 8.64966L1.54946 8.64966"
      stroke="#4F4F4F"
      strokeWidth="2"
    />
  </SvgIcon>
);

export default NavLeftArrow;
