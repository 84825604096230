import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as appModules from 'appUtils/appModuleUtils';
import * as boardModules from 'appUtils/boardModuleUtils';
import * as membersModules from 'appUtils/membersModuleUtils';
import * as membersSettingsModules from 'appUtils/membersSettingsModuleUtils';
import * as standardSettingsModules from 'appUtils/standardSettingsModuleUtils';
import * as integrationsModules from 'appUtils/integrationsModuleUtils';
import * as workloadModules from 'appUtils/workloadModuleUtils';
import {
  getMatchedRouteParams,
  getIsOnProjectView,
  getOnBoardView,
  getIsOnIntegrations,
  getOnWorkloadView,
  getIsOnStandardSettingsView,
  getIsOnMembersSettingsView,
  getSplitFlags
} from 'selectors';
import { permissionsModuleConstants } from 'appCore/navigation/permissions/constants';
import { getIsOnPermissionsSettingsView } from 'appCore/navigation/permissions/selectors';
import { getIsOnBudgetSettingsView } from 'SettingsModule/selectors/budgetSettings';
import { budgetSettingsModuleConstants } from 'SettingsModule/components/BudgetSettings/constants';

const ModuleListItem = ({
  currentTab,
  moduleId,
  onClick,
  appModule,
  moduleText,
  tabCounts,
  flagIsOff
}) => {
  if (!appModule || !moduleText || flagIsOff) {
    return null;
  }
  return (
    <li
      className={currentTab === appModule ? 'isActive selected' : 'clickable'}
      onClick={onClick(appModule)}
      id={`${appModule}-tab`}
    >
      {tabCounts?.[appModule]
        ? moduleText + ` (${tabCounts[appModule]})`
        : moduleText}
    </li>
  );
};

const mapStateToProps = (state, ownProps) => {
  const matchedParams = getMatchedRouteParams(state);
  const isOnProjectView = getIsOnProjectView(state);
  const isOnBoardView = getOnBoardView(state);
  const isOnMembersSettings = getIsOnMembersSettingsView(state);
  const isOnPermissionsSettings = getIsOnPermissionsSettingsView(state);
  const isOnStandardSettings = getIsOnStandardSettingsView(state);
  const isOnIntegrations = getIsOnIntegrations(state);
  const isOnWorkload = getOnWorkloadView(state);
  const isOnBudgetSettingsView = getIsOnBudgetSettingsView(state);
  const modules = isOnIntegrations
    ? integrationsModules
    : isOnMembersSettings
    ? membersSettingsModules
    : isOnPermissionsSettings
    ? permissionsModuleConstants
    : isOnStandardSettings
    ? standardSettingsModules
    : isOnProjectView
    ? appModules
    : isOnBoardView
    ? boardModules
    : isOnWorkload
    ? workloadModules
    : isOnBudgetSettingsView
    ? budgetSettingsModuleConstants
    : membersModules;
  const splitFlags = getSplitFlags(state);
  const flag = modules.moduleToFlagHash?.[ownProps.moduleId];
  const flagIsOff = flag !== undefined && !splitFlags[flag];

  return {
    appModule: modules.moduleHash[ownProps.moduleId],
    moduleText: modules.moduleTextHash[ownProps.moduleId],
    matchedParams,
    flagIsOff
  };
};

export default withRouter(connect(mapStateToProps)(ModuleListItem));
