import { createSelector } from 'reselect';
import { extendMoment } from 'moment-range';
import Moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import sum from 'lodash/sum';
import flatten from 'lodash/flatten';

import { initialState as workloadInitialState } from 'reducers/workload';
import { initialState as workloadPlannerBarModalInitialState } from 'reducers/workloadPlannerBarModal';
import {
  isOnTeamMemberProfile,
  getOnWorkloadView,
  getIsOnScheduleView,
  getIsOnTasksView
} from './navigations';
import {
  getIsOnPhaseSplitScreen,
  getIsOnWorkloadSplitScreen
} from 'appCore/navigation/selectors';
import { getTeamsState, getAuth, getSplitFlags } from './core';
import { getTeamMembersHash, getAllTeamMembers } from './team';
import {
  getSelectedProject,
  getOOOProject,
  getProjectHash,
  getWFHProject
} from './projects';
import {
  getPhasesAndMilestonesByProjectHash,
  getPhaseTotals,
  getFlatPhasesHash,
  getFlatPhases,
  getFlatPhasesAndMilestones
} from './phases';
import {
  getPlannerModalDates,
  getAllActivityRowInfo,
  getMemberBudgetsByProjectIdWithUnassigned,
  getMemberBudgets
} from './timesheet';
import { getPlannerMembersInfo } from './plannerMembersInfo';
import {
  VIEW_BY,
  CAPACITY_TYPES,
  ZOOM_TO_STEP_VALUES,
  CAPACITY_DATE_KEYS,
  ZOOM_LEVELS,
  VIEW_TYPE,
  SPLIT_SCREEN_TYPES
} from 'appConstants/workload';
import {
  getScheduleTimelineTasks,
  getProjectTimelineTasks,
  getPlannerTimelineTasks,
  getPlannerWorkCategories,
  getPlannerSplitScreenActive,
  getWorkloadSplitScreenActive,
  getPlannerSplitScreenProjectId,
  getWorkloadSplitScreenAccountId,
  getWorkloadSplitScreenType,
  getPlannerSplitScreenType
} from './timelines';
import {
  makeGetActiveWorkloadPlannerFilterIdHashes,
  makeGetActiveWorkloadPlannerFilter
} from './filters';
import { serializeBar } from 'appUtils/projectPlannerUtils';
import { getRemainingWorkDays, formatTotals } from 'appUtils/phaseDisplayUtils';
import {
  formatPlannerScheduleBars,
  formatWorkplanSplitScreenBars,
  filterBarsByDateRange,
  accumulateWeekCommitment,
  fitToScaleEnd,
  fitToScaleStart
} from 'appUtils/workplanDisplayUtils';

import { createDeepEqualSelector } from 'appUtils/createDeepEqualSelector';

import RootCell from 'views/projectPlanner/PersonalWorkloadPlanner/Rows/RootCell';
import { getMyWorkPlanSettings } from './timelineSettings';

const moment = extendMoment(Moment);

const emptyObj = {};
const emptyArray = [];

const summaryRow = {
  account: { id: 'summary' },
  account_id: `summary--summary`,
  isSummary: true
};

const plannerSkeletonLoaderRows = [
  {
    isRoot: true,
    account_id: `root--1`,
    isLoading: true
  },
  {
    isRoot: true,
    account_id: `root--2`,
    isLoading: true
  },
  {
    isRoot: true,
    account_id: `root--3`,
    isLoading: true
  }
];

const workloadSkeletonLoaderRows = [
  { ...summaryRow, isLoading: true },
  {
    isRoot: true,
    account_id: `root--1`,
    isLoading: true
  },
  {
    isRoot: true,
    account_id: `root--2`,
    isLoading: true
  },
  {
    isRoot: true,
    account_id: `root--3`,
    isLoading: true
  }
];

// todo fix import loop
const getAllGroups = (state) => state.groups.groupList;

export const getWorkloadViewType = (state) =>
  state.workloadPlannerFilter.viewType;
export const getCondensedZoomLevel = (state) =>
  state.workloadPlannerFilter.condensedZoomLevel;
export const getWorkloadViewBy = (state) => state.workloadPlannerFilter.viewBy;
export const getScheduleViewBy = (state) =>
  state.workloadPlannerFilter.scheduleViewBy;
export const getTimesheetViewBy = (state, ownProps) =>
  ownProps?.viewBy || state.workloadPlannerFilter.timesheetViewBy;
export const getWorkloadSettings = (state, ownProps) =>
  ownProps?.workloadSettings;

export const getTimesheetIsWeek = (state, ownProps) =>
  ownProps?.isWeek ??
  (state.workloadPlannerFilter.timesheetIsWeek === true ||
    state.workloadPlannerFilter.timesheetIsWeek === 'true');

const getGroupsHash = createSelector(getAllGroups, (groups) =>
  keyBy(groups, (item) => item.id)
);

const getPlannerType = (state, ownProps) => ownProps.plannerType;

export const getPlannerMemberIds = (state) =>
  state.users.me && state.users.me.team_planner_members_order
    ? state.users.me.team_planner_members_order
    : emptyArray;

export const getPlannerModalAccountId = createSelector(
  getTeamsState,
  getAuth,
  (teamsState, auth) =>
    teamsState.selectedAccountId || (auth.account && auth.account.id)
);

// Returns list of projects that this member can schedule for
export const getPlannerProjectsByMember = createSelector(
  getPlannerModalAccountId,
  getProjectHash,
  getPlannerMembersInfo,
  isOnTeamMemberProfile,
  (accountId, projectsHash, plannerMembers, onProfile) => {
    const projectIds = plannerMembers[accountId] || [];
    const projects = projectIds.map((id) => projectsHash[id]);
    return onProfile
      ? projects.filter((project) => !project.is_personal)
      : projects;
  }
);

export const getPlannerMembers = createSelector(
  getTeamMembersHash,
  (teamMembersHash) => {
    const plannerMembers = Object.keys(teamMembersHash).reduce((acc, id) => {
      const teamMember = teamMembersHash[id];

      if (teamMember) {
        const accountExtractedMember = {
          ...teamMember,
          account_id: teamMember.account.id,
          name: teamMember.account.name,
          initials: teamMember.account.initials
        };
        acc[id] = accountExtractedMember;
      }
      return acc;
    }, {});
    return plannerMembers;
  }
);

export const getPlannerMembersArray = createSelector(
  getPlannerMembers,
  (membersObj) =>
    !isEmpty(membersObj) ? Object.values(membersObj) : emptyArray
);
export const getPlannerMembersCount = createSelector(
  getPlannerMembersArray,
  (members) => members.length
);

export const getWorkloadFilter = (state) => state.workloadPlannerFilter.filter;
export const getZoom = (state, ownProps) =>
  state.workloadPlanner.zoom[ownProps.plannerType];
export const getVisibleTimeStart = (state, ownProps) =>
  state.workloadPlanner.visibleTimes[ownProps.plannerType].visibleTimeStart;
export const getVisibleTimeEnd = (state, ownProps) =>
  state.workloadPlanner.visibleTimes[ownProps.plannerType].visibleTimeEnd;

export const makeGetFilteredMembersArray = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    makeGetActiveWorkloadPlannerFilterIdHashes(),
    getPlannerMembersArray,
    getPlannerSplitScreenProjectId,
    getProjectHash,
    getWorkloadViewBy,
    (
      filterIds,
      filterIdHash,
      members,
      groupId,
      projectHash,
      workloadViewBy
    ) => {
      if (
        groupId &&
        projectHash[groupId]?.member_account_ids &&
        workloadViewBy === VIEW_BY.PROJECTS
      ) {
        const memberAccountIdHash = keyBy(
          projectHash[groupId]?.member_account_ids
        );
        return members?.filter(
          (member) => memberAccountIdHash[member.account.id]
        );
      }
      if (
        workloadViewBy === VIEW_BY.MEMBERS &&
        filterIds.account_ids.length > 0 &&
        !!filterIds.custom.sort
      ) {
        const filteredMembers = members?.filter(
          (member) => filterIdHash.account_ids[member.account.id]
        );
        const filteredMembersHash = {};
        filteredMembers.forEach((member) => {
          filteredMembersHash[member.account_id] = member;
        });
        return filterIds.account_ids
          .map((accountId) => filteredMembersHash[accountId])
          .filter((member) => member);
      }

      return (
        (filterIdHash?.account_ids &&
          members?.filter(
            (member) => filterIdHash.account_ids[member.account.id]
          )) ||
        members
      );
    }
  );
export const getFilteredMembersArray = makeGetFilteredMembersArray();
export const makeGetFilteredMembersIds = () =>
  createSelector(makeGetFilteredMembersArray(), (members) =>
    members.map((member) => member.account.id)
  );
export const getFilteredMembersIds = makeGetFilteredMembersIds();

export const getPlannerOpenMembers = (state) =>
  state.workloadPlanner.openMembers;
export const getPlannerOpenProjects = (state) =>
  state.workloadPlanner.openProjects;

export const getPlannerAccountIdsOnOpenProjects = createSelector(
  getPlannerOpenProjects,
  getProjectHash,
  (openProjects, projectHash) =>
    uniq(
      Object.entries(openProjects).reduce((acc, [id, open]) => {
        if (!open) return acc;
        const project = projectHash[id];
        if (!project) return acc;
        return [...acc, ...project.member_account_ids];
      }, [])
    )
);

export const makeGetPlannerAccountIdsOnOpenMembers = () =>
  createSelector(
    getMyWorkPlanSettings,
    makeGetFilteredMembersIds(),
    getPlannerOpenMembers,
    (workPlanSettings, accountIds, openMembers) => {
      if (workPlanSettings?.show_capacity_heat_map) {
        return Object.entries(openMembers).flatMap(([id, open]) =>
          open ? [id] : []
        );
      }
      // if show_capacity_heat_map is false, then it shows all member rows as open
      return accountIds;
    }
  );

const personalPlannerCustomBottomRows = [
  {
    rowType: 'root',
    CustomRootRenderer: RootCell
  }
];

const createDemoSuggestion = ({
  accountId,
  accountCapacity,
  accountUtilizations,
  startDate,
  endDate
}) => {
  let maxFill = 1000;
  let suggestionStartDate = null;
  let currentStartDate = null;
  let currentEndDate = null;
  let totalDays = 0;
  const subBars = [];

  for (
    let date = startDate.clone();
    date.isBefore(startDate.clone().endOf('week').add(1, 'week'));
    date.add(1, 'days')
  ) {
    const dayOfWeek = date.format('dddd').toLowerCase();
    const formattedDate = date.format('YYYY-MM-DD');
    if (!['saturday', 'sunday'].includes(dayOfWeek)) {
      const capacityRemaining =
        accountCapacity?.[dayOfWeek] - accountUtilizations?.[formattedDate];
      if (capacityRemaining > 0) {
        maxFill = Math.min(capacityRemaining, maxFill);
        totalDays++;
        if (!suggestionStartDate) {
          suggestionStartDate = date.format('MM/DD/YYYY');
        }
        if (!currentStartDate) {
          currentStartDate = formattedDate;
        }
        currentEndDate = formattedDate;
        if (dayOfWeek === 'friday') {
          subBars.push({
            start_date: currentStartDate,
            end_date: currentEndDate,
            day_count: date.diff(moment(currentStartDate), 'days') + 1
          });
          currentStartDate = null;
          currentEndDate = null;
        }
      } else if (suggestionStartDate) {
        break;
      }
    }
  }

  if (currentStartDate) {
    subBars.push({
      start_date: currentStartDate,
      end_date: currentEndDate,
      day_count:
        moment(currentEndDate).diff(moment(currentStartDate), 'days') + 1
    });
  }

  const daily_hours = maxFill.toFixed(2);

  if (subBars.length && daily_hours > 0) {
    return {
      account_id: accountId,
      all_day: false,
      bars: subBars,
      daily_hours,
      description: '',
      end_date: moment(subBars[subBars.length - 1].end_date).format(
        'MM/DD/YYYY'
      ),
      include_holidays: false,
      include_weekends: false,
      start_date: suggestionStartDate,
      total_hours: (daily_hours * totalDays).toFixed(1)
    };
  }
};

const getAccountCapacities = (state) =>
  state.accountCapacities?.accountCapacities ?? emptyObj;

export const getFormattedPlannerRows = createSelector(
  makeGetActiveWorkloadPlannerFilter(),
  getFilteredMembersArray,
  getPlannerOpenMembers,
  getAccountCapacities,
  (state) => state.workloadEvents.lastSent,
  (state) => state.utilizations?.utilizations,
  getPlannerType,
  getSplitFlags,
  getOnWorkloadView,
  getWorkloadSettings,
  getWorkloadViewBy,
  getIsOnScheduleView,
  (state) => state.workloadPlanner.isLoading,
  getIsOnWorkloadSplitScreen,
  (
    activeFilter,
    plannerMembers,
    openWorkloadMemberRows,
    accountCapacities,
    lastSent,
    utilizations,
    plannerType,
    splitFlags,
    isOnWorkloadView,
    workloadSettings,
    viewBy,
    isOnScheduleView,
    isPlannerLoading,
    isOnWorkloadSplitScreen
  ) => {
    let customBottomRows, noSummary, noRoot, defaultIsOpen;

    // quick fix for personal work planner (on home and member modal)
    // previously these values were coming in as ownProps but it was breaking trackpad scroll
    const hideHeatMap =
      (workloadSettings &&
        workloadSettings.show_capacity_heat_map !== undefined &&
        !workloadSettings.show_capacity_heat_map &&
        viewBy !== VIEW_BY.PROJECTS) ||
      plannerType === 'memberModal';
    if ((!isOnWorkloadView && !isOnScheduleView) || hideHeatMap) {
      customBottomRows =
        (!isOnWorkloadView && !isOnScheduleView) ||
        plannerType === 'memberModal'
          ? personalPlannerCustomBottomRows
          : [];
      noSummary = true;
      noRoot = true;
      defaultIsOpen = true;
    }

    if (isPlannerLoading && plannerMembers.length === 0) {
      return workloadSkeletonLoaderRows;
    }

    if (isOnWorkloadSplitScreen) {
      noSummary = true;
    }

    const showWorkloadSuggestions = splitFlags?.workloadSuggestions;
    const todayIsWeekend = [6, 0].includes(moment().day());
    const formattedRows = plannerMembers
      .slice()
      .sort((a, b) =>
        activeFilter.custom.sort
          ? 1
          : a?.name?.toLowerCase() > b?.name?.toLowerCase()
          ? 1
          : -1
      )
      .reduce(
        (acc, cur) => {
          if (!cur) {
            return acc;
          }
          const isOpen = isOnWorkloadSplitScreen
            ? true
            : hideHeatMap ||
              (openWorkloadMemberRows[cur.account_id] !== undefined
                ? openWorkloadMemberRows[cur.account_id]
                : defaultIsOpen);
          const accountCapacity = accountCapacities?.[cur.account_id];
          const nonZeroCapacities = CAPACITY_DATE_KEYS.map(
            (dateKey) => accountCapacity?.[dateKey]
          ).filter((capacity) => +capacity);

          const accountLastSent = lastSent[cur.account_id];
          const averageCapacity = nonZeroCapacities.length
            ? sum(nonZeroCapacities) / nonZeroCapacities.length
            : 8;

          const accountUtilizations = utilizations?.[cur.account_id];

          const isDemo = process.env.UI_ENV === 'demo';
          const suggestion = isDemo
            ? createDemoSuggestion({
                accountId: cur.account_id,
                startDate: todayIsWeekend
                  ? moment().startOf('week').add(1, 'week')
                  : moment(),
                accountCapacity,
                accountUtilizations
              }) || emptyObj
            : emptyObj;

          const rootRow = {
            ...cur,
            averageCapacity,
            accountCapacity,
            accountLastSent,
            account_id: `root--${cur.account_id}`,
            accountId: cur.account_id,
            isRoot: true,
            isOpen,
            suggestion
          };

          const rowHash = {
            root: rootRow
          };

          if (!noRoot) {
            acc.push(rootRow);
          }

          if (isOpen) {
            acc.push({
              ...cur,
              accountId: cur.account_id
            });
            if (!hideHeatMap)
              acc.push({
                ...cur,
                account_id: `bottom--${cur.account_id}`,
                accountId: cur.account_id,
                isBottom: true,
                isOpen
              });
          }
          if (customBottomRows)
            acc.push(
              ...customBottomRows
                .map((row) => ({ ...rowHash[row.rowType], ...row }))
                .filter((row) => row)
            );
          return acc;
        },
        [...(!noSummary ? [summaryRow] : [])]
      );
    return formattedRows;
  }
);

export const getAllMemberRowsOpen = createSelector(
  getFilteredMembersArray,
  getPlannerOpenMembers,
  (plannerMembers, openWorkloadMemberRows) => {
    return plannerMembers.every(
      (member) => openWorkloadMemberRows[member.account.id]
    );
  }
);

export const getNonPlannerMembers = createSelector(
  getAllTeamMembers,
  getPlannerMemberIds,
  (allTeamMembers, plannerMemberIds) => {
    const allTeamMemberIds = allTeamMembers.map(
      (membersObj) => membersObj.account.id
    );
    const nonPlannerMemberIds = allTeamMemberIds.filter(
      (id) => !plannerMemberIds.includes(id)
    );
    const nonPlannerMembers = nonPlannerMemberIds.reduce((acc, cur) => {
      const teamMember = allTeamMembers.find(
        (teamMember) => teamMember.account.id === cur
      );
      if (teamMember) {
        acc[cur] = {
          ...teamMember,
          account_id: teamMember.account.id,
          name: teamMember.account.name,
          initials: teamMember.account.initials
        };
      } else {
        acc[cur] = teamMember;
      }
      return acc;
    }, {});
    return nonPlannerMembers;
  }
);

export const getWorkloadState = (state) =>
  state.workload || workloadInitialState;

export const getWorkloadModalCapacityId = createSelector(
  getWorkloadState,
  (state) => state.modalCapacityId
);
export const getTeamWorkloadModalIsOpen = createSelector(
  getWorkloadState,
  (state) => state.isOpen && state.modalCapacityType === CAPACITY_TYPES.TEAM
);
export const getAccountWorkloadModalIsOpen = createSelector(
  getWorkloadState,
  (state) => state.isOpen && state.modalCapacityType === CAPACITY_TYPES.ACCOUNT
);

export const getWorkloadPlannerBarModalState = (state) =>
  state.workloadPlannerBarModal || workloadPlannerBarModalInitialState;

export const getSuggestedBar = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.suggestedBar
);

export const getShouldPredictWithInitialValue = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.shouldPredictWithInitialValue
);

export const getWorkloadPlannerBarModalIsOpen = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.isOpen
);
export const getWorkloadPlannerBarIsNew = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.isNew
);
export const getWorkloadPlannerBarModalIsRequest = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.isRequest
);
export const getWorkloadPlannerBarModalWorkplanStateId = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.workplanStateId
);
export const getWorkloadPlannerBarModalParentGroupId = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.parentGroupId
);
export const getWorkloadPlannerBarModalGroupAttribute = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.groupAttribute
);

export const getWorkloadPlannerBarModalBudgetTotals = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.budgetTotals
);

export const getWorkloadPlannerBarId = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.barId
);

export const getPlannerSchedules = (state) =>
  state.workloadPlanner.scheduleBars;

export const getWorkloadPlannerBarModalBar = createSelector(
  getWorkloadPlannerBarId,
  getPlannerSchedules,
  (id, schedules) => schedules && schedules[id]
);

export const getWorkloadPlannerBarDate = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.date
);

export const getWorkloadPlannerBarAccountId = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.accountId
);
export const getWorkloadPlannerBarProjectId = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.projectId
);
export const getWorkloadPlannerBarMemberBudgetId = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.memberBudgetId
);
export const getCalendarPrediction = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.calendarPrediction
);
export const getPredictedBar = createSelector(
  getWorkloadPlannerBarModalState,
  (state) => state.predictedBar
);

export const getNonPlannerMembersArray = createSelector(
  getNonPlannerMembers,
  (membersObj) => Object.values(membersObj)
);

export const getPlannerProjectsArray = createSelector(
  getProjectHash,
  (projects) => Object.values(projects)
);
export const getPlannerProjectsCount = createSelector(
  getPlannerProjectsArray,
  (projects) => projects.length
);

export const getFilteredProjectsArray = createSelector(
  makeGetActiveWorkloadPlannerFilter(),
  getPlannerProjectsArray,
  getProjectHash,
  getPlannerSplitScreenProjectId,
  getPlannerSplitScreenActive,
  getWorkloadViewBy,
  (
    filter,
    projects,
    projectHash,
    splitScreenProjectId,
    plannerSplitScreenActive,
    workloadViewBy
  ) => {
    if (plannerSplitScreenActive && workloadViewBy === VIEW_BY.PROJECTS) {
      return projectHash[splitScreenProjectId]
        ? [projectHash[splitScreenProjectId]]
        : emptyArray;
    }
    return (
      (filter?.project_ids?.length &&
        projects &&
        filter.project_ids
          .map((projectId) => projectHash[projectId])
          .filter((id) => id)) ||
      emptyArray
    );
  }
);
export const getFilterProjectsIds = createSelector(
  getFilteredProjectsArray,
  (projects) => projects.map((project) => project.id)
);

export const getAllProjectRowsOpen = createSelector(
  getFilterProjectsIds,
  getPlannerOpenProjects,
  (plannerProjectIds, openWorkloadProjectRows) => {
    return plannerProjectIds.every(
      (projectId) => openWorkloadProjectRows[projectId]
    );
  }
);

export const getOpenMembersByProject = (state) =>
  state.workloadPlanner.openMembersByProject;

export const getFormattedPlannerRowsByProject = createSelector(
  getFilteredProjectsArray,
  getPlannerOpenProjects,
  getIsOnTasksView,
  getGroupsHash,
  getPlannerMembers,
  getOpenMembersByProject,
  getPlannerSplitScreenActive,
  getPlannerSplitScreenProjectId,
  getMemberBudgetsByProjectIdWithUnassigned,
  getAccountCapacities,
  (
    projects,
    openWorkloadProjectRows,
    isOnTasksView,
    boards,
    plannerMembers,
    openMembersByProject,
    plannerSplitScreenActive,
    splitScreenProjectId,
    memberBudgetsByProjectId,
    accountCapacities
  ) =>
    projects.reduce((acc, cur) => {
      if (!cur) {
        return acc;
      }
      const isActiveSplitScreenProject = cur.id === splitScreenProjectId;
      const isOpen =
        openWorkloadProjectRows[cur.id] || isActiveSplitScreenProject;

      if (plannerSplitScreenActive && !isActiveSplitScreenProject) {
        return acc;
      }
      acc.push({
        ...cur,
        board: boards[cur.board_id],
        board_name: boards[cur.board_id]?.name,
        project_id: cur.id,
        projectId: cur.id,
        id: `root--${cur.id}`,
        project_view_group_key: `root--${cur.id}`,
        isRoot: true,
        isOpen
      });
      if (isOpen) {
        const openProjectMembers = !isOnTasksView
          ? openMembersByProject[cur.id] || {}
          : {};
        const projectMemberBudgets = !isOnTasksView
          ? memberBudgetsByProjectId[cur.id] || []
          : [];
        projectMemberBudgets.forEach((memberBudget) => {
          const isMember = memberBudget.account_id !== null;
          const isMemberDiscarded =
            !!memberBudget.project_membership?.discarded_at;
          const accountId = isMember
            ? memberBudget.project_membership?.account_id
            : null;
          const isMemberOpen = openProjectMembers[accountId];
          const member = plannerMembers[accountId];
          const accountCapacity = accountCapacities?.[accountId];
          if (member && !isMemberDiscarded) {
            acc.push({
              ...member,
              accountCapacity,
              member_budget_id: memberBudget.id,
              account_id: `root--${accountId}`,
              accountId: accountId,
              projectId: cur.id,
              isOpen: isMemberOpen,
              isAccountRoot: true,
              // should match with item_project_view_group_key
              project_view_group_key: memberBudget.id
            });
            if (isMemberOpen) {
              acc.push({
                ...member,
                member_budget_id: memberBudget.id,
                account_id: `account--${accountId}`,
                accountId: accountId,
                projectId: cur.id,
                // isAccountRoot: true,
                project_view_group_key: `account--${member.account_id}--project--${cur.id}--tasks`
              });
            }
          } else if (memberBudget.position?.name && !isMemberDiscarded) {
            acc.push({
              // ...member,
              isMemberBudget: true,
              member_budget_id: memberBudget.id,
              account_id: `root--${accountId}`,
              accountId: accountId,
              projectId: cur.id,
              isOpen: false,
              project_view_group_key: memberBudget.id,
              name: memberBudget.position?.name
            });
          }
        });

        acc.push({
          ...cur,
          project_id: cur.id,
          projectId: cur.id,
          id: `bottom--${cur.id}`,
          project_view_group_key: `bottom--${cur.id}`,
          isBottom: true,
          isOpen
        });
        if (!plannerSplitScreenActive) {
          acc.push({
            ...cur,
            project_id: cur.id,
            projectId: cur.id,
            id: `summary--${cur.id}`,
            project_view_group_key: `summary--${cur.id}`,
            isSummary: true,
            isOpen
          });
        }
      }
      return acc;
    }, [])
);

const bufferRow = {
  isEmptyBottomRow: true,
  isLast: true,
  id: `empty--2`,
  project_view_group_key: `empty--2`,
  account_id: 'empty--2',
  project_id: 'empty--2'
};

const getPositionsState = (state) => state.positions;

const getTeamPositionsHash = createSelector(
  getPositionsState,
  (state) => state?.teamPositionsHash
);

export const getTeamPositionOrderByAccount = createSelector(
  getPositionsState,
  (state) => state?.teamPositionOrderByAccount || []
);

export const getPositions = createSelector(
  getPositionsState,
  (state) => state?.positions
);

export const getFormattedPositionsForRows = createSelector(
  getPositions,
  getTeamPositionsHash,
  getTeamPositionOrderByAccount,
  getWorkloadSplitScreenAccountId,
  (
    positionsHash,
    teamPositionsHash,
    teamPositionOrderByAccount,
    splitScreenAccountId
  ) => {
    const positionOrder = teamPositionOrderByAccount[splitScreenAccountId];
    if (!positionOrder) {
      return [];
    }
    const unformattedPositions = positionOrder.map((teamPositionId) => {
      const positionId = teamPositionsHash[teamPositionId]?.position_id;
      const position = positionsHash[positionId];
      return position;
    });
    return unformattedPositions;
  }
);

export const getSplitScreenPositionRowIds = createSelector(
  getFormattedPositionsForRows,
  (formattedPositionsForRows) => formattedPositionsForRows.map((row) => row?.id)
);

const getWorkplanSplitScreenRows = createSelector(
  getFormattedPositionsForRows,
  getWorkloadSplitScreenAccountId,
  (formattedPositionsForRows, splitScreenAccountId) => {
    const workplanSplitScreenRows = formattedPositionsForRows.reduce(
      (acc, cur) => {
        if (!cur || cur?.is_default) {
          return acc;
        }
        acc.push({
          project_view_group_key: cur.id,
          isPosition: true,
          position_id: `${cur?.id}`,
          id: `${cur?.id}`,
          positionId: cur?.id,
          projectId: null,
          isOpen: true,
          project_view_group_key: cur.id,
          name: cur?.name,
          shouldRenderRow: true,
          isWorkloadSplitRoot: true,
          preventCanvasClick: true
        });
        return acc;
      },
      []
    );
    return workplanSplitScreenRows;
  }
);

export const getWorkloadSplitScreenRows = createSelector(
  getFilteredProjectsArray,
  getPlannerOpenProjects,
  getOnWorkloadView,
  getGroupsHash,
  getPlannerMembers,
  getPlannerOpenMembers,
  getWorkloadSplitScreenActive,
  getPlannerSplitScreenActive,
  getPlannerSplitScreenProjectId,
  getWorkloadSplitScreenAccountId,
  getWorkloadViewBy,
  getProjectHash,
  getMemberBudgetsByProjectIdWithUnassigned,
  getWorkloadSplitScreenType,
  getWorkplanSplitScreenRows,
  (
    projects,
    openWorkloadProjectRows,
    isOnWorkloadView,
    boards,
    plannerMembers,
    openMembers,
    workloadSplitScreenActive,
    plannerSplitScreenActive,
    splitScreenProjectId,
    splitScreenAccountId,
    workloadViewBy,
    projectHash,
    memberBudgetsByProjectId,
    workloadSplitScreenType,
    workplanSplitScreenRows
  ) => {
    // member split screen rows
    if (workloadViewBy === VIEW_BY.MEMBERS) {
      if (workloadSplitScreenActive && !!splitScreenAccountId) {
        return workloadSplitScreenType === SPLIT_SCREEN_TYPES.WORK_PLAN
          ? workplanSplitScreenRows
          : [
              {
                project_view_group_key: splitScreenAccountId,
                shouldRenderRow: true,
                project_id: splitScreenAccountId,
                projectId: splitScreenAccountId,
                id: `root--${splitScreenAccountId}`,
                isRoot: true,
                isSplitScreen: true,
                isOpen: true,
                isWorkloadSplitRoot: true
              },
              {
                isEmptyBottomRow: true,
                isLast: true,
                id: `empty--2`,
                project_view_group_key: `empty--2`,
                account_id: 'empty--2',
                project_id: 'empty--2'
              }
            ];
      } else {
        return [];
      }
    }
    // planner split screen rows
    return [
      ...projects.reduce((acc, cur) => {
        if (!cur) {
          return acc;
        }
        const projectMemberBudgets = memberBudgetsByProjectId[cur.id] || [];
        const isActiveSplitScreenProject = cur.id === splitScreenProjectId;
        const isOpen =
          openWorkloadProjectRows[cur.id] || isActiveSplitScreenProject;

        if (plannerSplitScreenActive && !isActiveSplitScreenProject) {
          return acc;
        }
        acc.push({
          ...cur,
          shouldRenderRow: true,
          project_id: cur.id,
          projectId: cur.id,
          id: `summary--${cur.id}`,
          project_view_group_key: `summary--${cur.id}`,
          isSummary: true,
          isRoot: true,
          isOpen,
          isSplitScreen: true
        });

        if (isOpen) {
          projectMemberBudgets.forEach((memberBudget, index) => {
            const isMemberDiscarded =
              !!memberBudget.project_membership?.discarded_at;
            const isMember =
              !isMemberDiscarded && memberBudget.account_id !== null;
            const accountId = isMember
              ? memberBudget.project_membership?.account_id
              : null;
            const isMemberOpen = openMembers[accountId];
            const member = plannerMembers[accountId];
            if (member) {
              acc.push({
                ...member,
                isOpen: isMemberOpen,
                shouldRenderRow: true,
                account_id: `root--${member.account_id}`,
                accountId: member.account_id,
                projectId: cur.id,
                isSplitScreen: true,
                isRootSplitScreen: true,
                project_view_group_key: `account--${member.account_id}--summary--${cur.id}`
              });
              if (isMemberOpen) {
                // add additional rows here
                acc.push({
                  ...member,
                  shouldRenderRow: true,
                  accountId: member.account_id,
                  account_id: `account--${member.account_id}`,
                  isOpen: isMemberOpen,
                  project_view_group_key: `account--${member.account_id}--split`
                });
                acc.push({
                  ...member,
                  shouldRenderRow: true,
                  account_id: `bottom--${member.account_id}`,
                  accountId: member.account_id,
                  projectId: cur.id,
                  isBottom: true,
                  isOpen: isMemberOpen,
                  project_view_group_key: `account--${member.account_id}--bottom--${cur.id}`
                });
              }
            }
          });
        }
        return acc;
      }, []),
      bufferRow
    ];
  }
);

export const getPlannerSplitScreenViewMemberIds = createSelector(
  getPlannerSplitScreenActive,
  getPlannerSplitScreenProjectId,
  getProjectHash,
  (isPlannerSplitScreenActive, splitScreenProjectId, projectHash) => {
    if (!isPlannerSplitScreenActive) return emptyArray;
    const splitScreenProject = projectHash[splitScreenProjectId];
    if (!splitScreenProject) return emptyArray;
    return splitScreenProject.member_account_ids;
  }
);

const unGroupedRow = [{ id: 'none' }];
const prepGetWorkloadRows = createSelector(
  getWorkloadViewBy,
  getFormattedPlannerRows,
  getFormattedPlannerRowsByProject,
  getWorkloadSplitScreenActive,
  getWorkloadSplitScreenAccountId,
  (
    viewBy,
    rowsByMember,
    rowsByProject,
    workloadSplitScreenActive,
    splitScreenAccountId
  ) => {
    switch (viewBy) {
      case VIEW_BY.NONE:
        return unGroupedRow;

      case VIEW_BY.MEMBERS: {
        return workloadSplitScreenActive
          ? rowsByMember.filter(
              (row) =>
                row.accountId === Number(splitScreenAccountId) || row.isSummary
            )
          : rowsByMember;
      }
      case VIEW_BY.PROJECTS:
      default:
        return rowsByProject;
    }
  }
);

export const getWorkloadRows = createSelector(
  prepGetWorkloadRows,
  (state) => state.workloadPlanner.visibleIndices,
  getWorkloadSplitScreenActive,
  getPlannerSplitScreenActive,
  getIsOnScheduleView,
  getWorkloadViewBy,
  (
    rows,
    visibleIndices = [0, 0],
    workloadSplitScreenActive,
    plannerSplitScreenActive,
    isOnScheduleView,
    viewBy
  ) => {
    const stop = visibleIndices[1];
    const workloadRows = rows.map((row, index) => ({
      ...row,
      index,
      shouldRenderRow: true
    }));
    if (workloadSplitScreenActive || plannerSplitScreenActive) {
      return workloadRows;
    }
    const shouldAddSecondRow = true; // to be updated later if we want

    /**
     * On Planner/Work Plans view (isOnScheduleView == true), we want to include
     * a 'New Project' link to the calendar sidebar. Insert the link here to
     * display it above the empty row of the sidebar.
     */
    const shouldAddNewProjectLink =
      isOnScheduleView && viewBy === VIEW_BY.PROJECTS;
    if (shouldAddNewProjectLink) {
      workloadRows.push({
        isNewProjectLink: true
      });
    }

    workloadRows.push({
      isEmptyBottomRow: true,
      isLast: !shouldAddSecondRow,
      id: `empty--1`,
      project_view_group_key: `empty--1`,
      account_id: 'empty--1',
      project_id: 'empty--1'
    });
    /**
     * Not display a second empty row when we are displaying 'New Project' link
     * in sidebar (on Planner/Work Plans).
     *
     * This is because the row occupied by 'New Project' link will act as an
     * empty row already.
     */
    if (shouldAddSecondRow && !shouldAddNewProjectLink) {
      workloadRows.push({
        isEmptyBottomRow: true,
        isLast: true,
        id: `empty--2`,
        project_view_group_key: `empty--2`,
        account_id: 'empty--2',
        project_id: 'empty--2'
      });
    }

    return workloadRows;
  }
);

export const getWorkloadGroupHash = createSelector(
  getWorkloadRows,
  getWorkloadViewBy,
  getPlannerSplitScreenActive,
  (rows, viewBy, plannerSplitScreenActive) =>
    keyBy(
      rows,
      viewBy === VIEW_BY.MEMBERS ||
        (viewBy === VIEW_BY.PROJECTS && plannerSplitScreenActive)
        ? 'accountId'
        : 'member_budget_id'
    )
);

export const getPlannerProjectMemberAccountIds = createDeepEqualSelector(
  getFormattedPlannerRows,
  getFormattedPlannerRowsByProject,
  getWorkloadViewBy,
  (rows, rowsByProject, viewBy) => {
    return viewBy === VIEW_BY.MEMBERS
      ? uniq(flatMap(rows, (row) => (row.accountId ? [row.accountId] : [])))
      : uniq(flatMap(rowsByProject, (row) => row.member_account_ids ?? []));
  }
);
export const getPlannerMemberBudetIds = createDeepEqualSelector(
  getFormattedPlannerRowsByProject,
  (rowsByProject) => {
    // if there is accountId in row, it is a member
    return uniq(
      flatMap(rowsByProject, (row) =>
        !row.accountId && row.member_budget_id ? [row.member_budget_id] : []
      )
    );
  }
);

export const getRootRowCount = createSelector(
  getWorkloadRows,
  (rows) => rows.filter((group) => group.isRoot).length
);

export const prepGetScheduleRows = createSelector(
  getScheduleViewBy,
  getFormattedPlannerRows,
  getFormattedPlannerRowsByProject,
  (viewBy, rowsByMember, rowsByProject) => {
    const unGroupedRow = [{ id: 'none' }];
    switch (viewBy) {
      case VIEW_BY.NONE:
        return unGroupedRow;

      case VIEW_BY.MEMBERS: {
        // Filter out the summary row
        return rowsByMember.slice(1);
      }
      case VIEW_BY.PROJECTS:
      default:
        return rowsByProject;
    }
  }
);

export const getScheduleRows = createSelector(
  prepGetScheduleRows,
  (state) => state.workloadPlanner.visibleIndices,
  (rows, visibleIndices = [0, 0]) => {
    const stop = visibleIndices[1];
    return rows.map((row, index) => ({
      ...row,
      index,
      shouldRenderRow: true
    }));
  }
);

const getOwnViewBy = (state, ownProps) => ownProps.viewBy;
export const getPossibleRowsCount = createSelector(
  getOwnViewBy,
  getPlannerMembersCount,
  getPlannerProjectsCount,
  (viewBy, membersCount, projectsCount) =>
    viewBy === VIEW_BY.MEMBERS ? membersCount : projectsCount
);
export const getVisibleRowsCount = createSelector(
  getOwnViewBy,
  getFilteredMembersArray,
  getFilteredProjectsArray,
  (viewBy, members, projects) =>
    viewBy === VIEW_BY.MEMBERS ? members.length : projects.length
);

export const getAllSelected = createSelector(
  getPossibleRowsCount,
  getVisibleRowsCount,
  (possible, visible) => possible === visible
);

export const getProjectPlannerVisibleTimeStart = (state, ownProps) =>
  state.workloadPlanner.visibleTimes[ownProps?.plannerType || 'workload']
    .visibleTimeStart;
export const getProjectPlannerVisibleTimeEnd = (state, ownProps) =>
  state.workloadPlanner.visibleTimes[ownProps?.plannerType || 'workload']
    .visibleTimeEnd;

const getProjectPlannerStepValue = createSelector(
  getZoom,
  (zoom) => ZOOM_TO_STEP_VALUES[zoom]
);

const bindStep = (timestamp, step) => moment(timestamp).startOf(step);

export const getProjectPlannerStepStart = createSelector(
  getProjectPlannerVisibleTimeStart,
  getProjectPlannerStepValue,
  bindStep
);
export const getProjectPlannerStepEnd = createSelector(
  getProjectPlannerVisibleTimeEnd,
  getProjectPlannerStepValue,
  bindStep
);

export const getProjectPlannerSteps = createSelector(
  getProjectPlannerStepStart,
  getProjectPlannerStepEnd,
  getProjectPlannerStepValue,
  (start, end, step) =>
    Array.from(moment.range(start, end).by(step)).map((date) => date.valueOf())
);

export const getFormattedPlannerScheduleBars = createSelector(
  getPlannerMembers,
  getPlannerSchedules,
  getProjectHash,
  getFlatPhasesHash,
  getAllActivityRowInfo,
  getZoom,
  getGroupsHash,
  getOOOProject,
  getProjectPlannerStepValue,
  (state) => state.workloadPlanner.splitScheduleBarId, // not used in calculations, just force new reference,
  (state) => state.workloadPlanner.contextMenuScheduleBarId, // not used in calculations, just force new reference,
  () => true, // include unassigned
  getCondensedZoomLevel,
  () => true, // include split stubs
  formatPlannerScheduleBars
);

export const getPrepWorkloadEventScheduleBars = createSelector(
  getPlannerMembers,
  getPlannerSchedules,
  getProjectHash,
  getFlatPhasesHash,
  getAllActivityRowInfo,
  () => ZOOM_LEVELS.WEEK,
  getGroupsHash,
  getOOOProject,
  () => null, // stepValue
  (state) => state.workloadPlanner.splitScheduleBarId, // not used in calculations, just force new reference,
  (state) => state.workloadPlanner.contextMenuScheduleBarId, // not used in calculations, just force new reference,
  () => true, // include unassigned
  getCondensedZoomLevel,
  () => false, // dont include split stubs
  formatPlannerScheduleBars
);
export const getWorkloadEventScheduleBars = createSelector(
  (state) => state.workloadEvents.workloadRangeStart,
  getPrepWorkloadEventScheduleBars,
  (start, bars) => {
    const modalBars = filterBarsByDateRange(
      start,
      moment(start).endOf('week')
    )(bars);
    const barsWithCommitment = accumulateWeekCommitment(
      start,
      moment(start).endOf('week')
    )(modalBars);

    return barsWithCommitment;
  }
);

export const getAllMilestonesHash = (state) => state.milestones.milestonesHash;

const getPlannerMilestones = createSelector(
  getProjectHash,
  getFilteredProjectsArray,
  getPhasesAndMilestonesByProjectHash,
  getFlatPhasesHash,
  getPhaseTotals,
  getZoom,
  (
    projectHash,
    projectsArray,
    phasesByProjectHash,
    phaseHash,
    phaseTotals,
    zoom
  ) => {
    const phasesByProject = projectsArray
      .map((project) => phasesByProjectHash[project.id])
      .filter((phases) => !!phases);

    const phasesWithStartDates = flatten(
      phasesByProject.map((project) => project.phases)
    ).filter((phase) => phase && phase.start_date);
    const milestones = phasesWithStartDates.map((phase) => {
      const unformattedTotals = phaseTotals[phase.id];
      const totals = unformattedTotals
        ? formatTotals(phaseTotals[phase.id])
        : undefined;
      const start_date_label = moment(phase.start_date, 'MM/DD/YYYY');
      const end_date_label = moment(phase.end_date, 'MM/DD/YYYY');
      const length = end_date_label.diff(start_date_label, 'days', false);
      return {
        ...phase,
        is_like_default: phaseHash[phase.id]?.is_like_default,
        id: serializeBar({ itemType: 'phase', itemId: phase.id }),
        is_phase: true,
        project_id: `root--${phase.project_id}`,
        project: projectHash[phase.project_id],
        initial_start_date: fitToScaleStart(
          zoom,
          phase.start_date,
          'MM/DD/YYYY'
        ),
        length: length,
        start_date_label: start_date_label,
        end_date_label: end_date_label,
        start_date: fitToScaleStart(zoom, phase.start_date, 'MM/DD/YYYY'),
        end_date: fitToScaleEnd(zoom, phase.end_date, 'MM/DD/YYYY'),
        remaining_work_days: getRemainingWorkDays(phase),
        ungrouped_id: 'none',
        totals,
        item_project_view_group_key: `root--${phase.project_id}`
      };
    });
    return milestones.filter((milestone) => !!milestone);
  }
);

const getScheduleMilestones = createSelector(
  getFilteredMembersArray,
  getProjectHash,
  getPhaseTotals,
  getZoom,
  getFlatPhases,
  getWorkloadSplitScreenActive,
  getWorkloadSplitScreenAccountId,
  getIsOnPhaseSplitScreen,
  (
    plannerMembers,
    projectHash,
    phaseTotals,
    zoom,
    flatPhases,
    workloadSplitScreenActive,
    splitScreenAccountId,
    isOnPhaseSplitScreen
  ) => {
    const plannerMemberIdHash = isOnPhaseSplitScreen
      ? { [splitScreenAccountId]: true }
      : keyBy(plannerMembers, 'account_id');
    const milestones = flatPhases.reduce((acc, phase) => {
      phase.phase_memberships.forEach((phaseMembership) => {
        if (plannerMemberIdHash[phaseMembership.account_id]) {
          const unformattedTotals = phaseTotals[phase.id];
          const totals = unformattedTotals
            ? formatTotals(phaseTotals[phase.id])
            : undefined;
          const start_date_label = moment(phase.start_date, 'MM/DD/YYYY');
          const end_date_label = moment(phase.end_date, 'MM/DD/YYYY');
          const length = end_date_label.diff(start_date_label, 'days', false);
          acc.push({
            ...phase,
            id: serializeBar({ itemType: 'phase', itemId: phase.id }),
            is_phase: true,
            project_id: `root--${phase.project_id}`,
            project: projectHash[phase.project_id],
            initial_start_date: fitToScaleStart(
              zoom,
              phase.start_date,
              'MM/DD/YYYY'
            ),
            length: length,
            start_date_label: start_date_label,
            end_date_label: end_date_label,
            start_date: fitToScaleStart(zoom, phase.start_date, 'MM/DD/YYYY'),
            end_date: fitToScaleEnd(zoom, phase.end_date, 'MM/DD/YYYY'),
            remaining_work_days: getRemainingWorkDays(phase),
            ungrouped_id: 'none',
            assignee_id: `root--${phaseMembership.account_id}`,
            totals,
            item_project_view_group_key: `root--${phase.project_id}`,
            item_project_view_split_screen_key: splitScreenAccountId
          });
        }
      });
      return acc;
    }, []);
    return milestones.filter((milestone) => !!milestone);
  }
);

const getWorkloadMilestones = createSelector(
  getPlannerMilestones,
  (milestones) => milestones
);

export const getFormattedPlannerScheduleBarsByProject = createSelector(
  getFormattedPlannerScheduleBars,
  getWorkloadMilestones,
  getPlannerTimelineTasks,
  getPlannerWorkCategories,
  getWorkloadViewType,
  (bars, milestoneBars, tasks, workCategories, workloadViewType) =>
    workloadViewType === VIEW_TYPE.CONDENSED
      ? [
          ...bars,
          ...milestoneBars,
          ...tasks.filter((task) => task.primary_assignee_id)
        ]
      : [
          ...bars,
          ...milestoneBars,
          ...workCategories,
          ...tasks.filter((task) => task.primary_assignee_id)
        ]
);

export const getFormattedScheduleScheduleBarsByMember = createSelector(
  getScheduleTimelineTasks,
  getScheduleMilestones,
  (tasks, milestoneBars) => [...milestoneBars, ...tasks]
);

export const getFormattedScheduleScheduleBarsByProject = createSelector(
  getProjectTimelineTasks,
  getPlannerMilestones,
  getPlannerWorkCategories,
  getWorkloadViewType,
  (tasks, milestoneBars, workCategories, workloadViewType) =>
    workloadViewType === VIEW_TYPE.CONDENSED
      ? [...milestoneBars, ...tasks]
      : [...milestoneBars, ...workCategories, ...tasks]
);

export const prepGetWorkloadItems = createSelector(
  getWorkloadViewBy,
  getFormattedPlannerScheduleBars,
  getFormattedPlannerScheduleBarsByProject,
  getWorkloadSettings,
  (viewBy, bars, moreBars, workloadSettings) => {
    const showTentativePlans = workloadSettings?.show_tentative_plans ?? true;
    const unfilteredBars = viewBy === VIEW_BY.MEMBERS ? bars : moreBars;
    return !showTentativePlans
      ? unfilteredBars.filter((bar) => bar.budget_status !== 'proposal')
      : unfilteredBars;
  }
);
export const getWorkloadItems = createSelector(
  prepGetWorkloadItems,
  getWorkloadRows,
  getWorkloadViewBy,
  (state) => state.workloadPlanner.visibleIndices,
  getWFHProject,
  (items, rows, viewBy, [start, stop], WFHProject) => {
    return WFHProject
      ? items.filter((item) => item.project_id !== WFHProject.id)
      : items;

    // below breaks when opening and closing rows for some reason
    // todo figure out why and fix
    const keyByFunc =
      viewBy === VIEW_BY.MEMBERS
        ? (item) => item.account?.id
        : (item) => item.id;
    const rowIdHash = keyBy(
      rows.slice(0, stop).filter((row) => row),
      keyByFunc
    );
    const idKey = viewBy === VIEW_BY.MEMBERS ? 'account_id' : 'project_id';
    return items.filter(rowIdHash[item[idKey]]);
  }
);

const getPlannerSplitScreenItems = createSelector(
  getFormattedPlannerScheduleBars,
  (bars) => bars
);

const getScheduledTaskSplitScreenItems = createSelector(
  getFormattedPlannerScheduleBarsByProject,
  (barsByProject) => barsByProject.filter((bar) => bar.is_task)
);

const getPhaseSplitScreenItems = createSelector(
  getProjectHash,
  getPhaseTotals,
  getZoom,
  getFlatPhasesAndMilestones,
  getWorkloadSplitScreenAccountId,
  getPlannerWorkCategories,
  getPlannerMembers,
  (
    projectHash,
    phaseTotals,
    zoom,
    flatPhasesAndMilestones,
    splitScreenAccountId,
    workCategories,
    plannerMembers
  ) => {
    const milestones = flatPhasesAndMilestones.reduce((acc, phase) => {
      const splitScreenAccountIsPhaseMember = phase.phase_memberships.find(
        (phaseMembership) => phaseMembership.account_id == splitScreenAccountId
      );
      if (splitScreenAccountIsPhaseMember || !phase.is_budget) {
        const unformattedTotals = phaseTotals[phase.id];
        const totals = unformattedTotals
          ? formatTotals(phaseTotals[phase.id])
          : undefined;
        const start_date_label = moment(phase.start_date, 'MM/DD/YYYY');
        const end_date_label = moment(phase.end_date, 'MM/DD/YYYY');
        const length = end_date_label.diff(start_date_label, 'days', false);
        acc.push({
          ...phase,
          id: serializeBar({ itemType: 'phase', itemId: phase.id }),
          is_phase: true,
          project_id: `root--${phase.project_id}`,
          project: projectHash[phase.project_id],
          initial_start_date: fitToScaleStart(
            zoom,
            phase.start_date,
            'MM/DD/YYYY'
          ),
          length: length,
          start_date_label: start_date_label,
          end_date_label: end_date_label,
          start_date: fitToScaleStart(zoom, phase.start_date, 'MM/DD/YYYY'),
          end_date: fitToScaleEnd(zoom, phase.end_date, 'MM/DD/YYYY'),
          remaining_work_days: getRemainingWorkDays(phase),
          ungrouped_id: 'none',
          totals,
          item_project_view_group_key: `root--${phase.project_id}`,
          item_project_view_split_screen_key: splitScreenAccountId
        });
      }
      return acc;
    }, []);
    return [
      ...milestones.filter((milestone) => !!milestone),
      ...workCategories
    ];
  }
);

const getWorkplanSplitScreenItems = createSelector(
  getFormattedPlannerScheduleBars,
  getMemberBudgets,
  (bars, memberBudgets) => {
    const unassignedBars = bars.filter((bar) => !bar.account_id);
    unassignedBars.forEach((bar) => {
      const positionId = memberBudgets[bar.member_budget_id]?.position_id;
      bar.item_project_view_split_screen_key = positionId; // should be the position ID
    });
    return unassignedBars;
  }
);

export const getWorkloadSplitScreenItems = createSelector(
  getWorkloadViewBy,
  getWorkloadSplitScreenType,
  getPlannerSplitScreenType,
  getPlannerSplitScreenItems,
  getScheduledTaskSplitScreenItems,
  getPhaseSplitScreenItems,
  getWorkplanSplitScreenItems,
  (
    viewBy,
    workloadSplitScreenType,
    plannerSplitScreenType,
    plannerSplitScreenItems,
    scheduledTaskSplitScreenItems,
    phaseSplitScreenItems,
    workplanSplitScreenItems
  ) => {
    const splitScreenType =
      viewBy === VIEW_BY.PROJECTS
        ? plannerSplitScreenType
        : workloadSplitScreenType;
    switch (splitScreenType) {
      case SPLIT_SCREEN_TYPES.PROJECT:
        return plannerSplitScreenItems;
      case SPLIT_SCREEN_TYPES.SCHEDULED_TASK:
        return scheduledTaskSplitScreenItems;
      case SPLIT_SCREEN_TYPES.PHASE:
        return phaseSplitScreenItems;
      case SPLIT_SCREEN_TYPES.WORK_PLAN:
        return workplanSplitScreenItems;
      default:
        return [];
    }
  }
);

export const prepGetScheduleItems = createSelector(
  getScheduleViewBy,
  getFormattedScheduleScheduleBarsByMember,
  getFormattedScheduleScheduleBarsByProject,
  (viewBy, bars, moreBars) => (viewBy === VIEW_BY.MEMBERS ? bars : moreBars)
);
export const getScheduleItems = createSelector(
  prepGetScheduleItems,
  getScheduleRows,
  getScheduleViewBy,
  (state) => state.workloadPlanner.visibleIndices,
  (items, rows, viewBy, [start, stop]) => {
    if (viewBy === VIEW_BY.NONE) {
      return items;
    }
    if (viewBy === VIEW_BY.PROJECTS) {
      const rowIdHash = keyBy(
        rows.filter((row) => row.project_id),
        (row) => row.project_id
      );
      return items.filter(
        (item) =>
          rowIdHash[
            item.is_phase || item.is_work_category
              ? item.project?.id
              : item.project_id
          ]
      );
    }
    return items;
  }
);

export const getWorkloadItemIds = createSelector(getWorkloadItems, (items) =>
  items.map((item) => item.id)
);

export const getWorkloadSplitScreenItemIds = createSelector(
  getWorkloadSplitScreenItems,
  (items) => items.map((item) => item.id)
);

export const getScheduleItemIds = createSelector(getScheduleItems, (items) =>
  items.map((item) => item.id)
);

export const getPlannerModalSchedules = createSelector(
  getPlannerModalAccountId,
  getPlannerSchedules,
  (selectedAccountId, scheduleBarsObj) =>
    Object.values(scheduleBarsObj)
      .filter((bar) => bar.account_id === selectedAccountId)
      .map((bar) => ({
        ...bar,
        start_date: moment(bar.start_date, 'MM/DD/YYYY'),
        end_date: moment(bar.end_date, 'MM/DD/YYYY')
      }))
);

/**
 * @deprecated
 */
export const getPDVPlannerSchedules = createSelector(
  getSelectedProject,
  getPlannerSchedules,
  (selectedProject, scheduleBarsObj) => {
    const membershipHash =
      (selectedProject &&
        selectedProject.project_membership.reduce((hash, member) => {
          hash[member.account.id] = true;
          return hash;
        }, {})) ||
      emptyObj;
    return Object.values(scheduleBarsObj)
      .filter((bar) => !!membershipHash[bar.account_id])
      .map((bar) => ({
        ...bar,
        start_date: moment(bar.start_date, 'MM/DD/YYYY'),
        end_date: moment(bar.end_date, 'MM/DD/YYYY')
      }));
  }
);

export const getFormattedPlannerModalDates = createSelector(
  getPlannerModalDates,
  ({ visibleTimeStart, visibleTimeEnd }) => ({
    visibleTimeStart: visibleTimeStart
      .clone()
      .startOf('day')
      .format('MM/DD/YYYY'),
    visibleTimeEnd: visibleTimeEnd.clone().startOf('day').format('MM/DD/YYYY')
  })
);

export const getPlannerModalMemberId = (state) =>
  state.projectPlannerModal.selectedMemberId;

export const getWorkloadProjectsOffset = (state) =>
  state.workloadPlanner.offset;

export const getIsTaskSidebarOpen = (state) =>
  state.workloadPlanner.isTaskSidebarOpen;

export const getIsActivitySideMenuOpen = (state) =>
  state.workloadPlanner.isActivitySideMenuOpen;

export const getTaskSidebarProjectId = (state) =>
  state.workloadPlanner.taskSidebarProjectId;

export const getTaskSidebarProject = createSelector(
  getTaskSidebarProjectId,
  getProjectHash,
  (projectId, projectHash) => projectHash[projectId]
);

const getPlannerSchedulesArray = createSelector(
  getPlannerSchedules,
  (scheduleBars) => Object.values(scheduleBars)
);

const getUtilizationsFromScheduleBars = createSelector(
  getPlannerSchedulesArray,
  (scheduleBars) => {
    const utilizations = [];
    scheduleBars.forEach((scheduleBar) => {
      scheduleBar.bars.forEach((bar) => {
        const range = Array.from(
          moment.range(bar.start_date, bar.end_date).by('day')
        ).map((day) => day.format('YYYY-MM-DD'));
        range.forEach((day) => {
          utilizations.push({
            accountId: scheduleBar.account_id,
            projectId: scheduleBar.project_id,
            day,
            hours: +scheduleBar.daily_hours
          });
        });
      });
    });
    return utilizations;
  }
);

export const getProjectUtilizations = createSelector(
  getPlannerOpenProjects,
  getUtilizationsFromScheduleBars,
  (openProjects, utilizations) => {
    const filteredUtilizations = utilizations.filter(
      (utilization) => openProjects[utilization.projectId]
    );

    const projectUtilizationHash = {};

    filteredUtilizations.forEach(({ projectId, day, hours }) => {
      if (!projectUtilizationHash[projectId]) {
        projectUtilizationHash[projectId] = {};
      }

      if (projectUtilizationHash[projectId][day] === undefined) {
        projectUtilizationHash[projectId][day] = 0;
      }
      projectUtilizationHash[projectId][day] += +hours;

      // grab all bars for open projects
      // serialize each bar into day - hours hash
    });
    return projectUtilizationHash;
  }
);

export const getIsFetchingWorkloadPlanner = (state) =>
  state.workloadPlanner.isLoading;

export const getTotalNumRequests = (state) =>
  state.workloadPlanner.totalNumRequests;

const getOwnWorkplanStateId = (state, ownProps) => ownProps.workplanStateId;

export const makeGetWorkplanState = () =>
  createSelector(
    (state) => state.workloadPlanner.workplanStates,
    getOwnWorkplanStateId,
    (workplanStates, workplanStateId) =>
      workplanStates[workplanStateId] || emptyObj
  );

export const makeGetWorkplanStateOrdersByGroup = () => {
  const getWorkplanState = makeGetWorkplanState();
  return createSelector(
    getWorkplanState,
    (workplanState) => workplanState.ordersByGroup || emptyObj
  );
};

export const makeGetWorkplanStateTopLevelGroupOrder = () => {
  const getWorkplanState = makeGetWorkplanState();
  return createSelector(
    getWorkplanState,
    (workplanState) => workplanState.topLevelGroupOrder || emptyArray
  );
};

export const makeGetWorkplanStateIsSaving = () => {
  const getWorkplanState = makeGetWorkplanState();
  return createSelector(
    getWorkplanState,
    (workplanState) => workplanState.isSaving || emptyObj
  );
};

export const makeGetWorkplanStateGroupCounts = () => {
  const getWorkplanState = makeGetWorkplanState();
  return createSelector(
    getWorkplanState,
    (workplanState) => workplanState.groupCounts || emptyObj
  );
};

export const makeGetWorkplanStateTotalCount = () => {
  const getWorkplanState = makeGetWorkplanState();
  return createSelector(
    getWorkplanState,
    (workplanState) => workplanState.totalCount || 0
  );
};

export const makeGetWorkplanStateTotalFetchedCount = () => {
  const getWorkplanState = makeGetWorkplanState();
  return createSelector(
    getWorkplanState,
    (workplanState) => workplanState.totalFetchedCount || 0
  );
};

export const makeGetWorkplanStateIsFetching = () => {
  const getWorkplanState = makeGetWorkplanState();
  return createSelector(
    getWorkplanState,
    (workplanState) => workplanState.isFetching || false
  );
};

export const makeGetWorkplanStateIsFetchingInitial = () => {
  const getWorkplanState = makeGetWorkplanState();
  return createSelector(
    getWorkplanState,
    (workplanState) => workplanState.isFetchingInitial || false
  );
};

export const getSuggestionsByPhase = createSelector(
  (state) => state.suggestions.suggestions,
  (suggestions) => keyBy(suggestions, (suggestion) => suggestion.phase_id)
);

export const getSuggestionsByPhaseMembership = createSelector(
  (state) => state.suggestions.suggestions,
  (suggestions) => keyBy(suggestions, 'phase_membership_id')
);
