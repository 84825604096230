import { useCallback, useMemo } from 'react';
import { useFilterContext } from 'FilterModule/FilterContextProvider';
import { useFilterSearch, UseFilterSearchProps } from './useFilterSearch';
import { useToggle } from 'react-use';
import intersection from 'lodash/intersection';
import keyBy from 'lodash/keyBy';

const emptyArray = [];

type UseArrayFilterFieldParams<ItemType, ItemHashType> = Pick<
  UseFilterSearchProps<ItemType, ItemHashType>,
  'itemSearchableKeys' | 'itemHash' | 'isFeSearchDisabled'
> & {
  field: string;
  items: ItemType[];
  /** prevent unnecessary hook calculations */
  isOff?: boolean;
  /**
   * searchText and setSearchText will not be returned.
   * use when custom search is needed (eg. a FilterListType that uses 2+ fields) or when list is too short
   * to require search
   */
  isSearchDisabled?: boolean;
  /**
   * Setting to true will skip sorting selected items to the top
   */
  shouldMaintainOrder?: boolean;
  /**
   * When true, selected items will show even if they don't exist in 'items'
   */
  shouldAlwaysShowSelectedItems?: boolean;
  resultCountHash?: Record<string | number, number>;
  shouldFilterResultsWithNoCount?: boolean;
  hasArchivedItems?: boolean;
  getIsItemArchived?: (item: ItemType) => boolean;
  /** configs that come from filter schema option hash */
  config?: {
    selectionLimit?: number;
    /** only one item can be selected at a time */
    isSingleSelect?: boolean;
  };
  shouldUseDraft?: boolean;
  /**
   * Provide when itemHash exists to show onlySelectedItemLabel
   */
  labelKey?: string;
};

/**
 * Requires FilterContext
 */
export const useArrayFilterField = <
  ItemType extends string | number,
  ItemHashType extends
    | Record<string | number, string>
    | Record<string | number, Record<string, any>>
>({
  config,
  field,
  items,
  itemHash,
  itemSearchableKeys,
  isOff,
  isFeSearchDisabled,
  isSearchDisabled,
  shouldMaintainOrder,
  shouldAlwaysShowSelectedItems,
  resultCountHash,
  shouldFilterResultsWithNoCount = true,
  hasArchivedItems,
  getIsItemArchived,
  shouldUseDraft,
  labelKey
}: UseArrayFilterFieldParams<ItemType, ItemHashType>) => {
  const { currentFilter, draftFilter } = useFilterContext();

  const { isSingleSelect, selectionLimit } = config || {};

  // items hidden due to not being associated with cross field dependency results
  // functionality not used currently
  const [isShowingHiddenItems, toggleIsShowingHiddenItems] = useToggle(true);

  const [isShowingArchivedItems, toggleIsShowingArchivedItems] =
    useToggle(false);

  const fieldValue = shouldUseDraft
    ? draftFilter[field] || currentFilter[field]
    : currentFilter[field];

  /**
   * Current filter selections. Note that this will be an empty array if the field doesn't exist on the currentFilter
   */
  const selectedItems = Array.isArray(fieldValue)
    ? (fieldValue as ItemType[])
    : emptyArray;

  const selectedItemsSet = useMemo(
    () => (isOff ? new Set() : new Set(selectedItems)),
    [selectedItems, isOff]
  );

  const getIsSelected = useCallback(
    (id) => selectedItemsSet.has(id),
    [selectedItemsSet]
  );

  // Sort selected items to the top (when applicable)
  const itemsSortedBySelectionOrder = useMemo(() => {
    if (isOff) return emptyArray;
    if (shouldMaintainOrder) return items;

    const itemExistenceHash = keyBy(items);

    const sortedItems = Array.from(new Set([...selectedItems, ...items]));

    return shouldAlwaysShowSelectedItems
      ? sortedItems
      : sortedItems.filter((item) => !!itemExistenceHash[item]);
  }, [
    isOff,
    items,
    selectedItems,
    shouldAlwaysShowSelectedItems,
    shouldMaintainOrder
  ]);

  // Filter items based on search (when applicable)
  const {
    searchText,
    setSearchText,
    optionsArray: filteredOptionsArray
  } = useFilterSearch({
    items: itemsSortedBySelectionOrder,
    isFeSearchDisabled: isOff || isFeSearchDisabled || isSearchDisabled,
    itemHash,
    itemSearchableKeys
  });

  /**
   * When applicable, handles hiding items that don't have associated results
   */
  const { visibleOptionsArray, numHiddenItems, nonArchivedOptionsArray } =
    useMemo(() => {
      if (isOff) {
        return {
          visibleOptionsArray: emptyArray,
          numHiddenItems: 0,
          nonArchivedOptionsArray: emptyArray
        };
      }

      const optionsAfterFilteringItemsWithNoResults =
        isShowingHiddenItems ||
        !resultCountHash ||
        !shouldFilterResultsWithNoCount
          ? filteredOptionsArray
          : filteredOptionsArray.filter((id) => resultCountHash[id]);

      const nonArchivedOptionsArray = !getIsItemArchived
        ? filteredOptionsArray
        : filteredOptionsArray.filter((item) => !getIsItemArchived(item));

      const optionsAfterFilteringArchivedItems =
        isShowingArchivedItems || !hasArchivedItems || !getIsItemArchived
          ? filteredOptionsArray
          : filteredOptionsArray.filter((item) => !getIsItemArchived(item));

      return {
        visibleOptionsArray: intersection(
          optionsAfterFilteringItemsWithNoResults,
          optionsAfterFilteringArchivedItems
        ),
        numHiddenItems:
          filteredOptionsArray.length -
          optionsAfterFilteringItemsWithNoResults.length,
        nonArchivedOptionsArray
      };
    }, [
      isShowingHiddenItems,
      filteredOptionsArray,
      resultCountHash,
      shouldFilterResultsWithNoCount,
      isShowingArchivedItems,
      hasArchivedItems,
      getIsItemArchived,
      isOff
    ]);

  /**
   * Updates the field with the new values.
   * Note that this won't do anything if the field does not exist in the currentFilterSchema
   */
  const updateSelectedItems = useCallback(
    (nextItems: ItemType[]) => {
      if (isOff) return;

      const filterToUpdate = shouldUseDraft ? draftFilter : currentFilter;

      filterToUpdate.update({ [field]: nextItems });
    },
    [currentFilter, field, isOff, shouldUseDraft, draftFilter]
  );

  /**
   * Will update the field with the item's value toggled
   */
  const toggleSelectedItem = useCallback(
    (item: ItemType) => {
      if (isOff) return;

      const isCurrentlySelected = selectedItemsSet.has(item);

      if (isSingleSelect) {
        updateSelectedItems(isCurrentlySelected ? [] : [item]);
      } else {
        const nextItems = isCurrentlySelected
          ? selectedItems.filter((selectedItem) => selectedItem !== item)
          : [...selectedItems, item];

        updateSelectedItems(nextItems);
      }
    },
    [
      isOff,
      selectedItems,
      selectedItemsSet,
      updateSelectedItems,
      isSingleSelect
    ]
  );

  const clearSelectedItems = useCallback(() => {
    updateSelectedItems([]);
  }, [updateSelectedItems]);

  const selectAllItems = useCallback(() => {
    const itemsToSelect = visibleOptionsArray;
    updateSelectedItems(
      selectionLimit ? itemsToSelect.slice(0, selectionLimit) : itemsToSelect
    );
  }, [updateSelectedItems, selectionLimit, visibleOptionsArray]);

  const isAllSelected = useMemo(
    () =>
      isOff ? false : visibleOptionsArray.every((item) => getIsSelected(item)),
    [getIsSelected, isOff, visibleOptionsArray]
  );

  const isUnableToSelectMoreItems =
    isAllSelected ||
    (!!selectionLimit && selectedItems.length >= selectionLimit);

  const onlySelectedItemLabel =
    selectedItems.length === 1
      ? itemHash
        ? labelKey
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (itemHash[selectedItems[0]!]?.[labelKey] as string)
          : undefined
        : (selectedItems[0] as string)
      : undefined;

  return {
    isUnableToSelectMoreItems,
    updateSelectedItems,
    clearSelectedItems,
    toggleSelectedItem,
    getIsSelected,
    selectedItems,
    selectionLimit,
    isSingleSelect,
    isAllSelected,
    optionsArray: visibleOptionsArray,
    ...(items.length > 0 && !isSingleSelect && { selectAllItems }),
    ...(!isSearchDisabled && {
      searchText,
      setSearchText
    }),
    numHiddenItems,
    ...(hasArchivedItems && {
      isShowingArchivedItems,
      toggleIsShowingArchivedItems,
      numArchivedItems:
        filteredOptionsArray.length - nonArchivedOptionsArray.length
    }),
    onlySelectedItemLabel
  };
};
