import theme from 'theme';
import styled from 'styled-components';
import KaratRight from 'icons/KaratRight';
import {
  ProjectUnassignedMemberBudgetWithRequirementsListItem,
  ProjectMembershipRequirementListItem,
  AddProjectMembershipRequirementListItem,
  RequirementLoaderListItem
} from '../types';
import EllipsisText from 'components/EllipsisText';
import {
  StyledKaratContainer,
  StyledItemContainer
} from 'SuggestionModule/components/FindPeople/List/styles';
import { Tag } from 'SuggestionModule/components/Tag';
import DeleteXIcon from 'icons/DeleteXIcon';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoaderV2';
import cn from 'classnames';

/* ----------------------------- Custom Renderer ---------------------------- */

export const ProjectUnassignedMemberBudgetWithRequirements = (
  item: ProjectUnassignedMemberBudgetWithRequirementsListItem
) => {
  const {
    memberBudget: { position },
    toggleCollapse,
    isOpen,
    requirementCounts
  } = item;
  const { nameWithCount, name } = position || {};

  const nameToUse = nameWithCount || name;

  const requirementsText =
    requirementCounts > 0
      ? `${requirementCounts} ${
          requirementCounts > 1 ? 'Requirements' : 'Requirement'
        }`
      : '';

  return (
    <StyledItemContainer
      className={'project-unassigned-member-budget-with-requirements'}
    >
      <div
        className={cn('text-container', {
          isOpen
        })}
        onClick={isOpen ? undefined : toggleCollapse}
      >
        <EllipsisText className="requirement-name" maxWidth={230}>
          {nameToUse}
        </EllipsisText>
        {!isOpen && (
          <div className="requirement-counts">{requirementsText}</div>
        )}
      </div>
    </StyledItemContainer>
  );
};

export const ProjectMembershipRequirement = (
  item: ProjectMembershipRequirementListItem
) => {
  const {
    memberBudget: { id: memberBudgetId },
    projectMembership: { id: projectMembershipId },
    requirement,
    onAddRequirementsClick
  } = item;

  const { id, name, tooltip, onDelete, type } = requirement;

  return (
    <StyledItemContainer className="project-membership-requirements">
      <Tag
        id={id}
        onClick={() => {
          onAddRequirementsClick({ memberBudgetId, projectMembershipId });
        }}
        dataTip={tooltip}
        name={name}
        type={type}
      />
      <DeleteIconContainer onClick={onDelete}>
        <DeleteXIcon
          color={theme.colors.colorMediumGray9}
          className={undefined}
        />
      </DeleteIconContainer>
    </StyledItemContainer>
  );
};

export const AddProjectMembershipRequirement = (
  item: AddProjectMembershipRequirementListItem,
  index
) => {
  const {
    memberBudget: { id: memberBudgetId },
    projectMembership: { id: projectMembershipId },
    onAddRequirementsClick
  } = item;

  return (
    <StyledItemContainer className="add-project-membership-requirements">
      <div
        onClick={() =>
          onAddRequirementsClick({ memberBudgetId, projectMembershipId })
        }
      >
        + Add Requirement
      </div>
    </StyledItemContainer>
  );
};

export const RequirementLoader = (item: RequirementLoaderListItem, index) => {
  const {
    memberBudget: { id: memberBudgetId },
    projectMembership: { id: projectMembershipId }
  } = item;

  return (
    <StyledItemContainer className="requirement-loader">
      <SkeletonLoader isLoading width={200} height={30}>
        <div></div>
      </SkeletonLoader>
    </StyledItemContainer>
  );
};

const DeleteIconContainer = styled.span`
  padding-left: 5px;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;

  path {
    fill: ${theme.colors.colorBudgetGrey};
  }

  &:hover {
    path {
      fill: ${theme.colors.colorCalendarBlue};
    }
  }
`;
