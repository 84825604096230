import * as constants from 'appConstants';
import * as budgetConstants from '../constants';
import keyBy from 'lodash/keyBy';
import { Rate, TeamRate } from 'RatesModule/models';
import { AnyAction } from '@reduxjs/toolkit';

export interface RatesState {
  rateIds: number[];
  rates: Record<number, Rate>;
  isFetchingRates: boolean;
  teamMemberRates: Record<
    number,
    {
      account_id: number;
      rate_id: number;
      archived: boolean;
    }
  >;
  teamRates: Record<number, TeamRate>;
  memberTeamRates: Record<number, TeamRate[]>;
}

export const initialState: RatesState = {
  rateIds: [], // standard rates id order
  rates: {}, // standard rates hash
  isFetchingRates: false,
  teamMemberRates: {}, // ?
  teamRates: {}, // members' current team rates, keyed by account_id
  memberTeamRates: {} // members' historical team rates, keyed by team membership id
};
const byRateId = (item) => item.id;
const byAccountId = (item) => item.account_id;

const rates = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case constants.LOGOUT_USER: {
      return initialState;
    }
    case budgetConstants.FETCH_RATES.TRIGGER: {
      return {
        ...state,
        isFetchingRates: true
      };
    }
    case budgetConstants.FETCH_RATES.FAILURE: {
      return {
        ...state,
        isFetchingRates: false
      };
    }
    case budgetConstants.FETCH_RATES.SUCCESS: {
      const { rates } = action.payload.response;
      return {
        ...state,
        rateIds: rates.map(byRateId),
        rates: {
          ...state.rates,
          ...keyBy(rates, byRateId)
        },
        isFetchingRates: false
      };
    }
    case budgetConstants.FETCH_ALL_TEAM_RATES.SUCCESS: {
      const rates = action.payload.response;
      return {
        ...state,
        teamRates: {
          ...keyBy(rates, byAccountId)
        }
      };
    }
    case budgetConstants.FETCH_MEMBER_TEAM_RATES.SUCCESS: {
      const rates = action.payload.response.team_rates;
      const teamMembershipId = action.payload.requestPayload.teamMembershipId;
      return {
        ...state,
        memberTeamRates: {
          ...state.memberTeamRates,
          [teamMembershipId]: rates
        }
      };
    }
    case budgetConstants.CREATE_MEMBER_TEAM_RATE.SUCCESS: {
      const { response, requestPayload } = action.payload;
      const rate = response;
      const { teamMembershipId, accountId } = requestPayload;

      const _newMemberTeamRates = state.memberTeamRates[teamMembershipId];

      const newMemberTeamRates = _newMemberTeamRates
        ? {
            ...state.memberTeamRates,
            [teamMembershipId]: [..._newMemberTeamRates, rate]
          }
        : state.memberTeamRates;

      const newTeamRates = {
        ...state.teamRates,
        [accountId]: rate
      };
      return {
        ...state,
        teamRates: newTeamRates,
        memberTeamRates: newMemberTeamRates
      };
    }
    case budgetConstants.FETCH_TEAM_MEMBERS_RATES.SUCCESS: {
      const rates = action.payload.response;
      return {
        ...state,
        teamMemberRates: {
          ...state.teamMemberRates,
          ...keyBy(rates, byAccountId)
        }
      };
    }
    case budgetConstants.UPDATE_TEAM_MEMBER_RATE.SUCCESS: {
      const rate = action.payload.response;
      const rates = rate ? [rate] : [];
      return {
        ...state,
        teamMemberRates: {
          ...state.teamMemberRates,
          ...keyBy(rates, byAccountId)
        }
      };
    }
    case budgetConstants.CREATE_RATE.SUCCESS: {
      const rate = action.payload.response;
      return {
        ...state,
        rateIds: [...state.rateIds, rate.id],
        rates: {
          ...state.rates,
          [rate.id]: rate
        }
      };
    }
    case budgetConstants.UPDATE_RATE.TRIGGER: {
      const rate = action.payload;
      return {
        ...state,
        rates: {
          ...state.rates,
          [rate.id]: rate
        }
      };
    }
    case budgetConstants.UPDATE_RATE.SUCCESS: {
      const rate = action.payload.response;
      return {
        ...state,
        rates: {
          ...state.rates,
          [rate.id]: rate
        }
      };
    }
    case budgetConstants.UPDATE_RATE.FAILURE: {
      const rate = action.payload.requestPayload;
      // undo optimistic update. Not all update_rate calls right now have original
      if (rate.original) {
        return {
          ...state,
          rates: {
            ...state.rates,
            [rate.id]: rate.original
          }
        };
      } else {
        return state;
      }
    }
    case budgetConstants.ARCHIVE_RATE.SUCCESS: {
      const rate = action.payload.response;
      return {
        ...state,
        rates: {
          ...state.rates,
          [rate.id]: rate
        }
      };
    }
    case budgetConstants.UNARCHIVE_RATE.SUCCESS: {
      const rate = action.payload.response;
      return {
        ...state,
        rates: {
          ...state.rates,
          [rate.id]: rate
        }
      };
    }
    default:
      return state;
  }
};

export default rates;
