import styled from 'styled-components';
import theme from 'theme';
import { reasonsHash } from 'views/boardDisplay/SuggestionsTable/util';
import { ReasonRowProps } from 'SuggestionModule/components/FindPeople/Table/helpers/types';
import { PlusCircleIcon } from 'icons/PlusMinusCircleIcon';
import NewCloseIcon from 'icons/NewCloseIcon';
import { useMemberOptions } from 'SuggestionModule/components/FindPeople/Table/helpers';

const ReasonHoursCell = ({ row }: ReasonRowProps) => {
  const {
    phase,
    phaseMembership,
    accountId,
    projectTotal,
    teamMembershipsByAccountId,
    isLast,
    customRowProps: {
      handleOpenMergeBudgetModal,
      projectId,
      unassignedMemberBudgetId,
      resetUnassignedRoleAndSelectedPhaseIds
    }
  } = row.original;
  const { openMenu, menuRef, MemberOptionMenu } = useMemberOptions({
    handleOpenMergeBudgetModal,
    resetUnassignedRoleAndSelectedPhaseIds,
    teamMembershipsByAccountId,
    accountId,
    unassignedMemberBudgetId,
    projectId,
    projectTotal
  });

  // SPEC: Only show the content for this cell when this is the last row
  if (!isLast) return <></>;

  // TODO when API ready
  const handleRejectSuggestedMember = () => undefined;

  return (
    <StyledContainer>
      <StyledIconsContainer>
        {/* When BE is ready */}

        {/* <StyledIcon
          data-tip="Reject & Hide <br /> Suggestion"
          data-for="app-tooltip"
          data-html
          data-effect="solid"
          className="reject-icon"
          onClick={handleRejectSuggestedMember}
        >
          <NewCloseIcon width={21} height={21} />
        </StyledIcon> */}
        <StyledIcon
          data-tip="Add to Project/Phase"
          data-for="app-tooltip"
          data-effect="solid"
          className="add-icon"
          ref={menuRef}
          onClick={openMenu}
        >
          <PlusCircleIcon />
        </StyledIcon>
      </StyledIconsContainer>
      <MemberOptionMenu />
    </StyledContainer>
  );
};

export default ReasonHoursCell;

const StyledIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StyledIcon = styled.div`
  cursor: pointer;

  &.reject-icon {
    circle {
      stroke: ${theme.colors.colorCalendarGray};
    }

    path {
      fill: ${theme.colors.colorCalendarGray};
      stroke: transparent;
    }

    &:hover {
      circle {
        fill: ${theme.colors.colorCalendarGray};
      }

      path {
        fill: ${theme.colors.colorPureWhite};
        stroke: transparent;
      }
    }
  }

  &.add-icon {
    &:hover {
      circle {
        fill: ${theme.colors.colorRoyalBlue};
      }

      path {
        stroke-width: 1px;
        stroke: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

const StyledContainer = styled.div``;
