import { useState, useCallback, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  getOnProjectPlanner,
  getMatchedRouteParams,
  getGroupsHash,
  getFlatPhasesHash
} from 'selectors';
import ColorPicker from 'components/ColorPicker';
import ProjectThreeDotMenu from 'views/projectDisplay/projectDetail/ProjectThreeDotMenu';
import Popover from 'components/Popover';

import {
  openWorkloadProjectRow,
  closeWorkloadProjectRow,
  navigateToProject,
  fetchProjectById,
  openEditProjectModal
} from 'actionCreators';
import KaratRight from 'icons/KaratRight';
import theme from 'theme';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import GoToProjectIcon from 'icons/GoToProjectIcon';

import { CONDENSED_ZOOM_LEVELS, VIEW_TYPE } from 'appConstants/workload';
import { StyledPhaseMilestoneIcon } from './styles';
import LockIcon from 'icons/LockIcon';
import { AddTaskPlus } from 'icons/AddTaskPlusGray';
import SmallHamburger from 'icons/SmallHamburger';
import TwoMembersIcon from 'icons/TwoMembersIcon';
import ProjectInfoIcon from 'icons/ProjectInfoIcon';
import { ReactComponent as DollarIcon } from 'icons/dollarSign.svg';
import { BoardIcon } from 'views/layoutStructuralComponents/MyProjectSideBarContainer/ProjectRow';
import MappingStatusIcon from 'components/MappingStatusIcon';
import { DATA_TYPES } from 'IntegrationsModule/constants';
import EllipsisText from 'components/EllipsisText';
import AddMembersDropdown from './AddMemberDropdown';
import { svgToDataURI } from 'appUtils/imageUtils';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const PhaseIconContainer = styled.span`
  color: #2f80ed;
  font-size: 12px;
  padding: 3px 5px;
  margin-right: 2px;
  margin-top: -4px;
  &.is-active {
    &:hover {
      background-color: ${theme.colors.colorTranslucentGray4};
    }
    cursor: pointer;
  }
  border-radius: 4px;
`;
export const StyledSmallHamburger = styled(SmallHamburger)`
  height: 13px;
  margin-top: -2px;
  width: 12px;
  path {
    fill: ${theme.colors.colorCalendarBlue};
  }
`;

export const AddMemberContainer = styled.div`
  line-height: 0;
  color: ${theme.colors.colorCalendarBlue};
  font-size: 13px;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
`;

const StyledAddPlus = styled(AddTaskPlus)`
  path {
    fill: ${theme.colors.colorCalendarBlue} !important;
    stroke: ${theme.colors.colorCalendarBlue} !important;
  }
`;

const StyledLockIcon = styled.img`
  margin-right: 4px;
  vertical-align: baseline;
`;

const BudgetButton = styled.div`
  border-radius: 100em;
  border: 0.5px solid transparent;
  color: ${({ theme }) => theme.colors.colorSemiDarkGray1};
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  margin-left: 7px;
  padding: 0 10px 2px;
  width: fit-content;
  &:hover {
    background: ${({ theme }) => theme.colors.colorTranslucentGray3};
  }
`;

const StyledGoToProjectIcon = styled(GoToProjectIcon)``;

const IconContainer = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;

const GoToProjectIconContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledColorContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

export const StyledDot = styled.div`
  margin-left: 2px;
  height: 8px;
  flex: 0 0 8px;
  margin-right: 8px;
  margin-top: 2px;
  border-radius: 10px;
  background: ${theme.colors.colorMediumGray1};
  background: ${calculateProjectColorPreference};
`;

const StyledTooltip = styled(ReactTooltip)`
  position: absolute !important;
  top: calc(50% - 50px) !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: fit-content;
`;

const StyledSubtext = styled.div`
  color: ${theme.colors.colorMediumGray5};
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
  padding-left: 18px;
  margin-top: -2px;
`;
export const EmptyCell = styled.div`
  height: 100%;
`;
export const StyledNameContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.2;
`;

const StyledProjectContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  width: 100%;
  padding-top: ${(props) =>
    // padding instead margin to not push this component into next row.
    props.isSmallCondensedView
      ? '7px'
      : props.isVerySmallCondensedView
      ? '5px'
      : '10px'};
  position: relative;
`;
export const StyledName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: ${theme.colors.colorSemiDarkGray1};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;
  &.member-count {
    position: absolute;
    top: ${({ isSmallCondensedView, isMediumCondensedView }) =>
      isSmallCondensedView
        ? '-11px'
        : (isMediumCondensedView && '0px') || '13px'};
  }
`;

const MenuContainer = styled.div`
  border-radius: 100px;
  height: 24px;
  width: 24px;
  margin-top: ${(props) =>
    props.isSmallCondensedView
      ? '3px'
      : props.isVerySmallCondensedView
      ? '1px'
      : '8px'};
  flex-basis: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background: ${({ isOpen }) =>
    isOpen ? theme.colors.colorPaleGray8 : 'transparent'};
  justify-content: center;
  &:hover {
    background: ${theme.colors.colorPaleGray8};
  }
  svg {
    height: 24px;
    width: 24px;
    path {
      stroke: ${theme.colors.colorMediumGray1};
      fill: ${theme.colors.colorMediumGray1};
    }
  }
`;
const StyledKaratContainer = styled.div`
  height: 20px;
  flex-basis: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) =>
      props.isSmallCondensedView
        ? '5px'
        : props.isVerySmallCondensedView
        ? '3px'
        : '8px'}
    2px 4px 5px;
  svg {
    transform-origin: center center;
    transition: 0.275s ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? `rotate(90deg)` : 'rotate(0deg)')};
    path {
      stroke-width: 1;
      stroke: ${theme.colors.colorSemiDarkGray1};
    }
  }
  &:hover {
    background: ${theme.colors.colorPaleGray6};
  }
`;

export const StyledCapacity = styled.div`
  line-height: 1;
  font-size: 14px;
  margin-top: 2px;
  position: absolute;
  top: 13px;
  color: ${theme.colors.colorCalendarBlue};
  &.with-member-count {
    position: unset;
    margin-top: ${({
      isSmallCondensedView,
      isVerySmallCondensedView,
      isMediumCondensedView
    }) =>
      isVerySmallCondensedView
        ? '-36px'
        : isSmallCondensedView
        ? '-33px'
        : isMediumCondensedView
        ? '-13px'
        : '0px'};
  }
  &.active {
    cursor: pointer;
  }
`;

export const ProjectCell = styled.div`
  display: flex;
  padding: 0px 4px;
  padding-top: ${({
    isSmallCondensedView,
    isMediumCondensedView,
    isVerySmallCondensedView,
    isSummary,
    isOnTeamSchedules
  }) =>
    isVerySmallCondensedView
      ? '0px'
      : isSmallCondensedView
      ? isOnTeamSchedules
        ? '8px'
        : isSummary
        ? '14px'
        : `5px`
      : isMediumCondensedView
      ? '9px'
      : '16px'};
  align-items: flex-start;
  line-height: 1.3;
  justify-content: flex-start;
  cursor: pointer;
  border-top: 1px solid ${theme.colors.colorPaleGray5};
  &:hover {
    ${StyledDot} {
      opacity: 1;
    }
    ${AddMemberContainer} {
      font-weight: 700;
    }
    ${StyledCapacity} {
      font-weight: 600;
    }

    ${BudgetButton} {
      border-color: ${({ theme }) => theme.colors.colorRoyalBlue};
      color: ${({ theme }) => theme.colors.colorRoyalBlue};
    }

    .three-dot-menu-wrapper path {
      fill: ${({ theme }) => theme.colors.colorRoyalBlue};
      stroke: ${({ theme }) => theme.colors.colorRoyalBlue};
    }
  }
`;

const StyledMenu = styled.div`
  cursor: pointer;
  .project-menu-item-container {
    width: 100%;
    padding: 5px 0px;
  }
  .project-menu-item {
    font-size: ${({ theme }) => theme.sizes.menuItemFontSize};
    font-weight: normal;
    color: ${({ theme }) => ` ${theme.colors.colorSemiDarkGray3}`};
    height: 27px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    :hover {
      background-color: ${({ theme }) =>
        ` ${theme.colors.colorTranslucentGray4}`};
    }
  }
`;

export const CloseTrigger = styled.button`
  position: absolute;
  left: 8px;
  width: 17px;
  height: 17px;
  outline: none;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 9px;
  font-weight: 600;
  background: ${theme.colors.colorCalendarBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const TopDetails = styled.div`
  position: absolute;
  top: 9px;
  left: 49px;
  color: ${theme.colors.colorMediumGray5};
  font-size: 10px;
  width: 201px;
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  white-space: nowrap;
  & > * {
    margin-right: 5px;
  }
  .show-on-hover {
    visibility: hidden;
  }
  ${ProjectCell}:hover & {
    text-overflow: ellipsis;
    .show-on-hover {
      visibility: visible;
    }
  }
`;
const MappingStatusContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 33px;
  line-height: 1.3;
  svg {
    width: 9px;
    height: 9px;
  }
`;

const ProjectMenuDivider = styled.div`
  width: 125px;
  height: 1px;
  margin: 8px auto;
  background: ${theme.colors.colorMediumGray11};
`;

export const AddMemberRowHeight = 400;

const WorkloadPlannerGroupRenderer = ({
  group,
  matchedParams: { teamSlug },
  openWorkloadProjectRow,
  closeWorkloadProjectRow,
  navigateToProject,
  isOnTeamSchedules,
  viewButtonClick,
  viewPhasesClick,
  openEditProjectModal,
  deactivateSplitScreen,
  activateSplitScreen,
  isSplitScreenActive,
  board,
  workloadViewType,
  condensedZoomLevel,
  handleOpenAddMembersForm,
  handleOpenFindPeopleModal,
  setShowMemberSuggestionsOnOpen
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const dispatch = useDispatch();
  const phaseHash = useSelector(getFlatPhasesHash);
  const phasesLength = group?.phases?.length;
  const phaseToEvaluate = phaseHash[group?.phases?.[0]];
  const isPhaseDefault =
    phasesLength === 1 &&
    phaseToEvaluate?.is_default_phase &&
    !phaseToEvaluate?.is_like_default;

  const openModal = useCallback(
    (e) => {
      e.stopPropagation();
      setModalIsOpen(true);
      dispatch(fetchProjectById(group.projectId));
    },
    [setModalIsOpen, dispatch, group]
  );
  const closeModal = useCallback(
    (e) => {
      e && e.stopPropagation();
      setModalIsOpen(false);
    },
    [setModalIsOpen]
  );
  const popoverTarget = useRef(null);

  const isCondensedView = workloadViewType === VIEW_TYPE.CONDENSED;

  if (!group?.id?.split) {
    return null;
  }

  if (group.isRoot) {
    const { isOpen, projectId } = group;
    const handleClick = group.isOpen
      ? closeWorkloadProjectRow
      : openWorkloadProjectRow;

    return (
      <ProjectCell
        className="team-member-cell app-cues-team-member-cell"
        data-for={'app-tooltip'}
        data-place="top"
        data-effect="solid"
        data-tip={group.title}
        data-offset="{'top': -25}"
        data-delay-show="250"
        data-testid={'project-group-cell'}
        onClick={() => handleClick({ projectId })}
        showBorder={!isOpen}
        isSmallCondensedView={
          isCondensedView && condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
        }
        isMediumCondensedView={
          isCondensedView && condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
        }
        isVerySmallCondensedView={
          isCondensedView &&
          condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
        }
        isOnTeamSchedules={isOnTeamSchedules}
      >
        {!isCondensedView && !group.isWorkloadSplitRoot && (
          <>
            <MappingStatusIcon
              id={group.project_id}
              dataType={DATA_TYPES.PROJECTS}
              Container={MappingStatusContainer}
            />
            <TopDetails>
              {group.project_number ? (
                <EllipsisText maxWidth={60} style={{ marginTop: 4 }}>
                  {group.project_number}
                </EllipsisText>
              ) : null}
              {board && (
                <EllipsisText
                  maxWidth={110}
                  style={{ marginTop: 4, flexGrow: 1 }}
                  className="show-on-hover"
                >
                  <BoardIcon />
                  {board.name}
                </EllipsisText>
              )}
              <PhaseIconContainer
                className={isPhaseDefault ? '' : 'is-active'}
                onClick={(e) =>
                  isPhaseDefault ? null : viewPhasesClick(projectId, e)
                }
              >
                {isPhaseDefault ? null : (
                  <>
                    <StyledPhaseMilestoneIcon
                      fill="transparent"
                      stroke={theme.colors.colorCalendarBlue}
                      marginTop="-3px"
                      height="14px"
                      width="13px"
                    />
                    {phasesLength}
                  </>
                )}
              </PhaseIconContainer>
            </TopDetails>
          </>
        )}
        <>
          <StyledKaratContainer
            isOpen={isOpen || false}
            className="app-cues-styled-karat"
            isVerySmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
            }
            isSmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
            }
          >
            <KaratRight />
          </StyledKaratContainer>
          <StyledProjectContainer
            isVerySmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
            }
            isSmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
            }
          >
            <StyledNameContainer>
              <StyledDot projectId={projectId}></StyledDot>
              <StyledColorContainer>
                <ColorPicker
                  projectId={projectId}
                  id={projectId}
                  originType={ORIGIN_TYPE_STRINGS.PROJECT}
                  boundariesElement="window"
                  pickerLocation={'workload-project-color-picker'}
                  row
                  className="color-picker-container"
                  stopPropagation
                />
              </StyledColorContainer>
              <StyledName id={projectId} color="#4a4a4a">
                {group.title}
              </StyledName>
            </StyledNameContainer>

            {group.description && !isCondensedView && (
              <StyledSubtext>{group.description}</StyledSubtext>
            )}
            {!isOnTeamSchedules && !isCondensedView && (
              <BudgetButton
                moveDown={group.description}
                isOpen={isOpen}
                onClick={(e) => {
                  e.stopPropagation();
                  viewButtonClick(projectId);
                }}
                data-for="app-tooltip"
                data-html
                data-effect="solid"
                data-tip={renderToStaticMarkup(
                  <>
                    <StyledLockIcon
                      src={svgToDataURI(<LockIcon height={10} />)}
                    />
                    Only Financial Managers and <br />
                    Admins can view Fee Budgets
                  </>
                )}
              >
                Budget
              </BudgetButton>
            )}
          </StyledProjectContainer>
          <MenuContainer
            isOpen={modalIsOpen}
            onClick={openModal}
            ref={popoverTarget}
            isVerySmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
            }
            isSmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
            }
          >
            <ProjectThreeDotMenu
              onCloseCallback={closeModal}
              projectTitle={group?.title}
            >
              <Popover
                isOpen={modalIsOpen}
                target={popoverTarget}
                closePopover={closeModal}
              >
                <StyledMenu>
                  <div className="project-menu-item-container">
                    {isCondensedView && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          viewButtonClick(projectId);
                          closeModal(e);
                        }}
                        className="project-menu-item"
                      >
                        <IconContainer>
                          <DollarIcon color={theme.colors.colorMediumGray9} />
                        </IconContainer>
                        View Budget
                      </div>
                    )}
                    <div
                      onClick={() => {
                        navigateToProject({
                          projectSlug: group.slug,
                          projectId,
                          teamSlug,
                          openInNewWindow: true
                        });
                      }}
                      className="project-menu-item"
                    >
                      <GoToProjectIconContainer>
                        <IconContainer>
                          <StyledGoToProjectIcon color="#4f4f4f" />
                        </IconContainer>
                        Go To Project
                      </GoToProjectIconContainer>
                    </div>
                    <div
                      onClick={(e) => {
                        closeModal(e);
                        handleOpenAddMembersForm(group);
                      }}
                      className="project-menu-item"
                    >
                      <IconContainer style={{ position: 'relative', top: -1 }}>
                        <TwoMembersIcon
                          width={15}
                          height={11}
                          viewBox="0 0 15 11"
                        />
                      </IconContainer>
                      <div>Manage Members</div>
                    </div>
                    <ProjectMenuDivider />
                    <div
                      onClick={(e) => {
                        viewPhasesClick(projectId);
                        closeModal(e);
                      }}
                      className="project-menu-item"
                    >
                      <IconContainer style={{ marginTop: 2 }}>
                        <StyledPhaseMilestoneIcon
                          stroke={theme.colors.colorMediumGray9}
                          height="14"
                          width="14"
                          fill="transparent"
                        />
                      </IconContainer>
                      Edit Phases
                    </div>
                    <div
                      onClick={(e) => {
                        closeModal(e);
                        openEditProjectModal(
                          group.project_id,
                          group.is_personal
                        );
                      }}
                      className="project-menu-item"
                    >
                      <IconContainer>
                        <ProjectInfoIcon />
                      </IconContainer>
                      Project Info
                    </div>
                  </div>
                </StyledMenu>
              </Popover>
            </ProjectThreeDotMenu>
          </MenuContainer>
          <StyledTooltip
            id={group.title}
            place="top"
            effect="solid"
            delayShow={200}
          />
        </>
      </ProjectCell>
    );
  } else if (group.isSummary && !isOnTeamSchedules) {
    const numberOfMembers = group.member_account_ids.length;
    return (
      <ProjectCell
        className="team-member-cell"
        isSplitScreenActive={isSplitScreenActive}
        isSummary
        onClick={() => activateSplitScreen(group.projectId)}
        isMediumCondensedView={
          isCondensedView && condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
        }
        isSmallCondensedView={
          isCondensedView && condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
        }
        isVerySmallCondensedView={
          isCondensedView &&
          condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
        }
      >
        <StyledNameContainer style={{ paddingLeft: 24, paddingTop: 31 }}>
          {(!isCondensedView ||
            condensedZoomLevel !== CONDENSED_ZOOM_LEVELS.VERY_SMALL) && (
            <StyledName
              className="member-count"
              isSmallCondensedView={
                isCondensedView &&
                condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
              }
              isMediumCondensedView={
                isCondensedView &&
                condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
              }
            >
              Total {numberOfMembers} Member
              {numberOfMembers === 1 ? '' : 's'}
            </StyledName>
          )}
          <StyledCapacity
            className="active with-member-count"
            isSmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.SMALL
            }
            isVerySmallCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.VERY_SMALL
            }
            isMediumCondensedView={
              isCondensedView &&
              condensedZoomLevel === CONDENSED_ZOOM_LEVELS.MEDIUM
            }
          >
            View Availability
          </StyledCapacity>
        </StyledNameContainer>
      </ProjectCell>
    );
    // add member filter here for project summary
  } else if (group?.isBottom) {
    return isOnTeamSchedules ? null : (
      <ProjectCell className="team-member-cell" style={{ paddingTop: 0 }}>
        <AddMembersDropdown
          group={group}
          projectId={group?.projectId}
          handleOpenAddMembersForm={handleOpenAddMembersForm}
          handleOpenFindPeopleModal={handleOpenFindPeopleModal}
          setShowMemberSuggestionsOnOpen={setShowMemberSuggestionsOnOpen}
        >
          <AddMemberContainer>
            <StyledAddPlus />
            Member
          </AddMemberContainer>
        </AddMembersDropdown>
      </ProjectCell>
    );
  } else {
    return <EmptyCell />;
  }
};

const mapStateToProps = (state, ownProps) => ({
  isOnProjectPlanner: getOnProjectPlanner(state),
  matchedParams: getMatchedRouteParams(state),
  board: getGroupsHash(state)[ownProps.group?.board_id]
});
const mapDispatchToProps = {
  openWorkloadProjectRow,
  closeWorkloadProjectRow,
  navigateToProject,
  openEditProjectModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkloadPlannerGroupRenderer);
