import styled from 'styled-components';
import theme from 'theme';
import NewMemberIcon from 'icons/new-team-member.svg';
import NewMemberIconWhite from 'icons/new-team-member-white.svg';
import SortIconUp from 'icons/SortUpIcon';
import CloseIcon from 'icons/CloseIcon';
import StageIcon from 'icons/StageIcon';
import PriorityFlagIcon from 'icons/PriorityFlagIcon';
import MilestoneIcon from 'icons/MilestoneIcon';
import PhaseMilestoneIcon from 'icons/PhaseMilestoneIcon';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { calculateProjectColorPreference } from 'appUtils/styleUtils';
import AddPlusIcon from 'icons/AddPlusIcon';
import { StyledTableRow } from 'components/Table/styles';
import clamp from 'lodash/clamp';
import { SORT_ORDER } from 'appConstants/filters';
import DeleteIcon from 'icons/DeleteIcon';

export const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  &:hover path {
    fill: ${theme.colors.colorDeleteRed};
  }
`;

export const StyledAddPlusIcon = styled(AddPlusIcon)``;
export const PlusMemberContainer = styled.div`
  width: 28px;
  flex: 0 0 28px;
  height: 28px;
  border-radius: 100px;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  background: ${theme.colors.colorMediumGray1};
  color: ${theme.colors.colorPureWhite};
  align-items: center;
  justify-content: center;
`;

export const AddPlusContainer = styled.div`
  width: 28px;
  flex: 0 0 28px;
  height: 28px;
  display: flex;
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid ${theme.colors.colorPaleGray5};
  background: ${theme.colors.colorPureWhite};
  &:hover {
    background: ${theme.colors.colorRoyalBlue};
    border: 1px solid ${theme.colors.colorRoyalBlue};
    ${StyledAddPlusIcon} {
      path {
        fill: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

export const StyledBudgetIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: -5px;
  .milestone-icon-box {
    stroke: ${theme.colors.colorMediumGray5};
    stroke-width: 1.5px;
  }
  .dollar-sign-in-icon {
    fill: ${theme.colors.colorMediumGray5};
  }
`;
export const StyledPhaseMilestoneIcon = styled(PhaseMilestoneIcon)`
  flex-basis: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 8px;
  path {
    stroke-width: 1.5px;
  }
`;
export const StyledBudgetPhaseMilestoneIcon = styled(BudgetPhaseMilestoneIcon)`
  flex-basis: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 1.5px;
  margin-right: 8px;
`;

export const AddViewIconContainer = styled.div`
  display: inline-block;

  &:hover:not(.disabled) {
    svg {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

export const StyledColorContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

export const StyledDot = styled.div`
  height: 10px;
  flex: 0 0 10px;
  margin-right: 8px;
  margin-top: 2px;
  border-radius: 10px;
  background: ${calculateProjectColorPreference};
`;
export const ClearSort = styled(CloseIcon)`
  height: 10px;
  width: 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  transition: all 0.25s ease-in-oßut;
`;

export const StyledSortIcon = styled(SortIconUp)`
  visibility: hidden;
  height: 6px;
  path {
    fill: ${theme.colors.colorRoyalBlue};
    stroke-width: 1;
  }
`;
export const HeaderText = styled.div`
  justify-content: center;
  align-self: center;
  margin-top: 2px;
  font-size: 13px;
  font-family: 'Proxima-nova';
  font-weight: 400;
  color: ${theme.colors.colorMediumGray5};
  white-space: pre;
  position: relative;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledProjectPriorityIcon = styled(PriorityFlagIcon)`
  path {
    box-shadow: 0px 0px 2px rgba(55, 55, 55, 0.25);
  }
`;
export const StyledProjectPriorityHeaderIcon = styled(PriorityFlagIcon)`
  path {
    stroke: ${theme.colors.colorPaleGray1};
    stroke-width: 2px;
    fill: transparent;
  }
`;
export const StyledProjectPriorityText = styled.div`
  margin-left: 8px;
`;
export const StyledProjectPriorityContainer = styled.div`
  color: ${theme.colors.colorSemiDarkGray1};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
`;

export const EmptyStateContainer = styled.div<{
  isBlue?: boolean;
  show?: boolean;
}>`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ isBlue }) => (isBlue ? 1 : 0.6)};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${HeaderText} {
    color: ${({ isBlue }) => (isBlue ? theme.colors.colorRoyalBlue : '')};
  }

  svg {
    path,
    line,
    circle,
    rect {
      stroke: ${({ isBlue }) => (isBlue ? theme.colors.colorRoyalBlue : '')};
    }
  }
  &:hover {
    opacity: 1;

    ${HeaderText} {
      color: ${theme.colors.colorRoyalBlue};
    }
    ${StyledProjectPriorityIcon} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
    svg {
      path,
      line,
      circle,
      rect {
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
  }
`;
export const EmptyProgressPill = styled.div`
  width: 100%;
  height: 18px;
  background: ${theme.colors.colorPaleGray2};
`;

export const BlankColumn = styled.td``;
export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  overflow: auto;
  height: calc(100vh - 150px);
  padding-bottom: 80px;
  @media print {
    overflow: visible;
  }

  table {
    margin: auto;
    border-collapse: separate;
    border-spacing: 2px 5px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .table-wrap {
    position: relative;
  }
  th,
  td {
    padding: 5px 15px;
    background-color: #fff;
    vertical-align: middle;
    position: relative;
  }
  td {
    padding: 0px;
  }
  th {
    box-shadow: 0px 0px 4px 2px ${theme.colors.colorTranslucentGray4};
  }
  td,
  th {
    :hover {
      box-shadow: 0px 0px 2px rgba(80, 80, 80, 0.2);
    }
  }
  .join-button-container {
    visibility: hidden;
  }
  .view-project-container {
    visibility: hidden;
  }
  ${BlankColumn} {
    background: transparent;
    &:hover {
      box-shadow: none;
    }
  }

  thead th {
    background-color: transparent;
  }
  /* safari and ios need the tfoot itself to be position:sticky also */
  tfoot,
  tfoot th,
  tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;

    z-index: 4;
  }
  tr {
    outline: none;
    th {
      .ico-drag-grip {
        opacity: 0;
        cursor: move;
      }
    }
    .project-item {
      .priority-icon,
      .my-unselected-star,
      .ico-drag-background {
        visibility: hidden;
        &.is-visible,
        &.hovered {
          visibility: visible;
        }
      }
      .mapping-status-icon {
        svg {
          display: none;
        }
        &.is-visible svg {
          display: unset;
        }
      }
    }
    &:hover {
      .project-item {
        .notification-toggle,
        .priority-icon,
        .my-unselected-star,
        .ico-drag-background {
          visibility: visible;
        }
        .mapping-status-icon svg {
          display: unset;
        }
      }
      th {
        .ico-drag-grip {
          opacity: 1;
        }
      }
    }
    &:hover {
      ${EmptyStateContainer} {
        visibility: visible;
      }
      .join-button-container {
        visibility: visible;
      }
      .view-project-container {
        visibility: visible;
      }
    }
  }
  a:focus {
  } /* testing links*/

  th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 32px;
    max-width: 720px;
    z-index: 2;
    padding: 5px 9px 5px 10px;

    border-right: 1px solid ${theme.colors.colorTranslucentGray4};
  }
  thead th:first-child,
  tfoot th:first-child {
    min-width: 370px;
    z-index: 5;
    background: ${theme.colors.colorTranslucentGray4};
    border-bottom: 1px solid ${theme.colors.colorLightGray9};
  }
`;

export const Box = styled.div`
  height: 90px;
  width: 130px;
  padding: 5px 15px;
  background-color: white;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    ${EmptyStateContainer} {
      visibility: visible;
    }
  }
`;

export const NoteCountContainer = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};
  font-weight: 400;
`;
export const ClientName = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};
  text-align: center;
`;
export const ReferenceNumber = styled.div`
  font-size: 13px;
  color: ${theme.colors.colorSemiDarkGray1};
  text-align: left;
`;

export const MembersContainer = styled.ul`
  padding: 0px;
  width: 100%;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  .member-initials {
    width: 28px;
    height: 28px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    padding: 0;
  }
`;

export const AddMemberIconContainer = styled.li`
  padding: 0px;
  margin: 0px;
`;

export const AddMemberIcon = styled.div`
  height: 18px;
  width: 18px;
  background: url(${NewMemberIcon}) no-repeat center;
`;

export const AddMemberButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0px;
  border: 1px solid ${theme.colors.colorPaleGray5};
  outline: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${theme.colors.colorRoyalBlue};
    ${AddMemberIcon} {
      background: url(${NewMemberIconWhite}) no-repeat center;
    }
  }
`;

export const MemberCountContainer = styled.div`
  font-size: 12px;
  margin-left: 0px;
  color: ${theme.colors.colorSemiDarkGray1};
`;

export const ProjectStatusContainer = styled.div`
  width: 330px;
  cursor: pointer;
  padding: 0px 15px;
`;
export const EmptyStatus = styled.div`
  font-size: 12px;
  color: ${theme.colors.colorMediumGray5};
  margin-left: 15px;
`;
const nameWidth = '300px + 20vw';
const updatesWidth = '330px';
export const ProjectNameHeader = styled.th`
  width: calc(${nameWidth});
`;

export const MainTableHeading = styled.th<{
  isSorted?: boolean;
  column?: string;
}>`
  padding-bottom: 0px;
  cursor: pointer;
  height: 50px;
  vertical-align: bottom;
  background: ${({ isSorted }) =>
    isSorted
      ? theme.colors.colorPureWhite
      : theme.colors.colorTranslucentGray4} !important;
  border-bottom: 1px solid ${theme.colors.colorLightGray9};

  ${HeaderText} {
    color: ${({ isSorted }) => (isSorted ? theme.colors.colorRoyalBlue : '')};
  }
  svg {
    display: none;
    path,
    line,
    rect,
    circle {
      stroke: ${({ isSorted }) =>
        isSorted ? theme.colors.colorRoyalBlue : ''};
    }
  }
  ${StyledSortIcon} {
    display: flex;
    position: absolute;
    right: -10px;
    bottom: 8px;
  }
  ${ClearSort} {
    display: flex;
    opacity: ${({ isSorted }) => (isSorted ? 1 : 0)};
    path {
      stroke: none;
    }
  }
  ${StyledProjectPriorityIcon} {
    path {
      fill: ${({ isSorted }) => (isSorted ? theme.colors.colorRoyalBlue : '')};
    }
  }

  z-index: 4;

  &:hover {
    background: ${theme.colors.colorPureWhite} !important;
    ${StyledSortIcon} {
      visibility: ${({ isSorted }) => (isSorted ? 'hidden' : 'visible')};
    }
  }
  &:hover {
    ${HeaderText} {
      color: ${theme.colors.colorRoyalBlue};
    }
    svg {
      path,
      line,
      rect,
      circle {
        stroke: ${theme.colors.colorRoyalBlue};
      }
    }
    ${ClearSort} {
      path {
        stroke: none;
      }
    }
    ${StyledProjectPriorityIcon} {
      path {
        fill: ${theme.colors.colorRoyalBlue};
      }
    }
  }

  ${(props) =>
    props.column === 'updates' &&
    `
      width: ${updatesWidth};
    `}
`;
export const BoardsColumnDropdownContainer = styled.th`
  vertical-align: bottom;
  font-size: 13px;
  font-weight: 400;
  z-index: 6;
  padding: 0px !important;
`;
export const MainTableHeader = styled.thead`
  th {
    position: sticky;
    top: 5px;
    border-spacing: 0px;
    border-collapse: collapse;
    vertical-align: bottom;
    &:hover {
      box-shadow: none;
    }

    @media print {
      background: ${theme.colors.colorPureWhite};
    }
  }
`;

export const LoadersContainer = styled.th`
  border-bottom: 0 !important;
`;
export const SpinnerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const AddMembersIconContainer = styled.div`
  background: transparent;
  border-radius: 100px;
  border: 1px solid ${theme.colors.colorPaleGray5};
  margin-right: 6px;
`;
export const AddMembersContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${theme.colors.colorSemiDarkGray5};
  cursor: pointer;

  svg {
    height: 26px;
    width: 26px;
  }

  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${AddMembersIconContainer} {
      background: ${theme.colors.colorRoyalBlue};
      border: 1px solid ${theme.colors.colorRoyalBlue};
      path {
        fill: ${theme.colors.colorPureWhite};
      }
      circle {
        stroke: ${theme.colors.colorPureWhite};
      }
    }
  }
`;

export const RemainingBarContainer = styled.div`
  height: 18px;
  display: flex;
  width: 100%;
  align-items: center;

  .remaining-bar-tooltip {
    text-align: center;
  }
`;
export const RemainingBar = styled.div<{
  barWidth?: number;
}>`
  flex-basis: ${({ barWidth }) => barWidth + '%'};
  min-width: 22px;
  font-size: 13px;
  padding-left: 2px;
  font-weight: 600;
  color: ${theme.colors.colorPureWhite};
  background: #25e1f0;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
`;

export const OverdueTasksContainer = styled.div`
  position: absolute;
  top: calc(50% + 14px);
  font-size: 13px;
  color: ${theme.colors.colorCalendarRed};
  padding-left: 0px;
  width: 100px;
`;

export const ColumnPropertyCell = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 5px 0px;
  font-size: 11px;

  &:hover {
  }
`;

export const DeleteConfirmationBoxContainer = styled.div`
  width: 100%;
  left: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  background: ${theme.colors.colorSemiDarkGray1};
  color: ${theme.colors.colorPureWhite};
  font-size: 13px;
  padding: 10px 30px;
  z-index: 1;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RemoveButton = styled.div`
  background: ${theme.colors.colorDeleteRed};
  padding: 5px 10px;
  cursor: pointer;
`;

export const CancelButton = styled.div`
  margin-right: 15px;
  cursor: pointer;
`;

export const StyledStageContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`;

export const StyledStageIcon = styled(StageIcon)<{
  hide?: boolean;
}>`
  height: 12px;
  width: 16px;
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
`;
export const StyledEmptyStageIcon = styled(StyledStageIcon)`
  path {
    fill: transparent;
    stroke: ${theme.colors.colorMediumGray1};
  }
`;

export const StyledProjectStatusContainer = styled.div`
  display: -webkit-box;
  -webkit-box-align: baseline;
`;

export const StyledProjectStatusDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 30px;
  background: ${({ color }) => color};
`;
export const StyledEmptyProjectStatusDot = styled(StyledProjectStatusDot)`
  background: transparent;
  border: 1px solid ${theme.colors.colorMediumGray1};
`;
export const StyledProjectStatusText = styled.div`
  flex-grow: 1;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  margin-left: 8px;
`;

export const StyledStageText = styled(StyledProjectStatusText)`
  margin-left: 2px;
`;

export const ItemCount = styled.div`
  margin-left: 2px;
`;

export const TooltipMessage = styled.div`
  font-size: 13px;
  line-height: 20px;
`;

export const BatchActionMenuWrapper = styled.div`
  position: absolute;
  left: 6px;
  top: 64px;
  min-width: 162px;
  display: flex;
  flex-flow: column nowrap;
  z-index: 100;
`;

export const BatchActionMenuItem = styled.div<{
  disabled?: boolean;
}>`
  position: relative;
  height: 15px;
  margin: 8px 0px;
  color: ${({ disabled }) =>
    disabled ? theme.colors.colorLightGray14 : '#0074d9'};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 13px;
  &:hover {
    font-weight: ${({ disabled }) => (disabled ? 'normal' : 'bold')};
  }
`;

export const BatchActionMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  margin-right: 9px;
`;

// The bar wont be displayed properly if the values are too close to the start or end
const getProgress = (currentProgress) => {
  if ([0, 100].includes(currentProgress)) return currentProgress;
  return clamp(currentProgress, 7, 94);
};

export const DateProgress = styled.div<{
  currentProgress: number;
}>`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: ${({ currentProgress }) => getProgress(currentProgress)}%;
  background: repeating-linear-gradient(
    120deg,
    transparent 1px,
    transparent 2px,
    ${theme.colors.colorLightGray12} 0px,
    ${theme.colors.colorLightGray12} 6px
  );
  border-radius: 100px;
  border-top-right-radius: ${({ currentProgress }) =>
    currentProgress === 100 ? '100px' : '0px'};
  border-bottom-right-radius: ${({ currentProgress }) =>
    currentProgress === 100 ? '100px' : '0px'};
  z-index: 0;
`;

export const DependencyLinkIconContainer = styled.div<{
  isMilestone?: boolean;
  isEndDate?: boolean;
}>`
  position: absolute;
  top: ${({ isMilestone }) => (isMilestone ? ' -5px' : '4px')};
  right: ${({ isEndDate, isMilestone }) =>
    isMilestone ? '95px' : isEndDate ? '-15px' : '133px'};
`;

export const DateDiv = styled.div<{
  isSetDate?: boolean;
}>`
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
  width: 64px;
  text-align: center;
  z-index: 1;
  color: ${theme.colors.colorPureBlack};
  &.start-date-div,
  &.set-date-phase {
    color: ${({ isSetDate }) => isSetDate && theme.colors.colorCalendarBlue};
  }
`;

export const SetDateLabel = styled.div`
  color: ${theme.colors.colorCalendarBlue};
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
`;

export const DateDash = styled.div`
  z-index: 1;
  color: ${theme.colors.colorPureBlack};
`;

export const DateLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
`;

export const DateInputDiv = styled.div<{
  noHover?: boolean;
  isSelected?: boolean;
  isPhase?: boolean;
}>`
  ${(props) => !props.noHover && `cursor: pointer;`}
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 132px;
  border-radius: 100px;
  position: relative;
  color: ${({ isSelected }) =>
    !isSelected ? ` ${theme.colors.colorRoyalBlue}` : ``};
  background: white;
  border: ${({ isSelected, isPhase }) =>
    isSelected && isPhase
      ? `1px solid ${theme.colors.colorLightGray12}`
      : `1px solid ${theme.colors.colorPureWhite}`};

  ${(props) =>
    !props.noHover &&
    `&:hover {
      color: ${theme.colors.colorRoyalBlue};
      border: ${({ isSelected, isPhase }) =>
        isSelected && isPhase
          ? `1px solid ${theme.colors.colorRoyalBlue}`
          : `1px solid ${theme.colors.colorPureWhite}`};
      ${DependencyLinkIconContainer} {
        path {
          stroke: ${theme.colors.colorRoyalBlue};
        }
      }
      span {
        color: ${theme.colors.colorRoyalBlue};
      }
    }`}

  &.budget-phase-progress-dates {
    &:hover {
      ${DateDiv} {
        color: ${theme.colors.colorRoyalBlue};
      }
      ${DateDash} {
        color: ${theme.colors.colorRoyalBlue};
      }
    }
    width: 131px;
    height: 18px;
    /* padding-top: 2px; */
    border-color: ${theme.colors.colorLightGray12};
    ${DateProgress} {
      height: 18px;
      top: -1px;
      background: repeating-linear-gradient(
        120deg,
        transparent 1px,
        transparent 2px,
        ${theme.colors.colorPaleGray4} 0px,
        ${theme.colors.colorPaleGray4} 5px
      );
    }
  }
`;

export const StyledQuestionIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 90px;
  margin-left: 4px;
  margin-top: 1px;
  height: 14px;
  width: 14px;
  background: ${theme.colors.colorLightGray7};
  border: 1px solid ${theme.colors.colorLightGray6};
  visibility: hidden;
`;

export const StyledDropdownItem = styled.div<{
  isDelete?: boolean;
}>`
  font-size: 14px;
  padding: 6px 12px;
  color: #4f4f4f;
  margin: 1px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  .delete-red {
    display: none;
  }
  .delete-gray {
    display: block;
  }
  &:hover {
    background: ${theme.colors.colorTranslucentGray4};
    color: ${({ isDelete }) => (isDelete ? theme.colors.colorDeleteRed : '')};
    ${StyledQuestionIconContainer} {
      visibility: visible;
    }
    .delete-gray {
      display: none;
    }
    .delete-red {
      display: block;
    }
  }
`;

export const StyledPhaseIconContainer = styled.div`
  padding-right: 5px;
  padding-bottom: 1px;
`;

export const StyledMilestoneIconContainer = styled.div`
  margin-left: 2px;
  padding-right: 3px;
  padding-bottom: 1px;
`;

export const StyledMilestoneIcon = styled(MilestoneIcon)`
  flex-basis: 14px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 2.5px;
  margin-right: 7px;
  margin-top: 3px;
  path {
    stroke-width: 1.5px;
  }
`;

export const StyledDragGrips = styled.div<{
  isDisabled?: boolean;
}>`
  margin-left: 10px;
  transform: translateX(-35px);
  visibility: ${(props) => (props.isDisabled ? 'hidden' : 'visible')};
`;

export const DeleteIconContainer = styled.div`
  position: relative;
  left: -5px;
  display: flex;
  opacity: 0;
  align-items: center;
  height: 70px;
  width: 30px;
  margin-right: -30px;
  display: flex;
  justify-content: flex-end;
  ${StyledTableRow}:hover & {
    opacity: 1;
  }
`;

export const ThreeDotIconContainer = styled.div<{
  isOpen?: boolean;
}>`
  position: absolute;
  left: -36px; /* Shift outside the right of the phase table item row */
  top: -4px;
  display: flex;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  align-items: center;
  display: flex;
  ${StyledTableRow}:hover & {
    opacity: 1 !important;
  }
`;

export const StyledDateSortIcon = styled(SortIconUp)`
  height: 10px;
`;

export const MilestoneTableStyler = styled.div`
  margin-top: -43px;
  margin-left: 18px;
  ${StyledTableRow} {
    align-items: center;
    margin-left: 35px !important;
    width: 605px !important;
    border-top: solid 3px #f5f5f5 !important;
    background: white;
  }
  .phaseUserActivityRow {
    border-top: none !important;
  }
  .archivedRow {
    background: transparent;
  }
  .table-headers-container {
    margin-left: 35px;
  }
`;

export const InactiveText = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  margin-top: 2px;
  margin-right: 5px;
`;

export const DependencyText = styled.div`
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

export const StyledAddPhaseAndMilestoneButton = styled.div`
  background-color: ${theme.colors.colorCalendarBlue};
  color: ${theme.colors.colorPureWhite};
  border-radius: 20px;
  width: 66px;
  text-align: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  height: 26px;
  padding-top: 5px;
`;
export const StatusContainer = styled.div`
  bottom: calc(100% - 0.4em);
  position: absolute;
  display: flex;
  left: 19px;
`;

export const StyledSortIconContainer = styled.div<{
  isActive?: boolean;
  order?: string;
}>`
  height: 14px;
  width: 14px;
  margin-right: -14px;
  transition: transform 0.275s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  transform: ${({ order }) =>
    order === SORT_ORDER.asc ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const HeaderContainer = styled.div<{
  isActive?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  transform: translateY(-4px);
  color: ${({ isActive }) =>
    isActive ? theme.colors.colorRoyalBlue : theme.colors.colorCalendarGray};
  &:hover {
    color: ${theme.colors.colorRoyalBlue};
    ${StyledSortIconContainer} {
      visibility: visible;
    }
  }
`;
