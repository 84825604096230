import { createSelector } from 'reselect';
import moment from 'moment';
import sum from 'lodash/sum';
import { initialState as teamCapacitiesInitialState } from '../reducers/teamCapacities';
import { initialState as accountCapacitiesInitialState } from '../reducers/accountCapacities';
import { initialState as holidaysInitialState } from '../reducers/holidays';
import { initialState as holidayGroupsInitialState } from '../reducers/holidayGroups';
import {
  initialState as initialCapacityReportState,
  initialFilterState as initialCapacityReportFilterState
} from '../reducers/capacityReport';
import {
  getWorkloadModalCapacityId,
  getPlannerOpenProjects,
  getProjectHash,
  getActiveWorkloadPlannerFilter
} from 'selectors';

import { CAPACITY_DATE_KEYS, VIEW_BY } from 'appConstants/workload';
import { BUDGET_RECORD_DATA_TYPES as DATA_TYPES } from 'appConstants';
import { serializeId } from 'appUtils';
import { formatData } from 'CapacityModule/utils';
import { DEFAULT_DATE_FORMAT } from 'appUtils/dateRangeHelpers';
import { MOMENT_ISO_DATE, MOMENT_USA_DATE } from 'appConstants/date';

const emptyObj = {};
const emptyArray = [];
export const getTeamCapacitiesState = (state) =>
  state.teamCapacities || teamCapacitiesInitialState;
export const getTeamCapacity = createSelector(
  getTeamCapacitiesState,
  (state) => state.capacity
);
export const getTeamCapacityId = createSelector(
  getTeamCapacity,
  (capacity) => capacity.id
);

export const getIsFetchingTeamCapacities = createSelector(
  getTeamCapacitiesState,
  (state) => state.isFetching
);

export const getAccountCapacitiesState = (state) =>
  state.accountCapacities || accountCapacitiesInitialState;
export const getAccountCapacities = createSelector(
  getAccountCapacitiesState,
  (state) => state.accountCapacities
);

export const getCapacityReportState = (state) =>
  state.capacityReport || initialCapacityReportState;

const calculateAverageCapacity = (accountCapacity) => {
  const nonZeroCapacities = CAPACITY_DATE_KEYS.map(
    (dateKey) => accountCapacity[dateKey]
  ).filter((capacity) => +capacity);
  return nonZeroCapacities.length
    ? sum(nonZeroCapacities) / nonZeroCapacities.length
    : 8;
};
export const getAverageCapacities = createSelector(
  getAccountCapacities,
  (accountCapacities) =>
    Object.values(accountCapacities).reduce(
      (averageCapacities, accountCapacity) => {
        averageCapacities[accountCapacity.account_id] =
          calculateAverageCapacity(accountCapacity);
        return averageCapacities;
      },
      {}
    )
);

const getAccountCapacitySummaryHash = (accountIds, accountCapacities) => {
  const capacitySummaryHash = {};
  CAPACITY_DATE_KEYS.forEach((dateKey) => {
    capacitySummaryHash[dateKey] = 0;
  });
  accountIds.forEach((accountId) => {
    const accountCapacity = accountCapacities[accountId] ?? {};
    CAPACITY_DATE_KEYS.forEach((dateKey) => {
      capacitySummaryHash[dateKey] += accountCapacity[dateKey] || 0;
    });
  });
  return capacitySummaryHash;
};

export const getAccountCapacitiesSummary = createSelector(
  getAccountCapacities,
  getActiveWorkloadPlannerFilter,
  (accountCapacities, filter) => {
    const accountIds = filter?.account_ids ?? emptyArray;
    const capacitySummaryHash = getAccountCapacitySummaryHash(
      accountIds,
      accountCapacities
    );
    return capacitySummaryHash;
  }
);
export const getAccountCapacitiesWithSummary = createSelector(
  getAccountCapacities,
  getAccountCapacitiesSummary,
  (accountCapacities, capacitySummary) => ({
    ...accountCapacities,
    summary: capacitySummary
  })
);

export const getProjectCapacities = createSelector(
  getPlannerOpenProjects,
  getAccountCapacities,
  getProjectHash,
  (openProjects, accountCapacities, projectHash) => {
    const openProjectIds = Object.entries(openProjects)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);
    const projectCapacityHash = {
      id: 'summary'
    };
    openProjectIds.forEach((projectId) => {
      const accountIds = projectHash[projectId]?.member_account_ids ?? [];
      const capacitySummaryHash = getAccountCapacitySummaryHash(
        accountIds,
        accountCapacities
      );
      projectCapacityHash[projectId] = capacitySummaryHash;
    });
    return projectCapacityHash;
  }
);

export const getAccountWorkloadCapacity = createSelector(
  getWorkloadModalCapacityId,
  getAccountCapacities,
  (modalId, accountCapacities) => accountCapacities[modalId]
);

export const getHolidaysState = (state) =>
  state.holidays || holidaysInitialState;
export const getHolidayOrder = createSelector(
  getHolidaysState,
  (state) => state.holidayOrder
);
export const getHolidaysHash = createSelector(
  getHolidaysState,
  (state) => state.holidays
);

export const getHolidaysArray = createSelector(
  getHolidayOrder,
  getHolidaysHash,
  (order, holidays) => order.map((id) => holidays[id]) || emptyArray
);

export const getHolidayModalOpen = createSelector(
  getHolidaysState,
  (state) => state.modalOpen
);
export const getHolidayModalId = createSelector(
  getHolidaysState,
  (state) => state.modalHolidayId
);
export const getHolidayModalHolidayIsNew = createSelector(
  getHolidaysState,
  (state) => state.modalHolidayIsNew
);
export const getHolidayModalHolidayIsRemove = createSelector(
  getHolidaysState,
  (state) => state.modalHolidayIsRemove
);

export const getIsFetchingHolidays = createSelector(
  getHolidaysState,
  (state) => state.isFetching
);

export const getHolidayGroupsState = (state) =>
  state.holidayGroups || holidayGroupsInitialState;

export const getIsFetchingHolidayGroups = createSelector(
  getHolidayGroupsState,
  (state) => state.isFetching
);

export const getHolidayGroupsHash = createSelector(
  getHolidayGroupsState,
  (state) => state.groupsHash
);

export const getHolidayGroupsOrder = createSelector(
  getHolidayGroupsState,
  (state) => state.groupsOrder
);

export const getOrderedHolidayGroups = createSelector(
  getHolidayGroupsHash,
  getHolidayGroupsOrder,
  (groups, order) => order.map((id) => groups[id])
);

export const getHolidayModalHoliday = createSelector(
  getHolidayModalId,
  getHolidaysHash,
  (id, holidays) => holidays[id]
);

export const getHolidayDatesHash = createSelector(
  getHolidaysArray,
  (holidays) =>
    holidays.length
      ? holidays.reduce((dateHash, holiday) => {
          dateHash[
            moment(holiday.start_date, DEFAULT_DATE_FORMAT).format(
              MOMENT_USA_DATE
            )
          ] = true; // match BE
          return dateHash;
        }, {})
      : emptyObj
);

// it is temporary selector until we have breakdown capacity/utilization APIs
// to get the hours directly from server
export const getHolidayISODatesHash = createSelector(
  getHolidaysArray,
  (holidays) =>
    holidays.length
      ? holidays.reduce((dateHash, holiday) => {
          const parsedDate = moment(holiday.start_date, DEFAULT_DATE_FORMAT);
          dateHash[parsedDate.format(MOMENT_ISO_DATE)] = {
            dayOfWeek: parsedDate.format('dddd').toLowerCase()
          };
          return dateHash;
        }, {})
      : emptyObj
);

export const getCapacityReportData = (state) => state.capacityReport.data;

const getOwnFilterStateId = (state, ownProps) =>
  ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id;
const getOwnIsShowingPto = (state, ownProps) =>
  ownProps?.activeFilter?.custom?.showPto;
const getOwnIsShowingHolidays = (state, ownProps) =>
  ownProps?.activeFilter?.custom?.showHolidays;
const getOwnViewBy = (state, ownProps) => {
  return ownProps?.viewBy;
};

export const makeGetCapacityReportDataByFilter = () =>
  createSelector(
    getOwnFilterStateId,
    getCapacityReportState,
    (filterStateId, state) =>
      state.filterStates[filterStateId] || initialCapacityReportFilterState
  );

export const makeGetDemandHoursListByViewBy = () =>
  createSelector(
    makeGetCapacityReportDataByFilter(),
    getCapacityReportData,
    getOwnViewBy,
    (filterState, data, viewBy) => {
      const topLevelOrder = filterState.topLevelOrder;
      const projectData = {};
      topLevelOrder.forEach((topLevelId) => {
        const uid = serializeId({
          ids: [topLevelId],
          itemType:
            viewBy === VIEW_BY.MEMBERS ? DATA_TYPES.ACCOUNT : DATA_TYPES.CLIENT
        });

        const projectOrders = filterState.ordersByGroup[uid] || [];
        projectOrders.forEach((projectId) => {
          const accountProjectUid = serializeId({
            ids: [topLevelId, projectId],
            itemType:
              viewBy === VIEW_BY.MEMBERS
                ? DATA_TYPES.ACCOUNT_PROJECT
                : DATA_TYPES.CLIENT_PROJECT
          });

          if (!projectData[uid]) {
            projectData[projectId] = {
              project_id: projectId,
              demand: 0
            };
          }

          projectData[projectId].demand +=
            +data[accountProjectUid]?.demand || 0;
        });
      });

      return Object.values(projectData);
    }
  );

export const makeGetProjectBreakdownDataByView = () =>
  createSelector(
    makeGetCapacityReportDataByFilter(),
    getOwnViewBy,
    getCapacityReportData,
    (filterState, viewBy, data) => {
      const topLevelOrder = filterState.topLevelOrder;
      const projectData = {};
      topLevelOrder.forEach((topLevelId) => {
        const uid = serializeId({
          ids: [topLevelId],
          itemType:
            viewBy === VIEW_BY.MEMBERS ? DATA_TYPES.ACCOUNT : DATA_TYPES.CLIENT
        });

        const projectOrders = filterState.ordersByGroup[uid] || [];
        projectOrders.forEach((projectId) => {
          const projectUid = serializeId({
            ids: [topLevelId, projectId],
            itemType:
              viewBy === VIEW_BY.MEMBERS
                ? DATA_TYPES.ACCOUNT_PROJECT
                : DATA_TYPES.CLIENT_PROJECT
          });

          if (!projectData[uid]) {
            projectData[uid] = {};
          }

          projectData[uid][projectId] = data[projectUid];
        });
      });

      return projectData;
    }
  );

export const makeGetProjectIdsListByViewBy = () =>
  createSelector(
    makeGetCapacityReportDataByFilter(),
    getOwnViewBy,
    (filterState, viewBy) => {
      const topLevelOrder = filterState.topLevelOrder;
      const projectIds = [];
      topLevelOrder.forEach((topLevelId) => {
        const uid = serializeId({
          ids: [topLevelId],
          itemType:
            viewBy === VIEW_BY.MEMBERS ? DATA_TYPES.ACCOUNT : DATA_TYPES.CLIENT
        });

        const projectOrder = filterState.ordersByGroup[uid] || [];
        projectOrder.forEach((projectId) => {
          projectIds.push(projectId);
        });
      });
      return [...new Set(projectIds)];
    }
  );

export const makeGetCapacityIntervalRecordsByFilter = () =>
  createSelector(
    makeGetCapacityReportDataByFilter(),
    (filterState) => filterState.intervalRecords
  );

const stackCapacityData = (data) => {
  // the value will be undefined when the option is off
  const stackedHolidayPercent = +data.holiday_percent || 0;
  const stackedPtoPercent = stackedHolidayPercent + (+data.pto_percent || 0);
  const stackedDemandPercent =
    stackedPtoPercent + +data.percentage_of_available_capacity_planned;

  return {
    ...data,
    stackedDemandPercent,
    stackedPtoPercent,
    stackedHolidayPercent
  };
};

const formatStackedCapacityData = (state, showPto, showHolidays) => {
  const formattedDataArray = state.intervalRecords.map((total) =>
    formatData(total, !showPto, !showHolidays)
  );
  const stackedData = formattedDataArray.map((data) => stackCapacityData(data));

  return { ...state, intervalRecords: stackedData };
};

export const makeGetStackedCapacityIntervalRecordsByFilter = () =>
  createSelector(
    makeGetCapacityReportDataByFilter(),
    getOwnIsShowingPto,
    getOwnIsShowingHolidays,
    formatStackedCapacityData
  );
