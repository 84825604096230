import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import {
  initialState as homeInitialState,
  initialFilterState as homeInitialFilterState
} from 'reducers/home';
import { initialFilterState as homeTasksInitialFilterState } from 'reducers/homeTasks';
import { integrationsHash } from 'IntegrationsModule/constants';

import { initialState as timersInitialState } from 'reducers/timers';

import { isOverdue, keyifyDate } from 'appUtils/plannerUtils';
import includes from 'lodash/includes';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/orderBy';
import flatten from 'lodash/flatten';
import pickBy from 'lodash/pickBy';
import { makeIdHash } from 'appUtils';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import partition from 'lodash/partition';
import invert from 'lodash/invert';
import sum from 'lodash/sum';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import { createTimesheetWeekKeys } from 'appUtils/timesheetUtils';
import * as taskRemoveTypes from 'appConstants/taskRemoveTypes';
import * as appModuleUtils from 'appUtils/appModuleUtils';
import * as boardModuleUtils from 'appUtils/boardModuleUtils';
import * as membersModuleUtils from 'appUtils/membersModuleUtils';
import * as workloadModuleUtils from 'appUtils/workloadModuleUtils';
import * as membersSettingsModuleUtils from 'appUtils/membersSettingsModuleUtils';
import * as standardSettingsModuleUtils from 'appUtils/standardSettingsModuleUtils';
import * as integrationsModuleUtils from 'appUtils/integrationsModuleUtils';
import { isOnHomePlanner } from 'appUtils/views';
import {
  getTeamsState,
  getAuth,
  getTimesheetsState,
  getMe,
  getSplitFlags
} from './core';
import { getCurrentUserId, getUserIsAdmin } from './user';
import { SORT_BY } from 'appConstants/filters';
import { VIEW_BY } from 'appConstants/workload';
import { makeSuggestedProjectHash, getFlatPhasesHash } from './phases';
import {
  getProjectHash,
  makeGetProjectById,
  makeGetProjectBasicInfoById,
  getSelectedProject,
  getOwnProjectId,
  getProjectsState,
  getEditingProjectId,
  getAllFetchedProjects,
  getAllFetchedProjectsArray,
  getSelectedProjectId,
  getOOOProject,
  getWFHProject
} from './projects';
import {
  getPlannerModalAccountId,
  getPlannerProjectsByMember,
  getPlannerMemberIds,
  getPlannerModalSchedules,
  getPlannerMembersArray,
  getTaskSidebarProjectId,
  getPlannerSchedules
} from './planner';
import {
  getAllTeams,
  getSelectedTeam,
  getTeamMembers,
  getAllTeamMembers,
  getAllTeamMembersWithArchived,
  getTeamMembersHash
} from './team';
import {
  getIsOnPersonalProject,
  getIsOnOwnTimesheets,
  getIsOnProjectView,
  getIsOnTeamProject,
  getOnHomeView,
  getIsOnTeamSettingsView,
  getIsOnMembersSettings,
  getMatchedRouteParams,
  getNavigationHistory,
  getOnActivityFeed,
  getOnBoardView,
  getOnMembersView,
  getOnWorkloadView,
  getIsOnScheduleView,
  getIsOnReportsView,
  getOnProjectDetail,
  getOnSettings,
  getRouterLocation,
  getOnScheduleView,
  getOnProjectPlanner,
  getOnTeamPlannerTasks,
  getTrackedNavigations,
  isOnTeamMemberProfile,
  getIsMemberModalOpen,
  getIsOnHomeCompletedTasks,
  getIsOnHomeNotifications,
  getIsOnDashboardView
} from './navigations';
import {
  getOwnTimesheets,
  getSubmissionDates,
  getPlannerModalDates,
  getDescriptions,
  getAllActivityRowInfo,
  getAllDescriptionInfo,
  getAllDescriptions,
  getAllTimesheetsByDescription,
  getDisplayedDescriptions,
  getPersonalBoards
} from './timesheet';

import {
  makeGetActiveWorkloadPlannerFilter,
  makeGetActiveWorkloadPlannerFilterIdHashes
} from './filters';

import {
  isTodayOn,
  daysFromToday,
  isToday,
  isSameDay
} from 'appUtils/momentUtils';

import { MODAL_TYPE } from 'appConstants/addMemberForm';
import { APP_CUES, RATE_TYPES } from 'appConstants';
import { REPORT_VIEW_TYPE } from 'appConstants/navigation';
import { sortTeamMembers } from 'TeamsModule/selectors';
import { getCurrentAccount } from 'AuthenticationModule/selectors';
import { TASK_RESCHEDULE_LABEL } from 'views/Home/TaskList/constants';
import { permissionsUtils } from 'PermissionsModule/utils';
import { ACCESS_ROLES } from 'PermissionsModule/constants';

const emptyArray = [];
const emptyObj = {};
const byId = (item) => item && item.id;
const emptyArrayMaker = () => emptyArray;
const byAccountId = (item) => item && item.account && item.account.id;
/*
https://github.com/reduxjs/reselect
Use plain functions as selectors for items explicitly in state
e.g. state.home.myStarredProjects

Use createSelector to calculate or derive
e.g. combining different parts of state, doing math, etc.
*/
const getOwnAccountId = (state, ownProps) =>
  ownProps.accountId || ownProps.account_id;

const getOwnFilterStateId = (state, ownProps) =>
  ownProps?.filterStateId || ownProps?.filterId || ownProps?.activeFilter?.id;

const getOwnPhase = (_, ownProps) => ownProps?.phase;

const getOwnActivity = (_, ownProps) => ownProps?.activity;

const getOwnDay = (_, ownProps) => ownProps?.day;

export const getNotes = (state) => state.notes || emptyObj;

export const getActivityFeedNotes = createSelector(
  getNotes,
  (notes) => notes.activityFeedNotes || emptyObj
);
export const getNotesSelectedAccountIds = createSelector(
  getNotes,
  (notes) => notes.selectedAccountIds || emptyArray
);
export const getNotesSelectedTagIds = createSelector(
  getNotes,
  (notes) => notes.selectedTagIds || emptyArray
);
export const getSettings = (state) => state.settings;

export const showInviteButton = createSelector(
  getSettings,
  (settings) => settings.showInviteButton
);
export const getInviteFormOpen = createSelector(
  getSettings,
  (settings) => settings.showInviteForm
);

export const getProjectGroupMemberInputOpen = createSelector(
  getSettings,
  (settings) => settings.projectGroupMemberInputOpen
);

export const getAdminBudgetTableSort = createSelector(
  getSettings,
  (settings) => settings.adminBudgetTableSort
);

export const getGlobalMenuInviteFormOpen = getInviteFormOpen;

export const getIsHelpMenuOpen = createSelector(
  getSettings,
  (settings) => settings.isHelpMenuOpen
);

export const getAddMembersForm = (state) => state.addMembersForm;
export const getAddMembersFormIsOpen = createSelector(
  getAddMembersForm,
  (addMembersForm) => addMembersForm.isOpen
);
export const getAddMembersFormLocation = createSelector(
  getAddMembersForm,
  (addMembersForm) => addMembersForm.formLocation
);
export const getAddMembersFormModalType = createSelector(
  getAddMembersForm,
  (addMembersForm) => addMembersForm.modalType
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 */
export const getTeamMembershipsByAccountId = createSelector(
  getAllTeams,
  (allTeams) =>
    (allTeams &&
      allTeams[0] &&
      allTeams[0].team_members &&
      keyBy(allTeams[0].team_members, byAccountId)) ||
    emptyObj
);

export const getAddMembersFormProject = createSelector(
  getAddMembersForm,
  (addMembersForm) => addMembersForm.project
);
export const getBulkAddIsPopulated = createSelector(
  getAddMembersForm,
  (addMembersForm) => addMembersForm.bulkAddIsPopulated
);
export const getBulkAddIsOpen = createSelector(
  getAddMembersForm,
  (addMembersForm) => addMembersForm.bulkAddIsOpen
);

export const getFromBoardViewText = (state) =>
  state.globalReducer.fromBoardViewText;

export const getReloaderScreenOpen = (state) =>
  state.globalReducer.reloaderScreen;
export const getGlobalAddOpen = (state) => state.globalReducer.globalAddOpen;
export const getTaskGlobalAddOpen = (state) =>
  state.globalReducer.taskGlobalAddOpen;
export const getIsGeneratingUrl = (state) => state.downloadFile.isGeneratingUrl;
export const getSelectedGroupId = (state) => state.groups.selectedGroupID;
export const getGroupsState = (state) => state.groups;
export const getBoardsSkeletons = createSelector(
  getGroupsState,
  (state) => state.boardsSkeletons
);
export const getMemberListByAccountId = createSelector(
  getGroupsState,
  (state) => keyBy(state.memberList, byAccountId) || emptyObj
);
const getOwnBoardId = (state, ownProps) => ownProps.boardId;

export const makeGetProjectIdsByBoardId = (getters = emptyObj) =>
  createSelector(
    getters.boardIdGetter || getOwnBoardId,
    getBoardsSkeletons,
    (boardId, boardsSkeletons) => boardsSkeletons[boardId] || emptyArray
  );

export const getProjectCounts = createSelector(
  getGroupsState,
  (state) => state.projectCounts || emptyObj
);

export const getIsFetchingBoardProjects = createSelector(
  getGroupsState,
  (state) => state.isFetchingBoardProjects
);
export const makeGetProjectCountByBoardId = () =>
  createSelector(
    getOwnBoardId,
    getProjectCounts,
    (boardId, projectCounts) => projectCounts[boardId]
  );
export const makeGetProjectsByBoardId = (getters = emptyObj) =>
  createSelector(
    makeGetProjectIdsByBoardId(getters),
    getProjectHash,
    (projectIds, projectHash) => projectIds.map((id) => projectHash[id])
  );

export const getBoardRoles = createSelector(
  getGroupsState,
  (state) => state.roles
);
export const getBoardSortProperty = createSelector(
  getGroupsState,
  (state) => state.sortProperty
);

export const getAllGroups = (state) => state.groups.groupList;
export const getNonAdminGroups = createSelector(getAllGroups, (groups) =>
  groups.filter((board) => !board.is_administrative)
);

const getGroupsHash = createSelector(getAllGroups, (groups) =>
  keyBy(groups, byId)
);

export const getEditingGroupId = (state) => state.groups.editingGroup;

export const getAllProjectBoardIds = createSelector(
  getAllGroups,
  (boards) =>
    boards?.reduce((acc, cur) => {
      if (!cur) {
        return acc;
      }
      // eslint-disable-next-line no-unused-expressions
      cur.project_position?.forEach((projectId) => {
        acc[projectId] = cur.id;
      });
      // eslint-disable-next-line no-unused-expressions
      cur.archived_project_position?.forEach((projectId) => {
        acc[projectId] = cur.id;
      });
      return acc;
    }, {}) ?? emptyObj
);

export const getAllProjects = createSelector(
  getProjectsState,
  (state) => state.allProjects
);

// project hash does not contain full project data
// use this with fetchProjectById which fetches full projectMembership data
export const makeGetFullProjectDataById = () =>
  createSelector(
    getAllProjects,
    (_, ownProps) => ownProps.projectId,
    (allProjects, projectId) =>
      allProjects.find((project) => project.id === projectId)
  );
export const getIsFetchingProjects = createSelector(
  getProjectsState,
  (state) => state.isFetchingProjects
);

export const getMaxRemainingTasksCount = (state) =>
  state.projects.maxRemainingTasksCount;
export const getIsTeamMemberModalOpen = (state) =>
  state.groups.isTeamMemberModalOpen;

export const getSelectedGroup = createSelector(
  [getSelectedGroupId, getAllGroups, getPersonalBoards],
  (selectedGroupId, groups, personalBoards) =>
    selectedGroupId &&
    (groups.find((group) => group.id === selectedGroupId) ||
      personalBoards.find((group) => group.id === selectedGroupId))
);

export const getShouldStayOnViewAfterCreate = (state) =>
  state.groups.shouldStayOnViewAfterCreate;
export const getEditingGroup = createSelector(
  [getEditingGroupId, getAllGroups],
  (editingGroupId, groups) =>
    groups.find((group) => group.id === editingGroupId)
);

export const getEditingProject = createSelector(
  [getEditingProjectId, getAllProjects],
  (editingProjectId, projects) =>
    projects.find((project) => project.id === editingProjectId)
);

export const getTimersState = (state) => state.timers || timersInitialState;

export const getAllTimers = createSelector(
  getTimersState,
  (state) => state.timers
);

export const getIsTimerDrawerOpen = createSelector(
  getTimersState,
  (state) => state.isTimerDrawerOpen
);

export const getIsTimerDeleteModalOpen = createSelector(
  getTimersState,
  (state) => state.isTimerDeleteModalOpen
);

export const getTimerToDelete = createSelector(
  getTimersState,
  (state) => state.timerToDelete
);

export const getTimerDate = createSelector(
  getTimersState,
  (state) => state.timerDate
);

export const getActiveTimer = createSelector(
  getTimersState,
  (state) => state.activeTimer
);

export const getTimersByTaskId = createSelector(getAllTimers, (timers) =>
  keyBy(timers, 'task_id')
);

export const getMergedTimers = createSelector(
  getAllTimers,
  getActiveTimer,
  (timers, activeTimer) => {
    const timersByCheckinId = groupBy(timers, 'check_in_id');
    Object.keys(timersByCheckinId).forEach((timerKey) => {
      const timersToFormat = groupBy(timersByCheckinId[timerKey], 'task_id');
      const formattedTimers = {};
      Object.keys(timersToFormat).forEach((key) => {
        const initialTimerDurations = {
          duration: 0,
          lastDuration: 0,
          isActive: false,
          timers: {}
        };
        const timerDurations = timersToFormat[key].reduce((result, timer) => {
          result.duration += timer.user_custom_duration || timer.duration;
          result.lastDuration = timer.user_custom_duration || timer.duration;
          result.isActive =
            result.isActive || (activeTimer && timer.id === activeTimer.id);
          result.timers[timer.id] = true;
          return result;
        }, initialTimerDurations);
        const timerTemplate = timersToFormat[key][0];
        formattedTimers[key] = {
          task_id: isNaN(key) ? null : key,
          check_in_id: timerKey,
          timers: timerDurations.timers,
          isActive: timerDurations.isActive,
          duration: timerDurations.duration,
          lastDuration: timerDurations.lastDuration,
          activity_phase_id: timerTemplate.activity_phase_id,
          activity_id: timerTemplate.activity_id,
          phase_id: timerTemplate.phase_id,
          project_id: timerTemplate.project_id,
          date: timerTemplate.date,
          account_id: timerTemplate.account_id
        };
      });
      timersByCheckinId[timerKey] = formattedTimers;
    });
    return timersByCheckinId;
  }
);

export const getHomeState = (state) => state.home || homeInitialState;

export const getCreatedCheckins = createSelector(
  getHomeState,
  (state) => state.createdCheckins
);

export const getCheckins = createSelector(
  getHomeState,
  (state) => state.checkins
);
export const getCheckinsHours = createSelector(
  getHomeState,
  (state) => state.checkinsHours
);

export const makeGetCheckinFilterState = () =>
  createSelector(
    getOwnFilterStateId,
    getHomeState,
    (filterStateId, state) =>
      state.filterStates[filterStateId] || homeInitialFilterState
  );

export const makeGetIsFetchingCheckinByFilter = () =>
  createSelector(
    makeGetCheckinFilterState(),
    (state) => state.isFetchingCheckins
  );
export const makeGetIsFetchingCheckinsHoursByFilter = () =>
  createSelector(
    makeGetCheckinFilterState(),
    (state) => state.isFetchingCheckinsHours
  );
export const makeGetCheckinsByFilter = () =>
  createSelector(makeGetCheckinFilterState(), (state) => state.checkins);
export const makeGetCheckinsHoursByFilter = () =>
  createSelector(makeGetCheckinFilterState(), (state) => state.checkinsHours);
export const makeGetCreatedCheckinsByFilter = () =>
  createSelector(makeGetCheckinFilterState(), (state) => state.createdCheckins);

export const makeGetCheckInsHashByFilter = () =>
  createSelector(makeGetAllCheckinsByFilter(), (allCheckins) => {
    const checkInAccountHash = groupBy(
      allCheckins,
      (checkIn) => checkIn.account_id
    );
    return checkInAccountHash;
  });
export const makeGetCheckInsHoursHashByFilter = () =>
  createSelector(makeGetCheckinFilterState(), (filterState) => {
    const checkInsHoursByFilterState = filterState.checkinsHours;
    const checkInHourAccountHash = keyBy(
      checkInsHoursByFilterState,
      (checkIn) => checkIn.account_id
    );
    return checkInHourAccountHash;
  });

const generateWorkplanCheckins = (
  team,
  accountId,
  checkins,
  suggestedProjectHash
) => {
  const workplanCheckins = [];
  Object.keys(suggestedProjectHash).forEach((suggestedProjectKey) => {
    const suggestedProject = suggestedProjectHash[suggestedProjectKey];
    suggestedProject.phases.forEach((phase) => {
      phase.activities.forEach((activity) => {
        // check if the workplan has already been used for a checkin
        const addCheckin = !checkins.some(
          (checkin) =>
            checkin.project_id == suggestedProject.project_id &&
            checkin.phase_id == phase.phase_id &&
            checkin.activity_id == activity.activity_id
        );
        if (addCheckin) {
          const checkinDate = moment().format('YYYY-MM-DD');
          workplanCheckins.push({
            account_id: accountId,
            activity_id: activity.activity_id,
            activity_phase_id: null,
            created_at: checkinDate,
            date: checkinDate,
            estimated_hours: '0.0',
            id: `${suggestedProject.project_id}-${activity.activity_id}-${phase.phase_id}-${checkinDate}`,
            phase_id: phase.phase_id,
            project_id: suggestedProject.project_id,
            team_id: team?.id,
            title: '',
            updated_at: checkinDate,
            isWorkplan: true
          });
        }
      });
    });
  });
  return workplanCheckins;
};

export const getIsTimerSidebar = (state, ownProps) => ownProps.isTimerSidebar;

const getSortedCheckins = (
  checkins,
  workplanCheckins,
  createdCheckins = emptyObj
) => {
  const filteredCheckins = checkins.filter(
    (checkin) => checkin.submittal_status !== 'deleted'
  );
  const allCheckins = [...filteredCheckins, ...workplanCheckins];
  allCheckins.sort((a, b) => {
    if (createdCheckins[a.id]) {
      return -1;
    } else if (createdCheckins[b.id]) {
      return 1;
    }
    if (a.project_id > b.project_id) {
      return -1;
    } else if (a.project_id < b.project_id) {
      return 1;
    } else {
      if (a.phase_id > b.phase_id) {
        return -1;
      } else if (a.phase_id < b.phase_id) {
        return 1;
      } else {
        if (a.activity_id > b.activity_id) {
          return -1;
        } else if (a.activity_id < b.activity_id) {
          return 1;
        } else {
          const time1 = moment(a.created_at);
          const time2 = moment(b.created_at);
          return time1.isBefore(time2) ? 1 : -1;
        }
      }
    }
  });
  return allCheckins;
};

export const makeGetWorkplanCheckinsByFilter = () =>
  createSelector(
    getSelectedTeam,
    makeGetCheckinsByFilter(),
    makeGetCheckinsHoursByFilter(),
    (team, checkins, checkinsHours) => {
      const checkinsHoursArr = Object.values(checkinsHours);
      const workplanGeneratedCheckIns = checkinsHoursArr.reduce((acc, cur) => {
        const suggestedProjectHash = keyBy(
          cur.project_totals,
          (item) => item.project_id
        );
        return acc.concat(
          generateWorkplanCheckins(
            team,
            cur?.account_id,
            checkins,
            suggestedProjectHash
          )
        );
      }, []);
      return workplanGeneratedCheckIns;
    }
  );

export const getWorkplanCheckins = createSelector(
  getSelectedTeam,
  getMe,
  getCheckins,
  makeSuggestedProjectHash,
  generateWorkplanCheckins
);

const moveActiveTimerToTop = (checkins, activeTimer) => {
  const [activeTimerArray, nonActiveTimersArray] = partition(
    checkins,
    (checkin) => checkin.id === activeTimer?.check_in_id
  );
  return [...activeTimerArray, ...nonActiveTimersArray];
};

export const makeGetAllCheckinsByFilter = () => {
  return createSelector(
    makeGetCheckinsByFilter(),
    makeGetWorkplanCheckinsByFilter(),
    makeGetCreatedCheckinsByFilter(),
    getActiveTimer,
    getIsTimerSidebar,
    (
      checkins,
      workplanCheckins,
      createdCheckins,
      activeTimer,
      isTimerSidebar
    ) =>
      isTimerSidebar && activeTimer
        ? moveActiveTimerToTop(
            getSortedCheckins(checkins, workplanCheckins, createdCheckins),
            activeTimer
          )
        : getSortedCheckins(checkins, workplanCheckins, createdCheckins)
  );
};

export const getAllCheckins = createSelector(
  getCheckins,
  getWorkplanCheckins,
  getCreatedCheckins,
  getActiveTimer,
  getIsTimerSidebar,
  (checkins, workplanCheckins, createdCheckins, activeTimer, isTimerSidebar) =>
    isTimerSidebar && activeTimer
      ? moveActiveTimerToTop(
          getSortedCheckins(checkins, workplanCheckins, createdCheckins),
          activeTimer
        )
      : getSortedCheckins(checkins, workplanCheckins, createdCheckins)
);

export const makeGetCheckinsForTimesheetDay = () =>
  createSelector(
    makeGetCheckinsByFilter(),
    getOwnDay,
    getOwnProjectId,
    getOwnPhase,
    getOwnActivity,
    (checkins, day, projectId, phase, activity) => {
      const formattedDay = moment(day).format('YYYY-MM-DD');
      // filter by date, project, phase, activity to get checkins that correspond to timesheetDay
      return checkins.filter(
        (checkin) =>
          checkin.date === formattedDay &&
          checkin.project_id === projectId &&
          checkin.phase_id === phase?.id &&
          checkin.activity_id === activity?.id
      );
    }
  );

export const makeGetPlaceholderInfoForTimesheetDay = () =>
  createSelector(
    makeGetCheckinsForTimesheetDay(),
    getMergedTimers,
    getPlannerSchedules,
    getOwnDay,
    getOwnProjectId,
    getOwnPhase,
    getOwnActivity,
    (checkins, timers, scheduleBars, day, projectId, phase, activity) => {
      const checkinsHours = checkins.reduce(
        (acc, checkin) => acc + Number(checkin.estimated_hours),
        0
      );
      const timerHours = checkins.reduce((acc, checkin) => {
        const currentTimers = timers[checkin.id] || {};
        const totalDuration = Object.values(currentTimers).reduce(
          (acc, timer) => {
            return acc + timer.duration;
          },
          0
        );
        return acc + totalDuration;
      }, 0);
      const plannedHours = Object.values(scheduleBars)
        .filter((scheduleBar) => {
          if (
            scheduleBar.project_id !== projectId ||
            scheduleBar.phase_id !== phase?.id ||
            scheduleBar.activity_id !== activity?.id
          ) {
            return false;
          }
          return moment(day).isBetween(
            scheduleBar.start_date,
            scheduleBar.end_date,
            undefined,
            '[]'
          );
        })
        .reduce((acc, scheduleBar) => acc + Number(scheduleBar.daily_hours), 0);
      return {
        checkinsHours,
        timerHours: timerHours / 3600,
        plannedHours
      };
    }
  );

export const getRawMyUnstarredProjects = createSelector(
  getHomeState,
  (state) => state.myProjects
);
export const getRawMyStarredProjects = createSelector(
  getHomeState,
  (state) => state.myStarredProjects
);
export const getMyUnstarredProjects = createSelector(
  getRawMyUnstarredProjects,
  getUserIsAdmin,
  (myProjects, userIsAdmin) =>
    userIsAdmin
      ? myProjects
      : myProjects.filter((project) => !project.is_administrative)
);
export const getMyStarredProjects = createSelector(
  getRawMyStarredProjects,
  getUserIsAdmin,
  (myStarredProjects, userIsAdmin) =>
    userIsAdmin
      ? myStarredProjects
      : myStarredProjects.filter((project) => !project.is_administrative)
);
export const getMyNumberOfProjects = createSelector(
  getHomeState,
  (state) => state.totalProjects
);
export const getNumberOfAllProjects = createSelector(
  getHomeState,
  (state) => state.totalAllProjects
);
export const rawGetMyProjects = createSelector(
  getRawMyUnstarredProjects,
  getRawMyStarredProjects,
  (unstarred, starred) => [...unstarred, ...starred]
);

export const getMyProjects = createSelector(
  [getMyUnstarredProjects, getMyStarredProjects],
  (unstarred, starred) => [...unstarred, ...starred]
);

export const getIsMyProjectsFullyLoaded = createSelector(
  rawGetMyProjects,
  getMyNumberOfProjects,
  (myProjects, myNumberOfProjects) => !(myProjects.length < myNumberOfProjects)
);

export const getOwnBulkTaskId = (state, ownProps) => ownProps.task.id;
export const getComponentTask = (state, ownProps) => ownProps.task;

export const getHomeTasks = (state) => state.homeTasks;

export const getTaskGroupCounts = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.taskGroupCounts
);

export const getBatchSelectedTaskIds = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.batchSelectedTaskIds
);
export const getBatchSelectedGroupIds = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.batchSelectedGroupIds
);
export const getNumberBatchSelectedTasks = createSelector(
  getBatchSelectedTaskIds,
  getBatchSelectedGroupIds,
  getTaskGroupCounts,
  (taskIds, groupIds, groupCounts) => {
    return (
      Object.keys(taskIds).length +
      sum(Object.keys(groupIds).map((groupId) => groupCounts[groupId] || 0))
    );
  }
);

export const getCurrentPageNumber = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.currentPage
);
export const getTotalPages = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.totalPages
);

export const getIsLazyLoadingTasks = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.isLazyLoading
);
export const getSelectedFlag = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.selectedFlag
);
export const getSelectedDate = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.selectedDate
);
export const getFlaggedTasksModalOpen = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.flaggedTasksModalOpen
);

export const getHomeTasksEditedTasks = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.editedTasks
);
export const getAllTasksWithDupes = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.allTasks
);
export const getAllTasks = createSelector(getAllTasksWithDupes, (allTasks) =>
  Array.from(new Set(allTasks))
);

export const getTaskProjectMembershipsHash = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.projectMembershipHash
);
export const getHomeTaskObj = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.taskHash
);

export const getDependencyTaskHash = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.dependencyTaskHash
);

export const getDependencyTaskArray = createSelector(
  getDependencyTaskHash,
  (dependencyTaskHash) =>
    Object.keys(dependencyTaskHash).map((id) => dependencyTaskHash[id])
);

export const getTimerTaskHash = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.timerTaskHash
);

export const getTimerTaskArray = createSelector(
  getTimerTaskHash,
  (timerTaskHash) => Object.keys(timerTaskHash).map((id) => timerTaskHash[id])
);

export const getCreatedTasksTempIds = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.createdTasksToTempIds
);
export const getTempIdsToCreatedTaskIds = createSelector(
  getCreatedTasksTempIds,
  invert
);
export const getOwnTaskId = (state, ownProps) => ownProps.taskId;

export const getBatchSelectedTasks = createSelector(
  getHomeTaskObj,
  getBatchSelectedTaskIds,
  (tasksHash, selectedIds) => {
    if (!selectedIds) {
      return emptyArray;
    } else {
      return Object.keys(selectedIds).map((id) => tasksHash[id]);
    }
  }
);

export const getHomeTasksSortValue = createSelector(
  getHomeTasks,
  (state) => state.sort
);
export const getHomeTasksSortDirection = createSelector(
  getHomeTasks,
  (state) => state.direction
);

export const getHomeTasksSortState = createSelector(
  getHomeTasksSortValue,
  getHomeTasksSortDirection,
  (sort, direction) => ({ value: sort, order: direction })
);

const formatTasks = (
  tasks,
  batchSelectedTasks,
  projects,
  phases,
  boards,
  teamMembersByAccountId
) =>
  tasks.map((task) => {
    const isSelected = batchSelectedTasks[task.id];
    const project = projects[task.project_id];
    const phase = phases[task.phase_id];
    const board = boards?.[project?.board_id];
    const assignees =
      task.assignee_ids
        ?.map((id) => teamMembersByAccountId[id])
        .filter((member) => member) ?? [];
    return {
      ...task,
      assignees,
      isSelected,
      project,
      phase,
      board
    };
  });

export const prepGetFormattedAllTasks = createSelector(
  getAllTasks,
  getHomeTaskObj,
  (taskIds, taskHash) => taskIds.map((id) => taskHash[id])
);
export const getFormattedAllTasks = createSelector(
  prepGetFormattedAllTasks,
  getBatchSelectedTasks,
  getProjectHash,
  getFlatPhasesHash,
  getGroupsHash,
  getTeamMembershipsByAccountId,
  formatTasks
);

export const makeGetIsBatchSelected = () =>
  createSelector(
    getBatchSelectedTaskIds,
    getOwnTaskId,
    (batchSelectedTaskIds, ownTaskId) => !!batchSelectedTaskIds[ownTaskId]
  );
export const makeGetOwnTaskIds = () => (state, ownProps) => ownProps.taskIds;
export const makeGetAllOwnTaskIds = () => (state, ownProps) =>
  ownProps.fromTaskGroups ? ownProps.taskGroupIds : ownProps.taskIds;
/* getOwnTaskIds is used in get SOME selected - we need to check if ANY regardless of group are selected to display "all" vs "none" for ALL
task groups, whereas determining whether we show specifically all OR none relies on the taskGroupIds specific to the group */
export const makeGetSomeIsBatchSelected = () => {
  const getOwnTaskIds = makeGetOwnTaskIds();
  return createSelector(
    getBatchSelectedTaskIds,
    getOwnTaskIds,
    (selectedIds, ownTaskIds) =>
      ownTaskIds?.some((taskId) => selectedIds[taskId])
  );
};
export const makeGetAllIsBatchSelected = () => {
  const getAllOwnTaskIds = makeGetAllOwnTaskIds();
  return createSelector(
    getBatchSelectedTaskIds,
    getAllOwnTaskIds,
    (selectedIds, ownTaskIds) =>
      !ownTaskIds ? false : ownTaskIds.every((taskId) => selectedIds[taskId])
  );
};
export const getPastDueCount = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.pastDueCount
);
export const getPastScheduledCount = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.pastScheduledCount
);
export const getScheduledForTodayCount = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.scheduledForToday
);
export const getPastDueTasks = createSelector(getHomeTaskObj, (tasksHash) => {
  return Object.values(tasksHash)
    .filter(
      (task) => !task.completed_at && task.due_at && !isTodayOn(task.due_at)
    )
    .sort((a, b) => (a.due_at > b.due_at ? -1 : 1));
});

export const getDueTodayTasks = createSelector(getHomeTaskObj, (tasksHash) => {
  return Object.values(tasksHash).filter(
    (task) => !task.completed_at && task.due_at && isToday(task.due_at)
  );
});

export const getDueAtTasks = createSelector(getHomeTaskObj, (tasksHash) => {
  return (due) =>
    Object.values(tasksHash).filter(
      (task) => !task.completed_at && task.due_at && isSameDay(task.due_at, due)
    );
});

export const getScheduleForTodayTasks = createSelector(
  getHomeTaskObj,
  (tasksHash) => {
    return Object.values(tasksHash).filter(
      (task) =>
        !task.completed_at &&
        task.schedule_start &&
        isToday(task.schedule_start)
    );
  }
);

export const getPastScheduledTasks = createSelector(
  getHomeTaskObj,
  (tasksHash) => {
    return Object.values(tasksHash)
      .filter(
        (task) =>
          !task.completed_at &&
          !!task.schedule_start &&
          !isTodayOn(task.schedule_start)
      )
      .sort((a, b) => (a.schedule_start > b.schedule_start ? 1 : -1));
  }
);
export const getScheduledAtTasks = createSelector(
  getHomeTaskObj,
  (tasksHash) => {
    return (date) =>
      Object.values(tasksHash).filter(
        (task) =>
          !task.completed_at &&
          !!task.schedule_start &&
          isSameDay(date, task.schedule_start)
      );
  }
);

export const getPastTasksLoading = createSelector(
  getHomeTasks,
  (homeTasks) =>
    homeTasks.isFetchingPastDue || homeTasks.isFetchingPastScheduled
);

export const getIsFetchingMyProjects = createSelector(
  getHomeState,
  (state) => state.isFetchingMyProjects
);
export const getIsFetchingAllProjects = createSelector(
  getHomeState,
  (state) => state.isFetchingAllProjects
);
export const getAllMyStarredProjectIds = (state) =>
  state.home.starredProjectIds;
export const getAllMyUnstarredProjectIds = (state) =>
  state.home.unstarredProjectIds;

export const getAllMyProjectIds = createSelector(
  getAllMyUnstarredProjectIds,
  getAllMyStarredProjectIds,
  (unstarredIds, starredIds) => {
    return [...starredIds, ...unstarredIds];
  }
);

export const getAllNotMyProjectIds = createSelector(
  getAllMyUnstarredProjectIds,
  getAllMyStarredProjectIds,
  getAllFetchedProjectsArray,
  (unstarredIds, starredIds, projects) => {
    const myProjectsHash = {
      ...makeIdHash(unstarredIds),
      ...makeIdHash(starredIds)
    };
    return projects
      .filter((project) => !myProjectsHash[project.id])
      .map((project) => project.id);
  }
);

export const getProjectsListByTeamId = createSelector(
  getAllFetchedProjectsArray,
  (projects) => {
    const dict = {};
    projects.forEach((project) => {
      if (dict[project.board_id]) {
        dict[project.board_id].push(project);
      } else {
        dict[project.board_id] = [project];
      }
    });
    return dict;
  }
);

export const getActiveTeam = createSelector(
  getTeamsState,
  (teams) => teams.allTeams && teams.allTeams[0]
);

export const makeGetBoardById = (getters = emptyObj) =>
  createSelector(
    getGroupsHash,
    getters.boardIdGetter || getOwnBoardId,
    (boards, boardId) => boards[boardId]
  );

export const makeGetBoardByProjectId = () =>
  createSelector(makeGetProjectById(), getGroupsHash, (project, groupsObj) => {
    if (groupsObj && project) return groupsObj[project.board_id];
  });

export const makeGetBoardByProjectBasicInfoId = () =>
  createSelector(
    makeGetProjectBasicInfoById(),
    getGroupsHash,
    (project, groupsObj) => {
      if (groupsObj && project) return groupsObj[project.board_id];
    }
  );

export const getProjectAssignmentProjectIds = createSelector(
  [
    getAllMyUnstarredProjectIds,
    getAllMyStarredProjectIds,
    getAllNotMyProjectIds
  ],
  (unstarred, starred, notMine) => [...starred, ...unstarred, ...notMine]
);

export const getMyProjectIdsIndexes = createSelector(
  getProjectAssignmentProjectIds,
  (projectIds) =>
    projectIds.reduce((acc, cur, index) => {
      acc[cur] = index;
      return acc;
    }, {})
);
export const getProjectAssignmentProjects = createSelector(
  getProjectAssignmentProjectIds,
  getAllFetchedProjects,
  (ids, projects) =>
    ids.map((id) => projects[id]).filter((project) => !!project)
);

export const makeGetTaskProjectIsPersonalOrUndefined = () =>
  createSelector(
    getComponentTask,
    getSelectedProject,
    getIsOnPersonalProject,
    getProjectHash,
    (task, selectedProjected, isOnPersonalProject, projectHash) => {
      if (task) {
        if (!task.project_id) {
          return true;
        }
        const taskProject = projectHash[task.project_id];
        return taskProject?.is_personal;
      }
      return false;
    }
  );
export const makeSafeGetProjectAssignmentProjects = () =>
  createSelector(
    getProjectAssignmentProjects,
    getComponentTask,
    getSelectedTeamMemberId,
    getSelectedProject,
    getIsOnPersonalProject,
    getBatchSelectedTasks,
    (
      assignmentProjects,
      task,
      selectedTeamMemberId,
      selectedProject,
      isOnPersonalProject,
      batchSelectedTasks
    ) => {
      const projectsById = keyBy(assignmentProjects, byId);
      if (selectedTeamMemberId) {
        // team member profile
        return assignmentProjects.filter((project) => !project.is_personal);
      } else if (selectedProject) {
        // project detail view
        return assignmentProjects.filter(
          (project) => project.is_personal === isOnPersonalProject
        );
      } else if (task) {
        // home
        const taskProject = projectsById[task.project_id];
        if (task.project_id && taskProject !== undefined) {
          // task already has a project, is locked into team/personal
          return assignmentProjects.filter(
            (project) => project.is_personal === taskProject.is_personal
          );
        } else {
          // task has no project, can become team or personal
          return assignmentProjects;
        }
      } else if (batchSelectedTasks.length) {
        const batchedIncludesPersonal = batchSelectedTasks.some(
          (task) => projectsById[task.project_id]?.is_personal === true
        );
        const batchedIncludesTeam = batchSelectedTasks.some(
          (task) => projectsById[task.project_id]?.is_personal === false
        );
        if (batchedIncludesPersonal && batchedIncludesTeam) {
          return emptyArray;
        } else if (batchedIncludesPersonal) {
          return assignmentProjects.filter((project) => project.is_personal);
        } else if (batchedIncludesTeam) {
          return assignmentProjects.filter((project) => !project.is_personal);
        } else {
          // all batch selected tasks have no project
          return assignmentProjects;
        }
      } else {
        return assignmentProjects.filter((project) => !project.is_personal);
      }
    }
  );

export const makeSafeGetProjectAssignmentProjectsHash = () => {
  const safeGetProjectAssignementProjects =
    makeSafeGetProjectAssignmentProjects();
  return createSelector(safeGetProjectAssignementProjects, (projects) =>
    keyBy(projects, (project) => project.id)
  );
};

export const getMyProjectsLength = createSelector(
  [getMyUnstarredProjects, getMyStarredProjects],
  (unstarred, starred) => unstarred.length + starred.length
);

export const getIsCreatingTask = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.isCreatingTask
);

export const getSelectedAccountIds = (state) =>
  state.projects.selectedAccountIds;
export const getIsFilteringAccounts = createSelector(
  getSelectedAccountIds,
  (accountIds) => !!accountIds.length
);
export const getSelectedAccountIdsHash = createSelector(
  getSelectedAccountIds,
  (accountIds) => makeIdHash(accountIds)
);
export const getTaskAccountFilter = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.selectedAccountIds
);
export const getIsFilteringTaskAccounts = createSelector(
  getTaskAccountFilter,
  (accountIds) => !!accountIds.length
);
export const getSearch = (state) => state.search;
export const getSearchText = createSelector(
  getSearch,
  (search) => search.searchText
);
export const getIsSearching = createSelector(
  getSearchText,
  (searchText) => searchText.length
);
export const getShowResultsPane = createSelector(
  getSearch,
  (search) => search.showResultsPane
);
export const getSearchWords = createSelector(getSearchText, (searchText) =>
  searchText ? searchText.split(' ') : emptyArray
);

export const getSidebarSearchOpen = createSelector(
  getSearch,
  (search) => search.sidebarSearchOpen
);
export const getSidebarProjectsOpen = createSelector(
  getSearch,
  (search) => search.sidebarProjectsOpen
);
export const getUserSidebarProjectsIsPinned = createSelector(
  getSearch,
  (search) => search.myProjectsStateBackendChoice
);
export const getProjectRoles = createSelector(
  getProjectsState,
  (projects) => projects.roles
);
export const getAddMembersFormRoles = createSelector(
  getAddMembersFormModalType,
  getProjectRoles,
  getBoardRoles,
  (modalType, projectRoles, boardRoles) =>
    modalType === MODAL_TYPE.BOARD ? boardRoles : projectRoles
);
export const getModalProjectIsPersonal = createSelector(
  getProjectsState,
  (state) => state.addModalProjectIsPersonal || state.editModalIsPersonal
);
export const getTotalProjects = createSelector(
  getProjectsState,
  (state) => state.totalProjects
);

export const getTaskCompletion = (state) => state.tasks.isCompleted;
export const getBoardSearchText = (state) =>
  state.search.savedSearchTexts.board;
export const getShouldUseBoard = (state) => state.search.shouldUse.board;
export const getHomeSearchText = (state) => state.search.savedSearchTexts.home;
export const getShouldUseHome = (state) => state.search.shouldUse.home;
export const getTags = (state) => state.tags;
export const getFilterTagId = createSelector(
  getTags,
  (tags) => tags.filterTagId
);

export const getUsers = (state) => state.users;

export const getUsersTeams = createSelector(getUsers, (users) => users.teams);
export const getPasswordError = createSelector(
  getUsers,
  (users) => users.password_error
);
export const getEmailError = createSelector(
  getUsers,
  (users) => users.email_error
);

export const getDefaultTeam = createSelector(
  getMe,
  (me) => me && me.default_team
);
export const getLastTenProjectNumbers = createSelector(
  getDefaultTeam,
  (defaultTeam) => defaultTeam && defaultTeam.last_10_project_numbers
);
export const getExistingClients = createSelector(
  getDefaultTeam,
  (defaultTeam) => defaultTeam && defaultTeam.existing_clients
);
export const getDefaultFollowUpMessage = createSelector(
  getMe,
  (me) => me && me.default_follow_up_message
);
export const getMyPersonalBoardID = createSelector(
  getMe,
  (me) => me && me.personal_board_id
);
export const getTeamSlug = (state) =>
  state.projects.selectedProject[0]?.team_slug
    ? state.projects.selectedProject[0].team_slug
    : state.users.me
    ? state.users.me.default_team && state.users.me.default_team.slug
    : null;

export const getTeamName = createSelector(getMe, (me) =>
  me ? me.default_team && me.default_team.name : 'Team'
);
export const getIsComplete = (state) => state.tasks.isCompleted;

export const getSelectedProjectProjectMembers = createSelector(
  getSelectedProject,
  (selectedProject) => selectedProject && selectedProject.project_membership
);

export const getSelectedProjectAccountIds = createSelector(
  getSelectedProjectProjectMembers,
  (members) => members && members.map((member) => member.account.id)
);
export const getSelectedProjectIsPersonal = createSelector(
  getSelectedProject,
  (project) => project && project.is_personal
);
export const getSelectedProjectBoardId = createSelector(
  getSelectedProject,
  (project) => project && project.board_id
);
export const getSelectedProjectBoardInfo = createSelector(
  getSelectedProject,
  getGroupsHash,
  (project, groups) =>
    project && project.board_id && groups && groups[project.board_id]
);

export const getTaskStatuses = createSelector(
  getSelectedProject,
  (selectedProject) =>
    (selectedProject && selectedProject.task_statuses) || emptyArray
);
export const getTaskStatusesById = createSelector(
  getTaskStatuses,
  (taskStatuses) => keyBy(taskStatuses, byId)
);

export const getTaskStatusOrder = createSelector(
  getSelectedProject,
  (selectedProject) =>
    (selectedProject && selectedProject.task_status_order) || emptyArray
);

export const getTaskPriorities = (state) => state.taskPriorities.taskPriorities;
export const getTaskPrioritiesById = createSelector(
  getTaskPriorities,
  (taskPriorities) => keyBy(taskPriorities, byId)
);

export const getTaskPriorityOrder = createSelector(
  getTaskPriorities,
  (taskPriorities) =>
    taskPriorities.map((priority) => priority.id) || emptyArray
);

export const getPhaseOrder = createSelector(
  getSelectedProject,
  (selectedProject) =>
    [...new Set(selectedProject && selectedProject.phase_orders)] || emptyArray
);

export const getOrderedTaskStatuses = createSelector(
  getTaskStatusesById,
  getTaskStatusOrder,
  (taskStatusesById, taskStatusIds) =>
    taskStatusIds.map((id) => taskStatusesById[id]).filter((status) => !!status)
);

export const makeGetOwnTaskStatusId = () => (state, ownProps) =>
  ownProps.statusId;

export const makeGetOwnTaskStatus = () => {
  const getOwnTaskStatusId = makeGetOwnTaskStatusId();
  return createSelector(
    getTaskStatusesById,
    getOwnTaskStatusId,
    (statuses, ownStatusId) => statuses[ownStatusId]
  );
};

export const getOrderedTaskPriorities = createSelector(
  getTaskPrioritiesById,
  getTaskPriorityOrder,
  (taskPrioritiesById, taskPriorities) =>
    taskPriorities
      .map((id) => taskPrioritiesById[id])
      .filter((priority) => !!priority)
);

export const getOwnTaskPriorityId = (state, ownProps) => ownProps.priorityId;

export const makeGetOwnTaskPriority = () => {
  return createSelector(
    getTaskPrioritiesById,
    getOwnTaskPriorityId,
    (priorities, ownPriorityId) => priorities[ownPriorityId]
  );
};

export const getGroupsErrorMessage = (state) => state.groups.errorMessage;
export const getRecentBoard = (state) => state.groups.recentBoard;
export const getTeamMemberId = createSelector(
  getTeamsState,
  (teamsState) =>
    teamsState.selectedTeamMember && teamsState.selectedTeamMember.id
);
export const getLocation = (state) => getRouterLocation(state).pathname;
export const getSortOrder = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.sort
);

export const getCurrentFilter = createSelector(
  getHomeTasks,
  getIsMemberModalOpen,
  (homeTasks, isMemberModalOpen) =>
    isMemberModalOpen
      ? homeTasks.memberModalTaskFilter
      : homeTasks.currentFilter
);

export const getMemberModalTaskCounts = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.memberModalTaskCounts
);

export const makeGetHomeTasksFilterState = () =>
  createSelector(
    getOwnFilterStateId,
    getHomeTasks,
    (filterStateId, state) =>
      state.filterStates[filterStateId] || homeTasksInitialFilterState
  );

export const makeGetIsFetchingTaskCountsByFilter = () =>
  createSelector(
    makeGetHomeTasksFilterState(),
    (state) => state.isFetchingCounts
  );

export const makeGetTaskCountsByFilter = () =>
  createSelector(
    makeGetHomeTasksFilterState(),
    (state) => state.memberModalTaskCounts
  );

const isAssignedByMe = (currentFilter) => {
  return currentFilter.section === 'Assigned By Me';
};

const isTodayOrInbox = (currentFilter) => {
  return (
    currentFilter.section === 'My Tasks' &&
    (currentFilter.subSection === 'scheduled' ||
      currentFilter.subSection === 'inbox') &&
    currentFilter.scope !== 'project'
  );
};

const isInbox = (currentFilter) => {
  return (
    currentFilter.section === 'My Tasks' &&
    currentFilter.subSection === 'inbox' &&
    currentFilter.scope !== 'project'
  );
};
const isOnToday = (currentFilter) => {
  return (
    currentFilter.section === 'My Tasks' &&
    currentFilter.subSection === 'scheduled' &&
    currentFilter.scope !== 'project'
  );
};
const isOnProfile = (currentFilter) => {
  return currentFilter.scope === 'profile';
};
const isHomeCompleted = (currentFilter) => {
  return (
    currentFilter.section === 'My Tasks' &&
    currentFilter.subSection === 'completed' &&
    currentFilter.scope !== 'project'
  );
};
const isProjectCompleted = (currentFilter) => {
  return (
    currentFilter.state === 'completed' && currentFilter.scope === 'project'
  );
};
const isProject = (currentFilter) => {
  return currentFilter.scope === 'project';
};

const isMyTasks = (currentFilter) => {
  return (
    currentFilter.section === 'My Tasks' && currentFilter.scope !== 'project'
  );
};
export const getCurrentPage = createSelector(
  getCurrentFilter,
  getIsMemberModalOpen,
  (currentFilter, isMemberModalOpen) => {
    const defaultFilter = {
      scope: 'accepted',
      section: 'My Tasks',
      state: 'incomplete',
      subSection: 'inbox'
    };

    const taskFilter = isMemberModalOpen ? defaultFilter : currentFilter;

    const activePage = {
      isToday: isOnToday(taskFilter),
      isInbox: isInbox(taskFilter),
      isOnProfile: isOnProfile(taskFilter),
      isHomeCompleted: isHomeCompleted(taskFilter),
      isProjectCompleted: isProjectCompleted(taskFilter),
      isTodayOrInbox: isTodayOrInbox(taskFilter),
      isProject: isProject(taskFilter),
      isMyTasks: isMyTasks(taskFilter),
      isAssignedByMe: isAssignedByMe(taskFilter)
    };
    return activePage;
  }
);

export const getBilling = (state) => state.billing;
export const getBillingCurrentModal = createSelector(
  getBilling,
  (billing) => billing.currentModal
);
export const getBillingCurrentSubscription = createSelector(
  getBilling,
  (billing) => billing.currentSubscription
);
export const getOnTaskDetailModal = (state) =>
  state.taskDetail.taskDetailModalOpen;

/**
 * @deprecated Use the typesafe version from `AuthenticationModule/selectors`.
 */
export const getAuthToken = createSelector(
  getAuth,
  (auth) => auth && auth.token
);

/**
 * @deprecated Use the typesafe version from `UsersModule/selectors`.
 */
export const getMyUserId = createSelector(
  getCurrentAccount,
  (currentAccount) => currentAccount && currentAccount.id
);

export const getDayPlannerDate = (state) => state.dayPlanner.plannerDate;
export const getHomePlannerLengthByDate = (dateKey) => (state) =>
  state.homePlanner.planners[dateKey].tasks.length - 1;

export const getHomePlanner = (state) => state.homePlanner;
export const getHomePlannerDateRange = createSelector(
  getHomePlanner,
  (homePlanner) => homePlanner.dateRange
);
export const getTaskPlanners = (state) => state.homePlanner.planners;
export const getPlannerTaskHash = (state) => state.homePlanner.taskHash;
export const getIsFetchingHomePlanner = (state) =>
  state.homePlanner.isFetchingHomePlanner;

export const getSelectedTeamRateOrder = createSelector(
  getSelectedTeam,
  (team) => team.rate_order || emptyArray
);
export const getSelectedTeamRoleOrder = createSelector(
  getSelectedTeam,
  (team) => team.team_role_order || emptyArray
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 */
export const getSelectedTeamId = createSelector(
  getSelectedTeam,
  (team) => team && team.id
);
export const getSelectedTeamName = createSelector(
  getSelectedTeam,
  (team) => team && team.name
);
export const getSelectedTeamViewSettings = createSelector(
  getSelectedTeam,
  (team) => (team && team.view_settings) || emptyObj
);

export const getSelectedTeamDefaultFeeTarget = createSelector(
  getSelectedTeam,
  (team) => team?.default_target_fee_percentage
);
export const getSelectedTeamDefaultRateType = createSelector(
  getSelectedTeamViewSettings,
  (viewSettings) =>
    viewSettings?.use_cost_rate ? RATE_TYPES.COST_RATE : RATE_TYPES.BILL_RATE
);
export const getSelectedTeamIsUsingCostRate = createSelector(
  getSelectedTeamDefaultRateType,
  (defaultRateType) => defaultRateType === RATE_TYPES.COST_RATE
);

export const getIsTimesheetsDisabled = createSelector(
  getSelectedTeamViewSettings,
  (teamSettings) => teamSettings?.is_timesheet_disabled === true
);

const getPlannerTask = (state, taskId) => {
  const task = state.homePlanner.taskHash[taskId];
  return task;
};

export const getHomeModalTaskIds = ({ homeTasks }) =>
  homeTasks.flaggedTasksModalOpen
    ? homeTasks.pastDueTasks
    : homeTasks.pastScheduledTasks;

export const getPastScheduledTaskIds = createSelector(
  getPastScheduledTasks,
  (pastScheduledTasks) =>
    pastScheduledTasks && pastScheduledTasks.map((task) => task.id)
);

const compareDaysFromToday = (plan, i) => {
  if (!plan.date) {
    return -Number.MAX_SAFE_INTEGER;
  }
  const daysDiff = daysFromToday(moment(plan.date));
  return daysDiff - i;
};
const maxUpcomingSchedule = 8; // 9 groups including today and reschedule

export const getPlannerDateHeader = (state) => state.homePlanner.dateHeader;

export const getScheduleGroupedTasks = createSelector(
  getHomePlanner,
  getPastScheduledTaskIds,
  (homePlanner, pastScheduledTasks) => {
    const planners =
      !!homePlanner &&
      !!homePlanner.planners &&
      !!homePlanner.planners[homePlanner.myId]
        ? homePlanner.planners[homePlanner.myId]
        : {};
    const schedules = Object.values(planners).filter(
      (plan) => plan && plan.date
    );
    const scheduledGroups = [];

    // reschedule
    scheduledGroups[0] = {};
    scheduledGroups[0].scheduleStart = '';
    scheduledGroups[0].task_order = pastScheduledTasks;
    scheduledGroups[0].title = TASK_RESCHEDULE_LABEL;

    // today - upcoming
    for (let i = 0; i < maxUpcomingSchedule - 1; i++) {
      scheduledGroups[i + 1] = {};
      scheduledGroups[i + 1].task_order = flatten(
        schedules
          .filter((plan) => compareDaysFromToday(plan, i) === 0)
          .map((plan) => plan.tasks)
      );
      // eslint-disable-next-line prettier/prettier
      const scheduleDate = moment().add(i, 'days');
      const dayOfWeekShort = scheduleDate.format('ddd');
      const dayOfWeekLong = scheduleDate.format('dddd');
      const month = scheduleDate.format('MMM');
      const dayOfMonth = scheduleDate.date();
      const title =
        // eslint-disable-next-line prettier/prettier
        `${i === 0 ? 'Today' : i === 1 ? 'Tomorrow' : dayOfWeekLong}`;
      scheduledGroups[i + 1].title = title;
      const headerInitial = i < 2 ? `${dayOfWeekShort} ` : '';
      scheduledGroups[
        i + 1
      ].dateHeader = `${headerInitial}${month} ${dayOfMonth}`;
      scheduledGroups[i + 1].scheduleStart = scheduleDate;
    }
    // anything beyond maxUpcomingSchedule
    scheduledGroups[maxUpcomingSchedule] = {};
    const scheduleDate = moment().add(maxUpcomingSchedule, 'days');
    scheduledGroups[maxUpcomingSchedule].scheduleStart = scheduleDate;
    scheduledGroups[maxUpcomingSchedule].task_order = flatten(
      schedules
        .filter((plan) => compareDaysFromToday(plan, maxUpcomingSchedule) >= 0)
        .map((plan) => plan.tasks)
    );
    scheduledGroups[maxUpcomingSchedule].title = 'Upcoming';
    return scheduledGroups;
  }
);

export const getPlannerGroupedTasks = createSelector(
  getHomePlanner,
  getPlannerDateHeader,
  (homePlanner, plannerDateHeader) => {
    const planners =
      !!homePlanner &&
      !!homePlanner.planners &&
      !!homePlanner.planners[homePlanner.myId]
        ? homePlanner.planners[homePlanner.myId]
        : {};
    const schedules = Object.values(planners).filter(
      (plan) => plan && plan.date
    );
    const scheduledGroups = [];

    scheduledGroups[0] = {};
    scheduledGroups[0].task_order = flatten(
      schedules
        .filter((plan) => isSameDay(plannerDateHeader, plan.date))
        .map((plan) => plan.tasks)
    );
    scheduledGroups[0].scheduleStart = plannerDateHeader;
    scheduledGroups[0].date = '';
    return scheduledGroups;
  }
);
export const makeScheduleGroupedTasks = (isOnHomeView) => {
  if (!isOnHomeView) {
    return emptyArrayMaker;
  }
  return getScheduleGroupedTasks;
};

export const makePlannerGroupedTasks = (isOnHomeView) => {
  if (!isOnHomeView) {
    return emptyArrayMaker;
  }
  return getPlannerGroupedTasks;
};

export const getProjectTaskGroupsState = (state) => state.projectTaskGroups;

export const getProjectTaskGroups = createSelector(
  getProjectTaskGroupsState,
  (state) => state.taskGroups
);
export const getNewTaskGroupId = createSelector(
  getProjectTaskGroupsState,
  (state) => state.newGroupId
);
export const makeGetIsNewTaskGroup = () =>
  createSelector(
    getNewTaskGroupId,
    getOwnId,
    (taskGroupId, ownId) => taskGroupId && ownId && taskGroupId === ownId
  );
export const getProjectTaskGroupOrder = createSelector(
  getProjectTaskGroupsState,
  (state) => state.taskGroupOrders
);
export const getAllTaskGroupsCollapsed = createSelector(
  getProjectTaskGroups,
  (taskGroups) =>
    Object.values(taskGroups).every((taskGroup) => taskGroup.collapsed)
);
export const getSelectedProjectTaskGroups = createSelector(
  getProjectTaskGroups,
  (projectTaskGroups) => projectTaskGroups
);
export const getSelectedProjectTaskGroupOrder = createSelector(
  getProjectTaskGroupOrder,
  (taskGroupOrder) => taskGroupOrder
);

export const getRouterProjectId = createSelector(
  getMatchedRouteParams,
  (matchedParams) => matchedParams.projectId
);
export const getTaskGroups = createSelector(
  getSelectedProjectTaskGroups,
  getSelectedProjectTaskGroupOrder,
  getRouterProjectId,
  getOwnProjectId,
  (taskGroups, taskGroupOrders, projectId, ownProjectId) => {
    const theProjectId = projectId || ownProjectId;
    return taskGroupOrders[theProjectId]
      ? taskGroupOrders[theProjectId]
          .map((id) => taskGroups[id])
          .filter((group) => group)
      : emptyArray;
  }
);

export const getIsFetchingTaskGroups = createSelector(
  getProjectTaskGroups,
  (taskGroups) =>
    Object.values(taskGroups).some((taskGroup) => taskGroup.isFetching)
);
export const getIsAnyFilterActive = createSelector(
  getCurrentFilter,
  getTaskAccountFilter,
  getSearchText,
  getFilterTagId,
  (currentFilter, selectedAccountIds, searchText, tagId) => {
    return (
      currentFilter.state !== 'incomplete' ||
      selectedAccountIds.length > 0 ||
      searchText.length > 0 ||
      tagId
    );
  }
);
export const getFilterAndSortStatus = createSelector(
  getSortOrder,
  getCurrentFilter,
  getTaskAccountFilter,
  getSearchText,
  getFilterTagId,
  (sort, currentFilter, selectedAccountIds, searchText, tagId) =>
    `${sort} - ${currentFilter.state} - ${selectedAccountIds.join(
      ','
    )} - ${searchText} - ${tagId}`
);

export const getIsFetchingTasks = createSelector(
  getHomeTasks,
  getIsFetchingTaskGroups,
  (homeTasks, isFetchingTaskGroups) =>
    homeTasks.isFetchingTasks > 0 || isFetchingTaskGroups
);

export const getOverdueProjectTasks = createSelector(
  getPlannerTaskHash,
  (projectTasks) => pickBy(projectTasks, isOverdue)
);

export const getHomePlannerOverdueTasks = createSelector(
  getOverdueProjectTasks,
  (projectTasks) => projectTasks
);

export const getNumOverdueProjectTasks = createSelector(
  getOverdueProjectTasks,
  (projectTasks) => Object.keys(projectTasks).length
);

export const getNumOverdueAllTasks = createSelector(
  getNumOverdueProjectTasks,
  (numProjectTasks) => numProjectTasks
);

export const getTeamMembershipsById = createSelector(
  getTeamMembers,
  (teamMembers) => keyBy(teamMembers, byId)
);

export const getMemberList = (state, ownProps) => ownProps.memberList;

export const makeGetNonGuestBoardMembers = () =>
  createSelector(
    getMemberList,
    getTeamMembershipsByAccountId,
    (memberList, teamMembershipsByAccountId) => {
      return memberList.flatMap((member) => {
        const account = teamMembershipsByAccountId[member?.account?.id];
        return account && !permissionsUtils.getIsProjectGuest(account)
          ? [member]
          : [];
      });
    }
  );

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`. NOTE that new selector assumes that ownProps is never undefined so if  using the typesafe selector, ensure that you will never have ownProps be undefined, and if so, then it's safe to directly switch to the typsafe version. On the other hand, if ownProps can be undefined, make sure the user of the typesafe version handles the undefined case properly
 */
export const getMemberAccountId = (state, ownProps) =>
  (ownProps && ownProps.account && ownProps.account.id) ||
  (ownProps && ownProps.accountId) ||
  (ownProps && ownProps.account_id);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`. NOTE that new selector assumes that ownProps is never undefined so if  using the typesafe selector, ensure that you will never have ownProps be undefined, and if so, then it's safe to directly switch to the typsafe version. On the other hand, if ownProps can be undefined, make sure the user of the typesafe version handles the undefined case properly
 */
export const getMyTeamMembership = createSelector(
  getTeamMembershipsByAccountId,
  getMe,
  (teamMemberships, me) => teamMemberships[me.id]
);
export const getMyModalPreferences = createSelector(
  getMyTeamMembership,
  (teamMembership) => teamMembership?.modal_preferences
);
export const getShouldTriggerTaskConfirmCompleteModal = createSelector(
  getMyModalPreferences,
  (modalPreferences) =>
    (modalPreferences?.confirm_complete_task_visits || 0) < 10 &&
    !modalPreferences?.prevent_confirm_complete
);

export const getMyWorkloadSettings = createSelector(
  getMyTeamMembership,
  (teamMembership) => {
    const workloadSettings = teamMembership?.work_load_settings;
    if (workloadSettings?.display_member_capacity === true) {
      return {
        ...workloadSettings,
        display_member_capacity: 'percent'
      };
    }
    return workloadSettings;
  }
);

export const getMyPlannerSettings = createSelector(
  getMyTeamMembership,
  (teamMembership) => {
    const plannerSettings = teamMembership?.planner_settings;
    if (plannerSettings?.display_member_capacity === true) {
      return {
        ...plannerSettings,
        display_member_capacity: 'percent'
      };
    }
    return plannerSettings;
  }
);

export const getTaskAssigneeId = (state, ownProps) => ownProps.task.assignee_id;

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 *
 */
export const makeGetTeamMembershipByAccountId = () =>
  createSelector(
    getTeamMembershipsByAccountId,
    getMemberAccountId,
    (teamMembershipsByAccountId, memberAccountId) =>
      teamMembershipsByAccountId[memberAccountId]
  );

/**
 * @deprecated Use the typesafe version from `PermissionsModule/selectors`.
 */
export const makeGetMemberIsGuestByAccountId = () => {
  const getTeamMembershipByAccountId = makeGetTeamMembershipByAccountId();

  return createSelector(
    getTeamMembershipByAccountId,
    (membership) => membership && permissionsUtils.getIsProjectGuest(membership)
  );
};

export const makeGetMemberByAssigneeId = () => {
  return createSelector(
    getTaskAssigneeId,
    getTeamMembershipsByAccountId,
    (taskAssigneeId, teamMembershipsByAccountId) =>
      teamMembershipsByAccountId[taskAssigneeId]
  );
};

export const getSelectedBoard = (state) => state.groups.selectedBoard;
export const getSelectedBoardTeamId = (state) =>
  state.groups.selectedBoard && state.groups.selectedBoard.team_id;
export const getSelectedBoardName = (state) =>
  state.groups.selectedBoard && state.groups.selectedBoard.name;

const defaultBoardColumnOrder = ['tasks', 'updates'];

export const getBoardColumns = createSelector(
  getSelectedBoard,
  (board) => (board && board.column_order) || defaultBoardColumnOrder
);

export const getSelectedBoardId = createSelector(
  getSelectedProjectBoardId,
  getSelectedBoard,
  (selectedProjectBoardId, selectedBoard) =>
    (selectedBoard && selectedBoard.id) || selectedProjectBoardId
);
export const getSelectedBoardTeam = createSelector(
  getSelectedBoardId,
  getUsersTeams,
  (boardId, teams) =>
    teams.find(
      (team) => team.boards && team.boards.some((board) => board.id === boardId)
    )
);

export const getTeamFromSelectedBoard = createSelector(
  getSelectedBoardTeam,
  getAllTeams,
  (selectedBoardTeam, allTeams) =>
    selectedBoardTeam &&
    allTeams &&
    allTeams.find((team) => team.id === selectedBoardTeam.team_id)
);

export const getTeamNameFromSelectedBoard = createSelector(
  getTeamFromSelectedBoard,
  (team) => team && team.name
);

export const getUserIsGuest = createSelector(
  getTeamMembershipsById,
  getMe,
  getTeamFromSelectedBoard,
  (teamMembershipsById, me, team) => {
    const boardBasedTeamMembership =
      team &&
      me &&
      team.team_members.find((member) => member.account.id === me.id);
    if (boardBasedTeamMembership) {
      return permissionsUtils.getIsProjectGuest(boardBasedTeamMembership);
    } else {
      const myMember =
        me &&
        teamMembershipsById[me.team_membership_id] &&
        teamMembershipsById[me.team_membership_id];
      return myMember ? permissionsUtils.getIsProjectGuest(myMember) : true;
    }
  }
);
export const makeGetTeamMembershipByTaskAssigneeId = () =>
  createSelector(
    getTeamMembershipsByAccountId,
    getTaskAssigneeId,
    (teamMembershipsByAccountId, memberAccountId) =>
      teamMembershipsByAccountId[memberAccountId]
  );

export const getProjectIdFromProjectOrTask = (state, ownProps) => {
  if (ownProps.project) {
    return ownProps.project.id || emptyArray;
  } else if (ownProps.task) {
    return ownProps.task.project_id;
  }
  if (ownProps.projectId) {
    return ownProps.projectId;
  }
};

/**
 * does not handle unassigned roles
 */
export const makeGetProjectMemberships = (getters = emptyObj) => {
  const projectIdGetter =
    getters.projectIdGetter || getProjectIdFromProjectOrTask;
  const getProjectBasicInfoById = makeGetProjectBasicInfoById({
    projectIdGetter
  });
  return createSelector(
    getProjectBasicInfoById,
    getTeamMembershipsByAccountId,
    getSelectedProject,
    (projectInfo, teamMemberships, selectedProject) => {
      let hash = null;
      if (selectedProject) {
        hash = keyBy(
          selectedProject.project_membership,
          (membership) => membership?.account?.id
        );
      } else {
        hash =
          projectInfo?.member_account_ids?.map(
            (accountId) => teamMemberships[accountId]
          ) ?? emptyArray;
      }
      return hash;
    }
  );
};
const makeGetProjectManagers = (getters = emptyObj) =>
  createSelector(makeGetProjectMemberships(getters), (projectMembers) =>
    // projectMembers is either an array or object depending on *when* this is run apparently.
    Object.values(projectMembers || {}).filter((projectMembership) =>
      projectMembership.role_ids?.some((role) => role === ACCESS_ROLES.ADMIN)
    )
  );

export const makeGetProjectManagersByAccountId = (getters = emptyObj) =>
  createSelector(makeGetProjectManagers(getters), (projectManagers) =>
    keyBy(projectManagers, (projectManager) => projectManager.account.id)
  );

export const makeGetProjectMembershipsByAccountId = (getters = emptyObj) => {
  const getProjectMemberships = makeGetProjectMemberships(getters);
  return createSelector(getProjectMemberships, (projectMemberships) =>
    keyBy(
      projectMemberships,
      (projectMembership) => projectMembership?.account?.id
    )
  );
};

export const makeGetOwnTeamMembership = () => (state, ownProps) =>
  ownProps.teamMember;

export const makeGetProjectMembershipByTeamMemberId = (getters = emptyObj) => {
  const getOwnTeamMembership = makeGetOwnTeamMembership();
  return createSelector(
    getOwnTeamMembership,
    makeGetProjectMembershipsByAccountId(getters),
    (teamMember, projectMembers) =>
      teamMember && projectMembers && projectMembers[teamMember.account.id]
        ? projectMembers[teamMember.account.id]
        : emptyObj
  );
};
export const makeGetProjectMembershipByAccountId = (getters = emptyObj) => {
  return createSelector(
    getters.getAccountId || getMyUserId,
    makeGetProjectMembershipsByAccountId(getters),
    (accountId, projectMembers) =>
      accountId && projectMembers && projectMembers[accountId]
        ? projectMembers[accountId]
        : emptyObj
  );
};

export const makeAmIOnProject = () => {
  return createSelector(
    getMe,
    makeGetProjectMembershipsByAccountId(),
    (me, projectMembers) => !!(me && projectMembers && projectMembers[me.id])
  );
};
export const makeGetProjectMemberIsPM = (getters = emptyObj) => {
  const getProjectMembershipByTeamMemberId =
    makeGetProjectMembershipByTeamMemberId(getters);
  return createSelector(
    getProjectMembershipByTeamMemberId,
    (projectMembership) =>
      projectMembership &&
      projectMembership.role_ids?.some((role) => role === ACCESS_ROLES.ADMIN)
  );
};
export const makeGetUserIsPM = (getters = emptyObj) => {
  const getProjectMembershipByAccountId =
    makeGetProjectMembershipByAccountId(getters);
  return createSelector(
    getProjectMembershipByAccountId,
    (projectMembership) =>
      projectMembership &&
      projectMembership.role_ids?.some((role) => role === ACCESS_ROLES.ADMIN)
  );
};

export const makeGetCommentThreadAccount = () => (state, ownProps) =>
  (ownProps.commentThread && ownProps.commentThread.account) || emptyObj;

export const makeGetCommentAuthorIsPM = (getters = emptyObj) => {
  const getCommentThreadAccount = makeGetCommentThreadAccount();
  return createSelector(
    getCommentThreadAccount,
    makeGetProjectMembershipsByAccountId(getters),
    (account, projectMembershipsByAccountId) =>
      projectMembershipsByAccountId[account.id] &&
      projectMembershipsByAccountId[account.id].role_ids?.some(
        (role) => role === ACCESS_ROLES.ADMIN
      )
  );
};
export const makeGetCommentAuthorIsGuest = () => {
  const getCommentThreadAccount = makeGetCommentThreadAccount();
  return createSelector(
    getCommentThreadAccount,
    getTeamMembersHash,
    (account, teamMembers) =>
      account &&
      teamMembers[account.id] &&
      permissionsUtils.getIsProjectGuest(teamMembers[account.id])
  );
};

export const getSelectedTeamMemberId = createSelector(
  getTeamsState,
  (teamsState) => teamsState.selectedMemberId
);

export const getSelectedTeamMember = createSelector(
  getAllTeamMembers,
  getSelectedTeamMemberId,
  (teamMembers, selectedMemberId) =>
    teamMembers.find((member) => +member.id === +selectedMemberId)
);

/**
 * @deprecated Use the typesafe version from `TeamsModule/selectors`.
 */
export const getSortedTeamMembers = createSelector(
  getAllTeamMembers,
  getMyUserId,
  sortTeamMembers
);

const getOwnIdOrder = (state, ownProps) => ownProps.idOrder || emptyArray;
export const makeGetSortedTeamMembersByPropsIdOrder = () =>
  createSelector(
    getSortedTeamMembers,
    getOwnIdOrder,
    (sortedTeamMembers, idOrder) => {
      const idOrderToIndexHash = idOrder.reduce((acc, cur, index) => {
        acc[cur] = index + 1;
        return acc;
      }, {});
      const [idOrderMembers, otherMembers] = partition(
        sortedTeamMembers,
        (member) => idOrderToIndexHash[member.account.id]
      );
      const sortedIdMembers = idOrderMembers.sort(
        (a, b) =>
          idOrderToIndexHash[a.account.id] - idOrderToIndexHash[b.account.id]
      );
      return [...sortedIdMembers, ...otherMembers];
    }
  );

export const getSortedAlphabeticalTeamMembers = createSelector(
  getAllTeamMembersWithArchived,
  (allTeamMembers) =>
    allTeamMembers.slice().sort((a, b) => {
      if (a.is_archived !== b.is_archived) {
        return a.is_archived ? 1 : -1;
      }
      const aIsGuest = permissionsUtils.getIsProjectGuest(a);
      const bIsGuest = permissionsUtils.getIsProjectGuest(b);
      if (aIsGuest !== bIsGuest) {
        return aIsGuest ? 1 : -1;
      }
      if (a.account.name.toLowerCase() !== b.account.name.toLowerCase()) {
        return a.account.name.toLowerCase() > b.account.name.toLowerCase()
          ? 1
          : -1;
      }

      return 0;
    })
);

/**
 * Returns array of account ids of alphabetically sorted team members
 */
export const getAllSortedAlphabeticalTeamMemberIds = createSelector(
  getSortedAlphabeticalTeamMembers,
  (allTeamMembers) => allTeamMembers.map((member) => member.account.id)
);

export const getAllTeamMemberIds = createSelector(
  getAllTeamMembersWithArchived,
  (allTeamMembers) => allTeamMembers.map((member) => member.account.id)
);

export const getOrderedFilterActiveTeamMembers = createSelector(
  makeGetActiveWorkloadPlannerFilterIdHashes(),
  getSortedAlphabeticalTeamMembers,
  (filterHash, sortedTeamMembers) =>
    Object.keys(filterHash?.account_ids).length
      ? sortedTeamMembers.filter(
          (member) => filterHash?.account_ids[member?.account?.id]
        )
      : sortedTeamMembers
);

export const getOrderedFilterActiveTeamMemberAccountIds = createSelector(
  getOrderedFilterActiveTeamMembers,
  (sortedTeamMembers) => sortedTeamMembers.map((member) => member.account.id)
);

// Difference between this and getOrderedFilterActiveTeamMembers is
// that it returns empty array when none selected
export const getOrderedSelectedTeamMembers = createSelector(
  makeGetActiveWorkloadPlannerFilterIdHashes(),
  getSortedAlphabeticalTeamMembers,
  (filterHash, sortedTeamMembers) =>
    sortedTeamMembers.filter(
      (member) => filterHash?.account_ids[member?.account?.id]
    )
);

export const makeGetFilteredAccountIds = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    (filter) => filter.account_ids || emptyArray
  );

export const makeGetOrderedFilterTeamMembers = () =>
  createSelector(
    makeGetFilteredAccountIds(),
    getTeamMembershipsByAccountId,
    getSortedAlphabeticalTeamMembers,
    (filterAccountIdOrder, membersByAccountId, sortedTeamMembers) => {
      const sortedAccountIds = sortedTeamMembers.map(
        (member) => member?.account?.id
      );
      const idOrder = Array.from(
        new Set([...filterAccountIdOrder, ...sortedAccountIds])
      );
      const orderedFilterMembers = idOrder
        .map((id) => membersByAccountId[id])
        .filter((member) => member);
      return orderedFilterMembers;
    }
  );

export const getRegularTeamMembers = createSelector(
  getSortedTeamMembers,
  (members) => {
    return members.filter(
      (member) => !permissionsUtils.getIsProjectGuest(member)
    );
  }
);

/**
 * @deprecated Use the typesafe version from `PermissionsModule/selectors`.
 */
export const getGuests = createSelector(getSortedTeamMembers, (members) =>
  members.filter(permissionsUtils.getIsProjectGuest)
);
export const getArchivedMembers = createSelector(
  getAllTeamMembersWithArchived,
  (members) => members.filter((member) => member.is_archived)
);

/**
 * @deprecated Use the typesafe version from `PermissionsModule/selectors`.
 */
export const getGuestByAccountId = createSelector(getGuests, (guests) =>
  keyBy(guests, (guest) => guest.account.id)
);

export const getMentionableTeamMembers = createSelector(
  getAllTeamMembers,
  (teamMembers) =>
    teamMembers.map((membership) => ({
      ...membership.account,
      display: membership.account.name,
      isGuest: permissionsUtils.getIsProjectGuest(membership),
      isContractor: permissionsUtils.getIsContractor(membership)
    }))
);

export const getTeamMemberById = (id) => (state) =>
  state.teams.selectedTeam.team_members.find(
    (teamMember) => teamMember.account.id === id
  );
export const getOnHomeFollowed = createSelector(
  getRouterLocation,
  (routerlocation) =>
    routerlocation.pathname.includes('/home/') &&
    routerlocation.pathname.includes('followed')
);

export const getOnHomeProjects = createSelector(
  getRouterLocation,
  (routerlocation) =>
    routerlocation.pathname.includes('/home/') &&
    routerlocation.pathname.includes('projects')
);

export const getOnHomePlanner = createSelector(
  getRouterLocation,
  (routerlocation) => routerlocation.pathname.includes('/home/planner')
);

export const getNotificationIds = (state) =>
  state.notifications.allNotifications.map((notificationDayObj) =>
    notificationDayObj.notifications.map((notification) => notification.id)
  );

export const getNotificationCount = (state) =>
  flatten(getNotificationIds(state)).length;

export const getNavViewText = createSelector(
  getOnProjectDetail,
  getOnBoardView,
  getOnScheduleView,
  getOnMembersView,
  getOnProjectPlanner,
  getOnTeamPlannerTasks,
  getIsOnPersonalProject,
  getOnActivityFeed,
  getOnHomePlanner,
  getOnSettings,
  getIsOnOwnTimesheets,
  getIsOnProjectView,
  getIsOnTeamSettingsView,
  getSelectedProjectBoardInfo,
  getOnWorkloadView,
  getIsOnScheduleView,
  getIsOnReportsView,
  getIsOnDashboardView,

  (
    isOnProjectDetail,
    isOnBoardView,
    isOnScheduleView,
    isOnTeamPage,
    isOnProjectPlanner,
    isOnTeamPlannerTasks,
    isOnPersonalProject,
    isOnActivityFeed,
    isOnHomePlanner,
    isOnSettings,
    isOnOwnTimesheets,
    isOnProjectView,
    isOnTeamSettingsView,
    boardInfo,
    isOnWorkloadPage,
    isOnSchedulePage,
    isOnTimeReportsPage,
    isOnDashboardView
  ) => {
    if (isOnPersonalProject) {
      return 'Home';
    } else if (isOnTeamPage) {
      return 'Team';
    } else if (isOnBoardView || (isOnScheduleView && !isOnProjectDetail)) {
      return 'Portfolios';
    } else if (isOnTeamPlannerTasks || isOnProjectPlanner) {
      return 'Portfolio Planner';
    } else if (isOnHomePlanner) {
      return 'Home';
    } else if (isOnProjectDetail || isOnProjectView) {
      return boardInfo && boardInfo.name;
    } else if (isOnActivityFeed) {
      return 'Updates';
    } else if (isOnSettings) {
      return 'Profile';
    } else if (isOnTeamSettingsView) {
      return 'Settings';
    } else if (isOnWorkloadPage) {
      return 'Workload';
    } else if (isOnSchedulePage) {
      return 'Planner';
    } else if (isOnTimeReportsPage) {
      return 'Reports';
    } else if (isOnDashboardView) {
      return 'Dashboard';
    } else {
      return 'Home';
    }
  }
);

const getMyIdInArray = createSelector(getMe, (me) => me && me.id && [me.id]);
export const getTaskPlannerMemberIds = createSelector(
  getMe,
  getMyIdInArray,
  getTrackedNavigations,
  (me, myIdInArray, navigations) =>
    me
      ? isOnHomePlanner(navigations || [])
        ? myIdInArray
        : me.team_planner_members_order
      : emptyArray
);

export const getTimelineMemberId = createSelector(
  getPlannerModalAccountId,
  getSelectedTeamMember,
  isOnTeamMemberProfile,
  (modalId, selectedMember, onProfile) =>
    modalId || (onProfile && selectedMember && selectedMember.account.id)
);

export const getIsCurrentUser = createSelector(
  getTimelineMemberId,
  getMyUserId,
  getOwnAccountId,
  (memberId, myId, ownAccountId) => memberId === myId || ownAccountId === myId
);
export const makeGetIsCurrentUser = () =>
  createSelector(
    getMyUserId,
    getOwnAccountId,
    (myId, ownAccountId) => ownAccountId === myId
  );

export const getLastRouteHistory = createSelector(
  getNavigationHistory,
  (history) => history[0]
);
export const getTaskPlannerMembers = createSelector(
  getTrackedNavigations,
  getMe,
  getPlannerMembersArray,
  (navigations, me, plannerMembersArray) =>
    navigations[0] && navigations[0].section === 'HOME_PLANNER'
      ? [me]
      : plannerMembersArray
);

// Return all projects that have been added but not scheduled, separated
// by team member (e.g. {team_member_id: {"06/18/2018": [project_ids..]},...}}
export const getUnscheduledProjectsGroupedByMembers = (state) =>
  state.projectPlannerModal.members;

// Returns list of projects that this member has scheduled for
// this week but not added any schedule bars to
export const getNewPlannerModalProjectsByMember = createSelector(
  getPlannerModalAccountId,
  getUnscheduledProjectsGroupedByMembers,
  getPlannerModalDates,
  (id, newPlannerProjects, modalDates) => {
    if (newPlannerProjects && newPlannerProjects[id]) {
      return newPlannerProjects[id][
        modalDates.visibleTimeStart.format('MM/DD/YYYY')
      ];
    } else {
      return emptyArray;
    }
  }
);

export const getAllActivityRowMembers = createSelector(
  getTimesheetsState,
  (state) => state.members
);

export const getActivityRowsByMember = createSelector(
  getPlannerModalAccountId,
  getAllActivityRowMembers,
  getPlannerModalDates,
  (memberId, members, modalDates) => {
    const dateKey = keyifyDate(modalDates.visibleTimeStart);
    return members[memberId]
      ? members[memberId][dateKey]
        ? members[memberId][dateKey]
        : emptyObj
      : emptyObj;
  }
);
export const getOwnColumnId = (state, ownProps) => ownProps.column.id;

export const makeGetColumnIsSelected = () =>
  createSelector(
    getSubmissionDates,
    getOwnColumnId,
    (submissionDates, columnId) => !!submissionDates[columnId]
  );

export const getSelectedTimeEntries = createSelector(
  getSubmissionDates,
  getAllTimesheetsByDescription,
  (submissionDates, timesheetsByDescription) => {
    const selectedTimesheets = flatten(
      Object.values(timesheetsByDescription).map((description) =>
        Object.values(description)
      )
    ).filter((timeEntry) => submissionDates[timeEntry.date]);
    return selectedTimesheets;
  }
);
export const getSomeTimeEntryIsSelected = createSelector(
  getSelectedTimeEntries,
  (timeEntries) => timeEntries.length
);

export const getSubmissionStatus = createSelector(
  getTimesheetsState,
  (state) => state.submissionStatus
);

export const getTimesheetDateKeys = createSelector(
  getPlannerModalDates,
  ({ visibleTimeStart }) => createTimesheetWeekKeys(visibleTimeStart)
);

const baseTimesheetColumns = [
  { headerType: 'project', accessor: (row) => row.project_id, id: 'project' },
  {
    headerType: 'activity',
    accessor: (row) => row.activity_id,
    id: 'activity'
  },
  { headerType: 'description', accessor: (row) => row.title, id: 'description' }
];
const totalsTimesheetColumn = {
  headerType: 'total',
  accessor: (row) => row.total,
  id: 'total'
};

const rightPaddingColumn = {
  headerType: 'rightPadding',
  accessor: (row) => row,
  id: 'rightPadding'
};

const makeTimesheetDateAccessor = (date) => (row) =>
  row.timesheetsByDate &&
  row.timesheetsByDate[date] &&
  row.timesheetsByDate[date].id;

export const getTimesheetTableColumns = createSelector(
  getTimesheetDateKeys,
  (dateKeys) => [
    ...baseTimesheetColumns,
    ...dateKeys.map((dateKey) => ({
      accessor: makeTimesheetDateAccessor(dateKey),
      headerType: 'date',
      id: dateKey
    })),
    totalsTimesheetColumn,
    rightPaddingColumn
  ]
);

export const makeGetDescriptionFromId = () =>
  createSelector(
    getOwnId,
    getAllDescriptions,
    (descriptionId, allDescriptions) =>
      allDescriptions && allDescriptions[descriptionId]
  );

const getAccountId = (state, ownProps) => ownProps.accountId;
export const getPlannedPhasesHash = createSelector(
  getDescriptions,
  getAccountId,
  getPlannerModalDates,
  (descriptions, memberId, { visibleTimeStart }) => {
    const startDate = visibleTimeStart.clone().format('MM/DD/YYYY');
    const displayedDescriptions = descriptions.filter(
      (description) => description.date === startDate
    );
    const phaseIds = displayedDescriptions
      .filter((description) => description.account_id == memberId)
      .map((description) => description.phase_id);
    return makeIdHash(phaseIds);
  }
);

export const getDescriptionsGroupedByProject = createSelector(
  getAllDescriptionInfo,
  getAllActivityRowInfo,
  getDisplayedDescriptions,
  (allDescriptions, allActivities, displayedDescriptions) => {
    if (!allActivities) {
      return emptyObj;
    }

    const rowsByProject = Object.keys(displayedDescriptions).reduce(
      (acc, descriptionId) => {
        if (!allDescriptions[descriptionId]) {
          return acc;
        }
        if (!allDescriptions[descriptionId]) {
          return acc;
        }
        const projectId = allDescriptions[descriptionId].project_id;
        if (!acc[projectId]) {
          acc[projectId] = {};
        }
        acc[projectId][descriptionId] = displayedDescriptions[descriptionId];
        return acc;
      },
      {}
    );

    return rowsByProject;
  }
);
export const getDescriptionsGroupedByAccount = createSelector(
  getAllDescriptionInfo,
  getAllActivityRowInfo,
  getDisplayedDescriptions,
  (allDescriptions, allActivities, displayedDescriptions) => {
    if (!allActivities) {
      return emptyObj;
    }

    const rowsByAccount = Object.keys(displayedDescriptions).reduce(
      (acc, descriptionId) => {
        if (!allDescriptions[descriptionId]) {
          return acc;
        }
        if (!allDescriptions[descriptionId]) {
          return acc;
        }
        const accountId = allDescriptions[descriptionId].account_id;
        if (!acc[accountId]) {
          acc[accountId] = {};
        }
        acc[accountId][descriptionId] = displayedDescriptions[descriptionId];
        return acc;
      },
      {}
    );

    return rowsByAccount;
  }
);
export const getActivitiesOwnProjectId = (state, ownProps) => ownProps.group.id;

export const getActivitiesSelectedProjectId = createSelector(
  getTimesheetsState,
  (state) => state.dropdownProjectId
);
export const makeGetIsOpenActivitiesDropdown = () =>
  createSelector(
    getActivitiesOwnProjectId,
    getActivitiesSelectedProjectId,
    (projectId, selectedProjectId) => projectId === selectedProjectId
  );

export const getOwnProject = (state, ownProps) => ownProps.project || emptyObj;

export const getProjectModuleOrder = createSelector(
  getSelectedProject,
  getSplitFlags,
  (project, splitFlags) => {
    const moduleIdToSplitFlag = {
      8: 'scope'
    };
    if (!(project && project.app_modules && project.app_modules.length)) {
      return project.is_personal
        ? appModuleUtils.defaultNavModuleIds
        : appModuleUtils.defaultModuleIds;
    }
    const supportedModules = project.is_personal
      ? appModuleUtils.defaultNavModuleIds
      : appModuleUtils.allModuleIds;
    const projectModules = project.app_modules.filter((id) =>
      supportedModules.some((supportedId) => id === supportedId)
    );
    const moduleList = [
      ...projectModules,
      'break',
      ...difference(supportedModules, projectModules)
    ];

    return Object.keys(moduleIdToSplitFlag).length
      ? moduleList.filter(
          (moduleId) =>
            !moduleIdToSplitFlag[moduleId] ||
            splitFlags[moduleIdToSplitFlag[moduleId]]
        )
      : moduleList;
  }
);
export const getBoardModuleOrder = createSelector(getSelectedBoard, (board) => {
  if (!(board && board.board_modules && board.board_modules.length)) {
    return boardModuleUtils.defaultModuleIds;
  }
  const boardModules = board.board_modules.filter((id) =>
    boardModuleUtils.allModuleIds.some((supportedId) => id === supportedId)
  );
  return [
    ...boardModules,
    'break',
    ...difference(boardModuleUtils.allModuleIds, boardModules)
  ];
});

export const getMembersModuleOrder = createSelector(getSelectedTeam, (team) => {
  if (!(team && team.members_views_order && team.members_views_order.length)) {
    return membersModuleUtils.defaultModuleIds;
  }
  const teamModules = team.members_views_order.filter((id) =>
    membersModuleUtils.allModuleIds.some((supportedId) => id === supportedId)
  );
  return [
    ...teamModules,
    'break',
    ...difference(membersModuleUtils.allModuleIds, teamModules)
  ];
});
export const getActiveProjectModules = createSelector(
  getSelectedProject,
  (project) =>
    project && project.app_modules && project.app_modules.length
      ? project.app_modules
      : appModuleUtils.defaultModuleIds
);
export const getActiveBoardModules = createSelector(getSelectedBoard, (board) =>
  board && board.board_modules && board.board_modules.length
    ? board.board_modules
    : boardModuleUtils.defaultModuleIds
);
export const getActiveMembersModules = createSelector(
  getSelectedTeam,
  (team) => membersModuleUtils.defaultModuleIds
  // would be team.members_views_order if enabled
);
export const getInstalledProjectModuleIds = createSelector(
  getOwnProject,
  (project) =>
    project && project.app_modules && project.app_modules.length
      ? project.app_modules
      : appModuleUtils.defaultNavModuleIds
);
export const getInstalledBoardModuleIds = createSelector(
  getSelectedBoard,
  (board) => boardModuleUtils.defaultNavModuleIds
);
export const getInstalledMembersModuleIds = () =>
  membersModuleUtils.allModuleIds;

export const getInstalledWorkloadModuleIds = () =>
  workloadModuleUtils.allModuleIds;

export const getInstalledMembersSettingsModuleIds = createSelector(
  getSplitFlags,
  getSelectedTeamViewSettings,
  (flags, teamSettings) => {
    const installedModuleIds = membersSettingsModuleUtils.allModuleIds;
    const filterIds = [];

    if (!flags.isRoleEnabledInSettings) {
      filterIds.push(8);
    }

    if (!flags.isLocationEnabledInSettings) {
      filterIds.push(9);
    }

    if (!flags.isRegionEnabledInSettings) {
      filterIds.push(10);
    }

    if (!flags.isOfficeEnabledInSettings) {
      filterIds.push(11);
    }

    if (!flags.isDisciplineEnabledInSettings) {
      filterIds.push(12);
    }

    if (!flags.isPTOEnabledInSettings) {
      filterIds.push(13);
    }

    if (teamSettings?.is_hours_only) {
      // Filter out budget tab
      filterIds.push(3);
    }

    return installedModuleIds.filter(
      (moduleId) => !filterIds.includes(moduleId)
    );
  }
);

export const getInstalledStandardSettingsModuleIds = createSelector(
  getSplitFlags,
  getSelectedTeamViewSettings,
  (flags, teamSettings) => {
    const installedModuleIds = standardSettingsModuleUtils.allModuleIds;

    if (!flags.isPTOEnabledInSettings) {
      installedModuleIds.splice(installedModuleIds.indexOf(10), 1);
    }

    if (!flags.isCapacityEnabledInSettings) {
      installedModuleIds.splice(installedModuleIds.indexOf(11), 1);
    }

    if (teamSettings?.is_hours_only) {
      // Filter out budget tab
      installedModuleIds.splice(installedModuleIds.indexOf(6), 1);
    }
    return installedModuleIds;
  }
);

export const getInstalledIntegrationModuleIds = (state, ownProps) => {
  const { targetService } = ownProps.integration;
  const { moduleIdsToIgnore } = integrationsHash[targetService];
  if (moduleIdsToIgnore) {
    return integrationsModuleUtils.allModuleIds.filter(
      (moduleId) => !moduleIdsToIgnore.has(moduleId)
    );
  }

  return integrationsModuleUtils.allModuleIds;
};

export const getProjectDropdownId = createSelector(
  getTimesheetsState,
  (state) => state.dropdownProjectId
);

export const getFlickerActivityCellByProjectId = createSelector(
  getTimesheetsState,
  (state) => state.flickerActivityCell
);

export const getFlickerTaskCategoryCellByProjectId = createSelector(
  getTimesheetsState,
  (state) => state.flickerTaskCategoryCell
);

export const makeGetDescription = (getters = emptyObj) =>
  createSelector(
    getAllDescriptionInfo,
    getters.descriptionIdGetter || getDescriptionOwnId,
    (allDescriptions, id) => allDescriptions[id]
  );

export const makeGetActivityByDescriptionId = (getters = emptyObj) =>
  createSelector(
    getAllActivityRowInfo,
    getAllDescriptionInfo,
    getters.descriptionIdGetter || getDescriptionOwnId,
    (allActivities, allDescriptions, id) => {
      if (!id || !allDescriptions[id]) {
        return emptyObj;
      }
      return allActivities[allDescriptions[id].activity_id];
    }
  );
export const makeGetActivityByActivityId = (getters = emptyObj) =>
  createSelector(
    getAllActivityRowInfo,
    getters.activityIdGetter || getDescriptionOwnId,
    (allActivities, id) => {
      if (!id || !allActivities[id]) {
        return emptyObj;
      }
      return allActivities[id];
    }
  );
export const getActivitiesArray = createSelector(
  getAllActivityRowInfo,
  (activitiesObj) =>
    Object.values(activitiesObj).filter((activity) => !activity.archived)
);

export const getDefaultActivity = createSelector(
  getActivitiesArray,
  (activities) => activities.find((activity) => activity.is_default) || {}
);

export const getDefaultActivityId = createSelector(
  getDefaultActivity,
  (activity) => activity.id
);

export const getAllCustomActivities = createSelector(
  getAllActivityRowInfo,
  getSelectedTeam,
  (activitiesObj, team) => {
    const ActivityOrderHash = {};
    if (team) {
      team.nonbillable_activity_order.forEach((id) => {
        ActivityOrderHash[id] = true;
      });
      team.billable_activity_order.forEach((id) => {
        ActivityOrderHash[id] = true;
      });
    }
    return filter(
      activitiesObj,
      (activity) =>
        activity.is_custom &&
        !activity.archived &&
        !ActivityOrderHash[activity.id]
    );
  }
);

export const getCustomBillableActivities = createSelector(
  getAllCustomActivities,
  (customActivities) =>
    filter(customActivities, (activity) => activity.billable)
);

export const getCustomNonBillableActivities = createSelector(
  getAllCustomActivities,
  (customActivities) =>
    filter(customActivities, (activity) => !activity.billable)
);

export const getOrderedNonBillableActivities = createSelector(
  getAllActivityRowInfo,
  getSelectedTeam,
  (activitiesObj, team) => {
    return (
      team?.nonbillable_activity_order
        ?.map((id) => activitiesObj[id])
        .filter((activity) => activity && !activity.archived) ?? []
    );
  }
);

export const getOrderedBillableActivities = createSelector(
  getAllActivityRowInfo,
  getSelectedTeam,
  (activitiesObj, team) => {
    return (
      team?.billable_activity_order
        .map((id) => activitiesObj[id])
        .filter((activity) => activity && !activity.archived) ?? []
    );
  }
);
export const getOrderedArchivedActivities = createSelector(
  getAllActivityRowInfo,
  (activitiesObj) =>
    Object.values(activitiesObj)
      .filter((activity) => activity.archived)
      .sort((a, b) =>
        a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1
      )
);

export const getOrderedAllActivities = createSelector(
  getOrderedBillableActivities,
  getOrderedNonBillableActivities,
  (billableActivities, nonBillableActivities) => [
    ...billableActivities,
    ...nonBillableActivities
  ]
);
export const getOrderedAllActivitiesWithArchived = createSelector(
  getOrderedAllActivities,
  getOrderedArchivedActivities,
  (all, archived) => [...all, ...archived]
);

export const makeGetOrderedFilterActivities = () =>
  createSelector(
    makeGetActiveWorkloadPlannerFilter(),
    getOrderedAllActivities,
    getAllActivityRowInfo,
    (filter, allActivities, activitiesObj) => {
      const filterActivityIdOrder = filter?.activity_ids ?? emptyArray;
      const sortedActivityIds = allActivities.map((activity) => activity.id);
      const idOrder = Array.from(
        new Set([...filterActivityIdOrder, ...sortedActivityIds])
      );
      const orderedFilterActivities = idOrder
        .map((id) => activitiesObj[id])
        .filter((activity) => activity);
      return orderedFilterActivities;
    }
  );
export const getActivitiesTitleHash = createSelector(
  getActivitiesArray,
  (activitiesArray) =>
    activitiesArray.reduce((acc, cur) => {
      acc[cur.title] = cur.id;
      return acc;
    }, {})
);

const sumArray = (arr) =>
  arr.reduce(
    (a, b) => Math.round(100 * parseFloat(a) + 100 * parseFloat(b)) / 100,
    0
  );

const getTotalHoursForActivity = (timesheets, dateKeys) =>
  sumArray(
    dateKeys
      .map((key) => timesheets[key])
      .filter((timesheet) => timesheet !== undefined)
      .map((timesheet) => timesheet.hours)
  );

export const makeGetActivityTotalHours = () =>
  createSelector(
    getOwnTimesheets,
    getTimesheetDateKeys,
    getTotalHoursForActivity
  );

export const getOwnDateKeys = (state, ownProps) =>
  ownProps.dateKeys || emptyArray;
export const getOwnActivityRows = (state, ownProps) => ownProps.activityRows;

export const makeGetHoursByDateKeys = () =>
  createSelector(
    getOwnActivityRows,
    getOwnDateKeys,
    (activityRows = {}, dateKeys) =>
      sumArray(
        Object.values(activityRows).map((activityRow) =>
          getTotalHoursForActivity(activityRow, dateKeys)
        )
      )
  );

export const getActivityOwnId = (state, ownProps) => ownProps.id;
export const getDescriptionOwnId = (state, ownProps) => ownProps.id;
export const getConfirmingActivityDeleteId = createSelector(
  getTimesheetsState,
  (state) => state.deleteConfirmationId
);
export const getEditingActivityId = createSelector(
  getTimesheetsState,
  (state) => state.editActivityId
);
export const getEditingDescriptionActivityId = createSelector(
  getTimesheetsState,
  (state) => state.editDescriptionActivityId
);
export const getEditingDescriptionId = createSelector(
  getTimesheetsState,
  (state) => state.editDescriptionId
);
export const makeGetIsConfirmingActivityDelete = () =>
  createSelector(
    getActivityOwnId,
    getConfirmingActivityDeleteId,
    (ownId, deleteConfirmationId) => ownId == deleteConfirmationId
  );

export const getAddEditActivityMenu = createSelector(
  getTimesheetsState,
  (state) => state.addEditActivityMenu
);
export const getAddEditDescriptionMenu = createSelector(
  getTimesheetsState,
  (state) => state.addEditDescriptionMenu
);
export const getEditingActivity = createSelector(
  getAddEditActivityMenu,
  getEditingActivityId,
  getAddEditDescriptionMenu,
  getEditingDescriptionActivityId,
  getAllActivityRowInfo,
  (
    activityMenu,
    editingActivityId,
    descriptionMenu,
    descriptionActivityId,
    activitiesObj
  ) => {
    if (activityMenu.isOpen) {
      return (editingActivityId && activitiesObj[editingActivityId]) || null;
    } else if (descriptionMenu.isOpen) {
      return (
        (descriptionActivityId && activitiesObj[descriptionActivityId]) || null
      );
    } else {
      return null;
    }
  }
);

export const makeGetIsEditingActivity = () =>
  createSelector(
    getActivityOwnId,
    getEditingActivityId,
    (ownId, editingActivityId) => ownId === editingActivityId
  );

export const makeGetIsEditingDescription = () =>
  createSelector(
    getDescriptionOwnId,
    getEditingDescriptionId,
    (ownId, getEditingDescriptionId) => ownId === getEditingDescriptionId
  );

// Abstracts logic for whether to show a specific schedule in a given week
const includeInWeek = (startDate, endDate, schedule) => {
  const startsDuring =
    schedule.start_date.isSameOrAfter(startDate) &&
    schedule.start_date.isBefore(endDate);
  const endsDuring =
    schedule.end_date.isAfter(startDate) &&
    schedule.end_date.isSameOrBefore(endDate);
  const startsBeforeAndEndsAfter =
    schedule.start_date.isSameOrBefore(startDate) &&
    schedule.end_date.isSameOrAfter(endDate);
  return startsDuring || endsDuring || startsBeforeAndEndsAfter;
};

// Get all schedules that should be shown between startDate and endDate
const getSchedulesInRange = (startDate, endDate, schedules) => {
  return schedules.filter((schedule) =>
    includeInWeek(startDate, endDate, schedule)
  );
};

// Goes through projectIds and removes any projects that are in the
// newProjectsByMember store that manages state for newly added projects in modal
const getPreviouslyAddedProjects = (
  projectIds,
  newProjectsByMember = [],
  plannerProjects
) => {
  return projectIds
    .filter((id) => !newProjectsByMember.find((projectId) => projectId === id))
    .map((id) => {
      return {
        id: Number(id),
        title: plannerProjects[id].title,
        description: plannerProjects[id].description,
        project_number: plannerProjects[id].project_number,
        slug: plannerProjects[id].slug
      };
    });
};

// Return list of projects with id, title for all the projects this member
// can access
const getNewProjects = (
  newProjectsByMember = [],
  availableProjectsByMember
) => {
  return newProjectsByMember.map((id) => {
    const project = availableProjectsByMember.find(
      (project) => project.id === id
    );
    return {
      id: Number(id),
      title: project.title,
      description: project.description,
      project_number: project.project_number,
      slug: project.slug
    };
  });
};

/*
In this method, we do the following:
1. We get all of the scheduled bars in for the given week
2. We get all of the projects that have been added to this week via the modal
3. We split the projects into rows based on if they were already on the modal or not
   and also if they have any scheduled bars or not
*/
export const getPlannerModalProjects = createSelector(
  getPlannerModalSchedules,
  getPlannerModalDates,
  getNewPlannerModalProjectsByMember,
  getPlannerProjectsByMember,
  getProjectHash,
  getDescriptionsGroupedByProject,
  getSelectedTeamMember,
  getSelectedProject,
  (
    plannerSchedules,
    plannerModalDates,
    newProjectsByMember,
    availableProjectsByMember,
    allProjects,
    descriptionsGroupedByProject,
    selectedTeamMember,
    selectedProject
  ) => {
    if (selectedProject) {
      return [
        {
          id: selectedProject.id,
          title: selectedProject.title,
          description: selectedProject.description,
          project_number: selectedProject.project_number,
          slug: selectedProject.slug
        }
      ];
    }
    const { visibleTimeStart, visibleTimeEnd } = plannerModalDates;
    const projectsInDateRange = getSchedulesInRange(
      visibleTimeStart,
      visibleTimeEnd,
      plannerSchedules
    );

    const scheduledProjectIds = Array.from(
      new Set(projectsInDateRange.map((schedule) => schedule.project_id))
    );

    const newProjects = getNewProjects(
      newProjectsByMember,
      availableProjectsByMember || []
    );

    // These projects were in the modal at the start of the session,
    // meaning they didn't just add and schedule these projects.
    // the reason for this distinction is that we don't want the
    // order in which these projects to appear to jump around
    // when the user adds a schedule for a project they just added
    const previouslyAddedProjects = getPreviouslyAddedProjects(
      scheduledProjectIds,
      newProjectsByMember,
      allProjects
    );

    const timesheetProjects = (
      selectedTeamMember
        ? Object.keys(descriptionsGroupedByProject)
            .map((id) => allProjects[id])
            .filter((row) => row && !scheduledProjectIds.includes(row.id))
        : []
    ).filter(
      (timesheetProject) =>
        !newProjects
          .map((newProject) => newProject.id)
          .includes(timesheetProject.id)
    );
    // This array will be used in the modal to construct the rows
    return [...previouslyAddedProjects, ...timesheetProjects, ...newProjects];
  }
);

export const getPlannerModalProjectsHash = createSelector(
  getPlannerModalProjects,
  (projectsArray) => keyBy(projectsArray, (project) => project.id)
);

export const makeGetPlannerModalProjectById = () =>
  createSelector(
    getPlannerModalProjectsHash,
    getOwnProjectId,
    (projects, id) => projects[id] || emptyObj
  );

export const getPlannerModalMember = createSelector(
  getPlannerModalAccountId,
  getPlannerMembersArray,
  (memberId, plannerMembers) =>
    plannerMembers.find((member) => member && member.account_id === memberId)
);

export const getProjectComments = (state) => state.projectComments.projects;
export const getSelectedProjectComments = createSelector(
  getSelectedProjectId,
  getProjectComments,
  (projectId, projectsObj) =>
    Object.values(projectsObj[projectId] || {}).map((comment) => ({
      ...comment,
      is_comment: true
    }))
);

export const getProjectMetadata = (state) => state.projectMetadata.projects;
export const getSelectedProjectMetadata = createSelector(
  getSelectedProjectId,
  getProjectMetadata,
  (projectId, projectsObj) =>
    projectsObj[projectId]
      ? Object.values(projectsObj[projectId]).map((metadata) => ({
          ...metadata,
          is_metadata: true
        }))
      : emptyArray
);

export const getSortedProjectCommentsAndMetadata = createSelector(
  getSelectedProjectComments,
  getSelectedProjectMetadata,
  (projectComments, projectMetadata) => {
    return [...projectComments, ...projectMetadata].sort((a, b) => {
      const aComparisonValue = a.created_at ? a.created_at : a.creation_date;
      const bComparisonValue = b.created_at ? b.created_at : b.creation_date;
      return -moment(aComparisonValue).diff(moment(bComparisonValue));
    });
  }
);

export const getDateHeader = (state) => state.homePlanner.dateHeader;
export const getOwnDateHeader = (state, ownProps) => ownProps.dateHeader;

export const getHomePlannerEditedTasks = (state) =>
  state.homePlanner.bulkUpdateModal.editedTasks;

export const makeGetBulkUpdateHomeTaskDisplay = () =>
  createSelector(
    getHomeTasksEditedTasks,
    getOwnBulkTaskId,
    (homeEditedTasks, ownTaskId) => homeEditedTasks[ownTaskId]
  );

export const getHomeModalTasks = createSelector(
  getHomeModalTaskIds,
  getHomeTaskObj,
  (taskIds, tasksObj) =>
    (taskIds || []).reduce((acc, cur) => {
      acc[cur] = tasksObj[cur];
      return acc;
    }, {})
);
export const getBulkModalCalendarType = (state) =>
  state.homeTasks.flaggedTasksModalOpen ? 'due_at' : 'schedule_start';

const getBulkUpdateTaskDisplay = (state, props) => {
  const { task } = props;
  const { bulkUpdateModal } = state.homePlanner;
  const bulkUpdateTask = bulkUpdateModal.editedTasks[task.id];
  return bulkUpdateTask && bulkUpdateTask.schedule_start;
};

export const getPlannerTaskProjects = (state) => state.homePlanner.projects;

/* add project_name here even though we *could* in getPlannerTask because this function creates a new object every time and we only want to give the component a new object if the results of getPlannerTask and getPlannerTaskProject changes */

export const makeGetPlannerTask = () =>
  createSelector(
    getPlannerTask,
    getPlannerTaskProjects,
    (task, plannerTaskProjects) => {
      if (!task) {
        return emptyObj;
      }
      const project = plannerTaskProjects[task.project_id];
      if (!project) {
        return task;
      } else {
        return {
          ...task,
          project_name: project.title
        };
      }
    }
  );

const getOwnDate = (state, ownProps) => ownProps.date;

export const getIncludesTeam = (state, ownProps) => ownProps.includesTeam;
export const getUnscheduledPlannerTaskIds = createSelector(
  getPlannerTaskHash,
  getIncludesTeam,
  getCurrentUserId,
  (taskHash, includesTeam, currentUserId) => {
    return Object.values(taskHash)
      .filter((task) => {
        const isUnscheduled =
          !task.schedule_start || task.schedule_start === 'unscheduled';
        const isIncomplete = !task.completed_at;
        const isAssignedToUser = task.assignee_id === currentUserId;
        return includesTeam
          ? isUnscheduled && isIncomplete
          : isUnscheduled && isIncomplete && isAssignedToUser;
      })
      .map((task) => task.id);
  }
);
export const getInboxTasks = createSelector(
  getHomeTaskObj,
  getAllTasks,
  (state) => state.homeTasks.taskRemovals,
  (homeTasksObj, allTasks, taskRemovals) =>
    allTasks.filter((taskId) => {
      const task = homeTasksObj[taskId];
      return taskRemovals[taskId] ? taskId : task && !task.completed_at;
    })
);

/**
 * @deprecated Will be removed once the content from `taskDrawerUpdateFlag` is
 * finalized.
 */
export const getUnscheduledTasks = createSelector(getHomeTaskObj, (homeTasks) =>
  Object.values(homeTasks)
    .filter((task) => task && !task.schedule_start && !task.completed_at)
    .sort((task) => task.id)
);

/**
 * @deprecated Will be removed once the content from `taskDrawerUpdateFlag` is
 * finalized.
 */
export const getUnscheduledSidebarTasks = createSelector(
  getUnscheduledTasks,
  getTaskSidebarProjectId,
  (tasks, projectId) => tasks.filter((task) => +task?.project_id === +projectId)
);

export const makeGetPlannerColumn = () =>
  createSelector(
    getTaskPlanners,
    getOwnAccountId,
    getOwnDate,
    getUnscheduledPlannerTaskIds,
    (planners, accountId, date, unscheduledIds) => {
      const accountPlanners = planners[accountId];
      const column = accountPlanners && accountPlanners[date];
      if (date === 'unscheduled') {
        return {
          date,
          tasks: unscheduledIds
        };
      } else if (!column) {
        return {
          date: date,
          tasks: emptyArray
        };
      } else {
        return column;
      }
    }
  );

export const makeIsDropDisabled = () =>
  createSelector(getPlannerDateHeader, getOwnDate, (dateHeader, ownDate) =>
    moment(dateHeader).isAfter(moment(ownDate), 'd')
  );

const getLongestDayHeight = (planner) => {
  if (!planner) {
    return 0;
  } else {
    return Object.keys(planner).reduce((acc, dateKey) => {
      if (dateKey !== 'unscheduled' && planner[dateKey].tasks.length > acc) {
        return planner[dateKey].tasks.length;
      } else {
        return acc;
      }
    }, 0);
  }
};

export const getPlannerRowHeights = createSelector(
  getTaskPlanners,
  getPlannerMemberIds,
  (planners, memberIds) =>
    memberIds.reduce((acc, id) => {
      const planner = planners[id];
      const rowHeight = getLongestDayHeight(planner);
      return {
        ...acc,
        [id]: rowHeight
      };
    }, {})
);

export const makeGetBulkUpdateTaskDisplay = () =>
  createSelector(getBulkUpdateTaskDisplay, (taskDisplay) => taskDisplay);

export const isProjectCommentsOpen = (state) => state.projectComments.isOpen;
export const getTaskDetail = (state) => state.taskDetail;

export const getMyPlanner = createSelector(
  getCurrentUserId,
  getTaskPlanners,
  (myId, planners) => planners[myId]
);

export const getMyUnscheduledTaskInfo = createSelector(
  getUnscheduledPlannerTaskIds,
  getPlannerTaskHash,
  getPlannerTaskProjects,
  (taskIds, taskHash, projects) => {
    const projectTaskInfo = taskIds.map((taskId) => {
      const task = taskHash[taskId];
      const project = projects[task.project_id] || {
        title: 'No Project'
      };

      return {
        ...task,
        description: task.description,
        projectTitle: project.title
      };
    });

    return projectTaskInfo;
  }
);

export const getDefaultMembersToNotify = createSelector(
  isProjectCommentsOpen,
  getTaskDetail,
  getSelectedProject,
  (isProjectComment, selectedTask, selectedProject) => {
    if (isProjectComment) {
      return !selectedProject
        ? emptyArray
        : selectedProject.members_to_notify || emptyArray;
    } else if (selectedTask.taskId) {
      return selectedTask.membersToNotify;
    } else {
      return emptyArray;
    }
  }
);

export const makeSafeHomePlannerGetProjects = () =>
  createSelector(
    getMyProjects,
    makeGetPlannerTask(),
    getPlannerTaskProjects,
    (myProjects, task, plannerTaskProjects) => {
      if (!task) {
        // adding a task
        return myProjects;
      } else if (plannerTaskProjects[task.project_id]) {
        return myProjects.filter(
          (project) =>
            project.is_personal ===
            plannerTaskProjects[task.project_id].is_personal
        );
      } else {
        return myProjects;
      }
    }
  );

const getTaskIsEditingBoolean = (taskId, taskEditId) => taskId === taskEditId;

const getIsBatchSelectedBoolean = (taskId, selectedBatchTaskIds) =>
  includes(selectedBatchTaskIds, taskId);

export const taskIsEditing = memoizeOne(getTaskIsEditingBoolean);
export const isBatchSelected = memoizeOne(getIsBatchSelectedBoolean);
export const makeGetOwnTaskInfo = () =>
  createSelector(
    getHomeTaskObj,
    getProjectHash,
    getOwnTaskId,
    (taskHash, projectHash, taskId) => {
      const projectId = taskHash[taskId]?.project_id;

      if (taskRemoveTypes[taskId]) {
        return taskId;
      } else if (taskHash[taskId]) {
        return {
          ...taskHash[taskId],
          project: {
            ...taskHash[taskId].project,
            is_personal: projectHash[projectId]?.is_personal,
            is_default: projectHash[projectId]?.is_default
          }
        };
      } else {
        return emptyObj;
      }
    }
  );

export const makeGetOwnTaskInfoFromView = () =>
  createSelector(
    getCurrentFilter,
    getHomeTaskObj,
    getPlannerTaskHash,
    getProjectHash,
    getOwnTaskId,
    (taskFilter, homeTaskHash, plannerTaskHash, projectHash, taskId) => {
      const taskHash =
        taskFilter.viewType === 'DAY' ? plannerTaskHash : homeTaskHash;
      const task = taskHash[taskId];
      const projectId = task?.project_id;

      if (taskRemoveTypes[taskId]) {
        return taskId;
      } else if (task) {
        return {
          ...task,
          project: projectHash[projectId]
        };
      } else {
        return emptyObj;
      }
    }
  );

const getOwnTaskGroupId = (state, ownProps) => ownProps.taskGroupId;
export const makeGetTaskGroup = (getters = emptyObj) =>
  createSelector(
    getProjectTaskGroups,
    getters.taskGroupIdGetter || getOwnTaskGroupId,
    (taskGroups, taskGroupId) => taskGroups[taskGroupId]
  );

export const makeGetIsFollowedByMe = () => {
  const getOwnTaskInfo = makeGetOwnTaskInfo();
  return createSelector(getOwnTaskInfo, getMe, (task, me) =>
    task && task.followers && me
      ? task.followers.map((follower) => follower.id).includes(me.id)
      : false
  );
};

export const getUserTheme = (state) => state.userTheme;
export const getTheme = createSelector(
  getUserTheme,
  (userTheme) => userTheme.theme
);
export const getOpenColorPickerId = createSelector(
  getUserTheme,
  (userTheme) => userTheme.openPickerId
);
export const getOpenColorPickerOriginType = createSelector(
  getUserTheme,
  (userTheme) => userTheme.openPickerOriginType
);

export const getOpenColorPickerLocation = createSelector(
  getUserTheme,
  (userTheme) => userTheme.openPickerLocation
);

export const getOwnId = (state, ownProps) => ownProps.id;

export const getOwnOriginType = (state, ownProps) => ownProps.originType;

export const getOwnLocation = (state, ownProps) => ownProps.pickerLocation;

export const makeGetColorPickerIsOpen = () =>
  createSelector(
    getOpenColorPickerId,
    getOpenColorPickerOriginType,
    getOpenColorPickerLocation,
    getOwnId,
    getOwnOriginType,
    getOwnLocation,
    (
      openPickerId,
      openPickerOriginType,
      openPickerLocation,
      ownId,
      ownOriginType,
      ownLocation
    ) =>
      openPickerId === ownId &&
      openPickerOriginType === ownOriginType &&
      openPickerLocation === ownLocation
  );

const originTypeToThemeHash = {
  project: 'projectColors',
  board: 'boardColors',
  taskGroup: 'taskGroupColors',
  tag: 'tagColors',
  teamMembership: 'teamMembershipColors',
  team: 'teamColors'
};
export const getThemeSlice = (state, ownProps) =>
  originTypeToThemeHash[ownProps.originType];

export const makeGetPreference = () =>
  createSelector(
    getTheme,
    getThemeSlice,
    getOwnId,
    (theme, themeSlice, id) =>
      theme && theme[themeSlice] && theme[themeSlice][id]
  );

export const makeGetPreferenceExistence = () => {
  const getPreference = makeGetPreference();
  return createSelector(getPreference, (preference) => !!preference);
};
export const getTeamTaskTags = (state) => state.homeTaskTags.teamTaskTags;
export const getPersonalTaskTags = (state) =>
  state.homeTaskTags.personalTaskTags;

export const getSortedTeamTaskTags = createSelector(getTeamTaskTags, (tags) =>
  sortBy(uniqBy(tags, 'title'), [(tag) => tag.title.toLowerCase()])
);
export const getSortedPersonalTaskTags = createSelector(
  getPersonalTaskTags,
  (tags) => sortBy(uniqBy(tags, 'title'), [(tag) => tag.title.toLowerCase()])
);

export const getAllTaskTags = createSelector(
  getTeamTaskTags,
  getPersonalTaskTags,
  (teamTagsArray, personalTagsArray) => {
    const uniqueTags = uniqBy(
      [...teamTagsArray, ...personalTagsArray],
      'title'
    );
    return sortBy(uniqueTags, [(tag) => tag.title.toLowerCase()]);
  }
);

export const getTagsByTitle = createSelector(getAllTaskTags, (taskTags) =>
  keyBy(taskTags, (tag) => tag.title.toLowerCase())
);

export const getTributeTaskTags = createSelector(
  getAllTaskTags,
  (taskTagsArray) =>
    taskTagsArray.map((tag) => ({ key: tag.title, value: tag.title }))
);

export const getUniqueNoteTags = createSelector(
  getTags,
  (tags) => tags.uniqNoteTags
);
export const getTributeNoteTags = createSelector(
  getUniqueNoteTags,
  (noteTagsArray) =>
    sortBy(noteTagsArray, [(tag) => tag.title.toLowerCase()]).map((tag) => ({
      key: tag.title,
      value: tag.title
    }))
);

export const getAllNoteTags = (state) => state.tags.allNoteTags;
export const getEditingNoteId = createSelector(
  getNotes,
  (notes) => notes.editingNoteId
);
export const getNoteAccountIds = createSelector(
  getNotes,
  (notes) => notes.selectedAccountIds || emptyArray
);
export const getNoteAccountIdHash = createSelector(
  getNoteAccountIds,
  (idArray) => keyBy(idArray, (id) => id)
);

export const getNoteTagsByProject = createSelector(
  getAllNoteTags,
  getRouterProjectId,
  getNoteAccountIdHash,
  (noteTagsArray, projectId, selectedAccountHash) => {
    if (!projectId) return emptyArray;
    const isMemberFilterActive = !isEmpty(selectedAccountHash);
    const tagsByProject = noteTagsArray.filter((tag) => {
      const tagMatchesProject = +tag.project_id === +projectId;
      const tagPassesMemberFilter = isMemberFilterActive
        ? selectedAccountHash[tag.account_id]
        : true;
      return tagMatchesProject && tagPassesMemberFilter;
    });
    const uniqueTagTitles = uniqBy(tagsByProject, 'title');
    return sortBy(uniqueTagTitles, (tag) => tag.title.toLowerCase());
  }
);

export const getEditingTask = (state) => state.tasks.editTaskId;

export const makeGetIsThisTaskEditing = () =>
  createSelector(
    getEditingTask,
    getOwnTaskId,
    (editTaskId, ownTaskId) => editTaskId === ownTaskId
  );

export const isSomeTaskEditing = (state) => !!state.tasks.editTaskId;

export const getSelectedHomeTask = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.selectedTask
);
export const getTaskModalIsOpen = createSelector(
  getSelectedHomeTask,
  (task) => !!task
);
export const getConfirmCompleteTaskModalIsOpen = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.confirmCompleteModalIsOpen
);
export const getConfirmCompleteTaskModalTaskId = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.confirmCompleteModalTaskId
);
export const getConfirmCompleteTaskModalTask = createSelector(
  getConfirmCompleteTaskModalTaskId,
  getHomeTaskObj,
  (taskId, taskHash) => taskHash[taskId]
);

export const makeGetIsSelectedHomeTask = () =>
  createSelector(
    getOwnTaskId,
    getSelectedHomeTask,
    (editTaskId, ownTaskId) => editTaskId === ownTaskId
  );

export const getTaskEditProperty = createSelector(
  getHomeTasks,
  (homeTasks) => homeTasks.taskEditProperty
);

export const getOwnTaskProperty = (state, ownProps) => ownProps.taskProperty;

export const getIsTaskEditProperty = createSelector(
  getTaskEditProperty,
  getOwnTaskProperty,
  (taskEditProperty, ownTaskProperty) => taskEditProperty === ownTaskProperty
);

export const getIsSelectedTaskAndProperty = () => {
  const getIsSelectedHomeTask = makeGetIsSelectedHomeTask();
  return createSelector(
    getIsTaskEditProperty,
    getIsSelectedHomeTask,
    (isTaskEditProperty, isSelectedHomeTask) =>
      isTaskEditProperty === isSelectedHomeTask
  );
};

export const baseModalColumns = ['schedule_start'];
export const baseHomeColumns = ['schedule_start', 'priority'];
export const completedHomeColumns = ['schedule_start', 'completed_at'];
export const getHomeColumns = createSelector(
  getCurrentFilter,
  getIsMemberModalOpen,
  ({ section, subSection }, isMemberModalOpen) => {
    if (
      section === 'My Tasks' &&
      subSection === 'completed' &&
      !isMemberModalOpen
    ) {
      return completedHomeColumns;
    } else {
      return baseHomeColumns;
    }
  }
);
const allColumns = [
  'due_at',
  'schedule_start',
  'estimated_hours',
  'status',
  'priority',
  'phase_id',
  'task_group_id'
];
const baseProjectColumns = ['schedule_start', 'priority'];
export const getProjectTaskColumns = createSelector(
  getSelectedProject,
  (selectedProject) => selectedProject?.task_columns ?? baseProjectColumns
);
const disabledColumnsFilter = (column, isOnPersonalProject) =>
  column !== 'phase_id' || !isOnPersonalProject;
export const getActiveTaskColumnOrder = createSelector(
  getOnHomeView,
  getIsOnTeamProject,
  getIsOnPersonalProject,
  getProjectTaskColumns,
  getHomeColumns,
  (
    isOnHomeView,
    isOnTeamProject,
    isOnPersonalProject,
    projectTaskColumns,
    homeColumns
  ) =>
    ((isOnTeamProject || isOnPersonalProject) && !isOnHomeView
      ? projectTaskColumns
      : homeColumns
    ).filter((column) => disabledColumnsFilter(column, isOnPersonalProject))
);

export const getTaskColumnOrderWithSelection = createSelector(
  getActiveTaskColumnOrder,
  getIsOnTeamProject,
  getOnHomeView,
  getCurrentPage,
  getOnHomePlanner,
  getCurrentFilter,
  getSplitFlags,
  (
    columns,
    isOnTeamProject,
    isOnHomeView,
    activePage,
    isOnPlannerview,
    taskFilter,
    splitFlags
  ) =>
    Array.from(
      new Set([
        // Show assignee column for team projects, and assigned by for home page. Hide on personal
        ...(isOnTeamProject || activePage.isAssignedByMe
          ? ['assignee']
          : isOnHomeView
          ? ['assigned']
          : []),
        ...columns,
        'selection',
        // Show timer on  all views
        ...(splitFlags.timeTrackingFlag ? ['timer'] : [])
      ])
    )
);

export const getTaskColumnsForTableOrder = createSelector(
  getTaskColumnOrderWithSelection,
  (columns) =>
    Array.from(new Set(['drag', 'completed', 'description', ...columns]))
);

const stubColumn = {
  headerType: SORT_BY.date,
  headerLabel: '',
  accessor: (row) => row.date,
  id: 'stub',
  align: 'center'
};
const dragColumn = {
  headerType: 'drag',
  headerLabel: '',
  accessor: (row) => row.id,
  id: 'drag',
  align: 'center'
};
const dueAtColumn = {
  headerType: 'due_at',
  headerLabel: 'DUE',
  accessor: (row) => row.due_at,
  id: 'due_at',
  align: 'center'
};
const scheduleStartColumn = {
  headerType: 'schedule_start',
  headerLabel: 'PLANNED',
  accessor: (row) => row.schedule_start,
  id: 'schedule_start',
  align: 'center'
};

const completedColumn = {
  headerType: 'completed',
  headerLabel: '',
  accessor: (row) => row.is_complete,
  id: 'completed',
  align: 'center'
};

const completedAtColumn = {
  headerType: 'completed_at',
  headerLabel: 'COMPLETED AT',
  accessor: (row) => row.completed_at,
  id: 'completed_at',
  align: 'center'
};

const assigneeColumn = {
  headerType: 'assignee',
  headerLabel: 'ASSIGNEES',
  accessor: (row) => row.assignees,
  id: 'assignee',
  align: 'center'
};
const phaseColumn = {
  headerType: SORT_BY.phase,
  headerLabel: 'PHASE',
  accessor: (row) => row.phase,
  id: 'phase',
  align: 'center'
};

const taskGroupColumn = {
  headerType: SORT_BY.task_group,
  headerLabel: 'LIST',
  accessor: (row) => row.taskGroup,
  id: 'task_group',
  align: 'center'
};

const taskStatusColumn = {
  headerType: 'status',
  headerLabel: 'STATUS',
  accessor: (row) => row.task_status_id,
  id: 'status',
  align: 'center'
};
const taskPriorityColumn = {
  headerType: 'priority',
  headerLabel: 'PRIORITY',
  accessor: (row) => row.task_priority_id,
  id: 'priority',
  align: 'center'
};
const estimatedHoursColumn = {
  headerType: 'estimated_hours',
  headerLabel: 'TIME',
  accessor: (row) => row.estimated_hours,
  id: 'estimated_hours',
  align: 'center'
};

const descriptionColumn = {
  headerType: SORT_BY.description,
  headerLabel: '',
  accessor: (row) => row.description_title,
  id: 'description',
  align: 'center'
};

const bulkColumn = {
  headerType: 'bulk',
  headerLabel: 'ALL',
  accessor: (row) => row.isSelected,
  id: 'bulk',
  align: 'center'
};

const timerColumn = {
  headerType: 'timer',
  headerLabel: 'TIMER',
  accessor: (row) => row.timer,
  id: 'timer',
  align: 'center'
};

const emptyColumn = {
  headerType: 'empty',
  headerLabel: '',
  accessor: (row) => 'empty',
  id: 'empty',
  align: 'center'
};

const taskColumns = {
  drag: dragColumn,
  completed: completedColumn,
  description: descriptionColumn,
  assignee: assigneeColumn,
  due_at: dueAtColumn,
  schedule_start: scheduleStartColumn,
  completed_at: completedAtColumn,
  phase_id: phaseColumn,
  task_group_id: taskGroupColumn,
  status: taskStatusColumn,
  priority: taskPriorityColumn,
  estimated_hours: estimatedHoursColumn,
  selection: bulkColumn,
  timer: timerColumn,
  emptyColumn
  // statusColumn
};
const filterColumnByViewBy = {
  phase: VIEW_BY.PHASES,
  task_group: VIEW_BY.TASK_GROUPS,
  priority: VIEW_BY.TASK_PRIORITIES
};

export const getTaskColumnsForTable = createSelector(
  getTaskColumnsForTableOrder,
  (state) => state.homeTasks.viewBy,
  (columnOrder, viewBy) =>
    columnOrder
      .map(
        (column) =>
          taskColumns[column] || { ...stubColumn, id: column, width: 80 }
      )
      .filter((column) => filterColumnByViewBy[column.id] !== viewBy)
);

export const prepGetTaskColumnOrder = createSelector(
  getActiveTaskColumnOrder,
  getIsOnPersonalProject,
  (activeTaskColumnOrder, isOnPersonalProject) =>
    [
      ...activeTaskColumnOrder,
      'break',
      ...difference(allColumns, activeTaskColumnOrder)
    ].filter((column) => disabledColumnsFilter(column, isOnPersonalProject))
);

export const getTaskColumnOrder = createSelector(
  prepGetTaskColumnOrder,
  getIsOnTeamProject,
  getSelectedProject,
  (taskColumns, isOnTeamProject, selectedProject) => {
    const dueDateEnabled = isOnTeamProject && selectedProject?.due_date_enabled;
    if (dueDateEnabled) {
      return taskColumns;
    } else {
      return taskColumns.filter((column) => column !== 'due_at');
    }
  }
);

const allBoardColumns = [
  'tasks',
  'remaining_tasks',
  'notes',
  'client',
  'number',
  'members',
  'updates',
  'status',
  'stage',
  'priority'
];

export const getBoardColumnOrder = createSelector(
  getBoardColumns,
  (columns) => [...columns, 'break', ...difference(allBoardColumns, columns)]
);

export const getResponsiveState = (state) => state.browser || emptyObj;
export const getResponsiveMediaType = createSelector(
  getResponsiveState,
  (browserState) => browserState.mediaType || ''
);
export const getResponsiveBreakpoints = createSelector(
  getResponsiveState,
  (browserState) => browserState.breakpoints || emptyObj
);

export const getResponsiveStateGreaterThan = createSelector(
  getResponsiveState,
  (browserState) => browserState.greaterThan || emptyObj
);

/* milestones */

export const getAllMilestones = (state) => state.milestones;

export const getEditingMilestoneId = createSelector(
  getAllMilestones,
  (milestones) => milestones.editingMilestoneId
);
export const getDeletingMilestoneId = createSelector(
  getAllMilestones,
  (milestones) => milestones.deletingMilestoneId
);
export const getIsMilestoneModalOpen = createSelector(
  getAllMilestones,
  (milestones) => milestones.isMilestoneModalOpen
);
export const getMilestoneModalProject = createSelector(
  getAllMilestones,
  (milestones) => milestones.milestoneModalProject
);
export const getModulesModalIsOpen = (state) => state.modules.open;

export const makeGetProjectBasicInfo = (getters = emptyObj) =>
  createSelector(
    getters.projectIdGetter || getOwnProjectId,
    getProjectHash,
    (id, hash) => {
      return hash[id] || emptyObj;
    }
  );

export const getStages = createSelector(
  getSelectedBoard,
  (selectedBoard) => (selectedBoard && selectedBoard.stages) || emptyArray
);
export const getStagesById = createSelector(getStages, (stages) =>
  keyBy(stages, byId)
);

export const getOwnStageId = (state, ownProps) => ownProps.stageId;

export const makeGetOwnStage = () =>
  createSelector(
    getStagesById,
    getOwnStageId,
    (statuses, ownStageId) => statuses[ownStageId]
  );

export const getStageOrder = createSelector(
  getSelectedBoard,
  (selectedBoard) => (selectedBoard && selectedBoard.stage_order) || emptyArray
);

export const getOrderedStages = createSelector(
  getStagesById,
  getStageOrder,
  (stagesById, stageIds) =>
    stageIds.map((id) => stagesById[id]).filter((stage) => !!stage)
);

export const getStatuses = createSelector(
  getSelectedBoard,
  (selectedBoard) => (selectedBoard && selectedBoard.statuses) || emptyArray
);
export const getStatusesById = createSelector(getStatuses, (statuses) =>
  keyBy(statuses, byId)
);

export const getOwnStatusId = (state, ownProps) => ownProps.statusId;

export const makeGetOwnStatus = () =>
  createSelector(
    getStatusesById,
    getOwnStatusId,
    (statuses, ownStatusId) => statuses[ownStatusId]
  );

export const getStatusOrder = createSelector(
  getSelectedBoard,
  (selectedBoard) => (selectedBoard && selectedBoard.status_order) || emptyArray
);

export const getOrderedStatuses = createSelector(
  getStatusesById,
  getStatusOrder,
  (statusesById, statusIds) =>
    statusIds.map((id) => statusesById[id]).filter((status) => !!status)
);

export const getPriorities = createSelector(
  getSelectedBoard,
  (selectedBoard) => (selectedBoard && selectedBoard.priorities) || emptyArray
);
export const getPrioritiesById = createSelector(getPriorities, (stages) =>
  keyBy(stages, byId)
);

export const getOwnPriorityId = (state, ownProps) => ownProps.priorityId;

export const makeGetOwnPriority = () =>
  createSelector(
    getPrioritiesById,
    getOwnPriorityId,
    (statuses, ownPriorityId) => statuses[ownPriorityId]
  );

export const getPriorityOrder = createSelector(
  getSelectedBoard,
  (selectedBoard) =>
    (selectedBoard && selectedBoard.priority_order) || emptyArray
);

export const getOrderedPriorities = createSelector(
  getPrioritiesById,
  getPriorityOrder,
  (prioritiesById, priorityIds) =>
    priorityIds.map((id) => prioritiesById[id]).filter((priority) => !!priority)
);

export const getInviteState = (state) => state.invite || emptyObj;

export const getResentInvitesList = createSelector(
  getInviteState,
  (inviteState) => inviteState.inviteResent || emptyArray
);

export const getAppCuesFlowId = createSelector(
  getOnActivityFeed,
  getOnMembersView,
  getOnHomeView,
  getOnBoardView,
  getIsOnOwnTimesheets,
  getOnWorkloadView,
  getIsOnScheduleView,
  getIsOnHomeCompletedTasks,
  getIsOnHomeNotifications,
  getOnProjectDetail,
  getIsOnMembersSettings,
  getIsOnReportsView,
  getMatchedRouteParams,
  (
    isOnActivityFeed,
    isOnMemberPage,
    isOnHomePage,
    isOnBoardsPage,
    isOnOwnTimesheet,
    isOnWorkload,
    isOnSchedulePage,
    isOnHomeCompletedTasks,
    isOnHomeNotifications,
    isOnProjectDetail,
    isOnMembersSettings,
    isOnReportsView,
    matchedParams
  ) => {
    const isOnProjectBudget = matchedParams.projectViewType === 'budget';
    const viewType = matchedParams.viewType;
    if (isOnOwnTimesheet) {
      return APP_CUES.TIMESHEET_PAGE_APP_CUES;
    } else if (isOnHomeCompletedTasks) {
      return APP_CUES.HOME_COMPLETED;
    } else if (isOnHomeNotifications) {
      return APP_CUES.HOME_NOTIFICATIONS;
    } else if (isOnHomePage) {
      return APP_CUES.HOME_PAGE_APP_CUES;
    } else if (isOnBoardsPage) {
      return APP_CUES.TEAM_PAGE_APP_CUES;
    } else if (isOnWorkload) {
      return APP_CUES.WORKLOAD_PAGE_APP_CUES;
    } else if (isOnMemberPage) {
      return APP_CUES.MEMBERS_PAGE_APP_CUES;
    } else if (isOnActivityFeed) {
      return APP_CUES.ACTIVITY_FEED_PAGE_APP_CUES;
    } else if (isOnSchedulePage) {
      return APP_CUES.SCHEDULE_PAGE_APP_CUES;
    } else if (isOnProjectDetail) {
      if (isOnProjectBudget) {
        return APP_CUES.PDV_BUDGET_FLOW;
      } else {
        return APP_CUES.PDV_SHOW_FLOW;
      }
    } else if (isOnMembersSettings) {
      return APP_CUES.MEMBERS_SETTINGS;
    } else if (isOnReportsView) {
      switch (viewType) {
        case REPORT_VIEW_TYPE.TIMESHEET:
          return APP_CUES.REPORT_TIMESHEET_APP_CUES;

        case REPORT_VIEW_TYPE.TIMESHEET_STATUS:
          return APP_CUES.REPORT_TIMESHEET_STATUS_APP_CUES;

        case REPORT_VIEW_TYPE.BUDGET:
          return APP_CUES.REPORT_BUDGET_APP_CUES;

        case REPORT_VIEW_TYPE.WORKLOAD:
          return APP_CUES.REPORT_WORKLOAD_APP_CUES;

        case REPORT_VIEW_TYPE.ACTIVITY:
          return APP_CUES.REPORT_ACTIVITY_APP_CUES;

        case REPORT_VIEW_TYPE.PROFIT:
          return APP_CUES.REPORT_PROFIT_APP_CUES;

        case REPORT_VIEW_TYPE.VARIANCE:
          return APP_CUES.REPORT_VARIANCE_APP_CUES;

        case REPORT_VIEW_TYPE.UTILIZATION:
          return APP_CUES.REPORT_UTILIZATION_APP_CUES;
      }
    } else {
      return '';
    }
  }
);

export const getAllProjectMembers = (state) =>
  state.teamMembers.project_members;

export const getProjectsByMembersHash = createSelector(
  getAllProjectMembers,
  getOOOProject,
  getWFHProject,
  (allProjectMembers, OOOProject, WFHProject) => {
    const projectsByMembersHash = {};
    allProjectMembers.forEach((projectMember) => {
      if (
        projectMember.project_id !== OOOProject?.id &&
        projectMember.project_id !== WFHProject?.id
      ) {
        if (!projectsByMembersHash[projectMember.account_id]) {
          projectsByMembersHash[projectMember.account_id] = [
            projectMember.project_id
          ];
        } else {
          projectsByMembersHash[projectMember.account_id].push(
            projectMember.project_id
          );
        }
      }
    });
    return projectsByMembersHash;
  }
);

export * from './offline';
export * from './csvImport';
export * from './phases';
export * from './milestones';
export * from './permissions';
export * from './core';
export * from './planner';
export * from './workloadEvents';
export * from './homeWorkplans';
export * from './homeTimesheets';
export * from './navigations';
export * from './user';
export * from './timesheet';
export * from './projects';
export * from './team';
export * from './teamMembers';
export * from './filters';
export * from './timesheetActivityModal';
export * from './timelines';
export * from './access';
export * from './downloadFile';
export * from './activities';
export * from './workGroups';
export * from './skills';
export * from './clients';
export * from './profit';
export * from './filterStates';
export * from './recurly';
export * from './priorities';
export * from './suggestions';
export * from './notifications';
export * from './scopes';
export * from './triggers';
export * from './attachments';
export * from './comments';
export * from './timelineSettings';
