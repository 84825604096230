import React, { ReactNode, useState } from 'react';
import { getMyTeamMembership } from 'selectors';
import { updateTeamMembership } from 'actionCreators';
import theme from 'theme';
import styled from 'styled-components';
import { Modal } from 'reactstrap';
import NewCloseIcon from 'icons/NewCloseIcon';
import { TextButtonWithBorder, ButtonContainer } from 'components/styles';
import {
  StyledSelectToggleContainer,
  StyledSelectToggle,
  ClickableArea
} from 'components/BatchActions/styles';
import { useAppDispatch, useAppSelector } from 'reduxInfra/hooks';

export const StyledModal = styled(Modal)`
  color: ${theme.colors.colorMediumGray7};
  .modal-content {
    width: 460px;
    padding: 34px;
    border-radius: 3px !important;
    margin: auto;
  }
`;
export const Header = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;
export const Body = styled.div`
  font-size: 15px;
  margin-bottom: 34px;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const DoNotShowAgain = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.colorCalendarGray};
  font-size: 12px;
  margin-left: 0;
  margin-right: auto;
  ${StyledSelectToggleContainer} {
    margin-right: 8px;
  }
`;

const CloseIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 40px;
  position: absolute;
  right: 22px;
  top: 22px;

  &:hover {
    circle {
      fill: ${theme.colors.colorPaleGray1};
    }
  }
`;

const noop = () => undefined;

interface WrapperProps {
  isOpen: boolean;
  className?: string;
  toggle?: () => void;
}

interface SimpleConfirmModalProps {
  isOpen: boolean;
  header?: string | ReactNode;
  body: string | ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmOnly?: boolean;
  stylesWrapper?: (props: WrapperProps) => JSX.Element;
  hasDoNotShowAgain?: boolean;
  doNotShowAgainLabel?: string;
  doNotShowAgainSettingName?: string;
  submitButtonColor?: string;
  confirmOnLeft?: boolean;
  preventClickOut?: boolean;
  shouldSetIsClosingOnClose?: boolean;
  className?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  toggle?: () => void;
  hasNoButtons?: boolean;
  hasXCloseIcon?: boolean;
  returnFocusAfterClose?: boolean;
}

const SimpleConfirmModal = ({
  isOpen,
  header,
  body,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  confirmOnly = false,
  onConfirm = noop,
  stylesWrapper: StylesWrapper,
  hasDoNotShowAgain = false,
  doNotShowAgainLabel = 'Do not show this again',
  doNotShowAgainSettingName,
  submitButtonColor,
  confirmOnLeft = false,
  onCancel = noop,
  preventClickOut = false,
  className,
  shouldSetIsClosingOnClose = true,
  toggle = noop,
  hasNoButtons,
  hasXCloseIcon,
  returnFocusAfterClose
}: SimpleConfirmModalProps) => {
  const dispatch = useAppDispatch();
  const myTeamMembership = useAppSelector(getMyTeamMembership);
  const [isClosing, setIsClosing] = useState(false); // prevent double clicks
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const toggleDoNotShowAgain = () => setDoNotShowAgain((current) => !current);

  const handleClose = () => {
    if (doNotShowAgain && doNotShowAgainSettingName) {
      dispatch(
        updateTeamMembership({
          id: myTeamMembership.id,
          modal_preferences: {
            ...myTeamMembership.modal_preferences,
            [doNotShowAgainSettingName]: true
          }
        })
      );
    }
    if (shouldSetIsClosingOnClose) {
      setIsClosing(true);
    }

    toggle();
  };

  const handleConfirm = () => {
    if (!isClosing) {
      onConfirm();
      handleClose();
    }
  };

  const handleCancel = () => {
    if (!isClosing) {
      onCancel && onCancel();
      handleClose();
    }
  };

  const renderCancelButton = () => (
    <TextButtonWithBorder
      onClick={handleCancel}
      isHidden={confirmOnly}
      className="confirm-modal-cancel-btn"
      data-testid="confirm-modal-cancel-btn"
    >
      {cancelLabel}
    </TextButtonWithBorder>
  );

  const Wrapper = StylesWrapper || StyledModal;

  return (
    <Wrapper
      isOpen={isOpen}
      toggle={preventClickOut ? undefined : handleClose}
      className={className}
      returnFocusAfterClose={returnFocusAfterClose}
    >
      {header && <Header className="confirm-modal-header">{header}</Header>}
      {hasXCloseIcon && (
        <CloseIconContainer className="confirm-close-icon-container">
          <NewCloseIcon
            width={40}
            height={40}
            onClick={handleClose}
            stroke="transparent"
            testId="close-modal-btn"
          />
        </CloseIconContainer>
      )}
      <Body className="confirm-modal-body">{body}</Body>
      <Footer className="confirm-modal-footer">
        {hasDoNotShowAgain && doNotShowAgainSettingName && myTeamMembership && (
          <DoNotShowAgain className="confirm-modal-do-not-show">
            <StyledSelectToggleContainer>
              <ClickableArea
                onClick={toggleDoNotShowAgain}
                data-testid="do-not-show-again-toggle"
              />
              <StyledSelectToggle isChecked={doNotShowAgain} size={14} />
            </StyledSelectToggleContainer>
            {doNotShowAgainLabel}
          </DoNotShowAgain>
        )}
        {!hasNoButtons && (
          <ButtonContainer>
            {!confirmOnLeft && renderCancelButton()}
            <TextButtonWithBorder
              onClick={handleConfirm}
              backgroundColor={
                submitButtonColor || theme.colors.colorCalendarBlue
              }
              color="white"
              className="confirm-modal-confirm-btn"
              data-testid="confirm-modal-confirm-btn"
            >
              {confirmLabel}
            </TextButtonWithBorder>
            {confirmOnLeft && renderCancelButton()}
          </ButtonContainer>
        )}
      </Footer>
    </Wrapper>
  );
};

export default SimpleConfirmModal;
