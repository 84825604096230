import React from 'react';
import theme from 'theme';
import { StyledTeamName } from '../styles';
import { connect } from 'react-redux';
import {
  getTimesheetDateKeys,
  makeSuggestedProjectHash,
  getSplitFlags
} from 'selectors';
import {
  StyledProjectTitleContainer,
  StyledProjectTitle,
  StyledProjectDescription,
  StyledProjectNumber,
  StyledProjectInfo,
  StyledPhaseName,
  StyledPhaseNameContainer,
  TimesheetTopCell,
  StyledFolderIcon,
  StyledDot,
  StyledColorContainer,
  ArchivedText,
  StatusText,
  OutsideOfPhaseText,
  LightBulbIconContainer,
  SuggestionIconsContainer
} from './styles';
import {
  SelectContainer,
  StyledDownArrow
} from 'views/projectPlanner/workloadBarModal/styles';
import QBDownArrow from 'icons/QBDownArrow';

import ColorPicker from 'components/ColorPicker';
import ProjectsAndPhasesDropdown from 'components/ProjectsAndPhasesDropdown/ProjectsAndPhasesDropdown';
import PhaseMilestoneIconNarrow from 'icons/PhaseMilestoneIconNarrow';
import BudgetPhaseMilestoneIcon from 'icons/BudgetPhaseMilestoneIcon';
import { getProjectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts } from 'SuggestionModule/selectors';
import { makeFormattedProjectsAndPhasesWithPhaseSuggestionsForAccounts } from 'SuggestionModule/utils';

import { rebuildTooltip } from 'appUtils/tooltipUtils';
import {
  getPhaseDisplayBudgetStatus,
  isPhaseInactive
} from 'appUtils/phaseDisplayUtils';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import LightBulbIcon from 'icons/LightBulbIcon';
import { ORIGIN_TYPE_STRINGS } from 'appConstants/colorPicker';

const LightBulbTooltip = `Suggested Project from<br/> data in Mosaic`;

class TimesheetProject extends React.Component {
  state = { modalOpen: false };
  openModal = () => {
    const { onOpen } = this.props;
    this.setState({ modalOpen: true });
    if (onOpen) {
      onOpen();
    }
  };

  closeModal = () => {
    const { onClose } = this.props;
    this.setState({ modalOpen: false });
    if (onClose) {
      onClose();
    }
  };

  handleSelectProjectOrPhase = (projectOrPhase) => {
    const { handleSelectProjectOrPhase } = this.props;
    handleSelectProjectOrPhase(projectOrPhase);
  };

  componentDidMount() {
    rebuildTooltip();
  }

  componentDidUpdate() {
    rebuildTooltip();
  }

  isOutsideOfPhaseDatesOrArchived = () => {
    const { project, phase, timesheetDateKeys } = this.props;
    if (project?.is_archived || phase?.is_archived) {
      return true;
    }
    const earliestDate = moment(timesheetDateKeys[0]);
    const latestDate = moment(timesheetDateKeys[timesheetDateKeys.length - 1]);
    const hasPhaseDates = phase?.start_date && phase?.end_date;
    return (
      hasPhaseDates &&
      (earliestDate.isAfter(phase.end_date) ||
        latestDate.isBefore(phase.start_date))
    );
  };

  renderDropdownHeader = (copy) => <div>{copy}</div>;

  getOutsideofPhaseDateTooltip = () => {
    const { project, phase } = this.props;
    return project?.is_archived
      ? 'Project is archived'
      : phase?.is_archived
      ? 'Phase is archived'
      : `
    Time Entry dates are <br />outside of Phase dates<br />${moment(
      phase.start_date
    ).format('M/D/YY')} - ${moment(phase.end_date).format('M/D/YY')} 
  `;
  };

  render() {
    const { modalOpen } = this.state;
    const {
      project,
      phase,
      board,
      accountId,
      colorPickerLocation,
      ContainerEl,
      renderMenu,
      selectionDisabled,
      showDropdown,
      isModalOpen,
      isNew = false,
      onOpen,
      dropdownTarget,
      isThreeDotOpen,
      showTopRow,
      suggestedProjectHash,
      projectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts,
      flags
    } = this.props;

    const { homeTimesheetSuggestionFlag } = flags;

    const { projectsAndPhases, suggestedPhasesForAccountsHash } =
      projectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts;
    const formattedProjectsAndPhasesWithPhaseSuggestionsForMembers =
      homeTimesheetSuggestionFlag
        ? makeFormattedProjectsAndPhasesWithPhaseSuggestionsForAccounts({
            ...projectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts,
            accountId
          })
        : projectsAndPhases;

    const suggestedPhasesForAccountSet = new Set(
      (suggestedPhasesForAccountsHash[accountId] || []).map(
        (phase) => phase.phase_id
      )
    );

    const isSuggestedPhase = suggestedPhasesForAccountSet.has(phase?.id);

    const isSuggestedProject =
      suggestedProjectHash && project && suggestedProjectHash[project.id];

    /* allow override of modal open and selection disabled from props */
    const shouldShowDropdown =
      isModalOpen !== undefined ? isModalOpen : modalOpen && !selectionDisabled;

    const isInactive = isPhaseInactive(phase);
    const statusText = project
      ? isInactive
        ? getPhaseDisplayBudgetStatus(phase)
        : capitalize(project.budget_status)
      : '';
    return (
      <ContainerEl
        onClick={selectionDisabled ? null : this.openModal}
        ref={(ref) => {
          this.target = ref;
          if (dropdownTarget) {
            dropdownTarget.current = ref;
          }
        }}
      >
        {project && (
          <>
            <TimesheetTopCell shouldShow={isThreeDotOpen || showTopRow}>
              {renderMenu && renderMenu()}
              <StyledProjectNumber show={project.project_number}>
                <span
                  data-for="app-tooltip"
                  data-html
                  data-class="project-info-tooltips"
                  data-tip={project.project_number}
                  style={{ maxWidth: '135px' }}
                  className="no-text-overflow"
                >
                  {project.project_number}
                </span>
              </StyledProjectNumber>
              <StyledTeamName>
                <StyledFolderIcon />
                <span
                  data-for="app-tooltip"
                  data-html
                  data-class="project-info-tooltips"
                  data-tip={board?.name}
                  style={{ maxWidth: '135px' }}
                  className="no-text-overflow"
                >
                  {board?.name}
                </span>
              </StyledTeamName>
            </TimesheetTopCell>
            <div className="project-planner-group-title">
              <StyledProjectInfo
                data-for="app-tooltip"
                data-html="true"
                data-class="project-info-tooltips"
                data-tip={`${project.title} <br /> ${
                  project.description ? `${project.description}` : ''
                }`}
              >
                <StyledDot projectId={project.id}></StyledDot>
                <StyledColorContainer
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <ColorPicker
                    projectId={project.id}
                    id={project.id}
                    originType={ORIGIN_TYPE_STRINGS.PROJECT}
                    boundariesElement="window"
                    pickerLocation={colorPickerLocation}
                    row
                    className="color-picker-container"
                  />
                </StyledColorContainer>
                <StyledProjectTitleContainer
                  data-multiline={true}
                  id={project.id}
                >
                  <StyledProjectTitle>
                    <span className="no-text-overflow">{project.title} </span>

                    {project?.is_archived ? (
                      <ArchivedText> | Archived</ArchivedText>
                    ) : (
                      ''
                    )}
                  </StyledProjectTitle>
                  <StyledProjectDescription>
                    {project?.description?.length ? ' - ' : null}
                    {project.description}
                  </StyledProjectDescription>
                </StyledProjectTitleContainer>

                {showDropdown && (
                  <StyledDownArrow className="styled-down-arrow">
                    <QBDownArrow />
                  </StyledDownArrow>
                )}
                <SuggestionIconsContainer>
                  {isSuggestedProject && (
                    <LightBulbIconContainer
                      data-for="app-tooltip"
                      data-html
                      data-tip={LightBulbTooltip}
                    >
                      <LightBulbIcon fill={theme.colors.colorMediumPurple2} />
                    </LightBulbIconContainer>
                  )}
                  {/* {isSuggestedPhase && (
                    <LightBulbIconContainer
                      data-for="app-tooltip"
                      data-html
                      data-tip={'Suggested Phase'}
                    >
                      <LightBulbIcon fill={theme.colors.colorRoyalBlue} />
                    </LightBulbIconContainer>
                  )} */}
                </SuggestionIconsContainer>
              </StyledProjectInfo>
            </div>
            <StyledPhaseNameContainer ref={(ref) => (this.target = ref)}>
              {statusText && <StatusText>{statusText}</StatusText>}
              {phase && !phase.is_like_default && phase?.is_budget && (
                <BudgetPhaseMilestoneIcon width="12" height="14" />
              )}
              <StyledPhaseName>
                {phase && !phase.is_like_default ? phase?.name : ''}
                {this.isOutsideOfPhaseDatesOrArchived() && (
                  <OutsideOfPhaseText
                    data-for="app-tooltip"
                    data-html="true"
                    data-class="center"
                    data-tip={this.getOutsideofPhaseDateTooltip()}
                  >
                    !
                  </OutsideOfPhaseText>
                )}
              </StyledPhaseName>
            </StyledPhaseNameContainer>
          </>
        )}
        {isNew && !project && (
          <SelectContainer onClick={onOpen}>
            {`Select Project`}
            <StyledDownArrow>
              <QBDownArrow />
            </StyledDownArrow>
          </SelectContainer>
        )}

        {shouldShowDropdown && (
          <ProjectsAndPhasesDropdown
            target={this.target}
            handleSelect={this.handleSelectProjectOrPhase}
            handleClose={this.closeModal}
            renderHeader={this.renderDropdownHeader}
            accountId={accountId}
            text={'time entry'}
            projectsAndPhases={
              formattedProjectsAndPhasesWithPhaseSuggestionsForMembers
            }
            onOpen={onOpen}
          />
        )}
      </ContainerEl>
    );
  }
}

const mapStateToProps = (state) => ({
  timesheetDateKeys: getTimesheetDateKeys(state),
  projectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts:
    getProjectsAndPhasesWithPhaseSuggestionsForTimesheetAccounts(state),
  suggestedProjectHash: makeSuggestedProjectHash(state),
  flags: getSplitFlags(state)
});
export default connect(mapStateToProps)(TimesheetProject);
