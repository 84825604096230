import AddViewTasksIcon from 'images/addviewtasksicon.svg';
import AddViewNotesIcon from 'images/addviewnotesicon.svg';
import AddViewMeetingIcon from 'images/addviewmeetingicon.svg';
import AddViewPlannerIcon from 'images/addviewplannericon.svg';
import AddViewTimelinesIcon from 'images/addviewtimelinesicon.svg';

export const moduleTextHash = {
  1: 'General',
  2: 'Settings',
  3: 'Members',
  4: 'Permissions',
  5: 'Payment',
  6: 'Portfolios',
  7: 'Standards',
  8: 'Help',
  9: 'Security',
  10: 'Activity',
  11: 'Integrations',
  12: 'Timesheet',
  13: 'Import',
  14: 'Budget'
  // 15: 'Billing'
};

export const moduleHash = {
  1: 'info',
  2: 'settings',
  3: 'members', // default nested tab
  4: 'permissions',
  5: 'payment',
  6: 'teams',
  7: 'standards',
  8: 'help',
  9: 'security',
  10: 'activity',
  11: 'integrations',
  12: 'timesheet',
  13: 'import',
  14: 'budget'
  // 15: 'billing'
};

export const defaultNavModuleIds = [1, 3, 4, 14, 6, 7, 9, 13, 11, 12];

const nonDefaultNavModuleIds = [];
export const comingSoonModuleIds = [];
// 'break' is used to split active and inactive sections in dnd list
// It is added by the selector in the case where defaultModuleIds is not used
export const defaultModuleIds = [
  ...defaultNavModuleIds,
  'break',
  ...nonDefaultNavModuleIds
];
export const allModuleIds = [...defaultNavModuleIds, ...nonDefaultNavModuleIds];

export const moduleDescriptionHash = {
  1: 'Manage Members.',
  2: 'View the latest timesheet submissions and manage approvals for teams that track time.',
  3: `Keep up on your teams workload at a glance with a view of everyone's planned tasks.`,
  4: `Keep up on your teams workload at a glance with a heatmap view of everyone's planned hours.`,
  5: `All your team's tasks in one place`
};
export const moduleColorHash = {
  1: '#78c946',
  2: '#3db489',
  3: '#DAAD0D',
  4: '#E16AA5',
  5: '#F14848'
};

export const moduleIconHash = {
  1: `url(${AddViewTasksIcon}) `,
  2: `url(${AddViewNotesIcon}) `,
  3: `url(${AddViewTimelinesIcon}) `,
  4: `url(${AddViewPlannerIcon}) `,
  5: `url(${AddViewMeetingIcon}) `
};

export const generateModuleBackgroundColor = ({ appModule }) =>
  moduleColorHash[appModule];

export const generateModuleBackgroundIcon = ({ appModule }) =>
  moduleIconHash[appModule];
