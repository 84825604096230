import styled, { css } from 'styled-components';
import theme from 'theme';
import { StyledStickyHeader } from 'components/Table/TableStickyHeader';
import type { FilterListsTableVariant } from './types';
import {
  stackedFilterMinLeftPaddingColumnWidth,
  stackedFilterRightPaddingColumnWidth,
  stackedFilterMaxInnerColumnWidth,
  stackedFilterLabelColumnWidth,
  stackedFilterSelectColumnWidth
} from './tableConfigs';
import {
  StyledSelectToggle,
  ClickableArea
} from 'components/BatchActions/styles';
import KaratRight from 'icons/KaratRight';

const ContainerDivStylesByTableVariant: Record<
  FilterListsTableVariant,
  ReturnType<typeof css>
> = {
  StackedFilters: css`
    width: 240px;
    padding-left: 17px; // todo - move to tableconfig
    padding-bottom: 30px;
    flex: 1;
    min-height: 0;
  `,

  SideFilter: css`
    height: 100%;
    width: 100%;
  `
};

export const ContainerDiv = styled.div<{
  tableVariant: FilterListsTableVariant;
}>`
  /* base shared styles */
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  /* table variant specific styles */
  ${({ tableVariant }) => ContainerDivStylesByTableVariant[tableVariant]}
`;

const StyledFilterListsTableStylesByTableVariant: Record<
  FilterListsTableVariant,
  ReturnType<typeof css>
> = {
  StackedFilters: css`
    background: ${theme.colors.colorLightGray19};
    width: 100%;

    .table {
      flex: 1;
      min-height: 0;
    }

    .tr {
      &.showHiddenItemsRow {
        .td.inner {
          padding-left: 28px;
        }
      }
    }

    ${StyledStickyHeader} {
      grid-template-columns: ${stackedFilterMinLeftPaddingColumnWidth}px ${stackedFilterMaxInnerColumnWidth}px ${stackedFilterRightPaddingColumnWidth}px;

      .td {
        background: ${theme.colors.colorLightGray19};
      }

      &.defaultFilterListTypeHeaderRow {
        top: 34px;
      }

      &.defaultFilterGroupHeaderRow {
        grid-template-columns: ${stackedFilterMinLeftPaddingColumnWidth}px ${stackedFilterSelectColumnWidth}px ${stackedFilterLabelColumnWidth}px ${stackedFilterRightPaddingColumnWidth}px;
        top: 194px;
      }

      &.filterListTypeSelectorRow {
        top: 104px;
      }

      &.searchInputRow {
        // no sticky search row above
        &.sticky-0 {
          top: 103px;
        }
        // has sticky row above
        &.sticky-1 {
          top: 134px;
        }
      }

      &.batchActionsRow {
        // no sticky search row above
        &.sticky-0 {
          top: 104px;
        }
        // has sticky row above
        &.sticky-1 {
          top: 133px;
        }
        // has 2 sticky rows above
        &.sticky-2 {
          top: 164px;
        }
      }
    }
  `,

  SideFilter: css`
    width: 100%;

    .table {
      height: 100%;
    }

    ${StyledStickyHeader} {
      // match tableConfigs cell widths
      &.searchInputRow,
      &.batchActionsRow {
        grid-template-columns: 16px 270px 14px;
      }

      &.searchInputRow {
        top: 10px;
      }

      &.batchActionsRow {
        // no sticky search row above
        &.sticky-0 {
          top: 10px;
        }
        // has sticky row above
        &.sticky-1 {
          top: 40px;
        }
        // has 2 sticky rows above
        &.sticky-2 {
          top: 133px;
        }
      }

      &.defaultFilterGroupHeaderRow {
        grid-template-columns: 10px 25px 245px 20px;
        top: 70px;
      }
    }
  `
};

export const StyledFilterListsTable = styled.div<{
  tableVariant: FilterListsTableVariant;
}>`
  /* base shared styles */
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  .table {
    margin-bottom: 0;
  }

  .table-body {
    height: 100%;
  }

  .variable-size-list {
    height: 100% !important;
    padding-bottom: 20px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
  }

  .tr {
    // row hover background
    &.defaultGroupedFilterItemRow,
    &.defaultFilterItemRow,
    &.defaultFilterGroupHeaderRow,
    &.addEntityRow,
    &.showArchivedRow {
      &:hover {
        background: ${theme.colors.colorTranslucentGray4};
      }
    }
  }

  .td {
    height: 100%;
    flex-shrink: 0;
    // default cell font
    font-size: 13px;
    color: ${theme.colors.colorSemiDarkGray1};

    &.rightPadding {
      flex-shrink: 1;
    }
  }

  ${StyledStickyHeader} {
    padding: 0;

    &:hover:not(.batchActionsRow):not(.searchInputRow):not(.filterListTypeSelectorRow)
      .td {
      background: ${theme.colors.colorTranslucentGray4};
    }

    &.filterListTypeSelectorRow {
      z-index: 10;
    }
  }

  /* table variant specific styles */
  ${({ tableVariant }) =>
    StyledFilterListsTableStylesByTableVariant[tableVariant]}
`;

export const ClearAllButton = styled.div`
  width: 100%;
  height: 24px;
  font-size: 12px;
  padding: 4px 20px;
  color: ${theme.colors.colorCalendarBlue};
  border-radius: 5px;
  ${(props) =>
    props.onClick &&
    `
    cursor: pointer;
    &:hover {
      filter: brightness(80%);
    }
  `}
`;

export const CustomStyledSelectToggle = styled(StyledSelectToggle)<{
  isHidden?: boolean;
}>`
  .muted &,
  .muted &:after {
    background: transparent;
  }
  ${({ isHidden }) => isHidden && `visibility: hidden;`}
`;

export const CustomClickableArea = styled(ClickableArea)<{
  showIndeterminateStateOnHover: boolean;
  toggleSize: number;
  showCheckedOnHover: boolean;
}>`
  ${({ showIndeterminateStateOnHover, toggleSize, theme }) =>
    showIndeterminateStateOnHover &&
    // top = (height of ClickableArea - height of minus) / 2, to put the minus in the center vertically
    // left = ((width of ClickableArea - toggleSize) / 2) + ((toggleSize - width of minus) / 2), toggleSize - width of minus = toggleSize - toggleSize + 6 = 6
    `
    &:hover {
      &::before {
        content: '';
        position: absolute;
        top: 
        calc((100% - 2px) / 2);
        left: calc((100% - ${toggleSize}px) / 2 + 3px);
        z-index: 1;
        width: ${toggleSize - 6}px;
        height: 2px;
        background: ${theme.colors.colorPureWhite};
      }
    }
  `}
  ${({ showCheckedOnHover, theme }) =>
    showCheckedOnHover &&
    `
    &:hover {
      & + ${CustomStyledSelectToggle} {
        &::after {
          background: ${theme.colors.colorRoyalBlue};
        }
      }
    } 
  `}
`;

export const StyledKaratRight = styled(KaratRight)<{ isOpen: boolean }>`
  transform-origin: center center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : '')};
  path {
    fill: ${theme.colors.colorLightGray18};
  }
  transition: transform 0.275s ease-in-out;
  margin-right: 6px;
`;
